'use strict';

var cov_bdrjt0qwr = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/project/dashboard/projectDashboardCtrl.js',
      hash = '1db0fe594cbd60b42f645733cadbb134e977c451',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/project/dashboard/projectDashboardCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 712,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 26
        }
      },
      '2': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 3,
          column: 31
        }
      },
      '3': {
        start: {
          line: 4,
          column: 25
        },
        end: {
          line: 4,
          column: 38
        }
      },
      '4': {
        start: {
          line: 5,
          column: 26
        },
        end: {
          line: 5,
          column: 39
        }
      },
      '5': {
        start: {
          line: 6,
          column: 24
        },
        end: {
          line: 6,
          column: 35
        }
      },
      '6': {
        start: {
          line: 7,
          column: 27
        },
        end: {
          line: 7,
          column: 40
        }
      },
      '7': {
        start: {
          line: 8,
          column: 33
        },
        end: {
          line: 8,
          column: 52
        }
      },
      '8': {
        start: {
          line: 9,
          column: 20
        },
        end: {
          line: 9,
          column: 26
        }
      },
      '9': {
        start: {
          line: 12,
          column: 20
        },
        end: {
          line: 12,
          column: 46
        }
      },
      '10': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 16,
          column: 4
        }
      },
      '11': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 22,
          column: 4
        }
      },
      '12': {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 28,
          column: 4
        }
      },
      '13': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 32,
          column: 4
        }
      },
      '14': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 31,
          column: 16
        }
      },
      '15': {
        start: {
          line: 33,
          column: 2
        },
        end: {
          line: 47,
          column: 4
        }
      },
      '16': {
        start: {
          line: 34,
          column: 15
        },
        end: {
          line: 34,
          column: 17
        }
      },
      '17': {
        start: {
          line: 35,
          column: 4
        },
        end: {
          line: 38,
          column: 5
        }
      },
      '18': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 36,
          column: 45
        }
      },
      '19': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 37,
          column: 35
        }
      },
      '20': {
        start: {
          line: 39,
          column: 4
        },
        end: {
          line: 39,
          column: 43
        }
      },
      '21': {
        start: {
          line: 40,
          column: 4
        },
        end: {
          line: 42,
          column: 5
        }
      },
      '22': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 41,
          column: 45
        }
      },
      '23': {
        start: {
          line: 44,
          column: 19
        },
        end: {
          line: 44,
          column: 59
        }
      },
      '24': {
        start: {
          line: 46,
          column: 4
        },
        end: {
          line: 46,
          column: 47
        }
      },
      '25': {
        start: {
          line: 49,
          column: 2
        },
        end: {
          line: 71,
          column: 5
        }
      },
      '26': {
        start: {
          line: 50,
          column: 4
        },
        end: {
          line: 53,
          column: 5
        }
      },
      '27': {
        start: {
          line: 51,
          column: 6
        },
        end: {
          line: 51,
          column: 29
        }
      },
      '28': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 52,
          column: 13
        }
      },
      '29': {
        start: {
          line: 54,
          column: 4
        },
        end: {
          line: 70,
          column: 5
        }
      },
      '30': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 57,
          column: 7
        }
      },
      '31': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 56,
          column: 17
        }
      },
      '32': {
        start: {
          line: 58,
          column: 6
        },
        end: {
          line: 58,
          column: 70
        }
      },
      '33': {
        start: {
          line: 59,
          column: 6
        },
        end: {
          line: 59,
          column: 46
        }
      },
      '34': {
        start: {
          line: 60,
          column: 6
        },
        end: {
          line: 60,
          column: 74
        }
      },
      '35': {
        start: {
          line: 61,
          column: 6
        },
        end: {
          line: 61,
          column: 70
        }
      },
      '36': {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 62,
          column: 75
        }
      },
      '37': {
        start: {
          line: 63,
          column: 6
        },
        end: {
          line: 63,
          column: 62
        }
      },
      '38': {
        start: {
          line: 64,
          column: 19
        },
        end: {
          line: 64,
          column: 21
        }
      },
      '39': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 67,
          column: 7
        }
      },
      '40': {
        start: {
          line: 66,
          column: 8
        },
        end: {
          line: 66,
          column: 47
        }
      },
      '41': {
        start: {
          line: 68,
          column: 6
        },
        end: {
          line: 68,
          column: 48
        }
      },
      '42': {
        start: {
          line: 69,
          column: 6
        },
        end: {
          line: 69,
          column: 37
        }
      },
      '43': {
        start: {
          line: 74,
          column: 2
        },
        end: {
          line: 130,
          column: 5
        }
      },
      '44': {
        start: {
          line: 75,
          column: 4
        },
        end: {
          line: 80,
          column: 7
        }
      },
      '45': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 76,
          column: 57
        }
      },
      '46': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 77,
          column: 63
        }
      },
      '47': {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 78,
          column: 53
        }
      },
      '48': {
        start: {
          line: 79,
          column: 6
        },
        end: {
          line: 79,
          column: 19
        }
      },
      '49': {
        start: {
          line: 82,
          column: 4
        },
        end: {
          line: 82,
          column: 47
        }
      },
      '50': {
        start: {
          line: 83,
          column: 4
        },
        end: {
          line: 96,
          column: 6
        }
      },
      '51': {
        start: {
          line: 103,
          column: 4
        },
        end: {
          line: 129,
          column: 6
        }
      },
      '52': {
        start: {
          line: 108,
          column: 12
        },
        end: {
          line: 111,
          column: 14
        }
      },
      '53': {
        start: {
          line: 113,
          column: 12
        },
        end: {
          line: 113,
          column: 38
        }
      },
      '54': {
        start: {
          line: 118,
          column: 8
        },
        end: {
          line: 120,
          column: 9
        }
      },
      '55': {
        start: {
          line: 119,
          column: 10
        },
        end: {
          line: 119,
          column: 56
        }
      },
      '56': {
        start: {
          line: 121,
          column: 8
        },
        end: {
          line: 121,
          column: 74
        }
      },
      '57': {
        start: {
          line: 133,
          column: 2
        },
        end: {
          line: 197,
          column: 5
        }
      },
      '58': {
        start: {
          line: 134,
          column: 4
        },
        end: {
          line: 134,
          column: 33
        }
      },
      '59': {
        start: {
          line: 140,
          column: 4
        },
        end: {
          line: 196,
          column: 6
        }
      },
      '60': {
        start: {
          line: 200,
          column: 2
        },
        end: {
          line: 294,
          column: 5
        }
      },
      '61': {
        start: {
          line: 201,
          column: 19
        },
        end: {
          line: 201,
          column: 21
        }
      },
      '62': {
        start: {
          line: 202,
          column: 4
        },
        end: {
          line: 205,
          column: 5
        }
      },
      '63': {
        start: {
          line: 203,
          column: 6
        },
        end: {
          line: 203,
          column: 28
        }
      },
      '64': {
        start: {
          line: 204,
          column: 6
        },
        end: {
          line: 204,
          column: 32
        }
      },
      '65': {
        start: {
          line: 207,
          column: 22
        },
        end: {
          line: 207,
          column: 24
        }
      },
      '66': {
        start: {
          line: 209,
          column: 4
        },
        end: {
          line: 211,
          column: 7
        }
      },
      '67': {
        start: {
          line: 210,
          column: 6
        },
        end: {
          line: 210,
          column: 40
        }
      },
      '68': {
        start: {
          line: 213,
          column: 4
        },
        end: {
          line: 253,
          column: 5
        }
      },
      '69': {
        start: {
          line: 214,
          column: 20
        },
        end: {
          line: 216,
          column: 8
        }
      },
      '70': {
        start: {
          line: 215,
          column: 8
        },
        end: {
          line: 215,
          column: 81
        }
      },
      '71': {
        start: {
          line: 217,
          column: 6
        },
        end: {
          line: 223,
          column: 7
        }
      },
      '72': {
        start: {
          line: 218,
          column: 8
        },
        end: {
          line: 222,
          column: 43
        }
      },
      '73': {
        start: {
          line: 224,
          column: 6
        },
        end: {
          line: 252,
          column: 7
        }
      },
      '74': {
        start: {
          line: 225,
          column: 8
        },
        end: {
          line: 225,
          column: 73
        }
      },
      '75': {
        start: {
          line: 226,
          column: 8
        },
        end: {
          line: 226,
          column: 73
        }
      },
      '76': {
        start: {
          line: 227,
          column: 8
        },
        end: {
          line: 227,
          column: 80
        }
      },
      '77': {
        start: {
          line: 229,
          column: 8
        },
        end: {
          line: 240,
          column: 11
        }
      },
      '78': {
        start: {
          line: 241,
          column: 8
        },
        end: {
          line: 246,
          column: 11
        }
      },
      '79': {
        start: {
          line: 247,
          column: 8
        },
        end: {
          line: 251,
          column: 11
        }
      },
      '80': {
        start: {
          line: 255,
          column: 4
        },
        end: {
          line: 290,
          column: 6
        }
      },
      '81': {
        start: {
          line: 259,
          column: 21
        },
        end: {
          line: 259,
          column: 30
        }
      },
      '82': {
        start: {
          line: 260,
          column: 8
        },
        end: {
          line: 263,
          column: 9
        }
      },
      '83': {
        start: {
          line: 261,
          column: 10
        },
        end: {
          line: 261,
          column: 27
        }
      },
      '84': {
        start: {
          line: 262,
          column: 10
        },
        end: {
          line: 262,
          column: 17
        }
      },
      '85': {
        start: {
          line: 265,
          column: 8
        },
        end: {
          line: 267,
          column: 9
        }
      },
      '86': {
        start: {
          line: 266,
          column: 10
        },
        end: {
          line: 266,
          column: 27
        }
      },
      '87': {
        start: {
          line: 269,
          column: 8
        },
        end: {
          line: 271,
          column: 9
        }
      },
      '88': {
        start: {
          line: 270,
          column: 10
        },
        end: {
          line: 270,
          column: 27
        }
      },
      '89': {
        start: {
          line: 273,
          column: 8
        },
        end: {
          line: 275,
          column: 9
        }
      },
      '90': {
        start: {
          line: 274,
          column: 10
        },
        end: {
          line: 274,
          column: 27
        }
      },
      '91': {
        start: {
          line: 297,
          column: 2
        },
        end: {
          line: 550,
          column: 5
        }
      },
      '92': {
        start: {
          line: 298,
          column: 4
        },
        end: {
          line: 447,
          column: 6
        }
      },
      '93': {
        start: {
          line: 300,
          column: 21
        },
        end: {
          line: 300,
          column: 30
        }
      },
      '94': {
        start: {
          line: 301,
          column: 8
        },
        end: {
          line: 303,
          column: 9
        }
      },
      '95': {
        start: {
          line: 302,
          column: 10
        },
        end: {
          line: 302,
          column: 35
        }
      },
      '96': {
        start: {
          line: 306,
          column: 8
        },
        end: {
          line: 308,
          column: 9
        }
      },
      '97': {
        start: {
          line: 307,
          column: 10
        },
        end: {
          line: 307,
          column: 55
        }
      },
      '98': {
        start: {
          line: 309,
          column: 8
        },
        end: {
          line: 309,
          column: 52
        }
      },
      '99': {
        start: {
          line: 312,
          column: 8
        },
        end: {
          line: 314,
          column: 9
        }
      },
      '100': {
        start: {
          line: 313,
          column: 10
        },
        end: {
          line: 313,
          column: 17
        }
      },
      '101': {
        start: {
          line: 315,
          column: 8
        },
        end: {
          line: 315,
          column: 51
        }
      },
      '102': {
        start: {
          line: 317,
          column: 22
        },
        end: {
          line: 317,
          column: 31
        }
      },
      '103': {
        start: {
          line: 319,
          column: 8
        },
        end: {
          line: 322,
          column: 11
        }
      },
      '104': {
        start: {
          line: 323,
          column: 8
        },
        end: {
          line: 323,
          column: 111
        }
      },
      '105': {
        start: {
          line: 324,
          column: 8
        },
        end: {
          line: 324,
          column: 113
        }
      },
      '106': {
        start: {
          line: 326,
          column: 8
        },
        end: {
          line: 326,
          column: 71
        }
      },
      '107': {
        start: {
          line: 327,
          column: 8
        },
        end: {
          line: 330,
          column: 9
        }
      },
      '108': {
        start: {
          line: 328,
          column: 10
        },
        end: {
          line: 328,
          column: 52
        }
      },
      '109': {
        start: {
          line: 329,
          column: 10
        },
        end: {
          line: 329,
          column: 17
        }
      },
      '110': {
        start: {
          line: 331,
          column: 8
        },
        end: {
          line: 331,
          column: 53
        }
      },
      '111': {
        start: {
          line: 332,
          column: 22
        },
        end: {
          line: 332,
          column: 29
        }
      },
      '112': {
        start: {
          line: 333,
          column: 20
        },
        end: {
          line: 333,
          column: 27
        }
      },
      '113': {
        start: {
          line: 334,
          column: 22
        },
        end: {
          line: 334,
          column: 45
        }
      },
      '114': {
        start: {
          line: 335,
          column: 23
        },
        end: {
          line: 400,
          column: 9
        }
      },
      '115': {
        start: {
          line: 402,
          column: 8
        },
        end: {
          line: 405,
          column: 11
        }
      },
      '116': {
        start: {
          line: 406,
          column: 8
        },
        end: {
          line: 409,
          column: 11
        }
      },
      '117': {
        start: {
          line: 410,
          column: 8
        },
        end: {
          line: 413,
          column: 11
        }
      },
      '118': {
        start: {
          line: 414,
          column: 8
        },
        end: {
          line: 427,
          column: 9
        }
      },
      '119': {
        start: {
          line: 415,
          column: 10
        },
        end: {
          line: 418,
          column: 13
        }
      },
      '120': {
        start: {
          line: 419,
          column: 10
        },
        end: {
          line: 422,
          column: 13
        }
      },
      '121': {
        start: {
          line: 423,
          column: 10
        },
        end: {
          line: 426,
          column: 13
        }
      },
      '122': {
        start: {
          line: 428,
          column: 8
        },
        end: {
          line: 431,
          column: 11
        }
      },
      '123': {
        start: {
          line: 432,
          column: 8
        },
        end: {
          line: 435,
          column: 11
        }
      },
      '124': {
        start: {
          line: 436,
          column: 8
        },
        end: {
          line: 439,
          column: 11
        }
      },
      '125': {
        start: {
          line: 441,
          column: 8
        },
        end: {
          line: 441,
          column: 52
        }
      },
      '126': {
        start: {
          line: 444,
          column: 8
        },
        end: {
          line: 444,
          column: 50
        }
      },
      '127': {
        start: {
          line: 445,
          column: 8
        },
        end: {
          line: 445,
          column: 49
        }
      },
      '128': {
        start: {
          line: 448,
          column: 4
        },
        end: {
          line: 448,
          column: 63
        }
      },
      '129': {
        start: {
          line: 451,
          column: 4
        },
        end: {
          line: 451,
          column: 18
        }
      },
      '130': {
        start: {
          line: 452,
          column: 18
        },
        end: {
          line: 452,
          column: 24
        }
      },
      '131': {
        start: {
          line: 453,
          column: 4
        },
        end: {
          line: 453,
          column: 43
        }
      },
      '132': {
        start: {
          line: 454,
          column: 24
        },
        end: {
          line: 454,
          column: 26
        }
      },
      '133': {
        start: {
          line: 455,
          column: 20
        },
        end: {
          line: 462,
          column: 5
        }
      },
      '134': {
        start: {
          line: 459,
          column: 8
        },
        end: {
          line: 459,
          column: 51
        }
      },
      '135': {
        start: {
          line: 463,
          column: 4
        },
        end: {
          line: 468,
          column: 5
        }
      },
      '136': {
        start: {
          line: 464,
          column: 6
        },
        end: {
          line: 467,
          column: 10
        }
      },
      '137': {
        start: {
          line: 469,
          column: 4
        },
        end: {
          line: 469,
          column: 168
        }
      },
      '138': {
        start: {
          line: 471,
          column: 4
        },
        end: {
          line: 471,
          column: 27
        }
      },
      '139': {
        start: {
          line: 472,
          column: 4
        },
        end: {
          line: 472,
          column: 61
        }
      },
      '140': {
        start: {
          line: 475,
          column: 4
        },
        end: {
          line: 511,
          column: 5
        }
      },
      '141': {
        start: {
          line: 476,
          column: 6
        },
        end: {
          line: 478,
          column: 64
        }
      },
      '142': {
        start: {
          line: 479,
          column: 6
        },
        end: {
          line: 486,
          column: 7
        }
      },
      '143': {
        start: {
          line: 480,
          column: 8
        },
        end: {
          line: 480,
          column: 62
        }
      },
      '144': {
        start: {
          line: 481,
          column: 8
        },
        end: {
          line: 481,
          column: 62
        }
      },
      '145': {
        start: {
          line: 482,
          column: 8
        },
        end: {
          line: 485,
          column: 9
        }
      },
      '146': {
        start: {
          line: 483,
          column: 10
        },
        end: {
          line: 483,
          column: 70
        }
      },
      '147': {
        start: {
          line: 484,
          column: 10
        },
        end: {
          line: 484,
          column: 82
        }
      },
      '148': {
        start: {
          line: 487,
          column: 19
        },
        end: {
          line: 487,
          column: 35
        }
      },
      '149': {
        start: {
          line: 488,
          column: 6
        },
        end: {
          line: 488,
          column: 25
        }
      },
      '150': {
        start: {
          line: 489,
          column: 6
        },
        end: {
          line: 489,
          column: 29
        }
      },
      '151': {
        start: {
          line: 490,
          column: 6
        },
        end: {
          line: 510,
          column: 7
        }
      },
      '152': {
        start: {
          line: 491,
          column: 8
        },
        end: {
          line: 491,
          column: 38
        }
      },
      '153': {
        start: {
          line: 492,
          column: 8
        },
        end: {
          line: 492,
          column: 23
        }
      },
      '154': {
        start: {
          line: 493,
          column: 8
        },
        end: {
          line: 493,
          column: 63
        }
      },
      '155': {
        start: {
          line: 494,
          column: 8
        },
        end: {
          line: 495,
          column: 124
        }
      },
      '156': {
        start: {
          line: 496,
          column: 8
        },
        end: {
          line: 496,
          column: 23
        }
      },
      '157': {
        start: {
          line: 497,
          column: 8
        },
        end: {
          line: 497,
          column: 23
        }
      },
      '158': {
        start: {
          line: 498,
          column: 8
        },
        end: {
          line: 498,
          column: 25
        }
      },
      '159': {
        start: {
          line: 499,
          column: 8
        },
        end: {
          line: 499,
          column: 40
        }
      },
      '160': {
        start: {
          line: 500,
          column: 8
        },
        end: {
          line: 500,
          column: 23
        }
      },
      '161': {
        start: {
          line: 501,
          column: 8
        },
        end: {
          line: 501,
          column: 23
        }
      },
      '162': {
        start: {
          line: 502,
          column: 8
        },
        end: {
          line: 502,
          column: 27
        }
      },
      '163': {
        start: {
          line: 503,
          column: 8
        },
        end: {
          line: 503,
          column: 23
        }
      },
      '164': {
        start: {
          line: 505,
          column: 8
        },
        end: {
          line: 508,
          column: 9
        }
      },
      '165': {
        start: {
          line: 506,
          column: 10
        },
        end: {
          line: 506,
          column: 72
        }
      },
      '166': {
        start: {
          line: 507,
          column: 10
        },
        end: {
          line: 507,
          column: 84
        }
      },
      '167': {
        start: {
          line: 509,
          column: 8
        },
        end: {
          line: 509,
          column: 30
        }
      },
      '168': {
        start: {
          line: 513,
          column: 4
        },
        end: {
          line: 531,
          column: 7
        }
      },
      '169': {
        start: {
          line: 514,
          column: 6
        },
        end: {
          line: 516,
          column: 7
        }
      },
      '170': {
        start: {
          line: 515,
          column: 8
        },
        end: {
          line: 515,
          column: 17
        }
      },
      '171': {
        start: {
          line: 517,
          column: 6
        },
        end: {
          line: 519,
          column: 7
        }
      },
      '172': {
        start: {
          line: 518,
          column: 8
        },
        end: {
          line: 518,
          column: 18
        }
      },
      '173': {
        start: {
          line: 521,
          column: 6
        },
        end: {
          line: 523,
          column: 7
        }
      },
      '174': {
        start: {
          line: 522,
          column: 8
        },
        end: {
          line: 522,
          column: 17
        }
      },
      '175': {
        start: {
          line: 524,
          column: 6
        },
        end: {
          line: 526,
          column: 7
        }
      },
      '176': {
        start: {
          line: 525,
          column: 8
        },
        end: {
          line: 525,
          column: 18
        }
      },
      '177': {
        start: {
          line: 527,
          column: 6
        },
        end: {
          line: 529,
          column: 7
        }
      },
      '178': {
        start: {
          line: 528,
          column: 8
        },
        end: {
          line: 528,
          column: 18
        }
      },
      '179': {
        start: {
          line: 530,
          column: 6
        },
        end: {
          line: 530,
          column: 15
        }
      },
      '180': {
        start: {
          line: 535,
          column: 24
        },
        end: {
          line: 535,
          column: 26
        }
      },
      '181': {
        start: {
          line: 536,
          column: 4
        },
        end: {
          line: 546,
          column: 5
        }
      },
      '182': {
        start: {
          line: 537,
          column: 17
        },
        end: {
          line: 537,
          column: 34
        }
      },
      '183': {
        start: {
          line: 538,
          column: 6
        },
        end: {
          line: 538,
          column: 64
        }
      },
      '184': {
        start: {
          line: 539,
          column: 6
        },
        end: {
          line: 539,
          column: 175
        }
      },
      '185': {
        start: {
          line: 540,
          column: 6
        },
        end: {
          line: 540,
          column: 27
        }
      },
      '186': {
        start: {
          line: 541,
          column: 6
        },
        end: {
          line: 545,
          column: 7
        }
      },
      '187': {
        start: {
          line: 542,
          column: 8
        },
        end: {
          line: 542,
          column: 38
        }
      },
      '188': {
        start: {
          line: 544,
          column: 8
        },
        end: {
          line: 544,
          column: 31
        }
      },
      '189': {
        start: {
          line: 548,
          column: 4
        },
        end: {
          line: 548,
          column: 54
        }
      },
      '190': {
        start: {
          line: 553,
          column: 2
        },
        end: {
          line: 711,
          column: 5
        }
      },
      '191': {
        start: {
          line: 554,
          column: 4
        },
        end: {
          line: 654,
          column: 6
        }
      },
      '192': {
        start: {
          line: 557,
          column: 8
        },
        end: {
          line: 559,
          column: 9
        }
      },
      '193': {
        start: {
          line: 558,
          column: 10
        },
        end: {
          line: 558,
          column: 61
        }
      },
      '194': {
        start: {
          line: 560,
          column: 8
        },
        end: {
          line: 560,
          column: 58
        }
      },
      '195': {
        start: {
          line: 563,
          column: 8
        },
        end: {
          line: 563,
          column: 57
        }
      },
      '196': {
        start: {
          line: 565,
          column: 22
        },
        end: {
          line: 565,
          column: 31
        }
      },
      '197': {
        start: {
          line: 567,
          column: 8
        },
        end: {
          line: 570,
          column: 11
        }
      },
      '198': {
        start: {
          line: 571,
          column: 8
        },
        end: {
          line: 571,
          column: 123
        }
      },
      '199': {
        start: {
          line: 572,
          column: 8
        },
        end: {
          line: 572,
          column: 125
        }
      },
      '200': {
        start: {
          line: 574,
          column: 8
        },
        end: {
          line: 574,
          column: 77
        }
      },
      '201': {
        start: {
          line: 575,
          column: 8
        },
        end: {
          line: 578,
          column: 9
        }
      },
      '202': {
        start: {
          line: 576,
          column: 10
        },
        end: {
          line: 576,
          column: 58
        }
      },
      '203': {
        start: {
          line: 577,
          column: 10
        },
        end: {
          line: 577,
          column: 17
        }
      },
      '204': {
        start: {
          line: 579,
          column: 8
        },
        end: {
          line: 579,
          column: 59
        }
      },
      '205': {
        start: {
          line: 580,
          column: 22
        },
        end: {
          line: 580,
          column: 45
        }
      },
      '206': {
        start: {
          line: 581,
          column: 8
        },
        end: {
          line: 581,
          column: 56
        }
      },
      '207': {
        start: {
          line: 582,
          column: 8
        },
        end: {
          line: 648,
          column: 11
        }
      },
      '208': {
        start: {
          line: 583,
          column: 25
        },
        end: {
          line: 631,
          column: 11
        }
      },
      '209': {
        start: {
          line: 632,
          column: 30
        },
        end: {
          line: 632,
          column: 99
        }
      },
      '210': {
        start: {
          line: 633,
          column: 28
        },
        end: {
          line: 633,
          column: 97
        }
      },
      '211': {
        start: {
          line: 634,
          column: 10
        },
        end: {
          line: 646,
          column: 11
        }
      },
      '212': {
        start: {
          line: 635,
          column: 12
        },
        end: {
          line: 645,
          column: 13
        }
      },
      '213': {
        start: {
          line: 636,
          column: 34
        },
        end: {
          line: 636,
          column: 81
        }
      },
      '214': {
        start: {
          line: 637,
          column: 32
        },
        end: {
          line: 637,
          column: 77
        }
      },
      '215': {
        start: {
          line: 638,
          column: 14
        },
        end: {
          line: 640,
          column: 15
        }
      },
      '216': {
        start: {
          line: 639,
          column: 16
        },
        end: {
          line: 639,
          column: 25
        }
      },
      '217': {
        start: {
          line: 641,
          column: 14
        },
        end: {
          line: 644,
          column: 17
        }
      },
      '218': {
        start: {
          line: 647,
          column: 10
        },
        end: {
          line: 647,
          column: 60
        }
      },
      '219': {
        start: {
          line: 651,
          column: 8
        },
        end: {
          line: 651,
          column: 56
        }
      },
      '220': {
        start: {
          line: 652,
          column: 8
        },
        end: {
          line: 652,
          column: 55
        }
      },
      '221': {
        start: {
          line: 655,
          column: 4
        },
        end: {
          line: 655,
          column: 78
        }
      },
      '222': {
        start: {
          line: 658,
          column: 4
        },
        end: {
          line: 658,
          column: 18
        }
      },
      '223': {
        start: {
          line: 659,
          column: 18
        },
        end: {
          line: 661,
          column: 6
        }
      },
      '224': {
        start: {
          line: 660,
          column: 6
        },
        end: {
          line: 660,
          column: 134
        }
      },
      '225': {
        start: {
          line: 662,
          column: 4
        },
        end: {
          line: 662,
          column: 49
        }
      },
      '226': {
        start: {
          line: 664,
          column: 20
        },
        end: {
          line: 670,
          column: 5
        }
      },
      '227': {
        start: {
          line: 668,
          column: 8
        },
        end: {
          line: 668,
          column: 57
        }
      },
      '228': {
        start: {
          line: 671,
          column: 4
        },
        end: {
          line: 673,
          column: 5
        }
      },
      '229': {
        start: {
          line: 672,
          column: 6
        },
        end: {
          line: 672,
          column: 138
        }
      },
      '230': {
        start: {
          line: 675,
          column: 4
        },
        end: {
          line: 675,
          column: 67
        }
      },
      '231': {
        start: {
          line: 678,
          column: 24
        },
        end: {
          line: 678,
          column: 26
        }
      },
      '232': {
        start: {
          line: 679,
          column: 4
        },
        end: {
          line: 689,
          column: 5
        }
      },
      '233': {
        start: {
          line: 680,
          column: 6
        },
        end: {
          line: 680,
          column: 49
        }
      },
      '234': {
        start: {
          line: 681,
          column: 6
        },
        end: {
          line: 685,
          column: 7
        }
      },
      '235': {
        start: {
          line: 682,
          column: 8
        },
        end: {
          line: 684,
          column: 9
        }
      },
      '236': {
        start: {
          line: 683,
          column: 10
        },
        end: {
          line: 683,
          column: 70
        }
      },
      '237': {
        start: {
          line: 686,
          column: 19
        },
        end: {
          line: 686,
          column: 35
        }
      },
      '238': {
        start: {
          line: 687,
          column: 6
        },
        end: {
          line: 687,
          column: 25
        }
      },
      '239': {
        start: {
          line: 688,
          column: 6
        },
        end: {
          line: 688,
          column: 29
        }
      },
      '240': {
        start: {
          line: 691,
          column: 4
        },
        end: {
          line: 709,
          column: 7
        }
      },
      '241': {
        start: {
          line: 692,
          column: 6
        },
        end: {
          line: 694,
          column: 7
        }
      },
      '242': {
        start: {
          line: 693,
          column: 8
        },
        end: {
          line: 693,
          column: 17
        }
      },
      '243': {
        start: {
          line: 695,
          column: 6
        },
        end: {
          line: 697,
          column: 7
        }
      },
      '244': {
        start: {
          line: 696,
          column: 8
        },
        end: {
          line: 696,
          column: 18
        }
      },
      '245': {
        start: {
          line: 699,
          column: 6
        },
        end: {
          line: 701,
          column: 7
        }
      },
      '246': {
        start: {
          line: 700,
          column: 8
        },
        end: {
          line: 700,
          column: 17
        }
      },
      '247': {
        start: {
          line: 702,
          column: 6
        },
        end: {
          line: 704,
          column: 7
        }
      },
      '248': {
        start: {
          line: 703,
          column: 8
        },
        end: {
          line: 703,
          column: 18
        }
      },
      '249': {
        start: {
          line: 705,
          column: 6
        },
        end: {
          line: 707,
          column: 7
        }
      },
      '250': {
        start: {
          line: 706,
          column: 8
        },
        end: {
          line: 706,
          column: 18
        }
      },
      '251': {
        start: {
          line: 708,
          column: 6
        },
        end: {
          line: 708,
          column: 15
        }
      },
      '252': {
        start: {
          line: 710,
          column: 4
        },
        end: {
          line: 710,
          column: 60
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 57
          },
          end: {
            line: 1,
            column: 58
          }
        },
        loc: {
          start: {
            line: 1,
            column: 169
          },
          end: {
            line: 712,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 30,
            column: 21
          },
          end: {
            line: 30,
            column: 22
          }
        },
        loc: {
          start: {
            line: 30,
            column: 35
          },
          end: {
            line: 32,
            column: 3
          }
        },
        line: 30
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 33,
            column: 22
          },
          end: {
            line: 33,
            column: 23
          }
        },
        loc: {
          start: {
            line: 33,
            column: 36
          },
          end: {
            line: 47,
            column: 3
          }
        },
        line: 33
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 49,
            column: 68
          },
          end: {
            line: 49,
            column: 69
          }
        },
        loc: {
          start: {
            line: 49,
            column: 76
          },
          end: {
            line: 71,
            column: 3
          }
        },
        line: 49
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 74,
            column: 69
          },
          end: {
            line: 74,
            column: 70
          }
        },
        loc: {
          start: {
            line: 74,
            column: 77
          },
          end: {
            line: 130,
            column: 3
          }
        },
        line: 74
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 75,
            column: 20
          },
          end: {
            line: 75,
            column: 21
          }
        },
        loc: {
          start: {
            line: 75,
            column: 29
          },
          end: {
            line: 80,
            column: 5
          }
        },
        line: 75
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 107,
            column: 45
          },
          end: {
            line: 107,
            column: 46
          }
        },
        loc: {
          start: {
            line: 107,
            column: 54
          },
          end: {
            line: 112,
            column: 11
          }
        },
        line: 107
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 112,
            column: 21
          },
          end: {
            line: 112,
            column: 22
          }
        },
        loc: {
          start: {
            line: 112,
            column: 29
          },
          end: {
            line: 114,
            column: 11
          }
        },
        line: 112
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 133,
            column: 75
          },
          end: {
            line: 133,
            column: 76
          }
        },
        loc: {
          start: {
            line: 133,
            column: 83
          },
          end: {
            line: 197,
            column: 3
          }
        },
        line: 133
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 200,
            column: 104
          },
          end: {
            line: 200,
            column: 105
          }
        },
        loc: {
          start: {
            line: 200,
            column: 112
          },
          end: {
            line: 294,
            column: 3
          }
        },
        line: 200
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 209,
            column: 16
          },
          end: {
            line: 209,
            column: 17
          }
        },
        loc: {
          start: {
            line: 209,
            column: 26
          },
          end: {
            line: 211,
            column: 5
          }
        },
        line: 209
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 214,
            column: 34
          },
          end: {
            line: 214,
            column: 35
          }
        },
        loc: {
          start: {
            line: 214,
            column: 47
          },
          end: {
            line: 216,
            column: 7
          }
        },
        line: 214
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 297,
            column: 105
          },
          end: {
            line: 297,
            column: 106
          }
        },
        loc: {
          start: {
            line: 297,
            column: 113
          },
          end: {
            line: 550,
            column: 3
          }
        },
        line: 297
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 513,
            column: 21
          },
          end: {
            line: 513,
            column: 22
          }
        },
        loc: {
          start: {
            line: 513,
            column: 31
          },
          end: {
            line: 531,
            column: 5
          }
        },
        line: 513
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 541,
            column: 44
          },
          end: {
            line: 541,
            column: 45
          }
        },
        loc: {
          start: {
            line: 541,
            column: 53
          },
          end: {
            line: 543,
            column: 7
          }
        },
        line: 541
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 553,
            column: 112
          },
          end: {
            line: 553,
            column: 113
          }
        },
        loc: {
          start: {
            line: 553,
            column: 120
          },
          end: {
            line: 711,
            column: 3
          }
        },
        line: 553
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 582,
            column: 143
          },
          end: {
            line: 582,
            column: 144
          }
        },
        loc: {
          start: {
            line: 582,
            column: 152
          },
          end: {
            line: 648,
            column: 9
          }
        },
        line: 582
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 659,
            column: 32
          },
          end: {
            line: 659,
            column: 33
          }
        },
        loc: {
          start: {
            line: 659,
            column: 40
          },
          end: {
            line: 661,
            column: 5
          }
        },
        line: 659
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 691,
            column: 21
          },
          end: {
            line: 691,
            column: 22
          }
        },
        loc: {
          start: {
            line: 691,
            column: 31
          },
          end: {
            line: 709,
            column: 5
          }
        },
        line: 691
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 35,
            column: 4
          },
          end: {
            line: 38,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 35,
            column: 4
          },
          end: {
            line: 38,
            column: 5
          }
        }, {
          start: {
            line: 35,
            column: 4
          },
          end: {
            line: 38,
            column: 5
          }
        }],
        line: 35
      },
      '1': {
        loc: {
          start: {
            line: 36,
            column: 14
          },
          end: {
            line: 36,
            column: 32
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 36,
            column: 14
          },
          end: {
            line: 36,
            column: 26
          }
        }, {
          start: {
            line: 36,
            column: 30
          },
          end: {
            line: 36,
            column: 32
          }
        }],
        line: 36
      },
      '2': {
        loc: {
          start: {
            line: 39,
            column: 12
          },
          end: {
            line: 39,
            column: 30
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 39,
            column: 12
          },
          end: {
            line: 39,
            column: 24
          }
        }, {
          start: {
            line: 39,
            column: 28
          },
          end: {
            line: 39,
            column: 30
          }
        }],
        line: 39
      },
      '3': {
        loc: {
          start: {
            line: 40,
            column: 4
          },
          end: {
            line: 42,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 4
          },
          end: {
            line: 42,
            column: 5
          }
        }, {
          start: {
            line: 40,
            column: 4
          },
          end: {
            line: 42,
            column: 5
          }
        }],
        line: 40
      },
      '4': {
        loc: {
          start: {
            line: 41,
            column: 14
          },
          end: {
            line: 41,
            column: 32
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 41,
            column: 14
          },
          end: {
            line: 41,
            column: 26
          }
        }, {
          start: {
            line: 41,
            column: 30
          },
          end: {
            line: 41,
            column: 32
          }
        }],
        line: 41
      },
      '5': {
        loc: {
          start: {
            line: 44,
            column: 20
          },
          end: {
            line: 44,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 44,
            column: 20
          },
          end: {
            line: 44,
            column: 41
          }
        }, {
          start: {
            line: 44,
            column: 45
          },
          end: {
            line: 44,
            column: 47
          }
        }],
        line: 44
      },
      '6': {
        loc: {
          start: {
            line: 46,
            column: 12
          },
          end: {
            line: 46,
            column: 27
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 46,
            column: 12
          },
          end: {
            line: 46,
            column: 21
          }
        }, {
          start: {
            line: 46,
            column: 25
          },
          end: {
            line: 46,
            column: 27
          }
        }],
        line: 46
      },
      '7': {
        loc: {
          start: {
            line: 50,
            column: 4
          },
          end: {
            line: 53,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 50,
            column: 4
          },
          end: {
            line: 53,
            column: 5
          }
        }, {
          start: {
            line: 50,
            column: 4
          },
          end: {
            line: 53,
            column: 5
          }
        }],
        line: 50
      },
      '8': {
        loc: {
          start: {
            line: 55,
            column: 6
          },
          end: {
            line: 57,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 55,
            column: 6
          },
          end: {
            line: 57,
            column: 7
          }
        }, {
          start: {
            line: 55,
            column: 6
          },
          end: {
            line: 57,
            column: 7
          }
        }],
        line: 55
      },
      '9': {
        loc: {
          start: {
            line: 62,
            column: 41
          },
          end: {
            line: 62,
            column: 73
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 62,
            column: 41
          },
          end: {
            line: 62,
            column: 60
          }
        }, {
          start: {
            line: 62,
            column: 64
          },
          end: {
            line: 62,
            column: 73
          }
        }],
        line: 62
      },
      '10': {
        loc: {
          start: {
            line: 109,
            column: 21
          },
          end: {
            line: 109,
            column: 73
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 109,
            column: 39
          },
          end: {
            line: 109,
            column: 66
          }
        }, {
          start: {
            line: 109,
            column: 69
          },
          end: {
            line: 109,
            column: 73
          }
        }],
        line: 109
      },
      '11': {
        loc: {
          start: {
            line: 118,
            column: 8
          },
          end: {
            line: 120,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 118,
            column: 8
          },
          end: {
            line: 120,
            column: 9
          }
        }, {
          start: {
            line: 118,
            column: 8
          },
          end: {
            line: 120,
            column: 9
          }
        }],
        line: 118
      },
      '12': {
        loc: {
          start: {
            line: 210,
            column: 13
          },
          end: {
            line: 210,
            column: 39
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 210,
            column: 33
          },
          end: {
            line: 210,
            column: 34
          }
        }, {
          start: {
            line: 210,
            column: 37
          },
          end: {
            line: 210,
            column: 39
          }
        }],
        line: 210
      },
      '13': {
        loc: {
          start: {
            line: 215,
            column: 15
          },
          end: {
            line: 215,
            column: 80
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 215,
            column: 15
          },
          end: {
            line: 215,
            column: 38
          }
        }, {
          start: {
            line: 215,
            column: 43
          },
          end: {
            line: 215,
            column: 59
          }
        }, {
          start: {
            line: 215,
            column: 63
          },
          end: {
            line: 215,
            column: 79
          }
        }],
        line: 215
      },
      '14': {
        loc: {
          start: {
            line: 217,
            column: 6
          },
          end: {
            line: 223,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 217,
            column: 6
          },
          end: {
            line: 223,
            column: 7
          }
        }, {
          start: {
            line: 217,
            column: 6
          },
          end: {
            line: 223,
            column: 7
          }
        }],
        line: 217
      },
      '15': {
        loc: {
          start: {
            line: 243,
            column: 15
          },
          end: {
            line: 243,
            column: 56
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 243,
            column: 26
          },
          end: {
            line: 243,
            column: 51
          }
        }, {
          start: {
            line: 243,
            column: 54
          },
          end: {
            line: 243,
            column: 56
          }
        }],
        line: 243
      },
      '16': {
        loc: {
          start: {
            line: 244,
            column: 15
          },
          end: {
            line: 244,
            column: 60
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 244,
            column: 28
          },
          end: {
            line: 244,
            column: 55
          }
        }, {
          start: {
            line: 244,
            column: 58
          },
          end: {
            line: 244,
            column: 60
          }
        }],
        line: 244
      },
      '17': {
        loc: {
          start: {
            line: 245,
            column: 18
          },
          end: {
            line: 245,
            column: 55
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 245,
            column: 27
          },
          end: {
            line: 245,
            column: 50
          }
        }, {
          start: {
            line: 245,
            column: 53
          },
          end: {
            line: 245,
            column: 55
          }
        }],
        line: 245
      },
      '18': {
        loc: {
          start: {
            line: 260,
            column: 8
          },
          end: {
            line: 263,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 260,
            column: 8
          },
          end: {
            line: 263,
            column: 9
          }
        }, {
          start: {
            line: 260,
            column: 8
          },
          end: {
            line: 263,
            column: 9
          }
        }],
        line: 260
      },
      '19': {
        loc: {
          start: {
            line: 265,
            column: 8
          },
          end: {
            line: 267,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 265,
            column: 8
          },
          end: {
            line: 267,
            column: 9
          }
        }, {
          start: {
            line: 265,
            column: 8
          },
          end: {
            line: 267,
            column: 9
          }
        }],
        line: 265
      },
      '20': {
        loc: {
          start: {
            line: 265,
            column: 12
          },
          end: {
            line: 265,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 265,
            column: 12
          },
          end: {
            line: 265,
            column: 38
          }
        }, {
          start: {
            line: 265,
            column: 42
          },
          end: {
            line: 265,
            column: 49
          }
        }],
        line: 265
      },
      '21': {
        loc: {
          start: {
            line: 269,
            column: 8
          },
          end: {
            line: 271,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 269,
            column: 8
          },
          end: {
            line: 271,
            column: 9
          }
        }, {
          start: {
            line: 269,
            column: 8
          },
          end: {
            line: 271,
            column: 9
          }
        }],
        line: 269
      },
      '22': {
        loc: {
          start: {
            line: 269,
            column: 12
          },
          end: {
            line: 269,
            column: 55
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 269,
            column: 12
          },
          end: {
            line: 269,
            column: 39
          }
        }, {
          start: {
            line: 269,
            column: 43
          },
          end: {
            line: 269,
            column: 55
          }
        }],
        line: 269
      },
      '23': {
        loc: {
          start: {
            line: 273,
            column: 8
          },
          end: {
            line: 275,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 273,
            column: 8
          },
          end: {
            line: 275,
            column: 9
          }
        }, {
          start: {
            line: 273,
            column: 8
          },
          end: {
            line: 275,
            column: 9
          }
        }],
        line: 273
      },
      '24': {
        loc: {
          start: {
            line: 273,
            column: 12
          },
          end: {
            line: 273,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 273,
            column: 12
          },
          end: {
            line: 273,
            column: 38
          }
        }, {
          start: {
            line: 273,
            column: 42
          },
          end: {
            line: 273,
            column: 49
          }
        }],
        line: 273
      },
      '25': {
        loc: {
          start: {
            line: 301,
            column: 8
          },
          end: {
            line: 303,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 301,
            column: 8
          },
          end: {
            line: 303,
            column: 9
          }
        }, {
          start: {
            line: 301,
            column: 8
          },
          end: {
            line: 303,
            column: 9
          }
        }],
        line: 301
      },
      '26': {
        loc: {
          start: {
            line: 301,
            column: 12
          },
          end: {
            line: 301,
            column: 51
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 301,
            column: 12
          },
          end: {
            line: 301,
            column: 21
          }
        }, {
          start: {
            line: 301,
            column: 25
          },
          end: {
            line: 301,
            column: 51
          }
        }],
        line: 301
      },
      '27': {
        loc: {
          start: {
            line: 306,
            column: 8
          },
          end: {
            line: 308,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 306,
            column: 8
          },
          end: {
            line: 308,
            column: 9
          }
        }, {
          start: {
            line: 306,
            column: 8
          },
          end: {
            line: 308,
            column: 9
          }
        }],
        line: 306
      },
      '28': {
        loc: {
          start: {
            line: 312,
            column: 8
          },
          end: {
            line: 314,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 312,
            column: 8
          },
          end: {
            line: 314,
            column: 9
          }
        }, {
          start: {
            line: 312,
            column: 8
          },
          end: {
            line: 314,
            column: 9
          }
        }],
        line: 312
      },
      '29': {
        loc: {
          start: {
            line: 327,
            column: 8
          },
          end: {
            line: 330,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 327,
            column: 8
          },
          end: {
            line: 330,
            column: 9
          }
        }, {
          start: {
            line: 327,
            column: 8
          },
          end: {
            line: 330,
            column: 9
          }
        }],
        line: 327
      },
      '30': {
        loc: {
          start: {
            line: 478,
            column: 34
          },
          end: {
            line: 478,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 478,
            column: 34
          },
          end: {
            line: 478,
            column: 40
          }
        }, {
          start: {
            line: 478,
            column: 44
          },
          end: {
            line: 478,
            column: 49
          }
        }],
        line: 478
      },
      '31': {
        loc: {
          start: {
            line: 479,
            column: 6
          },
          end: {
            line: 486,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 479,
            column: 6
          },
          end: {
            line: 486,
            column: 7
          }
        }, {
          start: {
            line: 479,
            column: 6
          },
          end: {
            line: 486,
            column: 7
          }
        }],
        line: 479
      },
      '32': {
        loc: {
          start: {
            line: 479,
            column: 10
          },
          end: {
            line: 479,
            column: 32
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 479,
            column: 10
          },
          end: {
            line: 479,
            column: 19
          }
        }, {
          start: {
            line: 479,
            column: 23
          },
          end: {
            line: 479,
            column: 32
          }
        }],
        line: 479
      },
      '33': {
        loc: {
          start: {
            line: 491,
            column: 17
          },
          end: {
            line: 491,
            column: 37
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 491,
            column: 27
          },
          end: {
            line: 491,
            column: 29
          }
        }, {
          start: {
            line: 491,
            column: 32
          },
          end: {
            line: 491,
            column: 37
          }
        }],
        line: 491
      },
      '34': {
        loc: {
          start: {
            line: 493,
            column: 17
          },
          end: {
            line: 493,
            column: 62
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 493,
            column: 42
          },
          end: {
            line: 493,
            column: 48
          }
        }, {
          start: {
            line: 493,
            column: 51
          },
          end: {
            line: 493,
            column: 62
          }
        }],
        line: 493
      },
      '35': {
        loc: {
          start: {
            line: 495,
            column: 10
          },
          end: {
            line: 495,
            column: 111
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 495,
            column: 21
          },
          end: {
            line: 495,
            column: 102
          }
        }, {
          start: {
            line: 495,
            column: 106
          },
          end: {
            line: 495,
            column: 111
          }
        }],
        line: 495
      },
      '36': {
        loc: {
          start: {
            line: 495,
            column: 40
          },
          end: {
            line: 495,
            column: 101
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 495,
            column: 54
          },
          end: {
            line: 495,
            column: 75
          }
        }, {
          start: {
            line: 495,
            column: 78
          },
          end: {
            line: 495,
            column: 101
          }
        }],
        line: 495
      },
      '37': {
        loc: {
          start: {
            line: 499,
            column: 17
          },
          end: {
            line: 499,
            column: 39
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 499,
            column: 27
          },
          end: {
            line: 499,
            column: 31
          }
        }, {
          start: {
            line: 499,
            column: 34
          },
          end: {
            line: 499,
            column: 39
          }
        }],
        line: 499
      },
      '38': {
        loc: {
          start: {
            line: 514,
            column: 6
          },
          end: {
            line: 516,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 514,
            column: 6
          },
          end: {
            line: 516,
            column: 7
          }
        }, {
          start: {
            line: 514,
            column: 6
          },
          end: {
            line: 516,
            column: 7
          }
        }],
        line: 514
      },
      '39': {
        loc: {
          start: {
            line: 517,
            column: 6
          },
          end: {
            line: 519,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 517,
            column: 6
          },
          end: {
            line: 519,
            column: 7
          }
        }, {
          start: {
            line: 517,
            column: 6
          },
          end: {
            line: 519,
            column: 7
          }
        }],
        line: 517
      },
      '40': {
        loc: {
          start: {
            line: 521,
            column: 6
          },
          end: {
            line: 523,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 521,
            column: 6
          },
          end: {
            line: 523,
            column: 7
          }
        }, {
          start: {
            line: 521,
            column: 6
          },
          end: {
            line: 523,
            column: 7
          }
        }],
        line: 521
      },
      '41': {
        loc: {
          start: {
            line: 524,
            column: 6
          },
          end: {
            line: 526,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 524,
            column: 6
          },
          end: {
            line: 526,
            column: 7
          }
        }, {
          start: {
            line: 524,
            column: 6
          },
          end: {
            line: 526,
            column: 7
          }
        }],
        line: 524
      },
      '42': {
        loc: {
          start: {
            line: 527,
            column: 6
          },
          end: {
            line: 529,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 527,
            column: 6
          },
          end: {
            line: 529,
            column: 7
          }
        }, {
          start: {
            line: 527,
            column: 6
          },
          end: {
            line: 529,
            column: 7
          }
        }],
        line: 527
      },
      '43': {
        loc: {
          start: {
            line: 527,
            column: 10
          },
          end: {
            line: 527,
            column: 40
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 527,
            column: 10
          },
          end: {
            line: 527,
            column: 23
          }
        }, {
          start: {
            line: 527,
            column: 27
          },
          end: {
            line: 527,
            column: 40
          }
        }],
        line: 527
      },
      '44': {
        loc: {
          start: {
            line: 557,
            column: 8
          },
          end: {
            line: 559,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 557,
            column: 8
          },
          end: {
            line: 559,
            column: 9
          }
        }, {
          start: {
            line: 557,
            column: 8
          },
          end: {
            line: 559,
            column: 9
          }
        }],
        line: 557
      },
      '45': {
        loc: {
          start: {
            line: 575,
            column: 8
          },
          end: {
            line: 578,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 575,
            column: 8
          },
          end: {
            line: 578,
            column: 9
          }
        }, {
          start: {
            line: 575,
            column: 8
          },
          end: {
            line: 578,
            column: 9
          }
        }],
        line: 575
      },
      '46': {
        loc: {
          start: {
            line: 638,
            column: 14
          },
          end: {
            line: 640,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 638,
            column: 14
          },
          end: {
            line: 640,
            column: 15
          }
        }, {
          start: {
            line: 638,
            column: 14
          },
          end: {
            line: 640,
            column: 15
          }
        }],
        line: 638
      },
      '47': {
        loc: {
          start: {
            line: 638,
            column: 18
          },
          end: {
            line: 638,
            column: 68
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 638,
            column: 18
          },
          end: {
            line: 638,
            column: 41
          }
        }, {
          start: {
            line: 638,
            column: 45
          },
          end: {
            line: 638,
            column: 68
          }
        }],
        line: 638
      },
      '48': {
        loc: {
          start: {
            line: 660,
            column: 13
          },
          end: {
            line: 660,
            column: 133
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 660,
            column: 13
          },
          end: {
            line: 660,
            column: 71
          }
        }, {
          start: {
            line: 660,
            column: 75
          },
          end: {
            line: 660,
            column: 133
          }
        }],
        line: 660
      },
      '49': {
        loc: {
          start: {
            line: 680,
            column: 19
          },
          end: {
            line: 680,
            column: 34
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 680,
            column: 19
          },
          end: {
            line: 680,
            column: 25
          }
        }, {
          start: {
            line: 680,
            column: 29
          },
          end: {
            line: 680,
            column: 34
          }
        }],
        line: 680
      },
      '50': {
        loc: {
          start: {
            line: 681,
            column: 6
          },
          end: {
            line: 685,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 681,
            column: 6
          },
          end: {
            line: 685,
            column: 7
          }
        }, {
          start: {
            line: 681,
            column: 6
          },
          end: {
            line: 685,
            column: 7
          }
        }],
        line: 681
      },
      '51': {
        loc: {
          start: {
            line: 681,
            column: 10
          },
          end: {
            line: 681,
            column: 32
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 681,
            column: 10
          },
          end: {
            line: 681,
            column: 19
          }
        }, {
          start: {
            line: 681,
            column: 23
          },
          end: {
            line: 681,
            column: 32
          }
        }],
        line: 681
      },
      '52': {
        loc: {
          start: {
            line: 692,
            column: 6
          },
          end: {
            line: 694,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 692,
            column: 6
          },
          end: {
            line: 694,
            column: 7
          }
        }, {
          start: {
            line: 692,
            column: 6
          },
          end: {
            line: 694,
            column: 7
          }
        }],
        line: 692
      },
      '53': {
        loc: {
          start: {
            line: 695,
            column: 6
          },
          end: {
            line: 697,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 695,
            column: 6
          },
          end: {
            line: 697,
            column: 7
          }
        }, {
          start: {
            line: 695,
            column: 6
          },
          end: {
            line: 697,
            column: 7
          }
        }],
        line: 695
      },
      '54': {
        loc: {
          start: {
            line: 699,
            column: 6
          },
          end: {
            line: 701,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 699,
            column: 6
          },
          end: {
            line: 701,
            column: 7
          }
        }, {
          start: {
            line: 699,
            column: 6
          },
          end: {
            line: 701,
            column: 7
          }
        }],
        line: 699
      },
      '55': {
        loc: {
          start: {
            line: 702,
            column: 6
          },
          end: {
            line: 704,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 702,
            column: 6
          },
          end: {
            line: 704,
            column: 7
          }
        }, {
          start: {
            line: 702,
            column: 6
          },
          end: {
            line: 704,
            column: 7
          }
        }],
        line: 702
      },
      '56': {
        loc: {
          start: {
            line: 705,
            column: 6
          },
          end: {
            line: 707,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 705,
            column: 6
          },
          end: {
            line: 707,
            column: 7
          }
        }, {
          start: {
            line: 705,
            column: 6
          },
          end: {
            line: 707,
            column: 7
          }
        }],
        line: 705
      },
      '57': {
        loc: {
          start: {
            line: 705,
            column: 10
          },
          end: {
            line: 705,
            column: 40
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 705,
            column: 10
          },
          end: {
            line: 705,
            column: 23
          }
        }, {
          start: {
            line: 705,
            column: 27
          },
          end: {
            line: 705,
            column: 40
          }
        }],
        line: 705
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0,
      '248': 0,
      '249': 0,
      '250': 0,
      '251': 0,
      '252': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_bdrjt0qwr.s[0]++;
angular.module('app').controller('ProjectDashboardCtrl', function ($scope, $CRUDService, $deltaNumber, $deltadate, $rootScope, $translate, $filter, $LOGINService, $deltahttp) {
  cov_bdrjt0qwr.f[0]++;

  var PATH = (cov_bdrjt0qwr.s[1]++, 'Milestone');
  var PATH_PROJECT = (cov_bdrjt0qwr.s[2]++, 'Projet');
  var PATH_FINANCING = (cov_bdrjt0qwr.s[3]++, 'Financement');
  var PATH_INDICATORS = (cov_bdrjt0qwr.s[4]++, 'Indicateurs');
  var PATH_DOCUMENT = (cov_bdrjt0qwr.s[5]++, 'Documents');
  var PATH_PHOTOTHEQUE = (cov_bdrjt0qwr.s[6]++, 'Phototheque');
  var PATH_INDICATORS_GLOBAL = (cov_bdrjt0qwr.s[7]++, 'IndicateursGlobal');
  var PATH_RISK = (cov_bdrjt0qwr.s[8]++, 'Risk');
  // Get single Project;

  var idProject = (cov_bdrjt0qwr.s[9]++, $rootScope.projectDetail());

  cov_bdrjt0qwr.s[10]++;
  $scope.entete = {
    page: 1
  };

  cov_bdrjt0qwr.s[11]++;
  $scope.listImpact = [{ id: 1, name: $translate.instant('RISK.IMPACTS.HIGH') }, { id: 2, name: $translate.instant('RISK.IMPACTS.MEDIUM') }, { id: 3, name: $translate.instant('RISK.IMPACTS.LOW') }];

  cov_bdrjt0qwr.s[12]++;
  $scope.listStatus = [{ id: 1, name: $translate.instant('RISK.STATUS_LIST.UNRESOLVED') }, { id: 2, name: $translate.instant('RISK.STATUS_LIST.IN_PROGRESS') }, { id: 3, name: $translate.instant('RISK.STATUS_LIST.RESOLVED') }];

  cov_bdrjt0qwr.s[13]++;
  $scope.isYoutube = function (el) {
    cov_bdrjt0qwr.f[1]++;
    cov_bdrjt0qwr.s[14]++;

    return true;
  };
  cov_bdrjt0qwr.s[15]++;
  $scope.getVideoId = function (el) {
    cov_bdrjt0qwr.f[2]++;

    var list = (cov_bdrjt0qwr.s[16]++, []);
    cov_bdrjt0qwr.s[17]++;
    if (!this.isYoutube(el)) {
      cov_bdrjt0qwr.b[0][0]++;
      cov_bdrjt0qwr.s[18]++;

      list = ((cov_bdrjt0qwr.b[1][0]++, el.URL_VIDEO) || (cov_bdrjt0qwr.b[1][1]++, '')).split('/');
      cov_bdrjt0qwr.s[19]++;
      return list[list.length - 1];
    } else {
      cov_bdrjt0qwr.b[0][1]++;
    }
    cov_bdrjt0qwr.s[20]++;
    list = ((cov_bdrjt0qwr.b[2][0]++, el.URL_VIDEO) || (cov_bdrjt0qwr.b[2][1]++, '')).split('?');
    cov_bdrjt0qwr.s[21]++;
    if (list.length == 1) {
      cov_bdrjt0qwr.b[3][0]++;
      cov_bdrjt0qwr.s[22]++;

      list = ((cov_bdrjt0qwr.b[4][0]++, el.URL_VIDEO) || (cov_bdrjt0qwr.b[4][1]++, '')).split('/');
    } else {
      cov_bdrjt0qwr.b[3][1]++;
    }

    var params = (cov_bdrjt0qwr.s[23]++, ((cov_bdrjt0qwr.b[5][0]++, list[list.length - 1]) || (cov_bdrjt0qwr.b[5][1]++, '')).split('&'));

    cov_bdrjt0qwr.s[24]++;
    return ((cov_bdrjt0qwr.b[6][0]++, params[0]) || (cov_bdrjt0qwr.b[6][1]++, '')).replace('v=', '');
  };

  cov_bdrjt0qwr.s[25]++;
  $CRUDService.getAll(PATH_PROJECT, { get: 'single', id: idProject }, function (data) {
    cov_bdrjt0qwr.f[3]++;
    cov_bdrjt0qwr.s[26]++;

    if (data.length <= 0) {
      cov_bdrjt0qwr.b[7][0]++;
      cov_bdrjt0qwr.s[27]++;

      undefined.isloading = false;
      cov_bdrjt0qwr.s[28]++;
      return;
    } else {
      cov_bdrjt0qwr.b[7][1]++;
    }
    cov_bdrjt0qwr.s[29]++;
    for (var i = 0; i < data.length; i++) {
      cov_bdrjt0qwr.s[30]++;

      if (!data[i].DATE_DEB_PROJET) {
        cov_bdrjt0qwr.b[8][0]++;
        cov_bdrjt0qwr.s[31]++;

        continue;
      } else {
        cov_bdrjt0qwr.b[8][1]++;
      }
      cov_bdrjt0qwr.s[32]++;
      data[i].DATE_DEB_PROJET = Date.newDate(data[i].DATE_DEB_PROJET);
      cov_bdrjt0qwr.s[33]++;
      data[i].COUT = parseFloat(data[i].COUT);
      cov_bdrjt0qwr.s[34]++;
      data[i].STATUT_BUDGET = parseInt(data[i].STATUT_BUDGET, 10) === 1;
      cov_bdrjt0qwr.s[35]++;
      data[i].DATE_FIN_PROJET = Date.newDate(data[i].DATE_FIN_PROJET);
      cov_bdrjt0qwr.s[36]++;
      data[i].DATE_MI_PRO = Date.newDate((cov_bdrjt0qwr.b[9][0]++, data[i].DATE_MI_PRO) || (cov_bdrjt0qwr.b[9][1]++, undefined));
      cov_bdrjt0qwr.s[37]++;
      data[i].PAYS_PROJET = parseInt(data[i].PAYS_PROJET, 10);
      var zone = (cov_bdrjt0qwr.s[38]++, []);
      cov_bdrjt0qwr.s[39]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.keys(data[i].LOCATION_FIELD)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var key = _step.value;
          cov_bdrjt0qwr.s[40]++;

          zone.push(data[i].LOCATION_FIELD[key]);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_bdrjt0qwr.s[41]++;
      data[i].LOCATION_FIELD = zone.join(' , ');
      cov_bdrjt0qwr.s[42]++;
      $scope.selectProject = data[i];
    }
  });

  // Financement
  cov_bdrjt0qwr.s[43]++;
  $CRUDService.getAll(PATH_FINANCING, { get: 'state', id: idProject }, function (data) {
    cov_bdrjt0qwr.f[4]++;
    cov_bdrjt0qwr.s[44]++;

    data = data.map(function (value) {
      cov_bdrjt0qwr.f[5]++;
      cov_bdrjt0qwr.s[45]++;

      value.AMOUNT_AFF = $filter('number')(value.AMOUNT);
      cov_bdrjt0qwr.s[46]++;
      value.DISBURSED_AFF = $filter('number')(value.DISBURSED);
      cov_bdrjt0qwr.s[47]++;
      value.SOLD_AFF = $filter('number')(value.SOLD);
      cov_bdrjt0qwr.s[48]++;
      return value;
    });

    cov_bdrjt0qwr.s[49]++;
    $scope.financingState = angular.copy(data);
    cov_bdrjt0qwr.s[50]++;
    $scope.financialParams = {
      dataSource: data,
      gridLines: 'Both',
      columns: [{ field: 'LIBELLE_FIN', headerText: $translate.instant('PROJECT.DASHBOARD_VIEW.FINANCING').toUpperCase() }, { field: 'AMOUNT_AFF', headerText: $translate.instant('PROJECT.DASHBOARD_VIEW.AMOUNT').toUpperCase() + ' (' + $rootScope.getGlobalCurrency() + ')', width: 200, textAlign: 'Right' }, { field: 'DISBURSED_AFF', headerText: $translate.instant('PROJECT.DASHBOARD_VIEW.DISBURSED').toUpperCase() + ' (' + $rootScope.getGlobalCurrency() + ')', width: 200, textAlign: 'Right' }, { field: 'SOLD_AFF', headerText: $translate.instant('PROJECT.DASHBOARD_VIEW.SOLD').toUpperCase() + ' (' + $rootScope.getGlobalCurrency() + ')', width: 200, textAlign: 'Right' }],
      allowSelection: false,
      width: 'auto',
      height: 'auto',
      allowTextWrap: true
    };

    // const grid = new ej.grids.Grid(params);
    // grid.appendTo('#financial_data');
    /*
    Chart by funding Source
     */
    cov_bdrjt0qwr.s[51]++;
    $scope.financingStateChart = {
      // Initializing Series
      series: [{
        type: 'Pie', dataSource: data.map(function (value) {
          cov_bdrjt0qwr.f[6]++;
          cov_bdrjt0qwr.s[52]++;

          return {
            value: value.DISBURSED ? (cov_bdrjt0qwr.b[10][0]++, Math.round(value.DISBURSED)) : (cov_bdrjt0qwr.b[10][1]++, null),
            name: value.LIBELLE_FIN
          };
        }).filter(function (item) {
          cov_bdrjt0qwr.f[7]++;
          cov_bdrjt0qwr.s[53]++;

          return item.value != null;
        }), xName: 'name', yName: 'value',
        dataLabel: { visible: true, position: 'Outside' // , innerRadius: '30%'
        } }], textRender: function textRender(args) {
        cov_bdrjt0qwr.s[54]++;

        if (!args.extraData) {
          cov_bdrjt0qwr.b[11][0]++;
          cov_bdrjt0qwr.s[55]++;

          args.extraData = $filter('number')(args.text);
        } else {
          cov_bdrjt0qwr.b[11][1]++;
        }
        cov_bdrjt0qwr.s[56]++;
        args.text = args.extraData + ' ' + $rootScope.getGlobalCurrency();
      },

      legendSettings: { visible: true, position: 'Bottom' }, enableSmartLabels: true,
      title: $translate.instant('PROJECT.DASHBOARD_VIEW.TOTAL_DISBURSED_BY_FUNDING').toUpperCase(),
      // Initializing Tooltip
      tooltip: { enable: true, format: '${point.x} <br> ${point.percentage} %' },
      width: '100%',
      height: '100%'
    };
  });

  // Financement Chart
  cov_bdrjt0qwr.s[57]++;
  $CRUDService.getAll(PATH_FINANCING, { get: 'state_chart', id: idProject }, function (data) {
    cov_bdrjt0qwr.f[8]++;
    cov_bdrjt0qwr.s[58]++;

    $scope.financingState = data;
    /*
    Chart by funding Source
    DISBURSED_RATE: 'Disbursement Rate',
            TOTAL_DISBURSED_BY_FUNDING: 'Total disbursed by funding source'
     */
    cov_bdrjt0qwr.s[59]++;
    $scope.financingStateChartProgress = {
      locale: $LOGINService.getLang(),
      // Initializing Primary X and Y Axis
      primaryXAxis: {
        // title: 'Years',
        interval: 1,
        labelIntersectAction: 'Rotate45',
        valueType: 'Category',
        majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
        majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
        lineStyle: { width: 0 }
      },
      primaryYAxis: {
        valueType: 'Double',
        lineStyle: { width: 0 },
        majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
        minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
        labelFormat: 'P'
      },
      chartArea: {
        border: {
          width: 0
        }
      },
      useGroupingSeparator: true,
      // Initializing Chart Series
      series: [{ // FILL
        type: 'Line',
        opacity: 0.5,
        dataSource: data,
        // dataLabel: {visible: true, position: 'Outside'},
        fill: 'rgba(0, 15, 62, 0.7)',
        xName: 'YEAR', yName: 'RATE', // name: serie.TITLE,
        width: 3,
        marker: {
          visible: true,
          width: 10,
          height: 10
        }
      }],
      height: 'calc(100% - 20px)',
      width: '100%',
      // Initializing Chart title
      title: $translate.instant('PROJECT.DASHBOARD_VIEW.DISBURSED_RATE').toUpperCase(),

      // crosshair: {enable: true, lineType: 'Vertical'},
      // Enable Legend
      legendSettings: {
        visible: false
      },
      // selectionMode: 'Series',
      /* pointClick(args) {
        $scope.process.deroule(args.pointIndex);
      }, */
      tooltip: { enable: true, shared: false }
    };
  });

  // Log Frame
  cov_bdrjt0qwr.s[60]++;
  $CRUDService.getAll(PATH_INDICATORS, { get: 'frameres_standard', p_bksb_: idProject, idl: 0, idcl: 0 }, function (data) {
    cov_bdrjt0qwr.f[9]++;

    var labels = (cov_bdrjt0qwr.s[61]++, []);
    cov_bdrjt0qwr.s[62]++;
    for (var key in data.cl) {
      cov_bdrjt0qwr.s[63]++;

      data.cl[key].id = key;
      cov_bdrjt0qwr.s[64]++;
      labels.push(data.cl[key]);
    }

    var finalData = (cov_bdrjt0qwr.s[65]++, []);

    cov_bdrjt0qwr.s[66]++;
    labels.sort(function (a, b) {
      cov_bdrjt0qwr.f[10]++;
      cov_bdrjt0qwr.s[67]++;

      return a.CODE > b.CODE ? (cov_bdrjt0qwr.b[12][0]++, 1) : (cov_bdrjt0qwr.b[12][1]++, -1);
    });

    cov_bdrjt0qwr.s[68]++;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      var _loop = function _loop() {
        var cl = _step2.value;

        var items = (cov_bdrjt0qwr.s[69]++, data.d.filter(function (indicator) {
          cov_bdrjt0qwr.f[11]++;
          cov_bdrjt0qwr.s[70]++;

          return (cov_bdrjt0qwr.b[13][0]++, indicator.idcl == cl.id) && ((cov_bdrjt0qwr.b[13][1]++, indicator.t == 1) || (cov_bdrjt0qwr.b[13][2]++, indicator.t == 2));
        }));
        cov_bdrjt0qwr.s[71]++;
        if (items.length > 0) {
          cov_bdrjt0qwr.b[14][0]++;
          cov_bdrjt0qwr.s[72]++;

          finalData.push({ li: ' <h4 class="font-bold"  >\n                      <span>' + cl.NIV_LABEL + '</span> : <span class="label label-primary  m-r-xs" style=" background-color: rgba(' + cl.COLOR + ',0.9);">\n                          ' + cl.CODE_AFF + '\n                      </span> ' + cl.LABEL + '\n                    </h3>', header: true });
        } else {
          cov_bdrjt0qwr.b[14][1]++;
        }
        cov_bdrjt0qwr.s[73]++;
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = items[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var el = _step3.value;
            cov_bdrjt0qwr.s[74]++;

            el.vra = $deltaNumber.formatNumberToString(el.vr, el.fo, null);
            cov_bdrjt0qwr.s[75]++;
            el.vca = $deltaNumber.formatNumberToString(el.vc, el.fo, null);
            cov_bdrjt0qwr.s[76]++;
            el.actual = $deltaNumber.formatNumberToString(el.actual, el.fo, null);

            cov_bdrjt0qwr.s[77]++;
            finalData.push({
              li: '<span class="font-bold" >\n                        <span class="label label-primary  m-r-xs text-left" style="background-color: rgba(' + el.co + ',0.9); color: rgba(' + el.co + ',0.9); padding:0; width: 10px; display: inline-block;">\n                          A\n                        </span> ' + el.in + '\n                    </span>',
              cu: el.cu,
              sl: '<span style="font-size: 10px" class="font-bold">' + $translate.instant('FRAME_RESULT.VALUE') + '</span>',
              vra: el.vra,
              vca: el.vca,
              actual: el.actual
            });
            cov_bdrjt0qwr.s[78]++;
            finalData.push({
              sl: '<span style="font-size: 10px" class="font-bold">' + $translate.instant('FRAME_RESULT.DATE') + '</span>',
              vra: el.begin ? (cov_bdrjt0qwr.b[15][0]++, $filter('date')(el.begin)) : (cov_bdrjt0qwr.b[15][1]++, ''),
              vca: el.actualD ? (cov_bdrjt0qwr.b[16][0]++, $filter('date')(el.actualD)) : (cov_bdrjt0qwr.b[16][1]++, ''),
              actual: el.end ? (cov_bdrjt0qwr.b[17][0]++, $filter('date')(el.end)) : (cov_bdrjt0qwr.b[17][1]++, '')
            });
            cov_bdrjt0qwr.s[79]++;
            finalData.push({
              sl: '<span style="font-size: 10px" class="font-bold">' + $translate.instant('FRAME_RESULT.COMMENT') + '</span>',
              comment: true,
              vra: el.comment
            });
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      };

      for (var _iterator2 = labels[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        _loop();
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    cov_bdrjt0qwr.s[80]++;
    $scope.params = {

      dataSource: finalData,
      queryCellInfo: function queryCellInfo(args) {
        var data = (cov_bdrjt0qwr.s[81]++, args.data);
        cov_bdrjt0qwr.s[82]++;
        if (data.header) {
          cov_bdrjt0qwr.b[18][0]++;
          cov_bdrjt0qwr.s[83]++;

          args.colSpan = 6;
          cov_bdrjt0qwr.s[84]++;
          return;
        } else {
          cov_bdrjt0qwr.b[18][1]++;
        }

        cov_bdrjt0qwr.s[85]++;
        if ((cov_bdrjt0qwr.b[20][0]++, args.column.field === 'li') && (cov_bdrjt0qwr.b[20][1]++, data.li)) {
          cov_bdrjt0qwr.b[19][0]++;
          cov_bdrjt0qwr.s[86]++;

          args.rowSpan = 3;
        } else {
          cov_bdrjt0qwr.b[19][1]++;
        }

        cov_bdrjt0qwr.s[87]++;
        if ((cov_bdrjt0qwr.b[22][0]++, args.column.field === 'vra') && (cov_bdrjt0qwr.b[22][1]++, data.comment)) {
          cov_bdrjt0qwr.b[21][0]++;
          cov_bdrjt0qwr.s[88]++;

          args.colSpan = 3;
        } else {
          cov_bdrjt0qwr.b[21][1]++;
        }

        cov_bdrjt0qwr.s[89]++;
        if ((cov_bdrjt0qwr.b[24][0]++, args.column.field === 'cu') && (cov_bdrjt0qwr.b[24][1]++, data.cu)) {
          cov_bdrjt0qwr.b[23][0]++;
          cov_bdrjt0qwr.s[90]++;

          args.rowSpan = 3;
        } else {
          cov_bdrjt0qwr.b[23][1]++;
        }
      },

      gridLines: 'Both',
      allowSelection: false,
      columns: [{ field: 'li', headerText: $translate.instant('FRAME_RESULT.INDIC').toUpperCase(), disableHtmlEncode: false }, { field: 'cu', headerText: $translate.instant('FRAME_RESULT.UNIT').toUpperCase(), width: 120, autoFit: true }, { field: 'sl', headerText: '', disableHtmlEncode: false, autoFit: true }, { field: 'vra', headerText: $translate.instant('INDICATOR.REF').toUpperCase(), width: 120 }, { field: 'actual', headerText: $translate.instant('FRAME_RESULT.ACTUAL').toUpperCase(), width: 120, textAlign: 'Center' }, { field: 'vca', headerText: $translate.instant('FRAME_RESULT.TARGET').toUpperCase(), width: 120 }],
      width: 'auto',
      height: $('body').height() - 330 + 'px',
      allowTextWrap: true
    };

    // const grid = new ej.grids.Grid(params);
    //  grid.appendTo('#table_item');
  });

  // ResultFramework 2
  cov_bdrjt0qwr.s[91]++;
  $CRUDService.getAll(PATH_INDICATORS, { get: 'frameres', valid: 0, idl: 0, idcl: 0, p_bksb_: idProject }, function (data) {
    cov_bdrjt0qwr.f[12]++;
    cov_bdrjt0qwr.s[92]++;

    $scope.frameresultParams = {
      queryCellInfo: function queryCellInfo(args) {
        var data = (cov_bdrjt0qwr.s[93]++, args.data);
        cov_bdrjt0qwr.s[94]++;
        if ((cov_bdrjt0qwr.b[26][0]++, data.cols) && (cov_bdrjt0qwr.b[26][1]++, args.column.field == 'li_')) {
          cov_bdrjt0qwr.b[25][0]++;
          cov_bdrjt0qwr.s[95]++;

          args.colSpan = data.cols;
        } else {
          cov_bdrjt0qwr.b[25][1]++;
        }
      },
      recordClick: function recordClick(item) {
        cov_bdrjt0qwr.s[96]++;

        if ($scope.frameresultParams.fromButton) {
          cov_bdrjt0qwr.b[27][0]++;
          cov_bdrjt0qwr.s[97]++;

          $scope.frameresultParams.openChart(item, {});
        } else {
          cov_bdrjt0qwr.b[27][1]++;
        }
        cov_bdrjt0qwr.s[98]++;
        $scope.frameresultParams.fromButton = false;
      },
      openChart: function openChart(item, event) {
        cov_bdrjt0qwr.s[99]++;

        if (item.sub) {
          cov_bdrjt0qwr.b[28][0]++;
          cov_bdrjt0qwr.s[100]++;

          return;
        } else {
          cov_bdrjt0qwr.b[28][1]++;
        }
        cov_bdrjt0qwr.s[101]++;
        $scope.frameresultParams.grapheType = true;

        var table = (cov_bdrjt0qwr.s[102]++, $('body'));

        cov_bdrjt0qwr.s[103]++;
        $scope.frameresultParams.panelPosition = Object.assign({ top: 20, left: 20 }, {
          width: table.width() - 300 + 'px',
          height: table.height() - 300 + 'px'
        });
        cov_bdrjt0qwr.s[104]++;
        $scope.frameresultParams.panelPosition.top = $scope.frameresultParams.panelPosition.top + 100 + 'px';
        cov_bdrjt0qwr.s[105]++;
        $scope.frameresultParams.panelPosition.left = $scope.frameresultParams.panelPosition.left + 100 + 'px';

        cov_bdrjt0qwr.s[106]++;
        $scope.frameresultParams.graphLabel = item.ci + ': ' + item.li;
        cov_bdrjt0qwr.s[107]++;
        if ($scope.frameresultParams.lastindex == item.id) {
          cov_bdrjt0qwr.b[29][0]++;
          cov_bdrjt0qwr.s[108]++;

          $scope.frameresultParams.lastindex = null;
          cov_bdrjt0qwr.s[109]++;
          return;
        } else {
          cov_bdrjt0qwr.b[29][1]++;
        }
        cov_bdrjt0qwr.s[110]++;
        $scope.frameresultParams.lastindex = item.id;
        var cible = (cov_bdrjt0qwr.s[111]++, item.vc);
        var ref = (cov_bdrjt0qwr.s[112]++, item.vr);
        var color = (cov_bdrjt0qwr.s[113]++, $rootScope.personalData);
        var params = (cov_bdrjt0qwr.s[114]++, {
          chartMouseClick: function chartMouseClick(args, a, b, c) {},

          // Initializing Primary X Axis
          primaryXAxis: {
            title: '',
            interval: 1,
            labelIntersectAction: 'Rotate45',
            valueType: 'Category',
            majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
            majorTickLines: { width: 0 }, minorTickLines: { width: 0 }
          },
          // Initializing Primary Y Axis
          primaryYAxis: {
            title: '',
            valueType: 'Double',
            labelFormat: 'N' + item.fo
          },
          chartArea: {
            border: {
              width: 1
            }
          },
          title: item.ci + ': ' + item.li,
          // Initializing Chart Series
          series: [{
            type: 'Column',
            fill: '#efe4b0', // 'rgba(' + color.rframeV + ',0.5)',
            dataSource: [],
            xName: 'x', yName: 'y', name: $translate.instant('INDICATOR.TARGET_VAL')
          }, {
            fill: 'rgba(' + color.rframeVD + ',0.5)',
            type: 'Line',
            dataSource: [],
            xName: 'x', yName: 'y', name: $translate.instant('INDICATOR.REAL_VAL'),
            width: 2,
            marker: {
              visible: true,
              width: 10,
              height: 10
            }
          }, {
            fill: 'rgba(' + color.rframeVC + ',0.5)',
            type: 'Line',
            dataSource: [],
            xName: 'x', yName: 'y', name: $translate.instant('INDICATOR.TARGET'),
            width: 2,
            marker: {
              visible: true,
              width: 10,
              height: 10
            }
          }],

          height: $scope.frameresultParams.panelPosition.height,
          width: $scope.frameresultParams.panelPosition.width, // ($('#chart-container').height() - 45) + 'px',
          // Initializing Chart Title
          // Initializing Tooltip
          tooltip: {
            enable: true
          }

        });

        cov_bdrjt0qwr.s[115]++;
        params.series[0].dataSource.push({
          x: $translate.instant('INDICATOR.REF'),
          y: null
        });
        cov_bdrjt0qwr.s[116]++;
        params.series[1].dataSource.push({
          x: $translate.instant('INDICATOR.REF'),
          y: ref
        });
        cov_bdrjt0qwr.s[117]++;
        params.series[2].dataSource.push({
          x: $translate.instant('INDICATOR.REF'),
          y: null
        });
        cov_bdrjt0qwr.s[118]++;
        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = this.years[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var el = _step4.value;
            cov_bdrjt0qwr.s[119]++;

            params.series[0].dataSource.push({
              x: el,
              y: $deltaNumber.formatNumber(item['P' + el])
            });
            cov_bdrjt0qwr.s[120]++;
            params.series[1].dataSource.push({
              x: el,
              y: $deltaNumber.formatNumber(item[el])
            });
            cov_bdrjt0qwr.s[121]++;
            params.series[2].dataSource.push({
              x: el,
              y: null
            });
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }

        cov_bdrjt0qwr.s[122]++;
        params.series[0].dataSource.push({
          x: $translate.instant('FRAME_RESULT.TARGET'),
          y: null
        });
        cov_bdrjt0qwr.s[123]++;
        params.series[1].dataSource.push({
          x: $translate.instant('FRAME_RESULT.TARGET'),
          y: null
        });
        cov_bdrjt0qwr.s[124]++;
        params.series[2].dataSource.push({
          x: $translate.instant('FRAME_RESULT.TARGET'),
          y: cible
        });

        cov_bdrjt0qwr.s[125]++;
        $scope.frameresultParams.dataChart = params;
      },
      closeChart: function closeChart(index) {
        cov_bdrjt0qwr.s[126]++;

        $scope.frameresultParams.lastindex = null;
        cov_bdrjt0qwr.s[127]++;
        $scope.frameresultParams.details = false;
      }
    };
    cov_bdrjt0qwr.s[128]++;
    $scope.frameresultParams.height = $('body').height() - 330;

    // Build Column
    cov_bdrjt0qwr.s[129]++;
    data.y.sort();
    var years = (cov_bdrjt0qwr.s[130]++, data.y);
    cov_bdrjt0qwr.s[131]++;
    $scope.frameresultParams.years = years;
    var currentData = (cov_bdrjt0qwr.s[132]++, []);
    var columns = (cov_bdrjt0qwr.s[133]++, [{ field: 'li_', headerText: $translate.instant('FRAME_RESULT.INDIC').toUpperCase(), width: 500, disableHtmlEncode: false }, { field: 'cu', headerText: $translate.instant('FRAME_RESULT.UNIT').toUpperCase(), width: 120 }, { width: 60, commands: [{ buttonOption: { content: '', cssClass: 'e-flat e-small', click: function click(args) {
            cov_bdrjt0qwr.s[134]++;

            $scope.frameresultParams.fromButton = true;
          },
          iconCss: 'fa text-success fa-line-chart' } }] }, { field: 'vr', headerText: $translate.instant('INDICATOR.REF').toUpperCase(), width: 120, textAlign: 'Right', format: 'N', type: 'number' }]);
    cov_bdrjt0qwr.s[135]++;
    var _iteratorNormalCompletion5 = true;
    var _didIteratorError5 = false;
    var _iteratorError5 = undefined;

    try {
      for (var _iterator5 = years[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
        var year = _step5.value;
        cov_bdrjt0qwr.s[136]++;

        columns.push({ headerText: year, autoFit: true, textAlign: 'Center', disableHtmlEncode: false, columns: [{ field: 'P' + year, headerText: $translate.instant('INDICATOR.TARGET_VAL'), textAlign: 'Right', format: 'N', type: 'number', width: 120, minWidth: 10 }, { field: year, headerText: $translate.instant('INDICATOR.REAL_VAL'), textAlign: 'Right', width: 120, minWidth: 10, format: 'N', type: 'number' }] });
      }
    } catch (err) {
      _didIteratorError5 = true;
      _iteratorError5 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion5 && _iterator5.return) {
          _iterator5.return();
        }
      } finally {
        if (_didIteratorError5) {
          throw _iteratorError5;
        }
      }
    }

    cov_bdrjt0qwr.s[137]++;
    columns.push({ field: 'vc', headerText: $translate.instant('FRAME_RESULT.FINAL_TARGET').toUpperCase(), width: 120, textAlign: 'Right', format: 'N', type: 'number' });
    // $scope.frameresultParams.columns = angular.copy(columns);
    cov_bdrjt0qwr.s[138]++;
    columns[0].width = 300;
    cov_bdrjt0qwr.s[139]++;
    $scope.frameresultParams.columns = angular.copy(columns);

    // Format Data
    cov_bdrjt0qwr.s[140]++;
    var _iteratorNormalCompletion6 = true;
    var _didIteratorError6 = false;
    var _iteratorError6 = undefined;

    try {
      for (var _iterator6 = angular.copy(data.d)[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
        var el = _step6.value;
        cov_bdrjt0qwr.s[141]++;

        el.li_ = '<span class="label label-primary  m-r-xs text-left" style="background-color: rgba(' + el.co + ',0.9); color: rgba(' + el.co + ',0.9); padding:0; width: 10px; display: inline-block;">\n                          A\n                        </span> ' + ((cov_bdrjt0qwr.b[30][0]++, el.ci_) || (cov_bdrjt0qwr.b[30][1]++, el.ci)) + ': ' + el.li;
        cov_bdrjt0qwr.s[142]++;
        if ((cov_bdrjt0qwr.b[32][0]++, el.t == 1) || (cov_bdrjt0qwr.b[32][1]++, el.t == 2)) {
          cov_bdrjt0qwr.b[31][0]++;
          cov_bdrjt0qwr.s[143]++;

          el.vr = $deltaNumber.formatNumber(el.vr, null, el.fo);
          cov_bdrjt0qwr.s[144]++;
          el.vc = $deltaNumber.formatNumber(el.vc, null, el.fo);
          cov_bdrjt0qwr.s[145]++;
          var _iteratorNormalCompletion7 = true;
          var _didIteratorError7 = false;
          var _iteratorError7 = undefined;

          try {
            for (var _iterator7 = years[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
              var _year = _step7.value;
              cov_bdrjt0qwr.s[146]++;

              el[_year] = $deltaNumber.formatNumber(el[_year], null, el.fo);
              cov_bdrjt0qwr.s[147]++;
              el['P' + _year] = $deltaNumber.formatNumber(el['P' + _year], null, el.fo);
            }
          } catch (err) {
            _didIteratorError7 = true;
            _iteratorError7 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion7 && _iterator7.return) {
                _iterator7.return();
              }
            } finally {
              if (_didIteratorError7) {
                throw _iteratorError7;
              }
            }
          }
        } else {
          cov_bdrjt0qwr.b[31][1]++;
        }
        var item = (cov_bdrjt0qwr.s[148]++, angular.copy(el));
        cov_bdrjt0qwr.s[149]++;
        item.d = undefined;
        cov_bdrjt0qwr.s[150]++;
        currentData.push(item);
        cov_bdrjt0qwr.s[151]++;
        var _iteratorNormalCompletion8 = true;
        var _didIteratorError8 = false;
        var _iteratorError8 = undefined;

        try {
          for (var _iterator8 = el.d[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
            var eld = _step8.value;
            cov_bdrjt0qwr.s[152]++;

            eld.cu = eld.tac ? (cov_bdrjt0qwr.b[33][0]++, '') : (cov_bdrjt0qwr.b[33][1]++, el.cu);
            cov_bdrjt0qwr.s[153]++;
            eld.ci = el.ci;
            cov_bdrjt0qwr.s[154]++;
            eld.li = el.li + ' (' + eld.tac ? (cov_bdrjt0qwr.b[34][0]++, eld.id) : (cov_bdrjt0qwr.b[34][1]++, eld.l + ')');
            cov_bdrjt0qwr.s[155]++;
            eld.li_ = '<div class="m-l-lg"> <i class="fa fa-angle-double-right"></i> <i>' + (eld.tac ? (cov_bdrjt0qwr.b[35][0]++, $translate.instant(eld.id == 1 ? (cov_bdrjt0qwr.b[36][0]++, 'INDICATOR.NUMERATOR') : (cov_bdrjt0qwr.b[36][1]++, 'INDICATOR.DENOMINATOR'))) : (cov_bdrjt0qwr.b[35][1]++, eld.l)) + '</i></div>';
            cov_bdrjt0qwr.s[156]++;
            eld.id = el.id;
            cov_bdrjt0qwr.s[157]++;
            eld.sp = el.sp;
            cov_bdrjt0qwr.s[158]++;
            eld.key = el.key;
            cov_bdrjt0qwr.s[159]++;
            eld.fo = eld.tac ? (cov_bdrjt0qwr.b[37][0]++, null) : (cov_bdrjt0qwr.b[37][1]++, el.fo);
            cov_bdrjt0qwr.s[160]++;
            eld.ti = el.ti;
            cov_bdrjt0qwr.s[161]++;
            eld.so = el.so;
            cov_bdrjt0qwr.s[162]++;
            eld.idcl = el.idcl;
            cov_bdrjt0qwr.s[163]++;
            eld.option = 1;

            cov_bdrjt0qwr.s[164]++;
            var _iteratorNormalCompletion9 = true;
            var _didIteratorError9 = false;
            var _iteratorError9 = undefined;

            try {
              for (var _iterator9 = years[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
                var _year2 = _step9.value;
                cov_bdrjt0qwr.s[165]++;

                eld[_year2] = $deltaNumber.formatNumber(eld[_year2], null, el.fo);
                cov_bdrjt0qwr.s[166]++;
                eld['P' + _year2] = $deltaNumber.formatNumber(eld['P' + _year2], null, el.fo);
              }
            } catch (err) {
              _didIteratorError9 = true;
              _iteratorError9 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion9 && _iterator9.return) {
                  _iterator9.return();
                }
              } finally {
                if (_didIteratorError9) {
                  throw _iteratorError9;
                }
              }
            }

            cov_bdrjt0qwr.s[167]++;
            currentData.push(eld);
          }
        } catch (err) {
          _didIteratorError8 = true;
          _iteratorError8 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion8 && _iterator8.return) {
              _iterator8.return();
            }
          } finally {
            if (_didIteratorError8) {
              throw _iteratorError8;
            }
          }
        }
      }
    } catch (err) {
      _didIteratorError6 = true;
      _iteratorError6 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion6 && _iterator6.return) {
          _iterator6.return();
        }
      } finally {
        if (_didIteratorError6) {
          throw _iteratorError6;
        }
      }
    }

    cov_bdrjt0qwr.s[168]++;
    currentData.sort(function (a, b) {
      cov_bdrjt0qwr.f[13]++;
      cov_bdrjt0qwr.s[169]++;

      if (a.ci > b.ci) {
        cov_bdrjt0qwr.b[38][0]++;
        cov_bdrjt0qwr.s[170]++;

        return 1;
      } else {
        cov_bdrjt0qwr.b[38][1]++;
      }
      cov_bdrjt0qwr.s[171]++;
      if (a.ci < b.ci) {
        cov_bdrjt0qwr.b[39][0]++;
        cov_bdrjt0qwr.s[172]++;

        return -1;
      } else {
        cov_bdrjt0qwr.b[39][1]++;
      }

      cov_bdrjt0qwr.s[173]++;
      if (a.id > b.id) {
        cov_bdrjt0qwr.b[40][0]++;
        cov_bdrjt0qwr.s[174]++;

        return 1;
      } else {
        cov_bdrjt0qwr.b[40][1]++;
      }
      cov_bdrjt0qwr.s[175]++;
      if (a.id < b.id) {
        cov_bdrjt0qwr.b[41][0]++;
        cov_bdrjt0qwr.s[176]++;

        return -1;
      } else {
        cov_bdrjt0qwr.b[41][1]++;
      }
      cov_bdrjt0qwr.s[177]++;
      if ((cov_bdrjt0qwr.b[43][0]++, a.option != 1) && (cov_bdrjt0qwr.b[43][1]++, b.option == 1)) {
        cov_bdrjt0qwr.b[42][0]++;
        cov_bdrjt0qwr.s[178]++;

        return -1;
      } else {
        cov_bdrjt0qwr.b[42][1]++;
      }
      cov_bdrjt0qwr.s[179]++;
      return 1;
    });

    // $scope.frameresultParams.dataSource = currentData;

    var leveledData = (cov_bdrjt0qwr.s[180]++, []);
    cov_bdrjt0qwr.s[181]++;

    var _loop2 = function _loop2(levelKey) {
      var cl = (cov_bdrjt0qwr.s[182]++, data.cl[levelKey]);
      cov_bdrjt0qwr.s[183]++;
      cl.cols = 5 + $scope.frameresultParams.years.length * 2;
      cov_bdrjt0qwr.s[184]++;
      cl.li_ = '<span>' + cl.NIV_LABEL + '</span>: <span class="label label-primary  m-r-xs" style=" background-color: rgba(' + cl.COLOR + ',0.9);">' + cl.CODE_AFF + '</span> - ' + cl.LABEL;
      cov_bdrjt0qwr.s[185]++;
      leveledData.push(cl);
      cov_bdrjt0qwr.s[186]++;
      var _iteratorNormalCompletion10 = true;
      var _didIteratorError10 = false;
      var _iteratorError10 = undefined;

      try {
        for (var _iterator10 = currentData.filter(function (value) {
          cov_bdrjt0qwr.f[14]++;
          cov_bdrjt0qwr.s[187]++;

          return value.idcl == levelKey;
        })[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
          var _item = _step10.value;
          cov_bdrjt0qwr.s[188]++;

          leveledData.push(_item);
        }
      } catch (err) {
        _didIteratorError10 = true;
        _iteratorError10 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion10 && _iterator10.return) {
            _iterator10.return();
          }
        } finally {
          if (_didIteratorError10) {
            throw _iteratorError10;
          }
        }
      }
    };

    for (var levelKey in data.cl) {
      _loop2(levelKey);
    }

    cov_bdrjt0qwr.s[189]++;
    $scope.frameresultParams.dataSource = leveledData;
    //  this.isloading = false;
  });

  // ResultFramework performance
  cov_bdrjt0qwr.s[190]++;
  $CRUDService.getAll(PATH_INDICATORS_GLOBAL, { get: 'frameres', valid: 0, idl: 0, idcl: 0, p_bksb_: idProject }, function (data) {
    cov_bdrjt0qwr.f[15]++;
    cov_bdrjt0qwr.s[191]++;

    $scope.frameresultParamsGlobal = {
      dataSource: null,
      recordClick: function recordClick(item) {
        cov_bdrjt0qwr.s[192]++;

        if ($scope.frameresultParamsGlobal.fromButton) {
          cov_bdrjt0qwr.b[44][0]++;
          cov_bdrjt0qwr.s[193]++;

          $scope.frameresultParamsGlobal.openChart(item, {});
        } else {
          cov_bdrjt0qwr.b[44][1]++;
        }
        cov_bdrjt0qwr.s[194]++;
        $scope.frameresultParamsGlobal.fromButton = false;
      },
      openChart: function openChart(item, event) {
        cov_bdrjt0qwr.s[195]++;

        $scope.frameresultParamsGlobal.grapheType = true;

        var table = (cov_bdrjt0qwr.s[196]++, $('body'));

        cov_bdrjt0qwr.s[197]++;
        $scope.frameresultParamsGlobal.panelPosition = Object.assign({ top: 20, left: 20 }, {
          width: table.width() - 300 + 'px',
          height: table.height() - 300 + 'px'
        });
        cov_bdrjt0qwr.s[198]++;
        $scope.frameresultParamsGlobal.panelPosition.top = $scope.frameresultParamsGlobal.panelPosition.top + 100 + 'px';
        cov_bdrjt0qwr.s[199]++;
        $scope.frameresultParamsGlobal.panelPosition.left = $scope.frameresultParamsGlobal.panelPosition.left + 100 + 'px';

        cov_bdrjt0qwr.s[200]++;
        $scope.frameresultParamsGlobal.graphLabel = item.ci + ': ' + item.li;
        cov_bdrjt0qwr.s[201]++;
        if ($scope.frameresultParamsGlobal.lastindex == item.id) {
          cov_bdrjt0qwr.b[45][0]++;
          cov_bdrjt0qwr.s[202]++;

          $scope.frameresultParamsGlobal.lastindex = null;
          cov_bdrjt0qwr.s[203]++;
          return;
        } else {
          cov_bdrjt0qwr.b[45][1]++;
        }
        cov_bdrjt0qwr.s[204]++;
        $scope.frameresultParamsGlobal.lastindex = item.id;
        var color = (cov_bdrjt0qwr.s[205]++, $rootScope.personalData);
        cov_bdrjt0qwr.s[206]++;
        $scope.frameresultParamsGlobal.dataChart = null;
        cov_bdrjt0qwr.s[207]++;
        $CRUDService.getAll(PATH_INDICATORS_GLOBAL, { get: 'tbhome_detail_period', valid: 0, idl: 0, idcl: 0, id: item.id, p_bksb_: idProject }, function (data_) {
          cov_bdrjt0qwr.f[16]++;

          var params = (cov_bdrjt0qwr.s[208]++, {
            chartMouseClick: function chartMouseClick(args, a, b, c) {},

            // Initializing Primary X Axis
            primaryXAxis: {
              title: '',
              interval: 1,
              labelIntersectAction: 'Rotate45',
              valueType: 'Category',
              majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
              majorTickLines: { width: 0 }, minorTickLines: { width: 0 }
            },
            // Initializing Primary Y Axis
            primaryYAxis: {
              title: '',
              valueType: 'Double',
              labelFormat: 'N' + item.fo
            },
            chartArea: {
              border: {
                width: 1
              }
            },
            title: item.ci + ': ' + item.li,
            // Initializing Chart Series
            series: [{
              fill: 'rgba(' + color.rframeVD + ',0.5)',
              type: 'Line',
              dataSource: [],
              xName: 'x', yName: 'y', name: $translate.instant('INDICATOR.REAL_VAL'),
              width: 2,
              marker: {
                visible: true,
                width: 10,
                height: 10
              }
            }],

            height: $scope.frameresultParamsGlobal.panelPosition.height,
            width: $scope.frameresultParamsGlobal.panelPosition.width, // ($('#chart-container').height() - 45) + 'px',
            // Initializing Chart Title
            // Initializing Tooltip
            tooltip: {
              enable: true
            }

          });
          var beginProjet = (cov_bdrjt0qwr.s[209]++, $deltadate.format($scope.selectProject.DATE_DEB_PROJET, 'yyyy-mm-dd'));
          var endProjet = (cov_bdrjt0qwr.s[210]++, $deltadate.format($scope.selectProject.DATE_FIN_PROJET, 'yyyy-mm-dd'));
          cov_bdrjt0qwr.s[211]++;
          var _iteratorNormalCompletion11 = true;
          var _didIteratorError11 = false;
          var _iteratorError11 = undefined;

          try {
            for (var _iterator11 = data_[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
              var line = _step11.value;
              cov_bdrjt0qwr.s[212]++;
              var _iteratorNormalCompletion12 = true;
              var _didIteratorError12 = false;
              var _iteratorError12 = undefined;

              try {
                for (var _iterator12 = $deltadate.getPeriods(Date.newDate(line.begin), Date.newDate(line.end), item.PERIOD_INDIC)[Symbol.iterator](), _step12; !(_iteratorNormalCompletion12 = (_step12 = _iterator12.next()).done); _iteratorNormalCompletion12 = true) {
                  var yearLine = _step12.value;

                  var beginPeriod = (cov_bdrjt0qwr.s[213]++, $deltadate.format(yearLine.begin, 'yyyy-mm-dd'));
                  var endPeriod = (cov_bdrjt0qwr.s[214]++, $deltadate.format(yearLine.end, 'yyyy-mm-dd'));
                  cov_bdrjt0qwr.s[215]++;
                  if ((cov_bdrjt0qwr.b[47][0]++, beginProjet > endPeriod) || (cov_bdrjt0qwr.b[47][1]++, beginPeriod > endProjet)) {
                    cov_bdrjt0qwr.b[46][0]++;
                    cov_bdrjt0qwr.s[216]++;

                    continue;
                  } else {
                    cov_bdrjt0qwr.b[46][1]++;
                  }
                  cov_bdrjt0qwr.s[217]++;
                  params.series[0].dataSource.push({
                    x: yearLine.label,
                    y: $deltaNumber.formatNumber(line[yearLine.year.toString() + yearLine.index.toString()], null, line.fo)
                  });
                }
              } catch (err) {
                _didIteratorError12 = true;
                _iteratorError12 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion12 && _iterator12.return) {
                    _iterator12.return();
                  }
                } finally {
                  if (_didIteratorError12) {
                    throw _iteratorError12;
                  }
                }
              }
            }
          } catch (err) {
            _didIteratorError11 = true;
            _iteratorError11 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion11 && _iterator11.return) {
                _iterator11.return();
              }
            } finally {
              if (_didIteratorError11) {
                throw _iteratorError11;
              }
            }
          }

          cov_bdrjt0qwr.s[218]++;
          $scope.frameresultParamsGlobal.dataChart = params;
        });
      },
      closeChart: function closeChart(index) {
        cov_bdrjt0qwr.s[219]++;

        $scope.frameresultParamsGlobal.lastindex = null;
        cov_bdrjt0qwr.s[220]++;
        $scope.frameresultParamsGlobal.details = false;
      }
    };
    cov_bdrjt0qwr.s[221]++;
    $scope.frameresultParamsGlobal.height = $('body').height() - 330 + 'px';

    // Build Column
    cov_bdrjt0qwr.s[222]++;
    data.y.sort();
    var years = (cov_bdrjt0qwr.s[223]++, data.y.filter(function (year) {
      cov_bdrjt0qwr.f[17]++;
      cov_bdrjt0qwr.s[224]++;

      return (cov_bdrjt0qwr.b[48][0]++, year >= $scope.selectProject.DATE_DEB_PROJET.getFullYear()) && (cov_bdrjt0qwr.b[48][1]++, year <= $scope.selectProject.DATE_FIN_PROJET.getFullYear());
    }));
    cov_bdrjt0qwr.s[225]++;
    $scope.frameresultParamsGlobal.years = years;

    var columns = (cov_bdrjt0qwr.s[226]++, [{ field: 'li_', headerText: $translate.instant('FRAME_RESULT.INDIC').toUpperCase(), width: 500 }, { field: 'cu', headerText: $translate.instant('FRAME_RESULT.UNIT').toUpperCase(), width: 120 }, { width: 60, commands: [{ buttonOption: { content: '', cssClass: 'e-flat e-small', click: function click(args) {
            cov_bdrjt0qwr.s[227]++;

            $scope.frameresultParamsGlobal.fromButton = true;
          },
          iconCss: 'fa text-success fa-line-chart' } }] }]);
    cov_bdrjt0qwr.s[228]++;
    var _iteratorNormalCompletion13 = true;
    var _didIteratorError13 = false;
    var _iteratorError13 = undefined;

    try {
      for (var _iterator13 = years[Symbol.iterator](), _step13; !(_iteratorNormalCompletion13 = (_step13 = _iterator13.next()).done); _iteratorNormalCompletion13 = true) {
        var year = _step13.value;
        cov_bdrjt0qwr.s[229]++;

        columns.push({ field: year.toString(), headerText: year, textAlign: 'Right', width: 120, minWidth: 10, format: 'N', type: 'number' });
      }
    } catch (err) {
      _didIteratorError13 = true;
      _iteratorError13 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion13 && _iterator13.return) {
          _iterator13.return();
        }
      } finally {
        if (_didIteratorError13) {
          throw _iteratorError13;
        }
      }
    }

    cov_bdrjt0qwr.s[230]++;
    $scope.frameresultParamsGlobal.columns = angular.copy(columns);

    // Format Data
    var currentData = (cov_bdrjt0qwr.s[231]++, []);
    cov_bdrjt0qwr.s[232]++;
    var _iteratorNormalCompletion14 = true;
    var _didIteratorError14 = false;
    var _iteratorError14 = undefined;

    try {
      for (var _iterator14 = angular.copy(data.d)[Symbol.iterator](), _step14; !(_iteratorNormalCompletion14 = (_step14 = _iterator14.next()).done); _iteratorNormalCompletion14 = true) {
        var el = _step14.value;
        cov_bdrjt0qwr.s[233]++;

        el.li_ = ' ' + ((cov_bdrjt0qwr.b[49][0]++, el.ci_) || (cov_bdrjt0qwr.b[49][1]++, el.ci)) + ': ' + el.li;
        cov_bdrjt0qwr.s[234]++;
        if ((cov_bdrjt0qwr.b[51][0]++, el.t == 1) || (cov_bdrjt0qwr.b[51][1]++, el.t == 2)) {
          cov_bdrjt0qwr.b[50][0]++;
          cov_bdrjt0qwr.s[235]++;
          var _iteratorNormalCompletion15 = true;
          var _didIteratorError15 = false;
          var _iteratorError15 = undefined;

          try {
            for (var _iterator15 = years[Symbol.iterator](), _step15; !(_iteratorNormalCompletion15 = (_step15 = _iterator15.next()).done); _iteratorNormalCompletion15 = true) {
              var _year3 = _step15.value;
              cov_bdrjt0qwr.s[236]++;

              el[_year3] = $deltaNumber.formatNumber(el[_year3], null, el.fo);
            }
          } catch (err) {
            _didIteratorError15 = true;
            _iteratorError15 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion15 && _iterator15.return) {
                _iterator15.return();
              }
            } finally {
              if (_didIteratorError15) {
                throw _iteratorError15;
              }
            }
          }
        } else {
          cov_bdrjt0qwr.b[50][1]++;
        }
        var item = (cov_bdrjt0qwr.s[237]++, angular.copy(el));
        cov_bdrjt0qwr.s[238]++;
        item.d = undefined;
        cov_bdrjt0qwr.s[239]++;
        currentData.push(item);
      }
    } catch (err) {
      _didIteratorError14 = true;
      _iteratorError14 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion14 && _iterator14.return) {
          _iterator14.return();
        }
      } finally {
        if (_didIteratorError14) {
          throw _iteratorError14;
        }
      }
    }

    cov_bdrjt0qwr.s[240]++;
    currentData.sort(function (a, b) {
      cov_bdrjt0qwr.f[18]++;
      cov_bdrjt0qwr.s[241]++;

      if (a.ci > b.ci) {
        cov_bdrjt0qwr.b[52][0]++;
        cov_bdrjt0qwr.s[242]++;

        return 1;
      } else {
        cov_bdrjt0qwr.b[52][1]++;
      }
      cov_bdrjt0qwr.s[243]++;
      if (a.ci < b.ci) {
        cov_bdrjt0qwr.b[53][0]++;
        cov_bdrjt0qwr.s[244]++;

        return -1;
      } else {
        cov_bdrjt0qwr.b[53][1]++;
      }

      cov_bdrjt0qwr.s[245]++;
      if (a.id > b.id) {
        cov_bdrjt0qwr.b[54][0]++;
        cov_bdrjt0qwr.s[246]++;

        return 1;
      } else {
        cov_bdrjt0qwr.b[54][1]++;
      }
      cov_bdrjt0qwr.s[247]++;
      if (a.id < b.id) {
        cov_bdrjt0qwr.b[55][0]++;
        cov_bdrjt0qwr.s[248]++;

        return -1;
      } else {
        cov_bdrjt0qwr.b[55][1]++;
      }
      cov_bdrjt0qwr.s[249]++;
      if ((cov_bdrjt0qwr.b[57][0]++, a.option != 1) && (cov_bdrjt0qwr.b[57][1]++, b.option == 1)) {
        cov_bdrjt0qwr.b[56][0]++;
        cov_bdrjt0qwr.s[250]++;

        return -1;
      } else {
        cov_bdrjt0qwr.b[56][1]++;
      }
      cov_bdrjt0qwr.s[251]++;
      return 1;
    });
    cov_bdrjt0qwr.s[252]++;
    $scope.frameresultParamsGlobal.dataSource = currentData;
  });
});