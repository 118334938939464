'use strict';

var cov_8ito5tns2 = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/indicator/workflow/workflowCtrl.js',
      hash = '72e1fa89c7c156adb8751b5b938049a72995a1c9',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/indicator/workflow/workflowCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 213,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 21
        }
      },
      '2': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 3,
          column: 33
        }
      },
      '3': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 26
        }
      },
      '4': {
        start: {
          line: 10,
          column: 21
        },
        end: {
          line: 10,
          column: 43
        }
      },
      '5': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 47
        }
      },
      '6': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 91
        }
      },
      '7': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 48
        }
      },
      '8': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 45
        }
      },
      '9': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 19,
          column: 5
        }
      },
      '10': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 18,
          column: 10
        }
      },
      '11': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 17,
          column: 54
        }
      },
      '12': {
        start: {
          line: 20,
          column: 2
        },
        end: {
          line: 39,
          column: 4
        }
      },
      '13': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 23,
          column: 5
        }
      },
      '14': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 34
        }
      },
      '15': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 26,
          column: 5
        }
      },
      '16': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 34
        }
      },
      '17': {
        start: {
          line: 28,
          column: 4
        },
        end: {
          line: 30,
          column: 5
        }
      },
      '18': {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 29,
          column: 38
        }
      },
      '19': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 33,
          column: 5
        }
      },
      '20': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 38
        }
      },
      '21': {
        start: {
          line: 35,
          column: 4
        },
        end: {
          line: 37,
          column: 5
        }
      },
      '22': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 36,
          column: 38
        }
      },
      '23': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 38,
          column: 32
        }
      },
      '24': {
        start: {
          line: 40,
          column: 2
        },
        end: {
          line: 55,
          column: 4
        }
      },
      '25': {
        start: {
          line: 41,
          column: 4
        },
        end: {
          line: 41,
          column: 28
        }
      },
      '26': {
        start: {
          line: 42,
          column: 4
        },
        end: {
          line: 54,
          column: 7
        }
      },
      '27': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 47,
          column: 7
        }
      },
      '28': {
        start: {
          line: 44,
          column: 8
        },
        end: {
          line: 44,
          column: 30
        }
      },
      '29': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 46,
          column: 22
        }
      },
      '30': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 52,
          column: 7
        }
      },
      '31': {
        start: {
          line: 49,
          column: 8
        },
        end: {
          line: 49,
          column: 30
        }
      },
      '32': {
        start: {
          line: 51,
          column: 8
        },
        end: {
          line: 51,
          column: 22
        }
      },
      '33': {
        start: {
          line: 53,
          column: 6
        },
        end: {
          line: 53,
          column: 32
        }
      },
      '34': {
        start: {
          line: 57,
          column: 2
        },
        end: {
          line: 73,
          column: 4
        }
      },
      '35': {
        start: {
          line: 58,
          column: 4
        },
        end: {
          line: 58,
          column: 28
        }
      },
      '36': {
        start: {
          line: 59,
          column: 24
        },
        end: {
          line: 61,
          column: 18
        }
      },
      '37': {
        start: {
          line: 62,
          column: 4
        },
        end: {
          line: 72,
          column: 7
        }
      },
      '38': {
        start: {
          line: 63,
          column: 6
        },
        end: {
          line: 66,
          column: 7
        }
      },
      '39': {
        start: {
          line: 64,
          column: 8
        },
        end: {
          line: 64,
          column: 41
        }
      },
      '40': {
        start: {
          line: 65,
          column: 8
        },
        end: {
          line: 65,
          column: 54
        }
      },
      '41': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 67,
          column: 29
        }
      },
      '42': {
        start: {
          line: 68,
          column: 6
        },
        end: {
          line: 68,
          column: 36
        }
      },
      '43': {
        start: {
          line: 69,
          column: 6
        },
        end: {
          line: 69,
          column: 31
        }
      },
      '44': {
        start: {
          line: 70,
          column: 6
        },
        end: {
          line: 70,
          column: 36
        }
      },
      '45': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 71,
          column: 34
        }
      },
      '46': {
        start: {
          line: 74,
          column: 2
        },
        end: {
          line: 88,
          column: 4
        }
      },
      '47': {
        start: {
          line: 75,
          column: 21
        },
        end: {
          line: 77,
          column: 14
        }
      },
      '48': {
        start: {
          line: 78,
          column: 15
        },
        end: {
          line: 78,
          column: 26
        }
      },
      '49': {
        start: {
          line: 79,
          column: 18
        },
        end: {
          line: 79,
          column: 47
        }
      },
      '50': {
        start: {
          line: 80,
          column: 4
        },
        end: {
          line: 87,
          column: 7
        }
      },
      '51': {
        start: {
          line: 89,
          column: 2
        },
        end: {
          line: 122,
          column: 4
        }
      },
      '52': {
        start: {
          line: 90,
          column: 4
        },
        end: {
          line: 121,
          column: 7
        }
      },
      '53': {
        start: {
          line: 102,
          column: 6
        },
        end: {
          line: 104,
          column: 7
        }
      },
      '54': {
        start: {
          line: 103,
          column: 8
        },
        end: {
          line: 103,
          column: 15
        }
      },
      '55': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 105,
          column: 30
        }
      },
      '56': {
        start: {
          line: 106,
          column: 27
        },
        end: {
          line: 106,
          column: 45
        }
      },
      '57': {
        start: {
          line: 107,
          column: 6
        },
        end: {
          line: 120,
          column: 9
        }
      },
      '58': {
        start: {
          line: 108,
          column: 8
        },
        end: {
          line: 115,
          column: 9
        }
      },
      '59': {
        start: {
          line: 109,
          column: 10
        },
        end: {
          line: 112,
          column: 13
        }
      },
      '60': {
        start: {
          line: 113,
          column: 10
        },
        end: {
          line: 113,
          column: 35
        }
      },
      '61': {
        start: {
          line: 114,
          column: 10
        },
        end: {
          line: 114,
          column: 17
        }
      },
      '62': {
        start: {
          line: 116,
          column: 21
        },
        end: {
          line: 116,
          column: 40
        }
      },
      '63': {
        start: {
          line: 117,
          column: 8
        },
        end: {
          line: 119,
          column: 19
        }
      },
      '64': {
        start: {
          line: 123,
          column: 2
        },
        end: {
          line: 152,
          column: 4
        }
      },
      '65': {
        start: {
          line: 124,
          column: 4
        },
        end: {
          line: 124,
          column: 45
        }
      },
      '66': {
        start: {
          line: 125,
          column: 4
        },
        end: {
          line: 125,
          column: 31
        }
      },
      '67': {
        start: {
          line: 126,
          column: 17
        },
        end: {
          line: 126,
          column: 36
        }
      },
      '68': {
        start: {
          line: 127,
          column: 21
        },
        end: {
          line: 129,
          column: 24
        }
      },
      '69': {
        start: {
          line: 130,
          column: 4
        },
        end: {
          line: 136,
          column: 5
        }
      },
      '70': {
        start: {
          line: 131,
          column: 20
        },
        end: {
          line: 131,
          column: 72
        }
      },
      '71': {
        start: {
          line: 132,
          column: 6
        },
        end: {
          line: 134,
          column: 7
        }
      },
      '72': {
        start: {
          line: 133,
          column: 8
        },
        end: {
          line: 133,
          column: 17
        }
      },
      '73': {
        start: {
          line: 135,
          column: 6
        },
        end: {
          line: 135,
          column: 39
        }
      },
      '74': {
        start: {
          line: 137,
          column: 14
        },
        end: {
          line: 137,
          column: 88
        }
      },
      '75': {
        start: {
          line: 138,
          column: 4
        },
        end: {
          line: 142,
          column: 5
        }
      },
      '76': {
        start: {
          line: 139,
          column: 6
        },
        end: {
          line: 139,
          column: 40
        }
      },
      '77': {
        start: {
          line: 141,
          column: 6
        },
        end: {
          line: 141,
          column: 79
        }
      },
      '78': {
        start: {
          line: 144,
          column: 4
        },
        end: {
          line: 151,
          column: 7
        }
      },
      '79': {
        start: {
          line: 154,
          column: 2
        },
        end: {
          line: 159,
          column: 4
        }
      },
      '80': {
        start: {
          line: 155,
          column: 17
        },
        end: {
          line: 155,
          column: 36
        }
      },
      '81': {
        start: {
          line: 156,
          column: 4
        },
        end: {
          line: 158,
          column: 45
        }
      },
      '82': {
        start: {
          line: 161,
          column: 2
        },
        end: {
          line: 185,
          column: 4
        }
      },
      '83': {
        start: {
          line: 164,
          column: 6
        },
        end: {
          line: 164,
          column: 27
        }
      },
      '84': {
        start: {
          line: 165,
          column: 6
        },
        end: {
          line: 183,
          column: 9
        }
      },
      '85': {
        start: {
          line: 166,
          column: 8
        },
        end: {
          line: 182,
          column: 9
        }
      },
      '86': {
        start: {
          line: 167,
          column: 27
        },
        end: {
          line: 172,
          column: 11
        }
      },
      '87': {
        start: {
          line: 173,
          column: 10
        },
        end: {
          line: 178,
          column: 13
        }
      },
      '88': {
        start: {
          line: 174,
          column: 12
        },
        end: {
          line: 174,
          column: 32
        }
      },
      '89': {
        start: {
          line: 175,
          column: 12
        },
        end: {
          line: 175,
          column: 36
        }
      },
      '90': {
        start: {
          line: 176,
          column: 12
        },
        end: {
          line: 176,
          column: 53
        }
      },
      '91': {
        start: {
          line: 177,
          column: 12
        },
        end: {
          line: 177,
          column: 49
        }
      },
      '92': {
        start: {
          line: 180,
          column: 10
        },
        end: {
          line: 180,
          column: 47
        }
      },
      '93': {
        start: {
          line: 181,
          column: 10
        },
        end: {
          line: 181,
          column: 47
        }
      },
      '94': {
        start: {
          line: 187,
          column: 2
        },
        end: {
          line: 210,
          column: 4
        }
      },
      '95': {
        start: {
          line: 190,
          column: 6
        },
        end: {
          line: 190,
          column: 27
        }
      },
      '96': {
        start: {
          line: 191,
          column: 6
        },
        end: {
          line: 208,
          column: 9
        }
      },
      '97': {
        start: {
          line: 192,
          column: 8
        },
        end: {
          line: 207,
          column: 9
        }
      },
      '98': {
        start: {
          line: 193,
          column: 27
        },
        end: {
          line: 197,
          column: 11
        }
      },
      '99': {
        start: {
          line: 198,
          column: 10
        },
        end: {
          line: 203,
          column: 13
        }
      },
      '100': {
        start: {
          line: 199,
          column: 12
        },
        end: {
          line: 199,
          column: 32
        }
      },
      '101': {
        start: {
          line: 200,
          column: 12
        },
        end: {
          line: 200,
          column: 36
        }
      },
      '102': {
        start: {
          line: 201,
          column: 12
        },
        end: {
          line: 201,
          column: 51
        }
      },
      '103': {
        start: {
          line: 202,
          column: 12
        },
        end: {
          line: 202,
          column: 47
        }
      },
      '104': {
        start: {
          line: 205,
          column: 10
        },
        end: {
          line: 205,
          column: 45
        }
      },
      '105': {
        start: {
          line: 206,
          column: 10
        },
        end: {
          line: 206,
          column: 45
        }
      },
      '106': {
        start: {
          line: 212,
          column: 2
        },
        end: {
          line: 212,
          column: 22
        }
      },
      '107': {
        start: {
          line: 216,
          column: 2
        },
        end: {
          line: 216,
          column: 28
        }
      },
      '108': {
        start: {
          line: 220,
          column: 15
        },
        end: {
          line: 220,
          column: 25
        }
      },
      '109': {
        start: {
          line: 221,
          column: 2
        },
        end: {
          line: 231,
          column: 4
        }
      },
      '110': {
        start: {
          line: 222,
          column: 25
        },
        end: {
          line: 222,
          column: 58
        }
      },
      '111': {
        start: {
          line: 223,
          column: 4
        },
        end: {
          line: 223,
          column: 49
        }
      },
      '112': {
        start: {
          line: 224,
          column: 4
        },
        end: {
          line: 230,
          column: 7
        }
      },
      '113': {
        start: {
          line: 225,
          column: 6
        },
        end: {
          line: 225,
          column: 26
        }
      },
      '114': {
        start: {
          line: 226,
          column: 19
        },
        end: {
          line: 226,
          column: 38
        }
      },
      '115': {
        start: {
          line: 227,
          column: 6
        },
        end: {
          line: 229,
          column: 17
        }
      },
      '116': {
        start: {
          line: 232,
          column: 2
        },
        end: {
          line: 234,
          column: 4
        }
      },
      '117': {
        start: {
          line: 233,
          column: 4
        },
        end: {
          line: 233,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 59
          },
          end: {
            line: 1,
            column: 60
          }
        },
        loc: {
          start: {
            line: 1,
            column: 245
          },
          end: {
            line: 213,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 15,
            column: 36
          },
          end: {
            line: 15,
            column: 37
          }
        },
        loc: {
          start: {
            line: 15,
            column: 42
          },
          end: {
            line: 19,
            column: 3
          }
        },
        line: 15
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 16,
            column: 65
          },
          end: {
            line: 16,
            column: 66
          }
        },
        loc: {
          start: {
            line: 16,
            column: 74
          },
          end: {
            line: 18,
            column: 5
          }
        },
        line: 16
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 20,
            column: 30
          },
          end: {
            line: 20,
            column: 31
          }
        },
        loc: {
          start: {
            line: 20,
            column: 46
          },
          end: {
            line: 39,
            column: 3
          }
        },
        line: 20
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 40,
            column: 26
          },
          end: {
            line: 40,
            column: 27
          }
        },
        loc: {
          start: {
            line: 40,
            column: 47
          },
          end: {
            line: 55,
            column: 3
          }
        },
        line: 40
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 42,
            column: 100
          },
          end: {
            line: 42,
            column: 101
          }
        },
        loc: {
          start: {
            line: 42,
            column: 108
          },
          end: {
            line: 54,
            column: 5
          }
        },
        line: 42
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 57,
            column: 22
          },
          end: {
            line: 57,
            column: 23
          }
        },
        loc: {
          start: {
            line: 57,
            column: 34
          },
          end: {
            line: 73,
            column: 3
          }
        },
        line: 57
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 62,
            column: 43
          },
          end: {
            line: 62,
            column: 44
          }
        },
        loc: {
          start: {
            line: 62,
            column: 51
          },
          end: {
            line: 72,
            column: 5
          }
        },
        line: 62
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 74,
            column: 19
          },
          end: {
            line: 74,
            column: 20
          }
        },
        loc: {
          start: {
            line: 74,
            column: 35
          },
          end: {
            line: 88,
            column: 3
          }
        },
        line: 74
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 89,
            column: 22
          },
          end: {
            line: 89,
            column: 23
          }
        },
        loc: {
          start: {
            line: 89,
            column: 38
          },
          end: {
            line: 122,
            column: 3
          }
        },
        line: 89
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 101,
            column: 7
          },
          end: {
            line: 101,
            column: 8
          }
        },
        loc: {
          start: {
            line: 101,
            column: 20
          },
          end: {
            line: 121,
            column: 5
          }
        },
        line: 101
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 107,
            column: 96
          },
          end: {
            line: 107,
            column: 97
          }
        },
        loc: {
          start: {
            line: 107,
            column: 104
          },
          end: {
            line: 120,
            column: 7
          }
        },
        line: 107
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 123,
            column: 20
          },
          end: {
            line: 123,
            column: 21
          }
        },
        loc: {
          start: {
            line: 123,
            column: 36
          },
          end: {
            line: 152,
            column: 3
          }
        },
        line: 123
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 154,
            column: 23
          },
          end: {
            line: 154,
            column: 24
          }
        },
        loc: {
          start: {
            line: 154,
            column: 35
          },
          end: {
            line: 159,
            column: 3
          }
        },
        line: 154
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 165,
            column: 87
          },
          end: {
            line: 165,
            column: 88
          }
        },
        loc: {
          start: {
            line: 165,
            column: 95
          },
          end: {
            line: 183,
            column: 7
          }
        },
        line: 165
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 173,
            column: 99
          },
          end: {
            line: 173,
            column: 100
          }
        },
        loc: {
          start: {
            line: 173,
            column: 108
          },
          end: {
            line: 178,
            column: 11
          }
        },
        line: 173
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 191,
            column: 85
          },
          end: {
            line: 191,
            column: 86
          }
        },
        loc: {
          start: {
            line: 191,
            column: 93
          },
          end: {
            line: 208,
            column: 7
          }
        },
        line: 191
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 198,
            column: 99
          },
          end: {
            line: 198,
            column: 100
          }
        },
        loc: {
          start: {
            line: 198,
            column: 108
          },
          end: {
            line: 203,
            column: 11
          }
        },
        line: 198
      },
      '18': {
        name: 'indicatorsWorkflowCtrl_edit',
        decl: {
          start: {
            line: 215,
            column: 9
          },
          end: {
            line: 215,
            column: 36
          }
        },
        loc: {
          start: {
            line: 215,
            column: 87
          },
          end: {
            line: 235,
            column: 1
          }
        },
        line: 215
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 221,
            column: 24
          },
          end: {
            line: 221,
            column: 25
          }
        },
        loc: {
          start: {
            line: 221,
            column: 36
          },
          end: {
            line: 231,
            column: 3
          }
        },
        line: 221
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 224,
            column: 94
          },
          end: {
            line: 224,
            column: 95
          }
        },
        loc: {
          start: {
            line: 224,
            column: 100
          },
          end: {
            line: 230,
            column: 5
          }
        },
        line: 224
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 232,
            column: 22
          },
          end: {
            line: 232,
            column: 23
          }
        },
        loc: {
          start: {
            line: 232,
            column: 34
          },
          end: {
            line: 234,
            column: 3
          }
        },
        line: 232
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 21,
            column: 4
          },
          end: {
            line: 23,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 21,
            column: 4
          },
          end: {
            line: 23,
            column: 5
          }
        }, {
          start: {
            line: 21,
            column: 4
          },
          end: {
            line: 23,
            column: 5
          }
        }],
        line: 21
      },
      '1': {
        loc: {
          start: {
            line: 24,
            column: 4
          },
          end: {
            line: 26,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 24,
            column: 4
          },
          end: {
            line: 26,
            column: 5
          }
        }, {
          start: {
            line: 24,
            column: 4
          },
          end: {
            line: 26,
            column: 5
          }
        }],
        line: 24
      },
      '2': {
        loc: {
          start: {
            line: 28,
            column: 4
          },
          end: {
            line: 30,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 4
          },
          end: {
            line: 30,
            column: 5
          }
        }, {
          start: {
            line: 28,
            column: 4
          },
          end: {
            line: 30,
            column: 5
          }
        }],
        line: 28
      },
      '3': {
        loc: {
          start: {
            line: 31,
            column: 4
          },
          end: {
            line: 33,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 31,
            column: 4
          },
          end: {
            line: 33,
            column: 5
          }
        }, {
          start: {
            line: 31,
            column: 4
          },
          end: {
            line: 33,
            column: 5
          }
        }],
        line: 31
      },
      '4': {
        loc: {
          start: {
            line: 35,
            column: 4
          },
          end: {
            line: 37,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 35,
            column: 4
          },
          end: {
            line: 37,
            column: 5
          }
        }, {
          start: {
            line: 35,
            column: 4
          },
          end: {
            line: 37,
            column: 5
          }
        }],
        line: 35
      },
      '5': {
        loc: {
          start: {
            line: 59,
            column: 24
          },
          end: {
            line: 61,
            column: 18
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 60,
            column: 6
          },
          end: {
            line: 60,
            column: 65
          }
        }, {
          start: {
            line: 61,
            column: 6
          },
          end: {
            line: 61,
            column: 18
          }
        }],
        line: 59
      },
      '6': {
        loc: {
          start: {
            line: 76,
            column: 8
          },
          end: {
            line: 76,
            column: 61
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 76,
            column: 20
          },
          end: {
            line: 76,
            column: 40
          }
        }, {
          start: {
            line: 76,
            column: 43
          },
          end: {
            line: 76,
            column: 61
          }
        }],
        line: 76
      },
      '7': {
        loc: {
          start: {
            line: 102,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 102,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }, {
          start: {
            line: 102,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }],
        line: 102
      },
      '8': {
        loc: {
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 115,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 115,
            column: 9
          }
        }, {
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 115,
            column: 9
          }
        }],
        line: 108
      },
      '9': {
        loc: {
          start: {
            line: 118,
            column: 12
          },
          end: {
            line: 118,
            column: 65
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 118,
            column: 24
          },
          end: {
            line: 118,
            column: 44
          }
        }, {
          start: {
            line: 118,
            column: 47
          },
          end: {
            line: 118,
            column: 65
          }
        }],
        line: 118
      },
      '10': {
        loc: {
          start: {
            line: 128,
            column: 8
          },
          end: {
            line: 128,
            column: 61
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 128,
            column: 20
          },
          end: {
            line: 128,
            column: 40
          }
        }, {
          start: {
            line: 128,
            column: 43
          },
          end: {
            line: 128,
            column: 61
          }
        }],
        line: 128
      },
      '11': {
        loc: {
          start: {
            line: 132,
            column: 6
          },
          end: {
            line: 134,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 132,
            column: 6
          },
          end: {
            line: 134,
            column: 7
          }
        }, {
          start: {
            line: 132,
            column: 6
          },
          end: {
            line: 134,
            column: 7
          }
        }],
        line: 132
      },
      '12': {
        loc: {
          start: {
            line: 132,
            column: 10
          },
          end: {
            line: 132,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 132,
            column: 10
          },
          end: {
            line: 132,
            column: 26
          }
        }, {
          start: {
            line: 132,
            column: 30
          },
          end: {
            line: 132,
            column: 52
          }
        }],
        line: 132
      },
      '13': {
        loc: {
          start: {
            line: 138,
            column: 4
          },
          end: {
            line: 142,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 138,
            column: 4
          },
          end: {
            line: 142,
            column: 5
          }
        }, {
          start: {
            line: 138,
            column: 4
          },
          end: {
            line: 142,
            column: 5
          }
        }],
        line: 138
      },
      '14': {
        loc: {
          start: {
            line: 156,
            column: 12
          },
          end: {
            line: 158,
            column: 33
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 156,
            column: 12
          },
          end: {
            line: 158,
            column: 14
          }
        }, {
          start: {
            line: 158,
            column: 18
          },
          end: {
            line: 158,
            column: 33
          }
        }],
        line: 156
      },
      '15': {
        loc: {
          start: {
            line: 157,
            column: 8
          },
          end: {
            line: 157,
            column: 61
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 157,
            column: 20
          },
          end: {
            line: 157,
            column: 40
          }
        }, {
          start: {
            line: 157,
            column: 43
          },
          end: {
            line: 157,
            column: 61
          }
        }],
        line: 157
      },
      '16': {
        loc: {
          start: {
            line: 166,
            column: 8
          },
          end: {
            line: 182,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 166,
            column: 8
          },
          end: {
            line: 182,
            column: 9
          }
        }, {
          start: {
            line: 166,
            column: 8
          },
          end: {
            line: 182,
            column: 9
          }
        }],
        line: 166
      },
      '17': {
        loc: {
          start: {
            line: 192,
            column: 8
          },
          end: {
            line: 207,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 192,
            column: 8
          },
          end: {
            line: 207,
            column: 9
          }
        }, {
          start: {
            line: 192,
            column: 8
          },
          end: {
            line: 207,
            column: 9
          }
        }],
        line: 192
      },
      '18': {
        loc: {
          start: {
            line: 228,
            column: 10
          },
          end: {
            line: 228,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 228,
            column: 22
          },
          end: {
            line: 228,
            column: 42
          }
        }, {
          start: {
            line: 228,
            column: 45
          },
          end: {
            line: 228,
            column: 63
          }
        }],
        line: 228
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_8ito5tns2.s[0]++;
angular.module('app').controller('indicatorsWorkflowCtrl', function ($scope, $stateParams, $deltaUnite, $deltahttp, $deltaTypeIndicateur, $rootScope, $translate, $deltaLocation, $CRUDService, SweetAlert, $deltadate, $deltaPeriode, $filter, $uibModal) {
  cov_8ito5tns2.f[0]++;

  var PATH = (cov_8ito5tns2.s[1]++, 'User');
  var PATHWORKFLOW = (cov_8ito5tns2.s[2]++, 'Workflow');

  /* if (!$stateParams.params) {
   $stateParams.params = {};
  } */
  cov_8ito5tns2.s[3]++;
  $scope.tab_workflow = 5;

  var PATHGLOBAL = (cov_8ito5tns2.s[4]++, 'DecoupageGlobalIndic');
  cov_8ito5tns2.s[5]++;
  $scope.selectedItem = $stateParams.indicator;
  cov_8ito5tns2.s[6]++;
  $scope.selectedItem.imageUrl = $deltahttp.getIndicatorRespositoy($scope.selectedItem.id);
  cov_8ito5tns2.s[7]++;
  $scope.onlyDashboard = $stateParams.dashboard;
  cov_8ito5tns2.s[8]++;
  $deltaTypeIndicateur.addController($scope);
  cov_8ito5tns2.s[9]++;
  $deltaUnite.addController($scope, function () {
    cov_8ito5tns2.f[1]++;
    cov_8ito5tns2.s[10]++;

    $scope.selectedItemUnit = $scope.listData_unites_bksb.filter(function (value) {
      cov_8ito5tns2.f[2]++;
      cov_8ito5tns2.s[11]++;

      return value.id == $scope.selectedItem.ID_UNITE;
    })[0];
  });
  cov_8ito5tns2.s[12]++;
  $scope.getIndicateurLabel = function (item) {
    cov_8ito5tns2.f[3]++;
    cov_8ito5tns2.s[13]++;

    if (!item.LIBELLE_C_INDIC_ENG) {
      cov_8ito5tns2.b[0][0]++;
      cov_8ito5tns2.s[14]++;

      return item.LIBELLE_C_INDIC;
    } else {
      cov_8ito5tns2.b[0][1]++;
    }
    cov_8ito5tns2.s[15]++;
    if (item.LIBELLE_C_INDIC_ENG.trim() == '') {
      cov_8ito5tns2.b[1][0]++;
      cov_8ito5tns2.s[16]++;

      return item.LIBELLE_C_INDIC;
    } else {
      cov_8ito5tns2.b[1][1]++;
    }

    cov_8ito5tns2.s[17]++;
    if (!item.LIBELLE_C_INDIC) {
      cov_8ito5tns2.b[2][0]++;
      cov_8ito5tns2.s[18]++;

      return item.LIBELLE_C_INDIC_ENG;
    } else {
      cov_8ito5tns2.b[2][1]++;
    }
    cov_8ito5tns2.s[19]++;
    if (item.LIBELLE_C_INDIC.trim() == '') {
      cov_8ito5tns2.b[3][0]++;
      cov_8ito5tns2.s[20]++;

      return item.LIBELLE_C_INDIC_ENG;
    } else {
      cov_8ito5tns2.b[3][1]++;
    }

    cov_8ito5tns2.s[21]++;
    if ($rootScope.language.id == 'en') {
      cov_8ito5tns2.b[4][0]++;
      cov_8ito5tns2.s[22]++;

      return item.LIBELLE_C_INDIC_ENG;
    } else {
      cov_8ito5tns2.b[4][1]++;
    }
    cov_8ito5tns2.s[23]++;
    return item.LIBELLE_C_INDIC;
  };
  cov_8ito5tns2.s[24]++;
  $scope.getGlobalValue = function (indicItem) {
    cov_8ito5tns2.f[4]++;
    cov_8ito5tns2.s[25]++;

    $scope.glovalValue = {};
    cov_8ito5tns2.s[26]++;
    $CRUDService.getAll(PATHGLOBAL, { get: 'all_global', idcl: indicItem.CODE_CL, id: indicItem.id }, function (data) {
      cov_8ito5tns2.f[5]++;
      cov_8ito5tns2.s[27]++;

      try {
        cov_8ito5tns2.s[28]++;

        data.c = eval(data.c);
      } catch (ex) {
        cov_8ito5tns2.s[29]++;

        data.c = null;
      }
      cov_8ito5tns2.s[30]++;
      try {
        cov_8ito5tns2.s[31]++;

        data.r = eval(data.r);
      } catch (ex) {
        cov_8ito5tns2.s[32]++;

        data.r = null;
      }
      cov_8ito5tns2.s[33]++;
      $scope.glovalValue = data;
    });
  };

  cov_8ito5tns2.s[34]++;
  $scope.getAllUser = function () {
    cov_8ito5tns2.f[6]++;
    cov_8ito5tns2.s[35]++;

    $scope.isloading = true;
    var userPayload = (cov_8ito5tns2.s[36]++, $scope.selectedProject.project.id ? (cov_8ito5tns2.b[5][0]++, { get: 'all', projet: [[$scope.selectedProject.project.id]] }) : (cov_8ito5tns2.b[5][1]++, { get: 'all' }));
    cov_8ito5tns2.s[37]++;
    $CRUDService.getAll(PATH, userPayload, function (data) {
      cov_8ito5tns2.f[7]++;
      cov_8ito5tns2.s[38]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var el = _step.value;
          cov_8ito5tns2.s[39]++;

          el.TYPEUSER = el.TYPEUSER == 1;
          cov_8ito5tns2.s[40]++;
          el.picture = $deltahttp.getUserPicture(el.id);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_8ito5tns2.s[41]++;
      $scope.listUser = data;
      cov_8ito5tns2.s[42]++;
      $scope.listUser.push({ id: 0 });
      cov_8ito5tns2.s[43]++;
      $scope.isloading = false;
      cov_8ito5tns2.s[44]++;
      $scope.planification.getAll();
      cov_8ito5tns2.s[45]++;
      $scope.realisation.getAll();
    });
  };
  cov_8ito5tns2.s[46]++;
  $scope.addStep = function (type) {
    cov_8ito5tns2.f[8]++;

    var workflow = (cov_8ito5tns2.s[47]++, (type == 5 ? (cov_8ito5tns2.b[6][0]++, $scope.planification) : (cov_8ito5tns2.b[6][1]++, $scope.realisation)).workflow);
    var id = (cov_8ito5tns2.s[48]++, workflow.id);
    var level = (cov_8ito5tns2.s[49]++, workflow.LIST_STEP.length + 1);
    cov_8ito5tns2.s[50]++;
    $scope.editStep({
      ID_WORKFLOW: id,
      NAME: '',
      COLOR: '0,0,0',
      id: 0,
      LEVEL: level,
      IDUSER: 0
    });
  };
  cov_8ito5tns2.s[51]++;
  $scope.deleteStep = function (item) {
    cov_8ito5tns2.f[9]++;
    cov_8ito5tns2.s[52]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_8ito5tns2.f[10]++;
      cov_8ito5tns2.s[53]++;

      if (isconfirm) {
        cov_8ito5tns2.b[7][0]++;
        cov_8ito5tns2.s[54]++;

        return;
      } else {
        cov_8ito5tns2.b[7][1]++;
      }
      cov_8ito5tns2.s[55]++;
      $scope.isloading = true;
      var validateItem = (cov_8ito5tns2.s[56]++, angular.copy(item));
      cov_8ito5tns2.s[57]++;
      $CRUDService.delet(PATHWORKFLOW, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_8ito5tns2.f[11]++;
        cov_8ito5tns2.s[58]++;

        if (data < 0) {
          cov_8ito5tns2.b[8][0]++;
          cov_8ito5tns2.s[59]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_8ito5tns2.s[60]++;
          $scope.isloading = false;
          cov_8ito5tns2.s[61]++;
          return;
        } else {
          cov_8ito5tns2.b[8][1]++;
        }
        var type = (cov_8ito5tns2.s[62]++, $scope.tab_workflow);
        cov_8ito5tns2.s[63]++;
        (type == 5 ? (cov_8ito5tns2.b[9][0]++, $scope.planification) : (cov_8ito5tns2.b[9][1]++, $scope.realisation)).getAll();
      });
    });
  };
  cov_8ito5tns2.s[64]++;
  $scope.editStep = function (item) {
    cov_8ito5tns2.f[12]++;
    cov_8ito5tns2.s[65]++;

    $scope.selectedStep = angular.copy(item);
    cov_8ito5tns2.s[66]++;
    $scope.listFilterUser = [];
    var type = (cov_8ito5tns2.s[67]++, $scope.tab_workflow);
    var listUser = (cov_8ito5tns2.s[68]++, (type == 5 ? (cov_8ito5tns2.b[10][0]++, $scope.planification) : (cov_8ito5tns2.b[10][1]++, $scope.realisation)).workflow.LIST_STEP);
    cov_8ito5tns2.s[69]++;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = $scope.listUser[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var user = _step2.value;

        var items = (cov_8ito5tns2.s[70]++, $filter('filter')(listUser, { IDUSER: user.id }, true));
        cov_8ito5tns2.s[71]++;
        if ((cov_8ito5tns2.b[12][0]++, items.length > 0) && (cov_8ito5tns2.b[12][1]++, items[0].id != item.id)) {
          cov_8ito5tns2.b[11][0]++;
          cov_8ito5tns2.s[72]++;

          continue;
        } else {
          cov_8ito5tns2.b[11][1]++;
        }
        cov_8ito5tns2.s[73]++;
        $scope.listFilterUser.push(user);
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    var u = (cov_8ito5tns2.s[74]++, $filter('filter')($scope.listUser, { id: $scope.selectedStep.IDUSER }, true));
    cov_8ito5tns2.s[75]++;
    if (u.length > 0) {
      cov_8ito5tns2.b[13][0]++;
      cov_8ito5tns2.s[76]++;

      $scope.selectedStep.IDUSER = u[0];
    } else {
      cov_8ito5tns2.b[13][1]++;
      cov_8ito5tns2.s[77]++;

      $scope.selectedStep.IDUSER = $scope.listUser[$scope.listUser.length - 1];
    }

    cov_8ito5tns2.s[78]++;
    $uibModal.open({
      templateUrl: 'app/views/indicator/workflow/edit.html',
      controller: indicatorsWorkflowCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_8ito5tns2.s[79]++;
  $scope.getStepList = function () {
    cov_8ito5tns2.f[13]++;

    var type = (cov_8ito5tns2.s[80]++, $scope.tab_workflow);
    cov_8ito5tns2.s[81]++;
    return ((cov_8ito5tns2.b[14][0]++, (type == 5 ? (cov_8ito5tns2.b[15][0]++, $scope.planification) : (cov_8ito5tns2.b[15][1]++, $scope.realisation)).workflow) || (cov_8ito5tns2.b[14][1]++, { LIST_STEP: [] })).LIST_STEP;
  };

  cov_8ito5tns2.s[82]++;
  $scope.planification = {
    loading: true,
    getAll: function getAll() {
      cov_8ito5tns2.s[83]++;

      this.loading = false;
      cov_8ito5tns2.s[84]++;
      $CRUDService.getAll(PATHWORKFLOW, { get: 'i_target', id: $scope.selectedItem.id }, function (data) {
        cov_8ito5tns2.f[14]++;
        cov_8ito5tns2.s[85]++;

        if (data == 'null') {
          cov_8ito5tns2.b[16][0]++;

          var workflow = (cov_8ito5tns2.s[86]++, {
            NAME: '',
            TYPE: '5',
            COLOR: '0, 0, 0',
            ID_REFERENCE: $scope.selectedItem.id
          });
          cov_8ito5tns2.s[87]++;
          $CRUDService.save(PATHWORKFLOW, { action: 'MiseAJour', valeur: angular.toJson(workflow) }, function (data_) {
            cov_8ito5tns2.f[15]++;
            cov_8ito5tns2.s[88]++;

            workflow.id = data_;
            cov_8ito5tns2.s[89]++;
            workflow.LIST_STEP = [];
            cov_8ito5tns2.s[90]++;
            $scope.planification.workflow = workflow;
            cov_8ito5tns2.s[91]++;
            $scope.planification.loading = false;
          });
        } else {
          cov_8ito5tns2.b[16][1]++;
          cov_8ito5tns2.s[92]++;

          $scope.planification.workflow = data;
          cov_8ito5tns2.s[93]++;
          $scope.planification.loading = false;
        }
      });
    }
  };

  cov_8ito5tns2.s[94]++;
  $scope.realisation = {
    loading: true,
    getAll: function getAll() {
      cov_8ito5tns2.s[95]++;

      this.loading = false;
      cov_8ito5tns2.s[96]++;
      $CRUDService.getAll(PATHWORKFLOW, { get: 'i_real', id: $scope.selectedItem.id }, function (data) {
        cov_8ito5tns2.f[16]++;
        cov_8ito5tns2.s[97]++;

        if (data == 'null') {
          cov_8ito5tns2.b[17][0]++;

          var workflow = (cov_8ito5tns2.s[98]++, {
            NAME: '',
            TYPE: '6',
            ID_REFERENCE: $scope.selectedItem.id
          });
          cov_8ito5tns2.s[99]++;
          $CRUDService.save(PATHWORKFLOW, { action: 'MiseAJour', valeur: angular.toJson(workflow) }, function (data_) {
            cov_8ito5tns2.f[17]++;
            cov_8ito5tns2.s[100]++;

            workflow.id = data_;
            cov_8ito5tns2.s[101]++;
            workflow.LIST_STEP = [];
            cov_8ito5tns2.s[102]++;
            $scope.realisation.workflow = workflow;
            cov_8ito5tns2.s[103]++;
            $scope.realisation.loading = false;
          });
        } else {
          cov_8ito5tns2.b[17][1]++;
          cov_8ito5tns2.s[104]++;

          $scope.realisation.workflow = data;
          cov_8ito5tns2.s[105]++;
          $scope.realisation.loading = false;
        }
      });
    }
  };

  cov_8ito5tns2.s[106]++;
  $scope.getAllUser();
});

function indicatorsWorkflowCtrl_edit($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_8ito5tns2.f[18]++;
  cov_8ito5tns2.s[107]++;

  $scope.error_code = false;

  // $scope.listFilterUser.push({id: 0});

  var PATH = (cov_8ito5tns2.s[108]++, 'Workflow');
  cov_8ito5tns2.s[109]++;
  $scope.setValidData = function () {
    cov_8ito5tns2.f[19]++;

    var validateItem = (cov_8ito5tns2.s[110]++, angular.copy($scope.selectedStep));
    cov_8ito5tns2.s[111]++;
    validateItem.IDUSER = validateItem.IDUSER.id;
    cov_8ito5tns2.s[112]++;
    $CRUDService.save(PATH, { action: 'MiseAJourStep', valeur: $filter('json')(validateItem) }, function () {
      cov_8ito5tns2.f[20]++;
      cov_8ito5tns2.s[113]++;

      $scope.closeModal();
      var type = (cov_8ito5tns2.s[114]++, $scope.tab_workflow);
      cov_8ito5tns2.s[115]++;
      (type == 5 ? (cov_8ito5tns2.b[18][0]++, $scope.planification) : (cov_8ito5tns2.b[18][1]++, $scope.realisation)).getAll();
    });
  };
  cov_8ito5tns2.s[116]++;
  $scope.closeModal = function () {
    cov_8ito5tns2.f[21]++;
    cov_8ito5tns2.s[117]++;

    $uibModalInstance.dismiss('cancel');
  };
}