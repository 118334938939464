'use strict';

var cov_1sbvw9n7g0 = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/TypeIndic/TypeIndicCtrl.js',
      hash = 'abe4c329d9f7d307958f308ae4eaa3d8f50e0517',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/TypeIndic/TypeIndicCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 1
        },
        end: {
          line: 125,
          column: 4
        }
      },
      '1': {
        start: {
          line: 7,
          column: 3
        },
        end: {
          line: 9,
          column: 4
        }
      },
      '2': {
        start: {
          line: 10,
          column: 3
        },
        end: {
          line: 10,
          column: 22
        }
      },
      '3': {
        start: {
          line: 11,
          column: 3
        },
        end: {
          line: 11,
          column: 23
        }
      },
      '4': {
        start: {
          line: 12,
          column: 3
        },
        end: {
          line: 12,
          column: 28
        }
      },
      '5': {
        start: {
          line: 13,
          column: 3
        },
        end: {
          line: 13,
          column: 27
        }
      },
      '6': {
        start: {
          line: 14,
          column: 3
        },
        end: {
          line: 14,
          column: 35
        }
      },
      '7': {
        start: {
          line: 15,
          column: 19
        },
        end: {
          line: 15,
          column: 49
        }
      },
      '8': {
        start: {
          line: 16,
          column: 3
        },
        end: {
          line: 16,
          column: 46
        }
      },
      '9': {
        start: {
          line: 18,
          column: 3
        },
        end: {
          line: 22,
          column: 5
        }
      },
      '10': {
        start: {
          line: 19,
          column: 5
        },
        end: {
          line: 19,
          column: 23
        }
      },
      '11': {
        start: {
          line: 20,
          column: 5
        },
        end: {
          line: 20,
          column: 25
        }
      },
      '12': {
        start: {
          line: 21,
          column: 5
        },
        end: {
          line: 21,
          column: 22
        }
      },
      '13': {
        start: {
          line: 24,
          column: 3
        },
        end: {
          line: 39,
          column: 5
        }
      },
      '14': {
        start: {
          line: 25,
          column: 5
        },
        end: {
          line: 25,
          column: 24
        }
      },
      '15': {
        start: {
          line: 26,
          column: 5
        },
        end: {
          line: 33,
          column: 6
        }
      },
      '16': {
        start: {
          line: 27,
          column: 7
        },
        end: {
          line: 31,
          column: 9
        }
      },
      '17': {
        start: {
          line: 32,
          column: 7
        },
        end: {
          line: 32,
          column: 54
        }
      },
      '18': {
        start: {
          line: 34,
          column: 5
        },
        end: {
          line: 38,
          column: 7
        }
      },
      '19': {
        start: {
          line: 41,
          column: 3
        },
        end: {
          line: 45,
          column: 5
        }
      },
      '20': {
        start: {
          line: 42,
          column: 5
        },
        end: {
          line: 42,
          column: 30
        }
      },
      '21': {
        start: {
          line: 43,
          column: 5
        },
        end: {
          line: 43,
          column: 32
        }
      },
      '22': {
        start: {
          line: 44,
          column: 5
        },
        end: {
          line: 44,
          column: 47
        }
      },
      '23': {
        start: {
          line: 46,
          column: 3
        },
        end: {
          line: 73,
          column: 5
        }
      },
      '24': {
        start: {
          line: 47,
          column: 5
        },
        end: {
          line: 47,
          column: 24
        }
      },
      '25': {
        start: {
          line: 48,
          column: 26
        },
        end: {
          line: 52,
          column: 14
        }
      },
      '26': {
        start: {
          line: 54,
          column: 17
        },
        end: {
          line: 56,
          column: 7
        }
      },
      '27': {
        start: {
          line: 55,
          column: 7
        },
        end: {
          line: 55,
          column: 115
        }
      },
      '28': {
        start: {
          line: 58,
          column: 5
        },
        end: {
          line: 61,
          column: 6
        }
      },
      '29': {
        start: {
          line: 59,
          column: 7
        },
        end: {
          line: 59,
          column: 25
        }
      },
      '30': {
        start: {
          line: 60,
          column: 7
        },
        end: {
          line: 60,
          column: 14
        }
      },
      '31': {
        start: {
          line: 63,
          column: 5
        },
        end: {
          line: 63,
          column: 31
        }
      },
      '32': {
        start: {
          line: 64,
          column: 5
        },
        end: {
          line: 64,
          column: 29
        }
      },
      '33': {
        start: {
          line: 65,
          column: 5
        },
        end: {
          line: 72,
          column: 8
        }
      },
      '34': {
        start: {
          line: 66,
          column: 7
        },
        end: {
          line: 70,
          column: 8
        }
      },
      '35': {
        start: {
          line: 67,
          column: 25
        },
        end: {
          line: 67,
          column: 44
        }
      },
      '36': {
        start: {
          line: 68,
          column: 9
        },
        end: {
          line: 68,
          column: 32
        }
      },
      '37': {
        start: {
          line: 69,
          column: 9
        },
        end: {
          line: 69,
          column: 61
        }
      },
      '38': {
        start: {
          line: 71,
          column: 7
        },
        end: {
          line: 71,
          column: 26
        }
      },
      '39': {
        start: {
          line: 75,
          column: 3
        },
        end: {
          line: 111,
          column: 5
        }
      },
      '40': {
        start: {
          line: 76,
          column: 5
        },
        end: {
          line: 110,
          column: 8
        }
      },
      '41': {
        start: {
          line: 88,
          column: 7
        },
        end: {
          line: 90,
          column: 8
        }
      },
      '42': {
        start: {
          line: 89,
          column: 9
        },
        end: {
          line: 89,
          column: 16
        }
      },
      '43': {
        start: {
          line: 91,
          column: 7
        },
        end: {
          line: 91,
          column: 31
        }
      },
      '44': {
        start: {
          line: 92,
          column: 28
        },
        end: {
          line: 97,
          column: 8
        }
      },
      '45': {
        start: {
          line: 98,
          column: 7
        },
        end: {
          line: 109,
          column: 10
        }
      },
      '46': {
        start: {
          line: 99,
          column: 9
        },
        end: {
          line: 106,
          column: 10
        }
      },
      '47': {
        start: {
          line: 100,
          column: 11
        },
        end: {
          line: 103,
          column: 14
        }
      },
      '48': {
        start: {
          line: 104,
          column: 11
        },
        end: {
          line: 104,
          column: 36
        }
      },
      '49': {
        start: {
          line: 105,
          column: 11
        },
        end: {
          line: 105,
          column: 18
        }
      },
      '50': {
        start: {
          line: 107,
          column: 9
        },
        end: {
          line: 107,
          column: 22
        }
      },
      '51': {
        start: {
          line: 108,
          column: 9
        },
        end: {
          line: 108,
          column: 28
        }
      },
      '52': {
        start: {
          line: 112,
          column: 3
        },
        end: {
          line: 114,
          column: 5
        }
      },
      '53': {
        start: {
          line: 115,
          column: 3
        },
        end: {
          line: 124,
          column: 3
        }
      },
      '54': {
        start: {
          line: 116,
          column: 15
        },
        end: {
          line: 116,
          column: 50
        }
      },
      '55': {
        start: {
          line: 118,
          column: 4
        },
        end: {
          line: 121,
          column: 5
        }
      },
      '56': {
        start: {
          line: 119,
          column: 6
        },
        end: {
          line: 119,
          column: 130
        }
      },
      '57': {
        start: {
          line: 123,
          column: 4
        },
        end: {
          line: 123,
          column: 15
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 51
          },
          end: {
            line: 2,
            column: 52
          }
        },
        loc: {
          start: {
            line: 2,
            column: 186
          },
          end: {
            line: 125,
            column: 2
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 18,
            column: 19
          },
          end: {
            line: 18,
            column: 20
          }
        },
        loc: {
          start: {
            line: 18,
            column: 31
          },
          end: {
            line: 22,
            column: 4
          }
        },
        line: 18
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 24,
            column: 20
          },
          end: {
            line: 24,
            column: 21
          }
        },
        loc: {
          start: {
            line: 24,
            column: 36
          },
          end: {
            line: 39,
            column: 4
          }
        },
        line: 24
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 41,
            column: 22
          },
          end: {
            line: 41,
            column: 23
          }
        },
        loc: {
          start: {
            line: 41,
            column: 34
          },
          end: {
            line: 45,
            column: 4
          }
        },
        line: 41
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 46,
            column: 25
          },
          end: {
            line: 46,
            column: 26
          }
        },
        loc: {
          start: {
            line: 46,
            column: 42
          },
          end: {
            line: 73,
            column: 4
          }
        },
        line: 46
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 54,
            column: 66
          },
          end: {
            line: 54,
            column: 67
          }
        },
        loc: {
          start: {
            line: 54,
            column: 74
          },
          end: {
            line: 56,
            column: 6
          }
        },
        line: 54
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 65,
            column: 98
          },
          end: {
            line: 65,
            column: 99
          }
        },
        loc: {
          start: {
            line: 65,
            column: 106
          },
          end: {
            line: 72,
            column: 6
          }
        },
        line: 65
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 75,
            column: 26
          },
          end: {
            line: 75,
            column: 27
          }
        },
        loc: {
          start: {
            line: 75,
            column: 42
          },
          end: {
            line: 111,
            column: 4
          }
        },
        line: 75
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 87,
            column: 8
          },
          end: {
            line: 87,
            column: 9
          }
        },
        loc: {
          start: {
            line: 87,
            column: 21
          },
          end: {
            line: 110,
            column: 6
          }
        },
        line: 87
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 98,
            column: 96
          },
          end: {
            line: 98,
            column: 97
          }
        },
        loc: {
          start: {
            line: 98,
            column: 104
          },
          end: {
            line: 109,
            column: 8
          }
        },
        line: 98
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 115,
            column: 45
          },
          end: {
            line: 115,
            column: 46
          }
        },
        loc: {
          start: {
            line: 115,
            column: 57
          },
          end: {
            line: 124,
            column: 3
          }
        },
        line: 115
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 26,
            column: 5
          },
          end: {
            line: 33,
            column: 6
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 26,
            column: 5
          },
          end: {
            line: 33,
            column: 6
          }
        }, {
          start: {
            line: 26,
            column: 5
          },
          end: {
            line: 33,
            column: 6
          }
        }],
        line: 26
      },
      '1': {
        loc: {
          start: {
            line: 51,
            column: 11
          },
          end: {
            line: 51,
            column: 66
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 51,
            column: 40
          },
          end: {
            line: 51,
            column: 62
          }
        }, {
          start: {
            line: 51,
            column: 65
          },
          end: {
            line: 51,
            column: 66
          }
        }],
        line: 51
      },
      '2': {
        loc: {
          start: {
            line: 54,
            column: 18
          },
          end: {
            line: 54,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 54,
            column: 18
          },
          end: {
            line: 54,
            column: 53
          }
        }, {
          start: {
            line: 54,
            column: 57
          },
          end: {
            line: 54,
            column: 59
          }
        }],
        line: 54
      },
      '3': {
        loc: {
          start: {
            line: 55,
            column: 14
          },
          end: {
            line: 55,
            column: 114
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 55,
            column: 14
          },
          end: {
            line: 55,
            column: 40
          }
        }, {
          start: {
            line: 55,
            column: 44
          },
          end: {
            line: 55,
            column: 114
          }
        }],
        line: 55
      },
      '4': {
        loc: {
          start: {
            line: 58,
            column: 5
          },
          end: {
            line: 61,
            column: 6
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 58,
            column: 5
          },
          end: {
            line: 61,
            column: 6
          }
        }, {
          start: {
            line: 58,
            column: 5
          },
          end: {
            line: 61,
            column: 6
          }
        }],
        line: 58
      },
      '5': {
        loc: {
          start: {
            line: 66,
            column: 7
          },
          end: {
            line: 70,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 66,
            column: 7
          },
          end: {
            line: 70,
            column: 8
          }
        }, {
          start: {
            line: 66,
            column: 7
          },
          end: {
            line: 70,
            column: 8
          }
        }],
        line: 66
      },
      '6': {
        loc: {
          start: {
            line: 88,
            column: 7
          },
          end: {
            line: 90,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 88,
            column: 7
          },
          end: {
            line: 90,
            column: 8
          }
        }, {
          start: {
            line: 88,
            column: 7
          },
          end: {
            line: 90,
            column: 8
          }
        }],
        line: 88
      },
      '7': {
        loc: {
          start: {
            line: 99,
            column: 9
          },
          end: {
            line: 106,
            column: 10
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 99,
            column: 9
          },
          end: {
            line: 106,
            column: 10
          }
        }, {
          start: {
            line: 99,
            column: 9
          },
          end: {
            line: 106,
            column: 10
          }
        }],
        line: 99
      },
      '8': {
        loc: {
          start: {
            line: 118,
            column: 4
          },
          end: {
            line: 121,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 118,
            column: 4
          },
          end: {
            line: 121,
            column: 5
          }
        }, {
          start: {
            line: 118,
            column: 4
          },
          end: {
            line: 121,
            column: 5
          }
        }],
        line: 118
      },
      '9': {
        loc: {
          start: {
            line: 118,
            column: 8
          },
          end: {
            line: 118,
            column: 82
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 118,
            column: 8
          },
          end: {
            line: 118,
            column: 40
          }
        }, {
          start: {
            line: 118,
            column: 44
          },
          end: {
            line: 118,
            column: 82
          }
        }],
        line: 118
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1sbvw9n7g0.s[0]++;

angular.module('app').controller('TypeIndicCtrl', function ($scope, SweetAlert, $deltadate, $deltaTypeIndicateur, $translate, $CRUDService, $otherHttpService, $location, $filter, $rootScope) {
  cov_1sbvw9n7g0.f[0]++;
  cov_1sbvw9n7g0.s[1]++;

  /* $rootScope.processPageRight('3_1');
  if ($rootScope.global_access_page_denied) {
    return;
  } */
  $scope.typeIndicateur = {
    searchText: ''
  };
  cov_1sbvw9n7g0.s[2]++;
  $scope.add = false;
  cov_1sbvw9n7g0.s[3]++;
  $scope.edit = false;
  cov_1sbvw9n7g0.s[4]++;
  $scope.btnAction = false;
  cov_1sbvw9n7g0.s[5]++;
  $scope.MyDataListe = [];
  cov_1sbvw9n7g0.s[6]++;
  $scope.PATH = 'type_indicateur';
  var project = (cov_1sbvw9n7g0.s[7]++, $rootScope.getCurrentProject());
  cov_1sbvw9n7g0.s[8]++;
  $deltaTypeIndicateur.addController($scope);

  cov_1sbvw9n7g0.s[9]++;
  $scope.setAdd = function () {
    cov_1sbvw9n7g0.f[1]++;
    cov_1sbvw9n7g0.s[10]++;

    $scope.add = true;
    cov_1sbvw9n7g0.s[11]++;
    $scope.edit = false;
    cov_1sbvw9n7g0.s[12]++;
    $scope.setEdit();
  };

  cov_1sbvw9n7g0.s[13]++;
  $scope.setEdit = function (item) {
    cov_1sbvw9n7g0.f[2]++;
    cov_1sbvw9n7g0.s[14]++;

    $scope.dbl = false;
    cov_1sbvw9n7g0.s[15]++;
    if (!item) {
      cov_1sbvw9n7g0.b[0][0]++;
      cov_1sbvw9n7g0.s[16]++;

      item = {
        id: -1,
        LIBELLE_TI: '',
        NUM_COULEUR_INDIC: '32,32,32'
      };
      cov_1sbvw9n7g0.s[17]++;
      $scope.listData_TypeIndicateur_bksb.push(item);
    } else {
      cov_1sbvw9n7g0.b[0][1]++;
    }
    cov_1sbvw9n7g0.s[18]++;
    $scope.validateItem = {
      LIBELLE_TI: item.LIBELLE_TI,
      NUM_COULEUR_INDIC: item.NUM_COULEUR_INDIC,
      id: item.id
    };
  };

  cov_1sbvw9n7g0.s[19]++;
  $scope.setCancel = function () {
    cov_1sbvw9n7g0.f[3]++;
    cov_1sbvw9n7g0.s[20]++;

    $scope.isloading = false;
    cov_1sbvw9n7g0.s[21]++;
    $scope.validateItem = null;
    cov_1sbvw9n7g0.s[22]++;
    $deltaTypeIndicateur.removeData({ id: -1 });
  };
  cov_1sbvw9n7g0.s[23]++;
  $scope.setValidData = function (right) {
    cov_1sbvw9n7g0.f[4]++;
    cov_1sbvw9n7g0.s[24]++;

    $scope.dbl = false;
    var validateItem = (cov_1sbvw9n7g0.s[25]++, Object.assign({
      LIBELLE_TI: $scope.validateItem.LIBELLE_TI.trim(),
      NUM_COULEUR_INDIC: $scope.validateItem.NUM_COULEUR_INDIC,
      id: $scope.validateItem.id > 0 ? (cov_1sbvw9n7g0.b[1][0]++, $scope.validateItem.id) : (cov_1sbvw9n7g0.b[1][1]++, 0)
    }, right));

    var dbl = (cov_1sbvw9n7g0.s[26]++, ((cov_1sbvw9n7g0.b[2][0]++, $scope.listData_TypeIndicateur_bksb) || (cov_1sbvw9n7g0.b[2][1]++, [])).find(function (type) {
      cov_1sbvw9n7g0.f[5]++;
      cov_1sbvw9n7g0.s[27]++;

      return (cov_1sbvw9n7g0.b[3][0]++, type.id != validateItem.id) && (cov_1sbvw9n7g0.b[3][1]++, validateItem.LIBELLE_TI.toUpperCase() == type.LIBELLE_TI.toUpperCase());
    }));

    cov_1sbvw9n7g0.s[28]++;
    if (dbl) {
      cov_1sbvw9n7g0.b[4][0]++;
      cov_1sbvw9n7g0.s[29]++;

      $scope.dbl = true;
      cov_1sbvw9n7g0.s[30]++;
      return;
    } else {
      cov_1sbvw9n7g0.b[4][1]++;
    }

    cov_1sbvw9n7g0.s[31]++;
    $scope.error_code = false;
    cov_1sbvw9n7g0.s[32]++;
    $scope.isloading = true;
    cov_1sbvw9n7g0.s[33]++;
    $CRUDService.save($scope.PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function (data) {
      cov_1sbvw9n7g0.f[6]++;
      cov_1sbvw9n7g0.s[34]++;

      if (data > 0) {
        cov_1sbvw9n7g0.b[5][0]++;

        var newData = (cov_1sbvw9n7g0.s[35]++, validateItem.id > 0);
        cov_1sbvw9n7g0.s[36]++;
        validateItem.id = data;
        cov_1sbvw9n7g0.s[37]++;
        $deltaTypeIndicateur.addData(validateItem, newData);
      } else {
        cov_1sbvw9n7g0.b[5][1]++;
      }
      cov_1sbvw9n7g0.s[38]++;
      $scope.setCancel();
    });
  };

  cov_1sbvw9n7g0.s[39]++;
  $scope.setDeleteData = function (item) {
    cov_1sbvw9n7g0.f[7]++;
    cov_1sbvw9n7g0.s[40]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_TYPINDIC.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1sbvw9n7g0.f[8]++;
      cov_1sbvw9n7g0.s[41]++;

      if (isconfirm) {
        cov_1sbvw9n7g0.b[6][0]++;
        cov_1sbvw9n7g0.s[42]++;

        return;
      } else {
        cov_1sbvw9n7g0.b[6][1]++;
      }
      cov_1sbvw9n7g0.s[43]++;
      $scope.isloading = true;
      var validateItem = (cov_1sbvw9n7g0.s[44]++, {
        LIBELLE_TI: item.LIBELLE_TI,
        ID_PROJET: item.ID_PROJET,
        NUM_COULEUR_INDIC: item.NUM_COULEUR_INDIC,
        id: item.id
      });
      cov_1sbvw9n7g0.s[45]++;
      $CRUDService.delet($scope.PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_1sbvw9n7g0.f[9]++;
        cov_1sbvw9n7g0.s[46]++;

        if (data < 0) {
          cov_1sbvw9n7g0.b[7][0]++;
          cov_1sbvw9n7g0.s[47]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_1sbvw9n7g0.s[48]++;
          $scope.isloading = false;
          cov_1sbvw9n7g0.s[49]++;
          return;
        } else {
          cov_1sbvw9n7g0.b[7][1]++;
        }
        cov_1sbvw9n7g0.s[50]++;
        item.id = -1;
        cov_1sbvw9n7g0.s[51]++;
        $scope.setCancel();
      });
    });
  };
  cov_1sbvw9n7g0.s[52]++;
  $scope.sortableOptions = {
    connectWith: '.connectList'
  };
  cov_1sbvw9n7g0.s[53]++;
  $scope.filterListDataTypeIndicateurBksb = function () {
    cov_1sbvw9n7g0.f[10]++;

    var data = (cov_1sbvw9n7g0.s[54]++, $scope.listData_TypeIndicateur_bksb);

    cov_1sbvw9n7g0.s[55]++;
    if ((cov_1sbvw9n7g0.b[9][0]++, $scope.typeIndicateur.searchText) && (cov_1sbvw9n7g0.b[9][1]++, $scope.typeIndicateur.searchText != '')) {
      cov_1sbvw9n7g0.b[8][0]++;
      cov_1sbvw9n7g0.s[56]++;

      data = $filter('filter')($scope.listData_TypeIndicateur_bksb, { LIBELLE_TI: $scope.typeIndicateur.searchText.toLowerCase() });
    } else {
      cov_1sbvw9n7g0.b[8][1]++;
    }

    cov_1sbvw9n7g0.s[57]++;
    return data;
  };
});