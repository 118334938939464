'use strict';

var cov_xvhl18uw = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/analytics/custom_report/analyticsCustomReportCtrl.js',
      hash = '42d54a1f39ab0c7edf3fdebc04a38f150b27077c',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/analytics/custom_report/analyticsCustomReportCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 1,
          column: 76
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 62
          },
          end: {
            line: 1,
            column: 63
          }
        },
        loc: {
          start: {
            line: 1,
            column: 72
          },
          end: {
            line: 1,
            column: 74
          }
        },
        line: 1
      }
    },
    branchMap: {},
    s: {
      '0': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_xvhl18uw.s[0]++;
angular.module('app').controller('analyticsCustomReportCtrl', function ($scope) {
  cov_xvhl18uw.f[0]++;
});