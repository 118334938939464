'use strict';

var cov_1g1hrro9qp = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/dashboard/dashboardCtrl.js',
      hash = '330455fc218024ae11be40eae107de31143c7a28',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/dashboard/dashboardCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 1559,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 3,
          column: 27
        }
      },
      '2': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 43
        }
      },
      '3': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 10,
          column: 5
        }
      },
      '4': {
        start: {
          line: 8,
          column: 4
        },
        end: {
          line: 8,
          column: 49
        }
      },
      '5': {
        start: {
          line: 9,
          column: 4
        },
        end: {
          line: 9,
          column: 37
        }
      },
      '6': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 25
        }
      },
      '7': {
        start: {
          line: 13,
          column: 24
        },
        end: {
          line: 13,
          column: 34
        }
      },
      '8': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 17
        }
      },
      '9': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 47
        }
      },
      '10': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 16,
          column: 49
        }
      },
      '11': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 22,
          column: 4
        }
      },
      '12': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 21,
          column: 7
        }
      },
      '13': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 48
        }
      },
      '14': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 57
        }
      },
      '15': {
        start: {
          line: 24,
          column: 38
        },
        end: {
          line: 24,
          column: 107
        }
      },
      '16': {
        start: {
          line: 25,
          column: 45
        },
        end: {
          line: 25,
          column: 121
        }
      },
      '17': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 27,
          column: 55
        }
      },
      '18': {
        start: {
          line: 28,
          column: 2
        },
        end: {
          line: 28,
          column: 63
        }
      },
      '19': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 30,
          column: 39
        }
      },
      '20': {
        start: {
          line: 31,
          column: 2
        },
        end: {
          line: 31,
          column: 42
        }
      },
      '21': {
        start: {
          line: 32,
          column: 2
        },
        end: {
          line: 32,
          column: 47
        }
      },
      '22': {
        start: {
          line: 33,
          column: 2
        },
        end: {
          line: 33,
          column: 43
        }
      },
      '23': {
        start: {
          line: 35,
          column: 24
        },
        end: {
          line: 41,
          column: 3
        }
      },
      '24': {
        start: {
          line: 43,
          column: 2
        },
        end: {
          line: 55,
          column: 4
        }
      },
      '25': {
        start: {
          line: 57,
          column: 2
        },
        end: {
          line: 57,
          column: 28
        }
      },
      '26': {
        start: {
          line: 58,
          column: 23
        },
        end: {
          line: 58,
          column: 32
        }
      },
      '27': {
        start: {
          line: 59,
          column: 2
        },
        end: {
          line: 59,
          column: 26
        }
      },
      '28': {
        start: {
          line: 62,
          column: 2
        },
        end: {
          line: 62,
          column: 34
        }
      },
      '29': {
        start: {
          line: 63,
          column: 2
        },
        end: {
          line: 63,
          column: 37
        }
      },
      '30': {
        start: {
          line: 64,
          column: 2
        },
        end: {
          line: 71,
          column: 5
        }
      },
      '31': {
        start: {
          line: 65,
          column: 4
        },
        end: {
          line: 70,
          column: 6
        }
      },
      '32': {
        start: {
          line: 68,
          column: 51
        },
        end: {
          line: 68,
          column: 58
        }
      },
      '33': {
        start: {
          line: 74,
          column: 2
        },
        end: {
          line: 88,
          column: 5
        }
      },
      '34': {
        start: {
          line: 76,
          column: 18
        },
        end: {
          line: 76,
          column: 61
        }
      },
      '35': {
        start: {
          line: 77,
          column: 4
        },
        end: {
          line: 80,
          column: 5
        }
      },
      '36': {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 78,
          column: 53
        }
      },
      '37': {
        start: {
          line: 79,
          column: 6
        },
        end: {
          line: 79,
          column: 29
        }
      },
      '38': {
        start: {
          line: 82,
          column: 4
        },
        end: {
          line: 86,
          column: 5
        }
      },
      '39': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 85,
          column: 10
        }
      },
      '40': {
        start: {
          line: 84,
          column: 8
        },
        end: {
          line: 84,
          column: 33
        }
      },
      '41': {
        start: {
          line: 87,
          column: 4
        },
        end: {
          line: 87,
          column: 25
        }
      },
      '42': {
        start: {
          line: 91,
          column: 2
        },
        end: {
          line: 101,
          column: 5
        }
      },
      '43': {
        start: {
          line: 93,
          column: 18
        },
        end: {
          line: 93,
          column: 61
        }
      },
      '44': {
        start: {
          line: 94,
          column: 4
        },
        end: {
          line: 98,
          column: 5
        }
      },
      '45': {
        start: {
          line: 95,
          column: 6
        },
        end: {
          line: 95,
          column: 26
        }
      },
      '46': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 96,
          column: 47
        }
      },
      '47': {
        start: {
          line: 97,
          column: 6
        },
        end: {
          line: 97,
          column: 29
        }
      },
      '48': {
        start: {
          line: 99,
          column: 4
        },
        end: {
          line: 99,
          column: 24
        }
      },
      '49': {
        start: {
          line: 100,
          column: 4
        },
        end: {
          line: 100,
          column: 33
        }
      },
      '50': {
        start: {
          line: 104,
          column: 2
        },
        end: {
          line: 112,
          column: 4
        }
      },
      '51': {
        start: {
          line: 105,
          column: 4
        },
        end: {
          line: 105,
          column: 48
        }
      },
      '52': {
        start: {
          line: 106,
          column: 4
        },
        end: {
          line: 106,
          column: 50
        }
      },
      '53': {
        start: {
          line: 107,
          column: 4
        },
        end: {
          line: 110,
          column: 5
        }
      },
      '54': {
        start: {
          line: 108,
          column: 6
        },
        end: {
          line: 108,
          column: 111
        }
      },
      '55': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 109,
          column: 13
        }
      },
      '56': {
        start: {
          line: 111,
          column: 4
        },
        end: {
          line: 111,
          column: 109
        }
      },
      '57': {
        start: {
          line: 114,
          column: 2
        },
        end: {
          line: 116,
          column: 5
        }
      },
      '58': {
        start: {
          line: 115,
          column: 4
        },
        end: {
          line: 115,
          column: 32
        }
      },
      '59': {
        start: {
          line: 117,
          column: 2
        },
        end: {
          line: 119,
          column: 4
        }
      },
      '60': {
        start: {
          line: 118,
          column: 4
        },
        end: {
          line: 118,
          column: 23
        }
      },
      '61': {
        start: {
          line: 120,
          column: 15
        },
        end: {
          line: 120,
          column: 28
        }
      },
      '62': {
        start: {
          line: 121,
          column: 19
        },
        end: {
          line: 121,
          column: 35
        }
      },
      '63': {
        start: {
          line: 122,
          column: 2
        },
        end: {
          line: 122,
          column: 45
        }
      },
      '64': {
        start: {
          line: 123,
          column: 2
        },
        end: {
          line: 140,
          column: 4
        }
      },
      '65': {
        start: {
          line: 124,
          column: 4
        },
        end: {
          line: 126,
          column: 5
        }
      },
      '66': {
        start: {
          line: 125,
          column: 6
        },
        end: {
          line: 125,
          column: 21
        }
      },
      '67': {
        start: {
          line: 127,
          column: 4
        },
        end: {
          line: 129,
          column: 5
        }
      },
      '68': {
        start: {
          line: 128,
          column: 6
        },
        end: {
          line: 128,
          column: 21
        }
      },
      '69': {
        start: {
          line: 130,
          column: 4
        },
        end: {
          line: 132,
          column: 5
        }
      },
      '70': {
        start: {
          line: 131,
          column: 6
        },
        end: {
          line: 131,
          column: 22
        }
      },
      '71': {
        start: {
          line: 133,
          column: 4
        },
        end: {
          line: 135,
          column: 5
        }
      },
      '72': {
        start: {
          line: 134,
          column: 6
        },
        end: {
          line: 134,
          column: 22
        }
      },
      '73': {
        start: {
          line: 136,
          column: 4
        },
        end: {
          line: 138,
          column: 5
        }
      },
      '74': {
        start: {
          line: 137,
          column: 6
        },
        end: {
          line: 137,
          column: 22
        }
      },
      '75': {
        start: {
          line: 139,
          column: 4
        },
        end: {
          line: 139,
          column: 19
        }
      },
      '76': {
        start: {
          line: 141,
          column: 2
        },
        end: {
          line: 150,
          column: 4
        }
      },
      '77': {
        start: {
          line: 146,
          column: 6
        },
        end: {
          line: 146,
          column: 29
        }
      },
      '78': {
        start: {
          line: 147,
          column: 6
        },
        end: {
          line: 147,
          column: 44
        }
      },
      '79': {
        start: {
          line: 148,
          column: 6
        },
        end: {
          line: 148,
          column: 72
        }
      },
      '80': {
        start: {
          line: 152,
          column: 18
        },
        end: {
          line: 152,
          column: 48
        }
      },
      '81': {
        start: {
          line: 153,
          column: 2
        },
        end: {
          line: 153,
          column: 39
        }
      },
      '82': {
        start: {
          line: 155,
          column: 2
        },
        end: {
          line: 155,
          column: 140
        }
      },
      '83': {
        start: {
          line: 157,
          column: 2
        },
        end: {
          line: 428,
          column: 4
        }
      },
      '84': {
        start: {
          line: 162,
          column: 6
        },
        end: {
          line: 171,
          column: 7
        }
      },
      '85': {
        start: {
          line: 163,
          column: 8
        },
        end: {
          line: 170,
          column: 11
        }
      },
      '86': {
        start: {
          line: 164,
          column: 10
        },
        end: {
          line: 164,
          column: 33
        }
      },
      '87': {
        start: {
          line: 165,
          column: 10
        },
        end: {
          line: 169,
          column: 11
        }
      },
      '88': {
        start: {
          line: 166,
          column: 12
        },
        end: {
          line: 166,
          column: 55
        }
      },
      '89': {
        start: {
          line: 167,
          column: 12
        },
        end: {
          line: 167,
          column: 87
        }
      },
      '90': {
        start: {
          line: 168,
          column: 12
        },
        end: {
          line: 168,
          column: 141
        }
      },
      '91': {
        start: {
          line: 174,
          column: 6
        },
        end: {
          line: 174,
          column: 40
        }
      },
      '92': {
        start: {
          line: 175,
          column: 6
        },
        end: {
          line: 233,
          column: 9
        }
      },
      '93': {
        start: {
          line: 176,
          column: 8
        },
        end: {
          line: 178,
          column: 9
        }
      },
      '94': {
        start: {
          line: 177,
          column: 10
        },
        end: {
          line: 177,
          column: 17
        }
      },
      '95': {
        start: {
          line: 179,
          column: 21
        },
        end: {
          line: 179,
          column: 28
        }
      },
      '96': {
        start: {
          line: 180,
          column: 26
        },
        end: {
          line: 180,
          column: 33
        }
      },
      '97': {
        start: {
          line: 181,
          column: 8
        },
        end: {
          line: 183,
          column: 9
        }
      },
      '98': {
        start: {
          line: 182,
          column: 10
        },
        end: {
          line: 182,
          column: 17
        }
      },
      '99': {
        start: {
          line: 184,
          column: 23
        },
        end: {
          line: 184,
          column: 25
        }
      },
      '100': {
        start: {
          line: 185,
          column: 8
        },
        end: {
          line: 193,
          column: 9
        }
      },
      '101': {
        start: {
          line: 186,
          column: 10
        },
        end: {
          line: 192,
          column: 11
        }
      },
      '102': {
        start: {
          line: 187,
          column: 27
        },
        end: {
          line: 187,
          column: 64
        }
      },
      '103': {
        start: {
          line: 188,
          column: 12
        },
        end: {
          line: 190,
          column: 13
        }
      },
      '104': {
        start: {
          line: 189,
          column: 14
        },
        end: {
          line: 189,
          column: 23
        }
      },
      '105': {
        start: {
          line: 191,
          column: 12
        },
        end: {
          line: 191,
          column: 30
        }
      },
      '106': {
        start: {
          line: 194,
          column: 26
        },
        end: {
          line: 194,
          column: 28
        }
      },
      '107': {
        start: {
          line: 196,
          column: 8
        },
        end: {
          line: 223,
          column: 9
        }
      },
      '108': {
        start: {
          line: 197,
          column: 25
        },
        end: {
          line: 207,
          column: 11
        }
      },
      '109': {
        start: {
          line: 208,
          column: 26
        },
        end: {
          line: 208,
          column: 28
        }
      },
      '110': {
        start: {
          line: 209,
          column: 10
        },
        end: {
          line: 215,
          column: 11
        }
      },
      '111': {
        start: {
          line: 210,
          column: 27
        },
        end: {
          line: 210,
          column: 39
        }
      },
      '112': {
        start: {
          line: 211,
          column: 12
        },
        end: {
          line: 213,
          column: 13
        }
      },
      '113': {
        start: {
          line: 212,
          column: 14
        },
        end: {
          line: 212,
          column: 23
        }
      },
      '114': {
        start: {
          line: 214,
          column: 12
        },
        end: {
          line: 214,
          column: 31
        }
      },
      '115': {
        start: {
          line: 216,
          column: 10
        },
        end: {
          line: 221,
          column: 11
        }
      },
      '116': {
        start: {
          line: 217,
          column: 12
        },
        end: {
          line: 220,
          column: 15
        }
      },
      '117': {
        start: {
          line: 222,
          column: 10
        },
        end: {
          line: 222,
          column: 33
        }
      },
      '118': {
        start: {
          line: 225,
          column: 8
        },
        end: {
          line: 225,
          column: 51
        }
      },
      '119': {
        start: {
          line: 226,
          column: 26
        },
        end: {
          line: 226,
          column: 80
        }
      },
      '120': {
        start: {
          line: 228,
          column: 8
        },
        end: {
          line: 228,
          column: 84
        }
      },
      '121': {
        start: {
          line: 230,
          column: 32
        },
        end: {
          line: 230,
          column: 88
        }
      },
      '122': {
        start: {
          line: 230,
          column: 61
        },
        end: {
          line: 230,
          column: 86
        }
      },
      '123': {
        start: {
          line: 232,
          column: 8
        },
        end: {
          line: 232,
          column: 84
        }
      },
      '124': {
        start: {
          line: 237,
          column: 6
        },
        end: {
          line: 239,
          column: 7
        }
      },
      '125': {
        start: {
          line: 238,
          column: 8
        },
        end: {
          line: 238,
          column: 15
        }
      },
      '126': {
        start: {
          line: 241,
          column: 6
        },
        end: {
          line: 245,
          column: 7
        }
      },
      '127': {
        start: {
          line: 242,
          column: 8
        },
        end: {
          line: 242,
          column: 33
        }
      },
      '128': {
        start: {
          line: 244,
          column: 8
        },
        end: {
          line: 244,
          column: 33
        }
      },
      '129': {
        start: {
          line: 247,
          column: 6
        },
        end: {
          line: 249,
          column: 7
        }
      },
      '130': {
        start: {
          line: 248,
          column: 8
        },
        end: {
          line: 248,
          column: 24
        }
      },
      '131': {
        start: {
          line: 251,
          column: 6
        },
        end: {
          line: 251,
          column: 78
        }
      },
      '132': {
        start: {
          line: 253,
          column: 21
        },
        end: {
          line: 253,
          column: 131
        }
      },
      '133': {
        start: {
          line: 254,
          column: 6
        },
        end: {
          line: 256,
          column: 7
        }
      },
      '134': {
        start: {
          line: 255,
          column: 8
        },
        end: {
          line: 255,
          column: 44
        }
      },
      '135': {
        start: {
          line: 257,
          column: 6
        },
        end: {
          line: 263,
          column: 8
        }
      },
      '136': {
        start: {
          line: 264,
          column: 6
        },
        end: {
          line: 264,
          column: 37
        }
      },
      '137': {
        start: {
          line: 265,
          column: 6
        },
        end: {
          line: 265,
          column: 41
        }
      },
      '138': {
        start: {
          line: 266,
          column: 6
        },
        end: {
          line: 266,
          column: 51
        }
      },
      '139': {
        start: {
          line: 267,
          column: 6
        },
        end: {
          line: 342,
          column: 9
        }
      },
      '140': {
        start: {
          line: 268,
          column: 8
        },
        end: {
          line: 268,
          column: 40
        }
      },
      '141': {
        start: {
          line: 269,
          column: 8
        },
        end: {
          line: 271,
          column: 9
        }
      },
      '142': {
        start: {
          line: 270,
          column: 10
        },
        end: {
          line: 270,
          column: 44
        }
      },
      '143': {
        start: {
          line: 272,
          column: 22
        },
        end: {
          line: 272,
          column: 108
        }
      },
      '144': {
        start: {
          line: 272,
          column: 76
        },
        end: {
          line: 272,
          column: 106
        }
      },
      '145': {
        start: {
          line: 274,
          column: 8
        },
        end: {
          line: 281,
          column: 9
        }
      },
      '146': {
        start: {
          line: 275,
          column: 28
        },
        end: {
          line: 275,
          column: 69
        }
      },
      '147': {
        start: {
          line: 276,
          column: 26
        },
        end: {
          line: 276,
          column: 84
        }
      },
      '148': {
        start: {
          line: 277,
          column: 10
        },
        end: {
          line: 280,
          column: 14
        }
      },
      '149': {
        start: {
          line: 277,
          column: 125
        },
        end: {
          line: 280,
          column: 11
        }
      },
      '150': {
        start: {
          line: 283,
          column: 20
        },
        end: {
          line: 283,
          column: 58
        }
      },
      '151': {
        start: {
          line: 285,
          column: 27
        },
        end: {
          line: 285,
          column: 51
        }
      },
      '152': {
        start: {
          line: 287,
          column: 8
        },
        end: {
          line: 308,
          column: 9
        }
      },
      '153': {
        start: {
          line: 288,
          column: 10
        },
        end: {
          line: 288,
          column: 108
        }
      },
      '154': {
        start: {
          line: 289,
          column: 10
        },
        end: {
          line: 291,
          column: 11
        }
      },
      '155': {
        start: {
          line: 290,
          column: 12
        },
        end: {
          line: 290,
          column: 120
        }
      },
      '156': {
        start: {
          line: 292,
          column: 10
        },
        end: {
          line: 292,
          column: 48
        }
      },
      '157': {
        start: {
          line: 294,
          column: 10
        },
        end: {
          line: 296,
          column: 11
        }
      },
      '158': {
        start: {
          line: 295,
          column: 12
        },
        end: {
          line: 295,
          column: 21
        }
      },
      '159': {
        start: {
          line: 298,
          column: 10
        },
        end: {
          line: 298,
          column: 105
        }
      },
      '160': {
        start: {
          line: 299,
          column: 10
        },
        end: {
          line: 299,
          column: 105
        }
      },
      '161': {
        start: {
          line: 300,
          column: 10
        },
        end: {
          line: 307,
          column: 11
        }
      },
      '162': {
        start: {
          line: 301,
          column: 12
        },
        end: {
          line: 304,
          column: 13
        }
      },
      '163': {
        start: {
          line: 302,
          column: 14
        },
        end: {
          line: 302,
          column: 47
        }
      },
      '164': {
        start: {
          line: 303,
          column: 14
        },
        end: {
          line: 303,
          column: 23
        }
      },
      '165': {
        start: {
          line: 305,
          column: 12
        },
        end: {
          line: 305,
          column: 139
        }
      },
      '166': {
        start: {
          line: 306,
          column: 12
        },
        end: {
          line: 306,
          column: 38
        }
      },
      '167': {
        start: {
          line: 310,
          column: 8
        },
        end: {
          line: 312,
          column: 11
        }
      },
      '168': {
        start: {
          line: 311,
          column: 10
        },
        end: {
          line: 311,
          column: 32
        }
      },
      '169': {
        start: {
          line: 314,
          column: 8
        },
        end: {
          line: 338,
          column: 10
        }
      },
      '170': {
        start: {
          line: 323,
          column: 14
        },
        end: {
          line: 325,
          column: 144
        }
      },
      '171': {
        start: {
          line: 336,
          column: 12
        },
        end: {
          line: 336,
          column: 47
        }
      },
      '172': {
        start: {
          line: 340,
          column: 8
        },
        end: {
          line: 340,
          column: 52
        }
      },
      '173': {
        start: {
          line: 341,
          column: 8
        },
        end: {
          line: 341,
          column: 47
        }
      },
      '174': {
        start: {
          line: 345,
          column: 20
        },
        end: {
          line: 345,
          column: 43
        }
      },
      '175': {
        start: {
          line: 346,
          column: 26
        },
        end: {
          line: 346,
          column: 68
        }
      },
      '176': {
        start: {
          line: 347,
          column: 24
        },
        end: {
          line: 347,
          column: 66
        }
      },
      '177': {
        start: {
          line: 348,
          column: 21
        },
        end: {
          line: 348,
          column: 41
        }
      },
      '178': {
        start: {
          line: 349,
          column: 17
        },
        end: {
          line: 349,
          column: 19
        }
      },
      '179': {
        start: {
          line: 350,
          column: 6
        },
        end: {
          line: 350,
          column: 88
        }
      },
      '180': {
        start: {
          line: 351,
          column: 6
        },
        end: {
          line: 353,
          column: 7
        }
      },
      '181': {
        start: {
          line: 352,
          column: 8
        },
        end: {
          line: 352,
          column: 15
        }
      },
      '182': {
        start: {
          line: 354,
          column: 18
        },
        end: {
          line: 354,
          column: 56
        }
      },
      '183': {
        start: {
          line: 355,
          column: 6
        },
        end: {
          line: 358,
          column: 7
        }
      },
      '184': {
        start: {
          line: 356,
          column: 8
        },
        end: {
          line: 356,
          column: 81
        }
      },
      '185': {
        start: {
          line: 357,
          column: 8
        },
        end: {
          line: 357,
          column: 119
        }
      },
      '186': {
        start: {
          line: 360,
          column: 21
        },
        end: {
          line: 409,
          column: 7
        }
      },
      '187': {
        start: {
          line: 407,
          column: 10
        },
        end: {
          line: 407,
          column: 124
        }
      },
      '188': {
        start: {
          line: 411,
          column: 6
        },
        end: {
          line: 411,
          column: 76
        }
      },
      '189': {
        start: {
          line: 413,
          column: 6
        },
        end: {
          line: 413,
          column: 145
        }
      },
      '190': {
        start: {
          line: 414,
          column: 6
        },
        end: {
          line: 418,
          column: 9
        }
      },
      '191': {
        start: {
          line: 419,
          column: 6
        },
        end: {
          line: 425,
          column: 7
        }
      },
      '192': {
        start: {
          line: 420,
          column: 8
        },
        end: {
          line: 424,
          column: 11
        }
      },
      '193': {
        start: {
          line: 426,
          column: 6
        },
        end: {
          line: 426,
          column: 31
        }
      },
      '194': {
        start: {
          line: 430,
          column: 37
        },
        end: {
          line: 452,
          column: 3
        }
      },
      '195': {
        start: {
          line: 439,
          column: 20
        },
        end: {
          line: 439,
          column: 68
        }
      },
      '196': {
        start: {
          line: 440,
          column: 25
        },
        end: {
          line: 440,
          column: 76
        }
      },
      '197': {
        start: {
          line: 441,
          column: 6
        },
        end: {
          line: 441,
          column: 58
        }
      },
      '198': {
        start: {
          line: 442,
          column: 6
        },
        end: {
          line: 442,
          column: 49
        }
      },
      '199': {
        start: {
          line: 443,
          column: 6
        },
        end: {
          line: 443,
          column: 98
        }
      },
      '200': {
        start: {
          line: 444,
          column: 6
        },
        end: {
          line: 444,
          column: 112
        }
      },
      '201': {
        start: {
          line: 445,
          column: 6
        },
        end: {
          line: 445,
          column: 54
        }
      },
      '202': {
        start: {
          line: 453,
          column: 2
        },
        end: {
          line: 651,
          column: 4
        }
      },
      '203': {
        start: {
          line: 460,
          column: 14
        },
        end: {
          line: 460,
          column: 107
        }
      },
      '204': {
        start: {
          line: 468,
          column: 24
        },
        end: {
          line: 468,
          column: 38
        }
      },
      '205': {
        start: {
          line: 469,
          column: 12
        },
        end: {
          line: 469,
          column: 37
        }
      },
      '206': {
        start: {
          line: 470,
          column: 12
        },
        end: {
          line: 470,
          column: 40
        }
      },
      '207': {
        start: {
          line: 471,
          column: 28
        },
        end: {
          line: 471,
          column: 62
        }
      },
      '208': {
        start: {
          line: 472,
          column: 12
        },
        end: {
          line: 472,
          column: 38
        }
      },
      '209': {
        start: {
          line: 473,
          column: 12
        },
        end: {
          line: 479,
          column: 13
        }
      },
      '210': {
        start: {
          line: 474,
          column: 36
        },
        end: {
          line: 474,
          column: 58
        }
      },
      '211': {
        start: {
          line: 475,
          column: 14
        },
        end: {
          line: 477,
          column: 17
        }
      },
      '212': {
        start: {
          line: 476,
          column: 16
        },
        end: {
          line: 476,
          column: 127
        }
      },
      '213': {
        start: {
          line: 478,
          column: 14
        },
        end: {
          line: 478,
          column: 20
        }
      },
      '214': {
        start: {
          line: 498,
          column: 20
        },
        end: {
          line: 498,
          column: 43
        }
      },
      '215': {
        start: {
          line: 499,
          column: 6
        },
        end: {
          line: 499,
          column: 44
        }
      },
      '216': {
        start: {
          line: 500,
          column: 6
        },
        end: {
          line: 500,
          column: 40
        }
      },
      '217': {
        start: {
          line: 501,
          column: 6
        },
        end: {
          line: 511,
          column: 7
        }
      },
      '218': {
        start: {
          line: 502,
          column: 8
        },
        end: {
          line: 507,
          column: 9
        }
      },
      '219': {
        start: {
          line: 503,
          column: 10
        },
        end: {
          line: 506,
          column: 11
        }
      },
      '220': {
        start: {
          line: 504,
          column: 12
        },
        end: {
          line: 504,
          column: 46
        }
      },
      '221': {
        start: {
          line: 505,
          column: 12
        },
        end: {
          line: 505,
          column: 18
        }
      },
      '222': {
        start: {
          line: 508,
          column: 8
        },
        end: {
          line: 510,
          column: 9
        }
      },
      '223': {
        start: {
          line: 509,
          column: 10
        },
        end: {
          line: 509,
          column: 56
        }
      },
      '224': {
        start: {
          line: 512,
          column: 6
        },
        end: {
          line: 512,
          column: 42
        }
      },
      '225': {
        start: {
          line: 513,
          column: 6
        },
        end: {
          line: 513,
          column: 45
        }
      },
      '226': {
        start: {
          line: 514,
          column: 6
        },
        end: {
          line: 596,
          column: 9
        }
      },
      '227': {
        start: {
          line: 516,
          column: 8
        },
        end: {
          line: 516,
          column: 48
        }
      },
      '228': {
        start: {
          line: 517,
          column: 23
        },
        end: {
          line: 574,
          column: 9
        }
      },
      '229': {
        start: {
          line: 566,
          column: 22
        },
        end: {
          line: 566,
          column: 42
        }
      },
      '230': {
        start: {
          line: 567,
          column: 12
        },
        end: {
          line: 567,
          column: 36
        }
      },
      '231': {
        start: {
          line: 576,
          column: 8
        },
        end: {
          line: 576,
          column: 62
        }
      },
      '232': {
        start: {
          line: 577,
          column: 8
        },
        end: {
          line: 577,
          column: 46
        }
      },
      '233': {
        start: {
          line: 579,
          column: 8
        },
        end: {
          line: 594,
          column: 9
        }
      },
      '234': {
        start: {
          line: 580,
          column: 10
        },
        end: {
          line: 583,
          column: 11
        }
      },
      '235': {
        start: {
          line: 581,
          column: 27
        },
        end: {
          line: 581,
          column: 48
        }
      },
      '236': {
        start: {
          line: 582,
          column: 12
        },
        end: {
          line: 582,
          column: 46
        }
      },
      '237': {
        start: {
          line: 584,
          column: 24
        },
        end: {
          line: 584,
          column: 97
        }
      },
      '238': {
        start: {
          line: 585,
          column: 10
        },
        end: {
          line: 588,
          column: 13
        }
      },
      '239': {
        start: {
          line: 589,
          column: 10
        },
        end: {
          line: 592,
          column: 13
        }
      },
      '240': {
        start: {
          line: 593,
          column: 10
        },
        end: {
          line: 593,
          column: 102
        }
      },
      '241': {
        start: {
          line: 595,
          column: 8
        },
        end: {
          line: 595,
          column: 59
        }
      },
      '242': {
        start: {
          line: 600,
          column: 6
        },
        end: {
          line: 600,
          column: 38
        }
      },
      '243': {
        start: {
          line: 601,
          column: 6
        },
        end: {
          line: 601,
          column: 80
        }
      },
      '244': {
        start: {
          line: 602,
          column: 6
        },
        end: {
          line: 602,
          column: 81
        }
      },
      '245': {
        start: {
          line: 603,
          column: 6
        },
        end: {
          line: 603,
          column: 82
        }
      },
      '246': {
        start: {
          line: 604,
          column: 6
        },
        end: {
          line: 604,
          column: 37
        }
      },
      '247': {
        start: {
          line: 605,
          column: 6
        },
        end: {
          line: 645,
          column: 9
        }
      },
      '248': {
        start: {
          line: 606,
          column: 8
        },
        end: {
          line: 630,
          column: 9
        }
      },
      '249': {
        start: {
          line: 607,
          column: 21
        },
        end: {
          line: 613,
          column: 11
        }
      },
      '250': {
        start: {
          line: 614,
          column: 10
        },
        end: {
          line: 616,
          column: 11
        }
      },
      '251': {
        start: {
          line: 615,
          column: 12
        },
        end: {
          line: 615,
          column: 93
        }
      },
      '252': {
        start: {
          line: 618,
          column: 21
        },
        end: {
          line: 624,
          column: 11
        }
      },
      '253': {
        start: {
          line: 625,
          column: 10
        },
        end: {
          line: 627,
          column: 11
        }
      },
      '254': {
        start: {
          line: 626,
          column: 12
        },
        end: {
          line: 626,
          column: 94
        }
      },
      '255': {
        start: {
          line: 629,
          column: 10
        },
        end: {
          line: 629,
          column: 90
        }
      },
      '256': {
        start: {
          line: 632,
          column: 8
        },
        end: {
          line: 642,
          column: 11
        }
      },
      '257': {
        start: {
          line: 633,
          column: 23
        },
        end: {
          line: 633,
          column: 68
        }
      },
      '258': {
        start: {
          line: 633,
          column: 39
        },
        end: {
          line: 633,
          column: 60
        }
      },
      '259': {
        start: {
          line: 634,
          column: 10
        },
        end: {
          line: 634,
          column: 62
        }
      },
      '260': {
        start: {
          line: 635,
          column: 10
        },
        end: {
          line: 640,
          column: 15
        }
      },
      '261': {
        start: {
          line: 641,
          column: 10
        },
        end: {
          line: 641,
          column: 58
        }
      },
      '262': {
        start: {
          line: 644,
          column: 8
        },
        end: {
          line: 644,
          column: 41
        }
      },
      '263': {
        start: {
          line: 648,
          column: 6
        },
        end: {
          line: 648,
          column: 31
        }
      },
      '264': {
        start: {
          line: 649,
          column: 6
        },
        end: {
          line: 649,
          column: 31
        }
      },
      '265': {
        start: {
          line: 653,
          column: 2
        },
        end: {
          line: 657,
          column: 4
        }
      },
      '266': {
        start: {
          line: 654,
          column: 4
        },
        end: {
          line: 656,
          column: 7
        }
      },
      '267': {
        start: {
          line: 655,
          column: 6
        },
        end: {
          line: 655,
          column: 33
        }
      },
      '268': {
        start: {
          line: 658,
          column: 2
        },
        end: {
          line: 658,
          column: 53
        }
      },
      '269': {
        start: {
          line: 659,
          column: 2
        },
        end: {
          line: 889,
          column: 4
        }
      },
      '270': {
        start: {
          line: 674,
          column: 6
        },
        end: {
          line: 674,
          column: 27
        }
      },
      '271': {
        start: {
          line: 677,
          column: 6
        },
        end: {
          line: 681,
          column: 7
        }
      },
      '272': {
        start: {
          line: 678,
          column: 8
        },
        end: {
          line: 680,
          column: 9
        }
      },
      '273': {
        start: {
          line: 679,
          column: 10
        },
        end: {
          line: 679,
          column: 52
        }
      },
      '274': {
        start: {
          line: 682,
          column: 6
        },
        end: {
          line: 682,
          column: 48
        }
      },
      '275': {
        start: {
          line: 685,
          column: 24
        },
        end: {
          line: 685,
          column: 42
        }
      },
      '276': {
        start: {
          line: 686,
          column: 6
        },
        end: {
          line: 686,
          column: 42
        }
      },
      '277': {
        start: {
          line: 688,
          column: 20
        },
        end: {
          line: 688,
          column: 82
        }
      },
      '278': {
        start: {
          line: 689,
          column: 6
        },
        end: {
          line: 697,
          column: 7
        }
      },
      '279': {
        start: {
          line: 690,
          column: 23
        },
        end: {
          line: 690,
          column: 44
        }
      },
      '280': {
        start: {
          line: 691,
          column: 21
        },
        end: {
          line: 691,
          column: 23
        }
      },
      '281': {
        start: {
          line: 692,
          column: 8
        },
        end: {
          line: 695,
          column: 9
        }
      },
      '282': {
        start: {
          line: 693,
          column: 20
        },
        end: {
          line: 693,
          column: 51
        }
      },
      '283': {
        start: {
          line: 694,
          column: 10
        },
        end: {
          line: 694,
          column: 52
        }
      },
      '284': {
        start: {
          line: 696,
          column: 8
        },
        end: {
          line: 696,
          column: 41
        }
      },
      '285': {
        start: {
          line: 698,
          column: 6
        },
        end: {
          line: 700,
          column: 7
        }
      },
      '286': {
        start: {
          line: 699,
          column: 8
        },
        end: {
          line: 699,
          column: 36
        }
      },
      '287': {
        start: {
          line: 701,
          column: 6
        },
        end: {
          line: 703,
          column: 7
        }
      },
      '288': {
        start: {
          line: 702,
          column: 8
        },
        end: {
          line: 702,
          column: 41
        }
      },
      '289': {
        start: {
          line: 705,
          column: 6
        },
        end: {
          line: 705,
          column: 25
        }
      },
      '290': {
        start: {
          line: 707,
          column: 6
        },
        end: {
          line: 709,
          column: 7
        }
      },
      '291': {
        start: {
          line: 708,
          column: 8
        },
        end: {
          line: 708,
          column: 28
        }
      },
      '292': {
        start: {
          line: 710,
          column: 6
        },
        end: {
          line: 717,
          column: 35
        }
      },
      '293': {
        start: {
          line: 718,
          column: 6
        },
        end: {
          line: 718,
          column: 63
        }
      },
      '294': {
        start: {
          line: 719,
          column: 6
        },
        end: {
          line: 722,
          column: 7
        }
      },
      '295': {
        start: {
          line: 720,
          column: 8
        },
        end: {
          line: 720,
          column: 38
        }
      },
      '296': {
        start: {
          line: 721,
          column: 8
        },
        end: {
          line: 721,
          column: 31
        }
      },
      '297': {
        start: {
          line: 723,
          column: 6
        },
        end: {
          line: 723,
          column: 24
        }
      },
      '298': {
        start: {
          line: 725,
          column: 6
        },
        end: {
          line: 725,
          column: 30
        }
      },
      '299': {
        start: {
          line: 728,
          column: 6
        },
        end: {
          line: 733,
          column: 7
        }
      },
      '300': {
        start: {
          line: 729,
          column: 8
        },
        end: {
          line: 731,
          column: 11
        }
      },
      '301': {
        start: {
          line: 732,
          column: 8
        },
        end: {
          line: 732,
          column: 42
        }
      },
      '302': {
        start: {
          line: 734,
          column: 6
        },
        end: {
          line: 734,
          column: 36
        }
      },
      '303': {
        start: {
          line: 740,
          column: 6
        },
        end: {
          line: 747,
          column: 9
        }
      },
      '304': {
        start: {
          line: 741,
          column: 8
        },
        end: {
          line: 744,
          column: 11
        }
      },
      '305': {
        start: {
          line: 742,
          column: 10
        },
        end: {
          line: 742,
          column: 71
        }
      },
      '306': {
        start: {
          line: 743,
          column: 10
        },
        end: {
          line: 743,
          column: 23
        }
      },
      '307': {
        start: {
          line: 745,
          column: 8
        },
        end: {
          line: 745,
          column: 54
        }
      },
      '308': {
        start: {
          line: 746,
          column: 8
        },
        end: {
          line: 746,
          column: 46
        }
      },
      '309': {
        start: {
          line: 751,
          column: 17
        },
        end: {
          line: 751,
          column: 22
        }
      },
      '310': {
        start: {
          line: 752,
          column: 6
        },
        end: {
          line: 758,
          column: 7
        }
      },
      '311': {
        start: {
          line: 753,
          column: 8
        },
        end: {
          line: 757,
          column: 9
        }
      },
      '312': {
        start: {
          line: 754,
          column: 10
        },
        end: {
          line: 754,
          column: 49
        }
      },
      '313': {
        start: {
          line: 755,
          column: 10
        },
        end: {
          line: 755,
          column: 22
        }
      },
      '314': {
        start: {
          line: 756,
          column: 10
        },
        end: {
          line: 756,
          column: 16
        }
      },
      '315': {
        start: {
          line: 759,
          column: 6
        },
        end: {
          line: 761,
          column: 7
        }
      },
      '316': {
        start: {
          line: 760,
          column: 8
        },
        end: {
          line: 760,
          column: 32
        }
      },
      '317': {
        start: {
          line: 765,
          column: 6
        },
        end: {
          line: 770,
          column: 7
        }
      },
      '318': {
        start: {
          line: 766,
          column: 8
        },
        end: {
          line: 768,
          column: 11
        }
      },
      '319': {
        start: {
          line: 767,
          column: 10
        },
        end: {
          line: 767,
          column: 68
        }
      },
      '320': {
        start: {
          line: 769,
          column: 8
        },
        end: {
          line: 769,
          column: 110
        }
      },
      '321': {
        start: {
          line: 772,
          column: 6
        },
        end: {
          line: 772,
          column: 30
        }
      },
      '322': {
        start: {
          line: 776,
          column: 6
        },
        end: {
          line: 778,
          column: 7
        }
      },
      '323': {
        start: {
          line: 777,
          column: 8
        },
        end: {
          line: 777,
          column: 15
        }
      },
      '324': {
        start: {
          line: 779,
          column: 6
        },
        end: {
          line: 779,
          column: 83
        }
      },
      '325': {
        start: {
          line: 780,
          column: 6
        },
        end: {
          line: 780,
          column: 27
        }
      },
      '326': {
        start: {
          line: 782,
          column: 6
        },
        end: {
          line: 784,
          column: 7
        }
      },
      '327': {
        start: {
          line: 783,
          column: 8
        },
        end: {
          line: 783,
          column: 15
        }
      },
      '328': {
        start: {
          line: 785,
          column: 6
        },
        end: {
          line: 785,
          column: 36
        }
      },
      '329': {
        start: {
          line: 787,
          column: 21
        },
        end: {
          line: 787,
          column: 207
        }
      },
      '330': {
        start: {
          line: 788,
          column: 6
        },
        end: {
          line: 788,
          column: 49
        }
      },
      '331': {
        start: {
          line: 789,
          column: 21
        },
        end: {
          line: 794,
          column: 7
        }
      },
      '332': {
        start: {
          line: 795,
          column: 6
        },
        end: {
          line: 795,
          column: 69
        }
      },
      '333': {
        start: {
          line: 796,
          column: 6
        },
        end: {
          line: 887,
          column: 9
        }
      },
      '334': {
        start: {
          line: 797,
          column: 23
        },
        end: {
          line: 797,
          column: 24
        }
      },
      '335': {
        start: {
          line: 798,
          column: 8
        },
        end: {
          line: 809,
          column: 9
        }
      },
      '336': {
        start: {
          line: 799,
          column: 10
        },
        end: {
          line: 802,
          column: 11
        }
      },
      '337': {
        start: {
          line: 800,
          column: 12
        },
        end: {
          line: 800,
          column: 27
        }
      },
      '338': {
        start: {
          line: 801,
          column: 12
        },
        end: {
          line: 801,
          column: 21
        }
      },
      '339': {
        start: {
          line: 803,
          column: 23
        },
        end: {
          line: 803,
          column: 63
        }
      },
      '340': {
        start: {
          line: 805,
          column: 10
        },
        end: {
          line: 805,
          column: 25
        }
      },
      '341': {
        start: {
          line: 806,
          column: 10
        },
        end: {
          line: 808,
          column: 11
        }
      },
      '342': {
        start: {
          line: 807,
          column: 12
        },
        end: {
          line: 807,
          column: 57
        }
      },
      '343': {
        start: {
          line: 870,
          column: 8
        },
        end: {
          line: 872,
          column: 9
        }
      },
      '344': {
        start: {
          line: 871,
          column: 10
        },
        end: {
          line: 871,
          column: 53
        }
      },
      '345': {
        start: {
          line: 873,
          column: 8
        },
        end: {
          line: 873,
          column: 81
        }
      },
      '346': {
        start: {
          line: 874,
          column: 8
        },
        end: {
          line: 885,
          column: 10
        }
      },
      '347': {
        start: {
          line: 875,
          column: 22
        },
        end: {
          line: 875,
          column: 60
        }
      },
      '348': {
        start: {
          line: 877,
          column: 10
        },
        end: {
          line: 883,
          column: 11
        }
      },
      '349': {
        start: {
          line: 878,
          column: 12
        },
        end: {
          line: 882,
          column: 169
        }
      },
      '350': {
        start: {
          line: 884,
          column: 10
        },
        end: {
          line: 884,
          column: 21
        }
      },
      '351': {
        start: {
          line: 886,
          column: 8
        },
        end: {
          line: 886,
          column: 75
        }
      },
      '352': {
        start: {
          line: 890,
          column: 2
        },
        end: {
          line: 890,
          column: 39
        }
      },
      '353': {
        start: {
          line: 892,
          column: 2
        },
        end: {
          line: 894,
          column: 5
        }
      },
      '354': {
        start: {
          line: 893,
          column: 4
        },
        end: {
          line: 893,
          column: 36
        }
      },
      '355': {
        start: {
          line: 895,
          column: 2
        },
        end: {
          line: 899,
          column: 5
        }
      },
      '356': {
        start: {
          line: 898,
          column: 4
        },
        end: {
          line: 898,
          column: 42
        }
      },
      '357': {
        start: {
          line: 901,
          column: 2
        },
        end: {
          line: 941,
          column: 4
        }
      },
      '358': {
        start: {
          line: 943,
          column: 2
        },
        end: {
          line: 943,
          column: 27
        }
      },
      '359': {
        start: {
          line: 944,
          column: 2
        },
        end: {
          line: 955,
          column: 4
        }
      },
      '360': {
        start: {
          line: 956,
          column: 2
        },
        end: {
          line: 956,
          column: 29
        }
      },
      '361': {
        start: {
          line: 957,
          column: 2
        },
        end: {
          line: 957,
          column: 49
        }
      },
      '362': {
        start: {
          line: 959,
          column: 2
        },
        end: {
          line: 964,
          column: 3
        }
      },
      '363': {
        start: {
          line: 960,
          column: 4
        },
        end: {
          line: 963,
          column: 5
        }
      },
      '364': {
        start: {
          line: 961,
          column: 6
        },
        end: {
          line: 961,
          column: 36
        }
      },
      '365': {
        start: {
          line: 962,
          column: 6
        },
        end: {
          line: 962,
          column: 12
        }
      },
      '366': {
        start: {
          line: 966,
          column: 2
        },
        end: {
          line: 1002,
          column: 5
        }
      },
      '367': {
        start: {
          line: 967,
          column: 16
        },
        end: {
          line: 967,
          column: 38
        }
      },
      '368': {
        start: {
          line: 971,
          column: 4
        },
        end: {
          line: 977,
          column: 5
        }
      },
      '369': {
        start: {
          line: 972,
          column: 6
        },
        end: {
          line: 976,
          column: 7
        }
      },
      '370': {
        start: {
          line: 975,
          column: 8
        },
        end: {
          line: 975,
          column: 57
        }
      },
      '371': {
        start: {
          line: 979,
          column: 18
        },
        end: {
          line: 981,
          column: 5
        }
      },
      '372': {
        start: {
          line: 983,
          column: 4
        },
        end: {
          line: 985,
          column: 7
        }
      },
      '373': {
        start: {
          line: 984,
          column: 6
        },
        end: {
          line: 984,
          column: 39
        }
      },
      '374': {
        start: {
          line: 986,
          column: 4
        },
        end: {
          line: 994,
          column: 7
        }
      },
      '375': {
        start: {
          line: 987,
          column: 6
        },
        end: {
          line: 992,
          column: 7
        }
      },
      '376': {
        start: {
          line: 988,
          column: 8
        },
        end: {
          line: 991,
          column: 9
        }
      },
      '377': {
        start: {
          line: 989,
          column: 10
        },
        end: {
          line: 989,
          column: 30
        }
      },
      '378': {
        start: {
          line: 990,
          column: 10
        },
        end: {
          line: 990,
          column: 16
        }
      },
      '379': {
        start: {
          line: 993,
          column: 6
        },
        end: {
          line: 993,
          column: 18
        }
      },
      '380': {
        start: {
          line: 996,
          column: 4
        },
        end: {
          line: 1001,
          column: 7
        }
      },
      '381': {
        start: {
          line: 997,
          column: 6
        },
        end: {
          line: 997,
          column: 35
        }
      },
      '382': {
        start: {
          line: 998,
          column: 6
        },
        end: {
          line: 1000,
          column: 7
        }
      },
      '383': {
        start: {
          line: 1004,
          column: 2
        },
        end: {
          line: 1011,
          column: 4
        }
      },
      '384': {
        start: {
          line: 1005,
          column: 4
        },
        end: {
          line: 1010,
          column: 7
        }
      },
      '385': {
        start: {
          line: 1006,
          column: 6
        },
        end: {
          line: 1006,
          column: 29
        }
      },
      '386': {
        start: {
          line: 1007,
          column: 6
        },
        end: {
          line: 1009,
          column: 7
        }
      },
      '387': {
        start: {
          line: 1008,
          column: 8
        },
        end: {
          line: 1008,
          column: 122
        }
      },
      '388': {
        start: {
          line: 1012,
          column: 2
        },
        end: {
          line: 1012,
          column: 24
        }
      },
      '389': {
        start: {
          line: 1014,
          column: 2
        },
        end: {
          line: 1036,
          column: 4
        }
      },
      '390': {
        start: {
          line: 1017,
          column: 6
        },
        end: {
          line: 1034,
          column: 9
        }
      },
      '391': {
        start: {
          line: 1018,
          column: 8
        },
        end: {
          line: 1024,
          column: 9
        }
      },
      '392': {
        start: {
          line: 1019,
          column: 10
        },
        end: {
          line: 1021,
          column: 11
        }
      },
      '393': {
        start: {
          line: 1020,
          column: 12
        },
        end: {
          line: 1020,
          column: 21
        }
      },
      '394': {
        start: {
          line: 1022,
          column: 10
        },
        end: {
          line: 1022,
          column: 62
        }
      },
      '395': {
        start: {
          line: 1023,
          column: 10
        },
        end: {
          line: 1023,
          column: 58
        }
      },
      '396': {
        start: {
          line: 1025,
          column: 8
        },
        end: {
          line: 1033,
          column: 11
        }
      },
      '397': {
        start: {
          line: 1026,
          column: 10
        },
        end: {
          line: 1028,
          column: 11
        }
      },
      '398': {
        start: {
          line: 1027,
          column: 12
        },
        end: {
          line: 1027,
          column: 21
        }
      },
      '399': {
        start: {
          line: 1029,
          column: 10
        },
        end: {
          line: 1031,
          column: 11
        }
      },
      '400': {
        start: {
          line: 1030,
          column: 12
        },
        end: {
          line: 1030,
          column: 22
        }
      },
      '401': {
        start: {
          line: 1032,
          column: 10
        },
        end: {
          line: 1032,
          column: 19
        }
      },
      '402': {
        start: {
          line: 1038,
          column: 2
        },
        end: {
          line: 1059,
          column: 4
        }
      },
      '403': {
        start: {
          line: 1041,
          column: 6
        },
        end: {
          line: 1045,
          column: 9
        }
      },
      '404': {
        start: {
          line: 1042,
          column: 8
        },
        end: {
          line: 1044,
          column: 9
        }
      },
      '405': {
        start: {
          line: 1043,
          column: 10
        },
        end: {
          line: 1043,
          column: 106
        }
      },
      '406': {
        start: {
          line: 1048,
          column: 6
        },
        end: {
          line: 1057,
          column: 9
        }
      },
      '407': {
        start: {
          line: 1049,
          column: 8
        },
        end: {
          line: 1052,
          column: 9
        }
      },
      '408': {
        start: {
          line: 1050,
          column: 10
        },
        end: {
          line: 1050,
          column: 72
        }
      },
      '409': {
        start: {
          line: 1051,
          column: 10
        },
        end: {
          line: 1051,
          column: 72
        }
      },
      '410': {
        start: {
          line: 1053,
          column: 8
        },
        end: {
          line: 1056,
          column: 11
        }
      },
      '411': {
        start: {
          line: 1054,
          column: 10
        },
        end: {
          line: 1054,
          column: 71
        }
      },
      '412': {
        start: {
          line: 1055,
          column: 10
        },
        end: {
          line: 1055,
          column: 23
        }
      },
      '413': {
        start: {
          line: 1062,
          column: 2
        },
        end: {
          line: 1062,
          column: 34
        }
      },
      '414': {
        start: {
          line: 1063,
          column: 2
        },
        end: {
          line: 1066,
          column: 4
        }
      },
      '415': {
        start: {
          line: 1068,
          column: 2
        },
        end: {
          line: 1070,
          column: 4
        }
      },
      '416': {
        start: {
          line: 1069,
          column: 4
        },
        end: {
          line: 1069,
          column: 57
        }
      },
      '417': {
        start: {
          line: 1072,
          column: 2
        },
        end: {
          line: 1131,
          column: 4
        }
      },
      '418': {
        start: {
          line: 1105,
          column: 24
        },
        end: {
          line: 1105,
          column: 54
        }
      },
      '419': {
        start: {
          line: 1106,
          column: 6
        },
        end: {
          line: 1106,
          column: 55
        }
      },
      '420': {
        start: {
          line: 1107,
          column: 6
        },
        end: {
          line: 1107,
          column: 33
        }
      },
      '421': {
        start: {
          line: 1108,
          column: 6
        },
        end: {
          line: 1108,
          column: 27
        }
      },
      '422': {
        start: {
          line: 1111,
          column: 6
        },
        end: {
          line: 1111,
          column: 25
        }
      },
      '423': {
        start: {
          line: 1112,
          column: 6
        },
        end: {
          line: 1112,
          column: 28
        }
      },
      '424': {
        start: {
          line: 1113,
          column: 6
        },
        end: {
          line: 1113,
          column: 21
        }
      },
      '425': {
        start: {
          line: 1116,
          column: 6
        },
        end: {
          line: 1122,
          column: 9
        }
      },
      '426': {
        start: {
          line: 1117,
          column: 8
        },
        end: {
          line: 1117,
          column: 65
        }
      },
      '427': {
        start: {
          line: 1118,
          column: 8
        },
        end: {
          line: 1118,
          column: 40
        }
      },
      '428': {
        start: {
          line: 1119,
          column: 8
        },
        end: {
          line: 1119,
          column: 48
        }
      },
      '429': {
        start: {
          line: 1120,
          column: 8
        },
        end: {
          line: 1120,
          column: 50
        }
      },
      '430': {
        start: {
          line: 1121,
          column: 8
        },
        end: {
          line: 1121,
          column: 54
        }
      },
      '431': {
        start: {
          line: 1125,
          column: 6
        },
        end: {
          line: 1125,
          column: 33
        }
      },
      '432': {
        start: {
          line: 1126,
          column: 6
        },
        end: {
          line: 1126,
          column: 58
        }
      },
      '433': {
        start: {
          line: 1127,
          column: 6
        },
        end: {
          line: 1129,
          column: 9
        }
      },
      '434': {
        start: {
          line: 1128,
          column: 8
        },
        end: {
          line: 1128,
          column: 22
        }
      },
      '435': {
        start: {
          line: 1132,
          column: 2
        },
        end: {
          line: 1132,
          column: 26
        }
      },
      '436': {
        start: {
          line: 1133,
          column: 2
        },
        end: {
          line: 1133,
          column: 29
        }
      },
      '437': {
        start: {
          line: 1134,
          column: 2
        },
        end: {
          line: 1134,
          column: 29
        }
      },
      '438': {
        start: {
          line: 1135,
          column: 2
        },
        end: {
          line: 1135,
          column: 46
        }
      },
      '439': {
        start: {
          line: 1137,
          column: 19
        },
        end: {
          line: 1142,
          column: 4
        }
      },
      '440': {
        start: {
          line: 1144,
          column: 2
        },
        end: {
          line: 1148,
          column: 4
        }
      },
      '441': {
        start: {
          line: 1145,
          column: 4
        },
        end: {
          line: 1145,
          column: 54
        }
      },
      '442': {
        start: {
          line: 1146,
          column: 4
        },
        end: {
          line: 1146,
          column: 28
        }
      },
      '443': {
        start: {
          line: 1147,
          column: 4
        },
        end: {
          line: 1147,
          column: 22
        }
      },
      '444': {
        start: {
          line: 1150,
          column: 2
        },
        end: {
          line: 1153,
          column: 4
        }
      },
      '445': {
        start: {
          line: 1151,
          column: 4
        },
        end: {
          line: 1151,
          column: 48
        }
      },
      '446': {
        start: {
          line: 1152,
          column: 4
        },
        end: {
          line: 1152,
          column: 29
        }
      },
      '447': {
        start: {
          line: 1155,
          column: 2
        },
        end: {
          line: 1304,
          column: 4
        }
      },
      '448': {
        start: {
          line: 1157,
          column: 6
        },
        end: {
          line: 1159,
          column: 7
        }
      },
      '449': {
        start: {
          line: 1158,
          column: 8
        },
        end: {
          line: 1158,
          column: 15
        }
      },
      '450': {
        start: {
          line: 1160,
          column: 6
        },
        end: {
          line: 1164,
          column: 7
        }
      },
      '451': {
        start: {
          line: 1161,
          column: 8
        },
        end: {
          line: 1161,
          column: 77
        }
      },
      '452': {
        start: {
          line: 1163,
          column: 8
        },
        end: {
          line: 1163,
          column: 83
        }
      },
      '453': {
        start: {
          line: 1168,
          column: 19
        },
        end: {
          line: 1168,
          column: 106
        }
      },
      '454': {
        start: {
          line: 1169,
          column: 6
        },
        end: {
          line: 1188,
          column: 7
        }
      },
      '455': {
        start: {
          line: 1171,
          column: 10
        },
        end: {
          line: 1171,
          column: 107
        }
      },
      '456': {
        start: {
          line: 1172,
          column: 10
        },
        end: {
          line: 1172,
          column: 16
        }
      },
      '457': {
        start: {
          line: 1174,
          column: 10
        },
        end: {
          line: 1174,
          column: 110
        }
      },
      '458': {
        start: {
          line: 1175,
          column: 10
        },
        end: {
          line: 1175,
          column: 16
        }
      },
      '459': {
        start: {
          line: 1177,
          column: 10
        },
        end: {
          line: 1177,
          column: 110
        }
      },
      '460': {
        start: {
          line: 1178,
          column: 10
        },
        end: {
          line: 1178,
          column: 16
        }
      },
      '461': {
        start: {
          line: 1180,
          column: 10
        },
        end: {
          line: 1180,
          column: 110
        }
      },
      '462': {
        start: {
          line: 1181,
          column: 10
        },
        end: {
          line: 1181,
          column: 16
        }
      },
      '463': {
        start: {
          line: 1183,
          column: 10
        },
        end: {
          line: 1183,
          column: 110
        }
      },
      '464': {
        start: {
          line: 1184,
          column: 10
        },
        end: {
          line: 1184,
          column: 16
        }
      },
      '465': {
        start: {
          line: 1186,
          column: 10
        },
        end: {
          line: 1186,
          column: 107
        }
      },
      '466': {
        start: {
          line: 1187,
          column: 10
        },
        end: {
          line: 1187,
          column: 16
        }
      },
      '467': {
        start: {
          line: 1189,
          column: 6
        },
        end: {
          line: 1189,
          column: 124
        }
      },
      '468': {
        start: {
          line: 1192,
          column: 25
        },
        end: {
          line: 1192,
          column: 71
        }
      },
      '469': {
        start: {
          line: 1193,
          column: 21
        },
        end: {
          line: 1193,
          column: 112
        }
      },
      '470': {
        start: {
          line: 1194,
          column: 6
        },
        end: {
          line: 1264,
          column: 9
        }
      },
      '471': {
        start: {
          line: 1195,
          column: 8
        },
        end: {
          line: 1195,
          column: 54
        }
      },
      '472': {
        start: {
          line: 1196,
          column: 20
        },
        end: {
          line: 1196,
          column: 21
        }
      },
      '473': {
        start: {
          line: 1197,
          column: 23
        },
        end: {
          line: 1197,
          column: 29
        }
      },
      '474': {
        start: {
          line: 1200,
          column: 19
        },
        end: {
          line: 1200,
          column: 21
        }
      },
      '475': {
        start: {
          line: 1202,
          column: 8
        },
        end: {
          line: 1207,
          column: 9
        }
      },
      '476': {
        start: {
          line: 1203,
          column: 10
        },
        end: {
          line: 1205,
          column: 11
        }
      },
      '477': {
        start: {
          line: 1204,
          column: 12
        },
        end: {
          line: 1204,
          column: 21
        }
      },
      '478': {
        start: {
          line: 1206,
          column: 10
        },
        end: {
          line: 1206,
          column: 79
        }
      },
      '479': {
        start: {
          line: 1209,
          column: 8
        },
        end: {
          line: 1257,
          column: 9
        }
      },
      '480': {
        start: {
          line: 1210,
          column: 20
        },
        end: {
          line: 1210,
          column: 34
        }
      },
      '481': {
        start: {
          line: 1211,
          column: 10
        },
        end: {
          line: 1211,
          column: 37
        }
      },
      '482': {
        start: {
          line: 1213,
          column: 10
        },
        end: {
          line: 1256,
          column: 11
        }
      },
      '483': {
        start: {
          line: 1214,
          column: 12
        },
        end: {
          line: 1255,
          column: 13
        }
      },
      '484': {
        start: {
          line: 1215,
          column: 14
        },
        end: {
          line: 1254,
          column: 15
        }
      },
      '485': {
        start: {
          line: 1216,
          column: 30
        },
        end: {
          line: 1216,
          column: 123
        }
      },
      '486': {
        start: {
          line: 1217,
          column: 16
        },
        end: {
          line: 1219,
          column: 17
        }
      },
      '487': {
        start: {
          line: 1218,
          column: 18
        },
        end: {
          line: 1218,
          column: 27
        }
      },
      '488': {
        start: {
          line: 1220,
          column: 16
        },
        end: {
          line: 1222,
          column: 17
        }
      },
      '489': {
        start: {
          line: 1221,
          column: 18
        },
        end: {
          line: 1221,
          column: 27
        }
      },
      '490': {
        start: {
          line: 1223,
          column: 16
        },
        end: {
          line: 1223,
          column: 38
        }
      },
      '491': {
        start: {
          line: 1224,
          column: 16
        },
        end: {
          line: 1224,
          column: 38
        }
      },
      '492': {
        start: {
          line: 1225,
          column: 16
        },
        end: {
          line: 1225,
          column: 24
        }
      },
      '493': {
        start: {
          line: 1226,
          column: 16
        },
        end: {
          line: 1249,
          column: 19
        }
      },
      '494': {
        start: {
          line: 1231,
          column: 30
        },
        end: {
          line: 1231,
          column: 48
        }
      },
      '495': {
        start: {
          line: 1232,
          column: 18
        },
        end: {
          line: 1244,
          column: 19
        }
      },
      '496': {
        start: {
          line: 1233,
          column: 36
        },
        end: {
          line: 1233,
          column: 59
        }
      },
      '497': {
        start: {
          line: 1234,
          column: 20
        },
        end: {
          line: 1242,
          column: 21
        }
      },
      '498': {
        start: {
          line: 1235,
          column: 22
        },
        end: {
          line: 1237,
          column: 23
        }
      },
      '499': {
        start: {
          line: 1236,
          column: 24
        },
        end: {
          line: 1236,
          column: 76
        }
      },
      '500': {
        start: {
          line: 1239,
          column: 22
        },
        end: {
          line: 1241,
          column: 23
        }
      },
      '501': {
        start: {
          line: 1240,
          column: 24
        },
        end: {
          line: 1240,
          column: 78
        }
      },
      '502': {
        start: {
          line: 1243,
          column: 20
        },
        end: {
          line: 1243,
          column: 79
        }
      },
      '503': {
        start: {
          line: 1245,
          column: 18
        },
        end: {
          line: 1248,
          column: 42
        }
      },
      '504': {
        start: {
          line: 1251,
          column: 16
        },
        end: {
          line: 1251,
          column: 64
        }
      },
      '505': {
        start: {
          line: 1259,
          column: 8
        },
        end: {
          line: 1262,
          column: 9
        }
      },
      '506': {
        start: {
          line: 1260,
          column: 10
        },
        end: {
          line: 1260,
          column: 29
        }
      },
      '507': {
        start: {
          line: 1261,
          column: 10
        },
        end: {
          line: 1261,
          column: 29
        }
      },
      '508': {
        start: {
          line: 1263,
          column: 8
        },
        end: {
          line: 1263,
          column: 37
        }
      },
      '509': {
        start: {
          line: 1268,
          column: 6
        },
        end: {
          line: 1268,
          column: 30
        }
      },
      '510': {
        start: {
          line: 1269,
          column: 6
        },
        end: {
          line: 1269,
          column: 25
        }
      },
      '511': {
        start: {
          line: 1270,
          column: 6
        },
        end: {
          line: 1270,
          column: 24
        }
      },
      '512': {
        start: {
          line: 1271,
          column: 6
        },
        end: {
          line: 1301,
          column: 9
        }
      },
      '513': {
        start: {
          line: 1272,
          column: 26
        },
        end: {
          line: 1272,
          column: 69
        }
      },
      '514': {
        start: {
          line: 1273,
          column: 8
        },
        end: {
          line: 1278,
          column: 65
        }
      },
      '515': {
        start: {
          line: 1279,
          column: 8
        },
        end: {
          line: 1281,
          column: 11
        }
      },
      '516': {
        start: {
          line: 1282,
          column: 8
        },
        end: {
          line: 1282,
          column: 46
        }
      },
      '517': {
        start: {
          line: 1284,
          column: 8
        },
        end: {
          line: 1284,
          column: 40
        }
      },
      '518': {
        start: {
          line: 1285,
          column: 8
        },
        end: {
          line: 1285,
          column: 42
        }
      },
      '519': {
        start: {
          line: 1293,
          column: 8
        },
        end: {
          line: 1293,
          column: 67
        }
      },
      '520': {
        start: {
          line: 1295,
          column: 8
        },
        end: {
          line: 1300,
          column: 9
        }
      },
      '521': {
        start: {
          line: 1296,
          column: 10
        },
        end: {
          line: 1296,
          column: 35
        }
      },
      '522': {
        start: {
          line: 1297,
          column: 10
        },
        end: {
          line: 1297,
          column: 71
        }
      },
      '523': {
        start: {
          line: 1298,
          column: 10
        },
        end: {
          line: 1298,
          column: 55
        }
      },
      '524': {
        start: {
          line: 1299,
          column: 10
        },
        end: {
          line: 1299,
          column: 48
        }
      },
      '525': {
        start: {
          line: 1310,
          column: 23
        },
        end: {
          line: 1310,
          column: 31
        }
      },
      '526': {
        start: {
          line: 1311,
          column: 25
        },
        end: {
          line: 1311,
          column: 38
        }
      },
      '527': {
        start: {
          line: 1313,
          column: 24
        },
        end: {
          line: 1313,
          column: 50
        }
      },
      '528': {
        start: {
          line: 1314,
          column: 20
        },
        end: {
          line: 1314,
          column: 38
        }
      },
      '529': {
        start: {
          line: 1316,
          column: 2
        },
        end: {
          line: 1338,
          column: 5
        }
      },
      '530': {
        start: {
          line: 1317,
          column: 4
        },
        end: {
          line: 1320,
          column: 5
        }
      },
      '531': {
        start: {
          line: 1318,
          column: 6
        },
        end: {
          line: 1318,
          column: 29
        }
      },
      '532': {
        start: {
          line: 1319,
          column: 6
        },
        end: {
          line: 1319,
          column: 13
        }
      },
      '533': {
        start: {
          line: 1321,
          column: 4
        },
        end: {
          line: 1337,
          column: 5
        }
      },
      '534': {
        start: {
          line: 1322,
          column: 6
        },
        end: {
          line: 1324,
          column: 7
        }
      },
      '535': {
        start: {
          line: 1323,
          column: 8
        },
        end: {
          line: 1323,
          column: 17
        }
      },
      '536': {
        start: {
          line: 1325,
          column: 6
        },
        end: {
          line: 1325,
          column: 70
        }
      },
      '537': {
        start: {
          line: 1326,
          column: 6
        },
        end: {
          line: 1326,
          column: 46
        }
      },
      '538': {
        start: {
          line: 1327,
          column: 6
        },
        end: {
          line: 1327,
          column: 74
        }
      },
      '539': {
        start: {
          line: 1328,
          column: 6
        },
        end: {
          line: 1328,
          column: 70
        }
      },
      '540': {
        start: {
          line: 1329,
          column: 6
        },
        end: {
          line: 1329,
          column: 75
        }
      },
      '541': {
        start: {
          line: 1330,
          column: 6
        },
        end: {
          line: 1330,
          column: 62
        }
      },
      '542': {
        start: {
          line: 1331,
          column: 19
        },
        end: {
          line: 1331,
          column: 21
        }
      },
      '543': {
        start: {
          line: 1332,
          column: 6
        },
        end: {
          line: 1334,
          column: 7
        }
      },
      '544': {
        start: {
          line: 1333,
          column: 8
        },
        end: {
          line: 1333,
          column: 47
        }
      },
      '545': {
        start: {
          line: 1335,
          column: 6
        },
        end: {
          line: 1335,
          column: 48
        }
      },
      '546': {
        start: {
          line: 1336,
          column: 6
        },
        end: {
          line: 1336,
          column: 37
        }
      },
      '547': {
        start: {
          line: 1340,
          column: 2
        },
        end: {
          line: 1340,
          column: 30
        }
      },
      '548': {
        start: {
          line: 1341,
          column: 2
        },
        end: {
          line: 1341,
          column: 29
        }
      },
      '549': {
        start: {
          line: 1342,
          column: 2
        },
        end: {
          line: 1342,
          column: 33
        }
      },
      '550': {
        start: {
          line: 1343,
          column: 2
        },
        end: {
          line: 1343,
          column: 34
        }
      },
      '551': {
        start: {
          line: 1346,
          column: 2
        },
        end: {
          line: 1356,
          column: 4
        }
      },
      '552': {
        start: {
          line: 1347,
          column: 16
        },
        end: {
          line: 1347,
          column: 17
        }
      },
      '553': {
        start: {
          line: 1348,
          column: 25
        },
        end: {
          line: 1348,
          column: 35
        }
      },
      '554': {
        start: {
          line: 1349,
          column: 4
        },
        end: {
          line: 1353,
          column: 7
        }
      },
      '555': {
        start: {
          line: 1350,
          column: 6
        },
        end: {
          line: 1352,
          column: 7
        }
      },
      '556': {
        start: {
          line: 1351,
          column: 8
        },
        end: {
          line: 1351,
          column: 19
        }
      },
      '557': {
        start: {
          line: 1355,
          column: 4
        },
        end: {
          line: 1355,
          column: 17
        }
      },
      '558': {
        start: {
          line: 1357,
          column: 2
        },
        end: {
          line: 1362,
          column: 4
        }
      },
      '559': {
        start: {
          line: 1363,
          column: 2
        },
        end: {
          line: 1465,
          column: 5
        }
      },
      '560': {
        start: {
          line: 1364,
          column: 4
        },
        end: {
          line: 1392,
          column: 7
        }
      },
      '561': {
        start: {
          line: 1365,
          column: 6
        },
        end: {
          line: 1365,
          column: 51
        }
      },
      '562': {
        start: {
          line: 1366,
          column: 6
        },
        end: {
          line: 1366,
          column: 57
        }
      },
      '563': {
        start: {
          line: 1367,
          column: 6
        },
        end: {
          line: 1367,
          column: 47
        }
      },
      '564': {
        start: {
          line: 1368,
          column: 6
        },
        end: {
          line: 1368,
          column: 21
        }
      },
      '565': {
        start: {
          line: 1370,
          column: 6
        },
        end: {
          line: 1370,
          column: 57
        }
      },
      '566': {
        start: {
          line: 1371,
          column: 6
        },
        end: {
          line: 1371,
          column: 63
        }
      },
      '567': {
        start: {
          line: 1372,
          column: 6
        },
        end: {
          line: 1374,
          column: 7
        }
      },
      '568': {
        start: {
          line: 1373,
          column: 8
        },
        end: {
          line: 1373,
          column: 60
        }
      },
      '569': {
        start: {
          line: 1376,
          column: 6
        },
        end: {
          line: 1376,
          column: 53
        }
      },
      '570': {
        start: {
          line: 1377,
          column: 6
        },
        end: {
          line: 1377,
          column: 53
        }
      },
      '571': {
        start: {
          line: 1379,
          column: 6
        },
        end: {
          line: 1381,
          column: 7
        }
      },
      '572': {
        start: {
          line: 1380,
          column: 8
        },
        end: {
          line: 1380,
          column: 60
        }
      },
      '573': {
        start: {
          line: 1382,
          column: 6
        },
        end: {
          line: 1384,
          column: 7
        }
      },
      '574': {
        start: {
          line: 1383,
          column: 8
        },
        end: {
          line: 1383,
          column: 62
        }
      },
      '575': {
        start: {
          line: 1387,
          column: 6
        },
        end: {
          line: 1387,
          column: 48
        }
      },
      '576': {
        start: {
          line: 1388,
          column: 6
        },
        end: {
          line: 1388,
          column: 54
        }
      },
      '577': {
        start: {
          line: 1389,
          column: 6
        },
        end: {
          line: 1389,
          column: 44
        }
      },
      '578': {
        start: {
          line: 1391,
          column: 6
        },
        end: {
          line: 1391,
          column: 19
        }
      },
      '579': {
        start: {
          line: 1394,
          column: 4
        },
        end: {
          line: 1394,
          column: 36
        }
      },
      '580': {
        start: {
          line: 1396,
          column: 4
        },
        end: {
          line: 1396,
          column: 77
        }
      },
      '581': {
        start: {
          line: 1397,
          column: 4
        },
        end: {
          line: 1397,
          column: 83
        }
      },
      '582': {
        start: {
          line: 1398,
          column: 4
        },
        end: {
          line: 1398,
          column: 77
        }
      },
      '583': {
        start: {
          line: 1399,
          column: 4
        },
        end: {
          line: 1399,
          column: 73
        }
      },
      '584': {
        start: {
          line: 1401,
          column: 4
        },
        end: {
          line: 1401,
          column: 47
        }
      },
      '585': {
        start: {
          line: 1402,
          column: 4
        },
        end: {
          line: 1431,
          column: 6
        }
      },
      '586': {
        start: {
          line: 1438,
          column: 4
        },
        end: {
          line: 1464,
          column: 6
        }
      },
      '587': {
        start: {
          line: 1443,
          column: 12
        },
        end: {
          line: 1446,
          column: 14
        }
      },
      '588': {
        start: {
          line: 1448,
          column: 12
        },
        end: {
          line: 1448,
          column: 38
        }
      },
      '589': {
        start: {
          line: 1453,
          column: 8
        },
        end: {
          line: 1455,
          column: 9
        }
      },
      '590': {
        start: {
          line: 1454,
          column: 10
        },
        end: {
          line: 1454,
          column: 56
        }
      },
      '591': {
        start: {
          line: 1456,
          column: 8
        },
        end: {
          line: 1456,
          column: 74
        }
      },
      '592': {
        start: {
          line: 1468,
          column: 2
        },
        end: {
          line: 1532,
          column: 5
        }
      },
      '593': {
        start: {
          line: 1469,
          column: 4
        },
        end: {
          line: 1469,
          column: 33
        }
      },
      '594': {
        start: {
          line: 1475,
          column: 4
        },
        end: {
          line: 1531,
          column: 6
        }
      },
      '595': {
        start: {
          line: 1536,
          column: 2
        },
        end: {
          line: 1541,
          column: 4
        }
      },
      '596': {
        start: {
          line: 1537,
          column: 4
        },
        end: {
          line: 1537,
          column: 36
        }
      },
      '597': {
        start: {
          line: 1538,
          column: 4
        },
        end: {
          line: 1540,
          column: 5
        }
      },
      '598': {
        start: {
          line: 1539,
          column: 6
        },
        end: {
          line: 1539,
          column: 93
        }
      },
      '599': {
        start: {
          line: 1543,
          column: 2
        },
        end: {
          line: 1557,
          column: 4
        }
      },
      '600': {
        start: {
          line: 1547,
          column: 6
        },
        end: {
          line: 1555,
          column: 9
        }
      },
      '601': {
        start: {
          line: 1548,
          column: 8
        },
        end: {
          line: 1554,
          column: 9
        }
      },
      '602': {
        start: {
          line: 1549,
          column: 10
        },
        end: {
          line: 1549,
          column: 58
        }
      },
      '603': {
        start: {
          line: 1550,
          column: 10
        },
        end: {
          line: 1550,
          column: 72
        }
      },
      '604': {
        start: {
          line: 1551,
          column: 10
        },
        end: {
          line: 1553,
          column: 11
        }
      },
      '605': {
        start: {
          line: 1552,
          column: 12
        },
        end: {
          line: 1552,
          column: 50
        }
      },
      '606': {
        start: {
          line: 1558,
          column: 2
        },
        end: {
          line: 1558,
          column: 33
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 50
          },
          end: {
            line: 2,
            column: 51
          }
        },
        loc: {
          start: {
            line: 2,
            column: 389
          },
          end: {
            line: 1559,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 7,
            column: 37
          },
          end: {
            line: 7,
            column: 38
          }
        },
        loc: {
          start: {
            line: 7,
            column: 43
          },
          end: {
            line: 10,
            column: 3
          }
        },
        line: 7
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 17,
            column: 24
          },
          end: {
            line: 17,
            column: 25
          }
        },
        loc: {
          start: {
            line: 17,
            column: 36
          },
          end: {
            line: 22,
            column: 3
          }
        },
        line: 17
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 18,
            column: 60
          },
          end: {
            line: 18,
            column: 61
          }
        },
        loc: {
          start: {
            line: 18,
            column: 68
          },
          end: {
            line: 21,
            column: 5
          }
        },
        line: 18
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 64,
            column: 26
          },
          end: {
            line: 64,
            column: 27
          }
        },
        loc: {
          start: {
            line: 64,
            column: 32
          },
          end: {
            line: 71,
            column: 3
          }
        },
        line: 64
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 68,
            column: 42
          },
          end: {
            line: 68,
            column: 43
          }
        },
        loc: {
          start: {
            line: 68,
            column: 51
          },
          end: {
            line: 68,
            column: 58
          }
        },
        line: 68
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 74,
            column: 27
          },
          end: {
            line: 74,
            column: 28
          }
        },
        loc: {
          start: {
            line: 74,
            column: 35
          },
          end: {
            line: 88,
            column: 3
          }
        },
        line: 74
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 83,
            column: 51
          },
          end: {
            line: 83,
            column: 52
          }
        },
        loc: {
          start: {
            line: 83,
            column: 64
          },
          end: {
            line: 85,
            column: 7
          }
        },
        line: 83
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 91,
            column: 25
          },
          end: {
            line: 91,
            column: 26
          }
        },
        loc: {
          start: {
            line: 91,
            column: 33
          },
          end: {
            line: 101,
            column: 3
          }
        },
        line: 91
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 104,
            column: 27
          },
          end: {
            line: 104,
            column: 28
          }
        },
        loc: {
          start: {
            line: 104,
            column: 43
          },
          end: {
            line: 112,
            column: 3
          }
        },
        line: 104
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 114,
            column: 51
          },
          end: {
            line: 114,
            column: 52
          }
        },
        loc: {
          start: {
            line: 114,
            column: 59
          },
          end: {
            line: 116,
            column: 3
          }
        },
        line: 114
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 117,
            column: 21
          },
          end: {
            line: 117,
            column: 22
          }
        },
        loc: {
          start: {
            line: 117,
            column: 38
          },
          end: {
            line: 119,
            column: 3
          }
        },
        line: 117
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 123,
            column: 30
          },
          end: {
            line: 123,
            column: 31
          }
        },
        loc: {
          start: {
            line: 123,
            column: 46
          },
          end: {
            line: 140,
            column: 3
          }
        },
        line: 123
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 163,
            column: 117
          },
          end: {
            line: 163,
            column: 118
          }
        },
        loc: {
          start: {
            line: 163,
            column: 125
          },
          end: {
            line: 170,
            column: 9
          }
        },
        line: 163
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 175,
            column: 79
          },
          end: {
            line: 175,
            column: 80
          }
        },
        loc: {
          start: {
            line: 175,
            column: 88
          },
          end: {
            line: 233,
            column: 7
          }
        },
        line: 175
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 230,
            column: 47
          },
          end: {
            line: 230,
            column: 48
          }
        },
        loc: {
          start: {
            line: 230,
            column: 61
          },
          end: {
            line: 230,
            column: 86
          }
        },
        line: 230
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 267,
            column: 51
          },
          end: {
            line: 267,
            column: 52
          }
        },
        loc: {
          start: {
            line: 267,
            column: 59
          },
          end: {
            line: 342,
            column: 7
          }
        },
        line: 267
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 272,
            column: 67
          },
          end: {
            line: 272,
            column: 68
          }
        },
        loc: {
          start: {
            line: 272,
            column: 76
          },
          end: {
            line: 272,
            column: 106
          }
        },
        line: 272
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 277,
            column: 114
          },
          end: {
            line: 277,
            column: 115
          }
        },
        loc: {
          start: {
            line: 277,
            column: 125
          },
          end: {
            line: 280,
            column: 11
          }
        },
        line: 277
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 310,
            column: 59
          },
          end: {
            line: 310,
            column: 60
          }
        },
        loc: {
          start: {
            line: 310,
            column: 69
          },
          end: {
            line: 312,
            column: 9
          }
        },
        line: 310
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 322,
            column: 50
          },
          end: {
            line: 322,
            column: 51
          }
        },
        loc: {
          start: {
            line: 322,
            column: 60
          },
          end: {
            line: 326,
            column: 13
          }
        },
        line: 322
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 335,
            column: 35
          },
          end: {
            line: 335,
            column: 36
          }
        },
        loc: {
          start: {
            line: 335,
            column: 44
          },
          end: {
            line: 337,
            column: 11
          }
        },
        line: 335
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 475,
            column: 41
          },
          end: {
            line: 475,
            column: 42
          }
        },
        loc: {
          start: {
            line: 475,
            column: 46
          },
          end: {
            line: 477,
            column: 15
          }
        },
        line: 475
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 514,
            column: 84
          },
          end: {
            line: 514,
            column: 85
          }
        },
        loc: {
          start: {
            line: 514,
            column: 92
          },
          end: {
            line: 596,
            column: 7
          }
        },
        line: 514
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 605,
            column: 58
          },
          end: {
            line: 605,
            column: 59
          }
        },
        loc: {
          start: {
            line: 605,
            column: 66
          },
          end: {
            line: 645,
            column: 7
          }
        },
        line: 605
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 632,
            column: 53
          },
          end: {
            line: 632,
            column: 54
          }
        },
        loc: {
          start: {
            line: 632,
            column: 61
          },
          end: {
            line: 642,
            column: 9
          }
        },
        line: 632
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 633,
            column: 33
          },
          end: {
            line: 633,
            column: 34
          }
        },
        loc: {
          start: {
            line: 633,
            column: 39
          },
          end: {
            line: 633,
            column: 60
          }
        },
        line: 633
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 653,
            column: 18
          },
          end: {
            line: 653,
            column: 19
          }
        },
        loc: {
          start: {
            line: 653,
            column: 30
          },
          end: {
            line: 657,
            column: 3
          }
        },
        line: 653
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 654,
            column: 53
          },
          end: {
            line: 654,
            column: 54
          }
        },
        loc: {
          start: {
            line: 654,
            column: 61
          },
          end: {
            line: 656,
            column: 5
          }
        },
        line: 654
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 740,
            column: 61
          },
          end: {
            line: 740,
            column: 62
          }
        },
        loc: {
          start: {
            line: 740,
            column: 69
          },
          end: {
            line: 747,
            column: 7
          }
        },
        line: 740
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 741,
            column: 54
          },
          end: {
            line: 741,
            column: 55
          }
        },
        loc: {
          start: {
            line: 741,
            column: 63
          },
          end: {
            line: 744,
            column: 9
          }
        },
        line: 741
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 766,
            column: 76
          },
          end: {
            line: 766,
            column: 77
          }
        },
        loc: {
          start: {
            line: 766,
            column: 85
          },
          end: {
            line: 768,
            column: 9
          }
        },
        line: 766
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 796,
            column: 46
          },
          end: {
            line: 796,
            column: 47
          }
        },
        loc: {
          start: {
            line: 796,
            column: 54
          },
          end: {
            line: 887,
            column: 7
          }
        },
        line: 796
      },
      '33': {
        name: '(anonymous_33)',
        decl: {
          start: {
            line: 874,
            column: 50
          },
          end: {
            line: 874,
            column: 51
          }
        },
        loc: {
          start: {
            line: 874,
            column: 65
          },
          end: {
            line: 885,
            column: 9
          }
        },
        line: 874
      },
      '34': {
        name: '(anonymous_34)',
        decl: {
          start: {
            line: 892,
            column: 38
          },
          end: {
            line: 892,
            column: 39
          }
        },
        loc: {
          start: {
            line: 892,
            column: 44
          },
          end: {
            line: 894,
            column: 3
          }
        },
        line: 892
      },
      '35': {
        name: '(anonymous_35)',
        decl: {
          start: {
            line: 895,
            column: 39
          },
          end: {
            line: 895,
            column: 40
          }
        },
        loc: {
          start: {
            line: 895,
            column: 45
          },
          end: {
            line: 899,
            column: 3
          }
        },
        line: 895
      },
      '36': {
        name: '(anonymous_36)',
        decl: {
          start: {
            line: 901,
            column: 27
          },
          end: {
            line: 901,
            column: 28
          }
        },
        loc: {
          start: {
            line: 901,
            column: 39
          },
          end: {
            line: 941,
            column: 3
          }
        },
        line: 901
      },
      '37': {
        name: '(anonymous_37)',
        decl: {
          start: {
            line: 966,
            column: 71
          },
          end: {
            line: 966,
            column: 72
          }
        },
        loc: {
          start: {
            line: 966,
            column: 79
          },
          end: {
            line: 1002,
            column: 3
          }
        },
        line: 966
      },
      '38': {
        name: '(anonymous_38)',
        decl: {
          start: {
            line: 983,
            column: 60
          },
          end: {
            line: 983,
            column: 61
          }
        },
        loc: {
          start: {
            line: 983,
            column: 66
          },
          end: {
            line: 985,
            column: 5
          }
        },
        line: 983
      },
      '39': {
        name: '(anonymous_39)',
        decl: {
          start: {
            line: 986,
            column: 44
          },
          end: {
            line: 986,
            column: 45
          }
        },
        loc: {
          start: {
            line: 986,
            column: 52
          },
          end: {
            line: 994,
            column: 5
          }
        },
        line: 986
      },
      '40': {
        name: '(anonymous_40)',
        decl: {
          start: {
            line: 996,
            column: 61
          },
          end: {
            line: 996,
            column: 62
          }
        },
        loc: {
          start: {
            line: 996,
            column: 69
          },
          end: {
            line: 1001,
            column: 5
          }
        },
        line: 996
      },
      '41': {
        name: '(anonymous_41)',
        decl: {
          start: {
            line: 1004,
            column: 24
          },
          end: {
            line: 1004,
            column: 25
          }
        },
        loc: {
          start: {
            line: 1004,
            column: 40
          },
          end: {
            line: 1011,
            column: 3
          }
        },
        line: 1004
      },
      '42': {
        name: '(anonymous_42)',
        decl: {
          start: {
            line: 1005,
            column: 116
          },
          end: {
            line: 1005,
            column: 117
          }
        },
        loc: {
          start: {
            line: 1005,
            column: 124
          },
          end: {
            line: 1010,
            column: 5
          }
        },
        line: 1005
      },
      '43': {
        name: '(anonymous_43)',
        decl: {
          start: {
            line: 1017,
            column: 61
          },
          end: {
            line: 1017,
            column: 62
          }
        },
        loc: {
          start: {
            line: 1017,
            column: 69
          },
          end: {
            line: 1034,
            column: 7
          }
        },
        line: 1017
      },
      '44': {
        name: '(anonymous_44)',
        decl: {
          start: {
            line: 1025,
            column: 96
          },
          end: {
            line: 1025,
            column: 97
          }
        },
        loc: {
          start: {
            line: 1025,
            column: 116
          },
          end: {
            line: 1033,
            column: 9
          }
        },
        line: 1025
      },
      '45': {
        name: '(anonymous_45)',
        decl: {
          start: {
            line: 1041,
            column: 139
          },
          end: {
            line: 1041,
            column: 140
          }
        },
        loc: {
          start: {
            line: 1041,
            column: 147
          },
          end: {
            line: 1045,
            column: 7
          }
        },
        line: 1041
      },
      '46': {
        name: '(anonymous_46)',
        decl: {
          start: {
            line: 1048,
            column: 63
          },
          end: {
            line: 1048,
            column: 64
          }
        },
        loc: {
          start: {
            line: 1048,
            column: 71
          },
          end: {
            line: 1057,
            column: 7
          }
        },
        line: 1048
      },
      '47': {
        name: '(anonymous_47)',
        decl: {
          start: {
            line: 1053,
            column: 52
          },
          end: {
            line: 1053,
            column: 53
          }
        },
        loc: {
          start: {
            line: 1053,
            column: 61
          },
          end: {
            line: 1056,
            column: 9
          }
        },
        line: 1053
      },
      '48': {
        name: '(anonymous_48)',
        decl: {
          start: {
            line: 1068,
            column: 20
          },
          end: {
            line: 1068,
            column: 21
          }
        },
        loc: {
          start: {
            line: 1068,
            column: 32
          },
          end: {
            line: 1070,
            column: 3
          }
        },
        line: 1068
      },
      '49': {
        name: '(anonymous_49)',
        decl: {
          start: {
            line: 1116,
            column: 66
          },
          end: {
            line: 1116,
            column: 67
          }
        },
        loc: {
          start: {
            line: 1116,
            column: 74
          },
          end: {
            line: 1122,
            column: 7
          }
        },
        line: 1116
      },
      '50': {
        name: '(anonymous_50)',
        decl: {
          start: {
            line: 1127,
            column: 86
          },
          end: {
            line: 1127,
            column: 87
          }
        },
        loc: {
          start: {
            line: 1127,
            column: 92
          },
          end: {
            line: 1129,
            column: 7
          }
        },
        line: 1127
      },
      '51': {
        name: '(anonymous_51)',
        decl: {
          start: {
            line: 1144,
            column: 31
          },
          end: {
            line: 1144,
            column: 32
          }
        },
        loc: {
          start: {
            line: 1144,
            column: 66
          },
          end: {
            line: 1148,
            column: 3
          }
        },
        line: 1144
      },
      '52': {
        name: '(anonymous_52)',
        decl: {
          start: {
            line: 1150,
            column: 27
          },
          end: {
            line: 1150,
            column: 28
          }
        },
        loc: {
          start: {
            line: 1150,
            column: 74
          },
          end: {
            line: 1153,
            column: 3
          }
        },
        line: 1150
      },
      '53': {
        name: '(anonymous_53)',
        decl: {
          start: {
            line: 1194,
            column: 49
          },
          end: {
            line: 1194,
            column: 50
          }
        },
        loc: {
          start: {
            line: 1194,
            column: 57
          },
          end: {
            line: 1264,
            column: 7
          }
        },
        line: 1194
      },
      '54': {
        name: '(anonymous_54)',
        decl: {
          start: {
            line: 1230,
            column: 42
          },
          end: {
            line: 1230,
            column: 43
          }
        },
        loc: {
          start: {
            line: 1230,
            column: 55
          },
          end: {
            line: 1249,
            column: 17
          }
        },
        line: 1230
      },
      '55': {
        name: '(anonymous_55)',
        decl: {
          start: {
            line: 1271,
            column: 67
          },
          end: {
            line: 1271,
            column: 68
          }
        },
        loc: {
          start: {
            line: 1271,
            column: 75
          },
          end: {
            line: 1301,
            column: 7
          }
        },
        line: 1271
      },
      '56': {
        name: '(anonymous_56)',
        decl: {
          start: {
            line: 1316,
            column: 68
          },
          end: {
            line: 1316,
            column: 69
          }
        },
        loc: {
          start: {
            line: 1316,
            column: 76
          },
          end: {
            line: 1338,
            column: 3
          }
        },
        line: 1316
      },
      '57': {
        name: '(anonymous_57)',
        decl: {
          start: {
            line: 1346,
            column: 27
          },
          end: {
            line: 1346,
            column: 28
          }
        },
        loc: {
          start: {
            line: 1346,
            column: 54
          },
          end: {
            line: 1356,
            column: 3
          }
        },
        line: 1346
      },
      '58': {
        name: '(anonymous_58)',
        decl: {
          start: {
            line: 1349,
            column: 20
          },
          end: {
            line: 1349,
            column: 21
          }
        },
        loc: {
          start: {
            line: 1349,
            column: 28
          },
          end: {
            line: 1353,
            column: 5
          }
        },
        line: 1349
      },
      '59': {
        name: '(anonymous_59)',
        decl: {
          start: {
            line: 1363,
            column: 69
          },
          end: {
            line: 1363,
            column: 70
          }
        },
        loc: {
          start: {
            line: 1363,
            column: 77
          },
          end: {
            line: 1465,
            column: 3
          }
        },
        line: 1363
      },
      '60': {
        name: '(anonymous_60)',
        decl: {
          start: {
            line: 1364,
            column: 20
          },
          end: {
            line: 1364,
            column: 21
          }
        },
        loc: {
          start: {
            line: 1364,
            column: 29
          },
          end: {
            line: 1392,
            column: 5
          }
        },
        line: 1364
      },
      '61': {
        name: '(anonymous_61)',
        decl: {
          start: {
            line: 1442,
            column: 45
          },
          end: {
            line: 1442,
            column: 46
          }
        },
        loc: {
          start: {
            line: 1442,
            column: 54
          },
          end: {
            line: 1447,
            column: 11
          }
        },
        line: 1442
      },
      '62': {
        name: '(anonymous_62)',
        decl: {
          start: {
            line: 1447,
            column: 21
          },
          end: {
            line: 1447,
            column: 22
          }
        },
        loc: {
          start: {
            line: 1447,
            column: 29
          },
          end: {
            line: 1449,
            column: 11
          }
        },
        line: 1447
      },
      '63': {
        name: '(anonymous_63)',
        decl: {
          start: {
            line: 1468,
            column: 75
          },
          end: {
            line: 1468,
            column: 76
          }
        },
        loc: {
          start: {
            line: 1468,
            column: 83
          },
          end: {
            line: 1532,
            column: 3
          }
        },
        line: 1468
      },
      '64': {
        name: '(anonymous_64)',
        decl: {
          start: {
            line: 1536,
            column: 34
          },
          end: {
            line: 1536,
            column: 35
          }
        },
        loc: {
          start: {
            line: 1536,
            column: 46
          },
          end: {
            line: 1541,
            column: 3
          }
        },
        line: 1536
      },
      '65': {
        name: '(anonymous_65)',
        decl: {
          start: {
            line: 1547,
            column: 76
          },
          end: {
            line: 1547,
            column: 77
          }
        },
        loc: {
          start: {
            line: 1547,
            column: 85
          },
          end: {
            line: 1555,
            column: 7
          }
        },
        line: 1547
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 77,
            column: 4
          },
          end: {
            line: 80,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 77,
            column: 4
          },
          end: {
            line: 80,
            column: 5
          }
        }, {
          start: {
            line: 77,
            column: 4
          },
          end: {
            line: 80,
            column: 5
          }
        }],
        line: 77
      },
      '1': {
        loc: {
          start: {
            line: 82,
            column: 4
          },
          end: {
            line: 86,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 82,
            column: 4
          },
          end: {
            line: 86,
            column: 5
          }
        }, {
          start: {
            line: 82,
            column: 4
          },
          end: {
            line: 86,
            column: 5
          }
        }],
        line: 82
      },
      '2': {
        loc: {
          start: {
            line: 94,
            column: 4
          },
          end: {
            line: 98,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 94,
            column: 4
          },
          end: {
            line: 98,
            column: 5
          }
        }, {
          start: {
            line: 94,
            column: 4
          },
          end: {
            line: 98,
            column: 5
          }
        }],
        line: 94
      },
      '3': {
        loc: {
          start: {
            line: 107,
            column: 4
          },
          end: {
            line: 110,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 107,
            column: 4
          },
          end: {
            line: 110,
            column: 5
          }
        }, {
          start: {
            line: 107,
            column: 4
          },
          end: {
            line: 110,
            column: 5
          }
        }],
        line: 107
      },
      '4': {
        loc: {
          start: {
            line: 124,
            column: 4
          },
          end: {
            line: 126,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 124,
            column: 4
          },
          end: {
            line: 126,
            column: 5
          }
        }, {
          start: {
            line: 124,
            column: 4
          },
          end: {
            line: 126,
            column: 5
          }
        }],
        line: 124
      },
      '5': {
        loc: {
          start: {
            line: 127,
            column: 4
          },
          end: {
            line: 129,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 127,
            column: 4
          },
          end: {
            line: 129,
            column: 5
          }
        }, {
          start: {
            line: 127,
            column: 4
          },
          end: {
            line: 129,
            column: 5
          }
        }],
        line: 127
      },
      '6': {
        loc: {
          start: {
            line: 130,
            column: 4
          },
          end: {
            line: 132,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 130,
            column: 4
          },
          end: {
            line: 132,
            column: 5
          }
        }, {
          start: {
            line: 130,
            column: 4
          },
          end: {
            line: 132,
            column: 5
          }
        }],
        line: 130
      },
      '7': {
        loc: {
          start: {
            line: 133,
            column: 4
          },
          end: {
            line: 135,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 133,
            column: 4
          },
          end: {
            line: 135,
            column: 5
          }
        }, {
          start: {
            line: 133,
            column: 4
          },
          end: {
            line: 135,
            column: 5
          }
        }],
        line: 133
      },
      '8': {
        loc: {
          start: {
            line: 136,
            column: 4
          },
          end: {
            line: 138,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 136,
            column: 4
          },
          end: {
            line: 138,
            column: 5
          }
        }, {
          start: {
            line: 136,
            column: 4
          },
          end: {
            line: 138,
            column: 5
          }
        }],
        line: 136
      },
      '9': {
        loc: {
          start: {
            line: 158,
            column: 16
          },
          end: {
            line: 158,
            column: 128
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 158,
            column: 16
          },
          end: {
            line: 158,
            column: 106
          }
        }, {
          start: {
            line: 158,
            column: 110
          },
          end: {
            line: 158,
            column: 128
          }
        }],
        line: 158
      },
      '10': {
        loc: {
          start: {
            line: 162,
            column: 6
          },
          end: {
            line: 171,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 162,
            column: 6
          },
          end: {
            line: 171,
            column: 7
          }
        }, {
          start: {
            line: 162,
            column: 6
          },
          end: {
            line: 171,
            column: 7
          }
        }],
        line: 162
      },
      '11': {
        loc: {
          start: {
            line: 165,
            column: 10
          },
          end: {
            line: 169,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 165,
            column: 10
          },
          end: {
            line: 169,
            column: 11
          }
        }, {
          start: {
            line: 165,
            column: 10
          },
          end: {
            line: 169,
            column: 11
          }
        }],
        line: 165
      },
      '12': {
        loc: {
          start: {
            line: 176,
            column: 8
          },
          end: {
            line: 178,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 176,
            column: 8
          },
          end: {
            line: 178,
            column: 9
          }
        }, {
          start: {
            line: 176,
            column: 8
          },
          end: {
            line: 178,
            column: 9
          }
        }],
        line: 176
      },
      '13': {
        loc: {
          start: {
            line: 176,
            column: 12
          },
          end: {
            line: 176,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 176,
            column: 12
          },
          end: {
            line: 176,
            column: 18
          }
        }, {
          start: {
            line: 176,
            column: 22
          },
          end: {
            line: 176,
            column: 41
          }
        }],
        line: 176
      },
      '14': {
        loc: {
          start: {
            line: 181,
            column: 8
          },
          end: {
            line: 183,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 181,
            column: 8
          },
          end: {
            line: 183,
            column: 9
          }
        }, {
          start: {
            line: 181,
            column: 8
          },
          end: {
            line: 183,
            column: 9
          }
        }],
        line: 181
      },
      '15': {
        loc: {
          start: {
            line: 185,
            column: 8
          },
          end: {
            line: 193,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 185,
            column: 8
          },
          end: {
            line: 193,
            column: 9
          }
        }, {
          start: {
            line: 185,
            column: 8
          },
          end: {
            line: 193,
            column: 9
          }
        }],
        line: 185
      },
      '16': {
        loc: {
          start: {
            line: 188,
            column: 12
          },
          end: {
            line: 190,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 188,
            column: 12
          },
          end: {
            line: 190,
            column: 13
          }
        }, {
          start: {
            line: 188,
            column: 12
          },
          end: {
            line: 190,
            column: 13
          }
        }],
        line: 188
      },
      '17': {
        loc: {
          start: {
            line: 211,
            column: 12
          },
          end: {
            line: 213,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 211,
            column: 12
          },
          end: {
            line: 213,
            column: 13
          }
        }, {
          start: {
            line: 211,
            column: 12
          },
          end: {
            line: 213,
            column: 13
          }
        }],
        line: 211
      },
      '18': {
        loc: {
          start: {
            line: 211,
            column: 16
          },
          end: {
            line: 211,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 211,
            column: 16
          },
          end: {
            line: 211,
            column: 36
          }
        }, {
          start: {
            line: 211,
            column: 40
          },
          end: {
            line: 211,
            column: 47
          }
        }],
        line: 211
      },
      '19': {
        loc: {
          start: {
            line: 226,
            column: 26
          },
          end: {
            line: 226,
            column: 80
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 226,
            column: 26
          },
          end: {
            line: 226,
            column: 75
          }
        }, {
          start: {
            line: 226,
            column: 79
          },
          end: {
            line: 226,
            column: 80
          }
        }],
        line: 226
      },
      '20': {
        loc: {
          start: {
            line: 228,
            column: 22
          },
          end: {
            line: 228,
            column: 83
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 228,
            column: 22
          },
          end: {
            line: 228,
            column: 78
          }
        }, {
          start: {
            line: 228,
            column: 82
          },
          end: {
            line: 228,
            column: 83
          }
        }],
        line: 228
      },
      '21': {
        loc: {
          start: {
            line: 232,
            column: 51
          },
          end: {
            line: 232,
            column: 82
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 232,
            column: 51
          },
          end: {
            line: 232,
            column: 66
          }
        }, {
          start: {
            line: 232,
            column: 70
          },
          end: {
            line: 232,
            column: 82
          }
        }],
        line: 232
      },
      '22': {
        loc: {
          start: {
            line: 237,
            column: 6
          },
          end: {
            line: 239,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 237,
            column: 6
          },
          end: {
            line: 239,
            column: 7
          }
        }, {
          start: {
            line: 237,
            column: 6
          },
          end: {
            line: 239,
            column: 7
          }
        }],
        line: 237
      },
      '23': {
        loc: {
          start: {
            line: 237,
            column: 10
          },
          end: {
            line: 237,
            column: 37
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 237,
            column: 10
          },
          end: {
            line: 237,
            column: 15
          }
        }, {
          start: {
            line: 237,
            column: 19
          },
          end: {
            line: 237,
            column: 37
          }
        }],
        line: 237
      },
      '24': {
        loc: {
          start: {
            line: 241,
            column: 6
          },
          end: {
            line: 245,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 241,
            column: 6
          },
          end: {
            line: 245,
            column: 7
          }
        }, {
          start: {
            line: 241,
            column: 6
          },
          end: {
            line: 245,
            column: 7
          }
        }],
        line: 241
      },
      '25': {
        loc: {
          start: {
            line: 247,
            column: 6
          },
          end: {
            line: 249,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 247,
            column: 6
          },
          end: {
            line: 249,
            column: 7
          }
        }, {
          start: {
            line: 247,
            column: 6
          },
          end: {
            line: 249,
            column: 7
          }
        }],
        line: 247
      },
      '26': {
        loc: {
          start: {
            line: 254,
            column: 6
          },
          end: {
            line: 256,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 254,
            column: 6
          },
          end: {
            line: 256,
            column: 7
          }
        }, {
          start: {
            line: 254,
            column: 6
          },
          end: {
            line: 256,
            column: 7
          }
        }],
        line: 254
      },
      '27': {
        loc: {
          start: {
            line: 269,
            column: 8
          },
          end: {
            line: 271,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 269,
            column: 8
          },
          end: {
            line: 271,
            column: 9
          }
        }, {
          start: {
            line: 269,
            column: 8
          },
          end: {
            line: 271,
            column: 9
          }
        }],
        line: 269
      },
      '28': {
        loc: {
          start: {
            line: 274,
            column: 8
          },
          end: {
            line: 281,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 274,
            column: 8
          },
          end: {
            line: 281,
            column: 9
          }
        }, {
          start: {
            line: 274,
            column: 8
          },
          end: {
            line: 281,
            column: 9
          }
        }],
        line: 274
      },
      '29': {
        loc: {
          start: {
            line: 274,
            column: 12
          },
          end: {
            line: 274,
            column: 71
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 274,
            column: 12
          },
          end: {
            line: 274,
            column: 30
          }
        }, {
          start: {
            line: 274,
            column: 34
          },
          end: {
            line: 274,
            column: 71
          }
        }],
        line: 274
      },
      '30': {
        loc: {
          start: {
            line: 289,
            column: 10
          },
          end: {
            line: 291,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 289,
            column: 10
          },
          end: {
            line: 291,
            column: 11
          }
        }, {
          start: {
            line: 289,
            column: 10
          },
          end: {
            line: 291,
            column: 11
          }
        }],
        line: 289
      },
      '31': {
        loc: {
          start: {
            line: 290,
            column: 36
          },
          end: {
            line: 290,
            column: 119
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 290,
            column: 78
          },
          end: {
            line: 290,
            column: 82
          }
        }, {
          start: {
            line: 290,
            column: 97
          },
          end: {
            line: 290,
            column: 118
          }
        }],
        line: 290
      },
      '32': {
        loc: {
          start: {
            line: 290,
            column: 37
          },
          end: {
            line: 290,
            column: 74
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 290,
            column: 37
          },
          end: {
            line: 290,
            column: 52
          }
        }, {
          start: {
            line: 290,
            column: 56
          },
          end: {
            line: 290,
            column: 74
          }
        }],
        line: 290
      },
      '33': {
        loc: {
          start: {
            line: 294,
            column: 10
          },
          end: {
            line: 296,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 294,
            column: 10
          },
          end: {
            line: 296,
            column: 11
          }
        }, {
          start: {
            line: 294,
            column: 10
          },
          end: {
            line: 296,
            column: 11
          }
        }],
        line: 294
      },
      '34': {
        loc: {
          start: {
            line: 301,
            column: 12
          },
          end: {
            line: 304,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 301,
            column: 12
          },
          end: {
            line: 304,
            column: 13
          }
        }, {
          start: {
            line: 301,
            column: 12
          },
          end: {
            line: 304,
            column: 13
          }
        }],
        line: 301
      },
      '35': {
        loc: {
          start: {
            line: 318,
            column: 12
          },
          end: {
            line: 320,
            column: 141
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 319,
            column: 12
          },
          end: {
            line: 319,
            column: 92
          }
        }, {
          start: {
            line: 320,
            column: 12
          },
          end: {
            line: 320,
            column: 141
          }
        }],
        line: 318
      },
      '36': {
        loc: {
          start: {
            line: 323,
            column: 21
          },
          end: {
            line: 325,
            column: 143
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 324,
            column: 16
          },
          end: {
            line: 324,
            column: 94
          }
        }, {
          start: {
            line: 325,
            column: 16
          },
          end: {
            line: 325,
            column: 143
          }
        }],
        line: 323
      },
      '37': {
        loc: {
          start: {
            line: 329,
            column: 14
          },
          end: {
            line: 331,
            column: 142
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 330,
            column: 14
          },
          end: {
            line: 330,
            column: 93
          }
        }, {
          start: {
            line: 331,
            column: 14
          },
          end: {
            line: 331,
            column: 142
          }
        }],
        line: 329
      },
      '38': {
        loc: {
          start: {
            line: 351,
            column: 6
          },
          end: {
            line: 353,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 351,
            column: 6
          },
          end: {
            line: 353,
            column: 7
          }
        }, {
          start: {
            line: 351,
            column: 6
          },
          end: {
            line: 353,
            column: 7
          }
        }],
        line: 351
      },
      '39': {
        loc: {
          start: {
            line: 407,
            column: 63
          },
          end: {
            line: 407,
            column: 122
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 407,
            column: 90
          },
          end: {
            line: 407,
            column: 92
          }
        }, {
          start: {
            line: 407,
            column: 96
          },
          end: {
            line: 407,
            column: 121
          }
        }],
        line: 407
      },
      '40': {
        loc: {
          start: {
            line: 501,
            column: 6
          },
          end: {
            line: 511,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 501,
            column: 6
          },
          end: {
            line: 511,
            column: 7
          }
        }, {
          start: {
            line: 501,
            column: 6
          },
          end: {
            line: 511,
            column: 7
          }
        }],
        line: 501
      },
      '41': {
        loc: {
          start: {
            line: 503,
            column: 10
          },
          end: {
            line: 506,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 503,
            column: 10
          },
          end: {
            line: 506,
            column: 11
          }
        }, {
          start: {
            line: 503,
            column: 10
          },
          end: {
            line: 506,
            column: 11
          }
        }],
        line: 503
      },
      '42': {
        loc: {
          start: {
            line: 508,
            column: 8
          },
          end: {
            line: 510,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 508,
            column: 8
          },
          end: {
            line: 510,
            column: 9
          }
        }, {
          start: {
            line: 508,
            column: 8
          },
          end: {
            line: 510,
            column: 9
          }
        }],
        line: 508
      },
      '43': {
        loc: {
          start: {
            line: 580,
            column: 10
          },
          end: {
            line: 583,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 580,
            column: 10
          },
          end: {
            line: 583,
            column: 11
          }
        }, {
          start: {
            line: 580,
            column: 10
          },
          end: {
            line: 583,
            column: 11
          }
        }],
        line: 580
      },
      '44': {
        loc: {
          start: {
            line: 582,
            column: 30
          },
          end: {
            line: 582,
            column: 45
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 582,
            column: 30
          },
          end: {
            line: 582,
            column: 39
          }
        }, {
          start: {
            line: 582,
            column: 43
          },
          end: {
            line: 582,
            column: 45
          }
        }],
        line: 582
      },
      '45': {
        loc: {
          start: {
            line: 584,
            column: 76
          },
          end: {
            line: 584,
            column: 90
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 584,
            column: 76
          },
          end: {
            line: 584,
            column: 85
          }
        }, {
          start: {
            line: 584,
            column: 89
          },
          end: {
            line: 584,
            column: 90
          }
        }],
        line: 584
      },
      '46': {
        loc: {
          start: {
            line: 587,
            column: 15
          },
          end: {
            line: 587,
            column: 53
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 587,
            column: 29
          },
          end: {
            line: 587,
            column: 45
          }
        }, {
          start: {
            line: 587,
            column: 49
          },
          end: {
            line: 587,
            column: 53
          }
        }],
        line: 587
      },
      '47': {
        loc: {
          start: {
            line: 591,
            column: 15
          },
          end: {
            line: 591,
            column: 53
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 591,
            column: 29
          },
          end: {
            line: 591,
            column: 45
          }
        }, {
          start: {
            line: 591,
            column: 49
          },
          end: {
            line: 591,
            column: 53
          }
        }],
        line: 591
      },
      '48': {
        loc: {
          start: {
            line: 593,
            column: 40
          },
          end: {
            line: 593,
            column: 101
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 593,
            column: 41
          },
          end: {
            line: 593,
            column: 55
          }
        }, {
          start: {
            line: 593,
            column: 59
          },
          end: {
            line: 593,
            column: 69
          }
        }, {
          start: {
            line: 593,
            column: 74
          },
          end: {
            line: 593,
            column: 101
          }
        }],
        line: 593
      },
      '49': {
        loc: {
          start: {
            line: 608,
            column: 15
          },
          end: {
            line: 608,
            column: 30
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 608,
            column: 15
          },
          end: {
            line: 608,
            column: 22
          }
        }, {
          start: {
            line: 608,
            column: 26
          },
          end: {
            line: 608,
            column: 30
          }
        }],
        line: 608
      },
      '50': {
        loc: {
          start: {
            line: 614,
            column: 10
          },
          end: {
            line: 616,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 614,
            column: 10
          },
          end: {
            line: 616,
            column: 11
          }
        }, {
          start: {
            line: 614,
            column: 10
          },
          end: {
            line: 616,
            column: 11
          }
        }],
        line: 614
      },
      '51': {
        loc: {
          start: {
            line: 619,
            column: 15
          },
          end: {
            line: 619,
            column: 30
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 619,
            column: 15
          },
          end: {
            line: 619,
            column: 22
          }
        }, {
          start: {
            line: 619,
            column: 26
          },
          end: {
            line: 619,
            column: 30
          }
        }],
        line: 619
      },
      '52': {
        loc: {
          start: {
            line: 625,
            column: 10
          },
          end: {
            line: 627,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 625,
            column: 10
          },
          end: {
            line: 627,
            column: 11
          }
        }, {
          start: {
            line: 625,
            column: 10
          },
          end: {
            line: 627,
            column: 11
          }
        }],
        line: 625
      },
      '53': {
        loc: {
          start: {
            line: 629,
            column: 35
          },
          end: {
            line: 629,
            column: 89
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 629,
            column: 36
          },
          end: {
            line: 629,
            column: 47
          }
        }, {
          start: {
            line: 629,
            column: 51
          },
          end: {
            line: 629,
            column: 62
          }
        }, {
          start: {
            line: 629,
            column: 67
          },
          end: {
            line: 629,
            column: 89
          }
        }],
        line: 629
      },
      '54': {
        loc: {
          start: {
            line: 633,
            column: 23
          },
          end: {
            line: 633,
            column: 68
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 633,
            column: 23
          },
          end: {
            line: 633,
            column: 62
          }
        }, {
          start: {
            line: 633,
            column: 66
          },
          end: {
            line: 633,
            column: 68
          }
        }],
        line: 633
      },
      '55': {
        loc: {
          start: {
            line: 678,
            column: 8
          },
          end: {
            line: 680,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 678,
            column: 8
          },
          end: {
            line: 680,
            column: 9
          }
        }, {
          start: {
            line: 678,
            column: 8
          },
          end: {
            line: 680,
            column: 9
          }
        }],
        line: 678
      },
      '56': {
        loc: {
          start: {
            line: 694,
            column: 21
          },
          end: {
            line: 694,
            column: 45
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 694,
            column: 37
          },
          end: {
            line: 694,
            column: 40
          }
        }, {
          start: {
            line: 694,
            column: 43
          },
          end: {
            line: 694,
            column: 45
          }
        }],
        line: 694
      },
      '57': {
        loc: {
          start: {
            line: 698,
            column: 6
          },
          end: {
            line: 700,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 698,
            column: 6
          },
          end: {
            line: 700,
            column: 7
          }
        }, {
          start: {
            line: 698,
            column: 6
          },
          end: {
            line: 700,
            column: 7
          }
        }],
        line: 698
      },
      '58': {
        loc: {
          start: {
            line: 701,
            column: 6
          },
          end: {
            line: 703,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 701,
            column: 6
          },
          end: {
            line: 703,
            column: 7
          }
        }, {
          start: {
            line: 701,
            column: 6
          },
          end: {
            line: 703,
            column: 7
          }
        }],
        line: 701
      },
      '59': {
        loc: {
          start: {
            line: 707,
            column: 6
          },
          end: {
            line: 709,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 707,
            column: 6
          },
          end: {
            line: 709,
            column: 7
          }
        }, {
          start: {
            line: 707,
            column: 6
          },
          end: {
            line: 709,
            column: 7
          }
        }],
        line: 707
      },
      '60': {
        loc: {
          start: {
            line: 719,
            column: 6
          },
          end: {
            line: 722,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 719,
            column: 6
          },
          end: {
            line: 722,
            column: 7
          }
        }, {
          start: {
            line: 719,
            column: 6
          },
          end: {
            line: 722,
            column: 7
          }
        }],
        line: 719
      },
      '61': {
        loc: {
          start: {
            line: 728,
            column: 6
          },
          end: {
            line: 733,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 728,
            column: 6
          },
          end: {
            line: 733,
            column: 7
          }
        }, {
          start: {
            line: 728,
            column: 6
          },
          end: {
            line: 733,
            column: 7
          }
        }],
        line: 728
      },
      '62': {
        loc: {
          start: {
            line: 753,
            column: 8
          },
          end: {
            line: 757,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 753,
            column: 8
          },
          end: {
            line: 757,
            column: 9
          }
        }, {
          start: {
            line: 753,
            column: 8
          },
          end: {
            line: 757,
            column: 9
          }
        }],
        line: 753
      },
      '63': {
        loc: {
          start: {
            line: 759,
            column: 6
          },
          end: {
            line: 761,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 759,
            column: 6
          },
          end: {
            line: 761,
            column: 7
          }
        }, {
          start: {
            line: 759,
            column: 6
          },
          end: {
            line: 761,
            column: 7
          }
        }],
        line: 759
      },
      '64': {
        loc: {
          start: {
            line: 765,
            column: 6
          },
          end: {
            line: 770,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 765,
            column: 6
          },
          end: {
            line: 770,
            column: 7
          }
        }, {
          start: {
            line: 765,
            column: 6
          },
          end: {
            line: 770,
            column: 7
          }
        }],
        line: 765
      },
      '65': {
        loc: {
          start: {
            line: 776,
            column: 6
          },
          end: {
            line: 778,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 776,
            column: 6
          },
          end: {
            line: 778,
            column: 7
          }
        }, {
          start: {
            line: 776,
            column: 6
          },
          end: {
            line: 778,
            column: 7
          }
        }],
        line: 776
      },
      '66': {
        loc: {
          start: {
            line: 776,
            column: 10
          },
          end: {
            line: 776,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 776,
            column: 10
          },
          end: {
            line: 776,
            column: 31
          }
        }, {
          start: {
            line: 776,
            column: 35
          },
          end: {
            line: 776,
            column: 52
          }
        }],
        line: 776
      },
      '67': {
        loc: {
          start: {
            line: 779,
            column: 23
          },
          end: {
            line: 779,
            column: 82
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 779,
            column: 23
          },
          end: {
            line: 779,
            column: 65
          }
        }, {
          start: {
            line: 779,
            column: 69
          },
          end: {
            line: 779,
            column: 82
          }
        }],
        line: 779
      },
      '68': {
        loc: {
          start: {
            line: 782,
            column: 6
          },
          end: {
            line: 784,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 782,
            column: 6
          },
          end: {
            line: 784,
            column: 7
          }
        }, {
          start: {
            line: 782,
            column: 6
          },
          end: {
            line: 784,
            column: 7
          }
        }],
        line: 782
      },
      '69': {
        loc: {
          start: {
            line: 799,
            column: 10
          },
          end: {
            line: 802,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 799,
            column: 10
          },
          end: {
            line: 802,
            column: 11
          }
        }, {
          start: {
            line: 799,
            column: 10
          },
          end: {
            line: 802,
            column: 11
          }
        }],
        line: 799
      },
      '70': {
        loc: {
          start: {
            line: 799,
            column: 14
          },
          end: {
            line: 799,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 799,
            column: 14
          },
          end: {
            line: 799,
            column: 31
          }
        }, {
          start: {
            line: 799,
            column: 35
          },
          end: {
            line: 799,
            column: 54
          }
        }],
        line: 799
      },
      '71': {
        loc: {
          start: {
            line: 806,
            column: 10
          },
          end: {
            line: 808,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 806,
            column: 10
          },
          end: {
            line: 808,
            column: 11
          }
        }, {
          start: {
            line: 806,
            column: 10
          },
          end: {
            line: 808,
            column: 11
          }
        }],
        line: 806
      },
      '72': {
        loc: {
          start: {
            line: 807,
            column: 23
          },
          end: {
            line: 807,
            column: 56
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 807,
            column: 41
          },
          end: {
            line: 807,
            column: 49
          }
        }, {
          start: {
            line: 807,
            column: 52
          },
          end: {
            line: 807,
            column: 56
          }
        }],
        line: 807
      },
      '73': {
        loc: {
          start: {
            line: 870,
            column: 8
          },
          end: {
            line: 872,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 870,
            column: 8
          },
          end: {
            line: 872,
            column: 9
          }
        }, {
          start: {
            line: 870,
            column: 8
          },
          end: {
            line: 872,
            column: 9
          }
        }],
        line: 870
      },
      '74': {
        loc: {
          start: {
            line: 881,
            column: 17
          },
          end: {
            line: 881,
            column: 134
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 881,
            column: 56
          },
          end: {
            line: 881,
            column: 127
          }
        }, {
          start: {
            line: 881,
            column: 130
          },
          end: {
            line: 881,
            column: 134
          }
        }],
        line: 881
      },
      '75': {
        loc: {
          start: {
            line: 882,
            column: 15
          },
          end: {
            line: 882,
            column: 158
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 882,
            column: 52
          },
          end: {
            line: 882,
            column: 153
          }
        }, {
          start: {
            line: 882,
            column: 156
          },
          end: {
            line: 882,
            column: 158
          }
        }],
        line: 882
      },
      '76': {
        loc: {
          start: {
            line: 946,
            column: 32
          },
          end: {
            line: 946,
            column: 130
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 946,
            column: 83
          },
          end: {
            line: 946,
            column: 100
          }
        }, {
          start: {
            line: 946,
            column: 103
          },
          end: {
            line: 946,
            column: 130
          }
        }],
        line: 946
      },
      '77': {
        loc: {
          start: {
            line: 947,
            column: 14
          },
          end: {
            line: 947,
            column: 112
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 947,
            column: 65
          },
          end: {
            line: 947,
            column: 82
          }
        }, {
          start: {
            line: 947,
            column: 85
          },
          end: {
            line: 947,
            column: 112
          }
        }],
        line: 947
      },
      '78': {
        loc: {
          start: {
            line: 960,
            column: 4
          },
          end: {
            line: 963,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 960,
            column: 4
          },
          end: {
            line: 963,
            column: 5
          }
        }, {
          start: {
            line: 960,
            column: 4
          },
          end: {
            line: 963,
            column: 5
          }
        }],
        line: 960
      },
      '79': {
        loc: {
          start: {
            line: 967,
            column: 16
          },
          end: {
            line: 967,
            column: 38
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 967,
            column: 16
          },
          end: {
            line: 967,
            column: 33
          }
        }, {
          start: {
            line: 967,
            column: 37
          },
          end: {
            line: 967,
            column: 38
          }
        }],
        line: 967
      },
      '80': {
        loc: {
          start: {
            line: 971,
            column: 4
          },
          end: {
            line: 977,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 971,
            column: 4
          },
          end: {
            line: 977,
            column: 5
          }
        }, {
          start: {
            line: 971,
            column: 4
          },
          end: {
            line: 977,
            column: 5
          }
        }],
        line: 971
      },
      '81': {
        loc: {
          start: {
            line: 972,
            column: 6
          },
          end: {
            line: 976,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 972,
            column: 6
          },
          end: {
            line: 976,
            column: 7
          }
        }, {
          start: {
            line: 972,
            column: 6
          },
          end: {
            line: 976,
            column: 7
          }
        }],
        line: 972
      },
      '82': {
        loc: {
          start: {
            line: 980,
            column: 6
          },
          end: {
            line: 980,
            column: 94
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 980,
            column: 6
          },
          end: {
            line: 980,
            column: 86
          }
        }, {
          start: {
            line: 980,
            column: 90
          },
          end: {
            line: 980,
            column: 94
          }
        }],
        line: 980
      },
      '83': {
        loc: {
          start: {
            line: 988,
            column: 8
          },
          end: {
            line: 991,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 988,
            column: 8
          },
          end: {
            line: 991,
            column: 9
          }
        }, {
          start: {
            line: 988,
            column: 8
          },
          end: {
            line: 991,
            column: 9
          }
        }],
        line: 988
      },
      '84': {
        loc: {
          start: {
            line: 998,
            column: 6
          },
          end: {
            line: 1000,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 998,
            column: 6
          },
          end: {
            line: 1000,
            column: 7
          }
        }, {
          start: {
            line: 998,
            column: 6
          },
          end: {
            line: 1000,
            column: 7
          }
        }],
        line: 998
      },
      '85': {
        loc: {
          start: {
            line: 1007,
            column: 6
          },
          end: {
            line: 1009,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1007,
            column: 6
          },
          end: {
            line: 1009,
            column: 7
          }
        }, {
          start: {
            line: 1007,
            column: 6
          },
          end: {
            line: 1009,
            column: 7
          }
        }],
        line: 1007
      },
      '86': {
        loc: {
          start: {
            line: 1019,
            column: 10
          },
          end: {
            line: 1021,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1019,
            column: 10
          },
          end: {
            line: 1021,
            column: 11
          }
        }, {
          start: {
            line: 1019,
            column: 10
          },
          end: {
            line: 1021,
            column: 11
          }
        }],
        line: 1019
      },
      '87': {
        loc: {
          start: {
            line: 1026,
            column: 10
          },
          end: {
            line: 1028,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1026,
            column: 10
          },
          end: {
            line: 1028,
            column: 11
          }
        }, {
          start: {
            line: 1026,
            column: 10
          },
          end: {
            line: 1028,
            column: 11
          }
        }],
        line: 1026
      },
      '88': {
        loc: {
          start: {
            line: 1026,
            column: 15
          },
          end: {
            line: 1026,
            column: 36
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 1026,
            column: 15
          },
          end: {
            line: 1026,
            column: 30
          }
        }, {
          start: {
            line: 1026,
            column: 34
          },
          end: {
            line: 1026,
            column: 36
          }
        }],
        line: 1026
      },
      '89': {
        loc: {
          start: {
            line: 1026,
            column: 55
          },
          end: {
            line: 1026,
            column: 76
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 1026,
            column: 55
          },
          end: {
            line: 1026,
            column: 70
          }
        }, {
          start: {
            line: 1026,
            column: 74
          },
          end: {
            line: 1026,
            column: 76
          }
        }],
        line: 1026
      },
      '90': {
        loc: {
          start: {
            line: 1029,
            column: 10
          },
          end: {
            line: 1031,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1029,
            column: 10
          },
          end: {
            line: 1031,
            column: 11
          }
        }, {
          start: {
            line: 1029,
            column: 10
          },
          end: {
            line: 1031,
            column: 11
          }
        }],
        line: 1029
      },
      '91': {
        loc: {
          start: {
            line: 1029,
            column: 15
          },
          end: {
            line: 1029,
            column: 36
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 1029,
            column: 15
          },
          end: {
            line: 1029,
            column: 30
          }
        }, {
          start: {
            line: 1029,
            column: 34
          },
          end: {
            line: 1029,
            column: 36
          }
        }],
        line: 1029
      },
      '92': {
        loc: {
          start: {
            line: 1029,
            column: 55
          },
          end: {
            line: 1029,
            column: 76
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 1029,
            column: 55
          },
          end: {
            line: 1029,
            column: 70
          }
        }, {
          start: {
            line: 1029,
            column: 74
          },
          end: {
            line: 1029,
            column: 76
          }
        }],
        line: 1029
      },
      '93': {
        loc: {
          start: {
            line: 1042,
            column: 8
          },
          end: {
            line: 1044,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1042,
            column: 8
          },
          end: {
            line: 1044,
            column: 9
          }
        }, {
          start: {
            line: 1042,
            column: 8
          },
          end: {
            line: 1044,
            column: 9
          }
        }],
        line: 1042
      },
      '94': {
        loc: {
          start: {
            line: 1157,
            column: 6
          },
          end: {
            line: 1159,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1157,
            column: 6
          },
          end: {
            line: 1159,
            column: 7
          }
        }, {
          start: {
            line: 1157,
            column: 6
          },
          end: {
            line: 1159,
            column: 7
          }
        }],
        line: 1157
      },
      '95': {
        loc: {
          start: {
            line: 1160,
            column: 6
          },
          end: {
            line: 1164,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1160,
            column: 6
          },
          end: {
            line: 1164,
            column: 7
          }
        }, {
          start: {
            line: 1160,
            column: 6
          },
          end: {
            line: 1164,
            column: 7
          }
        }],
        line: 1160
      },
      '96': {
        loc: {
          start: {
            line: 1169,
            column: 6
          },
          end: {
            line: 1188,
            column: 7
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 1170,
            column: 8
          },
          end: {
            line: 1172,
            column: 16
          }
        }, {
          start: {
            line: 1173,
            column: 8
          },
          end: {
            line: 1175,
            column: 16
          }
        }, {
          start: {
            line: 1176,
            column: 8
          },
          end: {
            line: 1178,
            column: 16
          }
        }, {
          start: {
            line: 1179,
            column: 8
          },
          end: {
            line: 1181,
            column: 16
          }
        }, {
          start: {
            line: 1182,
            column: 8
          },
          end: {
            line: 1184,
            column: 16
          }
        }, {
          start: {
            line: 1185,
            column: 8
          },
          end: {
            line: 1187,
            column: 16
          }
        }],
        line: 1169
      },
      '97': {
        loc: {
          start: {
            line: 1203,
            column: 10
          },
          end: {
            line: 1205,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1203,
            column: 10
          },
          end: {
            line: 1205,
            column: 11
          }
        }, {
          start: {
            line: 1203,
            column: 10
          },
          end: {
            line: 1205,
            column: 11
          }
        }],
        line: 1203
      },
      '98': {
        loc: {
          start: {
            line: 1213,
            column: 10
          },
          end: {
            line: 1256,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1213,
            column: 10
          },
          end: {
            line: 1256,
            column: 11
          }
        }, {
          start: {
            line: 1213,
            column: 10
          },
          end: {
            line: 1256,
            column: 11
          }
        }],
        line: 1213
      },
      '99': {
        loc: {
          start: {
            line: 1216,
            column: 30
          },
          end: {
            line: 1216,
            column: 123
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 1216,
            column: 68
          },
          end: {
            line: 1216,
            column: 103
          }
        }, {
          start: {
            line: 1216,
            column: 106
          },
          end: {
            line: 1216,
            column: 123
          }
        }],
        line: 1216
      },
      '100': {
        loc: {
          start: {
            line: 1217,
            column: 16
          },
          end: {
            line: 1219,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1217,
            column: 16
          },
          end: {
            line: 1219,
            column: 17
          }
        }, {
          start: {
            line: 1217,
            column: 16
          },
          end: {
            line: 1219,
            column: 17
          }
        }],
        line: 1217
      },
      '101': {
        loc: {
          start: {
            line: 1220,
            column: 16
          },
          end: {
            line: 1222,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1220,
            column: 16
          },
          end: {
            line: 1222,
            column: 17
          }
        }, {
          start: {
            line: 1220,
            column: 16
          },
          end: {
            line: 1222,
            column: 17
          }
        }],
        line: 1220
      },
      '102': {
        loc: {
          start: {
            line: 1233,
            column: 36
          },
          end: {
            line: 1233,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 1233,
            column: 36
          },
          end: {
            line: 1233,
            column: 53
          }
        }, {
          start: {
            line: 1233,
            column: 57
          },
          end: {
            line: 1233,
            column: 59
          }
        }],
        line: 1233
      },
      '103': {
        loc: {
          start: {
            line: 1234,
            column: 20
          },
          end: {
            line: 1242,
            column: 21
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1234,
            column: 20
          },
          end: {
            line: 1242,
            column: 21
          }
        }, {
          start: {
            line: 1234,
            column: 20
          },
          end: {
            line: 1242,
            column: 21
          }
        }],
        line: 1234
      },
      '104': {
        loc: {
          start: {
            line: 1235,
            column: 22
          },
          end: {
            line: 1237,
            column: 23
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1235,
            column: 22
          },
          end: {
            line: 1237,
            column: 23
          }
        }, {
          start: {
            line: 1235,
            column: 22
          },
          end: {
            line: 1237,
            column: 23
          }
        }],
        line: 1235
      },
      '105': {
        loc: {
          start: {
            line: 1239,
            column: 22
          },
          end: {
            line: 1241,
            column: 23
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1239,
            column: 22
          },
          end: {
            line: 1241,
            column: 23
          }
        }, {
          start: {
            line: 1239,
            column: 22
          },
          end: {
            line: 1241,
            column: 23
          }
        }],
        line: 1239
      },
      '106': {
        loc: {
          start: {
            line: 1259,
            column: 8
          },
          end: {
            line: 1262,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1259,
            column: 8
          },
          end: {
            line: 1262,
            column: 9
          }
        }, {
          start: {
            line: 1259,
            column: 8
          },
          end: {
            line: 1262,
            column: 9
          }
        }],
        line: 1259
      },
      '107': {
        loc: {
          start: {
            line: 1317,
            column: 4
          },
          end: {
            line: 1320,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1317,
            column: 4
          },
          end: {
            line: 1320,
            column: 5
          }
        }, {
          start: {
            line: 1317,
            column: 4
          },
          end: {
            line: 1320,
            column: 5
          }
        }],
        line: 1317
      },
      '108': {
        loc: {
          start: {
            line: 1322,
            column: 6
          },
          end: {
            line: 1324,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1322,
            column: 6
          },
          end: {
            line: 1324,
            column: 7
          }
        }, {
          start: {
            line: 1322,
            column: 6
          },
          end: {
            line: 1324,
            column: 7
          }
        }],
        line: 1322
      },
      '109': {
        loc: {
          start: {
            line: 1329,
            column: 41
          },
          end: {
            line: 1329,
            column: 73
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 1329,
            column: 41
          },
          end: {
            line: 1329,
            column: 60
          }
        }, {
          start: {
            line: 1329,
            column: 64
          },
          end: {
            line: 1329,
            column: 73
          }
        }],
        line: 1329
      },
      '110': {
        loc: {
          start: {
            line: 1350,
            column: 6
          },
          end: {
            line: 1352,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1350,
            column: 6
          },
          end: {
            line: 1352,
            column: 7
          }
        }, {
          start: {
            line: 1350,
            column: 6
          },
          end: {
            line: 1352,
            column: 7
          }
        }],
        line: 1350
      },
      '111': {
        loc: {
          start: {
            line: 1365,
            column: 21
          },
          end: {
            line: 1365,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 1365,
            column: 21
          },
          end: {
            line: 1365,
            column: 45
          }
        }, {
          start: {
            line: 1365,
            column: 49
          },
          end: {
            line: 1365,
            column: 50
          }
        }],
        line: 1365
      },
      '112': {
        loc: {
          start: {
            line: 1366,
            column: 24
          },
          end: {
            line: 1366,
            column: 56
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 1366,
            column: 24
          },
          end: {
            line: 1366,
            column: 51
          }
        }, {
          start: {
            line: 1366,
            column: 55
          },
          end: {
            line: 1366,
            column: 56
          }
        }],
        line: 1366
      },
      '113': {
        loc: {
          start: {
            line: 1367,
            column: 19
          },
          end: {
            line: 1367,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 1367,
            column: 19
          },
          end: {
            line: 1367,
            column: 41
          }
        }, {
          start: {
            line: 1367,
            column: 45
          },
          end: {
            line: 1367,
            column: 46
          }
        }],
        line: 1367
      },
      '114': {
        loc: {
          start: {
            line: 1372,
            column: 6
          },
          end: {
            line: 1374,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1372,
            column: 6
          },
          end: {
            line: 1374,
            column: 7
          }
        }, {
          start: {
            line: 1372,
            column: 6
          },
          end: {
            line: 1374,
            column: 7
          }
        }],
        line: 1372
      },
      '115': {
        loc: {
          start: {
            line: 1379,
            column: 6
          },
          end: {
            line: 1381,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1379,
            column: 6
          },
          end: {
            line: 1381,
            column: 7
          }
        }, {
          start: {
            line: 1379,
            column: 6
          },
          end: {
            line: 1381,
            column: 7
          }
        }],
        line: 1379
      },
      '116': {
        loc: {
          start: {
            line: 1382,
            column: 6
          },
          end: {
            line: 1384,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1382,
            column: 6
          },
          end: {
            line: 1384,
            column: 7
          }
        }, {
          start: {
            line: 1382,
            column: 6
          },
          end: {
            line: 1384,
            column: 7
          }
        }],
        line: 1382
      },
      '117': {
        loc: {
          start: {
            line: 1444,
            column: 21
          },
          end: {
            line: 1444,
            column: 73
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 1444,
            column: 39
          },
          end: {
            line: 1444,
            column: 66
          }
        }, {
          start: {
            line: 1444,
            column: 69
          },
          end: {
            line: 1444,
            column: 73
          }
        }],
        line: 1444
      },
      '118': {
        loc: {
          start: {
            line: 1453,
            column: 8
          },
          end: {
            line: 1455,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1453,
            column: 8
          },
          end: {
            line: 1455,
            column: 9
          }
        }, {
          start: {
            line: 1453,
            column: 8
          },
          end: {
            line: 1455,
            column: 9
          }
        }],
        line: 1453
      },
      '119': {
        loc: {
          start: {
            line: 1538,
            column: 4
          },
          end: {
            line: 1540,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1538,
            column: 4
          },
          end: {
            line: 1540,
            column: 5
          }
        }, {
          start: {
            line: 1538,
            column: 4
          },
          end: {
            line: 1540,
            column: 5
          }
        }],
        line: 1538
      },
      '120': {
        loc: {
          start: {
            line: 1548,
            column: 8
          },
          end: {
            line: 1554,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1548,
            column: 8
          },
          end: {
            line: 1554,
            column: 9
          }
        }, {
          start: {
            line: 1548,
            column: 8
          },
          end: {
            line: 1554,
            column: 9
          }
        }],
        line: 1548
      },
      '121': {
        loc: {
          start: {
            line: 1551,
            column: 10
          },
          end: {
            line: 1553,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1551,
            column: 10
          },
          end: {
            line: 1553,
            column: 11
          }
        }, {
          start: {
            line: 1551,
            column: 10
          },
          end: {
            line: 1553,
            column: 11
          }
        }],
        line: 1551
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0,
      '248': 0,
      '249': 0,
      '250': 0,
      '251': 0,
      '252': 0,
      '253': 0,
      '254': 0,
      '255': 0,
      '256': 0,
      '257': 0,
      '258': 0,
      '259': 0,
      '260': 0,
      '261': 0,
      '262': 0,
      '263': 0,
      '264': 0,
      '265': 0,
      '266': 0,
      '267': 0,
      '268': 0,
      '269': 0,
      '270': 0,
      '271': 0,
      '272': 0,
      '273': 0,
      '274': 0,
      '275': 0,
      '276': 0,
      '277': 0,
      '278': 0,
      '279': 0,
      '280': 0,
      '281': 0,
      '282': 0,
      '283': 0,
      '284': 0,
      '285': 0,
      '286': 0,
      '287': 0,
      '288': 0,
      '289': 0,
      '290': 0,
      '291': 0,
      '292': 0,
      '293': 0,
      '294': 0,
      '295': 0,
      '296': 0,
      '297': 0,
      '298': 0,
      '299': 0,
      '300': 0,
      '301': 0,
      '302': 0,
      '303': 0,
      '304': 0,
      '305': 0,
      '306': 0,
      '307': 0,
      '308': 0,
      '309': 0,
      '310': 0,
      '311': 0,
      '312': 0,
      '313': 0,
      '314': 0,
      '315': 0,
      '316': 0,
      '317': 0,
      '318': 0,
      '319': 0,
      '320': 0,
      '321': 0,
      '322': 0,
      '323': 0,
      '324': 0,
      '325': 0,
      '326': 0,
      '327': 0,
      '328': 0,
      '329': 0,
      '330': 0,
      '331': 0,
      '332': 0,
      '333': 0,
      '334': 0,
      '335': 0,
      '336': 0,
      '337': 0,
      '338': 0,
      '339': 0,
      '340': 0,
      '341': 0,
      '342': 0,
      '343': 0,
      '344': 0,
      '345': 0,
      '346': 0,
      '347': 0,
      '348': 0,
      '349': 0,
      '350': 0,
      '351': 0,
      '352': 0,
      '353': 0,
      '354': 0,
      '355': 0,
      '356': 0,
      '357': 0,
      '358': 0,
      '359': 0,
      '360': 0,
      '361': 0,
      '362': 0,
      '363': 0,
      '364': 0,
      '365': 0,
      '366': 0,
      '367': 0,
      '368': 0,
      '369': 0,
      '370': 0,
      '371': 0,
      '372': 0,
      '373': 0,
      '374': 0,
      '375': 0,
      '376': 0,
      '377': 0,
      '378': 0,
      '379': 0,
      '380': 0,
      '381': 0,
      '382': 0,
      '383': 0,
      '384': 0,
      '385': 0,
      '386': 0,
      '387': 0,
      '388': 0,
      '389': 0,
      '390': 0,
      '391': 0,
      '392': 0,
      '393': 0,
      '394': 0,
      '395': 0,
      '396': 0,
      '397': 0,
      '398': 0,
      '399': 0,
      '400': 0,
      '401': 0,
      '402': 0,
      '403': 0,
      '404': 0,
      '405': 0,
      '406': 0,
      '407': 0,
      '408': 0,
      '409': 0,
      '410': 0,
      '411': 0,
      '412': 0,
      '413': 0,
      '414': 0,
      '415': 0,
      '416': 0,
      '417': 0,
      '418': 0,
      '419': 0,
      '420': 0,
      '421': 0,
      '422': 0,
      '423': 0,
      '424': 0,
      '425': 0,
      '426': 0,
      '427': 0,
      '428': 0,
      '429': 0,
      '430': 0,
      '431': 0,
      '432': 0,
      '433': 0,
      '434': 0,
      '435': 0,
      '436': 0,
      '437': 0,
      '438': 0,
      '439': 0,
      '440': 0,
      '441': 0,
      '442': 0,
      '443': 0,
      '444': 0,
      '445': 0,
      '446': 0,
      '447': 0,
      '448': 0,
      '449': 0,
      '450': 0,
      '451': 0,
      '452': 0,
      '453': 0,
      '454': 0,
      '455': 0,
      '456': 0,
      '457': 0,
      '458': 0,
      '459': 0,
      '460': 0,
      '461': 0,
      '462': 0,
      '463': 0,
      '464': 0,
      '465': 0,
      '466': 0,
      '467': 0,
      '468': 0,
      '469': 0,
      '470': 0,
      '471': 0,
      '472': 0,
      '473': 0,
      '474': 0,
      '475': 0,
      '476': 0,
      '477': 0,
      '478': 0,
      '479': 0,
      '480': 0,
      '481': 0,
      '482': 0,
      '483': 0,
      '484': 0,
      '485': 0,
      '486': 0,
      '487': 0,
      '488': 0,
      '489': 0,
      '490': 0,
      '491': 0,
      '492': 0,
      '493': 0,
      '494': 0,
      '495': 0,
      '496': 0,
      '497': 0,
      '498': 0,
      '499': 0,
      '500': 0,
      '501': 0,
      '502': 0,
      '503': 0,
      '504': 0,
      '505': 0,
      '506': 0,
      '507': 0,
      '508': 0,
      '509': 0,
      '510': 0,
      '511': 0,
      '512': 0,
      '513': 0,
      '514': 0,
      '515': 0,
      '516': 0,
      '517': 0,
      '518': 0,
      '519': 0,
      '520': 0,
      '521': 0,
      '522': 0,
      '523': 0,
      '524': 0,
      '525': 0,
      '526': 0,
      '527': 0,
      '528': 0,
      '529': 0,
      '530': 0,
      '531': 0,
      '532': 0,
      '533': 0,
      '534': 0,
      '535': 0,
      '536': 0,
      '537': 0,
      '538': 0,
      '539': 0,
      '540': 0,
      '541': 0,
      '542': 0,
      '543': 0,
      '544': 0,
      '545': 0,
      '546': 0,
      '547': 0,
      '548': 0,
      '549': 0,
      '550': 0,
      '551': 0,
      '552': 0,
      '553': 0,
      '554': 0,
      '555': 0,
      '556': 0,
      '557': 0,
      '558': 0,
      '559': 0,
      '560': 0,
      '561': 0,
      '562': 0,
      '563': 0,
      '564': 0,
      '565': 0,
      '566': 0,
      '567': 0,
      '568': 0,
      '569': 0,
      '570': 0,
      '571': 0,
      '572': 0,
      '573': 0,
      '574': 0,
      '575': 0,
      '576': 0,
      '577': 0,
      '578': 0,
      '579': 0,
      '580': 0,
      '581': 0,
      '582': 0,
      '583': 0,
      '584': 0,
      '585': 0,
      '586': 0,
      '587': 0,
      '588': 0,
      '589': 0,
      '590': 0,
      '591': 0,
      '592': 0,
      '593': 0,
      '594': 0,
      '595': 0,
      '596': 0,
      '597': 0,
      '598': 0,
      '599': 0,
      '600': 0,
      '601': 0,
      '602': 0,
      '603': 0,
      '604': 0,
      '605': 0,
      '606': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0],
      '58': [0, 0],
      '59': [0, 0],
      '60': [0, 0],
      '61': [0, 0],
      '62': [0, 0],
      '63': [0, 0],
      '64': [0, 0],
      '65': [0, 0],
      '66': [0, 0],
      '67': [0, 0],
      '68': [0, 0],
      '69': [0, 0],
      '70': [0, 0],
      '71': [0, 0],
      '72': [0, 0],
      '73': [0, 0],
      '74': [0, 0],
      '75': [0, 0],
      '76': [0, 0],
      '77': [0, 0],
      '78': [0, 0],
      '79': [0, 0],
      '80': [0, 0],
      '81': [0, 0],
      '82': [0, 0],
      '83': [0, 0],
      '84': [0, 0],
      '85': [0, 0],
      '86': [0, 0],
      '87': [0, 0],
      '88': [0, 0],
      '89': [0, 0],
      '90': [0, 0],
      '91': [0, 0],
      '92': [0, 0],
      '93': [0, 0],
      '94': [0, 0],
      '95': [0, 0],
      '96': [0, 0, 0, 0, 0, 0],
      '97': [0, 0],
      '98': [0, 0],
      '99': [0, 0],
      '100': [0, 0],
      '101': [0, 0],
      '102': [0, 0],
      '103': [0, 0],
      '104': [0, 0],
      '105': [0, 0],
      '106': [0, 0],
      '107': [0, 0],
      '108': [0, 0],
      '109': [0, 0],
      '110': [0, 0],
      '111': [0, 0],
      '112': [0, 0],
      '113': [0, 0],
      '114': [0, 0],
      '115': [0, 0],
      '116': [0, 0],
      '117': [0, 0],
      '118': [0, 0],
      '119': [0, 0],
      '120': [0, 0],
      '121': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1g1hrro9qp.s[0]++;

angular.module('app').controller('dashboardCtrl', function ($scope, $translate, $stateParams, $deltaNumber, $sce, $otherHttpService, $LOGINService, $deltaCategoriesBudget, FileUploader, $deltaFinancSource, $deltaLocation, $CRUDService, $deltahttp, $deltaPlanLogique, $deltadate, $location, $http, $deltaGeoLevel, $rootScope, $filter, $deltaTypeIndicateur, $deltaPeriode, $state, $timeout, $log) {
  cov_1g1hrro9qp.f[0]++;
  cov_1g1hrro9qp.s[1]++;

  $scope.listProjects = [];

  cov_1g1hrro9qp.s[2]++;
  ej.grids.Grid.Inject(ej.grids.Aggregate);

  cov_1g1hrro9qp.s[3]++;
  $rootScope.processPageRight('1_1', function () {
    cov_1g1hrro9qp.f[1]++;
    cov_1g1hrro9qp.s[4]++;

    $rootScope.global_access_page_denied = false;
    cov_1g1hrro9qp.s[5]++;
    $rootScope.currentRight.v = true;
  });

  cov_1g1hrro9qp.s[6]++;
  $scope.PATH = 'Projet';
  var PATH_FORMULAR = (cov_1g1hrro9qp.s[7]++, 'Formular');
  cov_1g1hrro9qp.s[8]++;
  $scope.tab = 1;
  cov_1g1hrro9qp.s[9]++;
  $rootScope.global_access_page_denied = false;
  cov_1g1hrro9qp.s[10]++;
  $scope.financialData = { amount: 0, labels: '' };
  cov_1g1hrro9qp.s[11]++;
  $scope.getFinancial = function () {
    cov_1g1hrro9qp.f[2]++;
    cov_1g1hrro9qp.s[12]++;

    $CRUDService.getAll($scope.PATH, { get: 'finance_info' }, function (data) {
      cov_1g1hrro9qp.f[3]++;
      cov_1g1hrro9qp.s[13]++;

      $scope.financialData.amount = data.amount;
      cov_1g1hrro9qp.s[14]++;
      $scope.financialData.labels = data.list.join(', ');
    });
  };

  var PROJECT_DASHBOARD_INDICATOR = (cov_1g1hrro9qp.s[15]++, 'PROJECT_DASHBOARD_INDICATOR_' + $rootScope.currentProject.project.id);
  var PROJECT_DASHBOARD_INDICATOR_PERIOD = (cov_1g1hrro9qp.s[16]++, 'PROJECT_DASHBOARD_INDICATOR_PERIOD_' + $rootScope.currentProject.project.id);

  cov_1g1hrro9qp.s[17]++;
  $scope.PATHIMG = $deltahttp.getIndicatorRespositoy();
  cov_1g1hrro9qp.s[18]++;
  $scope.PATHIMG_ACTIVITY = $deltahttp.getActivityRespositoy();

  cov_1g1hrro9qp.s[19]++;
  $deltaLocation.addController($scope);
  cov_1g1hrro9qp.s[20]++;
  $deltaPlanLogique.addController($scope);
  cov_1g1hrro9qp.s[21]++;
  $deltaCategoriesBudget.addController($scope);
  cov_1g1hrro9qp.s[22]++;
  $deltaFinancSource.addController($scope);

  var defaultLegend = (cov_1g1hrro9qp.s[23]++, [{ c: '#ffffcc', l: '', v: 0 }, { c: '#c2e699', l: '', v: 10 }, { c: '#78c679', l: '', v: 40 }, { c: '#31a354', l: '', v: 60 }, { c: '#006837', l: '', v: 80 }]);

  cov_1g1hrro9qp.s[24]++;
  $scope.gridsterOpts = {
    columns: 8,
    margins: [20, 20],
    outerMargin: false,
    pushing: true,
    floating: true,
    draggable: {
      enabled: false
    },
    resizable: {
      enabled: false
    }
  };

  cov_1g1hrro9qp.s[25]++;
  $scope.listMilestone = [];
  var PATH_ANALYSE = (cov_1g1hrro9qp.s[26]++, 'Analyse');
  cov_1g1hrro9qp.s[27]++;
  $scope.analyseList = [];

  // Menu automatique
  cov_1g1hrro9qp.s[28]++;
  $scope.selectedDashboard = null;
  cov_1g1hrro9qp.s[29]++;
  $scope.availableDashboardList = [];
  cov_1g1hrro9qp.s[30]++;
  $scope.saveActiveTab = function () {
    cov_1g1hrro9qp.f[4]++;
    cov_1g1hrro9qp.s[31]++;

    localStorage.setItem('TAB_PROJECT_MENU_' + $rootScope.currentProject.project.id, angular.toJson($scope.availableDashboardList.map(function (item) {
      cov_1g1hrro9qp.f[5]++;
      cov_1g1hrro9qp.s[32]++;
      return item.id;
    })));
  };

  // Fermeture du menu activer
  cov_1g1hrro9qp.s[33]++;
  $scope.closeDashboard = function (item) {
    cov_1g1hrro9qp.f[6]++;

    // Retrait de la liste des menus active
    var index = (cov_1g1hrro9qp.s[34]++, $scope.availableDashboardList.indexOf(item));
    cov_1g1hrro9qp.s[35]++;
    if (index >= 0) {
      cov_1g1hrro9qp.b[0][0]++;
      cov_1g1hrro9qp.s[36]++;

      $scope.availableDashboardList.splice(index, 1);
      cov_1g1hrro9qp.s[37]++;
      $scope.saveActiveTab();
    } else {
      cov_1g1hrro9qp.b[0][1]++;
    }

    cov_1g1hrro9qp.s[38]++;
    if (item.id == $scope.selectedDashboard.id) {
      cov_1g1hrro9qp.b[1][0]++;
      cov_1g1hrro9qp.s[39]++;

      $scope.selectDashboard($scope.data_main.find(function (dashboard) {
        cov_1g1hrro9qp.f[7]++;
        cov_1g1hrro9qp.s[40]++;

        return dashboard.id == 0;
      }));
    } else {
      cov_1g1hrro9qp.b[1][1]++;
    }
    cov_1g1hrro9qp.s[41]++;
    item.display = false;
  };

  // Selection du menu activer
  cov_1g1hrro9qp.s[42]++;
  $scope.addDashboard = function (item) {
    cov_1g1hrro9qp.f[8]++;

    // Ajout à liste des menus active
    var index = (cov_1g1hrro9qp.s[43]++, $scope.availableDashboardList.indexOf(item));
    cov_1g1hrro9qp.s[44]++;
    if (index < 0) {
      cov_1g1hrro9qp.b[2][0]++;
      cov_1g1hrro9qp.s[45]++;

      item.display = true;
      cov_1g1hrro9qp.s[46]++;
      $scope.availableDashboardList.push(item);
      cov_1g1hrro9qp.s[47]++;
      $scope.saveActiveTab();
    } else {
      cov_1g1hrro9qp.b[2][1]++;
    }
    cov_1g1hrro9qp.s[48]++;
    item.display = true;
    cov_1g1hrro9qp.s[49]++;
    $scope.selectDashboard(item);
  };

  // Sauvegarde du menu selectionner en fonction du projet
  cov_1g1hrro9qp.s[50]++;
  $scope.selectDashboard = function (item) {
    cov_1g1hrro9qp.f[9]++;
    cov_1g1hrro9qp.s[51]++;

    $scope.idcomponent = new Date().getTime();
    cov_1g1hrro9qp.s[52]++;
    $scope.selectedDashboard = angular.copy(item);
    cov_1g1hrro9qp.s[53]++;
    if (item.id <= 0) {
      cov_1g1hrro9qp.b[3][0]++;
      cov_1g1hrro9qp.s[54]++;

      localStorage.setItem('TAB_PROJECT_' + $rootScope.currentProject.project.id, $scope.selectedDashboard.id);
      cov_1g1hrro9qp.s[55]++;
      return;
    } else {
      cov_1g1hrro9qp.b[3][1]++;
    }
    cov_1g1hrro9qp.s[56]++;
    localStorage.setItem('TAB_PROJECT_' + $rootScope.currentProject.project.id, $scope.selectedDashboard.id);
  };

  cov_1g1hrro9qp.s[57]++;
  $otherHttpService.get('Milestone', { get: 'all' }, function (data) {
    cov_1g1hrro9qp.f[10]++;
    cov_1g1hrro9qp.s[58]++;

    $scope.listMilestone = data;
  });
  cov_1g1hrro9qp.s[59]++;
  $scope.changeTab = function (index) {
    cov_1g1hrro9qp.f[11]++;
    cov_1g1hrro9qp.s[60]++;

    $scope.tab = index;
  };
  var PATH = (cov_1g1hrro9qp.s[61]++, 'Indicateurs');
  var PATH_DEC = (cov_1g1hrro9qp.s[62]++, 'DecoupageIndic');
  cov_1g1hrro9qp.s[63]++;
  $deltaTypeIndicateur.addController($scope);
  cov_1g1hrro9qp.s[64]++;
  $scope.getIndicateurLabel = function (item) {
    cov_1g1hrro9qp.f[12]++;
    cov_1g1hrro9qp.s[65]++;

    if (!item.lie) {
      cov_1g1hrro9qp.b[4][0]++;
      cov_1g1hrro9qp.s[66]++;

      return item.li;
    } else {
      cov_1g1hrro9qp.b[4][1]++;
    }
    cov_1g1hrro9qp.s[67]++;
    if (item.lie.trim() == '') {
      cov_1g1hrro9qp.b[5][0]++;
      cov_1g1hrro9qp.s[68]++;

      return item.li;
    } else {
      cov_1g1hrro9qp.b[5][1]++;
    }
    cov_1g1hrro9qp.s[69]++;
    if (!item.li) {
      cov_1g1hrro9qp.b[6][0]++;
      cov_1g1hrro9qp.s[70]++;

      return item.lie;
    } else {
      cov_1g1hrro9qp.b[6][1]++;
    }
    cov_1g1hrro9qp.s[71]++;
    if (item.li.trim() == '') {
      cov_1g1hrro9qp.b[7][0]++;
      cov_1g1hrro9qp.s[72]++;

      return item.lie;
    } else {
      cov_1g1hrro9qp.b[7][1]++;
    }
    cov_1g1hrro9qp.s[73]++;
    if ($rootScope.language.id == 'en') {
      cov_1g1hrro9qp.b[8][0]++;
      cov_1g1hrro9qp.s[74]++;

      return item.lie;
    } else {
      cov_1g1hrro9qp.b[8][1]++;
    }
    cov_1g1hrro9qp.s[75]++;
    return item.li;
  };
  cov_1g1hrro9qp.s[76]++;
  $scope.personalize = {
    editlabel: false,
    labelPerso: $rootScope.currentProject.project.getLabelTB(),
    editlabelPerso: $rootScope.currentProject.project.getLabelTB(),
    save: function save() {
      cov_1g1hrro9qp.s[77]++;

      this.editlabel = false;
      cov_1g1hrro9qp.s[78]++;
      this.labelPerso = this.editlabelPerso;
      cov_1g1hrro9qp.s[79]++;
      $rootScope.currentProject.project.setLabelTB(this.editlabelPerso);
    }
  };
  /** ********************************************************************************* */
  var project = (cov_1g1hrro9qp.s[80]++, $rootScope.getCurrentProject());
  cov_1g1hrro9qp.s[81]++;
  $scope.tmp_project = project.project;

  cov_1g1hrro9qp.s[82]++;
  $scope.YearList = $deltadate.getYearBetween(project.project.DATE_DEB_PROJET, project.project.DATE_FIN_PROJET, $deltadate.getStartMonth());

  cov_1g1hrro9qp.s[83]++;
  $scope.indicateurDashbord = {
    yearSelect: (cov_1g1hrro9qp.b[9][0]++, $filter('filter')($scope.YearList, { id: Date.newDate().getFullYear().toString() }, true)[0]) || (cov_1g1hrro9qp.b[9][1]++, $scope.YearList[0]),
    period: 1,
    data: [],
    openDashboard: function openDashboard(item) {
      cov_1g1hrro9qp.s[84]++;

      if (item.id) {
        cov_1g1hrro9qp.b[10][0]++;
        cov_1g1hrro9qp.s[85]++;

        $CRUDService.getAll('Indicateurs', { get: 'all', idcl: item.idcl, id: item.id, page: 1, nr: true, cat: true }, function (data) {
          cov_1g1hrro9qp.f[13]++;
          cov_1g1hrro9qp.s[86]++;

          $scope.loading = false;
          cov_1g1hrro9qp.s[87]++;
          if (data.length > 0) {
            cov_1g1hrro9qp.b[11][0]++;
            cov_1g1hrro9qp.s[88]++;

            data[0].RATIONAL = data[0].RATIONAL == 1;
            cov_1g1hrro9qp.s[89]++;
            localStorage.setItem('selectedIndicatorOverview', angular.toJson(data[0]));
            cov_1g1hrro9qp.s[90]++;
            $state.go('indicator.overview.dashboards', { indicator: data[0], origin: { from: 'index.dashboard', params: {} }, dashboard: true });
          } else {
            cov_1g1hrro9qp.b[11][1]++;
          }
        });
      } else {
        cov_1g1hrro9qp.b[10][1]++;
      }
    },
    getSituationIndicateur: function getSituationIndicateur() {
      var _this = this;

      cov_1g1hrro9qp.s[91]++;

      $scope.dateIndic = Date.newDate();
      cov_1g1hrro9qp.s[92]++;
      $otherHttpService.get('Indicateurs', { get: 'tbhome', idl: 0, type: 0 }, function (dataR) {
        cov_1g1hrro9qp.f[14]++;
        cov_1g1hrro9qp.s[93]++;

        if ((cov_1g1hrro9qp.b[13][0]++, !dataR) || (cov_1g1hrro9qp.b[13][1]++, dataR.d.length <= 0)) {
          cov_1g1hrro9qp.b[12][0]++;
          cov_1g1hrro9qp.s[94]++;

          return;
        } else {
          cov_1g1hrro9qp.b[12][1]++;
        }
        var data = (cov_1g1hrro9qp.s[95]++, dataR.d);
        var firstData = (cov_1g1hrro9qp.s[96]++, data[0]);
        cov_1g1hrro9qp.s[97]++;
        if (!firstData) {
          cov_1g1hrro9qp.b[14][0]++;
          cov_1g1hrro9qp.s[98]++;

          return;
        } else {
          cov_1g1hrro9qp.b[14][1]++;
        }
        var header = (cov_1g1hrro9qp.s[99]++, []);
        cov_1g1hrro9qp.s[100]++;
        if (firstData) {
          cov_1g1hrro9qp.b[15][0]++;
          cov_1g1hrro9qp.s[101]++;

          for (var prop in firstData) {
            var proprN = (cov_1g1hrro9qp.s[102]++, $deltaNumber.formatNumber(prop, null));
            cov_1g1hrro9qp.s[103]++;
            if (!proprN) {
              cov_1g1hrro9qp.b[16][0]++;
              cov_1g1hrro9qp.s[104]++;

              continue;
            } else {
              cov_1g1hrro9qp.b[16][1]++;
            }
            cov_1g1hrro9qp.s[105]++;
            header.push(prop);
          }
        } else {
          cov_1g1hrro9qp.b[15][1]++;
        }
        var dataFinal = (cov_1g1hrro9qp.s[106]++, []);

        cov_1g1hrro9qp.s[107]++;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var obj = _step.value;

            var second = (cov_1g1hrro9qp.s[108]++, {
              lib: $scope.getIndicateurLabel(obj),
              des: obj.ll, c: obj.ci, t: obj.ti,
              // value, type, format, possibleValue = [], displayWeight = 'WEIGHT', textFormat = false
              vc: $rootScope.displayIndicatorValue(obj.VALEUR_CIBLE, obj.t, obj.fo, obj.vp, obj.display, true), //    $deltaNumber.formatNumber(obj.VALEUR_CIBLE, null),
              vr: $rootScope.displayIndicatorValue(obj.VALEUR_REF, obj.t, obj.fo, obj.vp, obj.display, true), // $deltaNumber.formatNumber(obj.VALEUR_REF, null),
              vcN: $deltaNumber.formatNumber(obj.VALEUR_CIBLE, null, obj.fo),
              vrN: $deltaNumber.formatNumber(obj.VALEUR_REF, null, obj.fo),
              display: obj.display, type: obj.t, d1: [], d2: [], d: [], id: obj.id, idcl: obj.idcl, p: obj.p,
              fo: obj.fo, vp: obj.vp
            });
            var headers = (cov_1g1hrro9qp.s[109]++, []);
            cov_1g1hrro9qp.s[110]++;
            for (var _prop in obj) {
              var _proprN = (cov_1g1hrro9qp.s[111]++, Number(_prop));
              cov_1g1hrro9qp.s[112]++;
              if ((cov_1g1hrro9qp.b[18][0]++, _proprN == Number.NaN) || (cov_1g1hrro9qp.b[18][1]++, !_proprN)) {
                cov_1g1hrro9qp.b[17][0]++;
                cov_1g1hrro9qp.s[113]++;

                continue;
              } else {
                cov_1g1hrro9qp.b[17][1]++;
              }
              cov_1g1hrro9qp.s[114]++;
              headers.push(_prop);
            }
            cov_1g1hrro9qp.s[115]++;
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = headers[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var _header = _step2.value;
                cov_1g1hrro9qp.s[116]++;

                second.d.push({
                  y: _header,
                  v: $rootScope.displayIndicatorValue(obj[_header], obj.t, obj.fo, obj.vp, obj.display, true) // $deltaNumber.formatNumber(obj[header], null)
                });
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }

            cov_1g1hrro9qp.s[117]++;
            dataFinal.push(second);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        cov_1g1hrro9qp.s[118]++;
        $scope.indicateurDashbord.data = dataFinal;
        var savedItem = (cov_1g1hrro9qp.s[119]++, (cov_1g1hrro9qp.b[19][0]++, localStorage.getItem(PROJECT_DASHBOARD_INDICATOR)) || (cov_1g1hrro9qp.b[19][1]++, 0));

        cov_1g1hrro9qp.s[120]++;
        _this.period = (cov_1g1hrro9qp.b[20][0]++, localStorage.getItem(PROJECT_DASHBOARD_INDICATOR_PERIOD)) || (cov_1g1hrro9qp.b[20][1]++, 1);

        var selectIndicator = (cov_1g1hrro9qp.s[121]++, dataFinal.find(function (indicator) {
          cov_1g1hrro9qp.f[15]++;
          cov_1g1hrro9qp.s[122]++;
          return indicator.id == savedItem;
        }));

        cov_1g1hrro9qp.s[123]++;
        $scope.indicateurDashbord.selectIndicateur((cov_1g1hrro9qp.b[21][0]++, selectIndicator) || (cov_1g1hrro9qp.b[21][1]++, dataFinal[0]));
      });
    },
    selectIndicateur: function selectIndicateur(item) {
      cov_1g1hrro9qp.s[124]++;

      if ((cov_1g1hrro9qp.b[23][0]++, !item) && (cov_1g1hrro9qp.b[23][1]++, !this.selectedItem)) {
        cov_1g1hrro9qp.b[22][0]++;
        cov_1g1hrro9qp.s[125]++;

        return;
      } else {
        cov_1g1hrro9qp.b[22][1]++;
      }

      cov_1g1hrro9qp.s[126]++;
      if (!item) {
        cov_1g1hrro9qp.b[24][0]++;
        cov_1g1hrro9qp.s[127]++;

        item = this.selectedItem;
      } else {
        cov_1g1hrro9qp.b[24][1]++;
        cov_1g1hrro9qp.s[128]++;

        this.selectedItem = item;
      }

      cov_1g1hrro9qp.s[129]++;
      if ($scope.indicateurDashbord.selectedItem.p == 1) {
        cov_1g1hrro9qp.b[25][0]++;
        cov_1g1hrro9qp.s[130]++;

        this.period = 2;
      } else {
        cov_1g1hrro9qp.b[25][1]++;
      }

      cov_1g1hrro9qp.s[131]++;
      localStorage.setItem(PROJECT_DASHBOARD_INDICATOR, this.selectedItem.id);

      var params = (cov_1g1hrro9qp.s[132]++, { get: 'tbhome_detail', id: item.id, idcl: item.idcl, type: 0, all_location: 1, not_display_all_location: true });
      cov_1g1hrro9qp.s[133]++;
      if (this.period == 2) {
        cov_1g1hrro9qp.b[26][0]++;
        cov_1g1hrro9qp.s[134]++;

        params.get = 'tbhome_detail_period';
      } else {
        cov_1g1hrro9qp.b[26][1]++;
      }
      cov_1g1hrro9qp.s[135]++;
      this.grapheDash = {
        labels: [],
        series: [],
        data: [],
        options: [],
        colors: []
      };
      cov_1g1hrro9qp.s[136]++;
      $scope.indicatorLoading = true;
      cov_1g1hrro9qp.s[137]++;
      $scope.indicatorChartEmpty = false;
      cov_1g1hrro9qp.s[138]++;
      $scope.indicateurDashbord.tableParams = null;
      cov_1g1hrro9qp.s[139]++;
      $otherHttpService.get('Indicateurs', params, function (data) {
        cov_1g1hrro9qp.f[16]++;
        cov_1g1hrro9qp.s[140]++;

        $scope.indicatorLoading = false;
        cov_1g1hrro9qp.s[141]++;
        if (data.length == 0) {
          cov_1g1hrro9qp.b[27][0]++;
          cov_1g1hrro9qp.s[142]++;

          $scope.indicatorChartEmpty = true;
        } else {
          cov_1g1hrro9qp.b[27][1]++;
        }
        var headers = (cov_1g1hrro9qp.s[143]++, $scope.indicateurDashbord.selectedItem.d.map(function (year) {
          cov_1g1hrro9qp.f[17]++;
          cov_1g1hrro9qp.s[144]++;
          return { label: year.y, index: year.y };
        }));

        cov_1g1hrro9qp.s[145]++;
        if ((cov_1g1hrro9qp.b[29][0]++, headers.length > 0) && (cov_1g1hrro9qp.b[29][1]++, $scope.indicateurDashbord.period == 2)) {
          cov_1g1hrro9qp.b[28][0]++;

          var beginDate = (cov_1g1hrro9qp.s[146]++, Date.newDate(headers[0].index + '-01-01'));
          var endDate = (cov_1g1hrro9qp.s[147]++, Date.newDate(headers[headers.length - 1].index + '-12-31'));
          cov_1g1hrro9qp.s[148]++;
          headers = $deltadate.getPeriods(beginDate, endDate, $scope.indicateurDashbord.selectedItem.p, true).map(function (period) {
            cov_1g1hrro9qp.f[18]++;
            cov_1g1hrro9qp.s[149]++;
            return {
              label: $deltadate.getLabelPerioddepth(period.index * (12 / $deltaNumber.formatNumber($scope.indicateurDashbord.selectedItem.p)), 12, true, period.year),
              index: '' + period.year + period.index
            };
          });
        } else {
          cov_1g1hrro9qp.b[28][1]++;
        }

        var obj = (cov_1g1hrro9qp.s[150]++, $scope.indicateurDashbord.selectedItem);

        var canDisplay = (cov_1g1hrro9qp.s[151]++, obj.display !== 'WEIGHT');

        cov_1g1hrro9qp.s[152]++;
        for (var i = 0; i < data.length; i++) {
          cov_1g1hrro9qp.s[153]++;

          data[i].T = $deltaNumber.formatNumber(data[i].T, null, $scope.indicateurDashbord.selectedItem.fo);
          cov_1g1hrro9qp.s[154]++;
          if (data[i].T !== null) {
            cov_1g1hrro9qp.b[30][0]++;
            cov_1g1hrro9qp.s[155]++;

            data[i].T = data[i].T = (cov_1g1hrro9qp.b[32][0]++, data[i].c === 0) || (cov_1g1hrro9qp.b[32][1]++, data[i].c === null) ? (cov_1g1hrro9qp.b[31][0]++, null) : ( /* 100 * */cov_1g1hrro9qp.b[31][1]++, data[i].T / data[i].c);
          } else {
            cov_1g1hrro9qp.b[30][1]++;
          }
          cov_1g1hrro9qp.s[156]++;
          data[i].df = Date.newDate(data[i].df);

          cov_1g1hrro9qp.s[157]++;
          if (!data[i].lo) {
            cov_1g1hrro9qp.b[33][0]++;
            cov_1g1hrro9qp.s[158]++;

            continue;
          } else {
            cov_1g1hrro9qp.b[33][1]++;
          }

          cov_1g1hrro9qp.s[159]++;
          data[i].c = $rootScope.displayIndicatorValue(data[i].c, obj.type, obj.fo, obj.vp, obj.display);
          cov_1g1hrro9qp.s[160]++;
          data[i].b = $rootScope.displayIndicatorValue(data[i].b, obj.type, obj.fo, obj.vp, obj.display);
          cov_1g1hrro9qp.s[161]++;
          for (var j = 0; j < headers.length; j++) {
            cov_1g1hrro9qp.s[162]++;

            if (angular.isUndefined(data[i][headers[j].index])) {
              cov_1g1hrro9qp.b[34][0]++;
              cov_1g1hrro9qp.s[163]++;

              data[i][headers[j].index] = null;
              cov_1g1hrro9qp.s[164]++;
              continue;
            } else {
              cov_1g1hrro9qp.b[34][1]++;
            }
            cov_1g1hrro9qp.s[165]++;
            data[i][headers[j].index] = $rootScope.displayIndicatorValue(data[i][headers[j].index], obj.type, obj.fo, obj.vp, obj.display);
            cov_1g1hrro9qp.s[166]++;
            headers[j].checked = true;
          }
        }

        cov_1g1hrro9qp.s[167]++;
        $scope.indicateurDashbord.headers = headers.filter(function (header) {
          cov_1g1hrro9qp.f[19]++;
          cov_1g1hrro9qp.s[168]++;

          return header.checked;
        });

        cov_1g1hrro9qp.s[169]++;
        $scope.indicateurDashbord.tableParams = {
          columns: [{ field: 'lo', headerText: $translate.instant('INDICATOR.GLOBAL.LOCATION'), width: 150 }].concat([canDisplay ? (cov_1g1hrro9qp.b[35][0]++, { field: 'b', headerText: $translate.instant('INDICATOR.GLOBAL.REF'), width: 110 }) : (cov_1g1hrro9qp.b[35][1]++, { field: 'b', headerText: $translate.instant('INDICATOR.GLOBAL.REF'), format: 'N', width: 110, type: 'number', textAlign: 'Right' })]).concat($scope.indicateurDashbord.headers.map(function (header) {
            cov_1g1hrro9qp.f[20]++;
            cov_1g1hrro9qp.s[170]++;

            return canDisplay ? (cov_1g1hrro9qp.b[36][0]++, { field: header.index, headerText: header.label.replace(' - ', ' '), width: 85 }) : (cov_1g1hrro9qp.b[36][1]++, { field: header.index, headerText: header.label.replace(' - ', ' '), format: 'N', width: 85, type: 'number', textAlign: 'Right' });
          })).concat([{ field: 'T', headerText: $translate.instant('DASHBOARD.REALIZE'), format: 'P', width: 110, type: 'number', textAlign: 'Right' }]).concat([canDisplay ? (cov_1g1hrro9qp.b[37][0]++, { field: 'c', headerText: $translate.instant('DASHBOARD.END_VALUE'), width: 100 }) : (cov_1g1hrro9qp.b[37][1]++, { field: 'c', headerText: $translate.instant('DASHBOARD.END_VALUE'), format: 'N', width: 100, type: 'number', textAlign: 'Right' })]).concat([{ field: 'df', headerText: $translate.instant('INDICATOR.GLOBAL.END'), format: { skeleton: 'medium', type: 'date' }, width: 120, textAlign: 'Center' }]),
          dataSources: data.filter(function (value) {
            cov_1g1hrro9qp.f[21]++;
            cov_1g1hrro9qp.s[171]++;

            return angular.isDefined(value.lo);
          })
        };

        cov_1g1hrro9qp.s[172]++;
        $scope.indicateurDashbord.dataDetail = data;
        cov_1g1hrro9qp.s[173]++;
        $scope.indicateurDashbord.drawGraphe();
      });
    },
    drawGraphe: function drawGraphe() {
      var color = (cov_1g1hrro9qp.s[174]++, $rootScope.personalData);
      var valeurCible = (cov_1g1hrro9qp.s[175]++, $scope.indicateurDashbord.selectedItem.vcN);
      var valeurRef = (cov_1g1hrro9qp.s[176]++, $scope.indicateurDashbord.selectedItem.vrN);
      var format = (cov_1g1hrro9qp.s[177]++, this.selectedItem.fo);
      var item = (cov_1g1hrro9qp.s[178]++, {});
      cov_1g1hrro9qp.s[179]++;
      item = angular.copy($filter('filter')(this.dataDetail, { lo: undefined }, true)[0]);
      cov_1g1hrro9qp.s[180]++;
      if (!item) {
        cov_1g1hrro9qp.b[38][0]++;
        cov_1g1hrro9qp.s[181]++;

        return;
      } else {
        cov_1g1hrro9qp.b[38][1]++;
      }
      var obj = (cov_1g1hrro9qp.s[182]++, $scope.indicateurDashbord.selectedItem);
      cov_1g1hrro9qp.s[183]++;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = this.headers[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var el = _step3.value;
          cov_1g1hrro9qp.s[184]++;

          item[el.index] = $deltaNumber.formatNumber(item[el.index], null, format);
          cov_1g1hrro9qp.s[185]++;
          item[el.label + 'L'] = $rootScope.displayIndicatorValue(item[el.index], obj.type, obj.fo, obj.vp, obj.display);
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      var params = (cov_1g1hrro9qp.s[186]++, {
        locale: $LOGINService.getLang(),
        // Initializing Primary X and Y Axis
        primaryXAxis: {
          // title: 'Years',
          interval: 1,
          labelIntersectAction: 'Rotate45',
          valueType: 'Category',
          majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
          majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
          lineStyle: { width: 0 },
          maximumLabelWidth: 100
        },
        primaryYAxis: {
          valueType: 'Double',
          lineStyle: { width: 0 },
          majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
          minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
          labelFormat: 'N' + this.selectedItem.fo
        },
        chartArea: {
          border: {
            width: 0
          }
        },
        useGroupingSeparator: true,
        // Initializing Chart Series
        series: [{ // FILL
          type: 'Area',
          dataSource: [],
          xName: 'x', yName: 'y', name: $translate.instant('INDICATOR.REAL_VAL'),
          width: 2,
          fill: 'rgba(70, 182, 238, 0.39) ',
          marker: {
            visible: true,
            width: 10,
            height: 10
          }
        }],
        height: '400px',
        title: '',
        legendSettings: {
          visible: true
        },
        tooltip: { enable: true, shared: true, format: $translate.instant('INDICATOR.REAL_VAL') + ': <b>${point.customValue}<b>' },
        pointRender: function pointRender(args) {
          cov_1g1hrro9qp.s[187]++;

          args.point.customValue = item[args.point.x + 'L'] + (obj.display === 'WEIGHT' ? (cov_1g1hrro9qp.b[39][0]++, '') : (cov_1g1hrro9qp.b[39][1]++, ' (' + args.point.y + ')'));
        }
      });

      cov_1g1hrro9qp.s[188]++;
      localStorage.setItem(PROJECT_DASHBOARD_INDICATOR_PERIOD, this.period);

      cov_1g1hrro9qp.s[189]++;
      item[$translate.instant('DASHBOARD.REFERENCE') + 'L'] = $rootScope.displayIndicatorValue(valeurRef, obj.type, obj.fo, obj.vp, obj.display);
      cov_1g1hrro9qp.s[190]++;
      params.series[0].dataSource.push({
        x: $translate.instant('DASHBOARD.REFERENCE'),
        y: valeurRef,
        custom: item[$translate.instant('DASHBOARD.REFERENCE') + 'L']
      });
      cov_1g1hrro9qp.s[191]++;
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = this.headers[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var _el = _step4.value;
          cov_1g1hrro9qp.s[192]++;

          params.series[0].dataSource.push({
            y: /* $deltaNumber.formatNumber( */item[_el.index] /* , null, format) */
            , x: _el.label,
            custom: item[_el.index + 'L']
          });
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      cov_1g1hrro9qp.s[193]++;
      this.grapheDash = params;
    }
  };
  /** ********************************************************************************** */
  var graphe_repartition_default = (cov_1g1hrro9qp.s[194]++, {
    locale: $LOGINService.getLang(),
    series: [{
      type: 'Pie', dataSource: [], xName: 'x', yName: 'y',
      dataLabel: { visible: true, position: 'Outside' },
      pointColorMapping: 'color'
    }], textRender: function textRender(args) {
      var value = (cov_1g1hrro9qp.s[195]++, '<b>' + $filter('number')(args.point.y) + '</b>');
      var percentage = (cov_1g1hrro9qp.s[196]++, '<b>' + Math.round(args.point.percentage) + '%</b>');
      cov_1g1hrro9qp.s[197]++;
      args.point.customValue = args.point.x + '<br/><hr>';
      cov_1g1hrro9qp.s[198]++;
      args.point.customValue = '' + args.point.x;
      cov_1g1hrro9qp.s[199]++;
      args.point.customValue += '<br/>' + $translate.instant('DASHBOARD.ACTIVITY_COUNT', { value: value });
      cov_1g1hrro9qp.s[200]++;
      args.point.customValue += '<br/>' + $translate.instant('DASHBOARD.PROJECT_ACTIVITY', { value: percentage });
      cov_1g1hrro9qp.s[201]++;
      args.text = args.point.x + ' (' + args.point.y + ')';
    },

    legendSettings: { visible: false, position: 'Bottom' }, enableSmartLabels: true,
    title: $translate.instant('DASHBOARD.PHY'),
    height: '300px',
    // Initializing Tooltip
    tooltip: { enable: true, format: '${point.customValue}' }
  });
  cov_1g1hrro9qp.s[202]++;
  $scope.activityDashboard = {
    yeargraph: null,
    graphe_evolution: {
      data: [], labels: [], options: {
        tooltips: {
          callbacks: {
            label: function label(tooltipItem, data) {
              cov_1g1hrro9qp.s[203]++;

              return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel + '%';
            }
          }
        },
        scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
        maintainAspectRatio: false,
        animation: {
          onComplete: function onComplete() {
            var ctx = (cov_1g1hrro9qp.s[204]++, this.chart.ctx);
            cov_1g1hrro9qp.s[205]++;
            ctx.textAlign = 'center';
            cov_1g1hrro9qp.s[206]++;
            ctx.textBaseline = 'bottom';
            var dataset = (cov_1g1hrro9qp.s[207]++, this.chart.config.data.datasets[1]);
            cov_1g1hrro9qp.s[208]++;
            ctx.fillStyle = '#676a6c';
            cov_1g1hrro9qp.s[209]++;
            for (var numdata in dataset._meta) {
              var oncompletdata = (cov_1g1hrro9qp.s[210]++, dataset._meta[numdata]);
              cov_1g1hrro9qp.s[211]++;
              oncompletdata.data.forEach(function (p) {
                cov_1g1hrro9qp.f[22]++;
                cov_1g1hrro9qp.s[212]++;

                ctx.fillText(p._chart.config.data.datasets[p._datasetIndex].data[p._index] + '%', p._model.x, p._model.y - 10);
              });
              cov_1g1hrro9qp.s[213]++;
              break;
            }
          }
        },
        elements: {
          line: {
            cubicInterpolationMode: 'monotone',
            spanGaps: false,
            tension: 0
          }
        }
      },
      colors: ['rgba(' + $rootScope.personalData.activity.period.P + ',0.7)', 'rgba(' + $rootScope.personalData.activity.period.R + ',0.7)'],
      data_override: [{
        label: $translate.instant('ACTIVITY.DASHBOARD.RATE_PHY'), borderWidth: 1, type: 'bar'
      }, {
        label: $translate.instant('ACTIVITY.DASHBOARD.RATE_FINANC'), borderWidth: 3, type: 'line'
      }]
    },
    EvolutionActivite: function EvolutionActivite() {
      var color = (cov_1g1hrro9qp.s[214]++, $rootScope.personalData);
      cov_1g1hrro9qp.s[215]++;
      this.graphe_evolution.data = [[], []];
      cov_1g1hrro9qp.s[216]++;
      this.graphe_evolution.labels = [];
      cov_1g1hrro9qp.s[217]++;
      if (this.yeargraph == null) {
        cov_1g1hrro9qp.b[40][0]++;
        cov_1g1hrro9qp.s[218]++;
        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = $scope.listData_Periode_bksb[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var per = _step5.value;
            cov_1g1hrro9qp.s[219]++;

            if (per.PTBA_PERIOD == 1) {
              cov_1g1hrro9qp.b[41][0]++;
              cov_1g1hrro9qp.s[220]++;

              this.yeargraph = per.ANNEE_PERIOD;
              cov_1g1hrro9qp.s[221]++;
              break;
            } else {
              cov_1g1hrro9qp.b[41][1]++;
            }
          }
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }

        cov_1g1hrro9qp.s[222]++;
        if (this.yeargraph == null) {
          cov_1g1hrro9qp.b[42][0]++;
          cov_1g1hrro9qp.s[223]++;

          this.yeargraph = Date.newDate().getFullYear();
        } else {
          cov_1g1hrro9qp.b[42][1]++;
        }
      } else {
        cov_1g1hrro9qp.b[40][1]++;
      }
      cov_1g1hrro9qp.s[224]++;
      $scope.hasActivityEvolution = false;
      cov_1g1hrro9qp.s[225]++;
      $scope.loadingActivityEvolution = true;
      cov_1g1hrro9qp.s[226]++;
      $otherHttpService.get('Activites', { get: 'hometbEvol', year: this.yeargraph }, function (data) {
        cov_1g1hrro9qp.f[23]++;
        cov_1g1hrro9qp.s[227]++;

        // ['rgba(' + $rootScope.personalData.activity.period.P + ',0.7)', 'rgba(' + $rootScope.personalData.activity.period.R + ',0.7)']
        $scope.loadingActivityEvolution = false;
        var params = (cov_1g1hrro9qp.s[228]++, {
          locale: $LOGINService.getLang(),
          // Initializing Primary X and Y Axis
          primaryXAxis: {
            // title: 'Years',
            interval: 1,
            labelIntersectAction: 'Rotate45',
            valueType: 'Category',
            majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
            majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
            lineStyle: { width: 0 },
            maximumLabelWidth: 100
          },
          primaryYAxis: {
            valueType: 'Double',
            lineStyle: { width: 0 },
            majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
            minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
            labelFormat: 'P'
            // labelFormat: ($scope.analyse.FIELD == 1 || $scope.analyse.FIELD == 5) ? 'p0' : 'n0'
          },
          chartArea: {
            border: {
              width: 0
            }
          },
          useGroupingSeparator: true,
          // Initializing Chart Series
          series: [{ // FILL
            type: 'Column',
            dataSource: [],
            xName: 'x', yName: 'y', name: $translate.instant('DASHBOARD.PHY'),
            width: 3,
            fill: 'rgba(' + $rootScope.personalData.activity.period.P + ',0.7)'
          }, { // FILL
            type: 'Line',
            dataSource: [],
            xName: 'x', yName: 'y', name: $translate.instant('DASHBOARD.FIN'),
            width: 2,
            fill: 'rgba(' + $rootScope.personalData.activity.period.R + ',0.7)',
            marker: {
              visible: true,
              width: 10,
              height: 10
            }
          }],
          height: '300px',
          axisLabelRender: function axisLabelRender(args) {
            var _ = (cov_1g1hrro9qp.s[229]++, args.text.split(':'));
            cov_1g1hrro9qp.s[230]++;
            args.text = _[0].trim();
          },

          title: '',
          legendSettings: {
            visible: true
          },
          tooltip: { enable: true, shared: true }
        });

        cov_1g1hrro9qp.s[231]++;
        $scope.activityDashboard.dateRefresh_evo = new Date();
        cov_1g1hrro9qp.s[232]++;
        data = $filter('orderBy')(data, 'id');

        cov_1g1hrro9qp.s[233]++;
        for (var j = 0; j < data.length; j++) {
          cov_1g1hrro9qp.s[234]++;

          if (j == 0) {
            cov_1g1hrro9qp.b[43][0]++;

            var tampon = (cov_1g1hrro9qp.s[235]++, data[j].id.split('-'));
            cov_1g1hrro9qp.s[236]++;
            $scope.yearPTBA = (cov_1g1hrro9qp.b[44][0]++, tampon[0]) || (cov_1g1hrro9qp.b[44][1]++, '');
          } else {
            cov_1g1hrro9qp.b[43][1]++;
          }
          var label = (cov_1g1hrro9qp.s[237]++, $deltadate.getLabelPerioddepth(data[j].num * 12 / ((cov_1g1hrro9qp.b[45][0]++, data[j].d) || (cov_1g1hrro9qp.b[45][1]++, 1)), 12));
          cov_1g1hrro9qp.s[238]++;
          params.series[0].dataSource.push({
            x: label,
            y: data[j].tp ? (cov_1g1hrro9qp.b[46][0]++, data[j].tp / 100) : (cov_1g1hrro9qp.b[46][1]++, null)
          });
          cov_1g1hrro9qp.s[239]++;
          params.series[1].dataSource.push({
            x: label,
            y: data[j].tf ? (cov_1g1hrro9qp.b[47][0]++, data[j].tf / 100) : (cov_1g1hrro9qp.b[47][1]++, null)
          });
          cov_1g1hrro9qp.s[240]++;
          $scope.hasActivityEvolution = (cov_1g1hrro9qp.b[48][0]++, data[j].tf > 0) || (cov_1g1hrro9qp.b[48][1]++, data[j].tp) || (cov_1g1hrro9qp.b[48][2]++, $scope.hasActivityEvolution);
        }
        cov_1g1hrro9qp.s[241]++;
        $scope.activityDashboard.graphe_evolution = params;
      });
    },
    RepartionActivite: function RepartionActivite() {
      var _this2 = this;

      cov_1g1hrro9qp.s[242]++;

      $scope.loadingActivities = true;
      cov_1g1hrro9qp.s[243]++;
      this.graphe_repartition_physic = angular.copy(graphe_repartition_default);
      cov_1g1hrro9qp.s[244]++;
      this.graphe_repartition_finance = angular.copy(graphe_repartition_default);
      cov_1g1hrro9qp.s[245]++;
      this.graphe_repartition_finance.title = $translate.instant('DASHBOARD.FIN');
      cov_1g1hrro9qp.s[246]++;
      $scope.hasActivityData = false;
      cov_1g1hrro9qp.s[247]++;
      $otherHttpService.get('Activites', { get: 'hometb' }, function (data) {
        cov_1g1hrro9qp.f[24]++;
        cov_1g1hrro9qp.s[248]++;
        var _iteratorNormalCompletion6 = true;
        var _didIteratorError6 = false;
        var _iteratorError6 = undefined;

        try {
          for (var _iterator6 = data[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
            var item = _step6.value;

            var tp = (cov_1g1hrro9qp.s[249]++, {
              y: (cov_1g1hrro9qp.b[49][0]++, item.tp) || (cov_1g1hrro9qp.b[49][1]++, null),
              x: item.lib,
              min: item.min,
              max: item.max,
              color: 'rgb(' + item.idcol + ')'
            });
            cov_1g1hrro9qp.s[250]++;
            if (tp.y !== null) {
              cov_1g1hrro9qp.b[50][0]++;
              cov_1g1hrro9qp.s[251]++;

              $scope.activityDashboard.graphe_repartition_physic.series[0].dataSource.push(tp);
            } else {
              cov_1g1hrro9qp.b[50][1]++;
            }

            var tf = (cov_1g1hrro9qp.s[252]++, {
              y: (cov_1g1hrro9qp.b[51][0]++, item.tf) || (cov_1g1hrro9qp.b[51][1]++, null),
              x: item.lib,
              min: item.min,
              max: item.max,
              color: 'rgb(' + item.idcol + ')'
            });
            cov_1g1hrro9qp.s[253]++;
            if (tf.y !== null) {
              cov_1g1hrro9qp.b[52][0]++;
              cov_1g1hrro9qp.s[254]++;

              $scope.activityDashboard.graphe_repartition_finance.series[0].dataSource.push(tf);
            } else {
              cov_1g1hrro9qp.b[52][1]++;
            }

            cov_1g1hrro9qp.s[255]++;
            $scope.hasActivityData = (cov_1g1hrro9qp.b[53][0]++, item.tp > 0) || (cov_1g1hrro9qp.b[53][1]++, item.tf > 0) || (cov_1g1hrro9qp.b[53][2]++, $scope.hasActivityData);
          }
        } catch (err) {
          _didIteratorError6 = true;
          _iteratorError6 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion6 && _iterator6.return) {
              _iterator6.return();
            }
          } finally {
            if (_didIteratorError6) {
              throw _iteratorError6;
            }
          }
        }

        cov_1g1hrro9qp.s[256]++;
        _this2.graphe_repartition_physic.textRender = function (args) {
          cov_1g1hrro9qp.f[25]++;

          var item = (cov_1g1hrro9qp.s[257]++, (cov_1g1hrro9qp.b[54][0]++, data.find(function (v) {
            cov_1g1hrro9qp.f[26]++;
            cov_1g1hrro9qp.s[258]++;
            return v.lib == args.point.x;
          })) || (cov_1g1hrro9qp.b[54][1]++, {}));
          cov_1g1hrro9qp.s[259]++;
          args.point.customValue = args.point.x + '<br/><hr>';
          cov_1g1hrro9qp.s[260]++;
          args.point.customValue += '<br/>' + $translate.instant('DASHBOARD.ACTIVITY_PROGRESS_LABEL', {
            percent: Math.round(args.point.percentage),
            count: args.point.y,
            min: item.min,
            max: item.max
          });
          cov_1g1hrro9qp.s[261]++;
          args.text = args.point.x + ' (' + args.point.y + ')';
        };

        cov_1g1hrro9qp.s[262]++;
        $scope.loadingActivities = false;
      });
    },
    init: function init() {
      cov_1g1hrro9qp.s[263]++;

      this.EvolutionActivite();
      cov_1g1hrro9qp.s[264]++;
      this.RepartionActivite();
    }
  };

  cov_1g1hrro9qp.s[265]++;
  $scope.getAll = function () {
    cov_1g1hrro9qp.f[27]++;
    cov_1g1hrro9qp.s[266]++;

    $otherHttpService.get($scope.PATH, { get: 'all' }, function (data) {
      cov_1g1hrro9qp.f[28]++;
      cov_1g1hrro9qp.s[267]++;

      $scope.listProjects = data;
    });
  };
  cov_1g1hrro9qp.s[268]++;
  $scope.indicateurDashbord.getSituationIndicateur();
  cov_1g1hrro9qp.s[269]++;
  $scope.mapDashboard = {
    // mapView_: 2,
    loadingItem: 0,
    listIndicators: [],
    selectIndicator: null,
    selectLevel: null,
    requestPanel: false,
    legendMap: defaultLegend,
    mymap: null,
    flags: [],
    legend: {
      grades: [],
      labels: []
    },
    changeMapView: function changeMapView(view) {
      cov_1g1hrro9qp.s[270]++;

      this.mapView_ = view;
    },
    getColor: function getColor(d) {
      cov_1g1hrro9qp.s[271]++;

      for (var i = $scope.mapDashboard.legendMap.length - 1; i >= 0; i--) {
        cov_1g1hrro9qp.s[272]++;

        if (d > $scope.mapDashboard.legendMap[i].v) {
          cov_1g1hrro9qp.b[55][0]++;
          cov_1g1hrro9qp.s[273]++;

          return $scope.mapDashboard.legendMap[i].c;
        } else {
          cov_1g1hrro9qp.b[55][1]++;
        }
      }
      cov_1g1hrro9qp.s[274]++;
      return $scope.mapDashboard.legendMap[0].c;
    },
    initializeMap: function initializeMap() {
      var newHeight = (cov_1g1hrro9qp.s[275]++, $('body').height());
      cov_1g1hrro9qp.s[276]++;
      $('#mapid').height(newHeight - 350);

      var flags = (cov_1g1hrro9qp.s[277]++, angular.copy($rootScope.getCurrentProject().project.getFlag()));
      cov_1g1hrro9qp.s[278]++;
      var _iteratorNormalCompletion7 = true;
      var _didIteratorError7 = false;
      var _iteratorError7 = undefined;

      try {
        for (var _iterator7 = flags[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
          var flag = _step7.value;

          var colors = (cov_1g1hrro9qp.s[279]++, flag.color.split(','));
          var temp = (cov_1g1hrro9qp.s[280]++, []);
          cov_1g1hrro9qp.s[281]++;
          var _iteratorNormalCompletion8 = true;
          var _didIteratorError8 = false;
          var _iteratorError8 = undefined;

          try {
            for (var _iterator8 = colors[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
              var part = _step8.value;

              var l = (cov_1g1hrro9qp.s[282]++, parseInt(part, 10).toString(16));
              cov_1g1hrro9qp.s[283]++;
              temp.push((l.length == 1 ? (cov_1g1hrro9qp.b[56][0]++, '0') : (cov_1g1hrro9qp.b[56][1]++, '')) + l);
            }
          } catch (err) {
            _didIteratorError8 = true;
            _iteratorError8 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion8 && _iterator8.return) {
                _iterator8.return();
              }
            } finally {
              if (_didIteratorError8) {
                throw _iteratorError8;
              }
            }
          }

          cov_1g1hrro9qp.s[284]++;
          flag.color = '#' + temp.join('');
        }
      } catch (err) {
        _didIteratorError7 = true;
        _iteratorError7 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion7 && _iterator7.return) {
            _iterator7.return();
          }
        } finally {
          if (_didIteratorError7) {
            throw _iteratorError7;
          }
        }
      }

      cov_1g1hrro9qp.s[285]++;
      if (flags[0]) {
        cov_1g1hrro9qp.b[57][0]++;
        cov_1g1hrro9qp.s[286]++;

        flags[0].max = 999999999999;
      } else {
        cov_1g1hrro9qp.b[57][1]++;
      }
      cov_1g1hrro9qp.s[287]++;
      if (flags.length > 0) {
        cov_1g1hrro9qp.b[58][0]++;
        cov_1g1hrro9qp.s[288]++;

        flags[flags.length - 1].min = -1;
      } else {
        cov_1g1hrro9qp.b[58][1]++;
      }

      cov_1g1hrro9qp.s[289]++;
      this.flags = flags;

      cov_1g1hrro9qp.s[290]++;
      if (this.mymap) {
        cov_1g1hrro9qp.b[59][0]++;
        cov_1g1hrro9qp.s[291]++;

        this.mymap.remove();
      } else {
        cov_1g1hrro9qp.b[59][1]++;
      }
      cov_1g1hrro9qp.s[292]++;
      this.mymap = L.map('mapid', {
        fullscreenControl: {
          title: {
            false: $translate.instant('COMMON.FULLSCREEN'),
            true: $translate.instant('COMMON.EXIT_FULLSCREEN')
          }
        }
      }).setView([16.82, 11.3], 5);
      cov_1g1hrro9qp.s[293]++;
      $rootScope.getTileLayers(this.mymap, 'COMMON.GRAYSCALE');
      cov_1g1hrro9qp.s[294]++;
      if (this.layerGroup) {
        cov_1g1hrro9qp.b[60][0]++;
        cov_1g1hrro9qp.s[295]++;

        this.layerGroup.clearLayers();
        cov_1g1hrro9qp.s[296]++;
        this.layerGroup = null;
      } else {
        cov_1g1hrro9qp.b[60][1]++;
      }
      cov_1g1hrro9qp.s[297]++;
      this._getLayers();

      cov_1g1hrro9qp.s[298]++;
      this.processIndicator();
    },
    _getLayers: function _getLayers() {
      cov_1g1hrro9qp.s[299]++;

      if (!this.layerGroup) {
        cov_1g1hrro9qp.b[61][0]++;
        cov_1g1hrro9qp.s[300]++;

        this.layerGroup = L.layerGroup([], {
          attribution: '© <a href=\'https://www.mapbox.com/about/maps/\'>Mapbox</a> , © <a href=\'https://www.openstreetmap.org/copyright\'>OpenStreetMap</a>  <strong><a href=\'https://www.mapbox.com/map-feedback/\' target=\'_blank\'>Improve this map</a></strong>'
        });
        cov_1g1hrro9qp.s[301]++;
        this.layerGroup.addTo(this.mymap);
      } else {
        cov_1g1hrro9qp.b[61][1]++;
      }
      cov_1g1hrro9qp.s[302]++;
      this.layerGroup.clearLayers();
      /*    const mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew';
          L.tileLayer(mapstr).addTo(this.layerGroup);
      */
    },
    getInformation: function getInformation() {
      cov_1g1hrro9qp.s[303]++;

      $otherHttpService.get(PATH, { get: 'all_indic_search' }, function (data) {
        cov_1g1hrro9qp.f[29]++;
        cov_1g1hrro9qp.s[304]++;

        $scope.mapDashboard.listIndicators = data.map(function (indic) {
          cov_1g1hrro9qp.f[30]++;
          cov_1g1hrro9qp.s[305]++;

          indic.imageUrl = $deltahttp.getIndicatorRespositoy(indic.id);
          cov_1g1hrro9qp.s[306]++;
          return indic;
        });
        cov_1g1hrro9qp.s[307]++;
        $scope.mapDashboard.selectIndicator = data[0];
        cov_1g1hrro9qp.s[308]++;
        $scope.mapDashboard.changeIndicator();
      });
    },
    changeLevel: function changeLevel() {
      // NIVEAU_NG
      var find = (cov_1g1hrro9qp.s[309]++, false);
      cov_1g1hrro9qp.s[310]++;
      var _iteratorNormalCompletion9 = true;
      var _didIteratorError9 = false;
      var _iteratorError9 = undefined;

      try {
        for (var _iterator9 = $scope.mapDashboard.listgeo[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
          var item = _step9.value;
          cov_1g1hrro9qp.s[311]++;

          if (item.NIVEAU_NG - 1 == $scope.mapDashboard.selectLevel.NIVEAU_NG) {
            cov_1g1hrro9qp.b[62][0]++;
            cov_1g1hrro9qp.s[312]++;

            $scope.mapDashboard.selectLevel = item;
            cov_1g1hrro9qp.s[313]++;
            find = true;
            cov_1g1hrro9qp.s[314]++;
            break;
          } else {
            cov_1g1hrro9qp.b[62][1]++;
          }
        }
      } catch (err) {
        _didIteratorError9 = true;
        _iteratorError9 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion9 && _iterator9.return) {
            _iterator9.return();
          }
        } finally {
          if (_didIteratorError9) {
            throw _iteratorError9;
          }
        }
      }

      cov_1g1hrro9qp.s[315]++;
      if (find) {
        cov_1g1hrro9qp.b[63][0]++;
        cov_1g1hrro9qp.s[316]++;

        this.processIndicator();
      } else {
        cov_1g1hrro9qp.b[63][1]++;
      }
    },
    changeIndicator: function changeIndicator() {
      cov_1g1hrro9qp.s[317]++;

      if ($scope.mapDashboard.selectIndicator) {
        cov_1g1hrro9qp.b[64][0]++;
        cov_1g1hrro9qp.s[318]++;

        $scope.mapDashboard.listgeo = $scope.listData_GeoLevels_bksb.filter(function (value) {
          cov_1g1hrro9qp.f[31]++;
          cov_1g1hrro9qp.s[319]++;

          return value.id <= $scope.mapDashboard.selectIndicator.ng;
        });
        cov_1g1hrro9qp.s[320]++;
        $scope.mapDashboard.selectLevel = $scope.mapDashboard.listgeo[$scope.mapDashboard.listgeo.length - 1];
      } else {
        cov_1g1hrro9qp.b[64][1]++;
      }

      cov_1g1hrro9qp.s[321]++;
      this.processIndicator();
    },
    processIndicator: function processIndicator() {
      cov_1g1hrro9qp.s[322]++;

      if ((cov_1g1hrro9qp.b[66][0]++, !this.selectIndicator) || (cov_1g1hrro9qp.b[66][1]++, !this.selectLevel)) {
        cov_1g1hrro9qp.b[65][0]++;
        cov_1g1hrro9qp.s[323]++;

        return;
      } else {
        cov_1g1hrro9qp.b[65][1]++;
      }
      cov_1g1hrro9qp.s[324]++;
      this.legendMap = (cov_1g1hrro9qp.b[67][0]++, $scope.mapDashboard.selectIndicator.legend) || (cov_1g1hrro9qp.b[67][1]++, defaultLegend);
      cov_1g1hrro9qp.s[325]++;
      this.loadingItem = 0;
      // this.layerGroup.clearLayers();
      cov_1g1hrro9qp.s[326]++;
      if (!this.layerGroup) {
        cov_1g1hrro9qp.b[68][0]++;
        cov_1g1hrro9qp.s[327]++;

        return;
      } else {
        cov_1g1hrro9qp.b[68][1]++;
      }
      cov_1g1hrro9qp.s[328]++;
      this.layerGroup.clearLayers();

      var mapstr = (cov_1g1hrro9qp.s[329]++, 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew');
      cov_1g1hrro9qp.s[330]++;
      L.tileLayer(mapstr).addTo(this.layerGroup);
      var params = (cov_1g1hrro9qp.s[331]++, {
        CODE_NG: this.selectLevel.id,
        ID_INDIC: this.selectIndicator.id,
        get: 'suivi_n_g',
        valid: 0
      });
      cov_1g1hrro9qp.s[332]++;
      $scope.limitMap = [{ lat: 90, lon: 180 }, { lat: -90, lon: -180 }];
      cov_1g1hrro9qp.s[333]++;
      $otherHttpService.get(PATH_DEC, params, function (data) {
        cov_1g1hrro9qp.f[32]++;

        var maxValue = (cov_1g1hrro9qp.s[334]++, 0);
        cov_1g1hrro9qp.s[335]++;
        var _iteratorNormalCompletion10 = true;
        var _didIteratorError10 = false;
        var _iteratorError10 = undefined;

        try {
          for (var _iterator10 = data[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
            var item = _step10.value;
            cov_1g1hrro9qp.s[336]++;

            if ((cov_1g1hrro9qp.b[70][0]++, item.osm_id == -1) || (cov_1g1hrro9qp.b[70][1]++, item.osm_id == null)) {
              cov_1g1hrro9qp.b[69][0]++;
              cov_1g1hrro9qp.s[337]++;

              item.vr = null;
              cov_1g1hrro9qp.s[338]++;
              continue;
            } else {
              cov_1g1hrro9qp.b[69][1]++;
            }
            var rate = (cov_1g1hrro9qp.s[339]++, $deltaNumber.formatNumber(item.vr, null));

            cov_1g1hrro9qp.s[340]++;
            item.vr = rate;
            cov_1g1hrro9qp.s[341]++;
            if (angular.isNumber(rate)) {
              cov_1g1hrro9qp.b[71][0]++;
              cov_1g1hrro9qp.s[342]++;

              maxValue = maxValue > rate ? (cov_1g1hrro9qp.b[72][0]++, maxValue) : (cov_1g1hrro9qp.b[72][1]++, rate);
            } else {
              cov_1g1hrro9qp.b[71][1]++;
            }
          }
          /*
                  for (const item of data) {
                    if (!item.vr) {
                      continue;
                    }
                    const rate = item.vr;
                    $scope.mapDashboard.loadingItem++;
                    const display_html = '<h3>' + item.l + '</h3><h5>' + $filter('number')(rate, 2) + ' ' + $scope.mapDashboard.selectIndicator.cu + '</h5>';
                    $http({
                      method: 'GET',
                      url: 'https://nominatim.openstreetmap.org/reverse?format=json&polygon_geojson=1&osm_id=' + item.osm_id + '&osm_type=' + item.osm_type
                    }).then(response => {
                      const x = parseFloat(response.data.lat);
                      const y = parseFloat(response.data.lon);
                      $scope.limitMap[0].lat = $scope.limitMap[0].lat > x ? x : $scope.limitMap[0].lat;
                      $scope.limitMap[0].lon = $scope.limitMap[0].lon > y ? y : $scope.limitMap[0].lon;
                      $scope.limitMap[1].lat = $scope.limitMap[1].lat < x ? x : $scope.limitMap[1].lat;
                      $scope.limitMap[1].lon = $scope.limitMap[1].lon < y ? y : $scope.limitMap[1].lon;
                      const center = {
                        lat: ($scope.limitMap[1].lat + $scope.limitMap[0].lat) / 2,
                        lon: ($scope.limitMap[1].lon + $scope.limitMap[0].lon) / 2
                      };
          
                      this.mymap.setView([center.lat, center.lon]);
          
                      $scope.mapDashboard.loadingItem--;
                      L.geoJSON({
                        type: 'Feature',
                        properties: {label: display_html, value: rate},
                        geometry: response.data.geojson
                      }, {
                        //  $scope.mapDashboard.changeLevel()
                        pointToLayer(point, latlng) {
                          return L.circle(latlng, {radius: 15000});
                        },
                        onEachFeature(feature, layer) {
                          layer.on({
                            click() {
                              $scope.mapDashboard.changeLevel();
                            }
                          });
                        },
                        style(feature) {
                          return {
                            fillColor: $scope.mapDashboard.getColor(feature.properties.value),
                            weight: 2,
                            opacity: 1,
                            color: 'white',
                            dashArray: '3',
                            fillOpacity: 0.5
                          };
                        }
                      }).bindTooltip(layer => {
                        return layer.feature.properties.label;
                      }).addTo($scope.mapDashboard.layerGroup);
                    }, () => {
                      $scope.mapDashboard.loadingItem--;
                    });
                  }
          */
        } catch (err) {
          _didIteratorError10 = true;
          _iteratorError10 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion10 && _iterator10.return) {
              _iterator10.return();
            }
          } finally {
            if (_didIteratorError10) {
              throw _iteratorError10;
            }
          }
        }

        cov_1g1hrro9qp.s[343]++;
        if ($scope.mapDashboard.legendControl) {
          cov_1g1hrro9qp.b[73][0]++;
          cov_1g1hrro9qp.s[344]++;

          $scope.mapDashboard.legendControl.remove();
        } else {
          cov_1g1hrro9qp.b[73][1]++;
        }
        cov_1g1hrro9qp.s[345]++;
        $scope.mapDashboard.legendControl = L.control({ position: 'bottomright' });
        cov_1g1hrro9qp.s[346]++;
        $scope.mapDashboard.legendControl.onAdd = function (map) {
          cov_1g1hrro9qp.f[33]++;

          var div = (cov_1g1hrro9qp.s[347]++, L.DomUtil.create('div', 'info legend'));
          // loop through our density intervals and generate a label with a colored square for each interval
          cov_1g1hrro9qp.s[348]++;
          for (var i = 0; i < $scope.mapDashboard.legendMap.length; i++) {
            cov_1g1hrro9qp.s[349]++;

            div.innerHTML += '<i style="background:' + $scope.mapDashboard.legendMap[i].c + '"></i> ' + $filter('number')($scope.mapDashboard.legendMap[i].v) + ($scope.mapDashboard.legendMap[i + 1] ? (cov_1g1hrro9qp.b[74][0]++, ' &ndash; ' + $filter('number')($scope.mapDashboard.legendMap[i + 1].v)) : (cov_1g1hrro9qp.b[74][1]++, ' +')) + ($scope.mapDashboard.legendMap[i].l ? (cov_1g1hrro9qp.b[75][0]++, ' <smal style="font-weight: normal; font-size: 10px" >(' + $scope.mapDashboard.legendMap[i].l + ')</smal>') : (cov_1g1hrro9qp.b[75][1]++, '')) + '<br>';
          }
          cov_1g1hrro9qp.s[350]++;
          return div;
        };
        cov_1g1hrro9qp.s[351]++;
        $scope.mapDashboard.legendControl.addTo($scope.mapDashboard.mymap);
      });
    }
  };
  cov_1g1hrro9qp.s[352]++;
  $scope.mapDashboard.getInformation();

  cov_1g1hrro9qp.s[353]++;
  $deltaPeriode.addController($scope, function () {
    cov_1g1hrro9qp.f[34]++;
    cov_1g1hrro9qp.s[354]++;

    $scope.activityDashboard.init();
  });
  cov_1g1hrro9qp.s[355]++;
  $deltaGeoLevel.addController($scope, function () {
    cov_1g1hrro9qp.f[35]++;
    cov_1g1hrro9qp.s[356]++;

    // $scope.mapDashboard.listgeo = $scope.listData_GeoLevels_bksb;
    // $scope.mapDashboard.selectLevel = $scope.listData_GeoLevels_bksb[0];
    $scope.mapDashboard.changeIndicator();
  });

  cov_1g1hrro9qp.s[357]++;
  $scope.processLocality = function () {
    /* $otherHttpService.get('Localites', {get: 'all_query'}, queries => {
      let length_ = queries.length;
       for (const query of queries) {
        $http({
          method: 'GET',
          url: 'https://nominatim.openstreetmap.org/search?' + query[1]
        }).then(response => {
          // Search boundary
          let data = $filter('filter')(response.data, {class: 'boundary'}, true);
          if (data.length == 0) {
            data = $filter('filter')(response.data, {class: 'place'}, true);
          }
          if (data.length == 0) {
            data = [{osm_id: -1, lat: -1, lon: -1, osm_type: 'n'}];
          }
          const _param = {id: parseFloat(data[0].osm_id), x: parseFloat(data[0].lat), y: parseFloat(data[0].lon), type: data[0].osm_type[0].toUpperCase()};
          // $otherHttpService.post('Localites', {action: 'updatePos', valeur: _param, id: query[0]}, data_ => {});
           length_--;
          if (length_ == 0) {
            $timeout($scope.processLocality, 3000, false);
          }
        }, () => {
          length_--;
          const data = [{osm_id: -1, lat: -1, lon: -1, osm_type: 'n'}];
          for (const result of data) {
            const _param = {id: parseFloat(result.osm_id), x: parseFloat(result.lat), y: parseFloat(result.lon), type: result.osm_type[0].toUpperCase()};
            $otherHttpService.post('Localites', {action: 'updatePos', valeur: _param, id: query[0]}, data_ => {});
          }
          if (length_ == 0) {
            $timeout($scope.processLocality, 3000, false);
          }
        });
      }
    }); */

    // $scope.processLocality();
    // $scope.processLocality(page + 1);

    cov_1g1hrro9qp.f[36]++;
  };

  cov_1g1hrro9qp.s[358]++;
  $scope.processLocality();
  cov_1g1hrro9qp.s[359]++;
  $scope.data_main = [{ id: 0, display: true,
    TITLE: $translate.instant($rootScope.currentProject.project.PORTFOLIO == 0 ? (cov_1g1hrro9qp.b[76][0]++, 'DASHBOARD.TITLE') : (cov_1g1hrro9qp.b[76][1]++, 'DASHBOARD.TITLE_PORTFOLIO'), { code: $rootScope.currentProject.project.CODE_PRJ }),
    title_: $rootScope.currentProject.project.PORTFOLIO == 0 ? (cov_1g1hrro9qp.b[77][0]++, 'DASHBOARD.TITLE') : (cov_1g1hrro9qp.b[77][1]++, 'DASHBOARD.TITLE_PORTFOLIO'),
    def: true
  }, { id: -6, display: false, TITLE: $translate.instant('DASHBOARD.PROGRESS_REPORT'), title_: 'DASHBOARD.PROGRESS_REPORT', def: true }, { id: -4, display: false, TITLE: $translate.instant('DASHBOARD.ACTIVITY', { activity: $rootScope.currentProject.lastLogLevel }), title_: 'DASHBOARD.ACTIVITY', def: true }, { id: -5, display: false, TITLE: $translate.instant('DASHBOARD.INDICATOR'), title_: 'DASHBOARD.INDICATOR', def: true }, { id: -1, display: false, TITLE: $translate.instant('DASHBOARD.MAP'), title_: 'DASHBOARD.MAP', def: true
    /* , {id: -3, TITLE: $translate.instant('MENU.ANALYZE_DATA') + ' (' + $translate.instant('DASHBOARD.MAP') + ')'} */
  }];
  cov_1g1hrro9qp.s[360]++;
  $scope.dataIndicateur = [];
  cov_1g1hrro9qp.s[361]++;
  $scope.selectedDashboard = $scope.data_main[0];

  cov_1g1hrro9qp.s[362]++;
  var _iteratorNormalCompletion11 = true;
  var _didIteratorError11 = false;
  var _iteratorError11 = undefined;

  try {
    for (var _iterator11 = $scope.data_main[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
      var it = _step11.value;
      cov_1g1hrro9qp.s[363]++;

      if (it.id == $stateParams.view) {
        cov_1g1hrro9qp.b[78][0]++;
        cov_1g1hrro9qp.s[364]++;

        $scope.selectedDashboard = it;
        cov_1g1hrro9qp.s[365]++;
        break;
      } else {
        cov_1g1hrro9qp.b[78][1]++;
      }
    }
  } catch (err) {
    _didIteratorError11 = true;
    _iteratorError11 = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion11 && _iterator11.return) {
        _iterator11.return();
      }
    } finally {
      if (_didIteratorError11) {
        throw _iteratorError11;
      }
    }
  }

  cov_1g1hrro9qp.s[366]++;
  $otherHttpService.get('DashboardUser', { get: 'all_main', simple: 1 }, function (data) {
    cov_1g1hrro9qp.f[37]++;

    var _id = (cov_1g1hrro9qp.s[367]++, (cov_1g1hrro9qp.b[79][0]++, $stateParams.view) || (cov_1g1hrro9qp.b[79][1]++, 0)); // localStorage.getItem('TAB_PROJECT_' + $rootScope.currentProject.project.id);
    /* for (const item of data) {
      item.STRUCTURE = angular.fromJson(item.STRUCTURE);
    } */
    cov_1g1hrro9qp.s[368]++;
    if ($rootScope.currentProject.project.PORTFOLIO == 0) {
      cov_1g1hrro9qp.b[80][0]++;
      cov_1g1hrro9qp.s[369]++;

      if (data.includes('Stack trace')) {
        // $scope.data_main = $scope.data_main.concat(data);

        cov_1g1hrro9qp.b[81][0]++;
      } else {
        cov_1g1hrro9qp.b[81][1]++;
        cov_1g1hrro9qp.s[370]++;

        $scope.data_main = $scope.data_main.concat(data);
      }
    } else {
      cov_1g1hrro9qp.b[80][1]++;
    }

    var items = (cov_1g1hrro9qp.s[371]++, angular.fromJson((cov_1g1hrro9qp.b[82][0]++, localStorage.getItem('TAB_PROJECT_MENU_' + $rootScope.currentProject.project.id)) || (cov_1g1hrro9qp.b[82][1]++, '[]')));

    cov_1g1hrro9qp.s[372]++;
    $scope.availableDashboardList = $scope.data_main.filter(function (it) {
      cov_1g1hrro9qp.f[38]++;
      cov_1g1hrro9qp.s[373]++;

      return items.indexOf(it.id) >= 0;
    });
    cov_1g1hrro9qp.s[374]++;
    $scope.data_main = $scope.data_main.map(function (item) {
      cov_1g1hrro9qp.f[39]++;
      cov_1g1hrro9qp.s[375]++;
      var _iteratorNormalCompletion12 = true;
      var _didIteratorError12 = false;
      var _iteratorError12 = undefined;

      try {
        for (var _iterator12 = $scope.availableDashboardList[Symbol.iterator](), _step12; !(_iteratorNormalCompletion12 = (_step12 = _iterator12.next()).done); _iteratorNormalCompletion12 = true) {
          var it = _step12.value;
          cov_1g1hrro9qp.s[376]++;

          if (it.id == item.id) {
            cov_1g1hrro9qp.b[83][0]++;
            cov_1g1hrro9qp.s[377]++;

            item.display = true;
            cov_1g1hrro9qp.s[378]++;
            break;
          } else {
            cov_1g1hrro9qp.b[83][1]++;
          }
        }
      } catch (err) {
        _didIteratorError12 = true;
        _iteratorError12 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion12 && _iterator12.return) {
            _iterator12.return();
          }
        } finally {
          if (_didIteratorError12) {
            throw _iteratorError12;
          }
        }
      }

      cov_1g1hrro9qp.s[379]++;
      return item;
    });

    cov_1g1hrro9qp.s[380]++;
    $CRUDService.getAll('Indicateurs', { get: 'all_favoris' }, function (data) {
      cov_1g1hrro9qp.f[40]++;
      cov_1g1hrro9qp.s[381]++;

      $scope.dataIndicateur = data;
      cov_1g1hrro9qp.s[382]++;
      if (data.length > 0) {
        // $scope.data_main[1].view = true;

        cov_1g1hrro9qp.b[84][0]++;
      } else {
        cov_1g1hrro9qp.b[84][1]++;
      }
    });
  });

  cov_1g1hrro9qp.s[383]++;
  $scope.openOverview = function (item) {
    cov_1g1hrro9qp.f[41]++;
    cov_1g1hrro9qp.s[384]++;

    $CRUDService.getAll('Indicateurs', { get: 'all', idcl: item.CODE_CL, id: item.id, page: 1, nr: true, cat: true }, function (data) {
      cov_1g1hrro9qp.f[42]++;
      cov_1g1hrro9qp.s[385]++;

      $scope.loading = false;
      cov_1g1hrro9qp.s[386]++;
      if (data.length > 0) {
        cov_1g1hrro9qp.b[85][0]++;
        cov_1g1hrro9qp.s[387]++;

        $state.go('indicator.overview.dashboard', { indicator: data[0], idcl: item.CODE_CL, id: item.id, dashboard: true });
      } else {
        cov_1g1hrro9qp.b[85][1]++;
      }
    });
  };
  cov_1g1hrro9qp.s[388]++;
  $scope.getFinancial();

  cov_1g1hrro9qp.s[389]++;
  $scope.activities = {
    listActivities: [],
    getAll: function getAll() {
      cov_1g1hrro9qp.s[390]++;

      $CRUDService.getAll('Activites', { get: 'all_favoris' }, function (data) {
        cov_1g1hrro9qp.f[43]++;
        cov_1g1hrro9qp.s[391]++;
        var _iteratorNormalCompletion13 = true;
        var _didIteratorError13 = false;
        var _iteratorError13 = undefined;

        try {
          for (var _iterator13 = data[Symbol.iterator](), _step13; !(_iteratorNormalCompletion13 = (_step13 = _iterator13.next()).done); _iteratorNormalCompletion13 = true) {
            var el = _step13.value;
            cov_1g1hrro9qp.s[392]++;

            if (el.ID_UNITE < 1) {
              cov_1g1hrro9qp.b[86][0]++;
              cov_1g1hrro9qp.s[393]++;

              continue;
            } else {
              cov_1g1hrro9qp.b[86][1]++;
            }
            cov_1g1hrro9qp.s[394]++;
            el.DATE_DEMARRAGE = Date.newDate(el.DATE_DEMARRAGE);
            cov_1g1hrro9qp.s[395]++;
            el.DATE_FIN_ACT = Date.newDate(el.DATE_FIN_ACT);
          }
        } catch (err) {
          _didIteratorError13 = true;
          _iteratorError13 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion13 && _iterator13.return) {
              _iterator13.return();
            }
          } finally {
            if (_didIteratorError13) {
              throw _iteratorError13;
            }
          }
        }

        cov_1g1hrro9qp.s[396]++;
        $scope.activities.listActivities = $scope.activities.listActivities.concat(data).sort(function (valueA, valueB) {
          cov_1g1hrro9qp.f[44]++;
          cov_1g1hrro9qp.s[397]++;

          if (((cov_1g1hrro9qp.b[88][0]++, valueA.CODE_CLC) || (cov_1g1hrro9qp.b[88][1]++, '')).toUpperCase() > ((cov_1g1hrro9qp.b[89][0]++, valueB.CODE_CLC) || (cov_1g1hrro9qp.b[89][1]++, '')).toUpperCase()) {
            cov_1g1hrro9qp.b[87][0]++;
            cov_1g1hrro9qp.s[398]++;

            return 1;
          } else {
            cov_1g1hrro9qp.b[87][1]++;
          }
          cov_1g1hrro9qp.s[399]++;
          if (((cov_1g1hrro9qp.b[91][0]++, valueB.CODE_CLC) || (cov_1g1hrro9qp.b[91][1]++, '')).toUpperCase() > ((cov_1g1hrro9qp.b[92][0]++, valueA.CODE_CLC) || (cov_1g1hrro9qp.b[92][1]++, '')).toUpperCase()) {
            cov_1g1hrro9qp.b[90][0]++;
            cov_1g1hrro9qp.s[400]++;

            return -1;
          } else {
            cov_1g1hrro9qp.b[90][1]++;
          }
          cov_1g1hrro9qp.s[401]++;
          return 0;
        });
      });
    }
  };

  cov_1g1hrro9qp.s[402]++;
  $scope.indicators = {
    listIndicators: [],
    openOverview: function openOverview(item) {
      cov_1g1hrro9qp.s[403]++;

      $CRUDService.getAll('Indicateurs', { get: 'all', idcl: item.CODE_CL, idp: item.ID_PROJET, id: item.id, page: 1, nr: true, cat: true }, function (data) {
        cov_1g1hrro9qp.f[45]++;
        cov_1g1hrro9qp.s[404]++;

        if (data.length > 0) {
          cov_1g1hrro9qp.b[93][0]++;
          cov_1g1hrro9qp.s[405]++;

          $state.go('indicator.overview.settings', { indicator: data[0], idcl: item.CODE_CL, id: item.id });
        } else {
          cov_1g1hrro9qp.b[93][1]++;
        }
      });
    },
    getAll: function getAll() {
      cov_1g1hrro9qp.s[406]++;

      $CRUDService.getAll('Indicateurs', { get: 'all_favoris' }, function (data) {
        cov_1g1hrro9qp.f[46]++;
        cov_1g1hrro9qp.s[407]++;

        for (var i = 0; i < data.length; i++) {
          cov_1g1hrro9qp.s[408]++;

          data[i].DEB_EVAL_INDIC = Date.newDate(data[i].DEB_EVAL_INDIC);
          cov_1g1hrro9qp.s[409]++;
          data[i].FIN_EVAL_INDIC = Date.newDate(data[i].FIN_EVAL_INDIC);
        }
        cov_1g1hrro9qp.s[410]++;
        $scope.indicators.listIndicators = data.map(function (indic) {
          cov_1g1hrro9qp.f[47]++;
          cov_1g1hrro9qp.s[411]++;

          indic.imageUrl = $deltahttp.getIndicatorRespositoy(indic.id);
          cov_1g1hrro9qp.s[412]++;
          return indic;
        });
      });
    }
  };

  // CONVERSATION
  cov_1g1hrro9qp.s[413]++;
  $scope.view_conversation = true;
  cov_1g1hrro9qp.s[414]++;
  $rootScope.COMMENTS_HISTORY = {
    ID_PARENT: project.project.id,
    TYPE: 1
  };

  cov_1g1hrro9qp.s[415]++;
  $scope.viewChat = function () {
    cov_1g1hrro9qp.f[48]++;
    cov_1g1hrro9qp.s[416]++;

    $scope.view_conversation = !$scope.view_conversation;
  };
  // summernote report
  cov_1g1hrro9qp.s[417]++;
  $scope.report = {
    last_update: '0000-00-00',
    update_by: {
      id: 0,
      name: ''
    },
    displayValue: '',
    tmpValue: '',
    options_summernote: {
      focus: false,
      airMode: false,
      toolbar: [['edit', ['undo', 'redo']], ['headline', ['style']], ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']], ['fontface', ['fontname']], ['textsize', ['fontsize']], ['fontclr', ['color']], ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']], ['height', ['height']], ['table', ['table']], ['insert', ['link', 'picture', 'video', 'hr']],
      // ['view', ['fullscreen', 'codeview']],
      ['help', ['help']]]
    },
    editable: false,
    canUpdate: $rootScope.currentRight.u,
    adapteHeight: function adapteHeight() {},
    edit: function edit() {
      var newHeight = (cov_1g1hrro9qp.s[418]++, $('#report_progress').height());
      cov_1g1hrro9qp.s[419]++;
      this.options_summernote.height = newHeight - 100;
      cov_1g1hrro9qp.s[420]++;
      this.tmpValue = this.value;
      cov_1g1hrro9qp.s[421]++;
      this.editable = true;
    },
    cancel: function cancel() {
      cov_1g1hrro9qp.s[422]++;

      this.tmpValue = '';
      cov_1g1hrro9qp.s[423]++;
      this.editable = false;
      cov_1g1hrro9qp.s[424]++;
      this.getData();
    },
    getData: function getData() {
      cov_1g1hrro9qp.s[425]++;

      $CRUDService.getAll($scope.PATH, { get: 'project_progress' }, function (data) {
        cov_1g1hrro9qp.f[49]++;
        cov_1g1hrro9qp.s[426]++;

        $scope.report.displayValue = $sce.trustAsHtml(data.text);
        cov_1g1hrro9qp.s[427]++;
        $scope.report.value = data.text;
        cov_1g1hrro9qp.s[428]++;
        $scope.report.last_update = data.update;
        cov_1g1hrro9qp.s[429]++;
        $scope.report.update_by.id = data.user.id;
        cov_1g1hrro9qp.s[430]++;
        $scope.report.update_by.name = data.user.name;
      });
    },
    saveData: function saveData() {
      var _this3 = this;

      cov_1g1hrro9qp.s[431]++;

      this.value = this.tmpValue;
      cov_1g1hrro9qp.s[432]++;
      this.displayValue = $sce.trustAsHtml(this.tmpValue);
      cov_1g1hrro9qp.s[433]++;
      $CRUDService.save($scope.PATH, { action: 'project_progress', value: this.value }, function () {
        cov_1g1hrro9qp.f[50]++;
        cov_1g1hrro9qp.s[434]++;

        _this3.cancel();
      });
    }
  };
  cov_1g1hrro9qp.s[435]++;
  $scope.report.getData();
  cov_1g1hrro9qp.s[436]++;
  $scope.activities.getAll();
  cov_1g1hrro9qp.s[437]++;
  $scope.indicators.getAll();
  cov_1g1hrro9qp.s[438]++;
  $scope.date_update = new Date().getTime();

  var uploader = (cov_1g1hrro9qp.s[439]++, $scope.uploader = new FileUploader({
    url: $CRUDService.getServerAdress() + 'web_server.php?action=img&method=1&' + $CRUDService.getComplement() + '&li_bksb=Projet',
    headers: $CRUDService.getHeader(),
    alias: 'fileformatrapp',
    removeAfterUpload: true
  }));

  cov_1g1hrro9qp.s[440]++;
  uploader.onAfterAddingFile = function (fileItem, a, b, c, d, e) {
    cov_1g1hrro9qp.f[51]++;
    cov_1g1hrro9qp.s[441]++;

    fileItem.url = uploader.url + '&id=' + fileItem.p;
    cov_1g1hrro9qp.s[442]++;
    $scope.isloading = true;
    cov_1g1hrro9qp.s[443]++;
    fileItem.upload();
  };

  cov_1g1hrro9qp.s[444]++;
  uploader.onSuccessItem = function (fileItem, response, status, headers) {
    cov_1g1hrro9qp.f[52]++;
    cov_1g1hrro9qp.s[445]++;

    $scope.date_update = new Date().getTime();
    cov_1g1hrro9qp.s[446]++;
    $scope.isloading = false;
  };

  cov_1g1hrro9qp.s[447]++;
  $scope.analyseMap = {
    toggleLayer: function toggleLayer(request) {
      cov_1g1hrro9qp.s[448]++;

      if (!$scope.analyseMap.requestLayers[request.id]) {
        cov_1g1hrro9qp.b[94][0]++;
        cov_1g1hrro9qp.s[449]++;

        return;
      } else {
        cov_1g1hrro9qp.b[94][1]++;
      }
      cov_1g1hrro9qp.s[450]++;
      if (request.display__) {
        cov_1g1hrro9qp.b[95][0]++;
        cov_1g1hrro9qp.s[451]++;

        $scope.analyseMap.requestLayers[request.id].addTo($scope.layerGroup);
      } else {
        cov_1g1hrro9qp.b[95][1]++;
        cov_1g1hrro9qp.s[452]++;

        $scope.layerGroup.removeLayer($scope.analyseMap.requestLayers[request.id]);
      }
      // $scope.layerGroup
    },
    getMapUrl: function getMapUrl(value) {
      var mapstr = (cov_1g1hrro9qp.s[453]++, 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}');
      cov_1g1hrro9qp.s[454]++;
      switch (value) {
        case 0:
          cov_1g1hrro9qp.b[96][0]++;
          cov_1g1hrro9qp.s[455]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}';
          cov_1g1hrro9qp.s[456]++;
          break;
        case 1:
          cov_1g1hrro9qp.b[96][1]++;
          cov_1g1hrro9qp.s[457]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvs9jqk60fr91cscf0w98zth/tiles/256/{z}/{x}/{y}@2x';
          cov_1g1hrro9qp.s[458]++;
          break;
        case 2:
          cov_1g1hrro9qp.b[96][2]++;
          cov_1g1hrro9qp.s[459]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbh28818ic1cmqcfaz1is2/tiles/256/{z}/{x}/{y}@2x';
          cov_1g1hrro9qp.s[460]++;
          break;
        case 3:
          cov_1g1hrro9qp.b[96][3]++;
          cov_1g1hrro9qp.s[461]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbjkcx0mkz1cp3ot9cdfnk/tiles/256/{z}/{x}/{y}@2x';
          cov_1g1hrro9qp.s[462]++;
          break;
        case 4:
          cov_1g1hrro9qp.b[96][4]++;
          cov_1g1hrro9qp.s[463]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/ck0wcr1og05gz1cq7ik9y0ct5/tiles/256/{z}/{x}/{y}@2x';
          cov_1g1hrro9qp.s[464]++;
          break;
        default:
          cov_1g1hrro9qp.b[96][5]++;
          cov_1g1hrro9qp.s[465]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cj8x1v3iv05pz2rrtc9ps1tui/tiles/256/{z}/{x}/{y}';
          cov_1g1hrro9qp.s[466]++;
          break;
      }
      cov_1g1hrro9qp.s[467]++;
      return mapstr + '?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew';
    },
    drawLayers: function drawLayers(request) {
      var pathMarker = (cov_1g1hrro9qp.s[468]++, $deltahttp.getAnalysePinRepository(request.id));
      var params = (cov_1g1hrro9qp.s[469]++, { get: 'all_r_simple_table', withData: 1, id: request.ID, id_request: request.id, export: 1 });
      cov_1g1hrro9qp.s[470]++;
      $CRUDService.getAll(PATH_FORMULAR, params, function (data) {
        cov_1g1hrro9qp.f[53]++;
        cov_1g1hrro9qp.s[471]++;

        $scope.analyseMap.loading[request.id] = false;
        var count = (cov_1g1hrro9qp.s[472]++, 0);
        var center = (cov_1g1hrro9qp.s[473]++, [0, 0]);
        // $scope.analyseMap.requestLayers[request.id] = L.layerGroup();

        var mask = (cov_1g1hrro9qp.s[474]++, '');

        cov_1g1hrro9qp.s[475]++;
        var _iteratorNormalCompletion14 = true;
        var _didIteratorError14 = false;
        var _iteratorError14 = undefined;

        try {
          for (var _iterator14 = data.rows[Symbol.iterator](), _step14; !(_iteratorNormalCompletion14 = (_step14 = _iterator14.next()).done); _iteratorNormalCompletion14 = true) {
            var item = _step14.value;
            cov_1g1hrro9qp.s[476]++;

            if (item.o) {
              cov_1g1hrro9qp.b[97][0]++;
              cov_1g1hrro9qp.s[477]++;

              continue;
            } else {
              cov_1g1hrro9qp.b[97][1]++;
            }
            cov_1g1hrro9qp.s[478]++;
            mask += '<p><b>' + item.caption + ': </b> ${' + item.name + '} </p>';
          }
        } catch (err) {
          _didIteratorError14 = true;
          _iteratorError14 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion14 && _iterator14.return) {
              _iterator14.return();
            }
          } finally {
            if (_didIteratorError14) {
              throw _iteratorError14;
            }
          }
        }

        cov_1g1hrro9qp.s[479]++;
        var _iteratorNormalCompletion15 = true;
        var _didIteratorError15 = false;
        var _iteratorError15 = undefined;

        try {
          for (var _iterator15 = request.MAP[Symbol.iterator](), _step15; !(_iteratorNormalCompletion15 = (_step15 = _iterator15.next()).done); _iteratorNormalCompletion15 = true) {
            var layer = _step15.value;

            var l = (cov_1g1hrro9qp.s[480]++, L.layerGroup());
            cov_1g1hrro9qp.s[481]++;
            l.addTo($scope.layerGroup);

            cov_1g1hrro9qp.s[482]++;
            if (!layer.external) {
              cov_1g1hrro9qp.b[98][0]++;
              cov_1g1hrro9qp.s[483]++;
              var _iteratorNormalCompletion16 = true;
              var _didIteratorError16 = false;
              var _iteratorError16 = undefined;

              try {
                var _loop = function _loop() {
                  var item = _step16.value;
                  cov_1g1hrro9qp.s[484]++;

                  try {
                    var latlg = (cov_1g1hrro9qp.s[485]++, angular.isString(item[layer.field]) ? (cov_1g1hrro9qp.b[99][0]++, angular.fromJson(item[layer.field])) : (cov_1g1hrro9qp.b[99][1]++, item[layer.field]));
                    cov_1g1hrro9qp.s[486]++;
                    if (!angular.isArray(latlg)) {
                      cov_1g1hrro9qp.b[100][0]++;
                      cov_1g1hrro9qp.s[487]++;

                      return 'continue';
                    } else {
                      cov_1g1hrro9qp.b[100][1]++;
                    }
                    cov_1g1hrro9qp.s[488]++;
                    if (latlg.length < 2) {
                      cov_1g1hrro9qp.b[101][0]++;
                      cov_1g1hrro9qp.s[489]++;

                      return 'continue';
                    } else {
                      cov_1g1hrro9qp.b[101][1]++;
                    }
                    cov_1g1hrro9qp.s[490]++;
                    center[0] += latlg[0];
                    cov_1g1hrro9qp.s[491]++;
                    center[1] += latlg[1];
                    cov_1g1hrro9qp.s[492]++;
                    count++;
                    cov_1g1hrro9qp.s[493]++;
                    L.marker(latlg, { icon: L.icon({
                        iconUrl: pathMarker,
                        iconSize: [24, 41], // size of the icon
                        shadowSize: [32, 32] // size of the shadow
                      }) }).addTo(l).on('click', function (a, b, c) {
                      cov_1g1hrro9qp.f[54]++;

                      var label = (cov_1g1hrro9qp.s[494]++, angular.copy(mask));
                      cov_1g1hrro9qp.s[495]++;
                      var _iteratorNormalCompletion17 = true;
                      var _didIteratorError17 = false;
                      var _iteratorError17 = undefined;

                      try {
                        for (var _iterator17 = data.rows[Symbol.iterator](), _step17; !(_iteratorNormalCompletion17 = (_step17 = _iterator17.next()).done); _iteratorNormalCompletion17 = true) {
                          var item_s = _step17.value;

                          var valueItem = (cov_1g1hrro9qp.s[496]++, (cov_1g1hrro9qp.b[102][0]++, item[item_s.name]) || (cov_1g1hrro9qp.b[102][1]++, ''));
                          cov_1g1hrro9qp.s[497]++;
                          if (valueItem) {
                            cov_1g1hrro9qp.b[103][0]++;
                            cov_1g1hrro9qp.s[498]++;

                            if (item_s.t == 3) {
                              cov_1g1hrro9qp.b[104][0]++;
                              cov_1g1hrro9qp.s[499]++;

                              valueItem = $filter('date')(valueItem, valueItem.f);
                            } else {
                              cov_1g1hrro9qp.b[104][1]++;
                            }

                            cov_1g1hrro9qp.s[500]++;
                            if (item_s.t == 4) {
                              cov_1g1hrro9qp.b[105][0]++;
                              cov_1g1hrro9qp.s[501]++;

                              valueItem = $filter('number')(valueItem, valueItem.f);
                            } else {
                              cov_1g1hrro9qp.b[105][1]++;
                            }
                          } else {
                            cov_1g1hrro9qp.b[103][1]++;
                          }
                          cov_1g1hrro9qp.s[502]++;
                          label = label.replace('${' + item_s.name + '}', valueItem);
                        }
                      } catch (err) {
                        _didIteratorError17 = true;
                        _iteratorError17 = err;
                      } finally {
                        try {
                          if (!_iteratorNormalCompletion17 && _iterator17.return) {
                            _iterator17.return();
                          }
                        } finally {
                          if (_didIteratorError17) {
                            throw _iteratorError17;
                          }
                        }
                      }

                      cov_1g1hrro9qp.s[503]++;
                      L.popup().setLatLng(a.latlng).setContent(label).openOn($scope.mymap);
                    });

                    cov_1g1hrro9qp.s[504]++;
                    $scope.analyseMap.requestLayers[request.id] = l;
                  } catch (ex) {}
                };

                for (var _iterator16 = data.data[Symbol.iterator](), _step16; !(_iteratorNormalCompletion16 = (_step16 = _iterator16.next()).done); _iteratorNormalCompletion16 = true) {
                  var _ret = _loop();

                  if (_ret === 'continue') continue;
                }
              } catch (err) {
                _didIteratorError16 = true;
                _iteratorError16 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion16 && _iterator16.return) {
                    _iterator16.return();
                  }
                } finally {
                  if (_didIteratorError16) {
                    throw _iteratorError16;
                  }
                }
              }
            } else {
              cov_1g1hrro9qp.b[98][1]++;
            }
          }
        } catch (err) {
          _didIteratorError15 = true;
          _iteratorError15 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion15 && _iterator15.return) {
              _iterator15.return();
            }
          } finally {
            if (_didIteratorError15) {
              throw _iteratorError15;
            }
          }
        }

        cov_1g1hrro9qp.s[505]++;
        if (count > 1) {
          cov_1g1hrro9qp.b[106][0]++;
          cov_1g1hrro9qp.s[506]++;

          center[0] /= count;
          cov_1g1hrro9qp.s[507]++;
          center[1] /= count;
        } else {
          cov_1g1hrro9qp.b[106][1]++;
        }
        cov_1g1hrro9qp.s[508]++;
        $scope.mymap.setView(center);
      });
    },
    initialise: function initialise() {
      cov_1g1hrro9qp.s[509]++;

      // Get All Map Analyse
      this.requestLayers = {};
      cov_1g1hrro9qp.s[510]++;
      this.requests = [];
      cov_1g1hrro9qp.s[511]++;
      this.loading = {};
      cov_1g1hrro9qp.s[512]++;
      $CRUDService.getAll(PATH_FORMULAR, { get: 'all_map_request' }, function (data) {
        cov_1g1hrro9qp.f[55]++;

        var grayscale = (cov_1g1hrro9qp.s[513]++, L.tileLayer($scope.analyseMap.getMapUrl(1)));
        cov_1g1hrro9qp.s[514]++;
        $scope.mymap = L.map('map_analyze', { fullscreenControl: {
            title: {
              false: $translate.instant('COMMON.FULLSCREEN'),
              true: $translate.instant('COMMON.EXIT_FULLSCREEN')
            }
          }, layers: [/* grayscale */] }).setView([16.82, 11.3], 5);
        cov_1g1hrro9qp.s[515]++;
        $scope.layerGroup = L.layerGroup([], {
          attribution: '© <a href=\'https://www.mapbox.com/about/maps/\'>Mapbox</a> , © <a href=\'https://www.openstreetmap.org/copyright\'>OpenStreetMap</a>  <strong><a href=\'https://www.mapbox.com/map-feedback/\' target=\'_blank\'>Improve this map</a></strong>'
        });
        cov_1g1hrro9qp.s[516]++;
        $scope.layerGroup.addTo($scope.mymap);

        cov_1g1hrro9qp.s[517]++;
        $scope.layerGroup.clearLayers();
        cov_1g1hrro9qp.s[518]++;
        $scope.analyseMap.requests = data;
        /* const base = {};
        base[$translate.instant('COMMON.GRAYSCALE')] = grayscale;
        base[$translate.instant('COMMON.STREETMAP')] = L.tileLayer($scope.analyseMap.getMapUrl(4));
        base[$translate.instant('COMMON.SATELLITE')] = L.tileLayer($scope.analyseMap.getMapUrl(3));
         L.control.layers(base).addTo($scope.mymap);
        */
        cov_1g1hrro9qp.s[519]++;
        $rootScope.getTileLayers($scope.mymap, 'COMMON.GRAYSCALE');

        cov_1g1hrro9qp.s[520]++;
        var _iteratorNormalCompletion18 = true;
        var _didIteratorError18 = false;
        var _iteratorError18 = undefined;

        try {
          for (var _iterator18 = $scope.analyseMap.requests[Symbol.iterator](), _step18; !(_iteratorNormalCompletion18 = (_step18 = _iterator18.next()).done); _iteratorNormalCompletion18 = true) {
            var request = _step18.value;
            cov_1g1hrro9qp.s[521]++;

            request.display__ = true;
            cov_1g1hrro9qp.s[522]++;
            request.pin = $deltahttp.getAnalysePinRepository(request.id);
            cov_1g1hrro9qp.s[523]++;
            $scope.analyseMap.loading[request.id] = true;
            cov_1g1hrro9qp.s[524]++;
            $scope.analyseMap.drawLayers(request);
          }
        } catch (err) {
          _didIteratorError18 = true;
          _iteratorError18 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion18 && _iterator18.return) {
              _iterator18.return();
            }
          } finally {
            if (_didIteratorError18) {
              throw _iteratorError18;
            }
          }
        }
      });
      // Foreach analyse create layers
    }
  };

  // $scope.RepartionActivite();
  // $scope.EvolutionActivite();

  // Financial data
  var PATH_PROJECT = (cov_1g1hrro9qp.s[525]++, 'Projet');
  var PATH_FINANCING = (cov_1g1hrro9qp.s[526]++, 'Financement');

  var tmp_idProject = (cov_1g1hrro9qp.s[527]++, $rootScope.projectDetail());
  var idProject = (cov_1g1hrro9qp.s[528]++, project.project.id);

  cov_1g1hrro9qp.s[529]++;
  $CRUDService.getAll(PATH_PROJECT, { get: 'single', id: idProject }, function (data) {
    cov_1g1hrro9qp.f[56]++;
    cov_1g1hrro9qp.s[530]++;

    if (data.length <= 0) {
      cov_1g1hrro9qp.b[107][0]++;
      cov_1g1hrro9qp.s[531]++;

      undefined.isloading = false;
      cov_1g1hrro9qp.s[532]++;
      return;
    } else {
      cov_1g1hrro9qp.b[107][1]++;
    }
    cov_1g1hrro9qp.s[533]++;
    for (var i = 0; i < data.length; i++) {
      cov_1g1hrro9qp.s[534]++;

      if (!data[i].DATE_DEB_PROJET) {
        cov_1g1hrro9qp.b[108][0]++;
        cov_1g1hrro9qp.s[535]++;

        continue;
      } else {
        cov_1g1hrro9qp.b[108][1]++;
      }
      cov_1g1hrro9qp.s[536]++;
      data[i].DATE_DEB_PROJET = Date.newDate(data[i].DATE_DEB_PROJET);
      cov_1g1hrro9qp.s[537]++;
      data[i].COUT = parseFloat(data[i].COUT);
      cov_1g1hrro9qp.s[538]++;
      data[i].STATUT_BUDGET = parseInt(data[i].STATUT_BUDGET, 10) === 1;
      cov_1g1hrro9qp.s[539]++;
      data[i].DATE_FIN_PROJET = Date.newDate(data[i].DATE_FIN_PROJET);
      cov_1g1hrro9qp.s[540]++;
      data[i].DATE_MI_PRO = Date.newDate((cov_1g1hrro9qp.b[109][0]++, data[i].DATE_MI_PRO) || (cov_1g1hrro9qp.b[109][1]++, undefined));
      cov_1g1hrro9qp.s[541]++;
      data[i].PAYS_PROJET = parseInt(data[i].PAYS_PROJET, 10);
      var zone = (cov_1g1hrro9qp.s[542]++, []);
      cov_1g1hrro9qp.s[543]++;
      var _iteratorNormalCompletion19 = true;
      var _didIteratorError19 = false;
      var _iteratorError19 = undefined;

      try {
        for (var _iterator19 = Object.keys(data[i].LOCATION_FIELD)[Symbol.iterator](), _step19; !(_iteratorNormalCompletion19 = (_step19 = _iterator19.next()).done); _iteratorNormalCompletion19 = true) {
          var key = _step19.value;
          cov_1g1hrro9qp.s[544]++;

          zone.push(data[i].LOCATION_FIELD[key]);
        }
      } catch (err) {
        _didIteratorError19 = true;
        _iteratorError19 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion19 && _iterator19.return) {
            _iterator19.return();
          }
        } finally {
          if (_didIteratorError19) {
            throw _iteratorError19;
          }
        }
      }

      cov_1g1hrro9qp.s[545]++;
      data[i].LOCATION_FIELD = zone.join(' , ');
      cov_1g1hrro9qp.s[546]++;
      $scope.selectProject = data[i];
    }
  });

  cov_1g1hrro9qp.s[547]++;
  $scope.total_finacement = 0;
  cov_1g1hrro9qp.s[548]++;
  $scope.total_decaisser = 0;
  cov_1g1hrro9qp.s[549]++;
  $scope.total_decaisser_tmp = 0;
  cov_1g1hrro9qp.s[550]++;
  $scope.total_decaisser_rate = 0;
  // Financement

  cov_1g1hrro9qp.s[551]++;
  $scope.customSumColomn = function (data, aggColumn) {
    cov_1g1hrro9qp.f[57]++;

    var value = (cov_1g1hrro9qp.s[552]++, 0);
    var col_SOLD_AFF = (cov_1g1hrro9qp.s[553]++, 'RATE_AFF');
    cov_1g1hrro9qp.s[554]++;
    data.result.map(function (item) {
      cov_1g1hrro9qp.f[58]++;
      cov_1g1hrro9qp.s[555]++;

      if (item[aggColumn.columnName] === col_SOLD_AFF) {
        cov_1g1hrro9qp.b[110][0]++;
        cov_1g1hrro9qp.s[556]++;

        value += 1;
      } else {
        cov_1g1hrro9qp.b[110][1]++;
      }
    });

    cov_1g1hrro9qp.s[557]++;
    return value;
  };
  cov_1g1hrro9qp.s[558]++;
  $scope.total_tab = {
    AMOUNT_T: 0,
    DISBURSED_T: 0,
    RATE_T: 0,
    SOLD_T: 0
  };
  cov_1g1hrro9qp.s[559]++;
  $CRUDService.getAll(PATH_FINANCING, { get: 'state', id: idProject }, function (data) {
    cov_1g1hrro9qp.f[59]++;
    cov_1g1hrro9qp.s[560]++;

    data = data.map(function (value) {
      cov_1g1hrro9qp.f[60]++;
      cov_1g1hrro9qp.s[561]++;

      value.AMOUNT = (cov_1g1hrro9qp.b[111][0]++, parseFloat(value.AMOUNT)) || (cov_1g1hrro9qp.b[111][1]++, 0);
      cov_1g1hrro9qp.s[562]++;
      value.DISBURSED = (cov_1g1hrro9qp.b[112][0]++, parseFloat(value.DISBURSED)) || (cov_1g1hrro9qp.b[112][1]++, 0);
      cov_1g1hrro9qp.s[563]++;
      value.SOLD = (cov_1g1hrro9qp.b[113][0]++, parseFloat(value.SOLD)) || (cov_1g1hrro9qp.b[113][1]++, 0);
      cov_1g1hrro9qp.s[564]++;
      value.RATE = 0;

      cov_1g1hrro9qp.s[565]++;
      value.AMOUNT_AFF = $filter('number')(value.AMOUNT);
      cov_1g1hrro9qp.s[566]++;
      value.DISBURSED_AFF = $filter('number')(value.DISBURSED);
      cov_1g1hrro9qp.s[567]++;
      if (value.AMOUNT > 0) {
        cov_1g1hrro9qp.b[114][0]++;
        cov_1g1hrro9qp.s[568]++;

        value.RATE = value.DISBURSED * 100 / value.AMOUNT;
      } else {
        cov_1g1hrro9qp.b[114][1]++;
      }

      cov_1g1hrro9qp.s[569]++;
      value.RATE_AFF = $filter('number')(value.RATE);
      cov_1g1hrro9qp.s[570]++;
      value.SOLD_AFF = $filter('number')(value.SOLD);
      // rate distrubuted
      cov_1g1hrro9qp.s[571]++;
      if (value.AMOUNT) {
        cov_1g1hrro9qp.b[115][0]++;
        cov_1g1hrro9qp.s[572]++;

        $scope.total_finacement += parseFloat(value.AMOUNT);
      } else {
        cov_1g1hrro9qp.b[115][1]++;
      }
      cov_1g1hrro9qp.s[573]++;
      if (value.DISBURSED) {
        cov_1g1hrro9qp.b[116][0]++;
        cov_1g1hrro9qp.s[574]++;

        $scope.total_decaisser += parseFloat(value.DISBURSED);
      } else {
        cov_1g1hrro9qp.b[116][1]++;
      }
      // TOTAL

      cov_1g1hrro9qp.s[575]++;
      $scope.total_tab.AMOUNT_T += value.AMOUNT;
      cov_1g1hrro9qp.s[576]++;
      $scope.total_tab.DISBURSED_T += value.DISBURSED;
      cov_1g1hrro9qp.s[577]++;
      $scope.total_tab.SOLD_T += value.SOLD;

      cov_1g1hrro9qp.s[578]++;
      return value;
    });

    cov_1g1hrro9qp.s[579]++;
    $scope.set_disbursement_infos();

    cov_1g1hrro9qp.s[580]++;
    $scope.total_tab.AMOUNT_T = $filter('number')($scope.total_tab.AMOUNT_T);
    cov_1g1hrro9qp.s[581]++;
    $scope.total_tab.DISBURSED_T = $filter('number')($scope.total_tab.DISBURSED_T);
    cov_1g1hrro9qp.s[582]++;
    $scope.total_tab.RATE_T = $filter('number')($scope.total_decaisser_rate);
    cov_1g1hrro9qp.s[583]++;
    $scope.total_tab.SOLD_T = $filter('number')($scope.total_tab.SOLD_T);

    cov_1g1hrro9qp.s[584]++;
    $scope.financingState = angular.copy(data);
    cov_1g1hrro9qp.s[585]++;
    $scope.financialParams = {
      dataSource: data,
      gridLines: 'Both',

      columns: [{
        field: 'LIBELLE_FIN', headerText: $translate.instant('PROJECT.DASHBOARD_VIEW.FINANCING').toUpperCase()
      }, {
        field: 'AMOUNT_AFF', headerText: $translate.instant('PROJECT.DASHBOARD_VIEW.AMOUNT').toUpperCase() + ' (' + $rootScope.getGlobalCurrency() + ')',
        width: 150, textAlign: 'Right'
      }, {
        field: 'DISBURSED_AFF', headerText: $translate.instant('PROJECT.DASHBOARD_VIEW.DISBURSED').toUpperCase() + ' (' + $rootScope.getGlobalCurrency() + ')', width: 150, textAlign: 'Right'
      }, {
        field: 'RATE_AFF', headerText: '% ' + $translate.instant('PROJECT.DASHBOARD_VIEW.DISBURSED').toUpperCase(),
        width: 150, textAlign: 'Right', format: 'P'
      }, {
        field: 'SOLD_AFF', headerText: $translate.instant('PROJECT.DASHBOARD_VIEW.SOLD').toUpperCase() + ' (' + $rootScope.getGlobalCurrency() + ')',
        width: 150, textAlign: 'Right'
      }],
      allowSelection: false,
      width: 'auto',
      height: 'auto',
      allowTextWrap: true
    };

    // const grid = new ej.grids.Grid(params);
    // grid.appendTo('#financial_data');
    /*
    Chart by funding Source
      */
    cov_1g1hrro9qp.s[586]++;
    $scope.financingStateChart = {
      // Initializing Series
      series: [{
        type: 'Pie', dataSource: data.map(function (value) {
          cov_1g1hrro9qp.f[61]++;
          cov_1g1hrro9qp.s[587]++;

          return {
            value: value.DISBURSED ? (cov_1g1hrro9qp.b[117][0]++, Math.round(value.DISBURSED)) : (cov_1g1hrro9qp.b[117][1]++, null),
            name: value.LIBELLE_FIN
          };
        }).filter(function (item) {
          cov_1g1hrro9qp.f[62]++;
          cov_1g1hrro9qp.s[588]++;

          return item.value != null;
        }), xName: 'name', yName: 'value',
        dataLabel: { visible: true, position: 'Outside' // , innerRadius: '30%'
        } }], textRender: function textRender(args) {
        cov_1g1hrro9qp.s[589]++;

        if (!args.extraData) {
          cov_1g1hrro9qp.b[118][0]++;
          cov_1g1hrro9qp.s[590]++;

          args.extraData = $filter('number')(args.text);
        } else {
          cov_1g1hrro9qp.b[118][1]++;
        }
        cov_1g1hrro9qp.s[591]++;
        args.text = args.extraData + ' ' + $rootScope.getGlobalCurrency();
      },

      legendSettings: { visible: true, position: 'Bottom' }, enableSmartLabels: true,
      // title: $translate.instant('PROJECT.DASHBOARD_VIEW.TOTAL_DISBURSED_BY_FUNDING').toUpperCase(),
      // Initializing Tooltip
      tooltip: { enable: true, format: '${point.x} <br> ${point.percentage} %' },
      width: '100%',
      height: '100%'
    };
  });

  // Financement Chart
  cov_1g1hrro9qp.s[592]++;
  $CRUDService.getAll(PATH_FINANCING, { get: 'state_chart', id: idProject }, function (data) {
    cov_1g1hrro9qp.f[63]++;
    cov_1g1hrro9qp.s[593]++;

    $scope.financingState = data;
    /*
    Chart by funding Source
    DISBURSED_RATE: 'Disbursement Rate',
            TOTAL_DISBURSED_BY_FUNDING: 'Total disbursed by funding source'
      */
    cov_1g1hrro9qp.s[594]++;
    $scope.financingStateChartProgress = {
      locale: $LOGINService.getLang(),
      // Initializing Primary X and Y Axis
      primaryXAxis: {
        // title: 'Years',
        interval: 1,
        labelIntersectAction: 'Rotate45',
        valueType: 'Category',
        majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
        majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
        lineStyle: { width: 0 }
      },
      primaryYAxis: {
        valueType: 'Double',
        lineStyle: { width: 0 },
        majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
        minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
        labelFormat: 'P'
      },
      chartArea: {
        border: {
          width: 0
        }
      },
      useGroupingSeparator: true,
      // Initializing Chart Series
      series: [{ // FILL
        type: 'Line',
        opacity: 0.5,
        dataSource: data,
        // dataLabel: {visible: true, position: 'Outside'},
        fill: 'rgba(0, 15, 62, 0.7)',
        xName: 'YEAR', yName: 'RATE', // name: serie.TITLE,
        width: 3,
        marker: {
          visible: true,
          width: 10,
          height: 10
        }
      }],
      height: 'calc(100% - 20px)',
      width: '100%',
      // Initializing Chart title
      // title: $translate.instant('PROJECT.DASHBOARD_VIEW.DISBURSED_RATE').toUpperCase(),

      // crosshair: {enable: true, lineType: 'Vertical'},
      // Enable Legend
      legendSettings: {
        visible: false
      },
      // selectionMode: 'Series',
      /* pointClick(args) {
        $scope.process.deroule(args.pointIndex);
      }, */
      tooltip: { enable: true, shared: false }
    };
  });

  // SOURCE DE FINANCEMENT

  cov_1g1hrro9qp.s[595]++;
  $scope.set_disbursement_infos = function () {
    cov_1g1hrro9qp.f[64]++;
    cov_1g1hrro9qp.s[596]++;

    $scope.total_decaisser_rate = 0;
    cov_1g1hrro9qp.s[597]++;
    if ($scope.total_finacement > 0) {
      cov_1g1hrro9qp.b[119][0]++;
      cov_1g1hrro9qp.s[598]++;

      $scope.total_decaisser_rate = $scope.total_decaisser * 100 / $scope.total_finacement;
    } else {
      cov_1g1hrro9qp.b[119][1]++;
    }
  };

  cov_1g1hrro9qp.s[599]++;
  $scope.avancephysique = {
    perct: 0,
    perctview: 0,
    getall: function getall() {
      cov_1g1hrro9qp.s[600]++;

      $CRUDService.getAll('Activites', { get: 'projet_global_informations' }, function (_data) {
        cov_1g1hrro9qp.f[65]++;
        cov_1g1hrro9qp.s[601]++;

        if (_data) {
          cov_1g1hrro9qp.b[120][0]++;
          cov_1g1hrro9qp.s[602]++;

          $scope.avancephysique.perct = parseFloat(_data);
          cov_1g1hrro9qp.s[603]++;
          $scope.avancephysique.perctview = $scope.avancephysique.perct;
          cov_1g1hrro9qp.s[604]++;
          if ($scope.avancephysique.perctview > 100) {
            cov_1g1hrro9qp.b[121][0]++;
            cov_1g1hrro9qp.s[605]++;

            $scope.avancephysique.perctview = 100;
          } else {
            cov_1g1hrro9qp.b[121][1]++;
          }
        } else {
          cov_1g1hrro9qp.b[120][1]++;
        }
      });
    }
  };
  cov_1g1hrro9qp.s[606]++;
  $scope.avancephysique.getall();
});