'use strict';

var cov_1aopra40ab = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/accumulateChartSyncfusion.js',
      hash = 'f1bff3e5463303b7f5d3259cfdde89ea40574307',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/accumulateChartSyncfusion.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 91,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 90,
          column: 4
        }
      },
      '2': {
        start: {
          line: 11,
          column: 6
        },
        end: {
          line: 13,
          column: 7
        }
      },
      '3': {
        start: {
          line: 12,
          column: 8
        },
        end: {
          line: 12,
          column: 25
        }
      },
      '4': {
        start: {
          line: 14,
          column: 21
        },
        end: {
          line: 57,
          column: 7
        }
      },
      '5': {
        start: {
          line: 15,
          column: 8
        },
        end: {
          line: 24,
          column: 9
        }
      },
      '6': {
        start: {
          line: 16,
          column: 10
        },
        end: {
          line: 16,
          column: 36
        }
      },
      '7': {
        start: {
          line: 25,
          column: 8
        },
        end: {
          line: 27,
          column: 9
        }
      },
      '8': {
        start: {
          line: 26,
          column: 10
        },
        end: {
          line: 26,
          column: 17
        }
      },
      '9': {
        start: {
          line: 29,
          column: 25
        },
        end: {
          line: 40,
          column: 9
        }
      },
      '10': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 52,
          column: 9
        }
      },
      '11': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 51,
          column: 11
        }
      },
      '12': {
        start: {
          line: 43,
          column: 24
        },
        end: {
          line: 43,
          column: 25
        }
      },
      '13': {
        start: {
          line: 44,
          column: 12
        },
        end: {
          line: 47,
          column: 13
        }
      },
      '14': {
        start: {
          line: 45,
          column: 14
        },
        end: {
          line: 45,
          column: 51
        }
      },
      '15': {
        start: {
          line: 46,
          column: 14
        },
        end: {
          line: 46,
          column: 22
        }
      },
      '16': {
        start: {
          line: 48,
          column: 12
        },
        end: {
          line: 50,
          column: 13
        }
      },
      '17': {
        start: {
          line: 49,
          column: 14
        },
        end: {
          line: 49,
          column: 57
        }
      },
      '18': {
        start: {
          line: 54,
          column: 8
        },
        end: {
          line: 54,
          column: 68
        }
      },
      '19': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 56,
          column: 41
        }
      },
      '20': {
        start: {
          line: 58,
          column: 6
        },
        end: {
          line: 62,
          column: 9
        }
      },
      '21': {
        start: {
          line: 59,
          column: 8
        },
        end: {
          line: 59,
          column: 28
        }
      },
      '22': {
        start: {
          line: 61,
          column: 8
        },
        end: {
          line: 61,
          column: 17
        }
      },
      '23': {
        start: {
          line: 63,
          column: 6
        },
        end: {
          line: 63,
          column: 15
        }
      },
      '24': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 82,
          column: 9
        }
      },
      '25': {
        start: {
          line: 65,
          column: 8
        },
        end: {
          line: 65,
          column: 28
        }
      },
      '26': {
        start: {
          line: 67,
          column: 8
        },
        end: {
          line: 69,
          column: 9
        }
      },
      '27': {
        start: {
          line: 68,
          column: 10
        },
        end: {
          line: 68,
          column: 17
        }
      },
      '28': {
        start: {
          line: 70,
          column: 8
        },
        end: {
          line: 72,
          column: 9
        }
      },
      '29': {
        start: {
          line: 71,
          column: 10
        },
        end: {
          line: 71,
          column: 17
        }
      },
      '30': {
        start: {
          line: 73,
          column: 8
        },
        end: {
          line: 73,
          column: 70
        }
      },
      '31': {
        start: {
          line: 74,
          column: 8
        },
        end: {
          line: 81,
          column: 17
        }
      },
      '32': {
        start: {
          line: 75,
          column: 10
        },
        end: {
          line: 75,
          column: 92
        }
      },
      '33': {
        start: {
          line: 76,
          column: 10
        },
        end: {
          line: 80,
          column: 19
        }
      },
      '34': {
        start: {
          line: 77,
          column: 12
        },
        end: {
          line: 77,
          column: 57
        }
      },
      '35': {
        start: {
          line: 78,
          column: 12
        },
        end: {
          line: 78,
          column: 29
        }
      },
      '36': {
        start: {
          line: 79,
          column: 12
        },
        end: {
          line: 79,
          column: 44
        }
      },
      '37': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 88,
          column: 7
        }
      },
      '38': {
        start: {
          line: 84,
          column: 8
        },
        end: {
          line: 87,
          column: 11
        }
      },
      '39': {
        start: {
          line: 85,
          column: 10
        },
        end: {
          line: 85,
          column: 33
        }
      },
      '40': {
        start: {
          line: 86,
          column: 10
        },
        end: {
          line: 86,
          column: 67
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 61
          },
          end: {
            line: 1,
            column: 62
          }
        },
        loc: {
          start: {
            line: 1,
            column: 73
          },
          end: {
            line: 91,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 14,
            column: 21
          },
          end: {
            line: 14,
            column: 22
          }
        },
        loc: {
          start: {
            line: 14,
            column: 33
          },
          end: {
            line: 57,
            column: 7
          }
        },
        line: 14
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 58,
            column: 19
          },
          end: {
            line: 58,
            column: 20
          }
        },
        loc: {
          start: {
            line: 58,
            column: 28
          },
          end: {
            line: 60,
            column: 7
          }
        },
        line: 58
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 60,
            column: 9
          },
          end: {
            line: 60,
            column: 10
          }
        },
        loc: {
          start: {
            line: 60,
            column: 21
          },
          end: {
            line: 62,
            column: 7
          }
        },
        line: 60
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 64,
            column: 19
          },
          end: {
            line: 64,
            column: 20
          }
        },
        loc: {
          start: {
            line: 64,
            column: 28
          },
          end: {
            line: 66,
            column: 7
          }
        },
        line: 64
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 66,
            column: 9
          },
          end: {
            line: 66,
            column: 10
          }
        },
        loc: {
          start: {
            line: 66,
            column: 21
          },
          end: {
            line: 82,
            column: 7
          }
        },
        line: 66
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 74,
            column: 17
          },
          end: {
            line: 74,
            column: 18
          }
        },
        loc: {
          start: {
            line: 74,
            column: 23
          },
          end: {
            line: 81,
            column: 9
          }
        },
        line: 74
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 76,
            column: 19
          },
          end: {
            line: 76,
            column: 20
          }
        },
        loc: {
          start: {
            line: 76,
            column: 25
          },
          end: {
            line: 80,
            column: 11
          }
        },
        line: 76
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 84,
            column: 36
          },
          end: {
            line: 84,
            column: 37
          }
        },
        loc: {
          start: {
            line: 84,
            column: 45
          },
          end: {
            line: 87,
            column: 9
          }
        },
        line: 84
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 11,
            column: 6
          },
          end: {
            line: 13,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 11,
            column: 6
          },
          end: {
            line: 13,
            column: 7
          }
        }, {
          start: {
            line: 11,
            column: 6
          },
          end: {
            line: 13,
            column: 7
          }
        }],
        line: 11
      },
      '1': {
        loc: {
          start: {
            line: 15,
            column: 8
          },
          end: {
            line: 24,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 15,
            column: 8
          },
          end: {
            line: 24,
            column: 9
          }
        }, {
          start: {
            line: 15,
            column: 8
          },
          end: {
            line: 24,
            column: 9
          }
        }],
        line: 15
      },
      '2': {
        loc: {
          start: {
            line: 25,
            column: 8
          },
          end: {
            line: 27,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 25,
            column: 8
          },
          end: {
            line: 27,
            column: 9
          }
        }, {
          start: {
            line: 25,
            column: 8
          },
          end: {
            line: 27,
            column: 9
          }
        }],
        line: 25
      },
      '3': {
        loc: {
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 52,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 52,
            column: 9
          }
        }, {
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 52,
            column: 9
          }
        }],
        line: 41
      },
      '4': {
        loc: {
          start: {
            line: 48,
            column: 12
          },
          end: {
            line: 50,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 48,
            column: 12
          },
          end: {
            line: 50,
            column: 13
          }
        }, {
          start: {
            line: 48,
            column: 12
          },
          end: {
            line: 50,
            column: 13
          }
        }],
        line: 48
      },
      '5': {
        loc: {
          start: {
            line: 67,
            column: 8
          },
          end: {
            line: 69,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 8
          },
          end: {
            line: 69,
            column: 9
          }
        }, {
          start: {
            line: 67,
            column: 8
          },
          end: {
            line: 69,
            column: 9
          }
        }],
        line: 67
      },
      '6': {
        loc: {
          start: {
            line: 67,
            column: 14
          },
          end: {
            line: 67,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 67,
            column: 14
          },
          end: {
            line: 67,
            column: 27
          }
        }, {
          start: {
            line: 67,
            column: 31
          },
          end: {
            line: 67,
            column: 44
          }
        }],
        line: 67
      },
      '7': {
        loc: {
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 72,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 72,
            column: 9
          }
        }, {
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 72,
            column: 9
          }
        }],
        line: 70
      },
      '8': {
        loc: {
          start: {
            line: 73,
            column: 28
          },
          end: {
            line: 73,
            column: 69
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 73,
            column: 28
          },
          end: {
            line: 73,
            column: 46
          }
        }, {
          start: {
            line: 73,
            column: 50
          },
          end: {
            line: 73,
            column: 69
          }
        }],
        line: 73
      },
      '9': {
        loc: {
          start: {
            line: 75,
            column: 29
          },
          end: {
            line: 75,
            column: 58
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 75,
            column: 45
          },
          end: {
            line: 75,
            column: 50
          }
        }, {
          start: {
            line: 75,
            column: 53
          },
          end: {
            line: 75,
            column: 58
          }
        }],
        line: 75
      },
      '10': {
        loc: {
          start: {
            line: 75,
            column: 60
          },
          end: {
            line: 75,
            column: 90
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 75,
            column: 60
          },
          end: {
            line: 75,
            column: 79
          }
        }, {
          start: {
            line: 75,
            column: 83
          },
          end: {
            line: 75,
            column: 90
          }
        }],
        line: 75
      },
      '11': {
        loc: {
          start: {
            line: 77,
            column: 32
          },
          end: {
            line: 77,
            column: 56
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 77,
            column: 32
          },
          end: {
            line: 77,
            column: 50
          }
        }, {
          start: {
            line: 77,
            column: 54
          },
          end: {
            line: 77,
            column: 56
          }
        }],
        line: 77
      },
      '12': {
        loc: {
          start: {
            line: 83,
            column: 6
          },
          end: {
            line: 88,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 83,
            column: 6
          },
          end: {
            line: 88,
            column: 7
          }
        }, {
          start: {
            line: 83,
            column: 6
          },
          end: {
            line: 88,
            column: 7
          }
        }],
        line: 83
      },
      '13': {
        loc: {
          start: {
            line: 86,
            column: 36
          },
          end: {
            line: 86,
            column: 65
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 86,
            column: 36
          },
          end: {
            line: 86,
            column: 54
          }
        }, {
          start: {
            line: 86,
            column: 58
          },
          end: {
            line: 86,
            column: 65
          }
        }],
        line: 86
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1aopra40ab.s[0]++;
angular.module('app').directive('accumulateChartSyncfusion', function ($timeout) {
  cov_1aopra40ab.f[0]++;
  cov_1aopra40ab.s[1]++;

  return {
    restrict: 'A',
    scope: {
      params: '=',
      export: '=?',
      exportWithRightClick: '=?'
    },
    template: '<div></div>',
    link: function link(scope, element, attrs) {
      cov_1aopra40ab.s[2]++;

      if (!scope.export) {
        cov_1aopra40ab.b[0][0]++;
        cov_1aopra40ab.s[3]++;

        scope.export = 0;
      } else {
        cov_1aopra40ab.b[0][1]++;
      }
      cov_1aopra40ab.s[4]++;
      var render = function render() {
        cov_1aopra40ab.f[1]++;
        cov_1aopra40ab.s[5]++;

        if (scope.chart) {
          cov_1aopra40ab.b[1][0]++;
          cov_1aopra40ab.s[6]++;

          element[0].innerHTML = '';
          /* const tooltip = document.getElementsByClassName('e-tooltip-wrap')[0];
          if (tooltip) {
            tooltip.remove();
          }
          scope.chart.destroy();
          scope.chart.removeSvg();
          scope.chart = null; */
        } else {
          cov_1aopra40ab.b[1][1]++;
        }
        cov_1aopra40ab.s[7]++;
        if (!scope.params) {
          cov_1aopra40ab.b[2][0]++;
          cov_1aopra40ab.s[8]++;

          return;
        } else {
          cov_1aopra40ab.b[2][1]++;
        }

        var palettes = (cov_1aopra40ab.s[9]++, ['#BAF171', '#C4EAF6', '#E9B1FF', '#F49C94', '#FCF086', '#58D740', '#7DDAF8', '#CA6FEE', '#FE855F', '#F9E850', '#1EB799', '#29DAE9', '#B145F4', '#CD5145', '#EDDA2F', '#2EAB77', '#20C9B3', '#6F43EA', '#EB5757', '#EDC32F', '#66AF45', '#33ACED', '#562EBB', '#DA4A2B', '#D39F17', '#78A73D', '#208AC9', '#792AF9', '#F35634', '#B8953B', '#A5B212', '#3160BC', '#651E91', '#AE534B', '#F38434', '#12A5B2', '#5C83E7', '#911E71', '#7E3737', '#D36617', '#2F7980', '#035C88', '#401C5B', '#5E4138', '#B66225', '#0E3E53', '#093B69', '#240E35', '#3E2B11', '#B24712']);
        cov_1aopra40ab.s[10]++;
        if (scope.params.series) {
          cov_1aopra40ab.b[3][0]++;
          cov_1aopra40ab.s[11]++;
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = scope.params.series[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var series = _step.value;

              var index = (cov_1aopra40ab.s[12]++, 0);
              cov_1aopra40ab.s[13]++;
              var _iteratorNormalCompletion2 = true;
              var _didIteratorError2 = false;
              var _iteratorError2 = undefined;

              try {
                for (var _iterator2 = series.dataSource[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                  var item = _step2.value;
                  cov_1aopra40ab.s[14]++;

                  item.deltaAppColor = palettes[index];
                  cov_1aopra40ab.s[15]++;
                  index++;
                }
              } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion2 && _iterator2.return) {
                    _iterator2.return();
                  }
                } finally {
                  if (_didIteratorError2) {
                    throw _iteratorError2;
                  }
                }
              }

              cov_1aopra40ab.s[16]++;
              if (!series.pointColorMapping) {
                cov_1aopra40ab.b[4][0]++;
                cov_1aopra40ab.s[17]++;

                series.pointColorMapping = 'deltaAppColor';
              } else {
                cov_1aopra40ab.b[4][1]++;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        } else {
          cov_1aopra40ab.b[3][1]++;
        }

        cov_1aopra40ab.s[18]++;
        scope.chart = new ej.charts.AccumulationChart(scope.params);

        cov_1aopra40ab.s[19]++;
        scope.chart.appendTo(element[0]);
      };
      cov_1aopra40ab.s[20]++;
      scope.$watch(function (scope) {
        cov_1aopra40ab.f[2]++;
        cov_1aopra40ab.s[21]++;

        return scope.params;
      }, function (newValue) {
        cov_1aopra40ab.f[3]++;
        cov_1aopra40ab.s[22]++;

        render();
      });
      cov_1aopra40ab.s[23]++;
      render();
      cov_1aopra40ab.s[24]++;
      scope.$watch(function (scope) {
        cov_1aopra40ab.f[4]++;
        cov_1aopra40ab.s[25]++;

        return scope.export;
      }, function (newValue) {
        cov_1aopra40ab.f[5]++;
        cov_1aopra40ab.s[26]++;

        if (!((cov_1aopra40ab.b[6][0]++, newValue == 1) || (cov_1aopra40ab.b[6][1]++, newValue == 2))) {
          cov_1aopra40ab.b[5][0]++;
          cov_1aopra40ab.s[27]++;

          return;
        } else {
          cov_1aopra40ab.b[5][1]++;
        }
        cov_1aopra40ab.s[28]++;
        if (!scope.params) {
          cov_1aopra40ab.b[7][0]++;
          cov_1aopra40ab.s[29]++;

          return;
        } else {
          cov_1aopra40ab.b[7][1]++;
        }
        cov_1aopra40ab.s[30]++;
        scope.chart.title = (cov_1aopra40ab.b[8][0]++, scope.params.title) || (cov_1aopra40ab.b[8][1]++, scope.params.title_);
        cov_1aopra40ab.s[31]++;
        $timeout(function () {
          cov_1aopra40ab.f[6]++;
          cov_1aopra40ab.s[32]++;

          scope.chart.export(newValue == 1 ? (cov_1aopra40ab.b[9][0]++, 'PNG') : (cov_1aopra40ab.b[9][1]++, 'PDF'), (cov_1aopra40ab.b[10][0]++, scope.params.title_) || (cov_1aopra40ab.b[10][1]++, 'chart'));
          cov_1aopra40ab.s[33]++;
          $timeout(function () {
            cov_1aopra40ab.f[7]++;
            cov_1aopra40ab.s[34]++;

            scope.chart.title = (cov_1aopra40ab.b[11][0]++, scope.params.title) || (cov_1aopra40ab.b[11][1]++, '');
            cov_1aopra40ab.s[35]++;
            scope.export = 0;
            cov_1aopra40ab.s[36]++;
            scope.$emit('afterChartExport');
          }, 1000);
        }, 1500);
      });
      cov_1aopra40ab.s[37]++;
      if (scope.exportWithRightClick) {
        cov_1aopra40ab.b[12][0]++;
        cov_1aopra40ab.s[38]++;

        element.bind('contextmenu', function (event) {
          cov_1aopra40ab.f[8]++;
          cov_1aopra40ab.s[39]++;

          event.preventDefault();
          cov_1aopra40ab.s[40]++;
          scope.chart.export('PNG', (cov_1aopra40ab.b[13][0]++, scope.params.title) || (cov_1aopra40ab.b[13][1]++, 'chart'));
        });
      } else {
        cov_1aopra40ab.b[12][1]++;
      }
    }
  };
});