'use strict';

var cov_fexcxxza0 = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/home_page/homePageCtrl.js',
      hash = 'dea2c21e67d1d023e4023235fb5ecaf839f2f262',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/home_page/homePageCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 437,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 35
        }
      },
      '2': {
        start: {
          line: 3,
          column: 29
        },
        end: {
          line: 3,
          column: 43
        }
      },
      '3': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 4,
          column: 51
        }
      },
      '4': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 43
        }
      },
      '5': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 14,
          column: 5
        }
      },
      '6': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 13,
          column: 48
        }
      },
      '7': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 17,
          column: 5
        }
      },
      '8': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 16,
          column: 33
        }
      },
      '9': {
        start: {
          line: 19,
          column: 2
        },
        end: {
          line: 30,
          column: 5
        }
      },
      '10': {
        start: {
          line: 20,
          column: 15
        },
        end: {
          line: 20,
          column: 33
        }
      },
      '11': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 28,
          column: 5
        }
      },
      '12': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 27,
          column: 8
        }
      },
      '13': {
        start: {
          line: 29,
          column: 4
        },
        end: {
          line: 29,
          column: 32
        }
      },
      '14': {
        start: {
          line: 48,
          column: 2
        },
        end: {
          line: 50,
          column: 5
        }
      },
      '15': {
        start: {
          line: 49,
          column: 4
        },
        end: {
          line: 49,
          column: 33
        }
      },
      '16': {
        start: {
          line: 52,
          column: 2
        },
        end: {
          line: 56,
          column: 91
        }
      },
      '17': {
        start: {
          line: 58,
          column: 2
        },
        end: {
          line: 61,
          column: 5
        }
      },
      '18': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 59,
          column: 32
        }
      },
      '19': {
        start: {
          line: 62,
          column: 27
        },
        end: {
          line: 169,
          column: 3
        }
      },
      '20': {
        start: {
          line: 63,
          column: 4
        },
        end: {
          line: 63,
          column: 33
        }
      },
      '21': {
        start: {
          line: 64,
          column: 4
        },
        end: {
          line: 64,
          column: 27
        }
      },
      '22': {
        start: {
          line: 65,
          column: 19
        },
        end: {
          line: 65,
          column: 21
        }
      },
      '23': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 77,
          column: 5
        }
      },
      '24': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 67,
          column: 64
        }
      },
      '25': {
        start: {
          line: 69,
          column: 6
        },
        end: {
          line: 71,
          column: 7
        }
      },
      '26': {
        start: {
          line: 70,
          column: 8
        },
        end: {
          line: 70,
          column: 36
        }
      },
      '27': {
        start: {
          line: 73,
          column: 6
        },
        end: {
          line: 73,
          column: 43
        }
      },
      '28': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 76,
          column: 7
        }
      },
      '29': {
        start: {
          line: 75,
          column: 8
        },
        end: {
          line: 75,
          column: 36
        }
      },
      '30': {
        start: {
          line: 79,
          column: 4
        },
        end: {
          line: 91,
          column: 5
        }
      },
      '31': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 80,
          column: 60
        }
      },
      '32': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 84,
          column: 7
        }
      },
      '33': {
        start: {
          line: 83,
          column: 8
        },
        end: {
          line: 83,
          column: 31
        }
      },
      '34': {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 86,
          column: 38
        }
      },
      '35': {
        start: {
          line: 88,
          column: 6
        },
        end: {
          line: 90,
          column: 7
        }
      },
      '36': {
        start: {
          line: 89,
          column: 8
        },
        end: {
          line: 89,
          column: 36
        }
      },
      '37': {
        start: {
          line: 93,
          column: 4
        },
        end: {
          line: 105,
          column: 5
        }
      },
      '38': {
        start: {
          line: 94,
          column: 6
        },
        end: {
          line: 94,
          column: 56
        }
      },
      '39': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 98,
          column: 7
        }
      },
      '40': {
        start: {
          line: 97,
          column: 8
        },
        end: {
          line: 97,
          column: 30
        }
      },
      '41': {
        start: {
          line: 100,
          column: 6
        },
        end: {
          line: 100,
          column: 37
        }
      },
      '42': {
        start: {
          line: 102,
          column: 6
        },
        end: {
          line: 104,
          column: 7
        }
      },
      '43': {
        start: {
          line: 103,
          column: 8
        },
        end: {
          line: 103,
          column: 36
        }
      },
      '44': {
        start: {
          line: 107,
          column: 4
        },
        end: {
          line: 119,
          column: 5
        }
      },
      '45': {
        start: {
          line: 108,
          column: 6
        },
        end: {
          line: 108,
          column: 54
        }
      },
      '46': {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 112,
          column: 7
        }
      },
      '47': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 28
        }
      },
      '48': {
        start: {
          line: 114,
          column: 6
        },
        end: {
          line: 114,
          column: 35
        }
      },
      '49': {
        start: {
          line: 116,
          column: 6
        },
        end: {
          line: 118,
          column: 7
        }
      },
      '50': {
        start: {
          line: 117,
          column: 8
        },
        end: {
          line: 117,
          column: 36
        }
      },
      '51': {
        start: {
          line: 121,
          column: 4
        },
        end: {
          line: 132,
          column: 5
        }
      },
      '52': {
        start: {
          line: 122,
          column: 6
        },
        end: {
          line: 122,
          column: 55
        }
      },
      '53': {
        start: {
          line: 124,
          column: 6
        },
        end: {
          line: 126,
          column: 7
        }
      },
      '54': {
        start: {
          line: 125,
          column: 8
        },
        end: {
          line: 125,
          column: 29
        }
      },
      '55': {
        start: {
          line: 127,
          column: 6
        },
        end: {
          line: 127,
          column: 36
        }
      },
      '56': {
        start: {
          line: 129,
          column: 6
        },
        end: {
          line: 131,
          column: 7
        }
      },
      '57': {
        start: {
          line: 130,
          column: 8
        },
        end: {
          line: 130,
          column: 36
        }
      },
      '58': {
        start: {
          line: 134,
          column: 4
        },
        end: {
          line: 147,
          column: 5
        }
      },
      '59': {
        start: {
          line: 135,
          column: 6
        },
        end: {
          line: 135,
          column: 59
        }
      },
      '60': {
        start: {
          line: 137,
          column: 6
        },
        end: {
          line: 141,
          column: 7
        }
      },
      '61': {
        start: {
          line: 138,
          column: 8
        },
        end: {
          line: 138,
          column: 34
        }
      },
      '62': {
        start: {
          line: 140,
          column: 8
        },
        end: {
          line: 140,
          column: 30
        }
      },
      '63': {
        start: {
          line: 142,
          column: 6
        },
        end: {
          line: 142,
          column: 36
        }
      },
      '64': {
        start: {
          line: 144,
          column: 6
        },
        end: {
          line: 146,
          column: 7
        }
      },
      '65': {
        start: {
          line: 145,
          column: 8
        },
        end: {
          line: 145,
          column: 36
        }
      },
      '66': {
        start: {
          line: 149,
          column: 4
        },
        end: {
          line: 151,
          column: 5
        }
      },
      '67': {
        start: {
          line: 150,
          column: 6
        },
        end: {
          line: 150,
          column: 28
        }
      },
      '68': {
        start: {
          line: 152,
          column: 4
        },
        end: {
          line: 154,
          column: 5
        }
      },
      '69': {
        start: {
          line: 153,
          column: 6
        },
        end: {
          line: 153,
          column: 28
        }
      },
      '70': {
        start: {
          line: 155,
          column: 4
        },
        end: {
          line: 157,
          column: 5
        }
      },
      '71': {
        start: {
          line: 156,
          column: 6
        },
        end: {
          line: 156,
          column: 28
        }
      },
      '72': {
        start: {
          line: 158,
          column: 4
        },
        end: {
          line: 160,
          column: 5
        }
      },
      '73': {
        start: {
          line: 159,
          column: 6
        },
        end: {
          line: 159,
          column: 28
        }
      },
      '74': {
        start: {
          line: 161,
          column: 4
        },
        end: {
          line: 163,
          column: 5
        }
      },
      '75': {
        start: {
          line: 162,
          column: 6
        },
        end: {
          line: 162,
          column: 28
        }
      },
      '76': {
        start: {
          line: 164,
          column: 4
        },
        end: {
          line: 166,
          column: 5
        }
      },
      '77': {
        start: {
          line: 165,
          column: 6
        },
        end: {
          line: 165,
          column: 28
        }
      },
      '78': {
        start: {
          line: 168,
          column: 4
        },
        end: {
          line: 168,
          column: 18
        }
      },
      '79': {
        start: {
          line: 171,
          column: 2
        },
        end: {
          line: 409,
          column: 4
        }
      },
      '80': {
        start: {
          line: 174,
          column: 21
        },
        end: {
          line: 174,
          column: 39
        }
      },
      '81': {
        start: {
          line: 175,
          column: 6
        },
        end: {
          line: 175,
          column: 32
        }
      },
      '82': {
        start: {
          line: 176,
          column: 6
        },
        end: {
          line: 180,
          column: 9
        }
      },
      '83': {
        start: {
          line: 184,
          column: 22
        },
        end: {
          line: 184,
          column: 40
        }
      },
      '84': {
        start: {
          line: 185,
          column: 6
        },
        end: {
          line: 185,
          column: 44
        }
      },
      '85': {
        start: {
          line: 187,
          column: 6
        },
        end: {
          line: 226,
          column: 9
        }
      },
      '86': {
        start: {
          line: 188,
          column: 23
        },
        end: {
          line: 212,
          column: 9
        }
      },
      '87': {
        start: {
          line: 199,
          column: 25
        },
        end: {
          line: 199,
          column: 65
        }
      },
      '88': {
        start: {
          line: 200,
          column: 12
        },
        end: {
          line: 200,
          column: 64
        }
      },
      '89': {
        start: {
          line: 201,
          column: 12
        },
        end: {
          line: 201,
          column: 330
        }
      },
      '90': {
        start: {
          line: 202,
          column: 12
        },
        end: {
          line: 202,
          column: 34
        }
      },
      '91': {
        start: {
          line: 203,
          column: 12
        },
        end: {
          line: 203,
          column: 49
        }
      },
      '92': {
        start: {
          line: 204,
          column: 12
        },
        end: {
          line: 204,
          column: 42
        }
      },
      '93': {
        start: {
          line: 206,
          column: 12
        },
        end: {
          line: 206,
          column: 60
        }
      },
      '94': {
        start: {
          line: 213,
          column: 8
        },
        end: {
          line: 213,
          column: 48
        }
      },
      '95': {
        start: {
          line: 214,
          column: 8
        },
        end: {
          line: 224,
          column: 9
        }
      },
      '96': {
        start: {
          line: 215,
          column: 10
        },
        end: {
          line: 215,
          column: 51
        }
      },
      '97': {
        start: {
          line: 216,
          column: 23
        },
        end: {
          line: 216,
          column: 32
        }
      },
      '98': {
        start: {
          line: 217,
          column: 10
        },
        end: {
          line: 223,
          column: 13
        }
      },
      '99': {
        start: {
          line: 225,
          column: 8
        },
        end: {
          line: 225,
          column: 49
        }
      },
      '100': {
        start: {
          line: 229,
          column: 22
        },
        end: {
          line: 229,
          column: 40
        }
      },
      '101': {
        start: {
          line: 230,
          column: 6
        },
        end: {
          line: 230,
          column: 49
        }
      },
      '102': {
        start: {
          line: 232,
          column: 6
        },
        end: {
          line: 315,
          column: 9
        }
      },
      '103': {
        start: {
          line: 233,
          column: 23
        },
        end: {
          line: 295,
          column: 9
        }
      },
      '104': {
        start: {
          line: 286,
          column: 22
        },
        end: {
          line: 286,
          column: 42
        }
      },
      '105': {
        start: {
          line: 287,
          column: 12
        },
        end: {
          line: 287,
          column: 36
        }
      },
      '106': {
        start: {
          line: 296,
          column: 8
        },
        end: {
          line: 296,
          column: 52
        }
      },
      '107': {
        start: {
          line: 297,
          column: 8
        },
        end: {
          line: 312,
          column: 9
        }
      },
      '108': {
        start: {
          line: 298,
          column: 10
        },
        end: {
          line: 298,
          column: 55
        }
      },
      '109': {
        start: {
          line: 299,
          column: 23
        },
        end: {
          line: 299,
          column: 32
        }
      },
      '110': {
        start: {
          line: 301,
          column: 25
        },
        end: {
          line: 301,
          column: 56
        }
      },
      '111': {
        start: {
          line: 302,
          column: 31
        },
        end: {
          line: 302,
          column: 68
        }
      },
      '112': {
        start: {
          line: 304,
          column: 10
        },
        end: {
          line: 307,
          column: 13
        }
      },
      '113': {
        start: {
          line: 308,
          column: 10
        },
        end: {
          line: 311,
          column: 13
        }
      },
      '114': {
        start: {
          line: 314,
          column: 8
        },
        end: {
          line: 314,
          column: 53
        }
      },
      '115': {
        start: {
          line: 318,
          column: 22
        },
        end: {
          line: 318,
          column: 40
        }
      },
      '116': {
        start: {
          line: 319,
          column: 6
        },
        end: {
          line: 319,
          column: 45
        }
      },
      '117': {
        start: {
          line: 320,
          column: 6
        },
        end: {
          line: 395,
          column: 9
        }
      },
      '118': {
        start: {
          line: 321,
          column: 23
        },
        end: {
          line: 325,
          column: 96
        }
      },
      '119': {
        start: {
          line: 327,
          column: 23
        },
        end: {
          line: 372,
          column: 9
        }
      },
      '120': {
        start: {
          line: 361,
          column: 22
        },
        end: {
          line: 361,
          column: 42
        }
      },
      '121': {
        start: {
          line: 362,
          column: 12
        },
        end: {
          line: 362,
          column: 36
        }
      },
      '122': {
        start: {
          line: 373,
          column: 8
        },
        end: {
          line: 373,
          column: 48
        }
      },
      '123': {
        start: {
          line: 374,
          column: 8
        },
        end: {
          line: 381,
          column: 9
        }
      },
      '124': {
        start: {
          line: 375,
          column: 10
        },
        end: {
          line: 380,
          column: 13
        }
      },
      '125': {
        start: {
          line: 382,
          column: 8
        },
        end: {
          line: 393,
          column: 9
        }
      },
      '126': {
        start: {
          line: 383,
          column: 23
        },
        end: {
          line: 383,
          column: 32
        }
      },
      '127': {
        start: {
          line: 384,
          column: 10
        },
        end: {
          line: 392,
          column: 11
        }
      },
      '128': {
        start: {
          line: 385,
          column: 12
        },
        end: {
          line: 387,
          column: 13
        }
      },
      '129': {
        start: {
          line: 386,
          column: 14
        },
        end: {
          line: 386,
          column: 55
        }
      },
      '130': {
        start: {
          line: 388,
          column: 12
        },
        end: {
          line: 391,
          column: 15
        }
      },
      '131': {
        start: {
          line: 394,
          column: 8
        },
        end: {
          line: 394,
          column: 49
        }
      },
      '132': {
        start: {
          line: 398,
          column: 6
        },
        end: {
          line: 398,
          column: 34
        }
      },
      '133': {
        start: {
          line: 399,
          column: 6
        },
        end: {
          line: 399,
          column: 38
        }
      },
      '134': {
        start: {
          line: 400,
          column: 6
        },
        end: {
          line: 400,
          column: 34
        }
      },
      '135': {
        start: {
          line: 403,
          column: 6
        },
        end: {
          line: 403,
          column: 78
        }
      },
      '136': {
        start: {
          line: 404,
          column: 6
        },
        end: {
          line: 404,
          column: 134
        }
      },
      '137': {
        start: {
          line: 406,
          column: 6
        },
        end: {
          line: 406,
          column: 34
        }
      },
      '138': {
        start: {
          line: 407,
          column: 6
        },
        end: {
          line: 407,
          column: 38
        }
      },
      '139': {
        start: {
          line: 411,
          column: 2
        },
        end: {
          line: 428,
          column: 4
        }
      },
      '140': {
        start: {
          line: 412,
          column: 4
        },
        end: {
          line: 427,
          column: 6
        }
      },
      '141': {
        start: {
          line: 429,
          column: 2
        },
        end: {
          line: 429,
          column: 22
        }
      },
      '142': {
        start: {
          line: 431,
          column: 2
        },
        end: {
          line: 434,
          column: 4
        }
      },
      '143': {
        start: {
          line: 432,
          column: 4
        },
        end: {
          line: 432,
          column: 24
        }
      },
      '144': {
        start: {
          line: 433,
          column: 4
        },
        end: {
          line: 433,
          column: 32
        }
      },
      '145': {
        start: {
          line: 436,
          column: 2
        },
        end: {
          line: 436,
          column: 30
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 49
          },
          end: {
            line: 1,
            column: 50
          }
        },
        loc: {
          start: {
            line: 1,
            column: 123
          },
          end: {
            line: 437,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 12,
            column: 65
          },
          end: {
            line: 12,
            column: 66
          }
        },
        loc: {
          start: {
            line: 12,
            column: 73
          },
          end: {
            line: 14,
            column: 3
          }
        },
        line: 12
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 55
          },
          end: {
            line: 15,
            column: 56
          }
        },
        loc: {
          start: {
            line: 15,
            column: 63
          },
          end: {
            line: 17,
            column: 3
          }
        },
        line: 15
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 19,
            column: 68
          },
          end: {
            line: 19,
            column: 69
          }
        },
        loc: {
          start: {
            line: 19,
            column: 77
          },
          end: {
            line: 30,
            column: 3
          }
        },
        line: 19
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 48,
            column: 50
          },
          end: {
            line: 48,
            column: 51
          }
        },
        loc: {
          start: {
            line: 48,
            column: 58
          },
          end: {
            line: 50,
            column: 3
          }
        },
        line: 48
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 58,
            column: 47
          },
          end: {
            line: 58,
            column: 48
          }
        },
        loc: {
          start: {
            line: 58,
            column: 55
          },
          end: {
            line: 61,
            column: 3
          }
        },
        line: 58
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 62,
            column: 27
          },
          end: {
            line: 62,
            column: 28
          }
        },
        loc: {
          start: {
            line: 62,
            column: 33
          },
          end: {
            line: 169,
            column: 3
          }
        },
        line: 62
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 66,
            column: 70
          },
          end: {
            line: 66,
            column: 71
          }
        },
        loc: {
          start: {
            line: 66,
            column: 86
          },
          end: {
            line: 68,
            column: 5
          }
        },
        line: 66
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 79,
            column: 66
          },
          end: {
            line: 79,
            column: 67
          }
        },
        loc: {
          start: {
            line: 79,
            column: 82
          },
          end: {
            line: 81,
            column: 5
          }
        },
        line: 79
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 93,
            column: 65
          },
          end: {
            line: 93,
            column: 66
          }
        },
        loc: {
          start: {
            line: 93,
            column: 78
          },
          end: {
            line: 95,
            column: 5
          }
        },
        line: 93
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 107,
            column: 63
          },
          end: {
            line: 107,
            column: 64
          }
        },
        loc: {
          start: {
            line: 107,
            column: 76
          },
          end: {
            line: 109,
            column: 5
          }
        },
        line: 107
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 121,
            column: 64
          },
          end: {
            line: 121,
            column: 65
          }
        },
        loc: {
          start: {
            line: 121,
            column: 77
          },
          end: {
            line: 123,
            column: 5
          }
        },
        line: 121
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 134,
            column: 66
          },
          end: {
            line: 134,
            column: 67
          }
        },
        loc: {
          start: {
            line: 134,
            column: 81
          },
          end: {
            line: 136,
            column: 5
          }
        },
        line: 134
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 187,
            column: 41
          },
          end: {
            line: 187,
            column: 42
          }
        },
        loc: {
          start: {
            line: 187,
            column: 49
          },
          end: {
            line: 226,
            column: 7
          }
        },
        line: 187
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 232,
            column: 41
          },
          end: {
            line: 232,
            column: 42
          }
        },
        loc: {
          start: {
            line: 232,
            column: 49
          },
          end: {
            line: 315,
            column: 7
          }
        },
        line: 232
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 320,
            column: 41
          },
          end: {
            line: 320,
            column: 42
          }
        },
        loc: {
          start: {
            line: 320,
            column: 49
          },
          end: {
            line: 395,
            column: 7
          }
        },
        line: 320
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 411,
            column: 22
          },
          end: {
            line: 411,
            column: 23
          }
        },
        loc: {
          start: {
            line: 411,
            column: 28
          },
          end: {
            line: 428,
            column: 3
          }
        },
        line: 411
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 431,
            column: 23
          },
          end: {
            line: 431,
            column: 24
          }
        },
        loc: {
          start: {
            line: 431,
            column: 29
          },
          end: {
            line: 434,
            column: 3
          }
        },
        line: 431
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 21,
            column: 4
          },
          end: {
            line: 28,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 21,
            column: 4
          },
          end: {
            line: 28,
            column: 5
          }
        }, {
          start: {
            line: 21,
            column: 4
          },
          end: {
            line: 28,
            column: 5
          }
        }],
        line: 21
      },
      '1': {
        loc: {
          start: {
            line: 67,
            column: 13
          },
          end: {
            line: 67,
            column: 63
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 67,
            column: 13
          },
          end: {
            line: 67,
            column: 54
          }
        }, {
          start: {
            line: 67,
            column: 58
          },
          end: {
            line: 67,
            column: 63
          }
        }],
        line: 67
      },
      '2': {
        loc: {
          start: {
            line: 69,
            column: 6
          },
          end: {
            line: 71,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 69,
            column: 6
          },
          end: {
            line: 71,
            column: 7
          }
        }, {
          start: {
            line: 69,
            column: 6
          },
          end: {
            line: 71,
            column: 7
          }
        }],
        line: 69
      },
      '3': {
        loc: {
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 76,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 76,
            column: 7
          }
        }, {
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 76,
            column: 7
          }
        }],
        line: 74
      },
      '4': {
        loc: {
          start: {
            line: 80,
            column: 13
          },
          end: {
            line: 80,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 80,
            column: 13
          },
          end: {
            line: 80,
            column: 50
          }
        }, {
          start: {
            line: 80,
            column: 54
          },
          end: {
            line: 80,
            column: 59
          }
        }],
        line: 80
      },
      '5': {
        loc: {
          start: {
            line: 82,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 82,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        }, {
          start: {
            line: 82,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        }],
        line: 82
      },
      '6': {
        loc: {
          start: {
            line: 88,
            column: 6
          },
          end: {
            line: 90,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 88,
            column: 6
          },
          end: {
            line: 90,
            column: 7
          }
        }, {
          start: {
            line: 88,
            column: 6
          },
          end: {
            line: 90,
            column: 7
          }
        }],
        line: 88
      },
      '7': {
        loc: {
          start: {
            line: 94,
            column: 13
          },
          end: {
            line: 94,
            column: 55
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 94,
            column: 13
          },
          end: {
            line: 94,
            column: 46
          }
        }, {
          start: {
            line: 94,
            column: 50
          },
          end: {
            line: 94,
            column: 55
          }
        }],
        line: 94
      },
      '8': {
        loc: {
          start: {
            line: 96,
            column: 6
          },
          end: {
            line: 98,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 96,
            column: 6
          },
          end: {
            line: 98,
            column: 7
          }
        }, {
          start: {
            line: 96,
            column: 6
          },
          end: {
            line: 98,
            column: 7
          }
        }],
        line: 96
      },
      '9': {
        loc: {
          start: {
            line: 102,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 102,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }, {
          start: {
            line: 102,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }],
        line: 102
      },
      '10': {
        loc: {
          start: {
            line: 108,
            column: 13
          },
          end: {
            line: 108,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 108,
            column: 13
          },
          end: {
            line: 108,
            column: 44
          }
        }, {
          start: {
            line: 108,
            column: 48
          },
          end: {
            line: 108,
            column: 53
          }
        }],
        line: 108
      },
      '11': {
        loc: {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        }, {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        }],
        line: 110
      },
      '12': {
        loc: {
          start: {
            line: 116,
            column: 6
          },
          end: {
            line: 118,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 116,
            column: 6
          },
          end: {
            line: 118,
            column: 7
          }
        }, {
          start: {
            line: 116,
            column: 6
          },
          end: {
            line: 118,
            column: 7
          }
        }],
        line: 116
      },
      '13': {
        loc: {
          start: {
            line: 122,
            column: 13
          },
          end: {
            line: 122,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 122,
            column: 13
          },
          end: {
            line: 122,
            column: 45
          }
        }, {
          start: {
            line: 122,
            column: 49
          },
          end: {
            line: 122,
            column: 54
          }
        }],
        line: 122
      },
      '14': {
        loc: {
          start: {
            line: 124,
            column: 6
          },
          end: {
            line: 126,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 124,
            column: 6
          },
          end: {
            line: 126,
            column: 7
          }
        }, {
          start: {
            line: 124,
            column: 6
          },
          end: {
            line: 126,
            column: 7
          }
        }],
        line: 124
      },
      '15': {
        loc: {
          start: {
            line: 129,
            column: 6
          },
          end: {
            line: 131,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 129,
            column: 6
          },
          end: {
            line: 131,
            column: 7
          }
        }, {
          start: {
            line: 129,
            column: 6
          },
          end: {
            line: 131,
            column: 7
          }
        }],
        line: 129
      },
      '16': {
        loc: {
          start: {
            line: 135,
            column: 13
          },
          end: {
            line: 135,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 135,
            column: 13
          },
          end: {
            line: 135,
            column: 49
          }
        }, {
          start: {
            line: 135,
            column: 53
          },
          end: {
            line: 135,
            column: 58
          }
        }],
        line: 135
      },
      '17': {
        loc: {
          start: {
            line: 137,
            column: 6
          },
          end: {
            line: 141,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 137,
            column: 6
          },
          end: {
            line: 141,
            column: 7
          }
        }, {
          start: {
            line: 137,
            column: 6
          },
          end: {
            line: 141,
            column: 7
          }
        }],
        line: 137
      },
      '18': {
        loc: {
          start: {
            line: 144,
            column: 6
          },
          end: {
            line: 146,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 144,
            column: 6
          },
          end: {
            line: 146,
            column: 7
          }
        }, {
          start: {
            line: 144,
            column: 6
          },
          end: {
            line: 146,
            column: 7
          }
        }],
        line: 144
      },
      '19': {
        loc: {
          start: {
            line: 149,
            column: 4
          },
          end: {
            line: 151,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 149,
            column: 4
          },
          end: {
            line: 151,
            column: 5
          }
        }, {
          start: {
            line: 149,
            column: 4
          },
          end: {
            line: 151,
            column: 5
          }
        }],
        line: 149
      },
      '20': {
        loc: {
          start: {
            line: 152,
            column: 4
          },
          end: {
            line: 154,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 152,
            column: 4
          },
          end: {
            line: 154,
            column: 5
          }
        }, {
          start: {
            line: 152,
            column: 4
          },
          end: {
            line: 154,
            column: 5
          }
        }],
        line: 152
      },
      '21': {
        loc: {
          start: {
            line: 155,
            column: 4
          },
          end: {
            line: 157,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 155,
            column: 4
          },
          end: {
            line: 157,
            column: 5
          }
        }, {
          start: {
            line: 155,
            column: 4
          },
          end: {
            line: 157,
            column: 5
          }
        }],
        line: 155
      },
      '22': {
        loc: {
          start: {
            line: 158,
            column: 4
          },
          end: {
            line: 160,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 158,
            column: 4
          },
          end: {
            line: 160,
            column: 5
          }
        }, {
          start: {
            line: 158,
            column: 4
          },
          end: {
            line: 160,
            column: 5
          }
        }],
        line: 158
      },
      '23': {
        loc: {
          start: {
            line: 161,
            column: 4
          },
          end: {
            line: 163,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 161,
            column: 4
          },
          end: {
            line: 163,
            column: 5
          }
        }, {
          start: {
            line: 161,
            column: 4
          },
          end: {
            line: 163,
            column: 5
          }
        }],
        line: 161
      },
      '24': {
        loc: {
          start: {
            line: 164,
            column: 4
          },
          end: {
            line: 166,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 164,
            column: 4
          },
          end: {
            line: 166,
            column: 5
          }
        }, {
          start: {
            line: 164,
            column: 4
          },
          end: {
            line: 166,
            column: 5
          }
        }],
        line: 164
      },
      '25': {
        loc: {
          start: {
            line: 201,
            column: 94
          },
          end: {
            line: 201,
            column: 163
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 201,
            column: 113
          },
          end: {
            line: 201,
            column: 137
          }
        }, {
          start: {
            line: 201,
            column: 140
          },
          end: {
            line: 201,
            column: 163
          }
        }],
        line: 201
      },
      '26': {
        loc: {
          start: {
            line: 301,
            column: 25
          },
          end: {
            line: 301,
            column: 56
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 301,
            column: 25
          },
          end: {
            line: 301,
            column: 48
          }
        }, {
          start: {
            line: 301,
            column: 52
          },
          end: {
            line: 301,
            column: 56
          }
        }],
        line: 301
      },
      '27': {
        loc: {
          start: {
            line: 302,
            column: 31
          },
          end: {
            line: 302,
            column: 68
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 302,
            column: 31
          },
          end: {
            line: 302,
            column: 60
          }
        }, {
          start: {
            line: 302,
            column: 64
          },
          end: {
            line: 302,
            column: 68
          }
        }],
        line: 302
      },
      '28': {
        loc: {
          start: {
            line: 306,
            column: 15
          },
          end: {
            line: 306,
            column: 86
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 306,
            column: 24
          },
          end: {
            line: 306,
            column: 79
          }
        }, {
          start: {
            line: 306,
            column: 82
          },
          end: {
            line: 306,
            column: 86
          }
        }],
        line: 306
      },
      '29': {
        loc: {
          start: {
            line: 310,
            column: 15
          },
          end: {
            line: 310,
            column: 98
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 310,
            column: 30
          },
          end: {
            line: 310,
            column: 91
          }
        }, {
          start: {
            line: 310,
            column: 94
          },
          end: {
            line: 310,
            column: 98
          }
        }],
        line: 310
      },
      '30': {
        loc: {
          start: {
            line: 385,
            column: 12
          },
          end: {
            line: 387,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 385,
            column: 12
          },
          end: {
            line: 387,
            column: 13
          }
        }, {
          start: {
            line: 385,
            column: 12
          },
          end: {
            line: 387,
            column: 13
          }
        }],
        line: 385
      },
      '31': {
        loc: {
          start: {
            line: 390,
            column: 17
          },
          end: {
            line: 390,
            column: 45
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 390,
            column: 17
          },
          end: {
            line: 390,
            column: 37
          }
        }, {
          start: {
            line: 390,
            column: 41
          },
          end: {
            line: 390,
            column: 45
          }
        }],
        line: 390
      },
      '32': {
        loc: {
          start: {
            line: 404,
            column: 43
          },
          end: {
            line: 404,
            column: 133
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 404,
            column: 103
          },
          end: {
            line: 404,
            column: 104
          }
        }, {
          start: {
            line: 404,
            column: 107
          },
          end: {
            line: 404,
            column: 133
          }
        }],
        line: 404
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_fexcxxza0.s[0]++;
angular.module('app').controller('homePageCtrl', function ($scope, $CRUDService, $filter, $translate, $rootScope, $LOGINService) {
  cov_fexcxxza0.f[0]++;

  var PATH = (cov_fexcxxza0.s[1]++, 'PortfolioDashboard');
  var PATH_CUSTOM_FIELDS = (cov_fexcxxza0.s[2]++, 'CustomFields');
  cov_fexcxxza0.s[3]++;
  $scope.currency = $rootScope.getGlobalCurrency();

  /* PROJECT_BY_SECTOR: 'Projets par secteur',
                    INVESTING_BY_FUNDING_SOURCE: 'Investissement par source de financement',
                    PROJECT_BY_FUNDING_SOURCE: 'Projets par sources de financement' */

  cov_fexcxxza0.s[4]++;
  $scope.height = $('body').height() - 214;

  cov_fexcxxza0.s[5]++;
  $CRUDService.getAll('Financement', { get: 'all', simple: true }, function (data) {
    cov_fexcxxza0.f[1]++;
    cov_fexcxxza0.s[6]++;

    $scope.listData_FinancialSource_bksb = data;
  });
  cov_fexcxxza0.s[7]++;
  $CRUDService.getAll('AgenciesProject', { get: 'all' }, function (data) {
    cov_fexcxxza0.f[2]++;
    cov_fexcxxza0.s[8]++;

    $scope.agenciesFilter = data;
  });

  cov_fexcxxza0.s[9]++;
  $CRUDService.getAll(PATH_CUSTOM_FIELDS, { get: 'single', type: 3 }, function (data_) {
    cov_fexcxxza0.f[3]++;

    var data = (cov_fexcxxza0.s[10]++, data_.VALUES_FIELD);
    cov_fexcxxza0.s[11]++;
    if (!angular.isObject(data)) {
      cov_fexcxxza0.b[0][0]++;
      cov_fexcxxza0.s[12]++;

      data = [{ id: 1, name: $translate.instant('FINANCING.TYPES.GRANT') }, { id: 2, name: $translate.instant('FINANCING.TYPES.LOAN') }, { id: 3, name: $translate.instant('FINANCING.TYPES.DONATION') }, { id: 4, name: $translate.instant('FINANCING.TYPES.BUDGET_SUPPORT') }];
    } else {
      cov_fexcxxza0.b[0][1]++;
    }
    cov_fexcxxza0.s[13]++;
    $scope.typeFinancing = data;
  });

  /* $scope.filter = {
    flitre: false,
    display: false,
    financing: {},
    financingType: {},
    displayPanel: 1,
    agencies: {},
    status: {},
    sectors: {},
    locations: {},
    selectedCurrency: $scope.currency,
    exchangeRate: 1,
    validateSelectedCurrency: $scope.currency,
    validateExchangeRate: 1
  }; */

  cov_fexcxxza0.s[14]++;
  $CRUDService.getAll('Projet', { get: 'devises' }, function (data) {
    cov_fexcxxza0.f[4]++;
    cov_fexcxxza0.s[15]++;

    $scope.currenciesList = data;
  });

  cov_fexcxxza0.s[16]++;
  $scope.statutsList = [{ id: 1, name: $translate.instant('PROJECT.STATUTS.NOT_STARTED'), class: 'label-success' }, { id: 2, name: $translate.instant('PROJECT.STATUTS.ACTIVE'), class: 'label-primary' }, { id: 3, name: $translate.instant('PROJECT.STATUTS.SUSPENDED'), class: 'label-danger' }, { id: 4, name: $translate.instant('PROJECT.STATUTS.COMPLETED'), class: 'label-default' }, { id: 5, name: $translate.instant('PROJECT.STATUTS.ARCHIVED'), class: 'label-default' }];

  cov_fexcxxza0.s[17]++;
  $CRUDService.getAll('Sectors', { get: 'all' }, function (data) {
    cov_fexcxxza0.f[5]++;
    cov_fexcxxza0.s[18]++;

    $scope.sectorsFilter = data;
    // $scope.sectors.push({id: 0});
  });
  cov_fexcxxza0.s[19]++;
  var processParameter = function processParameter() {
    cov_fexcxxza0.f[6]++;
    cov_fexcxxza0.s[20]++;

    $scope.filter.flitre = false;
    cov_fexcxxza0.s[21]++;
    $scope.filter.nbre = 0;
    var params = (cov_fexcxxza0.s[22]++, {});
    cov_fexcxxza0.s[23]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = Object.keys($scope.filter.financingType).filter(function (financingKey) {
        cov_fexcxxza0.f[7]++;
        cov_fexcxxza0.s[24]++;

        return (cov_fexcxxza0.b[1][0]++, $scope.filter.financingType[financingKey]) || (cov_fexcxxza0.b[1][1]++, false);
      })[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var key = _step.value;
        cov_fexcxxza0.s[25]++;

        if (!params.type_financing) {
          cov_fexcxxza0.b[2][0]++;
          cov_fexcxxza0.s[26]++;

          params.type_financing = '0';
        } else {
          cov_fexcxxza0.b[2][1]++;
        }

        cov_fexcxxza0.s[27]++;
        params.type_financing += ' , ' + key;
        cov_fexcxxza0.s[28]++;
        if ($scope.filter.financingType[key]) {
          cov_fexcxxza0.b[3][0]++;
          cov_fexcxxza0.s[29]++;

          $scope.filter.flitre = true;
        } else {
          cov_fexcxxza0.b[3][1]++;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_fexcxxza0.s[30]++;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = Object.keys($scope.filter.financing).filter(function (financingKey) {
        cov_fexcxxza0.f[8]++;
        cov_fexcxxza0.s[31]++;

        return (cov_fexcxxza0.b[4][0]++, $scope.filter.financing[financingKey]) || (cov_fexcxxza0.b[4][1]++, false);
      })[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var _key = _step2.value;
        cov_fexcxxza0.s[32]++;

        if (!params.financing) {
          cov_fexcxxza0.b[5][0]++;
          cov_fexcxxza0.s[33]++;

          params.financing = '0';
        } else {
          cov_fexcxxza0.b[5][1]++;
        }

        cov_fexcxxza0.s[34]++;
        params.financing += ' , ' + _key;

        cov_fexcxxza0.s[35]++;
        if ($scope.filter.financing[_key]) {
          cov_fexcxxza0.b[6][0]++;
          cov_fexcxxza0.s[36]++;

          $scope.filter.flitre = true;
        } else {
          cov_fexcxxza0.b[6][1]++;
        }
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    cov_fexcxxza0.s[37]++;
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = Object.keys($scope.filter.agencies).filter(function (agencyKey) {
        cov_fexcxxza0.f[9]++;
        cov_fexcxxza0.s[38]++;

        return (cov_fexcxxza0.b[7][0]++, $scope.filter.agencies[agencyKey]) || (cov_fexcxxza0.b[7][1]++, false);
      })[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var _key2 = _step3.value;
        cov_fexcxxza0.s[39]++;

        if (!params.agencies) {
          cov_fexcxxza0.b[8][0]++;
          cov_fexcxxza0.s[40]++;

          params.agencies = '0';
        } else {
          cov_fexcxxza0.b[8][1]++;
        }

        cov_fexcxxza0.s[41]++;
        params.agencies += ' , ' + _key2;

        cov_fexcxxza0.s[42]++;
        if ($scope.filter.agencies[_key2]) {
          cov_fexcxxza0.b[9][0]++;
          cov_fexcxxza0.s[43]++;

          $scope.filter.flitre = true;
        } else {
          cov_fexcxxza0.b[9][1]++;
        }
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    cov_fexcxxza0.s[44]++;
    var _iteratorNormalCompletion4 = true;
    var _didIteratorError4 = false;
    var _iteratorError4 = undefined;

    try {
      for (var _iterator4 = Object.keys($scope.filter.status).filter(function (statusKey) {
        cov_fexcxxza0.f[10]++;
        cov_fexcxxza0.s[45]++;

        return (cov_fexcxxza0.b[10][0]++, $scope.filter.status[statusKey]) || (cov_fexcxxza0.b[10][1]++, false);
      })[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
        var _key3 = _step4.value;
        cov_fexcxxza0.s[46]++;

        if (!params.status) {
          cov_fexcxxza0.b[11][0]++;
          cov_fexcxxza0.s[47]++;

          params.status = '0';
        } else {
          cov_fexcxxza0.b[11][1]++;
        }

        cov_fexcxxza0.s[48]++;
        params.status += ' , ' + _key3;

        cov_fexcxxza0.s[49]++;
        if ($scope.filter.status[_key3]) {
          cov_fexcxxza0.b[12][0]++;
          cov_fexcxxza0.s[50]++;

          $scope.filter.flitre = true;
        } else {
          cov_fexcxxza0.b[12][1]++;
        }
      }
    } catch (err) {
      _didIteratorError4 = true;
      _iteratorError4 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion4 && _iterator4.return) {
          _iterator4.return();
        }
      } finally {
        if (_didIteratorError4) {
          throw _iteratorError4;
        }
      }
    }

    cov_fexcxxza0.s[51]++;
    var _iteratorNormalCompletion5 = true;
    var _didIteratorError5 = false;
    var _iteratorError5 = undefined;

    try {
      for (var _iterator5 = Object.keys($scope.filter.sectors).filter(function (sectorKey) {
        cov_fexcxxza0.f[11]++;
        cov_fexcxxza0.s[52]++;

        return (cov_fexcxxza0.b[13][0]++, $scope.filter.sectors[sectorKey]) || (cov_fexcxxza0.b[13][1]++, false);
      })[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
        var _key4 = _step5.value;
        cov_fexcxxza0.s[53]++;

        if (!params.sectors) {
          cov_fexcxxza0.b[14][0]++;
          cov_fexcxxza0.s[54]++;

          params.sectors = '0';
        } else {
          cov_fexcxxza0.b[14][1]++;
        }
        cov_fexcxxza0.s[55]++;
        params.sectors += ' , ' + _key4;

        cov_fexcxxza0.s[56]++;
        if ($scope.filter.sectors[_key4]) {
          cov_fexcxxza0.b[15][0]++;
          cov_fexcxxza0.s[57]++;

          $scope.filter.flitre = true;
        } else {
          cov_fexcxxza0.b[15][1]++;
        }
      }
    } catch (err) {
      _didIteratorError5 = true;
      _iteratorError5 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion5 && _iterator5.return) {
          _iterator5.return();
        }
      } finally {
        if (_didIteratorError5) {
          throw _iteratorError5;
        }
      }
    }

    cov_fexcxxza0.s[58]++;
    var _iteratorNormalCompletion6 = true;
    var _didIteratorError6 = false;
    var _iteratorError6 = undefined;

    try {
      for (var _iterator6 = Object.keys($scope.filter.locations).filter(function (locationKey) {
        cov_fexcxxza0.f[12]++;
        cov_fexcxxza0.s[59]++;

        return (cov_fexcxxza0.b[16][0]++, $scope.filter.locations[locationKey]) || (cov_fexcxxza0.b[16][1]++, false);
      })[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
        var _key5 = _step6.value;
        cov_fexcxxza0.s[60]++;

        if (params.locations) {
          cov_fexcxxza0.b[17][0]++;
          cov_fexcxxza0.s[61]++;

          params.locations += ' , ';
        } else {
          cov_fexcxxza0.b[17][1]++;
          cov_fexcxxza0.s[62]++;

          params.locations = '';
        }
        cov_fexcxxza0.s[63]++;
        params.locations += ' ' + _key5;

        cov_fexcxxza0.s[64]++;
        if ($scope.filter.locations[_key5]) {
          cov_fexcxxza0.b[18][0]++;
          cov_fexcxxza0.s[65]++;

          $scope.filter.flitre = true;
        } else {
          cov_fexcxxza0.b[18][1]++;
        }
      }
    } catch (err) {
      _didIteratorError6 = true;
      _iteratorError6 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion6 && _iterator6.return) {
          _iterator6.return();
        }
      } finally {
        if (_didIteratorError6) {
          throw _iteratorError6;
        }
      }
    }

    cov_fexcxxza0.s[66]++;
    if (Object.keys($scope.filter.financingType).length > 0) {
      cov_fexcxxza0.b[19][0]++;
      cov_fexcxxza0.s[67]++;

      $scope.filter.nbre++;
    } else {
      cov_fexcxxza0.b[19][1]++;
    }
    cov_fexcxxza0.s[68]++;
    if (Object.keys($scope.filter.financing).length > 0) {
      cov_fexcxxza0.b[20][0]++;
      cov_fexcxxza0.s[69]++;

      $scope.filter.nbre++;
    } else {
      cov_fexcxxza0.b[20][1]++;
    }
    cov_fexcxxza0.s[70]++;
    if (Object.keys($scope.filter.agencies).length > 0) {
      cov_fexcxxza0.b[21][0]++;
      cov_fexcxxza0.s[71]++;

      $scope.filter.nbre++;
    } else {
      cov_fexcxxza0.b[21][1]++;
    }
    cov_fexcxxza0.s[72]++;
    if (Object.keys($scope.filter.status).length > 0) {
      cov_fexcxxza0.b[22][0]++;
      cov_fexcxxza0.s[73]++;

      $scope.filter.nbre++;
    } else {
      cov_fexcxxza0.b[22][1]++;
    }
    cov_fexcxxza0.s[74]++;
    if (Object.keys($scope.filter.sectors).length > 0) {
      cov_fexcxxza0.b[23][0]++;
      cov_fexcxxza0.s[75]++;

      $scope.filter.nbre++;
    } else {
      cov_fexcxxza0.b[23][1]++;
    }
    cov_fexcxxza0.s[76]++;
    if (Object.keys($scope.filter.locations).length > 0) {
      cov_fexcxxza0.b[24][0]++;
      cov_fexcxxza0.s[77]++;

      $scope.filter.nbre++;
    } else {
      cov_fexcxxza0.b[24][1]++;
    }

    cov_fexcxxza0.s[78]++;
    return params;
  };

  cov_fexcxxza0.s[79]++;
  $scope.process = {
    printData: function printData() {
      var params = (cov_fexcxxza0.s[80]++, processParameter());
      cov_fexcxxza0.s[81]++;
      params.get = 'print_data';
      cov_fexcxxza0.s[82]++;
      $rootScope.printReport({
        link: PATH,
        params: params,
        title: $translate.instant('PROJECT.DASHBOARD_VIEW.DASHBOARD_TYPE.PROJECTS')
      });
    },
    financialRepartition: function financialRepartition() {
      var params_ = (cov_fexcxxza0.s[83]++, processParameter());
      cov_fexcxxza0.s[84]++;
      params_.get = 'financial_repartition';

      cov_fexcxxza0.s[85]++;
      $CRUDService.getAll(PATH, params_, function (data) {
        cov_fexcxxza0.f[13]++;

        var params = (cov_fexcxxza0.s[86]++, {
          exportWithRightClick: true,
          locale: $LOGINService.getLang(),
          series: [{
            innerRadius: '60%',
            type: 'Pie', dataSource: [], xName: 'x', yName: 'y',
            dataLabel: { visible: true, position: 'Outside' },
            pointColorMapping: 'color'
          }], textRender: function textRender(args) {
            var item = (cov_fexcxxza0.s[87]++, args.series.resultData[args.point.index]);
            cov_fexcxxza0.s[88]++;
            args.point.customValue = args.point.x + '<br/><hr>';
            cov_fexcxxza0.s[89]++;
            args.point.customValue += '<br/>' + $translate.instant('PROJECT.DASHBOARD_VIEW.' + (args.point.y > 1 ? (cov_fexcxxza0.b[25][0]++, 'TOOLTIP_SECTOR_PLURALS') : (cov_fexcxxza0.b[25][1]++, 'TOOLTIP_SECTOR_SINGLE')), { currency: $scope.filter.validateSelectedCurrency, amount: $filter('number')($scope.filter.validateExchangeRate * item.amount / 1000000), project: args.point.y });
            cov_fexcxxza0.s[90]++;
            args.border.width = 1;
            cov_fexcxxza0.s[91]++;
            args.border.color = args.point.color;
            cov_fexcxxza0.s[92]++;
            args.color = args.point.color;

            cov_fexcxxza0.s[93]++;
            args.text = args.point.x + ' (' + args.point.y + ')';
          },

          legendSettings: { visible: false, position: 'Bottom' }, enableSmartLabels: true,
          title_: $translate.instant('PROJECT.DASHBOARD_VIEW.PROJECT_BY_SECTOR'),
          // Initializing Tooltip
          tooltip: { enable: true, format: '${point.customValue}' }
        });
        cov_fexcxxza0.s[94]++;
        $scope.financialRepartitionEmpty = true;
        cov_fexcxxza0.s[95]++;
        for (var key in data) {
          cov_fexcxxza0.s[96]++;

          $scope.financialRepartitionEmpty = false;
          var item = (cov_fexcxxza0.s[97]++, data[key]);
          cov_fexcxxza0.s[98]++;
          params.series[0].dataSource.push({
            x: item.NAME,
            y: item.NBR,
            amount: item.AMOUNT,
            disbursed: item.DISBURSED,
            color: 'rgb(' + item.COLOR + ')'
          });
        }
        cov_fexcxxza0.s[99]++;
        $scope.financialRepartitionData = params;
      });
    },
    fundingSourceRepartition: function fundingSourceRepartition() {
      var params_ = (cov_fexcxxza0.s[100]++, processParameter());
      cov_fexcxxza0.s[101]++;
      params_.get = 'funding_source_repartition';

      cov_fexcxxza0.s[102]++;
      $CRUDService.getAll(PATH, params_, function (data) {
        cov_fexcxxza0.f[14]++;

        var params = (cov_fexcxxza0.s[103]++, {
          exportWithRightClick: true,
          locale: $LOGINService.getLang(),
          // Initializing Primary X and Y Axis
          primaryXAxis: {
            // title: 'Years',
            interval: 1,
            labelIntersectAction: 'Rotate45',
            valueType: 'Category',
            majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
            majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
            lineStyle: { width: 0 },
            maximumLabelWidth: 100,
            labelStyle: {
              color: '#728EB8',
              fontFamily: 'DM Sans'
            }
          },
          primaryYAxis: {
            valueType: 'Double',
            lineStyle: { width: 0 },
            majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
            minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
            labelFormat: '{value}',
            labelStyle: {
              color: '#728EB8',
              fontFamily: 'DM Sans'
              // labelFormat: ($scope.analyse.FIELD == 1 || $scope.analyse.FIELD == 5) ? 'p0' : 'n0'
            } },
          chartArea: {
            border: {
              width: 0
            }
          },
          useGroupingSeparator: true,
          // Initializing Chart Series
          series: [{ // FILL
            type: 'Area',
            dataSource: [],
            xName: 'x', yName: 'y', name: $translate.instant('PROJECT.DASHBOARD_VIEW.AMOUNT'),
            width: 2,
            fill: '#80E144',
            opacity: 0.5
          }, { // FILL
            type: 'Line',
            dataSource: [],
            xName: 'x', yName: 'y', name: $translate.instant('PROJECT.DASHBOARD_VIEW.DISBURSED'),
            width: 2
          }],
          height: '100%',
          axisLabelRender: function axisLabelRender(args) {
            var _ = (cov_fexcxxza0.s[104]++, args.text.split(':'));
            cov_fexcxxza0.s[105]++;
            args.text = _[0].trim();
          },

          title_: $translate.instant('PROJECT.DASHBOARD_VIEW.INVESTING_BY_FUNDING_SOURCE') + ' (' + $translate.instant('PROJECT.DASHBOARD_VIEW.DECIMAL_CURRENCY', { currency: $scope.filter.validateSelectedCurrency }) + ')',
          legendSettings: {
            visible: true
          },
          tooltip: { enable: true, format: '${series.name}: ${point.y}', shared: true
            // tooltip: {enable: true, shared: false}
          } });
        cov_fexcxxza0.s[106]++;
        $scope.fundingSourceRepartitionEmpty = true;
        cov_fexcxxza0.s[107]++;
        for (var key in data) {
          cov_fexcxxza0.s[108]++;

          $scope.fundingSourceRepartitionEmpty = false;
          var item = (cov_fexcxxza0.s[109]++, data[key]);

          var amount = (cov_fexcxxza0.s[110]++, (cov_fexcxxza0.b[26][0]++, parseFloat(item.AMOUNT)) || (cov_fexcxxza0.b[26][1]++, null));
          var disbursement = (cov_fexcxxza0.s[111]++, (cov_fexcxxza0.b[27][0]++, parseFloat(item.DISBURSEMENT)) || (cov_fexcxxza0.b[27][1]++, null));

          cov_fexcxxza0.s[112]++;
          params.series[0].dataSource.push({
            x: item.label,
            y: amount ? (cov_fexcxxza0.b[28][0]++, $scope.filter.validateExchangeRate * amount / 1000000) : (cov_fexcxxza0.b[28][1]++, null)
          });
          cov_fexcxxza0.s[113]++;
          params.series[1].dataSource.push({
            x: item.label,
            y: disbursement ? (cov_fexcxxza0.b[29][0]++, $scope.filter.validateExchangeRate * disbursement / 1000000) : (cov_fexcxxza0.b[29][1]++, null)
          });
        }

        cov_fexcxxza0.s[114]++;
        $scope.fundingSourceRepartitionData = params;
      });
    },
    fundingSourceProject: function fundingSourceProject() {
      var params_ = (cov_fexcxxza0.s[115]++, processParameter());
      cov_fexcxxza0.s[116]++;
      params_.get = 'funding_source_project';
      cov_fexcxxza0.s[117]++;
      $CRUDService.getAll(PATH, params_, function (data) {
        cov_fexcxxza0.f[15]++;

        var labels = (cov_fexcxxza0.s[118]++, [{ id: 1, name: $translate.instant('PROJECT.STATUTS.NOT_STARTED'), class: 'label-success' }, { id: 2, name: $translate.instant('PROJECT.STATUTS.ACTIVE'), class: 'label-primary' }, { id: 3, name: $translate.instant('PROJECT.STATUTS.SUSPENDED'), class: 'label-danger' }, { id: 4, name: $translate.instant('PROJECT.STATUTS.COMPLETED'), class: 'label-default' }, { id: 5, name: $translate.instant('PROJECT.STATUTS.ARCHIVED'), class: 'label-default' }]);

        var params = (cov_fexcxxza0.s[119]++, {
          exportWithRightClick: true,
          locale: $LOGINService.getLang(),
          // Initializing Primary X and Y Axis
          primaryXAxis: {
            // title: 'Years',
            interval: 1,
            labelIntersectAction: 'Rotate45',
            valueType: 'Category',
            majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
            majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
            lineStyle: { width: 0 },
            maximumLabelWidth: 100
          },
          primaryYAxis: {
            valueType: 'Double',
            lineStyle: { width: 0 },
            majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
            minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
            labelFormat: 'N'
            // labelFormat: ($scope.analyse.FIELD == 1 || $scope.analyse.FIELD == 5) ? 'p0' : 'n0'
          },
          chartArea: {
            border: {
              width: 0
            }
          },

          useGroupingSeparator: true,
          // Initializing Chart Series
          series: [],
          height: '100%',
          axisLabelRender: function axisLabelRender(args) {
            var _ = (cov_fexcxxza0.s[120]++, args.text.split(':'));
            cov_fexcxxza0.s[121]++;
            args.text = _[0].trim();
          },

          title_: $translate.instant('PROJECT.DASHBOARD_VIEW.PROJECT_BY_FUNDING_SOURCE'),
          // crosshair: {enable: true, lineType: 'Vertical'},
          // Enable Legend
          legendSettings: {
            visible: true
          },
          tooltip: { enable: true, format: '${series.name}: ${point.y} ' + $translate.instant('ACTIVITY.PROJECT') + '(s)', shared: true
            // tooltip: {enable: true, shared: false}
          } });
        cov_fexcxxza0.s[122]++;
        $scope.fundingSourceProjectEmpty = true;
        cov_fexcxxza0.s[123]++;
        var _iteratorNormalCompletion7 = true;
        var _didIteratorError7 = false;
        var _iteratorError7 = undefined;

        try {
          for (var _iterator7 = labels[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
            var _label = _step7.value;
            cov_fexcxxza0.s[124]++;

            params.series.push({
              type: 'Column',
              dataSource: [],
              xName: 'x', yName: 'y', name: _label.name,
              width: 3
            });
          }
        } catch (err) {
          _didIteratorError7 = true;
          _iteratorError7 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion7 && _iterator7.return) {
              _iterator7.return();
            }
          } finally {
            if (_didIteratorError7) {
              throw _iteratorError7;
            }
          }
        }

        cov_fexcxxza0.s[125]++;
        for (var key in data) {
          var item = (cov_fexcxxza0.s[126]++, data[key]);
          cov_fexcxxza0.s[127]++;
          var _iteratorNormalCompletion8 = true;
          var _didIteratorError8 = false;
          var _iteratorError8 = undefined;

          try {
            for (var _iterator8 = labels[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
              var label = _step8.value;
              cov_fexcxxza0.s[128]++;

              if (item[label.id + '_']) {
                cov_fexcxxza0.b[30][0]++;
                cov_fexcxxza0.s[129]++;

                $scope.fundingSourceProjectEmpty = false;
              } else {
                cov_fexcxxza0.b[30][1]++;
              }
              cov_fexcxxza0.s[130]++;
              params.series[label.id - 1].dataSource.push({
                x: item.label,
                y: (cov_fexcxxza0.b[31][0]++, item[label.id + '_']) || (cov_fexcxxza0.b[31][1]++, null)
              });
            }
          } catch (err) {
            _didIteratorError8 = true;
            _iteratorError8 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion8 && _iterator8.return) {
                _iterator8.return();
              }
            } finally {
              if (_didIteratorError8) {
                throw _iteratorError8;
              }
            }
          }
        }
        cov_fexcxxza0.s[131]++;
        $scope.fundingSourceProjectData = params;
      });
    },
    processAll: function processAll() {
      cov_fexcxxza0.s[132]++;

      this.financialRepartition();
      cov_fexcxxza0.s[133]++;
      this.fundingSourceRepartition();
      cov_fexcxxza0.s[134]++;
      this.fundingSourceProject();
    },
    validateRate: function validateRate() {
      cov_fexcxxza0.s[135]++;

      $scope.filter.validateSelectedCurrency = $scope.filter.selectedCurrency;
      cov_fexcxxza0.s[136]++;
      $scope.filter.validateExchangeRate = $scope.currency == $scope.filter.validateSelectedCurrency ? (cov_fexcxxza0.b[32][0]++, 1) : (cov_fexcxxza0.b[32][1]++, $scope.filter.exchangeRate);

      cov_fexcxxza0.s[137]++;
      this.financialRepartition();
      cov_fexcxxza0.s[138]++;
      this.fundingSourceRepartition();
    }
  };

  cov_fexcxxza0.s[139]++;
  $scope.initFilter = function () {
    cov_fexcxxza0.f[16]++;
    cov_fexcxxza0.s[140]++;

    $scope.filter = {
      nbre: 0,
      flitre: false,
      display: false,
      financing: {},
      financingType: {},
      displayPanel: 1,
      agencies: {},
      status: {},
      sectors: {},
      locations: {},
      selectedCurrency: $scope.currency,
      exchangeRate: 1,
      validateSelectedCurrency: $scope.currency,
      validateExchangeRate: 1
    };
  };
  cov_fexcxxza0.s[141]++;
  $scope.initFilter();

  cov_fexcxxza0.s[142]++;
  $scope.clearFilter = function () {
    cov_fexcxxza0.f[17]++;
    cov_fexcxxza0.s[143]++;

    $scope.initFilter();
    cov_fexcxxza0.s[144]++;
    $scope.process.processAll();
  };

  cov_fexcxxza0.s[145]++;
  $scope.process.processAll();
});