'use strict';

var cov_2iyzn53747 = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/document/edit/documentEditCtrl.js',
      hash = 'd40bf53c67080605f304dd8d2dc4ee0c526b79f2',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/document/edit/documentEditCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 361,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 19
        },
        end: {
          line: 2,
          column: 30
        }
      },
      '2': {
        start: {
          line: 3,
          column: 21
        },
        end: {
          line: 3,
          column: 30
        }
      },
      '3': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 37,
          column: 4
        }
      },
      '4': {
        start: {
          line: 38,
          column: 2
        },
        end: {
          line: 38,
          column: 45
        }
      },
      '5': {
        start: {
          line: 39,
          column: 2
        },
        end: {
          line: 39,
          column: 47
        }
      },
      '6': {
        start: {
          line: 40,
          column: 2
        },
        end: {
          line: 40,
          column: 38
        }
      },
      '7': {
        start: {
          line: 41,
          column: 2
        },
        end: {
          line: 41,
          column: 56
        }
      },
      '8': {
        start: {
          line: 42,
          column: 2
        },
        end: {
          line: 70,
          column: 4
        }
      },
      '9': {
        start: {
          line: 43,
          column: 17
        },
        end: {
          line: 43,
          column: 19
        }
      },
      '10': {
        start: {
          line: 44,
          column: 16
        },
        end: {
          line: 44,
          column: 18
        }
      },
      '11': {
        start: {
          line: 45,
          column: 4
        },
        end: {
          line: 49,
          column: 5
        }
      },
      '12': {
        start: {
          line: 46,
          column: 6
        },
        end: {
          line: 48,
          column: 9
        }
      },
      '13': {
        start: {
          line: 47,
          column: 8
        },
        end: {
          line: 47,
          column: 24
        }
      },
      '14': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 55,
          column: 5
        }
      },
      '15': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 54,
          column: 9
        }
      },
      '16': {
        start: {
          line: 53,
          column: 8
        },
        end: {
          line: 53,
          column: 24
        }
      },
      '17': {
        start: {
          line: 57,
          column: 4
        },
        end: {
          line: 57,
          column: 37
        }
      },
      '18': {
        start: {
          line: 58,
          column: 4
        },
        end: {
          line: 58,
          column: 39
        }
      },
      '19': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 59,
          column: 29
        }
      },
      '20': {
        start: {
          line: 61,
          column: 4
        },
        end: {
          line: 63,
          column: 7
        }
      },
      '21': {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 62,
          column: 43
        }
      },
      '22': {
        start: {
          line: 65,
          column: 4
        },
        end: {
          line: 67,
          column: 7
        }
      },
      '23': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 66,
          column: 42
        }
      },
      '24': {
        start: {
          line: 69,
          column: 4
        },
        end: {
          line: 69,
          column: 29
        }
      },
      '25': {
        start: {
          line: 72,
          column: 2
        },
        end: {
          line: 72,
          column: 41
        }
      },
      '26': {
        start: {
          line: 73,
          column: 2
        },
        end: {
          line: 84,
          column: 5
        }
      },
      '27': {
        start: {
          line: 74,
          column: 4
        },
        end: {
          line: 83,
          column: 6
        }
      },
      '28': {
        start: {
          line: 81,
          column: 8
        },
        end: {
          line: 81,
          column: 20
        }
      },
      '29': {
        start: {
          line: 86,
          column: 2
        },
        end: {
          line: 98,
          column: 4
        }
      },
      '30': {
        start: {
          line: 87,
          column: 4
        },
        end: {
          line: 87,
          column: 27
        }
      },
      '31': {
        start: {
          line: 88,
          column: 4
        },
        end: {
          line: 88,
          column: 29
        }
      },
      '32': {
        start: {
          line: 89,
          column: 4
        },
        end: {
          line: 97,
          column: 7
        }
      },
      '33': {
        start: {
          line: 100,
          column: 2
        },
        end: {
          line: 112,
          column: 4
        }
      },
      '34': {
        start: {
          line: 101,
          column: 4
        },
        end: {
          line: 101,
          column: 27
        }
      },
      '35': {
        start: {
          line: 102,
          column: 4
        },
        end: {
          line: 102,
          column: 29
        }
      },
      '36': {
        start: {
          line: 103,
          column: 4
        },
        end: {
          line: 111,
          column: 7
        }
      },
      '37': {
        start: {
          line: 114,
          column: 2
        },
        end: {
          line: 126,
          column: 4
        }
      },
      '38': {
        start: {
          line: 124,
          column: 6
        },
        end: {
          line: 124,
          column: 31
        }
      },
      '39': {
        start: {
          line: 128,
          column: 2
        },
        end: {
          line: 135,
          column: 5
        }
      },
      '40': {
        start: {
          line: 129,
          column: 4
        },
        end: {
          line: 131,
          column: 8
        }
      },
      '41': {
        start: {
          line: 130,
          column: 6
        },
        end: {
          line: 130,
          column: 32
        }
      },
      '42': {
        start: {
          line: 132,
          column: 4
        },
        end: {
          line: 134,
          column: 8
        }
      },
      '43': {
        start: {
          line: 133,
          column: 6
        },
        end: {
          line: 133,
          column: 32
        }
      },
      '44': {
        start: {
          line: 136,
          column: 2
        },
        end: {
          line: 138,
          column: 5
        }
      },
      '45': {
        start: {
          line: 137,
          column: 4
        },
        end: {
          line: 137,
          column: 29
        }
      },
      '46': {
        start: {
          line: 140,
          column: 2
        },
        end: {
          line: 146,
          column: 4
        }
      },
      '47': {
        start: {
          line: 141,
          column: 4
        },
        end: {
          line: 145,
          column: 5
        }
      },
      '48': {
        start: {
          line: 142,
          column: 6
        },
        end: {
          line: 144,
          column: 7
        }
      },
      '49': {
        start: {
          line: 143,
          column: 8
        },
        end: {
          line: 143,
          column: 61
        }
      },
      '50': {
        start: {
          line: 147,
          column: 2
        },
        end: {
          line: 157,
          column: 4
        }
      },
      '51': {
        start: {
          line: 148,
          column: 19
        },
        end: {
          line: 148,
          column: 53
        }
      },
      '52': {
        start: {
          line: 149,
          column: 4
        },
        end: {
          line: 152,
          column: 7
        }
      },
      '53': {
        start: {
          line: 154,
          column: 4
        },
        end: {
          line: 154,
          column: 45
        }
      },
      '54': {
        start: {
          line: 156,
          column: 4
        },
        end: {
          line: 156,
          column: 40
        }
      },
      '55': {
        start: {
          line: 159,
          column: 2
        },
        end: {
          line: 180,
          column: 4
        }
      },
      '56': {
        start: {
          line: 160,
          column: 4
        },
        end: {
          line: 179,
          column: 7
        }
      },
      '57': {
        start: {
          line: 182,
          column: 2
        },
        end: {
          line: 215,
          column: 4
        }
      },
      '58': {
        start: {
          line: 183,
          column: 4
        },
        end: {
          line: 183,
          column: 35
        }
      },
      '59': {
        start: {
          line: 184,
          column: 4
        },
        end: {
          line: 184,
          column: 22
        }
      },
      '60': {
        start: {
          line: 185,
          column: 4
        },
        end: {
          line: 185,
          column: 27
        }
      },
      '61': {
        start: {
          line: 186,
          column: 4
        },
        end: {
          line: 193,
          column: 5
        }
      },
      '62': {
        start: {
          line: 187,
          column: 19
        },
        end: {
          line: 187,
          column: 55
        }
      },
      '63': {
        start: {
          line: 188,
          column: 6
        },
        end: {
          line: 190,
          column: 7
        }
      },
      '64': {
        start: {
          line: 189,
          column: 8
        },
        end: {
          line: 189,
          column: 80
        }
      },
      '65': {
        start: {
          line: 191,
          column: 6
        },
        end: {
          line: 191,
          column: 36
        }
      },
      '66': {
        start: {
          line: 192,
          column: 6
        },
        end: {
          line: 192,
          column: 69
        }
      },
      '67': {
        start: {
          line: 197,
          column: 4
        },
        end: {
          line: 197,
          column: 45
        }
      },
      '68': {
        start: {
          line: 198,
          column: 4
        },
        end: {
          line: 198,
          column: 73
        }
      },
      '69': {
        start: {
          line: 200,
          column: 4
        },
        end: {
          line: 204,
          column: 7
        }
      },
      '70': {
        start: {
          line: 201,
          column: 6
        },
        end: {
          line: 201,
          column: 65
        }
      },
      '71': {
        start: {
          line: 206,
          column: 4
        },
        end: {
          line: 209,
          column: 7
        }
      },
      '72': {
        start: {
          line: 207,
          column: 6
        },
        end: {
          line: 207,
          column: 67
        }
      },
      '73': {
        start: {
          line: 211,
          column: 4
        },
        end: {
          line: 213,
          column: 38
        }
      },
      '74': {
        start: {
          line: 212,
          column: 6
        },
        end: {
          line: 212,
          column: 41
        }
      },
      '75': {
        start: {
          line: 217,
          column: 2
        },
        end: {
          line: 217,
          column: 34
        }
      },
      '76': {
        start: {
          line: 218,
          column: 2
        },
        end: {
          line: 283,
          column: 4
        }
      },
      '77': {
        start: {
          line: 219,
          column: 4
        },
        end: {
          line: 219,
          column: 22
        }
      },
      '78': {
        start: {
          line: 220,
          column: 4
        },
        end: {
          line: 220,
          column: 36
        }
      },
      '79': {
        start: {
          line: 221,
          column: 4
        },
        end: {
          line: 226,
          column: 5
        }
      },
      '80': {
        start: {
          line: 222,
          column: 6
        },
        end: {
          line: 225,
          column: 9
        }
      },
      '81': {
        start: {
          line: 227,
          column: 23
        },
        end: {
          line: 227,
          column: 56
        }
      },
      '82': {
        start: {
          line: 229,
          column: 4
        },
        end: {
          line: 231,
          column: 5
        }
      },
      '83': {
        start: {
          line: 230,
          column: 6
        },
        end: {
          line: 230,
          column: 31
        }
      },
      '84': {
        start: {
          line: 232,
          column: 4
        },
        end: {
          line: 234,
          column: 5
        }
      },
      '85': {
        start: {
          line: 233,
          column: 6
        },
        end: {
          line: 233,
          column: 31
        }
      },
      '86': {
        start: {
          line: 236,
          column: 4
        },
        end: {
          line: 241,
          column: 5
        }
      },
      '87': {
        start: {
          line: 237,
          column: 17
        },
        end: {
          line: 237,
          column: 129
        }
      },
      '88': {
        start: {
          line: 238,
          column: 6
        },
        end: {
          line: 240,
          column: 7
        }
      },
      '89': {
        start: {
          line: 239,
          column: 8
        },
        end: {
          line: 239,
          column: 33
        }
      },
      '90': {
        start: {
          line: 242,
          column: 4
        },
        end: {
          line: 246,
          column: 5
        }
      },
      '91': {
        start: {
          line: 243,
          column: 6
        },
        end: {
          line: 245,
          column: 7
        }
      },
      '92': {
        start: {
          line: 244,
          column: 8
        },
        end: {
          line: 244,
          column: 15
        }
      },
      '93': {
        start: {
          line: 247,
          column: 4
        },
        end: {
          line: 247,
          column: 17
        }
      },
      '94': {
        start: {
          line: 248,
          column: 4
        },
        end: {
          line: 248,
          column: 87
        }
      },
      '95': {
        start: {
          line: 249,
          column: 4
        },
        end: {
          line: 251,
          column: 24
        }
      },
      '96': {
        start: {
          line: 250,
          column: 6
        },
        end: {
          line: 250,
          column: 22
        }
      },
      '97': {
        start: {
          line: 252,
          column: 4
        },
        end: {
          line: 254,
          column: 24
        }
      },
      '98': {
        start: {
          line: 253,
          column: 6
        },
        end: {
          line: 253,
          column: 22
        }
      },
      '99': {
        start: {
          line: 255,
          column: 4
        },
        end: {
          line: 255,
          column: 71
        }
      },
      '100': {
        start: {
          line: 256,
          column: 4
        },
        end: {
          line: 256,
          column: 31
        }
      },
      '101': {
        start: {
          line: 257,
          column: 4
        },
        end: {
          line: 257,
          column: 29
        }
      },
      '102': {
        start: {
          line: 258,
          column: 4
        },
        end: {
          line: 258,
          column: 50
        }
      },
      '103': {
        start: {
          line: 259,
          column: 4
        },
        end: {
          line: 259,
          column: 56
        }
      },
      '104': {
        start: {
          line: 260,
          column: 15
        },
        end: {
          line: 260,
          column: 18
        }
      },
      '105': {
        start: {
          line: 261,
          column: 4
        },
        end: {
          line: 263,
          column: 5
        }
      },
      '106': {
        start: {
          line: 262,
          column: 6
        },
        end: {
          line: 262,
          column: 62
        }
      },
      '107': {
        start: {
          line: 264,
          column: 4
        },
        end: {
          line: 264,
          column: 35
        }
      },
      '108': {
        start: {
          line: 265,
          column: 18
        },
        end: {
          line: 265,
          column: 48
        }
      },
      '109': {
        start: {
          line: 266,
          column: 4
        },
        end: {
          line: 266,
          column: 33
        }
      },
      '110': {
        start: {
          line: 267,
          column: 4
        },
        end: {
          line: 267,
          column: 28
        }
      },
      '111': {
        start: {
          line: 268,
          column: 4
        },
        end: {
          line: 282,
          column: 7
        }
      },
      '112': {
        start: {
          line: 269,
          column: 6
        },
        end: {
          line: 269,
          column: 31
        }
      },
      '113': {
        start: {
          line: 270,
          column: 6
        },
        end: {
          line: 275,
          column: 7
        }
      },
      '114': {
        start: {
          line: 271,
          column: 8
        },
        end: {
          line: 273,
          column: 9
        }
      },
      '115': {
        start: {
          line: 272,
          column: 10
        },
        end: {
          line: 272,
          column: 41
        }
      },
      '116': {
        start: {
          line: 274,
          column: 8
        },
        end: {
          line: 274,
          column: 15
        }
      },
      '117': {
        start: {
          line: 276,
          column: 6
        },
        end: {
          line: 276,
          column: 53
        }
      },
      '118': {
        start: {
          line: 277,
          column: 6
        },
        end: {
          line: 277,
          column: 44
        }
      },
      '119': {
        start: {
          line: 278,
          column: 6
        },
        end: {
          line: 280,
          column: 7
        }
      },
      '120': {
        start: {
          line: 279,
          column: 8
        },
        end: {
          line: 279,
          column: 73
        }
      },
      '121': {
        start: {
          line: 281,
          column: 6
        },
        end: {
          line: 281,
          column: 30
        }
      },
      '122': {
        start: {
          line: 285,
          column: 2
        },
        end: {
          line: 288,
          column: 4
        }
      },
      '123': {
        start: {
          line: 286,
          column: 4
        },
        end: {
          line: 286,
          column: 50
        }
      },
      '124': {
        start: {
          line: 287,
          column: 4
        },
        end: {
          line: 287,
          column: 143
        }
      },
      '125': {
        start: {
          line: 290,
          column: 2
        },
        end: {
          line: 301,
          column: 4
        }
      },
      '126': {
        start: {
          line: 291,
          column: 4
        },
        end: {
          line: 300,
          column: 7
        }
      },
      '127': {
        start: {
          line: 292,
          column: 6
        },
        end: {
          line: 298,
          column: 7
        }
      },
      '128': {
        start: {
          line: 293,
          column: 22
        },
        end: {
          line: 293,
          column: 26
        }
      },
      '129': {
        start: {
          line: 294,
          column: 8
        },
        end: {
          line: 294,
          column: 43
        }
      },
      '130': {
        start: {
          line: 295,
          column: 8
        },
        end: {
          line: 295,
          column: 61
        }
      },
      '131': {
        start: {
          line: 296,
          column: 8
        },
        end: {
          line: 296,
          column: 33
        }
      },
      '132': {
        start: {
          line: 297,
          column: 8
        },
        end: {
          line: 297,
          column: 15
        }
      },
      '133': {
        start: {
          line: 299,
          column: 6
        },
        end: {
          line: 299,
          column: 30
        }
      },
      '134': {
        start: {
          line: 303,
          column: 2
        },
        end: {
          line: 313,
          column: 4
        }
      },
      '135': {
        start: {
          line: 304,
          column: 4
        },
        end: {
          line: 312,
          column: 7
        }
      },
      '136': {
        start: {
          line: 305,
          column: 6
        },
        end: {
          line: 311,
          column: 9
        }
      },
      '137': {
        start: {
          line: 306,
          column: 8
        },
        end: {
          line: 306,
          column: 54
        }
      },
      '138': {
        start: {
          line: 308,
          column: 8
        },
        end: {
          line: 308,
          column: 59
        }
      },
      '139': {
        start: {
          line: 309,
          column: 8
        },
        end: {
          line: 309,
          column: 31
        }
      },
      '140': {
        start: {
          line: 310,
          column: 8
        },
        end: {
          line: 310,
          column: 20
        }
      },
      '141': {
        start: {
          line: 314,
          column: 2
        },
        end: {
          line: 314,
          column: 19
        }
      },
      '142': {
        start: {
          line: 316,
          column: 2
        },
        end: {
          line: 320,
          column: 3
        }
      },
      '143': {
        start: {
          line: 317,
          column: 4
        },
        end: {
          line: 317,
          column: 99
        }
      },
      '144': {
        start: {
          line: 319,
          column: 4
        },
        end: {
          line: 319,
          column: 41
        }
      },
      '145': {
        start: {
          line: 322,
          column: 2
        },
        end: {
          line: 328,
          column: 4
        }
      },
      '146': {
        start: {
          line: 323,
          column: 4
        },
        end: {
          line: 325,
          column: 5
        }
      },
      '147': {
        start: {
          line: 324,
          column: 6
        },
        end: {
          line: 324,
          column: 13
        }
      },
      '148': {
        start: {
          line: 326,
          column: 4
        },
        end: {
          line: 326,
          column: 51
        }
      },
      '149': {
        start: {
          line: 327,
          column: 4
        },
        end: {
          line: 327,
          column: 25
        }
      },
      '150': {
        start: {
          line: 329,
          column: 19
        },
        end: {
          line: 334,
          column: 4
        }
      },
      '151': {
        start: {
          line: 335,
          column: 2
        },
        end: {
          line: 339,
          column: 4
        }
      },
      '152': {
        start: {
          line: 336,
          column: 4
        },
        end: {
          line: 336,
          column: 57
        }
      },
      '153': {
        start: {
          line: 337,
          column: 4
        },
        end: {
          line: 337,
          column: 32
        }
      },
      '154': {
        start: {
          line: 338,
          column: 4
        },
        end: {
          line: 338,
          column: 27
        }
      },
      '155': {
        start: {
          line: 342,
          column: 24
        },
        end: {
          line: 347,
          column: 4
        }
      },
      '156': {
        start: {
          line: 348,
          column: 2
        },
        end: {
          line: 351,
          column: 4
        }
      },
      '157': {
        start: {
          line: 349,
          column: 4
        },
        end: {
          line: 349,
          column: 32
        }
      },
      '158': {
        start: {
          line: 350,
          column: 4
        },
        end: {
          line: 350,
          column: 22
        }
      },
      '159': {
        start: {
          line: 353,
          column: 2
        },
        end: {
          line: 357,
          column: 4
        }
      },
      '160': {
        start: {
          line: 354,
          column: 4
        },
        end: {
          line: 354,
          column: 84
        }
      },
      '161': {
        start: {
          line: 355,
          column: 4
        },
        end: {
          line: 355,
          column: 47
        }
      },
      '162': {
        start: {
          line: 356,
          column: 4
        },
        end: {
          line: 356,
          column: 33
        }
      },
      '163': {
        start: {
          line: 358,
          column: 2
        },
        end: {
          line: 360,
          column: 4
        }
      },
      '164': {
        start: {
          line: 359,
          column: 4
        },
        end: {
          line: 359,
          column: 33
        }
      },
      '165': {
        start: {
          line: 364,
          column: 21
        },
        end: {
          line: 364,
          column: 30
        }
      },
      '166': {
        start: {
          line: 366,
          column: 2
        },
        end: {
          line: 370,
          column: 5
        }
      },
      '167': {
        start: {
          line: 367,
          column: 4
        },
        end: {
          line: 369,
          column: 7
        }
      },
      '168': {
        start: {
          line: 368,
          column: 6
        },
        end: {
          line: 368,
          column: 48
        }
      },
      '169': {
        start: {
          line: 372,
          column: 2
        },
        end: {
          line: 374,
          column: 4
        }
      },
      '170': {
        start: {
          line: 373,
          column: 4
        },
        end: {
          line: 373,
          column: 50
        }
      },
      '171': {
        start: {
          line: 375,
          column: 2
        },
        end: {
          line: 377,
          column: 4
        }
      },
      '172': {
        start: {
          line: 376,
          column: 4
        },
        end: {
          line: 376,
          column: 36
        }
      },
      '173': {
        start: {
          line: 378,
          column: 2
        },
        end: {
          line: 400,
          column: 4
        }
      },
      '174': {
        start: {
          line: 379,
          column: 4
        },
        end: {
          line: 381,
          column: 5
        }
      },
      '175': {
        start: {
          line: 380,
          column: 6
        },
        end: {
          line: 380,
          column: 13
        }
      },
      '176': {
        start: {
          line: 382,
          column: 4
        },
        end: {
          line: 387,
          column: 5
        }
      },
      '177': {
        start: {
          line: 383,
          column: 27
        },
        end: {
          line: 383,
          column: 173
        }
      },
      '178': {
        start: {
          line: 384,
          column: 6
        },
        end: {
          line: 386,
          column: 7
        }
      },
      '179': {
        start: {
          line: 385,
          column: 8
        },
        end: {
          line: 385,
          column: 15
        }
      },
      '180': {
        start: {
          line: 388,
          column: 4
        },
        end: {
          line: 388,
          column: 34
        }
      },
      '181': {
        start: {
          line: 389,
          column: 25
        },
        end: {
          line: 389,
          column: 63
        }
      },
      '182': {
        start: {
          line: 390,
          column: 19
        },
        end: {
          line: 390,
          column: 38
        }
      },
      '183': {
        start: {
          line: 391,
          column: 4
        },
        end: {
          line: 399,
          column: 7
        }
      },
      '184': {
        start: {
          line: 392,
          column: 6
        },
        end: {
          line: 392,
          column: 37
        }
      },
      '185': {
        start: {
          line: 393,
          column: 6
        },
        end: {
          line: 395,
          column: 7
        }
      },
      '186': {
        start: {
          line: 394,
          column: 8
        },
        end: {
          line: 394,
          column: 45
        }
      },
      '187': {
        start: {
          line: 396,
          column: 6
        },
        end: {
          line: 396,
          column: 54
        }
      },
      '188': {
        start: {
          line: 397,
          column: 6
        },
        end: {
          line: 397,
          column: 27
        }
      },
      '189': {
        start: {
          line: 398,
          column: 6
        },
        end: {
          line: 398,
          column: 38
        }
      },
      '190': {
        start: {
          line: 401,
          column: 2
        },
        end: {
          line: 434,
          column: 4
        }
      },
      '191': {
        start: {
          line: 402,
          column: 21
        },
        end: {
          line: 402,
          column: 52
        }
      },
      '192': {
        start: {
          line: 403,
          column: 4
        },
        end: {
          line: 405,
          column: 5
        }
      },
      '193': {
        start: {
          line: 404,
          column: 6
        },
        end: {
          line: 404,
          column: 50
        }
      },
      '194': {
        start: {
          line: 406,
          column: 4
        },
        end: {
          line: 408,
          column: 5
        }
      },
      '195': {
        start: {
          line: 407,
          column: 6
        },
        end: {
          line: 407,
          column: 50
        }
      },
      '196': {
        start: {
          line: 409,
          column: 4
        },
        end: {
          line: 433,
          column: 7
        }
      },
      '197': {
        start: {
          line: 421,
          column: 6
        },
        end: {
          line: 423,
          column: 7
        }
      },
      '198': {
        start: {
          line: 422,
          column: 8
        },
        end: {
          line: 422,
          column: 15
        }
      },
      '199': {
        start: {
          line: 424,
          column: 6
        },
        end: {
          line: 424,
          column: 30
        }
      },
      '200': {
        start: {
          line: 425,
          column: 6
        },
        end: {
          line: 432,
          column: 9
        }
      },
      '201': {
        start: {
          line: 426,
          column: 8
        },
        end: {
          line: 429,
          column: 9
        }
      },
      '202': {
        start: {
          line: 427,
          column: 10
        },
        end: {
          line: 427,
          column: 35
        }
      },
      '203': {
        start: {
          line: 428,
          column: 10
        },
        end: {
          line: 428,
          column: 17
        }
      },
      '204': {
        start: {
          line: 430,
          column: 8
        },
        end: {
          line: 430,
          column: 44
        }
      },
      '205': {
        start: {
          line: 431,
          column: 8
        },
        end: {
          line: 431,
          column: 29
        }
      },
      '206': {
        start: {
          line: 435,
          column: 2
        },
        end: {
          line: 442,
          column: 4
        }
      },
      '207': {
        start: {
          line: 436,
          column: 4
        },
        end: {
          line: 441,
          column: 7
        }
      },
      '208': {
        start: {
          line: 443,
          column: 2
        },
        end: {
          line: 446,
          column: 4
        }
      },
      '209': {
        start: {
          line: 444,
          column: 4
        },
        end: {
          line: 444,
          column: 23
        }
      },
      '210': {
        start: {
          line: 445,
          column: 4
        },
        end: {
          line: 445,
          column: 40
        }
      },
      '211': {
        start: {
          line: 447,
          column: 2
        },
        end: {
          line: 447,
          column: 23
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 53
          },
          end: {
            line: 1,
            column: 54
          }
        },
        loc: {
          start: {
            line: 1,
            column: 221
          },
          end: {
            line: 361,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 42,
            column: 21
          },
          end: {
            line: 42,
            column: 22
          }
        },
        loc: {
          start: {
            line: 42,
            column: 33
          },
          end: {
            line: 70,
            column: 3
          }
        },
        line: 42
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 46,
            column: 47
          },
          end: {
            line: 46,
            column: 48
          }
        },
        loc: {
          start: {
            line: 46,
            column: 56
          },
          end: {
            line: 48,
            column: 7
          }
        },
        line: 46
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 52,
            column: 48
          },
          end: {
            line: 52,
            column: 49
          }
        },
        loc: {
          start: {
            line: 52,
            column: 57
          },
          end: {
            line: 54,
            column: 7
          }
        },
        line: 52
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 61,
            column: 58
          },
          end: {
            line: 61,
            column: 59
          }
        },
        loc: {
          start: {
            line: 61,
            column: 67
          },
          end: {
            line: 63,
            column: 5
          }
        },
        line: 61
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 65,
            column: 62
          },
          end: {
            line: 65,
            column: 63
          }
        },
        loc: {
          start: {
            line: 65,
            column: 71
          },
          end: {
            line: 67,
            column: 5
          }
        },
        line: 65
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 73,
            column: 51
          },
          end: {
            line: 73,
            column: 52
          }
        },
        loc: {
          start: {
            line: 73,
            column: 59
          },
          end: {
            line: 84,
            column: 3
          }
        },
        line: 73
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 86,
            column: 25
          },
          end: {
            line: 86,
            column: 26
          }
        },
        loc: {
          start: {
            line: 86,
            column: 41
          },
          end: {
            line: 98,
            column: 3
          }
        },
        line: 86
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 100,
            column: 23
          },
          end: {
            line: 100,
            column: 24
          }
        },
        loc: {
          start: {
            line: 100,
            column: 39
          },
          end: {
            line: 112,
            column: 3
          }
        },
        line: 100
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 128,
            column: 28
          },
          end: {
            line: 128,
            column: 29
          }
        },
        loc: {
          start: {
            line: 128,
            column: 34
          },
          end: {
            line: 135,
            column: 3
          }
        },
        line: 128
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 129,
            column: 69
          },
          end: {
            line: 129,
            column: 70
          }
        },
        loc: {
          start: {
            line: 129,
            column: 77
          },
          end: {
            line: 131,
            column: 5
          }
        },
        line: 129
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 132,
            column: 71
          },
          end: {
            line: 132,
            column: 72
          }
        },
        loc: {
          start: {
            line: 132,
            column: 79
          },
          end: {
            line: 134,
            column: 5
          }
        },
        line: 132
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 136,
            column: 43
          },
          end: {
            line: 136,
            column: 44
          }
        },
        loc: {
          start: {
            line: 136,
            column: 49
          },
          end: {
            line: 138,
            column: 3
          }
        },
        line: 136
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 140,
            column: 24
          },
          end: {
            line: 140,
            column: 25
          }
        },
        loc: {
          start: {
            line: 140,
            column: 36
          },
          end: {
            line: 146,
            column: 3
          }
        },
        line: 140
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 147,
            column: 30
          },
          end: {
            line: 147,
            column: 31
          }
        },
        loc: {
          start: {
            line: 147,
            column: 42
          },
          end: {
            line: 157,
            column: 3
          }
        },
        line: 147
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 159,
            column: 26
          },
          end: {
            line: 159,
            column: 27
          }
        },
        loc: {
          start: {
            line: 159,
            column: 55
          },
          end: {
            line: 180,
            column: 3
          }
        },
        line: 159
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 182,
            column: 24
          },
          end: {
            line: 182,
            column: 25
          }
        },
        loc: {
          start: {
            line: 182,
            column: 40
          },
          end: {
            line: 215,
            column: 3
          }
        },
        line: 182
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 200,
            column: 58
          },
          end: {
            line: 200,
            column: 59
          }
        },
        loc: {
          start: {
            line: 200,
            column: 67
          },
          end: {
            line: 204,
            column: 5
          }
        },
        line: 200
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 206,
            column: 62
          },
          end: {
            line: 206,
            column: 63
          }
        },
        loc: {
          start: {
            line: 206,
            column: 71
          },
          end: {
            line: 209,
            column: 5
          }
        },
        line: 206
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 211,
            column: 67
          },
          end: {
            line: 211,
            column: 68
          }
        },
        loc: {
          start: {
            line: 211,
            column: 76
          },
          end: {
            line: 213,
            column: 5
          }
        },
        line: 211
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 218,
            column: 20
          },
          end: {
            line: 218,
            column: 21
          }
        },
        loc: {
          start: {
            line: 218,
            column: 32
          },
          end: {
            line: 283,
            column: 3
          }
        },
        line: 218
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 249,
            column: 61
          },
          end: {
            line: 249,
            column: 62
          }
        },
        loc: {
          start: {
            line: 249,
            column: 70
          },
          end: {
            line: 251,
            column: 5
          }
        },
        line: 249
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 252,
            column: 57
          },
          end: {
            line: 252,
            column: 58
          }
        },
        loc: {
          start: {
            line: 252,
            column: 66
          },
          end: {
            line: 254,
            column: 5
          }
        },
        line: 252
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 268,
            column: 155
          },
          end: {
            line: 268,
            column: 156
          }
        },
        loc: {
          start: {
            line: 268,
            column: 163
          },
          end: {
            line: 282,
            column: 5
          }
        },
        line: 268
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 285,
            column: 26
          },
          end: {
            line: 285,
            column: 27
          }
        },
        loc: {
          start: {
            line: 285,
            column: 38
          },
          end: {
            line: 288,
            column: 3
          }
        },
        line: 285
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 290,
            column: 16
          },
          end: {
            line: 290,
            column: 17
          }
        },
        loc: {
          start: {
            line: 290,
            column: 32
          },
          end: {
            line: 301,
            column: 3
          }
        },
        line: 290
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 291,
            column: 61
          },
          end: {
            line: 291,
            column: 62
          }
        },
        loc: {
          start: {
            line: 291,
            column: 69
          },
          end: {
            line: 300,
            column: 5
          }
        },
        line: 291
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 303,
            column: 19
          },
          end: {
            line: 303,
            column: 20
          }
        },
        loc: {
          start: {
            line: 303,
            column: 31
          },
          end: {
            line: 313,
            column: 3
          }
        },
        line: 303
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 304,
            column: 51
          },
          end: {
            line: 304,
            column: 52
          }
        },
        loc: {
          start: {
            line: 304,
            column: 59
          },
          end: {
            line: 312,
            column: 5
          }
        },
        line: 304
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 305,
            column: 33
          },
          end: {
            line: 305,
            column: 34
          }
        },
        loc: {
          start: {
            line: 305,
            column: 41
          },
          end: {
            line: 307,
            column: 7
          }
        },
        line: 305
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 307,
            column: 13
          },
          end: {
            line: 307,
            column: 14
          }
        },
        loc: {
          start: {
            line: 307,
            column: 21
          },
          end: {
            line: 311,
            column: 7
          }
        },
        line: 307
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 322,
            column: 29
          },
          end: {
            line: 322,
            column: 30
          }
        },
        loc: {
          start: {
            line: 322,
            column: 50
          },
          end: {
            line: 328,
            column: 3
          }
        },
        line: 322
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 335,
            column: 31
          },
          end: {
            line: 335,
            column: 32
          }
        },
        loc: {
          start: {
            line: 335,
            column: 51
          },
          end: {
            line: 339,
            column: 3
          }
        },
        line: 335
      },
      '33': {
        name: '(anonymous_33)',
        decl: {
          start: {
            line: 348,
            column: 36
          },
          end: {
            line: 348,
            column: 37
          }
        },
        loc: {
          start: {
            line: 348,
            column: 56
          },
          end: {
            line: 351,
            column: 3
          }
        },
        line: 348
      },
      '34': {
        name: '(anonymous_34)',
        decl: {
          start: {
            line: 353,
            column: 32
          },
          end: {
            line: 353,
            column: 33
          }
        },
        loc: {
          start: {
            line: 353,
            column: 75
          },
          end: {
            line: 357,
            column: 3
          }
        },
        line: 353
      },
      '35': {
        name: '(anonymous_35)',
        decl: {
          start: {
            line: 358,
            column: 30
          },
          end: {
            line: 358,
            column: 31
          }
        },
        loc: {
          start: {
            line: 358,
            column: 73
          },
          end: {
            line: 360,
            column: 3
          }
        },
        line: 358
      },
      '36': {
        name: 'OtherCtrl_edit',
        decl: {
          start: {
            line: 363,
            column: 9
          },
          end: {
            line: 363,
            column: 23
          }
        },
        loc: {
          start: {
            line: 363,
            column: 118
          },
          end: {
            line: 448,
            column: 1
          }
        },
        line: 363
      },
      '37': {
        name: '(anonymous_37)',
        decl: {
          start: {
            line: 366,
            column: 24
          },
          end: {
            line: 366,
            column: 25
          }
        },
        loc: {
          start: {
            line: 366,
            column: 30
          },
          end: {
            line: 370,
            column: 3
          }
        },
        line: 366
      },
      '38': {
        name: '(anonymous_38)',
        decl: {
          start: {
            line: 367,
            column: 64
          },
          end: {
            line: 367,
            column: 65
          }
        },
        loc: {
          start: {
            line: 367,
            column: 72
          },
          end: {
            line: 369,
            column: 5
          }
        },
        line: 367
      },
      '39': {
        name: '(anonymous_39)',
        decl: {
          start: {
            line: 372,
            column: 21
          },
          end: {
            line: 372,
            column: 22
          }
        },
        loc: {
          start: {
            line: 372,
            column: 37
          },
          end: {
            line: 374,
            column: 3
          }
        },
        line: 372
      },
      '40': {
        name: '(anonymous_40)',
        decl: {
          start: {
            line: 375,
            column: 23
          },
          end: {
            line: 375,
            column: 24
          }
        },
        loc: {
          start: {
            line: 375,
            column: 41
          },
          end: {
            line: 377,
            column: 3
          }
        },
        line: 375
      },
      '41': {
        name: '(anonymous_41)',
        decl: {
          start: {
            line: 378,
            column: 25
          },
          end: {
            line: 378,
            column: 26
          }
        },
        loc: {
          start: {
            line: 378,
            column: 37
          },
          end: {
            line: 400,
            column: 3
          }
        },
        line: 378
      },
      '42': {
        name: '(anonymous_42)',
        decl: {
          start: {
            line: 391,
            column: 96
          },
          end: {
            line: 391,
            column: 97
          }
        },
        loc: {
          start: {
            line: 391,
            column: 104
          },
          end: {
            line: 399,
            column: 5
          }
        },
        line: 391
      },
      '43': {
        name: '(anonymous_43)',
        decl: {
          start: {
            line: 401,
            column: 23
          },
          end: {
            line: 401,
            column: 24
          }
        },
        loc: {
          start: {
            line: 401,
            column: 39
          },
          end: {
            line: 434,
            column: 3
          }
        },
        line: 401
      },
      '44': {
        name: '(anonymous_44)',
        decl: {
          start: {
            line: 420,
            column: 7
          },
          end: {
            line: 420,
            column: 8
          }
        },
        loc: {
          start: {
            line: 420,
            column: 20
          },
          end: {
            line: 433,
            column: 5
          }
        },
        line: 420
      },
      '45': {
        name: '(anonymous_45)',
        decl: {
          start: {
            line: 425,
            column: 86
          },
          end: {
            line: 425,
            column: 87
          }
        },
        loc: {
          start: {
            line: 425,
            column: 94
          },
          end: {
            line: 432,
            column: 7
          }
        },
        line: 425
      },
      '46': {
        name: '(anonymous_46)',
        decl: {
          start: {
            line: 435,
            column: 20
          },
          end: {
            line: 435,
            column: 21
          }
        },
        loc: {
          start: {
            line: 435,
            column: 32
          },
          end: {
            line: 442,
            column: 3
          }
        },
        line: 435
      },
      '47': {
        name: '(anonymous_47)',
        decl: {
          start: {
            line: 443,
            column: 22
          },
          end: {
            line: 443,
            column: 23
          }
        },
        loc: {
          start: {
            line: 443,
            column: 34
          },
          end: {
            line: 446,
            column: 3
          }
        },
        line: 443
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 45,
            column: 4
          },
          end: {
            line: 49,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 4
          },
          end: {
            line: 49,
            column: 5
          }
        }, {
          start: {
            line: 45,
            column: 4
          },
          end: {
            line: 49,
            column: 5
          }
        }],
        line: 45
      },
      '1': {
        loc: {
          start: {
            line: 51,
            column: 4
          },
          end: {
            line: 55,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 51,
            column: 4
          },
          end: {
            line: 55,
            column: 5
          }
        }, {
          start: {
            line: 51,
            column: 4
          },
          end: {
            line: 55,
            column: 5
          }
        }],
        line: 51
      },
      '2': {
        loc: {
          start: {
            line: 142,
            column: 6
          },
          end: {
            line: 144,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 142,
            column: 6
          },
          end: {
            line: 144,
            column: 7
          }
        }, {
          start: {
            line: 142,
            column: 6
          },
          end: {
            line: 144,
            column: 7
          }
        }],
        line: 142
      },
      '3': {
        loc: {
          start: {
            line: 186,
            column: 4
          },
          end: {
            line: 193,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 186,
            column: 4
          },
          end: {
            line: 193,
            column: 5
          }
        }, {
          start: {
            line: 186,
            column: 4
          },
          end: {
            line: 193,
            column: 5
          }
        }],
        line: 186
      },
      '4': {
        loc: {
          start: {
            line: 187,
            column: 20
          },
          end: {
            line: 187,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 187,
            column: 20
          },
          end: {
            line: 187,
            column: 37
          }
        }, {
          start: {
            line: 187,
            column: 41
          },
          end: {
            line: 187,
            column: 43
          }
        }],
        line: 187
      },
      '5': {
        loc: {
          start: {
            line: 201,
            column: 14
          },
          end: {
            line: 201,
            column: 32
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 201,
            column: 14
          },
          end: {
            line: 201,
            column: 26
          }
        }, {
          start: {
            line: 201,
            column: 30
          },
          end: {
            line: 201,
            column: 32
          }
        }],
        line: 201
      },
      '6': {
        loc: {
          start: {
            line: 207,
            column: 14
          },
          end: {
            line: 207,
            column: 34
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 207,
            column: 14
          },
          end: {
            line: 207,
            column: 28
          }
        }, {
          start: {
            line: 207,
            column: 32
          },
          end: {
            line: 207,
            column: 34
          }
        }],
        line: 207
      },
      '7': {
        loc: {
          start: {
            line: 211,
            column: 37
          },
          end: {
            line: 213,
            column: 37
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 211,
            column: 37
          },
          end: {
            line: 213,
            column: 6
          }
        }, {
          start: {
            line: 213,
            column: 10
          },
          end: {
            line: 213,
            column: 37
          }
        }],
        line: 211
      },
      '8': {
        loc: {
          start: {
            line: 229,
            column: 4
          },
          end: {
            line: 231,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 229,
            column: 4
          },
          end: {
            line: 231,
            column: 5
          }
        }, {
          start: {
            line: 229,
            column: 4
          },
          end: {
            line: 231,
            column: 5
          }
        }],
        line: 229
      },
      '9': {
        loc: {
          start: {
            line: 232,
            column: 4
          },
          end: {
            line: 234,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 232,
            column: 4
          },
          end: {
            line: 234,
            column: 5
          }
        }, {
          start: {
            line: 232,
            column: 4
          },
          end: {
            line: 234,
            column: 5
          }
        }],
        line: 232
      },
      '10': {
        loc: {
          start: {
            line: 236,
            column: 4
          },
          end: {
            line: 241,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 236,
            column: 4
          },
          end: {
            line: 241,
            column: 5
          }
        }, {
          start: {
            line: 236,
            column: 4
          },
          end: {
            line: 241,
            column: 5
          }
        }],
        line: 236
      },
      '11': {
        loc: {
          start: {
            line: 236,
            column: 8
          },
          end: {
            line: 236,
            column: 63
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 236,
            column: 8
          },
          end: {
            line: 236,
            column: 33
          }
        }, {
          start: {
            line: 236,
            column: 37
          },
          end: {
            line: 236,
            column: 63
          }
        }],
        line: 236
      },
      '12': {
        loc: {
          start: {
            line: 238,
            column: 6
          },
          end: {
            line: 240,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 238,
            column: 6
          },
          end: {
            line: 240,
            column: 7
          }
        }, {
          start: {
            line: 238,
            column: 6
          },
          end: {
            line: 240,
            column: 7
          }
        }],
        line: 238
      },
      '13': {
        loc: {
          start: {
            line: 238,
            column: 10
          },
          end: {
            line: 238,
            column: 32
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 238,
            column: 10
          },
          end: {
            line: 238,
            column: 18
          }
        }, {
          start: {
            line: 238,
            column: 22
          },
          end: {
            line: 238,
            column: 32
          }
        }],
        line: 238
      },
      '14': {
        loc: {
          start: {
            line: 243,
            column: 6
          },
          end: {
            line: 245,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 243,
            column: 6
          },
          end: {
            line: 245,
            column: 7
          }
        }, {
          start: {
            line: 243,
            column: 6
          },
          end: {
            line: 245,
            column: 7
          }
        }],
        line: 243
      },
      '15': {
        loc: {
          start: {
            line: 258,
            column: 24
          },
          end: {
            line: 258,
            column: 49
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 258,
            column: 44
          },
          end: {
            line: 258,
            column: 45
          }
        }, {
          start: {
            line: 258,
            column: 48
          },
          end: {
            line: 258,
            column: 49
          }
        }],
        line: 258
      },
      '16': {
        loc: {
          start: {
            line: 259,
            column: 27
          },
          end: {
            line: 259,
            column: 55
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 259,
            column: 50
          },
          end: {
            line: 259,
            column: 51
          }
        }, {
          start: {
            line: 259,
            column: 54
          },
          end: {
            line: 259,
            column: 55
          }
        }],
        line: 259
      },
      '17': {
        loc: {
          start: {
            line: 262,
            column: 14
          },
          end: {
            line: 262,
            column: 61
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 262,
            column: 43
          },
          end: {
            line: 262,
            column: 55
          }
        }, {
          start: {
            line: 262,
            column: 59
          },
          end: {
            line: 262,
            column: 61
          }
        }],
        line: 262
      },
      '18': {
        loc: {
          start: {
            line: 270,
            column: 6
          },
          end: {
            line: 275,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 270,
            column: 6
          },
          end: {
            line: 275,
            column: 7
          }
        }, {
          start: {
            line: 270,
            column: 6
          },
          end: {
            line: 275,
            column: 7
          }
        }],
        line: 270
      },
      '19': {
        loc: {
          start: {
            line: 271,
            column: 8
          },
          end: {
            line: 273,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 271,
            column: 8
          },
          end: {
            line: 273,
            column: 9
          }
        }, {
          start: {
            line: 271,
            column: 8
          },
          end: {
            line: 273,
            column: 9
          }
        }],
        line: 271
      },
      '20': {
        loc: {
          start: {
            line: 278,
            column: 6
          },
          end: {
            line: 280,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 278,
            column: 6
          },
          end: {
            line: 280,
            column: 7
          }
        }, {
          start: {
            line: 278,
            column: 6
          },
          end: {
            line: 280,
            column: 7
          }
        }],
        line: 278
      },
      '21': {
        loc: {
          start: {
            line: 287,
            column: 52
          },
          end: {
            line: 287,
            column: 140
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 287,
            column: 106
          },
          end: {
            line: 287,
            column: 110
          }
        }, {
          start: {
            line: 287,
            column: 113
          },
          end: {
            line: 287,
            column: 140
          }
        }],
        line: 287
      },
      '22': {
        loc: {
          start: {
            line: 292,
            column: 6
          },
          end: {
            line: 298,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 292,
            column: 6
          },
          end: {
            line: 298,
            column: 7
          }
        }, {
          start: {
            line: 292,
            column: 6
          },
          end: {
            line: 298,
            column: 7
          }
        }],
        line: 292
      },
      '23': {
        loc: {
          start: {
            line: 316,
            column: 2
          },
          end: {
            line: 320,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 316,
            column: 2
          },
          end: {
            line: 320,
            column: 3
          }
        }, {
          start: {
            line: 316,
            column: 2
          },
          end: {
            line: 320,
            column: 3
          }
        }],
        line: 316
      },
      '24': {
        loc: {
          start: {
            line: 317,
            column: 60
          },
          end: {
            line: 317,
            column: 97
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 317,
            column: 60
          },
          end: {
            line: 317,
            column: 92
          }
        }, {
          start: {
            line: 317,
            column: 96
          },
          end: {
            line: 317,
            column: 97
          }
        }],
        line: 317
      },
      '25': {
        loc: {
          start: {
            line: 323,
            column: 4
          },
          end: {
            line: 325,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 323,
            column: 4
          },
          end: {
            line: 325,
            column: 5
          }
        }, {
          start: {
            line: 323,
            column: 4
          },
          end: {
            line: 325,
            column: 5
          }
        }],
        line: 323
      },
      '26': {
        loc: {
          start: {
            line: 379,
            column: 4
          },
          end: {
            line: 381,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 379,
            column: 4
          },
          end: {
            line: 381,
            column: 5
          }
        }, {
          start: {
            line: 379,
            column: 4
          },
          end: {
            line: 381,
            column: 5
          }
        }],
        line: 379
      },
      '27': {
        loc: {
          start: {
            line: 382,
            column: 4
          },
          end: {
            line: 387,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 382,
            column: 4
          },
          end: {
            line: 387,
            column: 5
          }
        }, {
          start: {
            line: 382,
            column: 4
          },
          end: {
            line: 387,
            column: 5
          }
        }],
        line: 382
      },
      '28': {
        loc: {
          start: {
            line: 384,
            column: 6
          },
          end: {
            line: 386,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 384,
            column: 6
          },
          end: {
            line: 386,
            column: 7
          }
        }, {
          start: {
            line: 384,
            column: 6
          },
          end: {
            line: 386,
            column: 7
          }
        }],
        line: 384
      },
      '29': {
        loc: {
          start: {
            line: 393,
            column: 6
          },
          end: {
            line: 395,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 393,
            column: 6
          },
          end: {
            line: 395,
            column: 7
          }
        }, {
          start: {
            line: 393,
            column: 6
          },
          end: {
            line: 395,
            column: 7
          }
        }],
        line: 393
      },
      '30': {
        loc: {
          start: {
            line: 403,
            column: 4
          },
          end: {
            line: 405,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 403,
            column: 4
          },
          end: {
            line: 405,
            column: 5
          }
        }, {
          start: {
            line: 403,
            column: 4
          },
          end: {
            line: 405,
            column: 5
          }
        }],
        line: 403
      },
      '31': {
        loc: {
          start: {
            line: 406,
            column: 4
          },
          end: {
            line: 408,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 406,
            column: 4
          },
          end: {
            line: 408,
            column: 5
          }
        }, {
          start: {
            line: 406,
            column: 4
          },
          end: {
            line: 408,
            column: 5
          }
        }],
        line: 406
      },
      '32': {
        loc: {
          start: {
            line: 421,
            column: 6
          },
          end: {
            line: 423,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 421,
            column: 6
          },
          end: {
            line: 423,
            column: 7
          }
        }, {
          start: {
            line: 421,
            column: 6
          },
          end: {
            line: 423,
            column: 7
          }
        }],
        line: 421
      },
      '33': {
        loc: {
          start: {
            line: 426,
            column: 8
          },
          end: {
            line: 429,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 426,
            column: 8
          },
          end: {
            line: 429,
            column: 9
          }
        }, {
          start: {
            line: 426,
            column: 8
          },
          end: {
            line: 429,
            column: 9
          }
        }],
        line: 426
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2iyzn53747.s[0]++;
angular.module('app').controller('documentEditCtrl', function ($scope, $state, $stateParams, $log, SweetAlert, $rootScope, $translate, $deltahttp, $deltaDocumentType, $deltadate, $CRUDService, $filter, FileUploader, $uibModal) {
  cov_2iyzn53747.f[0]++;

  var PATH_DOC = (cov_2iyzn53747.s[1]++, 'Documents');
  var PATH_THEME = (cov_2iyzn53747.s[2]++, 'DocType');
  cov_2iyzn53747.s[3]++;
  $scope.params = {
    tab_document: 1,
    checkedAll: false,
    formatList: [{
      id: 'docx',
      text: $translate.instant('DOCUMENT.FORMATS.TEXT')
    },
    /* {
      id: 'jpeg',
      text: $translate.instant('DOCUMENT.FORMATS.IMAGE')
    },
    {
      id: 'mp3',
      text: $translate.instant('DOCUMENT.FORMATS.AUDIO')
    },
    {
      id: 'mp4',
      text: $translate.instant('DOCUMENT.FORMATS.VIDEO')
    }, */
    {
      id: 'pdf',
      text: $translate.instant('DOCUMENT.FORMATS.PDF')
    }, {
      id: 'xlsx',
      text: $translate.instant('FORMULAR.FIELD.EXCEL')
    }, {
      id: 'pptx',
      text: $translate.instant('FORMULAR.FIELD.PPTX')
    }]
  };
  cov_2iyzn53747.s[4]++;
  $scope.refreshimg = new Date().getTime();
  cov_2iyzn53747.s[5]++;
  $rootScope.fullScreenPanel('document', true);
  cov_2iyzn53747.s[6]++;
  $scope.myParamsState = $stateParams;
  cov_2iyzn53747.s[7]++;
  $CRUDService.save(PATH_DOC, { action: 'suppcoverimg' });
  cov_2iyzn53747.s[8]++;
  $scope.otherInit = function () {
    cov_2iyzn53747.f[1]++;

    var themes = (cov_2iyzn53747.s[9]++, []);
    var types = (cov_2iyzn53747.s[10]++, []);
    cov_2iyzn53747.s[11]++;
    if (angular.isArray($scope.selectedItem.IDTHEME)) {
      cov_2iyzn53747.b[0][0]++;
      cov_2iyzn53747.s[12]++;

      themes = $scope.selectedItem.IDTHEME.map(function (value) {
        cov_2iyzn53747.f[2]++;
        cov_2iyzn53747.s[13]++;

        return value.id;
      });
    } else {
      cov_2iyzn53747.b[0][1]++;
    }

    cov_2iyzn53747.s[14]++;
    if (angular.isArray($scope.selectedItem.IDTHEME)) {
      cov_2iyzn53747.b[1][0]++;
      cov_2iyzn53747.s[15]++;

      types = $scope.selectedItem.IDTYPEDOC.map(function (value) {
        cov_2iyzn53747.f[3]++;
        cov_2iyzn53747.s[16]++;

        return value.id;
      });
    } else {
      cov_2iyzn53747.b[1][1]++;
    }

    cov_2iyzn53747.s[17]++;
    $scope.selectedItem.IDTHEME = [];
    cov_2iyzn53747.s[18]++;
    $scope.selectedItem.IDTYPEDOC = [];
    cov_2iyzn53747.s[19]++;
    $scope.updateListTheme();

    cov_2iyzn53747.s[20]++;
    $scope.selectedItem.IDTHEME = $scope.themeList.filter(function (value) {
      cov_2iyzn53747.f[4]++;
      cov_2iyzn53747.s[21]++;

      return themes.indexOf(value.id) >= 0;
    });

    cov_2iyzn53747.s[22]++;
    $scope.selectedItem.IDTYPEDOC = $scope.typeDocList.filter(function (value) {
      cov_2iyzn53747.f[5]++;
      cov_2iyzn53747.s[23]++;

      return types.indexOf(value.id) >= 0;
    });

    cov_2iyzn53747.s[24]++;
    $scope.otherIndex = null;
  };

  cov_2iyzn53747.s[25]++;
  $scope.autoCompleteGroupOptions = null;
  cov_2iyzn53747.s[26]++;
  $CRUDService.getAll(PATH_DOC, { get: 'language' }, function (_data) {
    cov_2iyzn53747.f[6]++;
    cov_2iyzn53747.s[27]++;

    $scope.autoCompleteGroupOptions = {
      minimumChars: 0,
      containerCssClass: 'white-bg',
      selectedCssClass: 'selected-item-auto-complete',
      noMatchTemplateEnabled: false,
      activateOnFocus: true,
      data: function data(searchText) {
        cov_2iyzn53747.s[28]++;
        // const data = $filter('filter')($scope.listActors, {CATEGORIE_ACTEUR: searchText});
        return _data;
      }
    };
  });

  cov_2iyzn53747.s[29]++;
  $scope.openEditOther = function (type) {
    cov_2iyzn53747.f[7]++;
    cov_2iyzn53747.s[30]++;

    $scope.addOther = true;
    cov_2iyzn53747.s[31]++;
    $scope.otherIndex = type;
    cov_2iyzn53747.s[32]++;
    $uibModal.open({
      templateUrl: 'app/views/document/viewOther.html',
      controller: OtherCtrl_edit,
      scope: $scope,
      size: 'sm_',
      windowClass: 'animated fadeInRight left-modal',
      backdrop: 'static',
      keyboard: false
    });
  };

  cov_2iyzn53747.s[33]++;
  $scope.selectOther = function (type) {
    cov_2iyzn53747.f[8]++;
    cov_2iyzn53747.s[34]++;

    $scope.addOther = true;
    cov_2iyzn53747.s[35]++;
    $scope.otherIndex = type;
    cov_2iyzn53747.s[36]++;
    $uibModal.open({
      templateUrl: 'app/views/document/viewOther.html',
      controller: OtherCtrl_edit,
      scope: $scope,
      size: 'sm_',
      windowClass: 'animated fadeInRight left-modal',
      backdrop: 'static',
      keyboard: false
    });
  };

  cov_2iyzn53747.s[37]++;
  $scope.userTableParams = {
    allowPaging: false,
    columns: [{ field: 'id', headerText: '', isPrimaryKey: true, visible: false }, { headerText: '', template: '<div><img src="' + $scope.userpicture + '${id}.jpg" class="img img-sm img-circle" /></div>', width: 60 }, { field: 'NOMUSER', template: '${NOMUSER} ${PRENOMUSER}', headerText: $translate.instant('DOCUMENT.USERS') }, { type: 'checkbox', width: 60 }],
    selectItemChange: function selectItemChange(data) {
      cov_2iyzn53747.s[38]++;

      $scope.listActeur = data;
    }
  };

  cov_2iyzn53747.s[39]++;
  $scope.updateListTheme = function () {
    cov_2iyzn53747.f[9]++;
    cov_2iyzn53747.s[40]++;

    $scope.themeList = [].concat($scope.listData_typeDoc_bksb.filter(function (type) {
      cov_2iyzn53747.f[10]++;
      cov_2iyzn53747.s[41]++;

      return type.TYPE_CAT == 2;
    }));
    cov_2iyzn53747.s[42]++;
    $scope.typeDocList = [].concat($scope.listData_typeDoc_bksb.filter(function (type) {
      cov_2iyzn53747.f[11]++;
      cov_2iyzn53747.s[43]++;

      return type.TYPE_CAT == 1;
    }));
  };
  cov_2iyzn53747.s[44]++;
  $deltaDocumentType.addController($scope, function () {
    cov_2iyzn53747.f[12]++;
    cov_2iyzn53747.s[45]++;

    $scope.updateListTheme();
  });

  cov_2iyzn53747.s[46]++;
  $scope.checkAllUser = function () {
    cov_2iyzn53747.f[13]++;
    cov_2iyzn53747.s[47]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $scope.users[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var act = _step.value;
        cov_2iyzn53747.s[48]++;

        if (act.id > 0) {
          cov_2iyzn53747.b[2][0]++;
          cov_2iyzn53747.s[49]++;

          $scope.listActeur[act.id] = $scope.params.checkedAll;
        } else {
          cov_2iyzn53747.b[2][1]++;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  };
  cov_2iyzn53747.s[50]++;
  $scope.insertDeltaElement = function () {
    cov_2iyzn53747.f[14]++;

    var params = (cov_2iyzn53747.s[51]++, angular.copy($scope.myParamsState));
    cov_2iyzn53747.s[52]++;
    params.params.pile = [{
      origin: $state.current.name,
      item: $scope.selectedItem
    }];

    cov_2iyzn53747.s[53]++;
    $rootScope.setAddToRapport(true, params);

    cov_2iyzn53747.s[54]++;
    $state.go('activity.planning.main');
  };

  cov_2iyzn53747.s[55]++;
  $scope.addnewdocument = function (id_, TYPE_RAPPORT) {
    cov_2iyzn53747.f[15]++;
    cov_2iyzn53747.s[56]++;

    $scope.editDocument({
      IDRAPPORTS: 0,
      Resume: '',
      INTITULE_RAPPORT: '',
      TYPE_RAPPORT: TYPE_RAPPORT,
      CORPS: '',
      PORTFOLIO: 0,
      DATE_RAPPORT: Date.newDate(),
      CHEMIN_RAPP: '',
      Reference: '',
      Mon_Acteur: '',
      IDRANGEMENT: 0,
      IDTHEME: '', // $scope.themeList[0].id,
      IDTYPEDOC: '', // $scope.typeDocList[0].id,
      IDCLASSEURS: id_,
      PUBLIC: false,
      IDACTEURS: 0,
      imageUrl: $deltahttp.getDocumentCoverRepository(0),
      FORMAT_DOC: $scope.params.formatList[0].id
    });
  };

  cov_2iyzn53747.s[57]++;
  $scope.editDocument = function (item) {
    cov_2iyzn53747.f[16]++;
    cov_2iyzn53747.s[58]++;

    $scope.params.tab_document = 1;
    cov_2iyzn53747.s[59]++;
    $scope.error = [];
    cov_2iyzn53747.s[60]++;
    $scope.listActeur = {};
    cov_2iyzn53747.s[61]++;
    if (item.id) {
      cov_2iyzn53747.b[3][0]++;

      var list = (cov_2iyzn53747.s[62]++, ((cov_2iyzn53747.b[4][0]++, item.LIST_ACTEURS) || (cov_2iyzn53747.b[4][1]++, '')).split(';'));
      cov_2iyzn53747.s[63]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = list[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var el = _step2.value;
          cov_2iyzn53747.s[64]++;

          $scope.listActeur[el.trim().replace('_0', '').replace('_1', '')] = true;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      cov_2iyzn53747.s[65]++;
      $scope.idlistActeur = item.id;
      cov_2iyzn53747.s[66]++;
      item.imageUrl = $deltahttp.getDocumentCoverRepository(item.id);
    } else {
      cov_2iyzn53747.b[3][1]++;
    }

    cov_2iyzn53747.s[67]++;
    $scope.selectedItem = angular.copy(item);
    cov_2iyzn53747.s[68]++;
    $scope.selectedItem.PORTFOLIO = $scope.selectedItem.PORTFOLIO == 1;

    cov_2iyzn53747.s[69]++;
    $scope.selectedItem.IDTHEME = $scope.themeList.filter(function (value) {
      cov_2iyzn53747.f[17]++;
      cov_2iyzn53747.s[70]++;

      return ((cov_2iyzn53747.b[5][0]++, item.IDTHEME) || (cov_2iyzn53747.b[5][1]++, '')).includes(';' + value.id + ';');

      // return value.id == item.IDTHEME;
    });

    cov_2iyzn53747.s[71]++;
    $scope.selectedItem.IDTYPEDOC = $scope.typeDocList.filter(function (value) {
      cov_2iyzn53747.f[18]++;
      cov_2iyzn53747.s[72]++;

      return ((cov_2iyzn53747.b[6][0]++, item.IDTYPEDOC) || (cov_2iyzn53747.b[6][1]++, '')).includes(';' + value.id + ';');
      // return value.id == item.IDTYPEDOC;
    });

    cov_2iyzn53747.s[73]++;
    $scope.selectedItem.FORMAT_DOC = (cov_2iyzn53747.b[7][0]++, $scope.params.formatList.find(function (value) {
      cov_2iyzn53747.f[19]++;
      cov_2iyzn53747.s[74]++;

      return value.id == item.FORMAT_DOC;
    })) || (cov_2iyzn53747.b[7][1]++, $scope.params.formatList[0]);
    // $scope.selectedItem.PORTFOLIO = ($scope.selectedItem.PORTFOLIO == 1);
  };

  cov_2iyzn53747.s[75]++;
  $scope.errorValidateRef = false;
  cov_2iyzn53747.s[76]++;
  $scope.validate = function () {
    cov_2iyzn53747.f[20]++;
    cov_2iyzn53747.s[77]++;

    $scope.error = [];
    cov_2iyzn53747.s[78]++;
    $scope.errorValidateRef = false;
    cov_2iyzn53747.s[79]++;
    for (var i = 0; i < 5; i++) {
      cov_2iyzn53747.s[80]++;

      $scope.error.push({
        d: false,
        l: $translate.instant('COMMON.ERROR.NONE_DATA')
      });
    }
    var valeurData = (cov_2iyzn53747.s[81]++, angular.copy($scope.selectedItem));
    // Doublons de référence
    cov_2iyzn53747.s[82]++;
    if (valeurData.IDTYPEDOC.length === 0) {
      cov_2iyzn53747.b[8][0]++;
      cov_2iyzn53747.s[83]++;

      $scope.error[2].d = true;
    } else {
      cov_2iyzn53747.b[8][1]++;
    }
    cov_2iyzn53747.s[84]++;
    if (valeurData.IDTHEME.length === 0) {
      cov_2iyzn53747.b[9][0]++;
      cov_2iyzn53747.s[85]++;

      $scope.error[3].d = true;
    } else {
      cov_2iyzn53747.b[9][1]++;
    }

    cov_2iyzn53747.s[86]++;
    if ((cov_2iyzn53747.b[11][0]++, valeurData.CONTACT !== '') && (cov_2iyzn53747.b[11][1]++, valeurData.CONTACT != null)) {
      cov_2iyzn53747.b[10][0]++;

      var __ = (cov_2iyzn53747.s[87]++, validate({ website: valeurData.CONTACT, email: valeurData.CONTACT }, { website: { url: true }, email: { email: true } }));
      cov_2iyzn53747.s[88]++;
      if ((cov_2iyzn53747.b[13][0]++, __.email) && (cov_2iyzn53747.b[13][1]++, __.website)) {
        cov_2iyzn53747.b[12][0]++;
        cov_2iyzn53747.s[89]++;

        $scope.error[4].d = true;
      } else {
        cov_2iyzn53747.b[12][1]++;
      }
    } else {
      cov_2iyzn53747.b[10][1]++;
    }
    cov_2iyzn53747.s[90]++;
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = $scope.error[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var el = _step3.value;
        cov_2iyzn53747.s[91]++;

        if (el.d) {
          cov_2iyzn53747.b[14][0]++;
          cov_2iyzn53747.s[92]++;

          return;
        } else {
          cov_2iyzn53747.b[14][1]++;
        }
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    cov_2iyzn53747.s[93]++;
    [].join(';');
    cov_2iyzn53747.s[94]++;
    valeurData.DATE_RAPPORT = $deltadate.format(valeurData.DATE_RAPPORT, 'yyyy-mm-dd');
    cov_2iyzn53747.s[95]++;
    valeurData.IDTYPEDOC = '0;' + valeurData.IDTYPEDOC.map(function (value) {
      cov_2iyzn53747.f[21]++;
      cov_2iyzn53747.s[96]++;

      return value.id;
    }).join(';') + ';0';
    cov_2iyzn53747.s[97]++;
    valeurData.IDTHEME = '0;' + valeurData.IDTHEME.map(function (value) {
      cov_2iyzn53747.f[22]++;
      cov_2iyzn53747.s[98]++;

      return value.id;
    }).join(';') + ';0';
    cov_2iyzn53747.s[99]++;
    valeurData.FORMAT_DOC = valeurData.FORMAT_DOC.id.toString().trim();
    cov_2iyzn53747.s[100]++;
    valeurData.IDRANGEMENT = 0;
    cov_2iyzn53747.s[101]++;
    valeurData.IDACTEURS = 0;
    cov_2iyzn53747.s[102]++;
    valeurData.PUBLIC = valeurData.PUBLIC ? (cov_2iyzn53747.b[15][0]++, 1) : (cov_2iyzn53747.b[15][1]++, 0);
    cov_2iyzn53747.s[103]++;
    valeurData.PORTFOLIO = valeurData.PORTFOLIO ? (cov_2iyzn53747.b[16][0]++, 1) : (cov_2iyzn53747.b[16][1]++, 0);
    var list = (cov_2iyzn53747.s[104]++, ';');
    cov_2iyzn53747.s[105]++;
    var _iteratorNormalCompletion4 = true;
    var _didIteratorError4 = false;
    var _iteratorError4 = undefined;

    try {
      for (var _iterator4 = $scope.users[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
        var eli = _step4.value;
        cov_2iyzn53747.s[106]++;

        list += $scope.listActeur[eli.id] ? (cov_2iyzn53747.b[17][0]++, eli.id + ';') : (cov_2iyzn53747.b[17][1]++, '');
      }
    } catch (err) {
      _didIteratorError4 = true;
      _iteratorError4 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion4 && _iterator4.return) {
          _iterator4.return();
        }
      } finally {
        if (_didIteratorError4) {
          throw _iteratorError4;
        }
      }
    }

    cov_2iyzn53747.s[107]++;
    valeurData.LIST_ACTEURS = list;
    var corps = (cov_2iyzn53747.s[108]++, angular.copy(valeurData.CORPS));
    cov_2iyzn53747.s[109]++;
    valeurData.CORPS = undefined;
    cov_2iyzn53747.s[110]++;
    $scope.isloading = true;
    cov_2iyzn53747.s[111]++;
    $CRUDService.save(PATH_DOC, { action: 'MiseAJour', type: 0, valeur: $filter('json')(valeurData), corps: corps, saveRapport: $scope.myParamsState.params.file }, function (data) {
      cov_2iyzn53747.f[23]++;
      cov_2iyzn53747.s[112]++;

      $scope.isloading = false;
      cov_2iyzn53747.s[113]++;
      if (data < 1) {
        cov_2iyzn53747.b[18][0]++;
        cov_2iyzn53747.s[114]++;

        if (data == -2) {
          cov_2iyzn53747.b[19][0]++;
          cov_2iyzn53747.s[115]++;

          $scope.errorValidateRef = true;
        } else {
          cov_2iyzn53747.b[19][1]++;
        }
        cov_2iyzn53747.s[116]++;
        return;
      } else {
        cov_2iyzn53747.b[18][1]++;
      }
      cov_2iyzn53747.s[117]++;
      $scope.uploadeFileDirect(valeurData.Reference);
      cov_2iyzn53747.s[118]++;
      $scope.myParamsState.params.id = data;
      cov_2iyzn53747.s[119]++;
      if ($scope.myParamsState.params.item) {
        cov_2iyzn53747.b[20][0]++;
        cov_2iyzn53747.s[120]++;

        $scope.myParamsState.params.item.IDRAPPORTS = parseInt(data, 10);
      } else {
        cov_2iyzn53747.b[20][1]++;
      }
      cov_2iyzn53747.s[121]++;
      $scope.cancelDocument();
    });
  };

  cov_2iyzn53747.s[122]++;
  $scope.cancelDocument = function () {
    cov_2iyzn53747.f[24]++;
    cov_2iyzn53747.s[123]++;

    $rootScope.fullScreenPanel('document', false);
    cov_2iyzn53747.s[124]++;
    $state.go($scope.myParamsState.origin, { params: $scope.myParamsState.origin === 'document.document' ? (cov_2iyzn53747.b[21][0]++, null) : (cov_2iyzn53747.b[21][1]++, $scope.myParamsState.params) });
  };

  cov_2iyzn53747.s[125]++;
  $scope.edit = function (item) {
    var _this = this;

    cov_2iyzn53747.f[25]++;
    cov_2iyzn53747.s[126]++;

    $CRUDService.getAll(PATH_DOC, { get: 'single', id: item }, function (data) {
      cov_2iyzn53747.f[26]++;
      cov_2iyzn53747.s[127]++;

      if (data) {
        cov_2iyzn53747.b[22][0]++;

        var value = (cov_2iyzn53747.s[128]++, data);
        cov_2iyzn53747.s[129]++;
        value.PUBLIC = value.PUBLIC == 1;
        cov_2iyzn53747.s[130]++;
        value.DATE_RAPPORT = Date.newDate(data.DATE_RAPPORT);
        cov_2iyzn53747.s[131]++;
        _this.editDocument(value);
        cov_2iyzn53747.s[132]++;
        return;
      } else {
        cov_2iyzn53747.b[22][1]++;
      }
      cov_2iyzn53747.s[133]++;
      $scope.cancelDocument();
    });
  };

  cov_2iyzn53747.s[134]++;
  $scope.getUser = function () {
    cov_2iyzn53747.f[27]++;
    cov_2iyzn53747.s[135]++;

    $CRUDService.getAll('User', { get: 'NonAdmin' }, function (data) {
      cov_2iyzn53747.f[28]++;
      cov_2iyzn53747.s[136]++;

      $scope.users = data.filter(function (user) {
        cov_2iyzn53747.f[29]++;
        cov_2iyzn53747.s[137]++;

        return user.id != $rootScope.connectedUser.id;
      }).map(function (user) {
        cov_2iyzn53747.f[30]++;
        cov_2iyzn53747.s[138]++;

        user.imageUrl = $deltahttp.getUserPicture(user.id);
        cov_2iyzn53747.s[139]++;
        user.checkImage = true;
        cov_2iyzn53747.s[140]++;
        return user;
      });
    });
  };
  cov_2iyzn53747.s[141]++;
  $scope.getUser();

  cov_2iyzn53747.s[142]++;
  if ($scope.myParamsState.id == 0) {
    cov_2iyzn53747.b[23][0]++;
    cov_2iyzn53747.s[143]++;

    $scope.addnewdocument($scope.myParamsState.params.idcl, (cov_2iyzn53747.b[24][0]++, $scope.myParamsState.params.type) || (cov_2iyzn53747.b[24][1]++, 0));
  } else {
    cov_2iyzn53747.b[23][1]++;
    cov_2iyzn53747.s[144]++;

    $scope.edit($scope.myParamsState.id);
  }

  cov_2iyzn53747.s[145]++;
  $scope.uploadeFileDirect = function (Reference) {
    cov_2iyzn53747.f[31]++;
    cov_2iyzn53747.s[146]++;

    if (!$scope.file) {
      cov_2iyzn53747.b[25][0]++;
      cov_2iyzn53747.s[147]++;

      return;
    } else {
      cov_2iyzn53747.b[25][1]++;
    }
    cov_2iyzn53747.s[148]++;
    $scope.file.url += '&saveRapport=' + Reference;
    cov_2iyzn53747.s[149]++;
    $scope.file.upload();
  };
  var uploader = (cov_2iyzn53747.s[150]++, $scope.uploader = new FileUploader({
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadFile&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + PATH_DOC,
    headers: $CRUDService.getHeader(),
    alias: 'CHEMIN_RAPP',
    removeAfterUpload: true
  }));
  cov_2iyzn53747.s[151]++;
  uploader.onAfterAddingFile = function (fileItem) {
    cov_2iyzn53747.f[32]++;
    cov_2iyzn53747.s[152]++;

    $scope.selectedItem.CHEMIN_RAPP = fileItem.file.name;
    cov_2iyzn53747.s[153]++;
    fileItem.url = uploader.url;
    cov_2iyzn53747.s[154]++;
    $scope.file = fileItem;
  };

  // uploadfile
  var uploaderCover = (cov_2iyzn53747.s[155]++, $scope.uploaderCover = new FileUploader({
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadCoverFile&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + PATH_DOC,
    alias: 'fileImage',
    headers: $CRUDService.getHeader(),
    removeAfterUpload: true
  }));
  cov_2iyzn53747.s[156]++;
  uploaderCover.onAfterAddingFile = function (fileItem) {
    cov_2iyzn53747.f[33]++;
    cov_2iyzn53747.s[157]++;

    $scope.uploaderCover = true;
    cov_2iyzn53747.s[158]++;
    fileItem.upload();
  };

  cov_2iyzn53747.s[159]++;
  uploaderCover.onSuccessItem = function (item, response, status, headers) {
    cov_2iyzn53747.f[34]++;
    cov_2iyzn53747.s[160]++;

    $scope.selectedItem.tempImg = $deltahttp.getDocumentCoverRepository(response.f);
    cov_2iyzn53747.s[161]++;
    $scope.refreshimg = new Date().getTime();
    cov_2iyzn53747.s[162]++;
    $scope.uploaderCover = false;
  };
  cov_2iyzn53747.s[163]++;
  uploaderCover.onErrorItem = function (item, response, status, headers) {
    cov_2iyzn53747.f[35]++;
    cov_2iyzn53747.s[164]++;

    $scope.loadingUpload = false;
  };
});

function OtherCtrl_edit($scope, $uibModalInstance, $filter, $deltaDocumentType, $CRUDService, $translate, SweetAlert) {
  cov_2iyzn53747.f[36]++;

  var PATH_THEME = (cov_2iyzn53747.s[165]++, 'DocType');

  cov_2iyzn53747.s[166]++;
  $scope.refreshList = function () {
    cov_2iyzn53747.f[37]++;
    cov_2iyzn53747.s[167]++;

    $scope.listAllThPhDoc = $scope.listData_typeDoc_bksb.filter(function (type) {
      cov_2iyzn53747.f[38]++;
      cov_2iyzn53747.s[168]++;

      return type.TYPE_CAT == $scope.otherIndex;
    });
  };

  cov_2iyzn53747.s[169]++;
  $scope.editOther = function (item) {
    cov_2iyzn53747.f[39]++;
    cov_2iyzn53747.s[170]++;

    $scope.selectedItemOther = angular.copy(item);
  };
  cov_2iyzn53747.s[171]++;
  $scope.cancelOther = function (action) {
    cov_2iyzn53747.f[40]++;
    cov_2iyzn53747.s[172]++;

    $scope.selectedItemOther = null;
  };
  cov_2iyzn53747.s[173]++;
  $scope.validateOther = function () {
    cov_2iyzn53747.f[41]++;
    cov_2iyzn53747.s[174]++;

    if (!$scope.selectedItemOther) {
      cov_2iyzn53747.b[26][0]++;
      cov_2iyzn53747.s[175]++;

      return;
    } else {
      cov_2iyzn53747.b[26][1]++;
    }
    cov_2iyzn53747.s[176]++;
    if ($scope.selectedItemOther.id < 1) {
      cov_2iyzn53747.b[27][0]++;

      var listDoublons = (cov_2iyzn53747.s[177]++, $filter('filter')($scope.listAllThPhDoc, { TYPE_CAT: $scope.selectedItemOther.TYPE_CAT, CODE_TYPEDOC: $scope.selectedItemOther.CODE_TYPEDOC }, true));
      cov_2iyzn53747.s[178]++;
      if (listDoublons[0]) {
        cov_2iyzn53747.b[28][0]++;
        cov_2iyzn53747.s[179]++;

        return;
      } else {
        cov_2iyzn53747.b[28][1]++;
      }
    } else {
      cov_2iyzn53747.b[27][1]++;
    }
    cov_2iyzn53747.s[180]++;
    $scope.isloading_other = true;
    var validateItem = (cov_2iyzn53747.s[181]++, angular.copy($scope.selectedItemOther));
    var isOld = (cov_2iyzn53747.s[182]++, validateItem.id > 0);
    cov_2iyzn53747.s[183]++;
    $CRUDService.save(PATH_THEME, { action: 'miseAJour', valeur: $filter('json')(validateItem) }, function (data) {
      cov_2iyzn53747.f[42]++;
      cov_2iyzn53747.s[184]++;

      $scope.isloading_other = false;
      cov_2iyzn53747.s[185]++;
      if (validateItem.id < 1) {
        cov_2iyzn53747.b[29][0]++;
        cov_2iyzn53747.s[186]++;

        validateItem.id = parseInt(data, 10);
      } else {
        cov_2iyzn53747.b[29][1]++;
      }
      cov_2iyzn53747.s[187]++;
      $deltaDocumentType.addData(validateItem, isOld);
      cov_2iyzn53747.s[188]++;
      $scope.refreshList();
      cov_2iyzn53747.s[189]++;
      $scope.selectedItemOther = null;
    });
  };
  cov_2iyzn53747.s[190]++;
  $scope.deleteOther = function (item) {
    cov_2iyzn53747.f[43]++;

    var textDelete = (cov_2iyzn53747.s[191]++, 'DOCUMENT.CONFIRM_DELETE_THEME');
    cov_2iyzn53747.s[192]++;
    if (item.TYPE_CAT == 1) {
      cov_2iyzn53747.b[30][0]++;
      cov_2iyzn53747.s[193]++;

      textDelete = 'DOCUMENT.CONFIRM_DELETE_TYPE';
    } else {
      cov_2iyzn53747.b[30][1]++;
    }
    cov_2iyzn53747.s[194]++;
    if (item.TYPE_CAT == 2) {
      cov_2iyzn53747.b[31][0]++;
      cov_2iyzn53747.s[195]++;

      textDelete = 'DOCUMENT.CONFIRM_DELETE_PHYS';
    } else {
      cov_2iyzn53747.b[31][1]++;
    }
    cov_2iyzn53747.s[196]++;
    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant(textDelete),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_2iyzn53747.f[44]++;
      cov_2iyzn53747.s[197]++;

      if (isconfirm) {
        cov_2iyzn53747.b[32][0]++;
        cov_2iyzn53747.s[198]++;

        return;
      } else {
        cov_2iyzn53747.b[32][1]++;
      }
      cov_2iyzn53747.s[199]++;
      $scope.isloading = true;
      cov_2iyzn53747.s[200]++;
      $CRUDService.delet(PATH_THEME, { action: 'supp', valeur: $filter('json')(item) }, function (data) {
        cov_2iyzn53747.f[45]++;
        cov_2iyzn53747.s[201]++;

        if (data < 1) {
          cov_2iyzn53747.b[33][0]++;
          cov_2iyzn53747.s[202]++;

          $scope.isloading = false;
          cov_2iyzn53747.s[203]++;
          return;
        } else {
          cov_2iyzn53747.b[33][1]++;
        }
        cov_2iyzn53747.s[204]++;
        $deltaDocumentType.removeData(item);
        cov_2iyzn53747.s[205]++;
        $scope.refreshList();
      });
    });
  };
  cov_2iyzn53747.s[206]++;
  $scope.addOther = function () {
    cov_2iyzn53747.f[46]++;
    cov_2iyzn53747.s[207]++;

    $scope.editOther({
      CODE_TYPEDOC: Date.newDate().getTime(),
      LIBELLE_TYPEDOC: '',
      TYPE_CAT: $scope.otherIndex,
      id: 0
    });
  };
  cov_2iyzn53747.s[208]++;
  $scope.closeModal = function () {
    cov_2iyzn53747.f[47]++;
    cov_2iyzn53747.s[209]++;

    $scope.otherInit();
    cov_2iyzn53747.s[210]++;
    $uibModalInstance.dismiss('cancel');
  };
  cov_2iyzn53747.s[211]++;
  $scope.refreshList();
}