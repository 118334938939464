'use strict';

var cov_1h8ohu2r1y = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/planning_division/planning_division.js',
      hash = 'fc6b9f19a85cbe10ca0c9b251de0a116abd7234d',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/planning_division/planning_division.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 155,
          column: 3
        }
      },
      '1': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 38
        }
      },
      '2': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 8,
          column: 3
        }
      },
      '3': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 7,
          column: 11
        }
      },
      '4': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 62
        }
      },
      '5': {
        start: {
          line: 10,
          column: 15
        },
        end: {
          line: 10,
          column: 24
        }
      },
      '6': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 26
        }
      },
      '7': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 24
        }
      },
      '8': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 27
        }
      },
      '9': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 28
        }
      },
      '10': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 24
        }
      },
      '11': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 16,
          column: 22
        }
      },
      '12': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 17,
          column: 25
        }
      },
      '13': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 18,
          column: 29
        }
      },
      '14': {
        start: {
          line: 20,
          column: 2
        },
        end: {
          line: 25,
          column: 4
        }
      },
      '15': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 36,
          column: 5
        }
      },
      '16': {
        start: {
          line: 37,
          column: 2
        },
        end: {
          line: 42,
          column: 5
        }
      },
      '17': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 40,
          column: 5
        }
      },
      '18': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 39,
          column: 54
        }
      },
      '19': {
        start: {
          line: 41,
          column: 4
        },
        end: {
          line: 41,
          column: 29
        }
      },
      '20': {
        start: {
          line: 43,
          column: 2
        },
        end: {
          line: 71,
          column: 4
        }
      },
      '21': {
        start: {
          line: 44,
          column: 17
        },
        end: {
          line: 44,
          column: 69
        }
      },
      '22': {
        start: {
          line: 45,
          column: 4
        },
        end: {
          line: 45,
          column: 87
        }
      },
      '23': {
        start: {
          line: 46,
          column: 4
        },
        end: {
          line: 46,
          column: 85
        }
      },
      '24': {
        start: {
          line: 47,
          column: 4
        },
        end: {
          line: 47,
          column: 53
        }
      },
      '25': {
        start: {
          line: 48,
          column: 4
        },
        end: {
          line: 48,
          column: 53
        }
      },
      '26': {
        start: {
          line: 49,
          column: 4
        },
        end: {
          line: 49,
          column: 54
        }
      },
      '27': {
        start: {
          line: 50,
          column: 4
        },
        end: {
          line: 50,
          column: 49
        }
      },
      '28': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 51,
          column: 49
        }
      },
      '29': {
        start: {
          line: 53,
          column: 19
        },
        end: {
          line: 53,
          column: 48
        }
      },
      '30': {
        start: {
          line: 55,
          column: 4
        },
        end: {
          line: 55,
          column: 102
        }
      },
      '31': {
        start: {
          line: 58,
          column: 4
        },
        end: {
          line: 58,
          column: 49
        }
      },
      '32': {
        start: {
          line: 60,
          column: 4
        },
        end: {
          line: 60,
          column: 49
        }
      },
      '33': {
        start: {
          line: 61,
          column: 4
        },
        end: {
          line: 63,
          column: 5
        }
      },
      '34': {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 62,
          column: 38
        }
      },
      '35': {
        start: {
          line: 64,
          column: 4
        },
        end: {
          line: 64,
          column: 90
        }
      },
      '36': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 70,
          column: 7
        }
      },
      '37': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 69,
          column: 7
        }
      },
      '38': {
        start: {
          line: 68,
          column: 8
        },
        end: {
          line: 68,
          column: 36
        }
      },
      '39': {
        start: {
          line: 72,
          column: 2
        },
        end: {
          line: 86,
          column: 4
        }
      },
      '40': {
        start: {
          line: 73,
          column: 17
        },
        end: {
          line: 73,
          column: 29
        }
      },
      '41': {
        start: {
          line: 75,
          column: 4
        },
        end: {
          line: 75,
          column: 43
        }
      },
      '42': {
        start: {
          line: 76,
          column: 4
        },
        end: {
          line: 76,
          column: 45
        }
      },
      '43': {
        start: {
          line: 77,
          column: 4
        },
        end: {
          line: 77,
          column: 43
        }
      },
      '44': {
        start: {
          line: 79,
          column: 4
        },
        end: {
          line: 79,
          column: 43
        }
      },
      '45': {
        start: {
          line: 80,
          column: 4
        },
        end: {
          line: 80,
          column: 45
        }
      },
      '46': {
        start: {
          line: 81,
          column: 4
        },
        end: {
          line: 81,
          column: 91
        }
      },
      '47': {
        start: {
          line: 83,
          column: 4
        },
        end: {
          line: 83,
          column: 43
        }
      },
      '48': {
        start: {
          line: 84,
          column: 4
        },
        end: {
          line: 84,
          column: 91
        }
      },
      '49': {
        start: {
          line: 85,
          column: 4
        },
        end: {
          line: 85,
          column: 119
        }
      },
      '50': {
        start: {
          line: 87,
          column: 2
        },
        end: {
          line: 87,
          column: 33
        }
      },
      '51': {
        start: {
          line: 88,
          column: 2
        },
        end: {
          line: 93,
          column: 4
        }
      },
      '52': {
        start: {
          line: 94,
          column: 2
        },
        end: {
          line: 101,
          column: 4
        }
      },
      '53': {
        start: {
          line: 95,
          column: 4
        },
        end: {
          line: 100,
          column: 14
        }
      },
      '54': {
        start: {
          line: 102,
          column: 2
        },
        end: {
          line: 105,
          column: 4
        }
      },
      '55': {
        start: {
          line: 103,
          column: 4
        },
        end: {
          line: 103,
          column: 25
        }
      },
      '56': {
        start: {
          line: 104,
          column: 4
        },
        end: {
          line: 104,
          column: 46
        }
      },
      '57': {
        start: {
          line: 106,
          column: 2
        },
        end: {
          line: 109,
          column: 4
        }
      },
      '58': {
        start: {
          line: 107,
          column: 4
        },
        end: {
          line: 107,
          column: 32
        }
      },
      '59': {
        start: {
          line: 108,
          column: 4
        },
        end: {
          line: 108,
          column: 22
        }
      },
      '60': {
        start: {
          line: 110,
          column: 2
        },
        end: {
          line: 113,
          column: 4
        }
      },
      '61': {
        start: {
          line: 112,
          column: 4
        },
        end: {
          line: 112,
          column: 93
        }
      },
      '62': {
        start: {
          line: 115,
          column: 2
        },
        end: {
          line: 144,
          column: 4
        }
      },
      '63': {
        start: {
          line: 116,
          column: 4
        },
        end: {
          line: 116,
          column: 25
        }
      },
      '64': {
        start: {
          line: 117,
          column: 4
        },
        end: {
          line: 119,
          column: 5
        }
      },
      '65': {
        start: {
          line: 118,
          column: 6
        },
        end: {
          line: 118,
          column: 13
        }
      },
      '66': {
        start: {
          line: 120,
          column: 4
        },
        end: {
          line: 120,
          column: 59
        }
      },
      '67': {
        start: {
          line: 121,
          column: 4
        },
        end: {
          line: 123,
          column: 5
        }
      },
      '68': {
        start: {
          line: 122,
          column: 6
        },
        end: {
          line: 122,
          column: 13
        }
      },
      '69': {
        start: {
          line: 125,
          column: 4
        },
        end: {
          line: 130,
          column: 5
        }
      },
      '70': {
        start: {
          line: 126,
          column: 6
        },
        end: {
          line: 129,
          column: 7
        }
      },
      '71': {
        start: {
          line: 127,
          column: 8
        },
        end: {
          line: 127,
          column: 28
        }
      },
      '72': {
        start: {
          line: 128,
          column: 8
        },
        end: {
          line: 128,
          column: 15
        }
      },
      '73': {
        start: {
          line: 131,
          column: 4
        },
        end: {
          line: 142,
          column: 5
        }
      },
      '74': {
        start: {
          line: 132,
          column: 6
        },
        end: {
          line: 132,
          column: 94
        }
      },
      '75': {
        start: {
          line: 133,
          column: 6
        },
        end: {
          line: 133,
          column: 86
        }
      },
      '76': {
        start: {
          line: 135,
          column: 6
        },
        end: {
          line: 141,
          column: 7
        }
      },
      '77': {
        start: {
          line: 136,
          column: 8
        },
        end: {
          line: 140,
          column: 9
        }
      },
      '78': {
        start: {
          line: 137,
          column: 10
        },
        end: {
          line: 137,
          column: 40
        }
      },
      '79': {
        start: {
          line: 138,
          column: 10
        },
        end: {
          line: 138,
          column: 40
        }
      },
      '80': {
        start: {
          line: 139,
          column: 10
        },
        end: {
          line: 139,
          column: 40
        }
      },
      '81': {
        start: {
          line: 143,
          column: 4
        },
        end: {
          line: 143,
          column: 25
        }
      },
      '82': {
        start: {
          line: 146,
          column: 2
        },
        end: {
          line: 152,
          column: 4
        }
      },
      '83': {
        start: {
          line: 147,
          column: 13
        },
        end: {
          line: 147,
          column: 14
        }
      },
      '84': {
        start: {
          line: 148,
          column: 4
        },
        end: {
          line: 150,
          column: 5
        }
      },
      '85': {
        start: {
          line: 149,
          column: 6
        },
        end: {
          line: 149,
          column: 31
        }
      },
      '86': {
        start: {
          line: 151,
          column: 4
        },
        end: {
          line: 151,
          column: 18
        }
      },
      '87': {
        start: {
          line: 154,
          column: 2
        },
        end: {
          line: 154,
          column: 20
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 58
          },
          end: {
            line: 4,
            column: 59
          }
        },
        loc: {
          start: {
            line: 4,
            column: 177
          },
          end: {
            line: 155,
            column: 1
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 37,
            column: 42
          },
          end: {
            line: 37,
            column: 43
          }
        },
        loc: {
          start: {
            line: 37,
            column: 50
          },
          end: {
            line: 42,
            column: 3
          }
        },
        line: 37
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 43,
            column: 20
          },
          end: {
            line: 43,
            column: 21
          }
        },
        loc: {
          start: {
            line: 43,
            column: 32
          },
          end: {
            line: 71,
            column: 3
          }
        },
        line: 43
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 66,
            column: 48
          },
          end: {
            line: 66,
            column: 49
          }
        },
        loc: {
          start: {
            line: 66,
            column: 56
          },
          end: {
            line: 70,
            column: 5
          }
        },
        line: 66
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 72,
            column: 23
          },
          end: {
            line: 72,
            column: 24
          }
        },
        loc: {
          start: {
            line: 72,
            column: 35
          },
          end: {
            line: 86,
            column: 3
          }
        },
        line: 72
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 94,
            column: 20
          },
          end: {
            line: 94,
            column: 21
          }
        },
        loc: {
          start: {
            line: 94,
            column: 37
          },
          end: {
            line: 101,
            column: 3
          }
        },
        line: 94
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 102,
            column: 21
          },
          end: {
            line: 102,
            column: 22
          }
        },
        loc: {
          start: {
            line: 102,
            column: 44
          },
          end: {
            line: 105,
            column: 3
          }
        },
        line: 102
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 106,
            column: 23
          },
          end: {
            line: 106,
            column: 24
          }
        },
        loc: {
          start: {
            line: 106,
            column: 35
          },
          end: {
            line: 109,
            column: 3
          }
        },
        line: 106
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 110,
            column: 23
          },
          end: {
            line: 110,
            column: 24
          }
        },
        loc: {
          start: {
            line: 110,
            column: 46
          },
          end: {
            line: 113,
            column: 3
          }
        },
        line: 110
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 115,
            column: 25
          },
          end: {
            line: 115,
            column: 26
          }
        },
        loc: {
          start: {
            line: 115,
            column: 37
          },
          end: {
            line: 144,
            column: 3
          }
        },
        line: 115
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 146,
            column: 26
          },
          end: {
            line: 146,
            column: 27
          }
        },
        loc: {
          start: {
            line: 146,
            column: 44
          },
          end: {
            line: 152,
            column: 3
          }
        },
        line: 146
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }, {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }],
        line: 6
      },
      '1': {
        loc: {
          start: {
            line: 61,
            column: 4
          },
          end: {
            line: 63,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 61,
            column: 4
          },
          end: {
            line: 63,
            column: 5
          }
        }, {
          start: {
            line: 61,
            column: 4
          },
          end: {
            line: 63,
            column: 5
          }
        }],
        line: 61
      },
      '2': {
        loc: {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 69,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 69,
            column: 7
          }
        }, {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 69,
            column: 7
          }
        }],
        line: 67
      },
      '3': {
        loc: {
          start: {
            line: 81,
            column: 17
          },
          end: {
            line: 81,
            column: 90
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 81,
            column: 85
          },
          end: {
            line: 81,
            column: 86
          }
        }, {
          start: {
            line: 81,
            column: 89
          },
          end: {
            line: 81,
            column: 90
          }
        }],
        line: 81
      },
      '4': {
        loc: {
          start: {
            line: 81,
            column: 18
          },
          end: {
            line: 81,
            column: 81
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 81,
            column: 18
          },
          end: {
            line: 81,
            column: 42
          }
        }, {
          start: {
            line: 81,
            column: 46
          },
          end: {
            line: 81,
            column: 81
          }
        }],
        line: 81
      },
      '5': {
        loc: {
          start: {
            line: 84,
            column: 17
          },
          end: {
            line: 84,
            column: 90
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 84,
            column: 85
          },
          end: {
            line: 84,
            column: 86
          }
        }, {
          start: {
            line: 84,
            column: 89
          },
          end: {
            line: 84,
            column: 90
          }
        }],
        line: 84
      },
      '6': {
        loc: {
          start: {
            line: 84,
            column: 18
          },
          end: {
            line: 84,
            column: 81
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 84,
            column: 18
          },
          end: {
            line: 84,
            column: 42
          }
        }, {
          start: {
            line: 84,
            column: 46
          },
          end: {
            line: 84,
            column: 81
          }
        }],
        line: 84
      },
      '7': {
        loc: {
          start: {
            line: 117,
            column: 4
          },
          end: {
            line: 119,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 117,
            column: 4
          },
          end: {
            line: 119,
            column: 5
          }
        }, {
          start: {
            line: 117,
            column: 4
          },
          end: {
            line: 119,
            column: 5
          }
        }],
        line: 117
      },
      '8': {
        loc: {
          start: {
            line: 121,
            column: 4
          },
          end: {
            line: 123,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 121,
            column: 4
          },
          end: {
            line: 123,
            column: 5
          }
        }, {
          start: {
            line: 121,
            column: 4
          },
          end: {
            line: 123,
            column: 5
          }
        }],
        line: 121
      },
      '9': {
        loc: {
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 129,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 129,
            column: 7
          }
        }, {
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 129,
            column: 7
          }
        }],
        line: 126
      },
      '10': {
        loc: {
          start: {
            line: 126,
            column: 10
          },
          end: {
            line: 126,
            column: 83
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 126,
            column: 10
          },
          end: {
            line: 126,
            column: 47
          }
        }, {
          start: {
            line: 126,
            column: 51
          },
          end: {
            line: 126,
            column: 83
          }
        }],
        line: 126
      },
      '11': {
        loc: {
          start: {
            line: 131,
            column: 4
          },
          end: {
            line: 142,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 131,
            column: 4
          },
          end: {
            line: 142,
            column: 5
          }
        }, {
          start: {
            line: 131,
            column: 4
          },
          end: {
            line: 142,
            column: 5
          }
        }],
        line: 131
      },
      '12': {
        loc: {
          start: {
            line: 136,
            column: 8
          },
          end: {
            line: 140,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 136,
            column: 8
          },
          end: {
            line: 140,
            column: 9
          }
        }, {
          start: {
            line: 136,
            column: 8
          },
          end: {
            line: 140,
            column: 9
          }
        }],
        line: 136
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1h8ohu2r1y.s[0]++;
/**
* Division Time - controller
*/
angular.module('app').controller('planning_divisionCtrl', function ($scope, SweetAlert, $rootScope, $deltadate, $translate, $CRUDService, $otherHttpService, $location, $filter, $log) {
  cov_1h8ohu2r1y.f[0]++;
  cov_1h8ohu2r1y.s[1]++;

  $rootScope.processPageRight('1_10');
  cov_1h8ohu2r1y.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_1h8ohu2r1y.b[0][0]++;
    cov_1h8ohu2r1y.s[3]++;

    return;
  } else {
    cov_1h8ohu2r1y.b[0][1]++;
  }
  cov_1h8ohu2r1y.s[4]++;
  $scope.currentRight = angular.copy($rootScope.currentRight);
  var PATH = (cov_1h8ohu2r1y.s[5]++, 'Periode');
  cov_1h8ohu2r1y.s[6]++;
  $scope.isloading = true;
  cov_1h8ohu2r1y.s[7]++;
  $scope.listAnnee = [];
  cov_1h8ohu2r1y.s[8]++;
  $scope.selectedItem = {};
  cov_1h8ohu2r1y.s[9]++;
  $scope.listPlanAnnee = [];
  cov_1h8ohu2r1y.s[10]++;
  $scope.beginYear = {};
  cov_1h8ohu2r1y.s[11]++;
  $scope.endYear = {};
  cov_1h8ohu2r1y.s[12]++;
  $scope.decoupPlan = {};
  cov_1h8ohu2r1y.s[13]++;
  $scope.disableStart = true;

  cov_1h8ohu2r1y.s[14]++;
  $scope.listPlanPeriodicities = [{ id: '1', name: $translate.instant('COMMON.YEAR') }, { id: '2', name: $translate.instant('COMMON.SEM') }, { id: '4', name: $translate.instant('COMMON.TRIM') }, { id: '12', name: $translate.instant('COMMON.MOIS') }];

  cov_1h8ohu2r1y.s[15]++;
  $scope.startListOption = [{
    id: 1,
    label: $translate.instant('TIME_DIVISION.GLOBAL')
  }, {
    id: 2,
    label: $translate.instant('TIME_DIVISION.ANNUAL')
  }, {
    id: 3,
    label: $translate.instant('TIME_DIVISION.PERIODIC')
  }];
  cov_1h8ohu2r1y.s[16]++;
  $CRUDService.getAll(PATH, { get: 'all' }, function (data) {
    cov_1h8ohu2r1y.f[1]++;
    cov_1h8ohu2r1y.s[17]++;

    for (var i = 0; i < data.length; i++) {
      cov_1h8ohu2r1y.s[18]++;

      $scope.listPlanAnnee.push(data[i].ANNEE_PERIOD);
    }
    cov_1h8ohu2r1y.s[19]++;
    $scope.isloading = false;
  });
  cov_1h8ohu2r1y.s[20]++;
  $scope.initPlan = function () {
    cov_1h8ohu2r1y.f[2]++;

    var info = (cov_1h8ohu2r1y.s[21]++, $rootScope.currentProject.project.getDecoupagePlan());
    cov_1h8ohu2r1y.s[22]++;
    $scope.decoupPlan.beginYear = String($deltadate.format(info.dDFirstLevel, 'yyyy'));
    cov_1h8ohu2r1y.s[23]++;
    $scope.decoupPlan.endYear = String($deltadate.format(info.dFFirstLevel, 'yyyy'));
    cov_1h8ohu2r1y.s[24]++;
    $scope.decoupPlan.labelpp = info.labelThirdLevel;
    cov_1h8ohu2r1y.s[25]++;
    $scope.decoupPlan.labelpg = info.labelFirstLevel;
    cov_1h8ohu2r1y.s[26]++;
    $scope.decoupPlan.labelpa = info.labelSecondLevel;
    cov_1h8ohu2r1y.s[27]++;
    $scope.decoupPlan.allowa = info.allow_annuel;
    cov_1h8ohu2r1y.s[28]++;
    $scope.decoupPlan.allowg = info.allow_global;

    var period = (cov_1h8ohu2r1y.s[29]++, String(info.periodThirdLevel));

    cov_1h8ohu2r1y.s[30]++;
    $scope.decoupPlan.period = $filter('filter')($scope.listPlanPeriodicities, { id: period }, true)[0];

    // $scope.decoupPlan.period = String(info.periodThirdLevel);
    cov_1h8ohu2r1y.s[31]++;
    $scope.decoupPlan.fields = info.customfields;

    cov_1h8ohu2r1y.s[32]++;
    $scope.decoupPlan.startplan = info.startplan;
    cov_1h8ohu2r1y.s[33]++;
    if (!$scope.decoupPlan.startplan) {
      cov_1h8ohu2r1y.b[1][0]++;
      cov_1h8ohu2r1y.s[34]++;

      $scope.decoupPlan.startplan = 1;
    } else {
      cov_1h8ohu2r1y.b[1][1]++;
    }
    cov_1h8ohu2r1y.s[35]++;
    $scope.decoupPlan.startplan = $scope.startListOption[$scope.decoupPlan.startplan - 1];

    cov_1h8ohu2r1y.s[36]++;
    $CRUDService.getAll(PATH, { get: 'hasPlan' }, function (data) {
      cov_1h8ohu2r1y.f[3]++;
      cov_1h8ohu2r1y.s[37]++;

      if (data == 0) {
        cov_1h8ohu2r1y.b[2][0]++;
        cov_1h8ohu2r1y.s[38]++;

        $scope.disableStart = false;
      } else {
        cov_1h8ohu2r1y.b[2][1]++;
      }
    });
  };
  cov_1h8ohu2r1y.s[39]++;
  $scope.validateDPL = function () {
    cov_1h8ohu2r1y.f[4]++;

    var data = (cov_1h8ohu2r1y.s[40]++, [[], [], []]);
    // Global
    cov_1h8ohu2r1y.s[41]++;
    data[0][0] = $scope.decoupPlan.labelpg;
    cov_1h8ohu2r1y.s[42]++;
    data[0][1] = $scope.decoupPlan.beginYear;
    cov_1h8ohu2r1y.s[43]++;
    data[0][2] = $scope.decoupPlan.endYear;
    // Period
    cov_1h8ohu2r1y.s[44]++;
    data[1][0] = $scope.decoupPlan.labelpp;
    cov_1h8ohu2r1y.s[45]++;
    data[1][1] = $scope.decoupPlan.period.id;
    cov_1h8ohu2r1y.s[46]++;
    data[1][2] = (cov_1h8ohu2r1y.b[4][0]++, $scope.decoupPlan.allowa) || (cov_1h8ohu2r1y.b[4][1]++, $scope.decoupPlan.startplan.id == 3) ? (cov_1h8ohu2r1y.b[3][0]++, 1) : (cov_1h8ohu2r1y.b[3][1]++, 0);
    // Annuel
    cov_1h8ohu2r1y.s[47]++;
    data[2][0] = $scope.decoupPlan.labelpa;
    cov_1h8ohu2r1y.s[48]++;
    data[2][1] = (cov_1h8ohu2r1y.b[6][0]++, $scope.decoupPlan.allowg) || (cov_1h8ohu2r1y.b[6][1]++, $scope.decoupPlan.startplan.id == 2) ? (cov_1h8ohu2r1y.b[5][0]++, 1) : (cov_1h8ohu2r1y.b[5][1]++, 0);
    cov_1h8ohu2r1y.s[49]++;
    $rootScope.currentProject.project.setDecoupagePlan(data, $scope.decoupPlan.fields, $scope.decoupPlan.startplan.id);
  };
  cov_1h8ohu2r1y.s[50]++;
  $scope.selectedItemTampon = {};
  cov_1h8ohu2r1y.s[51]++;
  $scope.listAggrega = [{ name: $translate.instant('INDICATOR.SUM'), id: '1' }, { name: $translate.instant('INDICATOR.MOY'), id: '2' }, { name: $translate.instant('INDICATOR.MAXI'), id: '3' }, { name: $translate.instant('INDICATOR.MINI'), id: '4' }];
  cov_1h8ohu2r1y.s[52]++;
  $scope.addField = function (index) {
    cov_1h8ohu2r1y.f[5]++;
    cov_1h8ohu2r1y.s[53]++;

    this.editField({
      id: 0,
      l: '',
      t: 1,
      a: '1'
    }, index);
  };
  cov_1h8ohu2r1y.s[54]++;
  $scope.editField = function (item, index) {
    cov_1h8ohu2r1y.f[6]++;
    cov_1h8ohu2r1y.s[55]++;

    $scope.index = index;
    cov_1h8ohu2r1y.s[56]++;
    $scope.selectedField = angular.copy(item);
  };
  cov_1h8ohu2r1y.s[57]++;
  $scope.cancelField = function () {
    cov_1h8ohu2r1y.f[7]++;
    cov_1h8ohu2r1y.s[58]++;

    $scope.selectedField = null;
    cov_1h8ohu2r1y.s[59]++;
    $scope.index = -1;
  };
  cov_1h8ohu2r1y.s[60]++;
  $scope.deleteField = function (item, index) {
    cov_1h8ohu2r1y.f[8]++;
    cov_1h8ohu2r1y.s[61]++;

    // Chercher si la valeur n'est pas attribuee
    $scope.decoupPlan.fields[index].splice($scope.decoupPlan.fields[index].indexOf(item), 1);
  };

  cov_1h8ohu2r1y.s[62]++;
  $scope.validateField = function () {
    cov_1h8ohu2r1y.f[9]++;
    cov_1h8ohu2r1y.s[63]++;

    $scope.error = false;
    cov_1h8ohu2r1y.s[64]++;
    if (!$scope.selectedField.l) {
      cov_1h8ohu2r1y.b[7][0]++;
      cov_1h8ohu2r1y.s[65]++;

      return;
    } else {
      cov_1h8ohu2r1y.b[7][1]++;
    }
    cov_1h8ohu2r1y.s[66]++;
    $scope.selectedField.l = $scope.selectedField.l.trim();
    cov_1h8ohu2r1y.s[67]++;
    if ($scope.selectedField.l == '') {
      cov_1h8ohu2r1y.b[8][0]++;
      cov_1h8ohu2r1y.s[68]++;

      return;
    } else {
      cov_1h8ohu2r1y.b[8][1]++;
    }

    cov_1h8ohu2r1y.s[69]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $scope.decoupPlan.fields[$scope.index][Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _el = _step.value;
        cov_1h8ohu2r1y.s[70]++;

        if ((cov_1h8ohu2r1y.b[10][0]++, _el.l == $scope.selectedField.l.trim()) && (cov_1h8ohu2r1y.b[10][1]++, _el.id != $scope.selectedField.id)) {
          cov_1h8ohu2r1y.b[9][0]++;
          cov_1h8ohu2r1y.s[71]++;

          $scope.error = true;
          cov_1h8ohu2r1y.s[72]++;
          return;
        } else {
          cov_1h8ohu2r1y.b[9][1]++;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_1h8ohu2r1y.s[73]++;
    if ($scope.selectedField.id == 0) {
      cov_1h8ohu2r1y.b[11][0]++;
      cov_1h8ohu2r1y.s[74]++;

      $scope.selectedField.id = $scope.getLastIdField($scope.decoupPlan.fields[$scope.index]);
      cov_1h8ohu2r1y.s[75]++;
      $scope.decoupPlan.fields[$scope.index].push(angular.copy($scope.selectedField));
    } else {
      cov_1h8ohu2r1y.b[11][1]++;
      cov_1h8ohu2r1y.s[76]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = $scope.decoupPlan.fields[$scope.index][Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var el = _step2.value;
          cov_1h8ohu2r1y.s[77]++;

          if (el.id == $scope.selectedField.id) {
            cov_1h8ohu2r1y.b[12][0]++;
            cov_1h8ohu2r1y.s[78]++;

            el.l = $scope.selectedField.l;
            cov_1h8ohu2r1y.s[79]++;
            el.a = $scope.selectedField.a;
            cov_1h8ohu2r1y.s[80]++;
            el.t = $scope.selectedField.t;
          } else {
            cov_1h8ohu2r1y.b[12][1]++;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    }
    cov_1h8ohu2r1y.s[81]++;
    $scope.cancelField();
  };

  cov_1h8ohu2r1y.s[82]++;
  $scope.getLastIdField = function (fields) {
    cov_1h8ohu2r1y.f[10]++;

    var id = (cov_1h8ohu2r1y.s[83]++, 0);
    cov_1h8ohu2r1y.s[84]++;
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = fields[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var el = _step3.value;
        cov_1h8ohu2r1y.s[85]++;

        id = Math.max(id, el.id);
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    cov_1h8ohu2r1y.s[86]++;
    return id + 1;
  };

  cov_1h8ohu2r1y.s[87]++;
  $scope.initPlan();
});