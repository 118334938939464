'use strict';

var cov_1irulf25ed = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/gantt.js',
      hash = 'd7013cbf4126c04e95d4097e792ea1eb7926b8b9',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/gantt.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 182,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 181,
          column: 6
        }
      },
      '2': {
        start: {
          line: 26,
          column: 19
        },
        end: {
          line: 26,
          column: 44
        }
      },
      '3': {
        start: {
          line: 27,
          column: 8
        },
        end: {
          line: 27,
          column: 47
        }
      },
      '4': {
        start: {
          line: 28,
          column: 27
        },
        end: {
          line: 110,
          column: 10
        }
      },
      '5': {
        start: {
          line: 91,
          column: 12
        },
        end: {
          line: 91,
          column: 72
        }
      },
      '6': {
        start: {
          line: 92,
          column: 12
        },
        end: {
          line: 92,
          column: 76
        }
      },
      '7': {
        start: {
          line: 93,
          column: 12
        },
        end: {
          line: 97,
          column: 13
        }
      },
      '8': {
        start: {
          line: 99,
          column: 12
        },
        end: {
          line: 108,
          column: 13
        }
      },
      '9': {
        start: {
          line: 102,
          column: 14
        },
        end: {
          line: 102,
          column: 74
        }
      },
      '10': {
        start: {
          line: 103,
          column: 14
        },
        end: {
          line: 107,
          column: 15
        }
      },
      '11': {
        start: {
          line: 104,
          column: 16
        },
        end: {
          line: 104,
          column: 72
        }
      },
      '12': {
        start: {
          line: 106,
          column: 16
        },
        end: {
          line: 106,
          column: 69
        }
      },
      '13': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 32
        }
      },
      '14': {
        start: {
          line: 113,
          column: 8
        },
        end: {
          line: 119,
          column: 11
        }
      },
      '15': {
        start: {
          line: 114,
          column: 10
        },
        end: {
          line: 114,
          column: 29
        }
      },
      '16': {
        start: {
          line: 116,
          column: 10
        },
        end: {
          line: 116,
          column: 52
        }
      },
      '17': {
        start: {
          line: 117,
          column: 10
        },
        end: {
          line: 117,
          column: 60
        }
      },
      '18': {
        start: {
          line: 118,
          column: 10
        },
        end: {
          line: 118,
          column: 31
        }
      },
      '19': {
        start: {
          line: 121,
          column: 8
        },
        end: {
          line: 129,
          column: 11
        }
      },
      '20': {
        start: {
          line: 122,
          column: 10
        },
        end: {
          line: 122,
          column: 88
        }
      },
      '21': {
        start: {
          line: 124,
          column: 25
        },
        end: {
          line: 124,
          column: 52
        }
      },
      '22': {
        start: {
          line: 125,
          column: 10
        },
        end: {
          line: 125,
          column: 94
        }
      },
      '23': {
        start: {
          line: 126,
          column: 10
        },
        end: {
          line: 126,
          column: 90
        }
      },
      '24': {
        start: {
          line: 128,
          column: 10
        },
        end: {
          line: 128,
          column: 59
        }
      },
      '25': {
        start: {
          line: 131,
          column: 8
        },
        end: {
          line: 143,
          column: 11
        }
      },
      '26': {
        start: {
          line: 132,
          column: 10
        },
        end: {
          line: 132,
          column: 83
        }
      },
      '27': {
        start: {
          line: 134,
          column: 10
        },
        end: {
          line: 138,
          column: 11
        }
      },
      '28': {
        start: {
          line: 135,
          column: 12
        },
        end: {
          line: 135,
          column: 68
        }
      },
      '29': {
        start: {
          line: 137,
          column: 12
        },
        end: {
          line: 137,
          column: 65
        }
      },
      '30': {
        start: {
          line: 142,
          column: 10
        },
        end: {
          line: 142,
          column: 59
        }
      },
      '31': {
        start: {
          line: 145,
          column: 8
        },
        end: {
          line: 152,
          column: 11
        }
      },
      '32': {
        start: {
          line: 146,
          column: 10
        },
        end: {
          line: 146,
          column: 29
        }
      },
      '33': {
        start: {
          line: 148,
          column: 10
        },
        end: {
          line: 150,
          column: 11
        }
      },
      '34': {
        start: {
          line: 149,
          column: 12
        },
        end: {
          line: 149,
          column: 19
        }
      },
      '35': {
        start: {
          line: 151,
          column: 10
        },
        end: {
          line: 151,
          column: 35
        }
      },
      '36': {
        start: {
          line: 154,
          column: 8
        },
        end: {
          line: 179,
          column: 11
        }
      },
      '37': {
        start: {
          line: 155,
          column: 10
        },
        end: {
          line: 155,
          column: 32
        }
      },
      '38': {
        start: {
          line: 157,
          column: 10
        },
        end: {
          line: 163,
          column: 11
        }
      },
      '39': {
        start: {
          line: 158,
          column: 12
        },
        end: {
          line: 162,
          column: 14
        }
      },
      '40': {
        start: {
          line: 165,
          column: 10
        },
        end: {
          line: 171,
          column: 11
        }
      },
      '41': {
        start: {
          line: 166,
          column: 12
        },
        end: {
          line: 170,
          column: 14
        }
      },
      '42': {
        start: {
          line: 172,
          column: 10
        },
        end: {
          line: 178,
          column: 11
        }
      },
      '43': {
        start: {
          line: 173,
          column: 12
        },
        end: {
          line: 177,
          column: 14
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 27
          },
          end: {
            line: 3,
            column: 28
          }
        },
        loc: {
          start: {
            line: 3,
            column: 66
          },
          end: {
            line: 182,
            column: 3
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 113,
            column: 22
          },
          end: {
            line: 113,
            column: 23
          }
        },
        loc: {
          start: {
            line: 113,
            column: 31
          },
          end: {
            line: 115,
            column: 9
          }
        },
        line: 113
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 115,
            column: 11
          },
          end: {
            line: 115,
            column: 12
          }
        },
        loc: {
          start: {
            line: 115,
            column: 23
          },
          end: {
            line: 119,
            column: 9
          }
        },
        line: 115
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 121,
            column: 22
          },
          end: {
            line: 121,
            column: 23
          }
        },
        loc: {
          start: {
            line: 121,
            column: 31
          },
          end: {
            line: 123,
            column: 9
          }
        },
        line: 121
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 123,
            column: 11
          },
          end: {
            line: 123,
            column: 12
          }
        },
        loc: {
          start: {
            line: 123,
            column: 23
          },
          end: {
            line: 129,
            column: 9
          }
        },
        line: 123
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 131,
            column: 22
          },
          end: {
            line: 131,
            column: 23
          }
        },
        loc: {
          start: {
            line: 131,
            column: 31
          },
          end: {
            line: 133,
            column: 9
          }
        },
        line: 131
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 133,
            column: 11
          },
          end: {
            line: 133,
            column: 12
          }
        },
        loc: {
          start: {
            line: 133,
            column: 23
          },
          end: {
            line: 143,
            column: 9
          }
        },
        line: 133
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 145,
            column: 22
          },
          end: {
            line: 145,
            column: 23
          }
        },
        loc: {
          start: {
            line: 145,
            column: 31
          },
          end: {
            line: 147,
            column: 9
          }
        },
        line: 145
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 147,
            column: 11
          },
          end: {
            line: 147,
            column: 12
          }
        },
        loc: {
          start: {
            line: 147,
            column: 23
          },
          end: {
            line: 152,
            column: 9
          }
        },
        line: 147
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 154,
            column: 22
          },
          end: {
            line: 154,
            column: 23
          }
        },
        loc: {
          start: {
            line: 154,
            column: 31
          },
          end: {
            line: 156,
            column: 9
          }
        },
        line: 154
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 156,
            column: 11
          },
          end: {
            line: 156,
            column: 12
          }
        },
        loc: {
          start: {
            line: 156,
            column: 23
          },
          end: {
            line: 179,
            column: 9
          }
        },
        line: 156
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 59,
            column: 53
          },
          end: {
            line: 59,
            column: 113
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 59,
            column: 53
          },
          end: {
            line: 59,
            column: 73
          }
        }, {
          start: {
            line: 59,
            column: 77
          },
          end: {
            line: 59,
            column: 113
          }
        }],
        line: 59
      },
      '1': {
        loc: {
          start: {
            line: 93,
            column: 12
          },
          end: {
            line: 97,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 93,
            column: 12
          },
          end: {
            line: 97,
            column: 13
          }
        }, {
          start: {
            line: 93,
            column: 12
          },
          end: {
            line: 97,
            column: 13
          }
        }],
        line: 93
      },
      '2': {
        loc: {
          start: {
            line: 99,
            column: 12
          },
          end: {
            line: 108,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 99,
            column: 12
          },
          end: {
            line: 108,
            column: 13
          }
        }, {
          start: {
            line: 99,
            column: 12
          },
          end: {
            line: 108,
            column: 13
          }
        }],
        line: 99
      },
      '3': {
        loc: {
          start: {
            line: 103,
            column: 14
          },
          end: {
            line: 107,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 103,
            column: 14
          },
          end: {
            line: 107,
            column: 15
          }
        }, {
          start: {
            line: 103,
            column: 14
          },
          end: {
            line: 107,
            column: 15
          }
        }],
        line: 103
      },
      '4': {
        loc: {
          start: {
            line: 116,
            column: 34
          },
          end: {
            line: 116,
            column: 51
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 116,
            column: 34
          },
          end: {
            line: 116,
            column: 45
          }
        }, {
          start: {
            line: 116,
            column: 49
          },
          end: {
            line: 116,
            column: 51
          }
        }],
        line: 116
      },
      '5': {
        loc: {
          start: {
            line: 117,
            column: 36
          },
          end: {
            line: 117,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 117,
            column: 36
          },
          end: {
            line: 117,
            column: 53
          }
        }, {
          start: {
            line: 117,
            column: 57
          },
          end: {
            line: 117,
            column: 59
          }
        }],
        line: 117
      },
      '6': {
        loc: {
          start: {
            line: 124,
            column: 26
          },
          end: {
            line: 124,
            column: 40
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 124,
            column: 26
          },
          end: {
            line: 124,
            column: 34
          }
        }, {
          start: {
            line: 124,
            column: 38
          },
          end: {
            line: 124,
            column: 40
          }
        }],
        line: 124
      },
      '7': {
        loc: {
          start: {
            line: 132,
            column: 18
          },
          end: {
            line: 132,
            column: 43
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 132,
            column: 38
          },
          end: {
            line: 132,
            column: 39
          }
        }, {
          start: {
            line: 132,
            column: 42
          },
          end: {
            line: 132,
            column: 43
          }
        }],
        line: 132
      },
      '8': {
        loc: {
          start: {
            line: 132,
            column: 54
          },
          end: {
            line: 132,
            column: 81
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 132,
            column: 76
          },
          end: {
            line: 132,
            column: 77
          }
        }, {
          start: {
            line: 132,
            column: 80
          },
          end: {
            line: 132,
            column: 81
          }
        }],
        line: 132
      },
      '9': {
        loc: {
          start: {
            line: 134,
            column: 10
          },
          end: {
            line: 138,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 134,
            column: 10
          },
          end: {
            line: 138,
            column: 11
          }
        }, {
          start: {
            line: 134,
            column: 10
          },
          end: {
            line: 138,
            column: 11
          }
        }],
        line: 134
      },
      '10': {
        loc: {
          start: {
            line: 148,
            column: 10
          },
          end: {
            line: 150,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 148,
            column: 10
          },
          end: {
            line: 150,
            column: 11
          }
        }, {
          start: {
            line: 148,
            column: 10
          },
          end: {
            line: 150,
            column: 11
          }
        }],
        line: 148
      },
      '11': {
        loc: {
          start: {
            line: 157,
            column: 10
          },
          end: {
            line: 163,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 157,
            column: 10
          },
          end: {
            line: 163,
            column: 11
          }
        }, {
          start: {
            line: 157,
            column: 10
          },
          end: {
            line: 163,
            column: 11
          }
        }],
        line: 157
      },
      '12': {
        loc: {
          start: {
            line: 165,
            column: 10
          },
          end: {
            line: 171,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 165,
            column: 10
          },
          end: {
            line: 171,
            column: 11
          }
        }, {
          start: {
            line: 165,
            column: 10
          },
          end: {
            line: 171,
            column: 11
          }
        }],
        line: 165
      },
      '13': {
        loc: {
          start: {
            line: 172,
            column: 10
          },
          end: {
            line: 178,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 172,
            column: 10
          },
          end: {
            line: 178,
            column: 11
          }
        }, {
          start: {
            line: 172,
            column: 10
          },
          end: {
            line: 178,
            column: 11
          }
        }],
        line: 172
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1irulf25ed.s[0]++;
angular.module('app').directive('ganttDelta', function ($translate, $LOGINService, $state) {
  cov_1irulf25ed.f[0]++;
  cov_1irulf25ed.s[1]++;

  return {

    restrict: 'A',
    scope: {
      data: '=',
      milestones: '=',
      option: '=',
      columnResource: '=',
      colorPlan: '=',
      colorActual: '=',
      timeLine: '=',
      displayPlan: '=',
      displayActual: '=',
      indexYear: '=',
      indexPeriod: '=',
      activityLabel: '=',
      lang: '@',
      parent: '@',
      print: '='
    },

    link: function link($scope, $element) {
      var ID = (cov_1irulf25ed.s[2]++, '#' + $element.attr('id'));
      cov_1irulf25ed.s[3]++;
      $scope.lastSelected = { id: 0, time: 0 };
      var ganttChart = (cov_1irulf25ed.s[4]++, new ej.gantt.Gantt({
        locale: $LOGINService.getLang(),
        dataSource: $scope.data,
        gridLines: 'Both',
        height: $('body').height() - ($element.position().top + 10),
        width: '100%',
        allowSelection: true,
        highlightWeekends: true,
        treeColumnIndex: 0,
        taskbarHeight: 20,
        frozenColumns: 1,
        labelSettings: {
          /* leftLabel: '#leftLabel',
          rightLabel: '#rightLabel', */
          taskLabel: '<span class="font-bold">${progress}%</span>'
        },
        rowHeight: 60,
        eventMarkers: $scope.milestones,
        taskFields: {
          id: 'id',
          name: 'label',
          startDate: 'ddp',
          endDate: 'dfp',
          /* baselineStartDate: 'ddr',
          baselineEndDate: 'dfr', */
          progress: 'progress',
          parentID: 'idp',
          duration: 'Duration',
          indicators: 'Indicators'
        },
        columns: [{ field: 'label', width: 450, headerText: (cov_1irulf25ed.b[0][0]++, $scope.activityLabel) || (cov_1irulf25ed.b[0][1]++, $translate.instant('GANTT.ACTIVITY')), lockColumn: true }, { field: 'r', width: 200, headerText: $scope.columnResource }, { field: 'responsable', width: 200, headerText: $translate.instant('FN_ACTORS.FON') }, { field: 'ddp', width: 120, headerText: $translate.instant('GANTT.PLAN_BEGIN'),
          format: { type: 'date', skeleton: 'yMMMd' } }, { field: 'dfp', width: 120, headerText: $translate.instant('GANTT.PLAN_END'),
          format: { type: 'date', skeleton: 'yMMMd' } }, { field: 'ddr', width: 120, headerText: $translate.instant('GANTT.ACTUAL_BEGIN'),
          format: { type: 'date', skeleton: 'yMMMd' } }, { field: 'dfr', width: 120, headerText: $translate.instant('GANTT.ACTUAL_END'),
          format: { type: 'date', skeleton: 'yMMMd' }
        }],
        /* labelSettings: {
          leftLabel: 'label'
        }, */
        tooltipSettings: {
          showTooltip: true,
          taskbar: '<div>${label}</div>',
          baseline: '<div>${label}</div>'
        },
        splitterSettings: {
          columnIndex: 1
        },
        timelineSettings: {
          timelineViewMode: $scope.timeLine
        },
        // toolbar: ['ZoomIn', 'ZoomOut', 'ZoomToFit'],
        allowExcelExport: true,
        renderBaseline: true,
        baselineColor: 'rgb(32, 201, 179)',
        queryTaskbarInfo: function queryTaskbarInfo(args) {
          cov_1irulf25ed.s[5]++;

          args.taskbarBgColor = 'rgba(' + $scope.colorPlan + ', 0.8)';
          cov_1irulf25ed.s[6]++;
          args.taskbarBorderColor = 'rgba(' + $scope.colorPlan + ', 0.8)';
          cov_1irulf25ed.s[7]++;
          if (args.taskbarType == 'ParentTask') {
            /* args.taskbarBgColor = 'rgba(' + $scope.colorPlan + ', 0.8)';
            args.taskbarBorderColor = 'rgba(' + $scope.colorPlan + ', 0.8)';
            args.progressBarBgColor = 'rgb(' + $scope.colorActual + ')'; */

            cov_1irulf25ed.b[1][0]++;
          } else {
            cov_1irulf25ed.b[1][1]++;
          }

          cov_1irulf25ed.s[8]++;
          if (args.taskbarType == 'ChildTask') {
            cov_1irulf25ed.b[2][0]++;
            cov_1irulf25ed.s[9]++;

            /* args.taskbarBgColor = 'rgba(' + $scope.colorPlan + ', 0.8)';
            args.taskbarBorderColor = 'rgba(' + $scope.colorPlan + ', 0.8)'; */
            args.progressBarBgColor = 'rgb(' + $scope.colorActual + ')';
            cov_1irulf25ed.s[10]++;
            if ($scope.displayPlan) {
              cov_1irulf25ed.b[3][0]++;
              cov_1irulf25ed.s[11]++;

              $('.e-gantt-child-taskbar').removeClass('display-none');
            } else {
              cov_1irulf25ed.b[3][1]++;
              cov_1irulf25ed.s[12]++;

              $('.e-gantt-child-taskbar').addClass('display-none');
            }
          } else {
            cov_1irulf25ed.b[2][1]++;
          }
        }
      }));
      cov_1irulf25ed.s[13]++;
      ganttChart.appendTo(ID);

      cov_1irulf25ed.s[14]++;
      $scope.$watch(function (scope) {
        cov_1irulf25ed.f[1]++;
        cov_1irulf25ed.s[15]++;

        return $scope.data;
      }, function (newValue) {
        cov_1irulf25ed.f[2]++;
        cov_1irulf25ed.s[16]++;

        ganttChart.dataSource = (cov_1irulf25ed.b[4][0]++, $scope.data) || (cov_1irulf25ed.b[4][1]++, []);
        cov_1irulf25ed.s[17]++;
        ganttChart.eventMarkers = (cov_1irulf25ed.b[5][0]++, $scope.milestones) || (cov_1irulf25ed.b[5][1]++, []);
        cov_1irulf25ed.s[18]++;
        ganttChart.refresh();
      });

      cov_1irulf25ed.s[19]++;
      $scope.$watch(function (scope) {
        cov_1irulf25ed.f[3]++;
        cov_1irulf25ed.s[20]++;

        return scope.colorPlan + '_' + scope.colorActual + '_' + scope.columnResource;
      }, function (newValue) {
        cov_1irulf25ed.f[4]++;

        var values = (cov_1irulf25ed.s[21]++, ((cov_1irulf25ed.b[6][0]++, newValue) || (cov_1irulf25ed.b[6][1]++, '')).split('_'));
        cov_1irulf25ed.s[22]++;
        $('.e-gantt-child-taskbar').css('background-color', 'rgba(' + values[0] + ', 0.8)');
        cov_1irulf25ed.s[23]++;
        $('.e-gantt-child-taskbar').css('border-color', 'rgba(' + values[0] + ', 0.8)');

        cov_1irulf25ed.s[24]++;
        ganttChart.baselineColor = 'rgb(32 , 201 , 179)';
      });

      cov_1irulf25ed.s[25]++;
      $scope.$watch(function (scope) {
        cov_1irulf25ed.f[5]++;
        cov_1irulf25ed.s[26]++;

        return (scope.displayPlan ? (cov_1irulf25ed.b[7][0]++, 1) : (cov_1irulf25ed.b[7][1]++, 0)) + '_' + (scope.displayActual ? (cov_1irulf25ed.b[8][0]++, 1) : (cov_1irulf25ed.b[8][1]++, 0));
      }, function (newValue) {
        cov_1irulf25ed.f[6]++;
        cov_1irulf25ed.s[27]++;

        if ($scope.displayPlan) {
          cov_1irulf25ed.b[9][0]++;
          cov_1irulf25ed.s[28]++;

          $('.e-gantt-child-taskbar').removeClass('display-none');
        } else {
          cov_1irulf25ed.b[9][1]++;
          cov_1irulf25ed.s[29]++;

          $('.e-gantt-child-taskbar').addClass('display-none');
        }
        // addClass('display-none')

        // ganttChart.taskbarHeight = $scope.displayPlan ? 10 : 0.1;
        cov_1irulf25ed.s[30]++;
        ganttChart.renderBaseline = $scope.displayActual;
      });

      cov_1irulf25ed.s[31]++;
      $scope.$watch(function (scope) {
        cov_1irulf25ed.f[7]++;
        cov_1irulf25ed.s[32]++;

        return scope.print;
      }, function (newValue) {
        cov_1irulf25ed.f[8]++;
        cov_1irulf25ed.s[33]++;

        if (newValue == 0) {
          cov_1irulf25ed.b[10][0]++;
          cov_1irulf25ed.s[34]++;

          return;
        } else {
          cov_1irulf25ed.b[10][1]++;
        }
        cov_1irulf25ed.s[35]++;
        ganttChart.excelExport();
      });

      cov_1irulf25ed.s[36]++;
      $scope.$watch(function (scope) {
        cov_1irulf25ed.f[9]++;
        cov_1irulf25ed.s[37]++;

        return scope.timeLine;
      }, function (newValue) {
        cov_1irulf25ed.f[10]++;
        cov_1irulf25ed.s[38]++;

        if ($scope.timeLine == 'year') {
          cov_1irulf25ed.b[11][0]++;
          cov_1irulf25ed.s[39]++;

          ganttChart.timelineSettings = {
            topTier: { unit: 'Year' },
            bottomTier: { unit: 'Month', format: 'MMM' }, timelineUnitSize: 99,
            timelineViewMode: 'Year', showTooltip: true
          };
        } else {
          cov_1irulf25ed.b[11][1]++;
        }

        cov_1irulf25ed.s[40]++;
        if ($scope.timeLine == 'month') {
          cov_1irulf25ed.b[12][0]++;
          cov_1irulf25ed.s[41]++;

          ganttChart.timelineSettings = {
            topTier: { unit: 'Month' },
            bottomTier: { unit: 'Week', format: 'dd MMM' }, timelineUnitSize: 99,
            timelineViewMode: 'Month', showTooltip: true
          };
        } else {
          cov_1irulf25ed.b[12][1]++;
        }
        cov_1irulf25ed.s[42]++;
        if ($scope.timeLine == 'week') {
          cov_1irulf25ed.b[13][0]++;
          cov_1irulf25ed.s[43]++;

          ganttChart.timelineSettings = {
            topTier: { unit: 'Week' },
            bottomTier: { unit: 'Day', format: 'd' }, timelineUnitSize: 33,
            timelineViewMode: 'Week', showTooltip: true
          };
        } else {
          cov_1irulf25ed.b[13][1]++;
        }
      });
    }
  };
});