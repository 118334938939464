'use strict';

var cov_5h6kat0m0 = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/dashboard-donut.js',
      hash = '60e7b7c28c30fa90cee8053d448b8f9fc38e5a12',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/dashboard-donut.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 520,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 519,
          column: 4
        }
      },
      '2': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 19,
          column: 7
        }
      },
      '3': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 46
        }
      },
      '4': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 67
        }
      },
      '5': {
        start: {
          line: 21,
          column: 19
        },
        end: {
          line: 21,
          column: 28
        }
      },
      '6': {
        start: {
          line: 22,
          column: 23
        },
        end: {
          line: 22,
          column: 41
        }
      },
      '7': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 30
        }
      },
      '8': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 35
        }
      },
      '9': {
        start: {
          line: 25,
          column: 23
        },
        end: {
          line: 35,
          column: 62
        }
      },
      '10': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 38,
          column: 7
        }
      },
      '11': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 37,
          column: 52
        }
      },
      '12': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 42,
          column: 8
        }
      },
      '13': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 41,
          column: 53
        }
      },
      '14': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 81,
          column: 7
        }
      },
      '15': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 63,
          column: 11
        }
      },
      '16': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 48,
          column: 11
        }
      },
      '17': {
        start: {
          line: 47,
          column: 12
        },
        end: {
          line: 47,
          column: 19
        }
      },
      '18': {
        start: {
          line: 49,
          column: 10
        },
        end: {
          line: 49,
          column: 51
        }
      },
      '19': {
        start: {
          line: 50,
          column: 23
        },
        end: {
          line: 50,
          column: 31
        }
      },
      '20': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 51,
          column: 93
        }
      },
      '21': {
        start: {
          line: 52,
          column: 10
        },
        end: {
          line: 61,
          column: 11
        }
      },
      '22': {
        start: {
          line: 53,
          column: 12
        },
        end: {
          line: 53,
          column: 102
        }
      },
      '23': {
        start: {
          line: 54,
          column: 25
        },
        end: {
          line: 54,
          column: 69
        }
      },
      '24': {
        start: {
          line: 55,
          column: 12
        },
        end: {
          line: 57,
          column: 13
        }
      },
      '25': {
        start: {
          line: 56,
          column: 14
        },
        end: {
          line: 56,
          column: 23
        }
      },
      '26': {
        start: {
          line: 58,
          column: 12
        },
        end: {
          line: 60,
          column: 13
        }
      },
      '27': {
        start: {
          line: 59,
          column: 14
        },
        end: {
          line: 59,
          column: 178
        }
      },
      '28': {
        start: {
          line: 62,
          column: 10
        },
        end: {
          line: 62,
          column: 32
        }
      },
      '29': {
        start: {
          line: 65,
          column: 8
        },
        end: {
          line: 80,
          column: 11
        }
      },
      '30': {
        start: {
          line: 66,
          column: 10
        },
        end: {
          line: 66,
          column: 93
        }
      },
      '31': {
        start: {
          line: 67,
          column: 10
        },
        end: {
          line: 79,
          column: 11
        }
      },
      '32': {
        start: {
          line: 68,
          column: 12
        },
        end: {
          line: 68,
          column: 122
        }
      },
      '33': {
        start: {
          line: 69,
          column: 12
        },
        end: {
          line: 71,
          column: 13
        }
      },
      '34': {
        start: {
          line: 70,
          column: 14
        },
        end: {
          line: 70,
          column: 23
        }
      },
      '35': {
        start: {
          line: 72,
          column: 25
        },
        end: {
          line: 72,
          column: 87
        }
      },
      '36': {
        start: {
          line: 73,
          column: 12
        },
        end: {
          line: 75,
          column: 13
        }
      },
      '37': {
        start: {
          line: 74,
          column: 14
        },
        end: {
          line: 74,
          column: 23
        }
      },
      '38': {
        start: {
          line: 76,
          column: 12
        },
        end: {
          line: 78,
          column: 13
        }
      },
      '39': {
        start: {
          line: 77,
          column: 14
        },
        end: {
          line: 77,
          column: 183
        }
      },
      '40': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 100,
          column: 8
        }
      },
      '41': {
        start: {
          line: 84,
          column: 8
        },
        end: {
          line: 86,
          column: 9
        }
      },
      '42': {
        start: {
          line: 85,
          column: 10
        },
        end: {
          line: 85,
          column: 22
        }
      },
      '43': {
        start: {
          line: 87,
          column: 8
        },
        end: {
          line: 89,
          column: 9
        }
      },
      '44': {
        start: {
          line: 88,
          column: 10
        },
        end: {
          line: 88,
          column: 23
        }
      },
      '45': {
        start: {
          line: 90,
          column: 8
        },
        end: {
          line: 92,
          column: 9
        }
      },
      '46': {
        start: {
          line: 91,
          column: 10
        },
        end: {
          line: 91,
          column: 22
        }
      },
      '47': {
        start: {
          line: 93,
          column: 21
        },
        end: {
          line: 93,
          column: 44
        }
      },
      '48': {
        start: {
          line: 94,
          column: 8
        },
        end: {
          line: 98,
          column: 9
        }
      },
      '49': {
        start: {
          line: 95,
          column: 10
        },
        end: {
          line: 97,
          column: 11
        }
      },
      '50': {
        start: {
          line: 96,
          column: 12
        },
        end: {
          line: 96,
          column: 33
        }
      },
      '51': {
        start: {
          line: 99,
          column: 8
        },
        end: {
          line: 99,
          column: 21
        }
      },
      '52': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 145,
          column: 8
        }
      },
      '53': {
        start: {
          line: 102,
          column: 8
        },
        end: {
          line: 123,
          column: 9
        }
      },
      '54': {
        start: {
          line: 103,
          column: 10
        },
        end: {
          line: 122,
          column: 11
        }
      },
      '55': {
        start: {
          line: 105,
          column: 14
        },
        end: {
          line: 105,
          column: 26
        }
      },
      '56': {
        start: {
          line: 107,
          column: 14
        },
        end: {
          line: 107,
          column: 26
        }
      },
      '57': {
        start: {
          line: 109,
          column: 14
        },
        end: {
          line: 109,
          column: 26
        }
      },
      '58': {
        start: {
          line: 111,
          column: 14
        },
        end: {
          line: 111,
          column: 26
        }
      },
      '59': {
        start: {
          line: 113,
          column: 14
        },
        end: {
          line: 113,
          column: 26
        }
      },
      '60': {
        start: {
          line: 115,
          column: 14
        },
        end: {
          line: 115,
          column: 26
        }
      },
      '61': {
        start: {
          line: 117,
          column: 14
        },
        end: {
          line: 117,
          column: 26
        }
      },
      '62': {
        start: {
          line: 119,
          column: 14
        },
        end: {
          line: 119,
          column: 26
        }
      },
      '63': {
        start: {
          line: 121,
          column: 14
        },
        end: {
          line: 121,
          column: 26
        }
      },
      '64': {
        start: {
          line: 125,
          column: 8
        },
        end: {
          line: 144,
          column: 9
        }
      },
      '65': {
        start: {
          line: 127,
          column: 12
        },
        end: {
          line: 127,
          column: 24
        }
      },
      '66': {
        start: {
          line: 129,
          column: 12
        },
        end: {
          line: 129,
          column: 24
        }
      },
      '67': {
        start: {
          line: 131,
          column: 12
        },
        end: {
          line: 131,
          column: 24
        }
      },
      '68': {
        start: {
          line: 133,
          column: 12
        },
        end: {
          line: 133,
          column: 24
        }
      },
      '69': {
        start: {
          line: 135,
          column: 12
        },
        end: {
          line: 135,
          column: 24
        }
      },
      '70': {
        start: {
          line: 137,
          column: 12
        },
        end: {
          line: 137,
          column: 24
        }
      },
      '71': {
        start: {
          line: 139,
          column: 12
        },
        end: {
          line: 139,
          column: 24
        }
      },
      '72': {
        start: {
          line: 141,
          column: 12
        },
        end: {
          line: 141,
          column: 24
        }
      },
      '73': {
        start: {
          line: 143,
          column: 12
        },
        end: {
          line: 143,
          column: 24
        }
      },
      '74': {
        start: {
          line: 147,
          column: 6
        },
        end: {
          line: 505,
          column: 8
        }
      },
      '75': {
        start: {
          line: 149,
          column: 10
        },
        end: {
          line: 151,
          column: 11
        }
      },
      '76': {
        start: {
          line: 150,
          column: 12
        },
        end: {
          line: 150,
          column: 19
        }
      },
      '77': {
        start: {
          line: 152,
          column: 10
        },
        end: {
          line: 152,
          column: 66
        }
      },
      '78': {
        start: {
          line: 153,
          column: 10
        },
        end: {
          line: 163,
          column: 19
        }
      },
      '79': {
        start: {
          line: 154,
          column: 12
        },
        end: {
          line: 158,
          column: 13
        }
      },
      '80': {
        start: {
          line: 155,
          column: 14
        },
        end: {
          line: 155,
          column: 69
        }
      },
      '81': {
        start: {
          line: 157,
          column: 14
        },
        end: {
          line: 157,
          column: 69
        }
      },
      '82': {
        start: {
          line: 160,
          column: 12
        },
        end: {
          line: 162,
          column: 21
        }
      },
      '83': {
        start: {
          line: 161,
          column: 14
        },
        end: {
          line: 161,
          column: 96
        }
      },
      '84': {
        start: {
          line: 166,
          column: 10
        },
        end: {
          line: 168,
          column: 11
        }
      },
      '85': {
        start: {
          line: 167,
          column: 12
        },
        end: {
          line: 167,
          column: 25
        }
      },
      '86': {
        start: {
          line: 169,
          column: 10
        },
        end: {
          line: 171,
          column: 11
        }
      },
      '87': {
        start: {
          line: 170,
          column: 12
        },
        end: {
          line: 170,
          column: 24
        }
      },
      '88': {
        start: {
          line: 173,
          column: 10
        },
        end: {
          line: 175,
          column: 11
        }
      },
      '89': {
        start: {
          line: 174,
          column: 12
        },
        end: {
          line: 174,
          column: 24
        }
      },
      '90': {
        start: {
          line: 177,
          column: 10
        },
        end: {
          line: 179,
          column: 11
        }
      },
      '91': {
        start: {
          line: 178,
          column: 12
        },
        end: {
          line: 178,
          column: 24
        }
      },
      '92': {
        start: {
          line: 181,
          column: 10
        },
        end: {
          line: 181,
          column: 23
        }
      },
      '93': {
        start: {
          line: 185,
          column: 23
        },
        end: {
          line: 185,
          column: 53
        }
      },
      '94': {
        start: {
          line: 186,
          column: 10
        },
        end: {
          line: 190,
          column: 11
        }
      },
      '95': {
        start: {
          line: 187,
          column: 12
        },
        end: {
          line: 189,
          column: 13
        }
      },
      '96': {
        start: {
          line: 188,
          column: 14
        },
        end: {
          line: 188,
          column: 21
        }
      },
      '97': {
        start: {
          line: 191,
          column: 10
        },
        end: {
          line: 191,
          column: 67
        }
      },
      '98': {
        start: {
          line: 192,
          column: 10
        },
        end: {
          line: 192,
          column: 30
        }
      },
      '99': {
        start: {
          line: 193,
          column: 10
        },
        end: {
          line: 193,
          column: 26
        }
      },
      '100': {
        start: {
          line: 197,
          column: 10
        },
        end: {
          line: 199,
          column: 11
        }
      },
      '101': {
        start: {
          line: 198,
          column: 12
        },
        end: {
          line: 198,
          column: 19
        }
      },
      '102': {
        start: {
          line: 200,
          column: 23
        },
        end: {
          line: 200,
          column: 29
        }
      },
      '103': {
        start: {
          line: 201,
          column: 10
        },
        end: {
          line: 203,
          column: 11
        }
      },
      '104': {
        start: {
          line: 202,
          column: 12
        },
        end: {
          line: 202,
          column: 37
        }
      },
      '105': {
        start: {
          line: 204,
          column: 10
        },
        end: {
          line: 204,
          column: 55
        }
      },
      '106': {
        start: {
          line: 205,
          column: 10
        },
        end: {
          line: 232,
          column: 11
        }
      },
      '107': {
        start: {
          line: 207,
          column: 12
        },
        end: {
          line: 214,
          column: 13
        }
      },
      '108': {
        start: {
          line: 208,
          column: 14
        },
        end: {
          line: 213,
          column: 16
        }
      },
      '109': {
        start: {
          line: 215,
          column: 17
        },
        end: {
          line: 232,
          column: 11
        }
      },
      '110': {
        start: {
          line: 216,
          column: 12
        },
        end: {
          line: 221,
          column: 13
        }
      },
      '111': {
        start: {
          line: 217,
          column: 14
        },
        end: {
          line: 220,
          column: 16
        }
      },
      '112': {
        start: {
          line: 224,
          column: 12
        },
        end: {
          line: 231,
          column: 13
        }
      },
      '113': {
        start: {
          line: 225,
          column: 14
        },
        end: {
          line: 230,
          column: 16
        }
      },
      '114': {
        start: {
          line: 233,
          column: 10
        },
        end: {
          line: 235,
          column: 11
        }
      },
      '115': {
        start: {
          line: 234,
          column: 12
        },
        end: {
          line: 234,
          column: 28
        }
      },
      '116': {
        start: {
          line: 238,
          column: 10
        },
        end: {
          line: 238,
          column: 27
        }
      },
      '117': {
        start: {
          line: 239,
          column: 10
        },
        end: {
          line: 242,
          column: 12
        }
      },
      '118': {
        start: {
          line: 245,
          column: 10
        },
        end: {
          line: 245,
          column: 27
        }
      },
      '119': {
        start: {
          line: 246,
          column: 10
        },
        end: {
          line: 249,
          column: 12
        }
      },
      '120': {
        start: {
          line: 252,
          column: 10
        },
        end: {
          line: 252,
          column: 27
        }
      },
      '121': {
        start: {
          line: 253,
          column: 10
        },
        end: {
          line: 256,
          column: 12
        }
      },
      '122': {
        start: {
          line: 262,
          column: 26
        },
        end: {
          line: 265,
          column: 11
        }
      },
      '123': {
        start: {
          line: 267,
          column: 10
        },
        end: {
          line: 267,
          column: 33
        }
      },
      '124': {
        start: {
          line: 268,
          column: 19
        },
        end: {
          line: 268,
          column: 21
        }
      },
      '125': {
        start: {
          line: 269,
          column: 24
        },
        end: {
          line: 269,
          column: 26
        }
      },
      '126': {
        start: {
          line: 270,
          column: 10
        },
        end: {
          line: 293,
          column: 11
        }
      },
      '127': {
        start: {
          line: 271,
          column: 12
        },
        end: {
          line: 271,
          column: 27
        }
      },
      '128': {
        start: {
          line: 272,
          column: 12
        },
        end: {
          line: 272,
          column: 39
        }
      },
      '129': {
        start: {
          line: 273,
          column: 12
        },
        end: {
          line: 273,
          column: 46
        }
      },
      '130': {
        start: {
          line: 275,
          column: 17
        },
        end: {
          line: 293,
          column: 11
        }
      },
      '131': {
        start: {
          line: 276,
          column: 12
        },
        end: {
          line: 276,
          column: 37
        }
      },
      '132': {
        start: {
          line: 277,
          column: 12
        },
        end: {
          line: 277,
          column: 46
        }
      },
      '133': {
        start: {
          line: 278,
          column: 12
        },
        end: {
          line: 278,
          column: 22
        }
      },
      '134': {
        start: {
          line: 280,
          column: 17
        },
        end: {
          line: 293,
          column: 11
        }
      },
      '135': {
        start: {
          line: 281,
          column: 12
        },
        end: {
          line: 281,
          column: 41
        }
      },
      '136': {
        start: {
          line: 282,
          column: 12
        },
        end: {
          line: 282,
          column: 22
        }
      },
      '137': {
        start: {
          line: 284,
          column: 17
        },
        end: {
          line: 293,
          column: 11
        }
      },
      '138': {
        start: {
          line: 285,
          column: 12
        },
        end: {
          line: 285,
          column: 46
        }
      },
      '139': {
        start: {
          line: 286,
          column: 12
        },
        end: {
          line: 286,
          column: 22
        }
      },
      '140': {
        start: {
          line: 289,
          column: 12
        },
        end: {
          line: 289,
          column: 42
        }
      },
      '141': {
        start: {
          line: 290,
          column: 12
        },
        end: {
          line: 290,
          column: 28
        }
      },
      '142': {
        start: {
          line: 291,
          column: 12
        },
        end: {
          line: 291,
          column: 46
        }
      },
      '143': {
        start: {
          line: 294,
          column: 10
        },
        end: {
          line: 383,
          column: 13
        }
      },
      '144': {
        start: {
          line: 295,
          column: 12
        },
        end: {
          line: 326,
          column: 13
        }
      },
      '145': {
        start: {
          line: 296,
          column: 14
        },
        end: {
          line: 325,
          column: 15
        }
      },
      '146': {
        start: {
          line: 297,
          column: 16
        },
        end: {
          line: 305,
          column: 17
        }
      },
      '147': {
        start: {
          line: 298,
          column: 18
        },
        end: {
          line: 300,
          column: 21
        }
      },
      '148': {
        start: {
          line: 299,
          column: 20
        },
        end: {
          line: 299,
          column: 44
        }
      },
      '149': {
        start: {
          line: 302,
          column: 18
        },
        end: {
          line: 304,
          column: 21
        }
      },
      '150': {
        start: {
          line: 303,
          column: 20
        },
        end: {
          line: 303,
          column: 70
        }
      },
      '151': {
        start: {
          line: 306,
          column: 21
        },
        end: {
          line: 325,
          column: 15
        }
      },
      '152': {
        start: {
          line: 307,
          column: 16
        },
        end: {
          line: 313,
          column: 19
        }
      },
      '153': {
        start: {
          line: 308,
          column: 18
        },
        end: {
          line: 312,
          column: 20
        }
      },
      '154': {
        start: {
          line: 315,
          column: 31
        },
        end: {
          line: 317,
          column: 21
        }
      },
      '155': {
        start: {
          line: 316,
          column: 18
        },
        end: {
          line: 316,
          column: 76
        }
      },
      '156': {
        start: {
          line: 318,
          column: 16
        },
        end: {
          line: 324,
          column: 19
        }
      },
      '157': {
        start: {
          line: 319,
          column: 18
        },
        end: {
          line: 323,
          column: 20
        }
      },
      '158': {
        start: {
          line: 327,
          column: 12
        },
        end: {
          line: 358,
          column: 14
        }
      },
      '159': {
        start: {
          line: 336,
          column: 41
        },
        end: {
          line: 338,
          column: 24
        }
      },
      '160': {
        start: {
          line: 337,
          column: 24
        },
        end: {
          line: 337,
          column: 61
        }
      },
      '161': {
        start: {
          line: 339,
          column: 38
        },
        end: {
          line: 339,
          column: 101
        }
      },
      '162': {
        start: {
          line: 340,
          column: 41
        },
        end: {
          line: 340,
          column: 98
        }
      },
      '163': {
        start: {
          line: 341,
          column: 22
        },
        end: {
          line: 341,
          column: 65
        }
      },
      '164': {
        start: {
          line: 342,
          column: 22
        },
        end: {
          line: 342,
          column: 42
        }
      },
      '165': {
        start: {
          line: 352,
          column: 18
        },
        end: {
          line: 354,
          column: 19
        }
      },
      '166': {
        start: {
          line: 353,
          column: 20
        },
        end: {
          line: 353,
          column: 27
        }
      },
      '167': {
        start: {
          line: 355,
          column: 18
        },
        end: {
          line: 355,
          column: 60
        }
      },
      '168': {
        start: {
          line: 359,
          column: 27
        },
        end: {
          line: 359,
          column: 29
        }
      },
      '169': {
        start: {
          line: 360,
          column: 27
        },
        end: {
          line: 360,
          column: 71
        }
      },
      '170': {
        start: {
          line: 361,
          column: 12
        },
        end: {
          line: 381,
          column: 13
        }
      },
      '171': {
        start: {
          line: 362,
          column: 14
        },
        end: {
          line: 364,
          column: 15
        }
      },
      '172': {
        start: {
          line: 363,
          column: 16
        },
        end: {
          line: 363,
          column: 25
        }
      },
      '173': {
        start: {
          line: 365,
          column: 14
        },
        end: {
          line: 367,
          column: 15
        }
      },
      '174': {
        start: {
          line: 366,
          column: 16
        },
        end: {
          line: 366,
          column: 25
        }
      },
      '175': {
        start: {
          line: 369,
          column: 24
        },
        end: {
          line: 369,
          column: 53
        }
      },
      '176': {
        start: {
          line: 371,
          column: 29
        },
        end: {
          line: 371,
          column: 226
        }
      },
      '177': {
        start: {
          line: 373,
          column: 14
        },
        end: {
          line: 377,
          column: 15
        }
      },
      '178': {
        start: {
          line: 374,
          column: 16
        },
        end: {
          line: 374,
          column: 40
        }
      },
      '179': {
        start: {
          line: 376,
          column: 16
        },
        end: {
          line: 376,
          column: 25
        }
      },
      '180': {
        start: {
          line: 379,
          column: 14
        },
        end: {
          line: 379,
          column: 44
        }
      },
      '181': {
        start: {
          line: 380,
          column: 14
        },
        end: {
          line: 380,
          column: 49
        }
      },
      '182': {
        start: {
          line: 382,
          column: 12
        },
        end: {
          line: 382,
          column: 38
        }
      },
      '183': {
        start: {
          line: 387,
          column: 10
        },
        end: {
          line: 390,
          column: 11
        }
      },
      '184': {
        start: {
          line: 388,
          column: 12
        },
        end: {
          line: 388,
          column: 37
        }
      },
      '185': {
        start: {
          line: 389,
          column: 12
        },
        end: {
          line: 389,
          column: 19
        }
      },
      '186': {
        start: {
          line: 391,
          column: 25
        },
        end: {
          line: 391,
          column: 52
        }
      },
      '187': {
        start: {
          line: 392,
          column: 10
        },
        end: {
          line: 400,
          column: 11
        }
      },
      '188': {
        start: {
          line: 393,
          column: 28
        },
        end: {
          line: 393,
          column: 80
        }
      },
      '189': {
        start: {
          line: 394,
          column: 12
        },
        end: {
          line: 396,
          column: 13
        }
      },
      '190': {
        start: {
          line: 395,
          column: 14
        },
        end: {
          line: 395,
          column: 31
        }
      },
      '191': {
        start: {
          line: 397,
          column: 12
        },
        end: {
          line: 397,
          column: 41
        }
      },
      '192': {
        start: {
          line: 398,
          column: 12
        },
        end: {
          line: 398,
          column: 43
        }
      },
      '193': {
        start: {
          line: 399,
          column: 12
        },
        end: {
          line: 399,
          column: 38
        }
      },
      '194': {
        start: {
          line: 402,
          column: 32
        },
        end: {
          line: 449,
          column: 11
        }
      },
      '195': {
        start: {
          line: 404,
          column: 14
        },
        end: {
          line: 404,
          column: 96
        }
      },
      '196': {
        start: {
          line: 410,
          column: 18
        },
        end: {
          line: 410,
          column: 48
        }
      },
      '197': {
        start: {
          line: 411,
          column: 18
        },
        end: {
          line: 411,
          column: 31
        }
      },
      '198': {
        start: {
          line: 418,
          column: 14
        },
        end: {
          line: 420,
          column: 15
        }
      },
      '199': {
        start: {
          line: 419,
          column: 16
        },
        end: {
          line: 419,
          column: 62
        }
      },
      '200': {
        start: {
          line: 421,
          column: 14
        },
        end: {
          line: 421,
          column: 141
        }
      },
      '201': {
        start: {
          line: 422,
          column: 14
        },
        end: {
          line: 422,
          column: 36
        }
      },
      '202': {
        start: {
          line: 423,
          column: 14
        },
        end: {
          line: 423,
          column: 51
        }
      },
      '203': {
        start: {
          line: 424,
          column: 14
        },
        end: {
          line: 424,
          column: 44
        }
      },
      '204': {
        start: {
          line: 430,
          column: 14
        },
        end: {
          line: 437,
          column: 15
        }
      },
      '205': {
        start: {
          line: 431,
          column: 16
        },
        end: {
          line: 431,
          column: 41
        }
      },
      '206': {
        start: {
          line: 433,
          column: 30
        },
        end: {
          line: 433,
          column: 64
        }
      },
      '207': {
        start: {
          line: 434,
          column: 16
        },
        end: {
          line: 436,
          column: 17
        }
      },
      '208': {
        start: {
          line: 435,
          column: 18
        },
        end: {
          line: 435,
          column: 54
        }
      },
      '209': {
        start: {
          line: 451,
          column: 10
        },
        end: {
          line: 451,
          column: 78
        }
      },
      '210': {
        start: {
          line: 452,
          column: 10
        },
        end: {
          line: 452,
          column: 90
        }
      },
      '211': {
        start: {
          line: 456,
          column: 24
        },
        end: {
          line: 456,
          column: 229
        }
      },
      '212': {
        start: {
          line: 457,
          column: 10
        },
        end: {
          line: 461,
          column: 11
        }
      },
      '213': {
        start: {
          line: 458,
          column: 12
        },
        end: {
          line: 458,
          column: 25
        }
      },
      '214': {
        start: {
          line: 459,
          column: 12
        },
        end: {
          line: 459,
          column: 25
        }
      },
      '215': {
        start: {
          line: 460,
          column: 12
        },
        end: {
          line: 460,
          column: 25
        }
      },
      '216': {
        start: {
          line: 462,
          column: 10
        },
        end: {
          line: 464,
          column: 11
        }
      },
      '217': {
        start: {
          line: 463,
          column: 12
        },
        end: {
          line: 463,
          column: 45
        }
      },
      '218': {
        start: {
          line: 465,
          column: 10
        },
        end: {
          line: 465,
          column: 51
        }
      },
      '219': {
        start: {
          line: 466,
          column: 10
        },
        end: {
          line: 486,
          column: 13
        }
      },
      '220': {
        start: {
          line: 467,
          column: 12
        },
        end: {
          line: 481,
          column: 13
        }
      },
      '221': {
        start: {
          line: 468,
          column: 14
        },
        end: {
          line: 480,
          column: 15
        }
      },
      '222': {
        start: {
          line: 469,
          column: 16
        },
        end: {
          line: 471,
          column: 17
        }
      },
      '223': {
        start: {
          line: 470,
          column: 18
        },
        end: {
          line: 470,
          column: 112
        }
      },
      '224': {
        start: {
          line: 472,
          column: 16
        },
        end: {
          line: 475,
          column: 17
        }
      },
      '225': {
        start: {
          line: 473,
          column: 18
        },
        end: {
          line: 473,
          column: 61
        }
      },
      '226': {
        start: {
          line: 474,
          column: 18
        },
        end: {
          line: 474,
          column: 158
        }
      },
      '227': {
        start: {
          line: 476,
          column: 16
        },
        end: {
          line: 479,
          column: 17
        }
      },
      '228': {
        start: {
          line: 477,
          column: 18
        },
        end: {
          line: 477,
          column: 61
        }
      },
      '229': {
        start: {
          line: 478,
          column: 18
        },
        end: {
          line: 478,
          column: 158
        }
      },
      '230': {
        start: {
          line: 483,
          column: 12
        },
        end: {
          line: 483,
          column: 31
        }
      },
      '231': {
        start: {
          line: 485,
          column: 12
        },
        end: {
          line: 485,
          column: 45
        }
      },
      '232': {
        start: {
          line: 489,
          column: 10
        },
        end: {
          line: 489,
          column: 27
        }
      },
      '233': {
        start: {
          line: 490,
          column: 10
        },
        end: {
          line: 502,
          column: 13
        }
      },
      '234': {
        start: {
          line: 503,
          column: 10
        },
        end: {
          line: 503,
          column: 26
        }
      },
      '235': {
        start: {
          line: 506,
          column: 6
        },
        end: {
          line: 508,
          column: 7
        }
      },
      '236': {
        start: {
          line: 507,
          column: 8
        },
        end: {
          line: 507,
          column: 30
        }
      },
      '237': {
        start: {
          line: 510,
          column: 6
        },
        end: {
          line: 517,
          column: 9
        }
      },
      '238': {
        start: {
          line: 511,
          column: 8
        },
        end: {
          line: 511,
          column: 28
        }
      },
      '239': {
        start: {
          line: 513,
          column: 8
        },
        end: {
          line: 516,
          column: 9
        }
      },
      '240': {
        start: {
          line: 514,
          column: 10
        },
        end: {
          line: 514,
          column: 47
        }
      },
      '241': {
        start: {
          line: 515,
          column: 10
        },
        end: {
          line: 515,
          column: 27
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 50
          },
          end: {
            line: 2,
            column: 51
          }
        },
        loc: {
          start: {
            line: 2,
            column: 62
          },
          end: {
            line: 520,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 40,
            column: 36
          },
          end: {
            line: 40,
            column: 37
          }
        },
        loc: {
          start: {
            line: 40,
            column: 52
          },
          end: {
            line: 42,
            column: 7
          }
        },
        line: 40
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 45,
            column: 125
          },
          end: {
            line: 45,
            column: 126
          }
        },
        loc: {
          start: {
            line: 45,
            column: 134
          },
          end: {
            line: 63,
            column: 9
          }
        },
        line: 45
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 65,
            column: 44
          },
          end: {
            line: 65,
            column: 45
          }
        },
        loc: {
          start: {
            line: 65,
            column: 50
          },
          end: {
            line: 80,
            column: 9
          }
        },
        line: 65
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 83,
            column: 30
          },
          end: {
            line: 83,
            column: 31
          }
        },
        loc: {
          start: {
            line: 83,
            column: 60
          },
          end: {
            line: 100,
            column: 7
          }
        },
        line: 83
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 101,
            column: 31
          },
          end: {
            line: 101,
            column: 32
          }
        },
        loc: {
          start: {
            line: 101,
            column: 48
          },
          end: {
            line: 145,
            column: 7
          }
        },
        line: 101
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 153,
            column: 19
          },
          end: {
            line: 153,
            column: 20
          }
        },
        loc: {
          start: {
            line: 153,
            column: 25
          },
          end: {
            line: 163,
            column: 11
          }
        },
        line: 153
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 160,
            column: 21
          },
          end: {
            line: 160,
            column: 22
          }
        },
        loc: {
          start: {
            line: 160,
            column: 27
          },
          end: {
            line: 162,
            column: 13
          }
        },
        line: 160
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 294,
            column: 58
          },
          end: {
            line: 294,
            column: 59
          }
        },
        loc: {
          start: {
            line: 294,
            column: 67
          },
          end: {
            line: 383,
            column: 11
          }
        },
        line: 294
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 298,
            column: 58
          },
          end: {
            line: 298,
            column: 59
          }
        },
        loc: {
          start: {
            line: 298,
            column: 66
          },
          end: {
            line: 300,
            column: 19
          }
        },
        line: 298
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 302,
            column: 58
          },
          end: {
            line: 302,
            column: 59
          }
        },
        loc: {
          start: {
            line: 302,
            column: 66
          },
          end: {
            line: 304,
            column: 19
          }
        },
        line: 302
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 307,
            column: 34
          },
          end: {
            line: 307,
            column: 35
          }
        },
        loc: {
          start: {
            line: 307,
            column: 44
          },
          end: {
            line: 313,
            column: 17
          }
        },
        line: 307
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 315,
            column: 44
          },
          end: {
            line: 315,
            column: 45
          }
        },
        loc: {
          start: {
            line: 315,
            column: 52
          },
          end: {
            line: 317,
            column: 17
          }
        },
        line: 315
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 318,
            column: 106
          },
          end: {
            line: 318,
            column: 107
          }
        },
        loc: {
          start: {
            line: 318,
            column: 114
          },
          end: {
            line: 324,
            column: 17
          }
        },
        line: 318
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 336,
            column: 93
          },
          end: {
            line: 336,
            column: 94
          }
        },
        loc: {
          start: {
            line: 336,
            column: 103
          },
          end: {
            line: 338,
            column: 23
          }
        },
        line: 336
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 373,
            column: 32
          },
          end: {
            line: 373,
            column: 33
          }
        },
        loc: {
          start: {
            line: 373,
            column: 41
          },
          end: {
            line: 375,
            column: 15
          }
        },
        line: 373
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 409,
            column: 52
          },
          end: {
            line: 409,
            column: 53
          }
        },
        loc: {
          start: {
            line: 409,
            column: 70
          },
          end: {
            line: 412,
            column: 17
          }
        },
        line: 409
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 466,
            column: 100
          },
          end: {
            line: 466,
            column: 101
          }
        },
        loc: {
          start: {
            line: 466,
            column: 108
          },
          end: {
            line: 486,
            column: 11
          }
        },
        line: 466
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 510,
            column: 20
          },
          end: {
            line: 510,
            column: 21
          }
        },
        loc: {
          start: {
            line: 510,
            column: 29
          },
          end: {
            line: 512,
            column: 7
          }
        },
        line: 510
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 512,
            column: 9
          },
          end: {
            line: 512,
            column: 10
          }
        },
        loc: {
          start: {
            line: 512,
            column: 21
          },
          end: {
            line: 517,
            column: 7
          }
        },
        line: 512
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }, {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }],
        line: 17
      },
      '1': {
        loc: {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        }, {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        }],
        line: 36
      },
      '2': {
        loc: {
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        }, {
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        }],
        line: 44
      },
      '3': {
        loc: {
          start: {
            line: 46,
            column: 10
          },
          end: {
            line: 48,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 46,
            column: 10
          },
          end: {
            line: 48,
            column: 11
          }
        }, {
          start: {
            line: 46,
            column: 10
          },
          end: {
            line: 48,
            column: 11
          }
        }],
        line: 46
      },
      '4': {
        loc: {
          start: {
            line: 55,
            column: 12
          },
          end: {
            line: 57,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 55,
            column: 12
          },
          end: {
            line: 57,
            column: 13
          }
        }, {
          start: {
            line: 55,
            column: 12
          },
          end: {
            line: 57,
            column: 13
          }
        }],
        line: 55
      },
      '5': {
        loc: {
          start: {
            line: 69,
            column: 12
          },
          end: {
            line: 71,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 69,
            column: 12
          },
          end: {
            line: 71,
            column: 13
          }
        }, {
          start: {
            line: 69,
            column: 12
          },
          end: {
            line: 71,
            column: 13
          }
        }],
        line: 69
      },
      '6': {
        loc: {
          start: {
            line: 73,
            column: 12
          },
          end: {
            line: 75,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 73,
            column: 12
          },
          end: {
            line: 75,
            column: 13
          }
        }, {
          start: {
            line: 73,
            column: 12
          },
          end: {
            line: 75,
            column: 13
          }
        }],
        line: 73
      },
      '7': {
        loc: {
          start: {
            line: 84,
            column: 8
          },
          end: {
            line: 86,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 84,
            column: 8
          },
          end: {
            line: 86,
            column: 9
          }
        }, {
          start: {
            line: 84,
            column: 8
          },
          end: {
            line: 86,
            column: 9
          }
        }],
        line: 84
      },
      '8': {
        loc: {
          start: {
            line: 87,
            column: 8
          },
          end: {
            line: 89,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 87,
            column: 8
          },
          end: {
            line: 89,
            column: 9
          }
        }, {
          start: {
            line: 87,
            column: 8
          },
          end: {
            line: 89,
            column: 9
          }
        }],
        line: 87
      },
      '9': {
        loc: {
          start: {
            line: 90,
            column: 8
          },
          end: {
            line: 92,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 90,
            column: 8
          },
          end: {
            line: 92,
            column: 9
          }
        }, {
          start: {
            line: 90,
            column: 8
          },
          end: {
            line: 92,
            column: 9
          }
        }],
        line: 90
      },
      '10': {
        loc: {
          start: {
            line: 95,
            column: 10
          },
          end: {
            line: 97,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 95,
            column: 10
          },
          end: {
            line: 97,
            column: 11
          }
        }, {
          start: {
            line: 95,
            column: 10
          },
          end: {
            line: 97,
            column: 11
          }
        }],
        line: 95
      },
      '11': {
        loc: {
          start: {
            line: 102,
            column: 8
          },
          end: {
            line: 123,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 102,
            column: 8
          },
          end: {
            line: 123,
            column: 9
          }
        }, {
          start: {
            line: 102,
            column: 8
          },
          end: {
            line: 123,
            column: 9
          }
        }],
        line: 102
      },
      '12': {
        loc: {
          start: {
            line: 103,
            column: 10
          },
          end: {
            line: 122,
            column: 11
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 104,
            column: 12
          },
          end: {
            line: 104,
            column: 19
          }
        }, {
          start: {
            line: 104,
            column: 20
          },
          end: {
            line: 105,
            column: 26
          }
        }, {
          start: {
            line: 106,
            column: 12
          },
          end: {
            line: 106,
            column: 19
          }
        }, {
          start: {
            line: 106,
            column: 20
          },
          end: {
            line: 107,
            column: 26
          }
        }, {
          start: {
            line: 108,
            column: 12
          },
          end: {
            line: 108,
            column: 19
          }
        }, {
          start: {
            line: 108,
            column: 20
          },
          end: {
            line: 109,
            column: 26
          }
        }, {
          start: {
            line: 110,
            column: 12
          },
          end: {
            line: 110,
            column: 19
          }
        }, {
          start: {
            line: 110,
            column: 20
          },
          end: {
            line: 111,
            column: 26
          }
        }, {
          start: {
            line: 112,
            column: 12
          },
          end: {
            line: 112,
            column: 19
          }
        }, {
          start: {
            line: 112,
            column: 20
          },
          end: {
            line: 113,
            column: 26
          }
        }, {
          start: {
            line: 114,
            column: 12
          },
          end: {
            line: 114,
            column: 19
          }
        }, {
          start: {
            line: 114,
            column: 20
          },
          end: {
            line: 115,
            column: 26
          }
        }, {
          start: {
            line: 116,
            column: 12
          },
          end: {
            line: 116,
            column: 19
          }
        }, {
          start: {
            line: 116,
            column: 20
          },
          end: {
            line: 117,
            column: 26
          }
        }, {
          start: {
            line: 118,
            column: 12
          },
          end: {
            line: 118,
            column: 19
          }
        }, {
          start: {
            line: 118,
            column: 20
          },
          end: {
            line: 119,
            column: 26
          }
        }, {
          start: {
            line: 120,
            column: 12
          },
          end: {
            line: 121,
            column: 26
          }
        }],
        line: 103
      },
      '13': {
        loc: {
          start: {
            line: 125,
            column: 8
          },
          end: {
            line: 144,
            column: 9
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 126,
            column: 10
          },
          end: {
            line: 126,
            column: 17
          }
        }, {
          start: {
            line: 126,
            column: 18
          },
          end: {
            line: 127,
            column: 24
          }
        }, {
          start: {
            line: 128,
            column: 10
          },
          end: {
            line: 128,
            column: 17
          }
        }, {
          start: {
            line: 128,
            column: 18
          },
          end: {
            line: 129,
            column: 24
          }
        }, {
          start: {
            line: 130,
            column: 10
          },
          end: {
            line: 130,
            column: 17
          }
        }, {
          start: {
            line: 130,
            column: 18
          },
          end: {
            line: 131,
            column: 24
          }
        }, {
          start: {
            line: 132,
            column: 10
          },
          end: {
            line: 132,
            column: 17
          }
        }, {
          start: {
            line: 132,
            column: 18
          },
          end: {
            line: 133,
            column: 24
          }
        }, {
          start: {
            line: 134,
            column: 10
          },
          end: {
            line: 134,
            column: 17
          }
        }, {
          start: {
            line: 134,
            column: 18
          },
          end: {
            line: 135,
            column: 24
          }
        }, {
          start: {
            line: 136,
            column: 10
          },
          end: {
            line: 136,
            column: 17
          }
        }, {
          start: {
            line: 136,
            column: 18
          },
          end: {
            line: 137,
            column: 24
          }
        }, {
          start: {
            line: 138,
            column: 10
          },
          end: {
            line: 138,
            column: 17
          }
        }, {
          start: {
            line: 138,
            column: 18
          },
          end: {
            line: 139,
            column: 24
          }
        }, {
          start: {
            line: 140,
            column: 10
          },
          end: {
            line: 140,
            column: 17
          }
        }, {
          start: {
            line: 140,
            column: 18
          },
          end: {
            line: 141,
            column: 24
          }
        }, {
          start: {
            line: 142,
            column: 10
          },
          end: {
            line: 143,
            column: 24
          }
        }],
        line: 125
      },
      '14': {
        loc: {
          start: {
            line: 149,
            column: 10
          },
          end: {
            line: 151,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 149,
            column: 10
          },
          end: {
            line: 151,
            column: 11
          }
        }, {
          start: {
            line: 149,
            column: 10
          },
          end: {
            line: 151,
            column: 11
          }
        }],
        line: 149
      },
      '15': {
        loc: {
          start: {
            line: 152,
            column: 38
          },
          end: {
            line: 152,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 152,
            column: 38
          },
          end: {
            line: 152,
            column: 58
          }
        }, {
          start: {
            line: 152,
            column: 62
          },
          end: {
            line: 152,
            column: 64
          }
        }],
        line: 152
      },
      '16': {
        loc: {
          start: {
            line: 154,
            column: 12
          },
          end: {
            line: 158,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 154,
            column: 12
          },
          end: {
            line: 158,
            column: 13
          }
        }, {
          start: {
            line: 154,
            column: 12
          },
          end: {
            line: 158,
            column: 13
          }
        }],
        line: 154
      },
      '17': {
        loc: {
          start: {
            line: 161,
            column: 41
          },
          end: {
            line: 161,
            column: 95
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 161,
            column: 70
          },
          end: {
            line: 161,
            column: 90
          }
        }, {
          start: {
            line: 161,
            column: 93
          },
          end: {
            line: 161,
            column: 95
          }
        }],
        line: 161
      },
      '18': {
        loc: {
          start: {
            line: 166,
            column: 10
          },
          end: {
            line: 168,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 166,
            column: 10
          },
          end: {
            line: 168,
            column: 11
          }
        }, {
          start: {
            line: 166,
            column: 10
          },
          end: {
            line: 168,
            column: 11
          }
        }],
        line: 166
      },
      '19': {
        loc: {
          start: {
            line: 169,
            column: 10
          },
          end: {
            line: 171,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 169,
            column: 10
          },
          end: {
            line: 171,
            column: 11
          }
        }, {
          start: {
            line: 169,
            column: 10
          },
          end: {
            line: 171,
            column: 11
          }
        }],
        line: 169
      },
      '20': {
        loc: {
          start: {
            line: 169,
            column: 14
          },
          end: {
            line: 169,
            column: 67
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 169,
            column: 14
          },
          end: {
            line: 169,
            column: 32
          }
        }, {
          start: {
            line: 169,
            column: 36
          },
          end: {
            line: 169,
            column: 67
          }
        }],
        line: 169
      },
      '21': {
        loc: {
          start: {
            line: 173,
            column: 10
          },
          end: {
            line: 175,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 173,
            column: 10
          },
          end: {
            line: 175,
            column: 11
          }
        }, {
          start: {
            line: 173,
            column: 10
          },
          end: {
            line: 175,
            column: 11
          }
        }],
        line: 173
      },
      '22': {
        loc: {
          start: {
            line: 173,
            column: 14
          },
          end: {
            line: 173,
            column: 87
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 173,
            column: 14
          },
          end: {
            line: 173,
            column: 47
          }
        }, {
          start: {
            line: 173,
            column: 51
          },
          end: {
            line: 173,
            column: 87
          }
        }],
        line: 173
      },
      '23': {
        loc: {
          start: {
            line: 177,
            column: 10
          },
          end: {
            line: 179,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 177,
            column: 10
          },
          end: {
            line: 179,
            column: 11
          }
        }, {
          start: {
            line: 177,
            column: 10
          },
          end: {
            line: 179,
            column: 11
          }
        }],
        line: 177
      },
      '24': {
        loc: {
          start: {
            line: 177,
            column: 14
          },
          end: {
            line: 177,
            column: 161
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 177,
            column: 14
          },
          end: {
            line: 177,
            column: 65
          }
        }, {
          start: {
            line: 177,
            column: 69
          },
          end: {
            line: 177,
            column: 124
          }
        }, {
          start: {
            line: 177,
            column: 128
          },
          end: {
            line: 177,
            column: 161
          }
        }],
        line: 177
      },
      '25': {
        loc: {
          start: {
            line: 186,
            column: 10
          },
          end: {
            line: 190,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 186,
            column: 10
          },
          end: {
            line: 190,
            column: 11
          }
        }, {
          start: {
            line: 186,
            column: 10
          },
          end: {
            line: 190,
            column: 11
          }
        }],
        line: 186
      },
      '26': {
        loc: {
          start: {
            line: 186,
            column: 14
          },
          end: {
            line: 186,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 186,
            column: 14
          },
          end: {
            line: 186,
            column: 37
          }
        }, {
          start: {
            line: 186,
            column: 41
          },
          end: {
            line: 186,
            column: 64
          }
        }],
        line: 186
      },
      '27': {
        loc: {
          start: {
            line: 187,
            column: 12
          },
          end: {
            line: 189,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 187,
            column: 12
          },
          end: {
            line: 189,
            column: 13
          }
        }, {
          start: {
            line: 187,
            column: 12
          },
          end: {
            line: 189,
            column: 13
          }
        }],
        line: 187
      },
      '28': {
        loc: {
          start: {
            line: 197,
            column: 10
          },
          end: {
            line: 199,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 197,
            column: 10
          },
          end: {
            line: 199,
            column: 11
          }
        }, {
          start: {
            line: 197,
            column: 10
          },
          end: {
            line: 199,
            column: 11
          }
        }],
        line: 197
      },
      '29': {
        loc: {
          start: {
            line: 201,
            column: 10
          },
          end: {
            line: 203,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 201,
            column: 10
          },
          end: {
            line: 203,
            column: 11
          }
        }, {
          start: {
            line: 201,
            column: 10
          },
          end: {
            line: 203,
            column: 11
          }
        }],
        line: 201
      },
      '30': {
        loc: {
          start: {
            line: 205,
            column: 10
          },
          end: {
            line: 232,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 205,
            column: 10
          },
          end: {
            line: 232,
            column: 11
          }
        }, {
          start: {
            line: 205,
            column: 10
          },
          end: {
            line: 232,
            column: 11
          }
        }],
        line: 205
      },
      '31': {
        loc: {
          start: {
            line: 207,
            column: 12
          },
          end: {
            line: 214,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 207,
            column: 12
          },
          end: {
            line: 214,
            column: 13
          }
        }, {
          start: {
            line: 207,
            column: 12
          },
          end: {
            line: 214,
            column: 13
          }
        }],
        line: 207
      },
      '32': {
        loc: {
          start: {
            line: 215,
            column: 17
          },
          end: {
            line: 232,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 215,
            column: 17
          },
          end: {
            line: 232,
            column: 11
          }
        }, {
          start: {
            line: 215,
            column: 17
          },
          end: {
            line: 232,
            column: 11
          }
        }],
        line: 215
      },
      '33': {
        loc: {
          start: {
            line: 216,
            column: 12
          },
          end: {
            line: 221,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 216,
            column: 12
          },
          end: {
            line: 221,
            column: 13
          }
        }, {
          start: {
            line: 216,
            column: 12
          },
          end: {
            line: 221,
            column: 13
          }
        }],
        line: 216
      },
      '34': {
        loc: {
          start: {
            line: 224,
            column: 12
          },
          end: {
            line: 231,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 224,
            column: 12
          },
          end: {
            line: 231,
            column: 13
          }
        }, {
          start: {
            line: 224,
            column: 12
          },
          end: {
            line: 231,
            column: 13
          }
        }],
        line: 224
      },
      '35': {
        loc: {
          start: {
            line: 233,
            column: 10
          },
          end: {
            line: 235,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 233,
            column: 10
          },
          end: {
            line: 235,
            column: 11
          }
        }, {
          start: {
            line: 233,
            column: 10
          },
          end: {
            line: 235,
            column: 11
          }
        }],
        line: 233
      },
      '36': {
        loc: {
          start: {
            line: 239,
            column: 17
          },
          end: {
            line: 242,
            column: 11
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 239,
            column: 45
          },
          end: {
            line: 239,
            column: 67
          }
        }, {
          start: {
            line: 239,
            column: 70
          },
          end: {
            line: 242,
            column: 11
          }
        }],
        line: 239
      },
      '37': {
        loc: {
          start: {
            line: 246,
            column: 17
          },
          end: {
            line: 249,
            column: 11
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 246,
            column: 45
          },
          end: {
            line: 246,
            column: 67
          }
        }, {
          start: {
            line: 246,
            column: 70
          },
          end: {
            line: 249,
            column: 11
          }
        }],
        line: 246
      },
      '38': {
        loc: {
          start: {
            line: 253,
            column: 17
          },
          end: {
            line: 256,
            column: 11
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 253,
            column: 45
          },
          end: {
            line: 253,
            column: 67
          }
        }, {
          start: {
            line: 253,
            column: 70
          },
          end: {
            line: 256,
            column: 11
          }
        }],
        line: 253
      },
      '39': {
        loc: {
          start: {
            line: 270,
            column: 10
          },
          end: {
            line: 293,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 270,
            column: 10
          },
          end: {
            line: 293,
            column: 11
          }
        }, {
          start: {
            line: 270,
            column: 10
          },
          end: {
            line: 293,
            column: 11
          }
        }],
        line: 270
      },
      '40': {
        loc: {
          start: {
            line: 275,
            column: 17
          },
          end: {
            line: 293,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 275,
            column: 17
          },
          end: {
            line: 293,
            column: 11
          }
        }, {
          start: {
            line: 275,
            column: 17
          },
          end: {
            line: 293,
            column: 11
          }
        }],
        line: 275
      },
      '41': {
        loc: {
          start: {
            line: 280,
            column: 17
          },
          end: {
            line: 293,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 280,
            column: 17
          },
          end: {
            line: 293,
            column: 11
          }
        }, {
          start: {
            line: 280,
            column: 17
          },
          end: {
            line: 293,
            column: 11
          }
        }],
        line: 280
      },
      '42': {
        loc: {
          start: {
            line: 284,
            column: 17
          },
          end: {
            line: 293,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 284,
            column: 17
          },
          end: {
            line: 293,
            column: 11
          }
        }, {
          start: {
            line: 284,
            column: 17
          },
          end: {
            line: 293,
            column: 11
          }
        }],
        line: 284
      },
      '43': {
        loc: {
          start: {
            line: 295,
            column: 12
          },
          end: {
            line: 326,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 295,
            column: 12
          },
          end: {
            line: 326,
            column: 13
          }
        }, {
          start: {
            line: 295,
            column: 12
          },
          end: {
            line: 326,
            column: 13
          }
        }],
        line: 295
      },
      '44': {
        loc: {
          start: {
            line: 296,
            column: 14
          },
          end: {
            line: 325,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 296,
            column: 14
          },
          end: {
            line: 325,
            column: 15
          }
        }, {
          start: {
            line: 296,
            column: 14
          },
          end: {
            line: 325,
            column: 15
          }
        }],
        line: 296
      },
      '45': {
        loc: {
          start: {
            line: 297,
            column: 16
          },
          end: {
            line: 305,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 297,
            column: 16
          },
          end: {
            line: 305,
            column: 17
          }
        }, {
          start: {
            line: 297,
            column: 16
          },
          end: {
            line: 305,
            column: 17
          }
        }],
        line: 297
      },
      '46': {
        loc: {
          start: {
            line: 306,
            column: 21
          },
          end: {
            line: 325,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 306,
            column: 21
          },
          end: {
            line: 325,
            column: 15
          }
        }, {
          start: {
            line: 306,
            column: 21
          },
          end: {
            line: 325,
            column: 15
          }
        }],
        line: 306
      },
      '47': {
        loc: {
          start: {
            line: 322,
            column: 41
          },
          end: {
            line: 322,
            column: 106
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 322,
            column: 72
          },
          end: {
            line: 322,
            column: 74
          }
        }, {
          start: {
            line: 322,
            column: 78
          },
          end: {
            line: 322,
            column: 105
          }
        }],
        line: 322
      },
      '48': {
        loc: {
          start: {
            line: 352,
            column: 18
          },
          end: {
            line: 354,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 352,
            column: 18
          },
          end: {
            line: 354,
            column: 19
          }
        }, {
          start: {
            line: 352,
            column: 18
          },
          end: {
            line: 354,
            column: 19
          }
        }],
        line: 352
      },
      '49': {
        loc: {
          start: {
            line: 362,
            column: 14
          },
          end: {
            line: 364,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 362,
            column: 14
          },
          end: {
            line: 364,
            column: 15
          }
        }, {
          start: {
            line: 362,
            column: 14
          },
          end: {
            line: 364,
            column: 15
          }
        }],
        line: 362
      },
      '50': {
        loc: {
          start: {
            line: 365,
            column: 14
          },
          end: {
            line: 367,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 365,
            column: 14
          },
          end: {
            line: 367,
            column: 15
          }
        }, {
          start: {
            line: 365,
            column: 14
          },
          end: {
            line: 367,
            column: 15
          }
        }],
        line: 365
      },
      '51': {
        loc: {
          start: {
            line: 371,
            column: 29
          },
          end: {
            line: 371,
            column: 226
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 371,
            column: 49
          },
          end: {
            line: 371,
            column: 113
          }
        }, {
          start: {
            line: 371,
            column: 117
          },
          end: {
            line: 371,
            column: 225
          }
        }],
        line: 371
      },
      '52': {
        loc: {
          start: {
            line: 371,
            column: 117
          },
          end: {
            line: 371,
            column: 225
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 371,
            column: 132
          },
          end: {
            line: 371,
            column: 180
          }
        }, {
          start: {
            line: 371,
            column: 184
          },
          end: {
            line: 371,
            column: 225
          }
        }],
        line: 371
      },
      '53': {
        loc: {
          start: {
            line: 371,
            column: 132
          },
          end: {
            line: 371,
            column: 180
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 371,
            column: 132
          },
          end: {
            line: 371,
            column: 142
          }
        }, {
          start: {
            line: 371,
            column: 146
          },
          end: {
            line: 371,
            column: 162
          }
        }, {
          start: {
            line: 371,
            column: 166
          },
          end: {
            line: 371,
            column: 180
          }
        }],
        line: 371
      },
      '54': {
        loc: {
          start: {
            line: 373,
            column: 14
          },
          end: {
            line: 377,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 373,
            column: 14
          },
          end: {
            line: 377,
            column: 15
          }
        }, {
          start: {
            line: 373,
            column: 14
          },
          end: {
            line: 377,
            column: 15
          }
        }],
        line: 373
      },
      '55': {
        loc: {
          start: {
            line: 387,
            column: 10
          },
          end: {
            line: 390,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 387,
            column: 10
          },
          end: {
            line: 390,
            column: 11
          }
        }, {
          start: {
            line: 387,
            column: 10
          },
          end: {
            line: 390,
            column: 11
          }
        }],
        line: 387
      },
      '56': {
        loc: {
          start: {
            line: 392,
            column: 10
          },
          end: {
            line: 400,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 392,
            column: 10
          },
          end: {
            line: 400,
            column: 11
          }
        }, {
          start: {
            line: 392,
            column: 10
          },
          end: {
            line: 400,
            column: 11
          }
        }],
        line: 392
      },
      '57': {
        loc: {
          start: {
            line: 394,
            column: 12
          },
          end: {
            line: 396,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 394,
            column: 12
          },
          end: {
            line: 396,
            column: 13
          }
        }, {
          start: {
            line: 394,
            column: 12
          },
          end: {
            line: 396,
            column: 13
          }
        }],
        line: 394
      },
      '58': {
        loc: {
          start: {
            line: 404,
            column: 41
          },
          end: {
            line: 404,
            column: 95
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 404,
            column: 70
          },
          end: {
            line: 404,
            column: 90
          }
        }, {
          start: {
            line: 404,
            column: 93
          },
          end: {
            line: 404,
            column: 95
          }
        }],
        line: 404
      },
      '59': {
        loc: {
          start: {
            line: 418,
            column: 14
          },
          end: {
            line: 420,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 418,
            column: 14
          },
          end: {
            line: 420,
            column: 15
          }
        }, {
          start: {
            line: 418,
            column: 14
          },
          end: {
            line: 420,
            column: 15
          }
        }],
        line: 418
      },
      '60': {
        loc: {
          start: {
            line: 421,
            column: 66
          },
          end: {
            line: 421,
            column: 133
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 421,
            column: 125
          },
          end: {
            line: 421,
            column: 128
          }
        }, {
          start: {
            line: 421,
            column: 131
          },
          end: {
            line: 421,
            column: 133
          }
        }],
        line: 421
      },
      '61': {
        loc: {
          start: {
            line: 421,
            column: 67
          },
          end: {
            line: 421,
            column: 121
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 421,
            column: 67
          },
          end: {
            line: 421,
            column: 92
          }
        }, {
          start: {
            line: 421,
            column: 96
          },
          end: {
            line: 421,
            column: 121
          }
        }],
        line: 421
      },
      '62': {
        loc: {
          start: {
            line: 430,
            column: 14
          },
          end: {
            line: 437,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 430,
            column: 14
          },
          end: {
            line: 437,
            column: 15
          }
        }, {
          start: {
            line: 430,
            column: 14
          },
          end: {
            line: 437,
            column: 15
          }
        }],
        line: 430
      },
      '63': {
        loc: {
          start: {
            line: 434,
            column: 16
          },
          end: {
            line: 436,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 434,
            column: 16
          },
          end: {
            line: 436,
            column: 17
          }
        }, {
          start: {
            line: 434,
            column: 16
          },
          end: {
            line: 436,
            column: 17
          }
        }],
        line: 434
      },
      '64': {
        loc: {
          start: {
            line: 443,
            column: 19
          },
          end: {
            line: 443,
            column: 81
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 443,
            column: 49
          },
          end: {
            line: 443,
            column: 75
          }
        }, {
          start: {
            line: 443,
            column: 79
          },
          end: {
            line: 443,
            column: 81
          }
        }],
        line: 443
      },
      '65': {
        loc: {
          start: {
            line: 443,
            column: 49
          },
          end: {
            line: 443,
            column: 75
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 443,
            column: 49
          },
          end: {
            line: 443,
            column: 69
          }
        }, {
          start: {
            line: 443,
            column: 73
          },
          end: {
            line: 443,
            column: 75
          }
        }],
        line: 443
      },
      '66': {
        loc: {
          start: {
            line: 456,
            column: 80
          },
          end: {
            line: 456,
            column: 228
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 456,
            column: 106
          },
          end: {
            line: 456,
            column: 135
          }
        }, {
          start: {
            line: 456,
            column: 139
          },
          end: {
            line: 456,
            column: 227
          }
        }],
        line: 456
      },
      '67': {
        loc: {
          start: {
            line: 456,
            column: 139
          },
          end: {
            line: 456,
            column: 227
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 456,
            column: 165
          },
          end: {
            line: 456,
            column: 190
          }
        }, {
          start: {
            line: 456,
            column: 193
          },
          end: {
            line: 456,
            column: 227
          }
        }],
        line: 456
      },
      '68': {
        loc: {
          start: {
            line: 457,
            column: 10
          },
          end: {
            line: 461,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 457,
            column: 10
          },
          end: {
            line: 461,
            column: 11
          }
        }, {
          start: {
            line: 457,
            column: 10
          },
          end: {
            line: 461,
            column: 11
          }
        }],
        line: 457
      },
      '69': {
        loc: {
          start: {
            line: 457,
            column: 14
          },
          end: {
            line: 457,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 457,
            column: 14
          },
          end: {
            line: 457,
            column: 37
          }
        }, {
          start: {
            line: 457,
            column: 41
          },
          end: {
            line: 457,
            column: 64
          }
        }],
        line: 457
      },
      '70': {
        loc: {
          start: {
            line: 462,
            column: 10
          },
          end: {
            line: 464,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 462,
            column: 10
          },
          end: {
            line: 464,
            column: 11
          }
        }, {
          start: {
            line: 462,
            column: 10
          },
          end: {
            line: 464,
            column: 11
          }
        }],
        line: 462
      },
      '71': {
        loc: {
          start: {
            line: 467,
            column: 12
          },
          end: {
            line: 481,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 467,
            column: 12
          },
          end: {
            line: 481,
            column: 13
          }
        }, {
          start: {
            line: 467,
            column: 12
          },
          end: {
            line: 481,
            column: 13
          }
        }],
        line: 467
      },
      '72': {
        loc: {
          start: {
            line: 470,
            column: 83
          },
          end: {
            line: 470,
            column: 110
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 470,
            column: 83
          },
          end: {
            line: 470,
            column: 105
          }
        }, {
          start: {
            line: 470,
            column: 109
          },
          end: {
            line: 470,
            column: 110
          }
        }],
        line: 470
      },
      '73': {
        loc: {
          start: {
            line: 472,
            column: 16
          },
          end: {
            line: 475,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 472,
            column: 16
          },
          end: {
            line: 475,
            column: 17
          }
        }, {
          start: {
            line: 472,
            column: 16
          },
          end: {
            line: 475,
            column: 17
          }
        }],
        line: 472
      },
      '74': {
        loc: {
          start: {
            line: 472,
            column: 20
          },
          end: {
            line: 472,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 472,
            column: 20
          },
          end: {
            line: 472,
            column: 40
          }
        }, {
          start: {
            line: 472,
            column: 44
          },
          end: {
            line: 472,
            column: 64
          }
        }],
        line: 472
      },
      '75': {
        loc: {
          start: {
            line: 474,
            column: 33
          },
          end: {
            line: 474,
            column: 157
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 474,
            column: 53
          },
          end: {
            line: 474,
            column: 57
          }
        }, {
          start: {
            line: 474,
            column: 60
          },
          end: {
            line: 474,
            column: 157
          }
        }],
        line: 474
      },
      '76': {
        loc: {
          start: {
            line: 474,
            column: 129
          },
          end: {
            line: 474,
            column: 156
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 474,
            column: 129
          },
          end: {
            line: 474,
            column: 151
          }
        }, {
          start: {
            line: 474,
            column: 155
          },
          end: {
            line: 474,
            column: 156
          }
        }],
        line: 474
      },
      '77': {
        loc: {
          start: {
            line: 476,
            column: 16
          },
          end: {
            line: 479,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 476,
            column: 16
          },
          end: {
            line: 479,
            column: 17
          }
        }, {
          start: {
            line: 476,
            column: 16
          },
          end: {
            line: 479,
            column: 17
          }
        }],
        line: 476
      },
      '78': {
        loc: {
          start: {
            line: 476,
            column: 20
          },
          end: {
            line: 476,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 476,
            column: 20
          },
          end: {
            line: 476,
            column: 40
          }
        }, {
          start: {
            line: 476,
            column: 44
          },
          end: {
            line: 476,
            column: 64
          }
        }],
        line: 476
      },
      '79': {
        loc: {
          start: {
            line: 478,
            column: 33
          },
          end: {
            line: 478,
            column: 157
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 478,
            column: 53
          },
          end: {
            line: 478,
            column: 57
          }
        }, {
          start: {
            line: 478,
            column: 60
          },
          end: {
            line: 478,
            column: 157
          }
        }],
        line: 478
      },
      '80': {
        loc: {
          start: {
            line: 478,
            column: 129
          },
          end: {
            line: 478,
            column: 156
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 478,
            column: 129
          },
          end: {
            line: 478,
            column: 151
          }
        }, {
          start: {
            line: 478,
            column: 155
          },
          end: {
            line: 478,
            column: 156
          }
        }],
        line: 478
      },
      '81': {
        loc: {
          start: {
            line: 490,
            column: 25
          },
          end: {
            line: 502,
            column: 12
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 490,
            column: 51
          },
          end: {
            line: 495,
            column: 11
          }
        }, {
          start: {
            line: 495,
            column: 15
          },
          end: {
            line: 502,
            column: 11
          }
        }],
        line: 490
      },
      '82': {
        loc: {
          start: {
            line: 495,
            column: 15
          },
          end: {
            line: 502,
            column: 11
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 495,
            column: 41
          },
          end: {
            line: 500,
            column: 11
          }
        }, {
          start: {
            line: 500,
            column: 14
          },
          end: {
            line: 502,
            column: 11
          }
        }],
        line: 495
      },
      '83': {
        loc: {
          start: {
            line: 506,
            column: 6
          },
          end: {
            line: 508,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 506,
            column: 6
          },
          end: {
            line: 508,
            column: 7
          }
        }, {
          start: {
            line: 506,
            column: 6
          },
          end: {
            line: 508,
            column: 7
          }
        }],
        line: 506
      },
      '84': {
        loc: {
          start: {
            line: 513,
            column: 8
          },
          end: {
            line: 516,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 513,
            column: 8
          },
          end: {
            line: 516,
            column: 9
          }
        }, {
          start: {
            line: 513,
            column: 8
          },
          end: {
            line: 516,
            column: 9
          }
        }],
        line: 513
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '13': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0],
      '58': [0, 0],
      '59': [0, 0],
      '60': [0, 0],
      '61': [0, 0],
      '62': [0, 0],
      '63': [0, 0],
      '64': [0, 0],
      '65': [0, 0],
      '66': [0, 0],
      '67': [0, 0],
      '68': [0, 0],
      '69': [0, 0],
      '70': [0, 0],
      '71': [0, 0],
      '72': [0, 0],
      '73': [0, 0],
      '74': [0, 0],
      '75': [0, 0],
      '76': [0, 0],
      '77': [0, 0],
      '78': [0, 0],
      '79': [0, 0],
      '80': [0, 0],
      '81': [0, 0],
      '82': [0, 0],
      '83': [0, 0],
      '84': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_5h6kat0m0.s[0]++;

angular.module('app').directive('dashboardDonut', function ($timeout) {
  cov_5h6kat0m0.f[0]++;
  cov_5h6kat0m0.s[1]++;

  return {
    restrict: 'E',
    scope: {
      idcomponent: '@',
      data: '=',
      analyse: '=',
      titleDashboard: '@',
      height: '@',
      print: '=',
      hidebutton: '=',
      tooltipProperty: '@?'
    },
    templateUrl: 'app/views/common/dashboard-donut.html',
    controller: function controller($scope, $deltadate, $rootScope, $deltaPeriode, $translate, $filter, $CRUDService, $deltaNumber) {
      cov_5h6kat0m0.s[2]++;

      if (!$scope.tooltipProperty) {
        cov_5h6kat0m0.b[0][0]++;
        cov_5h6kat0m0.s[3]++;

        $scope.tooltipProperty = 'percentage';
      } else {
        cov_5h6kat0m0.b[0][1]++;
      }
      cov_5h6kat0m0.s[4]++;
      $scope.ISINCLUDEDRAPPORT = $rootScope.getISINCLUDEDRAPPORT();
      var PATH = (cov_5h6kat0m0.s[5]++, 'Analyse');
      var PATH_CAT = (cov_5h6kat0m0.s[6]++, 'CategoriesBudget');
      cov_5h6kat0m0.s[7]++;
      $scope.periodsList = [];
      cov_5h6kat0m0.s[8]++;
      $scope.lastSelectedItem = [];
      var palettes = (cov_5h6kat0m0.s[9]++, ['#BAF171', '#C4EAF6', '#E9B1FF', '#F49C94', '#FCF086', '#58D740', '#7DDAF8', '#CA6FEE', '#FE855F', '#F9E850', '#1EB799', '#29DAE9', '#B145F4', '#CD5145', '#EDDA2F', '#2EAB77', '#20C9B3', '#6F43EA', '#EB5757', '#EDC32F', '#66AF45', '#33ACED', '#562EBB', '#DA4A2B', '#D39F17', '#78A73D', '#208AC9', '#792AF9', '#F35634', '#B8953B', '#A5B212', '#3160BC', '#651E91', '#AE534B', '#F38434', '#12A5B2', '#5C83E7', '#911E71', '#7E3737', '#D36617', '#2F7980', '#035C88', '#401C5B', '#5E4138', '#B66225', '#0E3E53', '#093B69', '#240E35', '#3E2B11', '#B24712']);
      cov_5h6kat0m0.s[10]++;
      if (!$scope.idcomponent) {
        cov_5h6kat0m0.b[1][0]++;
        cov_5h6kat0m0.s[11]++;

        $scope.idcomponent = new Date().getTime();
      } else {
        cov_5h6kat0m0.b[1][1]++;
      }

      cov_5h6kat0m0.s[12]++;
      $scope.getCadreLogiqueLabel = function (item) {
        cov_5h6kat0m0.f[1]++;
        cov_5h6kat0m0.s[13]++;

        return $rootScope.getCadreLogiqueLabel(item);
      };

      cov_5h6kat0m0.s[14]++;
      if ($scope.analyse.ID_INDIC) {
        cov_5h6kat0m0.b[2][0]++;
        cov_5h6kat0m0.s[15]++;

        $CRUDService.getAll(PATH, { get: 'single_indicator', id: $scope.analyse.ID_INDIC, p_bksb_: $scope.analyse.ID_PROJET }, function (data_) {
          cov_5h6kat0m0.f[2]++;
          cov_5h6kat0m0.s[16]++;

          if (data_.length < 0) {
            cov_5h6kat0m0.b[3][0]++;
            cov_5h6kat0m0.s[17]++;

            return;
          } else {
            cov_5h6kat0m0.b[3][1]++;
          }
          cov_5h6kat0m0.s[18]++;
          $scope.indicatorFormat = data_[0].format;
          var data = (cov_5h6kat0m0.s[19]++, data_[0]);
          cov_5h6kat0m0.s[20]++;
          $scope.dataperiodeGlobal = [{ label: 'Global', id: '0_0_0', leveld: 0, open: true }];
          cov_5h6kat0m0.s[21]++;
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = $deltadate.getYearBetween(Date.newDate(data.begin), Date.newDate(data.end))[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var el = _step.value;
              cov_5h6kat0m0.s[22]++;

              $scope.dataperiodeGlobal.push({ label: el.id, id: el.id + '_1_1', leveld: 1, open: false });
              var list = (cov_5h6kat0m0.s[23]++, $deltadate.getYearPeriod(el.id, data.period));
              cov_5h6kat0m0.s[24]++;
              if (list.length < 2) {
                cov_5h6kat0m0.b[4][0]++;
                cov_5h6kat0m0.s[25]++;

                continue;
              } else {
                cov_5h6kat0m0.b[4][1]++;
              }
              cov_5h6kat0m0.s[26]++;
              var _iteratorNormalCompletion2 = true;
              var _didIteratorError2 = false;
              var _iteratorError2 = undefined;

              try {
                for (var _iterator2 = list[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                  var ite = _step2.value;
                  cov_5h6kat0m0.s[27]++;

                  $scope.dataperiodeGlobal.push({ label: $filter('date')(ite.end, 'mediumDate'), id: el.id + '_' + ite.index + '_' + data.period, leveld: 2, leaf: true, year: el.id });
                }
              } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion2 && _iterator2.return) {
                    _iterator2.return();
                  }
                } finally {
                  if (_didIteratorError2) {
                    throw _iteratorError2;
                  }
                }
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          cov_5h6kat0m0.s[28]++;
          $scope.process.open();
        });
      } else {
        cov_5h6kat0m0.b[2][1]++;
        cov_5h6kat0m0.s[29]++;

        $deltaPeriode.addController($scope, function () {
          cov_5h6kat0m0.f[3]++;
          cov_5h6kat0m0.s[30]++;

          $scope.dataperiodeGlobal = [{ label: 'Global', id: '0_0_0', leveld: 0, open: true }];
          cov_5h6kat0m0.s[31]++;
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = $scope.listData_Periode_bksb[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var el = _step3.value;
              cov_5h6kat0m0.s[32]++;

              $scope.dataperiodeGlobal.push({ label: el.ANNEE_PERIOD, id: el.ANNEE_PERIOD + '_1_1', leveld: 1, open: false });
              cov_5h6kat0m0.s[33]++;
              if (el.DECOUPAGE_PERIOD == 0) {
                cov_5h6kat0m0.b[5][0]++;
                cov_5h6kat0m0.s[34]++;

                continue;
              } else {
                cov_5h6kat0m0.b[5][1]++;
              }
              var list = (cov_5h6kat0m0.s[35]++, $deltadate.getYearPeriod(el.ANNEE_PERIOD, el.DECOUPAGE_PERIOD));
              cov_5h6kat0m0.s[36]++;
              if (list.length < 2) {
                cov_5h6kat0m0.b[6][0]++;
                cov_5h6kat0m0.s[37]++;

                continue;
              } else {
                cov_5h6kat0m0.b[6][1]++;
              }
              cov_5h6kat0m0.s[38]++;
              var _iteratorNormalCompletion4 = true;
              var _didIteratorError4 = false;
              var _iteratorError4 = undefined;

              try {
                for (var _iterator4 = list[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                  var ite = _step4.value;
                  cov_5h6kat0m0.s[39]++;

                  $scope.dataperiodeGlobal.push({ label: $filter('date')(ite.end, 'mediumDate'), id: el.ANNEE_PERIOD + '_' + ite.index + '_' + el.DECOUPAGE_PERIOD, leveld: 2, leaf: true });
                }
              } catch (err) {
                _didIteratorError4 = true;
                _iteratorError4 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion4 && _iterator4.return) {
                    _iterator4.return();
                  }
                } finally {
                  if (_didIteratorError4) {
                    throw _iteratorError4;
                  }
                }
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }
        });
      }

      cov_5h6kat0m0.s[40]++;
      $scope.filteData_date = function (item, index, items) {
        cov_5h6kat0m0.f[4]++;
        cov_5h6kat0m0.s[41]++;

        if (item.id == '0_0_0') {
          cov_5h6kat0m0.b[7][0]++;
          cov_5h6kat0m0.s[42]++;

          return true;
        } else {
          cov_5h6kat0m0.b[7][1]++;
        }
        cov_5h6kat0m0.s[43]++;
        if (!items[0].open) {
          cov_5h6kat0m0.b[8][0]++;
          cov_5h6kat0m0.s[44]++;

          return false;
        } else {
          cov_5h6kat0m0.b[8][1]++;
        }
        cov_5h6kat0m0.s[45]++;
        if (item.label.length == 4) {
          cov_5h6kat0m0.b[9][0]++;
          cov_5h6kat0m0.s[46]++;

          return true;
        } else {
          cov_5h6kat0m0.b[9][1]++;
        }
        var year = (cov_5h6kat0m0.s[47]++, item.id.substring(0, 4));
        cov_5h6kat0m0.s[48]++;
        for (var i = index - 1; i >= 0; i--) {
          cov_5h6kat0m0.s[49]++;

          if (year == items[i].label) {
            cov_5h6kat0m0.b[10][0]++;
            cov_5h6kat0m0.s[50]++;

            return items[i].open;
          } else {
            cov_5h6kat0m0.b[10][1]++;
          }
        }
        cov_5h6kat0m0.s[51]++;
        return false;
      };
      cov_5h6kat0m0.s[52]++;
      $scope.getCorrectIndex = function (index) {
        cov_5h6kat0m0.f[5]++;
        cov_5h6kat0m0.s[53]++;

        if ($scope.analyse.ID_INDIC) {
          cov_5h6kat0m0.b[11][0]++;
          cov_5h6kat0m0.s[54]++;

          switch (index) {
            case 8:
              cov_5h6kat0m0.b[12][0]++;
            case '8':
              cov_5h6kat0m0.b[12][1]++;
              cov_5h6kat0m0.s[55]++;

              return 'cp';
            case 7:
              cov_5h6kat0m0.b[12][2]++;
            case '7':
              cov_5h6kat0m0.b[12][3]++;
              cov_5h6kat0m0.s[56]++;

              return 'cr';
            case 6:
              cov_5h6kat0m0.b[12][4]++;
            case '6':
              cov_5h6kat0m0.b[12][5]++;
              cov_5h6kat0m0.s[57]++;

              return 'ce';
            case 2:
              cov_5h6kat0m0.b[12][6]++;
            case '2':
              cov_5h6kat0m0.b[12][7]++;
              cov_5h6kat0m0.s[58]++;

              return 've';
            case 4:
              cov_5h6kat0m0.b[12][8]++;
            case '4':
              cov_5h6kat0m0.b[12][9]++;
              cov_5h6kat0m0.s[59]++;

              return 'vp';
            case 3:
              cov_5h6kat0m0.b[12][10]++;
            case '3':
              cov_5h6kat0m0.b[12][11]++;
              cov_5h6kat0m0.s[60]++;

              return 'vr';
            case 5:
              cov_5h6kat0m0.b[12][12]++;
            case '5':
              cov_5h6kat0m0.b[12][13]++;
              cov_5h6kat0m0.s[61]++;

              return 'tc';
            case 1:
              cov_5h6kat0m0.b[12][14]++;
            case '1':
              cov_5h6kat0m0.b[12][15]++;
              cov_5h6kat0m0.s[62]++;

              return 'tr';
            default:
              cov_5h6kat0m0.b[12][16]++;
              cov_5h6kat0m0.s[63]++;

              return 'tr';
          }
        } else {
          cov_5h6kat0m0.b[11][1]++;
        }

        cov_5h6kat0m0.s[64]++;
        switch (index) {
          case 8:
            cov_5h6kat0m0.b[13][0]++;
          case '8':
            cov_5h6kat0m0.b[13][1]++;
            cov_5h6kat0m0.s[65]++;

            return 'BB';
          case 7:
            cov_5h6kat0m0.b[13][2]++;
          case '7':
            cov_5h6kat0m0.b[13][3]++;
            cov_5h6kat0m0.s[66]++;

            return 'CB';
          case 6:
            cov_5h6kat0m0.b[13][4]++;
          case '6':
            cov_5h6kat0m0.b[13][5]++;
            cov_5h6kat0m0.s[67]++;

            return 'EB';
          case 2:
            cov_5h6kat0m0.b[13][6]++;
          case '2':
            cov_5h6kat0m0.b[13][7]++;
            cov_5h6kat0m0.s[68]++;

            return 'EP';
          case 4:
            cov_5h6kat0m0.b[13][8]++;
          case '4':
            cov_5h6kat0m0.b[13][9]++;
            cov_5h6kat0m0.s[69]++;

            return 'PP';
          case 3:
            cov_5h6kat0m0.b[13][10]++;
          case '3':
            cov_5h6kat0m0.b[13][11]++;
            cov_5h6kat0m0.s[70]++;

            return 'RP';
          case 5:
            cov_5h6kat0m0.b[13][12]++;
          case '5':
            cov_5h6kat0m0.b[13][13]++;
            cov_5h6kat0m0.s[71]++;

            return 'TB';
          case 1:
            cov_5h6kat0m0.b[13][14]++;
          case '1':
            cov_5h6kat0m0.b[13][15]++;
            cov_5h6kat0m0.s[72]++;

            return 'TP';
          default:
            cov_5h6kat0m0.b[13][16]++;
            cov_5h6kat0m0.s[73]++;

            return 'TP';
        }
      };

      cov_5h6kat0m0.s[74]++;
      $scope.process = {
        export_data: function export_data(type) {
          cov_5h6kat0m0.s[75]++;

          if (!$scope.chartDesign) {
            cov_5h6kat0m0.b[14][0]++;
            cov_5h6kat0m0.s[76]++;

            return;
          } else {
            cov_5h6kat0m0.b[14][1]++;
          }
          cov_5h6kat0m0.s[77]++;
          $scope.chartDesign.title = (cov_5h6kat0m0.b[15][0]++, $scope.analyse.TITLE) || (cov_5h6kat0m0.b[15][1]++, '');
          cov_5h6kat0m0.s[78]++;
          $timeout(function () {
            cov_5h6kat0m0.f[6]++;
            cov_5h6kat0m0.s[79]++;

            if (type == 1) {
              cov_5h6kat0m0.b[16][0]++;
              cov_5h6kat0m0.s[80]++;

              $scope.chartDesign.export('PNG', $scope.analyse.TITLE);
            } else {
              cov_5h6kat0m0.b[16][1]++;
              cov_5h6kat0m0.s[81]++;

              $scope.chartDesign.export('PDF', $scope.analyse.TITLE);
            }

            cov_5h6kat0m0.s[82]++;
            $timeout(function () {
              cov_5h6kat0m0.f[7]++;
              cov_5h6kat0m0.s[83]++;

              $scope.chartDesign.title = $scope.titleDashboard == 1 ? (cov_5h6kat0m0.b[17][0]++, $scope.analyse.TITLE) : (cov_5h6kat0m0.b[17][1]++, '');
            }, 1500);
          }, 1500);
        },
        filter_date: function filter_date(item) {
          cov_5h6kat0m0.s[84]++;

          if ($scope.analyse.AXE != 2) {
            cov_5h6kat0m0.b[18][0]++;
            cov_5h6kat0m0.s[85]++;

            return false;
          } else {
            cov_5h6kat0m0.b[18][1]++;
          }
          cov_5h6kat0m0.s[86]++;
          if ((cov_5h6kat0m0.b[20][0]++, item.id == '0_0_0') && (cov_5h6kat0m0.b[20][1]++, $scope.process.itemCol.id == '')) {
            cov_5h6kat0m0.b[19][0]++;
            cov_5h6kat0m0.s[87]++;

            return true;
          } else {
            cov_5h6kat0m0.b[19][1]++;
          }

          cov_5h6kat0m0.s[88]++;
          if ((cov_5h6kat0m0.b[22][0]++, item.id.substring(4, 8) == '_1_1') && (cov_5h6kat0m0.b[22][1]++, $scope.process.itemCol.id == '0_0_0')) {
            cov_5h6kat0m0.b[21][0]++;
            cov_5h6kat0m0.s[89]++;

            return true;
          } else {
            cov_5h6kat0m0.b[21][1]++;
          }

          cov_5h6kat0m0.s[90]++;
          if ((cov_5h6kat0m0.b[24][0]++, $scope.process.itemCol.id.substring(4, 8) == '_1_1') && (cov_5h6kat0m0.b[24][1]++, item.id.substring(0, 4) == $scope.process.itemCol.label) && (cov_5h6kat0m0.b[24][2]++, item.id.substring(4, 8) != '_1_1')) {
            cov_5h6kat0m0.b[23][0]++;
            cov_5h6kat0m0.s[91]++;

            return true;
          } else {
            cov_5h6kat0m0.b[23][1]++;
          }

          cov_5h6kat0m0.s[92]++;
          return false;
        },
        deroule: function deroule(index /* , serie */) {
          var item = (cov_5h6kat0m0.s[93]++, this.grapheParams.items[index]);
          cov_5h6kat0m0.s[94]++;
          if ((cov_5h6kat0m0.b[26][0]++, $scope.analyse.AXE != 3) && (cov_5h6kat0m0.b[26][1]++, $scope.analyse.AXE != 1)) {
            cov_5h6kat0m0.b[25][0]++;
            cov_5h6kat0m0.s[95]++;

            if (item.leveld == 2) {
              cov_5h6kat0m0.b[27][0]++;
              cov_5h6kat0m0.s[96]++;

              return;
            } else {
              cov_5h6kat0m0.b[27][1]++;
            }
          } else {
            cov_5h6kat0m0.b[25][1]++;
          }
          cov_5h6kat0m0.s[97]++;
          $scope.lastSelectedItem.push(angular.copy(this.itemCol));
          cov_5h6kat0m0.s[98]++;
          this.itemCol = item;
          cov_5h6kat0m0.s[99]++;
          this.loadData();
        },
        enroule: function enroule() {
          cov_5h6kat0m0.s[100]++;

          if ($scope.lastSelectedItem.length == 0) {
            cov_5h6kat0m0.b[28][0]++;
            cov_5h6kat0m0.s[101]++;

            return;
          } else {
            cov_5h6kat0m0.b[28][1]++;
          }
          var lastId = (cov_5h6kat0m0.s[102]++, 'BKSB');
          cov_5h6kat0m0.s[103]++;
          if (this.itemCol) {
            cov_5h6kat0m0.b[29][0]++;
            cov_5h6kat0m0.s[104]++;

            lastId = this.itemCol.id;
          } else {
            cov_5h6kat0m0.b[29][1]++;
          }
          cov_5h6kat0m0.s[105]++;
          this.itemCol = $scope.lastSelectedItem.pop();
          cov_5h6kat0m0.s[106]++;
          if ($scope.analyse.AXE == 3) {
            cov_5h6kat0m0.b[30][0]++;
            cov_5h6kat0m0.s[107]++;

            // this.itemCol = ($filter('filter')($scope.listData_bksb, {id: this.itemCol.CODE_LP}, true))[0];
            if (!this.itemCol) {
              cov_5h6kat0m0.b[31][0]++;
              cov_5h6kat0m0.s[108]++;

              this.itemCol = {
                level: 0,
                CODE_LC: '',
                id: null,
                CODE_LP: -1
              };
            } else {
              cov_5h6kat0m0.b[31][1]++;
            }
          } else {
              cov_5h6kat0m0.b[30][1]++;
              cov_5h6kat0m0.s[109]++;
              if ($scope.analyse.AXE == 2) {
                cov_5h6kat0m0.b[32][0]++;
                cov_5h6kat0m0.s[110]++;

                if (!this.itemCol) {
                  cov_5h6kat0m0.b[33][0]++;
                  cov_5h6kat0m0.s[111]++;

                  this.itemCol = {
                    leveld: 1,
                    CODE_CLC: '0_0_0'
                  };
                } else {
                  cov_5h6kat0m0.b[33][1]++;
                }
              } else {
                cov_5h6kat0m0.b[32][1]++;
                cov_5h6kat0m0.s[112]++;

                //  this.itemCol = ($filter('filter')($scope.listData_pl_bksb, {id: this.itemCol.CODE_CLP}, true))[0];
                if (!this.itemCol) {
                  cov_5h6kat0m0.b[34][0]++;
                  cov_5h6kat0m0.s[113]++;

                  this.itemCol = {
                    level: 0,
                    CODE_CLC: '',
                    id: null,
                    CODE_CLP: -1
                  };
                } else {
                  cov_5h6kat0m0.b[34][1]++;
                }
              }
            }cov_5h6kat0m0.s[114]++;
          if (lastId != this.itemCol.id) {
            cov_5h6kat0m0.b[35][0]++;
            cov_5h6kat0m0.s[115]++;

            this.loadData();
          } else {
            cov_5h6kat0m0.b[35][1]++;
          }
        },
        __loadData_planlogique: function __loadData_planlogique(item) {
          cov_5h6kat0m0.s[116]++;

          $scope.data = {};
          cov_5h6kat0m0.s[117]++;
          return $scope.analyse.ID_INDIC ? (cov_5h6kat0m0.b[36][0]++, { id_: this.itemCol.id }) : (cov_5h6kat0m0.b[36][1]++, {
            a: this.itemCol.CODE_CLC,
            la: this.itemCol.level
          });
        },
        __loadData_plangeographique: function __loadData_plangeographique(item) {
          cov_5h6kat0m0.s[118]++;

          $scope.data = {};
          cov_5h6kat0m0.s[119]++;
          return $scope.analyse.ID_INDIC ? (cov_5h6kat0m0.b[37][0]++, { id_: this.itemCol.id }) : (cov_5h6kat0m0.b[37][1]++, {
            a: this.itemCol.CODE_LC,
            la: this.itemCol.level
          });
        },
        __loadData_periode: function __loadData_periode() {
          cov_5h6kat0m0.s[120]++;

          $scope.data = {};
          cov_5h6kat0m0.s[121]++;
          return $scope.analyse.ID_INDIC ? (cov_5h6kat0m0.b[38][0]++, { id_: this.itemCol.id }) : (cov_5h6kat0m0.b[38][1]++, {
            a: this.itemCol.id,
            la: 0
          });
        },
        __launchDrawing: function __launchDrawing() {
          var _this = this;

          // Search child

          // Get Concerne Data
          var params_ = (cov_5h6kat0m0.s[122]++, {
            data: { get: 'all', p_bksb_: $scope.analyse.ID_PROJET, simple: 1 },
            path: ''
          });

          cov_5h6kat0m0.s[123]++;
          $scope.amountTotal = 0;
          var id = (cov_5h6kat0m0.s[124]++, '');
          var items = (cov_5h6kat0m0.s[125]++, []);
          cov_5h6kat0m0.s[126]++;
          if ($scope.analyse.AXE == 3) {
            cov_5h6kat0m0.b[39][0]++;
            cov_5h6kat0m0.s[127]++;

            id = 'CODE_LC';
            cov_5h6kat0m0.s[128]++;
            params_.path = 'Localites';
            cov_5h6kat0m0.s[129]++;
            params_.data.id = this.itemCol.id;
            // items = $filter('filter')($scope.listData_bksb, {CODE_LP: this.itemCol.id}, true);
          } else {
              cov_5h6kat0m0.b[39][1]++;
              cov_5h6kat0m0.s[130]++;
              if ($scope.analyse.AXE == 2) {
                cov_5h6kat0m0.b[40][0]++;
                cov_5h6kat0m0.s[131]++;

                params_.path = 'Periode';
                cov_5h6kat0m0.s[132]++;
                params_.data.id = this.itemCol.id;
                cov_5h6kat0m0.s[133]++;
                id = 'id';
                // items = $scope.dataperiodeGlobal;
              } else {
                  cov_5h6kat0m0.b[40][1]++;
                  cov_5h6kat0m0.s[134]++;
                  if ($scope.analyse.AXE == 4) {
                    cov_5h6kat0m0.b[41][0]++;
                    cov_5h6kat0m0.s[135]++;

                    params_.path = 'Financement';
                    cov_5h6kat0m0.s[136]++;
                    id = 'id';
                    // items = $scope.listData_FinancialSource_bksb;
                  } else {
                      cov_5h6kat0m0.b[41][1]++;
                      cov_5h6kat0m0.s[137]++;
                      if ($scope.analyse.AXE == 5) {
                        cov_5h6kat0m0.b[42][0]++;
                        cov_5h6kat0m0.s[138]++;

                        params_.path = 'CategoriesBudget';
                        cov_5h6kat0m0.s[139]++;
                        id = 'id';
                        // items = $scope.listData_CategoriesBudget_bksb;
                      } else {
                        cov_5h6kat0m0.b[42][1]++;
                        cov_5h6kat0m0.s[140]++;

                        params_.path = 'CadreLogique';
                        cov_5h6kat0m0.s[141]++;
                        id = 'CODE_CLC';
                        cov_5h6kat0m0.s[142]++;
                        params_.data.id = this.itemCol.id;
                        // items = $filter('filter')($scope.listData_pl_bksb, {CODE_CLP: this.itemCol.id}, true);
                      }
                    }
                }
            }cov_5h6kat0m0.s[143]++;
          $CRUDService.getAll(params_.path, params_.data, function (items) {
            cov_5h6kat0m0.f[8]++;
            cov_5h6kat0m0.s[144]++;

            if ($scope.analyse.AXE == 2) {
              cov_5h6kat0m0.b[43][0]++;
              cov_5h6kat0m0.s[145]++;

              if ($scope.analyse.ID_INDIC) {
                cov_5h6kat0m0.b[44][0]++;
                cov_5h6kat0m0.s[146]++;

                if (params_.data.id == '0_0_0') {
                  cov_5h6kat0m0.b[45][0]++;
                  cov_5h6kat0m0.s[147]++;

                  items = $scope.dataperiodeGlobal.filter(function (year) {
                    cov_5h6kat0m0.f[9]++;
                    cov_5h6kat0m0.s[148]++;

                    return year.leveld == 1;
                  });
                } else {
                  cov_5h6kat0m0.b[45][1]++;
                  cov_5h6kat0m0.s[149]++;

                  items = $scope.dataperiodeGlobal.filter(function (item) {
                    cov_5h6kat0m0.f[10]++;
                    cov_5h6kat0m0.s[150]++;

                    return item.year == params_.data.id.split('_')[0];
                  });
                }
              } else {
                  cov_5h6kat0m0.b[44][1]++;
                  cov_5h6kat0m0.s[151]++;
                  if (params_.data.id == '0_0_0') {
                    cov_5h6kat0m0.b[46][0]++;
                    cov_5h6kat0m0.s[152]++;

                    items = items.map(function (period) {
                      cov_5h6kat0m0.f[11]++;
                      cov_5h6kat0m0.s[153]++;

                      return {
                        leveld: 1,
                        id: period.ANNEE_PERIOD + '_1_1',
                        label: period.ANNEE_PERIOD
                      };
                    });
                  } else {
                    cov_5h6kat0m0.b[46][1]++;

                    var myYear = (cov_5h6kat0m0.s[154]++, items.filter(function (item) {
                      cov_5h6kat0m0.f[12]++;
                      cov_5h6kat0m0.s[155]++;

                      return item.ANNEE_PERIOD == params_.data.id.split('_')[0];
                    })[0]);
                    cov_5h6kat0m0.s[156]++;
                    items = $deltadate.getYearPeriod(myYear.ANNEE_PERIOD, myYear.DECOUPAGE_PERIOD, false).map(function (item) {
                      cov_5h6kat0m0.f[13]++;
                      cov_5h6kat0m0.s[157]++;

                      return {
                        leveld: 2,
                        id: myYear.ANNEE_PERIOD + '_' + item.index + '_' + myYear.DECOUPAGE_PERIOD,
                        label: item.label + (myYear.DECOUPAGE_PERIOD == 1 ? (cov_5h6kat0m0.b[47][0]++, '') : (cov_5h6kat0m0.b[47][1]++, ' - ' + myYear.ANNEE_PERIOD))
                      };
                    });
                  }
                }
            } else {
              cov_5h6kat0m0.b[43][1]++;
            }
            cov_5h6kat0m0.s[158]++;
            _this.grapheParams = {
              data: [],
              labels: [],
              colors: [],
              items: [],
              options: {
                tooltips: {
                  callbacks: {
                    label: function label(tooltipItem, data) {
                      var TotalValue = (cov_5h6kat0m0.s[159]++, data.datasets[tooltipItem.datasetIndex].data.reduce(function (a, b) {
                        cov_5h6kat0m0.f[14]++;
                        cov_5h6kat0m0.s[160]++;

                        return parseFloat(a) + parseFloat(b);
                      }));
                      var myValue = (cov_5h6kat0m0.s[161]++, data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
                      var tooltiplabel = (cov_5h6kat0m0.s[162]++, $filter('number')(100 * myValue / TotalValue, 2) + '% | ');
                      cov_5h6kat0m0.s[163]++;
                      tooltiplabel += $filter('number')(myValue);
                      cov_5h6kat0m0.s[164]++;
                      return tooltiplabel;
                      // return  [$filter("number")(100*myValue/TotalValue, 2)+"%", $filter("number")(myValue)];
                    }
                  } },
                legend: {
                  display: true,
                  position: 'top'
                },
                maintainAspectRatio: false,
                onClick: function onClick(params, element) {
                  cov_5h6kat0m0.s[165]++;

                  if (angular.isUndefined(element[0])) {
                    cov_5h6kat0m0.b[48][0]++;
                    cov_5h6kat0m0.s[166]++;

                    return;
                  } else {
                    cov_5h6kat0m0.b[48][1]++;
                  }
                  cov_5h6kat0m0.s[167]++;
                  $scope.process.deroule(element[0]._index);
                }
              }
            };
            var params = (cov_5h6kat0m0.s[168]++, []);
            var index_ = (cov_5h6kat0m0.s[169]++, $scope.getCorrectIndex($scope.analyse.FIELD));
            cov_5h6kat0m0.s[170]++;
            var _iteratorNormalCompletion5 = true;
            var _didIteratorError5 = false;
            var _iteratorError5 = undefined;

            try {
              var _loop = function _loop() {
                var item = _step5.value;
                cov_5h6kat0m0.s[171]++;

                if (angular.isUndefined($scope.data[item[id]])) {
                  cov_5h6kat0m0.b[49][0]++;
                  cov_5h6kat0m0.s[172]++;

                  return 'continue';
                } else {
                  cov_5h6kat0m0.b[49][1]++;
                }
                cov_5h6kat0m0.s[173]++;
                if (angular.isUndefined($scope.data[item[id]][index_])) {
                  cov_5h6kat0m0.b[50][0]++;
                  cov_5h6kat0m0.s[174]++;

                  return 'continue';
                } else {
                  cov_5h6kat0m0.b[50][1]++;
                }

                var _ = (cov_5h6kat0m0.s[175]++, $scope.data[item[id]][index_]);

                var label = (cov_5h6kat0m0.s[176]++, id === 'CODE_CLC' ? (cov_5h6kat0m0.b[51][0]++, item.CODE_CLCAFF + ' - ' + $rootScope.getCadreLogiqueLabel(item)) : (cov_5h6kat0m0.b[51][1]++, id === 'id' ? (cov_5h6kat0m0.b[52][0]++, (cov_5h6kat0m0.b[53][0]++, item.label) || (cov_5h6kat0m0.b[53][1]++, item.LIBELLE_FIN) || (cov_5h6kat0m0.b[53][2]++, item.LABEL_CAT)) : (cov_5h6kat0m0.b[52][1]++, item.CODE_LC + ' - ' + item.DESCRIPTION_L)));

                cov_5h6kat0m0.s[177]++;
                if (params.filter(function (value) {
                  cov_5h6kat0m0.f[15]++;
                  cov_5h6kat0m0.s[178]++;

                  return value.x == label;
                })[0]) {
                  cov_5h6kat0m0.b[54][0]++;
                  cov_5h6kat0m0.s[179]++;

                  return 'continue';
                } else {
                  cov_5h6kat0m0.b[54][1]++;
                }

                cov_5h6kat0m0.s[180]++;
                params.push({ x: label, y: _ });
                cov_5h6kat0m0.s[181]++;
                _this.grapheParams.items.push(item);
              };

              for (var _iterator5 = items[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                var _ret = _loop();

                if (_ret === 'continue') continue;
              }
            } catch (err) {
              _didIteratorError5 = true;
              _iteratorError5 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion5 && _iterator5.return) {
                  _iterator5.return();
                }
              } finally {
                if (_didIteratorError5) {
                  throw _iteratorError5;
                }
              }
            }

            cov_5h6kat0m0.s[182]++;
            _this.__drawGraphe(params);
          });
        },
        __drawGraphe: function __drawGraphe(params) {
          cov_5h6kat0m0.s[183]++;

          if (params.length == 0) {
            cov_5h6kat0m0.b[55][0]++;
            cov_5h6kat0m0.s[184]++;

            $scope.process.enroule();
            cov_5h6kat0m0.s[185]++;
            return;
          } else {
            cov_5h6kat0m0.b[55][1]++;
          }
          var is_new = (cov_5h6kat0m0.s[186]++, Boolean($scope.chartDesign));
          cov_5h6kat0m0.s[187]++;
          if ($scope.chartDesign) {
            cov_5h6kat0m0.b[56][0]++;

            var tooltip = (cov_5h6kat0m0.s[188]++, document.getElementsByClassName('e-tooltip-wrap')[0]);
            cov_5h6kat0m0.s[189]++;
            if (tooltip) {
              cov_5h6kat0m0.b[57][0]++;
              cov_5h6kat0m0.s[190]++;

              tooltip.remove();
            } else {
              cov_5h6kat0m0.b[57][1]++;
            }
            cov_5h6kat0m0.s[191]++;
            $scope.chartDesign.destroy();
            cov_5h6kat0m0.s[192]++;
            $scope.chartDesign.removeSvg();
            cov_5h6kat0m0.s[193]++;
            $scope.chartDesign = null;
          } else {
            cov_5h6kat0m0.b[56][1]++;
          }

          var clickInstance = (cov_5h6kat0m0.s[194]++, {
            afterExport: function afterExport() {
              cov_5h6kat0m0.s[195]++;

              $scope.chartDesign.title = $scope.titleDashboard == 1 ? (cov_5h6kat0m0.b[58][0]++, $scope.analyse.TITLE) : (cov_5h6kat0m0.b[58][1]++, '');
            },

            // Initializing Series
            series: [{
              type: 'Pie', dataSource: params.map(function (value, index) {
                cov_5h6kat0m0.f[16]++;
                cov_5h6kat0m0.s[196]++;

                value.color = palettes[index];
                cov_5h6kat0m0.s[197]++;
                return value;
              }), xName: 'x', yName: 'y', pointColorMapping: 'color',
              dataLabel: { visible: true, position: 'Outside' }, innerRadius: '30%'
            }],

            textRender: function textRender(args, a, b) {
              cov_5h6kat0m0.s[198]++;

              if (!args.extraData) {
                cov_5h6kat0m0.b[59][0]++;
                cov_5h6kat0m0.s[199]++;

                args.extraData = $filter('number')(args.text);
              } else {
                cov_5h6kat0m0.b[59][1]++;
              }
              cov_5h6kat0m0.s[200]++;
              args.text = args.point.x + ' (' + args.extraData + ((cov_5h6kat0m0.b[61][0]++, $scope.analyse.FIELD == 1) || (cov_5h6kat0m0.b[61][1]++, $scope.analyse.FIELD == 5) ? (cov_5h6kat0m0.b[60][0]++, '%') : (cov_5h6kat0m0.b[60][1]++, '')) + ')';
              cov_5h6kat0m0.s[201]++;
              args.border.width = 1;
              cov_5h6kat0m0.s[202]++;
              args.border.color = args.point.color;
              cov_5h6kat0m0.s[203]++;
              args.color = args.point.color;
            },

            annotations: [{
              content: '<div id="back" style="cursor:pointer;padding:3px;width:30px; height:30px;">' + '<img src="https://ej2.syncfusion.com/javascript/demos/src/chart/images/back.png" id="back" />', region: 'Series', x: '50%', y: '50%'
            }], chartMouseClick: function chartMouseClick(args) {
              cov_5h6kat0m0.s[204]++;
              // https://ej2.syncfusion.com/javascript/demos/src/chart/images/back.png
              if (args.target.indexOf('back') > -1) {
                cov_5h6kat0m0.b[62][0]++;
                cov_5h6kat0m0.s[205]++;

                $scope.process.enroule();
              } else {
                cov_5h6kat0m0.b[62][1]++;

                var index = (cov_5h6kat0m0.s[206]++, ej.charts.indexFinder(args.target));
                cov_5h6kat0m0.s[207]++;
                if (ej.charts.getElement($scope.chartDesign.element.id + '_Series_' + index.series + '_Point_' + index.point)) {
                  cov_5h6kat0m0.b[63][0]++;
                  cov_5h6kat0m0.s[208]++;

                  $scope.process.deroule(index.point);
                } else {
                  cov_5h6kat0m0.b[63][1]++;
                }
              }
            },

            legendSettings: { visible: false }, enableSmartLabels: true,
            /* titleStyle: {
              opacity: $scope.titleDashboard == 1 ? 100 : 0
            }, */
            title: $scope.titleDashboard == 1 ? (cov_5h6kat0m0.b[64][0]++, (cov_5h6kat0m0.b[65][0]++, $scope.analyse.TITLE) || (cov_5h6kat0m0.b[65][1]++, '')) : (cov_5h6kat0m0.b[64][1]++, ''),
            // Initializing Tooltip
            // eslint-disable-next-line no-template-curly-in-string
            tooltip: { enable: true, format: '${point.x} <br> ${point.' + $scope.tooltipProperty + '} %' },
            height: '100%',
            width: '100%'
          });

          cov_5h6kat0m0.s[209]++;
          $scope.chartDesign = new ej.charts.AccumulationChart(clickInstance);
          cov_5h6kat0m0.s[210]++;
          $scope.chartDesign.appendTo('#dashboard_combine_graphe__' + $scope.idcomponent);
        },
        loadData: function loadData(item) {
          var param = (cov_5h6kat0m0.s[211]++, Object.assign({ get: 'secteur', ida: $scope.analyse.id }, $scope.analyse.AXE == 1 ? (cov_5h6kat0m0.b[66][0]++, this.__loadData_planlogique()) : (cov_5h6kat0m0.b[66][1]++, $scope.analyse.AXE == 2 ? (cov_5h6kat0m0.b[67][0]++, this.__loadData_periode()) : (cov_5h6kat0m0.b[67][1]++, this.__loadData_plangeographique()))));
          cov_5h6kat0m0.s[212]++;
          if ((cov_5h6kat0m0.b[69][0]++, $scope.analyse.AXE == 4) || (cov_5h6kat0m0.b[69][1]++, $scope.analyse.AXE == 5)) {
            cov_5h6kat0m0.b[68][0]++;
            cov_5h6kat0m0.s[213]++;

            param.id = 0;
            cov_5h6kat0m0.s[214]++;
            param.a = '';
            cov_5h6kat0m0.s[215]++;
            param.la = 0;
          } else {
            cov_5h6kat0m0.b[68][1]++;
          }
          cov_5h6kat0m0.s[216]++;
          if ($scope.analyse.ID_INDIC) {
            cov_5h6kat0m0.b[70][0]++;
            cov_5h6kat0m0.s[217]++;

            param.get = 'secteur_indicateur';
          } else {
            cov_5h6kat0m0.b[70][1]++;
          }
          cov_5h6kat0m0.s[218]++;
          param.p_bksb_ = $scope.analyse.ID_PROJET;
          cov_5h6kat0m0.s[219]++;
          $CRUDService.getAll(PATH, Object.assign({ get: 'secteur', ida: $scope.analyse.id }, param), function (data) {
            cov_5h6kat0m0.f[17]++;
            cov_5h6kat0m0.s[220]++;

            if ($scope.analyse.ID_INDIC) {
              cov_5h6kat0m0.b[71][0]++;
              cov_5h6kat0m0.s[221]++;

              for (var key in data) {
                cov_5h6kat0m0.s[222]++;
                var _arr = ['vp', 'vr', 'cp', 'cr'];

                for (var _i = 0; _i < _arr.length; _i++) {
                  var in_ = _arr[_i];cov_5h6kat0m0.s[223]++;

                  data[key][in_] = $deltaNumber.formatNumber(data[key][in_], null, (cov_5h6kat0m0.b[72][0]++, $scope.indicatorFormat) || (cov_5h6kat0m0.b[72][1]++, 2));
                }
                cov_5h6kat0m0.s[224]++;
                if ((cov_5h6kat0m0.b[74][0]++, data[key].vp != null) && (cov_5h6kat0m0.b[74][1]++, data[key].vr != null)) {
                  cov_5h6kat0m0.b[73][0]++;
                  cov_5h6kat0m0.s[225]++;

                  data[key].ve = data[key].vr - data[key].vp;
                  cov_5h6kat0m0.s[226]++;
                  data[key].tr = data[key].vp == 0 ? (cov_5h6kat0m0.b[75][0]++, null) : (cov_5h6kat0m0.b[75][1]++, $deltaNumber.formatNumber(100 * data[key].vr / data[key].vp, null, (cov_5h6kat0m0.b[76][0]++, $scope.indicatorFormat) || (cov_5h6kat0m0.b[76][1]++, 2)));
                } else {
                  cov_5h6kat0m0.b[73][1]++;
                }
                cov_5h6kat0m0.s[227]++;
                if ((cov_5h6kat0m0.b[78][0]++, data[key].cp != null) && (cov_5h6kat0m0.b[78][1]++, data[key].cr != null)) {
                  cov_5h6kat0m0.b[77][0]++;
                  cov_5h6kat0m0.s[228]++;

                  data[key].ce = data[key].cp - data[key].cr;
                  cov_5h6kat0m0.s[229]++;
                  data[key].tc = data[key].cp == 0 ? (cov_5h6kat0m0.b[79][0]++, null) : (cov_5h6kat0m0.b[79][1]++, $deltaNumber.formatNumber(100 * data[key].cr / data[key].cp, null, (cov_5h6kat0m0.b[80][0]++, $scope.indicatorFormat) || (cov_5h6kat0m0.b[80][1]++, 2)));
                } else {
                  cov_5h6kat0m0.b[77][1]++;
                }
              }
            } else {
              cov_5h6kat0m0.b[71][1]++;
            }

            cov_5h6kat0m0.s[230]++;
            $scope.data = data;

            cov_5h6kat0m0.s[231]++;
            $scope.process.__launchDrawing();
          });
        },
        open: function open() {
          cov_5h6kat0m0.s[232]++;

          $scope.data = [];
          cov_5h6kat0m0.s[233]++;
          this.itemCol = $scope.analyse.AXE == 3 ? (cov_5h6kat0m0.b[81][0]++, {
            level: 0,
            CODE_LC: '',
            id: null,
            CODE_LP: -1
          }) : (cov_5h6kat0m0.b[81][1]++, $scope.analyse.AXE == 1 ? (cov_5h6kat0m0.b[82][0]++, {
            level: 0,
            CODE_CLC: '',
            id: null,
            CODE_CLP: -1
          }) : (cov_5h6kat0m0.b[82][1]++, {
            id: '0_0_0'
          }));
          cov_5h6kat0m0.s[234]++;
          this.loadData();
        }
      };
      cov_5h6kat0m0.s[235]++;
      if (!$scope.analyse.ID_INDIC) {
        cov_5h6kat0m0.b[83][0]++;
        cov_5h6kat0m0.s[236]++;

        $scope.process.open();
      } else {
        cov_5h6kat0m0.b[83][1]++;
      }

      cov_5h6kat0m0.s[237]++;
      $scope.$watch(function (scope) {
        cov_5h6kat0m0.f[18]++;
        cov_5h6kat0m0.s[238]++;

        return $scope.print;
      }, function (newValue) {
        cov_5h6kat0m0.f[19]++;
        cov_5h6kat0m0.s[239]++;

        if (newValue != 0) {
          cov_5h6kat0m0.b[84][0]++;
          cov_5h6kat0m0.s[240]++;

          $scope.process.export_data(newValue);
          cov_5h6kat0m0.s[241]++;
          $scope.print = 0;
        } else {
          cov_5h6kat0m0.b[84][1]++;
        }
      });
    }
  };
});