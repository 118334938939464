'use strict';

var cov_1yieg4iypn = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/dashboard-form-pie-chart.js',
      hash = '4a9cf1f9beab37a4a6b211e400857ce24719ebdb',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/dashboard-form-pie-chart.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 121,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 120,
          column: 4
        }
      },
      '2': {
        start: {
          line: 11,
          column: 19
        },
        end: {
          line: 11,
          column: 29
        }
      },
      '3': {
        start: {
          line: 13,
          column: 20
        },
        end: {
          line: 13,
          column: 24
        }
      },
      '4': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 102,
          column: 8
        }
      },
      '5': {
        start: {
          line: 16,
          column: 8
        },
        end: {
          line: 18,
          column: 9
        }
      },
      '6': {
        start: {
          line: 17,
          column: 10
        },
        end: {
          line: 17,
          column: 17
        }
      },
      '7': {
        start: {
          line: 19,
          column: 23
        },
        end: {
          line: 19,
          column: 154
        }
      },
      '8': {
        start: {
          line: 20,
          column: 8
        },
        end: {
          line: 20,
          column: 32
        }
      },
      '9': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 101,
          column: 11
        }
      },
      '10': {
        start: {
          line: 22,
          column: 10
        },
        end: {
          line: 22,
          column: 35
        }
      },
      '11': {
        start: {
          line: 23,
          column: 10
        },
        end: {
          line: 25,
          column: 11
        }
      },
      '12': {
        start: {
          line: 24,
          column: 12
        },
        end: {
          line: 24,
          column: 19
        }
      },
      '13': {
        start: {
          line: 26,
          column: 10
        },
        end: {
          line: 30,
          column: 11
        }
      },
      '14': {
        start: {
          line: 27,
          column: 12
        },
        end: {
          line: 29,
          column: 13
        }
      },
      '15': {
        start: {
          line: 28,
          column: 14
        },
        end: {
          line: 28,
          column: 66
        }
      },
      '16': {
        start: {
          line: 31,
          column: 28
        },
        end: {
          line: 33,
          column: 12
        }
      },
      '17': {
        start: {
          line: 32,
          column: 12
        },
        end: {
          line: 32,
          column: 62
        }
      },
      '18': {
        start: {
          line: 34,
          column: 24
        },
        end: {
          line: 34,
          column: 67
        }
      },
      '19': {
        start: {
          line: 35,
          column: 22
        },
        end: {
          line: 35,
          column: 23
        }
      },
      '20': {
        start: {
          line: 36,
          column: 19
        },
        end: {
          line: 36,
          column: 22
        }
      },
      '21': {
        start: {
          line: 37,
          column: 24
        },
        end: {
          line: 37,
          column: 62
        }
      },
      '22': {
        start: {
          line: 38,
          column: 10
        },
        end: {
          line: 38,
          column: 24
        }
      },
      '23': {
        start: {
          line: 39,
          column: 10
        },
        end: {
          line: 41,
          column: 11
        }
      },
      '24': {
        start: {
          line: 40,
          column: 12
        },
        end: {
          line: 40,
          column: 53
        }
      },
      '25': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 100,
          column: 12
        }
      },
      '26': {
        start: {
          line: 44,
          column: 75
        },
        end: {
          line: 83,
          column: 13
        }
      },
      '27': {
        start: {
          line: 47,
          column: 30
        },
        end: {
          line: 47,
          column: 53
        }
      },
      '28': {
        start: {
          line: 48,
          column: 18
        },
        end: {
          line: 50,
          column: 19
        }
      },
      '29': {
        start: {
          line: 49,
          column: 20
        },
        end: {
          line: 49,
          column: 33
        }
      },
      '30': {
        start: {
          line: 51,
          column: 18
        },
        end: {
          line: 53,
          column: 19
        }
      },
      '31': {
        start: {
          line: 52,
          column: 20
        },
        end: {
          line: 52,
          column: 33
        }
      },
      '32': {
        start: {
          line: 54,
          column: 18
        },
        end: {
          line: 56,
          column: 19
        }
      },
      '33': {
        start: {
          line: 55,
          column: 20
        },
        end: {
          line: 55,
          column: 32
        }
      },
      '34': {
        start: {
          line: 57,
          column: 18
        },
        end: {
          line: 57,
          column: 31
        }
      },
      '35': {
        start: {
          line: 60,
          column: 18
        },
        end: {
          line: 60,
          column: 56
        }
      },
      '36': {
        start: {
          line: 61,
          column: 18
        },
        end: {
          line: 61,
          column: 105
        }
      },
      '37': {
        start: {
          line: 62,
          column: 18
        },
        end: {
          line: 62,
          column: 27
        }
      },
      '38': {
        start: {
          line: 85,
          column: 14
        },
        end: {
          line: 85,
          column: 93
        }
      },
      '39': {
        start: {
          line: 104,
          column: 6
        },
        end: {
          line: 111,
          column: 9
        }
      },
      '40': {
        start: {
          line: 105,
          column: 8
        },
        end: {
          line: 105,
          column: 38
        }
      },
      '41': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 106,
          column: 51
        }
      },
      '42': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 110,
          column: 11
        }
      },
      '43': {
        start: {
          line: 108,
          column: 10
        },
        end: {
          line: 108,
          column: 39
        }
      },
      '44': {
        start: {
          line: 109,
          column: 10
        },
        end: {
          line: 109,
          column: 54
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 57
          },
          end: {
            line: 1,
            column: 58
          }
        },
        loc: {
          start: {
            line: 1,
            column: 63
          },
          end: {
            line: 121,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 10,
            column: 16
          },
          end: {
            line: 10,
            column: 17
          }
        },
        loc: {
          start: {
            line: 10,
            column: 106
          },
          end: {
            line: 119,
            column: 5
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 25
          },
          end: {
            line: 15,
            column: 26
          }
        },
        loc: {
          start: {
            line: 15,
            column: 39
          },
          end: {
            line: 102,
            column: 7
          }
        },
        line: 15
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 21,
            column: 42
          },
          end: {
            line: 21,
            column: 43
          }
        },
        loc: {
          start: {
            line: 21,
            column: 50
          },
          end: {
            line: 101,
            column: 9
          }
        },
        line: 21
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 31,
            column: 56
          },
          end: {
            line: 31,
            column: 57
          }
        },
        loc: {
          start: {
            line: 31,
            column: 65
          },
          end: {
            line: 33,
            column: 11
          }
        },
        line: 31
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 44,
            column: 65
          },
          end: {
            line: 44,
            column: 66
          }
        },
        loc: {
          start: {
            line: 44,
            column: 75
          },
          end: {
            line: 83,
            column: 13
          }
        },
        line: 44
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 46,
            column: 24
          },
          end: {
            line: 46,
            column: 25
          }
        },
        loc: {
          start: {
            line: 46,
            column: 29
          },
          end: {
            line: 58,
            column: 17
          }
        },
        line: 46
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 59,
            column: 21
          },
          end: {
            line: 59,
            column: 22
          }
        },
        loc: {
          start: {
            line: 59,
            column: 26
          },
          end: {
            line: 63,
            column: 17
          }
        },
        line: 59
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 104,
            column: 79
          },
          end: {
            line: 104,
            column: 80
          }
        },
        loc: {
          start: {
            line: 104,
            column: 87
          },
          end: {
            line: 111,
            column: 7
          }
        },
        line: 104
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 107,
            column: 81
          },
          end: {
            line: 107,
            column: 82
          }
        },
        loc: {
          start: {
            line: 107,
            column: 90
          },
          end: {
            line: 110,
            column: 9
          }
        },
        line: 107
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 16,
            column: 8
          },
          end: {
            line: 18,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 16,
            column: 8
          },
          end: {
            line: 18,
            column: 9
          }
        }, {
          start: {
            line: 16,
            column: 8
          },
          end: {
            line: 18,
            column: 9
          }
        }],
        line: 16
      },
      '1': {
        loc: {
          start: {
            line: 23,
            column: 10
          },
          end: {
            line: 25,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 23,
            column: 10
          },
          end: {
            line: 25,
            column: 11
          }
        }, {
          start: {
            line: 23,
            column: 10
          },
          end: {
            line: 25,
            column: 11
          }
        }],
        line: 23
      },
      '2': {
        loc: {
          start: {
            line: 28,
            column: 31
          },
          end: {
            line: 28,
            column: 65
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 28,
            column: 31
          },
          end: {
            line: 28,
            column: 57
          }
        }, {
          start: {
            line: 28,
            column: 61
          },
          end: {
            line: 28,
            column: 65
          }
        }],
        line: 28
      },
      '3': {
        loc: {
          start: {
            line: 34,
            column: 24
          },
          end: {
            line: 34,
            column: 67
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 34,
            column: 47
          },
          end: {
            line: 34,
            column: 61
          }
        }, {
          start: {
            line: 34,
            column: 64
          },
          end: {
            line: 34,
            column: 67
          }
        }],
        line: 34
      },
      '4': {
        loc: {
          start: {
            line: 40,
            column: 21
          },
          end: {
            line: 40,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 40,
            column: 21
          },
          end: {
            line: 40,
            column: 47
          }
        }, {
          start: {
            line: 40,
            column: 51
          },
          end: {
            line: 40,
            column: 52
          }
        }],
        line: 40
      },
      '5': {
        loc: {
          start: {
            line: 45,
            column: 27
          },
          end: {
            line: 45,
            column: 37
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 45,
            column: 27
          },
          end: {
            line: 45,
            column: 31
          }
        }, {
          start: {
            line: 45,
            column: 35
          },
          end: {
            line: 45,
            column: 37
          }
        }],
        line: 45
      },
      '6': {
        loc: {
          start: {
            line: 48,
            column: 18
          },
          end: {
            line: 50,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 48,
            column: 18
          },
          end: {
            line: 50,
            column: 19
          }
        }, {
          start: {
            line: 48,
            column: 18
          },
          end: {
            line: 50,
            column: 19
          }
        }],
        line: 48
      },
      '7': {
        loc: {
          start: {
            line: 51,
            column: 18
          },
          end: {
            line: 53,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 51,
            column: 18
          },
          end: {
            line: 53,
            column: 19
          }
        }, {
          start: {
            line: 51,
            column: 18
          },
          end: {
            line: 53,
            column: 19
          }
        }],
        line: 51
      },
      '8': {
        loc: {
          start: {
            line: 54,
            column: 18
          },
          end: {
            line: 56,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 54,
            column: 18
          },
          end: {
            line: 56,
            column: 19
          }
        }, {
          start: {
            line: 54,
            column: 18
          },
          end: {
            line: 56,
            column: 19
          }
        }],
        line: 54
      },
      '9': {
        loc: {
          start: {
            line: 64,
            column: 22
          },
          end: {
            line: 64,
            column: 87
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 64,
            column: 22
          },
          end: {
            line: 64,
            column: 53
          }
        }, {
          start: {
            line: 64,
            column: 57
          },
          end: {
            line: 64,
            column: 87
          }
        }],
        line: 64
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1yieg4iypn.s[0]++;
angular.module('app').directive('dashboardFromPieChart', function () {
  cov_1yieg4iypn.f[0]++;
  cov_1yieg4iypn.s[1]++;

  return {
    restrict: 'E',
    scope: {
      idcomponent: '@',
      idForm: '=',
      idRequest: '='
    },
    templateUrl: 'app/views/common/dashboard-from-pie-chart.html',
    controller: function controller($scope, $deltadate, $translate, $deltaLocation, $LOGINService, $filter, $CRUDService) {
      cov_1yieg4iypn.f[1]++;

      var PATH = (cov_1yieg4iypn.s[2]++, 'Formular');
      // Get Data
      var p_bksb_ = (cov_1yieg4iypn.s[3]++, null);

      cov_1yieg4iypn.s[4]++;
      $scope.drawChart = function (id) {
        cov_1yieg4iypn.f[2]++;
        cov_1yieg4iypn.s[5]++;

        if ($scope.chart) {
          cov_1yieg4iypn.b[0][0]++;
          cov_1yieg4iypn.s[6]++;

          return;
        } else {
          cov_1yieg4iypn.b[0][1]++;
        }
        var params = (cov_1yieg4iypn.s[7]++, { withData: 1, export: 1, page: 0, id: id, p_bksb_: p_bksb_, id_request: $scope.idForm, paging: 1, get: 'all_r_graph', sort: $filter('json')([]) });
        cov_1yieg4iypn.s[8]++;
        $scope.isloading = true;
        cov_1yieg4iypn.s[9]++;
        $CRUDService.getAll(PATH, params, function (data) {
          cov_1yieg4iypn.f[3]++;
          cov_1yieg4iypn.s[10]++;

          $scope.isloading = false;
          cov_1yieg4iypn.s[11]++;
          if (!angular.isArray(data)) {
            cov_1yieg4iypn.b[1][0]++;
            cov_1yieg4iypn.s[12]++;

            return;
          } else {
            cov_1yieg4iypn.b[1][1]++;
          }
          cov_1yieg4iypn.s[13]++;
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var item = _step.value;
              cov_1yieg4iypn.s[14]++;
              var _iteratorNormalCompletion3 = true;
              var _didIteratorError3 = false;
              var _iteratorError3 = undefined;

              try {
                for (var _iterator3 = $scope.selectedRequest.CHART.SERIES[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                  var _serie = _step3.value;
                  cov_1yieg4iypn.s[15]++;

                  item[_serie.id] = (cov_1yieg4iypn.b[2][0]++, parseFloat(item[_serie.id])) || (cov_1yieg4iypn.b[2][1]++, null);
                }
              } catch (err) {
                _didIteratorError3 = true;
                _iteratorError3 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion3 && _iterator3.return) {
                    _iterator3.return();
                  }
                } finally {
                  if (_didIteratorError3) {
                    throw _iteratorError3;
                  }
                }
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          var categorie = (cov_1yieg4iypn.s[16]++, $scope.requestFields.filter(function (value) {
            cov_1yieg4iypn.f[4]++;
            cov_1yieg4iypn.s[17]++;

            return value.id == $scope.selectedRequest.CHART.C;
          }));
          var idCat = (cov_1yieg4iypn.s[18]++, categorie.length > 0 ? (cov_1yieg4iypn.b[3][0]++, categorie[0].l) : (cov_1yieg4iypn.b[3][1]++, '_'));
          var Total = (cov_1yieg4iypn.s[19]++, 0);
          var id = (cov_1yieg4iypn.s[20]++, '_');
          var serie = (cov_1yieg4iypn.s[21]++, $scope.selectedRequest.CHART.SERIES[0]);
          cov_1yieg4iypn.s[22]++;
          id = serie.id;
          cov_1yieg4iypn.s[23]++;
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var _item = _step2.value;
              cov_1yieg4iypn.s[24]++;

              Total += (cov_1yieg4iypn.b[4][0]++, parseFloat(_item[serie.id])) || (cov_1yieg4iypn.b[4][1]++, 0);
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          cov_1yieg4iypn.s[25]++;
          $scope.params = {
            useGroupingSeparator: true,
            series: [$scope.selectedRequest.CHART.SERIES[0]].map(function (value) {
              cov_1yieg4iypn.f[5]++;
              cov_1yieg4iypn.s[26]++;
              return {
                dataSource: ((cov_1yieg4iypn.b[5][0]++, data) || (cov_1yieg4iypn.b[5][1]++, [])).filter(function (d) {
                  cov_1yieg4iypn.f[6]++;

                  var val = (cov_1yieg4iypn.s[27]++, parseFloat(d[value.id]));
                  cov_1yieg4iypn.s[28]++;
                  if (d[value.id] === null) {
                    cov_1yieg4iypn.b[6][0]++;
                    cov_1yieg4iypn.s[29]++;

                    return false;
                  } else {
                    cov_1yieg4iypn.b[6][1]++;
                  }
                  cov_1yieg4iypn.s[30]++;
                  if (Number.isNaN(val)) {
                    cov_1yieg4iypn.b[7][0]++;
                    cov_1yieg4iypn.s[31]++;

                    return false;
                  } else {
                    cov_1yieg4iypn.b[7][1]++;
                  }
                  cov_1yieg4iypn.s[32]++;
                  if (angular.isNumber(val)) {
                    cov_1yieg4iypn.b[8][0]++;
                    cov_1yieg4iypn.s[33]++;

                    return true;
                  } else {
                    cov_1yieg4iypn.b[8][1]++;
                  }
                  cov_1yieg4iypn.s[34]++;
                  return false;
                }).map(function (d) {
                  cov_1yieg4iypn.f[7]++;
                  cov_1yieg4iypn.s[35]++;

                  d[value.id] = parseFloat(d[value.id]);
                  cov_1yieg4iypn.s[36]++;
                  d.radius = $filter('number')(parseInt(10000 * d[value.id] / Total, 10) / 100, 2) + '%';
                  cov_1yieg4iypn.s[37]++;
                  return d;
                }),
                xName: (cov_1yieg4iypn.b[9][0]++, $scope.selectedRequest.CHART.SB) || (cov_1yieg4iypn.b[9][1]++, $scope.selectedRequest.CHART.C),
                yName: value.id,
                // radius: 'radius',
                // innerRadius: '20%',
                dataLabel: {
                  visible: true,
                  position: 'Outside',
                  name: 'radius',
                  font: {
                    fontWeight: '600'
                  }
                },
                radius: '70%',
                startAngle: 0,
                endAngle: 0,
                innerRadius: '0%',
                explode: true,
                explodeOffset: '10%',
                explodeIndex: 0
              };
            }),
            textRender: function textRender(args) {
              cov_1yieg4iypn.s[38]++;

              args.point.customValue = args.point.x + ': ' + $filter('number')(args.point.y);
            },

            width: '100%',
            height: '100%',
            center: { x: '50%', y: '50%' },
            enableSmartLabels: true,
            enableAnimation: true,
            legendSettings: {
              visible: true
            },
            // Initializing Tooltip
            // eslint-disable-next-line no-template-curly-in-string
            tooltip: { enable: true, /* header: 'Browser', */format: '${point.customValue}'
              // Initializing Title
              // title: 'Mobile Browser Statistics',
            } };
        });
      };

      cov_1yieg4iypn.s[39]++;
      $CRUDService.getAll(PATH, { get: 'one_request_by_id', id: $scope.idForm }, function (data) {
        cov_1yieg4iypn.f[8]++;
        cov_1yieg4iypn.s[40]++;

        $scope.selectedRequest = data;
        cov_1yieg4iypn.s[41]++;
        p_bksb_ = $scope.selectedRequest.ID_PROJET;
        cov_1yieg4iypn.s[42]++;
        $CRUDService.getAll(PATH, { get: 'all_filter_fields', id: $scope.idForm }, function (data_) {
          cov_1yieg4iypn.f[9]++;
          cov_1yieg4iypn.s[43]++;

          $scope.requestFields = data_;
          cov_1yieg4iypn.s[44]++;
          $scope.drawChart($scope.selectedRequest.ID);
        });
      });

      /*
            $CRUDService.getAll(PATH, {get: 'one_request_by_id', id: $scope.idForm}, data => {
              $scope.selectedRequest = data;
              p_bksb_ = $scope.selectedRequest.ID_PROJET;
              $scope.drawChart($scope.selectedRequest.ID);
            }); */
    }
  };
});