'use strict';

var cov_2qwxdtm7e9 = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/currencies/currenciesCtrl.js',
      hash = '27107b222881f7f2bd232053d8f1d3fd7b255f42',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/currencies/currenciesCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 69,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 3,
          column: 37
        }
      },
      '2': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '3': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 11
        }
      },
      '4': {
        start: {
          line: 7,
          column: 15
        },
        end: {
          line: 7,
          column: 25
        }
      },
      '5': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 15,
          column: 4
        }
      },
      '6': {
        start: {
          line: 10,
          column: 4
        },
        end: {
          line: 10,
          column: 28
        }
      },
      '7': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 14,
          column: 7
        }
      },
      '8': {
        start: {
          line: 12,
          column: 6
        },
        end: {
          line: 12,
          column: 35
        }
      },
      '9': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 13,
          column: 31
        }
      },
      '10': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 23,
          column: 4
        }
      },
      '11': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 22,
          column: 7
        }
      },
      '12': {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 35,
          column: 4
        }
      },
      '13': {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 25,
          column: 30
        }
      },
      '14': {
        start: {
          line: 26,
          column: 4
        },
        end: {
          line: 26,
          column: 43
        }
      },
      '15': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 34,
          column: 7
        }
      },
      '16': {
        start: {
          line: 37,
          column: 2
        },
        end: {
          line: 66,
          column: 4
        }
      },
      '17': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 65,
          column: 7
        }
      },
      '18': {
        start: {
          line: 50,
          column: 6
        },
        end: {
          line: 52,
          column: 7
        }
      },
      '19': {
        start: {
          line: 51,
          column: 8
        },
        end: {
          line: 51,
          column: 15
        }
      },
      '20': {
        start: {
          line: 53,
          column: 6
        },
        end: {
          line: 53,
          column: 30
        }
      },
      '21': {
        start: {
          line: 54,
          column: 6
        },
        end: {
          line: 64,
          column: 9
        }
      },
      '22': {
        start: {
          line: 55,
          column: 8
        },
        end: {
          line: 62,
          column: 9
        }
      },
      '23': {
        start: {
          line: 56,
          column: 10
        },
        end: {
          line: 59,
          column: 13
        }
      },
      '24': {
        start: {
          line: 60,
          column: 10
        },
        end: {
          line: 60,
          column: 35
        }
      },
      '25': {
        start: {
          line: 61,
          column: 10
        },
        end: {
          line: 61,
          column: 17
        }
      },
      '26': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 63,
          column: 24
        }
      },
      '27': {
        start: {
          line: 68,
          column: 2
        },
        end: {
          line: 68,
          column: 18
        }
      },
      '28': {
        start: {
          line: 72,
          column: 2
        },
        end: {
          line: 72,
          column: 28
        }
      },
      '29': {
        start: {
          line: 73,
          column: 15
        },
        end: {
          line: 73,
          column: 25
        }
      },
      '30': {
        start: {
          line: 74,
          column: 2
        },
        end: {
          line: 88,
          column: 4
        }
      },
      '31': {
        start: {
          line: 75,
          column: 25
        },
        end: {
          line: 75,
          column: 56
        }
      },
      '32': {
        start: {
          line: 76,
          column: 4
        },
        end: {
          line: 76,
          column: 30
        }
      },
      '33': {
        start: {
          line: 77,
          column: 18
        },
        end: {
          line: 77,
          column: 105
        }
      },
      '34': {
        start: {
          line: 78,
          column: 4
        },
        end: {
          line: 83,
          column: 5
        }
      },
      '35': {
        start: {
          line: 79,
          column: 6
        },
        end: {
          line: 82,
          column: 7
        }
      },
      '36': {
        start: {
          line: 80,
          column: 8
        },
        end: {
          line: 80,
          column: 33
        }
      },
      '37': {
        start: {
          line: 81,
          column: 8
        },
        end: {
          line: 81,
          column: 15
        }
      },
      '38': {
        start: {
          line: 84,
          column: 4
        },
        end: {
          line: 87,
          column: 7
        }
      },
      '39': {
        start: {
          line: 85,
          column: 6
        },
        end: {
          line: 85,
          column: 26
        }
      },
      '40': {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 86,
          column: 22
        }
      },
      '41': {
        start: {
          line: 89,
          column: 2
        },
        end: {
          line: 91,
          column: 4
        }
      },
      '42': {
        start: {
          line: 90,
          column: 4
        },
        end: {
          line: 90,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 51
          },
          end: {
            line: 2,
            column: 52
          }
        },
        loc: {
          start: {
            line: 2,
            column: 163
          },
          end: {
            line: 69,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 9,
            column: 18
          },
          end: {
            line: 9,
            column: 19
          }
        },
        loc: {
          start: {
            line: 9,
            column: 30
          },
          end: {
            line: 15,
            column: 3
          }
        },
        line: 9
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 11,
            column: 44
          },
          end: {
            line: 11,
            column: 45
          }
        },
        loc: {
          start: {
            line: 11,
            column: 52
          },
          end: {
            line: 14,
            column: 5
          }
        },
        line: 11
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 17,
            column: 15
          },
          end: {
            line: 17,
            column: 16
          }
        },
        loc: {
          start: {
            line: 17,
            column: 27
          },
          end: {
            line: 23,
            column: 3
          }
        },
        line: 17
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 24,
            column: 20
          },
          end: {
            line: 24,
            column: 21
          }
        },
        loc: {
          start: {
            line: 24,
            column: 36
          },
          end: {
            line: 35,
            column: 3
          }
        },
        line: 24
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 37,
            column: 22
          },
          end: {
            line: 37,
            column: 23
          }
        },
        loc: {
          start: {
            line: 37,
            column: 38
          },
          end: {
            line: 66,
            column: 3
          }
        },
        line: 37
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 49,
            column: 7
          },
          end: {
            line: 49,
            column: 8
          }
        },
        loc: {
          start: {
            line: 49,
            column: 20
          },
          end: {
            line: 65,
            column: 5
          }
        },
        line: 49
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 54,
            column: 62
          },
          end: {
            line: 54,
            column: 63
          }
        },
        loc: {
          start: {
            line: 54,
            column: 70
          },
          end: {
            line: 64,
            column: 7
          }
        },
        line: 54
      },
      '8': {
        name: 'CurrenciesCtrl_edit',
        decl: {
          start: {
            line: 71,
            column: 9
          },
          end: {
            line: 71,
            column: 28
          }
        },
        loc: {
          start: {
            line: 71,
            column: 79
          },
          end: {
            line: 92,
            column: 1
          }
        },
        line: 71
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 74,
            column: 24
          },
          end: {
            line: 74,
            column: 25
          }
        },
        loc: {
          start: {
            line: 74,
            column: 36
          },
          end: {
            line: 88,
            column: 3
          }
        },
        line: 74
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 84,
            column: 90
          },
          end: {
            line: 84,
            column: 91
          }
        },
        loc: {
          start: {
            line: 84,
            column: 96
          },
          end: {
            line: 87,
            column: 5
          }
        },
        line: 84
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 89,
            column: 22
          },
          end: {
            line: 89,
            column: 23
          }
        },
        loc: {
          start: {
            line: 89,
            column: 34
          },
          end: {
            line: 91,
            column: 3
          }
        },
        line: 89
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        }, {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        }],
        line: 4
      },
      '1': {
        loc: {
          start: {
            line: 50,
            column: 6
          },
          end: {
            line: 52,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 50,
            column: 6
          },
          end: {
            line: 52,
            column: 7
          }
        }, {
          start: {
            line: 50,
            column: 6
          },
          end: {
            line: 52,
            column: 7
          }
        }],
        line: 50
      },
      '2': {
        loc: {
          start: {
            line: 55,
            column: 8
          },
          end: {
            line: 62,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 55,
            column: 8
          },
          end: {
            line: 62,
            column: 9
          }
        }, {
          start: {
            line: 55,
            column: 8
          },
          end: {
            line: 62,
            column: 9
          }
        }],
        line: 55
      },
      '3': {
        loc: {
          start: {
            line: 79,
            column: 6
          },
          end: {
            line: 82,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 79,
            column: 6
          },
          end: {
            line: 82,
            column: 7
          }
        }, {
          start: {
            line: 79,
            column: 6
          },
          end: {
            line: 82,
            column: 7
          }
        }],
        line: 79
      },
      '4': {
        loc: {
          start: {
            line: 79,
            column: 10
          },
          end: {
            line: 79,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 79,
            column: 10
          },
          end: {
            line: 79,
            column: 14
          }
        }, {
          start: {
            line: 79,
            column: 18
          },
          end: {
            line: 79,
            column: 44
          }
        }],
        line: 79
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2qwxdtm7e9.s[0]++;

angular.module('app').controller('CurrenciesCtrl', function ($scope, SweetAlert, $uibModal, $translate, $CRUDService, $otherHttpService, $location, $filter, $rootScope) {
  cov_2qwxdtm7e9.f[0]++;
  cov_2qwxdtm7e9.s[1]++;

  $rootScope.processPageRight('0_1');
  cov_2qwxdtm7e9.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_2qwxdtm7e9.b[0][0]++;
    cov_2qwxdtm7e9.s[3]++;

    return;
  } else {
    cov_2qwxdtm7e9.b[0][1]++;
  }
  var PATH = (cov_2qwxdtm7e9.s[4]++, 'Currency');

  cov_2qwxdtm7e9.s[5]++;
  $scope.getAll = function () {
    cov_2qwxdtm7e9.f[1]++;
    cov_2qwxdtm7e9.s[6]++;

    $scope.isloading = true;
    cov_2qwxdtm7e9.s[7]++;
    $CRUDService.getAll(PATH, { get: 'all' }, function (data) {
      cov_2qwxdtm7e9.f[2]++;
      cov_2qwxdtm7e9.s[8]++;

      $scope.listCurrencies = data;
      cov_2qwxdtm7e9.s[9]++;
      $scope.isloading = false;
    });
  };

  cov_2qwxdtm7e9.s[10]++;
  $scope.add = function () {
    cov_2qwxdtm7e9.f[3]++;
    cov_2qwxdtm7e9.s[11]++;

    $scope.editItem({
      CODE: '',
      NAME: '',
      id: 0
    });
  };
  cov_2qwxdtm7e9.s[12]++;
  $scope.editItem = function (item) {
    cov_2qwxdtm7e9.f[4]++;
    cov_2qwxdtm7e9.s[13]++;

    $scope.error_code = false;
    cov_2qwxdtm7e9.s[14]++;
    $scope.editedItem = angular.copy(item);
    cov_2qwxdtm7e9.s[15]++;
    $uibModal.open({
      templateUrl: 'app/views/currencies/edit.html',
      controller: CurrenciesCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_2qwxdtm7e9.s[16]++;
  $scope.deleteItem = function (item) {
    cov_2qwxdtm7e9.f[5]++;
    cov_2qwxdtm7e9.s[17]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_2qwxdtm7e9.f[6]++;
      cov_2qwxdtm7e9.s[18]++;

      if (isconfirm) {
        cov_2qwxdtm7e9.b[1][0]++;
        cov_2qwxdtm7e9.s[19]++;

        return;
      } else {
        cov_2qwxdtm7e9.b[1][1]++;
      }
      cov_2qwxdtm7e9.s[20]++;
      $scope.isloading = true;
      cov_2qwxdtm7e9.s[21]++;
      $CRUDService.delet(PATH, { action: 'supp', id: item.id }, function (data) {
        cov_2qwxdtm7e9.f[7]++;
        cov_2qwxdtm7e9.s[22]++;

        if (data < 0) {
          cov_2qwxdtm7e9.b[2][0]++;
          cov_2qwxdtm7e9.s[23]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_2qwxdtm7e9.s[24]++;
          $scope.isloading = false;
          cov_2qwxdtm7e9.s[25]++;
          return;
        } else {
          cov_2qwxdtm7e9.b[2][1]++;
        }
        cov_2qwxdtm7e9.s[26]++;
        $scope.getAll();
      });
    });
  };

  cov_2qwxdtm7e9.s[27]++;
  $scope.getAll();
});

function CurrenciesCtrl_edit($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_2qwxdtm7e9.f[8]++;
  cov_2qwxdtm7e9.s[28]++;

  $scope.error_code = false;
  var PATH = (cov_2qwxdtm7e9.s[29]++, 'Currency');
  cov_2qwxdtm7e9.s[30]++;
  $scope.setValidData = function () {
    cov_2qwxdtm7e9.f[9]++;

    var validateItem = (cov_2qwxdtm7e9.s[31]++, angular.copy($scope.editedItem));
    cov_2qwxdtm7e9.s[32]++;
    $scope.error_code = false;
    var items = (cov_2qwxdtm7e9.s[33]++, $filter('filter')($scope.listCurrencies, { CODE: validateItem.CODE.toUpperCase() }, true));
    cov_2qwxdtm7e9.s[34]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = items[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var item = _step.value;
        cov_2qwxdtm7e9.s[35]++;

        if ((cov_2qwxdtm7e9.b[4][0]++, item) && (cov_2qwxdtm7e9.b[4][1]++, item.id != validateItem.id)) {
          cov_2qwxdtm7e9.b[3][0]++;
          cov_2qwxdtm7e9.s[36]++;

          $scope.error_code = true;
          cov_2qwxdtm7e9.s[37]++;
          return;
        } else {
          cov_2qwxdtm7e9.b[3][1]++;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_2qwxdtm7e9.s[38]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function () {
      cov_2qwxdtm7e9.f[10]++;
      cov_2qwxdtm7e9.s[39]++;

      $scope.closeModal();
      cov_2qwxdtm7e9.s[40]++;
      $scope.getAll();
    });
  };
  cov_2qwxdtm7e9.s[41]++;
  $scope.closeModal = function () {
    cov_2qwxdtm7e9.f[11]++;
    cov_2qwxdtm7e9.s[42]++;

    $uibModalInstance.dismiss('cancel');
  };
}