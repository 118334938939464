'use strict';

var cov_si8ysc2om = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/user/useractor/useractorCtrl.js',
      hash = '7a7f84e9824271c3f6b8f4bc4aa8f6413938a2dc',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/user/useractor/useractorCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 5,
          column: 0
        },
        end: {
          line: 209,
          column: 3
        }
      },
      '1': {
        start: {
          line: 6,
          column: 15
        },
        end: {
          line: 6,
          column: 21
        }
      },
      '2': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 10,
          column: 4
        }
      },
      '3': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 36
        }
      },
      '4': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 185,
          column: 4
        }
      },
      '5': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 27,
          column: 30
        }
      },
      '6': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 35,
          column: 9
        }
      },
      '7': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 32,
          column: 9
        }
      },
      '8': {
        start: {
          line: 30,
          column: 10
        },
        end: {
          line: 30,
          column: 43
        }
      },
      '9': {
        start: {
          line: 31,
          column: 10
        },
        end: {
          line: 31,
          column: 59
        }
      },
      '10': {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 33,
          column: 43
        }
      },
      '11': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 34,
          column: 33
        }
      },
      '12': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 45,
          column: 9
        }
      },
      '13': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 50,
          column: 7
        }
      },
      '14': {
        start: {
          line: 49,
          column: 8
        },
        end: {
          line: 49,
          column: 20
        }
      },
      '15': {
        start: {
          line: 51,
          column: 6
        },
        end: {
          line: 53,
          column: 7
        }
      },
      '16': {
        start: {
          line: 52,
          column: 8
        },
        end: {
          line: 52,
          column: 21
        }
      },
      '17': {
        start: {
          line: 54,
          column: 6
        },
        end: {
          line: 56,
          column: 7
        }
      },
      '18': {
        start: {
          line: 55,
          column: 8
        },
        end: {
          line: 55,
          column: 20
        }
      },
      '19': {
        start: {
          line: 57,
          column: 6
        },
        end: {
          line: 61,
          column: 7
        }
      },
      '20': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 60,
          column: 9
        }
      },
      '21': {
        start: {
          line: 59,
          column: 10
        },
        end: {
          line: 59,
          column: 23
        }
      },
      '22': {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 62,
          column: 18
        }
      },
      '23': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 69,
          column: 8
        }
      },
      '24': {
        start: {
          line: 70,
          column: 6
        },
        end: {
          line: 70,
          column: 44
        }
      },
      '25': {
        start: {
          line: 72,
          column: 6
        },
        end: {
          line: 72,
          column: 90
        }
      },
      '26': {
        start: {
          line: 73,
          column: 6
        },
        end: {
          line: 73,
          column: 125
        }
      },
      '27': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 76,
          column: 7
        }
      },
      '28': {
        start: {
          line: 75,
          column: 8
        },
        end: {
          line: 75,
          column: 55
        }
      },
      '29': {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 80,
          column: 7
        }
      },
      '30': {
        start: {
          line: 79,
          column: 8
        },
        end: {
          line: 79,
          column: 49
        }
      },
      '31': {
        start: {
          line: 81,
          column: 6
        },
        end: {
          line: 81,
          column: 59
        }
      },
      '32': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 82,
          column: 87
        }
      },
      '33': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 83,
          column: 33
        }
      },
      '34': {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 86,
          column: 30
        }
      },
      '35': {
        start: {
          line: 89,
          column: 6
        },
        end: {
          line: 93,
          column: 8
        }
      },
      '36': {
        start: {
          line: 95,
          column: 18
        },
        end: {
          line: 95,
          column: 122
        }
      },
      '37': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 99,
          column: 7
        }
      },
      '38': {
        start: {
          line: 97,
          column: 8
        },
        end: {
          line: 97,
          column: 32
        }
      },
      '39': {
        start: {
          line: 98,
          column: 8
        },
        end: {
          line: 98,
          column: 15
        }
      },
      '40': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 104,
          column: 7
        }
      },
      '41': {
        start: {
          line: 102,
          column: 8
        },
        end: {
          line: 102,
          column: 30
        }
      },
      '42': {
        start: {
          line: 103,
          column: 8
        },
        end: {
          line: 103,
          column: 15
        }
      },
      '43': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 108,
          column: 7
        }
      },
      '44': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 106,
          column: 31
        }
      },
      '45': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 107,
          column: 15
        }
      },
      '46': {
        start: {
          line: 109,
          column: 19
        },
        end: {
          line: 109,
          column: 63
        }
      },
      '47': {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 110,
          column: 41
        }
      },
      '48': {
        start: {
          line: 111,
          column: 6
        },
        end: {
          line: 117,
          column: 9
        }
      },
      '49': {
        start: {
          line: 112,
          column: 8
        },
        end: {
          line: 112,
          column: 40
        }
      },
      '50': {
        start: {
          line: 113,
          column: 8
        },
        end: {
          line: 115,
          column: 9
        }
      },
      '51': {
        start: {
          line: 114,
          column: 10
        },
        end: {
          line: 114,
          column: 17
        }
      },
      '52': {
        start: {
          line: 116,
          column: 8
        },
        end: {
          line: 116,
          column: 36
        }
      },
      '53': {
        start: {
          line: 120,
          column: 6
        },
        end: {
          line: 147,
          column: 9
        }
      },
      '54': {
        start: {
          line: 132,
          column: 8
        },
        end: {
          line: 134,
          column: 9
        }
      },
      '55': {
        start: {
          line: 133,
          column: 10
        },
        end: {
          line: 133,
          column: 17
        }
      },
      '56': {
        start: {
          line: 135,
          column: 8
        },
        end: {
          line: 135,
          column: 32
        }
      },
      '57': {
        start: {
          line: 136,
          column: 8
        },
        end: {
          line: 146,
          column: 11
        }
      },
      '58': {
        start: {
          line: 137,
          column: 10
        },
        end: {
          line: 144,
          column: 11
        }
      },
      '59': {
        start: {
          line: 138,
          column: 12
        },
        end: {
          line: 141,
          column: 15
        }
      },
      '60': {
        start: {
          line: 142,
          column: 12
        },
        end: {
          line: 142,
          column: 37
        }
      },
      '61': {
        start: {
          line: 143,
          column: 12
        },
        end: {
          line: 143,
          column: 19
        }
      },
      '62': {
        start: {
          line: 145,
          column: 10
        },
        end: {
          line: 145,
          column: 38
        }
      },
      '63': {
        start: {
          line: 151,
          column: 6
        },
        end: {
          line: 151,
          column: 31
        }
      },
      '64': {
        start: {
          line: 153,
          column: 6
        },
        end: {
          line: 153,
          column: 30
        }
      },
      '65': {
        start: {
          line: 154,
          column: 6
        },
        end: {
          line: 167,
          column: 7
        }
      },
      '66': {
        start: {
          line: 155,
          column: 8
        },
        end: {
          line: 157,
          column: 9
        }
      },
      '67': {
        start: {
          line: 156,
          column: 10
        },
        end: {
          line: 156,
          column: 19
        }
      },
      '68': {
        start: {
          line: 158,
          column: 25
        },
        end: {
          line: 158,
          column: 30
        }
      },
      '69': {
        start: {
          line: 159,
          column: 8
        },
        end: {
          line: 161,
          column: 9
        }
      },
      '70': {
        start: {
          line: 160,
          column: 10
        },
        end: {
          line: 160,
          column: 83
        }
      },
      '71': {
        start: {
          line: 162,
          column: 8
        },
        end: {
          line: 166,
          column: 11
        }
      },
      '72': {
        start: {
          line: 170,
          column: 6
        },
        end: {
          line: 170,
          column: 30
        }
      },
      '73': {
        start: {
          line: 173,
          column: 20
        },
        end: {
          line: 173,
          column: 22
        }
      },
      '74': {
        start: {
          line: 174,
          column: 6
        },
        end: {
          line: 178,
          column: 7
        }
      },
      '75': {
        start: {
          line: 175,
          column: 8
        },
        end: {
          line: 177,
          column: 9
        }
      },
      '76': {
        start: {
          line: 176,
          column: 10
        },
        end: {
          line: 176,
          column: 28
        }
      },
      '77': {
        start: {
          line: 180,
          column: 6
        },
        end: {
          line: 180,
          column: 57
        }
      },
      '78': {
        start: {
          line: 181,
          column: 6
        },
        end: {
          line: 183,
          column: 9
        }
      },
      '79': {
        start: {
          line: 182,
          column: 8
        },
        end: {
          line: 182,
          column: 40
        }
      },
      '80': {
        start: {
          line: 188,
          column: 19
        },
        end: {
          line: 193,
          column: 4
        }
      },
      '81': {
        start: {
          line: 194,
          column: 2
        },
        end: {
          line: 197,
          column: 4
        }
      },
      '82': {
        start: {
          line: 195,
          column: 4
        },
        end: {
          line: 195,
          column: 42
        }
      },
      '83': {
        start: {
          line: 196,
          column: 4
        },
        end: {
          line: 196,
          column: 22
        }
      },
      '84': {
        start: {
          line: 198,
          column: 2
        },
        end: {
          line: 204,
          column: 4
        }
      },
      '85': {
        start: {
          line: 199,
          column: 4
        },
        end: {
          line: 201,
          column: 5
        }
      },
      '86': {
        start: {
          line: 200,
          column: 6
        },
        end: {
          line: 200,
          column: 86
        }
      },
      '87': {
        start: {
          line: 203,
          column: 4
        },
        end: {
          line: 203,
          column: 43
        }
      },
      '88': {
        start: {
          line: 205,
          column: 2
        },
        end: {
          line: 207,
          column: 4
        }
      },
      '89': {
        start: {
          line: 206,
          column: 4
        },
        end: {
          line: 206,
          column: 43
        }
      },
      '90': {
        start: {
          line: 208,
          column: 2
        },
        end: {
          line: 208,
          column: 30
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 5,
            column: 50
          },
          end: {
            line: 5,
            column: 51
          }
        },
        loc: {
          start: {
            line: 5,
            column: 167
          },
          end: {
            line: 209,
            column: 1
          }
        },
        line: 5
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 28,
            column: 46
          },
          end: {
            line: 28,
            column: 47
          }
        },
        loc: {
          start: {
            line: 28,
            column: 54
          },
          end: {
            line: 35,
            column: 7
          }
        },
        line: 28
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 111,
            column: 89
          },
          end: {
            line: 111,
            column: 90
          }
        },
        loc: {
          start: {
            line: 111,
            column: 97
          },
          end: {
            line: 117,
            column: 7
          }
        },
        line: 111
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 131,
            column: 9
          },
          end: {
            line: 131,
            column: 10
          }
        },
        loc: {
          start: {
            line: 131,
            column: 22
          },
          end: {
            line: 147,
            column: 7
          }
        },
        line: 131
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 136,
            column: 84
          },
          end: {
            line: 136,
            column: 85
          }
        },
        loc: {
          start: {
            line: 136,
            column: 92
          },
          end: {
            line: 146,
            column: 9
          }
        },
        line: 136
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 181,
            column: 122
          },
          end: {
            line: 181,
            column: 123
          }
        },
        loc: {
          start: {
            line: 181,
            column: 130
          },
          end: {
            line: 183,
            column: 7
          }
        },
        line: 181
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 194,
            column: 31
          },
          end: {
            line: 194,
            column: 32
          }
        },
        loc: {
          start: {
            line: 194,
            column: 66
          },
          end: {
            line: 197,
            column: 3
          }
        },
        line: 194
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 198,
            column: 27
          },
          end: {
            line: 198,
            column: 28
          }
        },
        loc: {
          start: {
            line: 198,
            column: 74
          },
          end: {
            line: 204,
            column: 3
          }
        },
        line: 198
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 205,
            column: 25
          },
          end: {
            line: 205,
            column: 26
          }
        },
        loc: {
          start: {
            line: 205,
            column: 72
          },
          end: {
            line: 207,
            column: 3
          }
        },
        line: 205
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 48,
            column: 6
          },
          end: {
            line: 50,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 48,
            column: 6
          },
          end: {
            line: 50,
            column: 7
          }
        }, {
          start: {
            line: 48,
            column: 6
          },
          end: {
            line: 50,
            column: 7
          }
        }],
        line: 48
      },
      '1': {
        loc: {
          start: {
            line: 51,
            column: 6
          },
          end: {
            line: 53,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 51,
            column: 6
          },
          end: {
            line: 53,
            column: 7
          }
        }, {
          start: {
            line: 51,
            column: 6
          },
          end: {
            line: 53,
            column: 7
          }
        }],
        line: 51
      },
      '2': {
        loc: {
          start: {
            line: 54,
            column: 6
          },
          end: {
            line: 56,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 54,
            column: 6
          },
          end: {
            line: 56,
            column: 7
          }
        }, {
          start: {
            line: 54,
            column: 6
          },
          end: {
            line: 56,
            column: 7
          }
        }],
        line: 54
      },
      '3': {
        loc: {
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 60,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 60,
            column: 9
          }
        }, {
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 60,
            column: 9
          }
        }],
        line: 58
      },
      '4': {
        loc: {
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 76,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 76,
            column: 7
          }
        }, {
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 76,
            column: 7
          }
        }],
        line: 74
      },
      '5': {
        loc: {
          start: {
            line: 78,
            column: 6
          },
          end: {
            line: 80,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 78,
            column: 6
          },
          end: {
            line: 80,
            column: 7
          }
        }, {
          start: {
            line: 78,
            column: 6
          },
          end: {
            line: 80,
            column: 7
          }
        }],
        line: 78
      },
      '6': {
        loc: {
          start: {
            line: 96,
            column: 6
          },
          end: {
            line: 99,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 96,
            column: 6
          },
          end: {
            line: 99,
            column: 7
          }
        }, {
          start: {
            line: 96,
            column: 6
          },
          end: {
            line: 99,
            column: 7
          }
        }],
        line: 96
      },
      '7': {
        loc: {
          start: {
            line: 96,
            column: 10
          },
          end: {
            line: 96,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 96,
            column: 10
          },
          end: {
            line: 96,
            column: 13
          }
        }, {
          start: {
            line: 96,
            column: 17
          },
          end: {
            line: 96,
            column: 41
          }
        }],
        line: 96
      },
      '8': {
        loc: {
          start: {
            line: 101,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 101,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }, {
          start: {
            line: 101,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }],
        line: 101
      },
      '9': {
        loc: {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }, {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }],
        line: 105
      },
      '10': {
        loc: {
          start: {
            line: 113,
            column: 8
          },
          end: {
            line: 115,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 113,
            column: 8
          },
          end: {
            line: 115,
            column: 9
          }
        }, {
          start: {
            line: 113,
            column: 8
          },
          end: {
            line: 115,
            column: 9
          }
        }],
        line: 113
      },
      '11': {
        loc: {
          start: {
            line: 132,
            column: 8
          },
          end: {
            line: 134,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 132,
            column: 8
          },
          end: {
            line: 134,
            column: 9
          }
        }, {
          start: {
            line: 132,
            column: 8
          },
          end: {
            line: 134,
            column: 9
          }
        }],
        line: 132
      },
      '12': {
        loc: {
          start: {
            line: 137,
            column: 10
          },
          end: {
            line: 144,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 137,
            column: 10
          },
          end: {
            line: 144,
            column: 11
          }
        }, {
          start: {
            line: 137,
            column: 10
          },
          end: {
            line: 144,
            column: 11
          }
        }],
        line: 137
      },
      '13': {
        loc: {
          start: {
            line: 155,
            column: 8
          },
          end: {
            line: 157,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 155,
            column: 8
          },
          end: {
            line: 157,
            column: 9
          }
        }, {
          start: {
            line: 155,
            column: 8
          },
          end: {
            line: 157,
            column: 9
          }
        }],
        line: 155
      },
      '14': {
        loc: {
          start: {
            line: 159,
            column: 8
          },
          end: {
            line: 161,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 159,
            column: 8
          },
          end: {
            line: 161,
            column: 9
          }
        }, {
          start: {
            line: 159,
            column: 8
          },
          end: {
            line: 161,
            column: 9
          }
        }],
        line: 159
      },
      '15': {
        loc: {
          start: {
            line: 175,
            column: 8
          },
          end: {
            line: 177,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 175,
            column: 8
          },
          end: {
            line: 177,
            column: 9
          }
        }, {
          start: {
            line: 175,
            column: 8
          },
          end: {
            line: 177,
            column: 9
          }
        }],
        line: 175
      },
      '16': {
        loc: {
          start: {
            line: 199,
            column: 4
          },
          end: {
            line: 201,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 199,
            column: 4
          },
          end: {
            line: 201,
            column: 5
          }
        }, {
          start: {
            line: 199,
            column: 4
          },
          end: {
            line: 201,
            column: 5
          }
        }],
        line: 199
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_si8ysc2om.s[0]++;
/**
 * Created by BKSB on 16/04/2017.
 */

angular.module('app').controller('useractorCtrl', function ($scope, SweetAlert, $translate, $deltaActor, $CRUDService, $otherHttpService, $deltahttp, $filter, FileUploader) {
  cov_si8ysc2om.f[0]++;

  var PATH = (cov_si8ysc2om.s[1]++, 'User');
  cov_si8ysc2om.s[2]++;
  $scope.__labels = {
    EDIT: $translate.instant('COMMON.EDIT'),
    DELETE: $translate.instant('COMMON.DELETE')
  };
  cov_si8ysc2om.s[3]++;
  $deltaActor.addController($scope);
  cov_si8ysc2om.s[4]++;
  $scope.userManager = {
    isedited: false,
    editingUser: null,
    listUser: [],
    editLoading: false,
    picteurUpdated: null,
    actorList: [],
    actorListLink: [],
    error: {
      psw: false,
      email: false
    },

    // Get all user
    getAll: function getAll() {
      cov_si8ysc2om.s[5]++;

      $scope.isloading = true;
      cov_si8ysc2om.s[6]++;
      $CRUDService.getAll(PATH, { get: 'all' }, function (data) {
        cov_si8ysc2om.f[1]++;
        cov_si8ysc2om.s[7]++;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var el = _step.value;
            cov_si8ysc2om.s[8]++;

            el.TYPEUSER = el.TYPEUSER == 1;
            cov_si8ysc2om.s[9]++;
            el.picture = $deltahttp.getUserRepository(el.id);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        cov_si8ysc2om.s[10]++;
        $scope.userManager.listUser = data;
        cov_si8ysc2om.s[11]++;
        $scope.isloading = false;
      });
    },
    newItem: function newItem() {
      cov_si8ysc2om.s[12]++;

      this.edit({
        NOMUSER: '',
        PRENOMUSER: '',
        LOGIN: '',
        PASSWORD: '',
        TYPEUSER: false,
        id: 0
      });
    },
    __filterActor: function __filterActor(value) {
      cov_si8ysc2om.s[13]++;

      if (value.id == -100) {
        cov_si8ysc2om.b[0][0]++;
        cov_si8ysc2om.s[14]++;

        return true;
      } else {
        cov_si8ysc2om.b[0][1]++;
      }
      cov_si8ysc2om.s[15]++;
      if (value.id < 0) {
        cov_si8ysc2om.b[1][0]++;
        cov_si8ysc2om.s[16]++;

        return false;
      } else {
        cov_si8ysc2om.b[1][1]++;
      }
      cov_si8ysc2om.s[17]++;
      if ($scope.userManager.editingUser.IDACTEURS == value.id) {
        cov_si8ysc2om.b[2][0]++;
        cov_si8ysc2om.s[18]++;

        return true;
      } else {
        cov_si8ysc2om.b[2][1]++;
      }
      cov_si8ysc2om.s[19]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = $scope.userManager.listUser[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var el = _step2.value;
          cov_si8ysc2om.s[20]++;

          if (el.IDACTEURS == value.id) {
            cov_si8ysc2om.b[3][0]++;
            cov_si8ysc2om.s[21]++;

            return false;
          } else {
            cov_si8ysc2om.b[3][1]++;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      cov_si8ysc2om.s[22]++;
      return true;
    },
    edit: function edit(item) {
      cov_si8ysc2om.s[23]++;

      this.error = {
        psw: false,
        email: false,
        conf: false
      };
      cov_si8ysc2om.s[24]++;
      this.editingUser = angular.copy(item);

      cov_si8ysc2om.s[25]++;
      this.actorList = $filter('filter')($scope.listData_actors_bksb, this.__filterActor);
      cov_si8ysc2om.s[26]++;
      this.editingUser.IDACTEURS = $filter('filter')($scope.listData_actors_bksb, { id: this.editingUser.IDACTEURS }, true)[0];
      cov_si8ysc2om.s[27]++;
      if (!this.editingUser.IDACTEURS) {
        cov_si8ysc2om.b[4][0]++;
        cov_si8ysc2om.s[28]++;

        this.editingUser.IDACTEURS = this.actorList[0];
      } else {
        cov_si8ysc2om.b[4][1]++;
      }

      cov_si8ysc2om.s[29]++;
      if (this.editingUser.id != 0) {
        cov_si8ysc2om.b[5][0]++;
        cov_si8ysc2om.s[30]++;

        this.editingUser.PASSWORD = '&&&&&&&&&&';
      } else {
        cov_si8ysc2om.b[5][1]++;
      }
      cov_si8ysc2om.s[31]++;
      this.editingUser.CONFPSW = this.editingUser.PASSWORD;
      cov_si8ysc2om.s[32]++;
      this.editingUser.linkediting = $deltahttp.getUserRepository(this.editingUser.id);
      cov_si8ysc2om.s[33]++;
      this.picteurUpdated = null;
    },
    cancelItem: function cancelItem() {
      cov_si8ysc2om.s[34]++;

      this.editingUser = null;
    },
    validate: function validate() {
      cov_si8ysc2om.s[35]++;

      this.error = {
        psw: false,
        email: false,
        conf: false
      };
      // Controle login
      var dbl = (cov_si8ysc2om.s[36]++, $filter('filter')($scope.userManager.listUser, { LOGIN: $scope.userManager.editingUser.LOGIN }, true)[0]);
      cov_si8ysc2om.s[37]++;
      if ((cov_si8ysc2om.b[7][0]++, dbl) && (cov_si8ysc2om.b[7][1]++, this.editingUser.id == 0)) {
        cov_si8ysc2om.b[6][0]++;
        cov_si8ysc2om.s[38]++;

        this.error.email = true;
        cov_si8ysc2om.s[39]++;
        return;
      } else {
        cov_si8ysc2om.b[6][1]++;
      }
      // controle mot de passe (longueur)
      cov_si8ysc2om.s[40]++;
      if ($scope.userManager.editingUser.PASSWORD.length < 8) {
        cov_si8ysc2om.b[8][0]++;
        cov_si8ysc2om.s[41]++;

        this.error.psw = true;
        cov_si8ysc2om.s[42]++;
        return;
      } else {
        cov_si8ysc2om.b[8][1]++;
      }
      cov_si8ysc2om.s[43]++;
      if ($scope.userManager.editingUser.PASSWORD !== $scope.userManager.editingUser.CONFPSW) {
        cov_si8ysc2om.b[9][0]++;
        cov_si8ysc2om.s[44]++;

        this.error.conf = true;
        cov_si8ysc2om.s[45]++;
        return;
      } else {
        cov_si8ysc2om.b[9][1]++;
      }
      var user = (cov_si8ysc2om.s[46]++, angular.copy($scope.userManager.editingUser));
      cov_si8ysc2om.s[47]++;
      user.IDACTEURS = user.IDACTEURS.id;
      cov_si8ysc2om.s[48]++;
      $CRUDService.save(PATH, { action: 'miseAJourAdmin', valeur: $filter('json')(user) }, function (data) {
        cov_si8ysc2om.f[2]++;
        cov_si8ysc2om.s[49]++;

        $scope.userManager.cancelItem();
        cov_si8ysc2om.s[50]++;
        if (data == -2) {
          cov_si8ysc2om.b[10][0]++;
          cov_si8ysc2om.s[51]++;

          return;
        } else {
          cov_si8ysc2om.b[10][1]++;
        }
        cov_si8ysc2om.s[52]++;
        $scope.userManager.getAll();
      });
    },
    deleteItem: function deleteItem(item) {
      cov_si8ysc2om.s[53]++;

      SweetAlert.swal({
        title: $translate.instant('COMMON.CONFIRM'),
        // text: $translate.instant("G_U.CONFIRM_DELETE"),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c8c8c8',

        confirmButtonText: $translate.instant('COMMON.NO'),
        cancelButtonText: $translate.instant('COMMON.YES'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_si8ysc2om.f[3]++;
        cov_si8ysc2om.s[54]++;

        if (isconfirm) {
          cov_si8ysc2om.b[11][0]++;
          cov_si8ysc2om.s[55]++;

          return;
        } else {
          cov_si8ysc2om.b[11][1]++;
        }
        cov_si8ysc2om.s[56]++;
        $scope.isloading = true;
        cov_si8ysc2om.s[57]++;
        $CRUDService.delet(PATH, { action: 'delete', valeur: $filter('json')(item) }, function (data) {
          cov_si8ysc2om.f[4]++;
          cov_si8ysc2om.s[58]++;

          if (data < 0) {
            cov_si8ysc2om.b[12][0]++;
            cov_si8ysc2om.s[59]++;

            SweetAlert.swal({
              title: $translate.instant('COMMON.ERROR_DELETE'),
              text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
            });
            cov_si8ysc2om.s[60]++;
            $scope.isloading = false;
            cov_si8ysc2om.s[61]++;
            return;
          } else {
            cov_si8ysc2om.b[12][1]++;
          }
          cov_si8ysc2om.s[62]++;
          $scope.userManager.getAll();
        });
      });
    },
    listactor: function listactor(item) {
      cov_si8ysc2om.s[63]++;

      // List des acteurs
      this.userSelected = item;

      cov_si8ysc2om.s[64]++;
      this.actorListLink = [];
      cov_si8ysc2om.s[65]++;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = $scope.listData_actors_bksb[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var el = _step3.value;
          cov_si8ysc2om.s[66]++;

          if (el.id < 1) {
            cov_si8ysc2om.b[13][0]++;
            cov_si8ysc2om.s[67]++;

            continue;
          } else {
            cov_si8ysc2om.b[13][1]++;
          }
          var checkedvar = (cov_si8ysc2om.s[68]++, false);
          cov_si8ysc2om.s[69]++;
          if (item.LIST_ACTEURS != null) {
            cov_si8ysc2om.b[14][0]++;
            cov_si8ysc2om.s[70]++;

            checkedvar = item.LIST_ACTEURS.replace(el.id, '') != item.LIST_ACTEURS;
          } else {
            cov_si8ysc2om.b[14][1]++;
          }
          cov_si8ysc2om.s[71]++;
          this.actorListLink.push({
            name: el.FONCTION_ACTEUR + ' (' + el.CODE_ACTEUR + ')',
            id: el.id,
            checked: checkedvar
          });
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }
    },
    cancelList: function cancelList() {
      cov_si8ysc2om.s[72]++;

      this.actorListLink = [];
    },
    valideList: function valideList() {
      var tabEl = (cov_si8ysc2om.s[73]++, []);
      cov_si8ysc2om.s[74]++;
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = this.actorListLink[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var el = _step4.value;
          cov_si8ysc2om.s[75]++;

          if (el.checked) {
            cov_si8ysc2om.b[15][0]++;
            cov_si8ysc2om.s[76]++;

            tabEl.push(el.id);
          } else {
            cov_si8ysc2om.b[15][1]++;
          }
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      cov_si8ysc2om.s[77]++;
      this.userSelected.LIST_ACTEURS = tabEl.join(' , ');
      cov_si8ysc2om.s[78]++;
      $CRUDService.save(PATH, { id: this.userSelected.id, action: 'setListA', valeur: $filter('json')(tabEl.join(' , ')) }, function (data) {
        cov_si8ysc2om.f[5]++;
        cov_si8ysc2om.s[79]++;

        $scope.userManager.cancelList();
      });
    }
  };

  // uploadfile
  var uploader = (cov_si8ysc2om.s[80]++, $scope.uploader = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=chargePicture&' + $CRUDService.getComplement() + '&li_bksb=' + PATH,
    alias: 'photo_utilisateur',
    removeAfterUpload: true
  }));
  cov_si8ysc2om.s[81]++;
  uploader.onAfterAddingFile = function (fileItem, a, b, c, d, e) {
    cov_si8ysc2om.f[6]++;
    cov_si8ysc2om.s[82]++;

    $scope.userManager.editLoading = true;
    cov_si8ysc2om.s[83]++;
    fileItem.upload();
  };
  cov_si8ysc2om.s[84]++;
  uploader.onSuccessItem = function (fileItem, response, status, headers) {
    cov_si8ysc2om.f[7]++;
    cov_si8ysc2om.s[85]++;

    if (response.file) {
      cov_si8ysc2om.b[16][0]++;
      cov_si8ysc2om.s[86]++;

      $scope.userManager.picteurUpdated = $deltahttp.getUserRepository(response.file);
    } else {
      cov_si8ysc2om.b[16][1]++;
    }

    cov_si8ysc2om.s[87]++;
    $scope.userManager.editLoading = false;
  };
  cov_si8ysc2om.s[88]++;
  uploader.onErrorItem = function (fileItem, response, status, headers) {
    cov_si8ysc2om.f[8]++;
    cov_si8ysc2om.s[89]++;

    $scope.userManager.editLoading = false;
  };
  cov_si8ysc2om.s[90]++;
  $scope.userManager.getAll();
});