'use strict';

var cov_1x8zs20z2w = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/main/main_ctrl.js',
      hash = 'c35fccf1563546ed6c7e3a6288abb84d8f625a31',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/main/main_ctrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 5,
          column: 7
        }
      },
      '1': {
        start: {
          line: 4,
          column: 6
        },
        end: {
          line: 4,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 24
          },
          end: {
            line: 3,
            column: 25
          }
        },
        loc: {
          start: {
            line: 3,
            column: 36
          },
          end: {
            line: 5,
            column: 5
          }
        },
        line: 3
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1x8zs20z2w.s[0]++;
angular.module('app').controller('Main', function () {
  cov_1x8zs20z2w.f[0]++;
  cov_1x8zs20z2w.s[1]++;

  this.description = 'Test le loading';
});