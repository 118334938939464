'use strict';

var cov_4l82ltljn = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/risk/riskCtrl.js',
      hash = '2c2c9f2573a65a11d7b78a4811e1be6dcc9606d7',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/risk/riskCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 310,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 3,
          column: 70
        }
      },
      '2': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '3': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 11
        }
      },
      '4': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 7,
          column: 24
        }
      },
      '5': {
        start: {
          line: 8,
          column: 15
        },
        end: {
          line: 8,
          column: 21
        }
      },
      '6': {
        start: {
          line: 9,
          column: 20
        },
        end: {
          line: 9,
          column: 34
        }
      },
      '7': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 36
        }
      },
      '8': {
        start: {
          line: 11,
          column: 18
        },
        end: {
          line: 11,
          column: 48
        }
      },
      '9': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 41
        }
      },
      '10': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 38
        }
      },
      '11': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 19,
          column: 4
        }
      },
      '12': {
        start: {
          line: 20,
          column: 2
        },
        end: {
          line: 23,
          column: 4
        }
      },
      '13': {
        start: {
          line: 25,
          column: 2
        },
        end: {
          line: 30,
          column: 4
        }
      },
      '14': {
        start: {
          line: 32,
          column: 2
        },
        end: {
          line: 36,
          column: 4
        }
      },
      '15': {
        start: {
          line: 38,
          column: 2
        },
        end: {
          line: 42,
          column: 4
        }
      },
      '16': {
        start: {
          line: 44,
          column: 2
        },
        end: {
          line: 127,
          column: 4
        }
      },
      '17': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 103,
          column: 7
        }
      },
      '18': {
        start: {
          line: 102,
          column: 8
        },
        end: {
          line: 102,
          column: 50
        }
      },
      '19': {
        start: {
          line: 106,
          column: 6
        },
        end: {
          line: 109,
          column: 7
        }
      },
      '20': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 107,
          column: 29
        }
      },
      '21': {
        start: {
          line: 108,
          column: 8
        },
        end: {
          line: 108,
          column: 25
        }
      },
      '22': {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 113,
          column: 7
        }
      },
      '23': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 29
        }
      },
      '24': {
        start: {
          line: 112,
          column: 8
        },
        end: {
          line: 112,
          column: 40
        }
      },
      '25': {
        start: {
          line: 114,
          column: 6
        },
        end: {
          line: 117,
          column: 7
        }
      },
      '26': {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 115,
          column: 29
        }
      },
      '27': {
        start: {
          line: 116,
          column: 8
        },
        end: {
          line: 116,
          column: 42
        }
      },
      '28': {
        start: {
          line: 122,
          column: 6
        },
        end: {
          line: 122,
          column: 33
        }
      },
      '29': {
        start: {
          line: 129,
          column: 2
        },
        end: {
          line: 147,
          column: 5
        }
      },
      '30': {
        start: {
          line: 130,
          column: 15
        },
        end: {
          line: 130,
          column: 58
        }
      },
      '31': {
        start: {
          line: 131,
          column: 16
        },
        end: {
          line: 131,
          column: 17
        }
      },
      '32': {
        start: {
          line: 132,
          column: 4
        },
        end: {
          line: 142,
          column: 5
        }
      },
      '33': {
        start: {
          line: 134,
          column: 8
        },
        end: {
          line: 134,
          column: 18
        }
      },
      '34': {
        start: {
          line: 135,
          column: 8
        },
        end: {
          line: 135,
          column: 14
        }
      },
      '35': {
        start: {
          line: 138,
          column: 8
        },
        end: {
          line: 138,
          column: 18
        }
      },
      '36': {
        start: {
          line: 139,
          column: 8
        },
        end: {
          line: 139,
          column: 14
        }
      },
      '37': {
        start: {
          line: 141,
          column: 8
        },
        end: {
          line: 141,
          column: 18
        }
      },
      '38': {
        start: {
          line: 144,
          column: 4
        },
        end: {
          line: 146,
          column: 7
        }
      },
      '39': {
        start: {
          line: 145,
          column: 6
        },
        end: {
          line: 145,
          column: 37
        }
      },
      '40': {
        start: {
          line: 148,
          column: 2
        },
        end: {
          line: 186,
          column: 5
        }
      },
      '41': {
        start: {
          line: 149,
          column: 4
        },
        end: {
          line: 149,
          column: 28
        }
      },
      '42': {
        start: {
          line: 150,
          column: 4
        },
        end: {
          line: 185,
          column: 7
        }
      },
      '43': {
        start: {
          line: 151,
          column: 6
        },
        end: {
          line: 180,
          column: 9
        }
      },
      '44': {
        start: {
          line: 152,
          column: 8
        },
        end: {
          line: 152,
          column: 101
        }
      },
      '45': {
        start: {
          line: 153,
          column: 8
        },
        end: {
          line: 157,
          column: 9
        }
      },
      '46': {
        start: {
          line: 154,
          column: 10
        },
        end: {
          line: 154,
          column: 34
        }
      },
      '47': {
        start: {
          line: 156,
          column: 10
        },
        end: {
          line: 156,
          column: 59
        }
      },
      '48': {
        start: {
          line: 159,
          column: 8
        },
        end: {
          line: 159,
          column: 58
        }
      },
      '49': {
        start: {
          line: 161,
          column: 8
        },
        end: {
          line: 161,
          column: 70
        }
      },
      '50': {
        start: {
          line: 163,
          column: 8
        },
        end: {
          line: 163,
          column: 55
        }
      },
      '51': {
        start: {
          line: 165,
          column: 8
        },
        end: {
          line: 167,
          column: 11
        }
      },
      '52': {
        start: {
          line: 166,
          column: 10
        },
        end: {
          line: 166,
          column: 46
        }
      },
      '53': {
        start: {
          line: 168,
          column: 8
        },
        end: {
          line: 170,
          column: 11
        }
      },
      '54': {
        start: {
          line: 169,
          column: 10
        },
        end: {
          line: 169,
          column: 46
        }
      },
      '55': {
        start: {
          line: 172,
          column: 8
        },
        end: {
          line: 174,
          column: 11
        }
      },
      '56': {
        start: {
          line: 173,
          column: 10
        },
        end: {
          line: 173,
          column: 47
        }
      },
      '57': {
        start: {
          line: 175,
          column: 8
        },
        end: {
          line: 178,
          column: 10
        }
      },
      '58': {
        start: {
          line: 179,
          column: 8
        },
        end: {
          line: 179,
          column: 20
        }
      },
      '59': {
        start: {
          line: 182,
          column: 6
        },
        end: {
          line: 182,
          column: 57
        }
      },
      '60': {
        start: {
          line: 184,
          column: 6
        },
        end: {
          line: 184,
          column: 31
        }
      },
      '61': {
        start: {
          line: 188,
          column: 2
        },
        end: {
          line: 198,
          column: 5
        }
      },
      '62': {
        start: {
          line: 189,
          column: 4
        },
        end: {
          line: 189,
          column: 51
        }
      },
      '63': {
        start: {
          line: 190,
          column: 4
        },
        end: {
          line: 197,
          column: 7
        }
      },
      '64': {
        start: {
          line: 200,
          column: 2
        },
        end: {
          line: 216,
          column: 5
        }
      },
      '65': {
        start: {
          line: 201,
          column: 4
        },
        end: {
          line: 215,
          column: 7
        }
      },
      '66': {
        start: {
          line: 218,
          column: 2
        },
        end: {
          line: 242,
          column: 5
        }
      },
      '67': {
        start: {
          line: 219,
          column: 4
        },
        end: {
          line: 219,
          column: 30
        }
      },
      '68': {
        start: {
          line: 221,
          column: 4
        },
        end: {
          line: 241,
          column: 7
        }
      },
      '69': {
        start: {
          line: 222,
          column: 6
        },
        end: {
          line: 222,
          column: 47
        }
      },
      '70': {
        start: {
          line: 223,
          column: 6
        },
        end: {
          line: 229,
          column: 11
        }
      },
      '71': {
        start: {
          line: 225,
          column: 10
        },
        end: {
          line: 225,
          column: 81
        }
      },
      '72': {
        start: {
          line: 225,
          column: 57
        },
        end: {
          line: 225,
          column: 78
        }
      },
      '73': {
        start: {
          line: 228,
          column: 10
        },
        end: {
          line: 228,
          column: 23
        }
      },
      '74': {
        start: {
          line: 231,
          column: 6
        },
        end: {
          line: 231,
          column: 101
        }
      },
      '75': {
        start: {
          line: 233,
          column: 6
        },
        end: {
          line: 240,
          column: 9
        }
      },
      '76': {
        start: {
          line: 244,
          column: 2
        },
        end: {
          line: 283,
          column: 5
        }
      },
      '77': {
        start: {
          line: 245,
          column: 4
        },
        end: {
          line: 282,
          column: 7
        }
      },
      '78': {
        start: {
          line: 257,
          column: 6
        },
        end: {
          line: 259,
          column: 7
        }
      },
      '79': {
        start: {
          line: 258,
          column: 8
        },
        end: {
          line: 258,
          column: 15
        }
      },
      '80': {
        start: {
          line: 260,
          column: 6
        },
        end: {
          line: 260,
          column: 30
        }
      },
      '81': {
        start: {
          line: 261,
          column: 27
        },
        end: {
          line: 264,
          column: 7
        }
      },
      '82': {
        start: {
          line: 270,
          column: 6
        },
        end: {
          line: 281,
          column: 9
        }
      },
      '83': {
        start: {
          line: 271,
          column: 8
        },
        end: {
          line: 280,
          column: 9
        }
      },
      '84': {
        start: {
          line: 272,
          column: 10
        },
        end: {
          line: 276,
          column: 13
        }
      },
      '85': {
        start: {
          line: 277,
          column: 10
        },
        end: {
          line: 277,
          column: 35
        }
      },
      '86': {
        start: {
          line: 279,
          column: 10
        },
        end: {
          line: 279,
          column: 26
        }
      },
      '87': {
        start: {
          line: 285,
          column: 2
        },
        end: {
          line: 287,
          column: 5
        }
      },
      '88': {
        start: {
          line: 286,
          column: 4
        },
        end: {
          line: 286,
          column: 31
        }
      },
      '89': {
        start: {
          line: 289,
          column: 2
        },
        end: {
          line: 291,
          column: 5
        }
      },
      '90': {
        start: {
          line: 290,
          column: 4
        },
        end: {
          line: 290,
          column: 55
        }
      },
      '91': {
        start: {
          line: 293,
          column: 2
        },
        end: {
          line: 307,
          column: 5
        }
      },
      '92': {
        start: {
          line: 294,
          column: 4
        },
        end: {
          line: 298,
          column: 5
        }
      },
      '93': {
        start: {
          line: 295,
          column: 6
        },
        end: {
          line: 297,
          column: 9
        }
      },
      '94': {
        start: {
          line: 296,
          column: 8
        },
        end: {
          line: 296,
          column: 36
        }
      },
      '95': {
        start: {
          line: 300,
          column: 4
        },
        end: {
          line: 304,
          column: 5
        }
      },
      '96': {
        start: {
          line: 301,
          column: 6
        },
        end: {
          line: 303,
          column: 9
        }
      },
      '97': {
        start: {
          line: 302,
          column: 8
        },
        end: {
          line: 302,
          column: 36
        }
      },
      '98': {
        start: {
          line: 306,
          column: 4
        },
        end: {
          line: 306,
          column: 28
        }
      },
      '99': {
        start: {
          line: 309,
          column: 2
        },
        end: {
          line: 309,
          column: 18
        }
      },
      '100': {
        start: {
          line: 313,
          column: 2
        },
        end: {
          line: 313,
          column: 60
        }
      },
      '101': {
        start: {
          line: 315,
          column: 2
        },
        end: {
          line: 315,
          column: 64
        }
      },
      '102': {
        start: {
          line: 317,
          column: 2
        },
        end: {
          line: 317,
          column: 65
        }
      },
      '103': {
        start: {
          line: 319,
          column: 15
        },
        end: {
          line: 319,
          column: 21
        }
      },
      '104': {
        start: {
          line: 320,
          column: 2
        },
        end: {
          line: 320,
          column: 28
        }
      },
      '105': {
        start: {
          line: 324,
          column: 2
        },
        end: {
          line: 345,
          column: 3
        }
      },
      '106': {
        start: {
          line: 325,
          column: 20
        },
        end: {
          line: 325,
          column: 69
        }
      },
      '107': {
        start: {
          line: 326,
          column: 4
        },
        end: {
          line: 326,
          column: 43
        }
      },
      '108': {
        start: {
          line: 327,
          column: 4
        },
        end: {
          line: 327,
          column: 37
        }
      },
      '109': {
        start: {
          line: 329,
          column: 4
        },
        end: {
          line: 333,
          column: 5
        }
      },
      '110': {
        start: {
          line: 330,
          column: 6
        },
        end: {
          line: 330,
          column: 42
        }
      },
      '111': {
        start: {
          line: 332,
          column: 6
        },
        end: {
          line: 332,
          column: 27
        }
      },
      '112': {
        start: {
          line: 334,
          column: 4
        },
        end: {
          line: 338,
          column: 5
        }
      },
      '113': {
        start: {
          line: 335,
          column: 6
        },
        end: {
          line: 335,
          column: 42
        }
      },
      '114': {
        start: {
          line: 337,
          column: 6
        },
        end: {
          line: 337,
          column: 26
        }
      },
      '115': {
        start: {
          line: 339,
          column: 4
        },
        end: {
          line: 343,
          column: 5
        }
      },
      '116': {
        start: {
          line: 340,
          column: 6
        },
        end: {
          line: 340,
          column: 42
        }
      },
      '117': {
        start: {
          line: 342,
          column: 6
        },
        end: {
          line: 342,
          column: 23
        }
      },
      '118': {
        start: {
          line: 344,
          column: 4
        },
        end: {
          line: 344,
          column: 49
        }
      },
      '119': {
        start: {
          line: 346,
          column: 2
        },
        end: {
          line: 346,
          column: 83
        }
      },
      '120': {
        start: {
          line: 348,
          column: 2
        },
        end: {
          line: 356,
          column: 4
        }
      },
      '121': {
        start: {
          line: 349,
          column: 4
        },
        end: {
          line: 355,
          column: 5
        }
      },
      '122': {
        start: {
          line: 350,
          column: 6
        },
        end: {
          line: 354,
          column: 9
        }
      },
      '123': {
        start: {
          line: 351,
          column: 8
        },
        end: {
          line: 353,
          column: 11
        }
      },
      '124': {
        start: {
          line: 352,
          column: 10
        },
        end: {
          line: 352,
          column: 130
        }
      },
      '125': {
        start: {
          line: 358,
          column: 2
        },
        end: {
          line: 372,
          column: 4
        }
      },
      '126': {
        start: {
          line: 361,
          column: 18
        },
        end: {
          line: 361,
          column: 19
        }
      },
      '127': {
        start: {
          line: 362,
          column: 17
        },
        end: {
          line: 362,
          column: 49
        }
      },
      '128': {
        start: {
          line: 363,
          column: 14
        },
        end: {
          line: 363,
          column: 104
        }
      },
      '129': {
        start: {
          line: 364,
          column: 4
        },
        end: {
          line: 369,
          column: 7
        }
      },
      '130': {
        start: {
          line: 365,
          column: 21
        },
        end: {
          line: 365,
          column: 34
        }
      },
      '131': {
        start: {
          line: 366,
          column: 6
        },
        end: {
          line: 366,
          column: 55
        }
      },
      '132': {
        start: {
          line: 367,
          column: 6
        },
        end: {
          line: 367,
          column: 55
        }
      },
      '133': {
        start: {
          line: 368,
          column: 6
        },
        end: {
          line: 368,
          column: 36
        }
      },
      '134': {
        start: {
          line: 371,
          column: 4
        },
        end: {
          line: 371,
          column: 34
        }
      },
      '135': {
        start: {
          line: 374,
          column: 2
        },
        end: {
          line: 452,
          column: 4
        }
      },
      '136': {
        start: {
          line: 376,
          column: 6
        },
        end: {
          line: 381,
          column: 7
        }
      },
      '137': {
        start: {
          line: 377,
          column: 8
        },
        end: {
          line: 379,
          column: 11
        }
      },
      '138': {
        start: {
          line: 380,
          column: 8
        },
        end: {
          line: 380,
          column: 42
        }
      },
      '139': {
        start: {
          line: 382,
          column: 6
        },
        end: {
          line: 382,
          column: 36
        }
      },
      '140': {
        start: {
          line: 383,
          column: 21
        },
        end: {
          line: 383,
          column: 207
        }
      },
      '141': {
        start: {
          line: 384,
          column: 6
        },
        end: {
          line: 384,
          column: 49
        }
      },
      '142': {
        start: {
          line: 386,
          column: 20
        },
        end: {
          line: 386,
          column: 21
        }
      },
      '143': {
        start: {
          line: 387,
          column: 21
        },
        end: {
          line: 387,
          column: 22
        }
      },
      '144': {
        start: {
          line: 388,
          column: 22
        },
        end: {
          line: 388,
          column: 23
        }
      },
      '145': {
        start: {
          line: 389,
          column: 6
        },
        end: {
          line: 391,
          column: 7
        }
      },
      '146': {
        start: {
          line: 394,
          column: 19
        },
        end: {
          line: 394,
          column: 106
        }
      },
      '147': {
        start: {
          line: 398,
          column: 6
        },
        end: {
          line: 417,
          column: 7
        }
      },
      '148': {
        start: {
          line: 400,
          column: 10
        },
        end: {
          line: 400,
          column: 107
        }
      },
      '149': {
        start: {
          line: 401,
          column: 10
        },
        end: {
          line: 401,
          column: 16
        }
      },
      '150': {
        start: {
          line: 403,
          column: 10
        },
        end: {
          line: 403,
          column: 110
        }
      },
      '151': {
        start: {
          line: 404,
          column: 10
        },
        end: {
          line: 404,
          column: 16
        }
      },
      '152': {
        start: {
          line: 406,
          column: 10
        },
        end: {
          line: 406,
          column: 110
        }
      },
      '153': {
        start: {
          line: 407,
          column: 10
        },
        end: {
          line: 407,
          column: 16
        }
      },
      '154': {
        start: {
          line: 409,
          column: 10
        },
        end: {
          line: 409,
          column: 110
        }
      },
      '155': {
        start: {
          line: 410,
          column: 10
        },
        end: {
          line: 410,
          column: 16
        }
      },
      '156': {
        start: {
          line: 412,
          column: 10
        },
        end: {
          line: 412,
          column: 110
        }
      },
      '157': {
        start: {
          line: 413,
          column: 10
        },
        end: {
          line: 413,
          column: 16
        }
      },
      '158': {
        start: {
          line: 415,
          column: 10
        },
        end: {
          line: 415,
          column: 107
        }
      },
      '159': {
        start: {
          line: 416,
          column: 10
        },
        end: {
          line: 416,
          column: 16
        }
      },
      '160': {
        start: {
          line: 418,
          column: 6
        },
        end: {
          line: 418,
          column: 124
        }
      },
      '161': {
        start: {
          line: 421,
          column: 21
        },
        end: {
          line: 421,
          column: 22
        }
      },
      '162': {
        start: {
          line: 422,
          column: 6
        },
        end: {
          line: 425,
          column: 7
        }
      },
      '163': {
        start: {
          line: 423,
          column: 8
        },
        end: {
          line: 423,
          column: 42
        }
      },
      '164': {
        start: {
          line: 424,
          column: 8
        },
        end: {
          line: 424,
          column: 30
        }
      },
      '165': {
        start: {
          line: 427,
          column: 24
        },
        end: {
          line: 427,
          column: 66
        }
      },
      '166': {
        start: {
          line: 428,
          column: 6
        },
        end: {
          line: 434,
          column: 103
        }
      },
      '167': {
        start: {
          line: 436,
          column: 6
        },
        end: {
          line: 436,
          column: 64
        }
      },
      '168': {
        start: {
          line: 438,
          column: 19
        },
        end: {
          line: 438,
          column: 21
        }
      },
      '169': {
        start: {
          line: 439,
          column: 6
        },
        end: {
          line: 439,
          column: 96
        }
      },
      '170': {
        start: {
          line: 440,
          column: 6
        },
        end: {
          line: 440,
          column: 63
        }
      },
      '171': {
        start: {
          line: 441,
          column: 6
        },
        end: {
          line: 441,
          column: 96
        }
      },
      '172': {
        start: {
          line: 443,
          column: 6
        },
        end: {
          line: 443,
          column: 49
        }
      },
      '173': {
        start: {
          line: 445,
          column: 6
        },
        end: {
          line: 445,
          column: 26
        }
      },
      '174': {
        start: {
          line: 446,
          column: 6
        },
        end: {
          line: 446,
          column: 36
        }
      },
      '175': {
        start: {
          line: 448,
          column: 6
        },
        end: {
          line: 450,
          column: 9
        }
      },
      '176': {
        start: {
          line: 449,
          column: 8
        },
        end: {
          line: 449,
          column: 38
        }
      },
      '177': {
        start: {
          line: 454,
          column: 2
        },
        end: {
          line: 454,
          column: 30
        }
      },
      '178': {
        start: {
          line: 458,
          column: 20
        },
        end: {
          line: 458,
          column: 44
        }
      },
      '179': {
        start: {
          line: 459,
          column: 2
        },
        end: {
          line: 482,
          column: 4
        }
      },
      '180': {
        start: {
          line: 485,
          column: 2
        },
        end: {
          line: 485,
          column: 25
        }
      },
      '181': {
        start: {
          line: 487,
          column: 2
        },
        end: {
          line: 498,
          column: 5
        }
      },
      '182': {
        start: {
          line: 488,
          column: 4
        },
        end: {
          line: 497,
          column: 6
        }
      },
      '183': {
        start: {
          line: 495,
          column: 8
        },
        end: {
          line: 495,
          column: 20
        }
      },
      '184': {
        start: {
          line: 500,
          column: 2
        },
        end: {
          line: 514,
          column: 5
        }
      },
      '185': {
        start: {
          line: 501,
          column: 15
        },
        end: {
          line: 501,
          column: 96
        }
      },
      '186': {
        start: {
          line: 503,
          column: 4
        },
        end: {
          line: 513,
          column: 5
        }
      },
      '187': {
        start: {
          line: 505,
          column: 8
        },
        end: {
          line: 505,
          column: 47
        }
      },
      '188': {
        start: {
          line: 506,
          column: 8
        },
        end: {
          line: 506,
          column: 14
        }
      },
      '189': {
        start: {
          line: 509,
          column: 8
        },
        end: {
          line: 509,
          column: 47
        }
      },
      '190': {
        start: {
          line: 510,
          column: 8
        },
        end: {
          line: 510,
          column: 14
        }
      },
      '191': {
        start: {
          line: 512,
          column: 8
        },
        end: {
          line: 512,
          column: 47
        }
      },
      '192': {
        start: {
          line: 516,
          column: 2
        },
        end: {
          line: 518,
          column: 5
        }
      },
      '193': {
        start: {
          line: 517,
          column: 4
        },
        end: {
          line: 517,
          column: 61
        }
      },
      '194': {
        start: {
          line: 520,
          column: 2
        },
        end: {
          line: 522,
          column: 5
        }
      },
      '195': {
        start: {
          line: 521,
          column: 4
        },
        end: {
          line: 521,
          column: 57
        }
      },
      '196': {
        start: {
          line: 524,
          column: 2
        },
        end: {
          line: 526,
          column: 5
        }
      },
      '197': {
        start: {
          line: 525,
          column: 4
        },
        end: {
          line: 525,
          column: 52
        }
      },
      '198': {
        start: {
          line: 528,
          column: 2
        },
        end: {
          line: 530,
          column: 5
        }
      },
      '199': {
        start: {
          line: 529,
          column: 4
        },
        end: {
          line: 529,
          column: 58
        }
      },
      '200': {
        start: {
          line: 532,
          column: 2
        },
        end: {
          line: 583,
          column: 4
        }
      },
      '201': {
        start: {
          line: 533,
          column: 4
        },
        end: {
          line: 535,
          column: 5
        }
      },
      '202': {
        start: {
          line: 534,
          column: 6
        },
        end: {
          line: 534,
          column: 13
        }
      },
      '203': {
        start: {
          line: 538,
          column: 4
        },
        end: {
          line: 538,
          column: 68
        }
      },
      '204': {
        start: {
          line: 540,
          column: 25
        },
        end: {
          line: 557,
          column: 5
        }
      },
      '205': {
        start: {
          line: 555,
          column: 8
        },
        end: {
          line: 555,
          column: 24
        }
      },
      '206': {
        start: {
          line: 559,
          column: 4
        },
        end: {
          line: 559,
          column: 26
        }
      },
      '207': {
        start: {
          line: 560,
          column: 4
        },
        end: {
          line: 564,
          column: 5
        }
      },
      '208': {
        start: {
          line: 561,
          column: 6
        },
        end: {
          line: 561,
          column: 28
        }
      },
      '209': {
        start: {
          line: 562,
          column: 6
        },
        end: {
          line: 562,
          column: 38
        }
      },
      '210': {
        start: {
          line: 563,
          column: 6
        },
        end: {
          line: 563,
          column: 13
        }
      },
      '211': {
        start: {
          line: 566,
          column: 4
        },
        end: {
          line: 570,
          column: 5
        }
      },
      '212': {
        start: {
          line: 567,
          column: 6
        },
        end: {
          line: 567,
          column: 28
        }
      },
      '213': {
        start: {
          line: 568,
          column: 6
        },
        end: {
          line: 568,
          column: 38
        }
      },
      '214': {
        start: {
          line: 569,
          column: 6
        },
        end: {
          line: 569,
          column: 13
        }
      },
      '215': {
        start: {
          line: 572,
          column: 4
        },
        end: {
          line: 574,
          column: 5
        }
      },
      '216': {
        start: {
          line: 573,
          column: 6
        },
        end: {
          line: 573,
          column: 33
        }
      },
      '217': {
        start: {
          line: 575,
          column: 18
        },
        end: {
          line: 575,
          column: 69
        }
      },
      '218': {
        start: {
          line: 576,
          column: 4
        },
        end: {
          line: 582,
          column: 7
        }
      },
      '219': {
        start: {
          line: 577,
          column: 6
        },
        end: {
          line: 577,
          column: 26
        }
      },
      '220': {
        start: {
          line: 578,
          column: 6
        },
        end: {
          line: 578,
          column: 22
        }
      },
      '221': {
        start: {
          line: 579,
          column: 6
        },
        end: {
          line: 581,
          column: 7
        }
      },
      '222': {
        start: {
          line: 580,
          column: 8
        },
        end: {
          line: 580,
          column: 93
        }
      },
      '223': {
        start: {
          line: 585,
          column: 2
        },
        end: {
          line: 587,
          column: 4
        }
      },
      '224': {
        start: {
          line: 586,
          column: 4
        },
        end: {
          line: 586,
          column: 40
        }
      },
      '225': {
        start: {
          line: 588,
          column: 2
        },
        end: {
          line: 588,
          column: 24
        }
      },
      '226': {
        start: {
          line: 590,
          column: 2
        },
        end: {
          line: 592,
          column: 4
        }
      },
      '227': {
        start: {
          line: 591,
          column: 4
        },
        end: {
          line: 591,
          column: 50
        }
      },
      '228': {
        start: {
          line: 594,
          column: 2
        },
        end: {
          line: 634,
          column: 5
        }
      },
      '229': {
        start: {
          line: 595,
          column: 4
        },
        end: {
          line: 633,
          column: 7
        }
      },
      '230': {
        start: {
          line: 607,
          column: 6
        },
        end: {
          line: 609,
          column: 7
        }
      },
      '231': {
        start: {
          line: 608,
          column: 8
        },
        end: {
          line: 608,
          column: 15
        }
      },
      '232': {
        start: {
          line: 610,
          column: 6
        },
        end: {
          line: 610,
          column: 30
        }
      },
      '233': {
        start: {
          line: 611,
          column: 27
        },
        end: {
          line: 614,
          column: 7
        }
      },
      '234': {
        start: {
          line: 620,
          column: 6
        },
        end: {
          line: 632,
          column: 9
        }
      },
      '235': {
        start: {
          line: 621,
          column: 8
        },
        end: {
          line: 631,
          column: 9
        }
      },
      '236': {
        start: {
          line: 622,
          column: 10
        },
        end: {
          line: 626,
          column: 13
        }
      },
      '237': {
        start: {
          line: 627,
          column: 10
        },
        end: {
          line: 627,
          column: 35
        }
      },
      '238': {
        start: {
          line: 629,
          column: 10
        },
        end: {
          line: 629,
          column: 26
        }
      },
      '239': {
        start: {
          line: 630,
          column: 10
        },
        end: {
          line: 630,
          column: 30
        }
      },
      '240': {
        start: {
          line: 638,
          column: 2
        },
        end: {
          line: 640,
          column: 4
        }
      },
      '241': {
        start: {
          line: 639,
          column: 4
        },
        end: {
          line: 639,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 45
          },
          end: {
            line: 2,
            column: 46
          }
        },
        loc: {
          start: {
            line: 2,
            column: 229
          },
          end: {
            line: 310,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 129,
            column: 27
          },
          end: {
            line: 129,
            column: 28
          }
        },
        loc: {
          start: {
            line: 129,
            column: 35
          },
          end: {
            line: 147,
            column: 3
          }
        },
        line: 129
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 144,
            column: 37
          },
          end: {
            line: 144,
            column: 38
          }
        },
        loc: {
          start: {
            line: 144,
            column: 52
          },
          end: {
            line: 146,
            column: 5
          }
        },
        line: 144
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 148,
            column: 19
          },
          end: {
            line: 148,
            column: 20
          }
        },
        loc: {
          start: {
            line: 148,
            column: 25
          },
          end: {
            line: 186,
            column: 3
          }
        },
        line: 148
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 150,
            column: 67
          },
          end: {
            line: 150,
            column: 68
          }
        },
        loc: {
          start: {
            line: 150,
            column: 75
          },
          end: {
            line: 185,
            column: 5
          }
        },
        line: 150
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 151,
            column: 34
          },
          end: {
            line: 151,
            column: 35
          }
        },
        loc: {
          start: {
            line: 151,
            column: 42
          },
          end: {
            line: 180,
            column: 7
          }
        },
        line: 151
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 165,
            column: 54
          },
          end: {
            line: 165,
            column: 55
          }
        },
        loc: {
          start: {
            line: 165,
            column: 62
          },
          end: {
            line: 167,
            column: 9
          }
        },
        line: 165
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 168,
            column: 50
          },
          end: {
            line: 168,
            column: 51
          }
        },
        loc: {
          start: {
            line: 168,
            column: 58
          },
          end: {
            line: 170,
            column: 9
          }
        },
        line: 168
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 172,
            column: 45
          },
          end: {
            line: 172,
            column: 46
          }
        },
        loc: {
          start: {
            line: 172,
            column: 53
          },
          end: {
            line: 174,
            column: 9
          }
        },
        line: 172
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 188,
            column: 31
          },
          end: {
            line: 188,
            column: 32
          }
        },
        loc: {
          start: {
            line: 188,
            column: 39
          },
          end: {
            line: 198,
            column: 3
          }
        },
        line: 188
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 200,
            column: 20
          },
          end: {
            line: 200,
            column: 21
          }
        },
        loc: {
          start: {
            line: 200,
            column: 26
          },
          end: {
            line: 216,
            column: 3
          }
        },
        line: 200
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 218,
            column: 21
          },
          end: {
            line: 218,
            column: 22
          }
        },
        loc: {
          start: {
            line: 218,
            column: 29
          },
          end: {
            line: 242,
            column: 3
          }
        },
        line: 218
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 221,
            column: 60
          },
          end: {
            line: 221,
            column: 61
          }
        },
        loc: {
          start: {
            line: 221,
            column: 68
          },
          end: {
            line: 241,
            column: 5
          }
        },
        line: 221
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 224,
            column: 13
          },
          end: {
            line: 224,
            column: 14
          }
        },
        loc: {
          start: {
            line: 224,
            column: 22
          },
          end: {
            line: 226,
            column: 9
          }
        },
        line: 224
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 225,
            column: 50
          },
          end: {
            line: 225,
            column: 51
          }
        },
        loc: {
          start: {
            line: 225,
            column: 57
          },
          end: {
            line: 225,
            column: 78
          }
        },
        line: 225
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 227,
            column: 16
          },
          end: {
            line: 227,
            column: 17
          }
        },
        loc: {
          start: {
            line: 227,
            column: 25
          },
          end: {
            line: 229,
            column: 9
          }
        },
        line: 227
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 244,
            column: 23
          },
          end: {
            line: 244,
            column: 24
          }
        },
        loc: {
          start: {
            line: 244,
            column: 31
          },
          end: {
            line: 283,
            column: 3
          }
        },
        line: 244
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 256,
            column: 7
          },
          end: {
            line: 256,
            column: 8
          }
        },
        loc: {
          start: {
            line: 256,
            column: 20
          },
          end: {
            line: 282,
            column: 5
          }
        },
        line: 256
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 270,
            column: 88
          },
          end: {
            line: 270,
            column: 89
          }
        },
        loc: {
          start: {
            line: 270,
            column: 96
          },
          end: {
            line: 281,
            column: 7
          }
        },
        line: 270
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 285,
            column: 23
          },
          end: {
            line: 285,
            column: 24
          }
        },
        loc: {
          start: {
            line: 285,
            column: 29
          },
          end: {
            line: 287,
            column: 3
          }
        },
        line: 285
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 289,
            column: 23
          },
          end: {
            line: 289,
            column: 24
          }
        },
        loc: {
          start: {
            line: 289,
            column: 29
          },
          end: {
            line: 291,
            column: 3
          }
        },
        line: 289
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 293,
            column: 27
          },
          end: {
            line: 293,
            column: 28
          }
        },
        loc: {
          start: {
            line: 293,
            column: 33
          },
          end: {
            line: 307,
            column: 3
          }
        },
        line: 293
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 295,
            column: 37
          },
          end: {
            line: 295,
            column: 38
          }
        },
        loc: {
          start: {
            line: 295,
            column: 46
          },
          end: {
            line: 297,
            column: 7
          }
        },
        line: 295
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 301,
            column: 37
          },
          end: {
            line: 301,
            column: 38
          }
        },
        loc: {
          start: {
            line: 301,
            column: 46
          },
          end: {
            line: 303,
            column: 7
          }
        },
        line: 301
      },
      '24': {
        name: 'RiskCtrlCtrl_edit',
        decl: {
          start: {
            line: 312,
            column: 9
          },
          end: {
            line: 312,
            column: 26
          }
        },
        loc: {
          start: {
            line: 312,
            column: 156
          },
          end: {
            line: 635,
            column: 1
          }
        },
        line: 312
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 348,
            column: 30
          },
          end: {
            line: 348,
            column: 31
          }
        },
        loc: {
          start: {
            line: 348,
            column: 42
          },
          end: {
            line: 356,
            column: 3
          }
        },
        line: 348
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 350,
            column: 47
          },
          end: {
            line: 350,
            column: 48
          }
        },
        loc: {
          start: {
            line: 350,
            column: 59
          },
          end: {
            line: 354,
            column: 7
          }
        },
        line: 350
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 351,
            column: 22
          },
          end: {
            line: 351,
            column: 23
          }
        },
        loc: {
          start: {
            line: 351,
            column: 28
          },
          end: {
            line: 353,
            column: 9
          }
        },
        line: 351
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 358,
            column: 22
          },
          end: {
            line: 358,
            column: 23
          }
        },
        loc: {
          start: {
            line: 358,
            column: 34
          },
          end: {
            line: 372,
            column: 3
          }
        },
        line: 358
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 364,
            column: 20
          },
          end: {
            line: 364,
            column: 21
          }
        },
        loc: {
          start: {
            line: 364,
            column: 28
          },
          end: {
            line: 369,
            column: 5
          }
        },
        line: 364
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 448,
            column: 30
          },
          end: {
            line: 448,
            column: 31
          }
        },
        loc: {
          start: {
            line: 448,
            column: 39
          },
          end: {
            line: 450,
            column: 7
          }
        },
        line: 448
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 487,
            column: 46
          },
          end: {
            line: 487,
            column: 47
          }
        },
        loc: {
          start: {
            line: 487,
            column: 54
          },
          end: {
            line: 498,
            column: 3
          }
        },
        line: 487
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 500,
            column: 25
          },
          end: {
            line: 500,
            column: 26
          }
        },
        loc: {
          start: {
            line: 500,
            column: 31
          },
          end: {
            line: 514,
            column: 3
          }
        },
        line: 500
      },
      '33': {
        name: '(anonymous_33)',
        decl: {
          start: {
            line: 516,
            column: 63
          },
          end: {
            line: 516,
            column: 64
          }
        },
        loc: {
          start: {
            line: 516,
            column: 71
          },
          end: {
            line: 518,
            column: 3
          }
        },
        line: 516
      },
      '34': {
        name: '(anonymous_34)',
        decl: {
          start: {
            line: 520,
            column: 59
          },
          end: {
            line: 520,
            column: 60
          }
        },
        loc: {
          start: {
            line: 520,
            column: 67
          },
          end: {
            line: 522,
            column: 3
          }
        },
        line: 520
      },
      '35': {
        name: '(anonymous_35)',
        decl: {
          start: {
            line: 524,
            column: 54
          },
          end: {
            line: 524,
            column: 55
          }
        },
        loc: {
          start: {
            line: 524,
            column: 62
          },
          end: {
            line: 526,
            column: 3
          }
        },
        line: 524
      },
      '36': {
        name: '(anonymous_36)',
        decl: {
          start: {
            line: 528,
            column: 69
          },
          end: {
            line: 528,
            column: 70
          }
        },
        loc: {
          start: {
            line: 528,
            column: 78
          },
          end: {
            line: 530,
            column: 3
          }
        },
        line: 528
      },
      '37': {
        name: '(anonymous_37)',
        decl: {
          start: {
            line: 532,
            column: 24
          },
          end: {
            line: 532,
            column: 25
          }
        },
        loc: {
          start: {
            line: 532,
            column: 36
          },
          end: {
            line: 583,
            column: 3
          }
        },
        line: 532
      },
      '38': {
        name: '(anonymous_38)',
        decl: {
          start: {
            line: 554,
            column: 51
          },
          end: {
            line: 554,
            column: 52
          }
        },
        loc: {
          start: {
            line: 554,
            column: 60
          },
          end: {
            line: 556,
            column: 7
          }
        },
        line: 554
      },
      '39': {
        name: '(anonymous_39)',
        decl: {
          start: {
            line: 576,
            column: 90
          },
          end: {
            line: 576,
            column: 91
          }
        },
        loc: {
          start: {
            line: 576,
            column: 96
          },
          end: {
            line: 582,
            column: 5
          }
        },
        line: 576
      },
      '40': {
        name: '(anonymous_40)',
        decl: {
          start: {
            line: 585,
            column: 22
          },
          end: {
            line: 585,
            column: 23
          }
        },
        loc: {
          start: {
            line: 585,
            column: 34
          },
          end: {
            line: 587,
            column: 3
          }
        },
        line: 585
      },
      '41': {
        name: '(anonymous_41)',
        decl: {
          start: {
            line: 590,
            column: 22
          },
          end: {
            line: 590,
            column: 23
          }
        },
        loc: {
          start: {
            line: 590,
            column: 34
          },
          end: {
            line: 592,
            column: 3
          }
        },
        line: 590
      },
      '42': {
        name: '(anonymous_42)',
        decl: {
          start: {
            line: 594,
            column: 30
          },
          end: {
            line: 594,
            column: 31
          }
        },
        loc: {
          start: {
            line: 594,
            column: 38
          },
          end: {
            line: 634,
            column: 3
          }
        },
        line: 594
      },
      '43': {
        name: '(anonymous_43)',
        decl: {
          start: {
            line: 606,
            column: 7
          },
          end: {
            line: 606,
            column: 8
          }
        },
        loc: {
          start: {
            line: 606,
            column: 20
          },
          end: {
            line: 633,
            column: 5
          }
        },
        line: 606
      },
      '44': {
        name: '(anonymous_44)',
        decl: {
          start: {
            line: 620,
            column: 88
          },
          end: {
            line: 620,
            column: 89
          }
        },
        loc: {
          start: {
            line: 620,
            column: 96
          },
          end: {
            line: 632,
            column: 7
          }
        },
        line: 620
      },
      '45': {
        name: 'RiskCtrl_recommendation',
        decl: {
          start: {
            line: 637,
            column: 9
          },
          end: {
            line: 637,
            column: 32
          }
        },
        loc: {
          start: {
            line: 637,
            column: 60
          },
          end: {
            line: 641,
            column: 1
          }
        },
        line: 637
      },
      '46': {
        name: '(anonymous_46)',
        decl: {
          start: {
            line: 638,
            column: 22
          },
          end: {
            line: 638,
            column: 23
          }
        },
        loc: {
          start: {
            line: 638,
            column: 34
          },
          end: {
            line: 640,
            column: 3
          }
        },
        line: 638
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 3,
            column: 30
          },
          end: {
            line: 3,
            column: 68
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 3,
            column: 55
          },
          end: {
            line: 3,
            column: 60
          }
        }, {
          start: {
            line: 3,
            column: 63
          },
          end: {
            line: 3,
            column: 68
          }
        }],
        line: 3
      },
      '1': {
        loc: {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        }, {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        }],
        line: 4
      },
      '2': {
        loc: {
          start: {
            line: 18,
            column: 10
          },
          end: {
            line: 18,
            column: 38
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 18,
            column: 33
          },
          end: {
            line: 18,
            column: 34
          }
        }, {
          start: {
            line: 18,
            column: 37
          },
          end: {
            line: 18,
            column: 38
          }
        }],
        line: 18
      },
      '3': {
        loc: {
          start: {
            line: 22,
            column: 10
          },
          end: {
            line: 22,
            column: 38
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 22,
            column: 33
          },
          end: {
            line: 22,
            column: 34
          }
        }, {
          start: {
            line: 22,
            column: 37
          },
          end: {
            line: 22,
            column: 38
          }
        }],
        line: 22
      },
      '4': {
        loc: {
          start: {
            line: 46,
            column: 14
          },
          end: {
            line: 46,
            column: 61
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 46,
            column: 42
          },
          end: {
            line: 46,
            column: 49
          }
        }, {
          start: {
            line: 46,
            column: 52
          },
          end: {
            line: 46,
            column: 61
          }
        }],
        line: 46
      },
      '5': {
        loc: {
          start: {
            line: 49,
            column: 71
          },
          end: {
            line: 49,
            column: 122
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 49,
            column: 94
          },
          end: {
            line: 49,
            column: 105
          }
        }, {
          start: {
            line: 49,
            column: 108
          },
          end: {
            line: 49,
            column: 122
          }
        }],
        line: 49
      },
      '6': {
        loc: {
          start: {
            line: 77,
            column: 13
          },
          end: {
            line: 80,
            column: 10
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 78,
            column: 4
          },
          end: {
            line: 80,
            column: 5
          }
        }, {
          start: {
            line: 80,
            column: 8
          },
          end: {
            line: 80,
            column: 10
          }
        }],
        line: 77
      },
      '7': {
        loc: {
          start: {
            line: 101,
            column: 6
          },
          end: {
            line: 103,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 101,
            column: 6
          },
          end: {
            line: 103,
            column: 7
          }
        }, {
          start: {
            line: 101,
            column: 6
          },
          end: {
            line: 103,
            column: 7
          }
        }],
        line: 101
      },
      '8': {
        loc: {
          start: {
            line: 106,
            column: 6
          },
          end: {
            line: 109,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 106,
            column: 6
          },
          end: {
            line: 109,
            column: 7
          }
        }, {
          start: {
            line: 106,
            column: 6
          },
          end: {
            line: 109,
            column: 7
          }
        }],
        line: 106
      },
      '9': {
        loc: {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 113,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 113,
            column: 7
          }
        }, {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 113,
            column: 7
          }
        }],
        line: 110
      },
      '10': {
        loc: {
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 117,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 117,
            column: 7
          }
        }, {
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 117,
            column: 7
          }
        }],
        line: 114
      },
      '11': {
        loc: {
          start: {
            line: 132,
            column: 4
          },
          end: {
            line: 142,
            column: 5
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 133,
            column: 6
          },
          end: {
            line: 133,
            column: 17
          }
        }, {
          start: {
            line: 133,
            column: 18
          },
          end: {
            line: 133,
            column: 29
          }
        }, {
          start: {
            line: 133,
            column: 30
          },
          end: {
            line: 133,
            column: 41
          }
        }, {
          start: {
            line: 133,
            column: 42
          },
          end: {
            line: 133,
            column: 53
          }
        }, {
          start: {
            line: 133,
            column: 54
          },
          end: {
            line: 133,
            column: 65
          }
        }, {
          start: {
            line: 133,
            column: 66
          },
          end: {
            line: 133,
            column: 77
          }
        }, {
          start: {
            line: 133,
            column: 78
          },
          end: {
            line: 133,
            column: 89
          }
        }, {
          start: {
            line: 133,
            column: 90
          },
          end: {
            line: 135,
            column: 14
          }
        }, {
          start: {
            line: 137,
            column: 6
          },
          end: {
            line: 137,
            column: 17
          }
        }, {
          start: {
            line: 137,
            column: 18
          },
          end: {
            line: 137,
            column: 29
          }
        }, {
          start: {
            line: 137,
            column: 30
          },
          end: {
            line: 137,
            column: 41
          }
        }, {
          start: {
            line: 137,
            column: 42
          },
          end: {
            line: 137,
            column: 53
          }
        }, {
          start: {
            line: 137,
            column: 54
          },
          end: {
            line: 139,
            column: 14
          }
        }, {
          start: {
            line: 140,
            column: 6
          },
          end: {
            line: 141,
            column: 18
          }
        }],
        line: 132
      },
      '12': {
        loc: {
          start: {
            line: 152,
            column: 35
          },
          end: {
            line: 152,
            column: 99
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 152,
            column: 35
          },
          end: {
            line: 152,
            column: 94
          }
        }, {
          start: {
            line: 152,
            column: 98
          },
          end: {
            line: 152,
            column: 99
          }
        }],
        line: 152
      },
      '13': {
        loc: {
          start: {
            line: 153,
            column: 8
          },
          end: {
            line: 157,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 153,
            column: 8
          },
          end: {
            line: 157,
            column: 9
          }
        }, {
          start: {
            line: 153,
            column: 8
          },
          end: {
            line: 157,
            column: 9
          }
        }],
        line: 153
      },
      '14': {
        loc: {
          start: {
            line: 176,
            column: 14
          },
          end: {
            line: 176,
            column: 36
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 176,
            column: 14
          },
          end: {
            line: 176,
            column: 28
          }
        }, {
          start: {
            line: 176,
            column: 32
          },
          end: {
            line: 176,
            column: 36
          }
        }],
        line: 176
      },
      '15': {
        loc: {
          start: {
            line: 223,
            column: 39
          },
          end: {
            line: 223,
            column: 74
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 223,
            column: 39
          },
          end: {
            line: 223,
            column: 68
          }
        }, {
          start: {
            line: 223,
            column: 72
          },
          end: {
            line: 223,
            column: 74
          }
        }],
        line: 223
      },
      '16': {
        loc: {
          start: {
            line: 231,
            column: 47
          },
          end: {
            line: 231,
            column: 100
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 231,
            column: 72
          },
          end: {
            line: 231,
            column: 95
          }
        }, {
          start: {
            line: 231,
            column: 98
          },
          end: {
            line: 231,
            column: 100
          }
        }],
        line: 231
      },
      '17': {
        loc: {
          start: {
            line: 257,
            column: 6
          },
          end: {
            line: 259,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 257,
            column: 6
          },
          end: {
            line: 259,
            column: 7
          }
        }, {
          start: {
            line: 257,
            column: 6
          },
          end: {
            line: 259,
            column: 7
          }
        }],
        line: 257
      },
      '18': {
        loc: {
          start: {
            line: 271,
            column: 8
          },
          end: {
            line: 280,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 271,
            column: 8
          },
          end: {
            line: 280,
            column: 9
          }
        }, {
          start: {
            line: 271,
            column: 8
          },
          end: {
            line: 280,
            column: 9
          }
        }],
        line: 271
      },
      '19': {
        loc: {
          start: {
            line: 294,
            column: 4
          },
          end: {
            line: 298,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 294,
            column: 4
          },
          end: {
            line: 298,
            column: 5
          }
        }, {
          start: {
            line: 294,
            column: 4
          },
          end: {
            line: 298,
            column: 5
          }
        }],
        line: 294
      },
      '20': {
        loc: {
          start: {
            line: 294,
            column: 8
          },
          end: {
            line: 294,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 294,
            column: 8
          },
          end: {
            line: 294,
            column: 33
          }
        }, {
          start: {
            line: 294,
            column: 37
          },
          end: {
            line: 294,
            column: 66
          }
        }],
        line: 294
      },
      '21': {
        loc: {
          start: {
            line: 300,
            column: 4
          },
          end: {
            line: 304,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 300,
            column: 4
          },
          end: {
            line: 304,
            column: 5
          }
        }, {
          start: {
            line: 300,
            column: 4
          },
          end: {
            line: 304,
            column: 5
          }
        }],
        line: 300
      },
      '22': {
        loc: {
          start: {
            line: 300,
            column: 8
          },
          end: {
            line: 300,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 300,
            column: 8
          },
          end: {
            line: 300,
            column: 34
          }
        }, {
          start: {
            line: 300,
            column: 38
          },
          end: {
            line: 300,
            column: 66
          }
        }],
        line: 300
      },
      '23': {
        loc: {
          start: {
            line: 324,
            column: 2
          },
          end: {
            line: 345,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 324,
            column: 2
          },
          end: {
            line: 345,
            column: 3
          }
        }, {
          start: {
            line: 324,
            column: 2
          },
          end: {
            line: 345,
            column: 3
          }
        }],
        line: 324
      },
      '24': {
        loc: {
          start: {
            line: 329,
            column: 4
          },
          end: {
            line: 333,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 329,
            column: 4
          },
          end: {
            line: 333,
            column: 5
          }
        }, {
          start: {
            line: 329,
            column: 4
          },
          end: {
            line: 333,
            column: 5
          }
        }],
        line: 329
      },
      '25': {
        loc: {
          start: {
            line: 334,
            column: 4
          },
          end: {
            line: 338,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 334,
            column: 4
          },
          end: {
            line: 338,
            column: 5
          }
        }, {
          start: {
            line: 334,
            column: 4
          },
          end: {
            line: 338,
            column: 5
          }
        }],
        line: 334
      },
      '26': {
        loc: {
          start: {
            line: 339,
            column: 4
          },
          end: {
            line: 343,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 339,
            column: 4
          },
          end: {
            line: 343,
            column: 5
          }
        }, {
          start: {
            line: 339,
            column: 4
          },
          end: {
            line: 343,
            column: 5
          }
        }],
        line: 339
      },
      '27': {
        loc: {
          start: {
            line: 346,
            column: 37
          },
          end: {
            line: 346,
            column: 82
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 346,
            column: 37
          },
          end: {
            line: 346,
            column: 69
          }
        }, {
          start: {
            line: 346,
            column: 73
          },
          end: {
            line: 346,
            column: 82
          }
        }],
        line: 346
      },
      '28': {
        loc: {
          start: {
            line: 349,
            column: 4
          },
          end: {
            line: 355,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 349,
            column: 4
          },
          end: {
            line: 355,
            column: 5
          }
        }, {
          start: {
            line: 349,
            column: 4
          },
          end: {
            line: 355,
            column: 5
          }
        }],
        line: 349
      },
      '29': {
        loc: {
          start: {
            line: 352,
            column: 99
          },
          end: {
            line: 352,
            column: 128
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 352,
            column: 99
          },
          end: {
            line: 352,
            column: 123
          }
        }, {
          start: {
            line: 352,
            column: 127
          },
          end: {
            line: 352,
            column: 128
          }
        }],
        line: 352
      },
      '30': {
        loc: {
          start: {
            line: 376,
            column: 6
          },
          end: {
            line: 381,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 376,
            column: 6
          },
          end: {
            line: 381,
            column: 7
          }
        }, {
          start: {
            line: 376,
            column: 6
          },
          end: {
            line: 381,
            column: 7
          }
        }],
        line: 376
      },
      '31': {
        loc: {
          start: {
            line: 389,
            column: 6
          },
          end: {
            line: 391,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 389,
            column: 6
          },
          end: {
            line: 391,
            column: 7
          }
        }, {
          start: {
            line: 389,
            column: 6
          },
          end: {
            line: 391,
            column: 7
          }
        }],
        line: 389
      },
      '32': {
        loc: {
          start: {
            line: 398,
            column: 6
          },
          end: {
            line: 417,
            column: 7
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 399,
            column: 8
          },
          end: {
            line: 401,
            column: 16
          }
        }, {
          start: {
            line: 402,
            column: 8
          },
          end: {
            line: 404,
            column: 16
          }
        }, {
          start: {
            line: 405,
            column: 8
          },
          end: {
            line: 407,
            column: 16
          }
        }, {
          start: {
            line: 408,
            column: 8
          },
          end: {
            line: 410,
            column: 16
          }
        }, {
          start: {
            line: 411,
            column: 8
          },
          end: {
            line: 413,
            column: 16
          }
        }, {
          start: {
            line: 414,
            column: 8
          },
          end: {
            line: 416,
            column: 16
          }
        }],
        line: 398
      },
      '33': {
        loc: {
          start: {
            line: 422,
            column: 6
          },
          end: {
            line: 425,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 422,
            column: 6
          },
          end: {
            line: 425,
            column: 7
          }
        }, {
          start: {
            line: 422,
            column: 6
          },
          end: {
            line: 425,
            column: 7
          }
        }],
        line: 422
      },
      '34': {
        loc: {
          start: {
            line: 503,
            column: 4
          },
          end: {
            line: 513,
            column: 5
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 504,
            column: 6
          },
          end: {
            line: 504,
            column: 17
          }
        }, {
          start: {
            line: 504,
            column: 18
          },
          end: {
            line: 504,
            column: 29
          }
        }, {
          start: {
            line: 504,
            column: 30
          },
          end: {
            line: 504,
            column: 41
          }
        }, {
          start: {
            line: 504,
            column: 42
          },
          end: {
            line: 504,
            column: 53
          }
        }, {
          start: {
            line: 504,
            column: 54
          },
          end: {
            line: 504,
            column: 65
          }
        }, {
          start: {
            line: 504,
            column: 66
          },
          end: {
            line: 504,
            column: 77
          }
        }, {
          start: {
            line: 504,
            column: 78
          },
          end: {
            line: 504,
            column: 89
          }
        }, {
          start: {
            line: 504,
            column: 90
          },
          end: {
            line: 506,
            column: 14
          }
        }, {
          start: {
            line: 508,
            column: 6
          },
          end: {
            line: 508,
            column: 17
          }
        }, {
          start: {
            line: 508,
            column: 18
          },
          end: {
            line: 508,
            column: 29
          }
        }, {
          start: {
            line: 508,
            column: 30
          },
          end: {
            line: 508,
            column: 41
          }
        }, {
          start: {
            line: 508,
            column: 42
          },
          end: {
            line: 508,
            column: 53
          }
        }, {
          start: {
            line: 508,
            column: 54
          },
          end: {
            line: 510,
            column: 14
          }
        }, {
          start: {
            line: 511,
            column: 6
          },
          end: {
            line: 512,
            column: 47
          }
        }],
        line: 503
      },
      '35': {
        loc: {
          start: {
            line: 533,
            column: 4
          },
          end: {
            line: 535,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 533,
            column: 4
          },
          end: {
            line: 535,
            column: 5
          }
        }, {
          start: {
            line: 533,
            column: 4
          },
          end: {
            line: 535,
            column: 5
          }
        }],
        line: 533
      },
      '36': {
        loc: {
          start: {
            line: 560,
            column: 4
          },
          end: {
            line: 564,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 560,
            column: 4
          },
          end: {
            line: 564,
            column: 5
          }
        }, {
          start: {
            line: 560,
            column: 4
          },
          end: {
            line: 564,
            column: 5
          }
        }],
        line: 560
      },
      '37': {
        loc: {
          start: {
            line: 560,
            column: 9
          },
          end: {
            line: 560,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 560,
            column: 9
          },
          end: {
            line: 560,
            column: 33
          }
        }, {
          start: {
            line: 560,
            column: 37
          },
          end: {
            line: 560,
            column: 39
          }
        }],
        line: 560
      },
      '38': {
        loc: {
          start: {
            line: 566,
            column: 4
          },
          end: {
            line: 570,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 566,
            column: 4
          },
          end: {
            line: 570,
            column: 5
          }
        }, {
          start: {
            line: 566,
            column: 4
          },
          end: {
            line: 570,
            column: 5
          }
        }],
        line: 566
      },
      '39': {
        loc: {
          start: {
            line: 566,
            column: 9
          },
          end: {
            line: 566,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 566,
            column: 9
          },
          end: {
            line: 566,
            column: 40
          }
        }, {
          start: {
            line: 566,
            column: 44
          },
          end: {
            line: 566,
            column: 46
          }
        }],
        line: 566
      },
      '40': {
        loc: {
          start: {
            line: 572,
            column: 4
          },
          end: {
            line: 574,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 572,
            column: 4
          },
          end: {
            line: 574,
            column: 5
          }
        }, {
          start: {
            line: 572,
            column: 4
          },
          end: {
            line: 574,
            column: 5
          }
        }],
        line: 572
      },
      '41': {
        loc: {
          start: {
            line: 575,
            column: 18
          },
          end: {
            line: 575,
            column: 69
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 575,
            column: 18
          },
          end: {
            line: 575,
            column: 45
          }
        }, {
          start: {
            line: 575,
            column: 49
          },
          end: {
            line: 575,
            column: 69
          }
        }],
        line: 575
      },
      '42': {
        loc: {
          start: {
            line: 579,
            column: 6
          },
          end: {
            line: 581,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 579,
            column: 6
          },
          end: {
            line: 581,
            column: 7
          }
        }, {
          start: {
            line: 579,
            column: 6
          },
          end: {
            line: 581,
            column: 7
          }
        }],
        line: 579
      },
      '43': {
        loc: {
          start: {
            line: 607,
            column: 6
          },
          end: {
            line: 609,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 607,
            column: 6
          },
          end: {
            line: 609,
            column: 7
          }
        }, {
          start: {
            line: 607,
            column: 6
          },
          end: {
            line: 609,
            column: 7
          }
        }],
        line: 607
      },
      '44': {
        loc: {
          start: {
            line: 621,
            column: 8
          },
          end: {
            line: 631,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 621,
            column: 8
          },
          end: {
            line: 631,
            column: 9
          }
        }, {
          start: {
            line: 621,
            column: 8
          },
          end: {
            line: 631,
            column: 9
          }
        }],
        line: 621
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0, 0, 0, 0, 0],
      '33': [0, 0],
      '34': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_4l82ltljn.s[0]++;

angular.module('app').controller('RiskCtrl', function ($scope, SweetAlert, $deltaLocation, $window, $stateParams, $uibModal, $translate, $CRUDService, $deltaNumber, $otherHttpService, $location, $filter, $rootScope, $deltaActor, $sce) {
  cov_4l82ltljn.f[0]++;
  cov_4l82ltljn.s[1]++;

  $rootScope.processPageRight($stateParams.type == 1 ? (cov_4l82ltljn.b[0][0]++, '2_3') : (cov_4l82ltljn.b[0][1]++, '2_8'));
  cov_4l82ltljn.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_4l82ltljn.b[1][0]++;
    cov_4l82ltljn.s[3]++;

    return;
  } else {
    cov_4l82ltljn.b[1][1]++;
  }
  cov_4l82ltljn.s[4]++;
  $scope.listRisks = [];
  var PATH = (cov_4l82ltljn.s[5]++, 'Risk');
  var PATH_TYPE = (cov_4l82ltljn.s[6]++, 'Type_Mission');
  cov_4l82ltljn.s[7]++;
  $deltaActor.addController($scope);
  var project = (cov_4l82ltljn.s[8]++, $rootScope.getCurrentProject());

  cov_4l82ltljn.s[9]++;
  $scope.id_t = Date.newDate().getTime();

  cov_4l82ltljn.s[10]++;
  $scope.typeRisk = $stateParams.type;
  cov_4l82ltljn.s[11]++;
  $rootScope.COMMENTS_HISTORY = {
    ID_PARENT: 0,
    TYPE: $scope.typeRisk == 1 ? (cov_4l82ltljn.b[2][0]++, 2) : (cov_4l82ltljn.b[2][1]++, 3)
  };
  cov_4l82ltljn.s[12]++;
  $rootScope.OTHER_DOC_UPLOAD = {
    id_p: 0,
    type: $scope.typeRisk == 1 ? (cov_4l82ltljn.b[3][0]++, 1) : (cov_4l82ltljn.b[3][1]++, 2)
  };

  cov_4l82ltljn.s[13]++;
  $scope.listImpact = [{ id: 1, name: $translate.instant('RISK.IMPACTS.HIGH'), class: 'text-danger' }, { id: 2, name: $translate.instant('RISK.IMPACTS.MEDIUM'), class: 'text-warning' }, { id: 3, name: $translate.instant('RISK.IMPACTS.LOW'), class: 'text-success' }, { id: 0, name: $translate.instant('COMMON.NONE'), class: 'text-muted' }];

  cov_4l82ltljn.s[14]++;
  $scope.listStatus = [{ id: 1, name: $translate.instant('RISK.STATUS_LIST.UNRESOLVED'), class: 'unresolved' }, { id: 2, name: $translate.instant('RISK.STATUS_LIST.IN_PROGRESS'), class: 'in_progress' }, { id: 3, name: $translate.instant('RISK.STATUS_LIST.RESOLVED'), class: 'resolved' }];

  cov_4l82ltljn.s[15]++;
  $scope.criticalities = [{ id: 1, name: $translate.instant('RISK.CRITICALITY.WEAK'), class: 'text-success' }, { id: 2, name: $translate.instant('RISK.CRITICALITY.MEDIUM'), class: 'text-warning' }, { id: 3, name: $translate.instant('RISK.CRITICALITY.HIGH'), class: 'text-danger' }];

  cov_4l82ltljn.s[16]++;
  $scope.params = {
    selectionSettings: { mode: 'Cell', cellSelectionMode: 'Box', type: 'Multiple' },
    toolbar: $rootScope.currentRight.u ? (cov_4l82ltljn.b[4][0]++, ['Add']) : (cov_4l82ltljn.b[4][1]++, undefined),
    editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },
    columns: [{ field: 'TITLE_RISK', width: 250, headerText: $translate.instant($scope.typeRisk == 1 ? (cov_4l82ltljn.b[5][0]++, 'RISK.RISK') : (cov_4l82ltljn.b[5][1]++, 'RISK.PROBLEM')), type: 'string', showInColumnChooser: false }, { field: 'NATURE_RISK', width: 200, headerText: $translate.instant('RISK.NATURE_RISK'), type: 'string', filter: { type: 'CheckBox' } },

    // {field: 'DESCRIPTION_RISQUE', width: 250, headerText: $translate.instant('RISK.DESCRIPTION'), type: 'string'},

    { field: 'responsible.FONCTION_ACTEUR', width: 250, headerText: $translate.instant('PROBLEM.RESPONSIBLE'), type: 'string', filter: { type: 'CheckBox' } }, { field: 'DATE_RISQUE', width: 120, headerText: $translate.instant('RISK.DATE'), format: 'yMd', textAlign: 'Center' }, { headerText: $translate.instant('RISK.IMPACT_FINANCE'),
      field: 'financialImpact.name', width: 170, textAlign: 'Center', filter: { type: 'CheckBox' }, type: 'string',
      template: '<i class="fa fa-flag ${financialImpact.class}" aria-hidden="true"></i>'
      //  ${financialImpact.name}
    }, { headerText: $translate.instant('RISK.IMPACT_DELAY'),
      field: 'delayImpact.name', width: 170, textAlign: 'Center', filter: { type: 'CheckBox' }, type: 'string',
      template: '<i class="fa fa-flag ${delayImpact.class}" aria-hidden="true"></i>'
      //  ${delayImpact.name}
    }, { headerText: $translate.instant('RISK.CRITICALITY.TITLE'),
      field: 'criticality.name', width: 130, filter: { type: 'CheckBox' }, type: 'string',
      template: '<i class="fa fa-flag ${criticality.class}" aria-hidden="true"></i>'
      // template: '<div class="${criticality.class}">${criticality.name}</div>'
      //  ${criticality.name}
    }].concat($scope.typeRisk == 1 ? (cov_4l82ltljn.b[6][0]++, [{ field: 'PROBABILITE', headerText: $translate.instant('RISK.PROBABILITY'), textAlign: 'Right', type: 'number', format: 'P', width: 140 }]) : (cov_4l82ltljn.b[6][1]++, [])).concat([
    // statut_zone
    {
      field: 'status.name', width: 160, headerText: $translate.instant('RISK.STATUS'), filter: { type: 'CheckBox' }, type: 'string',
      template: '<div class="label-satut ${status.class}">${status.name}</div>' /* ,
                                                                                {headerText: $translate.instant('RISK.RECOMMANDATIONS'), width: 160,
                                                                                 commands: [
                                                                                   {type: $translate.instant('RISK.RECOMMANDATIONS'), buttonOption: {iconCss: ' e-icons e-file', cssClass: 'e-flat'}},
                                                                                   {type: 'Cancel', buttonOption: {iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat'}}
                                                                                 ]
                                                                                } ,
                                                                                {headerText: '', width: 140,
                                                                                 commands: ($rootScope.currentRight.u ? [{type: 'Edit', buttonOption: {iconCss: ' e-icons e-edit', cssClass: 'e-flat'}}] : [])
                                                                                 .concat($rootScope.currentRight.d ? [{type: 'Delete', buttonOption: {iconCss: 'e-icons e-delete', cssClass: 'e-flat'}}] : [])
                                                                                } */
    }]),
    commandClick: function commandClick(action) {
      cov_4l82ltljn.s[17]++;

      if (action.commandColumn.type === $translate.instant('RISK.RECOMMANDATIONS')) {
        cov_4l82ltljn.b[7][0]++;
        cov_4l82ltljn.s[18]++;

        $scope.openRecommendation(action.rowData);
      } else {
        cov_4l82ltljn.b[7][1]++;
      }
    },
    actionBegin: function actionBegin(action) {
      cov_4l82ltljn.s[19]++;

      if (action.requestType === 'add') {
        cov_4l82ltljn.b[8][0]++;
        cov_4l82ltljn.s[20]++;

        action.cancel = true;
        cov_4l82ltljn.s[21]++;
        $scope.addItem();
      } else {
        cov_4l82ltljn.b[8][1]++;
      }
      cov_4l82ltljn.s[22]++;
      if (action.requestType === 'beginEdit') {
        cov_4l82ltljn.b[9][0]++;
        cov_4l82ltljn.s[23]++;

        action.cancel = true;
        cov_4l82ltljn.s[24]++;
        $scope.editItem(action.rowData);
      } else {
        cov_4l82ltljn.b[9][1]++;
      }
      cov_4l82ltljn.s[25]++;
      if (action.requestType === 'delete') {
        cov_4l82ltljn.b[10][0]++;
        cov_4l82ltljn.s[26]++;

        action.cancel = true;
        cov_4l82ltljn.s[27]++;
        $scope.deleteItem(action.data[0]);
      } else {
        cov_4l82ltljn.b[10][1]++;
      }
    },
    cellSelected: function cellSelected(args) {
      cov_4l82ltljn.s[28]++;

      // Modification d'un risk ou projet
      $scope.editItem(args.data);
    },
    cellDeselected: function cellDeselected(args) {}
  };

  cov_4l82ltljn.s[29]++;
  $scope.getCriticality = function (item) {
    cov_4l82ltljn.f[1]++;

    var id = (cov_4l82ltljn.s[30]++, item.IMPACT_FINAN + '_' + item.IMPACT_DELAY);
    var index = (cov_4l82ltljn.s[31]++, 0);
    cov_4l82ltljn.s[32]++;
    switch (id) {
      case '2_3':
        cov_4l82ltljn.b[11][0]++;
      case '3_2':
        cov_4l82ltljn.b[11][1]++;
      case '3_3':
        cov_4l82ltljn.b[11][2]++;
      case '2_0':
        cov_4l82ltljn.b[11][3]++;
      case '0_2':
        cov_4l82ltljn.b[11][4]++;
      case '0_0':
        cov_4l82ltljn.b[11][5]++;
      case '0_3':
        cov_4l82ltljn.b[11][6]++;
      case '3_0':
        cov_4l82ltljn.b[11][7]++;
        cov_4l82ltljn.s[33]++;

        index = 1;
        cov_4l82ltljn.s[34]++;
        break;

      case '1_3':
        cov_4l82ltljn.b[11][8]++;
      case '2_2':
        cov_4l82ltljn.b[11][9]++;
      case '3_1':
        cov_4l82ltljn.b[11][10]++;
      case '1_0':
        cov_4l82ltljn.b[11][11]++;
      case '0_1':
        cov_4l82ltljn.b[11][12]++;
        cov_4l82ltljn.s[35]++;

        index = 2;
        cov_4l82ltljn.s[36]++;
        break;
      default:
        cov_4l82ltljn.b[11][13]++;
        cov_4l82ltljn.s[37]++;

        index = 3;
    }

    cov_4l82ltljn.s[38]++;
    return $scope.criticalities.find(function (criticality) {
      cov_4l82ltljn.f[2]++;
      cov_4l82ltljn.s[39]++;

      return criticality.id == index;
    });
  };
  cov_4l82ltljn.s[40]++;
  $scope.getAll = function () {
    cov_4l82ltljn.f[3]++;
    cov_4l82ltljn.s[41]++;

    $scope.isloading = true;
    cov_4l82ltljn.s[42]++;
    $CRUDService.getAll(PATH, { get: 'all', type: $scope.typeRisk }, function (data) {
      cov_4l82ltljn.f[4]++;
      cov_4l82ltljn.s[43]++;

      $scope.listRisks = data.map(function (item) {
        cov_4l82ltljn.f[5]++;
        cov_4l82ltljn.s[44]++;

        item.PROBABILITE_RISQUE = (cov_4l82ltljn.b[12][0]++, $deltaNumber.formatNumber(item.PROBABILITE_RISQUE, null, 0)) || (cov_4l82ltljn.b[12][1]++, 0);
        cov_4l82ltljn.s[45]++;
        if (item.RISK_TYPE == 2) {
          cov_4l82ltljn.b[13][0]++;
          cov_4l82ltljn.s[46]++;

          item.PROBABILITE = null;
        } else {
          cov_4l82ltljn.b[13][1]++;
          cov_4l82ltljn.s[47]++;

          item.PROBABILITE = item.PROBABILITE_RISQUE / 100;
        }

        cov_4l82ltljn.s[48]++;
        item.DATE_RISQUE = Date.newDate(item.DATE_RISQUE);

        cov_4l82ltljn.s[49]++;
        item.RESOLVE_DATE_RISK = Date.newDate(item.RESOLVE_DATE_RISK);

        cov_4l82ltljn.s[50]++;
        item.criticality = $scope.getCriticality(item);

        cov_4l82ltljn.s[51]++;
        item.financialImpact = $scope.listImpact.find(function (type) {
          cov_4l82ltljn.f[6]++;
          cov_4l82ltljn.s[52]++;

          return type.id == item.IMPACT_FINAN;
        });
        cov_4l82ltljn.s[53]++;
        item.delayImpact = $scope.listImpact.find(function (type) {
          cov_4l82ltljn.f[7]++;
          cov_4l82ltljn.s[54]++;

          return type.id == item.IMPACT_DELAY;
        });

        cov_4l82ltljn.s[55]++;
        item.status = $scope.listStatus.find(function (type) {
          cov_4l82ltljn.f[8]++;
          cov_4l82ltljn.s[56]++;

          return type.id == item.STATUS_RISQUE;
        });
        cov_4l82ltljn.s[57]++;
        item.responsible = {
          id: (cov_4l82ltljn.b[14][0]++, item.IDACTEURS) || (cov_4l82ltljn.b[14][1]++, -100),
          FONCTION_ACTEUR: item.responsible
        };
        cov_4l82ltljn.s[58]++;
        return item;
      });

      cov_4l82ltljn.s[59]++;
      $scope.params.dataSource = $scope.filterRiskType();

      cov_4l82ltljn.s[60]++;
      $scope.isloading = false;
    });
  };

  cov_4l82ltljn.s[61]++;
  $scope.openRecommendation = function (risk) {
    cov_4l82ltljn.f[9]++;
    cov_4l82ltljn.s[62]++;

    localStorage.setItem('SELECTED_RISK', risk.id);
    cov_4l82ltljn.s[63]++;
    $uibModal.open({
      templateUrl: 'app/views/risk/recommendation.html',
      controller: RiskCtrl_recommendation,
      scope: $scope,
      size: 'lg_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_4l82ltljn.s[64]++;
  $scope.addItem = function () {
    cov_4l82ltljn.f[10]++;
    cov_4l82ltljn.s[65]++;

    $scope.editItem({
      id: 0,
      RISK_TYPE: $scope.typeRisk,
      NATURE_RISK: '',
      DESCRIPTION_RISQUE: '',
      financialImpact: $scope.listImpact[0],
      delayImpact: $scope.listImpact[0],
      responsible: $scope.listData_actors_bksb[0],
      status: $scope.listStatus[0],
      DATE_RISQUE: Date.newDate(),
      RESOLVE_DATE_RISK: Date.newDate(),
      PROBABILITE_RISQUE: 0,
      SEND_PERS: ''

    });
  };

  cov_4l82ltljn.s[66]++;
  $scope.editItem = function (item) {
    cov_4l82ltljn.f[11]++;
    cov_4l82ltljn.s[67]++;

    $scope.error_code = false;

    cov_4l82ltljn.s[68]++;
    $CRUDService.getAll(PATH, { id: item.id, get: 'getOne' }, function (data) {
      cov_4l82ltljn.f[12]++;
      cov_4l82ltljn.s[69]++;

      $scope.validateItem = angular.copy(item);
      cov_4l82ltljn.s[70]++;
      $scope.validateItem.SEND_PERS = ((cov_4l82ltljn.b[15][0]++, $scope.validateItem.SEND_PERS) || (cov_4l82ltljn.b[15][1]++, '')).split(';').map(function (value) {
        cov_4l82ltljn.f[13]++;
        cov_4l82ltljn.s[71]++;

        return $scope.listData_actors_bksb.find(function (ac) {
          cov_4l82ltljn.f[14]++;
          cov_4l82ltljn.s[72]++;
          return ac.id == value.trim();
        });
      }).filter(function (value) {
        cov_4l82ltljn.f[15]++;
        cov_4l82ltljn.s[73]++;

        return value;
      });

      cov_4l82ltljn.s[74]++;
      $scope.validateItem.DESCRIPTION_RISQUE = angular.isObject(data) ? (cov_4l82ltljn.b[16][0]++, data.DESCRIPTION_RISQUE) : (cov_4l82ltljn.b[16][1]++, '');

      cov_4l82ltljn.s[75]++;
      $uibModal.open({
        templateUrl: 'app/views/risk/edit.html',
        controller: RiskCtrlCtrl_edit,
        scope: $scope,
        size: 'lg_',
        backdrop: 'static',
        windowClass: 'animated fadeInRight left-modal'
      });
    });
  };

  cov_4l82ltljn.s[76]++;
  $scope.deleteItem = function (item) {
    cov_4l82ltljn.f[16]++;
    cov_4l82ltljn.s[77]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_4l82ltljn.f[17]++;
      cov_4l82ltljn.s[78]++;

      if (isconfirm) {
        cov_4l82ltljn.b[17][0]++;
        cov_4l82ltljn.s[79]++;

        return;
      } else {
        cov_4l82ltljn.b[17][1]++;
      }
      cov_4l82ltljn.s[80]++;
      $scope.isloading = true;
      var validateItem = (cov_4l82ltljn.s[81]++, {
        id: item.id,
        NUMERO_ORDR_MISS: item.NUMERO_ORDR_MISS
      });
      /* SweetAlert.swal({
        title: $translate.instant('COMMON.ERROR_DELETE'),
        text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
        type: 'warning'
      }); */
      cov_4l82ltljn.s[82]++;
      $CRUDService.delet(PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_4l82ltljn.f[18]++;
        cov_4l82ltljn.s[83]++;

        if (data < 0) {
          cov_4l82ltljn.b[18][0]++;
          cov_4l82ltljn.s[84]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
            type: 'warning'
          });
          cov_4l82ltljn.s[85]++;
          $scope.isloading = false;
        } else {
          cov_4l82ltljn.b[18][1]++;
          cov_4l82ltljn.s[86]++;

          $scope.getAll();
        }
      });
    });
  };

  cov_4l82ltljn.s[87]++;
  $scope.cancelItem = function () {
    cov_4l82ltljn.f[19]++;
    cov_4l82ltljn.s[88]++;

    $scope.validateItem = null;
  };

  cov_4l82ltljn.s[89]++;
  $scope.changeType = function () {
    cov_4l82ltljn.f[20]++;
    cov_4l82ltljn.s[90]++;

    $scope.params.dataSource = $scope.filterRiskType();
  };

  cov_4l82ltljn.s[91]++;
  $scope.filterRiskType = function () {
    cov_4l82ltljn.f[21]++;
    cov_4l82ltljn.s[92]++;

    if ((cov_4l82ltljn.b[20][0]++, $scope.params.RISK_SELECT) && (cov_4l82ltljn.b[20][1]++, !$scope.params.PROBLEM_SELECT)) {
      cov_4l82ltljn.b[19][0]++;
      cov_4l82ltljn.s[93]++;

      return $scope.listRisks.filter(function (value) {
        cov_4l82ltljn.f[22]++;
        cov_4l82ltljn.s[94]++;

        return value.RISK_TYPE == 1;
      });
    } else {
      cov_4l82ltljn.b[19][1]++;
    }

    cov_4l82ltljn.s[95]++;
    if ((cov_4l82ltljn.b[22][0]++, !$scope.params.RISK_SELECT) && (cov_4l82ltljn.b[22][1]++, $scope.params.PROBLEM_SELECT)) {
      cov_4l82ltljn.b[21][0]++;
      cov_4l82ltljn.s[96]++;

      return $scope.listRisks.filter(function (value) {
        cov_4l82ltljn.f[23]++;
        cov_4l82ltljn.s[97]++;

        return value.RISK_TYPE == 2;
      });
    } else {
      cov_4l82ltljn.b[21][1]++;
    }

    cov_4l82ltljn.s[98]++;
    return $scope.listRisks;
  };

  cov_4l82ltljn.s[99]++;
  $scope.getAll();
});

function RiskCtrlCtrl_edit($scope, $sce, $deltaLocation, $window, SweetAlert, $uibModalInstance, $filter, $CRUDService, $rootScope, $deltadate, $translate) {
  cov_4l82ltljn.f[24]++;
  cov_4l82ltljn.s[100]++;

  $rootScope.OTHER_DOC_UPLOAD.id_p = $scope.validateItem.id;

  cov_4l82ltljn.s[101]++;
  localStorage.setItem('SELECTED_RISK', $scope.validateItem.id);

  cov_4l82ltljn.s[102]++;
  $rootScope.COMMENTS_HISTORY.ID_PARENT = $scope.validateItem.id;

  var PATH = (cov_4l82ltljn.s[103]++, 'Risk');
  cov_4l82ltljn.s[104]++;
  $scope.error_code = false;

  /* MAP5,014338718527209  */

  cov_4l82ltljn.s[105]++;
  if (typeof $scope.validateItem.LOCALISATION == 'string') {
    cov_4l82ltljn.b[23][0]++;

    var loca_data = (cov_4l82ltljn.s[106]++, $scope.validateItem.LOCALISATION.replace('[', ''));
    cov_4l82ltljn.s[107]++;
    loca_data = loca_data.replace(']', '');
    cov_4l82ltljn.s[108]++;
    loca_data = loca_data.split(',');

    cov_4l82ltljn.s[109]++;
    if (loca_data[0]) {
      cov_4l82ltljn.b[24][0]++;
      cov_4l82ltljn.s[110]++;

      loca_data[0] = Number(loca_data[0]);
    } else {
      cov_4l82ltljn.b[24][1]++;
      cov_4l82ltljn.s[111]++;

      loca_data[0] = 16.82;
    }
    cov_4l82ltljn.s[112]++;
    if (loca_data[1]) {
      cov_4l82ltljn.b[25][0]++;
      cov_4l82ltljn.s[113]++;

      loca_data[1] = Number(loca_data[1]);
    } else {
      cov_4l82ltljn.b[25][1]++;
      cov_4l82ltljn.s[114]++;

      loca_data[1] = 11.3;
    }
    cov_4l82ltljn.s[115]++;
    if (loca_data[2]) {
      cov_4l82ltljn.b[26][0]++;
      cov_4l82ltljn.s[116]++;

      loca_data[2] = Number(loca_data[2]);
    } else {
      cov_4l82ltljn.b[26][1]++;
      cov_4l82ltljn.s[117]++;

      loca_data[2] = 0;
    }
    cov_4l82ltljn.s[118]++;
    $scope.validateItem.LOCALISATION = loca_data;
  } else {
    cov_4l82ltljn.b[23][1]++;
  }
  cov_4l82ltljn.s[119]++;
  $scope.validateItem.LOCALISATION = (cov_4l82ltljn.b[27][0]++, $scope.validateItem.LOCALISATION) || (cov_4l82ltljn.b[27][1]++, [0, 0, 0]);

  cov_4l82ltljn.s[120]++;
  $scope.getCurrentPosition = function () {
    cov_4l82ltljn.f[25]++;
    cov_4l82ltljn.s[121]++;

    if (navigator.geolocation) {
      cov_4l82ltljn.b[28][0]++;
      cov_4l82ltljn.s[122]++;

      navigator.geolocation.getCurrentPosition(function (position) {
        cov_4l82ltljn.f[26]++;
        cov_4l82ltljn.s[123]++;

        $scope.$apply(function () {
          cov_4l82ltljn.f[27]++;
          cov_4l82ltljn.s[124]++;

          $scope.validateItem.LOCALISATION = [position.coords.latitude, position.coords.longitude, (cov_4l82ltljn.b[29][0]++, position.coords.altitude) || (cov_4l82ltljn.b[29][1]++, 0)];
        });
      });
    } else {
      cov_4l82ltljn.b[28][1]++;
    }
  };

  cov_4l82ltljn.s[125]++;
  $scope.getLatLong = function () {
    cov_4l82ltljn.f[28]++;

    // $scope.positionLayer.clearLayers();

    var index = (cov_4l82ltljn.s[126]++, 0);
    var line = (cov_4l82ltljn.s[127]++, $scope.validateItem.LOCALISATION);
    var l = (cov_4l82ltljn.s[128]++, L.marker(L.latLng(line[0], line[1]), { draggable: true, index: index }).addTo($scope.positionLayer));
    cov_4l82ltljn.s[129]++;
    l.on('dragend', function (data) {
      cov_4l82ltljn.f[29]++;

      var latLng = (cov_4l82ltljn.s[130]++, l.getLatLng());
      cov_4l82ltljn.s[131]++;
      $scope.validateItem.LOCALISATION[0] = latLng.lat;
      cov_4l82ltljn.s[132]++;
      $scope.validateItem.LOCALISATION[1] = latLng.lng;
      cov_4l82ltljn.s[133]++;
      $scope.myMap.invalidateSize();
    });

    cov_4l82ltljn.s[134]++;
    $scope.myMap.invalidateSize();
  };

  cov_4l82ltljn.s[135]++;
  $scope.map_coord = {
    getLayers: function getLayers() {
      cov_4l82ltljn.s[136]++;

      if (!this.layerGroup) {
        cov_4l82ltljn.b[30][0]++;
        cov_4l82ltljn.s[137]++;

        this.layerGroup = L.layerGroup([], {
          attribution: '© <a href=\'https://www.mapbox.com/about/maps/\'>Mapbox</a> , © <a href=\'https://www.openstreetmap.org/copyright\'>OpenStreetMap</a>  <strong><a href=\'https://www.mapbox.com/map-feedback/\' target=\'_blank\'>Improve this map</a></strong>'
        });
        cov_4l82ltljn.s[138]++;
        this.layerGroup.addTo(this.mymap);
      } else {
        cov_4l82ltljn.b[30][1]++;
      }
      cov_4l82ltljn.s[139]++;
      this.layerGroup.clearLayers();
      var mapstr = (cov_4l82ltljn.s[140]++, 'https://api.mapbox.com/styles/v1/bksb/cjl6cbt060nb92rlvec7i4fh9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew');
      cov_4l82ltljn.s[141]++;
      L.tileLayer(mapstr).addTo(this.layerGroup);

      var index = (cov_4l82ltljn.s[142]++, 0);
      var sumLat = (cov_4l82ltljn.s[143]++, 0);
      var sumLong = (cov_4l82ltljn.s[144]++, 0);
      cov_4l82ltljn.s[145]++;
      if (index > 0) {
        // this.mymap.setView([sumLat / index, sumLong / index]);

        cov_4l82ltljn.b[31][0]++;
      } else {
        cov_4l82ltljn.b[31][1]++;
      }
    },
    getMapUrl: function getMapUrl(value) {
      var mapstr = (cov_4l82ltljn.s[146]++, 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}');
      // L.tileLayer(mapstr).addTo(this.layerGroup);
      // let mapstr = '';
      // const value = ($scope.displayCity ? 1 : 0) + ($scope.displaySatelite ? 2 : 0);
      cov_4l82ltljn.s[147]++;
      switch (value) {
        case 0:
          cov_4l82ltljn.b[32][0]++;
          cov_4l82ltljn.s[148]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}';
          cov_4l82ltljn.s[149]++;
          break;
        case 1:
          cov_4l82ltljn.b[32][1]++;
          cov_4l82ltljn.s[150]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvs9jqk60fr91cscf0w98zth/tiles/256/{z}/{x}/{y}@2x';
          cov_4l82ltljn.s[151]++;
          break;
        case 2:
          cov_4l82ltljn.b[32][2]++;
          cov_4l82ltljn.s[152]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbh28818ic1cmqcfaz1is2/tiles/256/{z}/{x}/{y}@2x';
          cov_4l82ltljn.s[153]++;
          break;
        case 3:
          cov_4l82ltljn.b[32][3]++;
          cov_4l82ltljn.s[154]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbjkcx0mkz1cp3ot9cdfnk/tiles/256/{z}/{x}/{y}@2x';
          cov_4l82ltljn.s[155]++;
          break;
        case 4:
          cov_4l82ltljn.b[32][4]++;
          cov_4l82ltljn.s[156]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/ck0wcr1og05gz1cq7ik9y0ct5/tiles/256/{z}/{x}/{y}@2x';
          cov_4l82ltljn.s[157]++;
          break;
        default:
          cov_4l82ltljn.b[32][5]++;
          cov_4l82ltljn.s[158]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cj8x1v3iv05pz2rrtc9ps1tui/tiles/256/{z}/{x}/{y}';
          cov_4l82ltljn.s[159]++;
          break;
      }
      cov_4l82ltljn.s[160]++;
      return mapstr + '?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew';
    },
    drawMap: function drawMap() {
      var lastZoom = (cov_4l82ltljn.s[161]++, 5);
      cov_4l82ltljn.s[162]++;
      if ($scope.myMap) {
        cov_4l82ltljn.b[33][0]++;
        cov_4l82ltljn.s[163]++;

        lastZoom = $scope.myMap.getZoom();
        cov_4l82ltljn.s[164]++;
        $scope.myMap.remove();
      } else {
        cov_4l82ltljn.b[33][1]++;
      }

      var grayscale = (cov_4l82ltljn.s[165]++, L.tileLayer($scope.map_coord.getMapUrl(4)));
      cov_4l82ltljn.s[166]++;
      $scope.myMap = L.map('map_collect_data', { fullscreenControl: {
          title: {
            false: $translate.instant('COMMON.FULLSCREEN'),
            true: $translate.instant('COMMON.EXIT_FULLSCREEN')
          }
        }, layers: [grayscale]
      }).setView([$scope.validateItem.LOCALISATION[0], $scope.validateItem.LOCALISATION[1]], lastZoom);

      cov_4l82ltljn.s[167]++;
      $scope.positionLayer = L.layerGroup().addTo($scope.myMap);

      var base = (cov_4l82ltljn.s[168]++, {});
      cov_4l82ltljn.s[169]++;
      base[$translate.instant('COMMON.GRAYSCALE')] = L.tileLayer($scope.map_coord.getMapUrl(1));
      cov_4l82ltljn.s[170]++;
      base[$translate.instant('COMMON.STREETMAP')] = grayscale;
      cov_4l82ltljn.s[171]++;
      base[$translate.instant('COMMON.SATELLITE')] = L.tileLayer($scope.map_coord.getMapUrl(3));

      cov_4l82ltljn.s[172]++;
      L.control.layers(base).addTo($scope.myMap);

      cov_4l82ltljn.s[173]++;
      $scope.getLatLong();
      cov_4l82ltljn.s[174]++;
      $scope.myMap.invalidateSize();

      cov_4l82ltljn.s[175]++;
      $scope.myMap.on('zoom', function (event) {
        cov_4l82ltljn.f[30]++;
        cov_4l82ltljn.s[176]++;

        $scope.myMap.invalidateSize();
      });
    }
  };

  cov_4l82ltljn.s[177]++;
  $scope.getCurrentPosition();
  /* END MAP  */

  /* DESCRIPTION  */
  var newHeight = (cov_4l82ltljn.s[178]++, $('body').height() - 600);
  cov_4l82ltljn.s[179]++;
  $scope.report = {
    height: newHeight,
    displayValue: $sce.trustAsHtml($scope.validateItem.DESCRIPTION_RISQUE),
    tmpValue: $scope.validateItem.DESCRIPTION_RISQUE,
    options_summernote: {
      focus: false,
      airMode: false,
      toolbar: [['edit', ['undo', 'redo']], ['headline', ['style']], ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']], ['fontface', ['fontname']], ['textsize', ['fontsize']], ['fontclr', ['color']], ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']], ['height', ['height']], ['table', ['table']], ['insert', ['link', 'picture', 'video', 'hr']],
      // ['view', ['fullscreen', 'codeview']],
      ['help', ['help']]]
    },
    editable: true
  };
  /* END DESCRIPTION  */

  cov_4l82ltljn.s[180]++;
  $scope.edition_tab = 1;

  cov_4l82ltljn.s[181]++;
  $CRUDService.getAll(PATH, { get: 'natures' }, function (_data) {
    cov_4l82ltljn.f[31]++;
    cov_4l82ltljn.s[182]++;

    $scope.autoCompleteGroupOptions = {
      minimumChars: 0,
      containerCssClass: 'white-bg',
      selectedCssClass: 'selected-item-auto-complete',
      noMatchTemplateEnabled: false,
      activateOnFocus: true,
      data: function data() {
        cov_4l82ltljn.s[183]++;

        return _data;
      }
    };
  });

  cov_4l82ltljn.s[184]++;
  $scope.changeImpact = function () {
    cov_4l82ltljn.f[32]++;

    var id = (cov_4l82ltljn.s[185]++, $scope.validateItem.financialImpact.id + '_' + $scope.validateItem.delayImpact.id);

    cov_4l82ltljn.s[186]++;
    switch (id) {
      case '2_3':
        cov_4l82ltljn.b[34][0]++;
      case '3_2':
        cov_4l82ltljn.b[34][1]++;
      case '3_3':
        cov_4l82ltljn.b[34][2]++;
      case '2_0':
        cov_4l82ltljn.b[34][3]++;
      case '0_2':
        cov_4l82ltljn.b[34][4]++;
      case '0_0':
        cov_4l82ltljn.b[34][5]++;
      case '0_3':
        cov_4l82ltljn.b[34][6]++;
      case '3_0':
        cov_4l82ltljn.b[34][7]++;
        cov_4l82ltljn.s[187]++;

        $scope.validateItem.globalImpact = '1';
        cov_4l82ltljn.s[188]++;
        break;

      case '1_3':
        cov_4l82ltljn.b[34][8]++;
      case '2_2':
        cov_4l82ltljn.b[34][9]++;
      case '3_1':
        cov_4l82ltljn.b[34][10]++;
      case '1_0':
        cov_4l82ltljn.b[34][11]++;
      case '0_1':
        cov_4l82ltljn.b[34][12]++;
        cov_4l82ltljn.s[189]++;

        $scope.validateItem.globalImpact = '2';
        cov_4l82ltljn.s[190]++;
        break;
      default:
        cov_4l82ltljn.b[34][13]++;
        cov_4l82ltljn.s[191]++;

        $scope.validateItem.globalImpact = '3';
    }
  };

  cov_4l82ltljn.s[192]++;
  $scope.validateItem.financialImpact = $scope.listImpact.find(function (type) {
    cov_4l82ltljn.f[33]++;
    cov_4l82ltljn.s[193]++;

    return type.id == $scope.validateItem.financialImpact.id;
  });

  cov_4l82ltljn.s[194]++;
  $scope.validateItem.delayImpact = $scope.listImpact.find(function (type) {
    cov_4l82ltljn.f[34]++;
    cov_4l82ltljn.s[195]++;

    return type.id == $scope.validateItem.delayImpact.id;
  });

  cov_4l82ltljn.s[196]++;
  $scope.validateItem.status = $scope.listStatus.find(function (type) {
    cov_4l82ltljn.f[35]++;
    cov_4l82ltljn.s[197]++;

    return type.id == $scope.validateItem.status.id;
  });

  cov_4l82ltljn.s[198]++;
  $scope.validateItem.responsible = $scope.listData_actors_bksb.find(function (actor) {
    cov_4l82ltljn.f[36]++;
    cov_4l82ltljn.s[199]++;

    return actor.id == $scope.validateItem.responsible.id;
  });

  cov_4l82ltljn.s[200]++;
  $scope.setValidData = function () {
    cov_4l82ltljn.f[37]++;
    cov_4l82ltljn.s[201]++;

    if ($scope.riskForm.$invalid) {
      cov_4l82ltljn.b[35][0]++;
      cov_4l82ltljn.s[202]++;

      return;
    } else {
      cov_4l82ltljn.b[35][1]++;
    }

    // $scope.validateItem.DESCRIPTION_RISQUE = $sce.trustAsHtml($scope.report.tmpValue);
    cov_4l82ltljn.s[203]++;
    $scope.validateItem.DESCRIPTION_RISQUE = $scope.report.tmpValue;

    var validateItem = (cov_4l82ltljn.s[204]++, {
      LOCALISATION: $scope.validateItem.LOCALISATION,
      RISK_TYPE: $scope.validateItem.RISK_TYPE,
      IDACTEURS: $scope.validateItem.responsible.id,
      NATURE_RISK: $scope.validateItem.NATURE_RISK.trim(),
      DESCRIPTION_RISQUE: $scope.validateItem.DESCRIPTION_RISQUE.trim(),
      IMPACT_FINAN: $scope.validateItem.financialImpact.id,
      IMPACT_DELAY: $scope.validateItem.delayImpact.id,
      STATUS_RISQUE: $scope.validateItem.status.id,
      DATE_RISQUE: $deltadate.format($scope.validateItem.DATE_RISQUE, 'YYYY-MM-DD'),
      RESOLVE_DATE_RISK: $deltadate.format($scope.validateItem.RESOLVE_DATE_RISK, 'YYYY-MM-DD'),
      PROBABILITE_RISQUE: $scope.validateItem.PROBABILITE_RISQUE,
      TITLE_RISK: $scope.validateItem.TITLE_RISK,
      id: $scope.validateItem.id,
      SEND_PERS: $scope.validateItem.SEND_PERS.map(function (value) {
        cov_4l82ltljn.f[38]++;
        cov_4l82ltljn.s[205]++;

        return value.id;
      }).join(' ; ')
    });

    cov_4l82ltljn.s[206]++;
    $scope.error_code = 0;
    cov_4l82ltljn.s[207]++;
    if (((cov_4l82ltljn.b[37][0]++, validateItem.NATURE_RISK) || (cov_4l82ltljn.b[37][1]++, '')).trim().length == 0) {
      cov_4l82ltljn.b[36][0]++;
      cov_4l82ltljn.s[208]++;

      $scope.error_code = 2;
      cov_4l82ltljn.s[209]++;
      $scope.riskForm.$invalid = true;
      cov_4l82ltljn.s[210]++;
      return;
    } else {
      cov_4l82ltljn.b[36][1]++;
    }

    cov_4l82ltljn.s[211]++;
    if (((cov_4l82ltljn.b[39][0]++, validateItem.DESCRIPTION_RISQUE) || (cov_4l82ltljn.b[39][1]++, '')).trim().length == 0) {
      cov_4l82ltljn.b[38][0]++;
      cov_4l82ltljn.s[212]++;

      $scope.error_code = 1;
      cov_4l82ltljn.s[213]++;
      $scope.riskForm.$invalid = true;
      cov_4l82ltljn.s[214]++;
      return;
    } else {
      cov_4l82ltljn.b[38][1]++;
    }

    cov_4l82ltljn.s[215]++;
    if (validateItem.IDACTEURS < 0) {
      cov_4l82ltljn.b[40][0]++;
      cov_4l82ltljn.s[216]++;

      validateItem.IDACTEURS = 0;
    } else {
      cov_4l82ltljn.b[40][1]++;
    }
    var isAdd = (cov_4l82ltljn.s[217]++, (cov_4l82ltljn.b[41][0]++, validateItem.RISK_TYPE == 2) && (cov_4l82ltljn.b[41][1]++, validateItem.id == 0));
    cov_4l82ltljn.s[218]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function () {
      cov_4l82ltljn.f[39]++;
      cov_4l82ltljn.s[219]++;

      $scope.closeModal();
      cov_4l82ltljn.s[220]++;
      $scope.getAll();
      cov_4l82ltljn.s[221]++;
      if (isAdd) {
        cov_4l82ltljn.b[42][0]++;
        cov_4l82ltljn.s[222]++;

        $CRUDService.save(PATH, { action: 'notifier', valeur: $filter('json')(validateItem) });
      } else {
        cov_4l82ltljn.b[42][1]++;
      }
    });
  };

  cov_4l82ltljn.s[223]++;
  $scope.closeModal = function () {
    cov_4l82ltljn.f[40]++;
    cov_4l82ltljn.s[224]++;

    $uibModalInstance.dismiss('cancel');
  };
  cov_4l82ltljn.s[225]++;
  $scope.changeImpact();

  cov_4l82ltljn.s[226]++;
  $scope.deleteRisk = function () {
    cov_4l82ltljn.f[41]++;
    cov_4l82ltljn.s[227]++;

    $scope.deleteItemInModal($scope.validateItem);
  };

  cov_4l82ltljn.s[228]++;
  $scope.deleteItemInModal = function (item) {
    cov_4l82ltljn.f[42]++;
    cov_4l82ltljn.s[229]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_4l82ltljn.f[43]++;
      cov_4l82ltljn.s[230]++;

      if (isconfirm) {
        cov_4l82ltljn.b[43][0]++;
        cov_4l82ltljn.s[231]++;

        return;
      } else {
        cov_4l82ltljn.b[43][1]++;
      }
      cov_4l82ltljn.s[232]++;
      $scope.isloading = true;
      var validateItem = (cov_4l82ltljn.s[233]++, {
        id: item.id,
        NUMERO_ORDR_MISS: item.NUMERO_ORDR_MISS
      });
      /* SweetAlert.swal({
        title: $translate.instant('COMMON.ERROR_DELETE'),
        text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
        type: 'warning'
      }); */
      cov_4l82ltljn.s[234]++;
      $CRUDService.delet(PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_4l82ltljn.f[44]++;
        cov_4l82ltljn.s[235]++;

        if (data < 0) {
          cov_4l82ltljn.b[44][0]++;
          cov_4l82ltljn.s[236]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
            type: 'warning'
          });
          cov_4l82ltljn.s[237]++;
          $scope.isloading = false;
        } else {
          cov_4l82ltljn.b[44][1]++;
          cov_4l82ltljn.s[238]++;

          $scope.getAll();
          cov_4l82ltljn.s[239]++;
          $scope.closeModal();
        }
      });
    });
  };
}

function RiskCtrl_recommendation($scope, $uibModalInstance) {
  cov_4l82ltljn.f[45]++;
  cov_4l82ltljn.s[240]++;

  $scope.closeModal = function () {
    cov_4l82ltljn.f[46]++;
    cov_4l82ltljn.s[241]++;

    $uibModalInstance.dismiss('cancel');
  };
}