'use strict';

var cov_1g7at40g11 = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/custom_module.js',
      hash = '1301cac5b62409cea452f805cfeabfbfe0b0cc0d',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/custom_module.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 31,
          column: 3
        }
      },
      '1': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 30,
          column: 4
        }
      },
      '2': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 8,
          column: 5
        }
      },
      '3': {
        start: {
          line: 7,
          column: 6
        },
        end: {
          line: 7,
          column: 16
        }
      },
      '4': {
        start: {
          line: 9,
          column: 4
        },
        end: {
          line: 9,
          column: 28
        }
      },
      '5': {
        start: {
          line: 10,
          column: 4
        },
        end: {
          line: 12,
          column: 5
        }
      },
      '6': {
        start: {
          line: 11,
          column: 6
        },
        end: {
          line: 11,
          column: 19
        }
      },
      '7': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 15,
          column: 5
        }
      },
      '8': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 14,
          column: 19
        }
      },
      '9': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 17,
          column: 33
        }
      },
      '10': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 27,
          column: 5
        }
      },
      '11': {
        start: {
          line: 19,
          column: 22
        },
        end: {
          line: 19,
          column: 44
        }
      },
      '12': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 26,
          column: 7
        }
      },
      '13': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 24,
          column: 9
        }
      },
      '14': {
        start: {
          line: 23,
          column: 10
        },
        end: {
          line: 23,
          column: 25
        }
      },
      '15': {
        start: {
          line: 25,
          column: 8
        },
        end: {
          line: 25,
          column: 43
        }
      },
      '16': {
        start: {
          line: 29,
          column: 4
        },
        end: {
          line: 29,
          column: 34
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 42
          },
          end: {
            line: 4,
            column: 43
          }
        },
        loc: {
          start: {
            line: 4,
            column: 48
          },
          end: {
            line: 31,
            column: 1
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 5,
            column: 9
          },
          end: {
            line: 5,
            column: 10
          }
        },
        loc: {
          start: {
            line: 5,
            column: 47
          },
          end: {
            line: 30,
            column: 3
          }
        },
        line: 5
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 4
          },
          end: {
            line: 8,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 6,
            column: 4
          },
          end: {
            line: 8,
            column: 5
          }
        }, {
          start: {
            line: 6,
            column: 4
          },
          end: {
            line: 8,
            column: 5
          }
        }],
        line: 6
      },
      '1': {
        loc: {
          start: {
            line: 10,
            column: 4
          },
          end: {
            line: 12,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 10,
            column: 4
          },
          end: {
            line: 12,
            column: 5
          }
        }, {
          start: {
            line: 10,
            column: 4
          },
          end: {
            line: 12,
            column: 5
          }
        }],
        line: 10
      },
      '2': {
        loc: {
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 15,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 15,
            column: 5
          }
        }, {
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 15,
            column: 5
          }
        }],
        line: 13
      },
      '3': {
        loc: {
          start: {
            line: 18,
            column: 4
          },
          end: {
            line: 27,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 4
          },
          end: {
            line: 27,
            column: 5
          }
        }, {
          start: {
            line: 18,
            column: 4
          },
          end: {
            line: 27,
            column: 5
          }
        }],
        line: 18
      },
      '4': {
        loc: {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        }, {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        }],
        line: 20
      },
      '5': {
        loc: {
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 24,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 24,
            column: 9
          }
        }, {
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 24,
            column: 9
          }
        }],
        line: 22
      },
      '6': {
        loc: {
          start: {
            line: 22,
            column: 12
          },
          end: {
            line: 22,
            column: 86
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 22,
            column: 12
          },
          end: {
            line: 22,
            column: 47
          }
        }, {
          start: {
            line: 22,
            column: 51
          },
          end: {
            line: 22,
            column: 86
          }
        }],
        line: 22
      },
      '7': {
        loc: {
          start: {
            line: 29,
            column: 20
          },
          end: {
            line: 29,
            column: 32
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 29,
            column: 20
          },
          end: {
            line: 29,
            column: 24
          }
        }, {
          start: {
            line: 29,
            column: 28
          },
          end: {
            line: 29,
            column: 32
          }
        }],
        line: 29
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1g7at40g11.s[0]++;
/**
 * Created by BKSB on 11/03/2017.
 */
angular.module('delaStore').filter('cut', function () {
  cov_1g7at40g11.f[0]++;
  cov_1g7at40g11.s[1]++;

  return function (value, wordwise, max, tail) {
    cov_1g7at40g11.f[1]++;
    cov_1g7at40g11.s[2]++;

    if (!value) {
      cov_1g7at40g11.b[0][0]++;
      cov_1g7at40g11.s[3]++;

      return '';
    } else {
      cov_1g7at40g11.b[0][1]++;
    }
    cov_1g7at40g11.s[4]++;
    max = parseInt(max, 10);
    cov_1g7at40g11.s[5]++;
    if (!max) {
      cov_1g7at40g11.b[1][0]++;
      cov_1g7at40g11.s[6]++;

      return value;
    } else {
      cov_1g7at40g11.b[1][1]++;
    }
    cov_1g7at40g11.s[7]++;
    if (value.length <= max) {
      cov_1g7at40g11.b[2][0]++;
      cov_1g7at40g11.s[8]++;

      return value;
    } else {
      cov_1g7at40g11.b[2][1]++;
    }

    cov_1g7at40g11.s[9]++;
    value = value.substr(0, max);
    cov_1g7at40g11.s[10]++;
    if (wordwise) {
      cov_1g7at40g11.b[3][0]++;

      var lastspace = (cov_1g7at40g11.s[11]++, value.lastIndexOf(' '));
      cov_1g7at40g11.s[12]++;
      if (lastspace !== -1) {
        cov_1g7at40g11.b[4][0]++;
        cov_1g7at40g11.s[13]++;

        // Also remove . and , so its gives a cleaner result.
        if ((cov_1g7at40g11.b[6][0]++, value.charAt(lastspace - 1) === '.') || (cov_1g7at40g11.b[6][1]++, value.charAt(lastspace - 1) === ',')) {
          cov_1g7at40g11.b[5][0]++;
          cov_1g7at40g11.s[14]++;

          lastspace -= 1;
        } else {
          cov_1g7at40g11.b[5][1]++;
        }
        cov_1g7at40g11.s[15]++;
        value = value.substr(0, lastspace);
      } else {
        cov_1g7at40g11.b[4][1]++;
      }
    } else {
      cov_1g7at40g11.b[3][1]++;
    }

    cov_1g7at40g11.s[16]++;
    return value + ((cov_1g7at40g11.b[7][0]++, tail) || (cov_1g7at40g11.b[7][1]++, ' …'));
  };
});