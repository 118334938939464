'use strict';

var cov_2cenf8p23p = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/logical_plan/logical_planCtrl.js',
      hash = '3d8b67f9e43314ebabf48754b8a58064171fe127',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/logical_plan/logical_planCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 444,
          column: 3
        }
      },
      '1': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 37
        }
      },
      '2': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 8,
          column: 3
        }
      },
      '3': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 7,
          column: 11
        }
      },
      '4': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 29
        }
      },
      '5': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 33
        }
      },
      '6': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 34
        }
      },
      '7': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 14,
          column: 4
        }
      },
      '8': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 13,
          column: 36
        }
      },
      '9': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 35
        }
      },
      '10': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 53,
          column: 5
        }
      },
      '11': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 52,
          column: 7
        }
      },
      '12': {
        start: {
          line: 18,
          column: 20
        },
        end: {
          line: 18,
          column: 21
        }
      },
      '13': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 39,
          column: 7
        }
      },
      '14': {
        start: {
          line: 21,
          column: 23
        },
        end: {
          line: 21,
          column: 105
        }
      },
      '15': {
        start: {
          line: 21,
          column: 71
        },
        end: {
          line: 21,
          column: 103
        }
      },
      '16': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 37,
          column: 9
        }
      },
      '17': {
        start: {
          line: 24,
          column: 10
        },
        end: {
          line: 36,
          column: 13
        }
      },
      '18': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 38,
          column: 18
        }
      },
      '19': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 49,
          column: 9
        }
      },
      '20': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 44,
          column: 9
        }
      },
      '21': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 43,
          column: 19
        }
      },
      '22': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 47,
          column: 9
        }
      },
      '23': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 46,
          column: 19
        }
      },
      '24': {
        start: {
          line: 48,
          column: 8
        },
        end: {
          line: 48,
          column: 18
        }
      },
      '25': {
        start: {
          line: 51,
          column: 6
        },
        end: {
          line: 51,
          column: 41
        }
      },
      '26': {
        start: {
          line: 54,
          column: 2
        },
        end: {
          line: 54,
          column: 42
        }
      },
      '27': {
        start: {
          line: 56,
          column: 2
        },
        end: {
          line: 56,
          column: 80
        }
      },
      '28': {
        start: {
          line: 58,
          column: 2
        },
        end: {
          line: 60,
          column: 5
        }
      },
      '29': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 59,
          column: 128
        }
      },
      '30': {
        start: {
          line: 62,
          column: 2
        },
        end: {
          line: 62,
          column: 26
        }
      },
      '31': {
        start: {
          line: 63,
          column: 17
        },
        end: {
          line: 63,
          column: 32
        }
      },
      '32': {
        start: {
          line: 64,
          column: 19
        },
        end: {
          line: 64,
          column: 35
        }
      },
      '33': {
        start: {
          line: 65,
          column: 15
        },
        end: {
          line: 65,
          column: 26
        }
      },
      '34': {
        start: {
          line: 66,
          column: 18
        },
        end: {
          line: 66,
          column: 30
        }
      },
      '35': {
        start: {
          line: 67,
          column: 2
        },
        end: {
          line: 67,
          column: 30
        }
      },
      '36': {
        start: {
          line: 69,
          column: 2
        },
        end: {
          line: 69,
          column: 35
        }
      },
      '37': {
        start: {
          line: 71,
          column: 2
        },
        end: {
          line: 71,
          column: 31
        }
      },
      '38': {
        start: {
          line: 72,
          column: 2
        },
        end: {
          line: 72,
          column: 26
        }
      },
      '39': {
        start: {
          line: 73,
          column: 2
        },
        end: {
          line: 73,
          column: 27
        }
      },
      '40': {
        start: {
          line: 74,
          column: 2
        },
        end: {
          line: 79,
          column: 4
        }
      },
      '41': {
        start: {
          line: 75,
          column: 4
        },
        end: {
          line: 77,
          column: 5
        }
      },
      '42': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 76,
          column: 34
        }
      },
      '43': {
        start: {
          line: 78,
          column: 4
        },
        end: {
          line: 78,
          column: 28
        }
      },
      '44': {
        start: {
          line: 80,
          column: 2
        },
        end: {
          line: 83,
          column: 4
        }
      },
      '45': {
        start: {
          line: 81,
          column: 4
        },
        end: {
          line: 81,
          column: 37
        }
      },
      '46': {
        start: {
          line: 82,
          column: 4
        },
        end: {
          line: 82,
          column: 36
        }
      },
      '47': {
        start: {
          line: 84,
          column: 2
        },
        end: {
          line: 93,
          column: 4
        }
      },
      '48': {
        start: {
          line: 85,
          column: 4
        },
        end: {
          line: 85,
          column: 26
        }
      },
      '49': {
        start: {
          line: 86,
          column: 4
        },
        end: {
          line: 86,
          column: 24
        }
      },
      '50': {
        start: {
          line: 87,
          column: 4
        },
        end: {
          line: 91,
          column: 7
        }
      },
      '51': {
        start: {
          line: 88,
          column: 6
        },
        end: {
          line: 88,
          column: 30
        }
      },
      '52': {
        start: {
          line: 89,
          column: 6
        },
        end: {
          line: 89,
          column: 107
        }
      },
      '53': {
        start: {
          line: 89,
          column: 80
        },
        end: {
          line: 89,
          column: 102
        }
      },
      '54': {
        start: {
          line: 95,
          column: 2
        },
        end: {
          line: 99,
          column: 4
        }
      },
      '55': {
        start: {
          line: 101,
          column: 2
        },
        end: {
          line: 101,
          column: 42
        }
      },
      '56': {
        start: {
          line: 103,
          column: 2
        },
        end: {
          line: 107,
          column: 5
        }
      },
      '57': {
        start: {
          line: 105,
          column: 4
        },
        end: {
          line: 105,
          column: 58
        }
      },
      '58': {
        start: {
          line: 106,
          column: 4
        },
        end: {
          line: 106,
          column: 63
        }
      },
      '59': {
        start: {
          line: 110,
          column: 2
        },
        end: {
          line: 110,
          column: 29
        }
      },
      '60': {
        start: {
          line: 111,
          column: 2
        },
        end: {
          line: 111,
          column: 24
        }
      },
      '61': {
        start: {
          line: 112,
          column: 2
        },
        end: {
          line: 116,
          column: 4
        }
      },
      '62': {
        start: {
          line: 113,
          column: 4
        },
        end: {
          line: 115,
          column: 7
        }
      },
      '63': {
        start: {
          line: 114,
          column: 6
        },
        end: {
          line: 114,
          column: 35
        }
      },
      '64': {
        start: {
          line: 117,
          column: 2
        },
        end: {
          line: 121,
          column: 4
        }
      },
      '65': {
        start: {
          line: 118,
          column: 4
        },
        end: {
          line: 118,
          column: 96
        }
      },
      '66': {
        start: {
          line: 119,
          column: 4
        },
        end: {
          line: 119,
          column: 36
        }
      },
      '67': {
        start: {
          line: 120,
          column: 4
        },
        end: {
          line: 120,
          column: 29
        }
      },
      '68': {
        start: {
          line: 122,
          column: 2
        },
        end: {
          line: 126,
          column: 4
        }
      },
      '69': {
        start: {
          line: 123,
          column: 4
        },
        end: {
          line: 123,
          column: 28
        }
      },
      '70': {
        start: {
          line: 124,
          column: 4
        },
        end: {
          line: 124,
          column: 30
        }
      },
      '71': {
        start: {
          line: 125,
          column: 4
        },
        end: {
          line: 125,
          column: 47
        }
      },
      '72': {
        start: {
          line: 127,
          column: 2
        },
        end: {
          line: 138,
          column: 4
        }
      },
      '73': {
        start: {
          line: 128,
          column: 4
        },
        end: {
          line: 137,
          column: 7
        }
      },
      '74': {
        start: {
          line: 139,
          column: 2
        },
        end: {
          line: 142,
          column: 4
        }
      },
      '75': {
        start: {
          line: 140,
          column: 4
        },
        end: {
          line: 140,
          column: 31
        }
      },
      '76': {
        start: {
          line: 141,
          column: 4
        },
        end: {
          line: 141,
          column: 33
        }
      },
      '77': {
        start: {
          line: 143,
          column: 2
        },
        end: {
          line: 172,
          column: 4
        }
      },
      '78': {
        start: {
          line: 144,
          column: 4
        },
        end: {
          line: 171,
          column: 7
        }
      },
      '79': {
        start: {
          line: 156,
          column: 6
        },
        end: {
          line: 158,
          column: 7
        }
      },
      '80': {
        start: {
          line: 157,
          column: 8
        },
        end: {
          line: 157,
          column: 15
        }
      },
      '81': {
        start: {
          line: 159,
          column: 6
        },
        end: {
          line: 159,
          column: 37
        }
      },
      '82': {
        start: {
          line: 160,
          column: 6
        },
        end: {
          line: 170,
          column: 9
        }
      },
      '83': {
        start: {
          line: 161,
          column: 8
        },
        end: {
          line: 161,
          column: 40
        }
      },
      '84': {
        start: {
          line: 162,
          column: 8
        },
        end: {
          line: 168,
          column: 9
        }
      },
      '85': {
        start: {
          line: 163,
          column: 10
        },
        end: {
          line: 166,
          column: 13
        }
      },
      '86': {
        start: {
          line: 167,
          column: 10
        },
        end: {
          line: 167,
          column: 17
        }
      },
      '87': {
        start: {
          line: 169,
          column: 8
        },
        end: {
          line: 169,
          column: 42
        }
      },
      '88': {
        start: {
          line: 173,
          column: 2
        },
        end: {
          line: 205,
          column: 4
        }
      },
      '89': {
        start: {
          line: 174,
          column: 18
        },
        end: {
          line: 174,
          column: 36
        }
      },
      '90': {
        start: {
          line: 175,
          column: 4
        },
        end: {
          line: 175,
          column: 35
        }
      },
      '91': {
        start: {
          line: 176,
          column: 4
        },
        end: {
          line: 176,
          column: 27
        }
      },
      '92': {
        start: {
          line: 177,
          column: 4
        },
        end: {
          line: 177,
          column: 30
        }
      },
      '93': {
        start: {
          line: 178,
          column: 4
        },
        end: {
          line: 178,
          column: 82
        }
      },
      '94': {
        start: {
          line: 179,
          column: 4
        },
        end: {
          line: 179,
          column: 29
        }
      },
      '95': {
        start: {
          line: 180,
          column: 4
        },
        end: {
          line: 180,
          column: 58
        }
      },
      '96': {
        start: {
          line: 181,
          column: 4
        },
        end: {
          line: 184,
          column: 6
        }
      },
      '97': {
        start: {
          line: 185,
          column: 4
        },
        end: {
          line: 204,
          column: 7
        }
      },
      '98': {
        start: {
          line: 186,
          column: 6
        },
        end: {
          line: 186,
          column: 38
        }
      },
      '99': {
        start: {
          line: 187,
          column: 6
        },
        end: {
          line: 193,
          column: 7
        }
      },
      '100': {
        start: {
          line: 188,
          column: 8
        },
        end: {
          line: 191,
          column: 10
        }
      },
      '101': {
        start: {
          line: 192,
          column: 8
        },
        end: {
          line: 192,
          column: 15
        }
      },
      '102': {
        start: {
          line: 194,
          column: 6
        },
        end: {
          line: 200,
          column: 7
        }
      },
      '103': {
        start: {
          line: 195,
          column: 8
        },
        end: {
          line: 198,
          column: 10
        }
      },
      '104': {
        start: {
          line: 199,
          column: 8
        },
        end: {
          line: 199,
          column: 15
        }
      },
      '105': {
        start: {
          line: 201,
          column: 6
        },
        end: {
          line: 201,
          column: 31
        }
      },
      '106': {
        start: {
          line: 202,
          column: 6
        },
        end: {
          line: 202,
          column: 40
        }
      },
      '107': {
        start: {
          line: 203,
          column: 6
        },
        end: {
          line: 203,
          column: 28
        }
      },
      '108': {
        start: {
          line: 206,
          column: 2
        },
        end: {
          line: 208,
          column: 4
        }
      },
      '109': {
        start: {
          line: 207,
          column: 4
        },
        end: {
          line: 207,
          column: 47
        }
      },
      '110': {
        start: {
          line: 209,
          column: 2
        },
        end: {
          line: 219,
          column: 4
        }
      },
      '111': {
        start: {
          line: 210,
          column: 4
        },
        end: {
          line: 210,
          column: 29
        }
      },
      '112': {
        start: {
          line: 211,
          column: 4
        },
        end: {
          line: 218,
          column: 7
        }
      },
      '113': {
        start: {
          line: 220,
          column: 2
        },
        end: {
          line: 234,
          column: 4
        }
      },
      '114': {
        start: {
          line: 221,
          column: 4
        },
        end: {
          line: 221,
          column: 34
        }
      },
      '115': {
        start: {
          line: 222,
          column: 4
        },
        end: {
          line: 233,
          column: 7
        }
      },
      '116': {
        start: {
          line: 223,
          column: 6
        },
        end: {
          line: 232,
          column: 7
        }
      },
      '117': {
        start: {
          line: 224,
          column: 8
        },
        end: {
          line: 224,
          column: 35
        }
      },
      '118': {
        start: {
          line: 225,
          column: 8
        },
        end: {
          line: 225,
          column: 45
        }
      },
      '119': {
        start: {
          line: 226,
          column: 8
        },
        end: {
          line: 226,
          column: 42
        }
      },
      '120': {
        start: {
          line: 227,
          column: 8
        },
        end: {
          line: 227,
          column: 48
        }
      },
      '121': {
        start: {
          line: 228,
          column: 8
        },
        end: {
          line: 228,
          column: 37
        }
      },
      '122': {
        start: {
          line: 229,
          column: 8
        },
        end: {
          line: 229,
          column: 38
        }
      },
      '123': {
        start: {
          line: 231,
          column: 8
        },
        end: {
          line: 231,
          column: 35
        }
      },
      '124': {
        start: {
          line: 236,
          column: 19
        },
        end: {
          line: 241,
          column: 4
        }
      },
      '125': {
        start: {
          line: 243,
          column: 2
        },
        end: {
          line: 247,
          column: 4
        }
      },
      '126': {
        start: {
          line: 244,
          column: 4
        },
        end: {
          line: 244,
          column: 32
        }
      },
      '127': {
        start: {
          line: 245,
          column: 4
        },
        end: {
          line: 245,
          column: 30
        }
      },
      '128': {
        start: {
          line: 246,
          column: 4
        },
        end: {
          line: 246,
          column: 22
        }
      },
      '129': {
        start: {
          line: 248,
          column: 2
        },
        end: {
          line: 267,
          column: 4
        }
      },
      '130': {
        start: {
          line: 249,
          column: 4
        },
        end: {
          line: 263,
          column: 5
        }
      },
      '131': {
        start: {
          line: 250,
          column: 23
        },
        end: {
          line: 250,
          column: 49
        }
      },
      '132': {
        start: {
          line: 251,
          column: 6
        },
        end: {
          line: 253,
          column: 7
        }
      },
      '133': {
        start: {
          line: 252,
          column: 8
        },
        end: {
          line: 252,
          column: 49
        }
      },
      '134': {
        start: {
          line: 254,
          column: 6
        },
        end: {
          line: 256,
          column: 7
        }
      },
      '135': {
        start: {
          line: 255,
          column: 8
        },
        end: {
          line: 255,
          column: 49
        }
      },
      '136': {
        start: {
          line: 257,
          column: 6
        },
        end: {
          line: 260,
          column: 9
        }
      },
      '137': {
        start: {
          line: 261,
          column: 6
        },
        end: {
          line: 261,
          column: 35
        }
      },
      '138': {
        start: {
          line: 262,
          column: 6
        },
        end: {
          line: 262,
          column: 13
        }
      },
      '139': {
        start: {
          line: 264,
          column: 4
        },
        end: {
          line: 266,
          column: 7
        }
      },
      '140': {
        start: {
          line: 265,
          column: 6
        },
        end: {
          line: 265,
          column: 29
        }
      },
      '141': {
        start: {
          line: 268,
          column: 2
        },
        end: {
          line: 269,
          column: 4
        }
      },
      '142': {
        start: {
          line: 271,
          column: 2
        },
        end: {
          line: 271,
          column: 23
        }
      },
      '143': {
        start: {
          line: 272,
          column: 2
        },
        end: {
          line: 353,
          column: 4
        }
      },
      '144': {
        start: {
          line: 273,
          column: 13
        },
        end: {
          line: 273,
          column: 15
        }
      },
      '145': {
        start: {
          line: 274,
          column: 4
        },
        end: {
          line: 274,
          column: 26
        }
      },
      '146': {
        start: {
          line: 275,
          column: 4
        },
        end: {
          line: 275,
          column: 35
        }
      },
      '147': {
        start: {
          line: 276,
          column: 4
        },
        end: {
          line: 339,
          column: 5
        }
      },
      '148': {
        start: {
          line: 277,
          column: 6
        },
        end: {
          line: 292,
          column: 8
        }
      },
      '149': {
        start: {
          line: 293,
          column: 11
        },
        end: {
          line: 339,
          column: 5
        }
      },
      '150': {
        start: {
          line: 294,
          column: 6
        },
        end: {
          line: 308,
          column: 8
        }
      },
      '151': {
        start: {
          line: 309,
          column: 6
        },
        end: {
          line: 316,
          column: 7
        }
      },
      '152': {
        start: {
          line: 310,
          column: 8
        },
        end: {
          line: 315,
          column: 9
        }
      },
      '153': {
        start: {
          line: 311,
          column: 10
        },
        end: {
          line: 311,
          column: 65
        }
      },
      '154': {
        start: {
          line: 312,
          column: 10
        },
        end: {
          line: 312,
          column: 56
        }
      },
      '155': {
        start: {
          line: 313,
          column: 10
        },
        end: {
          line: 313,
          column: 69
        }
      },
      '156': {
        start: {
          line: 314,
          column: 10
        },
        end: {
          line: 314,
          column: 16
        }
      },
      '157': {
        start: {
          line: 318,
          column: 6
        },
        end: {
          line: 331,
          column: 8
        }
      },
      '158': {
        start: {
          line: 332,
          column: 6
        },
        end: {
          line: 338,
          column: 7
        }
      },
      '159': {
        start: {
          line: 333,
          column: 8
        },
        end: {
          line: 337,
          column: 9
        }
      },
      '160': {
        start: {
          line: 334,
          column: 10
        },
        end: {
          line: 334,
          column: 52
        }
      },
      '161': {
        start: {
          line: 335,
          column: 10
        },
        end: {
          line: 335,
          column: 69
        }
      },
      '162': {
        start: {
          line: 336,
          column: 10
        },
        end: {
          line: 336,
          column: 16
        }
      },
      '163': {
        start: {
          line: 340,
          column: 4
        },
        end: {
          line: 342,
          column: 6
        }
      },
      '164': {
        start: {
          line: 343,
          column: 4
        },
        end: {
          line: 343,
          column: 25
        }
      },
      '165': {
        start: {
          line: 345,
          column: 4
        },
        end: {
          line: 352,
          column: 7
        }
      },
      '166': {
        start: {
          line: 357,
          column: 4
        },
        end: {
          line: 359,
          column: 6
        }
      },
      '167': {
        start: {
          line: 358,
          column: 6
        },
        end: {
          line: 358,
          column: 42
        }
      },
      '168': {
        start: {
          line: 362,
          column: 2
        },
        end: {
          line: 371,
          column: 4
        }
      },
      '169': {
        start: {
          line: 363,
          column: 4
        },
        end: {
          line: 370,
          column: 7
        }
      },
      '170': {
        start: {
          line: 373,
          column: 2
        },
        end: {
          line: 428,
          column: 4
        }
      },
      '171': {
        start: {
          line: 374,
          column: 22
        },
        end: {
          line: 374,
          column: 31
        }
      },
      '172': {
        start: {
          line: 375,
          column: 4
        },
        end: {
          line: 427,
          column: 7
        }
      },
      '173': {
        start: {
          line: 387,
          column: 6
        },
        end: {
          line: 389,
          column: 7
        }
      },
      '174': {
        start: {
          line: 388,
          column: 8
        },
        end: {
          line: 388,
          column: 15
        }
      },
      '175': {
        start: {
          line: 390,
          column: 6
        },
        end: {
          line: 390,
          column: 26
        }
      },
      '176': {
        start: {
          line: 391,
          column: 27
        },
        end: {
          line: 397,
          column: 7
        }
      },
      '177': {
        start: {
          line: 399,
          column: 6
        },
        end: {
          line: 426,
          column: 9
        }
      },
      '178': {
        start: {
          line: 400,
          column: 8
        },
        end: {
          line: 400,
          column: 30
        }
      },
      '179': {
        start: {
          line: 401,
          column: 8
        },
        end: {
          line: 401,
          column: 34
        }
      },
      '180': {
        start: {
          line: 402,
          column: 8
        },
        end: {
          line: 423,
          column: 9
        }
      },
      '181': {
        start: {
          line: 403,
          column: 10
        },
        end: {
          line: 403,
          column: 35
        }
      },
      '182': {
        start: {
          line: 405,
          column: 20
        },
        end: {
          line: 405,
          column: 67
        }
      },
      '183': {
        start: {
          line: 407,
          column: 10
        },
        end: {
          line: 415,
          column: 11
        }
      },
      '184': {
        start: {
          line: 409,
          column: 14
        },
        end: {
          line: 409,
          column: 77
        }
      },
      '185': {
        start: {
          line: 410,
          column: 14
        },
        end: {
          line: 410,
          column: 20
        }
      },
      '186': {
        start: {
          line: 413,
          column: 14
        },
        end: {
          line: 413,
          column: 78
        }
      },
      '187': {
        start: {
          line: 414,
          column: 14
        },
        end: {
          line: 414,
          column: 20
        }
      },
      '188': {
        start: {
          line: 417,
          column: 10
        },
        end: {
          line: 420,
          column: 13
        }
      },
      '189': {
        start: {
          line: 422,
          column: 10
        },
        end: {
          line: 422,
          column: 17
        }
      },
      '190': {
        start: {
          line: 424,
          column: 8
        },
        end: {
          line: 424,
          column: 30
        }
      },
      '191': {
        start: {
          line: 425,
          column: 8
        },
        end: {
          line: 425,
          column: 43
        }
      },
      '192': {
        start: {
          line: 429,
          column: 2
        },
        end: {
          line: 432,
          column: 4
        }
      },
      '193': {
        start: {
          line: 430,
          column: 4
        },
        end: {
          line: 430,
          column: 25
        }
      },
      '194': {
        start: {
          line: 431,
          column: 4
        },
        end: {
          line: 431,
          column: 36
        }
      },
      '195': {
        start: {
          line: 434,
          column: 2
        },
        end: {
          line: 440,
          column: 5
        }
      },
      '196': {
        start: {
          line: 435,
          column: 4
        },
        end: {
          line: 439,
          column: 7
        }
      },
      '197': {
        start: {
          line: 442,
          column: 2
        },
        end: {
          line: 442,
          column: 23
        }
      },
      '198': {
        start: {
          line: 443,
          column: 2
        },
        end: {
          line: 443,
          column: 24
        }
      },
      '199': {
        start: {
          line: 447,
          column: 2
        },
        end: {
          line: 450,
          column: 4
        }
      },
      '200': {
        start: {
          line: 448,
          column: 4
        },
        end: {
          line: 448,
          column: 24
        }
      },
      '201': {
        start: {
          line: 449,
          column: 4
        },
        end: {
          line: 449,
          column: 40
        }
      },
      '202': {
        start: {
          line: 451,
          column: 2
        },
        end: {
          line: 453,
          column: 4
        }
      },
      '203': {
        start: {
          line: 452,
          column: 4
        },
        end: {
          line: 452,
          column: 75
        }
      },
      '204': {
        start: {
          line: 454,
          column: 2
        },
        end: {
          line: 463,
          column: 4
        }
      },
      '205': {
        start: {
          line: 455,
          column: 17
        },
        end: {
          line: 455,
          column: 32
        }
      },
      '206': {
        start: {
          line: 456,
          column: 4
        },
        end: {
          line: 458,
          column: 5
        }
      },
      '207': {
        start: {
          line: 457,
          column: 6
        },
        end: {
          line: 457,
          column: 18
        }
      },
      '208': {
        start: {
          line: 459,
          column: 4
        },
        end: {
          line: 461,
          column: 5
        }
      },
      '209': {
        start: {
          line: 460,
          column: 6
        },
        end: {
          line: 460,
          column: 18
        }
      },
      '210': {
        start: {
          line: 462,
          column: 4
        },
        end: {
          line: 462,
          column: 17
        }
      },
      '211': {
        start: {
          line: 464,
          column: 2
        },
        end: {
          line: 531,
          column: 4
        }
      },
      '212': {
        start: {
          line: 465,
          column: 4
        },
        end: {
          line: 465,
          column: 35
        }
      },
      '213': {
        start: {
          line: 466,
          column: 14
        },
        end: {
          line: 479,
          column: 5
        }
      },
      '214': {
        start: {
          line: 480,
          column: 4
        },
        end: {
          line: 480,
          column: 27
        }
      },
      '215': {
        start: {
          line: 482,
          column: 4
        },
        end: {
          line: 488,
          column: 5
        }
      },
      '216': {
        start: {
          line: 483,
          column: 6
        },
        end: {
          line: 486,
          column: 7
        }
      },
      '217': {
        start: {
          line: 484,
          column: 8
        },
        end: {
          line: 484,
          column: 30
        }
      },
      '218': {
        start: {
          line: 485,
          column: 8
        },
        end: {
          line: 485,
          column: 15
        }
      },
      '219': {
        start: {
          line: 487,
          column: 6
        },
        end: {
          line: 487,
          column: 49
        }
      },
      '220': {
        start: {
          line: 490,
          column: 4
        },
        end: {
          line: 495,
          column: 5
        }
      },
      '221': {
        start: {
          line: 491,
          column: 6
        },
        end: {
          line: 491,
          column: 35
        }
      },
      '222': {
        start: {
          line: 492,
          column: 6
        },
        end: {
          line: 492,
          column: 36
        }
      },
      '223': {
        start: {
          line: 493,
          column: 6
        },
        end: {
          line: 493,
          column: 13
        }
      },
      '224': {
        start: {
          line: 497,
          column: 18
        },
        end: {
          line: 497,
          column: 22
        }
      },
      '225': {
        start: {
          line: 498,
          column: 4
        },
        end: {
          line: 530,
          column: 7
        }
      },
      '226': {
        start: {
          line: 502,
          column: 6
        },
        end: {
          line: 504,
          column: 7
        }
      },
      '227': {
        start: {
          line: 503,
          column: 8
        },
        end: {
          line: 503,
          column: 15
        }
      },
      '228': {
        start: {
          line: 505,
          column: 6
        },
        end: {
          line: 527,
          column: 7
        }
      },
      '229': {
        start: {
          line: 506,
          column: 23
        },
        end: {
          line: 506,
          column: 94
        }
      },
      '230': {
        start: {
          line: 507,
          column: 8
        },
        end: {
          line: 521,
          column: 9
        }
      },
      '231': {
        start: {
          line: 508,
          column: 10
        },
        end: {
          line: 508,
          column: 32
        }
      },
      '232': {
        start: {
          line: 509,
          column: 10
        },
        end: {
          line: 509,
          column: 31
        }
      },
      '233': {
        start: {
          line: 510,
          column: 10
        },
        end: {
          line: 510,
          column: 35
        }
      },
      '234': {
        start: {
          line: 511,
          column: 10
        },
        end: {
          line: 511,
          column: 35
        }
      },
      '235': {
        start: {
          line: 512,
          column: 10
        },
        end: {
          line: 512,
          column: 94
        }
      },
      '236': {
        start: {
          line: 513,
          column: 15
        },
        end: {
          line: 521,
          column: 9
        }
      },
      '237': {
        start: {
          line: 514,
          column: 10
        },
        end: {
          line: 514,
          column: 32
        }
      },
      '238': {
        start: {
          line: 515,
          column: 10
        },
        end: {
          line: 515,
          column: 31
        }
      },
      '239': {
        start: {
          line: 516,
          column: 10
        },
        end: {
          line: 516,
          column: 35
        }
      },
      '240': {
        start: {
          line: 517,
          column: 10
        },
        end: {
          line: 517,
          column: 35
        }
      },
      '241': {
        start: {
          line: 518,
          column: 10
        },
        end: {
          line: 518,
          column: 69
        }
      },
      '242': {
        start: {
          line: 520,
          column: 10
        },
        end: {
          line: 520,
          column: 44
        }
      },
      '243': {
        start: {
          line: 523,
          column: 8
        },
        end: {
          line: 523,
          column: 29
        }
      },
      '244': {
        start: {
          line: 524,
          column: 8
        },
        end: {
          line: 524,
          column: 33
        }
      },
      '245': {
        start: {
          line: 525,
          column: 8
        },
        end: {
          line: 525,
          column: 33
        }
      },
      '246': {
        start: {
          line: 526,
          column: 8
        },
        end: {
          line: 526,
          column: 92
        }
      },
      '247': {
        start: {
          line: 528,
          column: 6
        },
        end: {
          line: 528,
          column: 28
        }
      },
      '248': {
        start: {
          line: 529,
          column: 6
        },
        end: {
          line: 529,
          column: 26
        }
      },
      '249': {
        start: {
          line: 534,
          column: 2
        },
        end: {
          line: 538,
          column: 4
        }
      },
      '250': {
        start: {
          line: 536,
          column: 6
        },
        end: {
          line: 536,
          column: 42
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 53
          },
          end: {
            line: 4,
            column: 54
          }
        },
        loc: {
          start: {
            line: 4,
            column: 218
          },
          end: {
            line: 444,
            column: 1
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 12,
            column: 30
          },
          end: {
            line: 12,
            column: 31
          }
        },
        loc: {
          start: {
            line: 12,
            column: 47
          },
          end: {
            line: 14,
            column: 3
          }
        },
        line: 12
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 16,
            column: 39
          },
          end: {
            line: 16,
            column: 40
          }
        },
        loc: {
          start: {
            line: 16,
            column: 45
          },
          end: {
            line: 53,
            column: 3
          }
        },
        line: 16
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 17,
            column: 67
          },
          end: {
            line: 17,
            column: 68
          }
        },
        loc: {
          start: {
            line: 17,
            column: 75
          },
          end: {
            line: 52,
            column: 5
          }
        },
        line: 17
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 21,
            column: 61
          },
          end: {
            line: 21,
            column: 62
          }
        },
        loc: {
          start: {
            line: 21,
            column: 71
          },
          end: {
            line: 21,
            column: 103
          }
        },
        line: 21
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 41,
            column: 42
          },
          end: {
            line: 41,
            column: 43
          }
        },
        loc: {
          start: {
            line: 41,
            column: 62
          },
          end: {
            line: 49,
            column: 7
          }
        },
        line: 41
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 58,
            column: 31
          },
          end: {
            line: 58,
            column: 32
          }
        },
        loc: {
          start: {
            line: 58,
            column: 37
          },
          end: {
            line: 60,
            column: 3
          }
        },
        line: 58
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 74,
            column: 25
          },
          end: {
            line: 74,
            column: 26
          }
        },
        loc: {
          start: {
            line: 74,
            column: 41
          },
          end: {
            line: 79,
            column: 3
          }
        },
        line: 74
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 80,
            column: 24
          },
          end: {
            line: 80,
            column: 25
          }
        },
        loc: {
          start: {
            line: 80,
            column: 36
          },
          end: {
            line: 83,
            column: 3
          }
        },
        line: 80
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 84,
            column: 24
          },
          end: {
            line: 84,
            column: 25
          }
        },
        loc: {
          start: {
            line: 84,
            column: 36
          },
          end: {
            line: 93,
            column: 3
          }
        },
        line: 84
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 87,
            column: 58
          },
          end: {
            line: 87,
            column: 59
          }
        },
        loc: {
          start: {
            line: 87,
            column: 66
          },
          end: {
            line: 91,
            column: 5
          }
        },
        line: 87
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 89,
            column: 63
          },
          end: {
            line: 89,
            column: 64
          }
        },
        loc: {
          start: {
            line: 89,
            column: 80
          },
          end: {
            line: 89,
            column: 102
          }
        },
        line: 89
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 103,
            column: 62
          },
          end: {
            line: 103,
            column: 63
          }
        },
        loc: {
          start: {
            line: 103,
            column: 70
          },
          end: {
            line: 107,
            column: 3
          }
        },
        line: 103
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 112,
            column: 23
          },
          end: {
            line: 112,
            column: 24
          }
        },
        loc: {
          start: {
            line: 112,
            column: 35
          },
          end: {
            line: 116,
            column: 3
          }
        },
        line: 112
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 113,
            column: 65
          },
          end: {
            line: 113,
            column: 66
          }
        },
        loc: {
          start: {
            line: 113,
            column: 73
          },
          end: {
            line: 115,
            column: 5
          }
        },
        line: 113
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 117,
            column: 25
          },
          end: {
            line: 117,
            column: 26
          }
        },
        loc: {
          start: {
            line: 117,
            column: 42
          },
          end: {
            line: 121,
            column: 3
          }
        },
        line: 117
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 122,
            column: 25
          },
          end: {
            line: 122,
            column: 26
          }
        },
        loc: {
          start: {
            line: 122,
            column: 41
          },
          end: {
            line: 126,
            column: 3
          }
        },
        line: 122
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 127,
            column: 28
          },
          end: {
            line: 127,
            column: 29
          }
        },
        loc: {
          start: {
            line: 127,
            column: 40
          },
          end: {
            line: 138,
            column: 3
          }
        },
        line: 127
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 139,
            column: 27
          },
          end: {
            line: 139,
            column: 28
          }
        },
        loc: {
          start: {
            line: 139,
            column: 39
          },
          end: {
            line: 142,
            column: 3
          }
        },
        line: 139
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 143,
            column: 27
          },
          end: {
            line: 143,
            column: 28
          }
        },
        loc: {
          start: {
            line: 143,
            column: 43
          },
          end: {
            line: 172,
            column: 3
          }
        },
        line: 143
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 155,
            column: 7
          },
          end: {
            line: 155,
            column: 8
          }
        },
        loc: {
          start: {
            line: 155,
            column: 20
          },
          end: {
            line: 171,
            column: 5
          }
        },
        line: 155
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 160,
            column: 89
          },
          end: {
            line: 160,
            column: 90
          }
        },
        loc: {
          start: {
            line: 160,
            column: 97
          },
          end: {
            line: 170,
            column: 7
          }
        },
        line: 160
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 173,
            column: 26
          },
          end: {
            line: 173,
            column: 27
          }
        },
        loc: {
          start: {
            line: 173,
            column: 42
          },
          end: {
            line: 205,
            column: 3
          }
        },
        line: 173
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 185,
            column: 92
          },
          end: {
            line: 185,
            column: 93
          }
        },
        loc: {
          start: {
            line: 185,
            column: 100
          },
          end: {
            line: 204,
            column: 5
          }
        },
        line: 185
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 206,
            column: 24
          },
          end: {
            line: 206,
            column: 25
          }
        },
        loc: {
          start: {
            line: 206,
            column: 40
          },
          end: {
            line: 208,
            column: 3
          }
        },
        line: 206
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 209,
            column: 28
          },
          end: {
            line: 209,
            column: 29
          }
        },
        loc: {
          start: {
            line: 209,
            column: 40
          },
          end: {
            line: 219,
            column: 3
          }
        },
        line: 209
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 220,
            column: 24
          },
          end: {
            line: 220,
            column: 25
          }
        },
        loc: {
          start: {
            line: 220,
            column: 37
          },
          end: {
            line: 234,
            column: 3
          }
        },
        line: 220
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 222,
            column: 66
          },
          end: {
            line: 222,
            column: 67
          }
        },
        loc: {
          start: {
            line: 222,
            column: 74
          },
          end: {
            line: 233,
            column: 5
          }
        },
        line: 222
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 243,
            column: 31
          },
          end: {
            line: 243,
            column: 32
          }
        },
        loc: {
          start: {
            line: 243,
            column: 51
          },
          end: {
            line: 247,
            column: 3
          }
        },
        line: 243
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 248,
            column: 27
          },
          end: {
            line: 248,
            column: 28
          }
        },
        loc: {
          start: {
            line: 248,
            column: 74
          },
          end: {
            line: 267,
            column: 3
          }
        },
        line: 248
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 264,
            column: 98
          },
          end: {
            line: 264,
            column: 99
          }
        },
        loc: {
          start: {
            line: 264,
            column: 106
          },
          end: {
            line: 266,
            column: 5
          }
        },
        line: 264
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 268,
            column: 25
          },
          end: {
            line: 268,
            column: 26
          }
        },
        loc: {
          start: {
            line: 268,
            column: 72
          },
          end: {
            line: 269,
            column: 3
          }
        },
        line: 268
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 272,
            column: 16
          },
          end: {
            line: 272,
            column: 17
          }
        },
        loc: {
          start: {
            line: 272,
            column: 38
          },
          end: {
            line: 353,
            column: 3
          }
        },
        line: 272
      },
      '33': {
        name: 'logical_planCtrl_notification',
        decl: {
          start: {
            line: 355,
            column: 11
          },
          end: {
            line: 355,
            column: 40
          }
        },
        loc: {
          start: {
            line: 355,
            column: 115
          },
          end: {
            line: 360,
            column: 3
          }
        },
        line: 355
      },
      '34': {
        name: '(anonymous_34)',
        decl: {
          start: {
            line: 357,
            column: 24
          },
          end: {
            line: 357,
            column: 25
          }
        },
        loc: {
          start: {
            line: 357,
            column: 36
          },
          end: {
            line: 359,
            column: 5
          }
        },
        line: 357
      },
      '35': {
        name: '(anonymous_35)',
        decl: {
          start: {
            line: 362,
            column: 28
          },
          end: {
            line: 362,
            column: 29
          }
        },
        loc: {
          start: {
            line: 362,
            column: 40
          },
          end: {
            line: 371,
            column: 3
          }
        },
        line: 362
      },
      '36': {
        name: '(anonymous_36)',
        decl: {
          start: {
            line: 373,
            column: 22
          },
          end: {
            line: 373,
            column: 23
          }
        },
        loc: {
          start: {
            line: 373,
            column: 38
          },
          end: {
            line: 428,
            column: 3
          }
        },
        line: 373
      },
      '37': {
        name: '(anonymous_37)',
        decl: {
          start: {
            line: 386,
            column: 7
          },
          end: {
            line: 386,
            column: 8
          }
        },
        loc: {
          start: {
            line: 386,
            column: 20
          },
          end: {
            line: 427,
            column: 5
          }
        },
        line: 386
      },
      '38': {
        name: '(anonymous_38)',
        decl: {
          start: {
            line: 399,
            column: 126
          },
          end: {
            line: 399,
            column: 127
          }
        },
        loc: {
          start: {
            line: 399,
            column: 134
          },
          end: {
            line: 426,
            column: 7
          }
        },
        line: 399
      },
      '39': {
        name: '(anonymous_39)',
        decl: {
          start: {
            line: 429,
            column: 22
          },
          end: {
            line: 429,
            column: 23
          }
        },
        loc: {
          start: {
            line: 429,
            column: 34
          },
          end: {
            line: 432,
            column: 3
          }
        },
        line: 429
      },
      '40': {
        name: '(anonymous_40)',
        decl: {
          start: {
            line: 434,
            column: 22
          },
          end: {
            line: 434,
            column: 23
          }
        },
        loc: {
          start: {
            line: 434,
            column: 31
          },
          end: {
            line: 440,
            column: 3
          }
        },
        line: 434
      },
      '41': {
        name: 'logical_planCtrl_edit',
        decl: {
          start: {
            line: 446,
            column: 9
          },
          end: {
            line: 446,
            column: 30
          }
        },
        loc: {
          start: {
            line: 446,
            column: 118
          },
          end: {
            line: 532,
            column: 1
          }
        },
        line: 446
      },
      '42': {
        name: '(anonymous_42)',
        decl: {
          start: {
            line: 447,
            column: 22
          },
          end: {
            line: 447,
            column: 23
          }
        },
        loc: {
          start: {
            line: 447,
            column: 34
          },
          end: {
            line: 450,
            column: 3
          }
        },
        line: 447
      },
      '43': {
        name: '(anonymous_43)',
        decl: {
          start: {
            line: 451,
            column: 24
          },
          end: {
            line: 451,
            column: 25
          }
        },
        loc: {
          start: {
            line: 451,
            column: 40
          },
          end: {
            line: 453,
            column: 3
          }
        },
        line: 451
      },
      '44': {
        name: '(anonymous_44)',
        decl: {
          start: {
            line: 454,
            column: 21
          },
          end: {
            line: 454,
            column: 22
          }
        },
        loc: {
          start: {
            line: 454,
            column: 33
          },
          end: {
            line: 463,
            column: 3
          }
        },
        line: 454
      },
      '45': {
        name: '(anonymous_45)',
        decl: {
          start: {
            line: 464,
            column: 24
          },
          end: {
            line: 464,
            column: 25
          }
        },
        loc: {
          start: {
            line: 464,
            column: 42
          },
          end: {
            line: 531,
            column: 3
          }
        },
        line: 464
      },
      '46': {
        name: '(anonymous_46)',
        decl: {
          start: {
            line: 498,
            column: 88
          },
          end: {
            line: 498,
            column: 89
          }
        },
        loc: {
          start: {
            line: 498,
            column: 96
          },
          end: {
            line: 530,
            column: 5
          }
        },
        line: 498
      },
      '47': {
        name: 'logical_levelCtrl',
        decl: {
          start: {
            line: 533,
            column: 9
          },
          end: {
            line: 533,
            column: 26
          }
        },
        loc: {
          start: {
            line: 533,
            column: 77
          },
          end: {
            line: 539,
            column: 1
          }
        },
        line: 533
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }, {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }],
        line: 6
      },
      '1': {
        loc: {
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 37,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 37,
            column: 9
          }
        }, {
          start: {
            line: 23,
            column: 8
          },
          end: {
            line: 37,
            column: 9
          }
        }],
        line: 23
      },
      '2': {
        loc: {
          start: {
            line: 42,
            column: 8
          },
          end: {
            line: 44,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 8
          },
          end: {
            line: 44,
            column: 9
          }
        }, {
          start: {
            line: 42,
            column: 8
          },
          end: {
            line: 44,
            column: 9
          }
        }],
        line: 42
      },
      '3': {
        loc: {
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 9
          }
        }, {
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 9
          }
        }],
        line: 45
      },
      '4': {
        loc: {
          start: {
            line: 59,
            column: 79
          },
          end: {
            line: 59,
            column: 125
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 59,
            column: 120
          },
          end: {
            line: 59,
            column: 121
          }
        }, {
          start: {
            line: 59,
            column: 124
          },
          end: {
            line: 59,
            column: 125
          }
        }],
        line: 59
      },
      '5': {
        loc: {
          start: {
            line: 75,
            column: 4
          },
          end: {
            line: 77,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 75,
            column: 4
          },
          end: {
            line: 77,
            column: 5
          }
        }, {
          start: {
            line: 75,
            column: 4
          },
          end: {
            line: 77,
            column: 5
          }
        }],
        line: 75
      },
      '6': {
        loc: {
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 75,
            column: 100
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 75,
            column: 38
          }
        }, {
          start: {
            line: 75,
            column: 42
          },
          end: {
            line: 75,
            column: 68
          }
        }, {
          start: {
            line: 75,
            column: 72
          },
          end: {
            line: 75,
            column: 100
          }
        }],
        line: 75
      },
      '7': {
        loc: {
          start: {
            line: 118,
            column: 31
          },
          end: {
            line: 118,
            column: 95
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 118,
            column: 62
          },
          end: {
            line: 118,
            column: 87
          }
        }, {
          start: {
            line: 118,
            column: 90
          },
          end: {
            line: 118,
            column: 95
          }
        }],
        line: 118
      },
      '8': {
        loc: {
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 158,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 158,
            column: 7
          }
        }, {
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 158,
            column: 7
          }
        }],
        line: 156
      },
      '9': {
        loc: {
          start: {
            line: 162,
            column: 8
          },
          end: {
            line: 168,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 162,
            column: 8
          },
          end: {
            line: 168,
            column: 9
          }
        }, {
          start: {
            line: 162,
            column: 8
          },
          end: {
            line: 168,
            column: 9
          }
        }],
        line: 162
      },
      '10': {
        loc: {
          start: {
            line: 178,
            column: 28
          },
          end: {
            line: 178,
            column: 81
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 178,
            column: 76
          },
          end: {
            line: 178,
            column: 77
          }
        }, {
          start: {
            line: 178,
            column: 80
          },
          end: {
            line: 178,
            column: 81
          }
        }],
        line: 178
      },
      '11': {
        loc: {
          start: {
            line: 178,
            column: 28
          },
          end: {
            line: 178,
            column: 73
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 178,
            column: 28
          },
          end: {
            line: 178,
            column: 49
          }
        }, {
          start: {
            line: 178,
            column: 53
          },
          end: {
            line: 178,
            column: 73
          }
        }],
        line: 178
      },
      '12': {
        loc: {
          start: {
            line: 180,
            column: 28
          },
          end: {
            line: 180,
            column: 57
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 180,
            column: 52
          },
          end: {
            line: 180,
            column: 53
          }
        }, {
          start: {
            line: 180,
            column: 56
          },
          end: {
            line: 180,
            column: 57
          }
        }],
        line: 180
      },
      '13': {
        loc: {
          start: {
            line: 187,
            column: 6
          },
          end: {
            line: 193,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 187,
            column: 6
          },
          end: {
            line: 193,
            column: 7
          }
        }, {
          start: {
            line: 187,
            column: 6
          },
          end: {
            line: 193,
            column: 7
          }
        }],
        line: 187
      },
      '14': {
        loc: {
          start: {
            line: 187,
            column: 10
          },
          end: {
            line: 187,
            column: 33
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 187,
            column: 10
          },
          end: {
            line: 187,
            column: 19
          }
        }, {
          start: {
            line: 187,
            column: 23
          },
          end: {
            line: 187,
            column: 33
          }
        }],
        line: 187
      },
      '15': {
        loc: {
          start: {
            line: 194,
            column: 6
          },
          end: {
            line: 200,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 194,
            column: 6
          },
          end: {
            line: 200,
            column: 7
          }
        }, {
          start: {
            line: 194,
            column: 6
          },
          end: {
            line: 200,
            column: 7
          }
        }],
        line: 194
      },
      '16': {
        loc: {
          start: {
            line: 223,
            column: 6
          },
          end: {
            line: 232,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 223,
            column: 6
          },
          end: {
            line: 232,
            column: 7
          }
        }, {
          start: {
            line: 223,
            column: 6
          },
          end: {
            line: 232,
            column: 7
          }
        }],
        line: 223
      },
      '17': {
        loc: {
          start: {
            line: 249,
            column: 4
          },
          end: {
            line: 263,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 249,
            column: 4
          },
          end: {
            line: 263,
            column: 5
          }
        }, {
          start: {
            line: 249,
            column: 4
          },
          end: {
            line: 263,
            column: 5
          }
        }],
        line: 249
      },
      '18': {
        loc: {
          start: {
            line: 251,
            column: 6
          },
          end: {
            line: 253,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 251,
            column: 6
          },
          end: {
            line: 253,
            column: 7
          }
        }, {
          start: {
            line: 251,
            column: 6
          },
          end: {
            line: 253,
            column: 7
          }
        }],
        line: 251
      },
      '19': {
        loc: {
          start: {
            line: 254,
            column: 6
          },
          end: {
            line: 256,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 254,
            column: 6
          },
          end: {
            line: 256,
            column: 7
          }
        }, {
          start: {
            line: 254,
            column: 6
          },
          end: {
            line: 256,
            column: 7
          }
        }],
        line: 254
      },
      '20': {
        loc: {
          start: {
            line: 276,
            column: 4
          },
          end: {
            line: 339,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 276,
            column: 4
          },
          end: {
            line: 339,
            column: 5
          }
        }, {
          start: {
            line: 276,
            column: 4
          },
          end: {
            line: 339,
            column: 5
          }
        }],
        line: 276
      },
      '21': {
        loc: {
          start: {
            line: 293,
            column: 11
          },
          end: {
            line: 339,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 293,
            column: 11
          },
          end: {
            line: 339,
            column: 5
          }
        }, {
          start: {
            line: 293,
            column: 11
          },
          end: {
            line: 339,
            column: 5
          }
        }],
        line: 293
      },
      '22': {
        loc: {
          start: {
            line: 310,
            column: 8
          },
          end: {
            line: 315,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 310,
            column: 8
          },
          end: {
            line: 315,
            column: 9
          }
        }, {
          start: {
            line: 310,
            column: 8
          },
          end: {
            line: 315,
            column: 9
          }
        }],
        line: 310
      },
      '23': {
        loc: {
          start: {
            line: 326,
            column: 18
          },
          end: {
            line: 326,
            column: 48
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 326,
            column: 18
          },
          end: {
            line: 326,
            column: 43
          }
        }, {
          start: {
            line: 326,
            column: 47
          },
          end: {
            line: 326,
            column: 48
          }
        }],
        line: 326
      },
      '24': {
        loc: {
          start: {
            line: 333,
            column: 8
          },
          end: {
            line: 337,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 333,
            column: 8
          },
          end: {
            line: 337,
            column: 9
          }
        }, {
          start: {
            line: 333,
            column: 8
          },
          end: {
            line: 337,
            column: 9
          }
        }],
        line: 333
      },
      '25': {
        loc: {
          start: {
            line: 387,
            column: 6
          },
          end: {
            line: 389,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 387,
            column: 6
          },
          end: {
            line: 389,
            column: 7
          }
        }, {
          start: {
            line: 387,
            column: 6
          },
          end: {
            line: 389,
            column: 7
          }
        }],
        line: 387
      },
      '26': {
        loc: {
          start: {
            line: 402,
            column: 8
          },
          end: {
            line: 423,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 402,
            column: 8
          },
          end: {
            line: 423,
            column: 9
          }
        }, {
          start: {
            line: 402,
            column: 8
          },
          end: {
            line: 423,
            column: 9
          }
        }],
        line: 402
      },
      '27': {
        loc: {
          start: {
            line: 407,
            column: 10
          },
          end: {
            line: 415,
            column: 11
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 408,
            column: 12
          },
          end: {
            line: 410,
            column: 20
          }
        }, {
          start: {
            line: 412,
            column: 12
          },
          end: {
            line: 414,
            column: 20
          }
        }],
        line: 407
      },
      '28': {
        loc: {
          start: {
            line: 456,
            column: 4
          },
          end: {
            line: 458,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 456,
            column: 4
          },
          end: {
            line: 458,
            column: 5
          }
        }, {
          start: {
            line: 456,
            column: 4
          },
          end: {
            line: 458,
            column: 5
          }
        }],
        line: 456
      },
      '29': {
        loc: {
          start: {
            line: 459,
            column: 4
          },
          end: {
            line: 461,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 459,
            column: 4
          },
          end: {
            line: 461,
            column: 5
          }
        }, {
          start: {
            line: 459,
            column: 4
          },
          end: {
            line: 461,
            column: 5
          }
        }],
        line: 459
      },
      '30': {
        loc: {
          start: {
            line: 459,
            column: 9
          },
          end: {
            line: 459,
            column: 31
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 459,
            column: 9
          },
          end: {
            line: 459,
            column: 25
          }
        }, {
          start: {
            line: 459,
            column: 29
          },
          end: {
            line: 459,
            column: 31
          }
        }],
        line: 459
      },
      '31': {
        loc: {
          start: {
            line: 473,
            column: 37
          },
          end: {
            line: 473,
            column: 137
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 473,
            column: 58
          },
          end: {
            line: 473,
            column: 82
          }
        }, {
          start: {
            line: 473,
            column: 86
          },
          end: {
            line: 473,
            column: 136
          }
        }],
        line: 473
      },
      '32': {
        loc: {
          start: {
            line: 482,
            column: 4
          },
          end: {
            line: 488,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 482,
            column: 4
          },
          end: {
            line: 488,
            column: 5
          }
        }, {
          start: {
            line: 482,
            column: 4
          },
          end: {
            line: 488,
            column: 5
          }
        }],
        line: 482
      },
      '33': {
        loc: {
          start: {
            line: 483,
            column: 6
          },
          end: {
            line: 486,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 483,
            column: 6
          },
          end: {
            line: 486,
            column: 7
          }
        }, {
          start: {
            line: 483,
            column: 6
          },
          end: {
            line: 486,
            column: 7
          }
        }],
        line: 483
      },
      '34': {
        loc: {
          start: {
            line: 490,
            column: 4
          },
          end: {
            line: 495,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 490,
            column: 4
          },
          end: {
            line: 495,
            column: 5
          }
        }, {
          start: {
            line: 490,
            column: 4
          },
          end: {
            line: 495,
            column: 5
          }
        }],
        line: 490
      },
      '35': {
        loc: {
          start: {
            line: 502,
            column: 6
          },
          end: {
            line: 504,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 502,
            column: 6
          },
          end: {
            line: 504,
            column: 7
          }
        }, {
          start: {
            line: 502,
            column: 6
          },
          end: {
            line: 504,
            column: 7
          }
        }],
        line: 502
      },
      '36': {
        loc: {
          start: {
            line: 505,
            column: 6
          },
          end: {
            line: 527,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 505,
            column: 6
          },
          end: {
            line: 527,
            column: 7
          }
        }, {
          start: {
            line: 505,
            column: 6
          },
          end: {
            line: 527,
            column: 7
          }
        }],
        line: 505
      },
      '37': {
        loc: {
          start: {
            line: 507,
            column: 8
          },
          end: {
            line: 521,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 507,
            column: 8
          },
          end: {
            line: 521,
            column: 9
          }
        }, {
          start: {
            line: 507,
            column: 8
          },
          end: {
            line: 521,
            column: 9
          }
        }],
        line: 507
      },
      '38': {
        loc: {
          start: {
            line: 513,
            column: 15
          },
          end: {
            line: 521,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 513,
            column: 15
          },
          end: {
            line: 521,
            column: 9
          }
        }, {
          start: {
            line: 513,
            column: 15
          },
          end: {
            line: 521,
            column: 9
          }
        }],
        line: 513
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0,
      '248': 0,
      '249': 0,
      '250': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2cenf8p23p.s[0]++;
/**
* Geographic Map - controller
*/
angular.module('app').controller('logical_planCtrl', function ($scope, SweetAlert, $deltaLogLevel, $deltaPlanLogique, $LOGINService, FileUploader, $rootScope, $translate, $CRUDService, $otherHttpService, $uibModal, $filter) {
  cov_2cenf8p23p.f[0]++;
  cov_2cenf8p23p.s[1]++;

  $rootScope.processPageRight('1_5');
  cov_2cenf8p23p.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_2cenf8p23p.b[0][0]++;
    cov_2cenf8p23p.s[3]++;

    return;
  } else {
    cov_2cenf8p23p.b[0][1]++;
  }
  cov_2cenf8p23p.s[4]++;
  $scope.PATHNL = 'NiveauCl';
  cov_2cenf8p23p.s[5]++;
  $scope.PATHPL = 'CadreLogique';
  cov_2cenf8p23p.s[6]++;
  $scope.editLabelPlanLog = false;
  cov_2cenf8p23p.s[7]++;
  $scope.openEditPersoLabel = function (value) {
    cov_2cenf8p23p.f[1]++;
    cov_2cenf8p23p.s[8]++;

    $scope.editLabelPlanLog = value;
  };
  cov_2cenf8p23p.s[9]++;
  $scope.listDefaultStructure = [];
  cov_2cenf8p23p.s[10]++;
  $deltaLogLevel.addController($scope, function () {
    cov_2cenf8p23p.f[2]++;
    cov_2cenf8p23p.s[11]++;

    $CRUDService.getAll($scope.PATHNL, { get: 'default_structure' }, function (data) {
      cov_2cenf8p23p.f[3]++;

      var index__ = (cov_2cenf8p23p.s[12]++, 0);

      cov_2cenf8p23p.s[13]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;

          var item_s = (cov_2cenf8p23p.s[14]++, $scope.listData_LogLevels_bksb.filter(function (level) {
            cov_2cenf8p23p.f[4]++;
            cov_2cenf8p23p.s[15]++;
            return level.Niveau_CL == index__ + 1;
          }));

          cov_2cenf8p23p.s[16]++;
          if (item_s.length == 0) {
            cov_2cenf8p23p.b[1][0]++;
            cov_2cenf8p23p.s[17]++;

            $scope.listData_LogLevels_bksb.push({
              id: -(index__ + 1),
              LONG_CODE_NCL: 0,
              LIBELLE_NCL: item,
              ETAT_INDIC_NCL: 1,
              Niveau_CL: index__ + 1,
              PLAN_ACT_NCL: 0,
              ETAT_SUIVI_NCL: 0,
              ETAT_INDIC_TAUX: 0,
              ETAT_POIDS_AUTO: 0,
              TYPE_CALCUL_POIDS: 0,
              COLOR: '0,0,0'
            });
          } else {
            cov_2cenf8p23p.b[1][1]++;
          }
          cov_2cenf8p23p.s[18]++;
          index__++;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_2cenf8p23p.s[19]++;
      $scope.listData_LogLevels_bksb.sort(function (levelA, levelB) {
        cov_2cenf8p23p.f[5]++;
        cov_2cenf8p23p.s[20]++;

        if (levelA.Niveau_CL > levelB.Niveau_CL) {
          cov_2cenf8p23p.b[2][0]++;
          cov_2cenf8p23p.s[21]++;

          return 1;
        } else {
          cov_2cenf8p23p.b[2][1]++;
        }
        cov_2cenf8p23p.s[22]++;
        if (levelA.Niveau_CL == levelB.Niveau_CL) {
          cov_2cenf8p23p.b[3][0]++;
          cov_2cenf8p23p.s[23]++;

          return 0;
        } else {
          cov_2cenf8p23p.b[3][1]++;
        }
        cov_2cenf8p23p.s[24]++;
        return -1;
      });

      cov_2cenf8p23p.s[25]++;
      $scope.listDefaultStructure = data;
    });
  });
  cov_2cenf8p23p.s[26]++;
  $deltaPlanLogique.addController($scope);

  cov_2cenf8p23p.s[27]++;
  $scope.canPlanWeight = $rootScope.currentProject.project.ACTIVITY_WEIGHT == 1;

  cov_2cenf8p23p.s[28]++;
  $scope.changeCrossCutting = function () {
    cov_2cenf8p23p.f[6]++;
    cov_2cenf8p23p.s[29]++;

    $CRUDService.save('Projet', { action: 'update_cross_cutting_manage', value: $scope.actionLabel.valueCrossIndicator ? (cov_2cenf8p23p.b[4][0]++, 1) : (cov_2cenf8p23p.b[4][1]++, 0) });
  };

  cov_2cenf8p23p.s[30]++;
  $scope.PATH = 'Periode';
  var EXPAND = (cov_2cenf8p23p.s[31]++, 'fa-angle-down');
  var COLLAPSE = (cov_2cenf8p23p.s[32]++, 'fa-angle-right');
  var LEAF = (cov_2cenf8p23p.s[33]++, 'fa-circle');
  var LOADING = (cov_2cenf8p23p.s[34]++, 'fa-spinner');
  cov_2cenf8p23p.s[35]++;
  $scope.idScope = $scope.$id;
  // Champ Personnalisé
  cov_2cenf8p23p.s[36]++;
  $scope.PATHCHAMP = 'ChampsPerso';

  cov_2cenf8p23p.s[37]++;
  $scope.showNiveauLog = false;
  cov_2cenf8p23p.s[38]++;
  $scope.isloading = true;
  cov_2cenf8p23p.s[39]++;
  $scope.listLocalite = [];
  cov_2cenf8p23p.s[40]++;
  $scope.getLevelLabel = function (item) {
    cov_2cenf8p23p.f[7]++;
    cov_2cenf8p23p.s[41]++;

    if ((cov_2cenf8p23p.b[6][0]++, $CRUDService.getLang() == 'en') && (cov_2cenf8p23p.b[6][1]++, item.LIBELLE_NCL_ENG != '') && (cov_2cenf8p23p.b[6][2]++, item.LIBELLE_NCL_ENG != null)) {
      cov_2cenf8p23p.b[5][0]++;
      cov_2cenf8p23p.s[42]++;

      return item.LIBELLE_NCL_ENG;
    } else {
      cov_2cenf8p23p.b[5][1]++;
    }
    cov_2cenf8p23p.s[43]++;
    return item.LIBELLE_NCL;
  };
  cov_2cenf8p23p.s[44]++;
  $scope.hidesubPanel = function () {
    cov_2cenf8p23p.f[8]++;
    cov_2cenf8p23p.s[45]++;

    $scope.show_logicallevel = false;
    cov_2cenf8p23p.s[46]++;
    $scope.show_logicalplan = false;
  };
  cov_2cenf8p23p.s[47]++;
  $scope.getPoidsList = function () {
    cov_2cenf8p23p.f[9]++;
    cov_2cenf8p23p.s[48]++;

    $scope.listPoids = [];
    cov_2cenf8p23p.s[49]++;
    $scope.sumPoids = 0;
    cov_2cenf8p23p.s[50]++;
    $CRUDService.getAll($scope.PATHPL, { get: 'allpoids' }, function (data) {
      cov_2cenf8p23p.f[10]++;
      cov_2cenf8p23p.s[51]++;

      $scope.listPoids = data;
      cov_2cenf8p23p.s[52]++;
      $scope.sumPoids = Object.values($scope.listPoids).reduce(function (acc, valeur) {
        cov_2cenf8p23p.f[11]++;
        cov_2cenf8p23p.s[53]++;
        return acc + parseInt(valeur);
      }, 0);
      //console.log($scope.sumPoids);
    });
  };

  cov_2cenf8p23p.s[54]++;
  $scope.actionLabel = {
    ADD: $translate.instant('LOGICAL_PLAN.ADD'),
    LEVEL: $translate.instant('MENU.ST'),
    IMPORT: $translate.instant('LOGICAL_PLAN.IMPORT')
  };

  cov_2cenf8p23p.s[55]++;
  $scope.canDisplayCrossIndicator = false;

  cov_2cenf8p23p.s[56]++;
  $CRUDService.getAll('Projet', { get: 'cross_cutting_state' }, function (data) {
    cov_2cenf8p23p.f[12]++;
    cov_2cenf8p23p.s[57]++;

    // eslint-disable-next-line radix
    $scope.canDisplayCrossIndicator = data.display == 1;
    cov_2cenf8p23p.s[58]++;
    $scope.actionLabel.valueCrossIndicator = data.value == 1;
  });

  // Niveau logique
  cov_2cenf8p23p.s[59]++;
  $scope.itemSelectedNL = {};
  cov_2cenf8p23p.s[60]++;
  $scope.hideAdd = true;
  cov_2cenf8p23p.s[61]++;
  $scope.canAddNewLG = function () {
    cov_2cenf8p23p.f[13]++;
    cov_2cenf8p23p.s[62]++;

    $otherHttpService.post($scope.PATHNL, { action: 'canAddNew' }, function (data) {
      cov_2cenf8p23p.f[14]++;
      cov_2cenf8p23p.s[63]++;

      $scope.hideAdd = data != 0;
    });
  };
  cov_2cenf8p23p.s[64]++;
  $scope.showNiveauLog = function (value) {
    cov_2cenf8p23p.f[15]++;
    cov_2cenf8p23p.s[65]++;

    $scope.show_logicallevel = angular.isUndefined(value) ? (cov_2cenf8p23p.b[7][0]++, !$scope.show_logicallevel) : (cov_2cenf8p23p.b[7][1]++, value);
    cov_2cenf8p23p.s[66]++;
    $scope.show_logicalplan = false;
    cov_2cenf8p23p.s[67]++;
    $scope.cancelNiveauLog();
  };
  cov_2cenf8p23p.s[68]++;
  $scope.editNiveauLog = function (item) {
    cov_2cenf8p23p.f[16]++;
    cov_2cenf8p23p.s[69]++;

    item.hideEditor = false;
    cov_2cenf8p23p.s[70]++;
    $scope.beginEditNL = true;
    cov_2cenf8p23p.s[71]++;
    $scope.itemSelectedNL = angular.copy(item);
  };
  cov_2cenf8p23p.s[72]++;
  $scope.editnewNiveauLog = function () {
    cov_2cenf8p23p.f[17]++;
    cov_2cenf8p23p.s[73]++;

    $scope.editNiveauLog({
      id: 0,
      COLOR: '0,0,0',
      LONG_CODE_NCL: 1,
      ID_PROJET: $rootScope.currentProject.project.id,
      Niveau_CL: $scope.listData_LogLevels_bksb.length + 1,
      ETAT_INDIC_NCL: true,
      TYPE_CALCUL_POIDS: 0,
      ETAT_POIDS_AUTO: false
    });
  };
  cov_2cenf8p23p.s[74]++;
  $scope.cancelNiveauLog = function () {
    cov_2cenf8p23p.f[18]++;
    cov_2cenf8p23p.s[75]++;

    $scope.beginEditNL = false;
    cov_2cenf8p23p.s[76]++;
    $scope.itemSelectedNL = null;
  };
  cov_2cenf8p23p.s[77]++;
  $scope.deleteNiveauLog = function (item) {
    cov_2cenf8p23p.f[19]++;
    cov_2cenf8p23p.s[78]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("LOGICAL_PLAN.CONFIRM_DELETE_LEVEL"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_2cenf8p23p.f[20]++;
      cov_2cenf8p23p.s[79]++;

      if (isconfirm) {
        cov_2cenf8p23p.b[8][0]++;
        cov_2cenf8p23p.s[80]++;

        return;
      } else {
        cov_2cenf8p23p.b[8][1]++;
      }
      cov_2cenf8p23p.s[81]++;
      $scope.isloadingNL_bksb = true;
      cov_2cenf8p23p.s[82]++;
      $CRUDService.delet($scope.PATHNL, { action: 'supp', valeur: $filter('json')(item) }, function (data) {
        cov_2cenf8p23p.f[21]++;
        cov_2cenf8p23p.s[83]++;

        $scope.isloadingNL_bksb = false;
        cov_2cenf8p23p.s[84]++;
        if (data < 1) {
          cov_2cenf8p23p.b[9][0]++;
          cov_2cenf8p23p.s[85]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_2cenf8p23p.s[86]++;
          return;
        } else {
          cov_2cenf8p23p.b[9][1]++;
        }
        cov_2cenf8p23p.s[87]++;
        $scope.loadingLogLevelData_bksb();
      });
    });
  };
  cov_2cenf8p23p.s[88]++;
  $scope.validateItemNL = function (item) {
    cov_2cenf8p23p.f[22]++;

    var itemp = (cov_2cenf8p23p.s[89]++, angular.copy(item));
    cov_2cenf8p23p.s[90]++;
    $scope.isloadingNL_bksb = true;
    cov_2cenf8p23p.s[91]++;
    itemp.PLAN_ACT_NCL = 0;
    cov_2cenf8p23p.s[92]++;
    itemp.ETAT_RISQUE_NCL = 0;
    cov_2cenf8p23p.s[93]++;
    itemp.ETAT_INDIC_TAUX = (cov_2cenf8p23p.b[11][0]++, itemp.ETAT_INDIC_TAUX) && (cov_2cenf8p23p.b[11][1]++, itemp.ETAT_INDIC_NCL) ? (cov_2cenf8p23p.b[10][0]++, 1) : (cov_2cenf8p23p.b[10][1]++, 0);
    cov_2cenf8p23p.s[94]++;
    itemp.ETAT_INDIC_NCL = 1; // itemp.ETAT_INDIC_NCL ? 1 : 0;
    cov_2cenf8p23p.s[95]++;
    itemp.ETAT_POIDS_AUTO = itemp.ETAT_POIDS_AUTO ? (cov_2cenf8p23p.b[12][0]++, 1) : (cov_2cenf8p23p.b[12][1]++, 0);
    cov_2cenf8p23p.s[96]++;
    $scope.nivclError = {
      label: '',
      id: null
    };
    cov_2cenf8p23p.s[97]++;
    $CRUDService.save($scope.PATHNL, { action: 'miseAJour', valeur: $filter('json')(itemp) }, function (data) {
      cov_2cenf8p23p.f[23]++;
      cov_2cenf8p23p.s[98]++;

      $scope.isloadingNL_bksb = false;
      cov_2cenf8p23p.s[99]++;
      if ((cov_2cenf8p23p.b[14][0]++, data == 0) || (cov_2cenf8p23p.b[14][1]++, data == -2)) {
        cov_2cenf8p23p.b[13][0]++;
        cov_2cenf8p23p.s[100]++;

        $scope.nivclError = {
          label: $translate.instant('LOGICAL_PLAN.ERROR_UPDATE_LONG'),
          id: 1
        };
        cov_2cenf8p23p.s[101]++;
        return;
      } else {
        cov_2cenf8p23p.b[13][1]++;
      }
      cov_2cenf8p23p.s[102]++;
      if (data < 1) {
        cov_2cenf8p23p.b[15][0]++;
        cov_2cenf8p23p.s[103]++;

        $scope.nivclError = {
          label: $translate.instant('COMMON.TITLE_ERROR_ADD'),
          id: 1
        };
        cov_2cenf8p23p.s[104]++;
        return;
      } else {
        cov_2cenf8p23p.b[15][1]++;
      }
      cov_2cenf8p23p.s[105]++;
      $scope.cancelNiveauLog();
      cov_2cenf8p23p.s[106]++;
      $scope.loadingLogLevelData_bksb();
      cov_2cenf8p23p.s[107]++;
      $scope.getPoidsList();
    });
  };
  cov_2cenf8p23p.s[108]++;
  $scope.selectItemNL = function (item) {
    cov_2cenf8p23p.f[24]++;
    cov_2cenf8p23p.s[109]++;

    $scope.itemSelectedNL = angular.copy(item);
  };
  cov_2cenf8p23p.s[110]++;
  $scope.openLogStructure = function () {
    cov_2cenf8p23p.f[25]++;
    cov_2cenf8p23p.s[111]++;

    $scope.cancelNiveauLog();
    cov_2cenf8p23p.s[112]++;
    $uibModal.open({
      templateUrl: 'app/views/logical_plan/editLevel.html',
      controller: logical_levelCtrl,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };
  cov_2cenf8p23p.s[113]++;
  $scope.updateByFile = function (l) {
    cov_2cenf8p23p.f[26]++;
    cov_2cenf8p23p.s[114]++;

    $scope.loadingUploadLevel = l;
    cov_2cenf8p23p.s[115]++;
    $CRUDService.save($scope.PATHPL, { action: 'updateByFile', l: l }, function (data) {
      cov_2cenf8p23p.f[27]++;
      cov_2cenf8p23p.s[116]++;

      if (data == 0) {
        cov_2cenf8p23p.b[16][0]++;
        cov_2cenf8p23p.s[117]++;

        $deltaLogLevel.clearData();
        cov_2cenf8p23p.s[118]++;
        $deltaLogLevel.addController($scope);
        cov_2cenf8p23p.s[119]++;
        $deltaPlanLogique.removeAllData();
        cov_2cenf8p23p.s[120]++;
        $deltaPlanLogique.addController($scope);
        cov_2cenf8p23p.s[121]++;
        $scope.loadingUpload = false;
        cov_2cenf8p23p.s[122]++;
        $scope.loadingUploadLevel = 0;
      } else {
        cov_2cenf8p23p.b[16][1]++;
        cov_2cenf8p23p.s[123]++;

        $scope.updateByFile(l + 1);
      }
    });
  };
  // uploadfile
  var uploader = (cov_2cenf8p23p.s[124]++, $scope.uploader = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadcsvFile&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + $scope.PATHNL,
    alias: 'fileCVSPlanLog',
    removeAfterUpload: true
  }));

  cov_2cenf8p23p.s[125]++;
  uploader.onAfterAddingFile = function (fileItem) {
    cov_2cenf8p23p.f[28]++;
    cov_2cenf8p23p.s[126]++;

    $scope.loadingUpload = true;
    cov_2cenf8p23p.s[127]++;
    $scope.uploadProgress = 0;
    cov_2cenf8p23p.s[128]++;
    fileItem.upload();
  };
  cov_2cenf8p23p.s[129]++;
  uploader.onSuccessItem = function (fileItem, response, status, headers) {
    cov_2cenf8p23p.f[29]++;
    cov_2cenf8p23p.s[130]++;

    if (response.f == '') {
      cov_2cenf8p23p.b[17][0]++;

      var labelError = (cov_2cenf8p23p.s[131]++, 'LOGICAL_PLAN.ERROR_STRUC');
      cov_2cenf8p23p.s[132]++;
      if (response.s == 1) {
        cov_2cenf8p23p.b[18][0]++;
        cov_2cenf8p23p.s[133]++;

        labelError = 'LOGICAL_PLAN.ERROR_FORMAT';
      } else {
        cov_2cenf8p23p.b[18][1]++;
      }
      cov_2cenf8p23p.s[134]++;
      if (response.s == 2) {
        cov_2cenf8p23p.b[19][0]++;
        cov_2cenf8p23p.s[135]++;

        labelError = 'LOGICAL_PLAN.ERROR_CANADD';
      } else {
        cov_2cenf8p23p.b[19][1]++;
      }
      cov_2cenf8p23p.s[136]++;
      SweetAlert.swal({
        title: $translate.instant('COMMON.ERROR_'),
        text: $translate.instant(labelError)
      });
      cov_2cenf8p23p.s[137]++;
      $scope.loadingUpload = false;
      cov_2cenf8p23p.s[138]++;
      return;
    } else {
      cov_2cenf8p23p.b[17][1]++;
    }
    cov_2cenf8p23p.s[139]++;
    $CRUDService.save($scope.PATHNL, { action: 'miseAJourNclFile', lang: $LOGINService.getLang() }, function (data) {
      cov_2cenf8p23p.f[30]++;
      cov_2cenf8p23p.s[140]++;

      $scope.updateByFile(1);
    });
  };
  cov_2cenf8p23p.s[141]++;
  uploader.onErrorItem = function (fileItem, response, status, headers) {
    cov_2cenf8p23p.f[31]++;
  };
  // Plan Logique
  cov_2cenf8p23p.s[142]++;
  $scope.planItem = {};
  cov_2cenf8p23p.s[143]++;
  $scope.edit = function (item, fils) {
    cov_2cenf8p23p.f[32]++;

    var el = (cov_2cenf8p23p.s[144]++, {});
    cov_2cenf8p23p.s[145]++;
    $scope.hidesubPanel();
    cov_2cenf8p23p.s[146]++;
    $scope.show_logicalplan = true;
    cov_2cenf8p23p.s[147]++;
    if (!item) {
      cov_2cenf8p23p.b[20][0]++;
      cov_2cenf8p23p.s[148]++;
      // Ajouter first level
      el = {
        LIBELLE_L_CL: '',
        LIBELLE_C_CL: '',
        LIBELLE_L_CL_EN: '',
        LIBELLE_C_CL_EN: '',
        CODE_CLCAFF: '',
        CODE_CLC: '',
        FIXE: '',
        POIDS_CL: 1,
        NC: $scope.listData_LogLevels_bksb[0],
        CODE_CLP: null,
        CODE_NCL: $scope.listData_LogLevels_bksb[0].id,
        expanded: false,
        id: 0,
        leaf: $scope.listData_LogLevels_bksb.length == 1
      };
    } else {
        cov_2cenf8p23p.b[20][1]++;
        cov_2cenf8p23p.s[149]++;
        if (fils) {
          cov_2cenf8p23p.b[21][0]++;
          cov_2cenf8p23p.s[150]++;
          // add fils
          el = {
            LIBELLE_L_CL_EN: '',
            LIBELLE_C_CL_EN: '',
            LIBELLE_L_CL: '',
            CODE_CLCAFF: '',
            CODE_CLC: '',
            FIXE: item.CODE_CLC,
            POIDS_CL: 1,
            LIBELLE_C_CL: '',
            NC: null,
            CODE_CLP: item.id,
            CODE_NCL: null,
            expanded: false,
            id: 0
          };
          cov_2cenf8p23p.s[151]++;
          for (var i = 0; i < $scope.listData_LogLevels_bksb.length; i++) {
            cov_2cenf8p23p.s[152]++;

            if ($scope.listData_LogLevels_bksb[i].id == item.CODE_NCL) {
              cov_2cenf8p23p.b[22][0]++;
              cov_2cenf8p23p.s[153]++;

              el.CODE_NCL = $scope.listData_LogLevels_bksb[i + 1].id;
              cov_2cenf8p23p.s[154]++;
              el.NC = $scope.listData_LogLevels_bksb[i + 1];
              cov_2cenf8p23p.s[155]++;
              el.leaf = i + 2 == $scope.listData_LogLevels_bksb.length;
              cov_2cenf8p23p.s[156]++;
              break;
            } else {
              cov_2cenf8p23p.b[22][1]++;
            }
          }
        } else {
          cov_2cenf8p23p.b[21][1]++;
          cov_2cenf8p23p.s[157]++;
          // Update
          el = {
            FIXE: '',
            LIBELLE_L_CL: item.LIBELLE_L_CL,
            LIBELLE_C_CL_EN: item.LIBELLE_C_CL_EN,
            LIBELLE_L_CL_EN: item.LIBELLE_L_CL_EN,
            LIBELLE_C_CL: item.LIBELLE_C_CL,
            CODE_CLCAFF: item.CODE_CLCAFF,
            CODE_CLC: item.CODE_CLC,
            POIDS_CL: (cov_2cenf8p23p.b[23][0]++, parseFloat(item.POIDS_CL)) || (cov_2cenf8p23p.b[23][1]++, 1),
            CODE_CLP: item.CODE_CLP,
            CODE_NCL: item.CODE_NCL,
            expanded: item.expanded,
            id: item.id
          };
          cov_2cenf8p23p.s[158]++;
          for (var _i = 0; _i < $scope.listData_LogLevels_bksb.length; _i++) {
            cov_2cenf8p23p.s[159]++;

            if ($scope.listData_LogLevels_bksb[_i].id == item.CODE_NCL) {
              cov_2cenf8p23p.b[24][0]++;
              cov_2cenf8p23p.s[160]++;

              el.NC = $scope.listData_LogLevels_bksb[_i];
              cov_2cenf8p23p.s[161]++;
              el.leaf = _i + 1 == $scope.listData_LogLevels_bksb.length;
              cov_2cenf8p23p.s[162]++;
              break;
            } else {
              cov_2cenf8p23p.b[24][1]++;
            }
          }
        }
      }cov_2cenf8p23p.s[163]++;
    $scope.editParam = {
      value: el.NC.LONG_CODE_NCL
    };
    cov_2cenf8p23p.s[164]++;
    $scope.planItem = el;

    cov_2cenf8p23p.s[165]++;
    $uibModal.open({
      templateUrl: 'app/views/logical_plan/edit.html',
      controller: logical_planCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  function logical_planCtrl_notification($scope, $uibModalInstance, $filter, $CRUDService, SweetAlert, $translate) {
    cov_2cenf8p23p.f[33]++;
    cov_2cenf8p23p.s[166]++;


    $scope.closeModal = function () {
      cov_2cenf8p23p.f[34]++;
      cov_2cenf8p23p.s[167]++;

      $uibModalInstance.dismiss('cancel');
    };
  }

  cov_2cenf8p23p.s[168]++;
  $scope.openNotification = function () {
    cov_2cenf8p23p.f[35]++;
    cov_2cenf8p23p.s[169]++;

    $uibModal.open({
      templateUrl: 'app/views/logical_plan/notificationWeight.html',
      controller: logical_planCtrl_notification,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_2cenf8p23p.s[170]++;
  $scope.deleteItem = function (item) {
    cov_2cenf8p23p.f[36]++;

    var lasticone = (cov_2cenf8p23p.s[171]++, item.icon);
    cov_2cenf8p23p.s[172]++;
    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("LOGICAL_PLAN.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_2cenf8p23p.f[37]++;
      cov_2cenf8p23p.s[173]++;

      if (isconfirm) {
        cov_2cenf8p23p.b[25][0]++;
        cov_2cenf8p23p.s[174]++;

        return;
      } else {
        cov_2cenf8p23p.b[25][1]++;
      }
      cov_2cenf8p23p.s[175]++;
      item.icon = LOADING;
      var validateItem = (cov_2cenf8p23p.s[176]++, {
        CODE_CLCAFF: item.CODE_CLCAFF,
        CODE_CLC: item.CODE_CLC,
        CODE_CLP: item.CODE_CLP,
        CODE_NCL: item.CODE_NCL,
        id: item.id
      });

      cov_2cenf8p23p.s[177]++;
      $CRUDService.delet($scope.PATHPL, { action: 'supp_partial', id: validateItem.id, valeur: $filter('json')(validateItem) }, function (data) {
        cov_2cenf8p23p.f[38]++;
        cov_2cenf8p23p.s[178]++;

        item.icon = lasticone;
        cov_2cenf8p23p.s[179]++;
        data = parseInt(data, 10);
        cov_2cenf8p23p.s[180]++;
        if (data < 0) {
          cov_2cenf8p23p.b[26][0]++;
          cov_2cenf8p23p.s[181]++;

          $scope.isloading = false;

          var msg = (cov_2cenf8p23p.s[182]++, $translate.instant('LOGICAL_PLAN.ERROR.DELETE'));

          cov_2cenf8p23p.s[183]++;
          switch (data) {
            case -2:
              cov_2cenf8p23p.b[27][0]++;
              cov_2cenf8p23p.s[184]++;

              msg = $translate.instant('LOGICAL_PLAN.ERROR.DELETE_ACTIVITY');
              cov_2cenf8p23p.s[185]++;
              break;

            case -3:
              cov_2cenf8p23p.b[27][1]++;
              cov_2cenf8p23p.s[186]++;

              msg = $translate.instant('LOGICAL_PLAN.ERROR.DELETE_INDICATOR');
              cov_2cenf8p23p.s[187]++;
              break;
          }

          cov_2cenf8p23p.s[188]++;
          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: msg
          });

          cov_2cenf8p23p.s[189]++;
          return;
        } else {
          cov_2cenf8p23p.b[26][1]++;
        }
        cov_2cenf8p23p.s[190]++;
        $scope.getPoidsList();
        cov_2cenf8p23p.s[191]++;
        $deltaPlanLogique.removeData(item);
      });
    });
  };
  cov_2cenf8p23p.s[192]++;
  $scope.cancelItem = function () {
    cov_2cenf8p23p.f[39]++;
    cov_2cenf8p23p.s[193]++;

    $scope.planItem = {};
    cov_2cenf8p23p.s[194]++;
    $scope.show_logicalplan = false;
  };

  cov_2cenf8p23p.s[195]++;
  $scope.printData = function (label) {
    cov_2cenf8p23p.f[40]++;
    cov_2cenf8p23p.s[196]++;

    $rootScope.printReport({
      link: $scope.PATHPL,
      params: { get: 'print' },
      title: label
    });
  };

  cov_2cenf8p23p.s[197]++;
  $scope.canAddNewLG();
  cov_2cenf8p23p.s[198]++;
  $scope.getPoidsList();
});

function logical_planCtrl_edit($scope, $uibModalInstance, $filter, $log, $CRUDService, $translate, $deltaPlanLogique) {
  cov_2cenf8p23p.f[41]++;
  cov_2cenf8p23p.s[199]++;

  $scope.closeModal = function () {
    cov_2cenf8p23p.f[42]++;
    cov_2cenf8p23p.s[200]++;

    $scope.cancelItem();
    cov_2cenf8p23p.s[201]++;
    $uibModalInstance.dismiss('cancel');
  };
  cov_2cenf8p23p.s[202]++;
  $scope.getCodeError = function (item) {
    cov_2cenf8p23p.f[43]++;
    cov_2cenf8p23p.s[203]++;

    return $translate.instant('COMMON.ERROR.CODE_ERROR', $scope.editParam);
  };
  cov_2cenf8p23p.s[204]++;
  $scope.checkCode = function () {
    cov_2cenf8p23p.f[44]++;

    var item = (cov_2cenf8p23p.s[205]++, $scope.planItem);
    cov_2cenf8p23p.s[206]++;
    if (item.id != 0) {
      cov_2cenf8p23p.b[28][0]++;
      cov_2cenf8p23p.s[207]++;

      return true;
    } else {
      cov_2cenf8p23p.b[28][1]++;
    }
    cov_2cenf8p23p.s[208]++;
    if (((cov_2cenf8p23p.b[30][0]++, item.CODE_CLCAFF) || (cov_2cenf8p23p.b[30][1]++, '')).length == $scope.editParam.value) {
      cov_2cenf8p23p.b[29][0]++;
      cov_2cenf8p23p.s[209]++;

      return true;
    } else {
      cov_2cenf8p23p.b[29][1]++;
    }
    cov_2cenf8p23p.s[210]++;
    return false;
  };
  cov_2cenf8p23p.s[211]++;
  $scope.validateItem = function (right_) {
    cov_2cenf8p23p.f[45]++;
    cov_2cenf8p23p.s[212]++;

    $scope.validationError = false;
    var p = (cov_2cenf8p23p.s[213]++, {
      id: $scope.planItem.id,
      POIDS_CL: $scope.planItem.POIDS_CL,
      LIBELLE_L_CL: $scope.planItem.LIBELLE_L_CL,
      LIBELLE_C_CL: $scope.planItem.LIBELLE_C_CL,
      LIBELLE_L_CL_EN: $scope.planItem.LIBELLE_L_CL_EN,
      LIBELLE_C_CL_EN: $scope.planItem.LIBELLE_C_CL_EN,
      CODE_CLC: $filter('uppercase')($scope.planItem.id ? (cov_2cenf8p23p.b[31][0]++, $scope.planItem.CODE_CLC) : (cov_2cenf8p23p.b[31][1]++, $scope.planItem.FIXE + $scope.planItem.CODE_CLCAFF)),
      CODE_CLP: $scope.planItem.CODE_CLP,
      CODE_NCL: $scope.planItem.CODE_NCL,
      CODE_CLCAFF: $filter('uppercase')($scope.planItem.CODE_CLCAFF),
      leaf: $scope.planItem.leaf,
      expanded: false
    });
    cov_2cenf8p23p.s[214]++;
    $scope.errorPL = false;
    // find doublons
    cov_2cenf8p23p.s[215]++;
    if (p.id == 0) {
      cov_2cenf8p23p.b[32][0]++;
      cov_2cenf8p23p.s[216]++;

      if ($filter('filter')($scope.listData_pl_bksb, { CODE_CLC: p.CODE_CLC }, true)[0]) {
        cov_2cenf8p23p.b[33][0]++;
        cov_2cenf8p23p.s[217]++;

        $scope.errorPL = true;
        cov_2cenf8p23p.s[218]++;
        return;
      } else {
        cov_2cenf8p23p.b[33][1]++;
      }
      cov_2cenf8p23p.s[219]++;
      p.ID_PROJET = $scope.planItem.NC.ID_PROJET;
    } else {
      cov_2cenf8p23p.b[32][1]++;
    }

    cov_2cenf8p23p.s[220]++;
    if ($scope.sumPoids >= 100) {
      cov_2cenf8p23p.b[34][0]++;
      cov_2cenf8p23p.s[221]++;

      console.log($scope.sumPoids);
      cov_2cenf8p23p.s[222]++;
      $scope.validationError = true;
      cov_2cenf8p23p.s[223]++;
      return;
    } else {
      cov_2cenf8p23p.b[34][1]++;
    }

    var oldid = (cov_2cenf8p23p.s[224]++, p.id);
    cov_2cenf8p23p.s[225]++;
    $CRUDService.save($scope.PATHPL, { action: 'miseAJour', valeur: $filter('json')(p) }, function (data) {
      cov_2cenf8p23p.f[46]++;
      cov_2cenf8p23p.s[226]++;

      /* if (data == -4) {
       } */
      if (data < 0) {
        cov_2cenf8p23p.b[35][0]++;
        cov_2cenf8p23p.s[227]++;

        return;
      } else {
        cov_2cenf8p23p.b[35][1]++;
      }
      cov_2cenf8p23p.s[228]++;
      if (oldid == 0) {
        cov_2cenf8p23p.b[36][0]++;

        var parent = (cov_2cenf8p23p.s[229]++, $filter('filter')($scope.listData_pl_bksb, { id: p.CODE_CLP }, true)[0]);
        cov_2cenf8p23p.s[230]++;
        if (!parent) {
          cov_2cenf8p23p.b[37][0]++;
          cov_2cenf8p23p.s[231]++;

          p.id = parseInt(data);
          cov_2cenf8p23p.s[232]++;
          p.view = right_.view;
          cov_2cenf8p23p.s[233]++;
          p.update = right_.update;
          cov_2cenf8p23p.s[234]++;
          p.delete = right_.delete;
          cov_2cenf8p23p.s[235]++;
          $deltaPlanLogique.addData(p, $scope.planItem.NC.Niveau_CL, $scope.planItem.id != 0);
        } else {
            cov_2cenf8p23p.b[37][1]++;
            cov_2cenf8p23p.s[236]++;
            if (parent.isLoaded == true) {
              cov_2cenf8p23p.b[38][0]++;
              cov_2cenf8p23p.s[237]++;
              // Ajouter à la suite
              p.id = parseInt(data);
              cov_2cenf8p23p.s[238]++;
              p.view = right_.view;
              cov_2cenf8p23p.s[239]++;
              p.update = right_.update;
              cov_2cenf8p23p.s[240]++;
              p.delete = right_.delete;
              cov_2cenf8p23p.s[241]++;
              $deltaPlanLogique.addData(p, $scope.planItem.NC.Niveau_CL);
            } else {
              cov_2cenf8p23p.b[38][1]++;
              cov_2cenf8p23p.s[242]++;

              $scope.loadingPLData_bksb(parent);
            }
          }
      } else {
        cov_2cenf8p23p.b[36][1]++;
        cov_2cenf8p23p.s[243]++;

        p.view = right_.view;
        cov_2cenf8p23p.s[244]++;
        p.update = right_.update;
        cov_2cenf8p23p.s[245]++;
        p.delete = right_.delete;
        cov_2cenf8p23p.s[246]++;
        $deltaPlanLogique.addData(p, $scope.planItem.NC.Niveau_CL, $scope.planItem.id != 0);
      }
      cov_2cenf8p23p.s[247]++;
      $scope.getPoidsList();
      cov_2cenf8p23p.s[248]++;
      $scope.closeModal();
    });
  };
}
function logical_levelCtrl($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_2cenf8p23p.f[47]++;
  cov_2cenf8p23p.s[249]++;

  $scope.LogLevel = {
    closeModal: function closeModal() {
      cov_2cenf8p23p.s[250]++;

      $uibModalInstance.dismiss('cancel');
    }
  };
}