'use strict';

var cov_utvoxt5ma = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/email-files-upload.js',
      hash = '1be9e76bd9764e1bf702e60aa8221136f852d973',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/email-files-upload.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 17,
          column: 7
        }
      },
      '1': {
        start: {
          line: 5,
          column: 6
        },
        end: {
          line: 16,
          column: 8
        }
      },
      '2': {
        start: {
          line: 9,
          column: 32
        },
        end: {
          line: 9,
          column: 65
        }
      },
      '3': {
        start: {
          line: 10,
          column: 10
        },
        end: {
          line: 10,
          column: 48
        }
      },
      '4': {
        start: {
          line: 11,
          column: 10
        },
        end: {
          line: 13,
          column: 13
        }
      },
      '5': {
        start: {
          line: 12,
          column: 12
        },
        end: {
          line: 12,
          column: 26
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 33
          },
          end: {
            line: 4,
            column: 34
          }
        },
        loc: {
          start: {
            line: 4,
            column: 44
          },
          end: {
            line: 17,
            column: 5
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 8,
            column: 14
          },
          end: {
            line: 8,
            column: 15
          }
        },
        loc: {
          start: {
            line: 8,
            column: 47
          },
          end: {
            line: 15,
            column: 9
          }
        },
        line: 8
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 11,
            column: 33
          },
          end: {
            line: 11,
            column: 34
          }
        },
        loc: {
          start: {
            line: 11,
            column: 44
          },
          end: {
            line: 13,
            column: 11
          }
        },
        line: 11
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_utvoxt5ma.s[0]++;

angular.module('app').directive('customOnChange', function () {
  cov_utvoxt5ma.f[0]++;
  cov_utvoxt5ma.s[1]++;

  return {
    //require:"ngModel",
    restrict: 'A',
    link: function link(scope, element, attrs) {
      cov_utvoxt5ma.f[1]++;

      var onChangeHandler = (cov_utvoxt5ma.s[2]++, scope.$eval(attrs.customOnChange));
      cov_utvoxt5ma.s[3]++;
      element.on('change', onChangeHandler);
      cov_utvoxt5ma.s[4]++;
      element.on('$destroy', function () {
        cov_utvoxt5ma.f[2]++;
        cov_utvoxt5ma.s[5]++;

        element.off();
      });
    }
  };
});