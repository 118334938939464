'use strict';

var cov_wgndlgphh = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/communities_chat/chat.js',
      hash = '13ea470ee663610d7f0ece693b7ccc30a5af69a7',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/communities_chat/chat.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 78,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 15
        },
        end: {
          line: 3,
          column: 32
        }
      },
      '2': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 6,
          column: 30
        }
      },
      '3': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 15,
          column: 3
        }
      },
      '4': {
        start: {
          line: 10,
          column: 4
        },
        end: {
          line: 14,
          column: 6
        }
      },
      '5': {
        start: {
          line: 16,
          column: 16
        },
        end: {
          line: 16,
          column: 48
        }
      },
      '6': {
        start: {
          line: 17,
          column: 21
        },
        end: {
          line: 17,
          column: 58
        }
      },
      '7': {
        start: {
          line: 19,
          column: 2
        },
        end: {
          line: 21,
          column: 4
        }
      },
      '8': {
        start: {
          line: 23,
          column: 2
        },
        end: {
          line: 23,
          column: 70
        }
      },
      '9': {
        start: {
          line: 25,
          column: 2
        },
        end: {
          line: 25,
          column: 34
        }
      },
      '10': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 44,
          column: 4
        }
      },
      '11': {
        start: {
          line: 28,
          column: 23
        },
        end: {
          line: 33,
          column: 5
        }
      },
      '12': {
        start: {
          line: 35,
          column: 4
        },
        end: {
          line: 38,
          column: 5
        }
      },
      '13': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 36,
          column: 37
        }
      },
      '14': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 37,
          column: 13
        }
      },
      '15': {
        start: {
          line: 40,
          column: 4
        },
        end: {
          line: 43,
          column: 7
        }
      },
      '16': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 41,
          column: 37
        }
      },
      '17': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 42,
          column: 38
        }
      },
      '18': {
        start: {
          line: 45,
          column: 2
        },
        end: {
          line: 50,
          column: 4
        }
      },
      '19': {
        start: {
          line: 46,
          column: 4
        },
        end: {
          line: 49,
          column: 5
        }
      },
      '20': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 47,
          column: 30
        }
      },
      '21': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 48,
          column: 28
        }
      },
      '22': {
        start: {
          line: 52,
          column: 2
        },
        end: {
          line: 70,
          column: 4
        }
      },
      '23': {
        start: {
          line: 53,
          column: 4
        },
        end: {
          line: 69,
          column: 7
        }
      },
      '24': {
        start: {
          line: 54,
          column: 6
        },
        end: {
          line: 64,
          column: 9
        }
      },
      '25': {
        start: {
          line: 55,
          column: 26
        },
        end: {
          line: 55,
          column: 80
        }
      },
      '26': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 56,
          column: 61
        }
      },
      '27': {
        start: {
          line: 57,
          column: 8
        },
        end: {
          line: 57,
          column: 28
        }
      },
      '28': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 58,
          column: 29
        }
      },
      '29': {
        start: {
          line: 59,
          column: 8
        },
        end: {
          line: 59,
          column: 47
        }
      },
      '30': {
        start: {
          line: 60,
          column: 8
        },
        end: {
          line: 62,
          column: 9
        }
      },
      '31': {
        start: {
          line: 61,
          column: 10
        },
        end: {
          line: 61,
          column: 29
        }
      },
      '32': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 63,
          column: 19
        }
      },
      '33': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 65,
          column: 55
        }
      },
      '34': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 67,
          column: 54
        }
      },
      '35': {
        start: {
          line: 72,
          column: 2
        },
        end: {
          line: 72,
          column: 33
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 45
          },
          end: {
            line: 2,
            column: 46
          }
        },
        loc: {
          start: {
            line: 2,
            column: 282
          },
          end: {
            line: 78,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 27,
            column: 24
          },
          end: {
            line: 27,
            column: 25
          }
        },
        loc: {
          start: {
            line: 27,
            column: 36
          },
          end: {
            line: 44,
            column: 3
          }
        },
        line: 27
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 40,
            column: 88
          },
          end: {
            line: 40,
            column: 89
          }
        },
        loc: {
          start: {
            line: 40,
            column: 94
          },
          end: {
            line: 43,
            column: 5
          }
        },
        line: 40
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 45,
            column: 26
          },
          end: {
            line: 45,
            column: 27
          }
        },
        loc: {
          start: {
            line: 45,
            column: 44
          },
          end: {
            line: 50,
            column: 3
          }
        },
        line: 45
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 52,
            column: 33
          },
          end: {
            line: 52,
            column: 34
          }
        },
        loc: {
          start: {
            line: 52,
            column: 45
          },
          end: {
            line: 70,
            column: 3
          }
        },
        line: 52
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 53,
            column: 75
          },
          end: {
            line: 53,
            column: 76
          }
        },
        loc: {
          start: {
            line: 53,
            column: 83
          },
          end: {
            line: 69,
            column: 5
          }
        },
        line: 53
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 54,
            column: 40
          },
          end: {
            line: 54,
            column: 41
          }
        },
        loc: {
          start: {
            line: 54,
            column: 47
          },
          end: {
            line: 64,
            column: 7
          }
        },
        line: 54
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 9,
            column: 2
          },
          end: {
            line: 15,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 9,
            column: 2
          },
          end: {
            line: 15,
            column: 3
          }
        }, {
          start: {
            line: 9,
            column: 2
          },
          end: {
            line: 15,
            column: 3
          }
        }],
        line: 9
      },
      '1': {
        loc: {
          start: {
            line: 35,
            column: 4
          },
          end: {
            line: 38,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 35,
            column: 4
          },
          end: {
            line: 38,
            column: 5
          }
        }, {
          start: {
            line: 35,
            column: 4
          },
          end: {
            line: 38,
            column: 5
          }
        }],
        line: 35
      },
      '2': {
        loc: {
          start: {
            line: 46,
            column: 4
          },
          end: {
            line: 49,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 46,
            column: 4
          },
          end: {
            line: 49,
            column: 5
          }
        }, {
          start: {
            line: 46,
            column: 4
          },
          end: {
            line: 49,
            column: 5
          }
        }],
        line: 46
      },
      '3': {
        loc: {
          start: {
            line: 46,
            column: 8
          },
          end: {
            line: 46,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 46,
            column: 8
          },
          end: {
            line: 46,
            column: 33
          }
        }, {
          start: {
            line: 46,
            column: 37
          },
          end: {
            line: 46,
            column: 57
          }
        }],
        line: 46
      },
      '4': {
        loc: {
          start: {
            line: 60,
            column: 8
          },
          end: {
            line: 62,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 60,
            column: 8
          },
          end: {
            line: 62,
            column: 9
          }
        }, {
          start: {
            line: 60,
            column: 8
          },
          end: {
            line: 62,
            column: 9
          }
        }],
        line: 60
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_wgndlgphh.s[0]++;
/* eslint-disable angular/log */
angular.module('app').controller('chatCtrl', function ($scope, $deltaLocation, $log, $stateParams, $deltaPlanLogique, $sce, $deltahttp, $timeout, $window, $deltaUnite, $deltaActor, $rootScope, $CRUDService, $filter, $translate, $uibModal, FileUploader, SweetAlert, $deltadate, $interval) {
  cov_wgndlgphh.f[0]++;

  var PATH = (cov_wgndlgphh.s[1]++, 'CommentsHistory');

  cov_wgndlgphh.s[2]++;
  $scope.commentsHistory = [];

  // '1-Report/2-Risk/3-Incident/4-activity/5-indicateur';
  cov_wgndlgphh.s[3]++;
  if (!$rootScope.COMMENTS_HISTORY) {
    cov_wgndlgphh.b[0][0]++;
    cov_wgndlgphh.s[4]++;

    $rootScope.COMMENTS_HISTORY = {
      length: 0,
      ID_PARENT: 0,
      TYPE: 0
    };
  } else {
    cov_wgndlgphh.b[0][1]++;
  }
  var _TYPE = (cov_wgndlgphh.s[5]++, $rootScope.COMMENTS_HISTORY.TYPE);
  var _ID_PARENT = (cov_wgndlgphh.s[6]++, $rootScope.COMMENTS_HISTORY.ID_PARENT);

  cov_wgndlgphh.s[7]++;
  $scope.newComment = {
    COMMENTS: ''
  };

  cov_wgndlgphh.s[8]++;
  $scope.IV_MESSAGE = document.getElementById('direct-chat-messages');

  cov_wgndlgphh.s[9]++;
  $scope.messageFormError = false;

  cov_wgndlgphh.s[10]++;
  $scope.setValidData = function () {
    cov_wgndlgphh.f[1]++;

    var newComment = (cov_wgndlgphh.s[11]++, {
      COMMENTS: $scope.newComment.COMMENTS.trim(),
      TYPE: _TYPE,
      SEND_DATE: Date.now(),
      ID_PARENT: _ID_PARENT
    });

    cov_wgndlgphh.s[12]++;
    if (newComment.COMMENTS == '') {
      cov_wgndlgphh.b[1][0]++;
      cov_wgndlgphh.s[13]++;

      $scope.messageFormError = true;
      cov_wgndlgphh.s[14]++;
      return;
    } else {
      cov_wgndlgphh.b[1][1]++;
    }

    cov_wgndlgphh.s[15]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', valeur: $filter('json')(newComment) }, function () {
      cov_wgndlgphh.f[2]++;
      cov_wgndlgphh.s[16]++;

      $scope.getAllCommentsHistory();
      cov_wgndlgphh.s[17]++;
      $scope.newComment.COMMENTS = '';
    });
  };
  cov_wgndlgphh.s[18]++;
  $scope.keyboardTouche = function ($event) {
    cov_wgndlgphh.f[3]++;
    cov_wgndlgphh.s[19]++;

    if ((cov_wgndlgphh.b[3][0]++, $event.type == 'keypress') && (cov_wgndlgphh.b[3][1]++, $event.keyCode == 13)) {
      cov_wgndlgphh.b[2][0]++;
      cov_wgndlgphh.s[20]++;

      $event.preventDefault();
      cov_wgndlgphh.s[21]++;
      $scope.setValidData();
    } else {
      cov_wgndlgphh.b[2][1]++;
    }
  };

  cov_wgndlgphh.s[22]++;
  $scope.getAllCommentsHistory = function () {
    cov_wgndlgphh.f[4]++;
    cov_wgndlgphh.s[23]++;

    $CRUDService.getAll(PATH, { get: 'all', type: _TYPE, id_p: _ID_PARENT }, function (data) {
      cov_wgndlgphh.f[5]++;
      cov_wgndlgphh.s[24]++;

      $scope.commentsHistory = data.map(function (msg) {
        cov_wgndlgphh.f[6]++;

        var date_send = (cov_wgndlgphh.s[25]++, new Date(parseInt(msg.SEND_DATE, 10)).toLocaleString());
        cov_wgndlgphh.s[26]++;
        msg.picture = $deltahttp.getUserPicture(msg.ID_USER);
        cov_wgndlgphh.s[27]++;
        msg.forUser = false;
        cov_wgndlgphh.s[28]++;
        msg.time = date_send;
        cov_wgndlgphh.s[29]++;
        msg.times = $filter('date')(date_send);
        cov_wgndlgphh.s[30]++;
        if ($rootScope.connectedUser.id == msg.ID_USER) {
          cov_wgndlgphh.b[4][0]++;
          cov_wgndlgphh.s[31]++;

          msg.forUser = true;
        } else {
          cov_wgndlgphh.b[4][1]++;
        }
        cov_wgndlgphh.s[32]++;
        return msg;
      });
      cov_wgndlgphh.s[33]++;
      $rootScope.COMMENTS_HISTORY.length = data.length;
      // $scope.commentsHistory = $filter('orderBy')($scope.commentsHistory, 'SEND_DATE');
      cov_wgndlgphh.s[34]++;
      $scope.IV_MESSAGE.scrollTop = data.length * 200;
      // $scope.IV_MESSAGE.scrollTop = $scope.IV_MESSAGE.offsetHeight;
    });
  };

  cov_wgndlgphh.s[35]++;
  $scope.getAllCommentsHistory();
  // window.setInterval(function () {  }, 3600);

  /* var auto_msg = $interval( function(){
    $scope.getAllCommentsHistory();
  }, 36000); */
});