'use strict';

var cov_t5cqusk7i = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/report_calendar/report_calendarCtrl.js',
      hash = 'bb6555022f559fe4914d0741a067052ae5c3ef1a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/report_calendar/report_calendarCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 376,
          column: 3
        }
      },
      '1': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 37
        }
      },
      '2': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 8,
          column: 3
        }
      },
      '3': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 7,
          column: 11
        }
      },
      '4': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 18,
          column: 4
        }
      },
      '5': {
        start: {
          line: 20,
          column: 2
        },
        end: {
          line: 23,
          column: 4
        }
      },
      '6': {
        start: {
          line: 24,
          column: 20
        },
        end: {
          line: 24,
          column: 25
        }
      },
      '7': {
        start: {
          line: 25,
          column: 19
        },
        end: {
          line: 25,
          column: 30
        }
      },
      '8': {
        start: {
          line: 26,
          column: 2
        },
        end: {
          line: 26,
          column: 21
        }
      },
      '9': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 30,
          column: 4
        }
      },
      '10': {
        start: {
          line: 31,
          column: 2
        },
        end: {
          line: 45,
          column: 7
        }
      },
      '11': {
        start: {
          line: 32,
          column: 23
        },
        end: {
          line: 32,
          column: 32
        }
      },
      '12': {
        start: {
          line: 33,
          column: 4
        },
        end: {
          line: 33,
          column: 31
        }
      },
      '13': {
        start: {
          line: 34,
          column: 4
        },
        end: {
          line: 41,
          column: 5
        }
      },
      '14': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 40,
          column: 9
        }
      },
      '15': {
        start: {
          line: 42,
          column: 4
        },
        end: {
          line: 44,
          column: 7
        }
      },
      '16': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 43,
          column: 65
        }
      },
      '17': {
        start: {
          line: 47,
          column: 2
        },
        end: {
          line: 47,
          column: 39
        }
      },
      '18': {
        start: {
          line: 48,
          column: 2
        },
        end: {
          line: 48,
          column: 27
        }
      },
      '19': {
        start: {
          line: 49,
          column: 2
        },
        end: {
          line: 49,
          column: 27
        }
      },
      '20': {
        start: {
          line: 50,
          column: 2
        },
        end: {
          line: 75,
          column: 4
        }
      },
      '21': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 51,
          column: 28
        }
      },
      '22': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 74,
          column: 7
        }
      },
      '23': {
        start: {
          line: 53,
          column: 6
        },
        end: {
          line: 56,
          column: 7
        }
      },
      '24': {
        start: {
          line: 54,
          column: 8
        },
        end: {
          line: 54,
          column: 33
        }
      },
      '25': {
        start: {
          line: 55,
          column: 8
        },
        end: {
          line: 55,
          column: 15
        }
      },
      '26': {
        start: {
          line: 57,
          column: 6
        },
        end: {
          line: 61,
          column: 7
        }
      },
      '27': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 58,
          column: 33
        }
      },
      '28': {
        start: {
          line: 60,
          column: 8
        },
        end: {
          line: 60,
          column: 15
        }
      },
      '29': {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 66,
          column: 7
        }
      },
      '30': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 63,
          column: 33
        }
      },
      '31': {
        start: {
          line: 64,
          column: 8
        },
        end: {
          line: 64,
          column: 51
        }
      },
      '32': {
        start: {
          line: 65,
          column: 8
        },
        end: {
          line: 65,
          column: 15
        }
      },
      '33': {
        start: {
          line: 68,
          column: 6
        },
        end: {
          line: 73,
          column: 9
        }
      },
      '34': {
        start: {
          line: 69,
          column: 8
        },
        end: {
          line: 69,
          column: 33
        }
      },
      '35': {
        start: {
          line: 70,
          column: 8
        },
        end: {
          line: 72,
          column: 9
        }
      },
      '36': {
        start: {
          line: 71,
          column: 10
        },
        end: {
          line: 71,
          column: 74
        }
      },
      '37': {
        start: {
          line: 76,
          column: 2
        },
        end: {
          line: 76,
          column: 36
        }
      },
      '38': {
        start: {
          line: 77,
          column: 2
        },
        end: {
          line: 77,
          column: 18
        }
      },
      '39': {
        start: {
          line: 78,
          column: 21
        },
        end: {
          line: 78,
          column: 62
        }
      },
      '40': {
        start: {
          line: 80,
          column: 2
        },
        end: {
          line: 82,
          column: 5
        }
      },
      '41': {
        start: {
          line: 81,
          column: 4
        },
        end: {
          line: 81,
          column: 46
        }
      },
      '42': {
        start: {
          line: 84,
          column: 2
        },
        end: {
          line: 100,
          column: 4
        }
      },
      '43': {
        start: {
          line: 85,
          column: 15
        },
        end: {
          line: 85,
          column: 16
        }
      },
      '44': {
        start: {
          line: 86,
          column: 4
        },
        end: {
          line: 92,
          column: 5
        }
      },
      '45': {
        start: {
          line: 87,
          column: 6
        },
        end: {
          line: 91,
          column: 7
        }
      },
      '46': {
        start: {
          line: 88,
          column: 8
        },
        end: {
          line: 90,
          column: 9
        }
      },
      '47': {
        start: {
          line: 89,
          column: 10
        },
        end: {
          line: 89,
          column: 43
        }
      },
      '48': {
        start: {
          line: 93,
          column: 25
        },
        end: {
          line: 96,
          column: 5
        }
      },
      '49': {
        start: {
          line: 97,
          column: 4
        },
        end: {
          line: 99,
          column: 7
        }
      },
      '50': {
        start: {
          line: 98,
          column: 6
        },
        end: {
          line: 98,
          column: 35
        }
      },
      '51': {
        start: {
          line: 101,
          column: 2
        },
        end: {
          line: 127,
          column: 4
        }
      },
      '52': {
        start: {
          line: 102,
          column: 19
        },
        end: {
          line: 102,
          column: 56
        }
      },
      '53': {
        start: {
          line: 103,
          column: 15
        },
        end: {
          line: 103,
          column: 16
        }
      },
      '54': {
        start: {
          line: 104,
          column: 4
        },
        end: {
          line: 110,
          column: 5
        }
      },
      '55': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 109,
          column: 7
        }
      },
      '56': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 108,
          column: 9
        }
      },
      '57': {
        start: {
          line: 107,
          column: 10
        },
        end: {
          line: 107,
          column: 43
        }
      },
      '58': {
        start: {
          line: 111,
          column: 25
        },
        end: {
          line: 115,
          column: 5
        }
      },
      '59': {
        start: {
          line: 116,
          column: 4
        },
        end: {
          line: 126,
          column: 7
        }
      },
      '60': {
        start: {
          line: 117,
          column: 6
        },
        end: {
          line: 117,
          column: 30
        }
      },
      '61': {
        start: {
          line: 118,
          column: 6
        },
        end: {
          line: 123,
          column: 7
        }
      },
      '62': {
        start: {
          line: 119,
          column: 8
        },
        end: {
          line: 119,
          column: 54
        }
      },
      '63': {
        start: {
          line: 120,
          column: 8
        },
        end: {
          line: 120,
          column: 54
        }
      },
      '64': {
        start: {
          line: 121,
          column: 8
        },
        end: {
          line: 121,
          column: 54
        }
      },
      '65': {
        start: {
          line: 122,
          column: 8
        },
        end: {
          line: 122,
          column: 54
        }
      },
      '66': {
        start: {
          line: 124,
          column: 6
        },
        end: {
          line: 124,
          column: 36
        }
      },
      '67': {
        start: {
          line: 125,
          column: 6
        },
        end: {
          line: 125,
          column: 30
        }
      },
      '68': {
        start: {
          line: 129,
          column: 2
        },
        end: {
          line: 149,
          column: 4
        }
      },
      '69': {
        start: {
          line: 137,
          column: 18
        },
        end: {
          line: 137,
          column: 21
        }
      },
      '70': {
        start: {
          line: 138,
          column: 6
        },
        end: {
          line: 140,
          column: 7
        }
      },
      '71': {
        start: {
          line: 139,
          column: 8
        },
        end: {
          line: 139,
          column: 20
        }
      },
      '72': {
        start: {
          line: 141,
          column: 6
        },
        end: {
          line: 143,
          column: 7
        }
      },
      '73': {
        start: {
          line: 142,
          column: 8
        },
        end: {
          line: 142,
          column: 20
        }
      },
      '74': {
        start: {
          line: 144,
          column: 6
        },
        end: {
          line: 146,
          column: 7
        }
      },
      '75': {
        start: {
          line: 145,
          column: 8
        },
        end: {
          line: 145,
          column: 20
        }
      },
      '76': {
        start: {
          line: 147,
          column: 6
        },
        end: {
          line: 147,
          column: 45
        }
      },
      '77': {
        start: {
          line: 151,
          column: 2
        },
        end: {
          line: 196,
          column: 4
        }
      },
      '78': {
        start: {
          line: 152,
          column: 4
        },
        end: {
          line: 152,
          column: 26
        }
      },
      '79': {
        start: {
          line: 153,
          column: 4
        },
        end: {
          line: 195,
          column: 7
        }
      },
      '80': {
        start: {
          line: 154,
          column: 8
        },
        end: {
          line: 154,
          column: 32
        }
      },
      '81': {
        start: {
          line: 155,
          column: 22
        },
        end: {
          line: 155,
          column: 26
        }
      },
      '82': {
        start: {
          line: 156,
          column: 22
        },
        end: {
          line: 156,
          column: 24
        }
      },
      '83': {
        start: {
          line: 157,
          column: 22
        },
        end: {
          line: 157,
          column: 45
        }
      },
      '84': {
        start: {
          line: 158,
          column: 22
        },
        end: {
          line: 158,
          column: 45
        }
      },
      '85': {
        start: {
          line: 161,
          column: 8
        },
        end: {
          line: 173,
          column: 9
        }
      },
      '86': {
        start: {
          line: 162,
          column: 25
        },
        end: {
          line: 162,
          column: 59
        }
      },
      '87': {
        start: {
          line: 163,
          column: 12
        },
        end: {
          line: 163,
          column: 68
        }
      },
      '88': {
        start: {
          line: 164,
          column: 12
        },
        end: {
          line: 164,
          column: 32
        }
      },
      '89': {
        start: {
          line: 167,
          column: 12
        },
        end: {
          line: 169,
          column: 13
        }
      },
      '90': {
        start: {
          line: 168,
          column: 16
        },
        end: {
          line: 168,
          column: 31
        }
      },
      '91': {
        start: {
          line: 170,
          column: 12
        },
        end: {
          line: 172,
          column: 13
        }
      },
      '92': {
        start: {
          line: 171,
          column: 16
        },
        end: {
          line: 171,
          column: 31
        }
      },
      '93': {
        start: {
          line: 177,
          column: 26
        },
        end: {
          line: 177,
          column: 42
        }
      },
      '94': {
        start: {
          line: 178,
          column: 8
        },
        end: {
          line: 178,
          column: 57
        }
      },
      '95': {
        start: {
          line: 179,
          column: 8
        },
        end: {
          line: 179,
          column: 50
        }
      },
      '96': {
        start: {
          line: 180,
          column: 8
        },
        end: {
          line: 180,
          column: 50
        }
      },
      '97': {
        start: {
          line: 181,
          column: 8
        },
        end: {
          line: 181,
          column: 58
        }
      },
      '98': {
        start: {
          line: 184,
          column: 8
        },
        end: {
          line: 184,
          column: 49
        }
      },
      '99': {
        start: {
          line: 185,
          column: 8
        },
        end: {
          line: 185,
          column: 51
        }
      },
      '100': {
        start: {
          line: 187,
          column: 8
        },
        end: {
          line: 189,
          column: 9
        }
      },
      '101': {
        start: {
          line: 188,
          column: 10
        },
        end: {
          line: 188,
          column: 47
        }
      },
      '102': {
        start: {
          line: 190,
          column: 8
        },
        end: {
          line: 192,
          column: 9
        }
      },
      '103': {
        start: {
          line: 191,
          column: 10
        },
        end: {
          line: 191,
          column: 45
        }
      },
      '104': {
        start: {
          line: 193,
          column: 8
        },
        end: {
          line: 193,
          column: 33
        }
      },
      '105': {
        start: {
          line: 194,
          column: 8
        },
        end: {
          line: 194,
          column: 33
        }
      },
      '106': {
        start: {
          line: 198,
          column: 2
        },
        end: {
          line: 198,
          column: 25
        }
      },
      '107': {
        start: {
          line: 199,
          column: 2
        },
        end: {
          line: 279,
          column: 4
        }
      },
      '108': {
        start: {
          line: 200,
          column: 4
        },
        end: {
          line: 200,
          column: 24
        }
      },
      '109': {
        start: {
          line: 201,
          column: 4
        },
        end: {
          line: 201,
          column: 25
        }
      },
      '110': {
        start: {
          line: 202,
          column: 4
        },
        end: {
          line: 204,
          column: 5
        }
      },
      '111': {
        start: {
          line: 203,
          column: 6
        },
        end: {
          line: 203,
          column: 13
        }
      },
      '112': {
        start: {
          line: 205,
          column: 4
        },
        end: {
          line: 205,
          column: 28
        }
      },
      '113': {
        start: {
          line: 206,
          column: 22
        },
        end: {
          line: 206,
          column: 72
        }
      },
      '114': {
        start: {
          line: 207,
          column: 20
        },
        end: {
          line: 207,
          column: 68
        }
      },
      '115': {
        start: {
          line: 208,
          column: 4
        },
        end: {
          line: 278,
          column: 7
        }
      },
      '116': {
        start: {
          line: 209,
          column: 6
        },
        end: {
          line: 215,
          column: 7
        }
      },
      '117': {
        start: {
          line: 210,
          column: 8
        },
        end: {
          line: 214,
          column: 9
        }
      },
      '118': {
        start: {
          line: 211,
          column: 10
        },
        end: {
          line: 213,
          column: 11
        }
      },
      '119': {
        start: {
          line: 212,
          column: 12
        },
        end: {
          line: 212,
          column: 36
        }
      },
      '120': {
        start: {
          line: 217,
          column: 6
        },
        end: {
          line: 267,
          column: 7
        }
      },
      '121': {
        start: {
          line: 218,
          column: 8
        },
        end: {
          line: 266,
          column: 9
        }
      },
      '122': {
        start: {
          line: 219,
          column: 30
        },
        end: {
          line: 219,
          column: 65
        }
      },
      '123': {
        start: {
          line: 219,
          column: 44
        },
        end: {
          line: 219,
          column: 64
        }
      },
      '124': {
        start: {
          line: 220,
          column: 10
        },
        end: {
          line: 225,
          column: 13
        }
      },
      '125': {
        start: {
          line: 227,
          column: 10
        },
        end: {
          line: 260,
          column: 11
        }
      },
      '126': {
        start: {
          line: 228,
          column: 23
        },
        end: {
          line: 228,
          column: 44
        }
      },
      '127': {
        start: {
          line: 229,
          column: 12
        },
        end: {
          line: 229,
          column: 68
        }
      },
      '128': {
        start: {
          line: 230,
          column: 29
        },
        end: {
          line: 230,
          column: 64
        }
      },
      '129': {
        start: {
          line: 231,
          column: 12
        },
        end: {
          line: 233,
          column: 13
        }
      },
      '130': {
        start: {
          line: 232,
          column: 14
        },
        end: {
          line: 232,
          column: 23
        }
      },
      '131': {
        start: {
          line: 236,
          column: 29
        },
        end: {
          line: 236,
          column: 33
        }
      },
      '132': {
        start: {
          line: 237,
          column: 35
        },
        end: {
          line: 237,
          column: 39
        }
      },
      '133': {
        start: {
          line: 239,
          column: 12
        },
        end: {
          line: 245,
          column: 13
        }
      },
      '134': {
        start: {
          line: 240,
          column: 34
        },
        end: {
          line: 240,
          column: 144
        }
      },
      '135': {
        start: {
          line: 241,
          column: 14
        },
        end: {
          line: 244,
          column: 15
        }
      },
      '136': {
        start: {
          line: 242,
          column: 16
        },
        end: {
          line: 242,
          column: 50
        }
      },
      '137': {
        start: {
          line: 243,
          column: 16
        },
        end: {
          line: 243,
          column: 64
        }
      },
      '138': {
        start: {
          line: 247,
          column: 12
        },
        end: {
          line: 259,
          column: 15
        }
      },
      '139': {
        start: {
          line: 263,
          column: 10
        },
        end: {
          line: 265,
          column: 11
        }
      },
      '140': {
        start: {
          line: 263,
          column: 41
        },
        end: {
          line: 263,
          column: 52
        }
      },
      '141': {
        start: {
          line: 264,
          column: 12
        },
        end: {
          line: 264,
          column: 33
        }
      },
      '142': {
        start: {
          line: 269,
          column: 6
        },
        end: {
          line: 269,
          column: 31
        }
      },
      '143': {
        start: {
          line: 270,
          column: 6
        },
        end: {
          line: 270,
          column: 22
        }
      },
      '144': {
        start: {
          line: 272,
          column: 6
        },
        end: {
          line: 275,
          column: 7
        }
      },
      '145': {
        start: {
          line: 273,
          column: 8
        },
        end: {
          line: 273,
          column: 46
        }
      },
      '146': {
        start: {
          line: 274,
          column: 8
        },
        end: {
          line: 274,
          column: 28
        }
      },
      '147': {
        start: {
          line: 280,
          column: 2
        },
        end: {
          line: 287,
          column: 4
        }
      },
      '148': {
        start: {
          line: 281,
          column: 4
        },
        end: {
          line: 281,
          column: 34
        }
      },
      '149': {
        start: {
          line: 281,
          column: 21
        },
        end: {
          line: 281,
          column: 34
        }
      },
      '150': {
        start: {
          line: 282,
          column: 18
        },
        end: {
          line: 282,
          column: 37
        }
      },
      '151': {
        start: {
          line: 283,
          column: 16
        },
        end: {
          line: 283,
          column: 33
        }
      },
      '152': {
        start: {
          line: 284,
          column: 22
        },
        end: {
          line: 284,
          column: 42
        }
      },
      '153': {
        start: {
          line: 286,
          column: 4
        },
        end: {
          line: 286,
          column: 50
        }
      },
      '154': {
        start: {
          line: 288,
          column: 2
        },
        end: {
          line: 312,
          column: 4
        }
      },
      '155': {
        start: {
          line: 289,
          column: 4
        },
        end: {
          line: 289,
          column: 27
        }
      },
      '156': {
        start: {
          line: 290,
          column: 4
        },
        end: {
          line: 290,
          column: 24
        }
      },
      '157': {
        start: {
          line: 291,
          column: 4
        },
        end: {
          line: 291,
          column: 22
        }
      },
      '158': {
        start: {
          line: 292,
          column: 4
        },
        end: {
          line: 294,
          column: 5
        }
      },
      '159': {
        start: {
          line: 293,
          column: 6
        },
        end: {
          line: 293,
          column: 13
        }
      },
      '160': {
        start: {
          line: 295,
          column: 4
        },
        end: {
          line: 295,
          column: 48
        }
      },
      '161': {
        start: {
          line: 296,
          column: 4
        },
        end: {
          line: 296,
          column: 35
        }
      },
      '162': {
        start: {
          line: 297,
          column: 4
        },
        end: {
          line: 297,
          column: 40
        }
      },
      '163': {
        start: {
          line: 299,
          column: 4
        },
        end: {
          line: 301,
          column: 5
        }
      },
      '164': {
        start: {
          line: 300,
          column: 6
        },
        end: {
          line: 300,
          column: 51
        }
      },
      '165': {
        start: {
          line: 303,
          column: 4
        },
        end: {
          line: 310,
          column: 7
        }
      },
      '166': {
        start: {
          line: 311,
          column: 4
        },
        end: {
          line: 311,
          column: 31
        }
      },
      '167': {
        start: {
          line: 313,
          column: 2
        },
        end: {
          line: 317,
          column: 4
        }
      },
      '168': {
        start: {
          line: 314,
          column: 4
        },
        end: {
          line: 314,
          column: 32
        }
      },
      '169': {
        start: {
          line: 315,
          column: 4
        },
        end: {
          line: 315,
          column: 32
        }
      },
      '170': {
        start: {
          line: 316,
          column: 4
        },
        end: {
          line: 316,
          column: 20
        }
      },
      '171': {
        start: {
          line: 318,
          column: 2
        },
        end: {
          line: 324,
          column: 4
        }
      },
      '172': {
        start: {
          line: 319,
          column: 22
        },
        end: {
          line: 319,
          column: 47
        }
      },
      '173': {
        start: {
          line: 320,
          column: 4
        },
        end: {
          line: 322,
          column: 5
        }
      },
      '174': {
        start: {
          line: 321,
          column: 6
        },
        end: {
          line: 321,
          column: 13
        }
      },
      '175': {
        start: {
          line: 323,
          column: 4
        },
        end: {
          line: 323,
          column: 20
        }
      },
      '176': {
        start: {
          line: 325,
          column: 2
        },
        end: {
          line: 353,
          column: 4
        }
      },
      '177': {
        start: {
          line: 326,
          column: 17
        },
        end: {
          line: 326,
          column: 20
        }
      },
      '178': {
        start: {
          line: 327,
          column: 22
        },
        end: {
          line: 327,
          column: 47
        }
      },
      '179': {
        start: {
          line: 328,
          column: 20
        },
        end: {
          line: 328,
          column: 51
        }
      },
      '180': {
        start: {
          line: 329,
          column: 20
        },
        end: {
          line: 329,
          column: 62
        }
      },
      '181': {
        start: {
          line: 330,
          column: 21
        },
        end: {
          line: 337,
          column: 5
        }
      },
      '182': {
        start: {
          line: 338,
          column: 4
        },
        end: {
          line: 338,
          column: 28
        }
      },
      '183': {
        start: {
          line: 339,
          column: 4
        },
        end: {
          line: 352,
          column: 7
        }
      },
      '184': {
        start: {
          line: 340,
          column: 6
        },
        end: {
          line: 346,
          column: 7
        }
      },
      '185': {
        start: {
          line: 341,
          column: 8
        },
        end: {
          line: 345,
          column: 9
        }
      },
      '186': {
        start: {
          line: 342,
          column: 10
        },
        end: {
          line: 342,
          column: 42
        }
      },
      '187': {
        start: {
          line: 343,
          column: 10
        },
        end: {
          line: 343,
          column: 34
        }
      },
      '188': {
        start: {
          line: 344,
          column: 10
        },
        end: {
          line: 344,
          column: 16
        }
      },
      '189': {
        start: {
          line: 347,
          column: 6
        },
        end: {
          line: 347,
          column: 31
        }
      },
      '190': {
        start: {
          line: 348,
          column: 6
        },
        end: {
          line: 348,
          column: 22
        }
      },
      '191': {
        start: {
          line: 349,
          column: 6
        },
        end: {
          line: 351,
          column: 7
        }
      },
      '192': {
        start: {
          line: 350,
          column: 8
        },
        end: {
          line: 350,
          column: 13
        }
      },
      '193': {
        start: {
          line: 354,
          column: 2
        },
        end: {
          line: 356,
          column: 5
        }
      },
      '194': {
        start: {
          line: 355,
          column: 4
        },
        end: {
          line: 355,
          column: 20
        }
      },
      '195': {
        start: {
          line: 357,
          column: 2
        },
        end: {
          line: 357,
          column: 18
        }
      },
      '196': {
        start: {
          line: 359,
          column: 2
        },
        end: {
          line: 371,
          column: 4
        }
      },
      '197': {
        start: {
          line: 365,
          column: 4
        },
        end: {
          line: 370,
          column: 5
        }
      },
      '198': {
        start: {
          line: 367,
          column: 8
        },
        end: {
          line: 367,
          column: 24
        }
      },
      '199': {
        start: {
          line: 374,
          column: 2
        },
        end: {
          line: 374,
          column: 31
        }
      },
      '200': {
        start: {
          line: 379,
          column: 19
        },
        end: {
          line: 379,
          column: 30
        }
      },
      '201': {
        start: {
          line: 380,
          column: 2
        },
        end: {
          line: 392,
          column: 4
        }
      },
      '202': {
        start: {
          line: 394,
          column: 2
        },
        end: {
          line: 401,
          column: 3
        }
      },
      '203': {
        start: {
          line: 395,
          column: 4
        },
        end: {
          line: 395,
          column: 28
        }
      },
      '204': {
        start: {
          line: 396,
          column: 4
        },
        end: {
          line: 400,
          column: 7
        }
      },
      '205': {
        start: {
          line: 397,
          column: 6
        },
        end: {
          line: 397,
          column: 45
        }
      },
      '206': {
        start: {
          line: 398,
          column: 6
        },
        end: {
          line: 398,
          column: 31
        }
      },
      '207': {
        start: {
          line: 399,
          column: 6
        },
        end: {
          line: 399,
          column: 31
        }
      },
      '208': {
        start: {
          line: 402,
          column: 2
        },
        end: {
          line: 404,
          column: 4
        }
      },
      '209': {
        start: {
          line: 403,
          column: 4
        },
        end: {
          line: 403,
          column: 39
        }
      },
      '210': {
        start: {
          line: 405,
          column: 2
        },
        end: {
          line: 409,
          column: 4
        }
      },
      '211': {
        start: {
          line: 406,
          column: 4
        },
        end: {
          line: 406,
          column: 43
        }
      },
      '212': {
        start: {
          line: 407,
          column: 4
        },
        end: {
          line: 407,
          column: 48
        }
      },
      '213': {
        start: {
          line: 408,
          column: 4
        },
        end: {
          line: 408,
          column: 29
        }
      },
      '214': {
        start: {
          line: 410,
          column: 2
        },
        end: {
          line: 413,
          column: 4
        }
      },
      '215': {
        start: {
          line: 411,
          column: 4
        },
        end: {
          line: 411,
          column: 28
        }
      },
      '216': {
        start: {
          line: 412,
          column: 4
        },
        end: {
          line: 412,
          column: 39
        }
      },
      '217': {
        start: {
          line: 414,
          column: 2
        },
        end: {
          line: 416,
          column: 4
        }
      },
      '218': {
        start: {
          line: 415,
          column: 4
        },
        end: {
          line: 415,
          column: 40
        }
      },
      '219': {
        start: {
          line: 417,
          column: 2
        },
        end: {
          line: 422,
          column: 4
        }
      },
      '220': {
        start: {
          line: 418,
          column: 4
        },
        end: {
          line: 420,
          column: 5
        }
      },
      '221': {
        start: {
          line: 419,
          column: 6
        },
        end: {
          line: 419,
          column: 19
        }
      },
      '222': {
        start: {
          line: 421,
          column: 4
        },
        end: {
          line: 421,
          column: 52
        }
      },
      '223': {
        start: {
          line: 424,
          column: 2
        },
        end: {
          line: 426,
          column: 4
        }
      },
      '224': {
        start: {
          line: 425,
          column: 4
        },
        end: {
          line: 425,
          column: 198
        }
      },
      '225': {
        start: {
          line: 428,
          column: 2
        },
        end: {
          line: 430,
          column: 4
        }
      },
      '226': {
        start: {
          line: 429,
          column: 4
        },
        end: {
          line: 429,
          column: 198
        }
      },
      '227': {
        start: {
          line: 432,
          column: 19
        },
        end: {
          line: 437,
          column: 4
        }
      },
      '228': {
        start: {
          line: 438,
          column: 2
        },
        end: {
          line: 457,
          column: 4
        }
      },
      '229': {
        start: {
          line: 439,
          column: 4
        },
        end: {
          line: 451,
          column: 6
        }
      },
      '230': {
        start: {
          line: 452,
          column: 4
        },
        end: {
          line: 452,
          column: 32
        }
      },
      '231': {
        start: {
          line: 453,
          column: 4
        },
        end: {
          line: 453,
          column: 27
        }
      },
      '232': {
        start: {
          line: 454,
          column: 4
        },
        end: {
          line: 454,
          column: 28
        }
      },
      '233': {
        start: {
          line: 455,
          column: 4
        },
        end: {
          line: 455,
          column: 84
        }
      },
      '234': {
        start: {
          line: 456,
          column: 4
        },
        end: {
          line: 456,
          column: 25
        }
      },
      '235': {
        start: {
          line: 458,
          column: 2
        },
        end: {
          line: 461,
          column: 4
        }
      },
      '236': {
        start: {
          line: 459,
          column: 4
        },
        end: {
          line: 459,
          column: 29
        }
      },
      '237': {
        start: {
          line: 460,
          column: 4
        },
        end: {
          line: 460,
          column: 207
        }
      },
      '238': {
        start: {
          line: 462,
          column: 2
        },
        end: {
          line: 465,
          column: 4
        }
      },
      '239': {
        start: {
          line: 463,
          column: 4
        },
        end: {
          line: 463,
          column: 29
        }
      },
      '240': {
        start: {
          line: 464,
          column: 4
        },
        end: {
          line: 464,
          column: 24
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 56
          },
          end: {
            line: 4,
            column: 57
          }
        },
        loc: {
          start: {
            line: 4,
            column: 232
          },
          end: {
            line: 376,
            column: 1
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 31,
            column: 3
          },
          end: {
            line: 31,
            column: 4
          }
        },
        loc: {
          start: {
            line: 31,
            column: 15
          },
          end: {
            line: 45,
            column: 3
          }
        },
        line: 31
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 42,
            column: 55
          },
          end: {
            line: 42,
            column: 56
          }
        },
        loc: {
          start: {
            line: 42,
            column: 63
          },
          end: {
            line: 44,
            column: 5
          }
        },
        line: 42
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 50,
            column: 24
          },
          end: {
            line: 50,
            column: 25
          }
        },
        loc: {
          start: {
            line: 50,
            column: 40
          },
          end: {
            line: 75,
            column: 3
          }
        },
        line: 50
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 52,
            column: 72
          },
          end: {
            line: 52,
            column: 73
          }
        },
        loc: {
          start: {
            line: 52,
            column: 82
          },
          end: {
            line: 74,
            column: 5
          }
        },
        line: 52
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 68,
            column: 75
          },
          end: {
            line: 68,
            column: 76
          }
        },
        loc: {
          start: {
            line: 68,
            column: 83
          },
          end: {
            line: 73,
            column: 7
          }
        },
        line: 68
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 80,
            column: 26
          },
          end: {
            line: 80,
            column: 27
          }
        },
        loc: {
          start: {
            line: 80,
            column: 32
          },
          end: {
            line: 82,
            column: 3
          }
        },
        line: 80
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 84,
            column: 26
          },
          end: {
            line: 84,
            column: 27
          }
        },
        loc: {
          start: {
            line: 84,
            column: 38
          },
          end: {
            line: 100,
            column: 3
          }
        },
        line: 84
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 97,
            column: 97
          },
          end: {
            line: 97,
            column: 98
          }
        },
        loc: {
          start: {
            line: 97,
            column: 105
          },
          end: {
            line: 99,
            column: 5
          }
        },
        line: 97
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 101,
            column: 26
          },
          end: {
            line: 101,
            column: 27
          }
        },
        loc: {
          start: {
            line: 101,
            column: 38
          },
          end: {
            line: 127,
            column: 3
          }
        },
        line: 101
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 116,
            column: 97
          },
          end: {
            line: 116,
            column: 98
          }
        },
        loc: {
          start: {
            line: 116,
            column: 105
          },
          end: {
            line: 126,
            column: 5
          }
        },
        line: 116
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 151,
            column: 22
          },
          end: {
            line: 151,
            column: 23
          }
        },
        loc: {
          start: {
            line: 151,
            column: 34
          },
          end: {
            line: 196,
            column: 3
          }
        },
        line: 151
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 153,
            column: 49
          },
          end: {
            line: 153,
            column: 50
          }
        },
        loc: {
          start: {
            line: 153,
            column: 57
          },
          end: {
            line: 195,
            column: 5
          }
        },
        line: 153
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 199,
            column: 18
          },
          end: {
            line: 199,
            column: 19
          }
        },
        loc: {
          start: {
            line: 199,
            column: 30
          },
          end: {
            line: 279,
            column: 3
          }
        },
        line: 199
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 208,
            column: 79
          },
          end: {
            line: 208,
            column: 80
          }
        },
        loc: {
          start: {
            line: 208,
            column: 87
          },
          end: {
            line: 278,
            column: 5
          }
        },
        line: 208
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 219,
            column: 39
          },
          end: {
            line: 219,
            column: 40
          }
        },
        loc: {
          start: {
            line: 219,
            column: 44
          },
          end: {
            line: 219,
            column: 64
          }
        },
        line: 219
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 263,
            column: 36
          },
          end: {
            line: 263,
            column: 37
          }
        },
        loc: {
          start: {
            line: 263,
            column: 41
          },
          end: {
            line: 263,
            column: 52
          }
        },
        line: 263
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 280,
            column: 27
          },
          end: {
            line: 280,
            column: 28
          }
        },
        loc: {
          start: {
            line: 280,
            column: 43
          },
          end: {
            line: 287,
            column: 3
          }
        },
        line: 280
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 288,
            column: 16
          },
          end: {
            line: 288,
            column: 17
          }
        },
        loc: {
          start: {
            line: 288,
            column: 32
          },
          end: {
            line: 312,
            column: 3
          }
        },
        line: 288
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 313,
            column: 18
          },
          end: {
            line: 313,
            column: 19
          }
        },
        loc: {
          start: {
            line: 313,
            column: 30
          },
          end: {
            line: 317,
            column: 3
          }
        },
        line: 313
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 318,
            column: 18
          },
          end: {
            line: 318,
            column: 19
          }
        },
        loc: {
          start: {
            line: 318,
            column: 30
          },
          end: {
            line: 324,
            column: 3
          }
        },
        line: 318
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 325,
            column: 20
          },
          end: {
            line: 325,
            column: 21
          }
        },
        loc: {
          start: {
            line: 325,
            column: 34
          },
          end: {
            line: 353,
            column: 3
          }
        },
        line: 325
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 339,
            column: 125
          },
          end: {
            line: 339,
            column: 126
          }
        },
        loc: {
          start: {
            line: 339,
            column: 133
          },
          end: {
            line: 352,
            column: 5
          }
        },
        line: 339
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 354,
            column: 41
          },
          end: {
            line: 354,
            column: 42
          }
        },
        loc: {
          start: {
            line: 354,
            column: 47
          },
          end: {
            line: 356,
            column: 3
          }
        },
        line: 354
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 359,
            column: 16
          },
          end: {
            line: 359,
            column: 17
          }
        },
        loc: {
          start: {
            line: 359,
            column: 28
          },
          end: {
            line: 371,
            column: 3
          }
        },
        line: 359
      },
      '25': {
        name: 'report_calendarCtrl_edit',
        decl: {
          start: {
            line: 378,
            column: 9
          },
          end: {
            line: 378,
            column: 33
          }
        },
        loc: {
          start: {
            line: 378,
            column: 106
          },
          end: {
            line: 466,
            column: 1
          }
        },
        line: 378
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 396,
            column: 90
          },
          end: {
            line: 396,
            column: 91
          }
        },
        loc: {
          start: {
            line: 396,
            column: 98
          },
          end: {
            line: 400,
            column: 5
          }
        },
        line: 396
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 402,
            column: 30
          },
          end: {
            line: 402,
            column: 31
          }
        },
        loc: {
          start: {
            line: 402,
            column: 52
          },
          end: {
            line: 404,
            column: 3
          }
        },
        line: 402
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 405,
            column: 26
          },
          end: {
            line: 405,
            column: 27
          }
        },
        loc: {
          start: {
            line: 405,
            column: 42
          },
          end: {
            line: 409,
            column: 3
          }
        },
        line: 405
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 410,
            column: 27
          },
          end: {
            line: 410,
            column: 28
          }
        },
        loc: {
          start: {
            line: 410,
            column: 39
          },
          end: {
            line: 413,
            column: 3
          }
        },
        line: 410
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 414,
            column: 22
          },
          end: {
            line: 414,
            column: 23
          }
        },
        loc: {
          start: {
            line: 414,
            column: 34
          },
          end: {
            line: 416,
            column: 3
          }
        },
        line: 414
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 417,
            column: 32
          },
          end: {
            line: 417,
            column: 33
          }
        },
        loc: {
          start: {
            line: 417,
            column: 57
          },
          end: {
            line: 422,
            column: 3
          }
        },
        line: 417
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 424,
            column: 26
          },
          end: {
            line: 424,
            column: 27
          }
        },
        loc: {
          start: {
            line: 424,
            column: 44
          },
          end: {
            line: 426,
            column: 3
          }
        },
        line: 424
      },
      '33': {
        name: '(anonymous_33)',
        decl: {
          start: {
            line: 428,
            column: 22
          },
          end: {
            line: 428,
            column: 23
          }
        },
        loc: {
          start: {
            line: 428,
            column: 40
          },
          end: {
            line: 430,
            column: 3
          }
        },
        line: 428
      },
      '34': {
        name: '(anonymous_34)',
        decl: {
          start: {
            line: 438,
            column: 31
          },
          end: {
            line: 438,
            column: 32
          }
        },
        loc: {
          start: {
            line: 438,
            column: 51
          },
          end: {
            line: 457,
            column: 3
          }
        },
        line: 438
      },
      '35': {
        name: '(anonymous_35)',
        decl: {
          start: {
            line: 458,
            column: 27
          },
          end: {
            line: 458,
            column: 28
          }
        },
        loc: {
          start: {
            line: 458,
            column: 47
          },
          end: {
            line: 461,
            column: 3
          }
        },
        line: 458
      },
      '36': {
        name: '(anonymous_36)',
        decl: {
          start: {
            line: 462,
            column: 25
          },
          end: {
            line: 462,
            column: 26
          }
        },
        loc: {
          start: {
            line: 462,
            column: 37
          },
          end: {
            line: 465,
            column: 3
          }
        },
        line: 462
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }, {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }],
        line: 6
      },
      '1': {
        loc: {
          start: {
            line: 53,
            column: 6
          },
          end: {
            line: 56,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 53,
            column: 6
          },
          end: {
            line: 56,
            column: 7
          }
        }, {
          start: {
            line: 53,
            column: 6
          },
          end: {
            line: 56,
            column: 7
          }
        }],
        line: 53
      },
      '2': {
        loc: {
          start: {
            line: 57,
            column: 6
          },
          end: {
            line: 61,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 57,
            column: 6
          },
          end: {
            line: 61,
            column: 7
          }
        }, {
          start: {
            line: 57,
            column: 6
          },
          end: {
            line: 61,
            column: 7
          }
        }],
        line: 57
      },
      '3': {
        loc: {
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 66,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 66,
            column: 7
          }
        }, {
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 66,
            column: 7
          }
        }],
        line: 62
      },
      '4': {
        loc: {
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 72,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 72,
            column: 9
          }
        }, {
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 72,
            column: 9
          }
        }],
        line: 70
      },
      '5': {
        loc: {
          start: {
            line: 86,
            column: 4
          },
          end: {
            line: 92,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 86,
            column: 4
          },
          end: {
            line: 92,
            column: 5
          }
        }, {
          start: {
            line: 86,
            column: 4
          },
          end: {
            line: 92,
            column: 5
          }
        }],
        line: 86
      },
      '6': {
        loc: {
          start: {
            line: 88,
            column: 8
          },
          end: {
            line: 90,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 88,
            column: 8
          },
          end: {
            line: 90,
            column: 9
          }
        }, {
          start: {
            line: 88,
            column: 8
          },
          end: {
            line: 90,
            column: 9
          }
        }],
        line: 88
      },
      '7': {
        loc: {
          start: {
            line: 102,
            column: 19
          },
          end: {
            line: 102,
            column: 56
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 102,
            column: 47
          },
          end: {
            line: 102,
            column: 50
          }
        }, {
          start: {
            line: 102,
            column: 53
          },
          end: {
            line: 102,
            column: 56
          }
        }],
        line: 102
      },
      '8': {
        loc: {
          start: {
            line: 104,
            column: 4
          },
          end: {
            line: 110,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 104,
            column: 4
          },
          end: {
            line: 110,
            column: 5
          }
        }, {
          start: {
            line: 104,
            column: 4
          },
          end: {
            line: 110,
            column: 5
          }
        }],
        line: 104
      },
      '9': {
        loc: {
          start: {
            line: 106,
            column: 8
          },
          end: {
            line: 108,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 106,
            column: 8
          },
          end: {
            line: 108,
            column: 9
          }
        }, {
          start: {
            line: 106,
            column: 8
          },
          end: {
            line: 108,
            column: 9
          }
        }],
        line: 106
      },
      '10': {
        loc: {
          start: {
            line: 134,
            column: 15
          },
          end: {
            line: 134,
            column: 127
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 134,
            column: 15
          },
          end: {
            line: 134,
            column: 51
          }
        }, {
          start: {
            line: 134,
            column: 55
          },
          end: {
            line: 134,
            column: 91
          }
        }, {
          start: {
            line: 134,
            column: 95
          },
          end: {
            line: 134,
            column: 127
          }
        }],
        line: 134
      },
      '11': {
        loc: {
          start: {
            line: 135,
            column: 19
          },
          end: {
            line: 135,
            column: 131
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 135,
            column: 19
          },
          end: {
            line: 135,
            column: 55
          }
        }, {
          start: {
            line: 135,
            column: 59
          },
          end: {
            line: 135,
            column: 95
          }
        }, {
          start: {
            line: 135,
            column: 99
          },
          end: {
            line: 135,
            column: 131
          }
        }],
        line: 135
      },
      '12': {
        loc: {
          start: {
            line: 138,
            column: 6
          },
          end: {
            line: 140,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 138,
            column: 6
          },
          end: {
            line: 140,
            column: 7
          }
        }, {
          start: {
            line: 138,
            column: 6
          },
          end: {
            line: 140,
            column: 7
          }
        }],
        line: 138
      },
      '13': {
        loc: {
          start: {
            line: 141,
            column: 6
          },
          end: {
            line: 143,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 141,
            column: 6
          },
          end: {
            line: 143,
            column: 7
          }
        }, {
          start: {
            line: 141,
            column: 6
          },
          end: {
            line: 143,
            column: 7
          }
        }],
        line: 141
      },
      '14': {
        loc: {
          start: {
            line: 144,
            column: 6
          },
          end: {
            line: 146,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 144,
            column: 6
          },
          end: {
            line: 146,
            column: 7
          }
        }, {
          start: {
            line: 144,
            column: 6
          },
          end: {
            line: 146,
            column: 7
          }
        }],
        line: 144
      },
      '15': {
        loc: {
          start: {
            line: 144,
            column: 10
          },
          end: {
            line: 144,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 144,
            column: 10
          },
          end: {
            line: 144,
            column: 28
          }
        }, {
          start: {
            line: 144,
            column: 32
          },
          end: {
            line: 144,
            column: 46
          }
        }],
        line: 144
      },
      '16': {
        loc: {
          start: {
            line: 167,
            column: 12
          },
          end: {
            line: 169,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 167,
            column: 12
          },
          end: {
            line: 169,
            column: 13
          }
        }, {
          start: {
            line: 167,
            column: 12
          },
          end: {
            line: 169,
            column: 13
          }
        }],
        line: 167
      },
      '17': {
        loc: {
          start: {
            line: 170,
            column: 12
          },
          end: {
            line: 172,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 170,
            column: 12
          },
          end: {
            line: 172,
            column: 13
          }
        }, {
          start: {
            line: 170,
            column: 12
          },
          end: {
            line: 172,
            column: 13
          }
        }],
        line: 170
      },
      '18': {
        loc: {
          start: {
            line: 187,
            column: 8
          },
          end: {
            line: 189,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 187,
            column: 8
          },
          end: {
            line: 189,
            column: 9
          }
        }, {
          start: {
            line: 187,
            column: 8
          },
          end: {
            line: 189,
            column: 9
          }
        }],
        line: 187
      },
      '19': {
        loc: {
          start: {
            line: 190,
            column: 8
          },
          end: {
            line: 192,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 190,
            column: 8
          },
          end: {
            line: 192,
            column: 9
          }
        }, {
          start: {
            line: 190,
            column: 8
          },
          end: {
            line: 192,
            column: 9
          }
        }],
        line: 190
      },
      '20': {
        loc: {
          start: {
            line: 202,
            column: 4
          },
          end: {
            line: 204,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 202,
            column: 4
          },
          end: {
            line: 204,
            column: 5
          }
        }, {
          start: {
            line: 202,
            column: 4
          },
          end: {
            line: 204,
            column: 5
          }
        }],
        line: 202
      },
      '21': {
        loc: {
          start: {
            line: 202,
            column: 8
          },
          end: {
            line: 202,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 202,
            column: 8
          },
          end: {
            line: 202,
            column: 26
          }
        }, {
          start: {
            line: 202,
            column: 30
          },
          end: {
            line: 202,
            column: 46
          }
        }],
        line: 202
      },
      '22': {
        loc: {
          start: {
            line: 211,
            column: 10
          },
          end: {
            line: 213,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 211,
            column: 10
          },
          end: {
            line: 213,
            column: 11
          }
        }, {
          start: {
            line: 211,
            column: 10
          },
          end: {
            line: 213,
            column: 11
          }
        }],
        line: 211
      },
      '23': {
        loc: {
          start: {
            line: 211,
            column: 14
          },
          end: {
            line: 211,
            column: 104
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 211,
            column: 14
          },
          end: {
            line: 211,
            column: 43
          }
        }, {
          start: {
            line: 211,
            column: 47
          },
          end: {
            line: 211,
            column: 75
          }
        }, {
          start: {
            line: 211,
            column: 79
          },
          end: {
            line: 211,
            column: 104
          }
        }],
        line: 211
      },
      '24': {
        loc: {
          start: {
            line: 217,
            column: 6
          },
          end: {
            line: 267,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 217,
            column: 6
          },
          end: {
            line: 267,
            column: 7
          }
        }, {
          start: {
            line: 217,
            column: 6
          },
          end: {
            line: 267,
            column: 7
          }
        }],
        line: 217
      },
      '25': {
        loc: {
          start: {
            line: 217,
            column: 9
          },
          end: {
            line: 217,
            column: 32
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 217,
            column: 9
          },
          end: {
            line: 217,
            column: 13
          }
        }, {
          start: {
            line: 217,
            column: 17
          },
          end: {
            line: 217,
            column: 32
          }
        }],
        line: 217
      },
      '26': {
        loc: {
          start: {
            line: 231,
            column: 12
          },
          end: {
            line: 233,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 231,
            column: 12
          },
          end: {
            line: 233,
            column: 13
          }
        }, {
          start: {
            line: 231,
            column: 12
          },
          end: {
            line: 233,
            column: 13
          }
        }],
        line: 231
      },
      '27': {
        loc: {
          start: {
            line: 231,
            column: 16
          },
          end: {
            line: 231,
            column: 98
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 231,
            column: 16
          },
          end: {
            line: 231,
            column: 56
          }
        }, {
          start: {
            line: 231,
            column: 60
          },
          end: {
            line: 231,
            column: 98
          }
        }],
        line: 231
      },
      '28': {
        loc: {
          start: {
            line: 240,
            column: 34
          },
          end: {
            line: 240,
            column: 144
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 240,
            column: 34
          },
          end: {
            line: 240,
            column: 67
          }
        }, {
          start: {
            line: 240,
            column: 71
          },
          end: {
            line: 240,
            column: 106
          }
        }, {
          start: {
            line: 240,
            column: 110
          },
          end: {
            line: 240,
            column: 144
          }
        }],
        line: 240
      },
      '29': {
        loc: {
          start: {
            line: 241,
            column: 14
          },
          end: {
            line: 244,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 241,
            column: 14
          },
          end: {
            line: 244,
            column: 15
          }
        }, {
          start: {
            line: 241,
            column: 14
          },
          end: {
            line: 244,
            column: 15
          }
        }],
        line: 241
      },
      '30': {
        loc: {
          start: {
            line: 263,
            column: 10
          },
          end: {
            line: 265,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 263,
            column: 10
          },
          end: {
            line: 265,
            column: 11
          }
        }, {
          start: {
            line: 263,
            column: 10
          },
          end: {
            line: 265,
            column: 11
          }
        }],
        line: 263
      },
      '31': {
        loc: {
          start: {
            line: 272,
            column: 6
          },
          end: {
            line: 275,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 272,
            column: 6
          },
          end: {
            line: 275,
            column: 7
          }
        }, {
          start: {
            line: 272,
            column: 6
          },
          end: {
            line: 275,
            column: 7
          }
        }],
        line: 272
      },
      '32': {
        loc: {
          start: {
            line: 272,
            column: 10
          },
          end: {
            line: 272,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 272,
            column: 10
          },
          end: {
            line: 272,
            column: 29
          }
        }, {
          start: {
            line: 272,
            column: 33
          },
          end: {
            line: 272,
            column: 44
          }
        }],
        line: 272
      },
      '33': {
        loc: {
          start: {
            line: 281,
            column: 4
          },
          end: {
            line: 281,
            column: 34
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 281,
            column: 4
          },
          end: {
            line: 281,
            column: 34
          }
        }, {
          start: {
            line: 281,
            column: 4
          },
          end: {
            line: 281,
            column: 34
          }
        }],
        line: 281
      },
      '34': {
        loc: {
          start: {
            line: 286,
            column: 11
          },
          end: {
            line: 286,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 286,
            column: 11
          },
          end: {
            line: 286,
            column: 29
          }
        }, {
          start: {
            line: 286,
            column: 33
          },
          end: {
            line: 286,
            column: 49
          }
        }],
        line: 286
      },
      '35': {
        loc: {
          start: {
            line: 292,
            column: 4
          },
          end: {
            line: 294,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 292,
            column: 4
          },
          end: {
            line: 294,
            column: 5
          }
        }, {
          start: {
            line: 292,
            column: 4
          },
          end: {
            line: 294,
            column: 5
          }
        }],
        line: 292
      },
      '36': {
        loc: {
          start: {
            line: 299,
            column: 4
          },
          end: {
            line: 301,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 299,
            column: 4
          },
          end: {
            line: 301,
            column: 5
          }
        }, {
          start: {
            line: 299,
            column: 4
          },
          end: {
            line: 301,
            column: 5
          }
        }],
        line: 299
      },
      '37': {
        loc: {
          start: {
            line: 299,
            column: 9
          },
          end: {
            line: 299,
            column: 133
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 299,
            column: 10
          },
          end: {
            line: 299,
            column: 41
          }
        }, {
          start: {
            line: 299,
            column: 47
          },
          end: {
            line: 299,
            column: 75
          }
        }, {
          start: {
            line: 299,
            column: 80
          },
          end: {
            line: 299,
            column: 110
          }
        }, {
          start: {
            line: 299,
            column: 115
          },
          end: {
            line: 299,
            column: 132
          }
        }],
        line: 299
      },
      '38': {
        loc: {
          start: {
            line: 320,
            column: 4
          },
          end: {
            line: 322,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 320,
            column: 4
          },
          end: {
            line: 322,
            column: 5
          }
        }, {
          start: {
            line: 320,
            column: 4
          },
          end: {
            line: 322,
            column: 5
          }
        }],
        line: 320
      },
      '39': {
        loc: {
          start: {
            line: 336,
            column: 10
          },
          end: {
            line: 336,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 336,
            column: 10
          },
          end: {
            line: 336,
            column: 34
          }
        }, {
          start: {
            line: 336,
            column: 38
          },
          end: {
            line: 336,
            column: 39
          }
        }],
        line: 336
      },
      '40': {
        loc: {
          start: {
            line: 341,
            column: 8
          },
          end: {
            line: 345,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 341,
            column: 8
          },
          end: {
            line: 345,
            column: 9
          }
        }, {
          start: {
            line: 341,
            column: 8
          },
          end: {
            line: 345,
            column: 9
          }
        }],
        line: 341
      },
      '41': {
        loc: {
          start: {
            line: 341,
            column: 12
          },
          end: {
            line: 341,
            column: 158
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 341,
            column: 12
          },
          end: {
            line: 341,
            column: 34
          }
        }, {
          start: {
            line: 341,
            column: 38
          },
          end: {
            line: 341,
            column: 76
          }
        }, {
          start: {
            line: 341,
            column: 80
          },
          end: {
            line: 341,
            column: 118
          }
        }, {
          start: {
            line: 341,
            column: 122
          },
          end: {
            line: 341,
            column: 158
          }
        }],
        line: 341
      },
      '42': {
        loc: {
          start: {
            line: 349,
            column: 6
          },
          end: {
            line: 351,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 349,
            column: 6
          },
          end: {
            line: 351,
            column: 7
          }
        }, {
          start: {
            line: 349,
            column: 6
          },
          end: {
            line: 351,
            column: 7
          }
        }],
        line: 349
      },
      '43': {
        loc: {
          start: {
            line: 365,
            column: 4
          },
          end: {
            line: 370,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 365,
            column: 4
          },
          end: {
            line: 370,
            column: 5
          }
        }, {
          start: {
            line: 365,
            column: 4
          },
          end: {
            line: 370,
            column: 5
          }
        }],
        line: 365
      },
      '44': {
        loc: {
          start: {
            line: 365,
            column: 8
          },
          end: {
            line: 365,
            column: 48
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 365,
            column: 8
          },
          end: {
            line: 365,
            column: 27
          }
        }, {
          start: {
            line: 365,
            column: 31
          },
          end: {
            line: 365,
            column: 48
          }
        }],
        line: 365
      },
      '45': {
        loc: {
          start: {
            line: 394,
            column: 2
          },
          end: {
            line: 401,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 394,
            column: 2
          },
          end: {
            line: 401,
            column: 3
          }
        }, {
          start: {
            line: 394,
            column: 2
          },
          end: {
            line: 401,
            column: 3
          }
        }],
        line: 394
      },
      '46': {
        loc: {
          start: {
            line: 394,
            column: 6
          },
          end: {
            line: 394,
            column: 69
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 394,
            column: 6
          },
          end: {
            line: 394,
            column: 28
          }
        }, {
          start: {
            line: 394,
            column: 32
          },
          end: {
            line: 394,
            column: 69
          }
        }],
        line: 394
      },
      '47': {
        loc: {
          start: {
            line: 418,
            column: 4
          },
          end: {
            line: 420,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 418,
            column: 4
          },
          end: {
            line: 420,
            column: 5
          }
        }, {
          start: {
            line: 418,
            column: 4
          },
          end: {
            line: 420,
            column: 5
          }
        }],
        line: 418
      },
      '48': {
        loc: {
          start: {
            line: 418,
            column: 8
          },
          end: {
            line: 418,
            column: 69
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 418,
            column: 8
          },
          end: {
            line: 418,
            column: 43
          }
        }, {
          start: {
            line: 418,
            column: 47
          },
          end: {
            line: 418,
            column: 69
          }
        }],
        line: 418
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0, 0],
      '11': [0, 0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0, 0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0, 0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_t5cqusk7i.s[0]++;
/**
 * Created by BKSB on 08/03/2017.
 */
angular.module('app').controller('report_calendarCtrl', function ($scope, $stateParams, $deltaReportType, $deltaActor, $deltahttp, $window, $deltaDocument, $uibModal, SweetAlert, $rootScope, $translate, $deltadate, $CRUDService, $filter) {
  cov_t5cqusk7i.f[0]++;
  cov_t5cqusk7i.s[1]++;

  $rootScope.processPageRight('5_2');
  cov_t5cqusk7i.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_t5cqusk7i.b[0][0]++;
    cov_t5cqusk7i.s[3]++;

    return;
  } else {
    cov_t5cqusk7i.b[0][1]++;
  }

  cov_t5cqusk7i.s[4]++;
  $scope.activity_criteria = {
    respo: 0,
    encours: true,
    acheved: true,
    later: true,
    advanced: true,
    year: null

  };

  cov_t5cqusk7i.s[5]++;
  $scope.rapport = {
    Type_id: 0,
    YEAR_rapport_id: 0
  };
  var KEY__BASE = (cov_t5cqusk7i.s[6]++, 'rc_');
  var PATH_DOC = (cov_t5cqusk7i.s[7]++, 'Documents');
  cov_t5cqusk7i.s[8]++;
  $scope.init = true;
  cov_t5cqusk7i.s[9]++;
  $scope.__labels = {
    EDIT: $translate.instant('COMMON.EDIT'),
    DELETE: $translate.instant('COMMON.DELETE')
  };
  cov_t5cqusk7i.s[10]++;
  (function () {
    cov_t5cqusk7i.f[1]++;

    var PATH_THEME = (cov_t5cqusk7i.s[11]++, 'DocType');
    cov_t5cqusk7i.s[12]++;
    $scope.listAllThPhDoc = [];
    cov_t5cqusk7i.s[13]++;
    for (var i = 1; i <= 3; i++) {
      cov_t5cqusk7i.s[14]++;

      $scope.listAllThPhDoc.push({
        CODE_TYPEDOC: '',
        LIBELLE_TYPEDOC: $translate.instant('COMMON.NONE'),
        TYPE_CAT: i,
        id: 0
      });
    }
    cov_t5cqusk7i.s[15]++;
    $CRUDService.getAll(PATH_THEME, { get: 'all_info' }, function (data) {
      cov_t5cqusk7i.f[2]++;
      cov_t5cqusk7i.s[16]++;

      $scope.listAllThPhDoc = $scope.listAllThPhDoc.concat(data);
    });
  })();

  cov_t5cqusk7i.s[17]++;
  $deltaDocument.addController($scope);
  cov_t5cqusk7i.s[18]++;
  $scope.listProjects = [];
  cov_t5cqusk7i.s[19]++;
  $scope.PATH = 'Calendar';
  cov_t5cqusk7i.s[20]++;
  $scope.downloadItem = function (item) {
    cov_t5cqusk7i.f[3]++;
    cov_t5cqusk7i.s[21]++;

    $scope.isloading = true;
    cov_t5cqusk7i.s[22]++;
    $CRUDService.getAll(PATH_DOC, { get: 'single', id: item.IDRAPPORTS }, function (folder) {
      cov_t5cqusk7i.f[4]++;
      cov_t5cqusk7i.s[23]++;

      if (!folder) {
        cov_t5cqusk7i.b[1][0]++;
        cov_t5cqusk7i.s[24]++;

        $scope.isloading = false;
        cov_t5cqusk7i.s[25]++;
        return;
      } else {
        cov_t5cqusk7i.b[1][1]++;
      }
      cov_t5cqusk7i.s[26]++;
      if (folder.TYPE_RAPPORT == 1) {
        cov_t5cqusk7i.b[2][0]++;
        cov_t5cqusk7i.s[27]++;

        $scope.isloading = false;
        // $state.go('document.preview', {id: folder.id});
        cov_t5cqusk7i.s[28]++;
        return;
      } else {
        cov_t5cqusk7i.b[2][1]++;
      }
      cov_t5cqusk7i.s[29]++;
      if (folder.TYPE_RAPPORT == 2) {
        cov_t5cqusk7i.b[3][0]++;
        cov_t5cqusk7i.s[30]++;

        $scope.isloading = false;
        cov_t5cqusk7i.s[31]++;
        $window.open(folder.CHEMIN_RAPP, '_blank');
        cov_t5cqusk7i.s[32]++;
        return;
      } else {
        cov_t5cqusk7i.b[3][1]++;
      }

      cov_t5cqusk7i.s[33]++;
      $CRUDService.getAll(PATH_DOC, { download: true, c: folder.Reference }, function (data) {
        cov_t5cqusk7i.f[5]++;
        cov_t5cqusk7i.s[34]++;

        $scope.isloading = false;
        cov_t5cqusk7i.s[35]++;
        if (data.f != '') {
          cov_t5cqusk7i.b[4][0]++;
          cov_t5cqusk7i.s[36]++;

          $window.open($CRUDService.getServerStrict() + data.f, '_blank');
        } else {
          cov_t5cqusk7i.b[4][1]++;
        }
      });
    });
  };
  cov_t5cqusk7i.s[37]++;
  $deltaActor.addController($scope);
  cov_t5cqusk7i.s[38]++;
  $scope.step = 1;
  var actualYear = (cov_t5cqusk7i.s[39]++, $deltadate.format(Date.newDate(), 'yyyy'));

  cov_t5cqusk7i.s[40]++;
  $scope.getActorImage = function (id) {
    cov_t5cqusk7i.f[6]++;
    cov_t5cqusk7i.s[41]++;

    return $deltahttp.getActorsRepository(id);
  };

  cov_t5cqusk7i.s[42]++;
  $scope.findAllreunion = function () {
    cov_t5cqusk7i.f[7]++;

    var Year = (cov_t5cqusk7i.s[43]++, 0);
    cov_t5cqusk7i.s[44]++;
    if ($scope.YEAR_reunion_id.id != 0) {
      cov_t5cqusk7i.b[5][0]++;
      cov_t5cqusk7i.s[45]++;

      for (var i = 0; i < $scope.listYears.length; i++) {
        cov_t5cqusk7i.s[46]++;

        if ($scope.listYears[i].id == $scope.YEAR_reunion_id.id) {
          cov_t5cqusk7i.b[6][0]++;
          cov_t5cqusk7i.s[47]++;

          Year = $scope.listYears[i].Label;
        } else {
          cov_t5cqusk7i.b[6][1]++;
        }
      }
    } else {
      cov_t5cqusk7i.b[5][1]++;
    }
    var resultParams = (cov_t5cqusk7i.s[48]++, {
      id: $scope.validateItem.id,
      annee: Year
    });
    cov_t5cqusk7i.s[49]++;
    $otherHttpService.get($scope.PATH, { get: 'Reuniontb', value: $filter('json')(resultParams) }, function (data) {
      cov_t5cqusk7i.f[8]++;
      cov_t5cqusk7i.s[50]++;

      $scope.MyDataListeReu = data;
    });
  };
  cov_t5cqusk7i.s[51]++;
  $scope.findAllmission = function () {
    cov_t5cqusk7i.f[9]++;

    var avanc = (cov_t5cqusk7i.s[52]++, $scope.RADIO_A1 == 'true' ? (cov_t5cqusk7i.b[7][0]++, '1') : (cov_t5cqusk7i.b[7][1]++, '0'));
    var Year = (cov_t5cqusk7i.s[53]++, 0);
    cov_t5cqusk7i.s[54]++;
    if ($scope.YEAR_mission_id.id != 0) {
      cov_t5cqusk7i.b[8][0]++;
      cov_t5cqusk7i.s[55]++;

      for (var i = 0; i < $scope.listYears.length; i++) {
        cov_t5cqusk7i.s[56]++;

        if ($scope.listYears[i].id == $scope.YEAR_mission_id.id) {
          cov_t5cqusk7i.b[9][0]++;
          cov_t5cqusk7i.s[57]++;

          Year = $scope.listYears[i].Label;
        } else {
          cov_t5cqusk7i.b[9][1]++;
        }
      }
    } else {
      cov_t5cqusk7i.b[8][1]++;
    }
    var resultParams = (cov_t5cqusk7i.s[58]++, {
      etat: avanc,
      id: $scope.validateItem.id,
      annee: Year
    });
    cov_t5cqusk7i.s[59]++;
    $otherHttpService.get($scope.PATH, { get: 'Missiontb', value: $filter('json')(resultParams) }, function (data) {
      cov_t5cqusk7i.f[10]++;
      cov_t5cqusk7i.s[60]++;

      $scope.isloading = true;
      cov_t5cqusk7i.s[61]++;
      for (var _i = 0; _i < data.length; _i++) {
        cov_t5cqusk7i.s[62]++;

        data[_i].DateDP = Date.newDate(data[_i].DateDP);
        cov_t5cqusk7i.s[63]++;
        data[_i].DateDR = Date.newDate(data[_i].DateDR);
        cov_t5cqusk7i.s[64]++;
        data[_i].DateFP = Date.newDate(data[_i].DateFP);
        cov_t5cqusk7i.s[65]++;
        data[_i].DateFR = Date.newDate(data[_i].DateFR);
      }
      cov_t5cqusk7i.s[66]++;
      $scope.MyDataListeMiss = data;
      cov_t5cqusk7i.s[67]++;
      $scope.isloading = true;
    });
  };

  cov_t5cqusk7i.s[68]++;
  $scope.choise = {
    begin: $scope.minDate,
    end: $scope.maxDate,
    editItem: null,
    editing: false,
    only_plan: (cov_t5cqusk7i.b[10][0]++, localStorage.getItem(KEY__BASE) == 1) || (cov_t5cqusk7i.b[10][1]++, localStorage.getItem(KEY__BASE) == 3) || (cov_t5cqusk7i.b[10][2]++, !localStorage.getItem(KEY__BASE)),
    only_not_plan: (cov_t5cqusk7i.b[11][0]++, localStorage.getItem(KEY__BASE) == 2) || (cov_t5cqusk7i.b[11][1]++, localStorage.getItem(KEY__BASE) == 3) || (cov_t5cqusk7i.b[11][2]++, !localStorage.getItem(KEY__BASE)),
    changeplanState: function changeplanState() {
      var value = (cov_t5cqusk7i.s[69]++, '0');
      cov_t5cqusk7i.s[70]++;
      if (this.only_not_plan) {
        cov_t5cqusk7i.b[12][0]++;
        cov_t5cqusk7i.s[71]++;

        value = '2';
      } else {
        cov_t5cqusk7i.b[12][1]++;
      }
      cov_t5cqusk7i.s[72]++;
      if (this.only_plan) {
        cov_t5cqusk7i.b[13][0]++;
        cov_t5cqusk7i.s[73]++;

        value = '1';
      } else {
        cov_t5cqusk7i.b[13][1]++;
      }
      cov_t5cqusk7i.s[74]++;
      if ((cov_t5cqusk7i.b[15][0]++, this.only_not_plan) && (cov_t5cqusk7i.b[15][1]++, this.only_plan)) {
        cov_t5cqusk7i.b[14][0]++;
        cov_t5cqusk7i.s[75]++;

        value = '3';
      } else {
        cov_t5cqusk7i.b[14][1]++;
      }
      cov_t5cqusk7i.s[76]++;
      localStorage.setItem(KEY__BASE, value);
    }
  };

  cov_t5cqusk7i.s[77]++;
  $scope.getAllYear = function () {
    cov_t5cqusk7i.f[11]++;
    cov_t5cqusk7i.s[78]++;

    $scope.listYears = [];
    cov_t5cqusk7i.s[79]++;
    $CRUDService.getAll('Periode', { get: 'all' }, function (data) {
      cov_t5cqusk7i.f[12]++;
      cov_t5cqusk7i.s[80]++;

      $scope.isloading = true;
      var AllYear = (cov_t5cqusk7i.s[81]++, null);
      var listY = (cov_t5cqusk7i.s[82]++, []);
      var minYear = (cov_t5cqusk7i.s[83]++, Number.MAX_SAFE_INTEGER); // Initialisation à la plus grande valeur possible
      var maxYear = (cov_t5cqusk7i.s[84]++, Number.MIN_SAFE_INTEGER); // Initialisation à la plus petite valeur possible

      // Parcours des données pour construire la liste des années et déterminer les limites
      cov_t5cqusk7i.s[85]++;
      for (var i = 0; i < data.length; i++) {
        var year = (cov_t5cqusk7i.s[86]++, parseInt(data[i].ANNEE_PERIOD, 10)); // Convertir l'année en entier
        cov_t5cqusk7i.s[87]++;
        AllYear = { id: data[i].id, Label: data[i].ANNEE_PERIOD };
        cov_t5cqusk7i.s[88]++;
        listY.push(AllYear);

        // Mise à jour des années min et max
        cov_t5cqusk7i.s[89]++;
        if (year < minYear) {
          cov_t5cqusk7i.b[16][0]++;
          cov_t5cqusk7i.s[90]++;

          minYear = year;
        } else {
          cov_t5cqusk7i.b[16][1]++;
        }
        cov_t5cqusk7i.s[91]++;
        if (year > maxYear) {
          cov_t5cqusk7i.b[17][0]++;
          cov_t5cqusk7i.s[92]++;

          maxYear = year;
        } else {
          cov_t5cqusk7i.b[17][1]++;
        }
      }

      // Ajouter une option "Tous les ans" à la liste
      // listY.push({id: 0, Label: $translate.instant('COMMON.YEAR_ALL')});
      var lastindex = (cov_t5cqusk7i.s[93]++, listY.length - 1);
      cov_t5cqusk7i.s[94]++;
      $scope.activity_criteria.year = listY[lastindex];
      cov_t5cqusk7i.s[95]++;
      $scope.YEAR_mission_id = listY[lastindex];
      cov_t5cqusk7i.s[96]++;
      $scope.YEAR_reunion_id = listY[lastindex];
      cov_t5cqusk7i.s[97]++;
      $scope.rapport.YEAR_rapport_id = listY[lastindex];

      // Définir les limites des dates en fonction des années min et max
      cov_t5cqusk7i.s[98]++;
      $scope.minDate = new Date(minYear, 0, 1); // 1er janvier de l'année minimale
      cov_t5cqusk7i.s[99]++;
      $scope.maxDate = new Date(maxYear, 11, 31); // 31 décembre de l'année maximale

      cov_t5cqusk7i.s[100]++;
      if (!$scope.choise.begin) {
        cov_t5cqusk7i.b[18][0]++;
        cov_t5cqusk7i.s[101]++;

        $scope.choise.begin = $scope.minDate;
      } else {
        cov_t5cqusk7i.b[18][1]++;
      }
      cov_t5cqusk7i.s[102]++;
      if (!$scope.choise.end) {
        cov_t5cqusk7i.b[19][0]++;
        cov_t5cqusk7i.s[103]++;

        $scope.choise.end = $scope.maxDate;
      } else {
        cov_t5cqusk7i.b[19][1]++;
      }
      cov_t5cqusk7i.s[104]++;
      $scope.isloading = false;
      cov_t5cqusk7i.s[105]++;
      $scope.listYears = listY;
    });
  };

  cov_t5cqusk7i.s[106]++;
  $scope.invitation = {};
  cov_t5cqusk7i.s[107]++;
  $scope.getAll = function () {
    var _this = this;

    cov_t5cqusk7i.f[13]++;
    cov_t5cqusk7i.s[108]++;

    $scope.getAllYear();
    cov_t5cqusk7i.s[109]++;
    $scope.listData = [];
    cov_t5cqusk7i.s[110]++;
    if ((cov_t5cqusk7i.b[21][0]++, !this.choise.begin) || (cov_t5cqusk7i.b[21][1]++, !this.choise.end)) {
      cov_t5cqusk7i.b[20][0]++;
      cov_t5cqusk7i.s[111]++;

      return;
    } else {
      cov_t5cqusk7i.b[20][1]++;
    }
    cov_t5cqusk7i.s[112]++;
    $scope.isloading = true;
    var beginDate = (cov_t5cqusk7i.s[113]++, $deltadate.format(this.choise.begin, 'yyyy-mm-dd'));
    var endDate = (cov_t5cqusk7i.s[114]++, $deltadate.format(this.choise.end, 'yyyy-mm-dd'));
    cov_t5cqusk7i.s[115]++;
    $CRUDService.getAll($scope.PATH, { get: 'all_p', b: beginDate, e: endDate }, function (data) {
      cov_t5cqusk7i.f[14]++;
      cov_t5cqusk7i.s[116]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var elr = _step.value;
          cov_t5cqusk7i.s[117]++;
          var _iteratorNormalCompletion5 = true;
          var _didIteratorError5 = false;
          var _iteratorError5 = undefined;

          try {
            for (var _iterator5 = $scope.listData[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
              var _el = _step5.value;
              cov_t5cqusk7i.s[118]++;

              if ((cov_t5cqusk7i.b[23][0]++, elr.IDTYPE_RAPPORT == _el.CODE) && (cov_t5cqusk7i.b[23][1]++, elr.ANNEE_RAPPORT == _el.year) && (cov_t5cqusk7i.b[23][2]++, elr.NUM_PERIODE == _el.num)) {
                cov_t5cqusk7i.b[22][0]++;
                cov_t5cqusk7i.s[119]++;

                angular.extend(_el, elr);
              } else {
                cov_t5cqusk7i.b[22][1]++;
              }
            }
          } catch (err) {
            _didIteratorError5 = true;
            _iteratorError5 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion5 && _iterator5.return) {
                _iterator5.return();
              }
            } finally {
              if (_didIteratorError5) {
                throw _iteratorError5;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_t5cqusk7i.s[120]++;
      if ((cov_t5cqusk7i.b[25][0]++, data) && (cov_t5cqusk7i.b[25][1]++, data.length > 0)) {
        cov_t5cqusk7i.b[24][0]++;
        cov_t5cqusk7i.s[121]++;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = $scope.listData_reportType_bksb[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var el = _step2.value;

            var dataEndDate = (cov_t5cqusk7i.s[122]++, data.map(function (i) {
              cov_t5cqusk7i.f[15]++;
              cov_t5cqusk7i.s[123]++;
              return i.DATE_RELLE_RAPPORT;
            }));
            cov_t5cqusk7i.s[124]++;
            $scope.listData.push({
              LIBELLE_TYPE: el.LIBELLE_TYPE,
              IDACTEURS: el.IDACTEURS,
              CODE: el.id,
              type: 1
            });

            cov_t5cqusk7i.s[125]++;
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
              for (var _iterator3 = $deltadate.getPeriods(_this.choise.begin, _this.choise.end, parseInt(el.PERIODE_RAPPORT, 10))[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                var elp = _step3.value;

                var dp = (cov_t5cqusk7i.s[126]++, angular.copy(elp.end));
                cov_t5cqusk7i.s[127]++;
                dp.setDate(dp.getDate() + parseInt(el.DELAIS_PROD, 10));
                var dpFormat = (cov_t5cqusk7i.s[128]++, $deltadate.format(dp, 'yyyy-mm-dd'));
                cov_t5cqusk7i.s[129]++;
                if ((cov_t5cqusk7i.b[27][0]++, new Date(beginDate) > new Date(dpFormat)) && (cov_t5cqusk7i.b[27][1]++, new Date(endDate) < new Date(dpFormat))) {
                  cov_t5cqusk7i.b[26][0]++;
                  cov_t5cqusk7i.s[130]++;

                  continue;
                } else {
                  cov_t5cqusk7i.b[26][1]++;
                }

                // console.log("el", {elp, el});
                var idRapports = (cov_t5cqusk7i.s[131]++, null);
                var dateRelleRapport = (cov_t5cqusk7i.s[132]++, null);

                cov_t5cqusk7i.s[133]++;
                var _iteratorNormalCompletion4 = true;
                var _didIteratorError4 = false;
                var _iteratorError4 = undefined;

                try {
                  for (var _iterator4 = data[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                    var data_item = _step4.value;

                    var _condition = (cov_t5cqusk7i.s[134]++, (cov_t5cqusk7i.b[28][0]++, data_item.IDTYPE_RAPPORT == el.id) && (cov_t5cqusk7i.b[28][1]++, data_item.ANNEE_RAPPORT == elp.year) && (cov_t5cqusk7i.b[28][2]++, data_item.NUM_PERIODE == elp.index));
                    cov_t5cqusk7i.s[135]++;
                    if (_condition) {
                      cov_t5cqusk7i.b[29][0]++;
                      cov_t5cqusk7i.s[136]++;

                      idRapports = data_item.IDRAPPORTS;
                      cov_t5cqusk7i.s[137]++;
                      dateRelleRapport = data_item.DATE_RELLE_RAPPORT;
                    } else {
                      cov_t5cqusk7i.b[29][1]++;
                    }
                  }
                } catch (err) {
                  _didIteratorError4 = true;
                  _iteratorError4 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion4 && _iterator4.return) {
                      _iterator4.return();
                    }
                  } finally {
                    if (_didIteratorError4) {
                      throw _iteratorError4;
                    }
                  }
                }

                cov_t5cqusk7i.s[138]++;
                $scope.listData.push({
                  period: elp.label,
                  real: dateRelleRapport,
                  LIBELLE_TYPE: el.LIBELLE_TYPE,
                  IDACTEURS: el.IDACTEURS,
                  CODE: el.id,
                  type: 2,
                  year: elp.year,
                  prevu: dp,
                  num: elp.index,
                  IDRAPPORTS: idRapports,
                  DATE_RELLE_RAPPORT: dateRelleRapport
                });
              }

              // ! set empty list if no data
            } catch (err) {
              _didIteratorError3 = true;
              _iteratorError3 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion3 && _iterator3.return) {
                  _iterator3.return();
                }
              } finally {
                if (_didIteratorError3) {
                  throw _iteratorError3;
                }
              }
            }

            cov_t5cqusk7i.s[139]++;
            if ($scope.listData.filter(function (i) {
              cov_t5cqusk7i.f[16]++;
              cov_t5cqusk7i.s[140]++;
              return i.type == 2;
            }).length == 0) {
              cov_t5cqusk7i.b[30][0]++;
              cov_t5cqusk7i.s[141]++;

              $scope.listData = [];
            } else {
              cov_t5cqusk7i.b[30][1]++;
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      } else {
        cov_t5cqusk7i.b[24][1]++;
      }

      cov_t5cqusk7i.s[142]++;
      $scope.isloading = false;
      cov_t5cqusk7i.s[143]++;
      $scope.cancel();

      cov_t5cqusk7i.s[144]++;
      if ((cov_t5cqusk7i.b[32][0]++, $stateParams.params) && (cov_t5cqusk7i.b[32][1]++, $scope.init)) {
        cov_t5cqusk7i.b[31][0]++;
        cov_t5cqusk7i.s[145]++;

        $scope.edit($stateParams.params.item);
        cov_t5cqusk7i.s[146]++;
        $scope.init = false;
      } else {
        cov_t5cqusk7i.b[31][1]++;
      }

      // $scope.listData=data;
    });
  };
  cov_t5cqusk7i.s[147]++;
  $scope.dateRangeFilter = function (item) {
    cov_t5cqusk7i.f[17]++;
    cov_t5cqusk7i.s[148]++;

    if (!item.prevu) {
        cov_t5cqusk7i.b[33][0]++;
        cov_t5cqusk7i.s[149]++;
        return false;
      } else {
      cov_t5cqusk7i.b[33][1]++;
    } // Éviter les erreurs si la date est manquante
    var start = (cov_t5cqusk7i.s[150]++, $scope.choise.begin);
    var end = (cov_t5cqusk7i.s[151]++, $scope.choise.end);
    var prevuDate = (cov_t5cqusk7i.s[152]++, new Date(item.prevu));

    cov_t5cqusk7i.s[153]++;
    return (cov_t5cqusk7i.b[34][0]++, prevuDate >= start) && (cov_t5cqusk7i.b[34][1]++, prevuDate <= end);
  };
  cov_t5cqusk7i.s[154]++;
  $scope.edit = function (item) {
    cov_t5cqusk7i.f[18]++;
    cov_t5cqusk7i.s[155]++;

    $scope.invitation = {};
    cov_t5cqusk7i.s[156]++;
    console.log('item');
    cov_t5cqusk7i.s[157]++;
    console.log(item);
    cov_t5cqusk7i.s[158]++;
    if (typeof item == 'undefined') {
      cov_t5cqusk7i.b[35][0]++;
      cov_t5cqusk7i.s[159]++;

      return;
    } else {
      cov_t5cqusk7i.b[35][1]++;
    }
    cov_t5cqusk7i.s[160]++;
    $scope.choise.editItem = angular.copy(item);
    cov_t5cqusk7i.s[161]++;
    console.log('choise.editItem');
    cov_t5cqusk7i.s[162]++;
    console.log($scope.choise.editItem);

    cov_t5cqusk7i.s[163]++;
    if ((cov_t5cqusk7i.b[37][0]++, typeof item.real == 'undefined') || (cov_t5cqusk7i.b[37][1]++, !item.hasOwnProperty('real')) || (cov_t5cqusk7i.b[37][2]++, angular.isUndefined(item.real)) || (cov_t5cqusk7i.b[37][3]++, item.real == null)) {
      cov_t5cqusk7i.b[36][0]++;
      cov_t5cqusk7i.s[164]++;

      $scope.choise.editItem.real = Date.newDate();
    } else {
      cov_t5cqusk7i.b[36][1]++;
    }

    cov_t5cqusk7i.s[165]++;
    $uibModal.open({
      templateUrl: 'app/views/report_calendar/edit.html',
      controller: report_calendarCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
    cov_t5cqusk7i.s[166]++;
    this.choise.editing = true;
  };
  cov_t5cqusk7i.s[167]++;
  $scope.cancel = function () {
    cov_t5cqusk7i.f[19]++;
    cov_t5cqusk7i.s[168]++;

    this.choise.editItem = null;
    cov_t5cqusk7i.s[169]++;
    this.choise.editing = false;
    cov_t5cqusk7i.s[170]++;
    $scope.step = 1;
  };
  cov_t5cqusk7i.s[171]++;
  $scope.nextEl = function () {
    cov_t5cqusk7i.f[20]++;

    var Dateparam = (cov_t5cqusk7i.s[172]++, this.choise.editItem.real);
    cov_t5cqusk7i.s[173]++;
    if (!Dateparam) {
      cov_t5cqusk7i.b[38][0]++;
      cov_t5cqusk7i.s[174]++;

      return;
    } else {
      cov_t5cqusk7i.b[38][1]++;
    }
    cov_t5cqusk7i.s[175]++;
    $scope.step = 2;
  };
  cov_t5cqusk7i.s[176]++;
  $scope.validate = function (fn) {
    cov_t5cqusk7i.f[21]++;

    var list = (cov_t5cqusk7i.s[177]++, '0');
    var Dateparam = (cov_t5cqusk7i.s[178]++, this.choise.editItem.real);
    var rapport = (cov_t5cqusk7i.s[179]++, this.choise.editItem.IDRAPPORTS);
    var datform = (cov_t5cqusk7i.s[180]++, $deltadate.format(Dateparam, 'yyyy-mm-dd'));
    var saveData = (cov_t5cqusk7i.s[181]++, {
      ANNEE_RAPPORT: this.choise.editItem.year,
      DATE_RELLE_RAPPORT: datform,
      IDTYPE_RAPPORT: this.choise.editItem.CODE,
      NUM_PERIODE: this.choise.editItem.num,
      IDRAPPORTS: this.choise.editItem.IDRAPPORTS,
      id: (cov_t5cqusk7i.b[39][0]++, this.choise.editItem.idP) || (cov_t5cqusk7i.b[39][1]++, 0)
    });
    cov_t5cqusk7i.s[182]++;
    $scope.isloading = true;
    cov_t5cqusk7i.s[183]++;
    $CRUDService.save($scope.PATH, { action: 'MiseAJour', valeur: $filter('json')(saveData), l: $filter('json')(list + ';') }, function (data) {
      cov_t5cqusk7i.f[22]++;
      cov_t5cqusk7i.s[184]++;
      var _iteratorNormalCompletion6 = true;
      var _didIteratorError6 = false;
      var _iteratorError6 = undefined;

      try {
        for (var _iterator6 = $scope.listData[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
          var el = _step6.value;
          cov_t5cqusk7i.s[185]++;

          if ((cov_t5cqusk7i.b[41][0]++, $scope.choise.editItem) && (cov_t5cqusk7i.b[41][1]++, $scope.choise.editItem.CODE == el.CODE) && (cov_t5cqusk7i.b[41][2]++, $scope.choise.editItem.year == el.year) && (cov_t5cqusk7i.b[41][3]++, $scope.choise.editItem.num == el.num)) {
            cov_t5cqusk7i.b[40][0]++;
            cov_t5cqusk7i.s[186]++;

            el.real = Date.newDate(datform);
            cov_t5cqusk7i.s[187]++;
            el.IDRAPPORTS = rapport;
            cov_t5cqusk7i.s[188]++;
            break;
          } else {
            cov_t5cqusk7i.b[40][1]++;
          }
        }
      } catch (err) {
        _didIteratorError6 = true;
        _iteratorError6 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion6 && _iterator6.return) {
            _iterator6.return();
          }
        } finally {
          if (_didIteratorError6) {
            throw _iteratorError6;
          }
        }
      }

      cov_t5cqusk7i.s[189]++;
      $scope.isloading = false;
      cov_t5cqusk7i.s[190]++;
      $scope.cancel();
      cov_t5cqusk7i.s[191]++;
      if (fn) {
        cov_t5cqusk7i.b[42][0]++;
        cov_t5cqusk7i.s[192]++;

        fn();
      } else {
        cov_t5cqusk7i.b[42][1]++;
      }
    });
  };
  cov_t5cqusk7i.s[193]++;
  $deltaReportType.addController($scope, function () {
    cov_t5cqusk7i.f[23]++;
    cov_t5cqusk7i.s[194]++;

    $scope.getAll();
  });
  cov_t5cqusk7i.s[195]++;
  $scope.getAll();

  cov_t5cqusk7i.s[196]++;
  $scope.init = function () {
    cov_t5cqusk7i.f[24]++;
    cov_t5cqusk7i.s[197]++;

    // console.log("Initialisation des filtres...");

    // Vérifier les valeurs
    // console.log("Début:", $scope.choise.begin, "Fin:", $scope.choise.end);

    if ((cov_t5cqusk7i.b[44][0]++, $scope.choise.begin) && (cov_t5cqusk7i.b[44][1]++, $scope.choise.end)) {
      cov_t5cqusk7i.b[43][0]++;
      cov_t5cqusk7i.s[198]++;

      // console.log("Appel de getAll() après initialisation");
      $scope.getAll();
    } else {
      // console.log("Les valeurs des filtres ne sont pas encore prêtes");

      cov_t5cqusk7i.b[43][1]++;
    }
  };

  //init après un petit délai pour s'assurer que les valeurs sont chargées
  cov_t5cqusk7i.s[199]++;
  setTimeout($scope.init, 500);
});

function report_calendarCtrl_edit($scope, $uibModalInstance, $state, $filter, $CRUDService, FileUploader) {
  cov_t5cqusk7i.f[25]++;

  var PATH_DOC = (cov_t5cqusk7i.s[200]++, 'Documents');
  cov_t5cqusk7i.s[201]++;
  $scope.options = {
    path: '/198/',
    action: 'save',
    data: {
      path: null, action: null, newName: null, names: null, name: '/', size: 0,
      previousName: null, dateModified: '2019-12-17T10:47:37.69301+00:00',
      dateCreated: '2019-12-17T13:23:02.2029559+00:00', hasChild: true,
      isFile: false, type: '', id: null, filterPath: '\\', filterId: null,
      targetPath: null, renameFiles: null, uploadFiles: null, caseSensitive: false,
      searchString: null, showHiddenItems: false, data: null, targetData: null, permission: null,
      _fm_id: 'fe_tree_0'
    }
  };

  cov_t5cqusk7i.s[202]++;
  if ((cov_t5cqusk7i.b[46][0]++, $scope.choise.editItem) && (cov_t5cqusk7i.b[46][1]++, $scope.choise.editItem.IDRAPPORTS > 0)) {
    cov_t5cqusk7i.b[45][0]++;
    cov_t5cqusk7i.s[203]++;

    $scope.isloading = true;
    cov_t5cqusk7i.s[204]++;
    $CRUDService.getAll(PATH_DOC, { get: 'single', id: $scope.choise.editItem.IDRAPPORTS }, function (data) {
      cov_t5cqusk7i.f[26]++;
      cov_t5cqusk7i.s[205]++;

      $scope.choise.editItem.document = data;
      cov_t5cqusk7i.s[206]++;
      $scope.typeSelect = null;
      cov_t5cqusk7i.s[207]++;
      $scope.isloading = false;
    });
  } else {
    cov_t5cqusk7i.b[45][1]++;
  }
  cov_t5cqusk7i.s[208]++;
  $scope.selectDocumentType = function (typeSelect) {
    cov_t5cqusk7i.f[27]++;
    cov_t5cqusk7i.s[209]++;

    $scope.typeSelect = typeSelect + 1;
  };
  cov_t5cqusk7i.s[210]++;
  $scope.selectDocument = function (item) {
    cov_t5cqusk7i.f[28]++;
    cov_t5cqusk7i.s[211]++;

    $scope.choise.editItem.document = item;
    cov_t5cqusk7i.s[212]++;
    $scope.choise.editItem.IDRAPPORTS = item.id;
    cov_t5cqusk7i.s[213]++;
    $scope.typeSelect = null;
  };
  cov_t5cqusk7i.s[214]++;
  $scope.localValidation = function () {
    cov_t5cqusk7i.f[29]++;
    cov_t5cqusk7i.s[215]++;

    $scope.isloading = true;
    cov_t5cqusk7i.s[216]++;
    $scope.validate($scope.closeModal);
  };
  cov_t5cqusk7i.s[217]++;
  $scope.closeModal = function () {
    cov_t5cqusk7i.f[30]++;
    cov_t5cqusk7i.s[218]++;

    $uibModalInstance.dismiss('cancel');
  };
  cov_t5cqusk7i.s[219]++;
  $scope.filterDataDoc_bksb__ = function (item, b, c, d) {
    cov_t5cqusk7i.f[31]++;
    cov_t5cqusk7i.s[220]++;

    if ((cov_t5cqusk7i.b[48][0]++, angular.isDefined(item.IDCLASSEURS)) && (cov_t5cqusk7i.b[48][1]++, $scope.typeSelect == 1)) {
      cov_t5cqusk7i.b[47][0]++;
      cov_t5cqusk7i.s[221]++;

      return false;
    } else {
      cov_t5cqusk7i.b[47][1]++;
    }
    cov_t5cqusk7i.s[222]++;
    return $scope.filterDataDoc_bksb(item, b, c, d);
  };

  cov_t5cqusk7i.s[223]++;
  $scope.addnewdocument = function (folder) {
    cov_t5cqusk7i.f[32]++;
    cov_t5cqusk7i.s[224]++;

    $state.go('document.edit', { id: 0, origin: $state.current.name, params: { idcl: folder.id, file: '', type: 1, item: angular.copy($scope.choise.editItem), listAllThPhDoc: $scope.listAllThPhDoc } });
  };

  cov_t5cqusk7i.s[225]++;
  $scope.addnewlink = function (folder) {
    cov_t5cqusk7i.f[33]++;
    cov_t5cqusk7i.s[226]++;

    $state.go('document.edit', { id: 0, origin: $state.current.name, params: { idcl: folder.id, file: '', type: 2, item: angular.copy($scope.choise.editItem), listAllThPhDoc: $scope.listAllThPhDoc } });
  };

  var uploader = (cov_t5cqusk7i.s[227]++, $scope.uploader = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadFile&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + PATH_DOC,
    alias: 'uploadFiles',
    removeAfterUpload: true
  }));
  cov_t5cqusk7i.s[228]++;
  uploader.onAfterAddingFile = function (fileItem) {
    cov_t5cqusk7i.f[34]++;
    cov_t5cqusk7i.s[229]++;

    fileItem.formData = [{ path: '/198/' }, { action: 'save' }, { data: angular.toJson({
        path: null, action: null, newName: null, names: null, name: '/', size: 0,
        previousName: null, dateModified: '2019-12-17T10:47:37.69301+00:00',
        dateCreated: '2019-12-17T13:23:02.2029559+00:00', hasChild: true,
        isFile: false, type: '', id: null, filterPath: '', filterId: null,
        targetPath: null, renameFiles: null, uploadFiles: null, caseSensitive: false,
        searchString: null, showHiddenItems: false, data: null, targetData: null, permission: null,
        _fm_id: 'fe_tree_0'
      }) }];
    cov_t5cqusk7i.s[230]++;
    fileItem.url = uploader.url; // +"&indic="+fileItem.indic.id;
    cov_t5cqusk7i.s[231]++;
    $scope.file = fileItem;
    cov_t5cqusk7i.s[232]++;
    $scope.isloading = true;
    cov_t5cqusk7i.s[233]++;
    $scope.file.url += '&temp=1&saveRapport_new=__' + $CRUDService.getUser() + '__';
    cov_t5cqusk7i.s[234]++;
    $scope.file.upload();
  };
  cov_t5cqusk7i.s[235]++;
  uploader.onSuccessItem = function (fileItem) {
    cov_t5cqusk7i.f[35]++;
    cov_t5cqusk7i.s[236]++;

    $scope.isloading = false;
    cov_t5cqusk7i.s[237]++;
    $state.go('document.edit', { id: 0, origin: $state.current.name, params: { idcl: fileItem.id, file: fileItem.file.name, item: angular.copy($scope.choise.editItem), listAllThPhDoc: $scope.listAllThPhDoc } });
  };
  cov_t5cqusk7i.s[238]++;
  uploader.onErrorItem = function () {
    cov_t5cqusk7i.f[36]++;
    cov_t5cqusk7i.s[239]++;

    $scope.isloading = false;
    cov_t5cqusk7i.s[240]++;
    $scope.closeModal();
  };
}