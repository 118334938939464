'use strict';

var cov_1zaa8mkq1e = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/modules/colorpicker.js',
      hash = 'b9f784e939ac80b03b28cf9d57bfbe48f1fc19d7',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/modules/colorpicker.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 589,
          column: 6
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 79,
          column: 6
        }
      },
      '2': {
        start: {
          line: 6,
          column: 32
        },
        end: {
          line: 6,
          column: 127
        }
      },
      '3': {
        start: {
          line: 7,
          column: 8
        },
        end: {
          line: 9,
          column: 9
        }
      },
      '4': {
        start: {
          line: 8,
          column: 10
        },
        end: {
          line: 8,
          column: 33
        }
      },
      '5': {
        start: {
          line: 10,
          column: 8
        },
        end: {
          line: 10,
          column: 20
        }
      },
      '6': {
        start: {
          line: 14,
          column: 20
        },
        end: {
          line: 14,
          column: 21
        }
      },
      '7': {
        start: {
          line: 15,
          column: 20
        },
        end: {
          line: 15,
          column: 21
        }
      },
      '8': {
        start: {
          line: 16,
          column: 17
        },
        end: {
          line: 16,
          column: 45
        }
      },
      '9': {
        start: {
          line: 17,
          column: 8
        },
        end: {
          line: 26,
          column: 9
        }
      },
      '10': {
        start: {
          line: 18,
          column: 10
        },
        end: {
          line: 24,
          column: 11
        }
      },
      '11': {
        start: {
          line: 19,
          column: 12
        },
        end: {
          line: 19,
          column: 78
        }
      },
      '12': {
        start: {
          line: 20,
          column: 12
        },
        end: {
          line: 20,
          column: 76
        }
      },
      '13': {
        start: {
          line: 22,
          column: 12
        },
        end: {
          line: 22,
          column: 39
        }
      },
      '14': {
        start: {
          line: 23,
          column: 12
        },
        end: {
          line: 23,
          column: 38
        }
      },
      '15': {
        start: {
          line: 25,
          column: 10
        },
        end: {
          line: 25,
          column: 35
        }
      },
      '16': {
        start: {
          line: 27,
          column: 8
        },
        end: {
          line: 32,
          column: 10
        }
      },
      '17': {
        start: {
          line: 39,
          column: 12
        },
        end: {
          line: 44,
          column: 14
        }
      },
      '18': {
        start: {
          line: 50,
          column: 12
        },
        end: {
          line: 55,
          column: 14
        }
      },
      '19': {
        start: {
          line: 61,
          column: 12
        },
        end: {
          line: 65,
          column: 14
        }
      },
      '20': {
        start: {
          line: 71,
          column: 12
        },
        end: {
          line: 75,
          column: 14
        }
      },
      '21': {
        start: {
          line: 83,
          column: 4
        },
        end: {
          line: 189,
          column: 6
        }
      },
      '22': {
        start: {
          line: 92,
          column: 20
        },
        end: {
          line: 92,
          column: 32
        }
      },
      '23': {
        start: {
          line: 93,
          column: 8
        },
        end: {
          line: 93,
          column: 64
        }
      },
      '24': {
        start: {
          line: 96,
          column: 20
        },
        end: {
          line: 96,
          column: 32
        }
      },
      '25': {
        start: {
          line: 97,
          column: 8
        },
        end: {
          line: 97,
          column: 57
        }
      },
      '26': {
        start: {
          line: 100,
          column: 20
        },
        end: {
          line: 100,
          column: 32
        }
      },
      '27': {
        start: {
          line: 101,
          column: 8
        },
        end: {
          line: 101,
          column: 79
        }
      },
      '28': {
        start: {
          line: 104,
          column: 8
        },
        end: {
          line: 104,
          column: 28
        }
      },
      '29': {
        start: {
          line: 109,
          column: 8
        },
        end: {
          line: 109,
          column: 17
        }
      },
      '30': {
        start: {
          line: 110,
          column: 8
        },
        end: {
          line: 110,
          column: 17
        }
      },
      '31': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 17
        }
      },
      '32': {
        start: {
          line: 114,
          column: 8
        },
        end: {
          line: 114,
          column: 30
        }
      },
      '33': {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 115,
          column: 34
        }
      },
      '34': {
        start: {
          line: 116,
          column: 8
        },
        end: {
          line: 120,
          column: 10
        }
      },
      '35': {
        start: {
          line: 121,
          column: 8
        },
        end: {
          line: 121,
          column: 39
        }
      },
      '36': {
        start: {
          line: 122,
          column: 8
        },
        end: {
          line: 122,
          column: 32
        }
      },
      '37': {
        start: {
          line: 123,
          column: 8
        },
        end: {
          line: 123,
          column: 50
        }
      },
      '38': {
        start: {
          line: 128,
          column: 8
        },
        end: {
          line: 128,
          column: 46
        }
      },
      '39': {
        start: {
          line: 129,
          column: 8
        },
        end: {
          line: 139,
          column: 9
        }
      },
      '40': {
        start: {
          line: 130,
          column: 10
        },
        end: {
          line: 138,
          column: 11
        }
      },
      '41': {
        start: {
          line: 131,
          column: 27
        },
        end: {
          line: 131,
          column: 52
        }
      },
      '42': {
        start: {
          line: 132,
          column: 24
        },
        end: {
          line: 132,
          column: 43
        }
      },
      '43': {
        start: {
          line: 133,
          column: 23
        },
        end: {
          line: 133,
          column: 51
        }
      },
      '44': {
        start: {
          line: 134,
          column: 12
        },
        end: {
          line: 137,
          column: 13
        }
      },
      '45': {
        start: {
          line: 135,
          column: 14
        },
        end: {
          line: 135,
          column: 61
        }
      },
      '46': {
        start: {
          line: 136,
          column: 14
        },
        end: {
          line: 136,
          column: 27
        }
      },
      '47': {
        start: {
          line: 143,
          column: 8
        },
        end: {
          line: 143,
          column: 29
        }
      },
      '48': {
        start: {
          line: 147,
          column: 8
        },
        end: {
          line: 147,
          column: 25
        }
      },
      '49': {
        start: {
          line: 151,
          column: 8
        },
        end: {
          line: 151,
          column: 29
        }
      },
      '50': {
        start: {
          line: 155,
          column: 8
        },
        end: {
          line: 155,
          column: 57
        }
      },
      '51': {
        start: {
          line: 161,
          column: 8
        },
        end: {
          line: 165,
          column: 9
        }
      },
      '52': {
        start: {
          line: 162,
          column: 10
        },
        end: {
          line: 162,
          column: 27
        }
      },
      '53': {
        start: {
          line: 163,
          column: 10
        },
        end: {
          line: 163,
          column: 27
        }
      },
      '54': {
        start: {
          line: 164,
          column: 10
        },
        end: {
          line: 164,
          column: 27
        }
      },
      '55': {
        start: {
          line: 166,
          column: 8
        },
        end: {
          line: 166,
          column: 17
        }
      },
      '56': {
        start: {
          line: 168,
          column: 8
        },
        end: {
          line: 168,
          column: 27
        }
      },
      '57': {
        start: {
          line: 169,
          column: 8
        },
        end: {
          line: 169,
          column: 18
        }
      },
      '58': {
        start: {
          line: 170,
          column: 8
        },
        end: {
          line: 170,
          column: 42
        }
      },
      '59': {
        start: {
          line: 171,
          column: 8
        },
        end: {
          line: 171,
          column: 26
        }
      },
      '60': {
        start: {
          line: 173,
          column: 8
        },
        end: {
          line: 173,
          column: 16
        }
      },
      '61': {
        start: {
          line: 174,
          column: 8
        },
        end: {
          line: 174,
          column: 35
        }
      },
      '62': {
        start: {
          line: 175,
          column: 8
        },
        end: {
          line: 175,
          column: 35
        }
      },
      '63': {
        start: {
          line: 176,
          column: 8
        },
        end: {
          line: 176,
          column: 35
        }
      },
      '64': {
        start: {
          line: 177,
          column: 8
        },
        end: {
          line: 182,
          column: 10
        }
      },
      '65': {
        start: {
          line: 186,
          column: 20
        },
        end: {
          line: 186,
          column: 42
        }
      },
      '66': {
        start: {
          line: 187,
          column: 8
        },
        end: {
          line: 187,
          column: 137
        }
      },
      '67': {
        start: {
          line: 194,
          column: 15
        },
        end: {
          line: 203,
          column: 7
        }
      },
      '68': {
        start: {
          line: 204,
          column: 16
        },
        end: {
          line: 204,
          column: 18
        }
      },
      '69': {
        start: {
          line: 206,
          column: 4
        },
        end: {
          line: 264,
          column: 6
        }
      },
      '70': {
        start: {
          line: 208,
          column: 8
        },
        end: {
          line: 208,
          column: 22
        }
      },
      '71': {
        start: {
          line: 211,
          column: 8
        },
        end: {
          line: 211,
          column: 115
        }
      },
      '72': {
        start: {
          line: 214,
          column: 8
        },
        end: {
          line: 214,
          column: 111
        }
      },
      '73': {
        start: {
          line: 218,
          column: 19
        },
        end: {
          line: 218,
          column: 53
        }
      },
      '74': {
        start: {
          line: 219,
          column: 25
        },
        end: {
          line: 219,
          column: 64
        }
      },
      '75': {
        start: {
          line: 220,
          column: 17
        },
        end: {
          line: 220,
          column: 47
        }
      },
      '76': {
        start: {
          line: 221,
          column: 20
        },
        end: {
          line: 221,
          column: 45
        }
      },
      '77': {
        start: {
          line: 222,
          column: 20
        },
        end: {
          line: 222,
          column: 44
        }
      },
      '78': {
        start: {
          line: 224,
          column: 8
        },
        end: {
          line: 224,
          column: 47
        }
      },
      '79': {
        start: {
          line: 225,
          column: 8
        },
        end: {
          line: 225,
          column: 98
        }
      },
      '80': {
        start: {
          line: 226,
          column: 8
        },
        end: {
          line: 226,
          column: 96
        }
      },
      '81': {
        start: {
          line: 228,
          column: 8
        },
        end: {
          line: 231,
          column: 10
        }
      },
      '82': {
        start: {
          line: 234,
          column: 8
        },
        end: {
          line: 239,
          column: 10
        }
      },
      '83': {
        start: {
          line: 240,
          column: 8
        },
        end: {
          line: 240,
          column: 45
        }
      },
      '84': {
        start: {
          line: 243,
          column: 8
        },
        end: {
          line: 248,
          column: 10
        }
      },
      '85': {
        start: {
          line: 249,
          column: 8
        },
        end: {
          line: 249,
          column: 45
        }
      },
      '86': {
        start: {
          line: 252,
          column: 8
        },
        end: {
          line: 257,
          column: 10
        }
      },
      '87': {
        start: {
          line: 258,
          column: 8
        },
        end: {
          line: 258,
          column: 45
        }
      },
      '88': {
        start: {
          line: 261,
          column: 8
        },
        end: {
          line: 261,
          column: 37
        }
      },
      '89': {
        start: {
          line: 262,
          column: 8
        },
        end: {
          line: 262,
          column: 39
        }
      },
      '90': {
        start: {
          line: 268,
          column: 4
        },
        end: {
          line: 588,
          column: 6
        }
      },
      '91': {
        start: {
          line: 273,
          column: 23
        },
        end: {
          line: 273,
          column: 80
        }
      },
      '92': {
        start: {
          line: 274,
          column: 21
        },
        end: {
          line: 274,
          column: 104
        }
      },
      '93': {
        start: {
          line: 275,
          column: 19
        },
        end: {
          line: 275,
          column: 95
        }
      },
      '94': {
        start: {
          line: 276,
          column: 26
        },
        end: {
          line: 276,
          column: 116
        }
      },
      '95': {
        start: {
          line: 277,
          column: 19
        },
        end: {
          line: 277,
          column: 110
        }
      },
      '96': {
        start: {
          line: 278,
          column: 22
        },
        end: {
          line: 278,
          column: 104
        }
      },
      '97': {
        start: {
          line: 279,
          column: 26
        },
        end: {
          line: 279,
          column: 96
        }
      },
      '98': {
        start: {
          line: 280,
          column: 28
        },
        end: {
          line: 280,
          column: 48
        }
      },
      '99': {
        start: {
          line: 281,
          column: 26
        },
        end: {
          line: 281,
          column: 108
        }
      },
      '100': {
        start: {
          line: 282,
          column: 24
        },
        end: {
          line: 282,
          column: 111
        }
      },
      '101': {
        start: {
          line: 284,
          column: 12
        },
        end: {
          line: 293,
          column: 20
        }
      },
      '102': {
        start: {
          line: 294,
          column: 32
        },
        end: {
          line: 294,
          column: 57
        }
      },
      '103': {
        start: {
          line: 295,
          column: 24
        },
        end: {
          line: 295,
          column: 29
        }
      },
      '104': {
        start: {
          line: 296,
          column: 29
        },
        end: {
          line: 301,
          column: 11
        }
      },
      '105': {
        start: {
          line: 302,
          column: 22
        },
        end: {
          line: 302,
          column: 65
        }
      },
      '106': {
        start: {
          line: 303,
          column: 29
        },
        end: {
          line: 303,
          column: 79
        }
      },
      '107': {
        start: {
          line: 304,
          column: 31
        },
        end: {
          line: 304,
          column: 78
        }
      },
      '108': {
        start: {
          line: 305,
          column: 32
        },
        end: {
          line: 305,
          column: 61
        }
      },
      '109': {
        start: {
          line: 306,
          column: 39
        },
        end: {
          line: 306,
          column: 102
        }
      },
      '110': {
        start: {
          line: 307,
          column: 40
        },
        end: {
          line: 307,
          column: 68
        }
      },
      '111': {
        start: {
          line: 310,
          column: 8
        },
        end: {
          line: 310,
          column: 46
        }
      },
      '112': {
        start: {
          line: 311,
          column: 8
        },
        end: {
          line: 311,
          column: 80
        }
      },
      '113': {
        start: {
          line: 312,
          column: 8
        },
        end: {
          line: 315,
          column: 11
        }
      },
      '114': {
        start: {
          line: 316,
          column: 8
        },
        end: {
          line: 316,
          column: 49
        }
      },
      '115': {
        start: {
          line: 318,
          column: 8
        },
        end: {
          line: 333,
          column: 9
        }
      },
      '116': {
        start: {
          line: 319,
          column: 33
        },
        end: {
          line: 319,
          column: 66
        }
      },
      '117': {
        start: {
          line: 320,
          column: 10
        },
        end: {
          line: 320,
          column: 57
        }
      },
      '118': {
        start: {
          line: 321,
          column: 10
        },
        end: {
          line: 332,
          column: 15
        }
      },
      '119': {
        start: {
          line: 323,
          column: 14
        },
        end: {
          line: 323,
          column: 38
        }
      },
      '120': {
        start: {
          line: 326,
          column: 31
        },
        end: {
          line: 326,
          column: 41
        }
      },
      '121': {
        start: {
          line: 327,
          column: 14
        },
        end: {
          line: 327,
          column: 33
        }
      },
      '122': {
        start: {
          line: 328,
          column: 14
        },
        end: {
          line: 331,
          column: 15
        }
      },
      '123': {
        start: {
          line: 329,
          column: 16
        },
        end: {
          line: 329,
          column: 63
        }
      },
      '124': {
        start: {
          line: 330,
          column: 16
        },
        end: {
          line: 330,
          column: 29
        }
      },
      '125': {
        start: {
          line: 336,
          column: 10
        },
        end: {
          line: 336,
          column: 47
        }
      },
      '126': {
        start: {
          line: 337,
          column: 10
        },
        end: {
          line: 337,
          column: 43
        }
      },
      '127': {
        start: {
          line: 340,
          column: 8
        },
        end: {
          line: 356,
          column: 9
        }
      },
      '128': {
        start: {
          line: 341,
          column: 10
        },
        end: {
          line: 341,
          column: 48
        }
      },
      '129': {
        start: {
          line: 342,
          column: 10
        },
        end: {
          line: 342,
          column: 70
        }
      },
      '130': {
        start: {
          line: 343,
          column: 10
        },
        end: {
          line: 343,
          column: 53
        }
      },
      '131': {
        start: {
          line: 344,
          column: 10
        },
        end: {
          line: 355,
          column: 15
        }
      },
      '132': {
        start: {
          line: 346,
          column: 14
        },
        end: {
          line: 346,
          column: 67
        }
      },
      '133': {
        start: {
          line: 347,
          column: 14
        },
        end: {
          line: 347,
          column: 31
        }
      },
      '134': {
        start: {
          line: 350,
          column: 14
        },
        end: {
          line: 350,
          column: 67
        }
      },
      '135': {
        start: {
          line: 351,
          column: 14
        },
        end: {
          line: 351,
          column: 32
        }
      },
      '136': {
        start: {
          line: 354,
          column: 14
        },
        end: {
          line: 354,
          column: 54
        }
      },
      '137': {
        start: {
          line: 358,
          column: 8
        },
        end: {
          line: 369,
          column: 13
        }
      },
      '138': {
        start: {
          line: 360,
          column: 12
        },
        end: {
          line: 360,
          column: 63
        }
      },
      '139': {
        start: {
          line: 361,
          column: 12
        },
        end: {
          line: 361,
          column: 29
        }
      },
      '140': {
        start: {
          line: 364,
          column: 12
        },
        end: {
          line: 364,
          column: 63
        }
      },
      '141': {
        start: {
          line: 365,
          column: 12
        },
        end: {
          line: 365,
          column: 30
        }
      },
      '142': {
        start: {
          line: 368,
          column: 12
        },
        end: {
          line: 368,
          column: 50
        }
      },
      '143': {
        start: {
          line: 371,
          column: 8
        },
        end: {
          line: 385,
          column: 13
        }
      },
      '144': {
        start: {
          line: 373,
          column: 12
        },
        end: {
          line: 373,
          column: 70
        }
      },
      '145': {
        start: {
          line: 374,
          column: 12
        },
        end: {
          line: 374,
          column: 29
        }
      },
      '146': {
        start: {
          line: 375,
          column: 12
        },
        end: {
          line: 377,
          column: 13
        }
      },
      '147': {
        start: {
          line: 376,
          column: 14
        },
        end: {
          line: 376,
          column: 40
        }
      },
      '148': {
        start: {
          line: 380,
          column: 12
        },
        end: {
          line: 380,
          column: 70
        }
      },
      '149': {
        start: {
          line: 381,
          column: 12
        },
        end: {
          line: 381,
          column: 30
        }
      },
      '150': {
        start: {
          line: 384,
          column: 12
        },
        end: {
          line: 384,
          column: 57
        }
      },
      '151': {
        start: {
          line: 387,
          column: 8
        },
        end: {
          line: 389,
          column: 9
        }
      },
      '152': {
        start: {
          line: 388,
          column: 10
        },
        end: {
          line: 388,
          column: 69
        }
      },
      '153': {
        start: {
          line: 391,
          column: 8
        },
        end: {
          line: 391,
          column: 73
        }
      },
      '154': {
        start: {
          line: 392,
          column: 8
        },
        end: {
          line: 394,
          column: 9
        }
      },
      '155': {
        start: {
          line: 393,
          column: 10
        },
        end: {
          line: 393,
          column: 61
        }
      },
      '156': {
        start: {
          line: 396,
          column: 8
        },
        end: {
          line: 396,
          column: 43
        }
      },
      '157': {
        start: {
          line: 398,
          column: 8
        },
        end: {
          line: 404,
          column: 9
        }
      },
      '158': {
        start: {
          line: 399,
          column: 10
        },
        end: {
          line: 403,
          column: 12
        }
      },
      '159': {
        start: {
          line: 400,
          column: 12
        },
        end: {
          line: 400,
          column: 41
        }
      },
      '160': {
        start: {
          line: 402,
          column: 12
        },
        end: {
          line: 402,
          column: 21
        }
      },
      '161': {
        start: {
          line: 406,
          column: 8
        },
        end: {
          line: 408,
          column: 11
        }
      },
      '162': {
        start: {
          line: 407,
          column: 10
        },
        end: {
          line: 407,
          column: 19
        }
      },
      '163': {
        start: {
          line: 410,
          column: 8
        },
        end: {
          line: 412,
          column: 11
        }
      },
      '164': {
        start: {
          line: 411,
          column: 10
        },
        end: {
          line: 411,
          column: 39
        }
      },
      '165': {
        start: {
          line: 415,
          column: 10
        },
        end: {
          line: 419,
          column: 11
        }
      },
      '166': {
        start: {
          line: 416,
          column: 12
        },
        end: {
          line: 416,
          column: 81
        }
      },
      '167': {
        start: {
          line: 418,
          column: 12
        },
        end: {
          line: 418,
          column: 75
        }
      },
      '168': {
        start: {
          line: 420,
          column: 10
        },
        end: {
          line: 420,
          column: 99
        }
      },
      '169': {
        start: {
          line: 421,
          column: 10
        },
        end: {
          line: 423,
          column: 11
        }
      },
      '170': {
        start: {
          line: 422,
          column: 12
        },
        end: {
          line: 422,
          column: 66
        }
      },
      '171': {
        start: {
          line: 428,
          column: 19
        },
        end: {
          line: 428,
          column: 48
        }
      },
      '172': {
        start: {
          line: 429,
          column: 18
        },
        end: {
          line: 429,
          column: 46
        }
      },
      '173': {
        start: {
          line: 430,
          column: 21
        },
        end: {
          line: 430,
          column: 39
        }
      },
      '174': {
        start: {
          line: 432,
          column: 10
        },
        end: {
          line: 432,
          column: 36
        }
      },
      '175': {
        start: {
          line: 434,
          column: 10
        },
        end: {
          line: 436,
          column: 11
        }
      },
      '176': {
        start: {
          line: 435,
          column: 12
        },
        end: {
          line: 435,
          column: 81
        }
      },
      '177': {
        start: {
          line: 437,
          column: 10
        },
        end: {
          line: 439,
          column: 11
        }
      },
      '178': {
        start: {
          line: 438,
          column: 12
        },
        end: {
          line: 438,
          column: 79
        }
      },
      '179': {
        start: {
          line: 440,
          column: 10
        },
        end: {
          line: 440,
          column: 25
        }
      },
      '180': {
        start: {
          line: 441,
          column: 27
        },
        end: {
          line: 441,
          column: 52
        }
      },
      '181': {
        start: {
          line: 442,
          column: 10
        },
        end: {
          line: 442,
          column: 29
        }
      },
      '182': {
        start: {
          line: 443,
          column: 10
        },
        end: {
          line: 445,
          column: 11
        }
      },
      '183': {
        start: {
          line: 444,
          column: 12
        },
        end: {
          line: 444,
          column: 59
        }
      },
      '184': {
        start: {
          line: 446,
          column: 10
        },
        end: {
          line: 448,
          column: 11
        }
      },
      '185': {
        start: {
          line: 447,
          column: 12
        },
        end: {
          line: 447,
          column: 43
        }
      },
      '186': {
        start: {
          line: 449,
          column: 10
        },
        end: {
          line: 449,
          column: 23
        }
      },
      '187': {
        start: {
          line: 453,
          column: 10
        },
        end: {
          line: 453,
          column: 44
        }
      },
      '188': {
        start: {
          line: 454,
          column: 10
        },
        end: {
          line: 454,
          column: 48
        }
      },
      '189': {
        start: {
          line: 455,
          column: 10
        },
        end: {
          line: 455,
          column: 44
        }
      },
      '190': {
        start: {
          line: 459,
          column: 10
        },
        end: {
          line: 459,
          column: 47
        }
      },
      '191': {
        start: {
          line: 460,
          column: 10
        },
        end: {
          line: 460,
          column: 43
        }
      },
      '192': {
        start: {
          line: 461,
          column: 10
        },
        end: {
          line: 463,
          column: 11
        }
      },
      '193': {
        start: {
          line: 462,
          column: 12
        },
        end: {
          line: 462,
          column: 45
        }
      },
      '194': {
        start: {
          line: 464,
          column: 10
        },
        end: {
          line: 467,
          column: 13
        }
      },
      '195': {
        start: {
          line: 468,
          column: 10
        },
        end: {
          line: 468,
          column: 97
        }
      },
      '196': {
        start: {
          line: 469,
          column: 10
        },
        end: {
          line: 469,
          column: 97
        }
      },
      '197': {
        start: {
          line: 470,
          column: 10
        },
        end: {
          line: 470,
          column: 47
        }
      },
      '198': {
        start: {
          line: 471,
          column: 10
        },
        end: {
          line: 471,
          column: 25
        }
      },
      '199': {
        start: {
          line: 477,
          column: 29
        },
        end: {
          line: 477,
          column: 54
        }
      },
      '200': {
        start: {
          line: 478,
          column: 45
        },
        end: {
          line: 478,
          column: 46
        }
      },
      '201': {
        start: {
          line: 480,
          column: 10
        },
        end: {
          line: 483,
          column: 11
        }
      },
      '202': {
        start: {
          line: 481,
          column: 12
        },
        end: {
          line: 481,
          column: 36
        }
      },
      '203': {
        start: {
          line: 482,
          column: 12
        },
        end: {
          line: 482,
          column: 35
        }
      },
      '204': {
        start: {
          line: 485,
          column: 10
        },
        end: {
          line: 505,
          column: 11
        }
      },
      '205': {
        start: {
          line: 486,
          column: 12
        },
        end: {
          line: 489,
          column: 14
        }
      },
      '206': {
        start: {
          line: 490,
          column: 17
        },
        end: {
          line: 505,
          column: 11
        }
      },
      '207': {
        start: {
          line: 491,
          column: 12
        },
        end: {
          line: 494,
          column: 14
        }
      },
      '208': {
        start: {
          line: 495,
          column: 17
        },
        end: {
          line: 505,
          column: 11
        }
      },
      '209': {
        start: {
          line: 496,
          column: 12
        },
        end: {
          line: 499,
          column: 14
        }
      },
      '210': {
        start: {
          line: 500,
          column: 17
        },
        end: {
          line: 505,
          column: 11
        }
      },
      '211': {
        start: {
          line: 501,
          column: 12
        },
        end: {
          line: 504,
          column: 14
        }
      },
      '212': {
        start: {
          line: 506,
          column: 10
        },
        end: {
          line: 509,
          column: 12
        }
      },
      '213': {
        start: {
          line: 513,
          column: 10
        },
        end: {
          line: 513,
          column: 36
        }
      },
      '214': {
        start: {
          line: 517,
          column: 10
        },
        end: {
          line: 535,
          column: 11
        }
      },
      '215': {
        start: {
          line: 518,
          column: 12
        },
        end: {
          line: 518,
          column: 21
        }
      },
      '216': {
        start: {
          line: 519,
          column: 12
        },
        end: {
          line: 521,
          column: 53
        }
      },
      '217': {
        start: {
          line: 522,
          column: 12
        },
        end: {
          line: 522,
          column: 43
        }
      },
      '218': {
        start: {
          line: 524,
          column: 12
        },
        end: {
          line: 527,
          column: 13
        }
      },
      '219': {
        start: {
          line: 526,
          column: 14
        },
        end: {
          line: 526,
          column: 66
        }
      },
      '220': {
        start: {
          line: 529,
          column: 12
        },
        end: {
          line: 534,
          column: 13
        }
      },
      '221': {
        start: {
          line: 530,
          column: 14
        },
        end: {
          line: 530,
          column: 53
        }
      },
      '222': {
        start: {
          line: 531,
          column: 14
        },
        end: {
          line: 533,
          column: 15
        }
      },
      '223': {
        start: {
          line: 532,
          column: 16
        },
        end: {
          line: 532,
          column: 33
        }
      },
      '224': {
        start: {
          line: 538,
          column: 8
        },
        end: {
          line: 542,
          column: 9
        }
      },
      '225': {
        start: {
          line: 539,
          column: 10
        },
        end: {
          line: 539,
          column: 52
        }
      },
      '226': {
        start: {
          line: 541,
          column: 10
        },
        end: {
          line: 541,
          column: 36
        }
      },
      '227': {
        start: {
          line: 544,
          column: 8
        },
        end: {
          line: 547,
          column: 11
        }
      },
      '228': {
        start: {
          line: 545,
          column: 10
        },
        end: {
          line: 545,
          column: 34
        }
      },
      '229': {
        start: {
          line: 546,
          column: 10
        },
        end: {
          line: 546,
          column: 33
        }
      },
      '230': {
        start: {
          line: 550,
          column: 10
        },
        end: {
          line: 555,
          column: 11
        }
      },
      '231': {
        start: {
          line: 551,
          column: 12
        },
        end: {
          line: 554,
          column: 15
        }
      },
      '232': {
        start: {
          line: 559,
          column: 10
        },
        end: {
          line: 571,
          column: 11
        }
      },
      '233': {
        start: {
          line: 560,
          column: 12
        },
        end: {
          line: 560,
          column: 67
        }
      },
      '234': {
        start: {
          line: 561,
          column: 12
        },
        end: {
          line: 561,
          column: 44
        }
      },
      '235': {
        start: {
          line: 563,
          column: 12
        },
        end: {
          line: 563,
          column: 65
        }
      },
      '236': {
        start: {
          line: 565,
          column: 12
        },
        end: {
          line: 570,
          column: 13
        }
      },
      '237': {
        start: {
          line: 566,
          column: 14
        },
        end: {
          line: 566,
          column: 54
        }
      },
      '238': {
        start: {
          line: 567,
          column: 14
        },
        end: {
          line: 569,
          column: 15
        }
      },
      '239': {
        start: {
          line: 568,
          column: 16
        },
        end: {
          line: 568,
          column: 33
        }
      },
      '240': {
        start: {
          line: 574,
          column: 8
        },
        end: {
          line: 576,
          column: 11
        }
      },
      '241': {
        start: {
          line: 575,
          column: 10
        },
        end: {
          line: 575,
          column: 36
        }
      },
      '242': {
        start: {
          line: 578,
          column: 8
        },
        end: {
          line: 586,
          column: 9
        }
      },
      '243': {
        start: {
          line: 579,
          column: 10
        },
        end: {
          line: 585,
          column: 13
        }
      },
      '244': {
        start: {
          line: 580,
          column: 12
        },
        end: {
          line: 584,
          column: 13
        }
      },
      '245': {
        start: {
          line: 581,
          column: 14
        },
        end: {
          line: 581,
          column: 40
        }
      },
      '246': {
        start: {
          line: 582,
          column: 19
        },
        end: {
          line: 584,
          column: 13
        }
      },
      '247': {
        start: {
          line: 583,
          column: 14
        },
        end: {
          line: 583,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 21
          },
          end: {
            line: 2,
            column: 22
          }
        },
        loc: {
          start: {
            line: 2,
            column: 27
          },
          end: {
            line: 80,
            column: 3
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 81,
            column: 31
          },
          end: {
            line: 81,
            column: 32
          }
        },
        loc: {
          start: {
            line: 81,
            column: 49
          },
          end: {
            line: 190,
            column: 3
          }
        },
        line: 81
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 191,
            column: 32
          },
          end: {
            line: 191,
            column: 33
          }
        },
        loc: {
          start: {
            line: 191,
            column: 50
          },
          end: {
            line: 265,
            column: 3
          }
        },
        line: 191
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 266,
            column: 89
          },
          end: {
            line: 266,
            column: 90
          }
        },
        loc: {
          start: {
            line: 266,
            column: 143
          },
          end: {
            line: 589,
            column: 3
          }
        },
        line: 266
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 322,
            column: 29
          },
          end: {
            line: 322,
            column: 30
          }
        },
        loc: {
          start: {
            line: 322,
            column: 38
          },
          end: {
            line: 324,
            column: 13
          }
        },
        line: 322
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 325,
            column: 25
          },
          end: {
            line: 325,
            column: 26
          }
        },
        loc: {
          start: {
            line: 325,
            column: 37
          },
          end: {
            line: 332,
            column: 13
          }
        },
        line: 325
      },
      '6': {
        name: 'bindMouseEvents',
        decl: {
          start: {
            line: 335,
            column: 17
          },
          end: {
            line: 335,
            column: 32
          }
        },
        loc: {
          start: {
            line: 335,
            column: 35
          },
          end: {
            line: 338,
            column: 9
          }
        },
        line: 335
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 345,
            column: 25
          },
          end: {
            line: 345,
            column: 26
          }
        },
        loc: {
          start: {
            line: 345,
            column: 34
          },
          end: {
            line: 348,
            column: 13
          }
        },
        line: 345
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 349,
            column: 29
          },
          end: {
            line: 349,
            column: 30
          }
        },
        loc: {
          start: {
            line: 349,
            column: 38
          },
          end: {
            line: 352,
            column: 13
          }
        },
        line: 349
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 353,
            column: 27
          },
          end: {
            line: 353,
            column: 28
          }
        },
        loc: {
          start: {
            line: 353,
            column: 36
          },
          end: {
            line: 355,
            column: 13
          }
        },
        line: 353
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 359,
            column: 23
          },
          end: {
            line: 359,
            column: 24
          }
        },
        loc: {
          start: {
            line: 359,
            column: 32
          },
          end: {
            line: 362,
            column: 11
          }
        },
        line: 359
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 363,
            column: 27
          },
          end: {
            line: 363,
            column: 28
          }
        },
        loc: {
          start: {
            line: 363,
            column: 36
          },
          end: {
            line: 366,
            column: 11
          }
        },
        line: 363
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 367,
            column: 25
          },
          end: {
            line: 367,
            column: 26
          }
        },
        loc: {
          start: {
            line: 367,
            column: 34
          },
          end: {
            line: 369,
            column: 11
          }
        },
        line: 367
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 372,
            column: 23
          },
          end: {
            line: 372,
            column: 24
          }
        },
        loc: {
          start: {
            line: 372,
            column: 32
          },
          end: {
            line: 378,
            column: 11
          }
        },
        line: 372
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 379,
            column: 27
          },
          end: {
            line: 379,
            column: 28
          }
        },
        loc: {
          start: {
            line: 379,
            column: 36
          },
          end: {
            line: 382,
            column: 11
          }
        },
        line: 379
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 383,
            column: 25
          },
          end: {
            line: 383,
            column: 26
          }
        },
        loc: {
          start: {
            line: 383,
            column: 34
          },
          end: {
            line: 385,
            column: 11
          }
        },
        line: 383
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 399,
            column: 28
          },
          end: {
            line: 399,
            column: 29
          }
        },
        loc: {
          start: {
            line: 399,
            column: 40
          },
          end: {
            line: 403,
            column: 11
          }
        },
        line: 399
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 406,
            column: 37
          },
          end: {
            line: 406,
            column: 38
          }
        },
        loc: {
          start: {
            line: 406,
            column: 43
          },
          end: {
            line: 408,
            column: 9
          }
        },
        line: 406
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 410,
            column: 28
          },
          end: {
            line: 410,
            column: 29
          }
        },
        loc: {
          start: {
            line: 410,
            column: 34
          },
          end: {
            line: 412,
            column: 9
          }
        },
        line: 410
      },
      '19': {
        name: 'previewColor',
        decl: {
          start: {
            line: 414,
            column: 17
          },
          end: {
            line: 414,
            column: 29
          }
        },
        loc: {
          start: {
            line: 414,
            column: 32
          },
          end: {
            line: 424,
            column: 9
          }
        },
        line: 414
      },
      '20': {
        name: 'mousemove',
        decl: {
          start: {
            line: 426,
            column: 17
          },
          end: {
            line: 426,
            column: 26
          }
        },
        loc: {
          start: {
            line: 426,
            column: 34
          },
          end: {
            line: 450,
            column: 9
          }
        },
        line: 426
      },
      '21': {
        name: 'mouseup',
        decl: {
          start: {
            line: 452,
            column: 17
          },
          end: {
            line: 452,
            column: 24
          }
        },
        loc: {
          start: {
            line: 452,
            column: 27
          },
          end: {
            line: 456,
            column: 9
          }
        },
        line: 452
      },
      '22': {
        name: 'update',
        decl: {
          start: {
            line: 458,
            column: 17
          },
          end: {
            line: 458,
            column: 23
          }
        },
        loc: {
          start: {
            line: 458,
            column: 40
          },
          end: {
            line: 472,
            column: 9
          }
        },
        line: 458
      },
      '23': {
        name: 'getColorpickerTemplatePosition',
        decl: {
          start: {
            line: 474,
            column: 17
          },
          end: {
            line: 474,
            column: 47
          }
        },
        loc: {
          start: {
            line: 474,
            column: 50
          },
          end: {
            line: 510,
            column: 9
          }
        },
        line: 474
      },
      '24': {
        name: 'documentMousedownHandler',
        decl: {
          start: {
            line: 512,
            column: 17
          },
          end: {
            line: 512,
            column: 41
          }
        },
        loc: {
          start: {
            line: 512,
            column: 44
          },
          end: {
            line: 514,
            column: 9
          }
        },
        line: 512
      },
      '25': {
        name: 'showColorpickerTemplate',
        decl: {
          start: {
            line: 516,
            column: 17
          },
          end: {
            line: 516,
            column: 40
          }
        },
        loc: {
          start: {
            line: 516,
            column: 43
          },
          end: {
            line: 536,
            column: 9
          }
        },
        line: 516
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 544,
            column: 44
          },
          end: {
            line: 544,
            column: 45
          }
        },
        loc: {
          start: {
            line: 544,
            column: 53
          },
          end: {
            line: 547,
            column: 9
          }
        },
        line: 544
      },
      '27': {
        name: 'emitEvent',
        decl: {
          start: {
            line: 549,
            column: 17
          },
          end: {
            line: 549,
            column: 26
          }
        },
        loc: {
          start: {
            line: 549,
            column: 33
          },
          end: {
            line: 556,
            column: 9
          }
        },
        line: 549
      },
      '28': {
        name: 'hideColorpickerTemplate',
        decl: {
          start: {
            line: 558,
            column: 17
          },
          end: {
            line: 558,
            column: 40
          }
        },
        loc: {
          start: {
            line: 558,
            column: 43
          },
          end: {
            line: 572,
            column: 9
          }
        },
        line: 558
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 574,
            column: 55
          },
          end: {
            line: 574,
            column: 56
          }
        },
        loc: {
          start: {
            line: 574,
            column: 61
          },
          end: {
            line: 576,
            column: 9
          }
        },
        line: 574
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 579,
            column: 49
          },
          end: {
            line: 579,
            column: 50
          }
        },
        loc: {
          start: {
            line: 579,
            column: 65
          },
          end: {
            line: 585,
            column: 11
          }
        },
        line: 579
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 32
          },
          end: {
            line: 6,
            column: 127
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 6,
            column: 32
          },
          end: {
            line: 6,
            column: 44
          }
        }, {
          start: {
            line: 6,
            column: 48
          },
          end: {
            line: 6,
            column: 74
          }
        }, {
          start: {
            line: 6,
            column: 78
          },
          end: {
            line: 6,
            column: 101
          }
        }, {
          start: {
            line: 6,
            column: 105
          },
          end: {
            line: 6,
            column: 127
          }
        }],
        line: 6
      },
      '1': {
        loc: {
          start: {
            line: 7,
            column: 8
          },
          end: {
            line: 9,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 7,
            column: 8
          },
          end: {
            line: 9,
            column: 9
          }
        }, {
          start: {
            line: 7,
            column: 8
          },
          end: {
            line: 9,
            column: 9
          }
        }],
        line: 7
      },
      '2': {
        loc: {
          start: {
            line: 17,
            column: 15
          },
          end: {
            line: 17,
            column: 72
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 17,
            column: 15
          },
          end: {
            line: 17,
            column: 19
          }
        }, {
          start: {
            line: 17,
            column: 23
          },
          end: {
            line: 17,
            column: 46
          }
        }, {
          start: {
            line: 17,
            column: 50
          },
          end: {
            line: 17,
            column: 72
          }
        }],
        line: 17
      },
      '3': {
        loc: {
          start: {
            line: 18,
            column: 10
          },
          end: {
            line: 24,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 10
          },
          end: {
            line: 24,
            column: 11
          }
        }, {
          start: {
            line: 18,
            column: 10
          },
          end: {
            line: 24,
            column: 11
          }
        }],
        line: 18
      },
      '4': {
        loc: {
          start: {
            line: 18,
            column: 14
          },
          end: {
            line: 18,
            column: 55
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 18,
            column: 14
          },
          end: {
            line: 18,
            column: 28
          }
        }, {
          start: {
            line: 18,
            column: 32
          },
          end: {
            line: 18,
            column: 55
          }
        }],
        line: 18
      },
      '5': {
        loc: {
          start: {
            line: 19,
            column: 23
          },
          end: {
            line: 19,
            column: 77
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 19,
            column: 23
          },
          end: {
            line: 19,
            column: 58
          }
        }, {
          start: {
            line: 19,
            column: 62
          },
          end: {
            line: 19,
            column: 77
          }
        }],
        line: 19
      },
      '6': {
        loc: {
          start: {
            line: 20,
            column: 23
          },
          end: {
            line: 20,
            column: 75
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 20,
            column: 23
          },
          end: {
            line: 20,
            column: 57
          }
        }, {
          start: {
            line: 20,
            column: 61
          },
          end: {
            line: 20,
            column: 75
          }
        }],
        line: 20
      },
      '7': {
        loc: {
          start: {
            line: 116,
            column: 13
          },
          end: {
            line: 119,
            column: 31
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 116,
            column: 23
          },
          end: {
            line: 116,
            column: 27
          }
        }, {
          start: {
            line: 117,
            column: 12
          },
          end: {
            line: 119,
            column: 31
          }
        }],
        line: 116
      },
      '8': {
        loc: {
          start: {
            line: 117,
            column: 12
          },
          end: {
            line: 119,
            column: 31
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 117,
            column: 22
          },
          end: {
            line: 117,
            column: 33
          }
        }, {
          start: {
            line: 118,
            column: 14
          },
          end: {
            line: 119,
            column: 31
          }
        }],
        line: 117
      },
      '9': {
        loc: {
          start: {
            line: 118,
            column: 14
          },
          end: {
            line: 119,
            column: 31
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 118,
            column: 24
          },
          end: {
            line: 118,
            column: 39
          }
        }, {
          start: {
            line: 119,
            column: 16
          },
          end: {
            line: 119,
            column: 31
          }
        }],
        line: 118
      },
      '10': {
        loc: {
          start: {
            line: 122,
            column: 12
          },
          end: {
            line: 122,
            column: 31
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 122,
            column: 22
          },
          end: {
            line: 122,
            column: 23
          }
        }, {
          start: {
            line: 122,
            column: 26
          },
          end: {
            line: 122,
            column: 31
          }
        }],
        line: 122
      },
      '11': {
        loc: {
          start: {
            line: 123,
            column: 19
          },
          end: {
            line: 123,
            column: 25
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 123,
            column: 19
          },
          end: {
            line: 123,
            column: 20
          }
        }, {
          start: {
            line: 123,
            column: 24
          },
          end: {
            line: 123,
            column: 25
          }
        }],
        line: 123
      },
      '12': {
        loc: {
          start: {
            line: 123,
            column: 42
          },
          end: {
            line: 123,
            column: 48
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 123,
            column: 42
          },
          end: {
            line: 123,
            column: 43
          }
        }, {
          start: {
            line: 123,
            column: 47
          },
          end: {
            line: 123,
            column: 48
          }
        }],
        line: 123
      },
      '13': {
        loc: {
          start: {
            line: 128,
            column: 14
          },
          end: {
            line: 128,
            column: 45
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 128,
            column: 22
          },
          end: {
            line: 128,
            column: 39
          }
        }, {
          start: {
            line: 128,
            column: 42
          },
          end: {
            line: 128,
            column: 45
          }
        }],
        line: 128
      },
      '14': {
        loc: {
          start: {
            line: 130,
            column: 10
          },
          end: {
            line: 138,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 130,
            column: 10
          },
          end: {
            line: 138,
            column: 11
          }
        }, {
          start: {
            line: 130,
            column: 10
          },
          end: {
            line: 138,
            column: 11
          }
        }],
        line: 130
      },
      '15': {
        loc: {
          start: {
            line: 133,
            column: 23
          },
          end: {
            line: 133,
            column: 51
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 133,
            column: 23
          },
          end: {
            line: 133,
            column: 28
          }
        }, {
          start: {
            line: 133,
            column: 32
          },
          end: {
            line: 133,
            column: 51
          }
        }],
        line: 133
      },
      '16': {
        loc: {
          start: {
            line: 134,
            column: 12
          },
          end: {
            line: 137,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 134,
            column: 12
          },
          end: {
            line: 137,
            column: 13
          }
        }, {
          start: {
            line: 134,
            column: 12
          },
          end: {
            line: 137,
            column: 13
          }
        }],
        line: 134
      },
      '17': {
        loc: {
          start: {
            line: 161,
            column: 8
          },
          end: {
            line: 165,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 161,
            column: 8
          },
          end: {
            line: 165,
            column: 9
          }
        }, {
          start: {
            line: 161,
            column: 8
          },
          end: {
            line: 165,
            column: 9
          }
        }],
        line: 161
      },
      '18': {
        loc: {
          start: {
            line: 181,
            column: 13
          },
          end: {
            line: 181,
            column: 30
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 181,
            column: 13
          },
          end: {
            line: 181,
            column: 14
          }
        }, {
          start: {
            line: 181,
            column: 18
          },
          end: {
            line: 181,
            column: 30
          }
        }],
        line: 181
      },
      '19': {
        loc: {
          start: {
            line: 211,
            column: 68
          },
          end: {
            line: 211,
            column: 95
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 211,
            column: 68
          },
          end: {
            line: 211,
            column: 79
          }
        }, {
          start: {
            line: 211,
            column: 83
          },
          end: {
            line: 211,
            column: 95
          }
        }],
        line: 211
      },
      '20': {
        loc: {
          start: {
            line: 214,
            column: 66
          },
          end: {
            line: 214,
            column: 92
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 214,
            column: 66
          },
          end: {
            line: 214,
            column: 77
          }
        }, {
          start: {
            line: 214,
            column: 81
          },
          end: {
            line: 214,
            column: 92
          }
        }],
        line: 214
      },
      '21': {
        loc: {
          start: {
            line: 273,
            column: 23
          },
          end: {
            line: 273,
            column: 80
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 273,
            column: 49
          },
          end: {
            line: 273,
            column: 72
          }
        }, {
          start: {
            line: 273,
            column: 75
          },
          end: {
            line: 273,
            column: 80
          }
        }],
        line: 273
      },
      '22': {
        loc: {
          start: {
            line: 274,
            column: 21
          },
          end: {
            line: 274,
            column: 104
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 274,
            column: 68
          },
          end: {
            line: 274,
            column: 93
          }
        }, {
          start: {
            line: 274,
            column: 96
          },
          end: {
            line: 274,
            column: 104
          }
        }],
        line: 274
      },
      '23': {
        loc: {
          start: {
            line: 275,
            column: 19
          },
          end: {
            line: 275,
            column: 95
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 275,
            column: 64
          },
          end: {
            line: 275,
            column: 87
          }
        }, {
          start: {
            line: 275,
            column: 90
          },
          end: {
            line: 275,
            column: 95
          }
        }],
        line: 275
      },
      '24': {
        loc: {
          start: {
            line: 276,
            column: 26
          },
          end: {
            line: 276,
            column: 116
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 276,
            column: 78
          },
          end: {
            line: 276,
            column: 108
          }
        }, {
          start: {
            line: 276,
            column: 111
          },
          end: {
            line: 276,
            column: 116
          }
        }],
        line: 276
      },
      '25': {
        loc: {
          start: {
            line: 277,
            column: 19
          },
          end: {
            line: 277,
            column: 110
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 277,
            column: 64
          },
          end: {
            line: 277,
            column: 77
          }
        }, {
          start: {
            line: 277,
            column: 80
          },
          end: {
            line: 277,
            column: 110
          }
        }],
        line: 277
      },
      '26': {
        loc: {
          start: {
            line: 278,
            column: 22
          },
          end: {
            line: 278,
            column: 104
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 278,
            column: 70
          },
          end: {
            line: 278,
            column: 96
          }
        }, {
          start: {
            line: 278,
            column: 99
          },
          end: {
            line: 278,
            column: 104
          }
        }],
        line: 278
      },
      '27': {
        loc: {
          start: {
            line: 279,
            column: 26
          },
          end: {
            line: 279,
            column: 96
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 279,
            column: 69
          },
          end: {
            line: 279,
            column: 90
          }
        }, {
          start: {
            line: 279,
            column: 93
          },
          end: {
            line: 279,
            column: 96
          }
        }],
        line: 279
      },
      '28': {
        loc: {
          start: {
            line: 281,
            column: 26
          },
          end: {
            line: 281,
            column: 108
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 281,
            column: 38
          },
          end: {
            line: 281,
            column: 103
          }
        }, {
          start: {
            line: 281,
            column: 106
          },
          end: {
            line: 281,
            column: 108
          }
        }],
        line: 281
      },
      '29': {
        loc: {
          start: {
            line: 282,
            column: 24
          },
          end: {
            line: 282,
            column: 111
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 282,
            column: 34
          },
          end: {
            line: 282,
            column: 106
          }
        }, {
          start: {
            line: 282,
            column: 109
          },
          end: {
            line: 282,
            column: 111
          }
        }],
        line: 282
      },
      '30': {
        loc: {
          start: {
            line: 306,
            column: 71
          },
          end: {
            line: 306,
            column: 101
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 306,
            column: 95
          },
          end: {
            line: 306,
            column: 97
          }
        }, {
          start: {
            line: 306,
            column: 100
          },
          end: {
            line: 306,
            column: 101
          }
        }],
        line: 306
      },
      '31': {
        loc: {
          start: {
            line: 318,
            column: 8
          },
          end: {
            line: 333,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 318,
            column: 8
          },
          end: {
            line: 333,
            column: 9
          }
        }, {
          start: {
            line: 318,
            column: 8
          },
          end: {
            line: 333,
            column: 9
          }
        }],
        line: 318
      },
      '32': {
        loc: {
          start: {
            line: 328,
            column: 14
          },
          end: {
            line: 331,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 328,
            column: 14
          },
          end: {
            line: 331,
            column: 15
          }
        }, {
          start: {
            line: 328,
            column: 14
          },
          end: {
            line: 331,
            column: 15
          }
        }],
        line: 328
      },
      '33': {
        loc: {
          start: {
            line: 328,
            column: 18
          },
          end: {
            line: 328,
            column: 61
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 328,
            column: 18
          },
          end: {
            line: 328,
            column: 25
          }
        }, {
          start: {
            line: 328,
            column: 29
          },
          end: {
            line: 328,
            column: 61
          }
        }],
        line: 328
      },
      '34': {
        loc: {
          start: {
            line: 340,
            column: 8
          },
          end: {
            line: 356,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 340,
            column: 8
          },
          end: {
            line: 356,
            column: 9
          }
        }, {
          start: {
            line: 340,
            column: 8
          },
          end: {
            line: 356,
            column: 9
          }
        }],
        line: 340
      },
      '35': {
        loc: {
          start: {
            line: 375,
            column: 12
          },
          end: {
            line: 377,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 375,
            column: 12
          },
          end: {
            line: 377,
            column: 13
          }
        }, {
          start: {
            line: 375,
            column: 12
          },
          end: {
            line: 377,
            column: 13
          }
        }],
        line: 375
      },
      '36': {
        loc: {
          start: {
            line: 387,
            column: 8
          },
          end: {
            line: 389,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 387,
            column: 8
          },
          end: {
            line: 389,
            column: 9
          }
        }, {
          start: {
            line: 387,
            column: 8
          },
          end: {
            line: 389,
            column: 9
          }
        }],
        line: 387
      },
      '37': {
        loc: {
          start: {
            line: 392,
            column: 8
          },
          end: {
            line: 394,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 392,
            column: 8
          },
          end: {
            line: 394,
            column: 9
          }
        }, {
          start: {
            line: 392,
            column: 8
          },
          end: {
            line: 394,
            column: 9
          }
        }],
        line: 392
      },
      '38': {
        loc: {
          start: {
            line: 398,
            column: 8
          },
          end: {
            line: 404,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 398,
            column: 8
          },
          end: {
            line: 404,
            column: 9
          }
        }, {
          start: {
            line: 398,
            column: 8
          },
          end: {
            line: 404,
            column: 9
          }
        }],
        line: 398
      },
      '39': {
        loc: {
          start: {
            line: 421,
            column: 10
          },
          end: {
            line: 423,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 421,
            column: 10
          },
          end: {
            line: 423,
            column: 11
          }
        }, {
          start: {
            line: 421,
            column: 10
          },
          end: {
            line: 423,
            column: 11
          }
        }],
        line: 421
      },
      '40': {
        loc: {
          start: {
            line: 434,
            column: 10
          },
          end: {
            line: 436,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 434,
            column: 10
          },
          end: {
            line: 436,
            column: 11
          }
        }, {
          start: {
            line: 434,
            column: 10
          },
          end: {
            line: 436,
            column: 11
          }
        }],
        line: 434
      },
      '41': {
        loc: {
          start: {
            line: 437,
            column: 10
          },
          end: {
            line: 439,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 437,
            column: 10
          },
          end: {
            line: 439,
            column: 11
          }
        }, {
          start: {
            line: 437,
            column: 10
          },
          end: {
            line: 439,
            column: 11
          }
        }],
        line: 437
      },
      '42': {
        loc: {
          start: {
            line: 443,
            column: 10
          },
          end: {
            line: 445,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 443,
            column: 10
          },
          end: {
            line: 445,
            column: 11
          }
        }, {
          start: {
            line: 443,
            column: 10
          },
          end: {
            line: 445,
            column: 11
          }
        }],
        line: 443
      },
      '43': {
        loc: {
          start: {
            line: 446,
            column: 10
          },
          end: {
            line: 448,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 446,
            column: 10
          },
          end: {
            line: 448,
            column: 11
          }
        }, {
          start: {
            line: 446,
            column: 10
          },
          end: {
            line: 448,
            column: 11
          }
        }],
        line: 446
      },
      '44': {
        loc: {
          start: {
            line: 461,
            column: 10
          },
          end: {
            line: 463,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 461,
            column: 10
          },
          end: {
            line: 463,
            column: 11
          }
        }, {
          start: {
            line: 461,
            column: 10
          },
          end: {
            line: 463,
            column: 11
          }
        }],
        line: 461
      },
      '45': {
        loc: {
          start: {
            line: 461,
            column: 14
          },
          end: {
            line: 461,
            column: 42
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 461,
            column: 14
          },
          end: {
            line: 461,
            column: 23
          }
        }, {
          start: {
            line: 461,
            column: 27
          },
          end: {
            line: 461,
            column: 42
          }
        }],
        line: 461
      },
      '46': {
        loc: {
          start: {
            line: 480,
            column: 10
          },
          end: {
            line: 483,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 480,
            column: 10
          },
          end: {
            line: 483,
            column: 11
          }
        }, {
          start: {
            line: 480,
            column: 10
          },
          end: {
            line: 483,
            column: 11
          }
        }],
        line: 480
      },
      '47': {
        loc: {
          start: {
            line: 485,
            column: 10
          },
          end: {
            line: 505,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 485,
            column: 10
          },
          end: {
            line: 505,
            column: 11
          }
        }, {
          start: {
            line: 485,
            column: 10
          },
          end: {
            line: 505,
            column: 11
          }
        }],
        line: 485
      },
      '48': {
        loc: {
          start: {
            line: 490,
            column: 17
          },
          end: {
            line: 505,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 490,
            column: 17
          },
          end: {
            line: 505,
            column: 11
          }
        }, {
          start: {
            line: 490,
            column: 17
          },
          end: {
            line: 505,
            column: 11
          }
        }],
        line: 490
      },
      '49': {
        loc: {
          start: {
            line: 495,
            column: 17
          },
          end: {
            line: 505,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 495,
            column: 17
          },
          end: {
            line: 505,
            column: 11
          }
        }, {
          start: {
            line: 495,
            column: 17
          },
          end: {
            line: 505,
            column: 11
          }
        }],
        line: 495
      },
      '50': {
        loc: {
          start: {
            line: 500,
            column: 17
          },
          end: {
            line: 505,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 500,
            column: 17
          },
          end: {
            line: 505,
            column: 11
          }
        }, {
          start: {
            line: 500,
            column: 17
          },
          end: {
            line: 505,
            column: 11
          }
        }],
        line: 500
      },
      '51': {
        loc: {
          start: {
            line: 517,
            column: 10
          },
          end: {
            line: 535,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 517,
            column: 10
          },
          end: {
            line: 535,
            column: 11
          }
        }, {
          start: {
            line: 517,
            column: 10
          },
          end: {
            line: 535,
            column: 11
          }
        }],
        line: 517
      },
      '52': {
        loc: {
          start: {
            line: 524,
            column: 12
          },
          end: {
            line: 527,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 524,
            column: 12
          },
          end: {
            line: 527,
            column: 13
          }
        }, {
          start: {
            line: 524,
            column: 12
          },
          end: {
            line: 527,
            column: 13
          }
        }],
        line: 524
      },
      '53': {
        loc: {
          start: {
            line: 529,
            column: 12
          },
          end: {
            line: 534,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 529,
            column: 12
          },
          end: {
            line: 534,
            column: 13
          }
        }, {
          start: {
            line: 529,
            column: 12
          },
          end: {
            line: 534,
            column: 13
          }
        }],
        line: 529
      },
      '54': {
        loc: {
          start: {
            line: 531,
            column: 14
          },
          end: {
            line: 533,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 531,
            column: 14
          },
          end: {
            line: 533,
            column: 15
          }
        }, {
          start: {
            line: 531,
            column: 14
          },
          end: {
            line: 533,
            column: 15
          }
        }],
        line: 531
      },
      '55': {
        loc: {
          start: {
            line: 531,
            column: 18
          },
          end: {
            line: 531,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 531,
            column: 18
          },
          end: {
            line: 531,
            column: 33
          }
        }, {
          start: {
            line: 531,
            column: 37
          },
          end: {
            line: 531,
            column: 58
          }
        }],
        line: 531
      },
      '56': {
        loc: {
          start: {
            line: 538,
            column: 8
          },
          end: {
            line: 542,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 538,
            column: 8
          },
          end: {
            line: 542,
            column: 9
          }
        }, {
          start: {
            line: 538,
            column: 8
          },
          end: {
            line: 542,
            column: 9
          }
        }],
        line: 538
      },
      '57': {
        loc: {
          start: {
            line: 550,
            column: 10
          },
          end: {
            line: 555,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 550,
            column: 10
          },
          end: {
            line: 555,
            column: 11
          }
        }, {
          start: {
            line: 550,
            column: 10
          },
          end: {
            line: 555,
            column: 11
          }
        }],
        line: 550
      },
      '58': {
        loc: {
          start: {
            line: 559,
            column: 10
          },
          end: {
            line: 571,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 559,
            column: 10
          },
          end: {
            line: 571,
            column: 11
          }
        }, {
          start: {
            line: 559,
            column: 10
          },
          end: {
            line: 571,
            column: 11
          }
        }],
        line: 559
      },
      '59': {
        loc: {
          start: {
            line: 565,
            column: 12
          },
          end: {
            line: 570,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 565,
            column: 12
          },
          end: {
            line: 570,
            column: 13
          }
        }, {
          start: {
            line: 565,
            column: 12
          },
          end: {
            line: 570,
            column: 13
          }
        }],
        line: 565
      },
      '60': {
        loc: {
          start: {
            line: 567,
            column: 14
          },
          end: {
            line: 569,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 567,
            column: 14
          },
          end: {
            line: 569,
            column: 15
          }
        }, {
          start: {
            line: 567,
            column: 14
          },
          end: {
            line: 569,
            column: 15
          }
        }],
        line: 567
      },
      '61': {
        loc: {
          start: {
            line: 567,
            column: 18
          },
          end: {
            line: 567,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 567,
            column: 18
          },
          end: {
            line: 567,
            column: 33
          }
        }, {
          start: {
            line: 567,
            column: 37
          },
          end: {
            line: 567,
            column: 58
          }
        }],
        line: 567
      },
      '62': {
        loc: {
          start: {
            line: 578,
            column: 8
          },
          end: {
            line: 586,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 578,
            column: 8
          },
          end: {
            line: 586,
            column: 9
          }
        }, {
          start: {
            line: 578,
            column: 8
          },
          end: {
            line: 586,
            column: 9
          }
        }],
        line: 578
      },
      '63': {
        loc: {
          start: {
            line: 580,
            column: 12
          },
          end: {
            line: 584,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 580,
            column: 12
          },
          end: {
            line: 584,
            column: 13
          }
        }, {
          start: {
            line: 580,
            column: 12
          },
          end: {
            line: 584,
            column: 13
          }
        }],
        line: 580
      },
      '64': {
        loc: {
          start: {
            line: 582,
            column: 19
          },
          end: {
            line: 584,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 582,
            column: 19
          },
          end: {
            line: 584,
            column: 13
          }
        }, {
          start: {
            line: 582,
            column: 19
          },
          end: {
            line: 584,
            column: 13
          }
        }],
        line: 582
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0
    },
    b: {
      '0': [0, 0, 0, 0],
      '1': [0, 0],
      '2': [0, 0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0],
      '58': [0, 0],
      '59': [0, 0],
      '60': [0, 0],
      '61': [0, 0],
      '62': [0, 0],
      '63': [0, 0],
      '64': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1zaa8mkq1e.s[0]++;
angular.module('deltacolorpicker.module', []).factory('Helper', function () {
  'use strict';

  cov_1zaa8mkq1e.f[0]++;
  cov_1zaa8mkq1e.s[1]++;
  return {
    closestSlider: function closestSlider(elem) {
      var matchesSelector = (cov_1zaa8mkq1e.s[2]++, (cov_1zaa8mkq1e.b[0][0]++, elem.matches) || (cov_1zaa8mkq1e.b[0][1]++, elem.webkitMatchesSelector) || (cov_1zaa8mkq1e.b[0][2]++, elem.mozMatchesSelector) || (cov_1zaa8mkq1e.b[0][3]++, elem.msMatchesSelector));
      cov_1zaa8mkq1e.s[3]++;
      if (matchesSelector.bind(elem)('I')) {
        cov_1zaa8mkq1e.b[1][0]++;
        cov_1zaa8mkq1e.s[4]++;

        return elem.parentNode;
      } else {
        cov_1zaa8mkq1e.b[1][1]++;
      }
      cov_1zaa8mkq1e.s[5]++;
      return elem;
    },
    getOffset: function getOffset(elem, fixedPosition) {
      var scrollX = (cov_1zaa8mkq1e.s[6]++, 0),
          scrollY = (cov_1zaa8mkq1e.s[7]++, 0),
          rect = (cov_1zaa8mkq1e.s[8]++, elem.getBoundingClientRect());
      cov_1zaa8mkq1e.s[9]++;
      while ((cov_1zaa8mkq1e.b[2][0]++, elem) && (cov_1zaa8mkq1e.b[2][1]++, !isNaN(elem.offsetLeft)) && (cov_1zaa8mkq1e.b[2][2]++, !isNaN(elem.offsetTop))) {
        cov_1zaa8mkq1e.s[10]++;

        if ((cov_1zaa8mkq1e.b[4][0]++, !fixedPosition) && (cov_1zaa8mkq1e.b[4][1]++, elem.tagName === 'BODY')) {
          cov_1zaa8mkq1e.b[3][0]++;
          cov_1zaa8mkq1e.s[11]++;

          scrollX += (cov_1zaa8mkq1e.b[5][0]++, document.documentElement.scrollLeft) || (cov_1zaa8mkq1e.b[5][1]++, elem.scrollLeft);
          cov_1zaa8mkq1e.s[12]++;
          scrollY += (cov_1zaa8mkq1e.b[6][0]++, document.documentElement.scrollTop) || (cov_1zaa8mkq1e.b[6][1]++, elem.scrollTop);
        } else {
          cov_1zaa8mkq1e.b[3][1]++;
          cov_1zaa8mkq1e.s[13]++;

          scrollX += elem.scrollLeft;
          cov_1zaa8mkq1e.s[14]++;
          scrollY += elem.scrollTop;
        }
        cov_1zaa8mkq1e.s[15]++;
        elem = elem.offsetParent;
      }
      cov_1zaa8mkq1e.s[16]++;
      return {
        top: rect.top + window.pageYOffset,
        left: rect.left + window.pageXOffset,
        scrollX: scrollX,
        scrollY: scrollY
      };
    },

    // a set of RE's that can match strings and generate color tuples. https://github.com/jquery/jquery-color/
    stringParsers: [{
      re: /rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(?:,\s*(\d+(?:\.\d+)?)\s*)?\)/,
      parse: function parse(execResult) {
        cov_1zaa8mkq1e.s[17]++;

        return [execResult[1], execResult[2], execResult[3], execResult[4]];
      }
    }, {
      re: /rgba?\(\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*(?:,\s*(\d+(?:\.\d+)?)\s*)?\)/,
      parse: function parse(execResult) {
        cov_1zaa8mkq1e.s[18]++;

        return [2.55 * execResult[1], 2.55 * execResult[2], 2.55 * execResult[3], execResult[4]];
      }
    }, {
      re: /#([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})/,
      parse: function parse(execResult) {
        cov_1zaa8mkq1e.s[19]++;

        return [parseInt(execResult[1], 16), parseInt(execResult[2], 16), parseInt(execResult[3], 16)];
      }
    }, {
      re: /#([a-fA-F0-9])([a-fA-F0-9])([a-fA-F0-9])/,
      parse: function parse(execResult) {
        cov_1zaa8mkq1e.s[20]++;

        return [parseInt(execResult[1] + execResult[1], 16), parseInt(execResult[2] + execResult[2], 16), parseInt(execResult[3] + execResult[3], 16)];
      }
    }]
  };
}).factory('Color', ['Helper', function (Helper) {
  'use strict';

  cov_1zaa8mkq1e.f[1]++;
  cov_1zaa8mkq1e.s[21]++;
  return {
    value: {
      h: 1,
      s: 1,
      b: 1,
      a: 1
    },
    // translate a format from Color object to a string
    rgb: function rgb() {
      var rgb = (cov_1zaa8mkq1e.s[22]++, this.toRGB());
      cov_1zaa8mkq1e.s[23]++;
      return 'rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')';
    },
    rgb_: function rgb_() {
      var rgb = (cov_1zaa8mkq1e.s[24]++, this.toRGB());
      cov_1zaa8mkq1e.s[25]++;
      return Number(rgb.r) + ',' + rgb.g + ',' + rgb.b;
    },
    rgba: function rgba() {
      var rgb = (cov_1zaa8mkq1e.s[26]++, this.toRGB());
      cov_1zaa8mkq1e.s[27]++;
      return 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + rgb.a + ')';
    },
    hex: function hex() {
      cov_1zaa8mkq1e.s[28]++;

      return this.toHex();
    },


    // HSBtoRGB from RaphaelJS
    RGBtoHSB: function RGBtoHSB(r, g, b, a) {
      cov_1zaa8mkq1e.s[29]++;

      r /= 255;
      cov_1zaa8mkq1e.s[30]++;
      g /= 255;
      cov_1zaa8mkq1e.s[31]++;
      b /= 255;

      var H = void 0,
          S = void 0,
          V = void 0,
          C = void 0;
      cov_1zaa8mkq1e.s[32]++;
      V = Math.max(r, g, b);
      cov_1zaa8mkq1e.s[33]++;
      C = V - Math.min(r, g, b);
      cov_1zaa8mkq1e.s[34]++;
      H = C === 0 ? (cov_1zaa8mkq1e.b[7][0]++, null) : (cov_1zaa8mkq1e.b[7][1]++, V === r ? (cov_1zaa8mkq1e.b[8][0]++, (g - b) / C) : (cov_1zaa8mkq1e.b[8][1]++, V === g ? (cov_1zaa8mkq1e.b[9][0]++, (b - r) / C + 2) : (cov_1zaa8mkq1e.b[9][1]++, (r - g) / C + 4)));
      cov_1zaa8mkq1e.s[35]++;
      H = (H + 360) % 6 * 60 / 360;
      cov_1zaa8mkq1e.s[36]++;
      S = C === 0 ? (cov_1zaa8mkq1e.b[10][0]++, 0) : (cov_1zaa8mkq1e.b[10][1]++, C / V);
      cov_1zaa8mkq1e.s[37]++;
      return { h: (cov_1zaa8mkq1e.b[11][0]++, H) || (cov_1zaa8mkq1e.b[11][1]++, 1), s: S, b: V, a: (cov_1zaa8mkq1e.b[12][0]++, a) || (cov_1zaa8mkq1e.b[12][1]++, 1) };
    },


    // parse a string to HSB
    setColor: function setColor(val) {
      cov_1zaa8mkq1e.s[38]++;

      val = val ? (cov_1zaa8mkq1e.b[13][0]++, val.toLowerCase()) : (cov_1zaa8mkq1e.b[13][1]++, val);
      cov_1zaa8mkq1e.s[39]++;
      for (var key in Helper.stringParsers) {
        cov_1zaa8mkq1e.s[40]++;

        if (Helper.stringParsers.hasOwnProperty(key)) {
          cov_1zaa8mkq1e.b[14][0]++;

          var parser = (cov_1zaa8mkq1e.s[41]++, Helper.stringParsers[key]);
          var match = (cov_1zaa8mkq1e.s[42]++, parser.re.exec(val)),
              values = (cov_1zaa8mkq1e.s[43]++, (cov_1zaa8mkq1e.b[15][0]++, match) && (cov_1zaa8mkq1e.b[15][1]++, parser.parse(match)));
          cov_1zaa8mkq1e.s[44]++;
          if (values) {
            cov_1zaa8mkq1e.b[16][0]++;
            cov_1zaa8mkq1e.s[45]++;

            this.value = this.RGBtoHSB.apply(null, values);
            cov_1zaa8mkq1e.s[46]++;
            return false;
          } else {
            cov_1zaa8mkq1e.b[16][1]++;
          }
        } else {
          cov_1zaa8mkq1e.b[14][1]++;
        }
      }
    },
    setHue: function setHue(h) {
      cov_1zaa8mkq1e.s[47]++;

      this.value.h = 1 - h;
    },
    setSaturation: function setSaturation(s) {
      cov_1zaa8mkq1e.s[48]++;

      this.value.s = s;
    },
    setLightness: function setLightness(b) {
      cov_1zaa8mkq1e.s[49]++;

      this.value.b = 1 - b;
    },
    setAlpha: function setAlpha(a) {
      cov_1zaa8mkq1e.s[50]++;

      this.value.a = parseInt((1 - a) * 100, 10) / 100;
    },


    // HSBtoRGB from RaphaelJS
    // https://github.com/DmitryBaranovskiy/raphael/
    toRGB: function toRGB(h, s, b, a) {
      cov_1zaa8mkq1e.s[51]++;

      if (!h) {
        cov_1zaa8mkq1e.b[17][0]++;
        cov_1zaa8mkq1e.s[52]++;

        h = this.value.h;
        cov_1zaa8mkq1e.s[53]++;
        s = this.value.s;
        cov_1zaa8mkq1e.s[54]++;
        b = this.value.b;
      } else {
        cov_1zaa8mkq1e.b[17][1]++;
      }
      cov_1zaa8mkq1e.s[55]++;
      h *= 360;
      var R = void 0,
          G = void 0,
          B = void 0,
          X = void 0,
          C = void 0;
      cov_1zaa8mkq1e.s[56]++;
      h = h % 360 / 60;
      cov_1zaa8mkq1e.s[57]++;
      C = b * s;
      cov_1zaa8mkq1e.s[58]++;
      X = C * (1 - Math.abs(h % 2 - 1));
      cov_1zaa8mkq1e.s[59]++;
      R = G = B = b - C;

      cov_1zaa8mkq1e.s[60]++;
      h = ~~h;
      cov_1zaa8mkq1e.s[61]++;
      R += [C, X, 0, 0, X, C][h];
      cov_1zaa8mkq1e.s[62]++;
      G += [X, C, C, X, 0, 0][h];
      cov_1zaa8mkq1e.s[63]++;
      B += [0, 0, X, C, C, X][h];
      cov_1zaa8mkq1e.s[64]++;
      return {
        r: Math.round(R * 255),
        g: Math.round(G * 255),
        b: Math.round(B * 255),
        a: (cov_1zaa8mkq1e.b[18][0]++, a) || (cov_1zaa8mkq1e.b[18][1]++, this.value.a)
      };
    },
    toHex: function toHex(h, s, b, a) {
      var rgb = (cov_1zaa8mkq1e.s[65]++, this.toRGB(h, s, b, a));
      cov_1zaa8mkq1e.s[66]++;
      return '#' + (1 << 24 | parseInt(rgb.r, 10) << 16 | parseInt(rgb.g, 10) << 8 | parseInt(rgb.b, 10)).toString(16).substr(1);
    }
  };
}]).factory('Slider', ['Helper', function (Helper) {
  'use strict';

  cov_1zaa8mkq1e.f[2]++;
  var slider = (cov_1zaa8mkq1e.s[67]++, {
    maxLeft: 0,
    maxTop: 0,
    callLeft: null,
    callTop: null,
    knob: {
      top: 0,
      left: 0
    }
  }),
      pointer = (cov_1zaa8mkq1e.s[68]++, {});

  cov_1zaa8mkq1e.s[69]++;
  return {
    getSlider: function getSlider() {
      cov_1zaa8mkq1e.s[70]++;

      return slider;
    },
    getLeftPosition: function getLeftPosition(event) {
      cov_1zaa8mkq1e.s[71]++;

      return Math.max(0, Math.min(slider.maxLeft, slider.left + (((cov_1zaa8mkq1e.b[19][0]++, event.pageX) || (cov_1zaa8mkq1e.b[19][1]++, pointer.left)) - pointer.left)));
    },
    getTopPosition: function getTopPosition(event) {
      cov_1zaa8mkq1e.s[72]++;

      return Math.max(0, Math.min(slider.maxTop, slider.top + (((cov_1zaa8mkq1e.b[20][0]++, event.pageY) || (cov_1zaa8mkq1e.b[20][1]++, pointer.top)) - pointer.top)));
    },
    setSlider: function setSlider(event, fixedPosition) {
      var target = (cov_1zaa8mkq1e.s[73]++, Helper.closestSlider(event.target)),
          targetOffset = (cov_1zaa8mkq1e.s[74]++, Helper.getOffset(target, fixedPosition)),
          rect = (cov_1zaa8mkq1e.s[75]++, target.getBoundingClientRect()),
          offsetX = (cov_1zaa8mkq1e.s[76]++, event.clientX - rect.left),
          offsetY = (cov_1zaa8mkq1e.s[77]++, event.clientY - rect.top);

      cov_1zaa8mkq1e.s[78]++;
      slider.knob = target.children[0].style;
      cov_1zaa8mkq1e.s[79]++;
      slider.left = event.pageX - targetOffset.left - window.pageXOffset + targetOffset.scrollX;
      cov_1zaa8mkq1e.s[80]++;
      slider.top = event.pageY - targetOffset.top - window.pageYOffset + targetOffset.scrollY;

      cov_1zaa8mkq1e.s[81]++;
      pointer = {
        left: event.pageX - (offsetX - slider.left),
        top: event.pageY - (offsetY - slider.top)
      };
    },
    setSaturation: function setSaturation(event, fixedPosition, componentSize) {
      cov_1zaa8mkq1e.s[82]++;

      slider = {
        maxLeft: componentSize,
        maxTop: componentSize,
        callLeft: 'setSaturation',
        callTop: 'setLightness'
      };
      cov_1zaa8mkq1e.s[83]++;
      this.setSlider(event, fixedPosition);
    },
    setHue: function setHue(event, fixedPosition, componentSize) {
      cov_1zaa8mkq1e.s[84]++;

      slider = {
        maxLeft: 0,
        maxTop: componentSize,
        callLeft: false,
        callTop: 'setHue'
      };
      cov_1zaa8mkq1e.s[85]++;
      this.setSlider(event, fixedPosition);
    },
    setAlpha: function setAlpha(event, fixedPosition, componentSize) {
      cov_1zaa8mkq1e.s[86]++;

      slider = {
        maxLeft: 0,
        maxTop: componentSize,
        callLeft: false,
        callTop: 'setAlpha'
      };
      cov_1zaa8mkq1e.s[87]++;
      this.setSlider(event, fixedPosition);
    },
    setKnob: function setKnob(top, left) {
      cov_1zaa8mkq1e.s[88]++;

      slider.knob.top = top + 'px';
      cov_1zaa8mkq1e.s[89]++;
      slider.knob.left = left + 'px';
    }
  };
}]).directive('colorpickercustom', ['$document', '$compile', 'Color', 'Slider', 'Helper', function ($document, $compile, Color, Slider, Helper) {
  'use strict';

  cov_1zaa8mkq1e.f[3]++;
  cov_1zaa8mkq1e.s[90]++;
  return {
    require: '?ngModel',
    restrict: 'A',
    link: function link($scope, elem, attrs, ngModel) {
      var thisFormat = (cov_1zaa8mkq1e.s[91]++, attrs.colorpickercustom ? (cov_1zaa8mkq1e.b[21][0]++, attrs.colorpickercustom) : (cov_1zaa8mkq1e.b[21][1]++, 'hex')),
          position = (cov_1zaa8mkq1e.s[92]++, angular.isDefined(attrs.colorpickerPosition) ? (cov_1zaa8mkq1e.b[22][0]++, attrs.colorpickerPosition) : (cov_1zaa8mkq1e.b[22][1]++, 'bottom')),
          inline = (cov_1zaa8mkq1e.s[93]++, angular.isDefined(attrs.colorpickerInline) ? (cov_1zaa8mkq1e.b[23][0]++, attrs.colorpickerInline) : (cov_1zaa8mkq1e.b[23][1]++, false)),
          fixedPosition = (cov_1zaa8mkq1e.s[94]++, angular.isDefined(attrs.colorpickerFixedPosition) ? (cov_1zaa8mkq1e.b[24][0]++, attrs.colorpickerFixedPosition) : (cov_1zaa8mkq1e.b[24][1]++, false)),
          target = (cov_1zaa8mkq1e.s[95]++, angular.isDefined(attrs.colorpickerParent) ? (cov_1zaa8mkq1e.b[25][0]++, elem.parent()) : (cov_1zaa8mkq1e.b[25][1]++, angular.element(document.body))),
          withInput = (cov_1zaa8mkq1e.s[96]++, angular.isDefined(attrs.colorpickerWithInput) ? (cov_1zaa8mkq1e.b[26][0]++, attrs.colorpickerWithInput) : (cov_1zaa8mkq1e.b[26][1]++, false)),
          componentSize = (cov_1zaa8mkq1e.s[97]++, angular.isDefined(attrs.colorpickerSize) ? (cov_1zaa8mkq1e.b[27][0]++, attrs.colorpickerSize) : (cov_1zaa8mkq1e.b[27][1]++, 100)),
          componentSizePx = (cov_1zaa8mkq1e.s[98]++, componentSize + 'px'),
          inputTemplate = (cov_1zaa8mkq1e.s[99]++, withInput ? (cov_1zaa8mkq1e.b[28][0]++, '<input type="text" name="colorpicker-input" spellcheck="false">') : (cov_1zaa8mkq1e.b[28][1]++, '')),
          closeButton = (cov_1zaa8mkq1e.s[100]++, !inline ? (cov_1zaa8mkq1e.b[29][0]++, '<button type="button" class="close close-colorpicker">&times;</button>') : (cov_1zaa8mkq1e.b[29][1]++, '')),
          template = (cov_1zaa8mkq1e.s[101]++, '<div class="colorpicker dropdown">' + '<div class="dropdown-menu">' + '<colorpicker-saturation><i></i></colorpicker-saturation>' + '<colorpicker-hue><i></i></colorpicker-hue>' + '<colorpicker-alpha><i></i></colorpicker-alpha>' + '<colorpicker-preview></colorpicker-preview>' + inputTemplate + closeButton + '</div>' + '</div>'),
          colorpickerTemplate = (cov_1zaa8mkq1e.s[102]++, angular.element(template)),
          pickerColor = (cov_1zaa8mkq1e.s[103]++, Color),
          colorpickerValue = (cov_1zaa8mkq1e.s[104]++, {
        h: 1,
        s: 0,
        b: 1,
        a: 1
      }),
          sliderHue = (cov_1zaa8mkq1e.s[105]++, colorpickerTemplate.find('colorpicker-hue')),
          sliderSaturation = (cov_1zaa8mkq1e.s[106]++, colorpickerTemplate.find('colorpicker-saturation')),
          colorpickerPreview = (cov_1zaa8mkq1e.s[107]++, colorpickerTemplate.find('colorpicker-preview')),
          pickerColorPointers = (cov_1zaa8mkq1e.s[108]++, colorpickerTemplate.find('i')),
          componentWidthWithToolbars = (cov_1zaa8mkq1e.s[109]++, parseInt(componentSize) + 29 + (thisFormat === 'rgba' ? (cov_1zaa8mkq1e.b[30][0]++, 15) : (cov_1zaa8mkq1e.b[30][1]++, 0))),
          componentHeightWithToolbars = (cov_1zaa8mkq1e.s[110]++, parseInt(componentSize) + 55);

      var sliderAlpha = void 0;
      cov_1zaa8mkq1e.s[111]++;
      $compile(colorpickerTemplate)($scope);
      cov_1zaa8mkq1e.s[112]++;
      colorpickerTemplate.css('min-width', componentWidthWithToolbars + 'px');
      cov_1zaa8mkq1e.s[113]++;
      sliderSaturation.css({
        width: componentSizePx,
        height: componentSizePx
      });
      cov_1zaa8mkq1e.s[114]++;
      sliderHue.css('height', componentSizePx);

      cov_1zaa8mkq1e.s[115]++;
      if (withInput) {
        cov_1zaa8mkq1e.b[31][0]++;

        var pickerColorInput = (cov_1zaa8mkq1e.s[116]++, colorpickerTemplate.find('input'));
        cov_1zaa8mkq1e.s[117]++;
        pickerColorInput.css('width', componentSizePx);
        cov_1zaa8mkq1e.s[118]++;
        pickerColorInput.on('mousedown', function (event) {
          cov_1zaa8mkq1e.f[4]++;
          cov_1zaa8mkq1e.s[119]++;

          event.stopPropagation();
        }).on('keyup', function () {
          cov_1zaa8mkq1e.f[5]++;

          var newColor = (cov_1zaa8mkq1e.s[120]++, this.value);
          cov_1zaa8mkq1e.s[121]++;
          elem.val(newColor);
          cov_1zaa8mkq1e.s[122]++;
          if ((cov_1zaa8mkq1e.b[33][0]++, ngModel) && (cov_1zaa8mkq1e.b[33][1]++, ngModel.$modelValue !== newColor)) {
            cov_1zaa8mkq1e.b[32][0]++;
            cov_1zaa8mkq1e.s[123]++;

            $scope.$apply(ngModel.$setViewValue(newColor));
            cov_1zaa8mkq1e.s[124]++;
            update(true);
          } else {
            cov_1zaa8mkq1e.b[32][1]++;
          }
        });
      } else {
        cov_1zaa8mkq1e.b[31][1]++;
      }

      function bindMouseEvents() {
        cov_1zaa8mkq1e.f[6]++;
        cov_1zaa8mkq1e.s[125]++;

        $document.on('mousemove', mousemove);
        cov_1zaa8mkq1e.s[126]++;
        $document.on('mouseup', mouseup);
      }

      cov_1zaa8mkq1e.s[127]++;
      if (thisFormat === 'rgba') {
        cov_1zaa8mkq1e.b[34][0]++;
        cov_1zaa8mkq1e.s[128]++;

        colorpickerTemplate.addClass('alpha');
        cov_1zaa8mkq1e.s[129]++;
        sliderAlpha = colorpickerTemplate.find('colorpicker-alpha');
        cov_1zaa8mkq1e.s[130]++;
        sliderAlpha.css('height', componentSizePx);
        cov_1zaa8mkq1e.s[131]++;
        sliderAlpha.on('click', function (event) {
          cov_1zaa8mkq1e.f[7]++;
          cov_1zaa8mkq1e.s[132]++;

          Slider.setAlpha(event, fixedPosition, componentSize);
          cov_1zaa8mkq1e.s[133]++;
          mousemove(event);
        }).on('mousedown', function (event) {
          cov_1zaa8mkq1e.f[8]++;
          cov_1zaa8mkq1e.s[134]++;

          Slider.setAlpha(event, fixedPosition, componentSize);
          cov_1zaa8mkq1e.s[135]++;
          bindMouseEvents();
        }).on('mouseup', function (event) {
          cov_1zaa8mkq1e.f[9]++;
          cov_1zaa8mkq1e.s[136]++;

          emitEvent('colorpicker-selected-alpha');
        });
      } else {
        cov_1zaa8mkq1e.b[34][1]++;
      }

      cov_1zaa8mkq1e.s[137]++;
      sliderHue.on('click', function (event) {
        cov_1zaa8mkq1e.f[10]++;
        cov_1zaa8mkq1e.s[138]++;

        Slider.setHue(event, fixedPosition, componentSize);
        cov_1zaa8mkq1e.s[139]++;
        mousemove(event);
      }).on('mousedown', function (event) {
        cov_1zaa8mkq1e.f[11]++;
        cov_1zaa8mkq1e.s[140]++;

        Slider.setHue(event, fixedPosition, componentSize);
        cov_1zaa8mkq1e.s[141]++;
        bindMouseEvents();
      }).on('mouseup', function (event) {
        cov_1zaa8mkq1e.f[12]++;
        cov_1zaa8mkq1e.s[142]++;

        emitEvent('colorpicker-selected-hue');
      });

      cov_1zaa8mkq1e.s[143]++;
      sliderSaturation.on('click', function (event) {
        cov_1zaa8mkq1e.f[13]++;
        cov_1zaa8mkq1e.s[144]++;

        Slider.setSaturation(event, fixedPosition, componentSize);
        cov_1zaa8mkq1e.s[145]++;
        mousemove(event);
        cov_1zaa8mkq1e.s[146]++;
        if (angular.isDefined(attrs.colorpickerCloseOnSelect)) {
          cov_1zaa8mkq1e.b[35][0]++;
          cov_1zaa8mkq1e.s[147]++;

          hideColorpickerTemplate();
        } else {
          cov_1zaa8mkq1e.b[35][1]++;
        }
      }).on('mousedown', function (event) {
        cov_1zaa8mkq1e.f[14]++;
        cov_1zaa8mkq1e.s[148]++;

        Slider.setSaturation(event, fixedPosition, componentSize);
        cov_1zaa8mkq1e.s[149]++;
        bindMouseEvents();
      }).on('mouseup', function (event) {
        cov_1zaa8mkq1e.f[15]++;
        cov_1zaa8mkq1e.s[150]++;

        emitEvent('colorpicker-selected-saturation');
      });

      cov_1zaa8mkq1e.s[151]++;
      if (fixedPosition) {
        cov_1zaa8mkq1e.b[36][0]++;
        cov_1zaa8mkq1e.s[152]++;

        colorpickerTemplate.addClass('colorpicker-fixed-position');
      } else {
        cov_1zaa8mkq1e.b[36][1]++;
      }

      cov_1zaa8mkq1e.s[153]++;
      colorpickerTemplate.addClass('colorpicker-position-' + position);
      cov_1zaa8mkq1e.s[154]++;
      if (inline === 'true') {
        cov_1zaa8mkq1e.b[37][0]++;
        cov_1zaa8mkq1e.s[155]++;

        colorpickerTemplate.addClass('colorpicker-inline');
      } else {
        cov_1zaa8mkq1e.b[37][1]++;
      }

      cov_1zaa8mkq1e.s[156]++;
      target.append(colorpickerTemplate);

      cov_1zaa8mkq1e.s[157]++;
      if (ngModel) {
        cov_1zaa8mkq1e.b[38][0]++;
        cov_1zaa8mkq1e.s[158]++;

        ngModel.$render = function () {
          cov_1zaa8mkq1e.f[16]++;
          cov_1zaa8mkq1e.s[159]++;

          elem.val(ngModel.$viewValue);

          cov_1zaa8mkq1e.s[160]++;
          update();
        };
      } else {
        cov_1zaa8mkq1e.b[38][1]++;
      }

      cov_1zaa8mkq1e.s[161]++;
      elem.on('blur keyup change', function () {
        cov_1zaa8mkq1e.f[17]++;
        cov_1zaa8mkq1e.s[162]++;

        update();
      });

      cov_1zaa8mkq1e.s[163]++;
      elem.on('$destroy', function () {
        cov_1zaa8mkq1e.f[18]++;
        cov_1zaa8mkq1e.s[164]++;

        colorpickerTemplate.remove();
      });

      function previewColor() {
        cov_1zaa8mkq1e.f[19]++;
        cov_1zaa8mkq1e.s[165]++;

        try {
          cov_1zaa8mkq1e.s[166]++;

          colorpickerPreview.css('backgroundColor', pickerColor[thisFormat]());
        } catch (e) {
          cov_1zaa8mkq1e.s[167]++;

          colorpickerPreview.css('backgroundColor', pickerColor.toHex());
        }
        cov_1zaa8mkq1e.s[168]++;
        sliderSaturation.css('backgroundColor', pickerColor.toHex(pickerColor.value.h, 1, 1, 1));
        cov_1zaa8mkq1e.s[169]++;
        if (thisFormat === 'rgba') {
          cov_1zaa8mkq1e.b[39][0]++;
          cov_1zaa8mkq1e.s[170]++;

          sliderAlpha.css.backgroundColor = pickerColor.toHex();
        } else {
          cov_1zaa8mkq1e.b[39][1]++;
        }
      }

      function mousemove(event) {
        cov_1zaa8mkq1e.f[20]++;

        var left = (cov_1zaa8mkq1e.s[171]++, Slider.getLeftPosition(event)),
            top = (cov_1zaa8mkq1e.s[172]++, Slider.getTopPosition(event)),
            slider = (cov_1zaa8mkq1e.s[173]++, Slider.getSlider());

        cov_1zaa8mkq1e.s[174]++;
        Slider.setKnob(top, left);

        cov_1zaa8mkq1e.s[175]++;
        if (slider.callLeft) {
          cov_1zaa8mkq1e.b[40][0]++;
          cov_1zaa8mkq1e.s[176]++;

          pickerColor[slider.callLeft].call(pickerColor, left / componentSize);
        } else {
          cov_1zaa8mkq1e.b[40][1]++;
        }
        cov_1zaa8mkq1e.s[177]++;
        if (slider.callTop) {
          cov_1zaa8mkq1e.b[41][0]++;
          cov_1zaa8mkq1e.s[178]++;

          pickerColor[slider.callTop].call(pickerColor, top / componentSize);
        } else {
          cov_1zaa8mkq1e.b[41][1]++;
        }
        cov_1zaa8mkq1e.s[179]++;
        previewColor();
        var newColor = (cov_1zaa8mkq1e.s[180]++, pickerColor[thisFormat]());
        cov_1zaa8mkq1e.s[181]++;
        elem.val(newColor);
        cov_1zaa8mkq1e.s[182]++;
        if (ngModel) {
          cov_1zaa8mkq1e.b[42][0]++;
          cov_1zaa8mkq1e.s[183]++;

          $scope.$apply(ngModel.$setViewValue(newColor));
        } else {
          cov_1zaa8mkq1e.b[42][1]++;
        }
        cov_1zaa8mkq1e.s[184]++;
        if (withInput) {
          cov_1zaa8mkq1e.b[43][0]++;
          cov_1zaa8mkq1e.s[185]++;

          pickerColorInput.val(newColor);
        } else {
          cov_1zaa8mkq1e.b[43][1]++;
        }
        cov_1zaa8mkq1e.s[186]++;
        return false;
      }

      function mouseup() {
        cov_1zaa8mkq1e.f[21]++;
        cov_1zaa8mkq1e.s[187]++;

        emitEvent('colorpicker-selected');
        cov_1zaa8mkq1e.s[188]++;
        $document.off('mousemove', mousemove);
        cov_1zaa8mkq1e.s[189]++;
        $document.off('mouseup', mouseup);
      }

      function update(omitInnerInput) {
        cov_1zaa8mkq1e.f[22]++;
        cov_1zaa8mkq1e.s[190]++;

        pickerColor.value = colorpickerValue;
        cov_1zaa8mkq1e.s[191]++;
        pickerColor.setColor(elem.val());
        cov_1zaa8mkq1e.s[192]++;
        if ((cov_1zaa8mkq1e.b[45][0]++, withInput) && (cov_1zaa8mkq1e.b[45][1]++, !omitInnerInput)) {
          cov_1zaa8mkq1e.b[44][0]++;
          cov_1zaa8mkq1e.s[193]++;

          pickerColorInput.val(elem.val());
        } else {
          cov_1zaa8mkq1e.b[44][1]++;
        }
        cov_1zaa8mkq1e.s[194]++;
        pickerColorPointers.eq(0).css({
          left: pickerColor.value.s * componentSize + 'px',
          top: componentSize - pickerColor.value.b * componentSize + 'px'
        });
        cov_1zaa8mkq1e.s[195]++;
        pickerColorPointers.eq(1).css('top', componentSize * (1 - pickerColor.value.h) + 'px');
        cov_1zaa8mkq1e.s[196]++;
        pickerColorPointers.eq(2).css('top', componentSize * (1 - pickerColor.value.a) + 'px');
        cov_1zaa8mkq1e.s[197]++;
        colorpickerValue = pickerColor.value;
        cov_1zaa8mkq1e.s[198]++;
        previewColor();
      }

      function getColorpickerTemplatePosition() {
        cov_1zaa8mkq1e.f[23]++;

        var positionValue = void 0,
            positionOffset = (cov_1zaa8mkq1e.s[199]++, Helper.getOffset(elem[0])),
            additionalSpaceBetweenElements = (cov_1zaa8mkq1e.s[200]++, 2);

        cov_1zaa8mkq1e.s[201]++;
        if (angular.isDefined(attrs.colorpickerParent)) {
          cov_1zaa8mkq1e.b[46][0]++;
          cov_1zaa8mkq1e.s[202]++;

          positionOffset.left = 0;
          cov_1zaa8mkq1e.s[203]++;
          positionOffset.top = 0;
        } else {
          cov_1zaa8mkq1e.b[46][1]++;
        }

        cov_1zaa8mkq1e.s[204]++;
        if (position === 'top') {
          cov_1zaa8mkq1e.b[47][0]++;
          cov_1zaa8mkq1e.s[205]++;

          positionValue = {
            top: positionOffset.top - componentHeightWithToolbars - additionalSpaceBetweenElements,
            left: positionOffset.left
          };
        } else {
            cov_1zaa8mkq1e.b[47][1]++;
            cov_1zaa8mkq1e.s[206]++;
            if (position === 'right') {
              cov_1zaa8mkq1e.b[48][0]++;
              cov_1zaa8mkq1e.s[207]++;

              positionValue = {
                top: positionOffset.top,
                left: positionOffset.left + elem[0].offsetWidth + additionalSpaceBetweenElements
              };
            } else {
                cov_1zaa8mkq1e.b[48][1]++;
                cov_1zaa8mkq1e.s[208]++;
                if (position === 'bottom') {
                  cov_1zaa8mkq1e.b[49][0]++;
                  cov_1zaa8mkq1e.s[209]++;

                  positionValue = {
                    top: positionOffset.top + elem[0].offsetHeight + additionalSpaceBetweenElements,
                    left: positionOffset.left
                  };
                } else {
                    cov_1zaa8mkq1e.b[49][1]++;
                    cov_1zaa8mkq1e.s[210]++;
                    if (position === 'left') {
                      cov_1zaa8mkq1e.b[50][0]++;
                      cov_1zaa8mkq1e.s[211]++;

                      positionValue = {
                        top: positionOffset.top,
                        left: positionOffset.left - componentWidthWithToolbars - additionalSpaceBetweenElements
                      };
                    } else {
                      cov_1zaa8mkq1e.b[50][1]++;
                    }
                  }
              }
          }cov_1zaa8mkq1e.s[212]++;
        return {
          top: positionValue.top + 'px',
          left: positionValue.left + 'px'
        };
      }

      function documentMousedownHandler() {
        cov_1zaa8mkq1e.f[24]++;
        cov_1zaa8mkq1e.s[213]++;

        hideColorpickerTemplate();
      }

      function showColorpickerTemplate() {
        cov_1zaa8mkq1e.f[25]++;
        cov_1zaa8mkq1e.s[214]++;

        if (!colorpickerTemplate.hasClass('colorpicker-visible')) {
          cov_1zaa8mkq1e.b[51][0]++;
          cov_1zaa8mkq1e.s[215]++;

          update();
          cov_1zaa8mkq1e.s[216]++;
          colorpickerTemplate.addClass('colorpicker-visible').css(getColorpickerTemplatePosition());
          cov_1zaa8mkq1e.s[217]++;
          emitEvent('colorpicker-shown');

          cov_1zaa8mkq1e.s[218]++;
          if (inline === false) {
            cov_1zaa8mkq1e.b[52][0]++;
            cov_1zaa8mkq1e.s[219]++;

            // register global mousedown event to hide the colorpicker
            $document.on('mousedown', documentMousedownHandler);
          } else {
            cov_1zaa8mkq1e.b[52][1]++;
          }

          cov_1zaa8mkq1e.s[220]++;
          if (attrs.colorpickerIsOpen) {
            cov_1zaa8mkq1e.b[53][0]++;
            cov_1zaa8mkq1e.s[221]++;

            $scope[attrs.colorpickerIsOpen] = true;
            cov_1zaa8mkq1e.s[222]++;
            if ((cov_1zaa8mkq1e.b[55][0]++, !$scope.$$phase) || (cov_1zaa8mkq1e.b[55][1]++, !$scope.$root.$$phase)) {
              cov_1zaa8mkq1e.b[54][0]++;
              cov_1zaa8mkq1e.s[223]++;

              $scope.$digest(); // trigger the watcher to fire
            } else {
              cov_1zaa8mkq1e.b[54][1]++;
            }
          } else {
            cov_1zaa8mkq1e.b[53][1]++;
          }
        } else {
          cov_1zaa8mkq1e.b[51][1]++;
        }
      }

      cov_1zaa8mkq1e.s[224]++;
      if (inline === false) {
        cov_1zaa8mkq1e.b[56][0]++;
        cov_1zaa8mkq1e.s[225]++;

        elem.on('click', showColorpickerTemplate);
      } else {
        cov_1zaa8mkq1e.b[56][1]++;
        cov_1zaa8mkq1e.s[226]++;

        showColorpickerTemplate();
      }

      cov_1zaa8mkq1e.s[227]++;
      colorpickerTemplate.on('mousedown', function (event) {
        cov_1zaa8mkq1e.f[26]++;
        cov_1zaa8mkq1e.s[228]++;

        event.stopPropagation();
        cov_1zaa8mkq1e.s[229]++;
        event.preventDefault();
      });

      function emitEvent(name) {
        cov_1zaa8mkq1e.f[27]++;
        cov_1zaa8mkq1e.s[230]++;

        if (ngModel) {
          cov_1zaa8mkq1e.b[57][0]++;
          cov_1zaa8mkq1e.s[231]++;

          $scope.$emit(name, {
            name: attrs.ngModel,
            value: ngModel.$modelValue
          });
        } else {
          cov_1zaa8mkq1e.b[57][1]++;
        }
      }

      function hideColorpickerTemplate() {
        cov_1zaa8mkq1e.f[28]++;
        cov_1zaa8mkq1e.s[232]++;

        if (colorpickerTemplate.hasClass('colorpicker-visible')) {
          cov_1zaa8mkq1e.b[58][0]++;
          cov_1zaa8mkq1e.s[233]++;

          colorpickerTemplate.removeClass('colorpicker-visible');
          cov_1zaa8mkq1e.s[234]++;
          emitEvent('colorpicker-closed');
          // unregister the global mousedown event
          cov_1zaa8mkq1e.s[235]++;
          $document.off('mousedown', documentMousedownHandler);

          cov_1zaa8mkq1e.s[236]++;
          if (attrs.colorpickerIsOpen) {
            cov_1zaa8mkq1e.b[59][0]++;
            cov_1zaa8mkq1e.s[237]++;

            $scope[attrs.colorpickerIsOpen] = false;
            cov_1zaa8mkq1e.s[238]++;
            if ((cov_1zaa8mkq1e.b[61][0]++, !$scope.$$phase) || (cov_1zaa8mkq1e.b[61][1]++, !$scope.$root.$$phase)) {
              cov_1zaa8mkq1e.b[60][0]++;
              cov_1zaa8mkq1e.s[239]++;

              $scope.$digest(); // trigger the watcher to fire
            } else {
              cov_1zaa8mkq1e.b[60][1]++;
            }
          } else {
            cov_1zaa8mkq1e.b[59][1]++;
          }
        } else {
          cov_1zaa8mkq1e.b[58][1]++;
        }
      }

      cov_1zaa8mkq1e.s[240]++;
      colorpickerTemplate.find('button').on('click', function () {
        cov_1zaa8mkq1e.f[29]++;
        cov_1zaa8mkq1e.s[241]++;

        hideColorpickerTemplate();
      });

      cov_1zaa8mkq1e.s[242]++;
      if (attrs.colorpickerIsOpen) {
        cov_1zaa8mkq1e.b[62][0]++;
        cov_1zaa8mkq1e.s[243]++;

        $scope.$watch(attrs.colorpickerIsOpen, function (shouldBeOpen) {
          cov_1zaa8mkq1e.f[30]++;
          cov_1zaa8mkq1e.s[244]++;

          if (shouldBeOpen === true) {
            cov_1zaa8mkq1e.b[63][0]++;
            cov_1zaa8mkq1e.s[245]++;

            showColorpickerTemplate();
          } else {
              cov_1zaa8mkq1e.b[63][1]++;
              cov_1zaa8mkq1e.s[246]++;
              if (shouldBeOpen === false) {
                cov_1zaa8mkq1e.b[64][0]++;
                cov_1zaa8mkq1e.s[247]++;

                hideColorpickerTemplate();
              } else {
                cov_1zaa8mkq1e.b[64][1]++;
              }
            }
        });
      } else {
        cov_1zaa8mkq1e.b[62][1]++;
      }
    }
  };
}]);