'use strict';

var cov_25xxk43zrk = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/agendas/AgendaCtrl.js',
      hash = '813dd8ac04f9f612ab3bdae393433b54fe25caa6',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/agendas/AgendaCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 234,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 23
        }
      },
      '2': {
        start: {
          line: 3,
          column: 19
        },
        end: {
          line: 3,
          column: 45
        }
      },
      '3': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 4,
          column: 34
        }
      },
      '4': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 32
        }
      },
      '5': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 62,
          column: 5
        }
      },
      '6': {
        start: {
          line: 11,
          column: 6
        },
        end: {
          line: 11,
          column: 25
        }
      },
      '7': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 16,
          column: 7
        }
      },
      '8': {
        start: {
          line: 14,
          column: 8
        },
        end: {
          line: 14,
          column: 40
        }
      },
      '9': {
        start: {
          line: 15,
          column: 8
        },
        end: {
          line: 15,
          column: 15
        }
      },
      '10': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 19,
          column: 7
        }
      },
      '11': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 40
        }
      },
      '12': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 25
        }
      },
      '13': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 39,
          column: 7
        }
      },
      '14': {
        start: {
          line: 27,
          column: 8
        },
        end: {
          line: 27,
          column: 27
        }
      },
      '15': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 30,
          column: 9
        }
      },
      '16': {
        start: {
          line: 29,
          column: 10
        },
        end: {
          line: 29,
          column: 17
        }
      },
      '17': {
        start: {
          line: 31,
          column: 8
        },
        end: {
          line: 38,
          column: 11
        }
      },
      '18': {
        start: {
          line: 64,
          column: 2
        },
        end: {
          line: 162,
          column: 4
        }
      },
      '19': {
        start: {
          line: 69,
          column: 6
        },
        end: {
          line: 73,
          column: 7
        }
      },
      '20': {
        start: {
          line: 70,
          column: 8
        },
        end: {
          line: 70,
          column: 36
        }
      },
      '21': {
        start: {
          line: 72,
          column: 8
        },
        end: {
          line: 72,
          column: 33
        }
      },
      '22': {
        start: {
          line: 76,
          column: 21
        },
        end: {
          line: 76,
          column: 23
        }
      },
      '23': {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 88,
          column: 7
        }
      },
      '24': {
        start: {
          line: 79,
          column: 8
        },
        end: {
          line: 87,
          column: 11
        }
      },
      '25': {
        start: {
          line: 90,
          column: 6
        },
        end: {
          line: 98,
          column: 10
        }
      },
      '26': {
        start: {
          line: 90,
          column: 74
        },
        end: {
          line: 98,
          column: 7
        }
      },
      '27': {
        start: {
          line: 100,
          column: 6
        },
        end: {
          line: 100,
          column: 35
        }
      },
      '28': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 105,
          column: 26
        }
      },
      '29': {
        start: {
          line: 106,
          column: 6
        },
        end: {
          line: 106,
          column: 21
        }
      },
      '30': {
        start: {
          line: 107,
          column: 6
        },
        end: {
          line: 115,
          column: 9
        }
      },
      '31': {
        start: {
          line: 108,
          column: 8
        },
        end: {
          line: 110,
          column: 9
        }
      },
      '32': {
        start: {
          line: 109,
          column: 10
        },
        end: {
          line: 109,
          column: 55
        }
      },
      '33': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 39
        }
      },
      '34': {
        start: {
          line: 112,
          column: 8
        },
        end: {
          line: 112,
          column: 37
        }
      },
      '35': {
        start: {
          line: 114,
          column: 8
        },
        end: {
          line: 114,
          column: 29
        }
      },
      '36': {
        start: {
          line: 118,
          column: 6
        },
        end: {
          line: 118,
          column: 26
        }
      },
      '37': {
        start: {
          line: 119,
          column: 6
        },
        end: {
          line: 119,
          column: 28
        }
      },
      '38': {
        start: {
          line: 120,
          column: 6
        },
        end: {
          line: 124,
          column: 9
        }
      },
      '39': {
        start: {
          line: 121,
          column: 8
        },
        end: {
          line: 121,
          column: 37
        }
      },
      '40': {
        start: {
          line: 123,
          column: 8
        },
        end: {
          line: 123,
          column: 29
        }
      },
      '41': {
        start: {
          line: 127,
          column: 21
        },
        end: {
          line: 127,
          column: 39
        }
      },
      '42': {
        start: {
          line: 128,
          column: 6
        },
        end: {
          line: 128,
          column: 46
        }
      },
      '43': {
        start: {
          line: 129,
          column: 6
        },
        end: {
          line: 136,
          column: 9
        }
      },
      '44': {
        start: {
          line: 139,
          column: 23
        },
        end: {
          line: 139,
          column: 41
        }
      },
      '45': {
        start: {
          line: 140,
          column: 6
        },
        end: {
          line: 140,
          column: 26
        }
      },
      '46': {
        start: {
          line: 141,
          column: 6
        },
        end: {
          line: 141,
          column: 31
        }
      },
      '47': {
        start: {
          line: 142,
          column: 6
        },
        end: {
          line: 142,
          column: 22
        }
      },
      '48': {
        start: {
          line: 146,
          column: 6
        },
        end: {
          line: 148,
          column: 9
        }
      },
      '49': {
        start: {
          line: 147,
          column: 8
        },
        end: {
          line: 147,
          column: 13
        }
      },
      '50': {
        start: {
          line: 152,
          column: 6
        },
        end: {
          line: 159,
          column: 9
        }
      },
      '51': {
        start: {
          line: 163,
          column: 2
        },
        end: {
          line: 163,
          column: 33
        }
      },
      '52': {
        start: {
          line: 164,
          column: 2
        },
        end: {
          line: 229,
          column: 5
        }
      },
      '53': {
        start: {
          line: 165,
          column: 4
        },
        end: {
          line: 168,
          column: 7
        }
      },
      '54': {
        start: {
          line: 166,
          column: 6
        },
        end: {
          line: 166,
          column: 57
        }
      },
      '55': {
        start: {
          line: 167,
          column: 6
        },
        end: {
          line: 167,
          column: 18
        }
      },
      '56': {
        start: {
          line: 169,
          column: 4
        },
        end: {
          line: 228,
          column: 6
        }
      },
      '57': {
        start: {
          line: 198,
          column: 24
        },
        end: {
          line: 202,
          column: 11
        }
      },
      '58': {
        start: {
          line: 204,
          column: 10
        },
        end: {
          line: 207,
          column: 11
        }
      },
      '59': {
        start: {
          line: 205,
          column: 12
        },
        end: {
          line: 205,
          column: 43
        }
      },
      '60': {
        start: {
          line: 206,
          column: 12
        },
        end: {
          line: 206,
          column: 19
        }
      },
      '61': {
        start: {
          line: 208,
          column: 10
        },
        end: {
          line: 208,
          column: 47
        }
      },
      '62': {
        start: {
          line: 211,
          column: 28
        },
        end: {
          line: 211,
          column: 134
        }
      },
      '63': {
        start: {
          line: 212,
          column: 10
        },
        end: {
          line: 222,
          column: 11
        }
      },
      '64': {
        start: {
          line: 213,
          column: 25
        },
        end: {
          line: 213,
          column: 105
        }
      },
      '65': {
        start: {
          line: 214,
          column: 12
        },
        end: {
          line: 214,
          column: 215
        }
      },
      '66': {
        start: {
          line: 215,
          column: 12
        },
        end: {
          line: 215,
          column: 55
        }
      },
      '67': {
        start: {
          line: 216,
          column: 12
        },
        end: {
          line: 216,
          column: 51
        }
      },
      '68': {
        start: {
          line: 219,
          column: 12
        },
        end: {
          line: 221,
          column: 15
        }
      },
      '69': {
        start: {
          line: 220,
          column: 14
        },
        end: {
          line: 220,
          column: 47
        }
      },
      '70': {
        start: {
          line: 223,
          column: 10
        },
        end: {
          line: 223,
          column: 104
        }
      },
      '71': {
        start: {
          line: 225,
          column: 10
        },
        end: {
          line: 225,
          column: 36
        }
      },
      '72': {
        start: {
          line: 231,
          column: 2
        },
        end: {
          line: 231,
          column: 23
        }
      },
      '73': {
        start: {
          line: 237,
          column: 2
        },
        end: {
          line: 237,
          column: 28
        }
      },
      '74': {
        start: {
          line: 238,
          column: 15
        },
        end: {
          line: 238,
          column: 23
        }
      },
      '75': {
        start: {
          line: 240,
          column: 2
        },
        end: {
          line: 242,
          column: 4
        }
      },
      '76': {
        start: {
          line: 241,
          column: 4
        },
        end: {
          line: 241,
          column: 34
        }
      },
      '77': {
        start: {
          line: 244,
          column: 2
        },
        end: {
          line: 262,
          column: 3
        }
      },
      '78': {
        start: {
          line: 245,
          column: 4
        },
        end: {
          line: 245,
          column: 26
        }
      },
      '79': {
        start: {
          line: 246,
          column: 4
        },
        end: {
          line: 261,
          column: 7
        }
      },
      '80': {
        start: {
          line: 247,
          column: 6
        },
        end: {
          line: 247,
          column: 29
        }
      },
      '81': {
        start: {
          line: 248,
          column: 6
        },
        end: {
          line: 255,
          column: 8
        }
      },
      '82': {
        start: {
          line: 256,
          column: 6
        },
        end: {
          line: 258,
          column: 7
        }
      },
      '83': {
        start: {
          line: 257,
          column: 8
        },
        end: {
          line: 257,
          column: 55
        }
      },
      '84': {
        start: {
          line: 260,
          column: 6
        },
        end: {
          line: 260,
          column: 29
        }
      },
      '85': {
        start: {
          line: 264,
          column: 2
        },
        end: {
          line: 272,
          column: 4
        }
      },
      '86': {
        start: {
          line: 265,
          column: 4
        },
        end: {
          line: 271,
          column: 7
        }
      },
      '87': {
        start: {
          line: 266,
          column: 6
        },
        end: {
          line: 266,
          column: 26
        }
      },
      '88': {
        start: {
          line: 267,
          column: 6
        },
        end: {
          line: 267,
          column: 35
        }
      },
      '89': {
        start: {
          line: 270,
          column: 8
        },
        end: {
          line: 270,
          column: 29
        }
      },
      '90': {
        start: {
          line: 273,
          column: 2
        },
        end: {
          line: 319,
          column: 4
        }
      },
      '91': {
        start: {
          line: 274,
          column: 25
        },
        end: {
          line: 274,
          column: 59
        }
      },
      '92': {
        start: {
          line: 275,
          column: 4
        },
        end: {
          line: 275,
          column: 30
        }
      },
      '93': {
        start: {
          line: 285,
          column: 4
        },
        end: {
          line: 289,
          column: 5
        }
      },
      '94': {
        start: {
          line: 286,
          column: 6
        },
        end: {
          line: 286,
          column: 31
        }
      },
      '95': {
        start: {
          line: 287,
          column: 6
        },
        end: {
          line: 287,
          column: 26
        }
      },
      '96': {
        start: {
          line: 288,
          column: 6
        },
        end: {
          line: 288,
          column: 13
        }
      },
      '97': {
        start: {
          line: 290,
          column: 17
        },
        end: {
          line: 290,
          column: 19
        }
      },
      '98': {
        start: {
          line: 291,
          column: 4
        },
        end: {
          line: 295,
          column: 5
        }
      },
      '99': {
        start: {
          line: 292,
          column: 6
        },
        end: {
          line: 294,
          column: 7
        }
      },
      '100': {
        start: {
          line: 293,
          column: 8
        },
        end: {
          line: 293,
          column: 23
        }
      },
      '101': {
        start: {
          line: 296,
          column: 4
        },
        end: {
          line: 296,
          column: 30
        }
      },
      '102': {
        start: {
          line: 298,
          column: 4
        },
        end: {
          line: 318,
          column: 7
        }
      },
      '103': {
        start: {
          line: 299,
          column: 6
        },
        end: {
          line: 299,
          column: 26
        }
      },
      '104': {
        start: {
          line: 300,
          column: 6
        },
        end: {
          line: 300,
          column: 35
        }
      },
      '105': {
        start: {
          line: 302,
          column: 6
        },
        end: {
          line: 304,
          column: 7
        }
      },
      '106': {
        start: {
          line: 303,
          column: 8
        },
        end: {
          line: 303,
          column: 56
        }
      },
      '107': {
        start: {
          line: 305,
          column: 6
        },
        end: {
          line: 305,
          column: 39
        }
      },
      '108': {
        start: {
          line: 307,
          column: 6
        },
        end: {
          line: 314,
          column: 9
        }
      },
      '109': {
        start: {
          line: 316,
          column: 6
        },
        end: {
          line: 316,
          column: 35
        }
      },
      '110': {
        start: {
          line: 317,
          column: 6
        },
        end: {
          line: 317,
          column: 27
        }
      },
      '111': {
        start: {
          line: 320,
          column: 2
        },
        end: {
          line: 322,
          column: 4
        }
      },
      '112': {
        start: {
          line: 321,
          column: 4
        },
        end: {
          line: 321,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 47
          },
          end: {
            line: 1,
            column: 48
          }
        },
        loc: {
          start: {
            line: 1,
            column: 175
          },
          end: {
            line: 234,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 24,
            column: 15
          },
          end: {
            line: 24,
            column: 16
          }
        },
        loc: {
          start: {
            line: 24,
            column: 23
          },
          end: {
            line: 40,
            column: 5
          }
        },
        line: 24
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 90,
            column: 61
          },
          end: {
            line: 90,
            column: 62
          }
        },
        loc: {
          start: {
            line: 90,
            column: 74
          },
          end: {
            line: 98,
            column: 7
          }
        },
        line: 90
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 107,
            column: 58
          },
          end: {
            line: 107,
            column: 59
          }
        },
        loc: {
          start: {
            line: 107,
            column: 66
          },
          end: {
            line: 113,
            column: 7
          }
        },
        line: 107
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 113,
            column: 9
          },
          end: {
            line: 113,
            column: 10
          }
        },
        loc: {
          start: {
            line: 113,
            column: 15
          },
          end: {
            line: 115,
            column: 7
          }
        },
        line: 113
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 120,
            column: 52
          },
          end: {
            line: 120,
            column: 53
          }
        },
        loc: {
          start: {
            line: 120,
            column: 60
          },
          end: {
            line: 122,
            column: 7
          }
        },
        line: 120
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 122,
            column: 9
          },
          end: {
            line: 122,
            column: 10
          }
        },
        loc: {
          start: {
            line: 122,
            column: 15
          },
          end: {
            line: 124,
            column: 7
          }
        },
        line: 122
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 146,
            column: 84
          },
          end: {
            line: 146,
            column: 85
          }
        },
        loc: {
          start: {
            line: 146,
            column: 90
          },
          end: {
            line: 148,
            column: 7
          }
        },
        line: 146
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 164,
            column: 44
          },
          end: {
            line: 164,
            column: 45
          }
        },
        loc: {
          start: {
            line: 164,
            column: 52
          },
          end: {
            line: 229,
            column: 3
          }
        },
        line: 164
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 165,
            column: 36
          },
          end: {
            line: 165,
            column: 37
          }
        },
        loc: {
          start: {
            line: 165,
            column: 44
          },
          end: {
            line: 168,
            column: 5
          }
        },
        line: 165
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 219,
            column: 37
          },
          end: {
            line: 219,
            column: 38
          }
        },
        loc: {
          start: {
            line: 219,
            column: 43
          },
          end: {
            line: 221,
            column: 13
          }
        },
        line: 219
      },
      '11': {
        name: 'AgendaCtrl_edit',
        decl: {
          start: {
            line: 236,
            column: 9
          },
          end: {
            line: 236,
            column: 24
          }
        },
        loc: {
          start: {
            line: 236,
            column: 87
          },
          end: {
            line: 323,
            column: 1
          }
        },
        line: 236
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 240,
            column: 21
          },
          end: {
            line: 240,
            column: 22
          }
        },
        loc: {
          start: {
            line: 240,
            column: 33
          },
          end: {
            line: 242,
            column: 3
          }
        },
        line: 240
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 246,
            column: 76
          },
          end: {
            line: 246,
            column: 77
          }
        },
        loc: {
          start: {
            line: 246,
            column: 84
          },
          end: {
            line: 259,
            column: 5
          }
        },
        line: 246
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 259,
            column: 7
          },
          end: {
            line: 259,
            column: 8
          }
        },
        loc: {
          start: {
            line: 259,
            column: 13
          },
          end: {
            line: 261,
            column: 5
          }
        },
        line: 259
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 264,
            column: 18
          },
          end: {
            line: 264,
            column: 19
          }
        },
        loc: {
          start: {
            line: 264,
            column: 32
          },
          end: {
            line: 272,
            column: 3
          }
        },
        line: 264
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 265,
            column: 77
          },
          end: {
            line: 265,
            column: 78
          }
        },
        loc: {
          start: {
            line: 265,
            column: 83
          },
          end: {
            line: 271,
            column: 5
          }
        },
        line: 265
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 273,
            column: 24
          },
          end: {
            line: 273,
            column: 25
          }
        },
        loc: {
          start: {
            line: 273,
            column: 36
          },
          end: {
            line: 319,
            column: 3
          }
        },
        line: 273
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 298,
            column: 90
          },
          end: {
            line: 298,
            column: 91
          }
        },
        loc: {
          start: {
            line: 298,
            column: 98
          },
          end: {
            line: 318,
            column: 5
          }
        },
        line: 298
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 320,
            column: 22
          },
          end: {
            line: 320,
            column: 23
          }
        },
        loc: {
          start: {
            line: 320,
            column: 34
          },
          end: {
            line: 322,
            column: 3
          }
        },
        line: 320
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 13,
            column: 6
          },
          end: {
            line: 16,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 13,
            column: 6
          },
          end: {
            line: 16,
            column: 7
          }
        }, {
          start: {
            line: 13,
            column: 6
          },
          end: {
            line: 16,
            column: 7
          }
        }],
        line: 13
      },
      '1': {
        loc: {
          start: {
            line: 13,
            column: 10
          },
          end: {
            line: 13,
            column: 102
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 13,
            column: 10
          },
          end: {
            line: 13,
            column: 51
          }
        }, {
          start: {
            line: 13,
            column: 56
          },
          end: {
            line: 13,
            column: 101
          }
        }],
        line: 13
      },
      '2': {
        loc: {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }, {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }],
        line: 17
      },
      '3': {
        loc: {
          start: {
            line: 17,
            column: 10
          },
          end: {
            line: 17,
            column: 104
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 17,
            column: 10
          },
          end: {
            line: 17,
            column: 47
          }
        }, {
          start: {
            line: 17,
            column: 52
          },
          end: {
            line: 17,
            column: 103
          }
        }],
        line: 17
      },
      '4': {
        loc: {
          start: {
            line: 26,
            column: 6
          },
          end: {
            line: 39,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 26,
            column: 6
          },
          end: {
            line: 39,
            column: 7
          }
        }, {
          start: {
            line: 26,
            column: 6
          },
          end: {
            line: 39,
            column: 7
          }
        }],
        line: 26
      },
      '5': {
        loc: {
          start: {
            line: 28,
            column: 8
          },
          end: {
            line: 30,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 8
          },
          end: {
            line: 30,
            column: 9
          }
        }, {
          start: {
            line: 28,
            column: 8
          },
          end: {
            line: 30,
            column: 9
          }
        }],
        line: 28
      },
      '6': {
        loc: {
          start: {
            line: 68,
            column: 11
          },
          end: {
            line: 68,
            column: 35
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 68,
            column: 19
          },
          end: {
            line: 68,
            column: 35
          }
        }],
        line: 68
      },
      '7': {
        loc: {
          start: {
            line: 68,
            column: 37
          },
          end: {
            line: 68,
            column: 57
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 68,
            column: 43
          },
          end: {
            line: 68,
            column: 57
          }
        }],
        line: 68
      },
      '8': {
        loc: {
          start: {
            line: 69,
            column: 6
          },
          end: {
            line: 73,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 69,
            column: 6
          },
          end: {
            line: 73,
            column: 7
          }
        }, {
          start: {
            line: 69,
            column: 6
          },
          end: {
            line: 73,
            column: 7
          }
        }],
        line: 69
      },
      '9': {
        loc: {
          start: {
            line: 75,
            column: 21
          },
          end: {
            line: 75,
            column: 33
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 75,
            column: 29
          },
          end: {
            line: 75,
            column: 33
          }
        }],
        line: 75
      },
      '10': {
        loc: {
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 110,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 110,
            column: 9
          }
        }, {
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 110,
            column: 9
          }
        }],
        line: 108
      },
      '11': {
        loc: {
          start: {
            line: 204,
            column: 10
          },
          end: {
            line: 207,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 204,
            column: 10
          },
          end: {
            line: 207,
            column: 11
          }
        }, {
          start: {
            line: 204,
            column: 10
          },
          end: {
            line: 207,
            column: 11
          }
        }],
        line: 204
      },
      '12': {
        loc: {
          start: {
            line: 212,
            column: 10
          },
          end: {
            line: 222,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 212,
            column: 10
          },
          end: {
            line: 222,
            column: 11
          }
        }, {
          start: {
            line: 212,
            column: 10
          },
          end: {
            line: 222,
            column: 11
          }
        }],
        line: 212
      },
      '13': {
        loc: {
          start: {
            line: 213,
            column: 25
          },
          end: {
            line: 213,
            column: 105
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 213,
            column: 25
          },
          end: {
            line: 213,
            column: 99
          }
        }, {
          start: {
            line: 213,
            column: 103
          },
          end: {
            line: 213,
            column: 105
          }
        }],
        line: 213
      },
      '14': {
        loc: {
          start: {
            line: 213,
            column: 26
          },
          end: {
            line: 213,
            column: 95
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 213,
            column: 26
          },
          end: {
            line: 213,
            column: 89
          }
        }, {
          start: {
            line: 213,
            column: 93
          },
          end: {
            line: 213,
            column: 95
          }
        }],
        line: 213
      },
      '15': {
        loc: {
          start: {
            line: 244,
            column: 2
          },
          end: {
            line: 262,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 244,
            column: 2
          },
          end: {
            line: 262,
            column: 3
          }
        }, {
          start: {
            line: 244,
            column: 2
          },
          end: {
            line: 262,
            column: 3
          }
        }],
        line: 244
      },
      '16': {
        loc: {
          start: {
            line: 285,
            column: 4
          },
          end: {
            line: 289,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 285,
            column: 4
          },
          end: {
            line: 289,
            column: 5
          }
        }, {
          start: {
            line: 285,
            column: 4
          },
          end: {
            line: 289,
            column: 5
          }
        }],
        line: 285
      },
      '17': {
        loc: {
          start: {
            line: 292,
            column: 6
          },
          end: {
            line: 294,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 292,
            column: 6
          },
          end: {
            line: 294,
            column: 7
          }
        }, {
          start: {
            line: 292,
            column: 6
          },
          end: {
            line: 294,
            column: 7
          }
        }],
        line: 292
      },
      '18': {
        loc: {
          start: {
            line: 302,
            column: 6
          },
          end: {
            line: 304,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 302,
            column: 6
          },
          end: {
            line: 304,
            column: 7
          }
        }, {
          start: {
            line: 302,
            column: 6
          },
          end: {
            line: 304,
            column: 7
          }
        }],
        line: 302
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0],
      '7': [0],
      '8': [0, 0],
      '9': [0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_25xxk43zrk.s[0]++;
angular.module('app').controller('AgendaCtrl', function ($scope, SweetAlert, $deltadate, $translate, $CRUDService, $rootScope, $deltahttp, $compile, $uibModal, $filter, $sce, $log) {
  cov_25xxk43zrk.f[0]++;

  var PATH = (cov_25xxk43zrk.s[1]++, 'Agenda');
  var timezone = (cov_25xxk43zrk.s[2]++, new ej.schedule.Timezone());
  cov_25xxk43zrk.s[3]++;
  $scope.beginDate = '1990-01-01';
  cov_25xxk43zrk.s[4]++;
  $scope.endDate = '2100-01-01';

  cov_25xxk43zrk.s[5]++;
  $scope.scheduleObj = new ej.schedule.Schedule({
    actionBegin: function actionBegin(args) {},
    cellClick: function cellClick(args) {
      cov_25xxk43zrk.s[6]++;

      args.cancel = true;
      // e-work-cells e-work-days e-selected-cell
      cov_25xxk43zrk.s[7]++;
      if ((cov_25xxk43zrk.b[1][0]++, angular.isDefined(args.element.className)) && (cov_25xxk43zrk.b[1][1]++, args.element.className !== 'e-more-indicator')) {
        cov_25xxk43zrk.b[0][0]++;
        cov_25xxk43zrk.s[8]++;

        $scope.data.add(args.startTime);
        cov_25xxk43zrk.s[9]++;
        return;
      } else {
        cov_25xxk43zrk.b[0][1]++;
      }
      cov_25xxk43zrk.s[10]++;
      if ((cov_25xxk43zrk.b[3][0]++, angular.isDefined(args.event.path[0])) && (cov_25xxk43zrk.b[3][1]++, args.event.path[0].className !== 'e-more-indicator')) {
        cov_25xxk43zrk.b[2][0]++;
        cov_25xxk43zrk.s[11]++;

        $scope.data.add(args.startTime);
      } else {
        cov_25xxk43zrk.b[2][1]++;
      }
    },
    cellDoubleClick: function cellDoubleClick(args) {
      cov_25xxk43zrk.s[12]++;

      args.cancel = true;
    },

    popupOpen: function popupOpen(args) {
      cov_25xxk43zrk.f[1]++;
      cov_25xxk43zrk.s[13]++;


      if (args.type === 'Editor') {
        cov_25xxk43zrk.b[4][0]++;
        cov_25xxk43zrk.s[14]++;

        args.cancel = true;
        cov_25xxk43zrk.s[15]++;
        if (args.data.length == 0) {
          cov_25xxk43zrk.b[5][0]++;
          cov_25xxk43zrk.s[16]++;

          return;
        } else {
          cov_25xxk43zrk.b[5][1]++;
        }
        cov_25xxk43zrk.s[17]++;
        $scope.data.edit({
          id: args.data.Id,
          LABEL: args.data.Subject,
          DESCRIPTION: args.data.Description,
          BEGIN: args.data.StartTime.toJSON(),
          END: args.data.EndTime.toJSON(),
          USERS: args.USERS
        });
      } else {
        cov_25xxk43zrk.b[4][1]++;
      }
    },
    width: '100%',
    height: '100%',
    // headerRows: '54px',
    // dateFormat:  'medium',
    views: ['Day', 'Week', 'Month', 'Agenda'],
    // timeFormat: {type: 'time', skeleton:'medium'},
    // timezone: 'UTC',
    // workHours: {start: '11:00'},
    // selectedDate: new Date(2021, 5, 20),
    selectedDate: new Date(),
    headerRows: [{ option: 'Year' }, { option: 'Month' }, { option: 'Week' }, { option: 'Date' }, { option: 'Hour' }],
    eventSettings: { dataSource: [], allowDeleting: false },
    eventRendered: function eventRendered(args) {
      // window.applyCategoryColor(args, $scope.scheduleObj.currentView);
    }
  });

  cov_25xxk43zrk.s[18]++;
  $scope.data = {
    eventSources: [],
    alreadyDraw: false,
    type: false,
    getAll: function getAll() {
      var begin = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (cov_25xxk43zrk.b[6][0]++, $scope.beginDate);
      var end = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_25xxk43zrk.b[7][0]++, $scope.endDate);
      cov_25xxk43zrk.s[19]++;

      if (this.type) {
        cov_25xxk43zrk.b[8][0]++;
        cov_25xxk43zrk.s[20]++;

        this._getSingle(begin, end);
      } else {
        cov_25xxk43zrk.b[8][1]++;
        cov_25xxk43zrk.s[21]++;

        this._getAll(begin, end);
      }
    },
    _fillEvent: function _fillEvent(data) {
      var added = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_25xxk43zrk.b[9][0]++, true);

      var events = (cov_25xxk43zrk.s[22]++, []);

      cov_25xxk43zrk.s[23]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var event = _step.value;
          cov_25xxk43zrk.s[24]++;

          events.push({
            user: event.IDUSER,
            owner: event.IDUSER == $CRUDService.getUser(),
            title: event.LABEL,
            start: new Date(event.BEGIN),
            end: new Date(event.END),
            id: event.id,
            description: event.DESCRIPTION
          });
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_25xxk43zrk.s[25]++;
      $scope.scheduleObj.eventSettings.dataSource = data.map(function (schedule) {
        cov_25xxk43zrk.f[2]++;
        cov_25xxk43zrk.s[26]++;
        return {
          Id: schedule.id,
          Subject: schedule.LABEL,
          Description: schedule.DESCRIPTION,
          USERS: schedule.USERS,
          Location: null,
          StartTime: new Date(schedule.BEGIN), // Date.newDate(schedule.BEGIN),
          EndTime: new Date(schedule.END) // Date.newDate(schedule.END)
        };
      });

      cov_25xxk43zrk.s[27]++;
      $scope.scheduleObj.refresh();

      // $scope.data.eventSources.dataSource = data;
    },
    _getAll: function _getAll(begin, end) {
      var _this = this;

      cov_25xxk43zrk.s[28]++;

      this.loading = true;
      cov_25xxk43zrk.s[29]++;
      this.data = [];
      cov_25xxk43zrk.s[30]++;
      $CRUDService.getAll(PATH, { get: 'all', begin: begin, end: end }, function (data) {
        cov_25xxk43zrk.f[3]++;
        cov_25xxk43zrk.s[31]++;

        if (!$scope.data.alreadyDraw) {
          cov_25xxk43zrk.b[10][0]++;
          cov_25xxk43zrk.s[32]++;

          $scope.scheduleObj.appendTo('#agenda-panel');
        } else {
          cov_25xxk43zrk.b[10][1]++;
        }
        cov_25xxk43zrk.s[33]++;
        $scope.data.alreadyDraw = true;
        cov_25xxk43zrk.s[34]++;
        $scope.data._fillEvent(data);
      }, function () {
        cov_25xxk43zrk.f[4]++;
        cov_25xxk43zrk.s[35]++;

        _this.loading = false;
      });
    },
    _getSingle: function _getSingle() {
      var _this2 = this;

      cov_25xxk43zrk.s[36]++;

      this.loading = true;
      cov_25xxk43zrk.s[37]++;
      $scope.data.data = [];
      cov_25xxk43zrk.s[38]++;
      $CRUDService.getAll(PATH, { get: 'all_owner' }, function (data) {
        cov_25xxk43zrk.f[5]++;
        cov_25xxk43zrk.s[39]++;

        $scope.data._fillEvent(data);
      }, function () {
        cov_25xxk43zrk.f[6]++;
        cov_25xxk43zrk.s[40]++;

        _this2.loading = false;
      });
    },
    add: function add(date) {
      var myDate = (cov_25xxk43zrk.s[41]++, angular.copy(date));
      cov_25xxk43zrk.s[42]++;
      myDate.setHours(myDate.getHours() + 24);
      cov_25xxk43zrk.s[43]++;
      this.edit({
        LABEL: '',
        DESCRIPTION: '',
        BEGIN: date.toJSON(),
        END: myDate.toJSON(),
        USERS: [],
        id: 0
      });
    },
    edit: function edit(item) {
      var itemEdit = (cov_25xxk43zrk.s[44]++, angular.copy(item));
      cov_25xxk43zrk.s[45]++;
      itemEdit.USERS = {};
      cov_25xxk43zrk.s[46]++;
      this.itemEdit = itemEdit;
      cov_25xxk43zrk.s[47]++;
      this.openEdit();
    },
    saveData: function saveData(item, fn) {
      cov_25xxk43zrk.s[48]++;

      $CRUDService.save(PATH, { action: 'MiseAJour', valeur: $filter('json')(item) }, function () {
        cov_25xxk43zrk.f[7]++;
        cov_25xxk43zrk.s[49]++;

        fn();
      });
    },

    listUser: [],
    openEdit: function openEdit() {
      cov_25xxk43zrk.s[50]++;

      $uibModal.open({
        templateUrl: 'app/views/agendas/edit.html',
        controller: AgendaCtrl_edit,
        scope: $scope,
        size: 'sm_',
        backdrop: 'static',
        windowClass: 'animated fadeInRight left-modal'
      });
    }
  };
  cov_25xxk43zrk.s[51]++;
  $scope.actualUser = Date.now();
  cov_25xxk43zrk.s[52]++;
  $CRUDService.getAll('User', { get: 'all' }, function (data) {
    cov_25xxk43zrk.f[8]++;
    cov_25xxk43zrk.s[53]++;

    $scope.data.listUser = data.map(function (user) {
      cov_25xxk43zrk.f[9]++;
      cov_25xxk43zrk.s[54]++;

      user.imageUrl = $deltahttp.getUserPicture(user.id);
      cov_25xxk43zrk.s[55]++;
      return user;
    });
    cov_25xxk43zrk.s[56]++;
    $scope.uiConfig = {
      lastSelected: {},
      calendar: {
        editable: false,
        header: {
          center: 'title',
          // right: 'myCustomAddButton',
          left: 'month,agendaWeek,agendaDay,listWeek',
          right: 'today prev,next'

        },
        buttonText: {
          prevYear: '&laquo;',
          // <<
          nextYear: '&raquo;',
          // >>
          today: $translate.instant('AGENDA.TODAY'),
          month: $translate.instant('AGENDA.MONTH'),
          week: $translate.instant('AGENDA.WEEK'),
          day: $translate.instant('AGENDA.DAY')
        },
        views: {
          list: { buttonText: $translate.instant('AGENDA.LIST') },
          today: { buttonText: $translate.instant('AGENDA.TODAY') },
          month: { buttonText: $translate.instant('AGENDA.MONTH') },
          agendaWeek: { buttonText: $translate.instant('AGENDA.WEEK') },
          agendaDay: { buttonText: $translate.instant('AGENDA.DAY') }
        },
        dayClick: function dayClick(date, jsEvent, view) {
          var value = (cov_25xxk43zrk.s[57]++, {
            id: date.toJSON(),
            element: jsEvent.toElement,
            name: view.name
          });

          cov_25xxk43zrk.s[58]++;
          if (value.id == $scope.uiConfig.lastSelected.id) {
            cov_25xxk43zrk.b[11][0]++;
            cov_25xxk43zrk.s[59]++;

            $scope.data.add(date.toDate());
            cov_25xxk43zrk.s[60]++;
            return;
          } else {
            cov_25xxk43zrk.b[11][1]++;
          }
          cov_25xxk43zrk.s[61]++;
          $scope.uiConfig.lastSelected = value;
        },
        eventRender: function eventRender(event, element) {
          var htmlToolTip = (cov_25xxk43zrk.s[62]++, $sce.trustAsHtml(('<div style="font-weight: bold">' + event.description + '</div>').replace('\'', '\\\'')));
          cov_25xxk43zrk.s[63]++;
          if (!event.owner) {
            cov_25xxk43zrk.b[12][0]++;

            var user = (cov_25xxk43zrk.s[64]++, (cov_25xxk43zrk.b[13][0]++, ((cov_25xxk43zrk.b[14][0]++, $filter('filter')($scope.data.listUser, { id: event.user }, true)) || (cov_25xxk43zrk.b[14][1]++, []))[0]) || (cov_25xxk43zrk.b[13][1]++, {}));
            cov_25xxk43zrk.s[65]++;
            htmlToolTip = $sce.trustAsHtml(('<div><i class="fa fa-user"></i> ' + user.NOMUSER + ' ' + user.PRENOMUSER + '</div><div style="font-weight: bold">' + event.description + '</div>').replace('\'', '\\\''));
            cov_25xxk43zrk.s[66]++;
            element.css('background-color', '#e67254');
            cov_25xxk43zrk.s[67]++;
            element.css('border-color', '#e67254');
            // element.attr({'uib-tooltip-html': '\'' + htmlToolTip + '\'', 'tooltip-append-to-body': true});
          } else {
            cov_25xxk43zrk.b[12][1]++;
            cov_25xxk43zrk.s[68]++;

            element.bind('dblclick', function () {
              cov_25xxk43zrk.f[10]++;
              cov_25xxk43zrk.s[69]++;

              $scope.data.edit({ id: event.id });
            });
          }
          cov_25xxk43zrk.s[70]++;
          element.attr({ 'uib-tooltip-html': '\'' + htmlToolTip + '\'', 'tooltip-append-to-body': true });

          cov_25xxk43zrk.s[71]++;
          $compile(element)($scope);
        }
      }
    };
  });

  cov_25xxk43zrk.s[72]++;
  $scope.data.getAll();

  // $scope.data._fillEvent();
});

function AgendaCtrl_edit($scope, $uibModalInstance, $filter, $CRUDService, $rootScope) {
  cov_25xxk43zrk.f[11]++;
  cov_25xxk43zrk.s[73]++;

  $scope.error_code = false;
  var PATH = (cov_25xxk43zrk.s[74]++, 'Agenda');

  cov_25xxk43zrk.s[75]++;
  $scope.getUserId = function () {
    cov_25xxk43zrk.f[12]++;
    cov_25xxk43zrk.s[76]++;

    return $CRUDService.getUser();
  };

  cov_25xxk43zrk.s[77]++;
  if ($scope.data.itemEdit.id > 0) {
    cov_25xxk43zrk.b[15][0]++;
    cov_25xxk43zrk.s[78]++;

    $scope.loading = true;
    cov_25xxk43zrk.s[79]++;
    $CRUDService.getAll(PATH, { get: 'single', id: $scope.data.itemEdit.id }, function (data) {
      cov_25xxk43zrk.f[13]++;
      cov_25xxk43zrk.s[80]++;

      $scope.loading = false;
      cov_25xxk43zrk.s[81]++;
      $scope.data.itemEdit = {
        BEGIN: new Date(data.BEGIN),
        END: new Date(data.END),
        USERS: {},
        id: data.id,
        LABEL: data.LABEL,
        DESCRIPTION: data.DESCRIPTION
      };
      cov_25xxk43zrk.s[82]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = data.USERS[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var user = _step2.value;
          cov_25xxk43zrk.s[83]++;

          $scope.data.itemEdit.USERS[user.IDUSER] = true;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    }, function () {
      cov_25xxk43zrk.f[14]++;
      cov_25xxk43zrk.s[84]++;

      $scope.loading = false;
    });
  } else {
    cov_25xxk43zrk.b[15][1]++;
  }

  cov_25xxk43zrk.s[85]++;
  $scope.delete = function (id) {
    cov_25xxk43zrk.f[15]++;
    cov_25xxk43zrk.s[86]++;

    $CRUDService.save(PATH, { action: 'supp', valeur: $filter('json')({ id: id }) }, function () {
      cov_25xxk43zrk.f[16]++;
      cov_25xxk43zrk.s[87]++;

      $scope.closeModal();
      cov_25xxk43zrk.s[88]++;
      $rootScope.getNotification();
      /* $scope.scheduleObj.deleteEvent(id, 'Delete');
      $scope.scheduleObj.refreshEvents();
      */cov_25xxk43zrk.s[89]++;
      $scope.data.getAll();
    });
  };
  cov_25xxk43zrk.s[90]++;
  $scope.setValidData = function () {
    cov_25xxk43zrk.f[17]++;

    var validateItem = (cov_25xxk43zrk.s[91]++, angular.copy($scope.data.itemEdit));
    cov_25xxk43zrk.s[92]++;
    $scope.error_code = false;
    /*
        if (angular.isDate(validateItem.BEGIN)) {
          validateItem.BEGIN = validateItem.BEGIN.getTime();
        }
    
        if (angular.isDate(validateItem.END)) {
          validateItem.END = validateItem.END.getTime();
        } */

    cov_25xxk43zrk.s[93]++;
    if (validateItem.BEGIN >= validateItem.END) {
      cov_25xxk43zrk.b[16][0]++;
      cov_25xxk43zrk.s[94]++;

      $scope.error_code = true;
      cov_25xxk43zrk.s[95]++;
      $scope.data.tab = 1;
      cov_25xxk43zrk.s[96]++;
      return;
    } else {
      cov_25xxk43zrk.b[16][1]++;
    }
    var user = (cov_25xxk43zrk.s[97]++, []);
    cov_25xxk43zrk.s[98]++;
    for (var key in validateItem.USERS) {
      cov_25xxk43zrk.s[99]++;

      if (validateItem.USERS[key]) {
        cov_25xxk43zrk.b[17][0]++;
        cov_25xxk43zrk.s[100]++;

        user.push(key);
      } else {
        cov_25xxk43zrk.b[17][1]++;
      }
    }
    cov_25xxk43zrk.s[101]++;
    validateItem.USERS = user;

    cov_25xxk43zrk.s[102]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function (data) {
      cov_25xxk43zrk.f[18]++;
      cov_25xxk43zrk.s[103]++;

      $scope.closeModal();
      cov_25xxk43zrk.s[104]++;
      $rootScope.getNotification();

      cov_25xxk43zrk.s[105]++;
      if (validateItem.id > 0) {
        cov_25xxk43zrk.b[18][0]++;
        cov_25xxk43zrk.s[106]++;

        $scope.scheduleObj.deleteEvent(validateItem.id);
      } else {
        cov_25xxk43zrk.b[18][1]++;
      }
      cov_25xxk43zrk.s[107]++;
      validateItem.id = parseInt(data);

      cov_25xxk43zrk.s[108]++;
      $scope.scheduleObj.addEvent({
        Id: validateItem.id,
        Subject: validateItem.LABEL,
        Description: validateItem.description,
        Location: null,
        StartTime: Date.newDate(validateItem.BEGIN),
        EndTime: Date.newDate(validateItem.END)
      });

      cov_25xxk43zrk.s[109]++;
      $rootScope.getNotification();
      cov_25xxk43zrk.s[110]++;
      $scope.data.getAll();
    });
  };
  cov_25xxk43zrk.s[111]++;
  $scope.closeModal = function () {
    cov_25xxk43zrk.f[19]++;
    cov_25xxk43zrk.s[112]++;

    $uibModalInstance.dismiss('cancel');
  };
}