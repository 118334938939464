'use strict';

var cov_1hq1v4j4z8 = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/activities_transversal/edit/edit.js',
      hash = '80f91d4e8abc861ac64b11055c8af925930504d3',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/activities_transversal/edit/edit.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 119,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 2,
          column: 37
        }
      },
      '2': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 5,
          column: 3
        }
      },
      '3': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 4,
          column: 11
        }
      },
      '4': {
        start: {
          line: 6,
          column: 15
        },
        end: {
          line: 6,
          column: 30
        }
      },
      '5': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 111,
          column: 4
        }
      },
      '6': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 15,
          column: 9
        }
      },
      '7': {
        start: {
          line: 14,
          column: 8
        },
        end: {
          line: 14,
          column: 37
        }
      },
      '8': {
        start: {
          line: 18,
          column: 23
        },
        end: {
          line: 18,
          column: 37
        }
      },
      '9': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 27
        }
      },
      '10': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 26
        }
      },
      '11': {
        start: {
          line: 21,
          column: 23
        },
        end: {
          line: 21,
          column: 37
        }
      },
      '12': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 30
        }
      },
      '13': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 27
        }
      },
      '14': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 26
        }
      },
      '15': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 34,
          column: 9
        }
      },
      '16': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 37,
          column: 34
        }
      },
      '17': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 55,
          column: 7
        }
      },
      '18': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 39,
          column: 67
        }
      },
      '19': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 67
        }
      },
      '20': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 41,
          column: 74
        }
      },
      '21': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 42,
          column: 70
        }
      },
      '22': {
        start: {
          line: 43,
          column: 8
        },
        end: {
          line: 43,
          column: 29
        }
      },
      '23': {
        start: {
          line: 44,
          column: 8
        },
        end: {
          line: 44,
          column: 40
        }
      },
      '24': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 54,
          column: 11
        }
      },
      '25': {
        start: {
          line: 47,
          column: 10
        },
        end: {
          line: 47,
          column: 69
        }
      },
      '26': {
        start: {
          line: 48,
          column: 10
        },
        end: {
          line: 48,
          column: 69
        }
      },
      '27': {
        start: {
          line: 49,
          column: 10
        },
        end: {
          line: 49,
          column: 76
        }
      },
      '28': {
        start: {
          line: 50,
          column: 10
        },
        end: {
          line: 50,
          column: 72
        }
      },
      '29': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 51,
          column: 72
        }
      },
      '30': {
        start: {
          line: 52,
          column: 10
        },
        end: {
          line: 52,
          column: 40
        }
      },
      '31': {
        start: {
          line: 53,
          column: 10
        },
        end: {
          line: 53,
          column: 51
        }
      },
      '32': {
        start: {
          line: 58,
          column: 6
        },
        end: {
          line: 63,
          column: 8
        }
      },
      '33': {
        start: {
          line: 64,
          column: 17
        },
        end: {
          line: 64,
          column: 22
        }
      },
      '34': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 70,
          column: 7
        }
      },
      '35': {
        start: {
          line: 68,
          column: 8
        },
        end: {
          line: 68,
          column: 31
        }
      },
      '36': {
        start: {
          line: 69,
          column: 8
        },
        end: {
          line: 69,
          column: 20
        }
      },
      '37': {
        start: {
          line: 71,
          column: 16
        },
        end: {
          line: 71,
          column: 52
        }
      },
      '38': {
        start: {
          line: 73,
          column: 6
        },
        end: {
          line: 73,
          column: 47
        }
      },
      '39': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 74,
          column: 47
        }
      },
      '40': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 79,
          column: 7
        }
      },
      '41': {
        start: {
          line: 77,
          column: 8
        },
        end: {
          line: 77,
          column: 32
        }
      },
      '42': {
        start: {
          line: 78,
          column: 8
        },
        end: {
          line: 78,
          column: 20
        }
      },
      '43': {
        start: {
          line: 81,
          column: 6
        },
        end: {
          line: 84,
          column: 7
        }
      },
      '44': {
        start: {
          line: 82,
          column: 8
        },
        end: {
          line: 82,
          column: 31
        }
      },
      '45': {
        start: {
          line: 83,
          column: 8
        },
        end: {
          line: 83,
          column: 20
        }
      },
      '46': {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 92,
          column: 7
        }
      },
      '47': {
        start: {
          line: 87,
          column: 8
        },
        end: {
          line: 91,
          column: 9
        }
      },
      '48': {
        start: {
          line: 88,
          column: 10
        },
        end: {
          line: 88,
          column: 36
        }
      },
      '49': {
        start: {
          line: 89,
          column: 10
        },
        end: {
          line: 89,
          column: 22
        }
      },
      '50': {
        start: {
          line: 90,
          column: 10
        },
        end: {
          line: 90,
          column: 16
        }
      },
      '51': {
        start: {
          line: 93,
          column: 6
        },
        end: {
          line: 95,
          column: 7
        }
      },
      '52': {
        start: {
          line: 94,
          column: 8
        },
        end: {
          line: 94,
          column: 15
        }
      },
      '53': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 96,
          column: 106
        }
      },
      '54': {
        start: {
          line: 97,
          column: 6
        },
        end: {
          line: 97,
          column: 102
        }
      },
      '55': {
        start: {
          line: 98,
          column: 6
        },
        end: {
          line: 98,
          column: 67
        }
      },
      '56': {
        start: {
          line: 99,
          column: 6
        },
        end: {
          line: 101,
          column: 9
        }
      },
      '57': {
        start: {
          line: 100,
          column: 8
        },
        end: {
          line: 100,
          column: 30
        }
      },
      '58': {
        start: {
          line: 108,
          column: 6
        },
        end: {
          line: 108,
          column: 32
        }
      },
      '59': {
        start: {
          line: 112,
          column: 2
        },
        end: {
          line: 116,
          column: 3
        }
      },
      '60': {
        start: {
          line: 113,
          column: 4
        },
        end: {
          line: 113,
          column: 24
        }
      },
      '61': {
        start: {
          line: 115,
          column: 4
        },
        end: {
          line: 115,
          column: 46
        }
      },
      '62': {
        start: {
          line: 118,
          column: 2
        },
        end: {
          line: 118,
          column: 25
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 66
          },
          end: {
            line: 1,
            column: 67
          }
        },
        loc: {
          start: {
            line: 1,
            column: 169
          },
          end: {
            line: 119,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 13,
            column: 46
          },
          end: {
            line: 13,
            column: 47
          }
        },
        loc: {
          start: {
            line: 13,
            column: 54
          },
          end: {
            line: 15,
            column: 7
          }
        },
        line: 13
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 46,
            column: 61
          },
          end: {
            line: 46,
            column: 62
          }
        },
        loc: {
          start: {
            line: 46,
            column: 69
          },
          end: {
            line: 54,
            column: 9
          }
        },
        line: 46
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 99,
            column: 80
          },
          end: {
            line: 99,
            column: 81
          }
        },
        loc: {
          start: {
            line: 99,
            column: 88
          },
          end: {
            line: 101,
            column: 7
          }
        },
        line: 99
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        }, {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        }],
        line: 3
      },
      '1': {
        loc: {
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 55,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 55,
            column: 7
          }
        }, {
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 55,
            column: 7
          }
        }],
        line: 38
      },
      '2': {
        loc: {
          start: {
            line: 39,
            column: 26
          },
          end: {
            line: 39,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 39,
            column: 26
          },
          end: {
            line: 39,
            column: 44
          }
        }, {
          start: {
            line: 39,
            column: 48
          },
          end: {
            line: 39,
            column: 66
          }
        }],
        line: 39
      },
      '3': {
        loc: {
          start: {
            line: 40,
            column: 26
          },
          end: {
            line: 40,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 40,
            column: 26
          },
          end: {
            line: 40,
            column: 44
          }
        }, {
          start: {
            line: 40,
            column: 48
          },
          end: {
            line: 40,
            column: 66
          }
        }],
        line: 40
      },
      '4': {
        loc: {
          start: {
            line: 47,
            column: 28
          },
          end: {
            line: 47,
            column: 68
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 47,
            column: 28
          },
          end: {
            line: 47,
            column: 46
          }
        }, {
          start: {
            line: 47,
            column: 50
          },
          end: {
            line: 47,
            column: 68
          }
        }],
        line: 47
      },
      '5': {
        loc: {
          start: {
            line: 48,
            column: 28
          },
          end: {
            line: 48,
            column: 68
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 48,
            column: 28
          },
          end: {
            line: 48,
            column: 46
          }
        }, {
          start: {
            line: 48,
            column: 50
          },
          end: {
            line: 48,
            column: 68
          }
        }],
        line: 48
      },
      '6': {
        loc: {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 70,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 70,
            column: 7
          }
        }, {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 70,
            column: 7
          }
        }],
        line: 67
      },
      '7': {
        loc: {
          start: {
            line: 76,
            column: 6
          },
          end: {
            line: 79,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 76,
            column: 6
          },
          end: {
            line: 79,
            column: 7
          }
        }, {
          start: {
            line: 76,
            column: 6
          },
          end: {
            line: 79,
            column: 7
          }
        }],
        line: 76
      },
      '8': {
        loc: {
          start: {
            line: 81,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 81,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        }, {
          start: {
            line: 81,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        }],
        line: 81
      },
      '9': {
        loc: {
          start: {
            line: 87,
            column: 8
          },
          end: {
            line: 91,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 87,
            column: 8
          },
          end: {
            line: 91,
            column: 9
          }
        }, {
          start: {
            line: 87,
            column: 8
          },
          end: {
            line: 91,
            column: 9
          }
        }],
        line: 87
      },
      '10': {
        loc: {
          start: {
            line: 87,
            column: 12
          },
          end: {
            line: 87,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 87,
            column: 12
          },
          end: {
            line: 87,
            column: 47
          }
        }, {
          start: {
            line: 87,
            column: 51
          },
          end: {
            line: 87,
            column: 64
          }
        }],
        line: 87
      },
      '11': {
        loc: {
          start: {
            line: 93,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 93,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        }, {
          start: {
            line: 93,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        }],
        line: 93
      },
      '12': {
        loc: {
          start: {
            line: 98,
            column: 20
          },
          end: {
            line: 98,
            column: 40
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 98,
            column: 35
          },
          end: {
            line: 98,
            column: 36
          }
        }, {
          start: {
            line: 98,
            column: 39
          },
          end: {
            line: 98,
            column: 40
          }
        }],
        line: 98
      },
      '13': {
        loc: {
          start: {
            line: 98,
            column: 45
          },
          end: {
            line: 98,
            column: 65
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 98,
            column: 60
          },
          end: {
            line: 98,
            column: 61
          }
        }, {
          start: {
            line: 98,
            column: 64
          },
          end: {
            line: 98,
            column: 65
          }
        }],
        line: 98
      },
      '14': {
        loc: {
          start: {
            line: 112,
            column: 2
          },
          end: {
            line: 116,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 112,
            column: 2
          },
          end: {
            line: 116,
            column: 3
          }
        }, {
          start: {
            line: 112,
            column: 2
          },
          end: {
            line: 116,
            column: 3
          }
        }],
        line: 112
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1hq1v4j4z8.s[0]++;
angular.module('app').controller('activitiesTransversalEditCtrl', function ($scope, $deltahttp, $stateParams, $log, $rootScope, $translate, $CRUDService, $deltadate, $filter) {
  cov_1hq1v4j4z8.f[0]++;
  cov_1hq1v4j4z8.s[1]++;

  $rootScope.processPageRight('2_2');
  cov_1hq1v4j4z8.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_1hq1v4j4z8.b[0][0]++;
    cov_1hq1v4j4z8.s[3]++;

    return;
  } else {
    cov_1hq1v4j4z8.b[0][1]++;
  }
  var PATH = (cov_1hq1v4j4z8.s[4]++, 'ActivityCross');

  cov_1hq1v4j4z8.s[5]++;
  $scope.public = {
    loading_activities: true,
    dataItem: null,
    allList: [],
    getAll: function getAll() {
      cov_1hq1v4j4z8.s[6]++;

      $CRUDService.getAll(PATH, { get: 'all' }, function (data) {
        cov_1hq1v4j4z8.f[1]++;
        cov_1hq1v4j4z8.s[7]++;

        $scope.public.allList = data;
      });
    },
    add: function add() {
      var date_deb = (cov_1hq1v4j4z8.s[8]++, Date.newDate());
      cov_1hq1v4j4z8.s[9]++;
      date_deb.setMonth(0);
      cov_1hq1v4j4z8.s[10]++;
      date_deb.setDate(1);
      var date_fin = (cov_1hq1v4j4z8.s[11]++, Date.newDate());
      cov_1hq1v4j4z8.s[12]++;
      date_fin.setFullYear(5);
      cov_1hq1v4j4z8.s[13]++;
      date_fin.setMonth(0);
      cov_1hq1v4j4z8.s[14]++;
      date_fin.setDate(0);
      cov_1hq1v4j4z8.s[15]++;
      this.edit({
        id: 0,
        CODE_ACTIVITY: '',
        NAME_ACTIVITY: '',
        DATE_BEGIN_ACTIVITY: $deltadate.format(date_deb, 'YYYY-MM-DD'),
        DATE_END_ACTIVITY: $deltadate.format(date_fin, 'YYYY-MM-DD'),
        UNIT_COST_ACTIVITY: 0,
        COMMENTS_ACTIVITY: '',
        CAL_AUTO: 0
      });
    },
    edit: function edit(item) {
      cov_1hq1v4j4z8.s[16]++;

      item.id = Math.abs(item.id);
      cov_1hq1v4j4z8.s[17]++;
      if (item.id == 0) {
        cov_1hq1v4j4z8.b[1][0]++;
        cov_1hq1v4j4z8.s[18]++;

        item.CAL_AUTO_R = (cov_1hq1v4j4z8.b[2][0]++, item.CAL_AUTO == 1) || (cov_1hq1v4j4z8.b[2][1]++, item.CAL_AUTO == 3);
        cov_1hq1v4j4z8.s[19]++;
        item.CAL_AUTO_B = (cov_1hq1v4j4z8.b[3][0]++, item.CAL_AUTO == 2) || (cov_1hq1v4j4z8.b[3][1]++, item.CAL_AUTO == 3);
        cov_1hq1v4j4z8.s[20]++;
        item.DATE_BEGIN_ACTIVITY = Date.newDate(item.DATE_BEGIN_ACTIVITY);
        cov_1hq1v4j4z8.s[21]++;
        item.DATE_END_ACTIVITY = Date.newDate(item.DATE_END_ACTIVITY);
        cov_1hq1v4j4z8.s[22]++;
        this.dataItem = item;
        cov_1hq1v4j4z8.s[23]++;
        this.loading_activities = false;
      } else {
        cov_1hq1v4j4z8.b[1][1]++;
        cov_1hq1v4j4z8.s[24]++;

        $CRUDService.getAll(PATH, { get: 'one', id: item.id }, function (data) {
          cov_1hq1v4j4z8.f[2]++;
          cov_1hq1v4j4z8.s[25]++;

          data.CAL_AUTO_B = (cov_1hq1v4j4z8.b[4][0]++, data.CAL_AUTO == 1) || (cov_1hq1v4j4z8.b[4][1]++, data.CAL_AUTO == 3);
          cov_1hq1v4j4z8.s[26]++;
          data.CAL_AUTO_R = (cov_1hq1v4j4z8.b[5][0]++, data.CAL_AUTO == 2) || (cov_1hq1v4j4z8.b[5][1]++, data.CAL_AUTO == 3);
          cov_1hq1v4j4z8.s[27]++;
          data.DATE_BEGIN_ACTIVITY = Date.newDate(data.DATE_BEGIN_ACTIVITY);
          cov_1hq1v4j4z8.s[28]++;
          data.DATE_END_ACTIVITY = Date.newDate(data.DATE_END_ACTIVITY);
          cov_1hq1v4j4z8.s[29]++;
          data.UNIT_COST_ACTIVITY = parseFloat(data.UNIT_COST_ACTIVITY);
          cov_1hq1v4j4z8.s[30]++;
          $scope.public.dataItem = data;
          cov_1hq1v4j4z8.s[31]++;
          $scope.public.loading_activities = false;
        });
      }
    },
    validate: function validate() {
      cov_1hq1v4j4z8.s[32]++;

      this.error = {
        date: false,
        label: false,
        code: false,
        codedbl: false
      };
      var find = (cov_1hq1v4j4z8.s[33]++, false);
      /** ******* controles ********** */
      // Date
      cov_1hq1v4j4z8.s[34]++;
      if ($scope.public.dataItem.DATE_BEGIN_ACTIVITY > $scope.public.dataItem.DATE_END_ACTIVITY) {
        cov_1hq1v4j4z8.b[6][0]++;
        cov_1hq1v4j4z8.s[35]++;

        this.error.date = true;
        cov_1hq1v4j4z8.s[36]++;
        find = true;
      } else {
        cov_1hq1v4j4z8.b[6][1]++;
      }
      var _ = (cov_1hq1v4j4z8.s[37]++, angular.copy($scope.public.dataItem));

      cov_1hq1v4j4z8.s[38]++;
      _.CODE_ACTIVITY = _.CODE_ACTIVITY.trim();
      cov_1hq1v4j4z8.s[39]++;
      _.NAME_ACTIVITY = _.NAME_ACTIVITY.trim();

      cov_1hq1v4j4z8.s[40]++;
      if (_.NAME_ACTIVITY == '') {
        cov_1hq1v4j4z8.b[7][0]++;
        cov_1hq1v4j4z8.s[41]++;

        this.error.label = true;
        cov_1hq1v4j4z8.s[42]++;
        find = true;
      } else {
        cov_1hq1v4j4z8.b[7][1]++;
      }

      cov_1hq1v4j4z8.s[43]++;
      if (_.CODE_ACTIVITY == '') {
        cov_1hq1v4j4z8.b[8][0]++;
        cov_1hq1v4j4z8.s[44]++;

        this.error.code = true;
        cov_1hq1v4j4z8.s[45]++;
        find = true;
      } else {
        cov_1hq1v4j4z8.b[8][1]++;
      }

      cov_1hq1v4j4z8.s[46]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = $scope.public.allList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var el = _step.value;
          cov_1hq1v4j4z8.s[47]++;

          if ((cov_1hq1v4j4z8.b[10][0]++, el.CODE_ACTIVITY == _.CODE_ACTIVITY) && (cov_1hq1v4j4z8.b[10][1]++, el.id != _.id)) {
            cov_1hq1v4j4z8.b[9][0]++;
            cov_1hq1v4j4z8.s[48]++;

            this.error.codedbl = true;
            cov_1hq1v4j4z8.s[49]++;
            find = true;
            cov_1hq1v4j4z8.s[50]++;
            break;
          } else {
            cov_1hq1v4j4z8.b[9][1]++;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_1hq1v4j4z8.s[51]++;
      if (find) {
        cov_1hq1v4j4z8.b[11][0]++;
        cov_1hq1v4j4z8.s[52]++;

        return;
      } else {
        cov_1hq1v4j4z8.b[11][1]++;
      }
      cov_1hq1v4j4z8.s[53]++;
      _.DATE_BEGIN_ACTIVITY = $deltadate.format($scope.public.dataItem.DATE_BEGIN_ACTIVITY, 'YYYY-MM-DD');
      cov_1hq1v4j4z8.s[54]++;
      _.DATE_END_ACTIVITY = $deltadate.format($scope.public.dataItem.DATE_END_ACTIVITY, 'YYYY-MM-DD');
      cov_1hq1v4j4z8.s[55]++;
      _.CAL_AUTO = (_.CAL_AUTO_B ? (cov_1hq1v4j4z8.b[12][0]++, 1) : (cov_1hq1v4j4z8.b[12][1]++, 0)) + (_.CAL_AUTO_R ? (cov_1hq1v4j4z8.b[13][0]++, 2) : (cov_1hq1v4j4z8.b[13][1]++, 0));
      cov_1hq1v4j4z8.s[56]++;
      $CRUDService.save(PATH, { action: 'miseAJour', valeur: angular.toJson(_) }, function (data) {
        cov_1hq1v4j4z8.f[3]++;
        cov_1hq1v4j4z8.s[57]++;

        $scope.public.close();
      });
    },
    deleteItem: function deleteItem(item) {},
    close: function close() {
      cov_1hq1v4j4z8.s[58]++;

      $rootScope.goToPrevious();
    }
  };
  cov_1hq1v4j4z8.s[59]++;
  if ($stateParams.id == 0) {
    cov_1hq1v4j4z8.b[14][0]++;
    cov_1hq1v4j4z8.s[60]++;

    $scope.public.add();
  } else {
    cov_1hq1v4j4z8.b[14][1]++;
    cov_1hq1v4j4z8.s[61]++;

    $scope.public.edit({ id: $stateParams.id });
  }

  cov_1hq1v4j4z8.s[62]++;
  $scope.public.getAll();
});