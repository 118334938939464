'use strict';

var cov_r6vfby86e = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/shared/optional_indicator_field/optionalIndicatorField.js',
      hash = '3aa03985c0ff8676289f9a3891b11002db3c0100',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/shared/optional_indicator_field/optionalIndicatorField.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 278,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 17
        },
        end: {
          line: 3,
          column: 32
        }
      },
      '2': {
        start: {
          line: 4,
          column: 18
        },
        end: {
          line: 4,
          column: 27
        }
      },
      '3': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 76,
          column: 6
        }
      },
      '4': {
        start: {
          line: 9,
          column: 8
        },
        end: {
          line: 9,
          column: 32
        }
      },
      '5': {
        start: {
          line: 10,
          column: 8
        },
        end: {
          line: 10,
          column: 38
        }
      },
      '6': {
        start: {
          line: 13,
          column: 8
        },
        end: {
          line: 20,
          column: 11
        }
      },
      '7': {
        start: {
          line: 14,
          column: 10
        },
        end: {
          line: 19,
          column: 11
        }
      },
      '8': {
        start: {
          line: 15,
          column: 12
        },
        end: {
          line: 18,
          column: 16
        }
      },
      '9': {
        start: {
          line: 16,
          column: 14
        },
        end: {
          line: 16,
          column: 65
        }
      },
      '10': {
        start: {
          line: 17,
          column: 14
        },
        end: {
          line: 17,
          column: 27
        }
      },
      '11': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 39,
          column: 11
        }
      },
      '12': {
        start: {
          line: 24,
          column: 10
        },
        end: {
          line: 34,
          column: 11
        }
      },
      '13': {
        start: {
          line: 25,
          column: 12
        },
        end: {
          line: 28,
          column: 15
        }
      },
      '14': {
        start: {
          line: 26,
          column: 14
        },
        end: {
          line: 26,
          column: 44
        }
      },
      '15': {
        start: {
          line: 27,
          column: 14
        },
        end: {
          line: 27,
          column: 27
        }
      },
      '16': {
        start: {
          line: 30,
          column: 12
        },
        end: {
          line: 33,
          column: 15
        }
      },
      '17': {
        start: {
          line: 31,
          column: 14
        },
        end: {
          line: 31,
          column: 46
        }
      },
      '18': {
        start: {
          line: 32,
          column: 14
        },
        end: {
          line: 32,
          column: 27
        }
      },
      '19': {
        start: {
          line: 36,
          column: 10
        },
        end: {
          line: 38,
          column: 11
        }
      },
      '20': {
        start: {
          line: 37,
          column: 12
        },
        end: {
          line: 37,
          column: 70
        }
      },
      '21': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 46,
          column: 9
        }
      },
      '22': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 43,
          column: 34
        }
      },
      '23': {
        start: {
          line: 44,
          column: 10
        },
        end: {
          line: 44,
          column: 40
        }
      },
      '24': {
        start: {
          line: 45,
          column: 10
        },
        end: {
          line: 45,
          column: 17
        }
      },
      '25': {
        start: {
          line: 48,
          column: 8
        },
        end: {
          line: 51,
          column: 9
        }
      },
      '26': {
        start: {
          line: 49,
          column: 10
        },
        end: {
          line: 49,
          column: 40
        }
      },
      '27': {
        start: {
          line: 50,
          column: 10
        },
        end: {
          line: 50,
          column: 17
        }
      },
      '28': {
        start: {
          line: 52,
          column: 8
        },
        end: {
          line: 52,
          column: 30
        }
      },
      '29': {
        start: {
          line: 55,
          column: 8
        },
        end: {
          line: 57,
          column: 11
        }
      },
      '30': {
        start: {
          line: 56,
          column: 10
        },
        end: {
          line: 56,
          column: 33
        }
      },
      '31': {
        start: {
          line: 60,
          column: 8
        },
        end: {
          line: 62,
          column: 11
        }
      },
      '32': {
        start: {
          line: 61,
          column: 10
        },
        end: {
          line: 61,
          column: 37
        }
      },
      '33': {
        start: {
          line: 65,
          column: 8
        },
        end: {
          line: 67,
          column: 11
        }
      },
      '34': {
        start: {
          line: 66,
          column: 10
        },
        end: {
          line: 66,
          column: 19
        }
      },
      '35': {
        start: {
          line: 70,
          column: 8
        },
        end: {
          line: 72,
          column: 9
        }
      },
      '36': {
        start: {
          line: 71,
          column: 10
        },
        end: {
          line: 71,
          column: 138
        }
      },
      '37': {
        start: {
          line: 73,
          column: 8
        },
        end: {
          line: 73,
          column: 69
        }
      },
      '38': {
        start: {
          line: 79,
          column: 4
        },
        end: {
          line: 168,
          column: 6
        }
      },
      '39': {
        start: {
          line: 94,
          column: 19
        },
        end: {
          line: 94,
          column: 25
        }
      },
      '40': {
        start: {
          line: 95,
          column: 8
        },
        end: {
          line: 165,
          column: 10
        }
      },
      '41': {
        start: {
          line: 97,
          column: 12
        },
        end: {
          line: 97,
          column: 125
        }
      },
      '42': {
        start: {
          line: 97,
          column: 104
        },
        end: {
          line: 97,
          column: 112
        }
      },
      '43': {
        start: {
          line: 98,
          column: 12
        },
        end: {
          line: 100,
          column: 13
        }
      },
      '44': {
        start: {
          line: 99,
          column: 14
        },
        end: {
          line: 99,
          column: 21
        }
      },
      '45': {
        start: {
          line: 101,
          column: 12
        },
        end: {
          line: 101,
          column: 47
        }
      },
      '46': {
        start: {
          line: 104,
          column: 12
        },
        end: {
          line: 104,
          column: 55
        }
      },
      '47': {
        start: {
          line: 105,
          column: 12
        },
        end: {
          line: 107,
          column: 13
        }
      },
      '48': {
        start: {
          line: 106,
          column: 14
        },
        end: {
          line: 106,
          column: 21
        }
      },
      '49': {
        start: {
          line: 108,
          column: 12
        },
        end: {
          line: 110,
          column: 20
        }
      },
      '50': {
        start: {
          line: 109,
          column: 14
        },
        end: {
          line: 109,
          column: 55
        }
      },
      '51': {
        start: {
          line: 113,
          column: 12
        },
        end: {
          line: 127,
          column: 15
        }
      },
      '52': {
        start: {
          line: 116,
          column: 16
        },
        end: {
          line: 120,
          column: 18
        }
      },
      '53': {
        start: {
          line: 117,
          column: 18
        },
        end: {
          line: 117,
          column: 64
        }
      },
      '54': {
        start: {
          line: 118,
          column: 18
        },
        end: {
          line: 118,
          column: 31
        }
      },
      '55': {
        start: {
          line: 119,
          column: 18
        },
        end: {
          line: 119,
          column: 54
        }
      },
      '56': {
        start: {
          line: 130,
          column: 12
        },
        end: {
          line: 130,
          column: 31
        }
      },
      '57': {
        start: {
          line: 131,
          column: 12
        },
        end: {
          line: 136,
          column: 13
        }
      },
      '58': {
        start: {
          line: 132,
          column: 14
        },
        end: {
          line: 134,
          column: 17
        }
      },
      '59': {
        start: {
          line: 133,
          column: 16
        },
        end: {
          line: 133,
          column: 50
        }
      },
      '60': {
        start: {
          line: 135,
          column: 14
        },
        end: {
          line: 135,
          column: 21
        }
      },
      '61': {
        start: {
          line: 137,
          column: 12
        },
        end: {
          line: 139,
          column: 15
        }
      },
      '62': {
        start: {
          line: 138,
          column: 14
        },
        end: {
          line: 138,
          column: 64
        }
      },
      '63': {
        start: {
          line: 142,
          column: 12
        },
        end: {
          line: 144,
          column: 14
        }
      },
      '64': {
        start: {
          line: 145,
          column: 12
        },
        end: {
          line: 145,
          column: 30
        }
      },
      '65': {
        start: {
          line: 147,
          column: 12
        },
        end: {
          line: 152,
          column: 13
        }
      },
      '66': {
        start: {
          line: 148,
          column: 14
        },
        end: {
          line: 150,
          column: 17
        }
      },
      '67': {
        start: {
          line: 149,
          column: 16
        },
        end: {
          line: 149,
          column: 83
        }
      },
      '68': {
        start: {
          line: 149,
          column: 56
        },
        end: {
          line: 149,
          column: 67
        }
      },
      '69': {
        start: {
          line: 151,
          column: 14
        },
        end: {
          line: 151,
          column: 21
        }
      },
      '70': {
        start: {
          line: 154,
          column: 12
        },
        end: {
          line: 154,
          column: 79
        }
      },
      '71': {
        start: {
          line: 155,
          column: 12
        },
        end: {
          line: 161,
          column: 13
        }
      },
      '72': {
        start: {
          line: 156,
          column: 14
        },
        end: {
          line: 159,
          column: 17
        }
      },
      '73': {
        start: {
          line: 157,
          column: 16
        },
        end: {
          line: 157,
          column: 83
        }
      },
      '74': {
        start: {
          line: 158,
          column: 16
        },
        end: {
          line: 158,
          column: 29
        }
      },
      '75': {
        start: {
          line: 160,
          column: 14
        },
        end: {
          line: 160,
          column: 21
        }
      },
      '76': {
        start: {
          line: 163,
          column: 12
        },
        end: {
          line: 163,
          column: 25
        }
      },
      '77': {
        start: {
          line: 166,
          column: 8
        },
        end: {
          line: 166,
          column: 21
        }
      },
      '78': {
        start: {
          line: 171,
          column: 4
        },
        end: {
          line: 171,
          column: 29
        }
      },
      '79': {
        start: {
          line: 172,
          column: 4
        },
        end: {
          line: 172,
          column: 20
        }
      },
      '80': {
        start: {
          line: 173,
          column: 4
        },
        end: {
          line: 247,
          column: 6
        }
      },
      '81': {
        start: {
          line: 176,
          column: 8
        },
        end: {
          line: 180,
          column: 11
        }
      },
      '82': {
        start: {
          line: 177,
          column: 10
        },
        end: {
          line: 177,
          column: 49
        }
      },
      '83': {
        start: {
          line: 178,
          column: 10
        },
        end: {
          line: 178,
          column: 41
        }
      },
      '84': {
        start: {
          line: 179,
          column: 10
        },
        end: {
          line: 179,
          column: 32
        }
      },
      '85': {
        start: {
          line: 184,
          column: 29
        },
        end: {
          line: 184,
          column: 68
        }
      },
      '86': {
        start: {
          line: 185,
          column: 8
        },
        end: {
          line: 185,
          column: 42
        }
      },
      '87': {
        start: {
          line: 186,
          column: 21
        },
        end: {
          line: 188,
          column: 10
        }
      },
      '88': {
        start: {
          line: 187,
          column: 10
        },
        end: {
          line: 187,
          column: 82
        }
      },
      '89': {
        start: {
          line: 189,
          column: 8
        },
        end: {
          line: 192,
          column: 9
        }
      },
      '90': {
        start: {
          line: 190,
          column: 10
        },
        end: {
          line: 190,
          column: 43
        }
      },
      '91': {
        start: {
          line: 191,
          column: 10
        },
        end: {
          line: 191,
          column: 17
        }
      },
      '92': {
        start: {
          line: 193,
          column: 8
        },
        end: {
          line: 193,
          column: 52
        }
      },
      '93': {
        start: {
          line: 194,
          column: 8
        },
        end: {
          line: 194,
          column: 54
        }
      },
      '94': {
        start: {
          line: 195,
          column: 8
        },
        end: {
          line: 195,
          column: 38
        }
      },
      '95': {
        start: {
          line: 196,
          column: 8
        },
        end: {
          line: 198,
          column: 11
        }
      },
      '96': {
        start: {
          line: 197,
          column: 10
        },
        end: {
          line: 197,
          column: 34
        }
      },
      '97': {
        start: {
          line: 202,
          column: 8
        },
        end: {
          line: 202,
          column: 24
        }
      },
      '98': {
        start: {
          line: 205,
          column: 8
        },
        end: {
          line: 210,
          column: 11
        }
      },
      '99': {
        start: {
          line: 213,
          column: 8
        },
        end: {
          line: 213,
          column: 42
        }
      },
      '100': {
        start: {
          line: 214,
          column: 8
        },
        end: {
          line: 214,
          column: 47
        }
      },
      '101': {
        start: {
          line: 215,
          column: 8
        },
        end: {
          line: 215,
          column: 24
        }
      },
      '102': {
        start: {
          line: 218,
          column: 8
        },
        end: {
          line: 245,
          column: 11
        }
      },
      '103': {
        start: {
          line: 229,
          column: 10
        },
        end: {
          line: 231,
          column: 11
        }
      },
      '104': {
        start: {
          line: 230,
          column: 12
        },
        end: {
          line: 230,
          column: 19
        }
      },
      '105': {
        start: {
          line: 232,
          column: 10
        },
        end: {
          line: 232,
          column: 40
        }
      },
      '106': {
        start: {
          line: 233,
          column: 10
        },
        end: {
          line: 244,
          column: 13
        }
      },
      '107': {
        start: {
          line: 234,
          column: 12
        },
        end: {
          line: 243,
          column: 13
        }
      },
      '108': {
        start: {
          line: 235,
          column: 14
        },
        end: {
          line: 239,
          column: 17
        }
      },
      '109': {
        start: {
          line: 240,
          column: 14
        },
        end: {
          line: 240,
          column: 45
        }
      },
      '110': {
        start: {
          line: 242,
          column: 14
        },
        end: {
          line: 242,
          column: 38
        }
      },
      '111': {
        start: {
          line: 248,
          column: 4
        },
        end: {
          line: 277,
          column: 7
        }
      },
      '112': {
        start: {
          line: 249,
          column: 6
        },
        end: {
          line: 275,
          column: 8
        }
      },
      '113': {
        start: {
          line: 262,
          column: 10
        },
        end: {
          line: 265,
          column: 11
        }
      },
      '114': {
        start: {
          line: 263,
          column: 12
        },
        end: {
          line: 263,
          column: 33
        }
      },
      '115': {
        start: {
          line: 264,
          column: 12
        },
        end: {
          line: 264,
          column: 36
        }
      },
      '116': {
        start: {
          line: 266,
          column: 10
        },
        end: {
          line: 269,
          column: 11
        }
      },
      '117': {
        start: {
          line: 267,
          column: 12
        },
        end: {
          line: 267,
          column: 33
        }
      },
      '118': {
        start: {
          line: 268,
          column: 12
        },
        end: {
          line: 268,
          column: 51
        }
      },
      '119': {
        start: {
          line: 270,
          column: 10
        },
        end: {
          line: 273,
          column: 11
        }
      },
      '120': {
        start: {
          line: 271,
          column: 12
        },
        end: {
          line: 271,
          column: 33
        }
      },
      '121': {
        start: {
          line: 272,
          column: 12
        },
        end: {
          line: 272,
          column: 48
        }
      },
      '122': {
        start: {
          line: 276,
          column: 6
        },
        end: {
          line: 276,
          column: 37
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 95
          },
          end: {
            line: 2,
            column: 96
          }
        },
        loc: {
          start: {
            line: 2,
            column: 144
          },
          end: {
            line: 77,
            column: 3
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 13,
            column: 80
          },
          end: {
            line: 13,
            column: 81
          }
        },
        loc: {
          start: {
            line: 13,
            column: 88
          },
          end: {
            line: 20,
            column: 9
          }
        },
        line: 13
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 24
          },
          end: {
            line: 15,
            column: 25
          }
        },
        loc: {
          start: {
            line: 15,
            column: 33
          },
          end: {
            line: 18,
            column: 13
          }
        },
        line: 15
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 23,
            column: 68
          },
          end: {
            line: 23,
            column: 69
          }
        },
        loc: {
          start: {
            line: 23,
            column: 76
          },
          end: {
            line: 39,
            column: 9
          }
        },
        line: 23
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 25,
            column: 46
          },
          end: {
            line: 25,
            column: 47
          }
        },
        loc: {
          start: {
            line: 25,
            column: 55
          },
          end: {
            line: 28,
            column: 13
          }
        },
        line: 25
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 30,
            column: 40
          },
          end: {
            line: 30,
            column: 41
          }
        },
        loc: {
          start: {
            line: 30,
            column: 49
          },
          end: {
            line: 33,
            column: 13
          }
        },
        line: 30
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 55,
            column: 113
          },
          end: {
            line: 55,
            column: 114
          }
        },
        loc: {
          start: {
            line: 55,
            column: 121
          },
          end: {
            line: 57,
            column: 9
          }
        },
        line: 55
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 60,
            column: 98
          },
          end: {
            line: 60,
            column: 99
          }
        },
        loc: {
          start: {
            line: 60,
            column: 106
          },
          end: {
            line: 62,
            column: 9
          }
        },
        line: 60
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 65,
            column: 84
          },
          end: {
            line: 65,
            column: 85
          }
        },
        loc: {
          start: {
            line: 65,
            column: 92
          },
          end: {
            line: 67,
            column: 9
          }
        },
        line: 65
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 78,
            column: 42
          },
          end: {
            line: 78,
            column: 43
          }
        },
        loc: {
          start: {
            line: 78,
            column: 48
          },
          end: {
            line: 169,
            column: 3
          }
        },
        line: 78
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 97,
            column: 95
          },
          end: {
            line: 97,
            column: 96
          }
        },
        loc: {
          start: {
            line: 97,
            column: 104
          },
          end: {
            line: 97,
            column: 112
          }
        },
        line: 97
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 108,
            column: 21
          },
          end: {
            line: 108,
            column: 22
          }
        },
        loc: {
          start: {
            line: 108,
            column: 27
          },
          end: {
            line: 110,
            column: 13
          }
        },
        line: 108
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 115,
            column: 27
          },
          end: {
            line: 115,
            column: 28
          }
        },
        loc: {
          start: {
            line: 115,
            column: 48
          },
          end: {
            line: 121,
            column: 15
          }
        },
        line: 115
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 116,
            column: 36
          },
          end: {
            line: 116,
            column: 37
          }
        },
        loc: {
          start: {
            line: 116,
            column: 42
          },
          end: {
            line: 120,
            column: 17
          }
        },
        line: 116
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 132,
            column: 52
          },
          end: {
            line: 132,
            column: 53
          }
        },
        loc: {
          start: {
            line: 132,
            column: 61
          },
          end: {
            line: 134,
            column: 15
          }
        },
        line: 132
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 137,
            column: 52
          },
          end: {
            line: 137,
            column: 53
          }
        },
        loc: {
          start: {
            line: 137,
            column: 61
          },
          end: {
            line: 139,
            column: 13
          }
        },
        line: 137
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 148,
            column: 71
          },
          end: {
            line: 148,
            column: 72
          }
        },
        loc: {
          start: {
            line: 148,
            column: 79
          },
          end: {
            line: 150,
            column: 15
          }
        },
        line: 148
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 149,
            column: 46
          },
          end: {
            line: 149,
            column: 47
          }
        },
        loc: {
          start: {
            line: 149,
            column: 56
          },
          end: {
            line: 149,
            column: 67
          }
        },
        line: 149
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 156,
            column: 59
          },
          end: {
            line: 156,
            column: 60
          }
        },
        loc: {
          start: {
            line: 156,
            column: 67
          },
          end: {
            line: 159,
            column: 15
          }
        },
        line: 156
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 170,
            column: 44
          },
          end: {
            line: 170,
            column: 45
          }
        },
        loc: {
          start: {
            line: 170,
            column: 119
          },
          end: {
            line: 278,
            column: 3
          }
        },
        line: 170
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 176,
            column: 57
          },
          end: {
            line: 176,
            column: 58
          }
        },
        loc: {
          start: {
            line: 176,
            column: 65
          },
          end: {
            line: 180,
            column: 9
          }
        },
        line: 176
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 186,
            column: 58
          },
          end: {
            line: 186,
            column: 59
          }
        },
        loc: {
          start: {
            line: 186,
            column: 67
          },
          end: {
            line: 188,
            column: 9
          }
        },
        line: 186
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 196,
            column: 67
          },
          end: {
            line: 196,
            column: 68
          }
        },
        loc: {
          start: {
            line: 196,
            column: 73
          },
          end: {
            line: 198,
            column: 9
          }
        },
        line: 196
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 228,
            column: 11
          },
          end: {
            line: 228,
            column: 12
          }
        },
        loc: {
          start: {
            line: 228,
            column: 24
          },
          end: {
            line: 245,
            column: 9
          }
        },
        line: 228
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 233,
            column: 63
          },
          end: {
            line: 233,
            column: 64
          }
        },
        loc: {
          start: {
            line: 233,
            column: 71
          },
          end: {
            line: 244,
            column: 11
          }
        },
        line: 233
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 248,
            column: 53
          },
          end: {
            line: 248,
            column: 54
          }
        },
        loc: {
          start: {
            line: 248,
            column: 61
          },
          end: {
            line: 277,
            column: 5
          }
        },
        line: 248
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 13,
            column: 28
          },
          end: {
            line: 13,
            column: 52
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 13,
            column: 40
          },
          end: {
            line: 13,
            column: 44
          }
        }, {
          start: {
            line: 13,
            column: 47
          },
          end: {
            line: 13,
            column: 52
          }
        }],
        line: 13
      },
      '1': {
        loc: {
          start: {
            line: 14,
            column: 10
          },
          end: {
            line: 19,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 14,
            column: 10
          },
          end: {
            line: 19,
            column: 11
          }
        }, {
          start: {
            line: 14,
            column: 10
          },
          end: {
            line: 19,
            column: 11
          }
        }],
        line: 14
      },
      '2': {
        loc: {
          start: {
            line: 16,
            column: 28
          },
          end: {
            line: 16,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 16,
            column: 28
          },
          end: {
            line: 16,
            column: 43
          }
        }, {
          start: {
            line: 16,
            column: 47
          },
          end: {
            line: 16,
            column: 64
          }
        }],
        line: 16
      },
      '3': {
        loc: {
          start: {
            line: 23,
            column: 28
          },
          end: {
            line: 23,
            column: 52
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 23,
            column: 40
          },
          end: {
            line: 23,
            column: 44
          }
        }, {
          start: {
            line: 23,
            column: 47
          },
          end: {
            line: 23,
            column: 52
          }
        }],
        line: 23
      },
      '4': {
        loc: {
          start: {
            line: 24,
            column: 10
          },
          end: {
            line: 34,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 24,
            column: 10
          },
          end: {
            line: 34,
            column: 11
          }
        }, {
          start: {
            line: 24,
            column: 10
          },
          end: {
            line: 34,
            column: 11
          }
        }],
        line: 24
      },
      '5': {
        loc: {
          start: {
            line: 36,
            column: 10
          },
          end: {
            line: 38,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 36,
            column: 10
          },
          end: {
            line: 38,
            column: 11
          }
        }, {
          start: {
            line: 36,
            column: 10
          },
          end: {
            line: 38,
            column: 11
          }
        }],
        line: 36
      },
      '6': {
        loc: {
          start: {
            line: 37,
            column: 15
          },
          end: {
            line: 37,
            column: 68
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 37,
            column: 27
          },
          end: {
            line: 37,
            column: 49
          }
        }, {
          start: {
            line: 37,
            column: 52
          },
          end: {
            line: 37,
            column: 68
          }
        }],
        line: 37
      },
      '7': {
        loc: {
          start: {
            line: 42,
            column: 8
          },
          end: {
            line: 46,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 8
          },
          end: {
            line: 46,
            column: 9
          }
        }, {
          start: {
            line: 42,
            column: 8
          },
          end: {
            line: 46,
            column: 9
          }
        }],
        line: 42
      },
      '8': {
        loc: {
          start: {
            line: 48,
            column: 8
          },
          end: {
            line: 51,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 48,
            column: 8
          },
          end: {
            line: 51,
            column: 9
          }
        }, {
          start: {
            line: 48,
            column: 8
          },
          end: {
            line: 51,
            column: 9
          }
        }],
        line: 48
      },
      '9': {
        loc: {
          start: {
            line: 55,
            column: 26
          },
          end: {
            line: 55,
            column: 50
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 55,
            column: 38
          },
          end: {
            line: 55,
            column: 42
          }
        }, {
          start: {
            line: 55,
            column: 45
          },
          end: {
            line: 55,
            column: 50
          }
        }],
        line: 55
      },
      '10': {
        loc: {
          start: {
            line: 60,
            column: 28
          },
          end: {
            line: 60,
            column: 52
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 60,
            column: 40
          },
          end: {
            line: 60,
            column: 44
          }
        }, {
          start: {
            line: 60,
            column: 47
          },
          end: {
            line: 60,
            column: 52
          }
        }],
        line: 60
      },
      '11': {
        loc: {
          start: {
            line: 65,
            column: 27
          },
          end: {
            line: 65,
            column: 51
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 65,
            column: 39
          },
          end: {
            line: 65,
            column: 43
          }
        }, {
          start: {
            line: 65,
            column: 46
          },
          end: {
            line: 65,
            column: 51
          }
        }],
        line: 65
      },
      '12': {
        loc: {
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 72,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 72,
            column: 9
          }
        }, {
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 72,
            column: 9
          }
        }],
        line: 70
      },
      '13': {
        loc: {
          start: {
            line: 71,
            column: 75
          },
          end: {
            line: 71,
            column: 136
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 71,
            column: 76
          },
          end: {
            line: 71,
            column: 129
          }
        }, {
          start: {
            line: 71,
            column: 134
          },
          end: {
            line: 71,
            column: 136
          }
        }],
        line: 71
      },
      '14': {
        loc: {
          start: {
            line: 71,
            column: 76
          },
          end: {
            line: 71,
            column: 129
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 71,
            column: 88
          },
          end: {
            line: 71,
            column: 110
          }
        }, {
          start: {
            line: 71,
            column: 113
          },
          end: {
            line: 71,
            column: 129
          }
        }],
        line: 71
      },
      '15': {
        loc: {
          start: {
            line: 73,
            column: 15
          },
          end: {
            line: 73,
            column: 68
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 73,
            column: 27
          },
          end: {
            line: 73,
            column: 49
          }
        }, {
          start: {
            line: 73,
            column: 52
          },
          end: {
            line: 73,
            column: 68
          }
        }],
        line: 73
      },
      '16': {
        loc: {
          start: {
            line: 98,
            column: 12
          },
          end: {
            line: 100,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 98,
            column: 12
          },
          end: {
            line: 100,
            column: 13
          }
        }, {
          start: {
            line: 98,
            column: 12
          },
          end: {
            line: 100,
            column: 13
          }
        }],
        line: 98
      },
      '17': {
        loc: {
          start: {
            line: 105,
            column: 12
          },
          end: {
            line: 107,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 105,
            column: 12
          },
          end: {
            line: 107,
            column: 13
          }
        }, {
          start: {
            line: 105,
            column: 12
          },
          end: {
            line: 107,
            column: 13
          }
        }],
        line: 105
      },
      '18': {
        loc: {
          start: {
            line: 131,
            column: 12
          },
          end: {
            line: 136,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 131,
            column: 12
          },
          end: {
            line: 136,
            column: 13
          }
        }, {
          start: {
            line: 131,
            column: 12
          },
          end: {
            line: 136,
            column: 13
          }
        }],
        line: 131
      },
      '19': {
        loc: {
          start: {
            line: 138,
            column: 22
          },
          end: {
            line: 138,
            column: 36
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 138,
            column: 22
          },
          end: {
            line: 138,
            column: 30
          }
        }, {
          start: {
            line: 138,
            column: 34
          },
          end: {
            line: 138,
            column: 36
          }
        }],
        line: 138
      },
      '20': {
        loc: {
          start: {
            line: 147,
            column: 12
          },
          end: {
            line: 152,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 147,
            column: 12
          },
          end: {
            line: 152,
            column: 13
          }
        }, {
          start: {
            line: 147,
            column: 12
          },
          end: {
            line: 152,
            column: 13
          }
        }],
        line: 147
      },
      '21': {
        loc: {
          start: {
            line: 155,
            column: 12
          },
          end: {
            line: 161,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 155,
            column: 12
          },
          end: {
            line: 161,
            column: 13
          }
        }, {
          start: {
            line: 155,
            column: 12
          },
          end: {
            line: 161,
            column: 13
          }
        }],
        line: 155
      },
      '22': {
        loc: {
          start: {
            line: 155,
            column: 16
          },
          end: {
            line: 155,
            column: 112
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 155,
            column: 16
          },
          end: {
            line: 155,
            column: 31
          }
        }, {
          start: {
            line: 155,
            column: 36
          },
          end: {
            line: 155,
            column: 55
          }
        }, {
          start: {
            line: 155,
            column: 59
          },
          end: {
            line: 155,
            column: 71
          }
        }, {
          start: {
            line: 155,
            column: 77
          },
          end: {
            line: 155,
            column: 96
          }
        }, {
          start: {
            line: 155,
            column: 100
          },
          end: {
            line: 155,
            column: 111
          }
        }],
        line: 155
      },
      '23': {
        loc: {
          start: {
            line: 187,
            column: 17
          },
          end: {
            line: 187,
            column: 81
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 187,
            column: 17
          },
          end: {
            line: 187,
            column: 50
          }
        }, {
          start: {
            line: 187,
            column: 54
          },
          end: {
            line: 187,
            column: 81
          }
        }],
        line: 187
      },
      '24': {
        loc: {
          start: {
            line: 189,
            column: 8
          },
          end: {
            line: 192,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 189,
            column: 8
          },
          end: {
            line: 192,
            column: 9
          }
        }, {
          start: {
            line: 189,
            column: 8
          },
          end: {
            line: 192,
            column: 9
          }
        }],
        line: 189
      },
      '25': {
        loc: {
          start: {
            line: 229,
            column: 10
          },
          end: {
            line: 231,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 229,
            column: 10
          },
          end: {
            line: 231,
            column: 11
          }
        }, {
          start: {
            line: 229,
            column: 10
          },
          end: {
            line: 231,
            column: 11
          }
        }],
        line: 229
      },
      '26': {
        loc: {
          start: {
            line: 234,
            column: 12
          },
          end: {
            line: 243,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 234,
            column: 12
          },
          end: {
            line: 243,
            column: 13
          }
        }, {
          start: {
            line: 234,
            column: 12
          },
          end: {
            line: 243,
            column: 13
          }
        }],
        line: 234
      },
      '27': {
        loc: {
          start: {
            line: 262,
            column: 10
          },
          end: {
            line: 265,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 262,
            column: 10
          },
          end: {
            line: 265,
            column: 11
          }
        }, {
          start: {
            line: 262,
            column: 10
          },
          end: {
            line: 265,
            column: 11
          }
        }],
        line: 262
      },
      '28': {
        loc: {
          start: {
            line: 266,
            column: 10
          },
          end: {
            line: 269,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 266,
            column: 10
          },
          end: {
            line: 269,
            column: 11
          }
        }, {
          start: {
            line: 266,
            column: 10
          },
          end: {
            line: 269,
            column: 11
          }
        }],
        line: 266
      },
      '29': {
        loc: {
          start: {
            line: 270,
            column: 10
          },
          end: {
            line: 273,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 270,
            column: 10
          },
          end: {
            line: 273,
            column: 11
          }
        }, {
          start: {
            line: 270,
            column: 10
          },
          end: {
            line: 273,
            column: 11
          }
        }],
        line: 270
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0, 0, 0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_r6vfby86e.s[0]++;
angular.module('optionalIndicatorFieldModule', []).factory('optionalIndicatorFieldFact', ['$CRUDService', '$q', '$translate', '$LOGINService', function ($CRUDService, $q, $translate, $LOGINService) {
  cov_r6vfby86e.f[0]++;

  var PATH = (cov_r6vfby86e.s[1]++, 'StrategicAxis');
  var PATH_ = (cov_r6vfby86e.s[2]++, 'Impacts');
  cov_r6vfby86e.s[3]++;
  return {
    dataStrategicAxis: null,
    dataImpacts: null,
    clearData: function clearData() {
      cov_r6vfby86e.s[4]++;

      this.dataImpacts = null;
      cov_r6vfby86e.s[5]++;
      this.dataStrategicAxis = null;
    },
    loadSingle: function loadSingle(type, ids, fn) {
      cov_r6vfby86e.s[6]++;

      $CRUDService.getAll(type == 1 ? (cov_r6vfby86e.b[0][0]++, PATH) : (cov_r6vfby86e.b[0][1]++, PATH_), { get: 'all_by_ids', ids: ids }, function (data) {
        cov_r6vfby86e.f[1]++;
        cov_r6vfby86e.s[7]++;

        if (fn) {
          cov_r6vfby86e.b[1][0]++;
          cov_r6vfby86e.s[8]++;

          fn(data.map(function (value) {
            cov_r6vfby86e.f[2]++;
            cov_r6vfby86e.s[9]++;

            value.label = (cov_r6vfby86e.b[2][0]++, value.NAME_AXIS) || (cov_r6vfby86e.b[2][1]++, value.NAME_IMPACT);
            cov_r6vfby86e.s[10]++;
            return value;
          }));
        } else {
          cov_r6vfby86e.b[1][1]++;
        }
      });
    },
    loadData: function loadData(type, fn) {
      var _this = this;

      cov_r6vfby86e.s[11]++;

      $CRUDService.getAll(type == 1 ? (cov_r6vfby86e.b[3][0]++, PATH) : (cov_r6vfby86e.b[3][1]++, PATH_), { get: 'all' }, function (data) {
        cov_r6vfby86e.f[3]++;
        cov_r6vfby86e.s[12]++;

        if (type == 1) {
          cov_r6vfby86e.b[4][0]++;
          cov_r6vfby86e.s[13]++;

          _this.dataStrategicAxis = data.map(function (value) {
            cov_r6vfby86e.f[4]++;
            cov_r6vfby86e.s[14]++;

            value.label = value.NAME_AXIS;
            cov_r6vfby86e.s[15]++;
            return value;
          });
        } else {
          cov_r6vfby86e.b[4][1]++;
          cov_r6vfby86e.s[16]++;

          _this.dataImpacts = data.map(function (value) {
            cov_r6vfby86e.f[5]++;
            cov_r6vfby86e.s[17]++;

            value.label = value.NAME_IMPACT;
            cov_r6vfby86e.s[18]++;
            return value;
          });
        }

        cov_r6vfby86e.s[19]++;
        if (fn) {
          cov_r6vfby86e.b[5][0]++;
          cov_r6vfby86e.s[20]++;

          fn(type == 1 ? (cov_r6vfby86e.b[6][0]++, _this.dataStrategicAxis) : (cov_r6vfby86e.b[6][1]++, _this.dataImpacts));
        } else {
          cov_r6vfby86e.b[5][1]++;
        }
      });
    },
    resetData: function resetData(type) {
      cov_r6vfby86e.s[21]++;

      if (!type) {
        cov_r6vfby86e.b[7][0]++;
        cov_r6vfby86e.s[22]++;

        this.dataImpacts = null;
        cov_r6vfby86e.s[23]++;
        this.dataStrategicAxis = null;
        cov_r6vfby86e.s[24]++;
        return;
      } else {
        cov_r6vfby86e.b[7][1]++;
      }

      cov_r6vfby86e.s[25]++;
      if (type == 1) {
        cov_r6vfby86e.b[8][0]++;
        cov_r6vfby86e.s[26]++;

        this.dataStrategicAxis = null;
        cov_r6vfby86e.s[27]++;
        return;
      } else {
        cov_r6vfby86e.b[8][1]++;
      }
      cov_r6vfby86e.s[28]++;
      this.dataImpacts = [];
    },
    save: function save(type, validateItem, fn) {
      cov_r6vfby86e.s[29]++;

      $CRUDService.save(type == 1 ? (cov_r6vfby86e.b[9][0]++, PATH) : (cov_r6vfby86e.b[9][1]++, PATH_), { action: 'MiseAJour', valeur: angular.toJson(validateItem) }, function (data) {
        cov_r6vfby86e.f[6]++;
        cov_r6vfby86e.s[30]++;

        fn(parseInt(data, 10));
      });
    },
    checkDelete: function checkDelete(type, validateItem, fn) {
      cov_r6vfby86e.s[31]++;

      $CRUDService.getAll(type == 1 ? (cov_r6vfby86e.b[10][0]++, PATH) : (cov_r6vfby86e.b[10][1]++, PATH_), { get: 'check_delete', id: validateItem.id }, function (data) {
        cov_r6vfby86e.f[7]++;
        cov_r6vfby86e.s[32]++;

        fn(parseInt(data, 10) > 0);
      });
    },
    delete: function _delete(type, item, fn) {
      cov_r6vfby86e.s[33]++;

      $CRUDService.delet(type == 1 ? (cov_r6vfby86e.b[11][0]++, PATH) : (cov_r6vfby86e.b[11][1]++, PATH_), { action: 'supp', id: item.id }, function (data) {
        cov_r6vfby86e.f[8]++;
        cov_r6vfby86e.s[34]++;

        fn(data);
      });
    },
    getData: function getData(type, filter) {
      cov_r6vfby86e.s[35]++;

      if (filter) {
        cov_r6vfby86e.b[12][0]++;
        cov_r6vfby86e.s[36]++;

        return [{ id: 0, label: $translate.instant('COMMON.ALL') }].concat((cov_r6vfby86e.b[13][0]++, type == 1 ? (cov_r6vfby86e.b[14][0]++, this.dataStrategicAxis) : (cov_r6vfby86e.b[14][1]++, this.dataImpacts)) || (cov_r6vfby86e.b[13][1]++, []));
      } else {
        cov_r6vfby86e.b[12][1]++;
      }
      cov_r6vfby86e.s[37]++;
      return type == 1 ? (cov_r6vfby86e.b[15][0]++, this.dataStrategicAxis) : (cov_r6vfby86e.b[15][1]++, this.dataImpacts);
    }
  };
}]).directive('appOptionalIndicatorField', function () {
  cov_r6vfby86e.f[9]++;
  cov_r6vfby86e.s[38]++;

  return {
    restrict: 'AE', // attribute or element
    templateUrl: 'app/shared/optional_indicator_field/dropdown.html',
    scope: {
      readonly: '=',
      value: '=?',
      valueSingle: '=?',
      isFilter: '=?',
      inline: '=',
      notInForm: '=',
      fieldLabel: '=',
      type: '=', // type == 1 ?   'StrategicAxis' :'Impacts';
      onChange: '&'
    },
    controller: function controller($scope, optionalIndicatorFieldFact, $LOGINService, $translate, $uibModal, $timeout) {
      var vm = (cov_r6vfby86e.s[39]++, $scope);
      cov_r6vfby86e.s[40]++;
      vm.fn = {
        changeValue: function changeValue() {
          cov_r6vfby86e.s[41]++;

          vm.value = [{ id: '0' }].concat(vm.values.selectedData).concat([{ id: '0' }]).map(function (value) {
            cov_r6vfby86e.f[10]++;
            cov_r6vfby86e.s[42]++;
            return value.id;
          }).join(',');
          cov_r6vfby86e.s[43]++;
          if (!$scope.onChange) {
            cov_r6vfby86e.b[16][0]++;
            cov_r6vfby86e.s[44]++;

            return;
          } else {
            cov_r6vfby86e.b[16][1]++;
          }
          cov_r6vfby86e.s[45]++;
          $scope.onChange({ value: vm.value });
        },
        changeSingleValue: function changeSingleValue() {
          cov_r6vfby86e.s[46]++;

          vm.valueSingle = vm.values.selectedData.id;
          cov_r6vfby86e.s[47]++;
          if (!$scope.onChange) {
            cov_r6vfby86e.b[17][0]++;
            cov_r6vfby86e.s[48]++;

            return;
          } else {
            cov_r6vfby86e.b[17][1]++;
          }
          cov_r6vfby86e.s[49]++;
          $timeout(function () {
            cov_r6vfby86e.f[11]++;
            cov_r6vfby86e.s[50]++;

            $scope.onChange({ value: vm.valueSingle });
          }, 150);
        },
        openEditForm: function openEditForm() {
          cov_r6vfby86e.s[51]++;

          $uibModal.open({
            templateUrl: 'app/shared/optional_indicator_field/modal.html',
            controller: function controller($uibModalInstance) {
              cov_r6vfby86e.f[12]++;
              cov_r6vfby86e.s[52]++;

              $scope.closeModal = function () {
                cov_r6vfby86e.f[13]++;
                cov_r6vfby86e.s[53]++;

                optionalIndicatorFieldFact.resetData(vm.type);
                cov_r6vfby86e.s[54]++;
                vm.fn.init();
                cov_r6vfby86e.s[55]++;
                $uibModalInstance.dismiss('cancel');
              };
            },
            scope: $scope,
            size: 'xs',
            windowClass: 'animated fadeInRight  left-modal',
            backdrop: 'static'
            // keyboard: false,
          });
        },
        _init: function _init() {
          cov_r6vfby86e.s[56]++;

          vm.loading = false;
          cov_r6vfby86e.s[57]++;
          if (vm.isFilter) {
            cov_r6vfby86e.b[18][0]++;
            cov_r6vfby86e.s[58]++;

            vm.values.selectedData = vm.data.find(function (value) {
              cov_r6vfby86e.f[14]++;
              cov_r6vfby86e.s[59]++;

              return vm.valueSingle == value.id;
            });
            cov_r6vfby86e.s[60]++;
            return;
          } else {
            cov_r6vfby86e.b[18][1]++;
          }
          cov_r6vfby86e.s[61]++;
          vm.values.selectedData = vm.data.filter(function (value) {
            cov_r6vfby86e.f[15]++;
            cov_r6vfby86e.s[62]++;

            return ((cov_r6vfby86e.b[19][0]++, vm.value) || (cov_r6vfby86e.b[19][1]++, '')).includes(',' + value.id + ',');
          });
        },
        init: function init() {
          var _this2 = this;

          cov_r6vfby86e.s[63]++;

          vm.values = {
            selectedData: null
          };
          cov_r6vfby86e.s[64]++;
          vm.loading = true;

          cov_r6vfby86e.s[65]++;
          if (vm.readonly) {
            cov_r6vfby86e.b[20][0]++;
            cov_r6vfby86e.s[66]++;

            optionalIndicatorFieldFact.loadSingle(vm.type, vm.value, function (data) {
              cov_r6vfby86e.f[16]++;
              cov_r6vfby86e.s[67]++;

              vm.values.display = data.map(function (value) {
                cov_r6vfby86e.f[17]++;
                cov_r6vfby86e.s[68]++;
                return value.label;
              }).join(' ; ');
            });
            cov_r6vfby86e.s[69]++;
            return;
          } else {
            cov_r6vfby86e.b[20][1]++;
          }

          cov_r6vfby86e.s[70]++;
          vm.data = optionalIndicatorFieldFact.getData(vm.type, vm.isFilter);
          cov_r6vfby86e.s[71]++;
          if ((cov_r6vfby86e.b[22][0]++, vm.data == null) || (cov_r6vfby86e.b[22][1]++, vm.data.length == 0) && (cov_r6vfby86e.b[22][2]++, !vm.isFilter) || (cov_r6vfby86e.b[22][3]++, vm.data.length == 1) && (cov_r6vfby86e.b[22][4]++, vm.isFilter)) {
            cov_r6vfby86e.b[21][0]++;
            cov_r6vfby86e.s[72]++;

            optionalIndicatorFieldFact.loadData(vm.type, function (data) {
              cov_r6vfby86e.f[18]++;
              cov_r6vfby86e.s[73]++;

              vm.data = optionalIndicatorFieldFact.getData(vm.type, vm.isFilter);
              cov_r6vfby86e.s[74]++;
              _this2._init();
            });
            cov_r6vfby86e.s[75]++;
            return;
          } else {
            cov_r6vfby86e.b[21][1]++;
          }

          cov_r6vfby86e.s[76]++;
          this._init();
        }
      };
      cov_r6vfby86e.s[77]++;
      vm.fn.init();
    }
  };
}).controller('OptionalIndicatorFieldCtrl', function ($scope, SweetAlert, $uibModal, $translate, optionalIndicatorFieldFact) {
  cov_r6vfby86e.f[19]++;
  cov_r6vfby86e.s[78]++;

  $scope.listDefaults = [];
  cov_r6vfby86e.s[79]++;
  $scope.page = 1;
  cov_r6vfby86e.s[80]++;
  $scope.modal = {
    isloading: true,
    initData: function initData() {
      cov_r6vfby86e.s[81]++;

      optionalIndicatorFieldFact.loadData($scope.type, function (data) {
        cov_r6vfby86e.f[20]++;
        cov_r6vfby86e.s[82]++;

        $scope.modal.params.dataSources = data;
        cov_r6vfby86e.s[83]++;
        $scope.modal.isloading = false;
        cov_r6vfby86e.s[84]++;
        $scope.modal.cancel();
      });
    },
    validateData: function validateData() {
      var validateItem = (cov_r6vfby86e.s[85]++, angular.copy($scope.modal.validateItem));
      cov_r6vfby86e.s[86]++;
      $scope.modal.error_double = false;
      var item = (cov_r6vfby86e.s[87]++, $scope.modal.params.dataSources.find(function (value) {
        cov_r6vfby86e.f[21]++;
        cov_r6vfby86e.s[88]++;

        return (cov_r6vfby86e.b[23][0]++, value.label == validateItem.label) && (cov_r6vfby86e.b[23][1]++, value.id != validateItem.id);
      }));
      cov_r6vfby86e.s[89]++;
      if (item) {
        cov_r6vfby86e.b[24][0]++;
        cov_r6vfby86e.s[90]++;

        $scope.modal.error_double = true;
        cov_r6vfby86e.s[91]++;
        return;
      } else {
        cov_r6vfby86e.b[24][1]++;
      }
      cov_r6vfby86e.s[92]++;
      validateItem.NAME_AXIS = validateItem.label;
      cov_r6vfby86e.s[93]++;
      validateItem.NAME_IMPACT = validateItem.label;
      cov_r6vfby86e.s[94]++;
      $scope.modal.isloading = true;
      cov_r6vfby86e.s[95]++;
      optionalIndicatorFieldFact.save($scope.type, validateItem, function () {
        cov_r6vfby86e.f[22]++;
        cov_r6vfby86e.s[96]++;

        $scope.modal.initData();
      });
    },
    cancel: function cancel() {
      cov_r6vfby86e.s[97]++;

      $scope.page = 1;
    },
    addModal: function addModal() {
      cov_r6vfby86e.s[98]++;

      this.editModal({
        id: 0,
        label: '',
        CODE_IMPACT: new Date().getTime().toString(),
        CODE_AXIS: new Date().getTime().toString()
      });
    },
    editModal: function editModal(item) {
      cov_r6vfby86e.s[99]++;

      $scope.modal.error_double = false;
      cov_r6vfby86e.s[100]++;
      this.validateItem = angular.copy(item);
      cov_r6vfby86e.s[101]++;
      $scope.page = 2;
    },
    delete: function _delete(item) {
      cov_r6vfby86e.s[102]++;

      SweetAlert.swal({
        title: $translate.instant('COMMON.CONFIRM'),
        // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c8c8c8',
        confirmButtonText: $translate.instant('COMMON.NO'),
        cancelButtonText: $translate.instant('COMMON.YES'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_r6vfby86e.f[23]++;
        cov_r6vfby86e.s[103]++;

        if (isconfirm) {
          cov_r6vfby86e.b[25][0]++;
          cov_r6vfby86e.s[104]++;

          return;
        } else {
          cov_r6vfby86e.b[25][1]++;
        }
        cov_r6vfby86e.s[105]++;
        $scope.modal.isloading = true;
        cov_r6vfby86e.s[106]++;
        optionalIndicatorFieldFact.delete($scope.type, item, function (data) {
          cov_r6vfby86e.f[24]++;
          cov_r6vfby86e.s[107]++;

          if (data < 0) {
            cov_r6vfby86e.b[26][0]++;
            cov_r6vfby86e.s[108]++;

            SweetAlert.swal({
              title: $translate.instant('COMMON.ERROR_DELETE'),
              text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
              type: 'warning'
            });
            cov_r6vfby86e.s[109]++;
            $scope.modal.isloading = false;
          } else {
            cov_r6vfby86e.b[26][1]++;
            cov_r6vfby86e.s[110]++;

            $scope.modal.initData();
          }
        });
      });
    }
  };
  cov_r6vfby86e.s[111]++;
  optionalIndicatorFieldFact.loadData($scope.type, function (data) {
    cov_r6vfby86e.f[25]++;
    cov_r6vfby86e.s[112]++;

    $scope.modal.params = {
      dataSources: data,
      columns: [{ field: 'label', headerText: $translate.instant('DEFAULT_LOGFRAME.LABEL').toUpperCase(), type: 'string' }, { headerText: '', width: 150, maxWidth: 180,
        commands: [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' }, id: 'edit' }, { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' }, id: 'delete' }]
      }],
      editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },
      actionBegin: function actionBegin(action) {
        cov_r6vfby86e.s[113]++;

        if (action.requestType === 'add') {
          cov_r6vfby86e.b[27][0]++;
          cov_r6vfby86e.s[114]++;

          action.cancel = true;
          cov_r6vfby86e.s[115]++;
          $scope.modal.addModal();
        } else {
          cov_r6vfby86e.b[27][1]++;
        }
        cov_r6vfby86e.s[116]++;
        if (action.requestType === 'beginEdit') {
          cov_r6vfby86e.b[28][0]++;
          cov_r6vfby86e.s[117]++;

          action.cancel = true;
          cov_r6vfby86e.s[118]++;
          $scope.modal.editModal(action.rowData);
        } else {
          cov_r6vfby86e.b[28][1]++;
        }
        cov_r6vfby86e.s[119]++;
        if (action.requestType === 'delete') {
          cov_r6vfby86e.b[29][0]++;
          cov_r6vfby86e.s[120]++;

          action.cancel = true;
          cov_r6vfby86e.s[121]++;
          $scope.modal.delete(action.data[0]);
        } else {
          cov_r6vfby86e.b[29][1]++;
        }
      }
    };
    cov_r6vfby86e.s[122]++;
    $scope.modal.isloading = false;
  });
});