'use strict';

var cov_kqyxplg8h = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/Units/UnitsCtrl.js',
      hash = '4b0c074e95f979811910f8d167a7dc9f7befd687',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/Units/UnitsCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 1
        },
        end: {
          line: 285,
          column: 4
        }
      },
      '1': {
        start: {
          line: 3,
          column: 3
        },
        end: {
          line: 3,
          column: 38
        }
      },
      '2': {
        start: {
          line: 4,
          column: 3
        },
        end: {
          line: 6,
          column: 4
        }
      },
      '3': {
        start: {
          line: 5,
          column: 5
        },
        end: {
          line: 5,
          column: 12
        }
      },
      '4': {
        start: {
          line: 7,
          column: 3
        },
        end: {
          line: 7,
          column: 22
        }
      },
      '5': {
        start: {
          line: 8,
          column: 3
        },
        end: {
          line: 8,
          column: 23
        }
      },
      '6': {
        start: {
          line: 9,
          column: 3
        },
        end: {
          line: 9,
          column: 28
        }
      },
      '7': {
        start: {
          line: 10,
          column: 3
        },
        end: {
          line: 10,
          column: 25
        }
      },
      '8': {
        start: {
          line: 11,
          column: 3
        },
        end: {
          line: 11,
          column: 25
        }
      },
      '9': {
        start: {
          line: 12,
          column: 3
        },
        end: {
          line: 12,
          column: 27
        }
      },
      '10': {
        start: {
          line: 13,
          column: 3
        },
        end: {
          line: 13,
          column: 27
        }
      },
      '11': {
        start: {
          line: 14,
          column: 19
        },
        end: {
          line: 14,
          column: 49
        }
      },
      '12': {
        start: {
          line: 15,
          column: 3
        },
        end: {
          line: 19,
          column: 5
        }
      },
      '13': {
        start: {
          line: 21,
          column: 3
        },
        end: {
          line: 27,
          column: 6
        }
      },
      '14': {
        start: {
          line: 22,
          column: 5
        },
        end: {
          line: 26,
          column: 8
        }
      },
      '15': {
        start: {
          line: 28,
          column: 3
        },
        end: {
          line: 28,
          column: 19
        }
      },
      '16': {
        start: {
          line: 30,
          column: 3
        },
        end: {
          line: 50,
          column: 5
        }
      },
      '17': {
        start: {
          line: 31,
          column: 5
        },
        end: {
          line: 31,
          column: 29
        }
      },
      '18': {
        start: {
          line: 32,
          column: 5
        },
        end: {
          line: 49,
          column: 8
        }
      },
      '19': {
        start: {
          line: 33,
          column: 7
        },
        end: {
          line: 44,
          column: 8
        }
      },
      '20': {
        start: {
          line: 34,
          column: 9
        },
        end: {
          line: 34,
          column: 49
        }
      },
      '21': {
        start: {
          line: 35,
          column: 22
        },
        end: {
          line: 37,
          column: 11
        }
      },
      '22': {
        start: {
          line: 36,
          column: 11
        },
        end: {
          line: 36,
          column: 47
        }
      },
      '23': {
        start: {
          line: 38,
          column: 9
        },
        end: {
          line: 43,
          column: 10
        }
      },
      '24': {
        start: {
          line: 39,
          column: 11
        },
        end: {
          line: 39,
          column: 42
        }
      },
      '25': {
        start: {
          line: 40,
          column: 11
        },
        end: {
          line: 42,
          column: 12
        }
      },
      '26': {
        start: {
          line: 41,
          column: 13
        },
        end: {
          line: 41,
          column: 40
        }
      },
      '27': {
        start: {
          line: 46,
          column: 7
        },
        end: {
          line: 46,
          column: 31
        }
      },
      '28': {
        start: {
          line: 47,
          column: 7
        },
        end: {
          line: 47,
          column: 32
        }
      },
      '29': {
        start: {
          line: 48,
          column: 7
        },
        end: {
          line: 48,
          column: 31
        }
      },
      '30': {
        start: {
          line: 52,
          column: 3
        },
        end: {
          line: 56,
          column: 5
        }
      },
      '31': {
        start: {
          line: 53,
          column: 5
        },
        end: {
          line: 55,
          column: 8
        }
      },
      '32': {
        start: {
          line: 54,
          column: 7
        },
        end: {
          line: 54,
          column: 54
        }
      },
      '33': {
        start: {
          line: 58,
          column: 3
        },
        end: {
          line: 85,
          column: 5
        }
      },
      '34': {
        start: {
          line: 72,
          column: 7
        },
        end: {
          line: 75,
          column: 8
        }
      },
      '35': {
        start: {
          line: 73,
          column: 9
        },
        end: {
          line: 73,
          column: 30
        }
      },
      '36': {
        start: {
          line: 74,
          column: 9
        },
        end: {
          line: 74,
          column: 25
        }
      },
      '37': {
        start: {
          line: 76,
          column: 7
        },
        end: {
          line: 79,
          column: 8
        }
      },
      '38': {
        start: {
          line: 77,
          column: 9
        },
        end: {
          line: 77,
          column: 30
        }
      },
      '39': {
        start: {
          line: 78,
          column: 9
        },
        end: {
          line: 78,
          column: 40
        }
      },
      '40': {
        start: {
          line: 80,
          column: 7
        },
        end: {
          line: 83,
          column: 8
        }
      },
      '41': {
        start: {
          line: 81,
          column: 9
        },
        end: {
          line: 81,
          column: 30
        }
      },
      '42': {
        start: {
          line: 82,
          column: 9
        },
        end: {
          line: 82,
          column: 46
        }
      },
      '43': {
        start: {
          line: 87,
          column: 3
        },
        end: {
          line: 89,
          column: 5
        }
      },
      '44': {
        start: {
          line: 88,
          column: 5
        },
        end: {
          line: 88,
          column: 21
        }
      },
      '45': {
        start: {
          line: 91,
          column: 3
        },
        end: {
          line: 99,
          column: 5
        }
      },
      '46': {
        start: {
          line: 92,
          column: 5
        },
        end: {
          line: 98,
          column: 8
        }
      },
      '47': {
        start: {
          line: 100,
          column: 3
        },
        end: {
          line: 114,
          column: 5
        }
      },
      '48': {
        start: {
          line: 101,
          column: 5
        },
        end: {
          line: 101,
          column: 31
        }
      },
      '49': {
        start: {
          line: 102,
          column: 26
        },
        end: {
          line: 102,
          column: 44
        }
      },
      '50': {
        start: {
          line: 103,
          column: 5
        },
        end: {
          line: 103,
          column: 102
        }
      },
      '51': {
        start: {
          line: 104,
          column: 5
        },
        end: {
          line: 104,
          column: 76
        }
      },
      '52': {
        start: {
          line: 105,
          column: 5
        },
        end: {
          line: 105,
          column: 40
        }
      },
      '53': {
        start: {
          line: 106,
          column: 5
        },
        end: {
          line: 113,
          column: 8
        }
      },
      '54': {
        start: {
          line: 115,
          column: 3
        },
        end: {
          line: 119,
          column: 5
        }
      },
      '55': {
        start: {
          line: 116,
          column: 5
        },
        end: {
          line: 116,
          column: 24
        }
      },
      '56': {
        start: {
          line: 117,
          column: 5
        },
        end: {
          line: 117,
          column: 25
        }
      },
      '57': {
        start: {
          line: 118,
          column: 5
        },
        end: {
          line: 118,
          column: 30
        }
      },
      '58': {
        start: {
          line: 120,
          column: 3
        },
        end: {
          line: 147,
          column: 5
        }
      },
      '59': {
        start: {
          line: 121,
          column: 5
        },
        end: {
          line: 121,
          column: 29
        }
      },
      '60': {
        start: {
          line: 122,
          column: 5
        },
        end: {
          line: 122,
          column: 29
        }
      },
      '61': {
        start: {
          line: 123,
          column: 5
        },
        end: {
          line: 145,
          column: 6
        }
      },
      '62': {
        start: {
          line: 125,
          column: 9
        },
        end: {
          line: 125,
          column: 33
        }
      },
      '63': {
        start: {
          line: 126,
          column: 9
        },
        end: {
          line: 126,
          column: 33
        }
      },
      '64': {
        start: {
          line: 127,
          column: 9
        },
        end: {
          line: 127,
          column: 15
        }
      },
      '65': {
        start: {
          line: 129,
          column: 9
        },
        end: {
          line: 129,
          column: 33
        }
      },
      '66': {
        start: {
          line: 130,
          column: 9
        },
        end: {
          line: 130,
          column: 33
        }
      },
      '67': {
        start: {
          line: 131,
          column: 9
        },
        end: {
          line: 131,
          column: 15
        }
      },
      '68': {
        start: {
          line: 133,
          column: 9
        },
        end: {
          line: 133,
          column: 33
        }
      },
      '69': {
        start: {
          line: 134,
          column: 9
        },
        end: {
          line: 134,
          column: 34
        }
      },
      '70': {
        start: {
          line: 135,
          column: 9
        },
        end: {
          line: 135,
          column: 15
        }
      },
      '71': {
        start: {
          line: 137,
          column: 9
        },
        end: {
          line: 137,
          column: 33
        }
      },
      '72': {
        start: {
          line: 138,
          column: 9
        },
        end: {
          line: 138,
          column: 34
        }
      },
      '73': {
        start: {
          line: 139,
          column: 9
        },
        end: {
          line: 139,
          column: 15
        }
      },
      '74': {
        start: {
          line: 141,
          column: 9
        },
        end: {
          line: 141,
          column: 33
        }
      },
      '75': {
        start: {
          line: 142,
          column: 9
        },
        end: {
          line: 142,
          column: 34
        }
      },
      '76': {
        start: {
          line: 143,
          column: 9
        },
        end: {
          line: 143,
          column: 15
        }
      },
      '77': {
        start: {
          line: 146,
          column: 5
        },
        end: {
          line: 146,
          column: 32
        }
      },
      '78': {
        start: {
          line: 149,
          column: 3
        },
        end: {
          line: 154,
          column: 5
        }
      },
      '79': {
        start: {
          line: 150,
          column: 5
        },
        end: {
          line: 150,
          column: 30
        }
      },
      '80': {
        start: {
          line: 151,
          column: 5
        },
        end: {
          line: 153,
          column: 6
        }
      },
      '81': {
        start: {
          line: 152,
          column: 7
        },
        end: {
          line: 152,
          column: 31
        }
      },
      '82': {
        start: {
          line: 156,
          column: 3
        },
        end: {
          line: 199,
          column: 5
        }
      },
      '83': {
        start: {
          line: 157,
          column: 5
        },
        end: {
          line: 198,
          column: 8
        }
      },
      '84': {
        start: {
          line: 169,
          column: 7
        },
        end: {
          line: 171,
          column: 8
        }
      },
      '85': {
        start: {
          line: 170,
          column: 9
        },
        end: {
          line: 170,
          column: 16
        }
      },
      '86': {
        start: {
          line: 172,
          column: 7
        },
        end: {
          line: 172,
          column: 31
        }
      },
      '87': {
        start: {
          line: 173,
          column: 28
        },
        end: {
          line: 179,
          column: 8
        }
      },
      '88': {
        start: {
          line: 185,
          column: 7
        },
        end: {
          line: 197,
          column: 10
        }
      },
      '89': {
        start: {
          line: 186,
          column: 9
        },
        end: {
          line: 196,
          column: 10
        }
      },
      '90': {
        start: {
          line: 187,
          column: 11
        },
        end: {
          line: 191,
          column: 14
        }
      },
      '91': {
        start: {
          line: 192,
          column: 11
        },
        end: {
          line: 192,
          column: 36
        }
      },
      '92': {
        start: {
          line: 194,
          column: 11
        },
        end: {
          line: 194,
          column: 30
        }
      },
      '93': {
        start: {
          line: 195,
          column: 11
        },
        end: {
          line: 195,
          column: 27
        }
      },
      '94': {
        start: {
          line: 201,
          column: 3
        },
        end: {
          line: 282,
          column: 5
        }
      },
      '95': {
        start: {
          line: 203,
          column: 7
        },
        end: {
          line: 209,
          column: 10
        }
      },
      '96': {
        start: {
          line: 212,
          column: 7
        },
        end: {
          line: 245,
          column: 10
        }
      },
      '97': {
        start: {
          line: 222,
          column: 9
        },
        end: {
          line: 224,
          column: 10
        }
      },
      '98': {
        start: {
          line: 223,
          column: 11
        },
        end: {
          line: 223,
          column: 18
        }
      },
      '99': {
        start: {
          line: 225,
          column: 9
        },
        end: {
          line: 225,
          column: 33
        }
      },
      '100': {
        start: {
          line: 226,
          column: 30
        },
        end: {
          line: 232,
          column: 10
        }
      },
      '101': {
        start: {
          line: 233,
          column: 9
        },
        end: {
          line: 244,
          column: 12
        }
      },
      '102': {
        start: {
          line: 234,
          column: 11
        },
        end: {
          line: 243,
          column: 12
        }
      },
      '103': {
        start: {
          line: 235,
          column: 13
        },
        end: {
          line: 239,
          column: 16
        }
      },
      '104': {
        start: {
          line: 240,
          column: 13
        },
        end: {
          line: 240,
          column: 38
        }
      },
      '105': {
        start: {
          line: 242,
          column: 13
        },
        end: {
          line: 242,
          column: 50
        }
      },
      '106': {
        start: {
          line: 248,
          column: 7
        },
        end: {
          line: 248,
          column: 23
        }
      },
      '107': {
        start: {
          line: 251,
          column: 7
        },
        end: {
          line: 251,
          column: 31
        }
      },
      '108': {
        start: {
          line: 252,
          column: 28
        },
        end: {
          line: 252,
          column: 46
        }
      },
      '109': {
        start: {
          line: 253,
          column: 7
        },
        end: {
          line: 253,
          column: 104
        }
      },
      '110': {
        start: {
          line: 254,
          column: 7
        },
        end: {
          line: 254,
          column: 78
        }
      },
      '111': {
        start: {
          line: 255,
          column: 7
        },
        end: {
          line: 255,
          column: 40
        }
      },
      '112': {
        start: {
          line: 256,
          column: 7
        },
        end: {
          line: 256,
          column: 23
        }
      },
      '113': {
        start: {
          line: 259,
          column: 28
        },
        end: {
          line: 259,
          column: 59
        }
      },
      '114': {
        start: {
          line: 260,
          column: 7
        },
        end: {
          line: 260,
          column: 29
        }
      },
      '115': {
        start: {
          line: 261,
          column: 7
        },
        end: {
          line: 264,
          column: 8
        }
      },
      '116': {
        start: {
          line: 262,
          column: 9
        },
        end: {
          line: 262,
          column: 31
        }
      },
      '117': {
        start: {
          line: 263,
          column: 9
        },
        end: {
          line: 263,
          column: 16
        }
      },
      '118': {
        start: {
          line: 265,
          column: 7
        },
        end: {
          line: 265,
          column: 64
        }
      },
      '119': {
        start: {
          line: 266,
          column: 21
        },
        end: {
          line: 266,
          column: 101
        }
      },
      '120': {
        start: {
          line: 267,
          column: 7
        },
        end: {
          line: 272,
          column: 8
        }
      },
      '121': {
        start: {
          line: 268,
          column: 9
        },
        end: {
          line: 271,
          column: 10
        }
      },
      '122': {
        start: {
          line: 269,
          column: 11
        },
        end: {
          line: 269,
          column: 33
        }
      },
      '123': {
        start: {
          line: 270,
          column: 11
        },
        end: {
          line: 270,
          column: 18
        }
      },
      '124': {
        start: {
          line: 273,
          column: 7
        },
        end: {
          line: 273,
          column: 62
        }
      },
      '125': {
        start: {
          line: 274,
          column: 23
        },
        end: {
          line: 274,
          column: 42
        }
      },
      '126': {
        start: {
          line: 275,
          column: 7
        },
        end: {
          line: 280,
          column: 10
        }
      },
      '127': {
        start: {
          line: 276,
          column: 9
        },
        end: {
          line: 276,
          column: 46
        }
      },
      '128': {
        start: {
          line: 277,
          column: 9
        },
        end: {
          line: 277,
          column: 55
        }
      },
      '129': {
        start: {
          line: 278,
          column: 9
        },
        end: {
          line: 278,
          column: 25
        }
      },
      '130': {
        start: {
          line: 284,
          column: 3
        },
        end: {
          line: 284,
          column: 19
        }
      },
      '131': {
        start: {
          line: 288,
          column: 3
        },
        end: {
          line: 288,
          column: 29
        }
      },
      '132': {
        start: {
          line: 289,
          column: 3
        },
        end: {
          line: 309,
          column: 5
        }
      },
      '133': {
        start: {
          line: 290,
          column: 26
        },
        end: {
          line: 290,
          column: 59
        }
      },
      '134': {
        start: {
          line: 291,
          column: 5
        },
        end: {
          line: 291,
          column: 27
        }
      },
      '135': {
        start: {
          line: 292,
          column: 5
        },
        end: {
          line: 295,
          column: 6
        }
      },
      '136': {
        start: {
          line: 293,
          column: 7
        },
        end: {
          line: 293,
          column: 29
        }
      },
      '137': {
        start: {
          line: 294,
          column: 7
        },
        end: {
          line: 294,
          column: 14
        }
      },
      '138': {
        start: {
          line: 296,
          column: 5
        },
        end: {
          line: 296,
          column: 62
        }
      },
      '139': {
        start: {
          line: 297,
          column: 19
        },
        end: {
          line: 297,
          column: 99
        }
      },
      '140': {
        start: {
          line: 298,
          column: 5
        },
        end: {
          line: 303,
          column: 6
        }
      },
      '141': {
        start: {
          line: 299,
          column: 7
        },
        end: {
          line: 302,
          column: 8
        }
      },
      '142': {
        start: {
          line: 300,
          column: 9
        },
        end: {
          line: 300,
          column: 31
        }
      },
      '143': {
        start: {
          line: 301,
          column: 9
        },
        end: {
          line: 301,
          column: 16
        }
      },
      '144': {
        start: {
          line: 304,
          column: 5
        },
        end: {
          line: 304,
          column: 60
        }
      },
      '145': {
        start: {
          line: 305,
          column: 5
        },
        end: {
          line: 308,
          column: 8
        }
      },
      '146': {
        start: {
          line: 306,
          column: 7
        },
        end: {
          line: 306,
          column: 27
        }
      },
      '147': {
        start: {
          line: 307,
          column: 7
        },
        end: {
          line: 307,
          column: 23
        }
      },
      '148': {
        start: {
          line: 310,
          column: 3
        },
        end: {
          line: 312,
          column: 5
        }
      },
      '149': {
        start: {
          line: 311,
          column: 5
        },
        end: {
          line: 311,
          column: 41
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 47
          },
          end: {
            line: 2,
            column: 48
          }
        },
        loc: {
          start: {
            line: 2,
            column: 172
          },
          end: {
            line: 285,
            column: 2
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 21,
            column: 23
          },
          end: {
            line: 21,
            column: 24
          }
        },
        loc: {
          start: {
            line: 21,
            column: 29
          },
          end: {
            line: 27,
            column: 4
          }
        },
        line: 21
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 30,
            column: 19
          },
          end: {
            line: 30,
            column: 20
          }
        },
        loc: {
          start: {
            line: 30,
            column: 31
          },
          end: {
            line: 50,
            column: 4
          }
        },
        line: 30
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 32,
            column: 52
          },
          end: {
            line: 32,
            column: 53
          }
        },
        loc: {
          start: {
            line: 32,
            column: 60
          },
          end: {
            line: 49,
            column: 6
          }
        },
        line: 32
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 35,
            column: 43
          },
          end: {
            line: 35,
            column: 44
          }
        },
        loc: {
          start: {
            line: 35,
            column: 52
          },
          end: {
            line: 37,
            column: 10
          }
        },
        line: 35
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 52,
            column: 22
          },
          end: {
            line: 52,
            column: 23
          }
        },
        loc: {
          start: {
            line: 52,
            column: 34
          },
          end: {
            line: 56,
            column: 4
          }
        },
        line: 52
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 53,
            column: 39
          },
          end: {
            line: 53,
            column: 40
          }
        },
        loc: {
          start: {
            line: 53,
            column: 45
          },
          end: {
            line: 55,
            column: 6
          }
        },
        line: 53
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 87,
            column: 21
          },
          end: {
            line: 87,
            column: 22
          }
        },
        loc: {
          start: {
            line: 87,
            column: 33
          },
          end: {
            line: 89,
            column: 4
          }
        },
        line: 87
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 91,
            column: 19
          },
          end: {
            line: 91,
            column: 20
          }
        },
        loc: {
          start: {
            line: 91,
            column: 31
          },
          end: {
            line: 99,
            column: 4
          }
        },
        line: 91
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 100,
            column: 20
          },
          end: {
            line: 100,
            column: 21
          }
        },
        loc: {
          start: {
            line: 100,
            column: 36
          },
          end: {
            line: 114,
            column: 4
          }
        },
        line: 100
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 115,
            column: 22
          },
          end: {
            line: 115,
            column: 23
          }
        },
        loc: {
          start: {
            line: 115,
            column: 34
          },
          end: {
            line: 119,
            column: 4
          }
        },
        line: 115
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 120,
            column: 23
          },
          end: {
            line: 120,
            column: 24
          }
        },
        loc: {
          start: {
            line: 120,
            column: 39
          },
          end: {
            line: 147,
            column: 4
          }
        },
        line: 120
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 149,
            column: 29
          },
          end: {
            line: 149,
            column: 30
          }
        },
        loc: {
          start: {
            line: 149,
            column: 41
          },
          end: {
            line: 154,
            column: 4
          }
        },
        line: 149
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 156,
            column: 26
          },
          end: {
            line: 156,
            column: 27
          }
        },
        loc: {
          start: {
            line: 156,
            column: 42
          },
          end: {
            line: 199,
            column: 4
          }
        },
        line: 156
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 168,
            column: 8
          },
          end: {
            line: 168,
            column: 9
          }
        },
        loc: {
          start: {
            line: 168,
            column: 21
          },
          end: {
            line: 198,
            column: 6
          }
        },
        line: 168
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 185,
            column: 96
          },
          end: {
            line: 185,
            column: 97
          }
        },
        loc: {
          start: {
            line: 185,
            column: 104
          },
          end: {
            line: 197,
            column: 8
          }
        },
        line: 185
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 221,
            column: 10
          },
          end: {
            line: 221,
            column: 11
          }
        },
        loc: {
          start: {
            line: 221,
            column: 23
          },
          end: {
            line: 245,
            column: 8
          }
        },
        line: 221
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 233,
            column: 98
          },
          end: {
            line: 233,
            column: 99
          }
        },
        loc: {
          start: {
            line: 233,
            column: 106
          },
          end: {
            line: 244,
            column: 10
          }
        },
        line: 233
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 275,
            column: 100
          },
          end: {
            line: 275,
            column: 101
          }
        },
        loc: {
          start: {
            line: 275,
            column: 108
          },
          end: {
            line: 280,
            column: 8
          }
        },
        line: 275
      },
      '19': {
        name: 'UnitsCtrl_edit',
        decl: {
          start: {
            line: 287,
            column: 10
          },
          end: {
            line: 287,
            column: 24
          }
        },
        loc: {
          start: {
            line: 287,
            column: 75
          },
          end: {
            line: 313,
            column: 2
          }
        },
        line: 287
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 289,
            column: 25
          },
          end: {
            line: 289,
            column: 26
          }
        },
        loc: {
          start: {
            line: 289,
            column: 37
          },
          end: {
            line: 309,
            column: 4
          }
        },
        line: 289
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 305,
            column: 98
          },
          end: {
            line: 305,
            column: 99
          }
        },
        loc: {
          start: {
            line: 305,
            column: 104
          },
          end: {
            line: 308,
            column: 6
          }
        },
        line: 305
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 310,
            column: 23
          },
          end: {
            line: 310,
            column: 24
          }
        },
        loc: {
          start: {
            line: 310,
            column: 35
          },
          end: {
            line: 312,
            column: 4
          }
        },
        line: 310
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 4,
            column: 3
          },
          end: {
            line: 6,
            column: 4
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 4,
            column: 3
          },
          end: {
            line: 6,
            column: 4
          }
        }, {
          start: {
            line: 4,
            column: 3
          },
          end: {
            line: 6,
            column: 4
          }
        }],
        line: 4
      },
      '1': {
        loc: {
          start: {
            line: 38,
            column: 9
          },
          end: {
            line: 43,
            column: 10
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 38,
            column: 9
          },
          end: {
            line: 43,
            column: 10
          }
        }, {
          start: {
            line: 38,
            column: 9
          },
          end: {
            line: 43,
            column: 10
          }
        }],
        line: 38
      },
      '2': {
        loc: {
          start: {
            line: 40,
            column: 11
          },
          end: {
            line: 42,
            column: 12
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 11
          },
          end: {
            line: 42,
            column: 12
          }
        }, {
          start: {
            line: 40,
            column: 11
          },
          end: {
            line: 42,
            column: 12
          }
        }],
        line: 40
      },
      '3': {
        loc: {
          start: {
            line: 59,
            column: 15
          },
          end: {
            line: 59,
            column: 62
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 59,
            column: 43
          },
          end: {
            line: 59,
            column: 50
          }
        }, {
          start: {
            line: 59,
            column: 53
          },
          end: {
            line: 59,
            column: 62
          }
        }],
        line: 59
      },
      '4': {
        loc: {
          start: {
            line: 67,
            column: 29
          },
          end: {
            line: 67,
            column: 142
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 67,
            column: 57
          },
          end: {
            line: 67,
            column: 137
          }
        }, {
          start: {
            line: 67,
            column: 140
          },
          end: {
            line: 67,
            column: 142
          }
        }],
        line: 67
      },
      '5': {
        loc: {
          start: {
            line: 68,
            column: 19
          },
          end: {
            line: 68,
            column: 135
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 68,
            column: 47
          },
          end: {
            line: 68,
            column: 130
          }
        }, {
          start: {
            line: 68,
            column: 133
          },
          end: {
            line: 68,
            column: 135
          }
        }],
        line: 68
      },
      '6': {
        loc: {
          start: {
            line: 72,
            column: 7
          },
          end: {
            line: 75,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 72,
            column: 7
          },
          end: {
            line: 75,
            column: 8
          }
        }, {
          start: {
            line: 72,
            column: 7
          },
          end: {
            line: 75,
            column: 8
          }
        }],
        line: 72
      },
      '7': {
        loc: {
          start: {
            line: 76,
            column: 7
          },
          end: {
            line: 79,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 76,
            column: 7
          },
          end: {
            line: 79,
            column: 8
          }
        }, {
          start: {
            line: 76,
            column: 7
          },
          end: {
            line: 79,
            column: 8
          }
        }],
        line: 76
      },
      '8': {
        loc: {
          start: {
            line: 80,
            column: 7
          },
          end: {
            line: 83,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 80,
            column: 7
          },
          end: {
            line: 83,
            column: 8
          }
        }, {
          start: {
            line: 80,
            column: 7
          },
          end: {
            line: 83,
            column: 8
          }
        }],
        line: 80
      },
      '9': {
        loc: {
          start: {
            line: 104,
            column: 44
          },
          end: {
            line: 104,
            column: 74
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 104,
            column: 44
          },
          end: {
            line: 104,
            column: 69
          }
        }, {
          start: {
            line: 104,
            column: 73
          },
          end: {
            line: 104,
            column: 74
          }
        }],
        line: 104
      },
      '10': {
        loc: {
          start: {
            line: 123,
            column: 5
          },
          end: {
            line: 145,
            column: 6
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 124,
            column: 7
          },
          end: {
            line: 127,
            column: 15
          }
        }, {
          start: {
            line: 128,
            column: 7
          },
          end: {
            line: 131,
            column: 15
          }
        }, {
          start: {
            line: 132,
            column: 7
          },
          end: {
            line: 135,
            column: 15
          }
        }, {
          start: {
            line: 136,
            column: 7
          },
          end: {
            line: 139,
            column: 15
          }
        }, {
          start: {
            line: 140,
            column: 7
          },
          end: {
            line: 143,
            column: 15
          }
        }, {
          start: {
            line: 144,
            column: 7
          },
          end: {
            line: 144,
            column: 15
          }
        }],
        line: 123
      },
      '11': {
        loc: {
          start: {
            line: 151,
            column: 5
          },
          end: {
            line: 153,
            column: 6
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 151,
            column: 5
          },
          end: {
            line: 153,
            column: 6
          }
        }, {
          start: {
            line: 151,
            column: 5
          },
          end: {
            line: 153,
            column: 6
          }
        }],
        line: 151
      },
      '12': {
        loc: {
          start: {
            line: 151,
            column: 9
          },
          end: {
            line: 151,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 151,
            column: 9
          },
          end: {
            line: 151,
            column: 31
          }
        }, {
          start: {
            line: 151,
            column: 35
          },
          end: {
            line: 151,
            column: 57
          }
        }],
        line: 151
      },
      '13': {
        loc: {
          start: {
            line: 169,
            column: 7
          },
          end: {
            line: 171,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 169,
            column: 7
          },
          end: {
            line: 171,
            column: 8
          }
        }, {
          start: {
            line: 169,
            column: 7
          },
          end: {
            line: 171,
            column: 8
          }
        }],
        line: 169
      },
      '14': {
        loc: {
          start: {
            line: 186,
            column: 9
          },
          end: {
            line: 196,
            column: 10
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 186,
            column: 9
          },
          end: {
            line: 196,
            column: 10
          }
        }, {
          start: {
            line: 186,
            column: 9
          },
          end: {
            line: 196,
            column: 10
          }
        }],
        line: 186
      },
      '15': {
        loc: {
          start: {
            line: 222,
            column: 9
          },
          end: {
            line: 224,
            column: 10
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 222,
            column: 9
          },
          end: {
            line: 224,
            column: 10
          }
        }, {
          start: {
            line: 222,
            column: 9
          },
          end: {
            line: 224,
            column: 10
          }
        }],
        line: 222
      },
      '16': {
        loc: {
          start: {
            line: 234,
            column: 11
          },
          end: {
            line: 243,
            column: 12
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 234,
            column: 11
          },
          end: {
            line: 243,
            column: 12
          }
        }, {
          start: {
            line: 234,
            column: 11
          },
          end: {
            line: 243,
            column: 12
          }
        }],
        line: 234
      },
      '17': {
        loc: {
          start: {
            line: 254,
            column: 46
          },
          end: {
            line: 254,
            column: 76
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 254,
            column: 46
          },
          end: {
            line: 254,
            column: 71
          }
        }, {
          start: {
            line: 254,
            column: 75
          },
          end: {
            line: 254,
            column: 76
          }
        }],
        line: 254
      },
      '18': {
        loc: {
          start: {
            line: 261,
            column: 7
          },
          end: {
            line: 264,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 261,
            column: 7
          },
          end: {
            line: 264,
            column: 8
          }
        }, {
          start: {
            line: 261,
            column: 7
          },
          end: {
            line: 264,
            column: 8
          }
        }],
        line: 261
      },
      '19': {
        loc: {
          start: {
            line: 261,
            column: 12
          },
          end: {
            line: 261,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 261,
            column: 12
          },
          end: {
            line: 261,
            column: 35
          }
        }, {
          start: {
            line: 261,
            column: 39
          },
          end: {
            line: 261,
            column: 41
          }
        }],
        line: 261
      },
      '20': {
        loc: {
          start: {
            line: 268,
            column: 9
          },
          end: {
            line: 271,
            column: 10
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 268,
            column: 9
          },
          end: {
            line: 271,
            column: 10
          }
        }, {
          start: {
            line: 268,
            column: 9
          },
          end: {
            line: 271,
            column: 10
          }
        }],
        line: 268
      },
      '21': {
        loc: {
          start: {
            line: 268,
            column: 13
          },
          end: {
            line: 268,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 268,
            column: 13
          },
          end: {
            line: 268,
            column: 17
          }
        }, {
          start: {
            line: 268,
            column: 21
          },
          end: {
            line: 268,
            column: 47
          }
        }],
        line: 268
      },
      '22': {
        loc: {
          start: {
            line: 292,
            column: 5
          },
          end: {
            line: 295,
            column: 6
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 292,
            column: 5
          },
          end: {
            line: 295,
            column: 6
          }
        }, {
          start: {
            line: 292,
            column: 5
          },
          end: {
            line: 295,
            column: 6
          }
        }],
        line: 292
      },
      '23': {
        loc: {
          start: {
            line: 292,
            column: 10
          },
          end: {
            line: 292,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 292,
            column: 10
          },
          end: {
            line: 292,
            column: 33
          }
        }, {
          start: {
            line: 292,
            column: 37
          },
          end: {
            line: 292,
            column: 39
          }
        }],
        line: 292
      },
      '24': {
        loc: {
          start: {
            line: 299,
            column: 7
          },
          end: {
            line: 302,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 299,
            column: 7
          },
          end: {
            line: 302,
            column: 8
          }
        }, {
          start: {
            line: 299,
            column: 7
          },
          end: {
            line: 302,
            column: 8
          }
        }],
        line: 299
      },
      '25': {
        loc: {
          start: {
            line: 299,
            column: 11
          },
          end: {
            line: 299,
            column: 45
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 299,
            column: 11
          },
          end: {
            line: 299,
            column: 15
          }
        }, {
          start: {
            line: 299,
            column: 19
          },
          end: {
            line: 299,
            column: 45
          }
        }],
        line: 299
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0, 0, 0, 0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_kqyxplg8h.s[0]++;

angular.module('app').controller('UnitsCtrl', function ($scope, SweetAlert, $uibModal, $translate, $CRUDService, $otherHttpService, $location, $filter, $rootScope, $deltaUnite) {
  cov_kqyxplg8h.f[0]++;
  cov_kqyxplg8h.s[1]++;

  $rootScope.processPageRight('1_2');
  cov_kqyxplg8h.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_kqyxplg8h.b[0][0]++;
    cov_kqyxplg8h.s[3]++;

    return;
  } else {
    cov_kqyxplg8h.b[0][1]++;
  }
  cov_kqyxplg8h.s[4]++;
  $scope.add = false;
  cov_kqyxplg8h.s[5]++;
  $scope.edit = false;
  cov_kqyxplg8h.s[6]++;
  $scope.btnAction = false;
  cov_kqyxplg8h.s[7]++;
  $scope.listUnits = [];
  cov_kqyxplg8h.s[8]++;
  $scope.PATH = 'Unite';
  cov_kqyxplg8h.s[9]++;
  $scope.TypeUnite = true;
  cov_kqyxplg8h.s[10]++;
  $scope.idTypeUnit = '1';
  var project = (cov_kqyxplg8h.s[11]++, $rootScope.getCurrentProject());
  cov_kqyxplg8h.s[12]++;
  $scope.TypeUnit = [{ text: $translate.instant('FN_UNIT.CB_N'), id: 1 }, { text: '%', id: 2 }, { text: $translate.instant('FN_UNIT.CB_T'), id: 3 }];

  cov_kqyxplg8h.s[13]++;
  $scope.printData = function () {
    cov_kqyxplg8h.f[1]++;
    cov_kqyxplg8h.s[14]++;

    $rootScope.printReport({
      link: $scope.PATH,
      params: { get: 'print' },
      title: $translate.instant('MENU_SHORT.UN')
    });
  };
  cov_kqyxplg8h.s[15]++;
  $scope.page = 1;

  cov_kqyxplg8h.s[16]++;
  $scope.getAll = function () {
    cov_kqyxplg8h.f[2]++;
    cov_kqyxplg8h.s[17]++;

    $scope.isloading = true;
    cov_kqyxplg8h.s[18]++;
    $CRUDService.getAll($scope.PATH, { get: 'all' }, function (data) {
      cov_kqyxplg8h.f[3]++;
      cov_kqyxplg8h.s[19]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        var _loop = function _loop() {
          var item = _step.value;
          cov_kqyxplg8h.s[20]++;

          item.FORMAT_UNITE_F = item.FORMAT_UNITE;
          var type = (cov_kqyxplg8h.s[21]++, $scope.TypeUnit.find(function (type_) {
            cov_kqyxplg8h.f[4]++;
            cov_kqyxplg8h.s[22]++;

            return type_.id == item.TYPE_UNITES;
          }));
          cov_kqyxplg8h.s[23]++;
          if (type) {
            cov_kqyxplg8h.b[1][0]++;
            cov_kqyxplg8h.s[24]++;

            item.TYPE_UNITES_F = type.text;
            cov_kqyxplg8h.s[25]++;
            if (type.id == 3) {
              cov_kqyxplg8h.b[2][0]++;
              cov_kqyxplg8h.s[26]++;

              item.FORMAT_UNITE_F = null;
            } else {
              cov_kqyxplg8h.b[2][1]++;
            }
          } else {
            cov_kqyxplg8h.b[1][1]++;
          }
        };

        for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          _loop();
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_kqyxplg8h.s[27]++;
      $scope.listUnits = data;
      cov_kqyxplg8h.s[28]++;
      $scope.isloading = false;
      cov_kqyxplg8h.s[29]++;
      $deltaUnite.clearData();
    });
  };

  cov_kqyxplg8h.s[30]++;
  $scope.initModal = function () {
    cov_kqyxplg8h.f[5]++;
    cov_kqyxplg8h.s[31]++;

    $deltaUnite.addController($scope, function () {
      cov_kqyxplg8h.f[6]++;
      cov_kqyxplg8h.s[32]++;

      $scope.listUnits = $scope.listData_unites_bksb;
    });
  };

  cov_kqyxplg8h.s[33]++;
  $scope.params = {
    toolbar: $rootScope.currentRight.u ? (cov_kqyxplg8h.b[3][0]++, ['Add']) : (cov_kqyxplg8h.b[3][1]++, undefined),
    editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },
    columns: [{ field: 'CODE_UNITE', width: 150, headerText: $translate.instant('FN_UNIT.COD').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'LIBELLE_UNITES', headerText: $translate.instant('FN_UNIT.LAB').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'TYPE_UNITES_F', width: 150, headerText: $translate.instant('FN_UNIT.TYP').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'FORMAT_UNITE_F', width: 150, headerText: $translate.instant('FN_UNIT.FOR').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { headerText: '', width: 120,
      commands: [].concat($rootScope.currentRight.u ? (cov_kqyxplg8h.b[4][0]++, [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } }]) : (cov_kqyxplg8h.b[4][1]++, [])).concat($rootScope.currentRight.d ? (cov_kqyxplg8h.b[5][0]++, [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }]) : (cov_kqyxplg8h.b[5][1]++, []))
    }],
    actionBegin: function actionBegin(action) {
      cov_kqyxplg8h.s[34]++;

      if (action.requestType === 'add') {
        cov_kqyxplg8h.b[6][0]++;
        cov_kqyxplg8h.s[35]++;

        action.cancel = true;
        cov_kqyxplg8h.s[36]++;
        $scope.addnew();
      } else {
        cov_kqyxplg8h.b[6][1]++;
      }
      cov_kqyxplg8h.s[37]++;
      if (action.requestType === 'beginEdit') {
        cov_kqyxplg8h.b[7][0]++;
        cov_kqyxplg8h.s[38]++;

        action.cancel = true;
        cov_kqyxplg8h.s[39]++;
        $scope.setEdit(action.rowData);
      } else {
        cov_kqyxplg8h.b[7][1]++;
      }
      cov_kqyxplg8h.s[40]++;
      if (action.requestType === 'delete') {
        cov_kqyxplg8h.b[8][0]++;
        cov_kqyxplg8h.s[41]++;

        action.cancel = true;
        cov_kqyxplg8h.s[42]++;
        $scope.setDeleteData(action.data[0]);
      } else {
        cov_kqyxplg8h.b[8][1]++;
      }
    }
  };

  cov_kqyxplg8h.s[43]++;
  $scope.initPage = function () {
    cov_kqyxplg8h.f[7]++;
    cov_kqyxplg8h.s[44]++;

    $scope.getAll();
  };

  cov_kqyxplg8h.s[45]++;
  $scope.addnew = function () {
    cov_kqyxplg8h.f[8]++;
    cov_kqyxplg8h.s[46]++;

    $scope.setEdit({
      CODE_UNITE: '',
      LIBELLE_UNITES: '',
      TYPE_UNITES: 1,
      FORMAT_UNITE: 0,
      id: 0
    });
  };
  cov_kqyxplg8h.s[47]++;
  $scope.setEdit = function (item) {
    cov_kqyxplg8h.f[9]++;
    cov_kqyxplg8h.s[48]++;

    $scope.error_code = false;
    var validateItem = (cov_kqyxplg8h.s[49]++, angular.copy(item));
    cov_kqyxplg8h.s[50]++;
    validateItem.TYPE_UNITES = $filter('filter')($scope.TypeUnit, { id: validateItem.TYPE_UNITES })[0];
    cov_kqyxplg8h.s[51]++;
    validateItem.FORMAT_UNITE = parseFloat((cov_kqyxplg8h.b[9][0]++, validateItem.FORMAT_UNITE) || (cov_kqyxplg8h.b[9][1]++, 0));
    cov_kqyxplg8h.s[52]++;
    $scope.validateItem = validateItem;
    cov_kqyxplg8h.s[53]++;
    $uibModal.open({
      templateUrl: 'app/views/Units/edit.html',
      controller: UnitsCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };
  cov_kqyxplg8h.s[54]++;
  $scope.setCancel = function () {
    cov_kqyxplg8h.f[10]++;
    cov_kqyxplg8h.s[55]++;

    $scope.add = false;
    cov_kqyxplg8h.s[56]++;
    $scope.edit = false;
    cov_kqyxplg8h.s[57]++;
    $scope.btnAction = false;
  };
  cov_kqyxplg8h.s[58]++;
  $scope.selectUnit = function (Unit) {
    cov_kqyxplg8h.f[11]++;
    cov_kqyxplg8h.s[59]++;

    $scope.btnAction = true;
    cov_kqyxplg8h.s[60]++;
    $scope.idTypeUnit = '1';
    cov_kqyxplg8h.s[61]++;
    switch (Unit.TYPE_UNITES) {
      case $translate.instant('FN_UNIT.CB_N'):
        cov_kqyxplg8h.b[10][0]++;
        cov_kqyxplg8h.s[62]++;

        $scope.idTypeUnit = '1';
        cov_kqyxplg8h.s[63]++;
        $scope.TypeUnite = true; // "Numérique";
        cov_kqyxplg8h.s[64]++;
        break;
      case '%':
        cov_kqyxplg8h.b[10][1]++;
        cov_kqyxplg8h.s[65]++;

        $scope.idTypeUnit = '2';
        cov_kqyxplg8h.s[66]++;
        $scope.TypeUnite = true;
        cov_kqyxplg8h.s[67]++;
        break;
      case $translate.instant('FN_UNIT.CB_T'):
        cov_kqyxplg8h.b[10][2]++;
        cov_kqyxplg8h.s[68]++;

        $scope.idTypeUnit = '3';
        cov_kqyxplg8h.s[69]++;
        $scope.TypeUnite = false; // "Texte";
        cov_kqyxplg8h.s[70]++;
        break;
      case $translate.instant('FN_UNIT.CB_B'):
        cov_kqyxplg8h.b[10][3]++;
        cov_kqyxplg8h.s[71]++;

        $scope.idTypeUnit = '4';
        cov_kqyxplg8h.s[72]++;
        $scope.TypeUnite = false; // "Booléen";
        cov_kqyxplg8h.s[73]++;
        break;
      case 'Date':
        cov_kqyxplg8h.b[10][4]++;
        cov_kqyxplg8h.s[74]++;

        $scope.idTypeUnit = '5';
        cov_kqyxplg8h.s[75]++;
        $scope.TypeUnite = false;
        cov_kqyxplg8h.s[76]++;
        break;
      default:
        cov_kqyxplg8h.b[10][5]++;

    }
    cov_kqyxplg8h.s[77]++;
    $scope.validateItem = Unit;
  };

  cov_kqyxplg8h.s[78]++;
  $scope.changedValueType = function () {
    cov_kqyxplg8h.f[12]++;
    cov_kqyxplg8h.s[79]++;

    $scope.TypeUnite = false;
    cov_kqyxplg8h.s[80]++;
    if ((cov_kqyxplg8h.b[12][0]++, $scope.idTypeUnit == 1) || (cov_kqyxplg8h.b[12][1]++, $scope.idTypeUnit == 2)) {
      cov_kqyxplg8h.b[11][0]++;
      cov_kqyxplg8h.s[81]++;

      $scope.TypeUnite = true;
    } else {
      cov_kqyxplg8h.b[11][1]++;
    }
  };

  cov_kqyxplg8h.s[82]++;
  $scope.setDeleteData = function (item) {
    cov_kqyxplg8h.f[13]++;
    cov_kqyxplg8h.s[83]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_kqyxplg8h.f[14]++;
      cov_kqyxplg8h.s[84]++;

      if (isconfirm) {
        cov_kqyxplg8h.b[13][0]++;
        cov_kqyxplg8h.s[85]++;

        return;
      } else {
        cov_kqyxplg8h.b[13][1]++;
      }
      cov_kqyxplg8h.s[86]++;
      $scope.isloading = true;
      var validateItem = (cov_kqyxplg8h.s[87]++, {
        CODE_UNITE: item.CODE_UNITE,
        LIBELLE_UNITES: item.LIBELLE_UNITES,
        TYPE_UNITES: $scope.idTypeUnit,
        FORMAT_UNITE: item.FORMAT_UNITE,
        id: item.id
      });
      /* SweetAlert.swal({
        title: $translate.instant('COMMON.ERROR_DELETE'),
        text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
        type: 'warning'
      }); */
      cov_kqyxplg8h.s[88]++;
      $CRUDService.delet($scope.PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_kqyxplg8h.f[15]++;
        cov_kqyxplg8h.s[89]++;

        if (data < 0) {
          cov_kqyxplg8h.b[14][0]++;
          cov_kqyxplg8h.s[90]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
            type: 'warning'
          });
          cov_kqyxplg8h.s[91]++;
          $scope.isloading = false;
        } else {
          cov_kqyxplg8h.b[14][1]++;
          cov_kqyxplg8h.s[92]++;

          $scope.setCancel();
          cov_kqyxplg8h.s[93]++;
          $scope.getAll();
        }
      });
    });
  };

  cov_kqyxplg8h.s[94]++;
  $scope.modal = {
    addnew: function addnew() {
      cov_kqyxplg8h.s[95]++;

      this.editItem({
        CODE_UNITE: '',
        LIBELLE_UNITES: '',
        TYPE_UNITES: 1,
        FORMAT_UNITE: 0,
        id: 0
      });
    },
    deleteItem: function deleteItem(item) {
      cov_kqyxplg8h.s[96]++;

      SweetAlert.swal({
        title: $translate.instant('COMMON.CONFIRM'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c8c8c8',
        confirmButtonText: $translate.instant('COMMON.NO'),
        cancelButtonText: $translate.instant('COMMON.YES'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isConfirm) {
        cov_kqyxplg8h.f[16]++;
        cov_kqyxplg8h.s[97]++;

        if (isConfirm) {
          cov_kqyxplg8h.b[15][0]++;
          cov_kqyxplg8h.s[98]++;

          return;
        } else {
          cov_kqyxplg8h.b[15][1]++;
        }
        cov_kqyxplg8h.s[99]++;
        $scope.isloading = true;
        var validateItem = (cov_kqyxplg8h.s[100]++, {
          CODE_UNITE: item.CODE_UNITE,
          LIBELLE_UNITES: item.LIBELLE_UNITES,
          TYPE_UNITES: $scope.idTypeUnit,
          FORMAT_UNITE: item.FORMAT_UNITE,
          id: item.id
        });
        cov_kqyxplg8h.s[101]++;
        $CRUDService.delet($scope.PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
          cov_kqyxplg8h.f[17]++;
          cov_kqyxplg8h.s[102]++;

          if (data < 0) {
            cov_kqyxplg8h.b[16][0]++;
            cov_kqyxplg8h.s[103]++;

            SweetAlert.swal({
              title: $translate.instant('COMMON.ERROR_DELETE'),
              text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
              type: 'warning'
            });
            cov_kqyxplg8h.s[104]++;
            $scope.isloading = false;
          } else {
            cov_kqyxplg8h.b[16][1]++;
            cov_kqyxplg8h.s[105]++;

            $deltaUnite.removeData(validateItem);
          }
        });
      });
    },
    cancel: function cancel() {
      cov_kqyxplg8h.s[106]++;

      $scope.page = 1;
    },
    editItem: function editItem(item) {
      cov_kqyxplg8h.s[107]++;

      this.error_code = false;
      var validateItem = (cov_kqyxplg8h.s[108]++, angular.copy(item));
      cov_kqyxplg8h.s[109]++;
      validateItem.TYPE_UNITES = $filter('filter')($scope.TypeUnit, { id: validateItem.TYPE_UNITES })[0];
      cov_kqyxplg8h.s[110]++;
      validateItem.FORMAT_UNITE = parseFloat((cov_kqyxplg8h.b[17][0]++, validateItem.FORMAT_UNITE) || (cov_kqyxplg8h.b[17][1]++, 0));
      cov_kqyxplg8h.s[111]++;
      this.validateItem = validateItem;
      cov_kqyxplg8h.s[112]++;
      $scope.page = 2;
    },
    setValidData: function setValidData() {
      var validateItem = (cov_kqyxplg8h.s[113]++, angular.copy(this.validateItem));
      cov_kqyxplg8h.s[114]++;
      $scope.error_code = 0;
      cov_kqyxplg8h.s[115]++;
      if (((cov_kqyxplg8h.b[19][0]++, validateItem.CODE_UNITE) || (cov_kqyxplg8h.b[19][1]++, '')).trim().length == 0) {
        cov_kqyxplg8h.b[18][0]++;
        cov_kqyxplg8h.s[116]++;

        $scope.error_code = 1;
        cov_kqyxplg8h.s[117]++;
        return;
      } else {
        cov_kqyxplg8h.b[18][1]++;
      }
      cov_kqyxplg8h.s[118]++;
      validateItem.CODE_UNITE = validateItem.CODE_UNITE.trim();
      var items = (cov_kqyxplg8h.s[119]++, $filter('filter')($scope.listUnits, { CODE_UNITE: validateItem.CODE_UNITE }, true));
      cov_kqyxplg8h.s[120]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = items[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _item = _step2.value;
          cov_kqyxplg8h.s[121]++;

          if ((cov_kqyxplg8h.b[21][0]++, _item) && (cov_kqyxplg8h.b[21][1]++, _item.id != validateItem.id)) {
            cov_kqyxplg8h.b[20][0]++;
            cov_kqyxplg8h.s[122]++;

            $scope.error_code = 2;
            cov_kqyxplg8h.s[123]++;
            return;
          } else {
            cov_kqyxplg8h.b[20][1]++;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      cov_kqyxplg8h.s[124]++;
      validateItem.TYPE_UNITES = validateItem.TYPE_UNITES.id;
      var oldData = (cov_kqyxplg8h.s[125]++, validateItem.id > 0);
      cov_kqyxplg8h.s[126]++;
      $CRUDService.save($scope.PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function (data) {
        cov_kqyxplg8h.f[18]++;
        cov_kqyxplg8h.s[127]++;

        validateItem.id = parseInt(data, 10);
        cov_kqyxplg8h.s[128]++;
        $deltaUnite.addData(validateItem, 0, oldData);
        cov_kqyxplg8h.s[129]++;
        $scope.page = 1;
        // $scope.getAll();
      });
    }
  };

  cov_kqyxplg8h.s[130]++;
  $scope.getAll();
});

function UnitsCtrl_edit($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_kqyxplg8h.f[19]++;
  cov_kqyxplg8h.s[131]++;

  $scope.error_code = false;
  cov_kqyxplg8h.s[132]++;
  $scope.setValidData = function () {
    cov_kqyxplg8h.f[20]++;

    var validateItem = (cov_kqyxplg8h.s[133]++, angular.copy($scope.validateItem));
    cov_kqyxplg8h.s[134]++;
    $scope.error_code = 0;
    cov_kqyxplg8h.s[135]++;
    if (((cov_kqyxplg8h.b[23][0]++, validateItem.CODE_UNITE) || (cov_kqyxplg8h.b[23][1]++, '')).trim().length == 0) {
      cov_kqyxplg8h.b[22][0]++;
      cov_kqyxplg8h.s[136]++;

      $scope.error_code = 1;
      cov_kqyxplg8h.s[137]++;
      return;
    } else {
      cov_kqyxplg8h.b[22][1]++;
    }
    cov_kqyxplg8h.s[138]++;
    validateItem.CODE_UNITE = validateItem.CODE_UNITE.trim();
    var items = (cov_kqyxplg8h.s[139]++, $filter('filter')($scope.listUnits, { CODE_UNITE: validateItem.CODE_UNITE }, true));
    cov_kqyxplg8h.s[140]++;
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = items[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var _item2 = _step3.value;
        cov_kqyxplg8h.s[141]++;

        if ((cov_kqyxplg8h.b[25][0]++, _item2) && (cov_kqyxplg8h.b[25][1]++, _item2.id != validateItem.id)) {
          cov_kqyxplg8h.b[24][0]++;
          cov_kqyxplg8h.s[142]++;

          $scope.error_code = 2;
          cov_kqyxplg8h.s[143]++;
          return;
        } else {
          cov_kqyxplg8h.b[24][1]++;
        }
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    cov_kqyxplg8h.s[144]++;
    validateItem.TYPE_UNITES = validateItem.TYPE_UNITES.id;
    cov_kqyxplg8h.s[145]++;
    $CRUDService.save($scope.PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function () {
      cov_kqyxplg8h.f[21]++;
      cov_kqyxplg8h.s[146]++;

      $scope.closeModal();
      cov_kqyxplg8h.s[147]++;
      $scope.getAll();
    });
  };
  cov_kqyxplg8h.s[148]++;
  $scope.closeModal = function () {
    cov_kqyxplg8h.f[22]++;
    cov_kqyxplg8h.s[149]++;

    $uibModalInstance.dismiss('cancel');
  };
}