'use strict';

var cov_1w3cqx9h = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/indicator_dashboard/indicatorDashboardGlobalCtrl.js',
      hash = 'a28f41046e29b0a3a3f77bcc104a6ecac54a0177',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/indicator_dashboard/indicatorDashboardGlobalCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 339,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 26
        }
      },
      '2': {
        start: {
          line: 3,
          column: 25
        },
        end: {
          line: 3,
          column: 38
        }
      },
      '3': {
        start: {
          line: 4,
          column: 24
        },
        end: {
          line: 4,
          column: 35
        }
      },
      '4': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 8,
          column: 4
        }
      },
      '5': {
        start: {
          line: 10,
          column: 54
        },
        end: {
          line: 10,
          column: 139
        }
      },
      '6': {
        start: {
          line: 11,
          column: 55
        },
        end: {
          line: 11,
          column: 141
        }
      },
      '7': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 50
        }
      },
      '8': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 58
        }
      },
      '9': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 17,
          column: 28
        }
      },
      '10': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 18,
          column: 38
        }
      },
      '11': {
        start: {
          line: 20,
          column: 2
        },
        end: {
          line: 20,
          column: 25
        }
      },
      '12': {
        start: {
          line: 24,
          column: 25
        },
        end: {
          line: 41,
          column: 3
        }
      },
      '13': {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 40,
          column: 7
        }
      },
      '14': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 39,
          column: 7
        }
      },
      '15': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 38,
          column: 27
        }
      },
      '16': {
        start: {
          line: 43,
          column: 2
        },
        end: {
          line: 48,
          column: 4
        }
      },
      '17': {
        start: {
          line: 46,
          column: 4
        },
        end: {
          line: 46,
          column: 32
        }
      },
      '18': {
        start: {
          line: 47,
          column: 4
        },
        end: {
          line: 47,
          column: 34
        }
      },
      '19': {
        start: {
          line: 50,
          column: 2
        },
        end: {
          line: 58,
          column: 4
        }
      },
      '20': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 51,
          column: 35
        }
      },
      '21': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 52,
          column: 31
        }
      },
      '22': {
        start: {
          line: 53,
          column: 4
        },
        end: {
          line: 53,
          column: 36
        }
      },
      '23': {
        start: {
          line: 55,
          column: 4
        },
        end: {
          line: 55,
          column: 93
        }
      },
      '24': {
        start: {
          line: 57,
          column: 4
        },
        end: {
          line: 57,
          column: 32
        }
      },
      '25': {
        start: {
          line: 60,
          column: 2
        },
        end: {
          line: 64,
          column: 4
        }
      },
      '26': {
        start: {
          line: 61,
          column: 4
        },
        end: {
          line: 63,
          column: 7
        }
      },
      '27': {
        start: {
          line: 69,
          column: 2
        },
        end: {
          line: 164,
          column: 4
        }
      },
      '28': {
        start: {
          line: 70,
          column: 4
        },
        end: {
          line: 73,
          column: 5
        }
      },
      '29': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 71,
          column: 40
        }
      },
      '30': {
        start: {
          line: 72,
          column: 6
        },
        end: {
          line: 72,
          column: 13
        }
      },
      '31': {
        start: {
          line: 74,
          column: 4
        },
        end: {
          line: 163,
          column: 7
        }
      },
      '32': {
        start: {
          line: 79,
          column: 6
        },
        end: {
          line: 81,
          column: 9
        }
      },
      '33': {
        start: {
          line: 80,
          column: 8
        },
        end: {
          line: 80,
          column: 46
        }
      },
      '34': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 102,
          column: 9
        }
      },
      '35': {
        start: {
          line: 83,
          column: 8
        },
        end: {
          line: 83,
          column: 100
        }
      },
      '36': {
        start: {
          line: 85,
          column: 8
        },
        end: {
          line: 85,
          column: 100
        }
      },
      '37': {
        start: {
          line: 87,
          column: 8
        },
        end: {
          line: 87,
          column: 214
        }
      },
      '38': {
        start: {
          line: 89,
          column: 8
        },
        end: {
          line: 89,
          column: 214
        }
      },
      '39': {
        start: {
          line: 91,
          column: 8
        },
        end: {
          line: 99,
          column: 9
        }
      },
      '40': {
        start: {
          line: 92,
          column: 10
        },
        end: {
          line: 92,
          column: 66
        }
      },
      '41': {
        start: {
          line: 94,
          column: 22
        },
        end: {
          line: 94,
          column: 41
        }
      },
      '42': {
        start: {
          line: 95,
          column: 10
        },
        end: {
          line: 95,
          column: 31
        }
      },
      '43': {
        start: {
          line: 96,
          column: 10
        },
        end: {
          line: 98,
          column: 11
        }
      },
      '44': {
        start: {
          line: 97,
          column: 12
        },
        end: {
          line: 97,
          column: 88
        }
      },
      '45': {
        start: {
          line: 101,
          column: 8
        },
        end: {
          line: 101,
          column: 21
        }
      },
      '46': {
        start: {
          line: 103,
          column: 6
        },
        end: {
          line: 162,
          column: 8
        }
      },
      '47': {
        start: {
          line: 130,
          column: 25
        },
        end: {
          line: 130,
          column: 50
        }
      },
      '48': {
        start: {
          line: 131,
          column: 14
        },
        end: {
          line: 133,
          column: 15
        }
      },
      '49': {
        start: {
          line: 132,
          column: 16
        },
        end: {
          line: 132,
          column: 32
        }
      },
      '50': {
        start: {
          line: 134,
          column: 14
        },
        end: {
          line: 134,
          column: 24
        }
      },
      '51': {
        start: {
          line: 141,
          column: 25
        },
        end: {
          line: 141,
          column: 50
        }
      },
      '52': {
        start: {
          line: 142,
          column: 14
        },
        end: {
          line: 144,
          column: 15
        }
      },
      '53': {
        start: {
          line: 143,
          column: 16
        },
        end: {
          line: 143,
          column: 32
        }
      },
      '54': {
        start: {
          line: 145,
          column: 14
        },
        end: {
          line: 145,
          column: 24
        }
      },
      '55': {
        start: {
          line: 165,
          column: 30
        },
        end: {
          line: 233,
          column: 3
        }
      },
      '56': {
        start: {
          line: 166,
          column: 4
        },
        end: {
          line: 232,
          column: 6
        }
      },
      '57': {
        start: {
          line: 195,
          column: 23
        },
        end: {
          line: 195,
          column: 66
        }
      },
      '58': {
        start: {
          line: 196,
          column: 12
        },
        end: {
          line: 198,
          column: 13
        }
      },
      '59': {
        start: {
          line: 197,
          column: 14
        },
        end: {
          line: 197,
          column: 30
        }
      },
      '60': {
        start: {
          line: 199,
          column: 12
        },
        end: {
          line: 199,
          column: 22
        }
      },
      '61': {
        start: {
          line: 209,
          column: 23
        },
        end: {
          line: 209,
          column: 66
        }
      },
      '62': {
        start: {
          line: 210,
          column: 12
        },
        end: {
          line: 212,
          column: 13
        }
      },
      '63': {
        start: {
          line: 211,
          column: 14
        },
        end: {
          line: 211,
          column: 30
        }
      },
      '64': {
        start: {
          line: 213,
          column: 12
        },
        end: {
          line: 213,
          column: 22
        }
      },
      '65': {
        start: {
          line: 224,
          column: 26
        },
        end: {
          line: 224,
          column: 65
        }
      },
      '66': {
        start: {
          line: 226,
          column: 8
        },
        end: {
          line: 229,
          column: 10
        }
      },
      '67': {
        start: {
          line: 230,
          column: 8
        },
        end: {
          line: 230,
          column: 38
        }
      },
      '68': {
        start: {
          line: 235,
          column: 2
        },
        end: {
          line: 277,
          column: 4
        }
      },
      '69': {
        start: {
          line: 236,
          column: 4
        },
        end: {
          line: 276,
          column: 7
        }
      },
      '70': {
        start: {
          line: 241,
          column: 24
        },
        end: {
          line: 241,
          column: 26
        }
      },
      '71': {
        start: {
          line: 242,
          column: 6
        },
        end: {
          line: 270,
          column: 7
        }
      },
      '72': {
        start: {
          line: 243,
          column: 22
        },
        end: {
          line: 243,
          column: 35
        }
      },
      '73': {
        start: {
          line: 244,
          column: 8
        },
        end: {
          line: 244,
          column: 29
        }
      },
      '74': {
        start: {
          line: 246,
          column: 8
        },
        end: {
          line: 246,
          column: 100
        }
      },
      '75': {
        start: {
          line: 248,
          column: 8
        },
        end: {
          line: 248,
          column: 100
        }
      },
      '76': {
        start: {
          line: 250,
          column: 8
        },
        end: {
          line: 250,
          column: 100
        }
      },
      '77': {
        start: {
          line: 252,
          column: 8
        },
        end: {
          line: 252,
          column: 100
        }
      },
      '78': {
        start: {
          line: 254,
          column: 8
        },
        end: {
          line: 254,
          column: 214
        }
      },
      '79': {
        start: {
          line: 256,
          column: 8
        },
        end: {
          line: 256,
          column: 214
        }
      },
      '80': {
        start: {
          line: 258,
          column: 8
        },
        end: {
          line: 260,
          column: 9
        }
      },
      '81': {
        start: {
          line: 259,
          column: 10
        },
        end: {
          line: 259,
          column: 54
        }
      },
      '82': {
        start: {
          line: 261,
          column: 8
        },
        end: {
          line: 263,
          column: 9
        }
      },
      '83': {
        start: {
          line: 262,
          column: 10
        },
        end: {
          line: 262,
          column: 54
        }
      },
      '84': {
        start: {
          line: 264,
          column: 8
        },
        end: {
          line: 264,
          column: 30
        }
      },
      '85': {
        start: {
          line: 266,
          column: 8
        },
        end: {
          line: 269,
          column: 10
        }
      },
      '86': {
        start: {
          line: 272,
          column: 6
        },
        end: {
          line: 272,
          column: 36
        }
      },
      '87': {
        start: {
          line: 274,
          column: 6
        },
        end: {
          line: 274,
          column: 36
        }
      },
      '88': {
        start: {
          line: 279,
          column: 2
        },
        end: {
          line: 282,
          column: 5
        }
      },
      '89': {
        start: {
          line: 280,
          column: 23
        },
        end: {
          line: 280,
          column: 93
        }
      },
      '90': {
        start: {
          line: 281,
          column: 4
        },
        end: {
          line: 281,
          column: 134
        }
      },
      '91': {
        start: {
          line: 281,
          column: 71
        },
        end: {
          line: 281,
          column: 94
        }
      },
      '92': {
        start: {
          line: 284,
          column: 23
        },
        end: {
          line: 293,
          column: 3
        }
      },
      '93': {
        start: {
          line: 285,
          column: 4
        },
        end: {
          line: 287,
          column: 5
        }
      },
      '94': {
        start: {
          line: 286,
          column: 6
        },
        end: {
          line: 286,
          column: 42
        }
      },
      '95': {
        start: {
          line: 288,
          column: 4
        },
        end: {
          line: 290,
          column: 5
        }
      },
      '96': {
        start: {
          line: 289,
          column: 6
        },
        end: {
          line: 289,
          column: 38
        }
      },
      '97': {
        start: {
          line: 292,
          column: 4
        },
        end: {
          line: 292,
          column: 57
        }
      },
      '98': {
        start: {
          line: 297,
          column: 2
        },
        end: {
          line: 314,
          column: 4
        }
      },
      '99': {
        start: {
          line: 298,
          column: 4
        },
        end: {
          line: 298,
          column: 28
        }
      },
      '100': {
        start: {
          line: 299,
          column: 4
        },
        end: {
          line: 299,
          column: 49
        }
      },
      '101': {
        start: {
          line: 300,
          column: 4
        },
        end: {
          line: 300,
          column: 35
        }
      },
      '102': {
        start: {
          line: 301,
          column: 4
        },
        end: {
          line: 301,
          column: 31
        }
      },
      '103': {
        start: {
          line: 302,
          column: 14
        },
        end: {
          line: 302,
          column: 99
        }
      },
      '104': {
        start: {
          line: 302,
          column: 65
        },
        end: {
          line: 302,
          column: 98
        }
      },
      '105': {
        start: {
          line: 303,
          column: 4
        },
        end: {
          line: 308,
          column: 5
        }
      },
      '106': {
        start: {
          line: 304,
          column: 6
        },
        end: {
          line: 304,
          column: 33
        }
      },
      '107': {
        start: {
          line: 305,
          column: 6
        },
        end: {
          line: 307,
          column: 7
        }
      },
      '108': {
        start: {
          line: 306,
          column: 8
        },
        end: {
          line: 306,
          column: 48
        }
      },
      '109': {
        start: {
          line: 309,
          column: 4
        },
        end: {
          line: 309,
          column: 110
        }
      },
      '110': {
        start: {
          line: 311,
          column: 4
        },
        end: {
          line: 311,
          column: 93
        }
      },
      '111': {
        start: {
          line: 313,
          column: 4
        },
        end: {
          line: 313,
          column: 32
        }
      },
      '112': {
        start: {
          line: 317,
          column: 2
        },
        end: {
          line: 329,
          column: 3
        }
      },
      '113': {
        start: {
          line: 321,
          column: 6
        },
        end: {
          line: 327,
          column: 7
        }
      },
      '114': {
        start: {
          line: 322,
          column: 8
        },
        end: {
          line: 324,
          column: 11
        }
      },
      '115': {
        start: {
          line: 323,
          column: 10
        },
        end: {
          line: 323,
          column: 114
        }
      },
      '116': {
        start: {
          line: 326,
          column: 8
        },
        end: {
          line: 326,
          column: 57
        }
      },
      '117': {
        start: {
          line: 331,
          column: 2
        },
        end: {
          line: 338,
          column: 5
        }
      },
      '118': {
        start: {
          line: 332,
          column: 4
        },
        end: {
          line: 332,
          column: 33
        }
      },
      '119': {
        start: {
          line: 333,
          column: 4
        },
        end: {
          line: 333,
          column: 54
        }
      },
      '120': {
        start: {
          line: 335,
          column: 27
        },
        end: {
          line: 335,
          column: 93
        }
      },
      '121': {
        start: {
          line: 337,
          column: 4
        },
        end: {
          line: 337,
          column: 118
        }
      },
      '122': {
        start: {
          line: 337,
          column: 62
        },
        end: {
          line: 337,
          column: 86
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 74
          },
          end: {
            line: 1,
            column: 75
          }
        },
        loc: {
          start: {
            line: 1,
            column: 236
          },
          end: {
            line: 339,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 24,
            column: 25
          },
          end: {
            line: 24,
            column: 26
          }
        },
        loc: {
          start: {
            line: 24,
            column: 57
          },
          end: {
            line: 41,
            column: 3
          }
        },
        line: 24
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 36,
            column: 24
          },
          end: {
            line: 36,
            column: 25
          }
        },
        loc: {
          start: {
            line: 36,
            column: 32
          },
          end: {
            line: 40,
            column: 5
          }
        },
        line: 36
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 43,
            column: 30
          },
          end: {
            line: 43,
            column: 31
          }
        },
        loc: {
          start: {
            line: 43,
            column: 42
          },
          end: {
            line: 48,
            column: 3
          }
        },
        line: 43
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 50,
            column: 23
          },
          end: {
            line: 50,
            column: 24
          }
        },
        loc: {
          start: {
            line: 50,
            column: 40
          },
          end: {
            line: 58,
            column: 3
          }
        },
        line: 50
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 60,
            column: 33
          },
          end: {
            line: 60,
            column: 34
          }
        },
        loc: {
          start: {
            line: 60,
            column: 45
          },
          end: {
            line: 64,
            column: 3
          }
        },
        line: 60
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 61,
            column: 142
          },
          end: {
            line: 61,
            column: 143
          }
        },
        loc: {
          start: {
            line: 61,
            column: 150
          },
          end: {
            line: 63,
            column: 5
          }
        },
        line: 61
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 69,
            column: 32
          },
          end: {
            line: 69,
            column: 33
          }
        },
        loc: {
          start: {
            line: 69,
            column: 44
          },
          end: {
            line: 164,
            column: 3
          }
        },
        line: 69
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 78,
            column: 7
          },
          end: {
            line: 78,
            column: 8
          }
        },
        loc: {
          start: {
            line: 78,
            column: 15
          },
          end: {
            line: 163,
            column: 5
          }
        },
        line: 78
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 79,
            column: 23
          },
          end: {
            line: 79,
            column: 24
          }
        },
        loc: {
          start: {
            line: 79,
            column: 43
          },
          end: {
            line: 81,
            column: 7
          }
        },
        line: 79
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 82,
            column: 22
          },
          end: {
            line: 82,
            column: 23
          }
        },
        loc: {
          start: {
            line: 82,
            column: 31
          },
          end: {
            line: 102,
            column: 7
          }
        },
        line: 82
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 129,
            column: 33
          },
          end: {
            line: 129,
            column: 34
          }
        },
        loc: {
          start: {
            line: 129,
            column: 42
          },
          end: {
            line: 135,
            column: 13
          }
        },
        line: 129
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 140,
            column: 33
          },
          end: {
            line: 140,
            column: 34
          }
        },
        loc: {
          start: {
            line: 140,
            column: 42
          },
          end: {
            line: 146,
            column: 13
          }
        },
        line: 140
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 165,
            column: 30
          },
          end: {
            line: 165,
            column: 31
          }
        },
        loc: {
          start: {
            line: 165,
            column: 40
          },
          end: {
            line: 233,
            column: 3
          }
        },
        line: 165
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 194,
            column: 33
          },
          end: {
            line: 194,
            column: 34
          }
        },
        loc: {
          start: {
            line: 194,
            column: 42
          },
          end: {
            line: 200,
            column: 11
          }
        },
        line: 194
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 208,
            column: 33
          },
          end: {
            line: 208,
            column: 34
          }
        },
        loc: {
          start: {
            line: 208,
            column: 42
          },
          end: {
            line: 214,
            column: 11
          }
        },
        line: 208
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 235,
            column: 30
          },
          end: {
            line: 235,
            column: 31
          }
        },
        loc: {
          start: {
            line: 235,
            column: 42
          },
          end: {
            line: 277,
            column: 3
          }
        },
        line: 235
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 240,
            column: 7
          },
          end: {
            line: 240,
            column: 8
          }
        },
        loc: {
          start: {
            line: 240,
            column: 15
          },
          end: {
            line: 276,
            column: 5
          }
        },
        line: 240
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 279,
            column: 39
          },
          end: {
            line: 279,
            column: 40
          }
        },
        loc: {
          start: {
            line: 279,
            column: 45
          },
          end: {
            line: 282,
            column: 3
          }
        },
        line: 279
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 281,
            column: 61
          },
          end: {
            line: 281,
            column: 62
          }
        },
        loc: {
          start: {
            line: 281,
            column: 71
          },
          end: {
            line: 281,
            column: 94
          }
        },
        line: 281
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 284,
            column: 23
          },
          end: {
            line: 284,
            column: 24
          }
        },
        loc: {
          start: {
            line: 284,
            column: 47
          },
          end: {
            line: 293,
            column: 3
          }
        },
        line: 284
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 297,
            column: 27
          },
          end: {
            line: 297,
            column: 28
          }
        },
        loc: {
          start: {
            line: 297,
            column: 56
          },
          end: {
            line: 314,
            column: 3
          }
        },
        line: 297
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 302,
            column: 60
          },
          end: {
            line: 302,
            column: 61
          }
        },
        loc: {
          start: {
            line: 302,
            column: 65
          },
          end: {
            line: 302,
            column: 98
          }
        },
        line: 302
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 320,
            column: 13
          },
          end: {
            line: 320,
            column: 14
          }
        },
        loc: {
          start: {
            line: 320,
            column: 25
          },
          end: {
            line: 328,
            column: 5
          }
        },
        line: 320
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 322,
            column: 61
          },
          end: {
            line: 322,
            column: 62
          }
        },
        loc: {
          start: {
            line: 322,
            column: 70
          },
          end: {
            line: 324,
            column: 9
          }
        },
        line: 322
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 331,
            column: 64
          },
          end: {
            line: 331,
            column: 65
          }
        },
        loc: {
          start: {
            line: 331,
            column: 72
          },
          end: {
            line: 338,
            column: 3
          }
        },
        line: 331
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 337,
            column: 54
          },
          end: {
            line: 337,
            column: 55
          }
        },
        loc: {
          start: {
            line: 337,
            column: 62
          },
          end: {
            line: 337,
            column: 86
          }
        },
        line: 337
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 31,
            column: 10
          },
          end: {
            line: 31,
            column: 56
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 31,
            column: 44
          },
          end: {
            line: 31,
            column: 46
          }
        }, {
          start: {
            line: 31,
            column: 49
          },
          end: {
            line: 31,
            column: 56
          }
        }],
        line: 31
      },
      '1': {
        loc: {
          start: {
            line: 33,
            column: 11
          },
          end: {
            line: 33,
            column: 50
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 33,
            column: 45
          },
          end: {
            line: 33,
            column: 46
          }
        }, {
          start: {
            line: 33,
            column: 49
          },
          end: {
            line: 33,
            column: 50
          }
        }],
        line: 33
      },
      '2': {
        loc: {
          start: {
            line: 34,
            column: 12
          },
          end: {
            line: 34,
            column: 93
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 34,
            column: 46
          },
          end: {
            line: 34,
            column: 53
          }
        }, {
          start: {
            line: 34,
            column: 57
          },
          end: {
            line: 34,
            column: 92
          }
        }],
        line: 34
      },
      '3': {
        loc: {
          start: {
            line: 36,
            column: 8
          },
          end: {
            line: 36,
            column: 20
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 36,
            column: 8
          },
          end: {
            line: 36,
            column: 14
          }
        }, {
          start: {
            line: 36,
            column: 18
          },
          end: {
            line: 36,
            column: 20
          }
        }],
        line: 36
      },
      '4': {
        loc: {
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 39,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 39,
            column: 7
          }
        }, {
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 39,
            column: 7
          }
        }],
        line: 37
      },
      '5': {
        loc: {
          start: {
            line: 70,
            column: 4
          },
          end: {
            line: 73,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 70,
            column: 4
          },
          end: {
            line: 73,
            column: 5
          }
        }, {
          start: {
            line: 70,
            column: 4
          },
          end: {
            line: 73,
            column: 5
          }
        }],
        line: 70
      },
      '6': {
        loc: {
          start: {
            line: 76,
            column: 11
          },
          end: {
            line: 76,
            column: 92
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 76,
            column: 45
          },
          end: {
            line: 76,
            column: 52
          }
        }, {
          start: {
            line: 76,
            column: 56
          },
          end: {
            line: 76,
            column: 91
          }
        }],
        line: 76
      },
      '7': {
        loc: {
          start: {
            line: 80,
            column: 15
          },
          end: {
            line: 80,
            column: 45
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 80,
            column: 39
          },
          end: {
            line: 80,
            column: 40
          }
        }, {
          start: {
            line: 80,
            column: 43
          },
          end: {
            line: 80,
            column: 45
          }
        }],
        line: 80
      },
      '8': {
        loc: {
          start: {
            line: 91,
            column: 8
          },
          end: {
            line: 99,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 91,
            column: 8
          },
          end: {
            line: 99,
            column: 9
          }
        }, {
          start: {
            line: 91,
            column: 8
          },
          end: {
            line: 99,
            column: 9
          }
        }],
        line: 91
      },
      '9': {
        loc: {
          start: {
            line: 96,
            column: 10
          },
          end: {
            line: 98,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 96,
            column: 10
          },
          end: {
            line: 98,
            column: 11
          }
        }, {
          start: {
            line: 96,
            column: 10
          },
          end: {
            line: 98,
            column: 11
          }
        }],
        line: 96
      },
      '10': {
        loc: {
          start: {
            line: 117,
            column: 23
          },
          end: {
            line: 117,
            column: 84
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 117,
            column: 67
          },
          end: {
            line: 117,
            column: 70
          }
        }, {
          start: {
            line: 117,
            column: 73
          },
          end: {
            line: 117,
            column: 84
          }
        }],
        line: 117
      },
      '11': {
        loc: {
          start: {
            line: 131,
            column: 14
          },
          end: {
            line: 133,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 131,
            column: 14
          },
          end: {
            line: 133,
            column: 15
          }
        }, {
          start: {
            line: 131,
            column: 14
          },
          end: {
            line: 133,
            column: 15
          }
        }],
        line: 131
      },
      '12': {
        loc: {
          start: {
            line: 142,
            column: 14
          },
          end: {
            line: 144,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 142,
            column: 14
          },
          end: {
            line: 144,
            column: 15
          }
        }, {
          start: {
            line: 142,
            column: 14
          },
          end: {
            line: 144,
            column: 15
          }
        }],
        line: 142
      },
      '13': {
        loc: {
          start: {
            line: 181,
            column: 21
          },
          end: {
            line: 181,
            column: 82
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 181,
            column: 65
          },
          end: {
            line: 181,
            column: 68
          }
        }, {
          start: {
            line: 181,
            column: 71
          },
          end: {
            line: 181,
            column: 82
          }
        }],
        line: 181
      },
      '14': {
        loc: {
          start: {
            line: 196,
            column: 12
          },
          end: {
            line: 198,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 196,
            column: 12
          },
          end: {
            line: 198,
            column: 13
          }
        }, {
          start: {
            line: 196,
            column: 12
          },
          end: {
            line: 198,
            column: 13
          }
        }],
        line: 196
      },
      '15': {
        loc: {
          start: {
            line: 210,
            column: 12
          },
          end: {
            line: 212,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 210,
            column: 12
          },
          end: {
            line: 212,
            column: 13
          }
        }, {
          start: {
            line: 210,
            column: 12
          },
          end: {
            line: 212,
            column: 13
          }
        }],
        line: 210
      },
      '16': {
        loc: {
          start: {
            line: 238,
            column: 14
          },
          end: {
            line: 238,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 238,
            column: 14
          },
          end: {
            line: 238,
            column: 32
          }
        }, {
          start: {
            line: 238,
            column: 36
          },
          end: {
            line: 238,
            column: 50
          }
        }],
        line: 238
      },
      '17': {
        loc: {
          start: {
            line: 258,
            column: 8
          },
          end: {
            line: 260,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 258,
            column: 8
          },
          end: {
            line: 260,
            column: 9
          }
        }, {
          start: {
            line: 258,
            column: 8
          },
          end: {
            line: 260,
            column: 9
          }
        }],
        line: 258
      },
      '18': {
        loc: {
          start: {
            line: 259,
            column: 28
          },
          end: {
            line: 259,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 259,
            column: 28
          },
          end: {
            line: 259,
            column: 36
          }
        }, {
          start: {
            line: 259,
            column: 40
          },
          end: {
            line: 259,
            column: 41
          }
        }],
        line: 259
      },
      '19': {
        loc: {
          start: {
            line: 261,
            column: 8
          },
          end: {
            line: 263,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 261,
            column: 8
          },
          end: {
            line: 263,
            column: 9
          }
        }, {
          start: {
            line: 261,
            column: 8
          },
          end: {
            line: 263,
            column: 9
          }
        }],
        line: 261
      },
      '20': {
        loc: {
          start: {
            line: 262,
            column: 28
          },
          end: {
            line: 262,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 262,
            column: 28
          },
          end: {
            line: 262,
            column: 36
          }
        }, {
          start: {
            line: 262,
            column: 40
          },
          end: {
            line: 262,
            column: 41
          }
        }],
        line: 262
      },
      '21': {
        loc: {
          start: {
            line: 280,
            column: 23
          },
          end: {
            line: 280,
            column: 93
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 280,
            column: 23
          },
          end: {
            line: 280,
            column: 88
          }
        }, {
          start: {
            line: 280,
            column: 92
          },
          end: {
            line: 280,
            column: 93
          }
        }],
        line: 280
      },
      '22': {
        loc: {
          start: {
            line: 281,
            column: 25
          },
          end: {
            line: 281,
            column: 133
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 281,
            column: 25
          },
          end: {
            line: 281,
            column: 96
          }
        }, {
          start: {
            line: 281,
            column: 100
          },
          end: {
            line: 281,
            column: 133
          }
        }],
        line: 281
      },
      '23': {
        loc: {
          start: {
            line: 285,
            column: 4
          },
          end: {
            line: 287,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 285,
            column: 4
          },
          end: {
            line: 287,
            column: 5
          }
        }, {
          start: {
            line: 285,
            column: 4
          },
          end: {
            line: 287,
            column: 5
          }
        }],
        line: 285
      },
      '24': {
        loc: {
          start: {
            line: 288,
            column: 4
          },
          end: {
            line: 290,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 288,
            column: 4
          },
          end: {
            line: 290,
            column: 5
          }
        }, {
          start: {
            line: 288,
            column: 4
          },
          end: {
            line: 290,
            column: 5
          }
        }],
        line: 288
      },
      '25': {
        loc: {
          start: {
            line: 302,
            column: 15
          },
          end: {
            line: 302,
            column: 51
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 302,
            column: 15
          },
          end: {
            line: 302,
            column: 45
          }
        }, {
          start: {
            line: 302,
            column: 49
          },
          end: {
            line: 302,
            column: 51
          }
        }],
        line: 302
      },
      '26': {
        loc: {
          start: {
            line: 303,
            column: 4
          },
          end: {
            line: 308,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 303,
            column: 4
          },
          end: {
            line: 308,
            column: 5
          }
        }, {
          start: {
            line: 303,
            column: 4
          },
          end: {
            line: 308,
            column: 5
          }
        }],
        line: 303
      },
      '27': {
        loc: {
          start: {
            line: 305,
            column: 6
          },
          end: {
            line: 307,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 305,
            column: 6
          },
          end: {
            line: 307,
            column: 7
          }
        }, {
          start: {
            line: 305,
            column: 6
          },
          end: {
            line: 307,
            column: 7
          }
        }],
        line: 305
      },
      '28': {
        loc: {
          start: {
            line: 321,
            column: 6
          },
          end: {
            line: 327,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 321,
            column: 6
          },
          end: {
            line: 327,
            column: 7
          }
        }, {
          start: {
            line: 321,
            column: 6
          },
          end: {
            line: 327,
            column: 7
          }
        }],
        line: 321
      },
      '29': {
        loc: {
          start: {
            line: 337,
            column: 27
          },
          end: {
            line: 337,
            column: 116
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 337,
            column: 27
          },
          end: {
            line: 337,
            column: 88
          }
        }, {
          start: {
            line: 337,
            column: 92
          },
          end: {
            line: 337,
            column: 116
          }
        }],
        line: 337
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1w3cqx9h.s[0]++;
angular.module('app').controller('indicatorPortfolioDashboardGlobalCtrl', function ($scope, $CRUDService, $deltaNumber, $deltaPeriode, $stateParams, $rootScope, $deltaPlanLogique, $filter, $deltaGeoLevel, $http, $deltadate, $translate, $log) {
  cov_1w3cqx9h.f[0]++;

  var PATH = (cov_1w3cqx9h.s[1]++, 'Activites');
  var PATH_INDICATOR = (cov_1w3cqx9h.s[2]++, 'Indicateurs');
  var PATH_LOCALITY = (cov_1w3cqx9h.s[3]++, 'Localites');
  // Initialize DashboardLayout component
  cov_1w3cqx9h.s[4]++;
  $scope.choise = {
    displayIndex: 0
  };

  var INDICATOR_DASHBOARD_PROJECT_GLOBAL_GEO_PLAN = (cov_1w3cqx9h.s[5]++, 'INDICATOR_DASHBOARD_PROJECT_GLOBAL_GEO_PLAN_' + $rootScope.currentProject.project.id);
  var INDICATOR_DASHBOARD_PROJECT_GLOBAL_INDICATOR = (cov_1w3cqx9h.s[6]++, 'INDICATOR_DASHBOARD_PROJECT_GLOBAL_INDICATOR_' + $rootScope.currentProject.project.id);

  cov_1w3cqx9h.s[7]++;
  $scope.canClose = $stateParams.view_item == 1;

  cov_1w3cqx9h.s[8]++;
  $scope.height = $('#side-menu').height() - 250 + 'px';

  cov_1w3cqx9h.s[9]++;
  $scope.globalValue = null;
  cov_1w3cqx9h.s[10]++;
  $deltaPeriode.addController($scope);

  cov_1w3cqx9h.s[11]++;
  $scope.activity = null;

  cov_1w3cqx9h.s[12]++;
  var getInformation = function getInformation(params, processAfter) {
    cov_1w3cqx9h.f[1]++;
    cov_1w3cqx9h.s[13]++;

    $CRUDService.getAll(PATH_INDICATOR, Object.assign({
      get: 'global_informations',
      id: 0,
      id_indic: $scope.selectedIndicator.id,
      // a1: $scope.activity.CODE_CLC.substr(0, $scope.activity.CODE_CLC.length - $scope.activity.CODE_CLCAFF.length), // CODE_CLCAFF
      // a2: item ? item.id : '',
      a2: $scope.choise.displayIndex == 0 ? (cov_1w3cqx9h.b[0][0]++, '') : (cov_1w3cqx9h.b[0][1]++, '0_0_0'),
      // la1: $scope.activity.level - 1,
      la2: $scope.choise.displayIndex == 0 ? (cov_1w3cqx9h.b[1][0]++, 0) : (cov_1w3cqx9h.b[1][1]++, 1),
      year: $scope.choise.displayIndex == 0 ? (cov_1w3cqx9h.b[2][0]++, '0_0_0') : (cov_1w3cqx9h.b[2][1]++, $scope.choise.displayIndex + '_1_1')
      // code: $scope.activity.CODE_CLC
    }, (cov_1w3cqx9h.b[3][0]++, params) || (cov_1w3cqx9h.b[3][1]++, {})), function (data) {
      cov_1w3cqx9h.f[2]++;
      cov_1w3cqx9h.s[14]++;

      if (processAfter) {
        cov_1w3cqx9h.b[4][0]++;
        cov_1w3cqx9h.s[15]++;

        processAfter(data);
      } else {
        cov_1w3cqx9h.b[4][1]++;
      }
    });
  };

  cov_1w3cqx9h.s[16]++;
  $scope.getAllInformations = function () {
    cov_1w3cqx9h.f[3]++;
    cov_1w3cqx9h.s[17]++;

    // $scope.getGlobalInformations();

    $scope.getMapInformations();
    cov_1w3cqx9h.s[18]++;
    $scope.getChartInformations();
  };

  cov_1w3cqx9h.s[19]++;
  $scope.changeLevel = function (level) {
    cov_1w3cqx9h.f[4]++;
    cov_1w3cqx9h.s[20]++;

    $scope.selectedLocality = null;
    cov_1w3cqx9h.s[21]++;
    $scope.mapGeoLevel = level;
    cov_1w3cqx9h.s[22]++;
    $scope.mappingLocatities = null;

    cov_1w3cqx9h.s[23]++;
    localStorage.setItem(INDICATOR_DASHBOARD_PROJECT_GLOBAL_GEO_PLAN, $scope.mapGeoLevel.id);

    cov_1w3cqx9h.s[24]++;
    $scope.getMapInformations();
  };

  cov_1w3cqx9h.s[25]++;
  $scope.getGlobalInformations = function () {
    cov_1w3cqx9h.f[5]++;
    cov_1w3cqx9h.s[26]++;

    $CRUDService.getAll('DecoupageGlobalIndic', { get: 'all_global', id: $scope.selectedIndicator.id, idcl: $scope.selectedIndicator.CODE_CL }, function (data) {
      cov_1w3cqx9h.f[6]++;
    });
  };

  /*      $scope.selectedLocality = $scope.mappingLocatities[layer.options.info.code];
      $scope.getChartInformations(); */

  cov_1w3cqx9h.s[27]++;
  $scope.getChartInformations = function () {
    cov_1w3cqx9h.f[7]++;
    cov_1w3cqx9h.s[28]++;

    if (!$scope.selectedLocality) {
      cov_1w3cqx9h.b[5][0]++;
      cov_1w3cqx9h.s[29]++;

      $scope.chartInformationsData = {};
      cov_1w3cqx9h.s[30]++;
      return;
    } else {
      cov_1w3cqx9h.b[5][1]++;
    }
    cov_1w3cqx9h.s[31]++;
    getInformation({
      get: 'chart_informations',
      id_: $scope.choise.displayIndex == 0 ? (cov_1w3cqx9h.b[6][0]++, '0_0_0') : (cov_1w3cqx9h.b[6][1]++, $scope.choise.displayIndex + '_1_1'),
      code: $scope.selectedLocality.CODE_LC
    }, function (data) {
      cov_1w3cqx9h.f[8]++;
      cov_1w3cqx9h.s[32]++;

      data = data.sort(function (valueA, valueB) {
        cov_1w3cqx9h.f[9]++;
        cov_1w3cqx9h.s[33]++;

        return valueA.id > valueB.id ? (cov_1w3cqx9h.b[7][0]++, 1) : (cov_1w3cqx9h.b[7][1]++, -1);
      });
      cov_1w3cqx9h.s[34]++;
      data = data.map(function (value) {
        cov_1w3cqx9h.f[10]++;
        cov_1w3cqx9h.s[35]++;

        value.vp = $deltaNumber.formatNumber(value.vp, null, $scope.selectedIndicator.FORMAT_UNITE);

        cov_1w3cqx9h.s[36]++;
        value.vr = $deltaNumber.formatNumber(value.vr, null, $scope.selectedIndicator.FORMAT_UNITE);

        cov_1w3cqx9h.s[37]++;
        value.vpDisplay = $rootScope.displayIndicatorValue(value.vp, $scope.selectedIndicator.TYPE_UNITE, $scope.selectedIndicator.FORMAT_UNITE, $scope.selectedIndicator.vp, $scope.selectedIndicator.DISPLAY_VALUE);

        cov_1w3cqx9h.s[38]++;
        value.vrDisplay = $rootScope.displayIndicatorValue(value.vr, $scope.selectedIndicator.TYPE_UNITE, $scope.selectedIndicator.FORMAT_UNITE, $scope.selectedIndicator.vp, $scope.selectedIndicator.DISPLAY_VALUE);

        cov_1w3cqx9h.s[39]++;
        if (value.id == '0_0_0') {
          cov_1w3cqx9h.b[8][0]++;
          cov_1w3cqx9h.s[40]++;

          value.label = $translate.instant('DASHBOARD.REFERENCE');
        } else {
          cov_1w3cqx9h.b[8][1]++;

          var ids = (cov_1w3cqx9h.s[41]++, value.id.split('_'));
          cov_1w3cqx9h.s[42]++;
          value.label = ids[0];
          cov_1w3cqx9h.s[43]++;
          if ($scope.selectedIndicator.PERIOD_INDIC != 1) {
            cov_1w3cqx9h.b[9][0]++;
            cov_1w3cqx9h.s[44]++;

            value.label = $deltadate.getLabelPerioddepth(ids[1], ids[2], false, ids[0]);
          } else {
            cov_1w3cqx9h.b[9][1]++;
          }
        }

        cov_1w3cqx9h.s[45]++;
        return value;
      });
      cov_1w3cqx9h.s[46]++;
      $scope.chartInformationsData = {
        // Initializing Primary X Axis
        primaryXAxis: {
          title: '',
          interval: 1,
          labelIntersectAction: 'Rotate45',
          valueType: 'Category',
          majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
          majorTickLines: { width: 0 }, minorTickLines: { width: 0 }
        },
        // Initializing Primary Y Axis
        primaryYAxis: {
          title: '',
          valueType: 'Double',
          labelFormat: $scope.selectedIndicator.TYPE_UNITES != 2 ? (cov_1w3cqx9h.b[10][0]++, 'N') : (cov_1w3cqx9h.b[10][1]++, '{value} %')
        },
        chartArea: {
          border: {
            width: 0
          }
        },
        // Initializing Chart Series
        series: [{
          type: 'Column',
          fill: '#127DB2',
          dataSource: data.map(function (value) {
            cov_1w3cqx9h.f[11]++;

            var d_ = (cov_1w3cqx9h.s[47]++, { x: value.label, y: null });
            cov_1w3cqx9h.s[48]++;
            if (value.vp) {
              cov_1w3cqx9h.b[11][0]++;
              cov_1w3cqx9h.s[49]++;

              d_.y = value.vp;
            } else {
              cov_1w3cqx9h.b[11][1]++;
            }
            cov_1w3cqx9h.s[50]++;
            return d_;
          }),
          xName: 'x', yName: 'y', name: $translate.instant('INDICATOR.TARGET_VAL')
        }, {
          fill: '#BBE635',
          type: 'Line',
          dataSource: data.map(function (value) {
            cov_1w3cqx9h.f[12]++;

            var d_ = (cov_1w3cqx9h.s[51]++, { x: value.label, y: null });
            cov_1w3cqx9h.s[52]++;
            if (value.vr) {
              cov_1w3cqx9h.b[12][0]++;
              cov_1w3cqx9h.s[53]++;

              d_.y = value.vr;
            } else {
              cov_1w3cqx9h.b[12][1]++;
            }
            cov_1w3cqx9h.s[54]++;
            return d_;
          }),
          xName: 'x', yName: 'y', name: $translate.instant('INDICATOR.REAL_VAL'),
          width: 2,
          marker: {
            visible: true,
            width: 10,
            height: 10
          }
        }],
        width: '100%',
        height: 'calc(100%)', // ($('#chart-container').height() - 20) + 'px',
        // Initializing Chart Title
        // Initializing Tooltip
        tooltip: { enable: true, shared: true }

      };
    });
  };
  cov_1w3cqx9h.s[55]++;
  var processRepartition = function processRepartition(params) {
    cov_1w3cqx9h.f[13]++;
    cov_1w3cqx9h.s[56]++;

    $scope.processRepartitionData = {
      enableSideBySidePlacement: false,
      // Initializing Primary X Axis
      primaryXAxis: {
        title: '',
        interval: 1,
        labelIntersectAction: 'Rotate45',
        valueType: 'Category',
        majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
        majorTickLines: { width: 0 }, minorTickLines: { width: 0 }
      },
      // Initializing Primary Y Axis
      primaryYAxis: {
        title: '',
        valueType: 'Double',
        labelFormat: $scope.selectedIndicator.TYPE_UNITES != 2 ? (cov_1w3cqx9h.b[13][0]++, 'N') : (cov_1w3cqx9h.b[13][1]++, '{value} %')
      },
      chartArea: {
        border: {
          width: 0
        }
      },
      // Initializing Chart Series
      series: [{
        type: 'Bar',
        stackingGroup: 'target',
        fill: '#93e5aa',
        dataSource: params.map(function (value) {
          cov_1w3cqx9h.f[14]++;

          var d_ = (cov_1w3cqx9h.s[57]++, { x: value.label, y: null, code: value.code });
          cov_1w3cqx9h.s[58]++;
          if (value.vp) {
            cov_1w3cqx9h.b[14][0]++;
            cov_1w3cqx9h.s[59]++;

            d_.y = value.vp;
          } else {
            cov_1w3cqx9h.b[14][1]++;
          }
          cov_1w3cqx9h.s[60]++;
          return d_;
        }),
        marker: { dataLabel: { visible: true, position: 'Top' } },
        xName: 'x', yName: 'y', name: $translate.instant('DASHBOARD.END_TARGET'),
        columnWidth: 0.5
      }, {
        fill: '#b5ffe1',
        type: 'Bar',
        stackingGroup: 'target',
        dataSource: params.map(function (value) {
          cov_1w3cqx9h.f[15]++;

          var d_ = (cov_1w3cqx9h.s[61]++, { x: value.label, y: null, code: value.code });
          cov_1w3cqx9h.s[62]++;
          if (value.vr) {
            cov_1w3cqx9h.b[15][0]++;
            cov_1w3cqx9h.s[63]++;

            d_.y = value.vr;
          } else {
            cov_1w3cqx9h.b[15][1]++;
          }
          cov_1w3cqx9h.s[64]++;
          return d_;
        }),
        marker: { dataLabel: { visible: true, position: 'Top' } },
        xName: 'x', yName: 'y', name: $translate.instant('DASHBOARD.ACTUAL_VALUE'),
        columnWidth: 0.5
      }],
      width: '100%',
      height: '100%',
      tooltip: { enable: true, shared: true },
      pointClick: function pointClick(args) {
        var serieData = (cov_1w3cqx9h.s[65]++, args.series.dataSource[args.pointIndex]);

        cov_1w3cqx9h.s[66]++;
        $scope.selectedLocality = {
          CODE_LC: serieData.code,
          DESCRIPTION_L: serieData.x
        };
        cov_1w3cqx9h.s[67]++;
        $scope.getChartInformations();
      }
    };
  };

  cov_1w3cqx9h.s[68]++;
  $scope.getMapInformations = function () {
    cov_1w3cqx9h.f[16]++;
    cov_1w3cqx9h.s[69]++;

    getInformation({
      get: 'map_informations',
      level: ((cov_1w3cqx9h.b[16][0]++, $scope.mapGeoLevel) || (cov_1w3cqx9h.b[16][1]++, { NIVEAU_NG: 1 })).NIVEAU_NG - 1,
      year: $scope.choise.displayIndex
    }, function (data) {
      cov_1w3cqx9h.f[17]++;

      var otherData = (cov_1w3cqx9h.s[70]++, []);
      cov_1w3cqx9h.s[71]++;
      for (var key_loc in data) {
        var value = (cov_1w3cqx9h.s[72]++, data[key_loc]);
        cov_1w3cqx9h.s[73]++;
        value.code = key_loc;

        cov_1w3cqx9h.s[74]++;
        value.cp = $deltaNumber.formatNumber(value.cp, null, $scope.selectedIndicator.FORMAT_UNITE);

        cov_1w3cqx9h.s[75]++;
        value.cr = $deltaNumber.formatNumber(value.cr, null, $scope.selectedIndicator.FORMAT_UNITE);

        cov_1w3cqx9h.s[76]++;
        value.vp = $deltaNumber.formatNumber(value.vp, null, $scope.selectedIndicator.FORMAT_UNITE);

        cov_1w3cqx9h.s[77]++;
        value.vr = $deltaNumber.formatNumber(value.vr, null, $scope.selectedIndicator.FORMAT_UNITE);

        cov_1w3cqx9h.s[78]++;
        value.vpDisplay = $rootScope.displayIndicatorValue(value.vp, $scope.selectedIndicator.TYPE_UNITE, $scope.selectedIndicator.FORMAT_UNITE, $scope.selectedIndicator.vp, $scope.selectedIndicator.DISPLAY_VALUE);

        cov_1w3cqx9h.s[79]++;
        value.vrDisplay = $rootScope.displayIndicatorValue(value.vr, $scope.selectedIndicator.TYPE_UNITE, $scope.selectedIndicator.FORMAT_UNITE, $scope.selectedIndicator.vp, $scope.selectedIndicator.DISPLAY_VALUE);

        cov_1w3cqx9h.s[80]++;
        if (value.vp) {
          cov_1w3cqx9h.b[17][0]++;
          cov_1w3cqx9h.s[81]++;

          value.TP = 100 * ((cov_1w3cqx9h.b[18][0]++, value.vr) || (cov_1w3cqx9h.b[18][1]++, 0)) / value.vp;
        } else {
          cov_1w3cqx9h.b[17][1]++;
        }
        cov_1w3cqx9h.s[82]++;
        if (value.cp) {
          cov_1w3cqx9h.b[19][0]++;
          cov_1w3cqx9h.s[83]++;

          value.TB = 100 * ((cov_1w3cqx9h.b[20][0]++, value.cr) || (cov_1w3cqx9h.b[20][1]++, 0)) / value.cp;
        } else {
          cov_1w3cqx9h.b[19][1]++;
        }
        cov_1w3cqx9h.s[84]++;
        otherData.push(value);

        cov_1w3cqx9h.s[85]++;
        $scope.selectedLocality = {
          CODE_LC: value.code,
          DESCRIPTION_L: value.label
        };
      }

      cov_1w3cqx9h.s[86]++;
      $scope.getChartInformations();

      cov_1w3cqx9h.s[87]++;
      processRepartition(otherData);
    });
  };

  cov_1w3cqx9h.s[88]++;
  $deltaGeoLevel.addController($scope, function () {
    cov_1w3cqx9h.f[18]++;

    var levelSaved = (cov_1w3cqx9h.s[89]++, (cov_1w3cqx9h.b[21][0]++, localStorage.getItem(INDICATOR_DASHBOARD_PROJECT_GLOBAL_GEO_PLAN)) || (cov_1w3cqx9h.b[21][1]++, 0));
    cov_1w3cqx9h.s[90]++;
    $scope.mapGeoLevel = (cov_1w3cqx9h.b[22][0]++, $scope.listData_GeoLevels_bksb.find(function (level) {
      cov_1w3cqx9h.f[19]++;
      cov_1w3cqx9h.s[91]++;
      return level.id === levelSaved;
    })) || (cov_1w3cqx9h.b[22][1]++, $scope.listData_GeoLevels_bksb[0]);
  });

  cov_1w3cqx9h.s[92]++;
  var processYear = function processYear(beginDate, endDate) {
    cov_1w3cqx9h.f[20]++;
    cov_1w3cqx9h.s[93]++;

    if (angular.isString(beginDate)) {
      cov_1w3cqx9h.b[23][0]++;
      cov_1w3cqx9h.s[94]++;

      beginDate = Date.newDate(beginDate);
    } else {
      cov_1w3cqx9h.b[23][1]++;
    }
    cov_1w3cqx9h.s[95]++;
    if (angular.isString(endDate)) {
      cov_1w3cqx9h.b[24][0]++;
      cov_1w3cqx9h.s[96]++;

      endDate = Date.newDate(endDate);
    } else {
      cov_1w3cqx9h.b[24][1]++;
    }

    cov_1w3cqx9h.s[97]++;
    return $deltadate.getYearBetween(beginDate, endDate);
  };

  // $scope.globalValue

  cov_1w3cqx9h.s[98]++;
  $scope.changeIndicator = function (selectedIndicator) {
    cov_1w3cqx9h.f[21]++;
    cov_1w3cqx9h.s[99]++;

    $scope.globalValue = {};
    cov_1w3cqx9h.s[100]++;
    $scope.selectedIndicator = selectedIndicator;
    cov_1w3cqx9h.s[101]++;
    $scope.getGlobalInformations();
    cov_1w3cqx9h.s[102]++;
    $scope.lastGeoLevel = null;
    var _ = (cov_1w3cqx9h.s[103]++, ((cov_1w3cqx9h.b[25][0]++, $scope.listData_GeoLevels_bksb) || (cov_1w3cqx9h.b[25][1]++, [])).filter(function (a) {
      cov_1w3cqx9h.f[22]++;
      cov_1w3cqx9h.s[104]++;
      return a.id == selectedIndicator.CODE_NG;
    }));
    cov_1w3cqx9h.s[105]++;
    if (_.length > 0) {
      cov_1w3cqx9h.b[26][0]++;
      cov_1w3cqx9h.s[106]++;

      $scope.lastGeoLevel = _[0];
      cov_1w3cqx9h.s[107]++;
      if ($scope.mapGeoLevel.NIVEAU_NG > $scope.lastGeoLevel.NIVEAU_NG) {
        cov_1w3cqx9h.b[27][0]++;
        cov_1w3cqx9h.s[108]++;

        $scope.changeLevel($scope.lastGeoLevel);
      } else {
        cov_1w3cqx9h.b[27][1]++;
      }
    } else {
      cov_1w3cqx9h.b[26][1]++;
    }
    cov_1w3cqx9h.s[109]++;
    $scope.listData_Periode = processYear(selectedIndicator.DEB_EVAL_INDIC, selectedIndicator.FIN_EVAL_INDIC);

    cov_1w3cqx9h.s[110]++;
    localStorage.setItem(INDICATOR_DASHBOARD_PROJECT_GLOBAL_INDICATOR, selectedIndicator.id);

    cov_1w3cqx9h.s[111]++;
    $scope.getAllInformations();
  };

  // search
  cov_1w3cqx9h.s[112]++;
  $scope.filterIndicator = {
    search: '',
    clone: null,
    filter: function filter() {
      var _this = this;

      cov_1w3cqx9h.f[23]++;
      cov_1w3cqx9h.s[113]++;

      if (this.search != '') {
        cov_1w3cqx9h.b[28][0]++;
        cov_1w3cqx9h.s[114]++;

        $scope.indicatorsList = $scope.indicatorsList.filter(function (value) {
          cov_1w3cqx9h.f[24]++;
          cov_1w3cqx9h.s[115]++;

          return (value.CODE_INDIC + ' ' + value.LIBELLE_C_INDIC).toLowerCase().includes(_this.search.toLowerCase());
        });
      } else {
        cov_1w3cqx9h.b[28][1]++;
        cov_1w3cqx9h.s[116]++;

        $scope.indicatorsList = angular.copy(this.clone);
      }
    }
  };

  cov_1w3cqx9h.s[117]++;
  $CRUDService.getAll(PATH_INDICATOR, { get: 'dashboard_indic' }, function (data) {
    cov_1w3cqx9h.f[25]++;
    cov_1w3cqx9h.s[118]++;

    $scope.indicatorsList = data;
    cov_1w3cqx9h.s[119]++;
    $scope.filterIndicator.clone = angular.copy(data);

    var savedIndicator = (cov_1w3cqx9h.s[120]++, localStorage.getItem(INDICATOR_DASHBOARD_PROJECT_GLOBAL_INDICATOR));

    cov_1w3cqx9h.s[121]++;
    $scope.changeIndicator((cov_1w3cqx9h.b[29][0]++, $scope.indicatorsList.find(function (ind) {
      cov_1w3cqx9h.f[26]++;
      cov_1w3cqx9h.s[122]++;
      return ind.id == savedIndicator;
    })) || (cov_1w3cqx9h.b[29][1]++, $scope.indicatorsList[0]));
  });
});