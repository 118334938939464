'use strict';

var cov_jxk99dp4z = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/activity_dashboard/activityDashboardGlobalCtrl.js',
      hash = '32f1ba760379c75381f88b527b07f5fe776e840b',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/activity_dashboard/activityDashboardGlobalCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 749,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 26
        }
      },
      '2': {
        start: {
          line: 3,
          column: 24
        },
        end: {
          line: 3,
          column: 35
        }
      },
      '3': {
        start: {
          line: 4,
          column: 18
        },
        end: {
          line: 4,
          column: 32
        }
      },
      '4': {
        start: {
          line: 5,
          column: 18
        },
        end: {
          line: 5,
          column: 33
        }
      },
      '5': {
        start: {
          line: 7,
          column: 53
        },
        end: {
          line: 7,
          column: 136
        }
      },
      '6': {
        start: {
          line: 9,
          column: 53
        },
        end: {
          line: 9,
          column: 136
        }
      },
      '7': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 52
        }
      },
      '8': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 16,
          column: 4
        }
      },
      '9': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 17,
          column: 22
        }
      },
      '10': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 18,
          column: 26
        }
      },
      '11': {
        start: {
          line: 20,
          column: 2
        },
        end: {
          line: 20,
          column: 50
        }
      },
      '12': {
        start: {
          line: 22,
          column: 21
        },
        end: {
          line: 48,
          column: 3
        }
      },
      '13': {
        start: {
          line: 23,
          column: 17
        },
        end: {
          line: 23,
          column: 104
        }
      },
      '14': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 46,
          column: 5
        }
      },
      '15': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 105
        }
      },
      '16': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 30,
          column: 14
        }
      },
      '17': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 108
        }
      },
      '18': {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 33,
          column: 14
        }
      },
      '19': {
        start: {
          line: 35,
          column: 8
        },
        end: {
          line: 35,
          column: 108
        }
      },
      '20': {
        start: {
          line: 36,
          column: 8
        },
        end: {
          line: 36,
          column: 14
        }
      },
      '21': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 38,
          column: 108
        }
      },
      '22': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 39,
          column: 14
        }
      },
      '23': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 41,
          column: 108
        }
      },
      '24': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 42,
          column: 14
        }
      },
      '25': {
        start: {
          line: 44,
          column: 8
        },
        end: {
          line: 44,
          column: 105
        }
      },
      '26': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 45,
          column: 14
        }
      },
      '27': {
        start: {
          line: 47,
          column: 4
        },
        end: {
          line: 47,
          column: 122
        }
      },
      '28': {
        start: {
          line: 50,
          column: 21
        },
        end: {
          line: 84,
          column: 3
        }
      },
      '29': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 83,
          column: 7
        }
      },
      '30': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 82,
          column: 46
        }
      },
      '31': {
        start: {
          line: 86,
          column: 19
        },
        end: {
          line: 95,
          column: 3
        }
      },
      '32': {
        start: {
          line: 87,
          column: 19
        },
        end: {
          line: 89,
          column: 6
        }
      },
      '33': {
        start: {
          line: 88,
          column: 6
        },
        end: {
          line: 88,
          column: 34
        }
      },
      '34': {
        start: {
          line: 91,
          column: 4
        },
        end: {
          line: 93,
          column: 5
        }
      },
      '35': {
        start: {
          line: 92,
          column: 6
        },
        end: {
          line: 92,
          column: 29
        }
      },
      '36': {
        start: {
          line: 94,
          column: 4
        },
        end: {
          line: 94,
          column: 68
        }
      },
      '37': {
        start: {
          line: 97,
          column: 24
        },
        end: {
          line: 220,
          column: 3
        }
      },
      '38': {
        start: {
          line: 98,
          column: 4
        },
        end: {
          line: 100,
          column: 5
        }
      },
      '39': {
        start: {
          line: 99,
          column: 6
        },
        end: {
          line: 99,
          column: 13
        }
      },
      '40': {
        start: {
          line: 101,
          column: 4
        },
        end: {
          line: 103,
          column: 5
        }
      },
      '41': {
        start: {
          line: 102,
          column: 6
        },
        end: {
          line: 102,
          column: 13
        }
      },
      '42': {
        start: {
          line: 105,
          column: 18
        },
        end: {
          line: 105,
          column: 54
        }
      },
      '43': {
        start: {
          line: 106,
          column: 4
        },
        end: {
          line: 106,
          column: 16
        }
      },
      '44': {
        start: {
          line: 107,
          column: 4
        },
        end: {
          line: 119,
          column: 5
        }
      },
      '45': {
        start: {
          line: 108,
          column: 6
        },
        end: {
          line: 117,
          column: 9
        }
      },
      '46': {
        start: {
          line: 109,
          column: 8
        },
        end: {
          line: 116,
          column: 10
        }
      },
      '47': {
        start: {
          line: 118,
          column: 6
        },
        end: {
          line: 118,
          column: 13
        }
      },
      '48': {
        start: {
          line: 120,
          column: 4
        },
        end: {
          line: 208,
          column: 7
        }
      },
      '49': {
        start: {
          line: 121,
          column: 20
        },
        end: {
          line: 124,
          column: 40
        }
      },
      '50': {
        start: {
          line: 125,
          column: 6
        },
        end: {
          line: 207,
          column: 9
        }
      },
      '51': {
        start: {
          line: 131,
          column: 8
        },
        end: {
          line: 131,
          column: 35
        }
      },
      '52': {
        start: {
          line: 132,
          column: 8
        },
        end: {
          line: 134,
          column: 11
        }
      },
      '53': {
        start: {
          line: 133,
          column: 10
        },
        end: {
          line: 133,
          column: 48
        }
      },
      '54': {
        start: {
          line: 136,
          column: 8
        },
        end: {
          line: 143,
          column: 11
        }
      },
      '55': {
        start: {
          line: 137,
          column: 22
        },
        end: {
          line: 137,
          column: 41
        }
      },
      '56': {
        start: {
          line: 138,
          column: 10
        },
        end: {
          line: 138,
          column: 31
        }
      },
      '57': {
        start: {
          line: 139,
          column: 10
        },
        end: {
          line: 141,
          column: 11
        }
      },
      '58': {
        start: {
          line: 140,
          column: 12
        },
        end: {
          line: 140,
          column: 73
        }
      },
      '59': {
        start: {
          line: 142,
          column: 10
        },
        end: {
          line: 142,
          column: 23
        }
      },
      '60': {
        start: {
          line: 144,
          column: 22
        },
        end: {
          line: 205,
          column: 10
        }
      },
      '61': {
        start: {
          line: 171,
          column: 27
        },
        end: {
          line: 171,
          column: 52
        }
      },
      '62': {
        start: {
          line: 172,
          column: 16
        },
        end: {
          line: 174,
          column: 17
        }
      },
      '63': {
        start: {
          line: 173,
          column: 18
        },
        end: {
          line: 173,
          column: 34
        }
      },
      '64': {
        start: {
          line: 175,
          column: 16
        },
        end: {
          line: 175,
          column: 26
        }
      },
      '65': {
        start: {
          line: 182,
          column: 27
        },
        end: {
          line: 182,
          column: 52
        }
      },
      '66': {
        start: {
          line: 183,
          column: 16
        },
        end: {
          line: 185,
          column: 17
        }
      },
      '67': {
        start: {
          line: 184,
          column: 18
        },
        end: {
          line: 184,
          column: 34
        }
      },
      '68': {
        start: {
          line: 186,
          column: 16
        },
        end: {
          line: 186,
          column: 26
        }
      },
      '69': {
        start: {
          line: 206,
          column: 8
        },
        end: {
          line: 206,
          column: 39
        }
      },
      '70': {
        start: {
          line: 209,
          column: 4
        },
        end: {
          line: 219,
          column: 7
        }
      },
      '71': {
        start: {
          line: 210,
          column: 21
        },
        end: {
          line: 217,
          column: 7
        }
      },
      '72': {
        start: {
          line: 218,
          column: 6
        },
        end: {
          line: 218,
          column: 20
        }
      },
      '73': {
        start: {
          line: 221,
          column: 2
        },
        end: {
          line: 221,
          column: 28
        }
      },
      '74': {
        start: {
          line: 222,
          column: 2
        },
        end: {
          line: 222,
          column: 38
        }
      },
      '75': {
        start: {
          line: 223,
          column: 2
        },
        end: {
          line: 223,
          column: 36
        }
      },
      '76': {
        start: {
          line: 225,
          column: 2
        },
        end: {
          line: 227,
          column: 5
        }
      },
      '77': {
        start: {
          line: 226,
          column: 4
        },
        end: {
          line: 226,
          column: 32
        }
      },
      '78': {
        start: {
          line: 229,
          column: 2
        },
        end: {
          line: 245,
          column: 5
        }
      },
      '79': {
        start: {
          line: 230,
          column: 4
        },
        end: {
          line: 232,
          column: 5
        }
      },
      '80': {
        start: {
          line: 231,
          column: 6
        },
        end: {
          line: 231,
          column: 16
        }
      },
      '81': {
        start: {
          line: 233,
          column: 4
        },
        end: {
          line: 235,
          column: 5
        }
      },
      '82': {
        start: {
          line: 234,
          column: 6
        },
        end: {
          line: 234,
          column: 81
        }
      },
      '83': {
        start: {
          line: 237,
          column: 4
        },
        end: {
          line: 239,
          column: 5
        }
      },
      '84': {
        start: {
          line: 238,
          column: 6
        },
        end: {
          line: 238,
          column: 87
        }
      },
      '85': {
        start: {
          line: 241,
          column: 4
        },
        end: {
          line: 243,
          column: 5
        }
      },
      '86': {
        start: {
          line: 242,
          column: 6
        },
        end: {
          line: 242,
          column: 80
        }
      },
      '87': {
        start: {
          line: 244,
          column: 4
        },
        end: {
          line: 244,
          column: 14
        }
      },
      '88': {
        start: {
          line: 247,
          column: 2
        },
        end: {
          line: 247,
          column: 25
        }
      },
      '89': {
        start: {
          line: 249,
          column: 25
        },
        end: {
          line: 265,
          column: 3
        }
      },
      '90': {
        start: {
          line: 250,
          column: 4
        },
        end: {
          line: 264,
          column: 7
        }
      },
      '91': {
        start: {
          line: 261,
          column: 6
        },
        end: {
          line: 263,
          column: 7
        }
      },
      '92': {
        start: {
          line: 262,
          column: 8
        },
        end: {
          line: 262,
          column: 27
        }
      },
      '93': {
        start: {
          line: 267,
          column: 2
        },
        end: {
          line: 277,
          column: 4
        }
      },
      '94': {
        start: {
          line: 268,
          column: 4
        },
        end: {
          line: 272,
          column: 5
        }
      },
      '95': {
        start: {
          line: 269,
          column: 6
        },
        end: {
          line: 269,
          column: 29
        }
      },
      '96': {
        start: {
          line: 270,
          column: 6
        },
        end: {
          line: 270,
          column: 80
        }
      },
      '97': {
        start: {
          line: 271,
          column: 6
        },
        end: {
          line: 271,
          column: 26
        }
      },
      '98': {
        start: {
          line: 273,
          column: 4
        },
        end: {
          line: 273,
          column: 36
        }
      },
      '99': {
        start: {
          line: 274,
          column: 4
        },
        end: {
          line: 274,
          column: 35
        }
      },
      '100': {
        start: {
          line: 275,
          column: 4
        },
        end: {
          line: 275,
          column: 34
        }
      },
      '101': {
        start: {
          line: 276,
          column: 4
        },
        end: {
          line: 276,
          column: 32
        }
      },
      '102': {
        start: {
          line: 279,
          column: 2
        },
        end: {
          line: 292,
          column: 5
        }
      },
      '103': {
        start: {
          line: 280,
          column: 4
        },
        end: {
          line: 280,
          column: 30
        }
      },
      '104': {
        start: {
          line: 281,
          column: 4
        },
        end: {
          line: 291,
          column: 7
        }
      },
      '105': {
        start: {
          line: 282,
          column: 6
        },
        end: {
          line: 290,
          column: 7
        }
      },
      '106': {
        start: {
          line: 283,
          column: 8
        },
        end: {
          line: 283,
          column: 37
        }
      },
      '107': {
        start: {
          line: 285,
          column: 8
        },
        end: {
          line: 289,
          column: 9
        }
      },
      '108': {
        start: {
          line: 286,
          column: 10
        },
        end: {
          line: 288,
          column: 13
        }
      },
      '109': {
        start: {
          line: 287,
          column: 12
        },
        end: {
          line: 287,
          column: 60
        }
      },
      '110': {
        start: {
          line: 294,
          column: 2
        },
        end: {
          line: 300,
          column: 4
        }
      },
      '111': {
        start: {
          line: 295,
          column: 4
        },
        end: {
          line: 295,
          column: 31
        }
      },
      '112': {
        start: {
          line: 296,
          column: 4
        },
        end: {
          line: 296,
          column: 36
        }
      },
      '113': {
        start: {
          line: 298,
          column: 4
        },
        end: {
          line: 298,
          column: 92
        }
      },
      '114': {
        start: {
          line: 299,
          column: 4
        },
        end: {
          line: 299,
          column: 32
        }
      },
      '115': {
        start: {
          line: 302,
          column: 2
        },
        end: {
          line: 427,
          column: 4
        }
      },
      '116': {
        start: {
          line: 303,
          column: 4
        },
        end: {
          line: 303,
          column: 30
        }
      },
      '117': {
        start: {
          line: 304,
          column: 4
        },
        end: {
          line: 306,
          column: 5
        }
      },
      '118': {
        start: {
          line: 305,
          column: 6
        },
        end: {
          line: 305,
          column: 13
        }
      },
      '119': {
        start: {
          line: 307,
          column: 4
        },
        end: {
          line: 426,
          column: 7
        }
      },
      '120': {
        start: {
          line: 308,
          column: 6
        },
        end: {
          line: 308,
          column: 32
        }
      },
      '121': {
        start: {
          line: 309,
          column: 6
        },
        end: {
          line: 309,
          column: 39
        }
      },
      '122': {
        start: {
          line: 310,
          column: 6
        },
        end: {
          line: 310,
          column: 41
        }
      },
      '123': {
        start: {
          line: 311,
          column: 6
        },
        end: {
          line: 311,
          column: 38
        }
      },
      '124': {
        start: {
          line: 314,
          column: 6
        },
        end: {
          line: 350,
          column: 7
        }
      },
      '125': {
        start: {
          line: 316,
          column: 10
        },
        end: {
          line: 348,
          column: 12
        }
      },
      '126': {
        start: {
          line: 349,
          column: 8
        },
        end: {
          line: 349,
          column: 53
        }
      },
      '127': {
        start: {
          line: 352,
          column: 6
        },
        end: {
          line: 381,
          column: 7
        }
      },
      '128': {
        start: {
          line: 353,
          column: 30
        },
        end: {
          line: 379,
          column: 10
        }
      },
      '129': {
        start: {
          line: 380,
          column: 8
        },
        end: {
          line: 380,
          column: 55
        }
      },
      '130': {
        start: {
          line: 383,
          column: 6
        },
        end: {
          line: 425,
          column: 7
        }
      },
      '131': {
        start: {
          line: 384,
          column: 19
        },
        end: {
          line: 384,
          column: 64
        }
      },
      '132': {
        start: {
          line: 385,
          column: 19
        },
        end: {
          line: 385,
          column: 64
        }
      },
      '133': {
        start: {
          line: 386,
          column: 23
        },
        end: {
          line: 386,
          column: 47
        }
      },
      '134': {
        start: {
          line: 387,
          column: 21
        },
        end: {
          line: 387,
          column: 25
        }
      },
      '135': {
        start: {
          line: 388,
          column: 8
        },
        end: {
          line: 424,
          column: 9
        }
      },
      '136': {
        start: {
          line: 389,
          column: 10
        },
        end: {
          line: 389,
          column: 53
        }
      },
      '137': {
        start: {
          line: 390,
          column: 10
        },
        end: {
          line: 392,
          column: 11
        }
      },
      '138': {
        start: {
          line: 391,
          column: 12
        },
        end: {
          line: 391,
          column: 25
        }
      },
      '139': {
        start: {
          line: 393,
          column: 10
        },
        end: {
          line: 395,
          column: 11
        }
      },
      '140': {
        start: {
          line: 394,
          column: 12
        },
        end: {
          line: 394,
          column: 23
        }
      },
      '141': {
        start: {
          line: 396,
          column: 32
        },
        end: {
          line: 422,
          column: 12
        }
      },
      '142': {
        start: {
          line: 423,
          column: 10
        },
        end: {
          line: 423,
          column: 54
        }
      },
      '143': {
        start: {
          line: 429,
          column: 2
        },
        end: {
          line: 516,
          column: 4
        }
      },
      '144': {
        start: {
          line: 430,
          column: 4
        },
        end: {
          line: 432,
          column: 5
        }
      },
      '145': {
        start: {
          line: 431,
          column: 6
        },
        end: {
          line: 431,
          column: 13
        }
      },
      '146': {
        start: {
          line: 434,
          column: 4
        },
        end: {
          line: 515,
          column: 7
        }
      },
      '147': {
        start: {
          line: 439,
          column: 6
        },
        end: {
          line: 441,
          column: 9
        }
      },
      '148': {
        start: {
          line: 440,
          column: 8
        },
        end: {
          line: 440,
          column: 46
        }
      },
      '149': {
        start: {
          line: 443,
          column: 6
        },
        end: {
          line: 450,
          column: 9
        }
      },
      '150': {
        start: {
          line: 444,
          column: 20
        },
        end: {
          line: 444,
          column: 39
        }
      },
      '151': {
        start: {
          line: 445,
          column: 8
        },
        end: {
          line: 445,
          column: 29
        }
      },
      '152': {
        start: {
          line: 446,
          column: 8
        },
        end: {
          line: 448,
          column: 9
        }
      },
      '153': {
        start: {
          line: 447,
          column: 10
        },
        end: {
          line: 447,
          column: 71
        }
      },
      '154': {
        start: {
          line: 449,
          column: 8
        },
        end: {
          line: 449,
          column: 21
        }
      },
      '155': {
        start: {
          line: 452,
          column: 6
        },
        end: {
          line: 513,
          column: 9
        }
      },
      '156': {
        start: {
          line: 479,
          column: 25
        },
        end: {
          line: 479,
          column: 50
        }
      },
      '157': {
        start: {
          line: 480,
          column: 14
        },
        end: {
          line: 482,
          column: 15
        }
      },
      '158': {
        start: {
          line: 481,
          column: 16
        },
        end: {
          line: 481,
          column: 32
        }
      },
      '159': {
        start: {
          line: 483,
          column: 14
        },
        end: {
          line: 483,
          column: 24
        }
      },
      '160': {
        start: {
          line: 490,
          column: 25
        },
        end: {
          line: 490,
          column: 50
        }
      },
      '161': {
        start: {
          line: 491,
          column: 14
        },
        end: {
          line: 493,
          column: 15
        }
      },
      '162': {
        start: {
          line: 492,
          column: 16
        },
        end: {
          line: 492,
          column: 32
        }
      },
      '163': {
        start: {
          line: 494,
          column: 14
        },
        end: {
          line: 494,
          column: 24
        }
      },
      '164': {
        start: {
          line: 518,
          column: 19
        },
        end: {
          line: 545,
          column: 3
        }
      },
      '165': {
        start: {
          line: 519,
          column: 18
        },
        end: {
          line: 524,
          column: 45
        }
      },
      '166': {
        start: {
          line: 526,
          column: 4
        },
        end: {
          line: 526,
          column: 56
        }
      },
      '167': {
        start: {
          line: 527,
          column: 4
        },
        end: {
          line: 527,
          column: 40
        }
      },
      '168': {
        start: {
          line: 529,
          column: 4
        },
        end: {
          line: 529,
          column: 36
        }
      },
      '169': {
        start: {
          line: 530,
          column: 22
        },
        end: {
          line: 530,
          column: 34
        }
      },
      '170': {
        start: {
          line: 531,
          column: 26
        },
        end: {
          line: 531,
          column: 62
        }
      },
      '171': {
        start: {
          line: 532,
          column: 4
        },
        end: {
          line: 543,
          column: 6
        }
      },
      '172': {
        start: {
          line: 533,
          column: 18
        },
        end: {
          line: 533,
          column: 56
        }
      },
      '173': {
        start: {
          line: 535,
          column: 6
        },
        end: {
          line: 541,
          column: 7
        }
      },
      '174': {
        start: {
          line: 536,
          column: 8
        },
        end: {
          line: 540,
          column: 125
        }
      },
      '175': {
        start: {
          line: 542,
          column: 6
        },
        end: {
          line: 542,
          column: 17
        }
      },
      '176': {
        start: {
          line: 544,
          column: 4
        },
        end: {
          line: 544,
          column: 31
        }
      },
      '177': {
        start: {
          line: 546,
          column: 24
        },
        end: {
          line: 578,
          column: 3
        }
      },
      '178': {
        start: {
          line: 547,
          column: 4
        },
        end: {
          line: 547,
          column: 50
        }
      },
      '179': {
        start: {
          line: 548,
          column: 14
        },
        end: {
          line: 570,
          column: 6
        }
      },
      '180': {
        start: {
          line: 551,
          column: 8
        },
        end: {
          line: 551,
          column: 49
        }
      },
      '181': {
        start: {
          line: 555,
          column: 8
        },
        end: {
          line: 558,
          column: 11
        }
      },
      '182': {
        start: {
          line: 561,
          column: 8
        },
        end: {
          line: 568,
          column: 10
        }
      },
      '183': {
        start: {
          line: 571,
          column: 4
        },
        end: {
          line: 574,
          column: 7
        }
      },
      '184': {
        start: {
          line: 572,
          column: 6
        },
        end: {
          line: 572,
          column: 52
        }
      },
      '185': {
        start: {
          line: 573,
          column: 6
        },
        end: {
          line: 573,
          column: 71
        }
      },
      '186': {
        start: {
          line: 575,
          column: 4
        },
        end: {
          line: 575,
          column: 32
        }
      },
      '187': {
        start: {
          line: 576,
          column: 4
        },
        end: {
          line: 576,
          column: 21
        }
      },
      '188': {
        start: {
          line: 577,
          column: 4
        },
        end: {
          line: 577,
          column: 25
        }
      },
      '189': {
        start: {
          line: 580,
          column: 25
        },
        end: {
          line: 671,
          column: 3
        }
      },
      '190': {
        start: {
          line: 581,
          column: 23
        },
        end: {
          line: 581,
          column: 25
        }
      },
      '191': {
        start: {
          line: 582,
          column: 16
        },
        end: {
          line: 582,
          column: 18
        }
      },
      '192': {
        start: {
          line: 583,
          column: 4
        },
        end: {
          line: 590,
          column: 5
        }
      },
      '193': {
        start: {
          line: 584,
          column: 22
        },
        end: {
          line: 584,
          column: 68
        }
      },
      '194': {
        start: {
          line: 585,
          column: 6
        },
        end: {
          line: 587,
          column: 7
        }
      },
      '195': {
        start: {
          line: 586,
          column: 8
        },
        end: {
          line: 586,
          column: 17
        }
      },
      '196': {
        start: {
          line: 588,
          column: 6
        },
        end: {
          line: 588,
          column: 24
        }
      },
      '197': {
        start: {
          line: 589,
          column: 6
        },
        end: {
          line: 589,
          column: 41
        }
      },
      '198': {
        start: {
          line: 592,
          column: 19
        },
        end: {
          line: 634,
          column: 5
        }
      },
      '199': {
        start: {
          line: 636,
          column: 4
        },
        end: {
          line: 651,
          column: 7
        }
      },
      '200': {
        start: {
          line: 637,
          column: 19
        },
        end: {
          line: 637,
          column: 23
        }
      },
      '201': {
        start: {
          line: 638,
          column: 6
        },
        end: {
          line: 647,
          column: 7
        }
      },
      '202': {
        start: {
          line: 639,
          column: 22
        },
        end: {
          line: 639,
          column: 70
        }
      },
      '203': {
        start: {
          line: 640,
          column: 25
        },
        end: {
          line: 640,
          column: 53
        }
      },
      '204': {
        start: {
          line: 642,
          column: 8
        },
        end: {
          line: 646,
          column: 9
        }
      },
      '205': {
        start: {
          line: 643,
          column: 10
        },
        end: {
          line: 643,
          column: 28
        }
      },
      '206': {
        start: {
          line: 645,
          column: 10
        },
        end: {
          line: 645,
          column: 34
        }
      },
      '207': {
        start: {
          line: 648,
          column: 6
        },
        end: {
          line: 650,
          column: 7
        }
      },
      '208': {
        start: {
          line: 649,
          column: 8
        },
        end: {
          line: 649,
          column: 50
        }
      },
      '209': {
        start: {
          line: 673,
          column: 2
        },
        end: {
          line: 718,
          column: 4
        }
      },
      '210': {
        start: {
          line: 674,
          column: 4
        },
        end: {
          line: 676,
          column: 5
        }
      },
      '211': {
        start: {
          line: 675,
          column: 6
        },
        end: {
          line: 675,
          column: 16
        }
      },
      '212': {
        start: {
          line: 678,
          column: 19
        },
        end: {
          line: 678,
          column: 71
        }
      },
      '213': {
        start: {
          line: 679,
          column: 4
        },
        end: {
          line: 681,
          column: 5
        }
      },
      '214': {
        start: {
          line: 680,
          column: 6
        },
        end: {
          line: 680,
          column: 43
        }
      },
      '215': {
        start: {
          line: 682,
          column: 4
        },
        end: {
          line: 682,
          column: 24
        }
      },
      '216': {
        start: {
          line: 683,
          column: 4
        },
        end: {
          line: 717,
          column: 7
        }
      },
      '217': {
        start: {
          line: 688,
          column: 6
        },
        end: {
          line: 688,
          column: 28
        }
      },
      '218': {
        start: {
          line: 690,
          column: 6
        },
        end: {
          line: 700,
          column: 7
        }
      },
      '219': {
        start: {
          line: 691,
          column: 8
        },
        end: {
          line: 694,
          column: 9
        }
      },
      '220': {
        start: {
          line: 692,
          column: 27
        },
        end: {
          line: 692,
          column: 65
        }
      },
      '221': {
        start: {
          line: 693,
          column: 10
        },
        end: {
          line: 693,
          column: 75
        }
      },
      '222': {
        start: {
          line: 695,
          column: 13
        },
        end: {
          line: 700,
          column: 7
        }
      },
      '223': {
        start: {
          line: 697,
          column: 8
        },
        end: {
          line: 697,
          column: 40
        }
      },
      '224': {
        start: {
          line: 698,
          column: 8
        },
        end: {
          line: 698,
          column: 41
        }
      },
      '225': {
        start: {
          line: 699,
          column: 8
        },
        end: {
          line: 699,
          column: 15
        }
      },
      '226': {
        start: {
          line: 702,
          column: 6
        },
        end: {
          line: 716,
          column: 7
        }
      },
      '227': {
        start: {
          line: 704,
          column: 8
        },
        end: {
          line: 704,
          column: 41
        }
      },
      '228': {
        start: {
          line: 707,
          column: 8
        },
        end: {
          line: 715,
          column: 11
        }
      },
      '229': {
        start: {
          line: 708,
          column: 10
        },
        end: {
          line: 708,
          column: 40
        }
      },
      '230': {
        start: {
          line: 709,
          column: 10
        },
        end: {
          line: 711,
          column: 11
        }
      },
      '231': {
        start: {
          line: 710,
          column: 12
        },
        end: {
          line: 710,
          column: 66
        }
      },
      '232': {
        start: {
          line: 712,
          column: 10
        },
        end: {
          line: 714,
          column: 11
        }
      },
      '233': {
        start: {
          line: 713,
          column: 12
        },
        end: {
          line: 713,
          column: 28
        }
      },
      '234': {
        start: {
          line: 719,
          column: 2
        },
        end: {
          line: 722,
          column: 5
        }
      },
      '235': {
        start: {
          line: 720,
          column: 23
        },
        end: {
          line: 720,
          column: 92
        }
      },
      '236': {
        start: {
          line: 721,
          column: 4
        },
        end: {
          line: 721,
          column: 134
        }
      },
      '237': {
        start: {
          line: 721,
          column: 71
        },
        end: {
          line: 721,
          column: 94
        }
      },
      '238': {
        start: {
          line: 724,
          column: 2
        },
        end: {
          line: 724,
          column: 30
        }
      },
      '239': {
        start: {
          line: 725,
          column: 2
        },
        end: {
          line: 725,
          column: 31
        }
      },
      '240': {
        start: {
          line: 726,
          column: 2
        },
        end: {
          line: 734,
          column: 4
        }
      },
      '241': {
        start: {
          line: 727,
          column: 4
        },
        end: {
          line: 733,
          column: 5
        }
      },
      '242': {
        start: {
          line: 728,
          column: 6
        },
        end: {
          line: 730,
          column: 9
        }
      },
      '243': {
        start: {
          line: 729,
          column: 8
        },
        end: {
          line: 729,
          column: 121
        }
      },
      '244': {
        start: {
          line: 732,
          column: 6
        },
        end: {
          line: 732,
          column: 60
        }
      },
      '245': {
        start: {
          line: 737,
          column: 2
        },
        end: {
          line: 748,
          column: 5
        }
      },
      '246': {
        start: {
          line: 738,
          column: 4
        },
        end: {
          line: 738,
          column: 27
        }
      },
      '247': {
        start: {
          line: 739,
          column: 4
        },
        end: {
          line: 739,
          column: 47
        }
      },
      '248': {
        start: {
          line: 741,
          column: 27
        },
        end: {
          line: 741,
          column: 91
        }
      },
      '249': {
        start: {
          line: 743,
          column: 4
        },
        end: {
          line: 747,
          column: 6
        }
      },
      '250': {
        start: {
          line: 744,
          column: 40
        },
        end: {
          line: 744,
          column: 69
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 64
          },
          end: {
            line: 1,
            column: 65
          }
        },
        loc: {
          start: {
            line: 1,
            column: 206
          },
          end: {
            line: 749,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 22,
            column: 21
          },
          end: {
            line: 22,
            column: 22
          }
        },
        loc: {
          start: {
            line: 22,
            column: 30
          },
          end: {
            line: 48,
            column: 3
          }
        },
        line: 22
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 50,
            column: 21
          },
          end: {
            line: 50,
            column: 22
          }
        },
        loc: {
          start: {
            line: 50,
            column: 29
          },
          end: {
            line: 84,
            column: 3
          }
        },
        line: 50
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 81,
            column: 13
          },
          end: {
            line: 81,
            column: 14
          }
        },
        loc: {
          start: {
            line: 81,
            column: 23
          },
          end: {
            line: 83,
            column: 5
          }
        },
        line: 81
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 86,
            column: 19
          },
          end: {
            line: 86,
            column: 20
          }
        },
        loc: {
          start: {
            line: 86,
            column: 42
          },
          end: {
            line: 95,
            column: 3
          }
        },
        line: 86
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 87,
            column: 42
          },
          end: {
            line: 87,
            column: 43
          }
        },
        loc: {
          start: {
            line: 87,
            column: 49
          },
          end: {
            line: 89,
            column: 5
          }
        },
        line: 87
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 97,
            column: 24
          },
          end: {
            line: 97,
            column: 25
          }
        },
        loc: {
          start: {
            line: 97,
            column: 44
          },
          end: {
            line: 220,
            column: 3
          }
        },
        line: 97
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 108,
            column: 21
          },
          end: {
            line: 108,
            column: 22
          }
        },
        loc: {
          start: {
            line: 108,
            column: 27
          },
          end: {
            line: 117,
            column: 7
          }
        },
        line: 108
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 120,
            column: 22
          },
          end: {
            line: 120,
            column: 23
          }
        },
        loc: {
          start: {
            line: 120,
            column: 27
          },
          end: {
            line: 208,
            column: 5
          }
        },
        line: 120
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 130,
            column: 9
          },
          end: {
            line: 130,
            column: 10
          }
        },
        loc: {
          start: {
            line: 130,
            column: 17
          },
          end: {
            line: 207,
            column: 7
          }
        },
        line: 130
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 132,
            column: 25
          },
          end: {
            line: 132,
            column: 26
          }
        },
        loc: {
          start: {
            line: 132,
            column: 45
          },
          end: {
            line: 134,
            column: 9
          }
        },
        line: 132
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 136,
            column: 24
          },
          end: {
            line: 136,
            column: 25
          }
        },
        loc: {
          start: {
            line: 136,
            column: 33
          },
          end: {
            line: 143,
            column: 9
          }
        },
        line: 136
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 170,
            column: 35
          },
          end: {
            line: 170,
            column: 36
          }
        },
        loc: {
          start: {
            line: 170,
            column: 44
          },
          end: {
            line: 176,
            column: 15
          }
        },
        line: 170
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 181,
            column: 35
          },
          end: {
            line: 181,
            column: 36
          }
        },
        loc: {
          start: {
            line: 181,
            column: 44
          },
          end: {
            line: 187,
            column: 15
          }
        },
        line: 181
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 209,
            column: 19
          },
          end: {
            line: 209,
            column: 20
          }
        },
        loc: {
          start: {
            line: 209,
            column: 25
          },
          end: {
            line: 219,
            column: 5
          }
        },
        line: 209
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 225,
            column: 64
          },
          end: {
            line: 225,
            column: 65
          }
        },
        loc: {
          start: {
            line: 225,
            column: 72
          },
          end: {
            line: 227,
            column: 3
          }
        },
        line: 225
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 229,
            column: 21
          },
          end: {
            line: 229,
            column: 22
          }
        },
        loc: {
          start: {
            line: 229,
            column: 27
          },
          end: {
            line: 245,
            column: 3
          }
        },
        line: 229
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 249,
            column: 25
          },
          end: {
            line: 249,
            column: 26
          }
        },
        loc: {
          start: {
            line: 249,
            column: 57
          },
          end: {
            line: 265,
            column: 3
          }
        },
        line: 249
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 260,
            column: 24
          },
          end: {
            line: 260,
            column: 25
          }
        },
        loc: {
          start: {
            line: 260,
            column: 32
          },
          end: {
            line: 264,
            column: 5
          }
        },
        line: 260
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 267,
            column: 30
          },
          end: {
            line: 267,
            column: 31
          }
        },
        loc: {
          start: {
            line: 267,
            column: 46
          },
          end: {
            line: 277,
            column: 3
          }
        },
        line: 267
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 279,
            column: 23
          },
          end: {
            line: 279,
            column: 24
          }
        },
        loc: {
          start: {
            line: 279,
            column: 29
          },
          end: {
            line: 292,
            column: 3
          }
        },
        line: 279
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 281,
            column: 74
          },
          end: {
            line: 281,
            column: 75
          }
        },
        loc: {
          start: {
            line: 281,
            column: 82
          },
          end: {
            line: 291,
            column: 5
          }
        },
        line: 281
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 286,
            column: 70
          },
          end: {
            line: 286,
            column: 71
          }
        },
        loc: {
          start: {
            line: 286,
            column: 79
          },
          end: {
            line: 288,
            column: 11
          }
        },
        line: 286
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 294,
            column: 23
          },
          end: {
            line: 294,
            column: 24
          }
        },
        loc: {
          start: {
            line: 294,
            column: 40
          },
          end: {
            line: 300,
            column: 3
          }
        },
        line: 294
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 302,
            column: 33
          },
          end: {
            line: 302,
            column: 34
          }
        },
        loc: {
          start: {
            line: 302,
            column: 45
          },
          end: {
            line: 427,
            column: 3
          }
        },
        line: 302
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 307,
            column: 23
          },
          end: {
            line: 307,
            column: 24
          }
        },
        loc: {
          start: {
            line: 307,
            column: 31
          },
          end: {
            line: 426,
            column: 5
          }
        },
        line: 307
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 429,
            column: 32
          },
          end: {
            line: 429,
            column: 33
          }
        },
        loc: {
          start: {
            line: 429,
            column: 44
          },
          end: {
            line: 516,
            column: 3
          }
        },
        line: 429
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 438,
            column: 7
          },
          end: {
            line: 438,
            column: 8
          }
        },
        loc: {
          start: {
            line: 438,
            column: 15
          },
          end: {
            line: 515,
            column: 5
          }
        },
        line: 438
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 439,
            column: 23
          },
          end: {
            line: 439,
            column: 24
          }
        },
        loc: {
          start: {
            line: 439,
            column: 43
          },
          end: {
            line: 441,
            column: 7
          }
        },
        line: 439
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 443,
            column: 22
          },
          end: {
            line: 443,
            column: 23
          }
        },
        loc: {
          start: {
            line: 443,
            column: 31
          },
          end: {
            line: 450,
            column: 7
          }
        },
        line: 443
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 478,
            column: 33
          },
          end: {
            line: 478,
            column: 34
          }
        },
        loc: {
          start: {
            line: 478,
            column: 42
          },
          end: {
            line: 484,
            column: 13
          }
        },
        line: 478
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 489,
            column: 33
          },
          end: {
            line: 489,
            column: 34
          }
        },
        loc: {
          start: {
            line: 489,
            column: 42
          },
          end: {
            line: 495,
            column: 13
          }
        },
        line: 489
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 518,
            column: 19
          },
          end: {
            line: 518,
            column: 20
          }
        },
        loc: {
          start: {
            line: 518,
            column: 25
          },
          end: {
            line: 545,
            column: 3
          }
        },
        line: 518
      },
      '33': {
        name: '(anonymous_33)',
        decl: {
          start: {
            line: 532,
            column: 26
          },
          end: {
            line: 532,
            column: 27
          }
        },
        loc: {
          start: {
            line: 532,
            column: 41
          },
          end: {
            line: 543,
            column: 5
          }
        },
        line: 532
      },
      '34': {
        name: '(anonymous_34)',
        decl: {
          start: {
            line: 546,
            column: 24
          },
          end: {
            line: 546,
            column: 25
          }
        },
        loc: {
          start: {
            line: 546,
            column: 47
          },
          end: {
            line: 578,
            column: 3
          }
        },
        line: 546
      },
      '35': {
        name: '(anonymous_35)',
        decl: {
          start: {
            line: 571,
            column: 16
          },
          end: {
            line: 571,
            column: 17
          }
        },
        loc: {
          start: {
            line: 571,
            column: 27
          },
          end: {
            line: 574,
            column: 5
          }
        },
        line: 571
      },
      '36': {
        name: '(anonymous_36)',
        decl: {
          start: {
            line: 580,
            column: 25
          },
          end: {
            line: 580,
            column: 26
          }
        },
        loc: {
          start: {
            line: 580,
            column: 31
          },
          end: {
            line: 671,
            column: 3
          }
        },
        line: 580
      },
      '37': {
        name: '(anonymous_37)',
        decl: {
          start: {
            line: 636,
            column: 66
          },
          end: {
            line: 636,
            column: 67
          }
        },
        loc: {
          start: {
            line: 636,
            column: 82
          },
          end: {
            line: 651,
            column: 5
          }
        },
        line: 636
      },
      '38': {
        name: '(anonymous_38)',
        decl: {
          start: {
            line: 673,
            column: 30
          },
          end: {
            line: 673,
            column: 31
          }
        },
        loc: {
          start: {
            line: 673,
            column: 42
          },
          end: {
            line: 718,
            column: 3
          }
        },
        line: 673
      },
      '39': {
        name: '(anonymous_39)',
        decl: {
          start: {
            line: 687,
            column: 7
          },
          end: {
            line: 687,
            column: 8
          }
        },
        loc: {
          start: {
            line: 687,
            column: 15
          },
          end: {
            line: 717,
            column: 5
          }
        },
        line: 687
      },
      '40': {
        name: '(anonymous_40)',
        decl: {
          start: {
            line: 707,
            column: 51
          },
          end: {
            line: 707,
            column: 52
          }
        },
        loc: {
          start: {
            line: 707,
            column: 65
          },
          end: {
            line: 715,
            column: 9
          }
        },
        line: 707
      },
      '41': {
        name: '(anonymous_41)',
        decl: {
          start: {
            line: 719,
            column: 39
          },
          end: {
            line: 719,
            column: 40
          }
        },
        loc: {
          start: {
            line: 719,
            column: 45
          },
          end: {
            line: 722,
            column: 3
          }
        },
        line: 719
      },
      '42': {
        name: '(anonymous_42)',
        decl: {
          start: {
            line: 721,
            column: 61
          },
          end: {
            line: 721,
            column: 62
          }
        },
        loc: {
          start: {
            line: 721,
            column: 71
          },
          end: {
            line: 721,
            column: 94
          }
        },
        line: 721
      },
      '43': {
        name: '(anonymous_43)',
        decl: {
          start: {
            line: 726,
            column: 26
          },
          end: {
            line: 726,
            column: 27
          }
        },
        loc: {
          start: {
            line: 726,
            column: 38
          },
          end: {
            line: 734,
            column: 3
          }
        },
        line: 726
      },
      '44': {
        name: '(anonymous_44)',
        decl: {
          start: {
            line: 728,
            column: 47
          },
          end: {
            line: 728,
            column: 48
          }
        },
        loc: {
          start: {
            line: 728,
            column: 56
          },
          end: {
            line: 730,
            column: 7
          }
        },
        line: 728
      },
      '45': {
        name: '(anonymous_45)',
        decl: {
          start: {
            line: 737,
            column: 56
          },
          end: {
            line: 737,
            column: 57
          }
        },
        loc: {
          start: {
            line: 737,
            column: 64
          },
          end: {
            line: 748,
            column: 3
          }
        },
        line: 737
      },
      '46': {
        name: '(anonymous_46)',
        decl: {
          start: {
            line: 744,
            column: 27
          },
          end: {
            line: 744,
            column: 28
          }
        },
        loc: {
          start: {
            line: 744,
            column: 40
          },
          end: {
            line: 744,
            column: 69
          }
        },
        line: 744
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 27,
            column: 4
          },
          end: {
            line: 46,
            column: 5
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 30,
            column: 14
          }
        }, {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 14
          }
        }, {
          start: {
            line: 34,
            column: 6
          },
          end: {
            line: 36,
            column: 14
          }
        }, {
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 39,
            column: 14
          }
        }, {
          start: {
            line: 40,
            column: 6
          },
          end: {
            line: 42,
            column: 14
          }
        }, {
          start: {
            line: 43,
            column: 6
          },
          end: {
            line: 45,
            column: 14
          }
        }],
        line: 27
      },
      '1': {
        loc: {
          start: {
            line: 51,
            column: 12
          },
          end: {
            line: 81,
            column: 6
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 51,
            column: 24
          },
          end: {
            line: 66,
            column: 6
          }
        }, {
          start: {
            line: 66,
            column: 9
          },
          end: {
            line: 81,
            column: 6
          }
        }],
        line: 51
      },
      '2': {
        loc: {
          start: {
            line: 82,
            column: 13
          },
          end: {
            line: 82,
            column: 45
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 82,
            column: 39
          },
          end: {
            line: 82,
            column: 41
          }
        }, {
          start: {
            line: 82,
            column: 44
          },
          end: {
            line: 82,
            column: 45
          }
        }],
        line: 82
      },
      '3': {
        loc: {
          start: {
            line: 91,
            column: 4
          },
          end: {
            line: 93,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 91,
            column: 4
          },
          end: {
            line: 93,
            column: 5
          }
        }, {
          start: {
            line: 91,
            column: 4
          },
          end: {
            line: 93,
            column: 5
          }
        }],
        line: 91
      },
      '4': {
        loc: {
          start: {
            line: 94,
            column: 11
          },
          end: {
            line: 94,
            column: 67
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 94,
            column: 23
          },
          end: {
            line: 94,
            column: 43
          }
        }, {
          start: {
            line: 94,
            column: 46
          },
          end: {
            line: 94,
            column: 67
          }
        }],
        line: 94
      },
      '5': {
        loc: {
          start: {
            line: 98,
            column: 4
          },
          end: {
            line: 100,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 98,
            column: 4
          },
          end: {
            line: 100,
            column: 5
          }
        }, {
          start: {
            line: 98,
            column: 4
          },
          end: {
            line: 100,
            column: 5
          }
        }],
        line: 98
      },
      '6': {
        loc: {
          start: {
            line: 101,
            column: 4
          },
          end: {
            line: 103,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 101,
            column: 4
          },
          end: {
            line: 103,
            column: 5
          }
        }, {
          start: {
            line: 101,
            column: 4
          },
          end: {
            line: 103,
            column: 5
          }
        }],
        line: 101
      },
      '7': {
        loc: {
          start: {
            line: 107,
            column: 4
          },
          end: {
            line: 119,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 107,
            column: 4
          },
          end: {
            line: 119,
            column: 5
          }
        }, {
          start: {
            line: 107,
            column: 4
          },
          end: {
            line: 119,
            column: 5
          }
        }],
        line: 107
      },
      '8': {
        loc: {
          start: {
            line: 127,
            column: 12
          },
          end: {
            line: 127,
            column: 93
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 127,
            column: 46
          },
          end: {
            line: 127,
            column: 53
          }
        }, {
          start: {
            line: 127,
            column: 57
          },
          end: {
            line: 127,
            column: 92
          }
        }],
        line: 127
      },
      '9': {
        loc: {
          start: {
            line: 128,
            column: 13
          },
          end: {
            line: 128,
            column: 52
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 128,
            column: 47
          },
          end: {
            line: 128,
            column: 48
          }
        }, {
          start: {
            line: 128,
            column: 51
          },
          end: {
            line: 128,
            column: 52
          }
        }],
        line: 128
      },
      '10': {
        loc: {
          start: {
            line: 133,
            column: 17
          },
          end: {
            line: 133,
            column: 47
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 133,
            column: 41
          },
          end: {
            line: 133,
            column: 42
          }
        }, {
          start: {
            line: 133,
            column: 45
          },
          end: {
            line: 133,
            column: 47
          }
        }],
        line: 133
      },
      '11': {
        loc: {
          start: {
            line: 139,
            column: 10
          },
          end: {
            line: 141,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 139,
            column: 10
          },
          end: {
            line: 141,
            column: 11
          }
        }, {
          start: {
            line: 139,
            column: 10
          },
          end: {
            line: 141,
            column: 11
          }
        }],
        line: 139
      },
      '12': {
        loc: {
          start: {
            line: 172,
            column: 16
          },
          end: {
            line: 174,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 172,
            column: 16
          },
          end: {
            line: 174,
            column: 17
          }
        }, {
          start: {
            line: 172,
            column: 16
          },
          end: {
            line: 174,
            column: 17
          }
        }],
        line: 172
      },
      '13': {
        loc: {
          start: {
            line: 183,
            column: 16
          },
          end: {
            line: 185,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 183,
            column: 16
          },
          end: {
            line: 185,
            column: 17
          }
        }, {
          start: {
            line: 183,
            column: 16
          },
          end: {
            line: 185,
            column: 17
          }
        }],
        line: 183
      },
      '14': {
        loc: {
          start: {
            line: 213,
            column: 17
          },
          end: {
            line: 213,
            column: 33
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 213,
            column: 28
          },
          end: {
            line: 213,
            column: 29
          }
        }, {
          start: {
            line: 213,
            column: 32
          },
          end: {
            line: 213,
            column: 33
          }
        }],
        line: 213
      },
      '15': {
        loc: {
          start: {
            line: 216,
            column: 21
          },
          end: {
            line: 216,
            column: 39
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 216,
            column: 32
          },
          end: {
            line: 216,
            column: 35
          }
        }, {
          start: {
            line: 216,
            column: 38
          },
          end: {
            line: 216,
            column: 39
          }
        }],
        line: 216
      },
      '16': {
        loc: {
          start: {
            line: 230,
            column: 4
          },
          end: {
            line: 232,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 230,
            column: 4
          },
          end: {
            line: 232,
            column: 5
          }
        }, {
          start: {
            line: 230,
            column: 4
          },
          end: {
            line: 232,
            column: 5
          }
        }],
        line: 230
      },
      '17': {
        loc: {
          start: {
            line: 230,
            column: 8
          },
          end: {
            line: 230,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 230,
            column: 8
          },
          end: {
            line: 230,
            column: 29
          }
        }, {
          start: {
            line: 230,
            column: 33
          },
          end: {
            line: 230,
            column: 49
          }
        }],
        line: 230
      },
      '18': {
        loc: {
          start: {
            line: 233,
            column: 4
          },
          end: {
            line: 235,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 233,
            column: 4
          },
          end: {
            line: 235,
            column: 5
          }
        }, {
          start: {
            line: 233,
            column: 4
          },
          end: {
            line: 235,
            column: 5
          }
        }],
        line: 233
      },
      '19': {
        loc: {
          start: {
            line: 237,
            column: 4
          },
          end: {
            line: 239,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 237,
            column: 4
          },
          end: {
            line: 239,
            column: 5
          }
        }, {
          start: {
            line: 237,
            column: 4
          },
          end: {
            line: 239,
            column: 5
          }
        }],
        line: 237
      },
      '20': {
        loc: {
          start: {
            line: 241,
            column: 4
          },
          end: {
            line: 243,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 241,
            column: 4
          },
          end: {
            line: 243,
            column: 5
          }
        }, {
          start: {
            line: 241,
            column: 4
          },
          end: {
            line: 243,
            column: 5
          }
        }],
        line: 241
      },
      '21': {
        loc: {
          start: {
            line: 255,
            column: 10
          },
          end: {
            line: 255,
            column: 56
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 255,
            column: 44
          },
          end: {
            line: 255,
            column: 46
          }
        }, {
          start: {
            line: 255,
            column: 49
          },
          end: {
            line: 255,
            column: 56
          }
        }],
        line: 255
      },
      '22': {
        loc: {
          start: {
            line: 257,
            column: 11
          },
          end: {
            line: 257,
            column: 50
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 257,
            column: 45
          },
          end: {
            line: 257,
            column: 46
          }
        }, {
          start: {
            line: 257,
            column: 49
          },
          end: {
            line: 257,
            column: 50
          }
        }],
        line: 257
      },
      '23': {
        loc: {
          start: {
            line: 258,
            column: 12
          },
          end: {
            line: 258,
            column: 93
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 258,
            column: 46
          },
          end: {
            line: 258,
            column: 53
          }
        }, {
          start: {
            line: 258,
            column: 57
          },
          end: {
            line: 258,
            column: 92
          }
        }],
        line: 258
      },
      '24': {
        loc: {
          start: {
            line: 260,
            column: 8
          },
          end: {
            line: 260,
            column: 20
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 260,
            column: 8
          },
          end: {
            line: 260,
            column: 14
          }
        }, {
          start: {
            line: 260,
            column: 18
          },
          end: {
            line: 260,
            column: 20
          }
        }],
        line: 260
      },
      '25': {
        loc: {
          start: {
            line: 261,
            column: 6
          },
          end: {
            line: 263,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 261,
            column: 6
          },
          end: {
            line: 263,
            column: 7
          }
        }, {
          start: {
            line: 261,
            column: 6
          },
          end: {
            line: 263,
            column: 7
          }
        }],
        line: 261
      },
      '26': {
        loc: {
          start: {
            line: 268,
            column: 4
          },
          end: {
            line: 272,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 268,
            column: 4
          },
          end: {
            line: 272,
            column: 5
          }
        }, {
          start: {
            line: 268,
            column: 4
          },
          end: {
            line: 272,
            column: 5
          }
        }],
        line: 268
      },
      '27': {
        loc: {
          start: {
            line: 282,
            column: 6
          },
          end: {
            line: 290,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 282,
            column: 6
          },
          end: {
            line: 290,
            column: 7
          }
        }, {
          start: {
            line: 282,
            column: 6
          },
          end: {
            line: 290,
            column: 7
          }
        }],
        line: 282
      },
      '28': {
        loc: {
          start: {
            line: 285,
            column: 8
          },
          end: {
            line: 289,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 285,
            column: 8
          },
          end: {
            line: 289,
            column: 9
          }
        }, {
          start: {
            line: 285,
            column: 8
          },
          end: {
            line: 289,
            column: 9
          }
        }],
        line: 285
      },
      '29': {
        loc: {
          start: {
            line: 304,
            column: 4
          },
          end: {
            line: 306,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 304,
            column: 4
          },
          end: {
            line: 306,
            column: 5
          }
        }, {
          start: {
            line: 304,
            column: 4
          },
          end: {
            line: 306,
            column: 5
          }
        }],
        line: 304
      },
      '30': {
        loc: {
          start: {
            line: 314,
            column: 6
          },
          end: {
            line: 350,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 314,
            column: 6
          },
          end: {
            line: 350,
            column: 7
          }
        }, {
          start: {
            line: 314,
            column: 6
          },
          end: {
            line: 350,
            column: 7
          }
        }],
        line: 314
      },
      '31': {
        loc: {
          start: {
            line: 331,
            column: 82
          },
          end: {
            line: 331,
            column: 139
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 331,
            column: 112
          },
          end: {
            line: 331,
            column: 115
          }
        }, {
          start: {
            line: 331,
            column: 118
          },
          end: {
            line: 331,
            column: 139
          }
        }],
        line: 331
      },
      '32': {
        loc: {
          start: {
            line: 336,
            column: 24
          },
          end: {
            line: 336,
            column: 81
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 336,
            column: 54
          },
          end: {
            line: 336,
            column: 57
          }
        }, {
          start: {
            line: 336,
            column: 60
          },
          end: {
            line: 336,
            column: 81
          }
        }],
        line: 336
      },
      '33': {
        loc: {
          start: {
            line: 339,
            column: 33
          },
          end: {
            line: 339,
            column: 90
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 339,
            column: 63
          },
          end: {
            line: 339,
            column: 66
          }
        }, {
          start: {
            line: 339,
            column: 69
          },
          end: {
            line: 339,
            column: 90
          }
        }],
        line: 339
      },
      '34': {
        loc: {
          start: {
            line: 341,
            column: 37
          },
          end: {
            line: 341,
            column: 73
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 341,
            column: 68
          },
          end: {
            line: 341,
            column: 69
          }
        }, {
          start: {
            line: 341,
            column: 72
          },
          end: {
            line: 341,
            column: 73
          }
        }],
        line: 341
      },
      '35': {
        loc: {
          start: {
            line: 352,
            column: 6
          },
          end: {
            line: 381,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 352,
            column: 6
          },
          end: {
            line: 381,
            column: 7
          }
        }, {
          start: {
            line: 352,
            column: 6
          },
          end: {
            line: 381,
            column: 7
          }
        }],
        line: 352
      },
      '36': {
        loc: {
          start: {
            line: 366,
            column: 81
          },
          end: {
            line: 366,
            column: 138
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 366,
            column: 111
          },
          end: {
            line: 366,
            column: 114
          }
        }, {
          start: {
            line: 366,
            column: 117
          },
          end: {
            line: 366,
            column: 138
          }
        }],
        line: 366
      },
      '37': {
        loc: {
          start: {
            line: 371,
            column: 22
          },
          end: {
            line: 371,
            column: 79
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 371,
            column: 52
          },
          end: {
            line: 371,
            column: 55
          }
        }, {
          start: {
            line: 371,
            column: 58
          },
          end: {
            line: 371,
            column: 79
          }
        }],
        line: 371
      },
      '38': {
        loc: {
          start: {
            line: 374,
            column: 31
          },
          end: {
            line: 374,
            column: 88
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 374,
            column: 61
          },
          end: {
            line: 374,
            column: 64
          }
        }, {
          start: {
            line: 374,
            column: 67
          },
          end: {
            line: 374,
            column: 88
          }
        }],
        line: 374
      },
      '39': {
        loc: {
          start: {
            line: 376,
            column: 35
          },
          end: {
            line: 376,
            column: 71
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 376,
            column: 66
          },
          end: {
            line: 376,
            column: 67
          }
        }, {
          start: {
            line: 376,
            column: 70
          },
          end: {
            line: 376,
            column: 71
          }
        }],
        line: 376
      },
      '40': {
        loc: {
          start: {
            line: 383,
            column: 6
          },
          end: {
            line: 425,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 383,
            column: 6
          },
          end: {
            line: 425,
            column: 7
          }
        }, {
          start: {
            line: 383,
            column: 6
          },
          end: {
            line: 425,
            column: 7
          }
        }],
        line: 383
      },
      '41': {
        loc: {
          start: {
            line: 383,
            column: 10
          },
          end: {
            line: 383,
            column: 56
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 383,
            column: 10
          },
          end: {
            line: 383,
            column: 31
          }
        }, {
          start: {
            line: 383,
            column: 35
          },
          end: {
            line: 383,
            column: 56
          }
        }],
        line: 383
      },
      '42': {
        loc: {
          start: {
            line: 388,
            column: 8
          },
          end: {
            line: 424,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 388,
            column: 8
          },
          end: {
            line: 424,
            column: 9
          }
        }, {
          start: {
            line: 388,
            column: 8
          },
          end: {
            line: 424,
            column: 9
          }
        }],
        line: 388
      },
      '43': {
        loc: {
          start: {
            line: 390,
            column: 10
          },
          end: {
            line: 392,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 390,
            column: 10
          },
          end: {
            line: 392,
            column: 11
          }
        }, {
          start: {
            line: 390,
            column: 10
          },
          end: {
            line: 392,
            column: 11
          }
        }],
        line: 390
      },
      '44': {
        loc: {
          start: {
            line: 393,
            column: 10
          },
          end: {
            line: 395,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 393,
            column: 10
          },
          end: {
            line: 395,
            column: 11
          }
        }, {
          start: {
            line: 393,
            column: 10
          },
          end: {
            line: 395,
            column: 11
          }
        }],
        line: 393
      },
      '45': {
        loc: {
          start: {
            line: 419,
            column: 37
          },
          end: {
            line: 419,
            column: 58
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 419,
            column: 53
          },
          end: {
            line: 419,
            column: 54
          }
        }, {
          start: {
            line: 419,
            column: 57
          },
          end: {
            line: 419,
            column: 58
          }
        }],
        line: 419
      },
      '46': {
        loc: {
          start: {
            line: 430,
            column: 4
          },
          end: {
            line: 432,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 430,
            column: 4
          },
          end: {
            line: 432,
            column: 5
          }
        }, {
          start: {
            line: 430,
            column: 4
          },
          end: {
            line: 432,
            column: 5
          }
        }],
        line: 430
      },
      '47': {
        loc: {
          start: {
            line: 436,
            column: 10
          },
          end: {
            line: 436,
            column: 91
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 436,
            column: 44
          },
          end: {
            line: 436,
            column: 51
          }
        }, {
          start: {
            line: 436,
            column: 55
          },
          end: {
            line: 436,
            column: 90
          }
        }],
        line: 436
      },
      '48': {
        loc: {
          start: {
            line: 437,
            column: 11
          },
          end: {
            line: 437,
            column: 50
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 437,
            column: 45
          },
          end: {
            line: 437,
            column: 46
          }
        }, {
          start: {
            line: 437,
            column: 49
          },
          end: {
            line: 437,
            column: 50
          }
        }],
        line: 437
      },
      '49': {
        loc: {
          start: {
            line: 440,
            column: 15
          },
          end: {
            line: 440,
            column: 45
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 440,
            column: 39
          },
          end: {
            line: 440,
            column: 40
          }
        }, {
          start: {
            line: 440,
            column: 43
          },
          end: {
            line: 440,
            column: 45
          }
        }],
        line: 440
      },
      '50': {
        loc: {
          start: {
            line: 446,
            column: 8
          },
          end: {
            line: 448,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 446,
            column: 8
          },
          end: {
            line: 448,
            column: 9
          }
        }, {
          start: {
            line: 446,
            column: 8
          },
          end: {
            line: 448,
            column: 9
          }
        }],
        line: 446
      },
      '51': {
        loc: {
          start: {
            line: 480,
            column: 14
          },
          end: {
            line: 482,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 480,
            column: 14
          },
          end: {
            line: 482,
            column: 15
          }
        }, {
          start: {
            line: 480,
            column: 14
          },
          end: {
            line: 482,
            column: 15
          }
        }],
        line: 480
      },
      '52': {
        loc: {
          start: {
            line: 491,
            column: 14
          },
          end: {
            line: 493,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 491,
            column: 14
          },
          end: {
            line: 493,
            column: 15
          }
        }, {
          start: {
            line: 491,
            column: 14
          },
          end: {
            line: 493,
            column: 15
          }
        }],
        line: 491
      },
      '53': {
        loc: {
          start: {
            line: 538,
            column: 30
          },
          end: {
            line: 538,
            column: 78
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 538,
            column: 49
          },
          end: {
            line: 538,
            column: 74
          }
        }, {
          start: {
            line: 538,
            column: 77
          },
          end: {
            line: 538,
            column: 78
          }
        }],
        line: 538
      },
      '54': {
        loc: {
          start: {
            line: 539,
            column: 13
          },
          end: {
            line: 539,
            column: 93
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 539,
            column: 32
          },
          end: {
            line: 539,
            column: 86
          }
        }, {
          start: {
            line: 539,
            column: 89
          },
          end: {
            line: 539,
            column: 93
          }
        }],
        line: 539
      },
      '55': {
        loc: {
          start: {
            line: 540,
            column: 11
          },
          end: {
            line: 540,
            column: 114
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 540,
            column: 28
          },
          end: {
            line: 540,
            column: 109
          }
        }, {
          start: {
            line: 540,
            column: 112
          },
          end: {
            line: 540,
            column: 114
          }
        }],
        line: 540
      },
      '56': {
        loc: {
          start: {
            line: 585,
            column: 6
          },
          end: {
            line: 587,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 585,
            column: 6
          },
          end: {
            line: 587,
            column: 7
          }
        }, {
          start: {
            line: 585,
            column: 6
          },
          end: {
            line: 587,
            column: 7
          }
        }],
        line: 585
      },
      '57': {
        loc: {
          start: {
            line: 642,
            column: 8
          },
          end: {
            line: 646,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 642,
            column: 8
          },
          end: {
            line: 646,
            column: 9
          }
        }, {
          start: {
            line: 642,
            column: 8
          },
          end: {
            line: 646,
            column: 9
          }
        }],
        line: 642
      },
      '58': {
        loc: {
          start: {
            line: 648,
            column: 6
          },
          end: {
            line: 650,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 648,
            column: 6
          },
          end: {
            line: 650,
            column: 7
          }
        }, {
          start: {
            line: 648,
            column: 6
          },
          end: {
            line: 650,
            column: 7
          }
        }],
        line: 648
      },
      '59': {
        loc: {
          start: {
            line: 674,
            column: 4
          },
          end: {
            line: 676,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 674,
            column: 4
          },
          end: {
            line: 676,
            column: 5
          }
        }, {
          start: {
            line: 674,
            column: 4
          },
          end: {
            line: 676,
            column: 5
          }
        }],
        line: 674
      },
      '60': {
        loc: {
          start: {
            line: 679,
            column: 4
          },
          end: {
            line: 681,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 679,
            column: 4
          },
          end: {
            line: 681,
            column: 5
          }
        }, {
          start: {
            line: 679,
            column: 4
          },
          end: {
            line: 681,
            column: 5
          }
        }],
        line: 679
      },
      '61': {
        loc: {
          start: {
            line: 685,
            column: 14
          },
          end: {
            line: 685,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 685,
            column: 14
          },
          end: {
            line: 685,
            column: 32
          }
        }, {
          start: {
            line: 685,
            column: 36
          },
          end: {
            line: 685,
            column: 50
          }
        }],
        line: 685
      },
      '62': {
        loc: {
          start: {
            line: 690,
            column: 6
          },
          end: {
            line: 700,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 690,
            column: 6
          },
          end: {
            line: 700,
            column: 7
          }
        }, {
          start: {
            line: 690,
            column: 6
          },
          end: {
            line: 700,
            column: 7
          }
        }],
        line: 690
      },
      '63': {
        loc: {
          start: {
            line: 690,
            column: 10
          },
          end: {
            line: 690,
            column: 115
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 690,
            column: 10
          },
          end: {
            line: 690,
            column: 34
          }
        }, {
          start: {
            line: 690,
            column: 38
          },
          end: {
            line: 690,
            column: 73
          }
        }, {
          start: {
            line: 690,
            column: 77
          },
          end: {
            line: 690,
            column: 115
          }
        }],
        line: 690
      },
      '64': {
        loc: {
          start: {
            line: 695,
            column: 13
          },
          end: {
            line: 700,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 695,
            column: 13
          },
          end: {
            line: 700,
            column: 7
          }
        }, {
          start: {
            line: 695,
            column: 13
          },
          end: {
            line: 700,
            column: 7
          }
        }],
        line: 695
      },
      '65': {
        loc: {
          start: {
            line: 702,
            column: 6
          },
          end: {
            line: 716,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 702,
            column: 6
          },
          end: {
            line: 716,
            column: 7
          }
        }, {
          start: {
            line: 702,
            column: 6
          },
          end: {
            line: 716,
            column: 7
          }
        }],
        line: 702
      },
      '66': {
        loc: {
          start: {
            line: 712,
            column: 10
          },
          end: {
            line: 714,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 712,
            column: 10
          },
          end: {
            line: 714,
            column: 11
          }
        }, {
          start: {
            line: 712,
            column: 10
          },
          end: {
            line: 714,
            column: 11
          }
        }],
        line: 712
      },
      '67': {
        loc: {
          start: {
            line: 712,
            column: 14
          },
          end: {
            line: 712,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 712,
            column: 14
          },
          end: {
            line: 712,
            column: 40
          }
        }, {
          start: {
            line: 712,
            column: 44
          },
          end: {
            line: 712,
            column: 64
          }
        }],
        line: 712
      },
      '68': {
        loc: {
          start: {
            line: 720,
            column: 23
          },
          end: {
            line: 720,
            column: 92
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 720,
            column: 23
          },
          end: {
            line: 720,
            column: 87
          }
        }, {
          start: {
            line: 720,
            column: 91
          },
          end: {
            line: 720,
            column: 92
          }
        }],
        line: 720
      },
      '69': {
        loc: {
          start: {
            line: 721,
            column: 25
          },
          end: {
            line: 721,
            column: 133
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 721,
            column: 25
          },
          end: {
            line: 721,
            column: 96
          }
        }, {
          start: {
            line: 721,
            column: 100
          },
          end: {
            line: 721,
            column: 133
          }
        }],
        line: 721
      },
      '70': {
        loc: {
          start: {
            line: 727,
            column: 4
          },
          end: {
            line: 733,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 727,
            column: 4
          },
          end: {
            line: 733,
            column: 5
          }
        }, {
          start: {
            line: 727,
            column: 4
          },
          end: {
            line: 733,
            column: 5
          }
        }],
        line: 727
      },
      '71': {
        loc: {
          start: {
            line: 744,
            column: 6
          },
          end: {
            line: 745,
            column: 24
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 744,
            column: 6
          },
          end: {
            line: 744,
            column: 71
          }
        }, {
          start: {
            line: 745,
            column: 6
          },
          end: {
            line: 745,
            column: 24
          }
        }],
        line: 744
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0,
      '248': 0,
      '249': 0,
      '250': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0
    },
    b: {
      '0': [0, 0, 0, 0, 0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0],
      '58': [0, 0],
      '59': [0, 0],
      '60': [0, 0],
      '61': [0, 0],
      '62': [0, 0],
      '63': [0, 0, 0],
      '64': [0, 0],
      '65': [0, 0],
      '66': [0, 0],
      '67': [0, 0],
      '68': [0, 0],
      '69': [0, 0],
      '70': [0, 0],
      '71': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

cov_jxk99dp4z.s[0]++;
angular.module('app').controller('activityDashboardGlobalCtrl', function ($scope, $rootScope, $CRUDService, $deltaPeriode, $filter, $deltaActor, $stateParams, $deltaGeoLevel, $http, $deltadate, $translate, $log) {
  cov_jxk99dp4z.f[0]++;

  var PATH = (cov_jxk99dp4z.s[1]++, 'Activites');
  var PATH_LOCALITY = (cov_jxk99dp4z.s[2]++, 'Localites');
  var PATH_CL = (cov_jxk99dp4z.s[3]++, 'CadreLogique');
  var PATH_DN = (cov_jxk99dp4z.s[4]++, 'DernierNiveau');

  var ACTIVITY_DASHBOARD_PROJECT_GLOBAL_ACTIVITY = (cov_jxk99dp4z.s[5]++, 'ACTIVITY_DASHBOARD_PROJECT_GLOBAL_ACTIVITY' + $rootScope.currentProject.project.id);

  var ACTIVITY_DASHBOARD_PROJECT_GLOBAL_GEO_PLAN = (cov_jxk99dp4z.s[6]++, 'ACTIVITY_DASHBOARD_PROJECT_GLOBAL_GEO_PLAN' + $rootScope.currentProject.project.id);

  // $scope.height = ($('#side-menu').height() - 350) + 'px';
  cov_jxk99dp4z.s[7]++;
  $scope.height = $('body').height() - 548 + 'px';
  // Initialize DashboardLayout component
  cov_jxk99dp4z.s[8]++;
  $scope.choise = {
    displayIndex: 0
  };
  cov_jxk99dp4z.s[9]++;
  $scope.mapData = {};
  cov_jxk99dp4z.s[10]++;
  $scope.mapCenter = null;

  cov_jxk99dp4z.s[11]++;
  $scope.canClose = $stateParams.view_item == 1;

  cov_jxk99dp4z.s[12]++;
  var getMapUrl = function getMapUrl(value) {
    cov_jxk99dp4z.f[1]++;

    var mapstr = (cov_jxk99dp4z.s[13]++, 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}');
    // L.tileLayer(mapstr).addTo(this.layerGroup);
    // let mapstr = '';
    // const value = ($scope.displayCity ? 1 : 0) + ($scope.displaySatelite ? 2 : 0);
    cov_jxk99dp4z.s[14]++;
    switch (value) {
      case 0:
        cov_jxk99dp4z.b[0][0]++;
        cov_jxk99dp4z.s[15]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}';
        cov_jxk99dp4z.s[16]++;
        break;
      case 1:
        cov_jxk99dp4z.b[0][1]++;
        cov_jxk99dp4z.s[17]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvs9jqk60fr91cscf0w98zth/tiles/256/{z}/{x}/{y}@2x';
        cov_jxk99dp4z.s[18]++;
        break;
      case 2:
        cov_jxk99dp4z.b[0][2]++;
        cov_jxk99dp4z.s[19]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbh28818ic1cmqcfaz1is2/tiles/256/{z}/{x}/{y}@2x';
        cov_jxk99dp4z.s[20]++;
        break;
      case 3:
        cov_jxk99dp4z.b[0][3]++;
        cov_jxk99dp4z.s[21]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbjkcx0mkz1cp3ot9cdfnk/tiles/256/{z}/{x}/{y}@2x';
        cov_jxk99dp4z.s[22]++;
        break;
      case 4:
        cov_jxk99dp4z.b[0][4]++;
        cov_jxk99dp4z.s[23]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/ck0wcr1og05gz1cq7ik9y0ct5/tiles/256/{z}/{x}/{y}@2x';
        cov_jxk99dp4z.s[24]++;
        break;
      default:
        cov_jxk99dp4z.b[0][5]++;
        cov_jxk99dp4z.s[25]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cj8x1v3iv05pz2rrtc9ps1tui/tiles/256/{z}/{x}/{y}';
        cov_jxk99dp4z.s[26]++;
        break;
    }
    cov_jxk99dp4z.s[27]++;
    return mapstr + '?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew';
  };

  cov_jxk99dp4z.s[28]++;
  var getColors = function getColors(type) {
    cov_jxk99dp4z.f[2]++;
    cov_jxk99dp4z.s[29]++;

    return (type == 1 ? (cov_jxk99dp4z.b[1][0]++, [{
      valueMax: 20,
      color: 'rgba(242,0,5,0.95)'
    }, {
      valueMax: 40,
      color: 'rgba(242,112,0,0.95)'
    }, {
      valueMax: 60,
      color: 'rgba(203,242,0,0.95)'
    }, {
      valueMax: 80,
      color: 'rgba(96,242,0,0.95)'
    }, {
      valueMax: 100,
      color: 'rgba(0,242,96,0.95)'
    }]) : (cov_jxk99dp4z.b[1][1]++, [{
      valueMax: 100,
      color: 'rgba(242,0,5,0.95)'
    }, {
      valueMax: 80,
      color: 'rgba(242,112,0,0.95)'
    }, {
      valueMax: 60,
      color: 'rgba(203,242,0,0.95)'
    }, {
      valueMax: 40,
      color: 'rgba(96,242,0,0.95)'
    }, {
      valueMax: 20,
      color: 'rgba(0,242,96,0.95)'
    }])).sort(function (a, b) {
      cov_jxk99dp4z.f[3]++;
      cov_jxk99dp4z.s[30]++;

      return a.valueMax < b.valueMax ? (cov_jxk99dp4z.b[2][0]++, -1) : (cov_jxk99dp4z.b[2][1]++, 1);
    });
  };

  cov_jxk99dp4z.s[31]++;
  var getColor = function getColor(value, type) {
    cov_jxk99dp4z.f[4]++;

    var colors = (cov_jxk99dp4z.s[32]++, getColors(type).filter(function (val) {
      cov_jxk99dp4z.f[5]++;
      cov_jxk99dp4z.s[33]++;

      return val.valueMax > value;
    }));

    cov_jxk99dp4z.s[34]++;
    if (colors.length > 0) {
      cov_jxk99dp4z.b[3][0]++;
      cov_jxk99dp4z.s[35]++;

      return colors[0].color;
    } else {
      cov_jxk99dp4z.b[3][1]++;
    }
    cov_jxk99dp4z.s[36]++;
    return type != 1 ? (cov_jxk99dp4z.b[4][0]++, 'rgba(242,0,5,0.95)') : (cov_jxk99dp4z.b[4][1]++, 'rgba(0,242,96,0.95)');
  };

  cov_jxk99dp4z.s[37]++;
  var customlayers = function customlayers(layerId, value) {
    cov_jxk99dp4z.f[6]++;
    cov_jxk99dp4z.s[38]++;

    if (!layerId) {
      cov_jxk99dp4z.b[5][0]++;
      cov_jxk99dp4z.s[39]++;

      return;
    } else {
      cov_jxk99dp4z.b[5][1]++;
    }
    cov_jxk99dp4z.s[40]++;
    if (!$scope.layersGroup.hasLayer(layerId)) {
      cov_jxk99dp4z.b[6][0]++;
      cov_jxk99dp4z.s[41]++;

      return;
    } else {
      cov_jxk99dp4z.b[6][1]++;
    }

    var layer = (cov_jxk99dp4z.s[42]++, $scope.layersGroup.getLayer(layerId));
    cov_jxk99dp4z.s[43]++;
    layer.off();
    cov_jxk99dp4z.s[44]++;
    if (!value) {
      cov_jxk99dp4z.b[7][0]++;
      cov_jxk99dp4z.s[45]++;

      layer.setStyle(function () {
        cov_jxk99dp4z.f[7]++;
        cov_jxk99dp4z.s[46]++;

        return {
          fillColor: getColor(0, 1),
          weight: 2,
          opacity: 0,
          color: getColor(0, 1),
          dashArray: '3',
          fillOpacity: 0
        };
      });
      cov_jxk99dp4z.s[47]++;
      return;
    } else {
      cov_jxk99dp4z.b[7][1]++;
    }
    cov_jxk99dp4z.s[48]++;
    layer.on('click', function (a) {
      cov_jxk99dp4z.f[8]++;

      var popup = (cov_jxk99dp4z.s[49]++, L.popup().setLatLng(a.latlng).setContent('<div><h4 class="text-center">' + $scope.mappingLocatities[layer.options.info.code].DESCRIPTION_L + '</h4></div><div id="chart-popup" style="width: 300px; height: 300px"><div class="spinner-example" > <div class="sk-spinner sk-spinner-wave">  <div class="sk-rect1"></div>  <div class="sk-rect2"></div>   <div class="sk-rect3"></div>  <div class="sk-rect4"></div> <div class="sk-rect5"></div> </div> </div></div>').openOn($scope.layersGroup._map));
      cov_jxk99dp4z.s[50]++;
      getInformation({
        get: 'chart_informations',
        a2: $scope.choise.displayIndex == 0 ? (cov_jxk99dp4z.b[8][0]++, '0_0_0') : (cov_jxk99dp4z.b[8][1]++, $scope.choise.displayIndex + '_1_1'),
        la2: $scope.choise.displayIndex == 0 ? (cov_jxk99dp4z.b[9][0]++, 1) : (cov_jxk99dp4z.b[9][1]++, 2),
        code_lc: layer.options.info.code
      }, function (data) {
        cov_jxk99dp4z.f[9]++;
        cov_jxk99dp4z.s[51]++;

        $('#chart-popup').html('');
        cov_jxk99dp4z.s[52]++;
        data = data.sort(function (valueA, valueB) {
          cov_jxk99dp4z.f[10]++;
          cov_jxk99dp4z.s[53]++;

          return valueA.id > valueB.id ? (cov_jxk99dp4z.b[10][0]++, 1) : (cov_jxk99dp4z.b[10][1]++, -1);
        });

        cov_jxk99dp4z.s[54]++;
        data = data.map(function (value) {
          cov_jxk99dp4z.f[11]++;

          var ids = (cov_jxk99dp4z.s[55]++, value.id.split('_'));
          cov_jxk99dp4z.s[56]++;
          value.label = ids[0];
          cov_jxk99dp4z.s[57]++;
          if ($scope.choise.displayIndex != 0) {
            cov_jxk99dp4z.b[11][0]++;
            cov_jxk99dp4z.s[58]++;

            value.label = $deltadate.getLabelPerioddepth(ids[1], ids[2]);
          } else {
            cov_jxk99dp4z.b[11][1]++;
          }
          cov_jxk99dp4z.s[59]++;
          return value;
        });
        var chart = (cov_jxk99dp4z.s[60]++, new ej.charts.Chart({
          // Initializing Primary X Axis
          primaryXAxis: {
            title: '',
            interval: 1,
            labelIntersectAction: 'Rotate45',
            valueType: 'Category',
            majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
            majorTickLines: { width: 0 }, minorTickLines: { width: 0 }
          },
          // Initializing Primary Y Axis
          primaryYAxis: {
            title: '',
            valueType: 'Double',
            labelFormat: '{value} %'
          },
          chartArea: {
            border: {
              width: 0
            }
          },
          // Initializing Chart Series
          series: [{
            type: 'Column',
            fill: 'rgb(68, 114, 196)',
            dataSource: data.map(function (value) {
              cov_jxk99dp4z.f[12]++;

              var d_ = (cov_jxk99dp4z.s[61]++, { x: value.label, y: null });
              cov_jxk99dp4z.s[62]++;
              if (value.BB) {
                cov_jxk99dp4z.b[12][0]++;
                cov_jxk99dp4z.s[63]++;

                d_.y = value.TB;
              } else {
                cov_jxk99dp4z.b[12][1]++;
              }
              cov_jxk99dp4z.s[64]++;
              return d_;
            }),
            xName: 'x', yName: 'y', name: $translate.instant('ANALYSE.FIELDS.BUDGET.T')
          }, {
            fill: 'rgb(237, 125, 49)',
            type: 'Line',
            dataSource: data.map(function (value) {
              cov_jxk99dp4z.f[13]++;

              var d_ = (cov_jxk99dp4z.s[65]++, { x: value.label, y: null });
              cov_jxk99dp4z.s[66]++;
              if (value.TP) {
                cov_jxk99dp4z.b[13][0]++;
                cov_jxk99dp4z.s[67]++;

                d_.y = value.TP;
              } else {
                cov_jxk99dp4z.b[13][1]++;
              }
              cov_jxk99dp4z.s[68]++;
              return d_;
            }),
            xName: 'x', yName: 'y', name: $translate.instant('ANALYSE.FIELDS.PHYSIC.T'),
            width: 2,
            marker: {
              visible: true,
              width: 10,
              height: 10
            }
          }],
          width: '300px',
          height: '300px',
          // Initializing Chart Title
          // Initializing Tooltip
          tooltip: {
            enable: true
          }

        }));
        cov_jxk99dp4z.s[69]++;
        chart.appendTo('#chart-popup');
      });
    });
    cov_jxk99dp4z.s[70]++;
    layer.setStyle(function () {
      cov_jxk99dp4z.f[14]++;

      var params = (cov_jxk99dp4z.s[71]++, {
        fillColor: getColor(value.TP, 1),
        weight: 2,
        opacity: value.TB ? (cov_jxk99dp4z.b[14][0]++, 1) : (cov_jxk99dp4z.b[14][1]++, 0),
        color: getColor(value.TP, 1),
        dashArray: '3',
        fillOpacity: value.TP ? (cov_jxk99dp4z.b[15][0]++, 0.1) : (cov_jxk99dp4z.b[15][1]++, 0)
      });
      cov_jxk99dp4z.s[72]++;
      return params;
    });
  };
  cov_jxk99dp4z.s[73]++;
  $scope.globalValue = null;
  cov_jxk99dp4z.s[74]++;
  $deltaPeriode.addController($scope);
  cov_jxk99dp4z.s[75]++;
  $deltaActor.addController($scope);

  cov_jxk99dp4z.s[76]++;
  $CRUDService.getAll('DernierNiveau', { get: 'activity_state' }, function (data) {
    cov_jxk99dp4z.f[15]++;
    cov_jxk99dp4z.s[77]++;

    $scope.stateActivity = data;
  });

  cov_jxk99dp4z.s[78]++;
  $scope.getState = function () {
    cov_jxk99dp4z.f[16]++;
    cov_jxk99dp4z.s[79]++;

    if ((cov_jxk99dp4z.b[17][0]++, !$scope.stateActivity) || (cov_jxk99dp4z.b[17][1]++, !$scope.activity)) {
      cov_jxk99dp4z.b[16][0]++;
      cov_jxk99dp4z.s[80]++;

      return '';
    } else {
      cov_jxk99dp4z.b[16][1]++;
    }
    cov_jxk99dp4z.s[81]++;
    if ($scope.stateActivity.completed[$scope.activity.id]) {
      cov_jxk99dp4z.b[18][0]++;
      cov_jxk99dp4z.s[82]++;

      return $translate.instant('DASHBOARD.ACTIVITIES_TAB.COMPLETED_ACTIVITIES');
    } else {
      cov_jxk99dp4z.b[18][1]++;
    }

    cov_jxk99dp4z.s[83]++;
    if ($scope.stateActivity.not_carried_out[$scope.activity.id]) {
      cov_jxk99dp4z.b[19][0]++;
      cov_jxk99dp4z.s[84]++;

      return $translate.instant('DASHBOARD.ACTIVITIES_TAB.NOT_CARRIED_OUT_ACTIVITIES');
    } else {
      cov_jxk99dp4z.b[19][1]++;
    }

    cov_jxk99dp4z.s[85]++;
    if ($scope.stateActivity.on_going[$scope.activity.id]) {
      cov_jxk99dp4z.b[20][0]++;
      cov_jxk99dp4z.s[86]++;

      return $translate.instant('DASHBOARD.ACTIVITIES_TAB.ON_GOING_ACTIVITIES');
    } else {
      cov_jxk99dp4z.b[20][1]++;
    }
    cov_jxk99dp4z.s[87]++;
    return '';
  };

  cov_jxk99dp4z.s[88]++;
  $scope.activity = null;

  cov_jxk99dp4z.s[89]++;
  var getInformation = function getInformation(params, processAfter) {
    cov_jxk99dp4z.f[17]++;
    cov_jxk99dp4z.s[90]++;

    $CRUDService.getAll(PATH, Object.assign({
      get: 'global_informations',
      id: 0,
      a1: $scope.activity.CODE_P, // CODE_CLCAFF
      // a2: item ? item.id : '',
      a2: $scope.choise.displayIndex == 0 ? (cov_jxk99dp4z.b[21][0]++, '') : (cov_jxk99dp4z.b[21][1]++, '0_0_0'),
      la1: $scope.activity.level - 1,
      la2: $scope.choise.displayIndex == 0 ? (cov_jxk99dp4z.b[22][0]++, 0) : (cov_jxk99dp4z.b[22][1]++, 1),
      year: $scope.choise.displayIndex == 0 ? (cov_jxk99dp4z.b[23][0]++, '0_0_0') : (cov_jxk99dp4z.b[23][1]++, $scope.choise.displayIndex + '_1_1'),
      code: $scope.activity.CODE_CLC
    }, (cov_jxk99dp4z.b[24][0]++, params) || (cov_jxk99dp4z.b[24][1]++, {})), function (data) {
      cov_jxk99dp4z.f[18]++;
      cov_jxk99dp4z.s[91]++;

      if (processAfter) {
        cov_jxk99dp4z.b[25][0]++;
        cov_jxk99dp4z.s[92]++;

        processAfter(data);
      } else {
        cov_jxk99dp4z.b[25][1]++;
      }
    });
  };

  cov_jxk99dp4z.s[93]++;
  $scope.getAllInformations = function (item) {
    cov_jxk99dp4z.f[19]++;
    cov_jxk99dp4z.s[94]++;

    if (item) {
      cov_jxk99dp4z.b[26][0]++;
      cov_jxk99dp4z.s[95]++;

      $scope.activity = item;
      cov_jxk99dp4z.s[96]++;
      localStorage.setItem(ACTIVITY_DASHBOARD_PROJECT_GLOBAL_ACTIVITY, item.id);
      cov_jxk99dp4z.s[97]++;
      $scope.getDetails();
    } else {
      cov_jxk99dp4z.b[26][1]++;
    }
    cov_jxk99dp4z.s[98]++;
    $scope.mappingLocatities = null;
    cov_jxk99dp4z.s[99]++;
    $scope.getGlobalInformations();
    cov_jxk99dp4z.s[100]++;
    $scope.getChartInformations();
    cov_jxk99dp4z.s[101]++;
    $scope.getMapInformations();
  };

  cov_jxk99dp4z.s[102]++;
  $scope.getDetails = function () {
    cov_jxk99dp4z.f[20]++;
    cov_jxk99dp4z.s[103]++;

    $scope.dataDetails = null;
    cov_jxk99dp4z.s[104]++;
    $CRUDService.getAll(PATH_DN, { get: 'single', id: $scope.activity.id }, function (data) {
      cov_jxk99dp4z.f[21]++;
      cov_jxk99dp4z.s[105]++;

      if (data.length > 0) {
        cov_jxk99dp4z.b[27][0]++;
        cov_jxk99dp4z.s[106]++;

        $scope.dataDetails = data[0];

        cov_jxk99dp4z.s[107]++;
        if ($scope.dataDetails.IDACTEURS) {
          cov_jxk99dp4z.b[28][0]++;
          cov_jxk99dp4z.s[108]++;

          $scope.dataDetails.RESPO = $scope.listData_actors_bksb.find(function (actor) {
            cov_jxk99dp4z.f[22]++;
            cov_jxk99dp4z.s[109]++;

            return actor.id == $scope.dataDetails.IDACTEURS;
          });
        } else {
          cov_jxk99dp4z.b[28][1]++;
        }
      } else {
        cov_jxk99dp4z.b[27][1]++;
      }
    });
  };

  cov_jxk99dp4z.s[110]++;
  $scope.changeLevel = function (level) {
    cov_jxk99dp4z.f[23]++;
    cov_jxk99dp4z.s[111]++;

    $scope.mapGeoLevel = level;
    cov_jxk99dp4z.s[112]++;
    $scope.mappingLocatities = null;

    cov_jxk99dp4z.s[113]++;
    localStorage.setItem(ACTIVITY_DASHBOARD_PROJECT_GLOBAL_GEO_PLAN, $scope.mapGeoLevel.id);
    cov_jxk99dp4z.s[114]++;
    $scope.getMapInformations();
  };

  cov_jxk99dp4z.s[115]++;
  $scope.getGlobalInformations = function () {
    cov_jxk99dp4z.f[24]++;
    cov_jxk99dp4z.s[116]++;

    $scope.globalValue = null;
    cov_jxk99dp4z.s[117]++;
    if (!$scope.activity) {
      cov_jxk99dp4z.b[29][0]++;
      cov_jxk99dp4z.s[118]++;

      return;
    } else {
      cov_jxk99dp4z.b[29][1]++;
    }
    cov_jxk99dp4z.s[119]++;
    getInformation({}, function (data) {
      cov_jxk99dp4z.f[25]++;
      cov_jxk99dp4z.s[120]++;

      $scope.globalValue = data;
      cov_jxk99dp4z.s[121]++;
      $('#budget_rate_chart').html('');
      cov_jxk99dp4z.s[122]++;
      $('#physique_rate_chart').html('');
      cov_jxk99dp4z.s[123]++;
      $('#avanc_rate_chart').html('');

      // Taux Physique
      cov_jxk99dp4z.s[124]++;
      if ($scope.globalValue.BB) {
        cov_jxk99dp4z.b[30][0]++;

        var circularGauge = (cov_jxk99dp4z.s[125]++, new ej.circulargauge.CircularGauge({
          height: '150px',
          width: '150px',
          axes: [{
            labelStyle: { font: { size: '0px' } }, majorTicks: { height: 0 }, minorTicks: { height: 0 },
            startAngle: 180, endAngle: 180, minimum: 0, maximum: 100, radius: '100%',

            ranges: [{
              start: 0, end: 100,
              radius: '0%',
              startWidth: 10, endWidth: 10,
              color: '#E0E0E000',
              opacity: 0
            }],
            annotations: [{
              content: '<div class="jauge-annotation" style="color: ' + getColor($scope.globalValue.TB > 100 ? (cov_jxk99dp4z.b[31][0]++, 100) : (cov_jxk99dp4z.b[31][1]++, $scope.globalValue.TB), 2) + '">' + $filter('number')($scope.globalValue.TB, 2) + '%</div>',
              angle: 180, zIndex: 1,
              radius: '0%'
            }],
            pointers: [{
              value: $scope.globalValue.TB > 100 ? (cov_jxk99dp4z.b[32][0]++, 100) : (cov_jxk99dp4z.b[32][1]++, $scope.globalValue.TB),
              type: 'RangeBar',
              radius: '100%',
              color: getColor($scope.globalValue.TB > 100 ? (cov_jxk99dp4z.b[33][0]++, 100) : (cov_jxk99dp4z.b[33][1]++, $scope.globalValue.TB), 2),
              pointerWidth: 16,
              roundedCornerRadius: $scope.globalValue.TB >= 100 ? (cov_jxk99dp4z.b[34][0]++, 0) : (cov_jxk99dp4z.b[34][1]++, 8),
              opacity: 0,
              cap: {
                radius: 0
              }
            }]
          }]
        }));
        cov_jxk99dp4z.s[126]++;
        circularGauge.appendTo('#budget_rate_chart');
      } else {
        cov_jxk99dp4z.b[30][1]++;
      }
      // Taux budgetaire
      cov_jxk99dp4z.s[127]++;
      if ($scope.globalValue.TP) {
        cov_jxk99dp4z.b[35][0]++;

        var _circularGauge = (cov_jxk99dp4z.s[128]++, new ej.circulargauge.CircularGauge({
          height: '150px',
          width: '150px',
          axes: [{
            labelStyle: { font: { size: '0px' } }, majorTicks: { height: 0 }, minorTicks: { height: 0 },
            startAngle: 180, endAngle: 180, minimum: 0, maximum: 100, radius: '100%',
            ranges: [{
              start: 0, end: 100,
              radius: '100%',
              startWidth: 10, endWidth: 10,
              color: '#E0E0E000'
            }],
            annotations: [{
              content: '<div class="jauge-annotation"  style="color: ' + getColor($scope.globalValue.TP > 100 ? (cov_jxk99dp4z.b[36][0]++, 100) : (cov_jxk99dp4z.b[36][1]++, $scope.globalValue.TP), 1) + '">' + $filter('number')($scope.globalValue.TP, 2) + '%</div>',
              angle: 50, zIndex: 1,
              radius: '0%'
            }],
            pointers: [{
              value: $scope.globalValue.TP > 100 ? (cov_jxk99dp4z.b[37][0]++, 100) : (cov_jxk99dp4z.b[37][1]++, $scope.globalValue.TP),
              type: 'RangeBar',
              radius: '100%',
              color: getColor($scope.globalValue.TP > 100 ? (cov_jxk99dp4z.b[38][0]++, 100) : (cov_jxk99dp4z.b[38][1]++, $scope.globalValue.TP), 1),
              pointerWidth: 16,
              roundedCornerRadius: $scope.globalValue.TP >= 100 ? (cov_jxk99dp4z.b[39][0]++, 0) : (cov_jxk99dp4z.b[39][1]++, 8)
            }]
          }]
        }));
        cov_jxk99dp4z.s[129]++;
        _circularGauge.appendTo('#physique_rate_chart');
      } else {
        cov_jxk99dp4z.b[35][1]++;
      }
      // Avancement
      cov_jxk99dp4z.s[130]++;
      if ((cov_jxk99dp4z.b[41][0]++, $scope.globalValue.DD) && (cov_jxk99dp4z.b[41][1]++, $scope.globalValue.DF)) {
        cov_jxk99dp4z.b[40][0]++;

        var dd = (cov_jxk99dp4z.s[131]++, Date.newDate($scope.globalValue.DD).getTime());
        var df = (cov_jxk99dp4z.s[132]++, Date.newDate($scope.globalValue.DF).getTime());
        var actual = (cov_jxk99dp4z.s[133]++, Date.newDate().getTime());
        var avance = (cov_jxk99dp4z.s[134]++, null);
        cov_jxk99dp4z.s[135]++;
        if (df - dd != 0) {
          cov_jxk99dp4z.b[42][0]++;
          cov_jxk99dp4z.s[136]++;

          avance = 100 * (actual - dd) / (df - dd);
          cov_jxk99dp4z.s[137]++;
          if (avance > 100) {
            cov_jxk99dp4z.b[43][0]++;
            cov_jxk99dp4z.s[138]++;

            avance = 100;
          } else {
            cov_jxk99dp4z.b[43][1]++;
          }
          cov_jxk99dp4z.s[139]++;
          if (avance < 0) {
            cov_jxk99dp4z.b[44][0]++;
            cov_jxk99dp4z.s[140]++;

            avance = 0;
          } else {
            cov_jxk99dp4z.b[44][1]++;
          }
          var _circularGauge2 = (cov_jxk99dp4z.s[141]++, new ej.circulargauge.CircularGauge({
            height: '150px',
            width: '150px',
            axes: [{
              labelStyle: { font: { size: '0px' } }, majorTicks: { height: 0 }, minorTicks: { height: 0 },
              startAngle: 180, endAngle: 180, minimum: 0, maximum: 100, radius: '100%',
              ranges: [{
                start: 0, end: 100,
                radius: '100%',
                startWidth: 10, endWidth: 10,
                color: '#E0E0E000'
              }],
              annotations: [{
                content: '<div class="jauge-annotation"  style="color: ' + getColor(avance, 2) + '">' + $filter('number')(avance, 2) + '%</div>',
                angle: 180, zIndex: 1,
                radius: '0%'
              }],
              pointers: [{
                value: avance,
                type: 'RangeBar',
                radius: '100%',
                color: getColor(avance, 2),
                pointerWidth: 16,
                roundedCornerRadius: avance >= 100 ? (cov_jxk99dp4z.b[45][0]++, 0) : (cov_jxk99dp4z.b[45][1]++, 8)
              }]
            }]
          }));
          cov_jxk99dp4z.s[142]++;
          _circularGauge2.appendTo('#avanc_rate_chart');
        } else {
          cov_jxk99dp4z.b[42][1]++;
        }
      } else {
        cov_jxk99dp4z.b[40][1]++;
      }
    });
  };

  cov_jxk99dp4z.s[143]++;
  $scope.getChartInformations = function () {
    cov_jxk99dp4z.f[26]++;
    cov_jxk99dp4z.s[144]++;

    if (!$scope.activity) {
      cov_jxk99dp4z.b[46][0]++;
      cov_jxk99dp4z.s[145]++;

      return;
    } else {
      cov_jxk99dp4z.b[46][1]++;
    }

    cov_jxk99dp4z.s[146]++;
    getInformation({
      get: 'chart_informations',
      a2: $scope.choise.displayIndex == 0 ? (cov_jxk99dp4z.b[47][0]++, '0_0_0') : (cov_jxk99dp4z.b[47][1]++, $scope.choise.displayIndex + '_1_1'),
      la2: $scope.choise.displayIndex == 0 ? (cov_jxk99dp4z.b[48][0]++, 1) : (cov_jxk99dp4z.b[48][1]++, 2)
    }, function (data) {
      cov_jxk99dp4z.f[27]++;
      cov_jxk99dp4z.s[147]++;

      data = data.sort(function (valueA, valueB) {
        cov_jxk99dp4z.f[28]++;
        cov_jxk99dp4z.s[148]++;

        return valueA.id > valueB.id ? (cov_jxk99dp4z.b[49][0]++, 1) : (cov_jxk99dp4z.b[49][1]++, -1);
      });

      cov_jxk99dp4z.s[149]++;
      data = data.map(function (value) {
        cov_jxk99dp4z.f[29]++;

        var ids = (cov_jxk99dp4z.s[150]++, value.id.split('_'));
        cov_jxk99dp4z.s[151]++;
        value.label = ids[0];
        cov_jxk99dp4z.s[152]++;
        if ($scope.choise.displayIndex != 0) {
          cov_jxk99dp4z.b[50][0]++;
          cov_jxk99dp4z.s[153]++;

          value.label = $deltadate.getLabelPerioddepth(ids[1], ids[2]);
        } else {
          cov_jxk99dp4z.b[50][1]++;
        }
        cov_jxk99dp4z.s[154]++;
        return value;
      });

      cov_jxk99dp4z.s[155]++;
      $scope.chartPanel = {
        // Initializing Primary X Axis
        primaryXAxis: {
          title: '',
          interval: 1,
          labelIntersectAction: 'Rotate45',
          valueType: 'Category',
          majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
          majorTickLines: { width: 0 }, minorTickLines: { width: 0 }
        },
        // Initializing Primary Y Axis
        primaryYAxis: {
          title: '',
          valueType: 'Double',
          labelFormat: '{value} %'
        },
        chartArea: {
          border: {
            width: 0
          }
        },
        // Initializing Chart Series
        series: [{
          type: 'Column',
          fill: 'rgb(68, 114, 196)',
          dataSource: data.map(function (value) {
            cov_jxk99dp4z.f[30]++;

            var d_ = (cov_jxk99dp4z.s[156]++, { x: value.label, y: null });
            cov_jxk99dp4z.s[157]++;
            if (value.BB) {
              cov_jxk99dp4z.b[51][0]++;
              cov_jxk99dp4z.s[158]++;

              d_.y = value.TB;
            } else {
              cov_jxk99dp4z.b[51][1]++;
            }
            cov_jxk99dp4z.s[159]++;
            return d_;
          }),
          xName: 'x', yName: 'y', name: $translate.instant('ANALYSE.FIELDS.BUDGET.T')
        }, {
          fill: 'rgb(237, 125, 49)',
          type: 'Line',
          dataSource: data.map(function (value) {
            cov_jxk99dp4z.f[31]++;

            var d_ = (cov_jxk99dp4z.s[160]++, { x: value.label, y: null });
            cov_jxk99dp4z.s[161]++;
            if (value.TP) {
              cov_jxk99dp4z.b[52][0]++;
              cov_jxk99dp4z.s[162]++;

              d_.y = value.TP;
            } else {
              cov_jxk99dp4z.b[52][1]++;
            }
            cov_jxk99dp4z.s[163]++;
            return d_;
          }),
          xName: 'x', yName: 'y', name: $translate.instant('ANALYSE.FIELDS.PHYSIC.T'),
          width: 2,
          marker: {
            visible: true,
            width: 10,
            height: 10
          }
        }],
        width: '100%',
        height: 'calc(100% - 45px)', // ($('#chart-container').height() - 45) + 'px',
        // Initializing Chart Title
        // Initializing Tooltip
        tooltip: {
          enable: true
        }

      };
      // chart.appendTo('#chartPanel');
    });
  };

  cov_jxk99dp4z.s[164]++;
  var initMap = function initMap() {
    cov_jxk99dp4z.f[32]++;

    var myMap = (cov_jxk99dp4z.s[165]++, L.map('map', { fullscreenControl: {
        title: {
          false: $translate.instant('COMMON.FULLSCREEN'),
          true: $translate.instant('COMMON.EXIT_FULLSCREEN')
        }
      }, layers: [] }).setView([16.82, 11.3], 5));

    cov_jxk99dp4z.s[166]++;
    $rootScope.getTileLayers(myMap, 'COMMON.GRAYSCALE');
    cov_jxk99dp4z.s[167]++;
    $scope.layersGroup = L.layerGroup();

    cov_jxk99dp4z.s[168]++;
    $scope.layersGroup.addTo(myMap);
    var legendMap = (cov_jxk99dp4z.s[169]++, getColors(1));
    var legendControl = (cov_jxk99dp4z.s[170]++, L.control({ position: 'bottomright' }));
    cov_jxk99dp4z.s[171]++;
    legendControl.onAdd = function (map) {
      cov_jxk99dp4z.f[33]++;

      var div = (cov_jxk99dp4z.s[172]++, L.DomUtil.create('div', 'info legend'));
      // loop through our density intervals and generate a label with a colored square for each interval
      cov_jxk99dp4z.s[173]++;
      for (var i = 0; i < legendMap.length; i++) {
        cov_jxk99dp4z.s[174]++;

        div.innerHTML += '<i style="background:' + legendMap[i].color + '"></i> ' + $filter('number')(legendMap[i - 1] ? (cov_jxk99dp4z.b[53][0]++, legendMap[i - 1].valueMax) : (cov_jxk99dp4z.b[53][1]++, 0)) + (legendMap[i + 1] ? (cov_jxk99dp4z.b[54][0]++, ' &ndash; ' + $filter('number')(legendMap[i].valueMax)) : (cov_jxk99dp4z.b[54][1]++, ' +')) + (legendMap[i].l ? (cov_jxk99dp4z.b[55][0]++, ' <smal style="font-weight: normal; font-size: 10px" >(' + legendMap[i].l + ')</smal>') : (cov_jxk99dp4z.b[55][1]++, '')) + '<br>';
      }
      cov_jxk99dp4z.s[175]++;
      return div;
    };
    cov_jxk99dp4z.s[176]++;
    legendControl.addTo(myMap);
  };
  cov_jxk99dp4z.s[177]++;
  var processLayer = function processLayer(locality, geojson) {
    cov_jxk99dp4z.f[34]++;
    cov_jxk99dp4z.s[178]++;

    geojson.properties = { code: locality.CODE_LC };
    var l = (cov_jxk99dp4z.s[179]++, L.geoJSON(geojson, {
      info: { code: locality.CODE_LC },
      pointToLayer: function pointToLayer(point, latlng) {
        cov_jxk99dp4z.s[180]++;

        return L.circle(latlng, { radius: 15000 });
      },
      onEachFeature: function onEachFeature(feature, layer) {
        cov_jxk99dp4z.s[181]++;

        // locality.layerId = layer.getLayerId();
        layer.on({
          click: function click() {}
        });
      },
      style: function style(feature) {
        cov_jxk99dp4z.s[182]++;

        return {
          fillColor: '#f1c120',
          weight: 2,
          opacity: 1,
          color: '#f1c120',
          dashArray: '3',
          fillOpacity: 0.1
        };
      }
    }));
    cov_jxk99dp4z.s[183]++;
    l.on('add', function (element) {
      cov_jxk99dp4z.f[35]++;
      cov_jxk99dp4z.s[184]++;

      locality.layerId = element.target._leaflet_id;
      cov_jxk99dp4z.s[185]++;
      customlayers(locality.layerId, $scope.mapData[locality.CODE_LC]);
    });
    cov_jxk99dp4z.s[186]++;
    l.addTo($scope.layersGroup);
    cov_jxk99dp4z.s[187]++;
    l.bringToFront();
    cov_jxk99dp4z.s[188]++;
    return l.getBounds();
  };

  cov_jxk99dp4z.s[189]++;
  var processLayers = function processLayers() {
    cov_jxk99dp4z.f[36]++;

    var localities = (cov_jxk99dp4z.s[190]++, {});
    var ids = (cov_jxk99dp4z.s[191]++, []);
    cov_jxk99dp4z.s[192]++;
    for (var key_locality in $scope.mappingLocatities) {
      var uid_arc = (cov_jxk99dp4z.s[193]++, $scope.mappingLocatities[key_locality].UID_ARC);
      cov_jxk99dp4z.s[194]++;
      if (!uid_arc) {
        cov_jxk99dp4z.b[56][0]++;
        cov_jxk99dp4z.s[195]++;

        continue;
      } else {
        cov_jxk99dp4z.b[56][1]++;
      }
      cov_jxk99dp4z.s[196]++;
      ids.push(uid_arc);
      cov_jxk99dp4z.s[197]++;
      localities[uid_arc] = key_locality;
    }

    var params = (cov_jxk99dp4z.s[198]++, {
      where: '1 = 1',
      objectIds: ids,
      time: '',
      geometry: '',
      geometryType: 'esriGeometryEnvelope',
      inSR: '',
      spatialRel: 'esriSpatialRelIntersects',
      resultType: 'none',
      distance: '0.0',
      units: 'esriSRUnit_Meter',
      returnGeodetic: false,
      outFields: 'FID',
      returnHiddenFields: false,
      returnGeometry: true,
      returnCentroid: false,
      featureEncoding: 'esriDefault',
      multipatchOption: 'xyFootprint',
      maxAllowableOffset: '',
      geometryPrecision: '',
      outSR: '4326',
      datumTransformation: '',
      applyVCSProjection: false,
      returnIdsOnly: false,
      returnUniqueIdsOnly: false,
      returnCountOnly: false,
      returnExtentOnly: false,
      returnQueryGeometry: false,
      returnDistinctValues: false,
      cacheHint: false,
      orderByFields: '',
      groupByFieldsForStatistics: '',
      outStatistics: '',
      having: '',
      resultOffset: '',
      resultRecordCount: '',
      returnZ: false,
      returnM: false,
      returnExceededLimitFeatures: true,
      quantizationParameters: '',
      sqlFormat: 'none',
      f: 'pgeojson'
    });

    cov_jxk99dp4z.s[199]++;
    $rootScope.loadGeojson($scope.mapGeoLevel.NIVEAU_NG - 1, ids, function (responseData) {
      cov_jxk99dp4z.f[37]++;

      var bounds = (cov_jxk99dp4z.s[200]++, null);
      cov_jxk99dp4z.s[201]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = responseData.features[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var geojson = _step.value;

          var child = (cov_jxk99dp4z.s[202]++, $scope.mappingLocatities[localities[geojson.id]]);
          var my_bound = (cov_jxk99dp4z.s[203]++, processLayer(child, geojson));
          // L.latLngBounds(
          cov_jxk99dp4z.s[204]++;
          if (!bounds) {
            cov_jxk99dp4z.b[57][0]++;
            cov_jxk99dp4z.s[205]++;

            bounds = my_bound;
          } else {
            cov_jxk99dp4z.b[57][1]++;
            cov_jxk99dp4z.s[206]++;

            bounds.extend(my_bound);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_jxk99dp4z.s[207]++;
      if (bounds) {
        cov_jxk99dp4z.b[58][0]++;
        cov_jxk99dp4z.s[208]++;

        $scope.layersGroup._map.fitBounds(bounds);
      } else {
        cov_jxk99dp4z.b[58][1]++;
      }
    });
    /*
    // const url = `${$rootScope.getGeoLayers($scope.mapGeoLevel.NIVEAU_NG - 1)}`;
    const url = `${$rootScope.getGeoLayers($scope.mapGeoLevel.NIVEAU_NG - 1, undefined, false)}/${ids}/format=geojson`;
    let bounds = null;
    $http({
      method: 'GET', url, params
    }).then(response => {
      for (const geojson of response.data.features) {
        const child = $scope.mappingLocatities[localities[geojson.id]];
        const my_bound = processLayer(child, geojson);
        // L.latLngBounds(
        if (!bounds) {
          bounds = my_bound;
        } else {
          bounds.extend(my_bound);
        }
      }
      $scope.layersGroup._map.fitBounds(bounds);
    }); */
  };

  cov_jxk99dp4z.s[209]++;
  $scope.getMapInformations = function () {
    cov_jxk99dp4z.f[38]++;
    cov_jxk99dp4z.s[210]++;

    if (!$scope.layersGroup) {
      cov_jxk99dp4z.b[59][0]++;
      cov_jxk99dp4z.s[211]++;

      initMap();
    } else {
      cov_jxk99dp4z.b[59][1]++;
    }
    // Get localities
    var params = (cov_jxk99dp4z.s[212]++, { get: 'all_geo_idoms', activity: $scope.activity.id });
    cov_jxk99dp4z.s[213]++;
    if ($scope.mapGeoLevel) {
      cov_jxk99dp4z.b[60][0]++;
      cov_jxk99dp4z.s[214]++;

      params.level = $scope.mapGeoLevel.id;
    } else {
      cov_jxk99dp4z.b[60][1]++;
    }
    cov_jxk99dp4z.s[215]++;
    $scope.mapData = {};
    cov_jxk99dp4z.s[216]++;
    getInformation({
      get: 'map_informations',
      level: ((cov_jxk99dp4z.b[61][0]++, $scope.mapGeoLevel) || (cov_jxk99dp4z.b[61][1]++, { NIVEAU_NG: 1 })).NIVEAU_NG - 1,
      year: $scope.choise.displayIndex
    }, function (data) {
      cov_jxk99dp4z.f[39]++;
      cov_jxk99dp4z.s[217]++;

      $scope.mapData = data;

      cov_jxk99dp4z.s[218]++;
      if ((cov_jxk99dp4z.b[63][0]++, $scope.mappingLocatities) && (cov_jxk99dp4z.b[63][1]++, _typeof($scope.mapData) == 'object') && (cov_jxk99dp4z.b[63][2]++, Object.keys($scope.mapData).length > 0)) {
        cov_jxk99dp4z.b[62][0]++;
        cov_jxk99dp4z.s[219]++;

        for (var key_locality in $scope.mappingLocatities) {
          var locality = (cov_jxk99dp4z.s[220]++, $scope.mappingLocatities[key_locality]);
          cov_jxk99dp4z.s[221]++;
          customlayers(locality.layerId, $scope.mapData[locality.CODE_LC]);
        }
      } else {
          cov_jxk99dp4z.b[62][1]++;
          cov_jxk99dp4z.s[222]++;
          if (Object.keys($scope.mapData).length == 0) {
            cov_jxk99dp4z.b[64][0]++;
            cov_jxk99dp4z.s[223]++;

            // initMap(); Clean du map
            $scope.mappingLocatities = null;
            cov_jxk99dp4z.s[224]++;
            $scope.layersGroup.clearLayers();
            cov_jxk99dp4z.s[225]++;
            return;
          } else {
            cov_jxk99dp4z.b[64][1]++;
          }
        }cov_jxk99dp4z.s[226]++;
      if (!$scope.mappingLocatities) {
        cov_jxk99dp4z.b[65][0]++;
        cov_jxk99dp4z.s[227]++;

        // Recuperer les layers
        $scope.layersGroup.clearLayers();
        //

        cov_jxk99dp4z.s[228]++;
        $CRUDService.getAll(PATH_LOCALITY, params, function (localities) {
          cov_jxk99dp4z.f[40]++;
          cov_jxk99dp4z.s[229]++;

          $scope.mappingLocatities = {};
          cov_jxk99dp4z.s[230]++;
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = localities[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var _locality = _step2.value;
              cov_jxk99dp4z.s[231]++;

              $scope.mappingLocatities[_locality.CODE_LC] = _locality;
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          cov_jxk99dp4z.s[232]++;
          if ((cov_jxk99dp4z.b[67][0]++, $scope.mapData.length != 0) && (cov_jxk99dp4z.b[67][1]++, $scope.mapData != {})) {
            cov_jxk99dp4z.b[66][0]++;
            cov_jxk99dp4z.s[233]++;

            processLayers();
          } else {
            cov_jxk99dp4z.b[66][1]++;
          }
        });
      } else {
        cov_jxk99dp4z.b[65][1]++;
      }
    });
  };
  cov_jxk99dp4z.s[234]++;
  $deltaGeoLevel.addController($scope, function () {
    cov_jxk99dp4z.f[41]++;

    var levelSaved = (cov_jxk99dp4z.s[235]++, (cov_jxk99dp4z.b[68][0]++, localStorage.getItem(ACTIVITY_DASHBOARD_PROJECT_GLOBAL_GEO_PLAN)) || (cov_jxk99dp4z.b[68][1]++, 0));
    cov_jxk99dp4z.s[236]++;
    $scope.mapGeoLevel = (cov_jxk99dp4z.b[69][0]++, $scope.listData_GeoLevels_bksb.find(function (level) {
      cov_jxk99dp4z.f[42]++;
      cov_jxk99dp4z.s[237]++;
      return level.id === levelSaved;
    })) || (cov_jxk99dp4z.b[69][1]++, $scope.listData_GeoLevels_bksb[0]);
  });

  cov_jxk99dp4z.s[238]++;
  $scope.search_activity = '';
  cov_jxk99dp4z.s[239]++;
  $scope.clone_listData = null;
  cov_jxk99dp4z.s[240]++;
  $scope.filterActivity = function () {
    cov_jxk99dp4z.f[43]++;
    cov_jxk99dp4z.s[241]++;

    if ($scope.search_activity != '') {
      cov_jxk99dp4z.b[70][0]++;
      cov_jxk99dp4z.s[242]++;

      $scope.listData = $scope.listData.filter(function (value) {
        cov_jxk99dp4z.f[44]++;
        cov_jxk99dp4z.s[243]++;

        return (value.CODE_CLCAFF + ' ' + value.LIBELLE_C_CL).toLowerCase().includes($scope.search_activity.toLowerCase());
      });
    } else {
      cov_jxk99dp4z.b[70][1]++;
      cov_jxk99dp4z.s[244]++;

      $scope.listData = angular.copy($scope.clone_listData);
    }
  };

  cov_jxk99dp4z.s[245]++;
  $CRUDService.getAll(PATH_CL, { get: 'all_activities' }, function (data) {
    cov_jxk99dp4z.f[45]++;
    cov_jxk99dp4z.s[246]++;

    $scope.listData = data;
    cov_jxk99dp4z.s[247]++;
    $scope.clone_listData = angular.copy(data);

    var selectActivity = (cov_jxk99dp4z.s[248]++, localStorage.getItem(ACTIVITY_DASHBOARD_PROJECT_GLOBAL_ACTIVITY));

    cov_jxk99dp4z.s[249]++;
    $scope.getAllInformations((cov_jxk99dp4z.b[71][0]++, $scope.listData.find(function (activity) {
      cov_jxk99dp4z.f[46]++;
      cov_jxk99dp4z.s[250]++;
      return activity.id == selectActivity;
    })) || (cov_jxk99dp4z.b[71][1]++, $scope.listData[0]));
  });
});