'use strict';

var cov_1s7ak9ao91 = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/dashboard-table-simple.js',
      hash = '99c85b55c7b59621dfee4dd4308d6c39bb28b5a8',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/dashboard-table-simple.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 31
        },
        end: {
          line: 340,
          column: 1
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 25
        }
      },
      '2': {
        start: {
          line: 4,
          column: 16
        },
        end: {
          line: 4,
          column: 20
        }
      },
      '3': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 78,
          column: 4
        }
      },
      '4': {
        start: {
          line: 6,
          column: 19
        },
        end: {
          line: 6,
          column: 85
        }
      },
      '5': {
        start: {
          line: 7,
          column: 17
        },
        end: {
          line: 7,
          column: 21
        }
      },
      '6': {
        start: {
          line: 8,
          column: 15
        },
        end: {
          line: 8,
          column: 17
        }
      },
      '7': {
        start: {
          line: 10,
          column: 4
        },
        end: {
          line: 10,
          column: 93
        }
      },
      '8': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 11,
          column: 58
        }
      },
      '9': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 77,
          column: 5
        }
      },
      '10': {
        start: {
          line: 13,
          column: 34
        },
        end: {
          line: 42,
          column: 7
        }
      },
      '11': {
        start: {
          line: 43,
          column: 21
        },
        end: {
          line: 43,
          column: 25
        }
      },
      '12': {
        start: {
          line: 44,
          column: 18
        },
        end: {
          line: 44,
          column: 19
        }
      },
      '13': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 50,
          column: 7
        }
      },
      '14': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 48,
          column: 9
        }
      },
      '15': {
        start: {
          line: 47,
          column: 10
        },
        end: {
          line: 47,
          column: 19
        }
      },
      '16': {
        start: {
          line: 49,
          column: 8
        },
        end: {
          line: 49,
          column: 60
        }
      },
      '17': {
        start: {
          line: 51,
          column: 6
        },
        end: {
          line: 54,
          column: 7
        }
      },
      '18': {
        start: {
          line: 52,
          column: 8
        },
        end: {
          line: 52,
          column: 22
        }
      },
      '19': {
        start: {
          line: 53,
          column: 8
        },
        end: {
          line: 53,
          column: 24
        }
      },
      '20': {
        start: {
          line: 55,
          column: 19
        },
        end: {
          line: 55,
          column: 33
        }
      },
      '21': {
        start: {
          line: 56,
          column: 6
        },
        end: {
          line: 59,
          column: 7
        }
      },
      '22': {
        start: {
          line: 57,
          column: 8
        },
        end: {
          line: 57,
          column: 59
        }
      },
      '23': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 58,
          column: 45
        }
      },
      '24': {
        start: {
          line: 60,
          column: 6
        },
        end: {
          line: 60,
          column: 70
        }
      },
      '25': {
        start: {
          line: 61,
          column: 6
        },
        end: {
          line: 61,
          column: 98
        }
      },
      '26': {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 64,
          column: 7
        }
      },
      '27': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 63,
          column: 62
        }
      },
      '28': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 65,
          column: 46
        }
      },
      '29': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 66,
          column: 58
        }
      },
      '30': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 69,
          column: 7
        }
      },
      '31': {
        start: {
          line: 68,
          column: 8
        },
        end: {
          line: 68,
          column: 74
        }
      },
      '32': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 73,
          column: 9
        }
      },
      '33': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 76,
          column: 7
        }
      },
      '34': {
        start: {
          line: 75,
          column: 8
        },
        end: {
          line: 75,
          column: 75
        }
      },
      '35': {
        start: {
          line: 80,
          column: 2
        },
        end: {
          line: 118,
          column: 4
        }
      },
      '36': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 109,
          column: 9
        }
      },
      '37': {
        start: {
          line: 108,
          column: 10
        },
        end: {
          line: 108,
          column: 35
        }
      },
      '38': {
        start: {
          line: 110,
          column: 8
        },
        end: {
          line: 112,
          column: 9
        }
      },
      '39': {
        start: {
          line: 111,
          column: 10
        },
        end: {
          line: 111,
          column: 37
        }
      },
      '40': {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 115,
          column: 50
        }
      },
      '41': {
        start: {
          line: 119,
          column: 2
        },
        end: {
          line: 119,
          column: 28
        }
      },
      '42': {
        start: {
          line: 121,
          column: 2
        },
        end: {
          line: 125,
          column: 4
        }
      },
      '43': {
        start: {
          line: 122,
          column: 4
        },
        end: {
          line: 124,
          column: 7
        }
      },
      '44': {
        start: {
          line: 123,
          column: 6
        },
        end: {
          line: 123,
          column: 34
        }
      },
      '45': {
        start: {
          line: 126,
          column: 2
        },
        end: {
          line: 128,
          column: 4
        }
      },
      '46': {
        start: {
          line: 127,
          column: 4
        },
        end: {
          line: 127,
          column: 41
        }
      },
      '47': {
        start: {
          line: 129,
          column: 2
        },
        end: {
          line: 272,
          column: 4
        }
      },
      '48': {
        start: {
          line: 130,
          column: 4
        },
        end: {
          line: 132,
          column: 5
        }
      },
      '49': {
        start: {
          line: 131,
          column: 6
        },
        end: {
          line: 131,
          column: 13
        }
      },
      '50': {
        start: {
          line: 133,
          column: 19
        },
        end: {
          line: 133,
          column: 150
        }
      },
      '51': {
        start: {
          line: 134,
          column: 4
        },
        end: {
          line: 271,
          column: 7
        }
      },
      '52': {
        start: {
          line: 135,
          column: 6
        },
        end: {
          line: 137,
          column: 7
        }
      },
      '53': {
        start: {
          line: 136,
          column: 8
        },
        end: {
          line: 136,
          column: 15
        }
      },
      '54': {
        start: {
          line: 138,
          column: 24
        },
        end: {
          line: 140,
          column: 8
        }
      },
      '55': {
        start: {
          line: 139,
          column: 8
        },
        end: {
          line: 139,
          column: 58
        }
      },
      '56': {
        start: {
          line: 141,
          column: 20
        },
        end: {
          line: 141,
          column: 63
        }
      },
      '57': {
        start: {
          line: 142,
          column: 18
        },
        end: {
          line: 142,
          column: 19
        }
      },
      '58': {
        start: {
          line: 143,
          column: 15
        },
        end: {
          line: 143,
          column: 18
        }
      },
      '59': {
        start: {
          line: 144,
          column: 6
        },
        end: {
          line: 151,
          column: 7
        }
      },
      '60': {
        start: {
          line: 145,
          column: 8
        },
        end: {
          line: 145,
          column: 18
        }
      },
      '61': {
        start: {
          line: 146,
          column: 22
        },
        end: {
          line: 146,
          column: 60
        }
      },
      '62': {
        start: {
          line: 147,
          column: 8
        },
        end: {
          line: 147,
          column: 22
        }
      },
      '63': {
        start: {
          line: 148,
          column: 8
        },
        end: {
          line: 150,
          column: 9
        }
      },
      '64': {
        start: {
          line: 149,
          column: 10
        },
        end: {
          line: 149,
          column: 51
        }
      },
      '65': {
        start: {
          line: 152,
          column: 19
        },
        end: {
          line: 206,
          column: 7
        }
      },
      '66': {
        start: {
          line: 153,
          column: 71
        },
        end: {
          line: 192,
          column: 9
        }
      },
      '67': {
        start: {
          line: 156,
          column: 26
        },
        end: {
          line: 156,
          column: 49
        }
      },
      '68': {
        start: {
          line: 157,
          column: 14
        },
        end: {
          line: 159,
          column: 15
        }
      },
      '69': {
        start: {
          line: 158,
          column: 16
        },
        end: {
          line: 158,
          column: 29
        }
      },
      '70': {
        start: {
          line: 160,
          column: 14
        },
        end: {
          line: 162,
          column: 15
        }
      },
      '71': {
        start: {
          line: 161,
          column: 16
        },
        end: {
          line: 161,
          column: 29
        }
      },
      '72': {
        start: {
          line: 163,
          column: 14
        },
        end: {
          line: 165,
          column: 15
        }
      },
      '73': {
        start: {
          line: 164,
          column: 16
        },
        end: {
          line: 164,
          column: 28
        }
      },
      '74': {
        start: {
          line: 166,
          column: 14
        },
        end: {
          line: 166,
          column: 27
        }
      },
      '75': {
        start: {
          line: 169,
          column: 14
        },
        end: {
          line: 169,
          column: 52
        }
      },
      '76': {
        start: {
          line: 170,
          column: 14
        },
        end: {
          line: 170,
          column: 101
        }
      },
      '77': {
        start: {
          line: 171,
          column: 14
        },
        end: {
          line: 171,
          column: 23
        }
      },
      '78': {
        start: {
          line: 208,
          column: 6
        },
        end: {
          line: 253,
          column: 7
        }
      },
      '79': {
        start: {
          line: 209,
          column: 8
        },
        end: {
          line: 252,
          column: 10
        }
      },
      '80': {
        start: {
          line: 225,
          column: 26
        },
        end: {
          line: 229,
          column: 13
        }
      },
      '81': {
        start: {
          line: 231,
          column: 12
        },
        end: {
          line: 235,
          column: 13
        }
      },
      '82': {
        start: {
          line: 232,
          column: 14
        },
        end: {
          line: 234,
          column: 16
        }
      },
      '83': {
        start: {
          line: 236,
          column: 12
        },
        end: {
          line: 236,
          column: 25
        }
      },
      '84': {
        start: {
          line: 248,
          column: 12
        },
        end: {
          line: 248,
          column: 42
        }
      },
      '85': {
        start: {
          line: 255,
          column: 6
        },
        end: {
          line: 270,
          column: 7
        }
      },
      '86': {
        start: {
          line: 256,
          column: 8
        },
        end: {
          line: 256,
          column: 83
        }
      },
      '87': {
        start: {
          line: 258,
          column: 8
        },
        end: {
          line: 268,
          column: 65
        }
      },
      '88': {
        start: {
          line: 269,
          column: 8
        },
        end: {
          line: 269,
          column: 95
        }
      },
      '89': {
        start: {
          line: 274,
          column: 2
        },
        end: {
          line: 330,
          column: 4
        }
      },
      '90': {
        start: {
          line: 275,
          column: 4
        },
        end: {
          line: 275,
          column: 31
        }
      },
      '91': {
        start: {
          line: 277,
          column: 4
        },
        end: {
          line: 277,
          column: 27
        }
      },
      '92': {
        start: {
          line: 278,
          column: 19
        },
        end: {
          line: 278,
          column: 172
        }
      },
      '93': {
        start: {
          line: 280,
          column: 4
        },
        end: {
          line: 329,
          column: 7
        }
      },
      '94': {
        start: {
          line: 281,
          column: 6
        },
        end: {
          line: 281,
          column: 34
        }
      },
      '95': {
        start: {
          line: 282,
          column: 24
        },
        end: {
          line: 282,
          column: 60
        }
      },
      '96': {
        start: {
          line: 283,
          column: 6
        },
        end: {
          line: 283,
          column: 48
        }
      },
      '97': {
        start: {
          line: 284,
          column: 6
        },
        end: {
          line: 325,
          column: 7
        }
      },
      '98': {
        start: {
          line: 285,
          column: 24
        },
        end: {
          line: 287,
          column: 10
        }
      },
      '99': {
        start: {
          line: 286,
          column: 10
        },
        end: {
          line: 286,
          column: 42
        }
      },
      '100': {
        start: {
          line: 288,
          column: 25
        },
        end: {
          line: 288,
          column: 123
        }
      },
      '101': {
        start: {
          line: 289,
          column: 22
        },
        end: {
          line: 294,
          column: 56
        }
      },
      '102': {
        start: {
          line: 295,
          column: 8
        },
        end: {
          line: 297,
          column: 9
        }
      },
      '103': {
        start: {
          line: 296,
          column: 10
        },
        end: {
          line: 296,
          column: 70
        }
      },
      '104': {
        start: {
          line: 298,
          column: 8
        },
        end: {
          line: 319,
          column: 9
        }
      },
      '105': {
        start: {
          line: 299,
          column: 10
        },
        end: {
          line: 299,
          column: 37
        }
      },
      '106': {
        start: {
          line: 301,
          column: 25
        },
        end: {
          line: 301,
          column: 107
        }
      },
      '107': {
        start: {
          line: 302,
          column: 10
        },
        end: {
          line: 302,
          column: 32
        }
      },
      '108': {
        start: {
          line: 303,
          column: 10
        },
        end: {
          line: 303,
          column: 30
        }
      },
      '109': {
        start: {
          line: 305,
          column: 10
        },
        end: {
          line: 308,
          column: 11
        }
      },
      '110': {
        start: {
          line: 306,
          column: 12
        },
        end: {
          line: 306,
          column: 76
        }
      },
      '111': {
        start: {
          line: 307,
          column: 12
        },
        end: {
          line: 307,
          column: 32
        }
      },
      '112': {
        start: {
          line: 310,
          column: 10
        },
        end: {
          line: 313,
          column: 11
        }
      },
      '113': {
        start: {
          line: 311,
          column: 12
        },
        end: {
          line: 311,
          column: 76
        }
      },
      '114': {
        start: {
          line: 312,
          column: 12
        },
        end: {
          line: 312,
          column: 32
        }
      },
      '115': {
        start: {
          line: 315,
          column: 10
        },
        end: {
          line: 318,
          column: 11
        }
      },
      '116': {
        start: {
          line: 316,
          column: 12
        },
        end: {
          line: 316,
          column: 80
        }
      },
      '117': {
        start: {
          line: 317,
          column: 12
        },
        end: {
          line: 317,
          column: 36
        }
      },
      '118': {
        start: {
          line: 320,
          column: 8
        },
        end: {
          line: 323,
          column: 9
        }
      },
      '119': {
        start: {
          line: 321,
          column: 10
        },
        end: {
          line: 321,
          column: 40
        }
      },
      '120': {
        start: {
          line: 322,
          column: 10
        },
        end: {
          line: 322,
          column: 36
        }
      },
      '121': {
        start: {
          line: 324,
          column: 8
        },
        end: {
          line: 324,
          column: 57
        }
      },
      '122': {
        start: {
          line: 326,
          column: 6
        },
        end: {
          line: 326,
          column: 58
        }
      },
      '123': {
        start: {
          line: 327,
          column: 6
        },
        end: {
          line: 327,
          column: 77
        }
      },
      '124': {
        start: {
          line: 328,
          column: 6
        },
        end: {
          line: 328,
          column: 73
        }
      },
      '125': {
        start: {
          line: 331,
          column: 2
        },
        end: {
          line: 331,
          column: 25
        }
      },
      '126': {
        start: {
          line: 332,
          column: 2
        },
        end: {
          line: 339,
          column: 5
        }
      },
      '127': {
        start: {
          line: 333,
          column: 4
        },
        end: {
          line: 333,
          column: 34
        }
      },
      '128': {
        start: {
          line: 334,
          column: 4
        },
        end: {
          line: 334,
          column: 47
        }
      },
      '129': {
        start: {
          line: 335,
          column: 4
        },
        end: {
          line: 335,
          column: 118
        }
      },
      '130': {
        start: {
          line: 336,
          column: 4
        },
        end: {
          line: 336,
          column: 45
        }
      },
      '131': {
        start: {
          line: 337,
          column: 4
        },
        end: {
          line: 337,
          column: 48
        }
      },
      '132': {
        start: {
          line: 338,
          column: 4
        },
        end: {
          line: 338,
          column: 26
        }
      },
      '133': {
        start: {
          line: 341,
          column: 0
        },
        end: {
          line: 365,
          column: 3
        }
      },
      '134': {
        start: {
          line: 342,
          column: 2
        },
        end: {
          line: 364,
          column: 4
        }
      },
      '135': {
        start: {
          line: 351,
          column: 6
        },
        end: {
          line: 361,
          column: 8
        }
      },
      '136': {
        start: {
          line: 354,
          column: 10
        },
        end: {
          line: 356,
          column: 11
        }
      },
      '137': {
        start: {
          line: 355,
          column: 12
        },
        end: {
          line: 355,
          column: 42
        }
      },
      '138': {
        start: {
          line: 357,
          column: 10
        },
        end: {
          line: 359,
          column: 11
        }
      },
      '139': {
        start: {
          line: 358,
          column: 12
        },
        end: {
          line: 358,
          column: 34
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 31
          },
          end: {
            line: 1,
            column: 32
          }
        },
        loc: {
          start: {
            line: 1,
            column: 127
          },
          end: {
            line: 340,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 5,
            column: 22
          },
          end: {
            line: 5,
            column: 23
          }
        },
        loc: {
          start: {
            line: 5,
            column: 38
          },
          end: {
            line: 78,
            column: 3
          }
        },
        line: 5
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 121,
            column: 27
          },
          end: {
            line: 121,
            column: 28
          }
        },
        loc: {
          start: {
            line: 121,
            column: 41
          },
          end: {
            line: 125,
            column: 3
          }
        },
        line: 121
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 122,
            column: 71
          },
          end: {
            line: 122,
            column: 72
          }
        },
        loc: {
          start: {
            line: 122,
            column: 79
          },
          end: {
            line: 124,
            column: 5
          }
        },
        line: 122
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 126,
            column: 23
          },
          end: {
            line: 126,
            column: 24
          }
        },
        loc: {
          start: {
            line: 126,
            column: 35
          },
          end: {
            line: 128,
            column: 3
          }
        },
        line: 126
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 129,
            column: 21
          },
          end: {
            line: 129,
            column: 22
          }
        },
        loc: {
          start: {
            line: 129,
            column: 35
          },
          end: {
            line: 272,
            column: 3
          }
        },
        line: 129
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 134,
            column: 38
          },
          end: {
            line: 134,
            column: 39
          }
        },
        loc: {
          start: {
            line: 134,
            column: 46
          },
          end: {
            line: 271,
            column: 5
          }
        },
        line: 134
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 138,
            column: 52
          },
          end: {
            line: 138,
            column: 53
          }
        },
        loc: {
          start: {
            line: 138,
            column: 61
          },
          end: {
            line: 140,
            column: 7
          }
        },
        line: 138
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 153,
            column: 61
          },
          end: {
            line: 153,
            column: 62
          }
        },
        loc: {
          start: {
            line: 153,
            column: 71
          },
          end: {
            line: 192,
            column: 9
          }
        },
        line: 153
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 155,
            column: 20
          },
          end: {
            line: 155,
            column: 21
          }
        },
        loc: {
          start: {
            line: 155,
            column: 25
          },
          end: {
            line: 167,
            column: 13
          }
        },
        line: 155
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 168,
            column: 17
          },
          end: {
            line: 168,
            column: 18
          }
        },
        loc: {
          start: {
            line: 168,
            column: 22
          },
          end: {
            line: 172,
            column: 13
          }
        },
        line: 168
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 224,
            column: 58
          },
          end: {
            line: 224,
            column: 59
          }
        },
        loc: {
          start: {
            line: 224,
            column: 67
          },
          end: {
            line: 237,
            column: 11
          }
        },
        line: 224
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 247,
            column: 60
          },
          end: {
            line: 247,
            column: 61
          }
        },
        loc: {
          start: {
            line: 247,
            column: 69
          },
          end: {
            line: 249,
            column: 11
          }
        },
        line: 247
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 274,
            column: 18
          },
          end: {
            line: 274,
            column: 19
          }
        },
        loc: {
          start: {
            line: 274,
            column: 32
          },
          end: {
            line: 330,
            column: 3
          }
        },
        line: 274
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 280,
            column: 38
          },
          end: {
            line: 280,
            column: 39
          }
        },
        loc: {
          start: {
            line: 280,
            column: 46
          },
          end: {
            line: 329,
            column: 5
          }
        },
        line: 280
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 285,
            column: 41
          },
          end: {
            line: 285,
            column: 42
          }
        },
        loc: {
          start: {
            line: 285,
            column: 48
          },
          end: {
            line: 287,
            column: 9
          }
        },
        line: 285
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 332,
            column: 75
          },
          end: {
            line: 332,
            column: 76
          }
        },
        loc: {
          start: {
            line: 332,
            column: 83
          },
          end: {
            line: 339,
            column: 3
          }
        },
        line: 332
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 341,
            column: 56
          },
          end: {
            line: 341,
            column: 57
          }
        },
        loc: {
          start: {
            line: 341,
            column: 62
          },
          end: {
            line: 365,
            column: 1
          }
        },
        line: 341
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 353,
            column: 8
          },
          end: {
            line: 353,
            column: 9
          }
        },
        loc: {
          start: {
            line: 353,
            column: 14
          },
          end: {
            line: 360,
            column: 9
          }
        },
        line: 353
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 20
          },
          end: {
            line: 6,
            column: 70
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 6,
            column: 20
          },
          end: {
            line: 6,
            column: 54
          }
        }, {
          start: {
            line: 6,
            column: 58
          },
          end: {
            line: 6,
            column: 70
          }
        }],
        line: 6
      },
      '1': {
        loc: {
          start: {
            line: 12,
            column: 4
          },
          end: {
            line: 77,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 12,
            column: 4
          },
          end: {
            line: 77,
            column: 5
          }
        }, {
          start: {
            line: 12,
            column: 4
          },
          end: {
            line: 77,
            column: 5
          }
        }],
        line: 12
      },
      '2': {
        loc: {
          start: {
            line: 46,
            column: 8
          },
          end: {
            line: 48,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 46,
            column: 8
          },
          end: {
            line: 48,
            column: 9
          }
        }, {
          start: {
            line: 46,
            column: 8
          },
          end: {
            line: 48,
            column: 9
          }
        }],
        line: 46
      },
      '3': {
        loc: {
          start: {
            line: 51,
            column: 6
          },
          end: {
            line: 54,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 51,
            column: 6
          },
          end: {
            line: 54,
            column: 7
          }
        }, {
          start: {
            line: 51,
            column: 6
          },
          end: {
            line: 54,
            column: 7
          }
        }],
        line: 51
      },
      '4': {
        loc: {
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        }, {
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        }],
        line: 62
      },
      '5': {
        loc: {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 69,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 69,
            column: 7
          }
        }, {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 69,
            column: 7
          }
        }],
        line: 67
      },
      '6': {
        loc: {
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 76,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 76,
            column: 7
          }
        }, {
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 76,
            column: 7
          }
        }],
        line: 74
      },
      '7': {
        loc: {
          start: {
            line: 107,
            column: 8
          },
          end: {
            line: 109,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 107,
            column: 8
          },
          end: {
            line: 109,
            column: 9
          }
        }, {
          start: {
            line: 107,
            column: 8
          },
          end: {
            line: 109,
            column: 9
          }
        }],
        line: 107
      },
      '8': {
        loc: {
          start: {
            line: 110,
            column: 8
          },
          end: {
            line: 112,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 110,
            column: 8
          },
          end: {
            line: 112,
            column: 9
          }
        }, {
          start: {
            line: 110,
            column: 8
          },
          end: {
            line: 112,
            column: 9
          }
        }],
        line: 110
      },
      '9': {
        loc: {
          start: {
            line: 130,
            column: 4
          },
          end: {
            line: 132,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 130,
            column: 4
          },
          end: {
            line: 132,
            column: 5
          }
        }, {
          start: {
            line: 130,
            column: 4
          },
          end: {
            line: 132,
            column: 5
          }
        }],
        line: 130
      },
      '10': {
        loc: {
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        }, {
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        }],
        line: 135
      },
      '11': {
        loc: {
          start: {
            line: 141,
            column: 20
          },
          end: {
            line: 141,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 141,
            column: 43
          },
          end: {
            line: 141,
            column: 57
          }
        }, {
          start: {
            line: 141,
            column: 60
          },
          end: {
            line: 141,
            column: 63
          }
        }],
        line: 141
      },
      '12': {
        loc: {
          start: {
            line: 144,
            column: 6
          },
          end: {
            line: 151,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 144,
            column: 6
          },
          end: {
            line: 151,
            column: 7
          }
        }, {
          start: {
            line: 144,
            column: 6
          },
          end: {
            line: 151,
            column: 7
          }
        }],
        line: 144
      },
      '13': {
        loc: {
          start: {
            line: 144,
            column: 10
          },
          end: {
            line: 144,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 144,
            column: 10
          },
          end: {
            line: 144,
            column: 25
          }
        }, {
          start: {
            line: 144,
            column: 29
          },
          end: {
            line: 144,
            column: 64
          }
        }],
        line: 144
      },
      '14': {
        loc: {
          start: {
            line: 149,
            column: 19
          },
          end: {
            line: 149,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 149,
            column: 19
          },
          end: {
            line: 149,
            column: 45
          }
        }, {
          start: {
            line: 149,
            column: 49
          },
          end: {
            line: 149,
            column: 50
          }
        }],
        line: 149
      },
      '15': {
        loc: {
          start: {
            line: 154,
            column: 23
          },
          end: {
            line: 154,
            column: 33
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 154,
            column: 23
          },
          end: {
            line: 154,
            column: 27
          }
        }, {
          start: {
            line: 154,
            column: 31
          },
          end: {
            line: 154,
            column: 33
          }
        }],
        line: 154
      },
      '16': {
        loc: {
          start: {
            line: 157,
            column: 14
          },
          end: {
            line: 159,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 157,
            column: 14
          },
          end: {
            line: 159,
            column: 15
          }
        }, {
          start: {
            line: 157,
            column: 14
          },
          end: {
            line: 159,
            column: 15
          }
        }],
        line: 157
      },
      '17': {
        loc: {
          start: {
            line: 160,
            column: 14
          },
          end: {
            line: 162,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 160,
            column: 14
          },
          end: {
            line: 162,
            column: 15
          }
        }, {
          start: {
            line: 160,
            column: 14
          },
          end: {
            line: 162,
            column: 15
          }
        }],
        line: 160
      },
      '18': {
        loc: {
          start: {
            line: 163,
            column: 14
          },
          end: {
            line: 165,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 163,
            column: 14
          },
          end: {
            line: 165,
            column: 15
          }
        }, {
          start: {
            line: 163,
            column: 14
          },
          end: {
            line: 165,
            column: 15
          }
        }],
        line: 163
      },
      '19': {
        loc: {
          start: {
            line: 173,
            column: 18
          },
          end: {
            line: 173,
            column: 83
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 173,
            column: 18
          },
          end: {
            line: 173,
            column: 49
          }
        }, {
          start: {
            line: 173,
            column: 53
          },
          end: {
            line: 173,
            column: 83
          }
        }],
        line: 173
      },
      '20': {
        loc: {
          start: {
            line: 208,
            column: 6
          },
          end: {
            line: 253,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 208,
            column: 6
          },
          end: {
            line: 253,
            column: 7
          }
        }, {
          start: {
            line: 208,
            column: 6
          },
          end: {
            line: 253,
            column: 7
          }
        }],
        line: 208
      },
      '21': {
        loc: {
          start: {
            line: 226,
            column: 20
          },
          end: {
            line: 226,
            column: 52
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 226,
            column: 35
          },
          end: {
            line: 226,
            column: 41
          }
        }, {
          start: {
            line: 226,
            column: 44
          },
          end: {
            line: 226,
            column: 52
          }
        }],
        line: 226
      },
      '22': {
        loc: {
          start: {
            line: 227,
            column: 22
          },
          end: {
            line: 227,
            column: 87
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 227,
            column: 22
          },
          end: {
            line: 227,
            column: 53
          }
        }, {
          start: {
            line: 227,
            column: 57
          },
          end: {
            line: 227,
            column: 87
          }
        }],
        line: 227
      },
      '23': {
        loc: {
          start: {
            line: 231,
            column: 12
          },
          end: {
            line: 235,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 231,
            column: 12
          },
          end: {
            line: 235,
            column: 13
          }
        }, {
          start: {
            line: 231,
            column: 12
          },
          end: {
            line: 235,
            column: 13
          }
        }],
        line: 231
      },
      '24': {
        loc: {
          start: {
            line: 255,
            column: 6
          },
          end: {
            line: 270,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 255,
            column: 6
          },
          end: {
            line: 270,
            column: 7
          }
        }, {
          start: {
            line: 255,
            column: 6
          },
          end: {
            line: 270,
            column: 7
          }
        }],
        line: 255
      },
      '25': {
        loc: {
          start: {
            line: 288,
            column: 25
          },
          end: {
            line: 288,
            column: 123
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 288,
            column: 77
          },
          end: {
            line: 288,
            column: 78
          }
        }, {
          start: {
            line: 288,
            column: 82
          },
          end: {
            line: 288,
            column: 122
          }
        }],
        line: 288
      },
      '26': {
        loc: {
          start: {
            line: 288,
            column: 26
          },
          end: {
            line: 288,
            column: 73
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 288,
            column: 26
          },
          end: {
            line: 288,
            column: 39
          }
        }, {
          start: {
            line: 288,
            column: 43
          },
          end: {
            line: 288,
            column: 56
          }
        }, {
          start: {
            line: 288,
            column: 60
          },
          end: {
            line: 288,
            column: 73
          }
        }],
        line: 288
      },
      '27': {
        loc: {
          start: {
            line: 288,
            column: 82
          },
          end: {
            line: 288,
            column: 122
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 288,
            column: 117
          },
          end: {
            line: 288,
            column: 118
          }
        }, {
          start: {
            line: 288,
            column: 121
          },
          end: {
            line: 288,
            column: 122
          }
        }],
        line: 288
      },
      '28': {
        loc: {
          start: {
            line: 288,
            column: 83
          },
          end: {
            line: 288,
            column: 113
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 288,
            column: 83
          },
          end: {
            line: 288,
            column: 96
          }
        }, {
          start: {
            line: 288,
            column: 100
          },
          end: {
            line: 288,
            column: 113
          }
        }],
        line: 288
      },
      '29': {
        loc: {
          start: {
            line: 289,
            column: 37
          },
          end: {
            line: 294,
            column: 9
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 289,
            column: 37
          },
          end: {
            line: 289,
            column: 47
          }
        }, {
          start: {
            line: 289,
            column: 51
          },
          end: {
            line: 294,
            column: 9
          }
        }],
        line: 289
      },
      '30': {
        loc: {
          start: {
            line: 292,
            column: 21
          },
          end: {
            line: 292,
            column: 53
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 292,
            column: 37
          },
          end: {
            line: 292,
            column: 44
          }
        }, {
          start: {
            line: 292,
            column: 47
          },
          end: {
            line: 292,
            column: 53
          }
        }],
        line: 292
      },
      '31': {
        loc: {
          start: {
            line: 295,
            column: 8
          },
          end: {
            line: 297,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 295,
            column: 8
          },
          end: {
            line: 297,
            column: 9
          }
        }, {
          start: {
            line: 295,
            column: 8
          },
          end: {
            line: 297,
            column: 9
          }
        }],
        line: 295
      },
      '32': {
        loc: {
          start: {
            line: 296,
            column: 32
          },
          end: {
            line: 296,
            column: 68
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 296,
            column: 48
          },
          end: {
            line: 296,
            column: 55
          }
        }, {
          start: {
            line: 296,
            column: 58
          },
          end: {
            line: 296,
            column: 68
          }
        }],
        line: 296
      },
      '33': {
        loc: {
          start: {
            line: 298,
            column: 8
          },
          end: {
            line: 319,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 298,
            column: 8
          },
          end: {
            line: 319,
            column: 9
          }
        }, {
          start: {
            line: 298,
            column: 8
          },
          end: {
            line: 319,
            column: 9
          }
        }],
        line: 298
      },
      '34': {
        loc: {
          start: {
            line: 301,
            column: 37
          },
          end: {
            line: 301,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 301,
            column: 37
          },
          end: {
            line: 301,
            column: 45
          }
        }, {
          start: {
            line: 301,
            column: 49
          },
          end: {
            line: 301,
            column: 57
          }
        }],
        line: 301
      },
      '35': {
        loc: {
          start: {
            line: 305,
            column: 10
          },
          end: {
            line: 308,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 305,
            column: 10
          },
          end: {
            line: 308,
            column: 11
          }
        }, {
          start: {
            line: 305,
            column: 10
          },
          end: {
            line: 308,
            column: 11
          }
        }],
        line: 305
      },
      '36': {
        loc: {
          start: {
            line: 310,
            column: 10
          },
          end: {
            line: 313,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 310,
            column: 10
          },
          end: {
            line: 313,
            column: 11
          }
        }, {
          start: {
            line: 310,
            column: 10
          },
          end: {
            line: 313,
            column: 11
          }
        }],
        line: 310
      },
      '37': {
        loc: {
          start: {
            line: 315,
            column: 10
          },
          end: {
            line: 318,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 315,
            column: 10
          },
          end: {
            line: 318,
            column: 11
          }
        }, {
          start: {
            line: 315,
            column: 10
          },
          end: {
            line: 318,
            column: 11
          }
        }],
        line: 315
      },
      '38': {
        loc: {
          start: {
            line: 320,
            column: 8
          },
          end: {
            line: 323,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 320,
            column: 8
          },
          end: {
            line: 323,
            column: 9
          }
        }, {
          start: {
            line: 320,
            column: 8
          },
          end: {
            line: 323,
            column: 9
          }
        }],
        line: 320
      },
      '39': {
        loc: {
          start: {
            line: 335,
            column: 80
          },
          end: {
            line: 335,
            column: 115
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 335,
            column: 80
          },
          end: {
            line: 335,
            column: 109
          }
        }, {
          start: {
            line: 335,
            column: 113
          },
          end: {
            line: 335,
            column: 115
          }
        }],
        line: 335
      },
      '40': {
        loc: {
          start: {
            line: 354,
            column: 10
          },
          end: {
            line: 356,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 354,
            column: 10
          },
          end: {
            line: 356,
            column: 11
          }
        }, {
          start: {
            line: 354,
            column: 10
          },
          end: {
            line: 356,
            column: 11
          }
        }],
        line: 354
      },
      '41': {
        loc: {
          start: {
            line: 357,
            column: 10
          },
          end: {
            line: 359,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 357,
            column: 10
          },
          end: {
            line: 359,
            column: 11
          }
        }, {
          start: {
            line: 357,
            column: 10
          },
          end: {
            line: 359,
            column: 11
          }
        }],
        line: 357
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1s7ak9ao91.s[0]++;
var fnDashboardTablesimple = function fnDashboardTablesimple($scope, $deltadate, $translate, $deltaLocation, $LOGINService, $filter, $CRUDService) {
  cov_1s7ak9ao91.f[0]++;

  var PATH = (cov_1s7ak9ao91.s[1]++, 'Formular');
  // Get Data
  var p_bksb_ = (cov_1s7ak9ao91.s[2]++, null);
  cov_1s7ak9ao91.s[3]++;
  $scope.exportData = function (type) {
    cov_1s7ak9ao91.f[1]++;

    var title_ = (cov_1s7ak9ao91.s[4]++, ((cov_1s7ak9ao91.b[0][0]++, $scope.selectedRequest.NAMEREQUEST) || (cov_1s7ak9ao91.b[0][1]++, 'new report')).toUpperCase());
    var A4Size = (cov_1s7ak9ao91.s[5]++, 1000);
    var date = (cov_1s7ak9ao91.s[6]++, '');

    cov_1s7ak9ao91.s[7]++;
    date += new Date().getMonth().toString() + '/' + new Date().getDate().toString();
    cov_1s7ak9ao91.s[8]++;
    date += '/' + new Date().getFullYear().toString();
    cov_1s7ak9ao91.s[9]++;
    if (type.toUpperCase() == 'PDF') {
      cov_1s7ak9ao91.b[1][0]++;

      var _pdfExportProperties = (cov_1s7ak9ao91.s[10]++, {
        pageSize: 'A4',
        header: {
          fromTop: 0,
          height: 50,
          contents: [{
            type: 'Text',
            value: title_,
            position: { x: 0, y: 0 },
            style: { fontSize: 11, hAlign: 'Center', vAlign: 'Center' }
          }]
        },
        footer: {
          fromBottom: 0,
          height: 20,
          contents: [{
            type: 'PageNumber',
            pageNumberType: 'Arabic',
            format: '{$current} / {$total}',
            position: { x: 200, y: 0 },
            style: { textBrushColor: '#353535', fontSize: 9, hAlign: 'Center' }
          }]
        },
        pageOrientation: 'Landscape',
        fileName: title_ + '.pdf'
      });
      var pageSize = (cov_1s7ak9ao91.s[11]++, 'A4');
      var width = (cov_1s7ak9ao91.s[12]++, 0);
      cov_1s7ak9ao91.s[13]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = $scope.pivotTableObj.columns[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var column = _step.value;
          cov_1s7ak9ao91.s[14]++;

          if (!column.visible) {
            cov_1s7ak9ao91.b[2][0]++;
            cov_1s7ak9ao91.s[15]++;

            continue;
          } else {
            cov_1s7ak9ao91.b[2][1]++;
          }
          cov_1s7ak9ao91.s[16]++;
          width += parseFloat(column.width.replace('px', ''));
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_1s7ak9ao91.s[17]++;
      if (width > A4Size) {
        cov_1s7ak9ao91.b[3][0]++;
        cov_1s7ak9ao91.s[18]++;

        A4Size = 1450;
        cov_1s7ak9ao91.s[19]++;
        pageSize = 'A3';
      } else {
        cov_1s7ak9ao91.b[3][1]++;
      }
      var prop = (cov_1s7ak9ao91.s[20]++, A4Size / width);
      cov_1s7ak9ao91.s[21]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = $scope.pivotTableObj.columns[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _column = _step2.value;
          cov_1s7ak9ao91.s[22]++;

          width = parseFloat(_column.width.replace('px', ''));
          cov_1s7ak9ao91.s[23]++;
          _column.width = width * prop + 'px';
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      cov_1s7ak9ao91.s[24]++;
      _pdfExportProperties.footer.contents[0].position.x = A4Size - 50;
      cov_1s7ak9ao91.s[25]++;
      _pdfExportProperties.header.contents[0].position.x = (700 - 700 * title_.length / 104) / 2;
      cov_1s7ak9ao91.s[26]++;
      if (_pdfExportProperties.header.contents[0].position.x < 0) {
        cov_1s7ak9ao91.b[4][0]++;
        cov_1s7ak9ao91.s[27]++;

        _pdfExportProperties.header.contents[0].position.x = 0;
      } else {
        cov_1s7ak9ao91.b[4][1]++;
      }
      cov_1s7ak9ao91.s[28]++;
      _pdfExportProperties.pageSize = pageSize;
      cov_1s7ak9ao91.s[29]++;
      $scope.pivotTableObj.pdfExport(_pdfExportProperties);
      cov_1s7ak9ao91.s[30]++;
      if ($scope.chart) {
        cov_1s7ak9ao91.b[5][0]++;
        cov_1s7ak9ao91.s[31]++;

        $scope.chart.export('PDF', title_ + 'Chart', _pdfExportProperties);
      } else {
        cov_1s7ak9ao91.b[5][1]++;
      }
    } else {
      cov_1s7ak9ao91.b[1][1]++;
      cov_1s7ak9ao91.s[32]++;

      $scope.pivotTableObj.excelExport({
        fileName: $scope.selectedRequest.NAMEREQUEST + '.xlsx'
      });
      cov_1s7ak9ao91.s[33]++;
      if ($scope.chart) {
        cov_1s7ak9ao91.b[6][0]++;
        cov_1s7ak9ao91.s[34]++;

        $scope.chart.export('JPEG', title_ + 'Chart', pdfExportProperties);
      } else {
        cov_1s7ak9ao91.b[6][1]++;
      }
    }
  };

  cov_1s7ak9ao91.s[35]++;
  $scope.syncfusionGrid = {
    params: {
      locale: $LOGINService.getLang(),
      // dataSource: window.getTradeData(1000),
      allowGrouping: true,
      showColumnChooser: false,
      allowSelection: true,
      allowSorting: true,
      allowFiltering: false,
      allowPdfExport: true,
      allowExcelExport: true,
      filterSettings: { type: 'Excel' },
      width: $('content_' + $scope.idcomponent).width() - 10 + 'px',
      pageSettings: { pageSize: 50 },
      enableHover: true,
      height: '100%',
      allowTextWrap: true,
      rowHeight: 38,
      columns: [],
      toolbar: [/* {
                prefixIcon: 'e-chart e-icons', tooltipText: $translate.instant('COMMON.CHART'),
                click(args) {
                $scope.chartView = true;
                },
                align: 'left'
                }, */'ExcelExport' /* , 'PdfExport' */],
      toolbarClick: function toolbarClick(args) {
        cov_1s7ak9ao91.s[36]++;

        if (args.item.id.endsWith('_pdfexport')) {
          cov_1s7ak9ao91.b[7][0]++;
          cov_1s7ak9ao91.s[37]++;

          $scope.exportData('pdf');
        } else {
          cov_1s7ak9ao91.b[7][1]++;
        }
        cov_1s7ak9ao91.s[38]++;
        if (args.item.id.endsWith('_excelexport')) {
          cov_1s7ak9ao91.b[8][0]++;
          cov_1s7ak9ao91.s[39]++;

          $scope.exportData('excel');
        } else {
          cov_1s7ak9ao91.b[8][1]++;
        }
      },
      pdfHeaderQueryCellInfo: function pdfHeaderQueryCellInfo(args) {
        cov_1s7ak9ao91.s[40]++;

        args.cell.row.pdfGrid.repeatHeader = true;
      }
    }
  };
  cov_1s7ak9ao91.s[41]++;
  $scope.requestFields = [];

  cov_1s7ak9ao91.s[42]++;
  $scope.getFilterFields = function (id) {
    cov_1s7ak9ao91.f[2]++;
    cov_1s7ak9ao91.s[43]++;

    $CRUDService.getAll(PATH, { get: 'all_filter_fields', id: id, p_bksb_: p_bksb_ }, function (data) {
      cov_1s7ak9ao91.f[3]++;
      cov_1s7ak9ao91.s[44]++;

      $scope.requestFields = data;
    });
  };
  cov_1s7ak9ao91.s[45]++;
  $scope.toggleChart = function () {
    cov_1s7ak9ao91.f[4]++;
    cov_1s7ak9ao91.s[46]++;

    $scope.chartView = !$scope.chartView;
  };
  cov_1s7ak9ao91.s[47]++;
  $scope.drawChart = function (id) {
    cov_1s7ak9ao91.f[5]++;
    cov_1s7ak9ao91.s[48]++;

    if ($scope.chart) {
      cov_1s7ak9ao91.b[9][0]++;
      cov_1s7ak9ao91.s[49]++;

      return;
    } else {
      cov_1s7ak9ao91.b[9][1]++;
    }
    var params = (cov_1s7ak9ao91.s[50]++, { withData: 1, export: 1, page: 0, id: id, p_bksb_: p_bksb_, id_request: $scope.idForm, paging: 1, get: 'all_r_graph', sort: $filter('json')([]) });
    cov_1s7ak9ao91.s[51]++;
    $CRUDService.getAll(PATH, params, function (data) {
      cov_1s7ak9ao91.f[6]++;
      cov_1s7ak9ao91.s[52]++;

      if (!angular.isArray(data)) {
        cov_1s7ak9ao91.b[10][0]++;
        cov_1s7ak9ao91.s[53]++;

        return;
      } else {
        cov_1s7ak9ao91.b[10][1]++;
      }
      var categorie = (cov_1s7ak9ao91.s[54]++, $scope.requestFields.filter(function (value) {
        cov_1s7ak9ao91.f[7]++;
        cov_1s7ak9ao91.s[55]++;

        return value.id == $scope.selectedRequest.CHART.C;
      }));
      var idCat = (cov_1s7ak9ao91.s[56]++, categorie.length > 0 ? (cov_1s7ak9ao91.b[11][0]++, categorie[0].l) : (cov_1s7ak9ao91.b[11][1]++, '_'));
      var Total = (cov_1s7ak9ao91.s[57]++, 1);
      var id = (cov_1s7ak9ao91.s[58]++, '_');
      cov_1s7ak9ao91.s[59]++;
      if ((cov_1s7ak9ao91.b[13][0]++, data.length > 0) && (cov_1s7ak9ao91.b[13][1]++, $scope.selectedRequest.CHART.T != 1)) {
        cov_1s7ak9ao91.b[12][0]++;
        cov_1s7ak9ao91.s[60]++;

        Total = 0;
        var serie = (cov_1s7ak9ao91.s[61]++, $scope.selectedRequest.CHART.SERIES[0]);
        cov_1s7ak9ao91.s[62]++;
        id = serie.id;
        cov_1s7ak9ao91.s[63]++;
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = data[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var item = _step3.value;
            cov_1s7ak9ao91.s[64]++;

            Total += (cov_1s7ak9ao91.b[14][0]++, parseFloat(item[serie.id])) || (cov_1s7ak9ao91.b[14][1]++, 0);
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      } else {
        cov_1s7ak9ao91.b[12][1]++;
      }
      var params = (cov_1s7ak9ao91.s[65]++, {
        series: [$scope.selectedRequest.CHART.SERIES[0]].map(function (value) {
          cov_1s7ak9ao91.f[8]++;
          cov_1s7ak9ao91.s[66]++;
          return {
            dataSource: ((cov_1s7ak9ao91.b[15][0]++, data) || (cov_1s7ak9ao91.b[15][1]++, [])).filter(function (d) {
              cov_1s7ak9ao91.f[9]++;

              var val = (cov_1s7ak9ao91.s[67]++, parseFloat(d[value.id]));
              cov_1s7ak9ao91.s[68]++;
              if (d[value.id] === null) {
                cov_1s7ak9ao91.b[16][0]++;
                cov_1s7ak9ao91.s[69]++;

                return false;
              } else {
                cov_1s7ak9ao91.b[16][1]++;
              }
              cov_1s7ak9ao91.s[70]++;
              if (Number.isNaN(val)) {
                cov_1s7ak9ao91.b[17][0]++;
                cov_1s7ak9ao91.s[71]++;

                return false;
              } else {
                cov_1s7ak9ao91.b[17][1]++;
              }
              cov_1s7ak9ao91.s[72]++;
              if (angular.isNumber(val)) {
                cov_1s7ak9ao91.b[18][0]++;
                cov_1s7ak9ao91.s[73]++;

                return true;
              } else {
                cov_1s7ak9ao91.b[18][1]++;
              }
              cov_1s7ak9ao91.s[74]++;
              return false;
            }).map(function (d) {
              cov_1s7ak9ao91.f[10]++;
              cov_1s7ak9ao91.s[75]++;

              d[value.id] = parseFloat(d[value.id]);
              cov_1s7ak9ao91.s[76]++;
              d.radius = $filter('number')(parseInt(10000 * d[value.id] / Total, 10) / 100, 2) + '%';
              cov_1s7ak9ao91.s[77]++;
              return d;
            }),
            xName: (cov_1s7ak9ao91.b[19][0]++, $scope.selectedRequest.CHART.SB) || (cov_1s7ak9ao91.b[19][1]++, $scope.selectedRequest.CHART.C),
            yName: value.id,
            // radius: 'radius',
            // innerRadius: '20%',
            dataLabel: {
              visible: true,
              position: 'Outside',
              name: 'radius',
              font: {
                fontWeight: '600'
              }
            },
            radius: '70%',
            startAngle: 0,
            endAngle: 0,
            innerRadius: '0%',
            explode: true,
            explodeOffset: '10%',
            explodeIndex: 0
          };
        }),
        width: $('content_' + $scope.idcomponent).width() - 10 + 'px',
        height: '100%',
        center: { x: '50%', y: '50%' },
        enableSmartLabels: true,
        enableAnimation: true,
        legendSettings: {
          visible: true
        },
        // Initializing Tooltip
        // eslint-disable-next-line no-template-curly-in-string
        tooltip: { enable: true, /* header: 'Browser', */format: '${point.x}:<b> ${point.y}<b>'
          // Initializing Title
          // title: 'Mobile Browser Statistics',
        } });

      cov_1s7ak9ao91.s[78]++;
      if ($scope.selectedRequest.CHART.T == 1) {
        cov_1s7ak9ao91.b[20][0]++;
        cov_1s7ak9ao91.s[79]++;

        params = {
          useGroupingSeparator: true,
          primaryXAxis: {
            title: idCat,
            valueType: 'Category',
            edgeLabelPlacement: 'Shift',
            majorGridLines: { width: 0 }
          },
          primaryYAxis: {
            rangePadding: 'None',
            lineStyle: { width: 0 },
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
            valueType: 'Double'
          },
          series: $scope.selectedRequest.CHART.SERIES.map(function (value) {
            cov_1s7ak9ao91.f[11]++;

            var serie = (cov_1s7ak9ao91.s[80]++, {
              type: value.T == 1 ? (cov_1s7ak9ao91.b[21][0]++, 'Line') : (cov_1s7ak9ao91.b[21][1]++, 'Column'), dataSource: data,
              xName: (cov_1s7ak9ao91.b[22][0]++, $scope.selectedRequest.CHART.SB) || (cov_1s7ak9ao91.b[22][1]++, $scope.selectedRequest.CHART.C), yName: value.id, name: value.L,
              width: 2, opacity: 0.6
            });

            cov_1s7ak9ao91.s[81]++;
            if (value.T == 1) {
              cov_1s7ak9ao91.b[23][0]++;
              cov_1s7ak9ao91.s[82]++;

              serie.marker = {
                visible: true, width: 5, height: 5
              };
            } else {
              cov_1s7ak9ao91.b[23][1]++;
            }
            cov_1s7ak9ao91.s[83]++;
            return serie;
          }),
          zoomSettings: {
            enableMouseWheelZooming: true,
            enablePinchZooming: true,
            enableSelectionZooming: true
          },
          tooltip: {
            enable: true
          },
          palettes: $scope.selectedRequest.CHART.SERIES.map(function (value) {
            cov_1s7ak9ao91.f[12]++;
            cov_1s7ak9ao91.s[84]++;

            return 'rgb(' + value.C + ')';
          }),
          width: $('content_' + $scope.idcomponent).width() - 10 + 'px',
          height: '100%'
        };
      } else {
        cov_1s7ak9ao91.b[20][1]++;
      }

      cov_1s7ak9ao91.s[85]++;
      if ($scope.selectedRequest.CHART.T == 1) {
        cov_1s7ak9ao91.b[24][0]++;
        cov_1s7ak9ao91.s[86]++;

        $scope.chart = new ej.charts.Chart(params, '#chart_' + $scope.idcomponent);
      } else {
        cov_1s7ak9ao91.b[24][1]++;
        cov_1s7ak9ao91.s[87]++;

        params.palette = ['#BAF171', '#C4EAF6', '#E9B1FF', '#F49C94', '#FCF086', '#58D740', '#7DDAF8', '#CA6FEE', '#FE855F', '#F9E850', '#1EB799', '#29DAE9', '#B145F4', '#CD5145', '#EDDA2F', '#2EAB77', '#20C9B3', '#6F43EA', '#EB5757', '#EDC32F', '#66AF45', '#33ACED', '#562EBB', '#DA4A2B', '#D39F17', '#78A73D', '#208AC9', '#792AF9', '#F35634', '#B8953B', '#A5B212', '#3160BC', '#651E91', '#AE534B', '#F38434', '#12A5B2', '#5C83E7', '#911E71', '#7E3737', '#D36617', '#2F7980', '#035C88', '#401C5B', '#5E4138', '#B66225', '#0E3E53', '#093B69', '#240E35', '#3E2B11', '#B24712'];
        cov_1s7ak9ao91.s[88]++;
        $scope.chart = new ej.charts.AccumulationChart(params, '#chart_' + $scope.idcomponent);
      }
    });
  };

  cov_1s7ak9ao91.s[89]++;
  $scope.getAll = function (id) {
    cov_1s7ak9ao91.f[13]++;
    cov_1s7ak9ao91.s[90]++;

    $scope.getFilterFields(id);

    cov_1s7ak9ao91.s[91]++;
    $scope.listRecord = [];
    var params = (cov_1s7ak9ao91.s[92]++, { withData: 1, export: 1, page: 0, id: id, id_request: $scope.idForm, normalDate: 1, p_bksb_: p_bksb_, paging: 1, get: 'all_r_simple_table', sort: $filter('json')([]) });
    // const params = {get: 'all_r_simple_table', id: $scope.selectedTemplate.id, sort: $filter('json')($scope.sort || [])};
    cov_1s7ak9ao91.s[93]++;
    $CRUDService.getAll(PATH, params, function (data) {
      cov_1s7ak9ao91.f[14]++;
      cov_1s7ak9ao91.s[94]++;

      ej.base.enableRipple(false);
      var existCols = (cov_1s7ak9ao91.s[95]++, $scope.syncfusionGrid.params.columns);
      cov_1s7ak9ao91.s[96]++;
      $scope.syncfusionGrid.params.columns = [];
      cov_1s7ak9ao91.s[97]++;
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        var _loop = function _loop() {
          var header = _step4.value;

          var searchs = (cov_1s7ak9ao91.s[98]++, existCols.filter(function (col) {
            cov_1s7ak9ao91.f[15]++;
            cov_1s7ak9ao91.s[99]++;

            return col.field == header.name;
          }));
          var checkbox = (cov_1s7ak9ao91.s[100]++, (cov_1s7ak9ao91.b[26][0]++, header.t == 1) || (cov_1s7ak9ao91.b[26][1]++, header.t == 3) || (cov_1s7ak9ao91.b[26][2]++, header.t == 4) ? (cov_1s7ak9ao91.b[25][0]++, 1) : (cov_1s7ak9ao91.b[25][1]++, (cov_1s7ak9ao91.b[28][0]++, header.t == 2) || (cov_1s7ak9ao91.b[28][1]++, header.t == 6) ? (cov_1s7ak9ao91.b[27][0]++, 2) : (cov_1s7ak9ao91.b[27][1]++, 0)));
          var field = (cov_1s7ak9ao91.s[101]++, Object.assign((cov_1s7ak9ao91.b[29][0]++, searchs[0]) || (cov_1s7ak9ao91.b[29][1]++, {
            field: header.name,
            clipMode: 'EllipsisWithTooltip',
            textAlign: header.t == 4 ? (cov_1s7ak9ao91.b[30][0]++, 'right') : (cov_1s7ak9ao91.b[30][1]++, 'left')
            // width: '200'
          }), { headerText: header.caption, autoFit: true }));
          cov_1s7ak9ao91.s[102]++;
          if (checkbox > 0) {
            cov_1s7ak9ao91.b[31][0]++;
            cov_1s7ak9ao91.s[103]++;

            field.filter = { type: checkbox == 1 ? (cov_1s7ak9ao91.b[32][0]++, 'Excel') : (cov_1s7ak9ao91.b[32][1]++, 'CheckBox') };
          } else {
            cov_1s7ak9ao91.b[31][1]++;
          }
          cov_1s7ak9ao91.s[104]++;
          if (header.t == 3) {
            cov_1s7ak9ao91.b[33][0]++;
            cov_1s7ak9ao91.s[105]++;

            field.textAlign = 'Center';

            var format = (cov_1s7ak9ao91.s[106]++, { skeleton: ((cov_1s7ak9ao91.b[34][0]++, header.f) || (cov_1s7ak9ao91.b[34][1]++, 'Medium')).replace('Date', '').toLowerCase(), type: 'date' });
            cov_1s7ak9ao91.s[107]++;
            field.format = format;
            cov_1s7ak9ao91.s[108]++;
            field.type = 'date';

            cov_1s7ak9ao91.s[109]++;
            if (header.f == 'shortDate') {
              cov_1s7ak9ao91.b[35][0]++;
              cov_1s7ak9ao91.s[110]++;

              field.format = { skeleton: 'Medium'.toLowerCase(), type: 'date' };
              cov_1s7ak9ao91.s[111]++;
              field.type = 'date';
            } else {
              cov_1s7ak9ao91.b[35][1]++;
            }

            cov_1s7ak9ao91.s[112]++;
            if (header.f == 'shortTime') {
              cov_1s7ak9ao91.b[36][0]++;
              cov_1s7ak9ao91.s[113]++;

              field.format = { skeleton: 'Medium'.toLowerCase(), type: 'time' };
              cov_1s7ak9ao91.s[114]++;
              field.type = 'time';
            } else {
              cov_1s7ak9ao91.b[36][1]++;
            }

            cov_1s7ak9ao91.s[115]++;
            if (header.f == 'mediumDateTime') {
              cov_1s7ak9ao91.b[37][0]++;
              cov_1s7ak9ao91.s[116]++;

              field.format = { skeleton: 'Medium'.toLowerCase(), type: 'dateTime' };
              cov_1s7ak9ao91.s[117]++;
              field.type = 'dateTime';
            } else {
              cov_1s7ak9ao91.b[37][1]++;
            }
          } else {
            cov_1s7ak9ao91.b[33][1]++;
          }
          cov_1s7ak9ao91.s[118]++;
          if (header.t == 4) {
            cov_1s7ak9ao91.b[38][0]++;
            cov_1s7ak9ao91.s[119]++;

            field.format = 'N' + header.f;
            cov_1s7ak9ao91.s[120]++;
            field.textAlign = 'Right';
          } else {
            cov_1s7ak9ao91.b[38][1]++;
          }
          cov_1s7ak9ao91.s[121]++;
          $scope.syncfusionGrid.params.columns.push(field);
        };

        for (var _iterator4 = data.rows[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          _loop();
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      cov_1s7ak9ao91.s[122]++;
      $scope.syncfusionGrid.params.dataSource = data.data;
      cov_1s7ak9ao91.s[123]++;
      $scope.pivotTableObj = new ej.grids.Grid($scope.syncfusionGrid.params);
      cov_1s7ak9ao91.s[124]++;
      $scope.pivotTableObj.appendTo('#pivotTable_' + $scope.idcomponent);
    });
  };
  cov_1s7ak9ao91.s[125]++;
  $scope.listRecord = [];
  cov_1s7ak9ao91.s[126]++;
  $CRUDService.getAll(PATH, { get: 'one_request_by_id', id: $scope.idForm }, function (data) {
    cov_1s7ak9ao91.f[16]++;
    cov_1s7ak9ao91.s[127]++;

    $scope.selectedRequest = data;
    cov_1s7ak9ao91.s[128]++;
    p_bksb_ = $scope.selectedRequest.ID_PROJET;
    cov_1s7ak9ao91.s[129]++;
    $scope.syncfusionGrid.params = Object.assign($scope.syncfusionGrid.params, (cov_1s7ak9ao91.b[39][0]++, $scope.selectedRequest.TABLE_) || (cov_1s7ak9ao91.b[39][1]++, {}));
    cov_1s7ak9ao91.s[130]++;
    $scope.getAll($scope.selectedRequest.ID);
    cov_1s7ak9ao91.s[131]++;
    $scope.drawChart($scope.selectedRequest.ID);
    cov_1s7ak9ao91.s[132]++;
    $scope.endInit = true;
  });
};
cov_1s7ak9ao91.s[133]++;
angular.module('app').directive('dashboardTablesimple', function () {
  cov_1s7ak9ao91.f[17]++;
  cov_1s7ak9ao91.s[134]++;

  return {
    restrict: 'E',
    scope: {
      idcomponent: '@',
      idForm: '=',
      idRequest: '='
    },
    templateUrl: 'app/views/common/dashboard-table-simple.html',
    link: function link(scope, element) {
      cov_1s7ak9ao91.s[135]++;

      scope.$on('$destroy', function () {
        cov_1s7ak9ao91.f[18]++;
        cov_1s7ak9ao91.s[136]++;

        if (scope.pivotTableObj) {
          cov_1s7ak9ao91.b[40][0]++;
          cov_1s7ak9ao91.s[137]++;

          scope.pivotTableObj.destroy();
        } else {
          cov_1s7ak9ao91.b[40][1]++;
        }
        cov_1s7ak9ao91.s[138]++;
        if (scope.chart) {
          cov_1s7ak9ao91.b[41][0]++;
          cov_1s7ak9ao91.s[139]++;

          scope.chart.destroy();
        } else {
          cov_1s7ak9ao91.b[41][1]++;
        }
      });
    },

    controller: fnDashboardTablesimple
  };
});