'use strict';

var cov_1vn8dwwzuy = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/deleted_items/deletedItemsCtrl.js',
      hash = '6f09658fdfe0b254083407b44bd763e480c4306a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/deleted_items/deletedItemsCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 335,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 18
        },
        end: {
          line: 2,
          column: 20
        }
      },
      '2': {
        start: {
          line: 3,
          column: 19
        },
        end: {
          line: 3,
          column: 21
        }
      },
      '3': {
        start: {
          line: 4,
          column: 21
        },
        end: {
          line: 4,
          column: 23
        }
      },
      '4': {
        start: {
          line: 5,
          column: 20
        },
        end: {
          line: 5,
          column: 22
        }
      },
      '5': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 113
        }
      },
      '6': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 18,
          column: 5
        }
      },
      '7': {
        start: {
          line: 20,
          column: 2
        },
        end: {
          line: 20,
          column: 27
        }
      },
      '8': {
        start: {
          line: 22,
          column: 2
        },
        end: {
          line: 24,
          column: 4
        }
      },
      '9': {
        start: {
          line: 26,
          column: 2
        },
        end: {
          line: 113,
          column: 4
        }
      },
      '10': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 27,
          column: 29
        }
      },
      '11': {
        start: {
          line: 29,
          column: 4
        },
        end: {
          line: 54,
          column: 5
        }
      },
      '12': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 40,
          column: 8
        }
      },
      '13': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 53,
          column: 8
        }
      },
      '14': {
        start: {
          line: 55,
          column: 15
        },
        end: {
          line: 55,
          column: 17
        }
      },
      '15': {
        start: {
          line: 56,
          column: 4
        },
        end: {
          line: 79,
          column: 5
        }
      },
      '16': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 58,
          column: 50
        }
      },
      '17': {
        start: {
          line: 59,
          column: 8
        },
        end: {
          line: 59,
          column: 14
        }
      },
      '18': {
        start: {
          line: 62,
          column: 8
        },
        end: {
          line: 62,
          column: 45
        }
      },
      '19': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 63,
          column: 14
        }
      },
      '20': {
        start: {
          line: 66,
          column: 8
        },
        end: {
          line: 66,
          column: 47
        }
      },
      '21': {
        start: {
          line: 67,
          column: 8
        },
        end: {
          line: 67,
          column: 14
        }
      },
      '22': {
        start: {
          line: 70,
          column: 8
        },
        end: {
          line: 70,
          column: 47
        }
      },
      '23': {
        start: {
          line: 71,
          column: 8
        },
        end: {
          line: 71,
          column: 14
        }
      },
      '24': {
        start: {
          line: 74,
          column: 8
        },
        end: {
          line: 74,
          column: 57
        }
      },
      '25': {
        start: {
          line: 75,
          column: 8
        },
        end: {
          line: 75,
          column: 14
        }
      },
      '26': {
        start: {
          line: 78,
          column: 8
        },
        end: {
          line: 78,
          column: 18
        }
      },
      '27': {
        start: {
          line: 80,
          column: 4
        },
        end: {
          line: 100,
          column: 7
        }
      },
      '28': {
        start: {
          line: 102,
          column: 19
        },
        end: {
          line: 104,
          column: 6
        }
      },
      '29': {
        start: {
          line: 103,
          column: 6
        },
        end: {
          line: 103,
          column: 51
        }
      },
      '30': {
        start: {
          line: 106,
          column: 4
        },
        end: {
          line: 106,
          column: 60
        }
      },
      '31': {
        start: {
          line: 107,
          column: 4
        },
        end: {
          line: 112,
          column: 7
        }
      },
      '32': {
        start: {
          line: 108,
          column: 6
        },
        end: {
          line: 111,
          column: 10
        }
      },
      '33': {
        start: {
          line: 109,
          column: 8
        },
        end: {
          line: 109,
          column: 65
        }
      },
      '34': {
        start: {
          line: 110,
          column: 8
        },
        end: {
          line: 110,
          column: 21
        }
      },
      '35': {
        start: {
          line: 118,
          column: 2
        },
        end: {
          line: 118,
          column: 24
        }
      },
      '36': {
        start: {
          line: 119,
          column: 2
        },
        end: {
          line: 119,
          column: 27
        }
      },
      '37': {
        start: {
          line: 120,
          column: 2
        },
        end: {
          line: 128,
          column: 3
        }
      },
      '38': {
        start: {
          line: 121,
          column: 4
        },
        end: {
          line: 121,
          column: 58
        }
      },
      '39': {
        start: {
          line: 129,
          column: 2
        },
        end: {
          line: 129,
          column: 23
        }
      },
      '40': {
        start: {
          line: 130,
          column: 2
        },
        end: {
          line: 141,
          column: 5
        }
      },
      '41': {
        start: {
          line: 131,
          column: 4
        },
        end: {
          line: 135,
          column: 5
        }
      },
      '42': {
        start: {
          line: 132,
          column: 6
        },
        end: {
          line: 132,
          column: 27
        }
      },
      '43': {
        start: {
          line: 133,
          column: 6
        },
        end: {
          line: 133,
          column: 41
        }
      },
      '44': {
        start: {
          line: 134,
          column: 6
        },
        end: {
          line: 134,
          column: 13
        }
      },
      '45': {
        start: {
          line: 137,
          column: 4
        },
        end: {
          line: 140,
          column: 5
        }
      },
      '46': {
        start: {
          line: 138,
          column: 6
        },
        end: {
          line: 138,
          column: 27
        }
      },
      '47': {
        start: {
          line: 139,
          column: 6
        },
        end: {
          line: 139,
          column: 40
        }
      },
      '48': {
        start: {
          line: 142,
          column: 2
        },
        end: {
          line: 160,
          column: 4
        }
      },
      '49': {
        start: {
          line: 144,
          column: 16
        },
        end: {
          line: 144,
          column: 20
        }
      },
      '50': {
        start: {
          line: 145,
          column: 4
        },
        end: {
          line: 157,
          column: 5
        }
      },
      '51': {
        start: {
          line: 146,
          column: 6
        },
        end: {
          line: 151,
          column: 9
        }
      },
      '52': {
        start: {
          line: 147,
          column: 8
        },
        end: {
          line: 150,
          column: 9
        }
      },
      '53': {
        start: {
          line: 148,
          column: 10
        },
        end: {
          line: 148,
          column: 24
        }
      },
      '54': {
        start: {
          line: 149,
          column: 10
        },
        end: {
          line: 149,
          column: 23
        }
      },
      '55': {
        start: {
          line: 153,
          column: 6
        },
        end: {
          line: 156,
          column: 7
        }
      },
      '56': {
        start: {
          line: 154,
          column: 8
        },
        end: {
          line: 154,
          column: 22
        }
      },
      '57': {
        start: {
          line: 155,
          column: 8
        },
        end: {
          line: 155,
          column: 21
        }
      },
      '58': {
        start: {
          line: 159,
          column: 4
        },
        end: {
          line: 159,
          column: 17
        }
      },
      '59': {
        start: {
          line: 161,
          column: 2
        },
        end: {
          line: 163,
          column: 4
        }
      },
      '60': {
        start: {
          line: 162,
          column: 4
        },
        end: {
          line: 162,
          column: 31
        }
      },
      '61': {
        start: {
          line: 164,
          column: 2
        },
        end: {
          line: 199,
          column: 4
        }
      },
      '62': {
        start: {
          line: 165,
          column: 18
        },
        end: {
          line: 167,
          column: 9
        }
      },
      '63': {
        start: {
          line: 166,
          column: 6
        },
        end: {
          line: 166,
          column: 51
        }
      },
      '64': {
        start: {
          line: 169,
          column: 4
        },
        end: {
          line: 198,
          column: 7
        }
      },
      '65': {
        start: {
          line: 179,
          column: 6
        },
        end: {
          line: 181,
          column: 7
        }
      },
      '66': {
        start: {
          line: 180,
          column: 8
        },
        end: {
          line: 180,
          column: 15
        }
      },
      '67': {
        start: {
          line: 182,
          column: 6
        },
        end: {
          line: 182,
          column: 48
        }
      },
      '68': {
        start: {
          line: 183,
          column: 6
        },
        end: {
          line: 197,
          column: 9
        }
      },
      '69': {
        start: {
          line: 184,
          column: 8
        },
        end: {
          line: 196,
          column: 9
        }
      },
      '70': {
        start: {
          line: 185,
          column: 10
        },
        end: {
          line: 189,
          column: 13
        }
      },
      '71': {
        start: {
          line: 190,
          column: 10
        },
        end: {
          line: 190,
          column: 53
        }
      },
      '72': {
        start: {
          line: 192,
          column: 10
        },
        end: {
          line: 195,
          column: 13
        }
      },
      '73': {
        start: {
          line: 193,
          column: 12
        },
        end: {
          line: 193,
          column: 55
        }
      },
      '74': {
        start: {
          line: 194,
          column: 12
        },
        end: {
          line: 194,
          column: 52
        }
      },
      '75': {
        start: {
          line: 201,
          column: 2
        },
        end: {
          line: 235,
          column: 4
        }
      },
      '76': {
        start: {
          line: 202,
          column: 18
        },
        end: {
          line: 204,
          column: 9
        }
      },
      '77': {
        start: {
          line: 203,
          column: 6
        },
        end: {
          line: 203,
          column: 51
        }
      },
      '78': {
        start: {
          line: 205,
          column: 16
        },
        end: {
          line: 205,
          column: 42
        }
      },
      '79': {
        start: {
          line: 206,
          column: 4
        },
        end: {
          line: 234,
          column: 7
        }
      },
      '80': {
        start: {
          line: 216,
          column: 6
        },
        end: {
          line: 218,
          column: 7
        }
      },
      '81': {
        start: {
          line: 217,
          column: 8
        },
        end: {
          line: 217,
          column: 15
        }
      },
      '82': {
        start: {
          line: 220,
          column: 6
        },
        end: {
          line: 220,
          column: 48
        }
      },
      '83': {
        start: {
          line: 221,
          column: 6
        },
        end: {
          line: 231,
          column: 7
        }
      },
      '84': {
        start: {
          line: 222,
          column: 8
        },
        end: {
          line: 230,
          column: 11
        }
      },
      '85': {
        start: {
          line: 223,
          column: 10
        },
        end: {
          line: 223,
          column: 18
        }
      },
      '86': {
        start: {
          line: 224,
          column: 10
        },
        end: {
          line: 229,
          column: 11
        }
      },
      '87': {
        start: {
          line: 225,
          column: 12
        },
        end: {
          line: 228,
          column: 15
        }
      },
      '88': {
        start: {
          line: 226,
          column: 14
        },
        end: {
          line: 226,
          column: 57
        }
      },
      '89': {
        start: {
          line: 227,
          column: 14
        },
        end: {
          line: 227,
          column: 54
        }
      },
      '90': {
        start: {
          line: 233,
          column: 6
        },
        end: {
          line: 233,
          column: 31
        }
      },
      '91': {
        start: {
          line: 237,
          column: 2
        },
        end: {
          line: 277,
          column: 4
        }
      },
      '92': {
        start: {
          line: 238,
          column: 18
        },
        end: {
          line: 240,
          column: 9
        }
      },
      '93': {
        start: {
          line: 239,
          column: 6
        },
        end: {
          line: 239,
          column: 51
        }
      },
      '94': {
        start: {
          line: 247,
          column: 4
        },
        end: {
          line: 276,
          column: 7
        }
      },
      '95': {
        start: {
          line: 257,
          column: 6
        },
        end: {
          line: 259,
          column: 7
        }
      },
      '96': {
        start: {
          line: 258,
          column: 8
        },
        end: {
          line: 258,
          column: 15
        }
      },
      '97': {
        start: {
          line: 260,
          column: 6
        },
        end: {
          line: 260,
          column: 48
        }
      },
      '98': {
        start: {
          line: 261,
          column: 6
        },
        end: {
          line: 275,
          column: 9
        }
      },
      '99': {
        start: {
          line: 262,
          column: 8
        },
        end: {
          line: 274,
          column: 9
        }
      },
      '100': {
        start: {
          line: 263,
          column: 10
        },
        end: {
          line: 267,
          column: 13
        }
      },
      '101': {
        start: {
          line: 268,
          column: 10
        },
        end: {
          line: 268,
          column: 53
        }
      },
      '102': {
        start: {
          line: 270,
          column: 10
        },
        end: {
          line: 273,
          column: 13
        }
      },
      '103': {
        start: {
          line: 271,
          column: 12
        },
        end: {
          line: 271,
          column: 55
        }
      },
      '104': {
        start: {
          line: 272,
          column: 12
        },
        end: {
          line: 272,
          column: 52
        }
      },
      '105': {
        start: {
          line: 279,
          column: 2
        },
        end: {
          line: 334,
          column: 4
        }
      },
      '106': {
        start: {
          line: 280,
          column: 18
        },
        end: {
          line: 282,
          column: 9
        }
      },
      '107': {
        start: {
          line: 281,
          column: 6
        },
        end: {
          line: 281,
          column: 51
        }
      },
      '108': {
        start: {
          line: 287,
          column: 16
        },
        end: {
          line: 287,
          column: 42
        }
      },
      '109': {
        start: {
          line: 288,
          column: 4
        },
        end: {
          line: 333,
          column: 7
        }
      },
      '110': {
        start: {
          line: 298,
          column: 6
        },
        end: {
          line: 300,
          column: 7
        }
      },
      '111': {
        start: {
          line: 299,
          column: 8
        },
        end: {
          line: 299,
          column: 15
        }
      },
      '112': {
        start: {
          line: 301,
          column: 6
        },
        end: {
          line: 301,
          column: 48
        }
      },
      '113': {
        start: {
          line: 303,
          column: 6
        },
        end: {
          line: 330,
          column: 7
        }
      },
      '114': {
        start: {
          line: 305,
          column: 8
        },
        end: {
          line: 328,
          column: 9
        }
      },
      '115': {
        start: {
          line: 307,
          column: 10
        },
        end: {
          line: 315,
          column: 13
        }
      },
      '116': {
        start: {
          line: 308,
          column: 12
        },
        end: {
          line: 308,
          column: 20
        }
      },
      '117': {
        start: {
          line: 309,
          column: 12
        },
        end: {
          line: 314,
          column: 13
        }
      },
      '118': {
        start: {
          line: 310,
          column: 14
        },
        end: {
          line: 313,
          column: 17
        }
      },
      '119': {
        start: {
          line: 311,
          column: 16
        },
        end: {
          line: 311,
          column: 59
        }
      },
      '120': {
        start: {
          line: 312,
          column: 16
        },
        end: {
          line: 312,
          column: 56
        }
      },
      '121': {
        start: {
          line: 319,
          column: 10
        },
        end: {
          line: 327,
          column: 13
        }
      },
      '122': {
        start: {
          line: 320,
          column: 12
        },
        end: {
          line: 320,
          column: 20
        }
      },
      '123': {
        start: {
          line: 321,
          column: 12
        },
        end: {
          line: 326,
          column: 13
        }
      },
      '124': {
        start: {
          line: 322,
          column: 14
        },
        end: {
          line: 325,
          column: 17
        }
      },
      '125': {
        start: {
          line: 323,
          column: 16
        },
        end: {
          line: 323,
          column: 59
        }
      },
      '126': {
        start: {
          line: 324,
          column: 16
        },
        end: {
          line: 324,
          column: 56
        }
      },
      '127': {
        start: {
          line: 332,
          column: 6
        },
        end: {
          line: 332,
          column: 31
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 53
          },
          end: {
            line: 1,
            column: 54
          }
        },
        loc: {
          start: {
            line: 1,
            column: 121
          },
          end: {
            line: 335,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 26,
            column: 23
          },
          end: {
            line: 26,
            column: 24
          }
        },
        loc: {
          start: {
            line: 26,
            column: 35
          },
          end: {
            line: 113,
            column: 3
          }
        },
        line: 26
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 102,
            column: 41
          },
          end: {
            line: 102,
            column: 42
          }
        },
        loc: {
          start: {
            line: 102,
            column: 50
          },
          end: {
            line: 104,
            column: 5
          }
        },
        line: 102
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 107,
            column: 59
          },
          end: {
            line: 107,
            column: 60
          }
        },
        loc: {
          start: {
            line: 107,
            column: 67
          },
          end: {
            line: 112,
            column: 5
          }
        },
        line: 107
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 108,
            column: 68
          },
          end: {
            line: 108,
            column: 69
          }
        },
        loc: {
          start: {
            line: 108,
            column: 77
          },
          end: {
            line: 111,
            column: 7
          }
        },
        line: 108
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 130,
            column: 24
          },
          end: {
            line: 130,
            column: 25
          }
        },
        loc: {
          start: {
            line: 130,
            column: 34
          },
          end: {
            line: 141,
            column: 3
          }
        },
        line: 130
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 142,
            column: 27
          },
          end: {
            line: 142,
            column: 28
          }
        },
        loc: {
          start: {
            line: 142,
            column: 50
          },
          end: {
            line: 160,
            column: 3
          }
        },
        line: 142
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 146,
            column: 34
          },
          end: {
            line: 146,
            column: 35
          }
        },
        loc: {
          start: {
            line: 146,
            column: 45
          },
          end: {
            line: 151,
            column: 7
          }
        },
        line: 146
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 161,
            column: 28
          },
          end: {
            line: 161,
            column: 29
          }
        },
        loc: {
          start: {
            line: 161,
            column: 36
          },
          end: {
            line: 163,
            column: 3
          }
        },
        line: 161
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 164,
            column: 23
          },
          end: {
            line: 164,
            column: 24
          }
        },
        loc: {
          start: {
            line: 164,
            column: 39
          },
          end: {
            line: 199,
            column: 3
          }
        },
        line: 164
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 165,
            column: 42
          },
          end: {
            line: 165,
            column: 43
          }
        },
        loc: {
          start: {
            line: 165,
            column: 51
          },
          end: {
            line: 167,
            column: 5
          }
        },
        line: 165
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 178,
            column: 7
          },
          end: {
            line: 178,
            column: 8
          }
        },
        loc: {
          start: {
            line: 178,
            column: 20
          },
          end: {
            line: 198,
            column: 5
          }
        },
        line: 178
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 183,
            column: 75
          },
          end: {
            line: 183,
            column: 76
          }
        },
        loc: {
          start: {
            line: 183,
            column: 83
          },
          end: {
            line: 197,
            column: 7
          }
        },
        line: 183
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 192,
            column: 64
          },
          end: {
            line: 192,
            column: 65
          }
        },
        loc: {
          start: {
            line: 192,
            column: 73
          },
          end: {
            line: 195,
            column: 11
          }
        },
        line: 192
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 201,
            column: 24
          },
          end: {
            line: 201,
            column: 25
          }
        },
        loc: {
          start: {
            line: 201,
            column: 36
          },
          end: {
            line: 235,
            column: 3
          }
        },
        line: 201
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 202,
            column: 42
          },
          end: {
            line: 202,
            column: 43
          }
        },
        loc: {
          start: {
            line: 202,
            column: 51
          },
          end: {
            line: 204,
            column: 5
          }
        },
        line: 202
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 215,
            column: 7
          },
          end: {
            line: 215,
            column: 8
          }
        },
        loc: {
          start: {
            line: 215,
            column: 20
          },
          end: {
            line: 234,
            column: 5
          }
        },
        line: 215
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 222,
            column: 77
          },
          end: {
            line: 222,
            column: 78
          }
        },
        loc: {
          start: {
            line: 222,
            column: 85
          },
          end: {
            line: 230,
            column: 9
          }
        },
        line: 222
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 225,
            column: 66
          },
          end: {
            line: 225,
            column: 67
          }
        },
        loc: {
          start: {
            line: 225,
            column: 75
          },
          end: {
            line: 228,
            column: 13
          }
        },
        line: 225
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 237,
            column: 22
          },
          end: {
            line: 237,
            column: 23
          }
        },
        loc: {
          start: {
            line: 237,
            column: 38
          },
          end: {
            line: 277,
            column: 3
          }
        },
        line: 237
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 238,
            column: 42
          },
          end: {
            line: 238,
            column: 43
          }
        },
        loc: {
          start: {
            line: 238,
            column: 51
          },
          end: {
            line: 240,
            column: 5
          }
        },
        line: 238
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 256,
            column: 7
          },
          end: {
            line: 256,
            column: 8
          }
        },
        loc: {
          start: {
            line: 256,
            column: 20
          },
          end: {
            line: 276,
            column: 5
          }
        },
        line: 256
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 261,
            column: 77
          },
          end: {
            line: 261,
            column: 78
          }
        },
        loc: {
          start: {
            line: 261,
            column: 85
          },
          end: {
            line: 275,
            column: 7
          }
        },
        line: 261
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 270,
            column: 64
          },
          end: {
            line: 270,
            column: 65
          }
        },
        loc: {
          start: {
            line: 270,
            column: 73
          },
          end: {
            line: 273,
            column: 11
          }
        },
        line: 270
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 279,
            column: 23
          },
          end: {
            line: 279,
            column: 24
          }
        },
        loc: {
          start: {
            line: 279,
            column: 35
          },
          end: {
            line: 334,
            column: 3
          }
        },
        line: 279
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 280,
            column: 42
          },
          end: {
            line: 280,
            column: 43
          }
        },
        loc: {
          start: {
            line: 280,
            column: 51
          },
          end: {
            line: 282,
            column: 5
          }
        },
        line: 280
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 297,
            column: 7
          },
          end: {
            line: 297,
            column: 8
          }
        },
        loc: {
          start: {
            line: 297,
            column: 20
          },
          end: {
            line: 333,
            column: 5
          }
        },
        line: 297
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 307,
            column: 97
          },
          end: {
            line: 307,
            column: 98
          }
        },
        loc: {
          start: {
            line: 307,
            column: 105
          },
          end: {
            line: 315,
            column: 11
          }
        },
        line: 307
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 310,
            column: 68
          },
          end: {
            line: 310,
            column: 69
          }
        },
        loc: {
          start: {
            line: 310,
            column: 77
          },
          end: {
            line: 313,
            column: 15
          }
        },
        line: 310
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 319,
            column: 81
          },
          end: {
            line: 319,
            column: 82
          }
        },
        loc: {
          start: {
            line: 319,
            column: 89
          },
          end: {
            line: 327,
            column: 11
          }
        },
        line: 319
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 322,
            column: 68
          },
          end: {
            line: 322,
            column: 69
          }
        },
        loc: {
          start: {
            line: 322,
            column: 77
          },
          end: {
            line: 325,
            column: 15
          }
        },
        line: 322
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 10,
            column: 22
          },
          end: {
            line: 12,
            column: 8
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 10,
            column: 73
          },
          end: {
            line: 12,
            column: 3
          }
        }, {
          start: {
            line: 12,
            column: 6
          },
          end: {
            line: 12,
            column: 8
          }
        }],
        line: 10
      },
      '1': {
        loc: {
          start: {
            line: 29,
            column: 4
          },
          end: {
            line: 54,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 29,
            column: 4
          },
          end: {
            line: 54,
            column: 5
          }
        }, {
          start: {
            line: 29,
            column: 4
          },
          end: {
            line: 54,
            column: 5
          }
        }],
        line: 29
      },
      '2': {
        loc: {
          start: {
            line: 56,
            column: 4
          },
          end: {
            line: 79,
            column: 5
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 57,
            column: 6
          },
          end: {
            line: 59,
            column: 14
          }
        }, {
          start: {
            line: 61,
            column: 6
          },
          end: {
            line: 63,
            column: 14
          }
        }, {
          start: {
            line: 65,
            column: 6
          },
          end: {
            line: 67,
            column: 14
          }
        }, {
          start: {
            line: 69,
            column: 6
          },
          end: {
            line: 71,
            column: 14
          }
        }, {
          start: {
            line: 73,
            column: 6
          },
          end: {
            line: 75,
            column: 14
          }
        }, {
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 78,
            column: 18
          }
        }],
        line: 56
      },
      '3': {
        loc: {
          start: {
            line: 131,
            column: 4
          },
          end: {
            line: 135,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 131,
            column: 4
          },
          end: {
            line: 135,
            column: 5
          }
        }, {
          start: {
            line: 131,
            column: 4
          },
          end: {
            line: 135,
            column: 5
          }
        }],
        line: 131
      },
      '4': {
        loc: {
          start: {
            line: 137,
            column: 4
          },
          end: {
            line: 140,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 137,
            column: 4
          },
          end: {
            line: 140,
            column: 5
          }
        }, {
          start: {
            line: 137,
            column: 4
          },
          end: {
            line: 140,
            column: 5
          }
        }],
        line: 137
      },
      '5': {
        loc: {
          start: {
            line: 142,
            column: 37
          },
          end: {
            line: 142,
            column: 48
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 142,
            column: 44
          },
          end: {
            line: 142,
            column: 48
          }
        }],
        line: 142
      },
      '6': {
        loc: {
          start: {
            line: 145,
            column: 4
          },
          end: {
            line: 157,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 145,
            column: 4
          },
          end: {
            line: 157,
            column: 5
          }
        }, {
          start: {
            line: 145,
            column: 4
          },
          end: {
            line: 157,
            column: 5
          }
        }],
        line: 145
      },
      '7': {
        loc: {
          start: {
            line: 147,
            column: 8
          },
          end: {
            line: 150,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 147,
            column: 8
          },
          end: {
            line: 150,
            column: 9
          }
        }, {
          start: {
            line: 147,
            column: 8
          },
          end: {
            line: 150,
            column: 9
          }
        }],
        line: 147
      },
      '8': {
        loc: {
          start: {
            line: 147,
            column: 12
          },
          end: {
            line: 147,
            column: 80
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 147,
            column: 12
          },
          end: {
            line: 147,
            column: 50
          }
        }, {
          start: {
            line: 147,
            column: 55
          },
          end: {
            line: 147,
            column: 79
          }
        }],
        line: 147
      },
      '9': {
        loc: {
          start: {
            line: 153,
            column: 6
          },
          end: {
            line: 156,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 153,
            column: 6
          },
          end: {
            line: 156,
            column: 7
          }
        }, {
          start: {
            line: 153,
            column: 6
          },
          end: {
            line: 156,
            column: 7
          }
        }],
        line: 153
      },
      '10': {
        loc: {
          start: {
            line: 153,
            column: 10
          },
          end: {
            line: 153,
            column: 72
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 153,
            column: 10
          },
          end: {
            line: 153,
            column: 45
          }
        }, {
          start: {
            line: 153,
            column: 50
          },
          end: {
            line: 153,
            column: 71
          }
        }],
        line: 153
      },
      '11': {
        loc: {
          start: {
            line: 179,
            column: 6
          },
          end: {
            line: 181,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 179,
            column: 6
          },
          end: {
            line: 181,
            column: 7
          }
        }, {
          start: {
            line: 179,
            column: 6
          },
          end: {
            line: 181,
            column: 7
          }
        }],
        line: 179
      },
      '12': {
        loc: {
          start: {
            line: 184,
            column: 8
          },
          end: {
            line: 196,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 184,
            column: 8
          },
          end: {
            line: 196,
            column: 9
          }
        }, {
          start: {
            line: 184,
            column: 8
          },
          end: {
            line: 196,
            column: 9
          }
        }],
        line: 184
      },
      '13': {
        loc: {
          start: {
            line: 216,
            column: 6
          },
          end: {
            line: 218,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 216,
            column: 6
          },
          end: {
            line: 218,
            column: 7
          }
        }, {
          start: {
            line: 216,
            column: 6
          },
          end: {
            line: 218,
            column: 7
          }
        }],
        line: 216
      },
      '14': {
        loc: {
          start: {
            line: 224,
            column: 10
          },
          end: {
            line: 229,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 224,
            column: 10
          },
          end: {
            line: 229,
            column: 11
          }
        }, {
          start: {
            line: 224,
            column: 10
          },
          end: {
            line: 229,
            column: 11
          }
        }],
        line: 224
      },
      '15': {
        loc: {
          start: {
            line: 257,
            column: 6
          },
          end: {
            line: 259,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 257,
            column: 6
          },
          end: {
            line: 259,
            column: 7
          }
        }, {
          start: {
            line: 257,
            column: 6
          },
          end: {
            line: 259,
            column: 7
          }
        }],
        line: 257
      },
      '16': {
        loc: {
          start: {
            line: 262,
            column: 8
          },
          end: {
            line: 274,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 262,
            column: 8
          },
          end: {
            line: 274,
            column: 9
          }
        }, {
          start: {
            line: 262,
            column: 8
          },
          end: {
            line: 274,
            column: 9
          }
        }],
        line: 262
      },
      '17': {
        loc: {
          start: {
            line: 298,
            column: 6
          },
          end: {
            line: 300,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 298,
            column: 6
          },
          end: {
            line: 300,
            column: 7
          }
        }, {
          start: {
            line: 298,
            column: 6
          },
          end: {
            line: 300,
            column: 7
          }
        }],
        line: 298
      },
      '18': {
        loc: {
          start: {
            line: 305,
            column: 8
          },
          end: {
            line: 328,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 305,
            column: 8
          },
          end: {
            line: 328,
            column: 9
          }
        }, {
          start: {
            line: 305,
            column: 8
          },
          end: {
            line: 328,
            column: 9
          }
        }],
        line: 305
      },
      '19': {
        loc: {
          start: {
            line: 305,
            column: 12
          },
          end: {
            line: 305,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 305,
            column: 13
          },
          end: {
            line: 305,
            column: 30
          }
        }, {
          start: {
            line: 305,
            column: 36
          },
          end: {
            line: 305,
            column: 65
          }
        }],
        line: 305
      },
      '20': {
        loc: {
          start: {
            line: 309,
            column: 12
          },
          end: {
            line: 314,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 309,
            column: 12
          },
          end: {
            line: 314,
            column: 13
          }
        }, {
          start: {
            line: 309,
            column: 12
          },
          end: {
            line: 314,
            column: 13
          }
        }],
        line: 309
      },
      '21': {
        loc: {
          start: {
            line: 321,
            column: 12
          },
          end: {
            line: 326,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 321,
            column: 12
          },
          end: {
            line: 326,
            column: 13
          }
        }, {
          start: {
            line: 321,
            column: 12
          },
          end: {
            line: 326,
            column: 13
          }
        }],
        line: 321
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0, 0, 0, 0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1vn8dwwzuy.s[0]++;
angular.module('app').controller('deletedItemsCtrl', function ($scope, $CRUDService, $translate, SweetAlert, $rootScope, $log) {
  cov_1vn8dwwzuy.f[0]++;

  var PATH_CL = (cov_1vn8dwwzuy.s[1]++, '');
  var PATH_PRJ = (cov_1vn8dwwzuy.s[2]++, '');
  var PATH_INDIC = (cov_1vn8dwwzuy.s[3]++, '');
  var PATH_FORM = (cov_1vn8dwwzuy.s[4]++, '');

  // $scope.pageSettings={pageSize: 2,pageCount: 3};
  cov_1vn8dwwzuy.s[5]++;
  $scope.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false };

  cov_1vn8dwwzuy.s[6]++;
  $scope.groupItem = ($rootScope.currentProject.project.PORTFOLIO == 1 ? (cov_1vn8dwwzuy.b[0][0]++, [{ id: 'PRJ', path: 'Projet', label: $translate.instant('MENU.PROJECT') }]) : (cov_1vn8dwwzuy.b[0][1]++, [])).concat([{ id: 'CL', path: 'CadreLogique', label: $translate.instant('MENU.PL') }, { id: 'INDIC', path: 'Indicateurs', label: $translate.instant('MENU.IN_T') }, { id: 'FORM', path: 'Formular', label: $translate.instant('MENU.DATA') }, { id: 'CLS', path: 'Classeur', label: $translate.instant('MENU.B_D') }, { id: 'ID_CLS_PHOTO', path: 'ClasseurPhototheque', label: $translate.instant('MENU.PH') }]);

  cov_1vn8dwwzuy.s[7]++;
  $scope.deletedItems = [];

  cov_1vn8dwwzuy.s[8]++;
  $scope.selectedItem = {
    value: $scope.groupItem[0].id
  };

  cov_1vn8dwwzuy.s[9]++;
  $scope.changeGroup = function () {
    cov_1vn8dwwzuy.f[1]++;
    cov_1vn8dwwzuy.s[10]++;

    $scope.deletedItems = [];

    cov_1vn8dwwzuy.s[11]++;
    if ($scope.selectedItem.value == 'PRJ') {
      cov_1vn8dwwzuy.b[1][0]++;
      cov_1vn8dwwzuy.s[12]++;

      $scope.columns = [{ type: 'checkbox', width: 60 }, { field: 'object', headerText: $translate.instant('DELETEDITEMS.OBJECT').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'date', headerText: $translate.instant('DELETEDITEMS.DATE').toUpperCase(), format: { type: 'dateTime', skeleton: 'medium' }, width: 200, clipMode: 'EllipsisWithTooltip'
        /* {headerText: '', width: 120,
          commands: [
            {type: 'Edit', buttonOption: {iconCss: 'fa fa-recycle', cssClass: 'e-flat'}, title: $translate.instant('DELETEDITEMS.REFRESH')},
            {type: 'Delete', buttonOption: {iconCss: 'e-icons e-delete', cssClass: 'e-flat'}}
          ]
        } */
      }];
    } else {
      cov_1vn8dwwzuy.b[1][1]++;
      cov_1vn8dwwzuy.s[13]++;

      $scope.columns = [{ type: 'checkbox', width: 60 }, { field: 'object', headerText: $translate.instant('DELETEDITEMS.OBJECT').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'project', headerText: $translate.instant('DELETEDITEMS.PROJECT').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'date', headerText: $translate.instant('DELETEDITEMS.DATE').toUpperCase(), format: { type: 'dateTime', skeleton: 'medium' }, width: 200, clipMode: 'EllipsisWithTooltip'
        /* {headerText: '', width: 120,
           commands: [
             {type: 'Edit', buttonOption: {iconCss: 'fa fa-recycle', cssClass: 'e-flat'}, title: $translate.instant('DELETEDITEMS.REFRESH')},
             {type: 'Delete', buttonOption: {iconCss: 'e-icons e-delete', cssClass: 'e-flat'}}
           ]
         } */
      }];
    }
    var item = (cov_1vn8dwwzuy.s[14]++, '');
    cov_1vn8dwwzuy.s[15]++;
    switch ($scope.selectedItem.value) {
      case 'PRJ':
        cov_1vn8dwwzuy.b[2][0]++;
        cov_1vn8dwwzuy.s[16]++;

        item = $translate.instant('MENU.PROJECT');
        cov_1vn8dwwzuy.s[17]++;
        break;

      case 'CL':
        cov_1vn8dwwzuy.b[2][1]++;
        cov_1vn8dwwzuy.s[18]++;

        item = $translate.instant('MENU.PL');
        cov_1vn8dwwzuy.s[19]++;
        break;

      case 'INDIC':
        cov_1vn8dwwzuy.b[2][2]++;
        cov_1vn8dwwzuy.s[20]++;

        item = $translate.instant('MENU.IN_T');
        cov_1vn8dwwzuy.s[21]++;
        break;

      case 'FORM':
        cov_1vn8dwwzuy.b[2][3]++;
        cov_1vn8dwwzuy.s[22]++;

        item = $translate.instant('MENU.DATA');
        cov_1vn8dwwzuy.s[23]++;
        break;

      case 'CLS':
        cov_1vn8dwwzuy.b[2][4]++;
        cov_1vn8dwwzuy.s[24]++;

        item = $translate.instant('PHOTOLIBRARY.FOLDER');
        cov_1vn8dwwzuy.s[25]++;
        break;

      default:
        cov_1vn8dwwzuy.b[2][5]++;
        cov_1vn8dwwzuy.s[26]++;

        item = '';
    }
    cov_1vn8dwwzuy.s[27]++;
    ej.base.L10n.load({
      en: {
        grid: {
          item: item,
          items: item
        },
        pager: {
          totalItemsInfo: '({0} ' + item + ')'
        }
      },
      fr: {
        grid: {
          item: item,
          items: item
        },
        pager: {
          totalItemsInfo: '({0} ' + item + ')'
        }
      }

    });

    var item__ = (cov_1vn8dwwzuy.s[28]++, $scope.groupItem.find(function (value) {
      cov_1vn8dwwzuy.f[2]++;
      cov_1vn8dwwzuy.s[29]++;

      return value.id == $scope.selectedItem.value;
    }));

    cov_1vn8dwwzuy.s[30]++;
    $scope.listItems[item__.id] = { loading: true, data: [] };
    cov_1vn8dwwzuy.s[31]++;
    $CRUDService.getAll(item__.path, { get: 'all_deleted' }, function (data) {
      cov_1vn8dwwzuy.f[3]++;
      cov_1vn8dwwzuy.s[32]++;

      $scope.listItems[item__.id] = { loading: false, data: data.map(function (value) {
          cov_1vn8dwwzuy.f[4]++;
          cov_1vn8dwwzuy.s[33]++;

          value.date = Date.newDate(parseFloat(value.date) * 1000);
          cov_1vn8dwwzuy.s[34]++;
          return value;
        }) };
    });
  };
  /* $obj = array("object" => $row["object"]);
              $obj["project"] = $row["project"];
              $obj["id"] = $row["ID"];
              $obj["date"] = $row["dateAction"]; */
  cov_1vn8dwwzuy.s[35]++;
  $scope.listItems = {};
  cov_1vn8dwwzuy.s[36]++;
  $scope.deletedItems = [];
  cov_1vn8dwwzuy.s[37]++;
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = $scope.groupItem[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var item = _step.value;
      cov_1vn8dwwzuy.s[38]++;

      $scope.listItems[item.id] = { loading: true, data: [] };
      /* $CRUDService.getAll(item.path, {get: 'all_deleted'}, data => {
        $scope.listItems[item.id] = {loading: false, data: data.map(value => {
          value.date = Date.newDate(parseFloat(value.date) * 1000);
          return value;
        })};
      }); */
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  cov_1vn8dwwzuy.s[39]++;
  $scope.changeGroup();
  cov_1vn8dwwzuy.s[40]++;
  $scope.actionBegin = function (action) {
    cov_1vn8dwwzuy.f[5]++;
    cov_1vn8dwwzuy.s[41]++;

    if (action.requestType === 'beginEdit') {
      cov_1vn8dwwzuy.b[3][0]++;
      cov_1vn8dwwzuy.s[42]++;

      action.cancel = true;
      cov_1vn8dwwzuy.s[43]++;
      $scope.restoreData(action.rowData);
      cov_1vn8dwwzuy.s[44]++;
      return;
    } else {
      cov_1vn8dwwzuy.b[3][1]++;
    }

    cov_1vn8dwwzuy.s[45]++;
    if (action.requestType === 'delete') {
      cov_1vn8dwwzuy.b[4][0]++;
      cov_1vn8dwwzuy.s[46]++;

      action.cancel = true;
      cov_1vn8dwwzuy.s[47]++;
      $scope.deleteData(action.data[0]);
    } else {
      cov_1vn8dwwzuy.b[4][1]++;
    }
  };
  cov_1vn8dwwzuy.s[48]++;
  $scope.checkDeleteFile = function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (cov_1vn8dwwzuy.b[5][0]++, null);
    cov_1vn8dwwzuy.f[6]++;

    // $log.log($scope.deletedItems);
    var check = (cov_1vn8dwwzuy.s[49]++, true);
    cov_1vn8dwwzuy.s[50]++;
    if (item == null) {
      cov_1vn8dwwzuy.b[6][0]++;
      cov_1vn8dwwzuy.s[51]++;

      $scope.deletedItems.forEach(function (element) {
        cov_1vn8dwwzuy.f[7]++;
        cov_1vn8dwwzuy.s[52]++;

        if ((cov_1vn8dwwzuy.b[8][0]++, angular.isDefined(element.can_restore)) && (cov_1vn8dwwzuy.b[8][1]++, element.can_restore == 0)) {
          cov_1vn8dwwzuy.b[7][0]++;
          cov_1vn8dwwzuy.s[53]++;

          check = false;
          cov_1vn8dwwzuy.s[54]++;
          return check;
        } else {
          cov_1vn8dwwzuy.b[7][1]++;
        }
      });
    } else {
      cov_1vn8dwwzuy.b[6][1]++;
      cov_1vn8dwwzuy.s[55]++;

      if ((cov_1vn8dwwzuy.b[10][0]++, angular.isDefined(item.can_restore)) && (cov_1vn8dwwzuy.b[10][1]++, item.can_restore == 0)) {
        cov_1vn8dwwzuy.b[9][0]++;
        cov_1vn8dwwzuy.s[56]++;

        check = false;
        cov_1vn8dwwzuy.s[57]++;
        return check;
      } else {
        cov_1vn8dwwzuy.b[9][1]++;
      }
    }

    cov_1vn8dwwzuy.s[58]++;
    return check;
  };
  cov_1vn8dwwzuy.s[59]++;
  $scope.selectItemChange = function (data) {
    cov_1vn8dwwzuy.f[8]++;
    cov_1vn8dwwzuy.s[60]++;

    $scope.deletedItems = data;
  };
  cov_1vn8dwwzuy.s[61]++;
  $scope.restoreData = function (item) {
    cov_1vn8dwwzuy.f[9]++;

    var group = (cov_1vn8dwwzuy.s[62]++, $scope.groupItem.filter(function (value) {
      cov_1vn8dwwzuy.f[10]++;
      cov_1vn8dwwzuy.s[63]++;

      return value.id == $scope.selectedItem.value;
    })[0]);

    cov_1vn8dwwzuy.s[64]++;
    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM_RESTORE'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',
      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1vn8dwwzuy.f[11]++;
      cov_1vn8dwwzuy.s[65]++;

      if (isconfirm) {
        cov_1vn8dwwzuy.b[11][0]++;
        cov_1vn8dwwzuy.s[66]++;

        return;
      } else {
        cov_1vn8dwwzuy.b[11][1]++;
      }
      cov_1vn8dwwzuy.s[67]++;
      $scope.listItems[group.id].loading = true;
      cov_1vn8dwwzuy.s[68]++;
      $CRUDService.save(group.path, { action: 'restore_data', id: item.id }, function (data) {
        cov_1vn8dwwzuy.f[12]++;
        cov_1vn8dwwzuy.s[69]++;

        if (data < 0) {
          cov_1vn8dwwzuy.b[12][0]++;
          cov_1vn8dwwzuy.s[70]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
            type: 'warning'
          });
          cov_1vn8dwwzuy.s[71]++;
          $scope.listItems[group.id].loading = false;
        } else {
          cov_1vn8dwwzuy.b[12][1]++;
          cov_1vn8dwwzuy.s[72]++;

          $CRUDService.getAll(group.path, { get: 'all_deleted' }, function (data_) {
            cov_1vn8dwwzuy.f[13]++;
            cov_1vn8dwwzuy.s[73]++;

            $scope.listItems[group.id].loading = false;
            cov_1vn8dwwzuy.s[74]++;
            $scope.listItems[group.id].data = data_;
          });
        }
      });
    });
  };

  cov_1vn8dwwzuy.s[75]++;
  $scope.restoreDatas = function () {
    cov_1vn8dwwzuy.f[14]++;

    var group = (cov_1vn8dwwzuy.s[76]++, $scope.groupItem.filter(function (value) {
      cov_1vn8dwwzuy.f[15]++;
      cov_1vn8dwwzuy.s[77]++;

      return value.id == $scope.selectedItem.value;
    })[0]);
    var count = (cov_1vn8dwwzuy.s[78]++, $scope.deletedItems.length);
    cov_1vn8dwwzuy.s[79]++;
    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM_RESTORE'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',
      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1vn8dwwzuy.f[16]++;
      cov_1vn8dwwzuy.s[80]++;

      if (isconfirm) {
        cov_1vn8dwwzuy.b[13][0]++;
        cov_1vn8dwwzuy.s[81]++;

        return;
      } else {
        cov_1vn8dwwzuy.b[13][1]++;
      }

      cov_1vn8dwwzuy.s[82]++;
      $scope.listItems[group.id].loading = true;
      cov_1vn8dwwzuy.s[83]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = $scope.deletedItems[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var item = _step2.value;
          cov_1vn8dwwzuy.s[84]++;

          $CRUDService.save(group.path, { action: 'restore_data', id: item.id }, function (data) {
            cov_1vn8dwwzuy.f[17]++;
            cov_1vn8dwwzuy.s[85]++;

            count--;
            cov_1vn8dwwzuy.s[86]++;
            if (count < 1) {
              cov_1vn8dwwzuy.b[14][0]++;
              cov_1vn8dwwzuy.s[87]++;

              $CRUDService.getAll(group.path, { get: 'all_deleted' }, function (data_) {
                cov_1vn8dwwzuy.f[18]++;
                cov_1vn8dwwzuy.s[88]++;

                $scope.listItems[group.id].loading = false;
                cov_1vn8dwwzuy.s[89]++;
                $scope.listItems[group.id].data = data_;
              });
            } else {
              cov_1vn8dwwzuy.b[14][1]++;
            }
          });
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      cov_1vn8dwwzuy.s[90]++;
      $scope.deletedItems = [];
    });
  };

  cov_1vn8dwwzuy.s[91]++;
  $scope.deleteData = function (item) {
    cov_1vn8dwwzuy.f[19]++;

    var group = (cov_1vn8dwwzuy.s[92]++, $scope.groupItem.filter(function (value) {
      cov_1vn8dwwzuy.f[20]++;
      cov_1vn8dwwzuy.s[93]++;

      return value.id == $scope.selectedItem.value;
    })[0]);

    // $log.log('deleteData ONE');
    // $log.log(group);
    // $log.log($scope.selectedItem);
    // $log.log(item);

    cov_1vn8dwwzuy.s[94]++;
    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',
      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1vn8dwwzuy.f[21]++;
      cov_1vn8dwwzuy.s[95]++;

      if (isconfirm) {
        cov_1vn8dwwzuy.b[15][0]++;
        cov_1vn8dwwzuy.s[96]++;

        return;
      } else {
        cov_1vn8dwwzuy.b[15][1]++;
      }
      cov_1vn8dwwzuy.s[97]++;
      $scope.listItems[group.id].loading = true;
      cov_1vn8dwwzuy.s[98]++;
      $CRUDService.save(group.path, { action: 'supp_definitif', id: item.id }, function (data) {
        cov_1vn8dwwzuy.f[22]++;
        cov_1vn8dwwzuy.s[99]++;

        if (data < 0) {
          cov_1vn8dwwzuy.b[16][0]++;
          cov_1vn8dwwzuy.s[100]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
            type: 'warning'
          });
          cov_1vn8dwwzuy.s[101]++;
          $scope.listItems[group.id].loading = false;
        } else {
          cov_1vn8dwwzuy.b[16][1]++;
          cov_1vn8dwwzuy.s[102]++;

          $CRUDService.getAll(group.path, { get: 'all_deleted' }, function (data_) {
            cov_1vn8dwwzuy.f[23]++;
            cov_1vn8dwwzuy.s[103]++;

            $scope.listItems[group.id].loading = false;
            cov_1vn8dwwzuy.s[104]++;
            $scope.listItems[group.id].data = data_;
          });
        }
      });
    });
  };

  cov_1vn8dwwzuy.s[105]++;
  $scope.deleteDatas = function () {
    cov_1vn8dwwzuy.f[24]++;

    var group = (cov_1vn8dwwzuy.s[106]++, $scope.groupItem.filter(function (value) {
      cov_1vn8dwwzuy.f[25]++;
      cov_1vn8dwwzuy.s[107]++;

      return value.id == $scope.selectedItem.value;
    })[0]);
    // $log.log('deleteDatas');
    // $log.log(group);
    // $log.log($scope.selectedItem);
    // $log.log($scope.deletedItems);
    var count = (cov_1vn8dwwzuy.s[108]++, $scope.deletedItems.length);
    cov_1vn8dwwzuy.s[109]++;
    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',
      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1vn8dwwzuy.f[26]++;
      cov_1vn8dwwzuy.s[110]++;

      if (isconfirm) {
        cov_1vn8dwwzuy.b[17][0]++;
        cov_1vn8dwwzuy.s[111]++;

        return;
      } else {
        cov_1vn8dwwzuy.b[17][1]++;
      }
      cov_1vn8dwwzuy.s[112]++;
      $scope.listItems[group.id].loading = true;

      cov_1vn8dwwzuy.s[113]++;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = $scope.deletedItems[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var item = _step3.value;
          cov_1vn8dwwzuy.s[114]++;


          if ((cov_1vn8dwwzuy.b[19][0]++, group.id == 'CLS') && (cov_1vn8dwwzuy.b[19][1]++, !$scope.checkDeleteFile(item))) {
            cov_1vn8dwwzuy.b[18][0]++;
            cov_1vn8dwwzuy.s[115]++;

            // $log.log('deleteDatas');
            $CRUDService.save(group.path, { action: 'supp_definitif', id: item.id, fileDeleted: 1 }, function (data) {
              cov_1vn8dwwzuy.f[27]++;
              cov_1vn8dwwzuy.s[116]++;

              count--;
              cov_1vn8dwwzuy.s[117]++;
              if (count < 1) {
                cov_1vn8dwwzuy.b[20][0]++;
                cov_1vn8dwwzuy.s[118]++;

                $CRUDService.getAll(group.path, { get: 'all_deleted' }, function (data_) {
                  cov_1vn8dwwzuy.f[28]++;
                  cov_1vn8dwwzuy.s[119]++;

                  $scope.listItems[group.id].loading = false;
                  cov_1vn8dwwzuy.s[120]++;
                  $scope.listItems[group.id].data = data_;
                });
              } else {
                cov_1vn8dwwzuy.b[20][1]++;
              }
            });
          } else {
            cov_1vn8dwwzuy.b[18][1]++;
            cov_1vn8dwwzuy.s[121]++;

            // $log.log('deleteDatas');
            $CRUDService.save(group.path, { action: 'supp_definitif', id: item.id }, function (data) {
              cov_1vn8dwwzuy.f[29]++;
              cov_1vn8dwwzuy.s[122]++;

              count--;
              cov_1vn8dwwzuy.s[123]++;
              if (count < 1) {
                cov_1vn8dwwzuy.b[21][0]++;
                cov_1vn8dwwzuy.s[124]++;

                $CRUDService.getAll(group.path, { get: 'all_deleted' }, function (data_) {
                  cov_1vn8dwwzuy.f[30]++;
                  cov_1vn8dwwzuy.s[125]++;

                  $scope.listItems[group.id].loading = false;
                  cov_1vn8dwwzuy.s[126]++;
                  $scope.listItems[group.id].data = data_;
                });
              } else {
                cov_1vn8dwwzuy.b[21][1]++;
              }
            });
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      cov_1vn8dwwzuy.s[127]++;
      $scope.deletedItems = [];
    });
  };
});