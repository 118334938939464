'use strict';

var cov_1idncka1oh = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/activities/workflow/workflowCtrl.js',
      hash = '37eb471c6a5e1034f59ca5c275eb342470c91507',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/activities/workflow/workflowCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 307,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 21
        }
      },
      '2': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 3,
          column: 33
        }
      },
      '3': {
        start: {
          line: 4,
          column: 23
        },
        end: {
          line: 4,
          column: 38
        }
      },
      '4': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 26
        }
      },
      '5': {
        start: {
          line: 11,
          column: 15
        },
        end: {
          line: 11,
          column: 67
        }
      },
      '6': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 74
        }
      },
      '7': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 72
        }
      },
      '8': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 44
        }
      },
      '9': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 45
        }
      },
      '10': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 16,
          column: 44
        }
      },
      '11': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 20,
          column: 5
        }
      },
      '12': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 32
        }
      },
      '13': {
        start: {
          line: 22,
          column: 2
        },
        end: {
          line: 28,
          column: 4
        }
      },
      '14': {
        start: {
          line: 23,
          column: 4
        },
        end: {
          line: 27,
          column: 5
        }
      },
      '15': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 120
        }
      },
      '16': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 121
        }
      },
      '17': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 30,
          column: 35
        }
      },
      '18': {
        start: {
          line: 31,
          column: 2
        },
        end: {
          line: 33,
          column: 5
        }
      },
      '19': {
        start: {
          line: 32,
          column: 4
        },
        end: {
          line: 32,
          column: 58
        }
      },
      '20': {
        start: {
          line: 35,
          column: 2
        },
        end: {
          line: 44,
          column: 4
        }
      },
      '21': {
        start: {
          line: 36,
          column: 17
        },
        end: {
          line: 36,
          column: 36
        }
      },
      '22': {
        start: {
          line: 37,
          column: 22
        },
        end: {
          line: 41,
          column: 33
        }
      },
      '23': {
        start: {
          line: 43,
          column: 4
        },
        end: {
          line: 43,
          column: 30
        }
      },
      '24': {
        start: {
          line: 46,
          column: 2
        },
        end: {
          line: 46,
          column: 36
        }
      },
      '25': {
        start: {
          line: 47,
          column: 2
        },
        end: {
          line: 101,
          column: 5
        }
      },
      '26': {
        start: {
          line: 48,
          column: 4
        },
        end: {
          line: 96,
          column: 5
        }
      },
      '27': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 51,
          column: 7
        }
      },
      '28': {
        start: {
          line: 50,
          column: 8
        },
        end: {
          line: 50,
          column: 17
        }
      },
      '29': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 52,
          column: 58
        }
      },
      '30': {
        start: {
          line: 53,
          column: 6
        },
        end: {
          line: 53,
          column: 54
        }
      },
      '31': {
        start: {
          line: 54,
          column: 6
        },
        end: {
          line: 54,
          column: 61
        }
      },
      '32': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 55,
          column: 68
        }
      },
      '33': {
        start: {
          line: 56,
          column: 6
        },
        end: {
          line: 56,
          column: 66
        }
      },
      '34': {
        start: {
          line: 57,
          column: 6
        },
        end: {
          line: 61,
          column: 7
        }
      },
      '35': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 58,
          column: 72
        }
      },
      '36': {
        start: {
          line: 60,
          column: 8
        },
        end: {
          line: 60,
          column: 39
        }
      },
      '37': {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 66,
          column: 7
        }
      },
      '38': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 63,
          column: 70
        }
      },
      '39': {
        start: {
          line: 65,
          column: 8
        },
        end: {
          line: 65,
          column: 38
        }
      },
      '40': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 71,
          column: 7
        }
      },
      '41': {
        start: {
          line: 68,
          column: 8
        },
        end: {
          line: 68,
          column: 66
        }
      },
      '42': {
        start: {
          line: 70,
          column: 8
        },
        end: {
          line: 70,
          column: 36
        }
      },
      '43': {
        start: {
          line: 72,
          column: 26
        },
        end: {
          line: 75,
          column: 7
        }
      },
      '44': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 94,
          column: 7
        }
      },
      '45': {
        start: {
          line: 77,
          column: 20
        },
        end: {
          line: 77,
          column: 44
        }
      },
      '46': {
        start: {
          line: 78,
          column: 8
        },
        end: {
          line: 93,
          column: 9
        }
      },
      '47': {
        start: {
          line: 79,
          column: 10
        },
        end: {
          line: 92,
          column: 11
        }
      },
      '48': {
        start: {
          line: 80,
          column: 23
        },
        end: {
          line: 80,
          column: 40
        }
      },
      '49': {
        start: {
          line: 81,
          column: 12
        },
        end: {
          line: 91,
          column: 13
        }
      },
      '50': {
        start: {
          line: 82,
          column: 14
        },
        end: {
          line: 84,
          column: 15
        }
      },
      '51': {
        start: {
          line: 83,
          column: 16
        },
        end: {
          line: 83,
          column: 25
        }
      },
      '52': {
        start: {
          line: 85,
          column: 14
        },
        end: {
          line: 87,
          column: 15
        }
      },
      '53': {
        start: {
          line: 86,
          column: 16
        },
        end: {
          line: 86,
          column: 44
        }
      },
      '54': {
        start: {
          line: 88,
          column: 14
        },
        end: {
          line: 90,
          column: 15
        }
      },
      '55': {
        start: {
          line: 89,
          column: 16
        },
        end: {
          line: 89,
          column: 44
        }
      },
      '56': {
        start: {
          line: 95,
          column: 6
        },
        end: {
          line: 95,
          column: 33
        }
      },
      '57': {
        start: {
          line: 97,
          column: 4
        },
        end: {
          line: 97,
          column: 28
        }
      },
      '58': {
        start: {
          line: 98,
          column: 4
        },
        end: {
          line: 98,
          column: 38
        }
      },
      '59': {
        start: {
          line: 99,
          column: 4
        },
        end: {
          line: 99,
          column: 142
        }
      },
      '60': {
        start: {
          line: 100,
          column: 4
        },
        end: {
          line: 100,
          column: 24
        }
      },
      '61': {
        start: {
          line: 103,
          column: 2
        },
        end: {
          line: 119,
          column: 4
        }
      },
      '62': {
        start: {
          line: 104,
          column: 4
        },
        end: {
          line: 104,
          column: 28
        }
      },
      '63': {
        start: {
          line: 105,
          column: 24
        },
        end: {
          line: 105,
          column: 134
        }
      },
      '64': {
        start: {
          line: 106,
          column: 4
        },
        end: {
          line: 118,
          column: 7
        }
      },
      '65': {
        start: {
          line: 107,
          column: 6
        },
        end: {
          line: 110,
          column: 7
        }
      },
      '66': {
        start: {
          line: 108,
          column: 8
        },
        end: {
          line: 108,
          column: 41
        }
      },
      '67': {
        start: {
          line: 109,
          column: 8
        },
        end: {
          line: 109,
          column: 54
        }
      },
      '68': {
        start: {
          line: 111,
          column: 6
        },
        end: {
          line: 111,
          column: 29
        }
      },
      '69': {
        start: {
          line: 112,
          column: 6
        },
        end: {
          line: 112,
          column: 36
        }
      },
      '70': {
        start: {
          line: 113,
          column: 6
        },
        end: {
          line: 113,
          column: 31
        }
      },
      '71': {
        start: {
          line: 114,
          column: 6
        },
        end: {
          line: 114,
          column: 43
        }
      },
      '72': {
        start: {
          line: 115,
          column: 6
        },
        end: {
          line: 115,
          column: 43
        }
      },
      '73': {
        start: {
          line: 116,
          column: 6
        },
        end: {
          line: 116,
          column: 45
        }
      },
      '74': {
        start: {
          line: 117,
          column: 6
        },
        end: {
          line: 117,
          column: 34
        }
      },
      '75': {
        start: {
          line: 120,
          column: 2
        },
        end: {
          line: 136,
          column: 4
        }
      },
      '76': {
        start: {
          line: 121,
          column: 21
        },
        end: {
          line: 125,
          column: 14
        }
      },
      '77': {
        start: {
          line: 126,
          column: 15
        },
        end: {
          line: 126,
          column: 26
        }
      },
      '78': {
        start: {
          line: 127,
          column: 18
        },
        end: {
          line: 127,
          column: 47
        }
      },
      '79': {
        start: {
          line: 128,
          column: 4
        },
        end: {
          line: 135,
          column: 7
        }
      },
      '80': {
        start: {
          line: 137,
          column: 2
        },
        end: {
          line: 172,
          column: 4
        }
      },
      '81': {
        start: {
          line: 138,
          column: 4
        },
        end: {
          line: 171,
          column: 7
        }
      },
      '82': {
        start: {
          line: 150,
          column: 6
        },
        end: {
          line: 152,
          column: 7
        }
      },
      '83': {
        start: {
          line: 151,
          column: 8
        },
        end: {
          line: 151,
          column: 15
        }
      },
      '84': {
        start: {
          line: 153,
          column: 6
        },
        end: {
          line: 153,
          column: 30
        }
      },
      '85': {
        start: {
          line: 154,
          column: 27
        },
        end: {
          line: 154,
          column: 45
        }
      },
      '86': {
        start: {
          line: 155,
          column: 6
        },
        end: {
          line: 170,
          column: 9
        }
      },
      '87': {
        start: {
          line: 156,
          column: 8
        },
        end: {
          line: 163,
          column: 9
        }
      },
      '88': {
        start: {
          line: 157,
          column: 10
        },
        end: {
          line: 160,
          column: 13
        }
      },
      '89': {
        start: {
          line: 161,
          column: 10
        },
        end: {
          line: 161,
          column: 35
        }
      },
      '90': {
        start: {
          line: 162,
          column: 10
        },
        end: {
          line: 162,
          column: 17
        }
      },
      '91': {
        start: {
          line: 164,
          column: 21
        },
        end: {
          line: 164,
          column: 40
        }
      },
      '92': {
        start: {
          line: 165,
          column: 8
        },
        end: {
          line: 169,
          column: 19
        }
      },
      '93': {
        start: {
          line: 173,
          column: 2
        },
        end: {
          line: 204,
          column: 4
        }
      },
      '94': {
        start: {
          line: 174,
          column: 4
        },
        end: {
          line: 174,
          column: 45
        }
      },
      '95': {
        start: {
          line: 175,
          column: 4
        },
        end: {
          line: 175,
          column: 31
        }
      },
      '96': {
        start: {
          line: 176,
          column: 17
        },
        end: {
          line: 176,
          column: 36
        }
      },
      '97': {
        start: {
          line: 177,
          column: 21
        },
        end: {
          line: 181,
          column: 24
        }
      },
      '98': {
        start: {
          line: 182,
          column: 4
        },
        end: {
          line: 188,
          column: 5
        }
      },
      '99': {
        start: {
          line: 183,
          column: 20
        },
        end: {
          line: 183,
          column: 72
        }
      },
      '100': {
        start: {
          line: 184,
          column: 6
        },
        end: {
          line: 186,
          column: 7
        }
      },
      '101': {
        start: {
          line: 185,
          column: 8
        },
        end: {
          line: 185,
          column: 17
        }
      },
      '102': {
        start: {
          line: 187,
          column: 6
        },
        end: {
          line: 187,
          column: 39
        }
      },
      '103': {
        start: {
          line: 189,
          column: 14
        },
        end: {
          line: 189,
          column: 88
        }
      },
      '104': {
        start: {
          line: 190,
          column: 4
        },
        end: {
          line: 194,
          column: 5
        }
      },
      '105': {
        start: {
          line: 191,
          column: 6
        },
        end: {
          line: 191,
          column: 40
        }
      },
      '106': {
        start: {
          line: 193,
          column: 6
        },
        end: {
          line: 193,
          column: 79
        }
      },
      '107': {
        start: {
          line: 196,
          column: 4
        },
        end: {
          line: 203,
          column: 7
        }
      },
      '108': {
        start: {
          line: 206,
          column: 2
        },
        end: {
          line: 281,
          column: 4
        }
      },
      '109': {
        start: {
          line: 210,
          column: 8
        },
        end: {
          line: 210,
          column: 29
        }
      },
      '110': {
        start: {
          line: 211,
          column: 8
        },
        end: {
          line: 229,
          column: 11
        }
      },
      '111': {
        start: {
          line: 212,
          column: 10
        },
        end: {
          line: 228,
          column: 11
        }
      },
      '112': {
        start: {
          line: 213,
          column: 29
        },
        end: {
          line: 218,
          column: 13
        }
      },
      '113': {
        start: {
          line: 219,
          column: 12
        },
        end: {
          line: 224,
          column: 15
        }
      },
      '114': {
        start: {
          line: 220,
          column: 14
        },
        end: {
          line: 220,
          column: 34
        }
      },
      '115': {
        start: {
          line: 221,
          column: 14
        },
        end: {
          line: 221,
          column: 38
        }
      },
      '116': {
        start: {
          line: 222,
          column: 14
        },
        end: {
          line: 222,
          column: 64
        }
      },
      '117': {
        start: {
          line: 223,
          column: 14
        },
        end: {
          line: 223,
          column: 60
        }
      },
      '118': {
        start: {
          line: 226,
          column: 12
        },
        end: {
          line: 226,
          column: 58
        }
      },
      '119': {
        start: {
          line: 227,
          column: 12
        },
        end: {
          line: 227,
          column: 58
        }
      },
      '120': {
        start: {
          line: 236,
          column: 8
        },
        end: {
          line: 236,
          column: 29
        }
      },
      '121': {
        start: {
          line: 237,
          column: 8
        },
        end: {
          line: 254,
          column: 11
        }
      },
      '122': {
        start: {
          line: 238,
          column: 10
        },
        end: {
          line: 253,
          column: 11
        }
      },
      '123': {
        start: {
          line: 239,
          column: 29
        },
        end: {
          line: 243,
          column: 13
        }
      },
      '124': {
        start: {
          line: 244,
          column: 12
        },
        end: {
          line: 249,
          column: 15
        }
      },
      '125': {
        start: {
          line: 245,
          column: 14
        },
        end: {
          line: 245,
          column: 34
        }
      },
      '126': {
        start: {
          line: 246,
          column: 14
        },
        end: {
          line: 246,
          column: 38
        }
      },
      '127': {
        start: {
          line: 247,
          column: 14
        },
        end: {
          line: 247,
          column: 62
        }
      },
      '128': {
        start: {
          line: 248,
          column: 14
        },
        end: {
          line: 248,
          column: 58
        }
      },
      '129': {
        start: {
          line: 251,
          column: 12
        },
        end: {
          line: 251,
          column: 56
        }
      },
      '130': {
        start: {
          line: 252,
          column: 12
        },
        end: {
          line: 252,
          column: 56
        }
      },
      '131': {
        start: {
          line: 260,
          column: 8
        },
        end: {
          line: 260,
          column: 29
        }
      },
      '132': {
        start: {
          line: 261,
          column: 8
        },
        end: {
          line: 278,
          column: 11
        }
      },
      '133': {
        start: {
          line: 262,
          column: 10
        },
        end: {
          line: 277,
          column: 11
        }
      },
      '134': {
        start: {
          line: 263,
          column: 29
        },
        end: {
          line: 267,
          column: 13
        }
      },
      '135': {
        start: {
          line: 268,
          column: 12
        },
        end: {
          line: 273,
          column: 15
        }
      },
      '136': {
        start: {
          line: 269,
          column: 14
        },
        end: {
          line: 269,
          column: 34
        }
      },
      '137': {
        start: {
          line: 270,
          column: 14
        },
        end: {
          line: 270,
          column: 38
        }
      },
      '138': {
        start: {
          line: 271,
          column: 14
        },
        end: {
          line: 271,
          column: 62
        }
      },
      '139': {
        start: {
          line: 272,
          column: 14
        },
        end: {
          line: 272,
          column: 58
        }
      },
      '140': {
        start: {
          line: 275,
          column: 12
        },
        end: {
          line: 275,
          column: 56
        }
      },
      '141': {
        start: {
          line: 276,
          column: 12
        },
        end: {
          line: 276,
          column: 56
        }
      },
      '142': {
        start: {
          line: 283,
          column: 2
        },
        end: {
          line: 306,
          column: 4
        }
      },
      '143': {
        start: {
          line: 286,
          column: 6
        },
        end: {
          line: 286,
          column: 27
        }
      },
      '144': {
        start: {
          line: 287,
          column: 6
        },
        end: {
          line: 304,
          column: 9
        }
      },
      '145': {
        start: {
          line: 288,
          column: 8
        },
        end: {
          line: 303,
          column: 9
        }
      },
      '146': {
        start: {
          line: 289,
          column: 27
        },
        end: {
          line: 293,
          column: 11
        }
      },
      '147': {
        start: {
          line: 294,
          column: 10
        },
        end: {
          line: 299,
          column: 13
        }
      },
      '148': {
        start: {
          line: 295,
          column: 12
        },
        end: {
          line: 295,
          column: 32
        }
      },
      '149': {
        start: {
          line: 296,
          column: 12
        },
        end: {
          line: 296,
          column: 36
        }
      },
      '150': {
        start: {
          line: 297,
          column: 12
        },
        end: {
          line: 297,
          column: 51
        }
      },
      '151': {
        start: {
          line: 298,
          column: 12
        },
        end: {
          line: 298,
          column: 47
        }
      },
      '152': {
        start: {
          line: 301,
          column: 10
        },
        end: {
          line: 301,
          column: 45
        }
      },
      '153': {
        start: {
          line: 302,
          column: 10
        },
        end: {
          line: 302,
          column: 45
        }
      },
      '154': {
        start: {
          line: 310,
          column: 2
        },
        end: {
          line: 310,
          column: 28
        }
      },
      '155': {
        start: {
          line: 314,
          column: 15
        },
        end: {
          line: 314,
          column: 25
        }
      },
      '156': {
        start: {
          line: 315,
          column: 2
        },
        end: {
          line: 327,
          column: 4
        }
      },
      '157': {
        start: {
          line: 316,
          column: 25
        },
        end: {
          line: 316,
          column: 58
        }
      },
      '158': {
        start: {
          line: 317,
          column: 4
        },
        end: {
          line: 317,
          column: 49
        }
      },
      '159': {
        start: {
          line: 318,
          column: 4
        },
        end: {
          line: 326,
          column: 7
        }
      },
      '160': {
        start: {
          line: 319,
          column: 6
        },
        end: {
          line: 319,
          column: 26
        }
      },
      '161': {
        start: {
          line: 320,
          column: 19
        },
        end: {
          line: 320,
          column: 38
        }
      },
      '162': {
        start: {
          line: 321,
          column: 6
        },
        end: {
          line: 325,
          column: 17
        }
      },
      '163': {
        start: {
          line: 328,
          column: 2
        },
        end: {
          line: 330,
          column: 4
        }
      },
      '164': {
        start: {
          line: 329,
          column: 4
        },
        end: {
          line: 329,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 59
          },
          end: {
            line: 1,
            column: 60
          }
        },
        loc: {
          start: {
            line: 1,
            column: 245
          },
          end: {
            line: 307,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 18,
            column: 30
          },
          end: {
            line: 18,
            column: 31
          }
        },
        loc: {
          start: {
            line: 18,
            column: 39
          },
          end: {
            line: 20,
            column: 3
          }
        },
        line: 18
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 22,
            column: 21
          },
          end: {
            line: 22,
            column: 22
          }
        },
        loc: {
          start: {
            line: 22,
            column: 37
          },
          end: {
            line: 28,
            column: 3
          }
        },
        line: 22
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 31,
            column: 60
          },
          end: {
            line: 31,
            column: 61
          }
        },
        loc: {
          start: {
            line: 31,
            column: 68
          },
          end: {
            line: 33,
            column: 3
          }
        },
        line: 31
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 35,
            column: 23
          },
          end: {
            line: 35,
            column: 24
          }
        },
        loc: {
          start: {
            line: 35,
            column: 35
          },
          end: {
            line: 44,
            column: 3
          }
        },
        line: 35
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 47,
            column: 79
          },
          end: {
            line: 47,
            column: 80
          }
        },
        loc: {
          start: {
            line: 47,
            column: 87
          },
          end: {
            line: 101,
            column: 3
          }
        },
        line: 47
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 103,
            column: 22
          },
          end: {
            line: 103,
            column: 23
          }
        },
        loc: {
          start: {
            line: 103,
            column: 34
          },
          end: {
            line: 119,
            column: 3
          }
        },
        line: 103
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 106,
            column: 43
          },
          end: {
            line: 106,
            column: 44
          }
        },
        loc: {
          start: {
            line: 106,
            column: 51
          },
          end: {
            line: 118,
            column: 5
          }
        },
        line: 106
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 120,
            column: 19
          },
          end: {
            line: 120,
            column: 20
          }
        },
        loc: {
          start: {
            line: 120,
            column: 35
          },
          end: {
            line: 136,
            column: 3
          }
        },
        line: 120
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 137,
            column: 22
          },
          end: {
            line: 137,
            column: 23
          }
        },
        loc: {
          start: {
            line: 137,
            column: 38
          },
          end: {
            line: 172,
            column: 3
          }
        },
        line: 137
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 149,
            column: 7
          },
          end: {
            line: 149,
            column: 8
          }
        },
        loc: {
          start: {
            line: 149,
            column: 20
          },
          end: {
            line: 171,
            column: 5
          }
        },
        line: 149
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 155,
            column: 96
          },
          end: {
            line: 155,
            column: 97
          }
        },
        loc: {
          start: {
            line: 155,
            column: 104
          },
          end: {
            line: 170,
            column: 7
          }
        },
        line: 155
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 173,
            column: 20
          },
          end: {
            line: 173,
            column: 21
          }
        },
        loc: {
          start: {
            line: 173,
            column: 36
          },
          end: {
            line: 204,
            column: 3
          }
        },
        line: 173
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 211,
            column: 82
          },
          end: {
            line: 211,
            column: 83
          }
        },
        loc: {
          start: {
            line: 211,
            column: 90
          },
          end: {
            line: 229,
            column: 9
          }
        },
        line: 211
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 219,
            column: 101
          },
          end: {
            line: 219,
            column: 102
          }
        },
        loc: {
          start: {
            line: 219,
            column: 110
          },
          end: {
            line: 224,
            column: 13
          }
        },
        line: 219
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 237,
            column: 82
          },
          end: {
            line: 237,
            column: 83
          }
        },
        loc: {
          start: {
            line: 237,
            column: 90
          },
          end: {
            line: 254,
            column: 9
          }
        },
        line: 237
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 244,
            column: 101
          },
          end: {
            line: 244,
            column: 102
          }
        },
        loc: {
          start: {
            line: 244,
            column: 110
          },
          end: {
            line: 249,
            column: 13
          }
        },
        line: 244
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 261,
            column: 82
          },
          end: {
            line: 261,
            column: 83
          }
        },
        loc: {
          start: {
            line: 261,
            column: 90
          },
          end: {
            line: 278,
            column: 9
          }
        },
        line: 261
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 268,
            column: 101
          },
          end: {
            line: 268,
            column: 102
          }
        },
        loc: {
          start: {
            line: 268,
            column: 110
          },
          end: {
            line: 273,
            column: 13
          }
        },
        line: 268
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 287,
            column: 78
          },
          end: {
            line: 287,
            column: 79
          }
        },
        loc: {
          start: {
            line: 287,
            column: 86
          },
          end: {
            line: 304,
            column: 7
          }
        },
        line: 287
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 294,
            column: 99
          },
          end: {
            line: 294,
            column: 100
          }
        },
        loc: {
          start: {
            line: 294,
            column: 108
          },
          end: {
            line: 299,
            column: 11
          }
        },
        line: 294
      },
      '21': {
        name: 'activitiesWorkflowCtrl_edit',
        decl: {
          start: {
            line: 309,
            column: 9
          },
          end: {
            line: 309,
            column: 36
          }
        },
        loc: {
          start: {
            line: 309,
            column: 87
          },
          end: {
            line: 331,
            column: 1
          }
        },
        line: 309
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 315,
            column: 24
          },
          end: {
            line: 315,
            column: 25
          }
        },
        loc: {
          start: {
            line: 315,
            column: 36
          },
          end: {
            line: 327,
            column: 3
          }
        },
        line: 315
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 318,
            column: 94
          },
          end: {
            line: 318,
            column: 95
          }
        },
        loc: {
          start: {
            line: 318,
            column: 100
          },
          end: {
            line: 326,
            column: 5
          }
        },
        line: 318
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 328,
            column: 22
          },
          end: {
            line: 328,
            column: 23
          }
        },
        loc: {
          start: {
            line: 328,
            column: 34
          },
          end: {
            line: 330,
            column: 3
          }
        },
        line: 328
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 23,
            column: 4
          },
          end: {
            line: 27,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 23,
            column: 4
          },
          end: {
            line: 27,
            column: 5
          }
        }, {
          start: {
            line: 23,
            column: 4
          },
          end: {
            line: 27,
            column: 5
          }
        }],
        line: 23
      },
      '1': {
        loc: {
          start: {
            line: 37,
            column: 22
          },
          end: {
            line: 41,
            column: 33
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 37,
            column: 22
          },
          end: {
            line: 41,
            column: 14
          }
        }, {
          start: {
            line: 41,
            column: 18
          },
          end: {
            line: 41,
            column: 33
          }
        }],
        line: 37
      },
      '2': {
        loc: {
          start: {
            line: 38,
            column: 8
          },
          end: {
            line: 40,
            column: 79
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 38,
            column: 20
          },
          end: {
            line: 38,
            column: 49
          }
        }, {
          start: {
            line: 39,
            column: 13
          },
          end: {
            line: 40,
            column: 78
          }
        }],
        line: 38
      },
      '3': {
        loc: {
          start: {
            line: 39,
            column: 13
          },
          end: {
            line: 40,
            column: 78
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 39,
            column: 25
          },
          end: {
            line: 39,
            column: 52
          }
        }, {
          start: {
            line: 40,
            column: 17
          },
          end: {
            line: 40,
            column: 77
          }
        }],
        line: 39
      },
      '4': {
        loc: {
          start: {
            line: 40,
            column: 17
          },
          end: {
            line: 40,
            column: 77
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 40,
            column: 29
          },
          end: {
            line: 40,
            column: 56
          }
        }, {
          start: {
            line: 40,
            column: 59
          },
          end: {
            line: 40,
            column: 77
          }
        }],
        line: 40
      },
      '5': {
        loc: {
          start: {
            line: 47,
            column: 56
          },
          end: {
            line: 47,
            column: 76
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 47,
            column: 56
          },
          end: {
            line: 47,
            column: 71
          }
        }, {
          start: {
            line: 47,
            column: 75
          },
          end: {
            line: 47,
            column: 76
          }
        }],
        line: 47
      },
      '6': {
        loc: {
          start: {
            line: 49,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 49,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        }, {
          start: {
            line: 49,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        }],
        line: 49
      },
      '7': {
        loc: {
          start: {
            line: 55,
            column: 26
          },
          end: {
            line: 55,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 55,
            column: 26
          },
          end: {
            line: 55,
            column: 44
          }
        }, {
          start: {
            line: 55,
            column: 48
          },
          end: {
            line: 55,
            column: 66
          }
        }],
        line: 55
      },
      '8': {
        loc: {
          start: {
            line: 56,
            column: 24
          },
          end: {
            line: 56,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 56,
            column: 24
          },
          end: {
            line: 56,
            column: 42
          }
        }, {
          start: {
            line: 56,
            column: 46
          },
          end: {
            line: 56,
            column: 64
          }
        }],
        line: 56
      },
      '9': {
        loc: {
          start: {
            line: 57,
            column: 6
          },
          end: {
            line: 61,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 57,
            column: 6
          },
          end: {
            line: 61,
            column: 7
          }
        }, {
          start: {
            line: 57,
            column: 6
          },
          end: {
            line: 61,
            column: 7
          }
        }],
        line: 57
      },
      '10': {
        loc: {
          start: {
            line: 57,
            column: 10
          },
          end: {
            line: 57,
            column: 76
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 57,
            column: 10
          },
          end: {
            line: 57,
            column: 33
          }
        }, {
          start: {
            line: 57,
            column: 37
          },
          end: {
            line: 57,
            column: 76
          }
        }],
        line: 57
      },
      '11': {
        loc: {
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 66,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 66,
            column: 7
          }
        }, {
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 66,
            column: 7
          }
        }],
        line: 62
      },
      '12': {
        loc: {
          start: {
            line: 62,
            column: 10
          },
          end: {
            line: 62,
            column: 74
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 62,
            column: 10
          },
          end: {
            line: 62,
            column: 32
          }
        }, {
          start: {
            line: 62,
            column: 36
          },
          end: {
            line: 62,
            column: 74
          }
        }],
        line: 62
      },
      '13': {
        loc: {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 71,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 71,
            column: 7
          }
        }, {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 71,
            column: 7
          }
        }],
        line: 67
      },
      '14': {
        loc: {
          start: {
            line: 67,
            column: 10
          },
          end: {
            line: 67,
            column: 70
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 67,
            column: 10
          },
          end: {
            line: 67,
            column: 30
          }
        }, {
          start: {
            line: 67,
            column: 34
          },
          end: {
            line: 67,
            column: 70
          }
        }],
        line: 67
      },
      '15': {
        loc: {
          start: {
            line: 76,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 76,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        }, {
          start: {
            line: 76,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        }],
        line: 76
      },
      '16': {
        loc: {
          start: {
            line: 78,
            column: 8
          },
          end: {
            line: 93,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 78,
            column: 8
          },
          end: {
            line: 93,
            column: 9
          }
        }, {
          start: {
            line: 78,
            column: 8
          },
          end: {
            line: 93,
            column: 9
          }
        }],
        line: 78
      },
      '17': {
        loc: {
          start: {
            line: 82,
            column: 14
          },
          end: {
            line: 84,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 82,
            column: 14
          },
          end: {
            line: 84,
            column: 15
          }
        }, {
          start: {
            line: 82,
            column: 14
          },
          end: {
            line: 84,
            column: 15
          }
        }],
        line: 82
      },
      '18': {
        loc: {
          start: {
            line: 82,
            column: 18
          },
          end: {
            line: 82,
            column: 45
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 82,
            column: 18
          },
          end: {
            line: 82,
            column: 31
          }
        }, {
          start: {
            line: 82,
            column: 35
          },
          end: {
            line: 82,
            column: 45
          }
        }],
        line: 82
      },
      '19': {
        loc: {
          start: {
            line: 85,
            column: 14
          },
          end: {
            line: 87,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 85,
            column: 14
          },
          end: {
            line: 87,
            column: 15
          }
        }, {
          start: {
            line: 85,
            column: 14
          },
          end: {
            line: 87,
            column: 15
          }
        }],
        line: 85
      },
      '20': {
        loc: {
          start: {
            line: 88,
            column: 14
          },
          end: {
            line: 90,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 88,
            column: 14
          },
          end: {
            line: 90,
            column: 15
          }
        }, {
          start: {
            line: 88,
            column: 14
          },
          end: {
            line: 90,
            column: 15
          }
        }],
        line: 88
      },
      '21': {
        loc: {
          start: {
            line: 99,
            column: 79
          },
          end: {
            line: 99,
            column: 120
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 99,
            column: 79
          },
          end: {
            line: 99,
            column: 102
          }
        }, {
          start: {
            line: 99,
            column: 106
          },
          end: {
            line: 99,
            column: 120
          }
        }],
        line: 99
      },
      '22': {
        loc: {
          start: {
            line: 105,
            column: 24
          },
          end: {
            line: 105,
            column: 134
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 105,
            column: 60
          },
          end: {
            line: 105,
            column: 119
          }
        }, {
          start: {
            line: 105,
            column: 122
          },
          end: {
            line: 105,
            column: 134
          }
        }],
        line: 105
      },
      '23': {
        loc: {
          start: {
            line: 122,
            column: 8
          },
          end: {
            line: 124,
            column: 79
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 122,
            column: 20
          },
          end: {
            line: 122,
            column: 49
          }
        }, {
          start: {
            line: 123,
            column: 13
          },
          end: {
            line: 124,
            column: 78
          }
        }],
        line: 122
      },
      '24': {
        loc: {
          start: {
            line: 123,
            column: 13
          },
          end: {
            line: 124,
            column: 78
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 123,
            column: 25
          },
          end: {
            line: 123,
            column: 52
          }
        }, {
          start: {
            line: 124,
            column: 17
          },
          end: {
            line: 124,
            column: 77
          }
        }],
        line: 123
      },
      '25': {
        loc: {
          start: {
            line: 124,
            column: 17
          },
          end: {
            line: 124,
            column: 77
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 124,
            column: 29
          },
          end: {
            line: 124,
            column: 56
          }
        }, {
          start: {
            line: 124,
            column: 59
          },
          end: {
            line: 124,
            column: 77
          }
        }],
        line: 124
      },
      '26': {
        loc: {
          start: {
            line: 150,
            column: 6
          },
          end: {
            line: 152,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 150,
            column: 6
          },
          end: {
            line: 152,
            column: 7
          }
        }, {
          start: {
            line: 150,
            column: 6
          },
          end: {
            line: 152,
            column: 7
          }
        }],
        line: 150
      },
      '27': {
        loc: {
          start: {
            line: 156,
            column: 8
          },
          end: {
            line: 163,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 156,
            column: 8
          },
          end: {
            line: 163,
            column: 9
          }
        }, {
          start: {
            line: 156,
            column: 8
          },
          end: {
            line: 163,
            column: 9
          }
        }],
        line: 156
      },
      '28': {
        loc: {
          start: {
            line: 166,
            column: 12
          },
          end: {
            line: 168,
            column: 83
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 166,
            column: 24
          },
          end: {
            line: 166,
            column: 53
          }
        }, {
          start: {
            line: 167,
            column: 17
          },
          end: {
            line: 168,
            column: 82
          }
        }],
        line: 166
      },
      '29': {
        loc: {
          start: {
            line: 167,
            column: 17
          },
          end: {
            line: 168,
            column: 82
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 167,
            column: 29
          },
          end: {
            line: 167,
            column: 56
          }
        }, {
          start: {
            line: 168,
            column: 21
          },
          end: {
            line: 168,
            column: 81
          }
        }],
        line: 167
      },
      '30': {
        loc: {
          start: {
            line: 168,
            column: 21
          },
          end: {
            line: 168,
            column: 81
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 168,
            column: 33
          },
          end: {
            line: 168,
            column: 60
          }
        }, {
          start: {
            line: 168,
            column: 63
          },
          end: {
            line: 168,
            column: 81
          }
        }],
        line: 168
      },
      '31': {
        loc: {
          start: {
            line: 178,
            column: 8
          },
          end: {
            line: 180,
            column: 79
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 178,
            column: 20
          },
          end: {
            line: 178,
            column: 49
          }
        }, {
          start: {
            line: 179,
            column: 13
          },
          end: {
            line: 180,
            column: 78
          }
        }],
        line: 178
      },
      '32': {
        loc: {
          start: {
            line: 179,
            column: 13
          },
          end: {
            line: 180,
            column: 78
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 179,
            column: 25
          },
          end: {
            line: 179,
            column: 52
          }
        }, {
          start: {
            line: 180,
            column: 17
          },
          end: {
            line: 180,
            column: 77
          }
        }],
        line: 179
      },
      '33': {
        loc: {
          start: {
            line: 180,
            column: 17
          },
          end: {
            line: 180,
            column: 77
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 180,
            column: 29
          },
          end: {
            line: 180,
            column: 56
          }
        }, {
          start: {
            line: 180,
            column: 59
          },
          end: {
            line: 180,
            column: 77
          }
        }],
        line: 180
      },
      '34': {
        loc: {
          start: {
            line: 184,
            column: 6
          },
          end: {
            line: 186,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 184,
            column: 6
          },
          end: {
            line: 186,
            column: 7
          }
        }, {
          start: {
            line: 184,
            column: 6
          },
          end: {
            line: 186,
            column: 7
          }
        }],
        line: 184
      },
      '35': {
        loc: {
          start: {
            line: 184,
            column: 10
          },
          end: {
            line: 184,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 184,
            column: 10
          },
          end: {
            line: 184,
            column: 26
          }
        }, {
          start: {
            line: 184,
            column: 30
          },
          end: {
            line: 184,
            column: 52
          }
        }],
        line: 184
      },
      '36': {
        loc: {
          start: {
            line: 190,
            column: 4
          },
          end: {
            line: 194,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 190,
            column: 4
          },
          end: {
            line: 194,
            column: 5
          }
        }, {
          start: {
            line: 190,
            column: 4
          },
          end: {
            line: 194,
            column: 5
          }
        }],
        line: 190
      },
      '37': {
        loc: {
          start: {
            line: 212,
            column: 10
          },
          end: {
            line: 228,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 212,
            column: 10
          },
          end: {
            line: 228,
            column: 11
          }
        }, {
          start: {
            line: 212,
            column: 10
          },
          end: {
            line: 228,
            column: 11
          }
        }],
        line: 212
      },
      '38': {
        loc: {
          start: {
            line: 238,
            column: 10
          },
          end: {
            line: 253,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 238,
            column: 10
          },
          end: {
            line: 253,
            column: 11
          }
        }, {
          start: {
            line: 238,
            column: 10
          },
          end: {
            line: 253,
            column: 11
          }
        }],
        line: 238
      },
      '39': {
        loc: {
          start: {
            line: 262,
            column: 10
          },
          end: {
            line: 277,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 262,
            column: 10
          },
          end: {
            line: 277,
            column: 11
          }
        }, {
          start: {
            line: 262,
            column: 10
          },
          end: {
            line: 277,
            column: 11
          }
        }],
        line: 262
      },
      '40': {
        loc: {
          start: {
            line: 288,
            column: 8
          },
          end: {
            line: 303,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 288,
            column: 8
          },
          end: {
            line: 303,
            column: 9
          }
        }, {
          start: {
            line: 288,
            column: 8
          },
          end: {
            line: 303,
            column: 9
          }
        }],
        line: 288
      },
      '41': {
        loc: {
          start: {
            line: 322,
            column: 10
          },
          end: {
            line: 324,
            column: 81
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 322,
            column: 22
          },
          end: {
            line: 322,
            column: 51
          }
        }, {
          start: {
            line: 323,
            column: 15
          },
          end: {
            line: 324,
            column: 80
          }
        }],
        line: 322
      },
      '42': {
        loc: {
          start: {
            line: 323,
            column: 15
          },
          end: {
            line: 324,
            column: 80
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 323,
            column: 27
          },
          end: {
            line: 323,
            column: 54
          }
        }, {
          start: {
            line: 324,
            column: 19
          },
          end: {
            line: 324,
            column: 79
          }
        }],
        line: 323
      },
      '43': {
        loc: {
          start: {
            line: 324,
            column: 19
          },
          end: {
            line: 324,
            column: 79
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 324,
            column: 31
          },
          end: {
            line: 324,
            column: 58
          }
        }, {
          start: {
            line: 324,
            column: 61
          },
          end: {
            line: 324,
            column: 79
          }
        }],
        line: 324
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1idncka1oh.s[0]++;
angular.module('app').controller('activitiesWorkflowCtrl', function ($scope, $stateParams, $deltaUnite, $state, $deltahttp, $rootScope, $translate, $deltaLocation, $CRUDService, SweetAlert, $deltadate, $deltaNumber, $deltaPeriode, $filter, $uibModal) {
  cov_1idncka1oh.f[0]++;

  var PATH = (cov_1idncka1oh.s[1]++, 'User');
  var PATHWORKFLOW = (cov_1idncka1oh.s[2]++, 'Workflow');
  var PATHACTIVITY = (cov_1idncka1oh.s[3]++, 'DernierNiveau');

  /* if (!$stateParams.params) {
   $stateParams.params = {};
  } */
  cov_1idncka1oh.s[4]++;
  $scope.tab_workflow = 1;

  var info = (cov_1idncka1oh.s[5]++, $rootScope.currentProject.project.getDecoupagePlan());
  cov_1idncka1oh.s[6]++;
  $scope.beginYear = String($deltadate.format(info.dDFirstLevel, 'yyyy'));
  cov_1idncka1oh.s[7]++;
  $scope.endYear = String($deltadate.format(info.dFFirstLevel, 'yyyy'));
  cov_1idncka1oh.s[8]++;
  $scope.labelGlobal = info.labelFirstLevel;
  cov_1idncka1oh.s[9]++;
  $scope.labelAnnual = info.labelSecondLevel;
  cov_1idncka1oh.s[10]++;
  $scope.labelPeriod = info.labelThirdLevel;

  cov_1idncka1oh.s[11]++;
  $scope.changeTabWorkflow = function (index) {
    cov_1idncka1oh.f[1]++;
    cov_1idncka1oh.s[12]++;

    $scope.tab_workflow = index;
  };

  cov_1idncka1oh.s[13]++;
  $scope.closePage = function (item) {
    cov_1idncka1oh.f[2]++;
    cov_1idncka1oh.s[14]++;

    if (!item.FINAL) {
      cov_1idncka1oh.b[0][0]++;
      cov_1idncka1oh.s[15]++;

      $state.go('activity.planning.overview.settings', { id: item.id, params: { item: item }, origin: 'activity.planning.main' });
    } else {
      cov_1idncka1oh.b[0][1]++;
      cov_1idncka1oh.s[16]++;

      $state.go('activity.planning.overview_portfolio', { id: item.id, params: { item: item }, origin: 'activity.planning.main' });
    }
  };

  cov_1idncka1oh.s[17]++;
  $scope.canDoGlobalPlaning = true;
  cov_1idncka1oh.s[18]++;
  $CRUDService.getAll('Planification', { get: 'plan_state' }, function (data) {
    cov_1idncka1oh.f[3]++;
    cov_1idncka1oh.s[19]++;

    $scope.canDoGlobalPlaning = parseInt(data, 10) == 1;
  });

  cov_1idncka1oh.s[20]++;
  $scope.getStepList = function () {
    cov_1idncka1oh.f[4]++;

    var type = (cov_1idncka1oh.s[21]++, $scope.tab_workflow);
    var workflow = (cov_1idncka1oh.s[22]++, (cov_1idncka1oh.b[1][0]++, (type == 1 ? (cov_1idncka1oh.b[2][0]++, $scope.planification.periodic) : (cov_1idncka1oh.b[2][1]++, type == 2 ? (cov_1idncka1oh.b[3][0]++, $scope.planification.annual) : (cov_1idncka1oh.b[3][1]++, type == 3 ? (cov_1idncka1oh.b[4][0]++, $scope.planification.global) : (cov_1idncka1oh.b[4][1]++, $scope.realisation)))).workflow) || (cov_1idncka1oh.b[1][1]++, { LIST_STEP: [] }));

    cov_1idncka1oh.s[23]++;
    return workflow.LIST_STEP;
  };

  cov_1idncka1oh.s[24]++;
  $deltaUnite.addController($scope);
  cov_1idncka1oh.s[25]++;
  $CRUDService.getAll(PATHACTIVITY, { get: 'single', id: (cov_1idncka1oh.b[5][0]++, $stateParams.id) || (cov_1idncka1oh.b[5][1]++, 0) }, function (data) {
    cov_1idncka1oh.f[5]++;
    cov_1idncka1oh.s[26]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var el = _step.value;
        cov_1idncka1oh.s[27]++;

        if (el.ID_UNITE < 1) {
          cov_1idncka1oh.b[6][0]++;
          cov_1idncka1oh.s[28]++;

          continue;
        } else {
          cov_1idncka1oh.b[6][1]++;
        }
        cov_1idncka1oh.s[29]++;
        el.DATE_DEMARRAGE = Date.newDate(el.DATE_DEMARRAGE);
        cov_1idncka1oh.s[30]++;
        el.DATE_FIN_ACT = Date.newDate(el.DATE_FIN_ACT);
        cov_1idncka1oh.s[31]++;
        el.COUT_U_DN = $deltaNumber.formatNumber(el.COUT_U_DN);
        cov_1idncka1oh.s[32]++;
        el.BUDGET_FOLLOW = (cov_1idncka1oh.b[7][0]++, el.CALCULAUTO == 1) || (cov_1idncka1oh.b[7][1]++, el.CALCULAUTO == 3);
        cov_1idncka1oh.s[33]++;
        el.COST_FOLLOW = (cov_1idncka1oh.b[8][0]++, el.CALCULAUTO == 2) || (cov_1idncka1oh.b[8][1]++, el.CALCULAUTO == 3);
        cov_1idncka1oh.s[34]++;
        if ((cov_1idncka1oh.b[10][0]++, el.DATE_REVISION_GLOBAL) && (cov_1idncka1oh.b[10][1]++, el.DATE_REVISION_GLOBAL != '0000-00-00')) {
          cov_1idncka1oh.b[9][0]++;
          cov_1idncka1oh.s[35]++;

          el.DATE_REVISION_GLOBAL = Date.newDate(el.DATE_REVISION_GLOBAL);
        } else {
          cov_1idncka1oh.b[9][1]++;
          cov_1idncka1oh.s[36]++;

          el.DATE_REVISION_GLOBAL = null;
        }
        cov_1idncka1oh.s[37]++;
        if ((cov_1idncka1oh.b[12][0]++, el.DATE_REVISION_ANNEE) && (cov_1idncka1oh.b[12][1]++, el.DATE_REVISION_ANNEE != '0000-00-00')) {
          cov_1idncka1oh.b[11][0]++;
          cov_1idncka1oh.s[38]++;

          el.DATE_REVISION_ANNEE = Date.newDate(el.DATE_REVISION_ANNEE);
        } else {
          cov_1idncka1oh.b[11][1]++;
          cov_1idncka1oh.s[39]++;

          el.DATE_REVISION_ANNEE = null;
        }
        cov_1idncka1oh.s[40]++;
        if ((cov_1idncka1oh.b[14][0]++, el.DATE_REVISION_PER) && (cov_1idncka1oh.b[14][1]++, el.DATE_REVISION_PER != '0000-00-00')) {
          cov_1idncka1oh.b[13][0]++;
          cov_1idncka1oh.s[41]++;

          el.DATE_REVISION_PER = Date.newDate(el.DATE_REVISION_PER);
        } else {
          cov_1idncka1oh.b[13][1]++;
          cov_1idncka1oh.s[42]++;

          el.DATE_REVISION_PER = null;
        }
        var tamponDroit = (cov_1idncka1oh.s[43]++, {
          p: {},
          c: {}
        });
        cov_1idncka1oh.s[44]++;
        if (el.DROITPLAN) {
          cov_1idncka1oh.b[15][0]++;

          var tab = (cov_1idncka1oh.s[45]++, el.DROITPLAN.split('\t'));
          cov_1idncka1oh.s[46]++;
          if (tab.length == 2) {
            cov_1idncka1oh.b[16][0]++;
            cov_1idncka1oh.s[47]++;

            for (var j = 0; j < tab.length; j++) {
              var ch = (cov_1idncka1oh.s[48]++, tab[j].split(';'));
              cov_1idncka1oh.s[49]++;
              var _iteratorNormalCompletion2 = true;
              var _didIteratorError2 = false;
              var _iteratorError2 = undefined;

              try {
                for (var _iterator2 = ch[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                  var itema = _step2.value;
                  cov_1idncka1oh.s[50]++;

                  if ((cov_1idncka1oh.b[18][0]++, itema == '') || (cov_1idncka1oh.b[18][1]++, itema == 0)) {
                    cov_1idncka1oh.b[17][0]++;
                    cov_1idncka1oh.s[51]++;

                    continue;
                  } else {
                    cov_1idncka1oh.b[17][1]++;
                  }
                  cov_1idncka1oh.s[52]++;
                  if (j == 0) {
                    cov_1idncka1oh.b[19][0]++;
                    cov_1idncka1oh.s[53]++;

                    tamponDroit.p[itema] = true;
                  } else {
                    cov_1idncka1oh.b[19][1]++;
                  }
                  cov_1idncka1oh.s[54]++;
                  if (j == 1) {
                    cov_1idncka1oh.b[20][0]++;
                    cov_1idncka1oh.s[55]++;

                    tamponDroit.c[itema] = true;
                  } else {
                    cov_1idncka1oh.b[20][1]++;
                  }
                }
              } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion2 && _iterator2.return) {
                    _iterator2.return();
                  }
                } finally {
                  if (_didIteratorError2) {
                    throw _iteratorError2;
                  }
                }
              }
            }
          } else {
            cov_1idncka1oh.b[16][1]++;
          }
        } else {
          cov_1idncka1oh.b[15][1]++;
        }
        cov_1idncka1oh.s[56]++;
        el.DROITPLAN = tamponDroit;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_1idncka1oh.s[57]++;
    $scope.indexLoading = 0;
    cov_1idncka1oh.s[58]++;
    $scope.selectedActivity = data[0];
    cov_1idncka1oh.s[59]++;
    $scope.unitSelected = $filter('filter')($scope.listData_unites_bksb, { id: ((cov_1idncka1oh.b[21][0]++, $scope.selectedActivity) || (cov_1idncka1oh.b[21][1]++, { ID_UNITE: -1 })).ID_UNITE }, true)[0];
    cov_1idncka1oh.s[60]++;
    $scope.getAllUser();
  });

  cov_1idncka1oh.s[61]++;
  $scope.getAllUser = function () {
    cov_1idncka1oh.f[6]++;
    cov_1idncka1oh.s[62]++;

    $scope.isloading = true;
    var userPayload = (cov_1idncka1oh.s[63]++, $scope.selectedProject.project.id ? (cov_1idncka1oh.b[22][0]++, { get: 'all', projet: [[$scope.selectedProject.project.id]] }) : (cov_1idncka1oh.b[22][1]++, { get: 'all' }));
    cov_1idncka1oh.s[64]++;
    $CRUDService.getAll(PATH, userPayload, function (data) {
      cov_1idncka1oh.f[7]++;
      cov_1idncka1oh.s[65]++;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = data[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var el = _step3.value;
          cov_1idncka1oh.s[66]++;

          el.TYPEUSER = el.TYPEUSER == 1;
          cov_1idncka1oh.s[67]++;
          el.picture = $deltahttp.getUserPicture(el.id);
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      cov_1idncka1oh.s[68]++;
      $scope.listUser = data;
      cov_1idncka1oh.s[69]++;
      $scope.listUser.push({ id: 0 });
      cov_1idncka1oh.s[70]++;
      $scope.isloading = false;
      cov_1idncka1oh.s[71]++;
      $scope.planification.global.getAll();
      cov_1idncka1oh.s[72]++;
      $scope.planification.annual.getAll();
      cov_1idncka1oh.s[73]++;
      $scope.planification.periodic.getAll();
      cov_1idncka1oh.s[74]++;
      $scope.realisation.getAll();
    });
  };
  cov_1idncka1oh.s[75]++;
  $scope.addStep = function (type) {
    cov_1idncka1oh.f[8]++;

    var workflow = (cov_1idncka1oh.s[76]++, (type == 1 ? (cov_1idncka1oh.b[23][0]++, $scope.planification.periodic) : (cov_1idncka1oh.b[23][1]++, type == 2 ? (cov_1idncka1oh.b[24][0]++, $scope.planification.annual) : (cov_1idncka1oh.b[24][1]++, type == 3 ? (cov_1idncka1oh.b[25][0]++, $scope.planification.global) : (cov_1idncka1oh.b[25][1]++, $scope.realisation)))).workflow);
    var id = (cov_1idncka1oh.s[77]++, workflow.id);
    var level = (cov_1idncka1oh.s[78]++, workflow.LIST_STEP.length + 1);
    cov_1idncka1oh.s[79]++;
    $scope.editStep({
      ID_WORKFLOW: id,
      NAME: '',
      COLOR: '0,0,0',
      id: 0,
      LEVEL: level,
      IDUSER: 0
    });
  };
  cov_1idncka1oh.s[80]++;
  $scope.deleteStep = function (item) {
    cov_1idncka1oh.f[9]++;
    cov_1idncka1oh.s[81]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1idncka1oh.f[10]++;
      cov_1idncka1oh.s[82]++;

      if (isconfirm) {
        cov_1idncka1oh.b[26][0]++;
        cov_1idncka1oh.s[83]++;

        return;
      } else {
        cov_1idncka1oh.b[26][1]++;
      }
      cov_1idncka1oh.s[84]++;
      $scope.isloading = true;
      var validateItem = (cov_1idncka1oh.s[85]++, angular.copy(item));
      cov_1idncka1oh.s[86]++;
      $CRUDService.delet(PATHWORKFLOW, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_1idncka1oh.f[11]++;
        cov_1idncka1oh.s[87]++;

        if (data < 0) {
          cov_1idncka1oh.b[27][0]++;
          cov_1idncka1oh.s[88]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_1idncka1oh.s[89]++;
          $scope.isloading = false;
          cov_1idncka1oh.s[90]++;
          return;
        } else {
          cov_1idncka1oh.b[27][1]++;
        }
        var type = (cov_1idncka1oh.s[91]++, $scope.tab_workflow);
        cov_1idncka1oh.s[92]++;
        (type == 1 ? (cov_1idncka1oh.b[28][0]++, $scope.planification.periodic) : (cov_1idncka1oh.b[28][1]++, type == 2 ? (cov_1idncka1oh.b[29][0]++, $scope.planification.annual) : (cov_1idncka1oh.b[29][1]++, type == 3 ? (cov_1idncka1oh.b[30][0]++, $scope.planification.global) : (cov_1idncka1oh.b[30][1]++, $scope.realisation)))).getAll();
      });
    });
  };
  cov_1idncka1oh.s[93]++;
  $scope.editStep = function (item) {
    cov_1idncka1oh.f[12]++;
    cov_1idncka1oh.s[94]++;

    $scope.selectedStep = angular.copy(item);
    cov_1idncka1oh.s[95]++;
    $scope.listFilterUser = [];
    var type = (cov_1idncka1oh.s[96]++, $scope.tab_workflow);
    var listUser = (cov_1idncka1oh.s[97]++, (type == 1 ? (cov_1idncka1oh.b[31][0]++, $scope.planification.periodic) : (cov_1idncka1oh.b[31][1]++, type == 2 ? (cov_1idncka1oh.b[32][0]++, $scope.planification.annual) : (cov_1idncka1oh.b[32][1]++, type == 3 ? (cov_1idncka1oh.b[33][0]++, $scope.planification.global) : (cov_1idncka1oh.b[33][1]++, $scope.realisation)))).workflow.LIST_STEP);
    cov_1idncka1oh.s[98]++;
    var _iteratorNormalCompletion4 = true;
    var _didIteratorError4 = false;
    var _iteratorError4 = undefined;

    try {
      for (var _iterator4 = $scope.listUser[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
        var user = _step4.value;

        var items = (cov_1idncka1oh.s[99]++, $filter('filter')(listUser, { IDUSER: user.id }, true));
        cov_1idncka1oh.s[100]++;
        if ((cov_1idncka1oh.b[35][0]++, items.length > 0) && (cov_1idncka1oh.b[35][1]++, items[0].id != item.id)) {
          cov_1idncka1oh.b[34][0]++;
          cov_1idncka1oh.s[101]++;

          continue;
        } else {
          cov_1idncka1oh.b[34][1]++;
        }
        cov_1idncka1oh.s[102]++;
        $scope.listFilterUser.push(user);
      }
    } catch (err) {
      _didIteratorError4 = true;
      _iteratorError4 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion4 && _iterator4.return) {
          _iterator4.return();
        }
      } finally {
        if (_didIteratorError4) {
          throw _iteratorError4;
        }
      }
    }

    var u = (cov_1idncka1oh.s[103]++, $filter('filter')($scope.listUser, { id: $scope.selectedStep.IDUSER }, true));
    cov_1idncka1oh.s[104]++;
    if (u.length > 0) {
      cov_1idncka1oh.b[36][0]++;
      cov_1idncka1oh.s[105]++;

      $scope.selectedStep.IDUSER = u[0];
    } else {
      cov_1idncka1oh.b[36][1]++;
      cov_1idncka1oh.s[106]++;

      $scope.selectedStep.IDUSER = $scope.listUser[$scope.listUser.length - 1];
    }

    cov_1idncka1oh.s[107]++;
    $uibModal.open({
      templateUrl: 'app/views/activities/workflow/edit.html',
      controller: activitiesWorkflowCtrl_edit,
      scope: $scope,
      backdrop: 'static',
      size: 'sm_',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_1idncka1oh.s[108]++;
  $scope.planification = {
    loading: true,
    periodic: {
      getAll: function getAll() {
        cov_1idncka1oh.s[109]++;

        this.loading = false;
        cov_1idncka1oh.s[110]++;
        $CRUDService.getAll(PATHWORKFLOW, { get: 'a_period', id: $stateParams.id }, function (data) {
          cov_1idncka1oh.f[13]++;
          cov_1idncka1oh.s[111]++;

          if (data == 'null') {
            cov_1idncka1oh.b[37][0]++;

            var workflow = (cov_1idncka1oh.s[112]++, {
              NAME: '',
              TYPE: '1',
              COLOR: '0, 0, 0',
              ID_REFERENCE: $stateParams.id
            });
            cov_1idncka1oh.s[113]++;
            $CRUDService.save(PATHWORKFLOW, { action: 'MiseAJour', valeur: angular.toJson(workflow) }, function (data_) {
              cov_1idncka1oh.f[14]++;
              cov_1idncka1oh.s[114]++;

              workflow.id = data_;
              cov_1idncka1oh.s[115]++;
              workflow.LIST_STEP = [];
              cov_1idncka1oh.s[116]++;
              $scope.planification.periodic.workflow = workflow;
              cov_1idncka1oh.s[117]++;
              $scope.planification.periodic.loading = false;
            });
          } else {
            cov_1idncka1oh.b[37][1]++;
            cov_1idncka1oh.s[118]++;

            $scope.planification.periodic.workflow = data;
            cov_1idncka1oh.s[119]++;
            $scope.planification.periodic.loading = false;
          }
        });
      }
    },
    annual: {
      loading: true,
      getAll: function getAll() {
        cov_1idncka1oh.s[120]++;

        this.loading = false;
        cov_1idncka1oh.s[121]++;
        $CRUDService.getAll(PATHWORKFLOW, { get: 'a_annual', id: $stateParams.id }, function (data) {
          cov_1idncka1oh.f[15]++;
          cov_1idncka1oh.s[122]++;

          if (data == 'null') {
            cov_1idncka1oh.b[38][0]++;

            var workflow = (cov_1idncka1oh.s[123]++, {
              NAME: '',
              TYPE: '2',
              ID_REFERENCE: $stateParams.id
            });
            cov_1idncka1oh.s[124]++;
            $CRUDService.save(PATHWORKFLOW, { action: 'MiseAJour', valeur: angular.toJson(workflow) }, function (data_) {
              cov_1idncka1oh.f[16]++;
              cov_1idncka1oh.s[125]++;

              workflow.id = data_;
              cov_1idncka1oh.s[126]++;
              workflow.LIST_STEP = [];
              cov_1idncka1oh.s[127]++;
              $scope.planification.annual.workflow = workflow;
              cov_1idncka1oh.s[128]++;
              $scope.planification.annual.loading = false;
            });
          } else {
            cov_1idncka1oh.b[38][1]++;
            cov_1idncka1oh.s[129]++;

            $scope.planification.annual.workflow = data;
            cov_1idncka1oh.s[130]++;
            $scope.planification.annual.loading = false;
          }
        });
      }
    },
    global: {
      loading: true,
      getAll: function getAll() {
        cov_1idncka1oh.s[131]++;

        this.loading = false;
        cov_1idncka1oh.s[132]++;
        $CRUDService.getAll(PATHWORKFLOW, { get: 'a_global', id: $stateParams.id }, function (data) {
          cov_1idncka1oh.f[17]++;
          cov_1idncka1oh.s[133]++;

          if (data == 'null') {
            cov_1idncka1oh.b[39][0]++;

            var workflow = (cov_1idncka1oh.s[134]++, {
              NAME: '',
              TYPE: '3',
              ID_REFERENCE: $stateParams.id
            });
            cov_1idncka1oh.s[135]++;
            $CRUDService.save(PATHWORKFLOW, { action: 'MiseAJour', valeur: angular.toJson(workflow) }, function (data_) {
              cov_1idncka1oh.f[18]++;
              cov_1idncka1oh.s[136]++;

              workflow.id = data_;
              cov_1idncka1oh.s[137]++;
              workflow.LIST_STEP = [];
              cov_1idncka1oh.s[138]++;
              $scope.planification.global.workflow = workflow;
              cov_1idncka1oh.s[139]++;
              $scope.planification.global.loading = false;
            });
          } else {
            cov_1idncka1oh.b[39][1]++;
            cov_1idncka1oh.s[140]++;

            $scope.planification.global.workflow = data;
            cov_1idncka1oh.s[141]++;
            $scope.planification.global.loading = false;
          }
        });
      }
    }
  };

  cov_1idncka1oh.s[142]++;
  $scope.realisation = {
    loading: true,
    getAll: function getAll() {
      cov_1idncka1oh.s[143]++;

      this.loading = false;
      cov_1idncka1oh.s[144]++;
      $CRUDService.getAll(PATHWORKFLOW, { get: 'a_real', id: $stateParams.id }, function (data) {
        cov_1idncka1oh.f[19]++;
        cov_1idncka1oh.s[145]++;

        if (data == 'null') {
          cov_1idncka1oh.b[40][0]++;

          var workflow = (cov_1idncka1oh.s[146]++, {
            NAME: '',
            TYPE: '4',
            ID_REFERENCE: $stateParams.id
          });
          cov_1idncka1oh.s[147]++;
          $CRUDService.save(PATHWORKFLOW, { action: 'MiseAJour', valeur: angular.toJson(workflow) }, function (data_) {
            cov_1idncka1oh.f[20]++;
            cov_1idncka1oh.s[148]++;

            workflow.id = data_;
            cov_1idncka1oh.s[149]++;
            workflow.LIST_STEP = [];
            cov_1idncka1oh.s[150]++;
            $scope.realisation.workflow = workflow;
            cov_1idncka1oh.s[151]++;
            $scope.realisation.loading = false;
          });
        } else {
          cov_1idncka1oh.b[40][1]++;
          cov_1idncka1oh.s[152]++;

          $scope.realisation.workflow = data;
          cov_1idncka1oh.s[153]++;
          $scope.realisation.loading = false;
        }
      });
    }
  };
});

function activitiesWorkflowCtrl_edit($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_1idncka1oh.f[21]++;
  cov_1idncka1oh.s[154]++;

  $scope.error_code = false;

  // $scope.listFilterUser.push({id: 0});

  var PATH = (cov_1idncka1oh.s[155]++, 'Workflow');
  cov_1idncka1oh.s[156]++;
  $scope.setValidData = function () {
    cov_1idncka1oh.f[22]++;

    var validateItem = (cov_1idncka1oh.s[157]++, angular.copy($scope.selectedStep));
    cov_1idncka1oh.s[158]++;
    validateItem.IDUSER = validateItem.IDUSER.id;
    cov_1idncka1oh.s[159]++;
    $CRUDService.save(PATH, { action: 'MiseAJourStep', valeur: $filter('json')(validateItem) }, function () {
      cov_1idncka1oh.f[23]++;
      cov_1idncka1oh.s[160]++;

      $scope.closeModal();
      var type = (cov_1idncka1oh.s[161]++, $scope.tab_workflow);
      cov_1idncka1oh.s[162]++;
      (type == 1 ? (cov_1idncka1oh.b[41][0]++, $scope.planification.periodic) : (cov_1idncka1oh.b[41][1]++, type == 2 ? (cov_1idncka1oh.b[42][0]++, $scope.planification.annual) : (cov_1idncka1oh.b[42][1]++, type == 3 ? (cov_1idncka1oh.b[43][0]++, $scope.planification.global) : (cov_1idncka1oh.b[43][1]++, $scope.realisation)))).getAll();
    });
  };
  cov_1idncka1oh.s[163]++;
  $scope.closeModal = function () {
    cov_1idncka1oh.f[24]++;
    cov_1idncka1oh.s[164]++;

    $uibModalInstance.dismiss('cancel');
  };
}