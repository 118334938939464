'use strict';

var cov_2p05yxmecw = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/index.js',
      hash = '73d125e45c3dfd9f6cc2ad6c63bab58873e73595',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/index.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 62,
          column: 5
        }
      },
      '1': {
        start: {
          line: 40,
          column: 4
        },
        end: {
          line: 40,
          column: 145
        }
      },
      '2': {
        start: {
          line: 42,
          column: 4
        },
        end: {
          line: 42,
          column: 47
        }
      },
      '3': {
        start: {
          line: 44,
          column: 4
        },
        end: {
          line: 44,
          column: 65
        }
      },
      '4': {
        start: {
          line: 47,
          column: 4
        },
        end: {
          line: 47,
          column: 45
        }
      },
      '5': {
        start: {
          line: 50,
          column: 4
        },
        end: {
          line: 50,
          column: 31
        }
      },
      '6': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 51,
          column: 29
        }
      },
      '7': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 52,
          column: 34
        }
      },
      '8': {
        start: {
          line: 54,
          column: 4
        },
        end: {
          line: 61,
          column: 7
        }
      },
      '9': {
        start: {
          line: 63,
          column: 0
        },
        end: {
          line: 89,
          column: 2
        }
      },
      '10': {
        start: {
          line: 64,
          column: 15
        },
        end: {
          line: 64,
          column: 19
        }
      },
      '11': {
        start: {
          line: 65,
          column: 2
        },
        end: {
          line: 81,
          column: 3
        }
      },
      '12': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 66,
          column: 24
        }
      },
      '13': {
        start: {
          line: 67,
          column: 9
        },
        end: {
          line: 81,
          column: 3
        }
      },
      '14': {
        start: {
          line: 68,
          column: 4
        },
        end: {
          line: 68,
          column: 26
        }
      },
      '15': {
        start: {
          line: 69,
          column: 9
        },
        end: {
          line: 81,
          column: 3
        }
      },
      '16': {
        start: {
          line: 70,
          column: 4
        },
        end: {
          line: 70,
          column: 30
        }
      },
      '17': {
        start: {
          line: 71,
          column: 9
        },
        end: {
          line: 81,
          column: 3
        }
      },
      '18': {
        start: {
          line: 72,
          column: 4
        },
        end: {
          line: 72,
          column: 34
        }
      },
      '19': {
        start: {
          line: 73,
          column: 9
        },
        end: {
          line: 81,
          column: 3
        }
      },
      '20': {
        start: {
          line: 74,
          column: 4
        },
        end: {
          line: 74,
          column: 38
        }
      },
      '21': {
        start: {
          line: 75,
          column: 9
        },
        end: {
          line: 81,
          column: 3
        }
      },
      '22': {
        start: {
          line: 76,
          column: 4
        },
        end: {
          line: 76,
          column: 42
        }
      },
      '23': {
        start: {
          line: 77,
          column: 9
        },
        end: {
          line: 81,
          column: 3
        }
      },
      '24': {
        start: {
          line: 78,
          column: 4
        },
        end: {
          line: 78,
          column: 46
        }
      },
      '25': {
        start: {
          line: 80,
          column: 4
        },
        end: {
          line: 80,
          column: 50
        }
      },
      '26': {
        start: {
          line: 83,
          column: 2
        },
        end: {
          line: 87,
          column: 3
        }
      },
      '27': {
        start: {
          line: 84,
          column: 4
        },
        end: {
          line: 86,
          column: 5
        }
      },
      '28': {
        start: {
          line: 85,
          column: 6
        },
        end: {
          line: 85,
          column: 80
        }
      },
      '29': {
        start: {
          line: 88,
          column: 2
        },
        end: {
          line: 88,
          column: 16
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 39,
            column: 41
          },
          end: {
            line: 39,
            column: 42
          }
        },
        loc: {
          start: {
            line: 39,
            column: 77
          },
          end: {
            line: 41,
            column: 3
          }
        },
        line: 39
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 41,
            column: 36
          },
          end: {
            line: 41,
            column: 37
          }
        },
        loc: {
          start: {
            line: 41,
            column: 66
          },
          end: {
            line: 45,
            column: 3
          }
        },
        line: 41
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 46,
            column: 31
          },
          end: {
            line: 46,
            column: 32
          }
        },
        loc: {
          start: {
            line: 46,
            column: 59
          },
          end: {
            line: 48,
            column: 3
          }
        },
        line: 46
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 48,
            column: 51
          },
          end: {
            line: 48,
            column: 52
          }
        },
        loc: {
          start: {
            line: 48,
            column: 88
          },
          end: {
            line: 53,
            column: 3
          }
        },
        line: 48
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 53,
            column: 13
          },
          end: {
            line: 53,
            column: 14
          }
        },
        loc: {
          start: {
            line: 53,
            column: 36
          },
          end: {
            line: 62,
            column: 3
          }
        },
        line: 53
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 63,
            column: 15
          },
          end: {
            line: 63,
            column: 16
          }
        },
        loc: {
          start: {
            line: 63,
            column: 53
          },
          end: {
            line: 89,
            column: 1
          }
        },
        line: 63
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 65,
            column: 2
          },
          end: {
            line: 81,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 65,
            column: 2
          },
          end: {
            line: 81,
            column: 3
          }
        }, {
          start: {
            line: 65,
            column: 2
          },
          end: {
            line: 81,
            column: 3
          }
        }],
        line: 65
      },
      '1': {
        loc: {
          start: {
            line: 65,
            column: 6
          },
          end: {
            line: 65,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 65,
            column: 6
          },
          end: {
            line: 65,
            column: 29
          }
        }, {
          start: {
            line: 65,
            column: 33
          },
          end: {
            line: 65,
            column: 43
          }
        }],
        line: 65
      },
      '2': {
        loc: {
          start: {
            line: 67,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        }, {
          start: {
            line: 67,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        }],
        line: 67
      },
      '3': {
        loc: {
          start: {
            line: 67,
            column: 13
          },
          end: {
            line: 67,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 67,
            column: 13
          },
          end: {
            line: 67,
            column: 36
          }
        }, {
          start: {
            line: 67,
            column: 40
          },
          end: {
            line: 67,
            column: 50
          }
        }],
        line: 67
      },
      '4': {
        loc: {
          start: {
            line: 69,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 69,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        }, {
          start: {
            line: 69,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        }],
        line: 69
      },
      '5': {
        loc: {
          start: {
            line: 69,
            column: 13
          },
          end: {
            line: 69,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 69,
            column: 13
          },
          end: {
            line: 69,
            column: 36
          }
        }, {
          start: {
            line: 69,
            column: 40
          },
          end: {
            line: 69,
            column: 50
          }
        }],
        line: 69
      },
      '6': {
        loc: {
          start: {
            line: 71,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 71,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        }, {
          start: {
            line: 71,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        }],
        line: 71
      },
      '7': {
        loc: {
          start: {
            line: 71,
            column: 13
          },
          end: {
            line: 71,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 71,
            column: 13
          },
          end: {
            line: 71,
            column: 36
          }
        }, {
          start: {
            line: 71,
            column: 40
          },
          end: {
            line: 71,
            column: 50
          }
        }],
        line: 71
      },
      '8': {
        loc: {
          start: {
            line: 73,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 73,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        }, {
          start: {
            line: 73,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        }],
        line: 73
      },
      '9': {
        loc: {
          start: {
            line: 73,
            column: 13
          },
          end: {
            line: 73,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 73,
            column: 13
          },
          end: {
            line: 73,
            column: 36
          }
        }, {
          start: {
            line: 73,
            column: 40
          },
          end: {
            line: 73,
            column: 50
          }
        }],
        line: 73
      },
      '10': {
        loc: {
          start: {
            line: 75,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 75,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        }, {
          start: {
            line: 75,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        }],
        line: 75
      },
      '11': {
        loc: {
          start: {
            line: 75,
            column: 13
          },
          end: {
            line: 75,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 75,
            column: 13
          },
          end: {
            line: 75,
            column: 36
          }
        }, {
          start: {
            line: 75,
            column: 40
          },
          end: {
            line: 75,
            column: 50
          }
        }],
        line: 75
      },
      '12': {
        loc: {
          start: {
            line: 77,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 77,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        }, {
          start: {
            line: 77,
            column: 9
          },
          end: {
            line: 81,
            column: 3
          }
        }],
        line: 77
      },
      '13': {
        loc: {
          start: {
            line: 77,
            column: 13
          },
          end: {
            line: 77,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 77,
            column: 13
          },
          end: {
            line: 77,
            column: 36
          }
        }, {
          start: {
            line: 77,
            column: 40
          },
          end: {
            line: 77,
            column: 50
          }
        }],
        line: 77
      },
      '14': {
        loc: {
          start: {
            line: 83,
            column: 2
          },
          end: {
            line: 87,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 83,
            column: 2
          },
          end: {
            line: 87,
            column: 3
          }
        }, {
          start: {
            line: 83,
            column: 2
          },
          end: {
            line: 87,
            column: 3
          }
        }],
        line: 83
      },
      '15': {
        loc: {
          start: {
            line: 84,
            column: 4
          },
          end: {
            line: 86,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 84,
            column: 4
          },
          end: {
            line: 86,
            column: 5
          }
        }, {
          start: {
            line: 84,
            column: 4
          },
          end: {
            line: 86,
            column: 5
          }
        }],
        line: 84
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2p05yxmecw.s[0]++;
// ej.base.registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFac11JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBjX39XcX1WT2daWEc=');
angular.module('app', ['ngSanitize', 'ngAnimate', 'ui.router', // Routing
'oc.lazyLoad', // ocLazyLoad
'ui.bootstrap', // Ui Bootstrap
'pascalprecht.translate', // translateProvider
'delaHttp', 'koboModule', 'deltadate', 'deltaNumber', 'angularFileUpload', 'delaStore', 'chart.js', 'tmh.dynamicLocale', 'oitozero.ngSweetAlert', 'ui.checkbox', 'deltacolorpicker.module',
// 'colorpicker.module',
'ui.select', 'localytics.directives', 'summernote', 'angular-peity', 'datePicker', 'dndLists', 'angular-flexslider', 'ui.calendar', 'angular-img-cropper', 'autoCompleteModule', 'gridster', 'angularMoment', 'ngIdle', 'angularjsToast', 'deltaLogframeTemplateModule', 'optionalIndicatorFieldModule', 'ngTagsInput']).config(['tmhDynamicLocaleProvider', function (tmhDynamicLocaleProvider) {
  cov_2p05yxmecw.f[0]++;
  cov_2p05yxmecw.s[1]++;

  tmhDynamicLocaleProvider.localeLocationPattern('https://cdnjs.cloudflare.com/ajax/libs/angular-i18n/1.6.8/angular-locale_{{locale}}.min.js');
}]).config(['$translateProvider', function ($translateProvider) {
  cov_2p05yxmecw.f[1]++;
  cov_2p05yxmecw.s[2]++;

  $translateProvider.preferredLanguage('en');
  // Enable escaping of HTML
  cov_2p05yxmecw.s[3]++;
  $translateProvider.useSanitizeValueStrategy('sceParameters');
}]).config(['$compileProvider', function ($compileProvider) {
  cov_2p05yxmecw.f[2]++;
  cov_2p05yxmecw.s[4]++;

  $compileProvider.debugInfoEnabled(false);
}]).config(['IdleProvider', 'KeepaliveProvider', function (IdleProvider, KeepaliveProvider) {
  cov_2p05yxmecw.f[3]++;
  cov_2p05yxmecw.s[5]++;

  // configure Idle settings
  IdleProvider.idle(15 * 60); // in seconds
  cov_2p05yxmecw.s[6]++;
  IdleProvider.timeout(10); // in seconds
  cov_2p05yxmecw.s[7]++;
  KeepaliveProvider.interval(2); // in seconds
}]).config(function ($ocLazyLoadProvider) {
  cov_2p05yxmecw.f[4]++;
  cov_2p05yxmecw.s[8]++;

  $ocLazyLoadProvider.config({
    modules: [{
      name: 'LogframeTemplate',
      file: ['src/app/shared/logframe_template/logFrameTemplate.js']
    }]
  });
});
cov_2p05yxmecw.s[9]++;
Date.newDate = function (p1, p2, p3, p4, p5, p6, p7) {
  cov_2p05yxmecw.f[5]++;

  var result = (cov_2p05yxmecw.s[10]++, null);
  cov_2p05yxmecw.s[11]++;
  if ((cov_2p05yxmecw.b[1][0]++, angular.isUndefined(p1)) || (cov_2p05yxmecw.b[1][1]++, p1 == null)) {
    cov_2p05yxmecw.b[0][0]++;
    cov_2p05yxmecw.s[12]++;

    result = new Date();
  } else {
      cov_2p05yxmecw.b[0][1]++;
      cov_2p05yxmecw.s[13]++;
      if ((cov_2p05yxmecw.b[3][0]++, angular.isUndefined(p2)) || (cov_2p05yxmecw.b[3][1]++, p2 == null)) {
        cov_2p05yxmecw.b[2][0]++;
        cov_2p05yxmecw.s[14]++;

        result = new Date(p1);
      } else {
          cov_2p05yxmecw.b[2][1]++;
          cov_2p05yxmecw.s[15]++;
          if ((cov_2p05yxmecw.b[5][0]++, angular.isUndefined(p3)) || (cov_2p05yxmecw.b[5][1]++, p3 == null)) {
            cov_2p05yxmecw.b[4][0]++;
            cov_2p05yxmecw.s[16]++;

            result = new Date(p1, p2);
          } else {
              cov_2p05yxmecw.b[4][1]++;
              cov_2p05yxmecw.s[17]++;
              if ((cov_2p05yxmecw.b[7][0]++, angular.isUndefined(p4)) || (cov_2p05yxmecw.b[7][1]++, p4 == null)) {
                cov_2p05yxmecw.b[6][0]++;
                cov_2p05yxmecw.s[18]++;

                result = new Date(p1, p2, p3);
              } else {
                  cov_2p05yxmecw.b[6][1]++;
                  cov_2p05yxmecw.s[19]++;
                  if ((cov_2p05yxmecw.b[9][0]++, angular.isUndefined(p5)) || (cov_2p05yxmecw.b[9][1]++, p5 == null)) {
                    cov_2p05yxmecw.b[8][0]++;
                    cov_2p05yxmecw.s[20]++;

                    result = new Date(p1, p2, p3, p4);
                  } else {
                      cov_2p05yxmecw.b[8][1]++;
                      cov_2p05yxmecw.s[21]++;
                      if ((cov_2p05yxmecw.b[11][0]++, angular.isUndefined(p6)) || (cov_2p05yxmecw.b[11][1]++, p6 == null)) {
                        cov_2p05yxmecw.b[10][0]++;
                        cov_2p05yxmecw.s[22]++;

                        result = new Date(p1, p2, p3, p4, p5);
                      } else {
                          cov_2p05yxmecw.b[10][1]++;
                          cov_2p05yxmecw.s[23]++;
                          if ((cov_2p05yxmecw.b[13][0]++, angular.isUndefined(p7)) || (cov_2p05yxmecw.b[13][1]++, p7 == null)) {
                            cov_2p05yxmecw.b[12][0]++;
                            cov_2p05yxmecw.s[24]++;

                            result = new Date(p1, p2, p3, p4, p5, p6);
                          } else {
                            cov_2p05yxmecw.b[12][1]++;
                            cov_2p05yxmecw.s[25]++;

                            result = new Date(p1, p2, p3, p4, p5, p6, p7);
                          }
                        }
                    }
                }
            }
        }
    }cov_2p05yxmecw.s[26]++;
  if (result) {
    cov_2p05yxmecw.b[14][0]++;
    cov_2p05yxmecw.s[27]++;

    if (result.getTimezoneOffset() > 0) {
      cov_2p05yxmecw.b[15][0]++;
      cov_2p05yxmecw.s[28]++;

      result.setMinutes(result.getMinutes() + result.getTimezoneOffset() + 120);
    } else {
      cov_2p05yxmecw.b[15][1]++;
    }
  } else {
    cov_2p05yxmecw.b[14][1]++;
  }
  cov_2p05yxmecw.s[29]++;
  return result;
};