'use strict';

var cov_z5l10t65r = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/modules/date.js',
      hash = '5cbeee012e29351f0bf614256bd6084a2277e195',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/modules/date.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 302,
          column: 4
        }
      },
      '1': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 4,
          column: 23
        }
      },
      '2': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 301,
          column: 4
        }
      },
      '3': {
        start: {
          line: 8,
          column: 27
        },
        end: {
          line: 8,
          column: 79
        }
      },
      '4': {
        start: {
          line: 9,
          column: 28
        },
        end: {
          line: 9,
          column: 82
        }
      },
      '5': {
        start: {
          line: 10,
          column: 6
        },
        end: {
          line: 12,
          column: 7
        }
      },
      '6': {
        start: {
          line: 11,
          column: 8
        },
        end: {
          line: 11,
          column: 42
        }
      },
      '7': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 13,
          column: 41
        }
      },
      '8': {
        start: {
          line: 16,
          column: 27
        },
        end: {
          line: 16,
          column: 79
        }
      },
      '9': {
        start: {
          line: 17,
          column: 28
        },
        end: {
          line: 17,
          column: 82
        }
      },
      '10': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 20,
          column: 7
        }
      },
      '11': {
        start: {
          line: 19,
          column: 8
        },
        end: {
          line: 19,
          column: 42
        }
      },
      '12': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 21,
          column: 41
        }
      },
      '13': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 27,
          column: 7
        }
      },
      '14': {
        start: {
          line: 26,
          column: 8
        },
        end: {
          line: 26,
          column: 17
        }
      },
      '15': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 28,
          column: 15
        }
      },
      '16': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 33,
          column: 7
        }
      },
      '17': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 17
        }
      },
      '18': {
        start: {
          line: 34,
          column: 6
        },
        end: {
          line: 34,
          column: 15
        }
      },
      '19': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 37,
          column: 31
        }
      },
      '20': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 40,
          column: 30
        }
      },
      '21': {
        start: {
          line: 43,
          column: 23
        },
        end: {
          line: 48,
          column: 7
        }
      },
      '22': {
        start: {
          line: 49,
          column: 23
        },
        end: {
          line: 54,
          column: 7
        }
      },
      '23': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 58,
          column: 8
        }
      },
      '24': {
        start: {
          line: 61,
          column: 18
        },
        end: {
          line: 61,
          column: 20
        }
      },
      '25': {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 64,
          column: 7
        }
      },
      '26': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 63,
          column: 20
        }
      },
      '27': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 84,
          column: 7
        }
      },
      '28': {
        start: {
          line: 66,
          column: 23
        },
        end: {
          line: 66,
          column: 52
        }
      },
      '29': {
        start: {
          line: 68,
          column: 8
        },
        end: {
          line: 70,
          column: 9
        }
      },
      '30': {
        start: {
          line: 69,
          column: 10
        },
        end: {
          line: 69,
          column: 22
        }
      },
      '31': {
        start: {
          line: 71,
          column: 8
        },
        end: {
          line: 73,
          column: 9
        }
      },
      '32': {
        start: {
          line: 72,
          column: 10
        },
        end: {
          line: 72,
          column: 44
        }
      },
      '33': {
        start: {
          line: 74,
          column: 8
        },
        end: {
          line: 76,
          column: 9
        }
      },
      '34': {
        start: {
          line: 75,
          column: 10
        },
        end: {
          line: 75,
          column: 44
        }
      },
      '35': {
        start: {
          line: 77,
          column: 8
        },
        end: {
          line: 81,
          column: 21
        }
      },
      '36': {
        start: {
          line: 83,
          column: 8
        },
        end: {
          line: 83,
          column: 32
        }
      },
      '37': {
        start: {
          line: 85,
          column: 17
        },
        end: {
          line: 85,
          column: 45
        }
      },
      '38': {
        start: {
          line: 86,
          column: 17
        },
        end: {
          line: 86,
          column: 45
        }
      },
      '39': {
        start: {
          line: 87,
          column: 19
        },
        end: {
          line: 87,
          column: 49
        }
      },
      '40': {
        start: {
          line: 89,
          column: 19
        },
        end: {
          line: 89,
          column: 25
        }
      },
      '41': {
        start: {
          line: 90,
          column: 6
        },
        end: {
          line: 90,
          column: 44
        }
      },
      '42': {
        start: {
          line: 91,
          column: 6
        },
        end: {
          line: 91,
          column: 44
        }
      },
      '43': {
        start: {
          line: 92,
          column: 6
        },
        end: {
          line: 92,
          column: 40
        }
      },
      '44': {
        start: {
          line: 93,
          column: 6
        },
        end: {
          line: 93,
          column: 40
        }
      },
      '45': {
        start: {
          line: 94,
          column: 6
        },
        end: {
          line: 94,
          column: 40
        }
      },
      '46': {
        start: {
          line: 95,
          column: 6
        },
        end: {
          line: 95,
          column: 40
        }
      },
      '47': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 96,
          column: 20
        }
      },
      '48': {
        start: {
          line: 99,
          column: 19
        },
        end: {
          line: 99,
          column: 118
        }
      },
      '49': {
        start: {
          line: 100,
          column: 6
        },
        end: {
          line: 100,
          column: 54
        }
      },
      '50': {
        start: {
          line: 104,
          column: 21
        },
        end: {
          line: 104,
          column: 23
        }
      },
      '51': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 110,
          column: 7
        }
      },
      '52': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 109,
          column: 11
        }
      },
      '53': {
        start: {
          line: 122,
          column: 6
        },
        end: {
          line: 122,
          column: 20
        }
      },
      '54': {
        start: {
          line: 125,
          column: 20
        },
        end: {
          line: 125,
          column: 63
        }
      },
      '55': {
        start: {
          line: 126,
          column: 6
        },
        end: {
          line: 128,
          column: 7
        }
      },
      '56': {
        start: {
          line: 127,
          column: 8
        },
        end: {
          line: 127,
          column: 21
        }
      },
      '57': {
        start: {
          line: 129,
          column: 6
        },
        end: {
          line: 129,
          column: 34
        }
      },
      '58': {
        start: {
          line: 132,
          column: 6
        },
        end: {
          line: 132,
          column: 53
        }
      },
      '59': {
        start: {
          line: 134,
          column: 20
        },
        end: {
          line: 134,
          column: 46
        }
      },
      '60': {
        start: {
          line: 135,
          column: 6
        },
        end: {
          line: 143,
          column: 7
        }
      },
      '61': {
        start: {
          line: 136,
          column: 16
        },
        end: {
          line: 136,
          column: 25
        }
      },
      '62': {
        start: {
          line: 137,
          column: 8
        },
        end: {
          line: 139,
          column: 9
        }
      },
      '63': {
        start: {
          line: 138,
          column: 10
        },
        end: {
          line: 138,
          column: 22
        }
      },
      '64': {
        start: {
          line: 140,
          column: 23
        },
        end: {
          line: 140,
          column: 56
        }
      },
      '65': {
        start: {
          line: 141,
          column: 8
        },
        end: {
          line: 141,
          column: 55
        }
      },
      '66': {
        start: {
          line: 142,
          column: 8
        },
        end: {
          line: 142,
          column: 73
        }
      },
      '67': {
        start: {
          line: 144,
          column: 6
        },
        end: {
          line: 144,
          column: 67
        }
      },
      '68': {
        start: {
          line: 147,
          column: 19
        },
        end: {
          line: 147,
          column: 21
        }
      },
      '69': {
        start: {
          line: 148,
          column: 23
        },
        end: {
          line: 148,
          column: 25
        }
      },
      '70': {
        start: {
          line: 149,
          column: 6
        },
        end: {
          line: 179,
          column: 7
        }
      },
      '71': {
        start: {
          line: 152,
          column: 10
        },
        end: {
          line: 152,
          column: 52
        }
      },
      '72': {
        start: {
          line: 153,
          column: 10
        },
        end: {
          line: 153,
          column: 62
        }
      },
      '73': {
        start: {
          line: 154,
          column: 10
        },
        end: {
          line: 154,
          column: 16
        }
      },
      '74': {
        start: {
          line: 157,
          column: 10
        },
        end: {
          line: 157,
          column: 54
        }
      },
      '75': {
        start: {
          line: 158,
          column: 10
        },
        end: {
          line: 158,
          column: 64
        }
      },
      '76': {
        start: {
          line: 160,
          column: 10
        },
        end: {
          line: 160,
          column: 16
        }
      },
      '77': {
        start: {
          line: 163,
          column: 10
        },
        end: {
          line: 163,
          column: 53
        }
      },
      '78': {
        start: {
          line: 164,
          column: 10
        },
        end: {
          line: 164,
          column: 63
        }
      },
      '79': {
        start: {
          line: 165,
          column: 10
        },
        end: {
          line: 165,
          column: 16
        }
      },
      '80': {
        start: {
          line: 168,
          column: 10
        },
        end: {
          line: 168,
          column: 52
        }
      },
      '81': {
        start: {
          line: 169,
          column: 10
        },
        end: {
          line: 169,
          column: 62
        }
      },
      '82': {
        start: {
          line: 170,
          column: 10
        },
        end: {
          line: 170,
          column: 16
        }
      },
      '83': {
        start: {
          line: 173,
          column: 10
        },
        end: {
          line: 173,
          column: 53
        }
      },
      '84': {
        start: {
          line: 174,
          column: 10
        },
        end: {
          line: 174,
          column: 63
        }
      },
      '85': {
        start: {
          line: 175,
          column: 10
        },
        end: {
          line: 175,
          column: 16
        }
      },
      '86': {
        start: {
          line: 177,
          column: 10
        },
        end: {
          line: 177,
          column: 53
        }
      },
      '87': {
        start: {
          line: 178,
          column: 10
        },
        end: {
          line: 178,
          column: 63
        }
      },
      '88': {
        start: {
          line: 180,
          column: 6
        },
        end: {
          line: 180,
          column: 43
        }
      },
      '89': {
        start: {
          line: 183,
          column: 18
        },
        end: {
          line: 183,
          column: 49
        }
      },
      '90': {
        start: {
          line: 184,
          column: 6
        },
        end: {
          line: 186,
          column: 7
        }
      },
      '91': {
        start: {
          line: 185,
          column: 8
        },
        end: {
          line: 185,
          column: 28
        }
      },
      '92': {
        start: {
          line: 187,
          column: 6
        },
        end: {
          line: 187,
          column: 49
        }
      },
      '93': {
        start: {
          line: 190,
          column: 18
        },
        end: {
          line: 190,
          column: 49
        }
      },
      '94': {
        start: {
          line: 191,
          column: 6
        },
        end: {
          line: 193,
          column: 7
        }
      },
      '95': {
        start: {
          line: 192,
          column: 8
        },
        end: {
          line: 192,
          column: 28
        }
      },
      '96': {
        start: {
          line: 194,
          column: 16
        },
        end: {
          line: 194,
          column: 51
        }
      },
      '97': {
        start: {
          line: 195,
          column: 6
        },
        end: {
          line: 195,
          column: 36
        }
      },
      '98': {
        start: {
          line: 196,
          column: 6
        },
        end: {
          line: 196,
          column: 19
        }
      },
      '99': {
        start: {
          line: 197,
          column: 6
        },
        end: {
          line: 197,
          column: 15
        }
      },
      '100': {
        start: {
          line: 200,
          column: 18
        },
        end: {
          line: 200,
          column: 49
        }
      },
      '101': {
        start: {
          line: 201,
          column: 6
        },
        end: {
          line: 203,
          column: 7
        }
      },
      '102': {
        start: {
          line: 202,
          column: 8
        },
        end: {
          line: 202,
          column: 28
        }
      },
      '103': {
        start: {
          line: 204,
          column: 24
        },
        end: {
          line: 204,
          column: 64
        }
      },
      '104': {
        start: {
          line: 205,
          column: 22
        },
        end: {
          line: 205,
          column: 62
        }
      },
      '105': {
        start: {
          line: 207,
          column: 6
        },
        end: {
          line: 207,
          column: 48
        }
      },
      '106': {
        start: {
          line: 208,
          column: 6
        },
        end: {
          line: 208,
          column: 25
        }
      },
      '107': {
        start: {
          line: 210,
          column: 6
        },
        end: {
          line: 210,
          column: 72
        }
      },
      '108': {
        start: {
          line: 213,
          column: 20
        },
        end: {
          line: 213,
          column: 80
        }
      },
      '109': {
        start: {
          line: 214,
          column: 6
        },
        end: {
          line: 214,
          column: 57
        }
      },
      '110': {
        start: {
          line: 215,
          column: 6
        },
        end: {
          line: 217,
          column: 7
        }
      },
      '111': {
        start: {
          line: 216,
          column: 8
        },
        end: {
          line: 216,
          column: 51
        }
      },
      '112': {
        start: {
          line: 219,
          column: 6
        },
        end: {
          line: 219,
          column: 44
        }
      },
      '113': {
        start: {
          line: 220,
          column: 6
        },
        end: {
          line: 220,
          column: 82
        }
      },
      '114': {
        start: {
          line: 223,
          column: 6
        },
        end: {
          line: 223,
          column: 53
        }
      },
      '115': {
        start: {
          line: 228,
          column: 6
        },
        end: {
          line: 230,
          column: 7
        }
      },
      '116': {
        start: {
          line: 229,
          column: 8
        },
        end: {
          line: 229,
          column: 18
        }
      },
      '117': {
        start: {
          line: 231,
          column: 20
        },
        end: {
          line: 231,
          column: 22
        }
      },
      '118': {
        start: {
          line: 233,
          column: 18
        },
        end: {
          line: 233,
          column: 116
        }
      },
      '119': {
        start: {
          line: 239,
          column: 18
        },
        end: {
          line: 239,
          column: 19
        }
      },
      '120': {
        start: {
          line: 240,
          column: 24
        },
        end: {
          line: 240,
          column: 25
        }
      },
      '121': {
        start: {
          line: 241,
          column: 14
        },
        end: {
          line: 241,
          column: 15
        }
      },
      '122': {
        start: {
          line: 242,
          column: 25
        },
        end: {
          line: 242,
          column: 36
        }
      },
      '123': {
        start: {
          line: 244,
          column: 22
        },
        end: {
          line: 244,
          column: 68
        }
      },
      '124': {
        start: {
          line: 245,
          column: 21
        },
        end: {
          line: 245,
          column: 66
        }
      },
      '125': {
        start: {
          line: 246,
          column: 6
        },
        end: {
          line: 292,
          column: 7
        }
      },
      '126': {
        start: {
          line: 247,
          column: 8
        },
        end: {
          line: 247,
          column: 12
        }
      },
      '127': {
        start: {
          line: 248,
          column: 8
        },
        end: {
          line: 250,
          column: 9
        }
      },
      '128': {
        start: {
          line: 249,
          column: 10
        },
        end: {
          line: 249,
          column: 20
        }
      },
      '129': {
        start: {
          line: 255,
          column: 8
        },
        end: {
          line: 258,
          column: 9
        }
      },
      '130': {
        start: {
          line: 256,
          column: 10
        },
        end: {
          line: 256,
          column: 20
        }
      },
      '131': {
        start: {
          line: 257,
          column: 10
        },
        end: {
          line: 257,
          column: 44
        }
      },
      '132': {
        start: {
          line: 259,
          column: 8
        },
        end: {
          line: 259,
          column: 16
        }
      },
      '133': {
        start: {
          line: 260,
          column: 8
        },
        end: {
          line: 262,
          column: 9
        }
      },
      '134': {
        start: {
          line: 261,
          column: 10
        },
        end: {
          line: 261,
          column: 16
        }
      },
      '135': {
        start: {
          line: 268,
          column: 20
        },
        end: {
          line: 268,
          column: 88
        }
      },
      '136': {
        start: {
          line: 269,
          column: 8
        },
        end: {
          line: 288,
          column: 9
        }
      },
      '137': {
        start: {
          line: 270,
          column: 25
        },
        end: {
          line: 270,
          column: 62
        }
      },
      '138': {
        start: {
          line: 271,
          column: 10
        },
        end: {
          line: 273,
          column: 11
        }
      },
      '139': {
        start: {
          line: 272,
          column: 12
        },
        end: {
          line: 272,
          column: 83
        }
      },
      '140': {
        start: {
          line: 274,
          column: 10
        },
        end: {
          line: 276,
          column: 11
        }
      },
      '141': {
        start: {
          line: 275,
          column: 12
        },
        end: {
          line: 275,
          column: 54
        }
      },
      '142': {
        start: {
          line: 277,
          column: 29
        },
        end: {
          line: 277,
          column: 37
        }
      },
      '143': {
        start: {
          line: 278,
          column: 10
        },
        end: {
          line: 278,
          column: 72
        }
      },
      '144': {
        start: {
          line: 279,
          column: 10
        },
        end: {
          line: 287,
          column: 13
        }
      },
      '145': {
        start: {
          line: 289,
          column: 8
        },
        end: {
          line: 289,
          column: 85
        }
      },
      '146': {
        start: {
          line: 293,
          column: 6
        },
        end: {
          line: 293,
          column: 19
        }
      },
      '147': {
        start: {
          line: 296,
          column: 6
        },
        end: {
          line: 298,
          column: 7
        }
      },
      '148': {
        start: {
          line: 297,
          column: 8
        },
        end: {
          line: 297,
          column: 20
        }
      },
      '149': {
        start: {
          line: 299,
          column: 6
        },
        end: {
          line: 299,
          column: 29
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 77
          },
          end: {
            line: 3,
            column: 78
          }
        },
        loc: {
          start: {
            line: 3,
            column: 130
          },
          end: {
            line: 302,
            column: 1
          }
        },
        line: 3
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 8,
            column: 39
          },
          end: {
            line: 8,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 8,
            column: 39
          },
          end: {
            line: 8,
            column: 51
          }
        }, {
          start: {
            line: 8,
            column: 55
          },
          end: {
            line: 8,
            column: 64
          }
        }],
        line: 8
      },
      '1': {
        loc: {
          start: {
            line: 9,
            column: 40
          },
          end: {
            line: 9,
            column: 67
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 9,
            column: 40
          },
          end: {
            line: 9,
            column: 53
          }
        }, {
          start: {
            line: 9,
            column: 57
          },
          end: {
            line: 9,
            column: 67
          }
        }],
        line: 9
      },
      '2': {
        loc: {
          start: {
            line: 10,
            column: 6
          },
          end: {
            line: 12,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 10,
            column: 6
          },
          end: {
            line: 12,
            column: 7
          }
        }, {
          start: {
            line: 10,
            column: 6
          },
          end: {
            line: 12,
            column: 7
          }
        }],
        line: 10
      },
      '3': {
        loc: {
          start: {
            line: 16,
            column: 39
          },
          end: {
            line: 16,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 16,
            column: 39
          },
          end: {
            line: 16,
            column: 51
          }
        }, {
          start: {
            line: 16,
            column: 55
          },
          end: {
            line: 16,
            column: 64
          }
        }],
        line: 16
      },
      '4': {
        loc: {
          start: {
            line: 17,
            column: 40
          },
          end: {
            line: 17,
            column: 67
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 17,
            column: 40
          },
          end: {
            line: 17,
            column: 53
          }
        }, {
          start: {
            line: 17,
            column: 57
          },
          end: {
            line: 17,
            column: 67
          }
        }],
        line: 17
      },
      '5': {
        loc: {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        }, {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        }],
        line: 18
      },
      '6': {
        loc: {
          start: {
            line: 25,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 25,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        }, {
          start: {
            line: 25,
            column: 6
          },
          end: {
            line: 27,
            column: 7
          }
        }],
        line: 25
      },
      '7': {
        loc: {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }, {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }],
        line: 31
      },
      '8': {
        loc: {
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        }, {
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        }],
        line: 62
      },
      '9': {
        loc: {
          start: {
            line: 65,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 65,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        }, {
          start: {
            line: 65,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        }],
        line: 65
      },
      '10': {
        loc: {
          start: {
            line: 68,
            column: 8
          },
          end: {
            line: 70,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 68,
            column: 8
          },
          end: {
            line: 70,
            column: 9
          }
        }, {
          start: {
            line: 68,
            column: 8
          },
          end: {
            line: 70,
            column: 9
          }
        }],
        line: 68
      },
      '11': {
        loc: {
          start: {
            line: 71,
            column: 8
          },
          end: {
            line: 73,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 71,
            column: 8
          },
          end: {
            line: 73,
            column: 9
          }
        }, {
          start: {
            line: 71,
            column: 8
          },
          end: {
            line: 73,
            column: 9
          }
        }],
        line: 71
      },
      '12': {
        loc: {
          start: {
            line: 74,
            column: 8
          },
          end: {
            line: 76,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 8
          },
          end: {
            line: 76,
            column: 9
          }
        }, {
          start: {
            line: 74,
            column: 8
          },
          end: {
            line: 76,
            column: 9
          }
        }],
        line: 74
      },
      '13': {
        loc: {
          start: {
            line: 79,
            column: 10
          },
          end: {
            line: 79,
            column: 59
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 79,
            column: 38
          },
          end: {
            line: 79,
            column: 47
          }
        }, {
          start: {
            line: 79,
            column: 50
          },
          end: {
            line: 79,
            column: 59
          }
        }],
        line: 79
      },
      '14': {
        loc: {
          start: {
            line: 80,
            column: 10
          },
          end: {
            line: 80,
            column: 59
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 80,
            column: 38
          },
          end: {
            line: 80,
            column: 47
          }
        }, {
          start: {
            line: 80,
            column: 50
          },
          end: {
            line: 80,
            column: 59
          }
        }],
        line: 80
      },
      '15': {
        loc: {
          start: {
            line: 83,
            column: 16
          },
          end: {
            line: 83,
            column: 31
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 83,
            column: 16
          },
          end: {
            line: 83,
            column: 23
          }
        }, {
          start: {
            line: 83,
            column: 27
          },
          end: {
            line: 83,
            column: 31
          }
        }],
        line: 83
      },
      '16': {
        loc: {
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 128,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 128,
            column: 7
          }
        }, {
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 128,
            column: 7
          }
        }],
        line: 126
      },
      '17': {
        loc: {
          start: {
            line: 132,
            column: 18
          },
          end: {
            line: 132,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 132,
            column: 18
          },
          end: {
            line: 132,
            column: 27
          }
        }, {
          start: {
            line: 132,
            column: 31
          },
          end: {
            line: 132,
            column: 47
          }
        }, {
          start: {
            line: 132,
            column: 51
          },
          end: {
            line: 132,
            column: 52
          }
        }],
        line: 132
      },
      '18': {
        loc: {
          start: {
            line: 134,
            column: 20
          },
          end: {
            line: 134,
            column: 46
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 134,
            column: 28
          },
          end: {
            line: 134,
            column: 40
          }
        }, {
          start: {
            line: 134,
            column: 44
          },
          end: {
            line: 134,
            column: 46
          }
        }],
        line: 134
      },
      '19': {
        loc: {
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 143,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 143,
            column: 7
          }
        }, {
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 143,
            column: 7
          }
        }],
        line: 135
      },
      '20': {
        loc: {
          start: {
            line: 137,
            column: 8
          },
          end: {
            line: 139,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 137,
            column: 8
          },
          end: {
            line: 139,
            column: 9
          }
        }, {
          start: {
            line: 137,
            column: 8
          },
          end: {
            line: 139,
            column: 9
          }
        }],
        line: 137
      },
      '21': {
        loc: {
          start: {
            line: 142,
            column: 39
          },
          end: {
            line: 142,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 142,
            column: 49
          },
          end: {
            line: 142,
            column: 54
          }
        }, {
          start: {
            line: 142,
            column: 57
          },
          end: {
            line: 142,
            column: 63
          }
        }],
        line: 142
      },
      '22': {
        loc: {
          start: {
            line: 149,
            column: 6
          },
          end: {
            line: 179,
            column: 7
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 150,
            column: 8
          },
          end: {
            line: 150,
            column: 17
          }
        }, {
          start: {
            line: 151,
            column: 8
          },
          end: {
            line: 154,
            column: 16
          }
        }, {
          start: {
            line: 155,
            column: 8
          },
          end: {
            line: 155,
            column: 15
          }
        }, {
          start: {
            line: 156,
            column: 8
          },
          end: {
            line: 160,
            column: 16
          }
        }, {
          start: {
            line: 161,
            column: 8
          },
          end: {
            line: 161,
            column: 15
          }
        }, {
          start: {
            line: 162,
            column: 8
          },
          end: {
            line: 165,
            column: 16
          }
        }, {
          start: {
            line: 166,
            column: 8
          },
          end: {
            line: 166,
            column: 15
          }
        }, {
          start: {
            line: 167,
            column: 8
          },
          end: {
            line: 170,
            column: 16
          }
        }, {
          start: {
            line: 171,
            column: 8
          },
          end: {
            line: 171,
            column: 16
          }
        }, {
          start: {
            line: 172,
            column: 8
          },
          end: {
            line: 175,
            column: 16
          }
        }, {
          start: {
            line: 176,
            column: 8
          },
          end: {
            line: 178,
            column: 63
          }
        }],
        line: 149
      },
      '23': {
        loc: {
          start: {
            line: 180,
            column: 13
          },
          end: {
            line: 180,
            column: 42
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 180,
            column: 23
          },
          end: {
            line: 180,
            column: 33
          }
        }, {
          start: {
            line: 180,
            column: 36
          },
          end: {
            line: 180,
            column: 42
          }
        }],
        line: 180
      },
      '24': {
        loc: {
          start: {
            line: 183,
            column: 18
          },
          end: {
            line: 183,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 183,
            column: 18
          },
          end: {
            line: 183,
            column: 24
          }
        }, {
          start: {
            line: 183,
            column: 28
          },
          end: {
            line: 183,
            column: 44
          }
        }, {
          start: {
            line: 183,
            column: 48
          },
          end: {
            line: 183,
            column: 49
          }
        }],
        line: 183
      },
      '25': {
        loc: {
          start: {
            line: 184,
            column: 6
          },
          end: {
            line: 186,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 184,
            column: 6
          },
          end: {
            line: 186,
            column: 7
          }
        }, {
          start: {
            line: 184,
            column: 6
          },
          end: {
            line: 186,
            column: 7
          }
        }],
        line: 184
      },
      '26': {
        loc: {
          start: {
            line: 190,
            column: 18
          },
          end: {
            line: 190,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 190,
            column: 18
          },
          end: {
            line: 190,
            column: 24
          }
        }, {
          start: {
            line: 190,
            column: 28
          },
          end: {
            line: 190,
            column: 44
          }
        }, {
          start: {
            line: 190,
            column: 48
          },
          end: {
            line: 190,
            column: 49
          }
        }],
        line: 190
      },
      '27': {
        loc: {
          start: {
            line: 191,
            column: 6
          },
          end: {
            line: 193,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 191,
            column: 6
          },
          end: {
            line: 193,
            column: 7
          }
        }, {
          start: {
            line: 191,
            column: 6
          },
          end: {
            line: 193,
            column: 7
          }
        }],
        line: 191
      },
      '28': {
        loc: {
          start: {
            line: 200,
            column: 18
          },
          end: {
            line: 200,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 200,
            column: 18
          },
          end: {
            line: 200,
            column: 24
          }
        }, {
          start: {
            line: 200,
            column: 28
          },
          end: {
            line: 200,
            column: 44
          }
        }, {
          start: {
            line: 200,
            column: 48
          },
          end: {
            line: 200,
            column: 49
          }
        }],
        line: 200
      },
      '29': {
        loc: {
          start: {
            line: 201,
            column: 6
          },
          end: {
            line: 203,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 201,
            column: 6
          },
          end: {
            line: 203,
            column: 7
          }
        }, {
          start: {
            line: 201,
            column: 6
          },
          end: {
            line: 203,
            column: 7
          }
        }],
        line: 201
      },
      '30': {
        loc: {
          start: {
            line: 213,
            column: 35
          },
          end: {
            line: 213,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 213,
            column: 35
          },
          end: {
            line: 213,
            column: 43
          }
        }, {
          start: {
            line: 213,
            column: 47
          },
          end: {
            line: 213,
            column: 52
          }
        }],
        line: 213
      },
      '31': {
        loc: {
          start: {
            line: 215,
            column: 6
          },
          end: {
            line: 217,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 215,
            column: 6
          },
          end: {
            line: 217,
            column: 7
          }
        }, {
          start: {
            line: 215,
            column: 6
          },
          end: {
            line: 217,
            column: 7
          }
        }],
        line: 215
      },
      '32': {
        loc: {
          start: {
            line: 223,
            column: 18
          },
          end: {
            line: 223,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 223,
            column: 18
          },
          end: {
            line: 223,
            column: 27
          }
        }, {
          start: {
            line: 223,
            column: 31
          },
          end: {
            line: 223,
            column: 47
          }
        }, {
          start: {
            line: 223,
            column: 51
          },
          end: {
            line: 223,
            column: 52
          }
        }],
        line: 223
      },
      '33': {
        loc: {
          start: {
            line: 228,
            column: 6
          },
          end: {
            line: 230,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 228,
            column: 6
          },
          end: {
            line: 230,
            column: 7
          }
        }, {
          start: {
            line: 228,
            column: 6
          },
          end: {
            line: 230,
            column: 7
          }
        }],
        line: 228
      },
      '34': {
        loc: {
          start: {
            line: 233,
            column: 32
          },
          end: {
            line: 233,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 233,
            column: 32
          },
          end: {
            line: 233,
            column: 40
          }
        }, {
          start: {
            line: 233,
            column: 44
          },
          end: {
            line: 233,
            column: 49
          }
        }],
        line: 233
      },
      '35': {
        loc: {
          start: {
            line: 233,
            column: 68
          },
          end: {
            line: 233,
            column: 90
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 233,
            column: 84
          },
          end: {
            line: 233,
            column: 86
          }
        }, {
          start: {
            line: 233,
            column: 89
          },
          end: {
            line: 233,
            column: 90
          }
        }],
        line: 233
      },
      '36': {
        loc: {
          start: {
            line: 245,
            column: 49
          },
          end: {
            line: 245,
            column: 65
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 245,
            column: 49
          },
          end: {
            line: 245,
            column: 54
          }
        }, {
          start: {
            line: 245,
            column: 58
          },
          end: {
            line: 245,
            column: 65
          }
        }],
        line: 245
      },
      '37': {
        loc: {
          start: {
            line: 248,
            column: 8
          },
          end: {
            line: 250,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 248,
            column: 8
          },
          end: {
            line: 250,
            column: 9
          }
        }, {
          start: {
            line: 248,
            column: 8
          },
          end: {
            line: 250,
            column: 9
          }
        }],
        line: 248
      },
      '38': {
        loc: {
          start: {
            line: 255,
            column: 8
          },
          end: {
            line: 258,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 255,
            column: 8
          },
          end: {
            line: 258,
            column: 9
          }
        }, {
          start: {
            line: 255,
            column: 8
          },
          end: {
            line: 258,
            column: 9
          }
        }],
        line: 255
      },
      '39': {
        loc: {
          start: {
            line: 260,
            column: 8
          },
          end: {
            line: 262,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 260,
            column: 8
          },
          end: {
            line: 262,
            column: 9
          }
        }, {
          start: {
            line: 260,
            column: 8
          },
          end: {
            line: 262,
            column: 9
          }
        }],
        line: 260
      },
      '40': {
        loc: {
          start: {
            line: 269,
            column: 8
          },
          end: {
            line: 288,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 269,
            column: 8
          },
          end: {
            line: 288,
            column: 9
          }
        }, {
          start: {
            line: 269,
            column: 8
          },
          end: {
            line: 288,
            column: 9
          }
        }],
        line: 269
      },
      '41': {
        loc: {
          start: {
            line: 270,
            column: 35
          },
          end: {
            line: 270,
            column: 53
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 270,
            column: 45
          },
          end: {
            line: 270,
            column: 47
          }
        }, {
          start: {
            line: 270,
            column: 50
          },
          end: {
            line: 270,
            column: 53
          }
        }],
        line: 270
      },
      '42': {
        loc: {
          start: {
            line: 271,
            column: 10
          },
          end: {
            line: 273,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 271,
            column: 10
          },
          end: {
            line: 273,
            column: 11
          }
        }, {
          start: {
            line: 271,
            column: 10
          },
          end: {
            line: 273,
            column: 11
          }
        }],
        line: 271
      },
      '43': {
        loc: {
          start: {
            line: 272,
            column: 46
          },
          end: {
            line: 272,
            column: 81
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 272,
            column: 67
          },
          end: {
            line: 272,
            column: 72
          }
        }, {
          start: {
            line: 272,
            column: 75
          },
          end: {
            line: 272,
            column: 81
          }
        }],
        line: 272
      },
      '44': {
        loc: {
          start: {
            line: 272,
            column: 47
          },
          end: {
            line: 272,
            column: 63
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 272,
            column: 47
          },
          end: {
            line: 272,
            column: 52
          }
        }, {
          start: {
            line: 272,
            column: 56
          },
          end: {
            line: 272,
            column: 63
          }
        }],
        line: 272
      },
      '45': {
        loc: {
          start: {
            line: 274,
            column: 10
          },
          end: {
            line: 276,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 274,
            column: 10
          },
          end: {
            line: 276,
            column: 11
          }
        }, {
          start: {
            line: 274,
            column: 10
          },
          end: {
            line: 276,
            column: 11
          }
        }],
        line: 274
      },
      '46': {
        loc: {
          start: {
            line: 278,
            column: 22
          },
          end: {
            line: 278,
            column: 71
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 278,
            column: 48
          },
          end: {
            line: 278,
            column: 65
          }
        }, {
          start: {
            line: 278,
            column: 69
          },
          end: {
            line: 278,
            column: 71
          }
        }],
        line: 278
      },
      '47': {
        loc: {
          start: {
            line: 278,
            column: 22
          },
          end: {
            line: 278,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 278,
            column: 22
          },
          end: {
            line: 278,
            column: 29
          }
        }, {
          start: {
            line: 278,
            column: 33
          },
          end: {
            line: 278,
            column: 44
          }
        }],
        line: 278
      },
      '48': {
        loc: {
          start: {
            line: 296,
            column: 6
          },
          end: {
            line: 298,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 296,
            column: 6
          },
          end: {
            line: 298,
            column: 7
          }
        }, {
          start: {
            line: 296,
            column: 6
          },
          end: {
            line: 298,
            column: 7
          }
        }],
        line: 296
      },
      '49': {
        loc: {
          start: {
            line: 299,
            column: 13
          },
          end: {
            line: 299,
            column: 28
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 299,
            column: 13
          },
          end: {
            line: 299,
            column: 20
          }
        }, {
          start: {
            line: 299,
            column: 24
          },
          end: {
            line: 299,
            column: 28
          }
        }],
        line: 299
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0
    },
    f: {
      '0': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '23': [0, 0],
      '24': [0, 0, 0],
      '25': [0, 0],
      '26': [0, 0, 0],
      '27': [0, 0],
      '28': [0, 0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_z5l10t65r.s[0]++;
angular.module('deltadate', []).factory('$deltadate', ['$http', '$q', '$location', '$translate', '$filter', function ($http, $q, $location, $translate, $filter) {
  cov_z5l10t65r.f[0]++;
  cov_z5l10t65r.s[1]++;

  this.start_month = 1;

  cov_z5l10t65r.s[2]++;
  return {
    getMaxDate: function getMaxDate(firstDate, secondDate) {
      var firstDateStr = (cov_z5l10t65r.s[3]++, this.format((cov_z5l10t65r.b[0][0]++, firstDate._d) || (cov_z5l10t65r.b[0][1]++, firstDate), 'YYYY-MM-DD'));
      var secondDateStr = (cov_z5l10t65r.s[4]++, this.format((cov_z5l10t65r.b[1][0]++, secondDate._d) || (cov_z5l10t65r.b[1][1]++, secondDate), 'YYYY-MM-DD'));
      cov_z5l10t65r.s[5]++;
      if (firstDateStr > secondDateStr) {
        cov_z5l10t65r.b[2][0]++;
        cov_z5l10t65r.s[6]++;

        return Date.newDate(firstDateStr);
      } else {
        cov_z5l10t65r.b[2][1]++;
      }
      cov_z5l10t65r.s[7]++;
      return Date.newDate(secondDateStr);
    },
    getMinDate: function getMinDate(firstDate, secondDate) {
      var firstDateStr = (cov_z5l10t65r.s[8]++, this.format((cov_z5l10t65r.b[3][0]++, firstDate._d) || (cov_z5l10t65r.b[3][1]++, firstDate), 'YYYY-MM-DD'));
      var secondDateStr = (cov_z5l10t65r.s[9]++, this.format((cov_z5l10t65r.b[4][0]++, secondDate._d) || (cov_z5l10t65r.b[4][1]++, secondDate), 'YYYY-MM-DD'));
      cov_z5l10t65r.s[10]++;
      if (firstDateStr < secondDateStr) {
        cov_z5l10t65r.b[5][0]++;
        cov_z5l10t65r.s[11]++;

        return Date.newDate(firstDateStr);
      } else {
        cov_z5l10t65r.b[5][1]++;
      }
      cov_z5l10t65r.s[12]++;
      return Date.newDate(secondDateStr);
    },
    getMin: function getMin(a, b) {
      cov_z5l10t65r.s[13]++;

      if (a < b) {
        cov_z5l10t65r.b[6][0]++;
        cov_z5l10t65r.s[14]++;

        return a;
      } else {
        cov_z5l10t65r.b[6][1]++;
      }
      cov_z5l10t65r.s[15]++;
      return b;
    },
    getMax: function getMax(a, b) {
      cov_z5l10t65r.s[16]++;

      if (a > b) {
        cov_z5l10t65r.b[7][0]++;
        cov_z5l10t65r.s[17]++;

        return a;
      } else {
        cov_z5l10t65r.b[7][1]++;
      }
      cov_z5l10t65r.s[18]++;
      return b;
    },
    setStartMonth: function setStartMonth(value) {
      cov_z5l10t65r.s[19]++;

      this.start_month = value;
    },
    getStartMonth: function getStartMonth() {
      cov_z5l10t65r.s[20]++;

      return this.start_month;
    },
    getCodeDate: function getCodeDate(label) {
      var myDatefr = (cov_z5l10t65r.s[21]++, {
        fullDate: 'dddd DD MMMM YYYY',
        longDate: 'DD MMMM YYYY',
        mediumDate: 'DD MMM YYYY',
        shortDate: 'DD/MM/YY'
      });
      var myDateen = (cov_z5l10t65r.s[22]++, {
        fullDate: 'dddd, MMMM DD, YYYY',
        longDate: 'MMMM DD, YYYY',
        mediumDate: 'MMM DD, YYYY',
        shortDate: 'M/DD/YY'
      });
      cov_z5l10t65r.s[23]++;
      return {
        en: myDateen[label],
        fr: myDatefr[label]
      };
    },
    format: function format(date, _format) {
      var value = (cov_z5l10t65r.s[24]++, '');
      cov_z5l10t65r.s[25]++;
      if (date === null) {
        cov_z5l10t65r.b[8][0]++;
        cov_z5l10t65r.s[26]++;

        return null;
      } else {
        cov_z5l10t65r.b[8][1]++;
      }
      cov_z5l10t65r.s[27]++;
      if (angular.isString(date)) {
        cov_z5l10t65r.b[9][0]++;

        var proper = (cov_z5l10t65r.s[28]++, angular.copy(date).split('/'));

        cov_z5l10t65r.s[29]++;
        if (proper.length != 3) {
          cov_z5l10t65r.b[10][0]++;
          cov_z5l10t65r.s[30]++;

          return null;
        } else {
          cov_z5l10t65r.b[10][1]++;
        }
        cov_z5l10t65r.s[31]++;
        if (proper[1] < 10) {
          cov_z5l10t65r.b[11][0]++;
          cov_z5l10t65r.s[32]++;

          proper[1] = '0' + eval(proper[1]);
        } else {
          cov_z5l10t65r.b[11][1]++;
        }
        cov_z5l10t65r.s[33]++;
        if (proper[0] < 10) {
          cov_z5l10t65r.b[12][0]++;
          cov_z5l10t65r.s[34]++;

          proper[0] = '0' + eval(proper[0]);
        } else {
          cov_z5l10t65r.b[12][1]++;
        }
        cov_z5l10t65r.s[35]++;
        value = Date.newDate([proper[2], this.CURRENT_LANG == 'en' ? (cov_z5l10t65r.b[13][0]++, proper[0]) : (cov_z5l10t65r.b[13][1]++, proper[1]), this.CURRENT_LANG == 'en' ? (cov_z5l10t65r.b[14][0]++, proper[1]) : (cov_z5l10t65r.b[14][1]++, proper[0])].join('-'));
      } else {
        cov_z5l10t65r.b[9][1]++;
        cov_z5l10t65r.s[36]++;

        value = (cov_z5l10t65r.b[15][0]++, date._d) || (cov_z5l10t65r.b[15][1]++, date);
      }
      var dd = (cov_z5l10t65r.s[37]++, $filter('date')(value, 'dd'));
      var mm = (cov_z5l10t65r.s[38]++, $filter('date')(value, 'MM'));
      var year = (cov_z5l10t65r.s[39]++, $filter('date')(value, 'yyyy'));

      var result = (cov_z5l10t65r.s[40]++, _format);
      cov_z5l10t65r.s[41]++;
      result = result.replace('yyyy', year);
      cov_z5l10t65r.s[42]++;
      result = result.replace('YYYY', year);
      cov_z5l10t65r.s[43]++;
      result = result.replace('mm', mm);
      cov_z5l10t65r.s[44]++;
      result = result.replace('MM', mm);
      cov_z5l10t65r.s[45]++;
      result = result.replace('dd', dd);
      cov_z5l10t65r.s[46]++;
      result = result.replace('DD', dd);
      cov_z5l10t65r.s[47]++;
      return result;
    },
    daysBetween: function daysBetween(beginDate, endDate) {
      var diff = (cov_z5l10t65r.s[48]++, Math.max(beginDate.getTime(), endDate.getTime()) - Math.min(beginDate.getTime(), endDate.getTime()));
      cov_z5l10t65r.s[49]++;
      return Math.round(diff / (1000 * 60 * 60 * 24));
    },
    getYearBetween: function getYearBetween(dateD, dateF, beginning) {
      // const periods = this.getPeriods(dateD, dateF, 1, false, beginning);
      var result = (cov_z5l10t65r.s[50]++, []);
      cov_z5l10t65r.s[51]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.getPeriods(dateD, dateF, 1, false, beginning)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var year = _step.value;
          cov_z5l10t65r.s[52]++;

          result.push({
            name: year.year.toString(),
            id: year.year.toString()
          });
        }

        /*
        const begin = (dateD._d || dateD).getFullYear();
        const end = (dateF._d || dateF).getFullYear();
        const length = end - begin;
        for (let i = 0; i <= length; i++) {
          result.push({
            name: (begin + i).toString(),
            id: (begin + i).toString()
          });
        } */
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_z5l10t65r.s[53]++;
      return result;
    },
    getFullLabel: function getFullLabel(num, per, year, isShort) {
      var label = (cov_z5l10t65r.s[54]++, this.getLabelPerioddepth(num, per, isShort));
      cov_z5l10t65r.s[55]++;
      if (per == 1) {
        cov_z5l10t65r.b[16][0]++;
        cov_z5l10t65r.s[56]++;

        return label;
      } else {
        cov_z5l10t65r.b[16][1]++;
      }
      cov_z5l10t65r.s[57]++;
      return label + ' - ' + year;
    },
    getLabelPerioddepth: function getLabelPerioddepth(num, per, isShort, year, beginning) {
      cov_z5l10t65r.s[58]++;

      beginning = (cov_z5l10t65r.b[17][0]++, beginning) || (cov_z5l10t65r.b[17][1]++, this.start_month) || (cov_z5l10t65r.b[17][2]++, 1);
      // num = (num + (beginning - 1)) % 12;
      var label = (cov_z5l10t65r.s[59]++, year ? (cov_z5l10t65r.b[18][0]++, ' - ' + year) : (cov_z5l10t65r.b[18][1]++, ''));
      cov_z5l10t65r.s[60]++;
      if (per == 12) {
        cov_z5l10t65r.b[19][0]++;

        var _ = (cov_z5l10t65r.s[61]++, beginning);
        cov_z5l10t65r.s[62]++;
        if (_ < 10) {
          cov_z5l10t65r.b[20][0]++;
          cov_z5l10t65r.s[63]++;

          _ = '0' + _;
        } else {
          cov_z5l10t65r.b[20][1]++;
        }
        var mydate = (cov_z5l10t65r.s[64]++, Date.newDate('1989-' + _ + '-01'));
        cov_z5l10t65r.s[65]++;
        mydate.setMonth(mydate.getMonth() + (num - 1));
        cov_z5l10t65r.s[66]++;
        return $filter('date')(mydate, isShort ? (cov_z5l10t65r.b[21][0]++, 'MMM') : (cov_z5l10t65r.b[21][1]++, 'MMMM')) + label;
      } else {
        cov_z5l10t65r.b[19][1]++;
      }
      cov_z5l10t65r.s[67]++;
      return this.getLabelPeriod(per, isShort) + ' ' + num + label;
    },
    getLabelPeriod: function getLabelPeriod(per, isShort) {
      var labelP = (cov_z5l10t65r.s[68]++, '');
      var labelShort = (cov_z5l10t65r.s[69]++, '');
      cov_z5l10t65r.s[70]++;
      switch (per) {
        case '2':
          cov_z5l10t65r.b[22][0]++;

        case 2:
          cov_z5l10t65r.b[22][1]++;
          cov_z5l10t65r.s[71]++;

          labelP = $translate.instant('COMMON.SEM');
          cov_z5l10t65r.s[72]++;
          labelShort = $translate.instant('COMMON.SEM_SHORT');
          cov_z5l10t65r.s[73]++;
          break;
        case 3:
          cov_z5l10t65r.b[22][2]++;

        case '3':
          cov_z5l10t65r.b[22][3]++;
          cov_z5l10t65r.s[74]++;

          labelP = $translate.instant('COMMON.QUATR');
          cov_z5l10t65r.s[75]++;
          labelShort = $translate.instant('COMMON.QUATR_SHORT');

          cov_z5l10t65r.s[76]++;
          break;
        case 4:
          cov_z5l10t65r.b[22][4]++;

        case '4':
          cov_z5l10t65r.b[22][5]++;
          cov_z5l10t65r.s[77]++;

          labelP = $translate.instant('COMMON.TRIM');
          cov_z5l10t65r.s[78]++;
          labelShort = $translate.instant('COMMON.TRIM_SHORT');
          cov_z5l10t65r.s[79]++;
          break;
        case 6:
          cov_z5l10t65r.b[22][6]++;

        case '6':
          cov_z5l10t65r.b[22][7]++;
          cov_z5l10t65r.s[80]++;

          labelP = $translate.instant('COMMON.BIM');
          cov_z5l10t65r.s[81]++;
          labelShort = $translate.instant('COMMON.BIM_SHORT');
          cov_z5l10t65r.s[82]++;
          break;
        case 12:
          cov_z5l10t65r.b[22][8]++;

        case '12':
          cov_z5l10t65r.b[22][9]++;
          cov_z5l10t65r.s[83]++;

          labelP = $translate.instant('COMMON.MOIS');
          cov_z5l10t65r.s[84]++;
          labelShort = $translate.instant('COMMON.MOIS_SHORT');
          cov_z5l10t65r.s[85]++;
          break;
        default:
          cov_z5l10t65r.b[22][10]++;
          cov_z5l10t65r.s[86]++;

          labelP = $translate.instant('COMMON.YEAR');
          cov_z5l10t65r.s[87]++;
          labelShort = $translate.instant('COMMON.YEAR_SHORT');
      }
      cov_z5l10t65r.s[88]++;
      return isShort ? (cov_z5l10t65r.b[23][0]++, labelShort) : (cov_z5l10t65r.b[23][1]++, labelP);
    },
    getBeginYear: function getBeginYear(year, _begin) {
      var begin = (cov_z5l10t65r.s[89]++, (cov_z5l10t65r.b[24][0]++, _begin) || (cov_z5l10t65r.b[24][1]++, this.start_month) || (cov_z5l10t65r.b[24][2]++, 1));
      cov_z5l10t65r.s[90]++;
      if (begin < 10) {
        cov_z5l10t65r.b[25][0]++;
        cov_z5l10t65r.s[91]++;

        begin = '0' + begin;
      } else {
        cov_z5l10t65r.b[25][1]++;
      }
      cov_z5l10t65r.s[92]++;
      return Date.newDate(year + '-' + begin + '-01');
    },
    getEndYear: function getEndYear(year, _begin) {
      var begin = (cov_z5l10t65r.s[93]++, (cov_z5l10t65r.b[26][0]++, _begin) || (cov_z5l10t65r.b[26][1]++, this.start_month) || (cov_z5l10t65r.b[26][2]++, 1));
      cov_z5l10t65r.s[94]++;
      if (begin < 10) {
        cov_z5l10t65r.b[27][0]++;
        cov_z5l10t65r.s[95]++;

        begin = '0' + begin;
      } else {
        cov_z5l10t65r.b[27][1]++;
      }
      var _ = (cov_z5l10t65r.s[96]++, Date.newDate(year + '-' + begin + '-01'));
      cov_z5l10t65r.s[97]++;
      _.setMonth(_.getMonth() + 12);
      cov_z5l10t65r.s[98]++;
      _.setDate(0);
      cov_z5l10t65r.s[99]++;
      return _;
    },
    getYearPeriod: function getYearPeriod(year, period, short, _begin) {
      var begin = (cov_z5l10t65r.s[100]++, (cov_z5l10t65r.b[28][0]++, _begin) || (cov_z5l10t65r.b[28][1]++, this.start_month) || (cov_z5l10t65r.b[28][2]++, 1));
      cov_z5l10t65r.s[101]++;
      if (begin < 10) {
        cov_z5l10t65r.b[29][0]++;
        cov_z5l10t65r.s[102]++;

        begin = '0' + begin;
      } else {
        cov_z5l10t65r.b[29][1]++;
      }
      var beginDate = (cov_z5l10t65r.s[103]++, Date.newDate(year + '-' + begin + '-01'));
      var endDate = (cov_z5l10t65r.s[104]++, Date.newDate(year + '-' + begin + '-01'));

      cov_z5l10t65r.s[105]++;
      endDate.setMonth(endDate.getMonth() + 12);
      cov_z5l10t65r.s[106]++;
      endDate.setDate(0);

      cov_z5l10t65r.s[107]++;
      return this.getPeriods(beginDate, endDate, period, short, _begin);
    },
    _isShort: function _isShort(dateD, dateF, period, beginning) {
      var begin = (cov_z5l10t65r.s[108]++, Date.newDate(((cov_z5l10t65r.b[30][0]++, dateD._d) || (cov_z5l10t65r.b[30][1]++, dateD)).getFullYear() + '-01-01'));
      cov_z5l10t65r.s[109]++;
      begin.setMonth(begin.getMonth() + (beginning - 1));
      cov_z5l10t65r.s[110]++;
      if (beginning > 1) {
        cov_z5l10t65r.b[31][0]++;
        cov_z5l10t65r.s[111]++;

        begin.setFullYear(begin.getFullYear() - 1);
      } else {
        cov_z5l10t65r.b[31][1]++;
      }

      cov_z5l10t65r.s[112]++;
      begin.setMonth(begin.getMonth() + 12);
      cov_z5l10t65r.s[113]++;
      return this.format(begin, 'YYYY-MM-DD') <= this.format(dateF, 'YYYY-MM-DD');
    },
    getPeriods: function getPeriods(dateD, dateF, period, short, beginning) {
      cov_z5l10t65r.s[114]++;

      beginning = (cov_z5l10t65r.b[32][0]++, beginning) || (cov_z5l10t65r.b[32][1]++, this.start_month) || (cov_z5l10t65r.b[32][2]++, 1);
      /* if (!beginning) {
        beginning = 1;
      } */

      cov_z5l10t65r.s[115]++;
      if (dateD > dateF) {
        cov_z5l10t65r.b[33][0]++;
        cov_z5l10t65r.s[116]++;

        return [];
      } else {
        cov_z5l10t65r.b[33][1]++;
      }
      var resul = (cov_z5l10t65r.s[117]++, []);

      var begin = (cov_z5l10t65r.s[118]++, Date.newDate(((cov_z5l10t65r.b[34][0]++, dateD._d) || (cov_z5l10t65r.b[34][1]++, dateD)).getFullYear() + (beginning > 1 ? (cov_z5l10t65r.b[35][0]++, -1) : (cov_z5l10t65r.b[35][1]++, 0)), 0 + (beginning - 1), 1));
      /* begin.setMonth(begin.getMonth() + (beginning - 1));
      if (beginning > 1) {
        begin.setFullYear(begin.getFullYear() - 1);
      } */

      var index = (cov_z5l10t65r.s[119]++, 0);
      var currentYear = (cov_z5l10t65r.s[120]++, 0);
      var _ = (cov_z5l10t65r.s[121]++, 0);
      var subPeriod = (cov_z5l10t65r.s[122]++, 12 / period);

      var isShort = (cov_z5l10t65r.s[123]++, this._isShort(dateD, dateF, period, beginning));
      var labelP = (cov_z5l10t65r.s[124]++, this.getLabelPeriod(period, (cov_z5l10t65r.b[36][0]++, short) || (cov_z5l10t65r.b[36][1]++, isShort)));
      cov_z5l10t65r.s[125]++;
      while (true) {
        cov_z5l10t65r.s[126]++;

        _++;
        cov_z5l10t65r.s[127]++;
        if (_ == 500) {
          cov_z5l10t65r.b[37][0]++;
          cov_z5l10t65r.s[128]++;

          return [];
        } else {
          cov_z5l10t65r.b[37][1]++;
        }
        /* if (currentYear != begin.getFullYear()) {
          index = 0;
          currentYear = begin.getFullYear();
        } */
        cov_z5l10t65r.s[129]++;
        if (index % period == 0) {
          cov_z5l10t65r.b[38][0]++;
          cov_z5l10t65r.s[130]++;

          index = 0;
          cov_z5l10t65r.s[131]++;
          currentYear = begin.getFullYear();
        } else {
          cov_z5l10t65r.b[38][1]++;
        }
        cov_z5l10t65r.s[132]++;
        index++;
        cov_z5l10t65r.s[133]++;
        if (begin > dateF) {
          cov_z5l10t65r.b[39][0]++;
          cov_z5l10t65r.s[134]++;

          break;
        } else {
          cov_z5l10t65r.b[39][1]++;
        } /*
          const end_ = Date.newDate(this.format(begin, 'YYYY-MM-DD'));
          end_.setMonth(end_.getMonth() + subPeriod, 1);
          end_.setDate(0); */

        // const end = Date.newDate(this.format(end_, 'YYYY-MM-DD'));
        var end = (cov_z5l10t65r.s[135]++, Date.newDate(begin.getFullYear(), begin.getMonth() + subPeriod, 0));
        cov_z5l10t65r.s[136]++;
        if (end >= dateD) {
          cov_z5l10t65r.b[40][0]++;

          var reslabel = (cov_z5l10t65r.s[137]++, labelP + (isShort ? (cov_z5l10t65r.b[41][0]++, '') : (cov_z5l10t65r.b[41][1]++, ' ')) + index);
          cov_z5l10t65r.s[138]++;
          if (period == 12) {
            cov_z5l10t65r.b[42][0]++;
            cov_z5l10t65r.s[139]++;

            reslabel = $filter('date')(begin, (cov_z5l10t65r.b[44][0]++, short) || (cov_z5l10t65r.b[44][1]++, isShort) ? (cov_z5l10t65r.b[43][0]++, 'MMM') : (cov_z5l10t65r.b[43][1]++, 'MMMM'));
          } else {
            cov_z5l10t65r.b[42][1]++;
          }
          cov_z5l10t65r.s[140]++;
          if (period == 1) {
            cov_z5l10t65r.b[45][0]++;
            cov_z5l10t65r.s[141]++;

            reslabel = $filter('date')(begin, 'yyyy');
          } else {
            cov_z5l10t65r.b[45][1]++;
          }
          var labelShort = (cov_z5l10t65r.s[142]++, reslabel);
          cov_z5l10t65r.s[143]++;
          reslabel += (cov_z5l10t65r.b[47][0]++, isShort) && (cov_z5l10t65r.b[47][1]++, period != 1) ? (cov_z5l10t65r.b[46][0]++, ' ' + currentYear) : (cov_z5l10t65r.b[46][1]++, '');
          cov_z5l10t65r.s[144]++;
          resul.push({
            year: currentYear,
            label: reslabel,
            labelShort: labelShort,
            begin: this.getMaxDate(begin, dateD),
            end: this.getMinDate(end, dateF),
            datet: angular.copy(begin),
            index: index
          });
        } else {
          cov_z5l10t65r.b[40][1]++;
        }
        cov_z5l10t65r.s[145]++;
        begin = Date.newDate(begin.getFullYear(), begin.getMonth() + subPeriod, 1);

        // begin.setMonth(begin.getMonth() + subPeriod, 1);
      }
      cov_z5l10t65r.s[146]++;
      return resul;
    },
    getSanitizeDate: function getSanitizeDate(date) {
      cov_z5l10t65r.s[147]++;

      if (!date) {
        cov_z5l10t65r.b[48][0]++;
        cov_z5l10t65r.s[148]++;

        return null;
      } else {
        cov_z5l10t65r.b[48][1]++;
      }
      cov_z5l10t65r.s[149]++;
      return (cov_z5l10t65r.b[49][0]++, date._d) || (cov_z5l10t65r.b[49][1]++, date);
    }
  };
}]);