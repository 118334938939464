'use strict';

var cov_f74zs8uo6 = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/formular/content/formularContent.js',
      hash = 'a9d8319672981e83ba777769f3c96a559184bfe9',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/formular/content/formularContent.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 33,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 15
        },
        end: {
          line: 3,
          column: 25
        }
      },
      '2': {
        start: {
          line: 4,
          column: 22
        },
        end: {
          line: 4,
          column: 47
        }
      },
      '3': {
        start: {
          line: 5,
          column: 28
        },
        end: {
          line: 5,
          column: 57
        }
      },
      '4': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 7,
          column: 42
        }
      },
      '5': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 27,
          column: 3
        }
      },
      '6': {
        start: {
          line: 10,
          column: 21
        },
        end: {
          line: 10,
          column: 117
        }
      },
      '7': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 12,
          column: 30
        }
      },
      '8': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 13,
          column: 33
        }
      },
      '9': {
        start: {
          line: 14,
          column: 4
        },
        end: {
          line: 14,
          column: 32
        }
      },
      '10': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 15,
          column: 33
        }
      },
      '11': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 16,
          column: 92
        }
      },
      '12': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 17,
          column: 59
        }
      },
      '13': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 26,
          column: 5
        }
      },
      '14': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 25,
          column: 7
        }
      },
      '15': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 21,
          column: 149
        }
      },
      '16': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 89
        }
      },
      '17': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 23,
          column: 151
        }
      },
      '18': {
        start: {
          line: 24,
          column: 8
        },
        end: {
          line: 24,
          column: 45
        }
      },
      '19': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 32,
          column: 5
        }
      },
      '20': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 31,
          column: 35
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 56
          },
          end: {
            line: 1,
            column: 57
          }
        },
        loc: {
          start: {
            line: 1,
            column: 114
          },
          end: {
            line: 33,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 30,
            column: 23
          },
          end: {
            line: 30,
            column: 24
          }
        },
        loc: {
          start: {
            line: 30,
            column: 29
          },
          end: {
            line: 32,
            column: 3
          }
        },
        line: 30
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 9,
            column: 2
          },
          end: {
            line: 27,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 9,
            column: 2
          },
          end: {
            line: 27,
            column: 3
          }
        }, {
          start: {
            line: 9,
            column: 2
          },
          end: {
            line: 27,
            column: 3
          }
        }],
        line: 9
      },
      '1': {
        loc: {
          start: {
            line: 10,
            column: 21
          },
          end: {
            line: 10,
            column: 117
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 10,
            column: 21
          },
          end: {
            line: 10,
            column: 56
          }
        }, {
          start: {
            line: 10,
            column: 60
          },
          end: {
            line: 10,
            column: 117
          }
        }],
        line: 10
      },
      '2': {
        loc: {
          start: {
            line: 19,
            column: 4
          },
          end: {
            line: 26,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 19,
            column: 4
          },
          end: {
            line: 26,
            column: 5
          }
        }, {
          start: {
            line: 19,
            column: 4
          },
          end: {
            line: 26,
            column: 5
          }
        }],
        line: 19
      },
      '3': {
        loc: {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 25,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 25,
            column: 7
          }
        }, {
          start: {
            line: 20,
            column: 6
          },
          end: {
            line: 25,
            column: 7
          }
        }],
        line: 20
      },
      '4': {
        loc: {
          start: {
            line: 21,
            column: 25
          },
          end: {
            line: 21,
            column: 148
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 21,
            column: 25
          },
          end: {
            line: 21,
            column: 85
          }
        }, {
          start: {
            line: 21,
            column: 89
          },
          end: {
            line: 21,
            column: 148
          }
        }],
        line: 21
      },
      '5': {
        loc: {
          start: {
            line: 23,
            column: 27
          },
          end: {
            line: 23,
            column: 150
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 23,
            column: 27
          },
          end: {
            line: 23,
            column: 87
          }
        }, {
          start: {
            line: 23,
            column: 91
          },
          end: {
            line: 23,
            column: 150
          }
        }],
        line: 23
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_f74zs8uo6.s[0]++;
angular.module('app').controller('formularContentCtrl', function ($scope, $state, $stateParams, $deltahttp, $rootScope) {
  cov_f74zs8uo6.f[0]++;

  // $scope.currentUser = $deltahttp.idUser;
  var PATH = (cov_f74zs8uo6.s[1]++, 'Formular');
  var KEY_DISPLAY = (cov_f74zs8uo6.s[2]++, 'KEY_DISPLAY_CUSTOM_DATA');
  var KEY_DISPLAY_IMAGE = (cov_f74zs8uo6.s[3]++, 'KEY_DISPLAY_CUSTOM_DATA_IMG');

  cov_f74zs8uo6.s[4]++;
  $scope.selectedForm = $stateParams.form;

  cov_f74zs8uo6.s[5]++;
  if ($scope.selectedForm) {
    cov_f74zs8uo6.b[0][0]++;

    var isSupper = (cov_f74zs8uo6.s[6]++, (cov_f74zs8uo6.b[1][0]++, $rootScope.connectedUser.superAdmin) || (cov_f74zs8uo6.b[1][1]++, $scope.selectedForm.IDUSER == $rootScope.connectedUser.id));

    cov_f74zs8uo6.s[7]++;
    $scope.canEdit = isSupper;
    cov_f74zs8uo6.s[8]++;
    $scope.canCollect = isSupper;
    cov_f74zs8uo6.s[9]++;
    $scope.canReview = isSupper;
    cov_f74zs8uo6.s[10]++;
    $scope.canAnalyse = isSupper;
    cov_f74zs8uo6.s[11]++;
    $scope.selectedForm.imageUrl = $deltahttp.getFormularRespositoy($scope.selectedForm.id);
    cov_f74zs8uo6.s[12]++;
    $scope.isCrossForm = $scope.selectedForm.ID_LINKED > 0;

    cov_f74zs8uo6.s[13]++;
    if (!isSupper) {
      cov_f74zs8uo6.b[2][0]++;
      cov_f74zs8uo6.s[14]++;

      if ($scope.selectedForm.RIGHT_[$rootScope.connectedUser.id]) {
        cov_f74zs8uo6.b[3][0]++;
        cov_f74zs8uo6.s[15]++;

        $scope.canEdit = (cov_f74zs8uo6.b[4][0]++, $scope.selectedForm.RIGHT_[$rootScope.connectedUser.id].m.ed) || (cov_f74zs8uo6.b[4][1]++, $scope.selectedForm.RIGHT_[$rootScope.connectedUser.id].m.v);
        cov_f74zs8uo6.s[16]++;
        $scope.canCollect = $scope.selectedForm.RIGHT_[$rootScope.connectedUser.id].d.ed;
        cov_f74zs8uo6.s[17]++;
        $scope.canReview = (cov_f74zs8uo6.b[5][0]++, $scope.selectedForm.RIGHT_[$rootScope.connectedUser.id].d.ed) || (cov_f74zs8uo6.b[5][1]++, $scope.selectedForm.RIGHT_[$rootScope.connectedUser.id].d.v);
        cov_f74zs8uo6.s[18]++;
        $scope.canAnalyse = $scope.canReview;
      } else {
        cov_f74zs8uo6.b[3][1]++;
      }
    } else {
      cov_f74zs8uo6.b[2][1]++;
    }
  } else {
    cov_f74zs8uo6.b[0][1]++;
  }

  // formular.editItem
  cov_f74zs8uo6.s[19]++;
  $scope.closePanel = function () {
    cov_f74zs8uo6.f[1]++;
    cov_f74zs8uo6.s[20]++;

    $state.go('formular.formular');
  };
});