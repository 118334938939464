'use strict';

var cov_1bbr0xzn84 = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/risk/problemCtrl.js',
      hash = '8afc6eb760f5952b16c9b37a1c9a299d1ff2af8a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/risk/problemCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 176,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 3,
          column: 37
        }
      },
      '2': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '3': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 11
        }
      },
      '4': {
        start: {
          line: 7,
          column: 15
        },
        end: {
          line: 7,
          column: 24
        }
      },
      '5': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 36
        }
      },
      '6': {
        start: {
          line: 9,
          column: 18
        },
        end: {
          line: 9,
          column: 48
        }
      },
      '7': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 41
        }
      },
      '8': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 18
        }
      },
      '9': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 27
        }
      },
      '10': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 20,
          column: 4
        }
      },
      '11': {
        start: {
          line: 22,
          column: 2
        },
        end: {
          line: 86,
          column: 5
        }
      },
      '12': {
        start: {
          line: 23,
          column: 4
        },
        end: {
          line: 63,
          column: 6
        }
      },
      '13': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 47,
          column: 9
        }
      },
      '14': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 46,
          column: 44
        }
      },
      '15': {
        start: {
          line: 50,
          column: 8
        },
        end: {
          line: 53,
          column: 9
        }
      },
      '16': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 51,
          column: 31
        }
      },
      '17': {
        start: {
          line: 52,
          column: 10
        },
        end: {
          line: 52,
          column: 27
        }
      },
      '18': {
        start: {
          line: 54,
          column: 8
        },
        end: {
          line: 57,
          column: 9
        }
      },
      '19': {
        start: {
          line: 55,
          column: 10
        },
        end: {
          line: 55,
          column: 31
        }
      },
      '20': {
        start: {
          line: 56,
          column: 10
        },
        end: {
          line: 56,
          column: 42
        }
      },
      '21': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 61,
          column: 9
        }
      },
      '22': {
        start: {
          line: 59,
          column: 10
        },
        end: {
          line: 59,
          column: 31
        }
      },
      '23': {
        start: {
          line: 60,
          column: 10
        },
        end: {
          line: 60,
          column: 44
        }
      },
      '24': {
        start: {
          line: 65,
          column: 4
        },
        end: {
          line: 65,
          column: 28
        }
      },
      '25': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 85,
          column: 7
        }
      },
      '26': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 83,
          column: 9
        }
      },
      '27': {
        start: {
          line: 68,
          column: 8
        },
        end: {
          line: 68,
          column: 60
        }
      },
      '28': {
        start: {
          line: 70,
          column: 8
        },
        end: {
          line: 72,
          column: 11
        }
      },
      '29': {
        start: {
          line: 71,
          column: 10
        },
        end: {
          line: 71,
          column: 46
        }
      },
      '30': {
        start: {
          line: 74,
          column: 8
        },
        end: {
          line: 76,
          column: 11
        }
      },
      '31': {
        start: {
          line: 75,
          column: 10
        },
        end: {
          line: 75,
          column: 46
        }
      },
      '32': {
        start: {
          line: 78,
          column: 8
        },
        end: {
          line: 81,
          column: 10
        }
      },
      '33': {
        start: {
          line: 82,
          column: 8
        },
        end: {
          line: 82,
          column: 20
        }
      },
      '34': {
        start: {
          line: 84,
          column: 6
        },
        end: {
          line: 84,
          column: 31
        }
      },
      '35': {
        start: {
          line: 88,
          column: 2
        },
        end: {
          line: 104,
          column: 5
        }
      },
      '36': {
        start: {
          line: 89,
          column: 4
        },
        end: {
          line: 103,
          column: 5
        }
      },
      '37': {
        start: {
          line: 90,
          column: 6
        },
        end: {
          line: 102,
          column: 9
        }
      },
      '38': {
        start: {
          line: 106,
          column: 2
        },
        end: {
          line: 116,
          column: 5
        }
      },
      '39': {
        start: {
          line: 107,
          column: 4
        },
        end: {
          line: 115,
          column: 7
        }
      },
      '40': {
        start: {
          line: 117,
          column: 2
        },
        end: {
          line: 128,
          column: 5
        }
      },
      '41': {
        start: {
          line: 118,
          column: 4
        },
        end: {
          line: 118,
          column: 30
        }
      },
      '42': {
        start: {
          line: 119,
          column: 4
        },
        end: {
          line: 119,
          column: 45
        }
      },
      '43': {
        start: {
          line: 120,
          column: 4
        },
        end: {
          line: 127,
          column: 7
        }
      },
      '44': {
        start: {
          line: 130,
          column: 2
        },
        end: {
          line: 169,
          column: 5
        }
      },
      '45': {
        start: {
          line: 131,
          column: 4
        },
        end: {
          line: 168,
          column: 7
        }
      },
      '46': {
        start: {
          line: 143,
          column: 6
        },
        end: {
          line: 145,
          column: 7
        }
      },
      '47': {
        start: {
          line: 144,
          column: 8
        },
        end: {
          line: 144,
          column: 15
        }
      },
      '48': {
        start: {
          line: 146,
          column: 6
        },
        end: {
          line: 146,
          column: 30
        }
      },
      '49': {
        start: {
          line: 147,
          column: 27
        },
        end: {
          line: 150,
          column: 7
        }
      },
      '50': {
        start: {
          line: 156,
          column: 6
        },
        end: {
          line: 167,
          column: 9
        }
      },
      '51': {
        start: {
          line: 157,
          column: 8
        },
        end: {
          line: 166,
          column: 9
        }
      },
      '52': {
        start: {
          line: 158,
          column: 10
        },
        end: {
          line: 162,
          column: 13
        }
      },
      '53': {
        start: {
          line: 163,
          column: 10
        },
        end: {
          line: 163,
          column: 35
        }
      },
      '54': {
        start: {
          line: 165,
          column: 10
        },
        end: {
          line: 165,
          column: 26
        }
      },
      '55': {
        start: {
          line: 171,
          column: 2
        },
        end: {
          line: 173,
          column: 5
        }
      },
      '56': {
        start: {
          line: 172,
          column: 4
        },
        end: {
          line: 172,
          column: 31
        }
      },
      '57': {
        start: {
          line: 175,
          column: 2
        },
        end: {
          line: 175,
          column: 18
        }
      },
      '58': {
        start: {
          line: 179,
          column: 15
        },
        end: {
          line: 179,
          column: 24
        }
      },
      '59': {
        start: {
          line: 180,
          column: 2
        },
        end: {
          line: 180,
          column: 28
        }
      },
      '60': {
        start: {
          line: 182,
          column: 2
        },
        end: {
          line: 184,
          column: 5
        }
      },
      '61': {
        start: {
          line: 183,
          column: 4
        },
        end: {
          line: 183,
          column: 61
        }
      },
      '62': {
        start: {
          line: 186,
          column: 2
        },
        end: {
          line: 188,
          column: 5
        }
      },
      '63': {
        start: {
          line: 187,
          column: 4
        },
        end: {
          line: 187,
          column: 57
        }
      },
      '64': {
        start: {
          line: 190,
          column: 2
        },
        end: {
          line: 192,
          column: 5
        }
      },
      '65': {
        start: {
          line: 191,
          column: 4
        },
        end: {
          line: 191,
          column: 58
        }
      },
      '66': {
        start: {
          line: 194,
          column: 2
        },
        end: {
          line: 220,
          column: 4
        }
      },
      '67': {
        start: {
          line: 195,
          column: 25
        },
        end: {
          line: 203,
          column: 5
        }
      },
      '68': {
        start: {
          line: 205,
          column: 4
        },
        end: {
          line: 205,
          column: 26
        }
      },
      '69': {
        start: {
          line: 206,
          column: 4
        },
        end: {
          line: 209,
          column: 5
        }
      },
      '70': {
        start: {
          line: 207,
          column: 6
        },
        end: {
          line: 207,
          column: 28
        }
      },
      '71': {
        start: {
          line: 208,
          column: 6
        },
        end: {
          line: 208,
          column: 13
        }
      },
      '72': {
        start: {
          line: 211,
          column: 4
        },
        end: {
          line: 214,
          column: 5
        }
      },
      '73': {
        start: {
          line: 212,
          column: 6
        },
        end: {
          line: 212,
          column: 28
        }
      },
      '74': {
        start: {
          line: 213,
          column: 6
        },
        end: {
          line: 213,
          column: 13
        }
      },
      '75': {
        start: {
          line: 216,
          column: 4
        },
        end: {
          line: 219,
          column: 7
        }
      },
      '76': {
        start: {
          line: 217,
          column: 6
        },
        end: {
          line: 217,
          column: 26
        }
      },
      '77': {
        start: {
          line: 218,
          column: 6
        },
        end: {
          line: 218,
          column: 22
        }
      },
      '78': {
        start: {
          line: 222,
          column: 2
        },
        end: {
          line: 224,
          column: 4
        }
      },
      '79': {
        start: {
          line: 223,
          column: 4
        },
        end: {
          line: 223,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 48
          },
          end: {
            line: 2,
            column: 49
          }
        },
        loc: {
          start: {
            line: 2,
            column: 173
          },
          end: {
            line: 176,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 22,
            column: 19
          },
          end: {
            line: 22,
            column: 20
          }
        },
        loc: {
          start: {
            line: 22,
            column: 25
          },
          end: {
            line: 86,
            column: 3
          }
        },
        line: 22
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 66,
            column: 44
          },
          end: {
            line: 66,
            column: 45
          }
        },
        loc: {
          start: {
            line: 66,
            column: 52
          },
          end: {
            line: 85,
            column: 5
          }
        },
        line: 66
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 67,
            column: 37
          },
          end: {
            line: 67,
            column: 38
          }
        },
        loc: {
          start: {
            line: 67,
            column: 45
          },
          end: {
            line: 83,
            column: 7
          }
        },
        line: 67
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 70,
            column: 54
          },
          end: {
            line: 70,
            column: 55
          }
        },
        loc: {
          start: {
            line: 70,
            column: 62
          },
          end: {
            line: 72,
            column: 9
          }
        },
        line: 70
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 74,
            column: 50
          },
          end: {
            line: 74,
            column: 51
          }
        },
        loc: {
          start: {
            line: 74,
            column: 58
          },
          end: {
            line: 76,
            column: 9
          }
        },
        line: 74
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 88,
            column: 23
          },
          end: {
            line: 88,
            column: 24
          }
        },
        loc: {
          start: {
            line: 88,
            column: 34
          },
          end: {
            line: 104,
            column: 3
          }
        },
        line: 88
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 100,
            column: 9
          },
          end: {
            line: 100,
            column: 10
          }
        },
        loc: {
          start: {
            line: 100,
            column: 22
          },
          end: {
            line: 102,
            column: 7
          }
        },
        line: 100
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 106,
            column: 20
          },
          end: {
            line: 106,
            column: 21
          }
        },
        loc: {
          start: {
            line: 106,
            column: 26
          },
          end: {
            line: 116,
            column: 3
          }
        },
        line: 106
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 117,
            column: 21
          },
          end: {
            line: 117,
            column: 22
          }
        },
        loc: {
          start: {
            line: 117,
            column: 29
          },
          end: {
            line: 128,
            column: 3
          }
        },
        line: 117
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 130,
            column: 23
          },
          end: {
            line: 130,
            column: 24
          }
        },
        loc: {
          start: {
            line: 130,
            column: 31
          },
          end: {
            line: 169,
            column: 3
          }
        },
        line: 130
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 142,
            column: 7
          },
          end: {
            line: 142,
            column: 8
          }
        },
        loc: {
          start: {
            line: 142,
            column: 20
          },
          end: {
            line: 168,
            column: 5
          }
        },
        line: 142
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 156,
            column: 88
          },
          end: {
            line: 156,
            column: 89
          }
        },
        loc: {
          start: {
            line: 156,
            column: 96
          },
          end: {
            line: 167,
            column: 7
          }
        },
        line: 156
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 171,
            column: 23
          },
          end: {
            line: 171,
            column: 24
          }
        },
        loc: {
          start: {
            line: 171,
            column: 29
          },
          end: {
            line: 173,
            column: 3
          }
        },
        line: 171
      },
      '14': {
        name: 'ProblemCtrl_edit',
        decl: {
          start: {
            line: 178,
            column: 9
          },
          end: {
            line: 178,
            column: 25
          }
        },
        loc: {
          start: {
            line: 178,
            column: 112
          },
          end: {
            line: 225,
            column: 1
          }
        },
        line: 178
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 182,
            column: 63
          },
          end: {
            line: 182,
            column: 64
          }
        },
        loc: {
          start: {
            line: 182,
            column: 71
          },
          end: {
            line: 184,
            column: 3
          }
        },
        line: 182
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 186,
            column: 59
          },
          end: {
            line: 186,
            column: 60
          }
        },
        loc: {
          start: {
            line: 186,
            column: 67
          },
          end: {
            line: 188,
            column: 3
          }
        },
        line: 186
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 190,
            column: 69
          },
          end: {
            line: 190,
            column: 70
          }
        },
        loc: {
          start: {
            line: 190,
            column: 78
          },
          end: {
            line: 192,
            column: 3
          }
        },
        line: 190
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 194,
            column: 24
          },
          end: {
            line: 194,
            column: 25
          }
        },
        loc: {
          start: {
            line: 194,
            column: 36
          },
          end: {
            line: 220,
            column: 3
          }
        },
        line: 194
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 216,
            column: 90
          },
          end: {
            line: 216,
            column: 91
          }
        },
        loc: {
          start: {
            line: 216,
            column: 96
          },
          end: {
            line: 219,
            column: 5
          }
        },
        line: 216
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 222,
            column: 22
          },
          end: {
            line: 222,
            column: 23
          }
        },
        loc: {
          start: {
            line: 222,
            column: 34
          },
          end: {
            line: 224,
            column: 3
          }
        },
        line: 222
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        }, {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        }],
        line: 4
      },
      '1': {
        loc: {
          start: {
            line: 24,
            column: 16
          },
          end: {
            line: 24,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 24,
            column: 44
          },
          end: {
            line: 24,
            column: 51
          }
        }, {
          start: {
            line: 24,
            column: 54
          },
          end: {
            line: 24,
            column: 63
          }
        }],
        line: 24
      },
      '2': {
        loc: {
          start: {
            line: 40,
            column: 30
          },
          end: {
            line: 40,
            column: 143
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 40,
            column: 58
          },
          end: {
            line: 40,
            column: 138
          }
        }, {
          start: {
            line: 40,
            column: 141
          },
          end: {
            line: 40,
            column: 143
          }
        }],
        line: 40
      },
      '3': {
        loc: {
          start: {
            line: 41,
            column: 20
          },
          end: {
            line: 41,
            column: 136
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 41,
            column: 48
          },
          end: {
            line: 41,
            column: 131
          }
        }, {
          start: {
            line: 41,
            column: 134
          },
          end: {
            line: 41,
            column: 136
          }
        }],
        line: 41
      },
      '4': {
        loc: {
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 9
          }
        }, {
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 9
          }
        }],
        line: 45
      },
      '5': {
        loc: {
          start: {
            line: 50,
            column: 8
          },
          end: {
            line: 53,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 50,
            column: 8
          },
          end: {
            line: 53,
            column: 9
          }
        }, {
          start: {
            line: 50,
            column: 8
          },
          end: {
            line: 53,
            column: 9
          }
        }],
        line: 50
      },
      '6': {
        loc: {
          start: {
            line: 54,
            column: 8
          },
          end: {
            line: 57,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 54,
            column: 8
          },
          end: {
            line: 57,
            column: 9
          }
        }, {
          start: {
            line: 54,
            column: 8
          },
          end: {
            line: 57,
            column: 9
          }
        }],
        line: 54
      },
      '7': {
        loc: {
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 61,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 61,
            column: 9
          }
        }, {
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 61,
            column: 9
          }
        }],
        line: 58
      },
      '8': {
        loc: {
          start: {
            line: 89,
            column: 4
          },
          end: {
            line: 103,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 89,
            column: 4
          },
          end: {
            line: 103,
            column: 5
          }
        }, {
          start: {
            line: 89,
            column: 4
          },
          end: {
            line: 103,
            column: 5
          }
        }],
        line: 89
      },
      '9': {
        loc: {
          start: {
            line: 143,
            column: 6
          },
          end: {
            line: 145,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 143,
            column: 6
          },
          end: {
            line: 145,
            column: 7
          }
        }, {
          start: {
            line: 143,
            column: 6
          },
          end: {
            line: 145,
            column: 7
          }
        }],
        line: 143
      },
      '10': {
        loc: {
          start: {
            line: 157,
            column: 8
          },
          end: {
            line: 166,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 157,
            column: 8
          },
          end: {
            line: 166,
            column: 9
          }
        }, {
          start: {
            line: 157,
            column: 8
          },
          end: {
            line: 166,
            column: 9
          }
        }],
        line: 157
      },
      '11': {
        loc: {
          start: {
            line: 206,
            column: 4
          },
          end: {
            line: 209,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 206,
            column: 4
          },
          end: {
            line: 209,
            column: 5
          }
        }, {
          start: {
            line: 206,
            column: 4
          },
          end: {
            line: 209,
            column: 5
          }
        }],
        line: 206
      },
      '12': {
        loc: {
          start: {
            line: 206,
            column: 9
          },
          end: {
            line: 206,
            column: 42
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 206,
            column: 9
          },
          end: {
            line: 206,
            column: 36
          }
        }, {
          start: {
            line: 206,
            column: 40
          },
          end: {
            line: 206,
            column: 42
          }
        }],
        line: 206
      },
      '13': {
        loc: {
          start: {
            line: 211,
            column: 4
          },
          end: {
            line: 214,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 211,
            column: 4
          },
          end: {
            line: 214,
            column: 5
          }
        }, {
          start: {
            line: 211,
            column: 4
          },
          end: {
            line: 214,
            column: 5
          }
        }],
        line: 211
      },
      '14': {
        loc: {
          start: {
            line: 211,
            column: 9
          },
          end: {
            line: 211,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 211,
            column: 9
          },
          end: {
            line: 211,
            column: 41
          }
        }, {
          start: {
            line: 211,
            column: 45
          },
          end: {
            line: 211,
            column: 47
          }
        }],
        line: 211
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1bbr0xzn84.s[0]++;

angular.module('app').controller('ProblemCtrl', function ($scope, SweetAlert, $uibModal, $translate, $CRUDService, $otherHttpService, $location, $filter, $rootScope, $deltaActor) {
  cov_1bbr0xzn84.f[0]++;
  cov_1bbr0xzn84.s[1]++;

  $rootScope.processPageRight('2_3');
  cov_1bbr0xzn84.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_1bbr0xzn84.b[0][0]++;
    cov_1bbr0xzn84.s[3]++;

    return;
  } else {
    cov_1bbr0xzn84.b[0][1]++;
  }
  var PATH = (cov_1bbr0xzn84.s[4]++, 'Problem');
  cov_1bbr0xzn84.s[5]++;
  $deltaActor.addController($scope);
  var project = (cov_1bbr0xzn84.s[6]++, $rootScope.getCurrentProject());

  cov_1bbr0xzn84.s[7]++;
  $scope.id_t = Date.newDate().getTime();

  cov_1bbr0xzn84.s[8]++;
  $scope.page = 1;
  cov_1bbr0xzn84.s[9]++;
  $scope.listMissions = [];

  cov_1bbr0xzn84.s[10]++;
  $scope.listImpact = [{ id: 1, name: $translate.instant('RISK.IMPACTS.HIGH') }, { id: 2, name: $translate.instant('RISK.IMPACTS.MEDIUM') }, { id: 3, name: $translate.instant('RISK.IMPACTS.LOW') }];

  cov_1bbr0xzn84.s[11]++;
  $scope.getAll = function () {
    cov_1bbr0xzn84.f[1]++;
    cov_1bbr0xzn84.s[12]++;

    $scope.params = {
      toolbar: $rootScope.currentRight.u ? (cov_1bbr0xzn84.b[1][0]++, ['Add']) : (cov_1bbr0xzn84.b[1][1]++, undefined),
      editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },
      columns: [{ field: 'DATE_PROBLEM', width: 150, headerText: $translate.instant('PROBLEM.DATE_PROBLEM').toUpperCase(), format: 'yMd', clipMode: 'EllipsisWithTooltip' }, { field: 'NATURE_PROBLEM', headerText: $translate.instant('PROBLEM.NATURE_PROBLEM').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'DESCRIPTION_PROBLEM', headerText: $translate.instant('PROBLEM.DESCRIPTION').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'delayImpact.name', headerText: $translate.instant('PROBLEM.IMPACT_DELAY').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'financialImpact.name', headerText: $translate.instant('PROBLEM.IMPACT_FINANCE').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'responsible.FONCTION_ACTEUR', headerText: $translate.instant('PROBLEM.RESPONSIBLE').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { width: 100, headerText: $translate.instant('PROBLEM.RECOMMANDATIONS').toUpperCase(), commands: [{ type: 'openFile', buttonOption: { iconCss: ' e-icons e-file', cssClass: 'e-flat' } }] }, { headerText: '', width: 120,
        commands: [].concat($rootScope.currentRight.u ? (cov_1bbr0xzn84.b[2][0]++, [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } }]) : (cov_1bbr0xzn84.b[2][1]++, [])).concat($rootScope.currentRight.d ? (cov_1bbr0xzn84.b[3][0]++, [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }]) : (cov_1bbr0xzn84.b[3][1]++, []))
      }],
      commandClick: function commandClick(action) {
        cov_1bbr0xzn84.s[13]++;

        if (action.commandColumn.type === 'openFile') {
          cov_1bbr0xzn84.b[4][0]++;
          cov_1bbr0xzn84.s[14]++;

          $scope.openReport(action.rowData);
        } else {
          cov_1bbr0xzn84.b[4][1]++;
        }
      },
      actionBegin: function actionBegin(action) {
        cov_1bbr0xzn84.s[15]++;

        if (action.requestType === 'add') {
          cov_1bbr0xzn84.b[5][0]++;
          cov_1bbr0xzn84.s[16]++;

          action.cancel = true;
          cov_1bbr0xzn84.s[17]++;
          $scope.addItem();
        } else {
          cov_1bbr0xzn84.b[5][1]++;
        }
        cov_1bbr0xzn84.s[18]++;
        if (action.requestType === 'beginEdit') {
          cov_1bbr0xzn84.b[6][0]++;
          cov_1bbr0xzn84.s[19]++;

          action.cancel = true;
          cov_1bbr0xzn84.s[20]++;
          $scope.editItem(action.rowData);
        } else {
          cov_1bbr0xzn84.b[6][1]++;
        }
        cov_1bbr0xzn84.s[21]++;
        if (action.requestType === 'delete') {
          cov_1bbr0xzn84.b[7][0]++;
          cov_1bbr0xzn84.s[22]++;

          action.cancel = true;
          cov_1bbr0xzn84.s[23]++;
          $scope.deleteItem(action.data[0]);
        } else {
          cov_1bbr0xzn84.b[7][1]++;
        }
      }
    };

    cov_1bbr0xzn84.s[24]++;
    $scope.isloading = true;
    cov_1bbr0xzn84.s[25]++;
    $CRUDService.getAll(PATH, { get: 'all' }, function (data) {
      cov_1bbr0xzn84.f[2]++;
      cov_1bbr0xzn84.s[26]++;

      $scope.listProblems = data.map(function (item) {
        cov_1bbr0xzn84.f[3]++;
        cov_1bbr0xzn84.s[27]++;

        item.DATE_PROBLEM = Date.newDate(item.DATE_PROBLEM);

        cov_1bbr0xzn84.s[28]++;
        item.financialImpact = $scope.listImpact.find(function (type) {
          cov_1bbr0xzn84.f[4]++;
          cov_1bbr0xzn84.s[29]++;

          return type.id == item.IMPACT_FINAN;
        });

        cov_1bbr0xzn84.s[30]++;
        item.delayImpact = $scope.listImpact.find(function (type) {
          cov_1bbr0xzn84.f[5]++;
          cov_1bbr0xzn84.s[31]++;

          return type.id == item.IMPACT_DELAY;
        });

        cov_1bbr0xzn84.s[32]++;
        item.responsible = {
          id: item.IDACTEURS,
          FONCTION_ACTEUR: item.responsible
        };
        cov_1bbr0xzn84.s[33]++;
        return item;
      });
      cov_1bbr0xzn84.s[34]++;
      $scope.isloading = false;
    });
  };

  cov_1bbr0xzn84.s[35]++;
  $scope.openReport = function (mission) {
    cov_1bbr0xzn84.f[6]++;
    cov_1bbr0xzn84.s[36]++;

    if (!mission.IDRAPPORTS) {
      cov_1bbr0xzn84.b[8][0]++;
      cov_1bbr0xzn84.s[37]++;

      SweetAlert.swal({
        title: '', // $translate.instant('COMMON.CONFIRM'),
        text: $translate.instant('MISSIONS.NO_REPORT'),
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#c8c8c8',

        confirmButtonText: $translate.instant('COMMON.VALIDATE'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_1bbr0xzn84.f[7]++;
      });
    } else {
      cov_1bbr0xzn84.b[8][1]++;
    }
  };

  cov_1bbr0xzn84.s[38]++;
  $scope.addItem = function () {
    cov_1bbr0xzn84.f[8]++;
    cov_1bbr0xzn84.s[39]++;

    $scope.editItem({
      DESCRIPTION_PROBLEM: '',
      financialImpact: $scope.listImpact[0],
      delayImpact: $scope.listImpact[0],
      responsible: $scope.listData_actors_bksb[0],
      DATE_PROBLEM: Date.newDate(),
      NATURE_PROBLEM: '',
      id: 0
    });
  };
  cov_1bbr0xzn84.s[40]++;
  $scope.editItem = function (item) {
    cov_1bbr0xzn84.f[9]++;
    cov_1bbr0xzn84.s[41]++;

    $scope.error_code = false;
    cov_1bbr0xzn84.s[42]++;
    $scope.validateItem = angular.copy(item);
    cov_1bbr0xzn84.s[43]++;
    $uibModal.open({
      templateUrl: 'app/views/risk/edit_problem.html',
      controller: ProblemCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_1bbr0xzn84.s[44]++;
  $scope.deleteItem = function (item) {
    cov_1bbr0xzn84.f[10]++;
    cov_1bbr0xzn84.s[45]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1bbr0xzn84.f[11]++;
      cov_1bbr0xzn84.s[46]++;

      if (isconfirm) {
        cov_1bbr0xzn84.b[9][0]++;
        cov_1bbr0xzn84.s[47]++;

        return;
      } else {
        cov_1bbr0xzn84.b[9][1]++;
      }
      cov_1bbr0xzn84.s[48]++;
      $scope.isloading = true;
      var validateItem = (cov_1bbr0xzn84.s[49]++, {
        id: item.id,
        NUMERO_ORDR_MISS: item.NUMERO_ORDR_MISS
      });
      /* SweetAlert.swal({
        title: $translate.instant('COMMON.ERROR_DELETE'),
        text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
        type: 'warning'
      }); */
      cov_1bbr0xzn84.s[50]++;
      $CRUDService.delet(PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_1bbr0xzn84.f[12]++;
        cov_1bbr0xzn84.s[51]++;

        if (data < 0) {
          cov_1bbr0xzn84.b[10][0]++;
          cov_1bbr0xzn84.s[52]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
            type: 'warning'
          });
          cov_1bbr0xzn84.s[53]++;
          $scope.isloading = false;
        } else {
          cov_1bbr0xzn84.b[10][1]++;
          cov_1bbr0xzn84.s[54]++;

          $scope.getAll();
        }
      });
    });
  };

  cov_1bbr0xzn84.s[55]++;
  $scope.cancelItem = function () {
    cov_1bbr0xzn84.f[13]++;
    cov_1bbr0xzn84.s[56]++;

    $scope.validateItem = null;
  };

  cov_1bbr0xzn84.s[57]++;
  $scope.getAll();
});

function ProblemCtrl_edit($scope, $uibModalInstance, $filter, $CRUDService, $rootScope, $deltadate, $translate) {
  cov_1bbr0xzn84.f[14]++;

  var PATH = (cov_1bbr0xzn84.s[58]++, 'Problem');
  cov_1bbr0xzn84.s[59]++;
  $scope.error_code = false;

  cov_1bbr0xzn84.s[60]++;
  $scope.validateItem.financialImpact = $scope.listImpact.find(function (type) {
    cov_1bbr0xzn84.f[15]++;
    cov_1bbr0xzn84.s[61]++;

    return type.id == $scope.validateItem.financialImpact.id;
  });

  cov_1bbr0xzn84.s[62]++;
  $scope.validateItem.delayImpact = $scope.listImpact.find(function (type) {
    cov_1bbr0xzn84.f[16]++;
    cov_1bbr0xzn84.s[63]++;

    return type.id == $scope.validateItem.delayImpact.id;
  });

  cov_1bbr0xzn84.s[64]++;
  $scope.validateItem.responsible = $scope.listData_actors_bksb.find(function (actor) {
    cov_1bbr0xzn84.f[17]++;
    cov_1bbr0xzn84.s[65]++;

    return actor.id == $scope.validateItem.responsible.id;
  });

  cov_1bbr0xzn84.s[66]++;
  $scope.setValidData = function () {
    cov_1bbr0xzn84.f[18]++;

    var validateItem = (cov_1bbr0xzn84.s[67]++, {
      DESCRIPTION_PROBLEM: $scope.validateItem.DESCRIPTION_PROBLEM.trim(),
      IMPACT_FINAN: $scope.validateItem.financialImpact.id,
      IMPACT_DELAY: $scope.validateItem.delayImpact.id,
      IDACTEURS: $scope.validateItem.responsible.id,
      DATE_PROBLEM: $deltadate.format($scope.validateItem.DATE_PROBLEM, 'YYYY-MM-DD'),
      NATURE_PROBLEM: $scope.validateItem.NATURE_PROBLEM.trim(),
      id: $scope.validateItem.id
    });

    cov_1bbr0xzn84.s[68]++;
    $scope.error_code = 0;
    cov_1bbr0xzn84.s[69]++;
    if (((cov_1bbr0xzn84.b[12][0]++, validateItem.NATURE_PROBLEM) || (cov_1bbr0xzn84.b[12][1]++, '')).trim().length == 0) {
      cov_1bbr0xzn84.b[11][0]++;
      cov_1bbr0xzn84.s[70]++;

      $scope.error_code = 1;
      cov_1bbr0xzn84.s[71]++;
      return;
    } else {
      cov_1bbr0xzn84.b[11][1]++;
    }

    cov_1bbr0xzn84.s[72]++;
    if (((cov_1bbr0xzn84.b[14][0]++, validateItem.DESCRIPTION_PROBLEM) || (cov_1bbr0xzn84.b[14][1]++, '')).trim().length == 0) {
      cov_1bbr0xzn84.b[13][0]++;
      cov_1bbr0xzn84.s[73]++;

      $scope.error_code = 2;
      cov_1bbr0xzn84.s[74]++;
      return;
    } else {
      cov_1bbr0xzn84.b[13][1]++;
    }

    cov_1bbr0xzn84.s[75]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function () {
      cov_1bbr0xzn84.f[19]++;
      cov_1bbr0xzn84.s[76]++;

      $scope.closeModal();
      cov_1bbr0xzn84.s[77]++;
      $scope.getAll();
    });
  };

  cov_1bbr0xzn84.s[78]++;
  $scope.closeModal = function () {
    cov_1bbr0xzn84.f[20]++;
    cov_1bbr0xzn84.s[79]++;

    $uibModalInstance.dismiss('cancel');
  };
}