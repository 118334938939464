'use strict';

var cov_1oes3fbrts = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/photolibrary/photolibrayCtrl.js',
      hash = 'dccbca55124349940b4ae131b2a728bd48498d77',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/photolibrary/photolibrayCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 8,
          column: 0
        },
        end: {
          line: 463,
          column: 3
        }
      },
      '1': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 37
        }
      },
      '2': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 12,
          column: 3
        }
      },
      '3': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 11,
          column: 11
        }
      },
      '4': {
        start: {
          line: 13,
          column: 19
        },
        end: {
          line: 13,
          column: 32
        }
      },
      '5': {
        start: {
          line: 14,
          column: 19
        },
        end: {
          line: 14,
          column: 40
        }
      },
      '6': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 39
        }
      },
      '7': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 16,
          column: 27
        }
      },
      '8': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 17,
          column: 22
        }
      },
      '9': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 18,
          column: 20
        }
      },
      '10': {
        start: {
          line: 21,
          column: 2
        },
        end: {
          line: 26,
          column: 3
        }
      },
      '11': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 25,
          column: 6
        }
      },
      '12': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 27,
          column: 23
        }
      },
      '13': {
        start: {
          line: 29,
          column: 2
        },
        end: {
          line: 36,
          column: 3
        }
      },
      '14': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 30,
          column: 47
        }
      },
      '15': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 31,
          column: 58
        }
      },
      '16': {
        start: {
          line: 34,
          column: 4
        },
        end: {
          line: 34,
          column: 41
        }
      },
      '17': {
        start: {
          line: 38,
          column: 2
        },
        end: {
          line: 61,
          column: 4
        }
      },
      '18': {
        start: {
          line: 39,
          column: 21
        },
        end: {
          line: 39,
          column: 22
        }
      },
      '19': {
        start: {
          line: 41,
          column: 4
        },
        end: {
          line: 43,
          column: 5
        }
      },
      '20': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 42,
          column: 64
        }
      },
      '21': {
        start: {
          line: 45,
          column: 4
        },
        end: {
          line: 60,
          column: 7
        }
      },
      '22': {
        start: {
          line: 63,
          column: 2
        },
        end: {
          line: 87,
          column: 4
        }
      },
      '23': {
        start: {
          line: 64,
          column: 21
        },
        end: {
          line: 64,
          column: 22
        }
      },
      '24': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 68,
          column: 5
        }
      },
      '25': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 67,
          column: 64
        }
      },
      '26': {
        start: {
          line: 70,
          column: 4
        },
        end: {
          line: 86,
          column: 7
        }
      },
      '27': {
        start: {
          line: 89,
          column: 2
        },
        end: {
          line: 96,
          column: 4
        }
      },
      '28': {
        start: {
          line: 91,
          column: 6
        },
        end: {
          line: 91,
          column: 43
        }
      },
      '29': {
        start: {
          line: 92,
          column: 6
        },
        end: {
          line: 92,
          column: 74
        }
      },
      '30': {
        start: {
          line: 93,
          column: 6
        },
        end: {
          line: 93,
          column: 24
        }
      },
      '31': {
        start: {
          line: 97,
          column: 2
        },
        end: {
          line: 101,
          column: 4
        }
      },
      '32': {
        start: {
          line: 98,
          column: 4
        },
        end: {
          line: 98,
          column: 41
        }
      },
      '33': {
        start: {
          line: 99,
          column: 4
        },
        end: {
          line: 99,
          column: 72
        }
      },
      '34': {
        start: {
          line: 100,
          column: 4
        },
        end: {
          line: 100,
          column: 32
        }
      },
      '35': {
        start: {
          line: 102,
          column: 2
        },
        end: {
          line: 128,
          column: 4
        }
      },
      '36': {
        start: {
          line: 103,
          column: 4
        },
        end: {
          line: 127,
          column: 7
        }
      },
      '37': {
        start: {
          line: 115,
          column: 6
        },
        end: {
          line: 117,
          column: 7
        }
      },
      '38': {
        start: {
          line: 116,
          column: 8
        },
        end: {
          line: 116,
          column: 15
        }
      },
      '39': {
        start: {
          line: 118,
          column: 6
        },
        end: {
          line: 118,
          column: 30
        }
      },
      '40': {
        start: {
          line: 119,
          column: 6
        },
        end: {
          line: 126,
          column: 9
        }
      },
      '41': {
        start: {
          line: 120,
          column: 8
        },
        end: {
          line: 120,
          column: 33
        }
      },
      '42': {
        start: {
          line: 121,
          column: 8
        },
        end: {
          line: 123,
          column: 9
        }
      },
      '43': {
        start: {
          line: 122,
          column: 10
        },
        end: {
          line: 122,
          column: 17
        }
      },
      '44': {
        start: {
          line: 124,
          column: 8
        },
        end: {
          line: 124,
          column: 69
        }
      },
      '45': {
        start: {
          line: 125,
          column: 8
        },
        end: {
          line: 125,
          column: 29
        }
      },
      '46': {
        start: {
          line: 130,
          column: 2
        },
        end: {
          line: 134,
          column: 4
        }
      },
      '47': {
        start: {
          line: 131,
          column: 4
        },
        end: {
          line: 131,
          column: 31
        }
      },
      '48': {
        start: {
          line: 132,
          column: 4
        },
        end: {
          line: 132,
          column: 67
        }
      },
      '49': {
        start: {
          line: 133,
          column: 4
        },
        end: {
          line: 133,
          column: 24
        }
      },
      '50': {
        start: {
          line: 136,
          column: 2
        },
        end: {
          line: 140,
          column: 4
        }
      },
      '51': {
        start: {
          line: 137,
          column: 4
        },
        end: {
          line: 137,
          column: 31
        }
      },
      '52': {
        start: {
          line: 138,
          column: 4
        },
        end: {
          line: 138,
          column: 67
        }
      },
      '53': {
        start: {
          line: 139,
          column: 4
        },
        end: {
          line: 139,
          column: 24
        }
      },
      '54': {
        start: {
          line: 142,
          column: 2
        },
        end: {
          line: 145,
          column: 4
        }
      },
      '55': {
        start: {
          line: 143,
          column: 4
        },
        end: {
          line: 143,
          column: 25
        }
      },
      '56': {
        start: {
          line: 144,
          column: 4
        },
        end: {
          line: 144,
          column: 31
        }
      },
      '57': {
        start: {
          line: 147,
          column: 2
        },
        end: {
          line: 157,
          column: 4
        }
      },
      '58': {
        start: {
          line: 148,
          column: 4
        },
        end: {
          line: 156,
          column: 5
        }
      },
      '59': {
        start: {
          line: 149,
          column: 6
        },
        end: {
          line: 153,
          column: 7
        }
      },
      '60': {
        start: {
          line: 150,
          column: 8
        },
        end: {
          line: 150,
          column: 29
        }
      },
      '61': {
        start: {
          line: 152,
          column: 8
        },
        end: {
          line: 152,
          column: 29
        }
      },
      '62': {
        start: {
          line: 155,
          column: 6
        },
        end: {
          line: 155,
          column: 30
        }
      },
      '63': {
        start: {
          line: 158,
          column: 2
        },
        end: {
          line: 164,
          column: 4
        }
      },
      '64': {
        start: {
          line: 159,
          column: 4
        },
        end: {
          line: 163,
          column: 5
        }
      },
      '65': {
        start: {
          line: 160,
          column: 6
        },
        end: {
          line: 160,
          column: 29
        }
      },
      '66': {
        start: {
          line: 162,
          column: 6
        },
        end: {
          line: 162,
          column: 32
        }
      },
      '67': {
        start: {
          line: 165,
          column: 2
        },
        end: {
          line: 214,
          column: 4
        }
      },
      '68': {
        start: {
          line: 166,
          column: 16
        },
        end: {
          line: 166,
          column: 21
        }
      },
      '69': {
        start: {
          line: 167,
          column: 4
        },
        end: {
          line: 189,
          column: 5
        }
      },
      '70': {
        start: {
          line: 168,
          column: 6
        },
        end: {
          line: 168,
          column: 19
        }
      },
      '71': {
        start: {
          line: 170,
          column: 6
        },
        end: {
          line: 187,
          column: 7
        }
      },
      '72': {
        start: {
          line: 171,
          column: 8
        },
        end: {
          line: 173,
          column: 9
        }
      },
      '73': {
        start: {
          line: 172,
          column: 10
        },
        end: {
          line: 172,
          column: 19
        }
      },
      '74': {
        start: {
          line: 174,
          column: 8
        },
        end: {
          line: 186,
          column: 9
        }
      },
      '75': {
        start: {
          line: 175,
          column: 10
        },
        end: {
          line: 175,
          column: 49
        }
      },
      '76': {
        start: {
          line: 176,
          column: 10
        },
        end: {
          line: 176,
          column: 49
        }
      },
      '77': {
        start: {
          line: 177,
          column: 10
        },
        end: {
          line: 177,
          column: 104
        }
      },
      '78': {
        start: {
          line: 178,
          column: 10
        },
        end: {
          line: 178,
          column: 55
        }
      },
      '79': {
        start: {
          line: 179,
          column: 10
        },
        end: {
          line: 179,
          column: 49
        }
      },
      '80': {
        start: {
          line: 180,
          column: 10
        },
        end: {
          line: 180,
          column: 49
        }
      },
      '81': {
        start: {
          line: 181,
          column: 10
        },
        end: {
          line: 181,
          column: 49
        }
      },
      '82': {
        start: {
          line: 182,
          column: 10
        },
        end: {
          line: 182,
          column: 49
        }
      },
      '83': {
        start: {
          line: 183,
          column: 10
        },
        end: {
          line: 183,
          column: 57
        }
      },
      '84': {
        start: {
          line: 184,
          column: 10
        },
        end: {
          line: 184,
          column: 55
        }
      },
      '85': {
        start: {
          line: 185,
          column: 10
        },
        end: {
          line: 185,
          column: 16
        }
      },
      '86': {
        start: {
          line: 188,
          column: 6
        },
        end: {
          line: 188,
          column: 27
        }
      },
      '87': {
        start: {
          line: 191,
          column: 22
        },
        end: {
          line: 191,
          column: 55
        }
      },
      '88': {
        start: {
          line: 192,
          column: 4
        },
        end: {
          line: 192,
          column: 81
        }
      },
      '89': {
        start: {
          line: 193,
          column: 4
        },
        end: {
          line: 193,
          column: 48
        }
      },
      '90': {
        start: {
          line: 194,
          column: 4
        },
        end: {
          line: 194,
          column: 56
        }
      },
      '91': {
        start: {
          line: 195,
          column: 4
        },
        end: {
          line: 195,
          column: 29
        }
      },
      '92': {
        start: {
          line: 197,
          column: 4
        },
        end: {
          line: 213,
          column: 7
        }
      },
      '93': {
        start: {
          line: 198,
          column: 6
        },
        end: {
          line: 200,
          column: 7
        }
      },
      '94': {
        start: {
          line: 199,
          column: 8
        },
        end: {
          line: 199,
          column: 15
        }
      },
      '95': {
        start: {
          line: 201,
          column: 6
        },
        end: {
          line: 204,
          column: 7
        }
      },
      '96': {
        start: {
          line: 202,
          column: 8
        },
        end: {
          line: 202,
          column: 33
        }
      },
      '97': {
        start: {
          line: 203,
          column: 8
        },
        end: {
          line: 203,
          column: 15
        }
      },
      '98': {
        start: {
          line: 205,
          column: 6
        },
        end: {
          line: 212,
          column: 7
        }
      },
      '99': {
        start: {
          line: 206,
          column: 8
        },
        end: {
          line: 206,
          column: 41
        }
      },
      '100': {
        start: {
          line: 207,
          column: 8
        },
        end: {
          line: 207,
          column: 29
        }
      },
      '101': {
        start: {
          line: 209,
          column: 8
        },
        end: {
          line: 209,
          column: 33
        }
      },
      '102': {
        start: {
          line: 210,
          column: 8
        },
        end: {
          line: 210,
          column: 29
        }
      },
      '103': {
        start: {
          line: 211,
          column: 8
        },
        end: {
          line: 211,
          column: 69
        }
      },
      '104': {
        start: {
          line: 215,
          column: 21
        },
        end: {
          line: 236,
          column: 3
        }
      },
      '105': {
        start: {
          line: 216,
          column: 14
        },
        end: {
          line: 216,
          column: 15
        }
      },
      '106': {
        start: {
          line: 217,
          column: 4
        },
        end: {
          line: 221,
          column: 5
        }
      },
      '107': {
        start: {
          line: 218,
          column: 6
        },
        end: {
          line: 218,
          column: 14
        }
      },
      '108': {
        start: {
          line: 220,
          column: 6
        },
        end: {
          line: 220,
          column: 25
        }
      },
      '109': {
        start: {
          line: 222,
          column: 4
        },
        end: {
          line: 222,
          column: 28
        }
      },
      '110': {
        start: {
          line: 224,
          column: 17
        },
        end: {
          line: 224,
          column: 38
        }
      },
      '111': {
        start: {
          line: 225,
          column: 4
        },
        end: {
          line: 227,
          column: 5
        }
      },
      '112': {
        start: {
          line: 226,
          column: 6
        },
        end: {
          line: 226,
          column: 67
        }
      },
      '113': {
        start: {
          line: 229,
          column: 4
        },
        end: {
          line: 235,
          column: 7
        }
      },
      '114': {
        start: {
          line: 230,
          column: 6
        },
        end: {
          line: 233,
          column: 7
        }
      },
      '115': {
        start: {
          line: 231,
          column: 8
        },
        end: {
          line: 231,
          column: 72
        }
      },
      '116': {
        start: {
          line: 232,
          column: 8
        },
        end: {
          line: 232,
          column: 41
        }
      },
      '117': {
        start: {
          line: 234,
          column: 6
        },
        end: {
          line: 234,
          column: 31
        }
      },
      '118': {
        start: {
          line: 237,
          column: 21
        },
        end: {
          line: 273,
          column: 3
        }
      },
      '119': {
        start: {
          line: 238,
          column: 14
        },
        end: {
          line: 238,
          column: 15
        }
      },
      '120': {
        start: {
          line: 239,
          column: 4
        },
        end: {
          line: 243,
          column: 5
        }
      },
      '121': {
        start: {
          line: 240,
          column: 6
        },
        end: {
          line: 240,
          column: 14
        }
      },
      '122': {
        start: {
          line: 242,
          column: 6
        },
        end: {
          line: 242,
          column: 25
        }
      },
      '123': {
        start: {
          line: 244,
          column: 4
        },
        end: {
          line: 244,
          column: 28
        }
      },
      '124': {
        start: {
          line: 245,
          column: 17
        },
        end: {
          line: 245,
          column: 39
        }
      },
      '125': {
        start: {
          line: 246,
          column: 4
        },
        end: {
          line: 248,
          column: 5
        }
      },
      '126': {
        start: {
          line: 247,
          column: 6
        },
        end: {
          line: 247,
          column: 85
        }
      },
      '127': {
        start: {
          line: 250,
          column: 4
        },
        end: {
          line: 272,
          column: 7
        }
      },
      '128': {
        start: {
          line: 251,
          column: 6
        },
        end: {
          line: 253,
          column: 9
        }
      },
      '129': {
        start: {
          line: 252,
          column: 8
        },
        end: {
          line: 252,
          column: 34
        }
      },
      '130': {
        start: {
          line: 254,
          column: 6
        },
        end: {
          line: 261,
          column: 7
        }
      },
      '131': {
        start: {
          line: 255,
          column: 8
        },
        end: {
          line: 255,
          column: 36
        }
      },
      '132': {
        start: {
          line: 256,
          column: 8
        },
        end: {
          line: 256,
          column: 36
        }
      },
      '133': {
        start: {
          line: 257,
          column: 8
        },
        end: {
          line: 257,
          column: 52
        }
      },
      '134': {
        start: {
          line: 258,
          column: 8
        },
        end: {
          line: 258,
          column: 43
        }
      },
      '135': {
        start: {
          line: 259,
          column: 8
        },
        end: {
          line: 259,
          column: 51
        }
      },
      '136': {
        start: {
          line: 260,
          column: 8
        },
        end: {
          line: 260,
          column: 41
        }
      },
      '137': {
        start: {
          line: 271,
          column: 6
        },
        end: {
          line: 271,
          column: 31
        }
      },
      '138': {
        start: {
          line: 274,
          column: 2
        },
        end: {
          line: 291,
          column: 4
        }
      },
      '139': {
        start: {
          line: 275,
          column: 4
        },
        end: {
          line: 278,
          column: 6
        }
      },
      '140': {
        start: {
          line: 279,
          column: 4
        },
        end: {
          line: 290,
          column: 7
        }
      },
      '141': {
        start: {
          line: 292,
          column: 18
        },
        end: {
          line: 298,
          column: 3
        }
      },
      '142': {
        start: {
          line: 293,
          column: 4
        },
        end: {
          line: 293,
          column: 33
        }
      },
      '143': {
        start: {
          line: 294,
          column: 4
        },
        end: {
          line: 294,
          column: 21
        }
      },
      '144': {
        start: {
          line: 295,
          column: 4
        },
        end: {
          line: 297,
          column: 5
        }
      },
      '145': {
        start: {
          line: 296,
          column: 6
        },
        end: {
          line: 296,
          column: 23
        }
      },
      '146': {
        start: {
          line: 299,
          column: 2
        },
        end: {
          line: 308,
          column: 4
        }
      },
      '147': {
        start: {
          line: 300,
          column: 4
        },
        end: {
          line: 300,
          column: 16
        }
      },
      '148': {
        start: {
          line: 309,
          column: 2
        },
        end: {
          line: 323,
          column: 4
        }
      },
      '149': {
        start: {
          line: 310,
          column: 15
        },
        end: {
          line: 310,
          column: 17
        }
      },
      '150': {
        start: {
          line: 311,
          column: 4
        },
        end: {
          line: 314,
          column: 5
        }
      },
      '151': {
        start: {
          line: 312,
          column: 6
        },
        end: {
          line: 312,
          column: 45
        }
      },
      '152': {
        start: {
          line: 313,
          column: 6
        },
        end: {
          line: 313,
          column: 35
        }
      },
      '153': {
        start: {
          line: 315,
          column: 4
        },
        end: {
          line: 315,
          column: 43
        }
      },
      '154': {
        start: {
          line: 316,
          column: 4
        },
        end: {
          line: 318,
          column: 5
        }
      },
      '155': {
        start: {
          line: 317,
          column: 6
        },
        end: {
          line: 317,
          column: 45
        }
      },
      '156': {
        start: {
          line: 320,
          column: 19
        },
        end: {
          line: 320,
          column: 59
        }
      },
      '157': {
        start: {
          line: 322,
          column: 4
        },
        end: {
          line: 322,
          column: 47
        }
      },
      '158': {
        start: {
          line: 324,
          column: 2
        },
        end: {
          line: 352,
          column: 4
        }
      },
      '159': {
        start: {
          line: 325,
          column: 4
        },
        end: {
          line: 331,
          column: 5
        }
      },
      '160': {
        start: {
          line: 326,
          column: 6
        },
        end: {
          line: 328,
          column: 7
        }
      },
      '161': {
        start: {
          line: 327,
          column: 8
        },
        end: {
          line: 327,
          column: 15
        }
      },
      '162': {
        start: {
          line: 330,
          column: 6
        },
        end: {
          line: 330,
          column: 26
        }
      },
      '163': {
        start: {
          line: 332,
          column: 4
        },
        end: {
          line: 344,
          column: 5
        }
      },
      '164': {
        start: {
          line: 333,
          column: 6
        },
        end: {
          line: 335,
          column: 8
        }
      },
      '165': {
        start: {
          line: 334,
          column: 8
        },
        end: {
          line: 334,
          column: 36
        }
      },
      '166': {
        start: {
          line: 336,
          column: 6
        },
        end: {
          line: 336,
          column: 32
        }
      },
      '167': {
        start: {
          line: 338,
          column: 6
        },
        end: {
          line: 343,
          column: 7
        }
      },
      '168': {
        start: {
          line: 339,
          column: 8
        },
        end: {
          line: 341,
          column: 9
        }
      },
      '169': {
        start: {
          line: 340,
          column: 10
        },
        end: {
          line: 340,
          column: 16
        }
      },
      '170': {
        start: {
          line: 342,
          column: 8
        },
        end: {
          line: 342,
          column: 29
        }
      },
      '171': {
        start: {
          line: 345,
          column: 4
        },
        end: {
          line: 345,
          column: 32
        }
      },
      '172': {
        start: {
          line: 346,
          column: 4
        },
        end: {
          line: 348,
          column: 5
        }
      },
      '173': {
        start: {
          line: 347,
          column: 6
        },
        end: {
          line: 347,
          column: 120
        }
      },
      '174': {
        start: {
          line: 350,
          column: 4
        },
        end: {
          line: 350,
          column: 18
        }
      },
      '175': {
        start: {
          line: 351,
          column: 4
        },
        end: {
          line: 351,
          column: 21
        }
      },
      '176': {
        start: {
          line: 353,
          column: 2
        },
        end: {
          line: 362,
          column: 4
        }
      },
      '177': {
        start: {
          line: 354,
          column: 4
        },
        end: {
          line: 361,
          column: 7
        }
      },
      '178': {
        start: {
          line: 363,
          column: 2
        },
        end: {
          line: 374,
          column: 4
        }
      },
      '179': {
        start: {
          line: 365,
          column: 4
        },
        end: {
          line: 365,
          column: 48
        }
      },
      '180': {
        start: {
          line: 366,
          column: 4
        },
        end: {
          line: 373,
          column: 7
        }
      },
      '181': {
        start: {
          line: 376,
          column: 2
        },
        end: {
          line: 378,
          column: 4
        }
      },
      '182': {
        start: {
          line: 377,
          column: 4
        },
        end: {
          line: 377,
          column: 28
        }
      },
      '183': {
        start: {
          line: 379,
          column: 2
        },
        end: {
          line: 401,
          column: 4
        }
      },
      '184': {
        start: {
          line: 380,
          column: 4
        },
        end: {
          line: 400,
          column: 7
        }
      },
      '185': {
        start: {
          line: 391,
          column: 6
        },
        end: {
          line: 393,
          column: 7
        }
      },
      '186': {
        start: {
          line: 392,
          column: 8
        },
        end: {
          line: 392,
          column: 15
        }
      },
      '187': {
        start: {
          line: 394,
          column: 6
        },
        end: {
          line: 394,
          column: 30
        }
      },
      '188': {
        start: {
          line: 395,
          column: 6
        },
        end: {
          line: 399,
          column: 9
        }
      },
      '189': {
        start: {
          line: 396,
          column: 8
        },
        end: {
          line: 396,
          column: 33
        }
      },
      '190': {
        start: {
          line: 397,
          column: 8
        },
        end: {
          line: 397,
          column: 69
        }
      },
      '191': {
        start: {
          line: 398,
          column: 8
        },
        end: {
          line: 398,
          column: 27
        }
      },
      '192': {
        start: {
          line: 402,
          column: 2
        },
        end: {
          line: 402,
          column: 22
        }
      },
      '193': {
        start: {
          line: 404,
          column: 2
        },
        end: {
          line: 410,
          column: 5
        }
      },
      '194': {
        start: {
          line: 411,
          column: 2
        },
        end: {
          line: 414,
          column: 4
        }
      },
      '195': {
        start: {
          line: 412,
          column: 4
        },
        end: {
          line: 412,
          column: 106
        }
      },
      '196': {
        start: {
          line: 413,
          column: 4
        },
        end: {
          line: 413,
          column: 22
        }
      },
      '197': {
        start: {
          line: 415,
          column: 2
        },
        end: {
          line: 425,
          column: 4
        }
      },
      '198': {
        start: {
          line: 416,
          column: 4
        },
        end: {
          line: 424,
          column: 5
        }
      },
      '199': {
        start: {
          line: 417,
          column: 6
        },
        end: {
          line: 423,
          column: 7
        }
      },
      '200': {
        start: {
          line: 418,
          column: 8
        },
        end: {
          line: 420,
          column: 9
        }
      },
      '201': {
        start: {
          line: 419,
          column: 10
        },
        end: {
          line: 419,
          column: 19
        }
      },
      '202': {
        start: {
          line: 421,
          column: 8
        },
        end: {
          line: 421,
          column: 52
        }
      },
      '203': {
        start: {
          line: 422,
          column: 8
        },
        end: {
          line: 422,
          column: 86
        }
      },
      '204': {
        start: {
          line: 426,
          column: 19
        },
        end: {
          line: 432,
          column: 4
        }
      },
      '205': {
        start: {
          line: 433,
          column: 2
        },
        end: {
          line: 442,
          column: 4
        }
      },
      '206': {
        start: {
          line: 434,
          column: 4
        },
        end: {
          line: 434,
          column: 32
        }
      },
      '207': {
        start: {
          line: 435,
          column: 4
        },
        end: {
          line: 435,
          column: 27
        }
      },
      '208': {
        start: {
          line: 436,
          column: 4
        },
        end: {
          line: 441,
          column: 5
        }
      },
      '209': {
        start: {
          line: 437,
          column: 6
        },
        end: {
          line: 437,
          column: 44
        }
      },
      '210': {
        start: {
          line: 438,
          column: 6
        },
        end: {
          line: 438,
          column: 24
        }
      },
      '211': {
        start: {
          line: 440,
          column: 6
        },
        end: {
          line: 440,
          column: 27
        }
      },
      '212': {
        start: {
          line: 443,
          column: 2
        },
        end: {
          line: 456,
          column: 4
        }
      },
      '213': {
        start: {
          line: 444,
          column: 4
        },
        end: {
          line: 444,
          column: 29
        }
      },
      '214': {
        start: {
          line: 445,
          column: 4
        },
        end: {
          line: 453,
          column: 5
        }
      },
      '215': {
        start: {
          line: 446,
          column: 6
        },
        end: {
          line: 451,
          column: 7
        }
      },
      '216': {
        start: {
          line: 447,
          column: 8
        },
        end: {
          line: 450,
          column: 9
        }
      },
      '217': {
        start: {
          line: 448,
          column: 10
        },
        end: {
          line: 448,
          column: 28
        }
      },
      '218': {
        start: {
          line: 449,
          column: 10
        },
        end: {
          line: 449,
          column: 16
        }
      },
      '219': {
        start: {
          line: 452,
          column: 6
        },
        end: {
          line: 452,
          column: 13
        }
      },
      '220': {
        start: {
          line: 454,
          column: 4
        },
        end: {
          line: 454,
          column: 25
        }
      },
      '221': {
        start: {
          line: 455,
          column: 4
        },
        end: {
          line: 455,
          column: 65
        }
      },
      '222': {
        start: {
          line: 457,
          column: 2
        },
        end: {
          line: 462,
          column: 4
        }
      },
      '223': {
        start: {
          line: 458,
          column: 4
        },
        end: {
          line: 458,
          column: 29
        }
      },
      '224': {
        start: {
          line: 460,
          column: 4
        },
        end: {
          line: 460,
          column: 25
        }
      },
      '225': {
        start: {
          line: 461,
          column: 4
        },
        end: {
          line: 461,
          column: 65
        }
      },
      '226': {
        start: {
          line: 466,
          column: 19
        },
        end: {
          line: 466,
          column: 40
        }
      },
      '227': {
        start: {
          line: 467,
          column: 2
        },
        end: {
          line: 480,
          column: 4
        }
      },
      '228': {
        start: {
          line: 468,
          column: 18
        },
        end: {
          line: 468,
          column: 123
        }
      },
      '229': {
        start: {
          line: 469,
          column: 16
        },
        end: {
          line: 469,
          column: 21
        }
      },
      '230': {
        start: {
          line: 470,
          column: 4
        },
        end: {
          line: 473,
          column: 5
        }
      },
      '231': {
        start: {
          line: 471,
          column: 6
        },
        end: {
          line: 471,
          column: 92
        }
      },
      '232': {
        start: {
          line: 472,
          column: 6
        },
        end: {
          line: 472,
          column: 19
        }
      },
      '233': {
        start: {
          line: 474,
          column: 4
        },
        end: {
          line: 479,
          column: 7
        }
      },
      '234': {
        start: {
          line: 475,
          column: 6
        },
        end: {
          line: 475,
          column: 39
        }
      },
      '235': {
        start: {
          line: 476,
          column: 6
        },
        end: {
          line: 476,
          column: 67
        }
      },
      '236': {
        start: {
          line: 477,
          column: 6
        },
        end: {
          line: 477,
          column: 25
        }
      },
      '237': {
        start: {
          line: 478,
          column: 6
        },
        end: {
          line: 478,
          column: 26
        }
      },
      '238': {
        start: {
          line: 481,
          column: 2
        },
        end: {
          line: 483,
          column: 4
        }
      },
      '239': {
        start: {
          line: 482,
          column: 4
        },
        end: {
          line: 482,
          column: 40
        }
      },
      '240': {
        start: {
          line: 486,
          column: 2
        },
        end: {
          line: 489,
          column: 4
        }
      },
      '241': {
        start: {
          line: 491,
          column: 2
        },
        end: {
          line: 491,
          column: 24
        }
      },
      '242': {
        start: {
          line: 493,
          column: 19
        },
        end: {
          line: 493,
          column: 20
        }
      },
      '243': {
        start: {
          line: 494,
          column: 2
        },
        end: {
          line: 499,
          column: 3
        }
      },
      '244': {
        start: {
          line: 495,
          column: 4
        },
        end: {
          line: 498,
          column: 5
        }
      },
      '245': {
        start: {
          line: 496,
          column: 6
        },
        end: {
          line: 496,
          column: 21
        }
      },
      '246': {
        start: {
          line: 497,
          column: 6
        },
        end: {
          line: 497,
          column: 12
        }
      },
      '247': {
        start: {
          line: 501,
          column: 2
        },
        end: {
          line: 514,
          column: 3
        }
      },
      '248': {
        start: {
          line: 502,
          column: 15
        },
        end: {
          line: 502,
          column: 89
        }
      },
      '249': {
        start: {
          line: 503,
          column: 4
        },
        end: {
          line: 505,
          column: 5
        }
      },
      '250': {
        start: {
          line: 504,
          column: 6
        },
        end: {
          line: 504,
          column: 12
        }
      },
      '251': {
        start: {
          line: 506,
          column: 4
        },
        end: {
          line: 508,
          column: 5
        }
      },
      '252': {
        start: {
          line: 507,
          column: 6
        },
        end: {
          line: 507,
          column: 15
        }
      },
      '253': {
        start: {
          line: 509,
          column: 4
        },
        end: {
          line: 513,
          column: 7
        }
      },
      '254': {
        start: {
          line: 515,
          column: 2
        },
        end: {
          line: 517,
          column: 4
        }
      },
      '255': {
        start: {
          line: 516,
          column: 4
        },
        end: {
          line: 516,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 8,
            column: 53
          },
          end: {
            line: 8,
            column: 54
          }
        },
        loc: {
          start: {
            line: 8,
            column: 217
          },
          end: {
            line: 463,
            column: 1
          }
        },
        line: 8
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 38,
            column: 23
          },
          end: {
            line: 38,
            column: 24
          }
        },
        loc: {
          start: {
            line: 38,
            column: 35
          },
          end: {
            line: 61,
            column: 3
          }
        },
        line: 38
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 63,
            column: 23
          },
          end: {
            line: 63,
            column: 24
          }
        },
        loc: {
          start: {
            line: 63,
            column: 35
          },
          end: {
            line: 87,
            column: 3
          }
        },
        line: 63
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 97,
            column: 28
          },
          end: {
            line: 97,
            column: 29
          }
        },
        loc: {
          start: {
            line: 97,
            column: 44
          },
          end: {
            line: 101,
            column: 3
          }
        },
        line: 97
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 102,
            column: 23
          },
          end: {
            line: 102,
            column: 24
          }
        },
        loc: {
          start: {
            line: 102,
            column: 39
          },
          end: {
            line: 128,
            column: 3
          }
        },
        line: 102
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 114,
            column: 7
          },
          end: {
            line: 114,
            column: 8
          }
        },
        loc: {
          start: {
            line: 114,
            column: 20
          },
          end: {
            line: 127,
            column: 5
          }
        },
        line: 114
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 119,
            column: 84
          },
          end: {
            line: 119,
            column: 85
          }
        },
        loc: {
          start: {
            line: 119,
            column: 92
          },
          end: {
            line: 126,
            column: 7
          }
        },
        line: 119
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 130,
            column: 21
          },
          end: {
            line: 130,
            column: 22
          }
        },
        loc: {
          start: {
            line: 130,
            column: 37
          },
          end: {
            line: 134,
            column: 3
          }
        },
        line: 130
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 136,
            column: 21
          },
          end: {
            line: 136,
            column: 22
          }
        },
        loc: {
          start: {
            line: 136,
            column: 37
          },
          end: {
            line: 140,
            column: 3
          }
        },
        line: 136
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 142,
            column: 23
          },
          end: {
            line: 142,
            column: 24
          }
        },
        loc: {
          start: {
            line: 142,
            column: 35
          },
          end: {
            line: 145,
            column: 3
          }
        },
        line: 142
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 147,
            column: 16
          },
          end: {
            line: 147,
            column: 17
          }
        },
        loc: {
          start: {
            line: 147,
            column: 32
          },
          end: {
            line: 157,
            column: 3
          }
        },
        line: 147
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 158,
            column: 18
          },
          end: {
            line: 158,
            column: 19
          }
        },
        loc: {
          start: {
            line: 158,
            column: 34
          },
          end: {
            line: 164,
            column: 3
          }
        },
        line: 158
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 165,
            column: 20
          },
          end: {
            line: 165,
            column: 21
          }
        },
        loc: {
          start: {
            line: 165,
            column: 32
          },
          end: {
            line: 214,
            column: 3
          }
        },
        line: 165
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 197,
            column: 91
          },
          end: {
            line: 197,
            column: 92
          }
        },
        loc: {
          start: {
            line: 197,
            column: 99
          },
          end: {
            line: 213,
            column: 5
          }
        },
        line: 197
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 215,
            column: 21
          },
          end: {
            line: 215,
            column: 22
          }
        },
        loc: {
          start: {
            line: 215,
            column: 37
          },
          end: {
            line: 236,
            column: 3
          }
        },
        line: 215
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 229,
            column: 42
          },
          end: {
            line: 229,
            column: 43
          }
        },
        loc: {
          start: {
            line: 229,
            column: 50
          },
          end: {
            line: 235,
            column: 5
          }
        },
        line: 229
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 237,
            column: 21
          },
          end: {
            line: 237,
            column: 22
          }
        },
        loc: {
          start: {
            line: 237,
            column: 37
          },
          end: {
            line: 273,
            column: 3
          }
        },
        line: 237
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 250,
            column: 42
          },
          end: {
            line: 250,
            column: 43
          }
        },
        loc: {
          start: {
            line: 250,
            column: 50
          },
          end: {
            line: 272,
            column: 5
          }
        },
        line: 250
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 251,
            column: 63
          },
          end: {
            line: 251,
            column: 64
          }
        },
        loc: {
          start: {
            line: 251,
            column: 71
          },
          end: {
            line: 253,
            column: 7
          }
        },
        line: 251
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 274,
            column: 22
          },
          end: {
            line: 274,
            column: 23
          }
        },
        loc: {
          start: {
            line: 274,
            column: 38
          },
          end: {
            line: 291,
            column: 3
          }
        },
        line: 274
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 292,
            column: 18
          },
          end: {
            line: 292,
            column: 19
          }
        },
        loc: {
          start: {
            line: 292,
            column: 34
          },
          end: {
            line: 298,
            column: 3
          }
        },
        line: 292
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 299,
            column: 21
          },
          end: {
            line: 299,
            column: 22
          }
        },
        loc: {
          start: {
            line: 299,
            column: 35
          },
          end: {
            line: 308,
            column: 3
          }
        },
        line: 299
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 309,
            column: 22
          },
          end: {
            line: 309,
            column: 23
          }
        },
        loc: {
          start: {
            line: 309,
            column: 36
          },
          end: {
            line: 323,
            column: 3
          }
        },
        line: 309
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 324,
            column: 22
          },
          end: {
            line: 324,
            column: 23
          }
        },
        loc: {
          start: {
            line: 324,
            column: 44
          },
          end: {
            line: 352,
            column: 3
          }
        },
        line: 324
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 333,
            column: 22
          },
          end: {
            line: 333,
            column: 23
          }
        },
        loc: {
          start: {
            line: 333,
            column: 34
          },
          end: {
            line: 335,
            column: 7
          }
        },
        line: 333
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 353,
            column: 24
          },
          end: {
            line: 353,
            column: 25
          }
        },
        loc: {
          start: {
            line: 353,
            column: 36
          },
          end: {
            line: 362,
            column: 3
          }
        },
        line: 353
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 363,
            column: 22
          },
          end: {
            line: 363,
            column: 23
          }
        },
        loc: {
          start: {
            line: 363,
            column: 38
          },
          end: {
            line: 374,
            column: 3
          }
        },
        line: 363
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 376,
            column: 21
          },
          end: {
            line: 376,
            column: 22
          }
        },
        loc: {
          start: {
            line: 376,
            column: 33
          },
          end: {
            line: 378,
            column: 3
          }
        },
        line: 376
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 379,
            column: 24
          },
          end: {
            line: 379,
            column: 25
          }
        },
        loc: {
          start: {
            line: 379,
            column: 40
          },
          end: {
            line: 401,
            column: 3
          }
        },
        line: 379
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 390,
            column: 7
          },
          end: {
            line: 390,
            column: 8
          }
        },
        loc: {
          start: {
            line: 390,
            column: 20
          },
          end: {
            line: 400,
            column: 5
          }
        },
        line: 390
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 395,
            column: 84
          },
          end: {
            line: 395,
            column: 85
          }
        },
        loc: {
          start: {
            line: 395,
            column: 92
          },
          end: {
            line: 399,
            column: 7
          }
        },
        line: 395
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 411,
            column: 44
          },
          end: {
            line: 411,
            column: 45
          }
        },
        loc: {
          start: {
            line: 411,
            column: 79
          },
          end: {
            line: 414,
            column: 3
          }
        },
        line: 411
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 415,
            column: 40
          },
          end: {
            line: 415,
            column: 41
          }
        },
        loc: {
          start: {
            line: 415,
            column: 87
          },
          end: {
            line: 425,
            column: 3
          }
        },
        line: 415
      },
      '33': {
        name: '(anonymous_33)',
        decl: {
          start: {
            line: 433,
            column: 31
          },
          end: {
            line: 433,
            column: 32
          }
        },
        loc: {
          start: {
            line: 433,
            column: 66
          },
          end: {
            line: 442,
            column: 3
          }
        },
        line: 433
      },
      '34': {
        name: '(anonymous_34)',
        decl: {
          start: {
            line: 443,
            column: 27
          },
          end: {
            line: 443,
            column: 28
          }
        },
        loc: {
          start: {
            line: 443,
            column: 74
          },
          end: {
            line: 456,
            column: 3
          }
        },
        line: 443
      },
      '35': {
        name: '(anonymous_35)',
        decl: {
          start: {
            line: 457,
            column: 25
          },
          end: {
            line: 457,
            column: 26
          }
        },
        loc: {
          start: {
            line: 457,
            column: 72
          },
          end: {
            line: 462,
            column: 3
          }
        },
        line: 457
      },
      '36': {
        name: 'photolibraryCtrl_edit',
        decl: {
          start: {
            line: 465,
            column: 9
          },
          end: {
            line: 465,
            column: 30
          }
        },
        loc: {
          start: {
            line: 465,
            column: 81
          },
          end: {
            line: 484,
            column: 1
          }
        },
        line: 465
      },
      '37': {
        name: '(anonymous_37)',
        decl: {
          start: {
            line: 467,
            column: 23
          },
          end: {
            line: 467,
            column: 24
          }
        },
        loc: {
          start: {
            line: 467,
            column: 35
          },
          end: {
            line: 480,
            column: 3
          }
        },
        line: 467
      },
      '38': {
        name: '(anonymous_38)',
        decl: {
          start: {
            line: 474,
            column: 124
          },
          end: {
            line: 474,
            column: 125
          }
        },
        loc: {
          start: {
            line: 474,
            column: 132
          },
          end: {
            line: 479,
            column: 5
          }
        },
        line: 474
      },
      '39': {
        name: '(anonymous_39)',
        decl: {
          start: {
            line: 481,
            column: 22
          },
          end: {
            line: 481,
            column: 23
          }
        },
        loc: {
          start: {
            line: 481,
            column: 34
          },
          end: {
            line: 483,
            column: 3
          }
        },
        line: 481
      },
      '40': {
        name: 'photolibraryCtrl_slide',
        decl: {
          start: {
            line: 485,
            column: 9
          },
          end: {
            line: 485,
            column: 31
          }
        },
        loc: {
          start: {
            line: 485,
            column: 71
          },
          end: {
            line: 518,
            column: 1
          }
        },
        line: 485
      },
      '41': {
        name: '(anonymous_41)',
        decl: {
          start: {
            line: 515,
            column: 22
          },
          end: {
            line: 515,
            column: 23
          }
        },
        loc: {
          start: {
            line: 515,
            column: 34
          },
          end: {
            line: 517,
            column: 3
          }
        },
        line: 515
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 10,
            column: 2
          },
          end: {
            line: 12,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 10,
            column: 2
          },
          end: {
            line: 12,
            column: 3
          }
        }, {
          start: {
            line: 10,
            column: 2
          },
          end: {
            line: 12,
            column: 3
          }
        }],
        line: 10
      },
      '1': {
        loc: {
          start: {
            line: 21,
            column: 2
          },
          end: {
            line: 26,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 21,
            column: 2
          },
          end: {
            line: 26,
            column: 3
          }
        }, {
          start: {
            line: 21,
            column: 2
          },
          end: {
            line: 26,
            column: 3
          }
        }],
        line: 21
      },
      '2': {
        loc: {
          start: {
            line: 29,
            column: 2
          },
          end: {
            line: 36,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 29,
            column: 2
          },
          end: {
            line: 36,
            column: 3
          }
        }, {
          start: {
            line: 29,
            column: 2
          },
          end: {
            line: 36,
            column: 3
          }
        }],
        line: 29
      },
      '3': {
        loc: {
          start: {
            line: 29,
            column: 6
          },
          end: {
            line: 29,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 29,
            column: 6
          },
          end: {
            line: 29,
            column: 18
          }
        }, {
          start: {
            line: 29,
            column: 22
          },
          end: {
            line: 29,
            column: 39
          }
        }],
        line: 29
      },
      '4': {
        loc: {
          start: {
            line: 41,
            column: 4
          },
          end: {
            line: 43,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 41,
            column: 4
          },
          end: {
            line: 43,
            column: 5
          }
        }, {
          start: {
            line: 41,
            column: 4
          },
          end: {
            line: 43,
            column: 5
          }
        }],
        line: 41
      },
      '5': {
        loc: {
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 41,
            column: 135
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 41,
            column: 68
          }
        }, {
          start: {
            line: 41,
            column: 72
          },
          end: {
            line: 41,
            column: 135
          }
        }],
        line: 41
      },
      '6': {
        loc: {
          start: {
            line: 66,
            column: 4
          },
          end: {
            line: 68,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 66,
            column: 4
          },
          end: {
            line: 68,
            column: 5
          }
        }, {
          start: {
            line: 66,
            column: 4
          },
          end: {
            line: 68,
            column: 5
          }
        }],
        line: 66
      },
      '7': {
        loc: {
          start: {
            line: 66,
            column: 8
          },
          end: {
            line: 66,
            column: 135
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 66,
            column: 8
          },
          end: {
            line: 66,
            column: 68
          }
        }, {
          start: {
            line: 66,
            column: 72
          },
          end: {
            line: 66,
            column: 135
          }
        }],
        line: 66
      },
      '8': {
        loc: {
          start: {
            line: 115,
            column: 6
          },
          end: {
            line: 117,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 115,
            column: 6
          },
          end: {
            line: 117,
            column: 7
          }
        }, {
          start: {
            line: 115,
            column: 6
          },
          end: {
            line: 117,
            column: 7
          }
        }],
        line: 115
      },
      '9': {
        loc: {
          start: {
            line: 121,
            column: 8
          },
          end: {
            line: 123,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 121,
            column: 8
          },
          end: {
            line: 123,
            column: 9
          }
        }, {
          start: {
            line: 121,
            column: 8
          },
          end: {
            line: 123,
            column: 9
          }
        }],
        line: 121
      },
      '10': {
        loc: {
          start: {
            line: 148,
            column: 4
          },
          end: {
            line: 156,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 148,
            column: 4
          },
          end: {
            line: 156,
            column: 5
          }
        }, {
          start: {
            line: 148,
            column: 4
          },
          end: {
            line: 156,
            column: 5
          }
        }],
        line: 148
      },
      '11': {
        loc: {
          start: {
            line: 149,
            column: 6
          },
          end: {
            line: 153,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 149,
            column: 6
          },
          end: {
            line: 153,
            column: 7
          }
        }, {
          start: {
            line: 149,
            column: 6
          },
          end: {
            line: 153,
            column: 7
          }
        }],
        line: 149
      },
      '12': {
        loc: {
          start: {
            line: 159,
            column: 4
          },
          end: {
            line: 163,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 159,
            column: 4
          },
          end: {
            line: 163,
            column: 5
          }
        }, {
          start: {
            line: 159,
            column: 4
          },
          end: {
            line: 163,
            column: 5
          }
        }],
        line: 159
      },
      '13': {
        loc: {
          start: {
            line: 167,
            column: 4
          },
          end: {
            line: 189,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 167,
            column: 4
          },
          end: {
            line: 189,
            column: 5
          }
        }, {
          start: {
            line: 167,
            column: 4
          },
          end: {
            line: 189,
            column: 5
          }
        }],
        line: 167
      },
      '14': {
        loc: {
          start: {
            line: 171,
            column: 8
          },
          end: {
            line: 173,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 171,
            column: 8
          },
          end: {
            line: 173,
            column: 9
          }
        }, {
          start: {
            line: 171,
            column: 8
          },
          end: {
            line: 173,
            column: 9
          }
        }],
        line: 171
      },
      '15': {
        loc: {
          start: {
            line: 174,
            column: 8
          },
          end: {
            line: 186,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 174,
            column: 8
          },
          end: {
            line: 186,
            column: 9
          }
        }, {
          start: {
            line: 174,
            column: 8
          },
          end: {
            line: 186,
            column: 9
          }
        }],
        line: 174
      },
      '16': {
        loc: {
          start: {
            line: 193,
            column: 23
          },
          end: {
            line: 193,
            column: 47
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 193,
            column: 42
          },
          end: {
            line: 193,
            column: 43
          }
        }, {
          start: {
            line: 193,
            column: 46
          },
          end: {
            line: 193,
            column: 47
          }
        }],
        line: 193
      },
      '17': {
        loc: {
          start: {
            line: 194,
            column: 27
          },
          end: {
            line: 194,
            column: 55
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 194,
            column: 50
          },
          end: {
            line: 194,
            column: 51
          }
        }, {
          start: {
            line: 194,
            column: 54
          },
          end: {
            line: 194,
            column: 55
          }
        }],
        line: 194
      },
      '18': {
        loc: {
          start: {
            line: 198,
            column: 6
          },
          end: {
            line: 200,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 198,
            column: 6
          },
          end: {
            line: 200,
            column: 7
          }
        }, {
          start: {
            line: 198,
            column: 6
          },
          end: {
            line: 200,
            column: 7
          }
        }],
        line: 198
      },
      '19': {
        loc: {
          start: {
            line: 201,
            column: 6
          },
          end: {
            line: 204,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 201,
            column: 6
          },
          end: {
            line: 204,
            column: 7
          }
        }, {
          start: {
            line: 201,
            column: 6
          },
          end: {
            line: 204,
            column: 7
          }
        }],
        line: 201
      },
      '20': {
        loc: {
          start: {
            line: 205,
            column: 6
          },
          end: {
            line: 212,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 205,
            column: 6
          },
          end: {
            line: 212,
            column: 7
          }
        }, {
          start: {
            line: 205,
            column: 6
          },
          end: {
            line: 212,
            column: 7
          }
        }],
        line: 205
      },
      '21': {
        loc: {
          start: {
            line: 217,
            column: 4
          },
          end: {
            line: 221,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 217,
            column: 4
          },
          end: {
            line: 221,
            column: 5
          }
        }, {
          start: {
            line: 217,
            column: 4
          },
          end: {
            line: 221,
            column: 5
          }
        }],
        line: 217
      },
      '22': {
        loc: {
          start: {
            line: 220,
            column: 12
          },
          end: {
            line: 220,
            column: 24
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 220,
            column: 12
          },
          end: {
            line: 220,
            column: 19
          }
        }, {
          start: {
            line: 220,
            column: 23
          },
          end: {
            line: 220,
            column: 24
          }
        }],
        line: 220
      },
      '23': {
        loc: {
          start: {
            line: 225,
            column: 4
          },
          end: {
            line: 227,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 225,
            column: 4
          },
          end: {
            line: 227,
            column: 5
          }
        }, {
          start: {
            line: 225,
            column: 4
          },
          end: {
            line: 227,
            column: 5
          }
        }],
        line: 225
      },
      '24': {
        loc: {
          start: {
            line: 239,
            column: 4
          },
          end: {
            line: 243,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 239,
            column: 4
          },
          end: {
            line: 243,
            column: 5
          }
        }, {
          start: {
            line: 239,
            column: 4
          },
          end: {
            line: 243,
            column: 5
          }
        }],
        line: 239
      },
      '25': {
        loc: {
          start: {
            line: 242,
            column: 12
          },
          end: {
            line: 242,
            column: 24
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 242,
            column: 12
          },
          end: {
            line: 242,
            column: 19
          }
        }, {
          start: {
            line: 242,
            column: 23
          },
          end: {
            line: 242,
            column: 24
          }
        }],
        line: 242
      },
      '26': {
        loc: {
          start: {
            line: 246,
            column: 4
          },
          end: {
            line: 248,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 246,
            column: 4
          },
          end: {
            line: 248,
            column: 5
          }
        }, {
          start: {
            line: 246,
            column: 4
          },
          end: {
            line: 248,
            column: 5
          }
        }],
        line: 246
      },
      '27': {
        loc: {
          start: {
            line: 295,
            column: 4
          },
          end: {
            line: 297,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 295,
            column: 4
          },
          end: {
            line: 297,
            column: 5
          }
        }, {
          start: {
            line: 295,
            column: 4
          },
          end: {
            line: 297,
            column: 5
          }
        }],
        line: 295
      },
      '28': {
        loc: {
          start: {
            line: 311,
            column: 4
          },
          end: {
            line: 314,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 311,
            column: 4
          },
          end: {
            line: 314,
            column: 5
          }
        }, {
          start: {
            line: 311,
            column: 4
          },
          end: {
            line: 314,
            column: 5
          }
        }],
        line: 311
      },
      '29': {
        loc: {
          start: {
            line: 312,
            column: 14
          },
          end: {
            line: 312,
            column: 32
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 312,
            column: 14
          },
          end: {
            line: 312,
            column: 26
          }
        }, {
          start: {
            line: 312,
            column: 30
          },
          end: {
            line: 312,
            column: 32
          }
        }],
        line: 312
      },
      '30': {
        loc: {
          start: {
            line: 315,
            column: 12
          },
          end: {
            line: 315,
            column: 30
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 315,
            column: 12
          },
          end: {
            line: 315,
            column: 24
          }
        }, {
          start: {
            line: 315,
            column: 28
          },
          end: {
            line: 315,
            column: 30
          }
        }],
        line: 315
      },
      '31': {
        loc: {
          start: {
            line: 316,
            column: 4
          },
          end: {
            line: 318,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 316,
            column: 4
          },
          end: {
            line: 318,
            column: 5
          }
        }, {
          start: {
            line: 316,
            column: 4
          },
          end: {
            line: 318,
            column: 5
          }
        }],
        line: 316
      },
      '32': {
        loc: {
          start: {
            line: 317,
            column: 14
          },
          end: {
            line: 317,
            column: 32
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 317,
            column: 14
          },
          end: {
            line: 317,
            column: 26
          }
        }, {
          start: {
            line: 317,
            column: 30
          },
          end: {
            line: 317,
            column: 32
          }
        }],
        line: 317
      },
      '33': {
        loc: {
          start: {
            line: 320,
            column: 20
          },
          end: {
            line: 320,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 320,
            column: 20
          },
          end: {
            line: 320,
            column: 41
          }
        }, {
          start: {
            line: 320,
            column: 45
          },
          end: {
            line: 320,
            column: 47
          }
        }],
        line: 320
      },
      '34': {
        loc: {
          start: {
            line: 322,
            column: 12
          },
          end: {
            line: 322,
            column: 27
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 322,
            column: 12
          },
          end: {
            line: 322,
            column: 21
          }
        }, {
          start: {
            line: 322,
            column: 25
          },
          end: {
            line: 322,
            column: 27
          }
        }],
        line: 322
      },
      '35': {
        loc: {
          start: {
            line: 325,
            column: 4
          },
          end: {
            line: 331,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 325,
            column: 4
          },
          end: {
            line: 331,
            column: 5
          }
        }, {
          start: {
            line: 325,
            column: 4
          },
          end: {
            line: 331,
            column: 5
          }
        }],
        line: 325
      },
      '36': {
        loc: {
          start: {
            line: 326,
            column: 6
          },
          end: {
            line: 328,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 326,
            column: 6
          },
          end: {
            line: 328,
            column: 7
          }
        }, {
          start: {
            line: 326,
            column: 6
          },
          end: {
            line: 328,
            column: 7
          }
        }],
        line: 326
      },
      '37': {
        loc: {
          start: {
            line: 332,
            column: 4
          },
          end: {
            line: 344,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 332,
            column: 4
          },
          end: {
            line: 344,
            column: 5
          }
        }, {
          start: {
            line: 332,
            column: 4
          },
          end: {
            line: 344,
            column: 5
          }
        }],
        line: 332
      },
      '38': {
        loc: {
          start: {
            line: 339,
            column: 8
          },
          end: {
            line: 341,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 339,
            column: 8
          },
          end: {
            line: 341,
            column: 9
          }
        }, {
          start: {
            line: 339,
            column: 8
          },
          end: {
            line: 341,
            column: 9
          }
        }],
        line: 339
      },
      '39': {
        loc: {
          start: {
            line: 346,
            column: 4
          },
          end: {
            line: 348,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 346,
            column: 4
          },
          end: {
            line: 348,
            column: 5
          }
        }, {
          start: {
            line: 346,
            column: 4
          },
          end: {
            line: 348,
            column: 5
          }
        }],
        line: 346
      },
      '40': {
        loc: {
          start: {
            line: 358,
            column: 25
          },
          end: {
            line: 358,
            column: 117
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 358,
            column: 69
          },
          end: {
            line: 358,
            column: 113
          }
        }, {
          start: {
            line: 358,
            column: 116
          },
          end: {
            line: 358,
            column: 117
          }
        }],
        line: 358
      },
      '41': {
        loc: {
          start: {
            line: 391,
            column: 6
          },
          end: {
            line: 393,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 391,
            column: 6
          },
          end: {
            line: 393,
            column: 7
          }
        }, {
          start: {
            line: 391,
            column: 6
          },
          end: {
            line: 393,
            column: 7
          }
        }],
        line: 391
      },
      '42': {
        loc: {
          start: {
            line: 417,
            column: 6
          },
          end: {
            line: 423,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 417,
            column: 6
          },
          end: {
            line: 423,
            column: 7
          }
        }, {
          start: {
            line: 417,
            column: 6
          },
          end: {
            line: 423,
            column: 7
          }
        }],
        line: 417
      },
      '43': {
        loc: {
          start: {
            line: 418,
            column: 8
          },
          end: {
            line: 420,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 418,
            column: 8
          },
          end: {
            line: 420,
            column: 9
          }
        }, {
          start: {
            line: 418,
            column: 8
          },
          end: {
            line: 420,
            column: 9
          }
        }],
        line: 418
      },
      '44': {
        loc: {
          start: {
            line: 436,
            column: 4
          },
          end: {
            line: 441,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 436,
            column: 4
          },
          end: {
            line: 441,
            column: 5
          }
        }, {
          start: {
            line: 436,
            column: 4
          },
          end: {
            line: 441,
            column: 5
          }
        }],
        line: 436
      },
      '45': {
        loc: {
          start: {
            line: 445,
            column: 4
          },
          end: {
            line: 453,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 445,
            column: 4
          },
          end: {
            line: 453,
            column: 5
          }
        }, {
          start: {
            line: 445,
            column: 4
          },
          end: {
            line: 453,
            column: 5
          }
        }],
        line: 445
      },
      '46': {
        loc: {
          start: {
            line: 447,
            column: 8
          },
          end: {
            line: 450,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 447,
            column: 8
          },
          end: {
            line: 450,
            column: 9
          }
        }, {
          start: {
            line: 447,
            column: 8
          },
          end: {
            line: 450,
            column: 9
          }
        }],
        line: 447
      },
      '47': {
        loc: {
          start: {
            line: 447,
            column: 12
          },
          end: {
            line: 447,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 447,
            column: 12
          },
          end: {
            line: 447,
            column: 33
          }
        }, {
          start: {
            line: 447,
            column: 37
          },
          end: {
            line: 447,
            column: 49
          }
        }],
        line: 447
      },
      '48': {
        loc: {
          start: {
            line: 468,
            column: 18
          },
          end: {
            line: 468,
            column: 123
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 468,
            column: 62
          },
          end: {
            line: 468,
            column: 118
          }
        }, {
          start: {
            line: 468,
            column: 121
          },
          end: {
            line: 468,
            column: 123
          }
        }],
        line: 468
      },
      '49': {
        loc: {
          start: {
            line: 470,
            column: 4
          },
          end: {
            line: 473,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 470,
            column: 4
          },
          end: {
            line: 473,
            column: 5
          }
        }, {
          start: {
            line: 470,
            column: 4
          },
          end: {
            line: 473,
            column: 5
          }
        }],
        line: 470
      },
      '50': {
        loc: {
          start: {
            line: 495,
            column: 4
          },
          end: {
            line: 498,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 495,
            column: 4
          },
          end: {
            line: 498,
            column: 5
          }
        }, {
          start: {
            line: 495,
            column: 4
          },
          end: {
            line: 498,
            column: 5
          }
        }],
        line: 495
      },
      '51': {
        loc: {
          start: {
            line: 503,
            column: 4
          },
          end: {
            line: 505,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 503,
            column: 4
          },
          end: {
            line: 505,
            column: 5
          }
        }, {
          start: {
            line: 503,
            column: 4
          },
          end: {
            line: 505,
            column: 5
          }
        }],
        line: 503
      },
      '52': {
        loc: {
          start: {
            line: 506,
            column: 4
          },
          end: {
            line: 508,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 506,
            column: 4
          },
          end: {
            line: 508,
            column: 5
          }
        }, {
          start: {
            line: 506,
            column: 4
          },
          end: {
            line: 508,
            column: 5
          }
        }],
        line: 506
      },
      '53': {
        loc: {
          start: {
            line: 512,
            column: 11
          },
          end: {
            line: 512,
            column: 121
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 512,
            column: 26
          },
          end: {
            line: 512,
            column: 112
          }
        }, {
          start: {
            line: 512,
            column: 115
          },
          end: {
            line: 512,
            column: 121
          }
        }],
        line: 512
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0,
      '248': 0,
      '249': 0,
      '250': 0,
      '251': 0,
      '252': 0,
      '253': 0,
      '254': 0,
      '255': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1oes3fbrts.s[0]++;
/**
 * Created by BKSB on 21/03/2017.
 */

/**
 * Created by BKSB on 10/03/2017.
 */
angular.module('app').controller('photolibraryCtrl', function ($scope, SweetAlert, $uibModal, $log, $rootScope, $deltahttp, $translate, $deltadate, $CRUDService, $filter, $deltaLocation, FileUploader, $state, $stateParams) {
  cov_1oes3fbrts.f[0]++;
  cov_1oes3fbrts.s[1]++;

  $rootScope.processPageRight('5_4');
  cov_1oes3fbrts.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_1oes3fbrts.b[0][0]++;
    cov_1oes3fbrts.s[3]++;

    return;
  } else {
    cov_1oes3fbrts.b[0][1]++;
  }
  var PATH_DOC = (cov_1oes3fbrts.s[4]++, 'Phototheque');
  var PATH_CLS = (cov_1oes3fbrts.s[5]++, 'ClasseurPhototheque');
  cov_1oes3fbrts.s[6]++;
  $deltaLocation.addController($scope);
  cov_1oes3fbrts.s[7]++;
  $scope.listDataType = [];
  cov_1oes3fbrts.s[8]++;
  $scope.listcls = [];
  cov_1oes3fbrts.s[9]++;
  $scope.index = -3;

  // 0-photos && 1-video
  cov_1oes3fbrts.s[10]++;
  if (!$rootScope.filtre_type_media) {
    cov_1oes3fbrts.b[1][0]++;
    cov_1oes3fbrts.s[11]++;

    $rootScope.filtre_type_media = {
      filtre: false,
      type: ''
    };
  } else {
    cov_1oes3fbrts.b[1][1]++;
  }
  cov_1oes3fbrts.s[12]++;
  $scope.mediaType = 0;

  cov_1oes3fbrts.s[13]++;
  if ((cov_1oes3fbrts.b[3][0]++, $stateParams) && (cov_1oes3fbrts.b[3][1]++, $stateParams.type)) {
    cov_1oes3fbrts.b[2][0]++;
    cov_1oes3fbrts.s[14]++;

    $rootScope.filtre_type_media.filtre = true;
    cov_1oes3fbrts.s[15]++;
    $rootScope.filtre_type_media.type = $stateParams.type;

    cov_1oes3fbrts.s[16]++;
    $scope.mediaType = $stateParams.type;
  } else {
    cov_1oes3fbrts.b[2][1]++;
  }

  cov_1oes3fbrts.s[17]++;
  $scope.addnewphoto = function () {
    cov_1oes3fbrts.f[1]++;

    var tmp_id_cls = (cov_1oes3fbrts.s[18]++, 0);

    cov_1oes3fbrts.s[19]++;
    if ((cov_1oes3fbrts.b[5][0]++, angular.isDefined($scope.listcls[$scope.listcls.length - 1])) && (cov_1oes3fbrts.b[5][1]++, angular.isDefined($scope.listcls[$scope.listcls.length - 1].id))) {
      cov_1oes3fbrts.b[4][0]++;
      cov_1oes3fbrts.s[20]++;

      tmp_id_cls = $scope.listcls[$scope.listcls.length - 1].id;
    } else {
      cov_1oes3fbrts.b[4][1]++;
    }

    cov_1oes3fbrts.s[21]++;
    $scope.editPhoto({
      CODE_L: $scope.listData_bksb[0].id,
      Lieu: $scope.listData_bksb[0].CODE_LC + ': ' + $scope.listData_bksb[0].DESCRIPTION_L,
      PUBLIC: false,
      DATE_PHOTO: Date.newDate(),
      PORTFOLIO_: false,
      DESCRIPTE: '',
      id: 0,
      ID_CLS_PHOTO: tmp_id_cls,
      REFERENCE: '',
      RESUME: '',
      TYPE: 0,
      AUTEUR: '',
      GPSLAT: 0,
      GPSLON: 0
    });
  };

  cov_1oes3fbrts.s[22]++;
  $scope.addnewvideo = function () {
    cov_1oes3fbrts.f[2]++;

    var tmp_id_cls = (cov_1oes3fbrts.s[23]++, 0);

    cov_1oes3fbrts.s[24]++;
    if ((cov_1oes3fbrts.b[7][0]++, angular.isDefined($scope.listcls[$scope.listcls.length - 1])) && (cov_1oes3fbrts.b[7][1]++, angular.isDefined($scope.listcls[$scope.listcls.length - 1].id))) {
      cov_1oes3fbrts.b[6][0]++;
      cov_1oes3fbrts.s[25]++;

      tmp_id_cls = $scope.listcls[$scope.listcls.length - 1].id;
    } else {
      cov_1oes3fbrts.b[6][1]++;
    }

    cov_1oes3fbrts.s[26]++;
    $scope.editVideo({
      CODE_L: $scope.listData_bksb[0].id,
      Lieu: $scope.listData_bksb[0].CODE_LC + ': ' + $scope.listData_bksb[0].DESCRIPTION_L,
      PUBLIC: false,
      DATE_PHOTO: Date.newDate(),
      PORTFOLIO_: false,
      DESCRIPTE: '',
      id: 0,
      ID_CLS_PHOTO: tmp_id_cls,
      REFERENCE: '',
      RESUME: '',
      AUTEUR: '',
      GPSLAT: 0,
      GPSLON: 0,
      URL_VIDEO: '',
      TYPE: 1
    });
  };

  cov_1oes3fbrts.s[27]++;
  $scope.location = {
    selected: function selected(item) {
      cov_1oes3fbrts.s[28]++;

      $scope.selectedItem.CODE_L = item.id;
      cov_1oes3fbrts.s[29]++;
      $scope.selectedItem.Lieu = item.CODE_LC + ': ' + item.DESCRIPTION_L;
      cov_1oes3fbrts.s[30]++;
      this.open = false;
    },

    open: false
  };
  cov_1oes3fbrts.s[31]++;
  $scope.selectedLocation = function (item) {
    cov_1oes3fbrts.f[3]++;
    cov_1oes3fbrts.s[32]++;

    $scope.selectedItem.CODE_L = item.id;
    cov_1oes3fbrts.s[33]++;
    $scope.selectedItem.Lieu = item.CODE_LC + ': ' + item.DESCRIPTION_L;
    cov_1oes3fbrts.s[34]++;
    $scope.openlocation = false;
  };
  cov_1oes3fbrts.s[35]++;
  $scope.deletePhoto = function (item) {
    cov_1oes3fbrts.f[4]++;
    cov_1oes3fbrts.s[36]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant(  item.TYPE ==0?  "PHOTOLIBRARY.CONFIRM_DELETE_PHOTO": "PHOTOLIBRARY.CONFIRM_DELETE_VIDEO"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1oes3fbrts.f[5]++;
      cov_1oes3fbrts.s[37]++;

      if (isconfirm) {
        cov_1oes3fbrts.b[8][0]++;
        cov_1oes3fbrts.s[38]++;

        return;
      } else {
        cov_1oes3fbrts.b[8][1]++;
      }
      cov_1oes3fbrts.s[39]++;
      $scope.isloading = true;
      cov_1oes3fbrts.s[40]++;
      $CRUDService.delet(PATH_DOC, { action: 'supp', valeur: $filter('json')(item) }, function (data) {
        cov_1oes3fbrts.f[6]++;
        cov_1oes3fbrts.s[41]++;

        $scope.isloading = false;
        cov_1oes3fbrts.s[42]++;
        if (data < 1) {
          cov_1oes3fbrts.b[9][0]++;
          cov_1oes3fbrts.s[43]++;

          return;
        } else {
          cov_1oes3fbrts.b[9][1]++;
        }
        cov_1oes3fbrts.s[44]++;
        $scope.gotoFolder($scope.listcls[$scope.listcls.length - 1]);
        cov_1oes3fbrts.s[45]++;
        $scope.cancelPhoto();
      });
    });
  };

  cov_1oes3fbrts.s[46]++;
  $scope.editVideo = function (item) {
    cov_1oes3fbrts.f[7]++;
    cov_1oes3fbrts.s[47]++;

    $scope.selectedItem = item;
    cov_1oes3fbrts.s[48]++;
    $scope.selectedItem.PUBLIC = $scope.selectedItem.PUBLIC == 1;
    cov_1oes3fbrts.s[49]++;
    $scope.isadd = true;
  };

  cov_1oes3fbrts.s[50]++;
  $scope.editPhoto = function (item) {
    cov_1oes3fbrts.f[8]++;
    cov_1oes3fbrts.s[51]++;

    $scope.selectedItem = item;
    cov_1oes3fbrts.s[52]++;
    $scope.selectedItem.PUBLIC = $scope.selectedItem.PUBLIC == 1;
    cov_1oes3fbrts.s[53]++;
    $scope.isadd = true;
  };

  cov_1oes3fbrts.s[54]++;
  $scope.cancelPhoto = function () {
    cov_1oes3fbrts.f[9]++;
    cov_1oes3fbrts.s[55]++;

    $scope.isadd = false;
    cov_1oes3fbrts.s[56]++;
    $scope.selectedItem = null;
  };

  cov_1oes3fbrts.s[57]++;
  $scope.edit = function (item) {
    cov_1oes3fbrts.f[10]++;
    cov_1oes3fbrts.s[58]++;

    if (item.ID_CLS_PHOTO) {
      cov_1oes3fbrts.b[10][0]++;
      cov_1oes3fbrts.s[59]++;

      if (item.type == 1) {
        cov_1oes3fbrts.b[11][0]++;
        cov_1oes3fbrts.s[60]++;

        this.editVideo(item);
      } else {
        cov_1oes3fbrts.b[11][1]++;
        cov_1oes3fbrts.s[61]++;

        this.editPhoto(item);
      }
    } else {
      cov_1oes3fbrts.b[10][1]++;
      cov_1oes3fbrts.s[62]++;

      $scope.editfolder(item);
    }
  };
  cov_1oes3fbrts.s[63]++;
  $scope.delete = function (item) {
    cov_1oes3fbrts.f[11]++;
    cov_1oes3fbrts.s[64]++;

    if (item.ID_CLS_PHOTO) {
      cov_1oes3fbrts.b[12][0]++;
      cov_1oes3fbrts.s[65]++;

      this.deletePhoto(item);
    } else {
      cov_1oes3fbrts.b[12][1]++;
      cov_1oes3fbrts.s[66]++;

      $scope.deletefolder(item);
    }
  };
  cov_1oes3fbrts.s[67]++;
  $scope.validate = function () {
    cov_1oes3fbrts.f[12]++;

    var isnew = (cov_1oes3fbrts.s[68]++, false);
    cov_1oes3fbrts.s[69]++;
    if (!$scope.selectedItem.id) {
      cov_1oes3fbrts.b[13][0]++;
      cov_1oes3fbrts.s[70]++;

      isnew = true;
    } else {
      cov_1oes3fbrts.b[13][1]++;
      cov_1oes3fbrts.s[71]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = $scope.listDataDocument[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var el = _step.value;
          cov_1oes3fbrts.s[72]++;

          if (!el.ID_CLS_PHOTO) {
            cov_1oes3fbrts.b[14][0]++;
            cov_1oes3fbrts.s[73]++;

            continue;
          } else {
            cov_1oes3fbrts.b[14][1]++;
          }
          cov_1oes3fbrts.s[74]++;
          if (el.id == $scope.selectedItem.id) {
            cov_1oes3fbrts.b[15][0]++;
            cov_1oes3fbrts.s[75]++;

            el.CODE_L = $scope.selectedItem.CODE_L;
            cov_1oes3fbrts.s[76]++;
            el.PUBLIC = $scope.selectedItem.PUBLIC;
            cov_1oes3fbrts.s[77]++;
            el.DATE_PHOTO = Date.newDate($deltadate.format($scope.selectedItem.DATE_PHOTO, 'yyyy-mm-dd'));
            cov_1oes3fbrts.s[78]++;
            el.DESCRIPTE = $scope.selectedItem.DESCRIPTE;
            cov_1oes3fbrts.s[79]++;
            el.RESUME = $scope.selectedItem.RESUME;
            cov_1oes3fbrts.s[80]++;
            el.AUTEUR = $scope.selectedItem.AUTEUR;
            cov_1oes3fbrts.s[81]++;
            el.GPSLAT = $scope.selectedItem.GPSLAT;
            cov_1oes3fbrts.s[82]++;
            el.GPSLON = $scope.selectedItem.GPSLON;
            cov_1oes3fbrts.s[83]++;
            el.PORTFOLIO_ = $scope.selectedItem.PORTFOLIO_;
            cov_1oes3fbrts.s[84]++;
            el.URL_VIDEO = $scope.selectedItem.URL_VIDEO;
            cov_1oes3fbrts.s[85]++;
            break;
          } else {
            cov_1oes3fbrts.b[15][1]++;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_1oes3fbrts.s[86]++;
      $scope.isadd = false;
    }

    var valueData = (cov_1oes3fbrts.s[87]++, angular.copy($scope.selectedItem));
    cov_1oes3fbrts.s[88]++;
    valueData.DATE_PHOTO = $deltadate.format(valueData.DATE_PHOTO, 'yyyy-mm-dd');
    cov_1oes3fbrts.s[89]++;
    valueData.PUBLIC = valueData.PUBLIC ? (cov_1oes3fbrts.b[16][0]++, 1) : (cov_1oes3fbrts.b[16][1]++, 0);
    cov_1oes3fbrts.s[90]++;
    valueData.PORTFOLIO_ = valueData.PORTFOLIO_ ? (cov_1oes3fbrts.b[17][0]++, 1) : (cov_1oes3fbrts.b[17][1]++, 0);
    cov_1oes3fbrts.s[91]++;
    $scope.isloading = isnew;

    cov_1oes3fbrts.s[92]++;
    $CRUDService.save(PATH_DOC, { action: 'MiseAJour', valeur: $filter('json')(valueData) }, function (data) {
      cov_1oes3fbrts.f[13]++;
      cov_1oes3fbrts.s[93]++;

      if (!isnew) {
        cov_1oes3fbrts.b[18][0]++;
        cov_1oes3fbrts.s[94]++;

        return;
      } else {
        cov_1oes3fbrts.b[18][1]++;
      }
      cov_1oes3fbrts.s[95]++;
      if (data < 1) {
        cov_1oes3fbrts.b[19][0]++;
        cov_1oes3fbrts.s[96]++;

        $scope.isloading = false;
        cov_1oes3fbrts.s[97]++;
        return;
      } else {
        cov_1oes3fbrts.b[19][1]++;
      }
      cov_1oes3fbrts.s[98]++;
      if ($scope.selectedItem.TYPE == 0) {
        cov_1oes3fbrts.b[20][0]++;
        cov_1oes3fbrts.s[99]++;

        $scope.file.url += '&id=' + data;
        cov_1oes3fbrts.s[100]++;
        $scope.file.upload();
      } else {
        cov_1oes3fbrts.b[20][1]++;
        cov_1oes3fbrts.s[101]++;

        $scope.isloading = false;
        cov_1oes3fbrts.s[102]++;
        $scope.cancelPhoto();
        cov_1oes3fbrts.s[103]++;
        $scope.gotoFolder($scope.listcls[$scope.listcls.length - 1]);
      }
    });
  };
  cov_1oes3fbrts.s[104]++;
  var _getAllCls = function _getAllCls(item) {
    cov_1oes3fbrts.f[14]++;

    var idP = (cov_1oes3fbrts.s[105]++, 0);
    cov_1oes3fbrts.s[106]++;
    if (!item) {
      cov_1oes3fbrts.b[21][0]++;
      cov_1oes3fbrts.s[107]++;

      idP = 0;
    } else {
      cov_1oes3fbrts.b[21][1]++;
      cov_1oes3fbrts.s[108]++;

      idP = (cov_1oes3fbrts.b[22][0]++, item.id) || (cov_1oes3fbrts.b[22][1]++, 0);
    }
    cov_1oes3fbrts.s[109]++;
    $scope.isloading = true;

    var params = (cov_1oes3fbrts.s[110]++, { get: 'all', id: idP });
    cov_1oes3fbrts.s[111]++;
    if ($rootScope.filtre_type_media.filtre) {
      cov_1oes3fbrts.b[23][0]++;
      cov_1oes3fbrts.s[112]++;

      params = { get: 'all', id: idP, type_media: $scope.mediaType };
    } else {
      cov_1oes3fbrts.b[23][1]++;
    }

    cov_1oes3fbrts.s[113]++;
    $CRUDService.getAll(PATH_CLS, params, function (data) {
      cov_1oes3fbrts.f[15]++;
      cov_1oes3fbrts.s[114]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var el = _step2.value;
          cov_1oes3fbrts.s[115]++;

          el.img = $deltahttp.getcoverphotolibrary(el.id, el.COVER_PHOTO);
          cov_1oes3fbrts.s[116]++;
          $scope.listDataDocument.push(el);
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      cov_1oes3fbrts.s[117]++;
      $scope.isloading = false;
    });
  };
  cov_1oes3fbrts.s[118]++;
  var _getAllDoc = function _getAllDoc(item) {
    cov_1oes3fbrts.f[16]++;

    var idP = (cov_1oes3fbrts.s[119]++, 0);
    cov_1oes3fbrts.s[120]++;
    if (!item) {
      cov_1oes3fbrts.b[24][0]++;
      cov_1oes3fbrts.s[121]++;

      idP = 0;
    } else {
      cov_1oes3fbrts.b[24][1]++;
      cov_1oes3fbrts.s[122]++;

      idP = (cov_1oes3fbrts.b[25][0]++, item.id) || (cov_1oes3fbrts.b[25][1]++, 0);
    }
    cov_1oes3fbrts.s[123]++;
    $scope.isloading = true;
    var params = (cov_1oes3fbrts.s[124]++, { get: 'all', cls: idP });
    cov_1oes3fbrts.s[125]++;
    if ($rootScope.filtre_type_media.filtre) {
      cov_1oes3fbrts.b[26][0]++;
      cov_1oes3fbrts.s[126]++;

      params = { get: 'all', cls: idP, type_media: $rootScope.filtre_type_media.type };
    } else {
      cov_1oes3fbrts.b[26][1]++;
    }

    cov_1oes3fbrts.s[127]++;
    $CRUDService.getAll(PATH_DOC, params, function (data) {
      cov_1oes3fbrts.f[17]++;
      cov_1oes3fbrts.s[128]++;

      $scope.listDataDocument = $scope.listDataDocument.filter(function (item) {
        cov_1oes3fbrts.f[18]++;
        cov_1oes3fbrts.s[129]++;

        return !item.ID_CLS_PHOTO;
      });
      cov_1oes3fbrts.s[130]++;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = data[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var el = _step3.value;
          cov_1oes3fbrts.s[131]++;

          el.GPSLAT = eval(el.GPSLAT);
          cov_1oes3fbrts.s[132]++;
          el.GPSLON = eval(el.GPSLON);
          cov_1oes3fbrts.s[133]++;
          el.DATE_PHOTO = Date.newDate(el.DATE_PHOTO);
          cov_1oes3fbrts.s[134]++;
          el.PORTFOLIO_ = el.PORTFOLIO_ == 1;
          cov_1oes3fbrts.s[135]++;
          el.img = $deltahttp.getphotolibrary(el.id);
          cov_1oes3fbrts.s[136]++;
          $scope.listDataDocument.push(el);
        }
        /*
          document.getElementById('links').onclick = function (event) {
            event = event || window.event;
            const target = event.target || event.srcElement;
            const link = target.src ? target.parentNode : target;
            const options = {index: link, event};
            const links = this.getElementsByTagName('a');
            blueimp.Gallery(links, options);
          }; */
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      cov_1oes3fbrts.s[137]++;
      $scope.isloading = false;
    });
  };
  cov_1oes3fbrts.s[138]++;
  $scope.openSlider = function (item) {
    cov_1oes3fbrts.f[19]++;
    cov_1oes3fbrts.s[139]++;

    $scope.slide = {
      start: 0,
      item: item
    };
    cov_1oes3fbrts.s[140]++;
    $uibModal.open({
      // templateUrl: 'app/views/photolibrary/slider_view.html',
      templateUrl: 'app/views/photolibrary/slider_view _news.html',
      controller: photolibraryCtrl_slide,
      scope: $scope,
      size: 'slider',
      backdrop: 'static',
      backdropClass: 'theme_blue_modal_mod',
      windowClass: 'animated theme_blue_modal_win',
      openedClass: 'theme_blue_modal_openmodal',
      windowTopClass: 'theme_blue_modal_top'
    });
  };
  cov_1oes3fbrts.s[141]++;
  var _getAll = function _getAll(item) {
    cov_1oes3fbrts.f[20]++;
    cov_1oes3fbrts.s[142]++;

    $scope.listDataDocument = [];
    cov_1oes3fbrts.s[143]++;
    _getAllCls(item);
    cov_1oes3fbrts.s[144]++;
    if (item) {
      cov_1oes3fbrts.b[27][0]++;
      cov_1oes3fbrts.s[145]++;

      _getAllDoc(item);
    } else {
      cov_1oes3fbrts.b[27][1]++;
    }
  };
  cov_1oes3fbrts.s[146]++;
  $scope.isYoutube = function (el) {
    cov_1oes3fbrts.f[21]++;
    cov_1oes3fbrts.s[147]++;

    return true;
    /* if (el.TYPE == 0) {
      return false;
    }
    if (el.URL_VIDEO == null || el.URL_VIDEO == '') {
      return false;
    }
    return (el.URL_VIDEO != (el.URL_VIDEO).replace('www.youtube.com', '')); */
  };
  cov_1oes3fbrts.s[148]++;
  $scope.getVideoId = function (el) {
    cov_1oes3fbrts.f[22]++;

    var list = (cov_1oes3fbrts.s[149]++, []);
    cov_1oes3fbrts.s[150]++;
    if (!this.isYoutube(el)) {
      cov_1oes3fbrts.b[28][0]++;
      cov_1oes3fbrts.s[151]++;

      list = ((cov_1oes3fbrts.b[29][0]++, el.URL_VIDEO) || (cov_1oes3fbrts.b[29][1]++, '')).split('/');
      cov_1oes3fbrts.s[152]++;
      return list[list.length - 1];
    } else {
      cov_1oes3fbrts.b[28][1]++;
    }
    cov_1oes3fbrts.s[153]++;
    list = ((cov_1oes3fbrts.b[30][0]++, el.URL_VIDEO) || (cov_1oes3fbrts.b[30][1]++, '')).split('?');
    cov_1oes3fbrts.s[154]++;
    if (list.length == 1) {
      cov_1oes3fbrts.b[31][0]++;
      cov_1oes3fbrts.s[155]++;

      list = ((cov_1oes3fbrts.b[32][0]++, el.URL_VIDEO) || (cov_1oes3fbrts.b[32][1]++, '')).split('/');
    } else {
      cov_1oes3fbrts.b[31][1]++;
    }

    var params = (cov_1oes3fbrts.s[156]++, ((cov_1oes3fbrts.b[33][0]++, list[list.length - 1]) || (cov_1oes3fbrts.b[33][1]++, '')).split('&'));

    cov_1oes3fbrts.s[157]++;
    return ((cov_1oes3fbrts.b[34][0]++, params[0]) || (cov_1oes3fbrts.b[34][1]++, '')).replace('v=', '');
  };
  cov_1oes3fbrts.s[158]++;
  $scope.gotoFolder = function (item, type) {
    cov_1oes3fbrts.f[23]++;
    cov_1oes3fbrts.s[159]++;

    if (item) {
      cov_1oes3fbrts.b[35][0]++;
      cov_1oes3fbrts.s[160]++;

      if (item.ID_CLS_PHOTO) {
        cov_1oes3fbrts.b[36][0]++;
        cov_1oes3fbrts.s[161]++;

        return;
      } else {
        cov_1oes3fbrts.b[36][1]++;
      }
    } else {
      cov_1oes3fbrts.b[35][1]++;
      cov_1oes3fbrts.s[162]++;

      $scope.listcls = [];
    }
    cov_1oes3fbrts.s[163]++;
    if (type) {
      cov_1oes3fbrts.b[37][0]++;
      cov_1oes3fbrts.s[164]++;
      // ajouter à la liste
      item.toString = function () {
        cov_1oes3fbrts.f[24]++;
        cov_1oes3fbrts.s[165]++;

        return item.LABEL_CLS_PHOTO;
      };
      cov_1oes3fbrts.s[166]++;
      $scope.listcls.push(item);
    } else {
      cov_1oes3fbrts.b[37][1]++;
      cov_1oes3fbrts.s[167]++;
      // Retirer de la liste
      for (var i = $scope.listcls.length - 1; i >= 0; i--) {
        cov_1oes3fbrts.s[168]++;

        if (item.id == $scope.listcls[i].id) {
          cov_1oes3fbrts.b[38][0]++;
          cov_1oes3fbrts.s[169]++;

          break;
        } else {
          cov_1oes3fbrts.b[38][1]++;
        }
        cov_1oes3fbrts.s[170]++;
        $scope.listcls.pop();
      }
    }
    cov_1oes3fbrts.s[171]++;
    $scope.readonlyItem = false;
    cov_1oes3fbrts.s[172]++;
    if ($scope.listcls.length > 0) {
      cov_1oes3fbrts.b[39][0]++;
      cov_1oes3fbrts.s[173]++;

      $scope.readonlyItem = $scope.listcls[$scope.listcls.length - 1].ID_PROJET != $rootScope.currentProject.project.id;
    } else {
      cov_1oes3fbrts.b[39][1]++;
    }

    cov_1oes3fbrts.s[174]++;
    _getAll(item);
    cov_1oes3fbrts.s[175]++;
    _getAllDoc(item);
  };
  cov_1oes3fbrts.s[176]++;
  $scope.addnewfolder = function () {
    cov_1oes3fbrts.f[25]++;
    cov_1oes3fbrts.s[177]++;

    this.editfolder({
      TYPE: $scope.mediaType,
      TYPE_CLS: $scope.mediaType,
      CODE_CLS_PHOTO: '',
      ID_PERE_CLS_PHOTO: $scope.listcls[$scope.listcls.length - 1] ? (cov_1oes3fbrts.b[40][0]++, $scope.listcls[$scope.listcls.length - 1].id) : (cov_1oes3fbrts.b[40][1]++, 0),
      LABEL_CLS_PHOTO: '',
      LEVEL_CLS_PHOTO: $scope.listcls.length + 1
    });
  };
  cov_1oes3fbrts.s[178]++;
  $scope.editfolder = function (item) {
    cov_1oes3fbrts.f[26]++;
    cov_1oes3fbrts.s[179]++;

    // $scope.isaddcls = true;
    $scope.selectedItemCls = angular.copy(item);
    cov_1oes3fbrts.s[180]++;
    $uibModal.open({
      templateUrl: 'app/views/photolibrary/editFolder.html',
      controller: photolibraryCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_1oes3fbrts.s[181]++;
  $scope.cancelcls = function () {
    cov_1oes3fbrts.f[27]++;
    cov_1oes3fbrts.s[182]++;

    $scope.isaddcls = false;
  };
  cov_1oes3fbrts.s[183]++;
  $scope.deletefolder = function (item) {
    cov_1oes3fbrts.f[28]++;
    cov_1oes3fbrts.s[184]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      text: $translate.instant('DOCUMENT.CONFIRM_DELETE_FOLDER'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',
      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1oes3fbrts.f[29]++;
      cov_1oes3fbrts.s[185]++;

      if (isconfirm) {
        cov_1oes3fbrts.b[41][0]++;
        cov_1oes3fbrts.s[186]++;

        return;
      } else {
        cov_1oes3fbrts.b[41][1]++;
      }
      cov_1oes3fbrts.s[187]++;
      $scope.isloading = true;
      cov_1oes3fbrts.s[188]++;
      $CRUDService.delet(PATH_CLS, { action: 'supp', valeur: $filter('json')(item) }, function (data) {
        cov_1oes3fbrts.f[30]++;
        cov_1oes3fbrts.s[189]++;

        $scope.isloading = false;
        cov_1oes3fbrts.s[190]++;
        $scope.gotoFolder($scope.listcls[$scope.listcls.length - 1]);
        cov_1oes3fbrts.s[191]++;
        $scope.cancelcls();
      });
    });
  };
  cov_1oes3fbrts.s[192]++;
  $scope.gotoFolder();

  cov_1oes3fbrts.s[193]++;
  $scope.uploader_cover = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=load_cover_file&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + PATH_DOC,
    alias: 'photo',
    removeAfterUpload: true
    // $scope.file.url += '&id=' + data;
  });
  cov_1oes3fbrts.s[194]++;
  $scope.uploader_cover.onAfterAddingFile = function (fileItem, a, b, c, d, e) {
    cov_1oes3fbrts.f[31]++;
    cov_1oes3fbrts.s[195]++;

    fileItem.url = $scope.uploader_cover.url + '&id=' + fileItem.id + '&date=' + Date.newDate().getTime();
    cov_1oes3fbrts.s[196]++;
    fileItem.upload();
  };
  cov_1oes3fbrts.s[197]++;
  $scope.uploader_cover.onSuccessItem = function (fileItem, response, status, headers) {
    cov_1oes3fbrts.f[32]++;
    cov_1oes3fbrts.s[198]++;
    var _iteratorNormalCompletion4 = true;
    var _didIteratorError4 = false;
    var _iteratorError4 = undefined;

    try {
      for (var _iterator4 = $scope.listDataDocument[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
        var item = _step4.value;
        cov_1oes3fbrts.s[199]++;

        if (item.id == fileItem.id) {
          cov_1oes3fbrts.b[42][0]++;
          cov_1oes3fbrts.s[200]++;

          if (item.ID_CLS_PHOTO) {
            cov_1oes3fbrts.b[43][0]++;
            cov_1oes3fbrts.s[201]++;

            continue;
          } else {
            cov_1oes3fbrts.b[43][1]++;
          }
          cov_1oes3fbrts.s[202]++;
          item.COVER_PHOTO = Date.newDate().getTime();
          cov_1oes3fbrts.s[203]++;
          item.img = $deltahttp.getcoverphotolibrary(item.id, Date.newDate().getTime());
        } else {
          cov_1oes3fbrts.b[42][1]++;
        }
      }
    } catch (err) {
      _didIteratorError4 = true;
      _iteratorError4 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion4 && _iterator4.return) {
          _iterator4.return();
        }
      } finally {
        if (_didIteratorError4) {
          throw _iteratorError4;
        }
      }
    }
  };
  var uploader = (cov_1oes3fbrts.s[204]++, $scope.uploader = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadFile&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + PATH_DOC,
    alias: 'photo',
    removeAfterUpload: true
    // $scope.file.url += '&id=' + data;
  }));
  cov_1oes3fbrts.s[205]++;
  uploader.onAfterAddingFile = function (fileItem, a, b, c, d, e) {
    cov_1oes3fbrts.f[33]++;
    cov_1oes3fbrts.s[206]++;

    fileItem.url = uploader.url; // +"&indic="+fileItem.indic.id;
    cov_1oes3fbrts.s[207]++;
    $scope.file = fileItem;
    cov_1oes3fbrts.s[208]++;
    if (fileItem.idp > 0) {
      cov_1oes3fbrts.b[44][0]++;
      cov_1oes3fbrts.s[209]++;

      fileItem.url += '&id=' + fileItem.idp;
      cov_1oes3fbrts.s[210]++;
      fileItem.upload();
    } else {
      cov_1oes3fbrts.b[44][1]++;
      cov_1oes3fbrts.s[211]++;

      $scope.addnewphoto();
    }
  };
  cov_1oes3fbrts.s[212]++;
  uploader.onSuccessItem = function (fileItem, response, status, headers) {
    cov_1oes3fbrts.f[34]++;
    cov_1oes3fbrts.s[213]++;

    $scope.isloading = false;
    cov_1oes3fbrts.s[214]++;
    if (fileItem.idp > 0) {
      cov_1oes3fbrts.b[45][0]++;
      cov_1oes3fbrts.s[215]++;
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = $scope.listDataDocument[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var el = _step5.value;
          cov_1oes3fbrts.s[216]++;

          if ((cov_1oes3fbrts.b[47][0]++, el.id == fileItem.idp) && (cov_1oes3fbrts.b[47][1]++, el.TYPE == 0)) {
            cov_1oes3fbrts.b[46][0]++;
            cov_1oes3fbrts.s[217]++;

            el.img += '?0011';
            cov_1oes3fbrts.s[218]++;
            break;
          } else {
            cov_1oes3fbrts.b[46][1]++;
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      cov_1oes3fbrts.s[219]++;
      return;
    } else {
      cov_1oes3fbrts.b[45][1]++;
    }
    cov_1oes3fbrts.s[220]++;
    $scope.cancelPhoto();
    cov_1oes3fbrts.s[221]++;
    $scope.gotoFolder($scope.listcls[$scope.listcls.length - 1]);
  };
  cov_1oes3fbrts.s[222]++;
  uploader.onErrorItem = function (fileItem, response, status, headers) {
    cov_1oes3fbrts.f[35]++;
    cov_1oes3fbrts.s[223]++;

    $scope.isloading = false;

    cov_1oes3fbrts.s[224]++;
    $scope.cancelPhoto();
    cov_1oes3fbrts.s[225]++;
    $scope.gotoFolder($scope.listcls[$scope.listcls.length - 1]);
  };
});

function photolibraryCtrl_edit($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_1oes3fbrts.f[36]++;

  var PATH_CLS = (cov_1oes3fbrts.s[226]++, 'ClasseurPhototheque');
  cov_1oes3fbrts.s[227]++;
  $scope.validatecls = function () {
    cov_1oes3fbrts.f[37]++;

    var codeP = (cov_1oes3fbrts.s[228]++, $scope.listcls[$scope.listcls.length - 1] ? (cov_1oes3fbrts.b[48][0]++, $scope.listcls[$scope.listcls.length - 1].CODE_CLS_PHOTO) : (cov_1oes3fbrts.b[48][1]++, ''));
    var isnew = (cov_1oes3fbrts.s[229]++, false);
    cov_1oes3fbrts.s[230]++;
    if ($scope.selectedItemCls.id == 0) {
      cov_1oes3fbrts.b[49][0]++;
      cov_1oes3fbrts.s[231]++;

      $scope.selectedItemCls.CODE_CLS_PHOTO = codeP + $scope.selectedItemCls.CODE_CLS_PHOTO;
      cov_1oes3fbrts.s[232]++;
      isnew = true;
    } else {
      cov_1oes3fbrts.b[49][1]++;
    }
    cov_1oes3fbrts.s[233]++;
    $CRUDService.save(PATH_CLS, { action: 'MiseAJour', Code_Parent: codeP, valeur: $filter('json')($scope.selectedItemCls) }, function (data) {
      cov_1oes3fbrts.f[38]++;
      cov_1oes3fbrts.s[234]++;

      $scope.selectedItemCls.id = data;
      cov_1oes3fbrts.s[235]++;
      $scope.gotoFolder($scope.listcls[$scope.listcls.length - 1]);
      cov_1oes3fbrts.s[236]++;
      $scope.cancelcls();
      cov_1oes3fbrts.s[237]++;
      $scope.closeModal();
    });
  };
  cov_1oes3fbrts.s[238]++;
  $scope.closeModal = function () {
    cov_1oes3fbrts.f[39]++;
    cov_1oes3fbrts.s[239]++;

    $uibModalInstance.dismiss('cancel');
  };
}
function photolibraryCtrl_slide($scope, $uibModalInstance, $sce, $log) {
  cov_1oes3fbrts.f[40]++;
  cov_1oes3fbrts.s[240]++;

  $scope.sizePanel = {
    height: $('body').height() - 300,
    width: $('body').width()
  };

  cov_1oes3fbrts.s[241]++;
  $scope.slideData = [];
  // Search index
  var startIndex = (cov_1oes3fbrts.s[242]++, 0);
  cov_1oes3fbrts.s[243]++;
  for (var i = $scope.listDataDocument.length - 1; i >= 0; i--) {
    cov_1oes3fbrts.s[244]++;

    if ($scope.listDataDocument[i] == $scope.slide.item) {
      cov_1oes3fbrts.b[50][0]++;
      cov_1oes3fbrts.s[245]++;

      startIndex = i;
      cov_1oes3fbrts.s[246]++;
      break;
    } else {
      cov_1oes3fbrts.b[50][1]++;
    }
  }
  // Populate list
  cov_1oes3fbrts.s[247]++;
  for (var _i = 0; _i < $scope.listDataDocument.length; _i++) {
    var el = (cov_1oes3fbrts.s[248]++, $scope.listDataDocument[(_i + startIndex) % $scope.listDataDocument.length]);
    cov_1oes3fbrts.s[249]++;
    if (angular.isUndefined(el)) {
      cov_1oes3fbrts.b[51][0]++;
      cov_1oes3fbrts.s[250]++;

      break;
    } else {
      cov_1oes3fbrts.b[51][1]++;
    }
    cov_1oes3fbrts.s[251]++;
    if (angular.isUndefined(el.ID_CLS_PHOTO)) {
      cov_1oes3fbrts.b[52][0]++;
      cov_1oes3fbrts.s[252]++;

      continue;
    } else {
      cov_1oes3fbrts.b[52][1]++;
    }
    cov_1oes3fbrts.s[253]++;
    $scope.slideData.push({
      TYPE: el.TYPE,
      LABEL: el.DESCRIPTE,
      url: el.TYPE == 1 ? (cov_1oes3fbrts.b[53][0]++, $sce.trustAsResourceUrl('//www.youtube.com/embed/' + $scope.getVideoId(el) + '?rel=0')) : (cov_1oes3fbrts.b[53][1]++, el.img)
    }); // $sce.trustAsResourceUrl("//www.youtube.com/embed/9B7te184ZpQ?rel=0")
  }
  cov_1oes3fbrts.s[254]++;
  $scope.closeModal = function () {
    cov_1oes3fbrts.f[41]++;
    cov_1oes3fbrts.s[255]++;

    $uibModalInstance.dismiss('cancel');
  };
}