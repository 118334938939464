'use strict';

var cov_2rr60skmtv = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/default_log_frame/DefaultLogFrameCtrl.js',
      hash = '2deb82499f5e8a0af0bc11f89df4d1d10bba8b0a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/default_log_frame/DefaultLogFrameCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 178,
          column: 3
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 7,
          column: 27
        }
      },
      '2': {
        start: {
          line: 8,
          column: 15
        },
        end: {
          line: 8,
          column: 32
        }
      },
      '3': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 20,
          column: 5
        }
      },
      '4': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 64
        }
      },
      '5': {
        start: {
          line: 22,
          column: 2
        },
        end: {
          line: 28,
          column: 4
        }
      },
      '6': {
        start: {
          line: 23,
          column: 4
        },
        end: {
          line: 27,
          column: 7
        }
      },
      '7': {
        start: {
          line: 29,
          column: 2
        },
        end: {
          line: 39,
          column: 4
        }
      },
      '8': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 30,
          column: 45
        }
      },
      '9': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 38,
          column: 7
        }
      },
      '10': {
        start: {
          line: 41,
          column: 2
        },
        end: {
          line: 41,
          column: 18
        }
      },
      '11': {
        start: {
          line: 43,
          column: 2
        },
        end: {
          line: 73,
          column: 4
        }
      },
      '12': {
        start: {
          line: 44,
          column: 4
        },
        end: {
          line: 72,
          column: 7
        }
      },
      '13': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 57,
          column: 7
        }
      },
      '14': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 56,
          column: 15
        }
      },
      '15': {
        start: {
          line: 58,
          column: 6
        },
        end: {
          line: 58,
          column: 30
        }
      },
      '16': {
        start: {
          line: 59,
          column: 6
        },
        end: {
          line: 71,
          column: 9
        }
      },
      '17': {
        start: {
          line: 60,
          column: 8
        },
        end: {
          line: 70,
          column: 9
        }
      },
      '18': {
        start: {
          line: 61,
          column: 10
        },
        end: {
          line: 65,
          column: 13
        }
      },
      '19': {
        start: {
          line: 66,
          column: 10
        },
        end: {
          line: 66,
          column: 35
        }
      },
      '20': {
        start: {
          line: 68,
          column: 10
        },
        end: {
          line: 68,
          column: 49
        }
      },
      '21': {
        start: {
          line: 75,
          column: 2
        },
        end: {
          line: 177,
          column: 4
        }
      },
      '22': {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 78,
          column: 22
        }
      },
      '23': {
        start: {
          line: 79,
          column: 6
        },
        end: {
          line: 79,
          column: 30
        }
      },
      '24': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 80,
          column: 37
        }
      },
      '25': {
        start: {
          line: 81,
          column: 19
        },
        end: {
          line: 81,
          column: 36
        }
      },
      '26': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 82,
          column: 26
        }
      },
      '27': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 83,
          column: 30
        }
      },
      '28': {
        start: {
          line: 84,
          column: 6
        },
        end: {
          line: 86,
          column: 8
        }
      },
      '29': {
        start: {
          line: 87,
          column: 6
        },
        end: {
          line: 87,
          column: 30
        }
      },
      '30': {
        start: {
          line: 89,
          column: 6
        },
        end: {
          line: 95,
          column: 7
        }
      },
      '31': {
        start: {
          line: 90,
          column: 8
        },
        end: {
          line: 90,
          column: 33
        }
      },
      '32': {
        start: {
          line: 92,
          column: 8
        },
        end: {
          line: 94,
          column: 11
        }
      },
      '33': {
        start: {
          line: 93,
          column: 10
        },
        end: {
          line: 93,
          column: 60
        }
      },
      '34': {
        start: {
          line: 98,
          column: 6
        },
        end: {
          line: 98,
          column: 48
        }
      },
      '35': {
        start: {
          line: 101,
          column: 27
        },
        end: {
          line: 101,
          column: 66
        }
      },
      '36': {
        start: {
          line: 102,
          column: 6
        },
        end: {
          line: 102,
          column: 80
        }
      },
      '37': {
        start: {
          line: 102,
          column: 67
        },
        end: {
          line: 102,
          column: 78
        }
      },
      '38': {
        start: {
          line: 103,
          column: 6
        },
        end: {
          line: 103,
          column: 49
        }
      },
      '39': {
        start: {
          line: 104,
          column: 6
        },
        end: {
          line: 104,
          column: 49
        }
      },
      '40': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 105,
          column: 49
        }
      },
      '41': {
        start: {
          line: 106,
          column: 6
        },
        end: {
          line: 106,
          column: 49
        }
      },
      '42': {
        start: {
          line: 107,
          column: 6
        },
        end: {
          line: 107,
          column: 49
        }
      },
      '43': {
        start: {
          line: 108,
          column: 6
        },
        end: {
          line: 108,
          column: 38
        }
      },
      '44': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 109,
          column: 212
        }
      },
      '45': {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 112,
          column: 7
        }
      },
      '46': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 15
        }
      },
      '47': {
        start: {
          line: 114,
          column: 6
        },
        end: {
          line: 116,
          column: 7
        }
      },
      '48': {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 115,
          column: 50
        }
      },
      '49': {
        start: {
          line: 118,
          column: 6
        },
        end: {
          line: 120,
          column: 7
        }
      },
      '50': {
        start: {
          line: 119,
          column: 8
        },
        end: {
          line: 119,
          column: 50
        }
      },
      '51': {
        start: {
          line: 122,
          column: 6
        },
        end: {
          line: 124,
          column: 7
        }
      },
      '52': {
        start: {
          line: 123,
          column: 8
        },
        end: {
          line: 123,
          column: 50
        }
      },
      '53': {
        start: {
          line: 126,
          column: 6
        },
        end: {
          line: 128,
          column: 7
        }
      },
      '54': {
        start: {
          line: 127,
          column: 8
        },
        end: {
          line: 127,
          column: 50
        }
      },
      '55': {
        start: {
          line: 130,
          column: 6
        },
        end: {
          line: 132,
          column: 7
        }
      },
      '56': {
        start: {
          line: 131,
          column: 8
        },
        end: {
          line: 131,
          column: 50
        }
      },
      '57': {
        start: {
          line: 134,
          column: 6
        },
        end: {
          line: 136,
          column: 7
        }
      },
      '58': {
        start: {
          line: 135,
          column: 8
        },
        end: {
          line: 135,
          column: 15
        }
      },
      '59': {
        start: {
          line: 138,
          column: 6
        },
        end: {
          line: 143,
          column: 9
        }
      },
      '60': {
        start: {
          line: 140,
          column: 8
        },
        end: {
          line: 140,
          column: 45
        }
      },
      '61': {
        start: {
          line: 141,
          column: 8
        },
        end: {
          line: 141,
          column: 52
        }
      },
      '62': {
        start: {
          line: 142,
          column: 8
        },
        end: {
          line: 142,
          column: 30
        }
      },
      '63': {
        start: {
          line: 146,
          column: 6
        },
        end: {
          line: 151,
          column: 7
        }
      },
      '64': {
        start: {
          line: 147,
          column: 8
        },
        end: {
          line: 150,
          column: 9
        }
      },
      '65': {
        start: {
          line: 148,
          column: 10
        },
        end: {
          line: 148,
          column: 48
        }
      },
      '66': {
        start: {
          line: 149,
          column: 10
        },
        end: {
          line: 149,
          column: 16
        }
      },
      '67': {
        start: {
          line: 152,
          column: 6
        },
        end: {
          line: 152,
          column: 124
        }
      },
      '68': {
        start: {
          line: 153,
          column: 6
        },
        end: {
          line: 153,
          column: 36
        }
      },
      '69': {
        start: {
          line: 156,
          column: 6
        },
        end: {
          line: 156,
          column: 22
        }
      },
      '70': {
        start: {
          line: 159,
          column: 6
        },
        end: {
          line: 167,
          column: 9
        }
      },
      '71': {
        start: {
          line: 170,
          column: 6
        },
        end: {
          line: 170,
          column: 46
        }
      },
      '72': {
        start: {
          line: 171,
          column: 6
        },
        end: {
          line: 171,
          column: 45
        }
      },
      '73': {
        start: {
          line: 172,
          column: 6
        },
        end: {
          line: 174,
          column: 9
        }
      },
      '74': {
        start: {
          line: 173,
          column: 8
        },
        end: {
          line: 173,
          column: 38
        }
      },
      '75': {
        start: {
          line: 175,
          column: 6
        },
        end: {
          line: 175,
          column: 18
        }
      },
      '76': {
        start: {
          line: 181,
          column: 2
        },
        end: {
          line: 181,
          column: 28
        }
      },
      '77': {
        start: {
          line: 182,
          column: 2
        },
        end: {
          line: 182,
          column: 35
        }
      },
      '78': {
        start: {
          line: 183,
          column: 15
        },
        end: {
          line: 183,
          column: 32
        }
      },
      '79': {
        start: {
          line: 184,
          column: 2
        },
        end: {
          line: 184,
          column: 24
        }
      },
      '80': {
        start: {
          line: 185,
          column: 2
        },
        end: {
          line: 185,
          column: 28
        }
      },
      '81': {
        start: {
          line: 187,
          column: 2
        },
        end: {
          line: 189,
          column: 4
        }
      },
      '82': {
        start: {
          line: 192,
          column: 2
        },
        end: {
          line: 198,
          column: 3
        }
      },
      '83': {
        start: {
          line: 193,
          column: 4
        },
        end: {
          line: 193,
          column: 31
        }
      },
      '84': {
        start: {
          line: 195,
          column: 4
        },
        end: {
          line: 197,
          column: 7
        }
      },
      '85': {
        start: {
          line: 196,
          column: 6
        },
        end: {
          line: 196,
          column: 50
        }
      },
      '86': {
        start: {
          line: 200,
          column: 2
        },
        end: {
          line: 210,
          column: 4
        }
      },
      '87': {
        start: {
          line: 201,
          column: 4
        },
        end: {
          line: 206,
          column: 5
        }
      },
      '88': {
        start: {
          line: 202,
          column: 6
        },
        end: {
          line: 205,
          column: 7
        }
      },
      '89': {
        start: {
          line: 203,
          column: 8
        },
        end: {
          line: 203,
          column: 40
        }
      },
      '90': {
        start: {
          line: 204,
          column: 8
        },
        end: {
          line: 204,
          column: 14
        }
      },
      '91': {
        start: {
          line: 208,
          column: 4
        },
        end: {
          line: 208,
          column: 73
        }
      },
      '92': {
        start: {
          line: 209,
          column: 4
        },
        end: {
          line: 209,
          column: 28
        }
      },
      '93': {
        start: {
          line: 212,
          column: 2
        },
        end: {
          line: 214,
          column: 4
        }
      },
      '94': {
        start: {
          line: 213,
          column: 4
        },
        end: {
          line: 213,
          column: 40
        }
      },
      '95': {
        start: {
          line: 216,
          column: 2
        },
        end: {
          line: 230,
          column: 4
        }
      },
      '96': {
        start: {
          line: 217,
          column: 25
        },
        end: {
          line: 217,
          column: 58
        }
      },
      '97': {
        start: {
          line: 218,
          column: 4
        },
        end: {
          line: 218,
          column: 30
        }
      },
      '98': {
        start: {
          line: 219,
          column: 18
        },
        end: {
          line: 219,
          column: 111
        }
      },
      '99': {
        start: {
          line: 220,
          column: 4
        },
        end: {
          line: 225,
          column: 5
        }
      },
      '100': {
        start: {
          line: 221,
          column: 6
        },
        end: {
          line: 224,
          column: 7
        }
      },
      '101': {
        start: {
          line: 222,
          column: 8
        },
        end: {
          line: 222,
          column: 33
        }
      },
      '102': {
        start: {
          line: 223,
          column: 8
        },
        end: {
          line: 223,
          column: 15
        }
      },
      '103': {
        start: {
          line: 226,
          column: 4
        },
        end: {
          line: 229,
          column: 7
        }
      },
      '104': {
        start: {
          line: 227,
          column: 6
        },
        end: {
          line: 227,
          column: 26
        }
      },
      '105': {
        start: {
          line: 228,
          column: 6
        },
        end: {
          line: 228,
          column: 22
        }
      },
      '106': {
        start: {
          line: 231,
          column: 2
        },
        end: {
          line: 233,
          column: 4
        }
      },
      '107': {
        start: {
          line: 232,
          column: 4
        },
        end: {
          line: 232,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 58
          },
          end: {
            line: 2,
            column: 59
          }
        },
        loc: {
          start: {
            line: 2,
            column: 151
          },
          end: {
            line: 178,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 18,
            column: 46
          },
          end: {
            line: 18,
            column: 47
          }
        },
        loc: {
          start: {
            line: 18,
            column: 54
          },
          end: {
            line: 20,
            column: 3
          }
        },
        line: 18
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 22,
            column: 15
          },
          end: {
            line: 22,
            column: 16
          }
        },
        loc: {
          start: {
            line: 22,
            column: 27
          },
          end: {
            line: 28,
            column: 3
          }
        },
        line: 22
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 29,
            column: 16
          },
          end: {
            line: 29,
            column: 17
          }
        },
        loc: {
          start: {
            line: 29,
            column: 32
          },
          end: {
            line: 39,
            column: 3
          }
        },
        line: 29
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 43,
            column: 22
          },
          end: {
            line: 43,
            column: 23
          }
        },
        loc: {
          start: {
            line: 43,
            column: 38
          },
          end: {
            line: 73,
            column: 3
          }
        },
        line: 43
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 54,
            column: 7
          },
          end: {
            line: 54,
            column: 8
          }
        },
        loc: {
          start: {
            line: 54,
            column: 20
          },
          end: {
            line: 72,
            column: 5
          }
        },
        line: 54
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 59,
            column: 62
          },
          end: {
            line: 59,
            column: 63
          }
        },
        loc: {
          start: {
            line: 59,
            column: 70
          },
          end: {
            line: 71,
            column: 7
          }
        },
        line: 59
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 92,
            column: 91
          },
          end: {
            line: 92,
            column: 92
          }
        },
        loc: {
          start: {
            line: 92,
            column: 99
          },
          end: {
            line: 94,
            column: 9
          }
        },
        line: 92
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 102,
            column: 58
          },
          end: {
            line: 102,
            column: 59
          }
        },
        loc: {
          start: {
            line: 102,
            column: 67
          },
          end: {
            line: 102,
            column: 78
          }
        },
        line: 102
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 138,
            column: 92
          },
          end: {
            line: 138,
            column: 93
          }
        },
        loc: {
          start: {
            line: 138,
            column: 100
          },
          end: {
            line: 143,
            column: 7
          }
        },
        line: 138
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 172,
            column: 68
          },
          end: {
            line: 172,
            column: 69
          }
        },
        loc: {
          start: {
            line: 172,
            column: 86
          },
          end: {
            line: 174,
            column: 7
          }
        },
        line: 172
      },
      '11': {
        name: 'DefaultLogFrameCtrl___edit',
        decl: {
          start: {
            line: 180,
            column: 9
          },
          end: {
            line: 180,
            column: 35
          }
        },
        loc: {
          start: {
            line: 180,
            column: 86
          },
          end: {
            line: 234,
            column: 1
          }
        },
        line: 180
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 195,
            column: 81
          },
          end: {
            line: 195,
            column: 82
          }
        },
        loc: {
          start: {
            line: 195,
            column: 89
          },
          end: {
            line: 197,
            column: 5
          }
        },
        line: 195
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 200,
            column: 21
          },
          end: {
            line: 200,
            column: 22
          }
        },
        loc: {
          start: {
            line: 200,
            column: 33
          },
          end: {
            line: 210,
            column: 3
          }
        },
        line: 200
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 212,
            column: 24
          },
          end: {
            line: 212,
            column: 25
          }
        },
        loc: {
          start: {
            line: 212,
            column: 36
          },
          end: {
            line: 214,
            column: 3
          }
        },
        line: 212
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 216,
            column: 24
          },
          end: {
            line: 216,
            column: 25
          }
        },
        loc: {
          start: {
            line: 216,
            column: 36
          },
          end: {
            line: 230,
            column: 3
          }
        },
        line: 216
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 226,
            column: 90
          },
          end: {
            line: 226,
            column: 91
          }
        },
        loc: {
          start: {
            line: 226,
            column: 96
          },
          end: {
            line: 229,
            column: 5
          }
        },
        line: 226
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 231,
            column: 22
          },
          end: {
            line: 231,
            column: 23
          }
        },
        loc: {
          start: {
            line: 231,
            column: 34
          },
          end: {
            line: 233,
            column: 3
          }
        },
        line: 231
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 55,
            column: 6
          },
          end: {
            line: 57,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 55,
            column: 6
          },
          end: {
            line: 57,
            column: 7
          }
        }, {
          start: {
            line: 55,
            column: 6
          },
          end: {
            line: 57,
            column: 7
          }
        }],
        line: 55
      },
      '1': {
        loc: {
          start: {
            line: 60,
            column: 8
          },
          end: {
            line: 70,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 60,
            column: 8
          },
          end: {
            line: 70,
            column: 9
          }
        }, {
          start: {
            line: 60,
            column: 8
          },
          end: {
            line: 70,
            column: 9
          }
        }],
        line: 60
      },
      '2': {
        loc: {
          start: {
            line: 89,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 89,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        }, {
          start: {
            line: 89,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        }],
        line: 89
      },
      '3': {
        loc: {
          start: {
            line: 109,
            column: 42
          },
          end: {
            line: 109,
            column: 210
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 109,
            column: 42
          },
          end: {
            line: 109,
            column: 70
          }
        }, {
          start: {
            line: 109,
            column: 74
          },
          end: {
            line: 109,
            column: 105
          }
        }, {
          start: {
            line: 109,
            column: 109
          },
          end: {
            line: 109,
            column: 140
          }
        }, {
          start: {
            line: 109,
            column: 144
          },
          end: {
            line: 109,
            column: 175
          }
        }, {
          start: {
            line: 109,
            column: 179
          },
          end: {
            line: 109,
            column: 210
          }
        }],
        line: 109
      },
      '4': {
        loc: {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        }, {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        }],
        line: 110
      },
      '5': {
        loc: {
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 116,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 116,
            column: 7
          }
        }, {
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 116,
            column: 7
          }
        }],
        line: 114
      },
      '6': {
        loc: {
          start: {
            line: 118,
            column: 6
          },
          end: {
            line: 120,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 118,
            column: 6
          },
          end: {
            line: 120,
            column: 7
          }
        }, {
          start: {
            line: 118,
            column: 6
          },
          end: {
            line: 120,
            column: 7
          }
        }],
        line: 118
      },
      '7': {
        loc: {
          start: {
            line: 122,
            column: 6
          },
          end: {
            line: 124,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 122,
            column: 6
          },
          end: {
            line: 124,
            column: 7
          }
        }, {
          start: {
            line: 122,
            column: 6
          },
          end: {
            line: 124,
            column: 7
          }
        }],
        line: 122
      },
      '8': {
        loc: {
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 128,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 128,
            column: 7
          }
        }, {
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 128,
            column: 7
          }
        }],
        line: 126
      },
      '9': {
        loc: {
          start: {
            line: 130,
            column: 6
          },
          end: {
            line: 132,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 130,
            column: 6
          },
          end: {
            line: 132,
            column: 7
          }
        }, {
          start: {
            line: 130,
            column: 6
          },
          end: {
            line: 132,
            column: 7
          }
        }],
        line: 130
      },
      '10': {
        loc: {
          start: {
            line: 134,
            column: 6
          },
          end: {
            line: 136,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 134,
            column: 6
          },
          end: {
            line: 136,
            column: 7
          }
        }, {
          start: {
            line: 134,
            column: 6
          },
          end: {
            line: 136,
            column: 7
          }
        }],
        line: 134
      },
      '11': {
        loc: {
          start: {
            line: 134,
            column: 10
          },
          end: {
            line: 134,
            column: 193
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 134,
            column: 10
          },
          end: {
            line: 134,
            column: 41
          }
        }, {
          start: {
            line: 134,
            column: 45
          },
          end: {
            line: 134,
            column: 79
          }
        }, {
          start: {
            line: 134,
            column: 83
          },
          end: {
            line: 134,
            column: 117
          }
        }, {
          start: {
            line: 134,
            column: 121
          },
          end: {
            line: 134,
            column: 155
          }
        }, {
          start: {
            line: 134,
            column: 159
          },
          end: {
            line: 134,
            column: 193
          }
        }],
        line: 134
      },
      '12': {
        loc: {
          start: {
            line: 147,
            column: 8
          },
          end: {
            line: 150,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 147,
            column: 8
          },
          end: {
            line: 150,
            column: 9
          }
        }, {
          start: {
            line: 147,
            column: 8
          },
          end: {
            line: 150,
            column: 9
          }
        }],
        line: 147
      },
      '13': {
        loc: {
          start: {
            line: 192,
            column: 2
          },
          end: {
            line: 198,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 192,
            column: 2
          },
          end: {
            line: 198,
            column: 3
          }
        }, {
          start: {
            line: 192,
            column: 2
          },
          end: {
            line: 198,
            column: 3
          }
        }],
        line: 192
      },
      '14': {
        loc: {
          start: {
            line: 202,
            column: 6
          },
          end: {
            line: 205,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 202,
            column: 6
          },
          end: {
            line: 205,
            column: 7
          }
        }, {
          start: {
            line: 202,
            column: 6
          },
          end: {
            line: 205,
            column: 7
          }
        }],
        line: 202
      },
      '15': {
        loc: {
          start: {
            line: 221,
            column: 6
          },
          end: {
            line: 224,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 221,
            column: 6
          },
          end: {
            line: 224,
            column: 7
          }
        }, {
          start: {
            line: 221,
            column: 6
          },
          end: {
            line: 224,
            column: 7
          }
        }],
        line: 221
      },
      '16': {
        loc: {
          start: {
            line: 221,
            column: 10
          },
          end: {
            line: 221,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 221,
            column: 10
          },
          end: {
            line: 221,
            column: 14
          }
        }, {
          start: {
            line: 221,
            column: 18
          },
          end: {
            line: 221,
            column: 44
          }
        }],
        line: 221
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0, 0, 0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0, 0, 0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2rr60skmtv.s[0]++;

angular.module('app').controller('DefaultLogFrameCtrl__', function ($scope, SweetAlert, $uibModal, $translate, $CRUDService, $filter, $deltaDefaultTemplate) {
  cov_2rr60skmtv.f[0]++;
  cov_2rr60skmtv.s[1]++;

  // $rootScope.processPageRight('0_1_6');
  // if ($rootScope.global_access_page_denied) {
  //   return;
  // }
  $scope.listDefaults = [];
  var PATH = (cov_2rr60skmtv.s[2]++, 'DefaultLogFrame');
  /*
    $scope.getAll = function () {
      $scope.isloading = true;
      $CRUDService.getAll(PATH, {get: 'all'}, data => {
        $scope.isloading = false;
      });
    };
    $scope.getAll(); */

  cov_2rr60skmtv.s[3]++;
  $deltaDefaultTemplate.addController($scope, function (data) {
    cov_2rr60skmtv.f[1]++;
    cov_2rr60skmtv.s[4]++;

    $scope.listDefaults = $scope.listData_default_template_bksb;
  });

  cov_2rr60skmtv.s[5]++;
  $scope.add = function () {
    cov_2rr60skmtv.f[2]++;
    cov_2rr60skmtv.s[6]++;

    $scope.edit({
      id: 0,
      LABEL_LOG_FRAME: '',
      STRUCTURE: []
    });
  };
  cov_2rr60skmtv.s[7]++;
  $scope.edit = function (item) {
    cov_2rr60skmtv.f[3]++;
    cov_2rr60skmtv.s[8]++;

    $scope.validateItem = angular.copy(item);
    cov_2rr60skmtv.s[9]++;
    $uibModal.open({
      templateUrl: 'app/views/default_log_frame/edit.html',
      controller: DefaultLogFrameCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_2rr60skmtv.s[10]++;
  $scope.page = 1;

  cov_2rr60skmtv.s[11]++;
  $scope.deleteItem = function (item) {
    cov_2rr60skmtv.f[4]++;
    cov_2rr60skmtv.s[12]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',
      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_2rr60skmtv.f[5]++;
      cov_2rr60skmtv.s[13]++;

      if (isconfirm) {
        cov_2rr60skmtv.b[0][0]++;
        cov_2rr60skmtv.s[14]++;

        return;
      } else {
        cov_2rr60skmtv.b[0][1]++;
      }
      cov_2rr60skmtv.s[15]++;
      $scope.isloading = true;
      cov_2rr60skmtv.s[16]++;
      $CRUDService.delet(PATH, { action: 'supp', id: item.id }, function (data) {
        cov_2rr60skmtv.f[6]++;
        cov_2rr60skmtv.s[17]++;

        if (data < 0) {
          cov_2rr60skmtv.b[1][0]++;
          cov_2rr60skmtv.s[18]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
            type: 'warning'
          });
          cov_2rr60skmtv.s[19]++;
          $scope.isloading = false;
        } else {
          cov_2rr60skmtv.b[1][1]++;
          cov_2rr60skmtv.s[20]++;

          $deltaDefaultTemplate.removeData(data);
          // $scope.getAll();
        }
      });
    });
  };

  cov_2rr60skmtv.s[21]++;
  $scope.modal = {
    init: function init() {
      cov_2rr60skmtv.s[22]++;

      $scope.page = 2;
      cov_2rr60skmtv.s[23]++;
      this.error_code = false;
      cov_2rr60skmtv.s[24]++;
      this.error_code_option = false;
      var PATH = (cov_2rr60skmtv.s[25]++, 'DefaultLogFrame');
      cov_2rr60skmtv.s[26]++;
      this.newOption = '';
      cov_2rr60skmtv.s[27]++;
      this.blockDelete = true;
      cov_2rr60skmtv.s[28]++;
      this.data = {
        option: ''
      };
      cov_2rr60skmtv.s[29]++;
      this.blockDelete = true;
      // check_delete
      cov_2rr60skmtv.s[30]++;
      if (this.validateItem.id == 0) {
        cov_2rr60skmtv.b[2][0]++;
        cov_2rr60skmtv.s[31]++;

        this.blockDelete = false;
      } else {
        cov_2rr60skmtv.b[2][1]++;
        cov_2rr60skmtv.s[32]++;

        $CRUDService.getAll(PATH, { get: 'check_delete', id: $scope.modal.validateItem.id }, function (data) {
          cov_2rr60skmtv.f[7]++;
          cov_2rr60skmtv.s[33]++;

          $scope.modal.blockDelete = parseInt(data, 10) > 0;
        });
      }
    },
    deleteOption: function deleteOption() {
      cov_2rr60skmtv.s[34]++;

      $scope.modal.validateItem.STRUCTURE.pop();
    },
    validateData: function validateData() {
      var validateItem = (cov_2rr60skmtv.s[35]++, angular.copy($scope.modal.validateItem));
      cov_2rr60skmtv.s[36]++;
      validateItem.STRUCTURE = validateItem.STRUCTURE.map(function (value) {
        cov_2rr60skmtv.f[8]++;
        cov_2rr60skmtv.s[37]++;
        return value.value;
      });
      cov_2rr60skmtv.s[38]++;
      $scope.modal.error_code_require_fr = false;
      cov_2rr60skmtv.s[39]++;
      $scope.modal.error_code_require_en = false;
      cov_2rr60skmtv.s[40]++;
      $scope.modal.error_code_require_ar = false;
      cov_2rr60skmtv.s[41]++;
      $scope.modal.error_code_require_es = false;
      cov_2rr60skmtv.s[42]++;
      $scope.modal.error_code_require_pt = false;
      cov_2rr60skmtv.s[43]++;
      $scope.modal.error_code = false;
      cov_2rr60skmtv.s[44]++;
      $scope.modal.error_code_require = !((cov_2rr60skmtv.b[3][0]++, validateItem.LABEL_LOG_FRAME) || (cov_2rr60skmtv.b[3][1]++, validateItem.LABEL_LOG_FRAME_EN) || (cov_2rr60skmtv.b[3][2]++, validateItem.LABEL_LOG_FRAME_AR) || (cov_2rr60skmtv.b[3][3]++, validateItem.LABEL_LOG_FRAME_ES) || (cov_2rr60skmtv.b[3][4]++, validateItem.LABEL_LOG_FRAME_PT));
      cov_2rr60skmtv.s[45]++;
      if ($scope.modal.error_code_require) {
        cov_2rr60skmtv.b[4][0]++;
        cov_2rr60skmtv.s[46]++;

        return;
      } else {
        cov_2rr60skmtv.b[4][1]++;
      }

      cov_2rr60skmtv.s[47]++;
      if (validateItem.LABEL_LOG_FRAME.length > 20) {
        cov_2rr60skmtv.b[5][0]++;
        cov_2rr60skmtv.s[48]++;

        $scope.modal.error_code_require_fr = true;
      } else {
        cov_2rr60skmtv.b[5][1]++;
      }

      cov_2rr60skmtv.s[49]++;
      if (validateItem.LABEL_LOG_FRAME_EN.length > 20) {
        cov_2rr60skmtv.b[6][0]++;
        cov_2rr60skmtv.s[50]++;

        $scope.modal.error_code_require_en = true;
      } else {
        cov_2rr60skmtv.b[6][1]++;
      }

      cov_2rr60skmtv.s[51]++;
      if (validateItem.LABEL_LOG_FRAME_AR.length > 20) {
        cov_2rr60skmtv.b[7][0]++;
        cov_2rr60skmtv.s[52]++;

        $scope.modal.error_code_require_ar = true;
      } else {
        cov_2rr60skmtv.b[7][1]++;
      }

      cov_2rr60skmtv.s[53]++;
      if (validateItem.LABEL_LOG_FRAME_ES.length > 20) {
        cov_2rr60skmtv.b[8][0]++;
        cov_2rr60skmtv.s[54]++;

        $scope.modal.error_code_require_es = true;
      } else {
        cov_2rr60skmtv.b[8][1]++;
      }

      cov_2rr60skmtv.s[55]++;
      if (validateItem.LABEL_LOG_FRAME_PT.length > 20) {
        cov_2rr60skmtv.b[9][0]++;
        cov_2rr60skmtv.s[56]++;

        $scope.modal.error_code_require_pt = true;
      } else {
        cov_2rr60skmtv.b[9][1]++;
      }

      cov_2rr60skmtv.s[57]++;
      if ((cov_2rr60skmtv.b[11][0]++, $scope.modal.error_code_require) || (cov_2rr60skmtv.b[11][1]++, $scope.modal.error_code_require_en) || (cov_2rr60skmtv.b[11][2]++, $scope.modal.error_code_require_ar) || (cov_2rr60skmtv.b[11][3]++, $scope.modal.error_code_require_es) || (cov_2rr60skmtv.b[11][4]++, $scope.modal.error_code_require_pt)) {
        cov_2rr60skmtv.b[10][0]++;
        cov_2rr60skmtv.s[58]++;

        return;
      } else {
        cov_2rr60skmtv.b[10][1]++;
      }

      cov_2rr60skmtv.s[59]++;
      $CRUDService.save(PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function (data) {
        cov_2rr60skmtv.f[9]++;
        cov_2rr60skmtv.s[60]++;

        // $scope.closeModal();
        validateItem.id = parseInt(data, 10);
        cov_2rr60skmtv.s[61]++;
        $deltaDefaultTemplate.addData(validateItem);
        cov_2rr60skmtv.s[62]++;
        $scope.modal.cancel();
      });
    },
    addOption: function addOption() {
      cov_2rr60skmtv.s[63]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = $scope.modal.validateItem.STRUCTURE[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;
          cov_2rr60skmtv.s[64]++;

          if (item == $scope.modal.data.option) {
            cov_2rr60skmtv.b[12][0]++;
            cov_2rr60skmtv.s[65]++;

            $scope.modal.error_code_option = true;
            cov_2rr60skmtv.s[66]++;
            break;
          } else {
            cov_2rr60skmtv.b[12][1]++;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_2rr60skmtv.s[67]++;
      $scope.modal.validateItem.STRUCTURE.push({ id: new Date().getTime(), value: angular.copy($scope.modal.data.option) });
      cov_2rr60skmtv.s[68]++;
      $scope.modal.data.option = '';
    },
    cancel: function cancel() {
      cov_2rr60skmtv.s[69]++;

      $scope.page = 1;
    },
    addModal: function addModal() {
      cov_2rr60skmtv.s[70]++;

      this.editModal({
        id: 0,
        LABEL_LOG_FRAME: 'Cadre Logique',
        LABEL_LOG_FRAME_EN: 'LogFrame',
        LABEL_LOG_FRAME_ES: 'Marco de Registro',
        LABEL_LOG_FRAME_PT: 'Quadro de Registro',
        LABEL_LOG_FRAME_AR: 'سجل الإطار',
        STRUCTURE: []
      });
    },
    editModal: function editModal(item) {
      cov_2rr60skmtv.s[71]++;

      $scope.modal.error_code_require = false;
      cov_2rr60skmtv.s[72]++;
      this.validateItem = angular.copy(item);
      cov_2rr60skmtv.s[73]++;
      this.validateItem.STRUCTURE = this.validateItem.STRUCTURE.map(function (value, index) {
        cov_2rr60skmtv.f[10]++;
        cov_2rr60skmtv.s[74]++;

        return { value: value, id: index + 1 };
      });
      cov_2rr60skmtv.s[75]++;
      this.init();
    }
  };
});

function DefaultLogFrameCtrl___edit($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_2rr60skmtv.f[11]++;
  cov_2rr60skmtv.s[76]++;

  $scope.error_code = false;
  cov_2rr60skmtv.s[77]++;
  $scope.error_code_option = false;
  var PATH = (cov_2rr60skmtv.s[78]++, 'DefaultLogFrame');
  cov_2rr60skmtv.s[79]++;
  $scope.newOption = '';
  cov_2rr60skmtv.s[80]++;
  $scope.blockDelete = true;

  cov_2rr60skmtv.s[81]++;
  $scope.data = {
    option: ''
  };

  // check_delete
  cov_2rr60skmtv.s[82]++;
  if ($scope.validateItem.id == 0) {
    cov_2rr60skmtv.b[13][0]++;
    cov_2rr60skmtv.s[83]++;

    $scope.blockDelete = false;
  } else {
    cov_2rr60skmtv.b[13][1]++;
    cov_2rr60skmtv.s[84]++;

    $CRUDService.getAll(PATH, { get: 'check_delete', id: $scope.validateItem.id }, function (data) {
      cov_2rr60skmtv.f[12]++;
      cov_2rr60skmtv.s[85]++;

      $scope.blockDelete = parseInt(data, 10) > 0;
    });
  }

  cov_2rr60skmtv.s[86]++;
  $scope.addOption = function () {
    cov_2rr60skmtv.f[13]++;
    cov_2rr60skmtv.s[87]++;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = $scope.validateItem.STRUCTURE[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var item = _step2.value;
        cov_2rr60skmtv.s[88]++;

        if (item == $scope.data.option) {
          cov_2rr60skmtv.b[14][0]++;
          cov_2rr60skmtv.s[89]++;

          $scope.error_code_option = true;
          cov_2rr60skmtv.s[90]++;
          break;
        } else {
          cov_2rr60skmtv.b[14][1]++;
        }
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    cov_2rr60skmtv.s[91]++;
    $scope.validateItem.STRUCTURE.push(angular.copy($scope.data.option));
    cov_2rr60skmtv.s[92]++;
    $scope.data.option = '';
  };

  cov_2rr60skmtv.s[93]++;
  $scope.deleteOption = function () {
    cov_2rr60skmtv.f[14]++;
    cov_2rr60skmtv.s[94]++;

    $scope.validateItem.STRUCTURE.pop();
  };

  cov_2rr60skmtv.s[95]++;
  $scope.validateData = function () {
    cov_2rr60skmtv.f[15]++;

    var validateItem = (cov_2rr60skmtv.s[96]++, angular.copy($scope.validateItem));
    cov_2rr60skmtv.s[97]++;
    $scope.error_code = false;
    var items = (cov_2rr60skmtv.s[98]++, $filter('filter')($scope.listDefaults, { LABEL_LOG_FRAME: validateItem.LABEL_LOG_FRAME }, true));
    cov_2rr60skmtv.s[99]++;
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = items[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var item = _step3.value;
        cov_2rr60skmtv.s[100]++;

        if ((cov_2rr60skmtv.b[16][0]++, item) && (cov_2rr60skmtv.b[16][1]++, item.id != validateItem.id)) {
          cov_2rr60skmtv.b[15][0]++;
          cov_2rr60skmtv.s[101]++;

          $scope.error_code = true;
          cov_2rr60skmtv.s[102]++;
          return;
        } else {
          cov_2rr60skmtv.b[15][1]++;
        }
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    cov_2rr60skmtv.s[103]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function () {
      cov_2rr60skmtv.f[16]++;
      cov_2rr60skmtv.s[104]++;

      $scope.closeModal();
      cov_2rr60skmtv.s[105]++;
      $scope.getAll();
    });
  };
  cov_2rr60skmtv.s[106]++;
  $scope.closeModal = function () {
    cov_2rr60skmtv.f[17]++;
    cov_2rr60skmtv.s[107]++;

    $uibModalInstance.dismiss('cancel');
  };
}