'use strict';

var cov_1c8fg2jx5r = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/SourceFin/SourceFinCtrl.js',
      hash = '240280c0123eab6e305a87fe10c528b7ebc1ce02',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/SourceFin/SourceFinCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 3,
          column: 33
        }
      },
      '1': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 45,
          column: 4
        }
      },
      '2': {
        start: {
          line: 5,
          column: 25
        },
        end: {
          line: 16,
          column: 5
        }
      },
      '3': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 17,
          column: 30
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 20,
          column: 5
        }
      },
      '5': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 13
        }
      },
      '6': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 24,
          column: 5
        }
      },
      '7': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 13
        }
      },
      '8': {
        start: {
          line: 26,
          column: 4
        },
        end: {
          line: 37,
          column: 5
        }
      },
      '9': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '10': {
        start: {
          line: 28,
          column: 21
        },
        end: {
          line: 28,
          column: 42
        }
      },
      '11': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 35,
          column: 9
        }
      },
      '12': {
        start: {
          line: 31,
          column: 10
        },
        end: {
          line: 34,
          column: 11
        }
      },
      '13': {
        start: {
          line: 32,
          column: 12
        },
        end: {
          line: 32,
          column: 37
        }
      },
      '14': {
        start: {
          line: 33,
          column: 12
        },
        end: {
          line: 33,
          column: 19
        }
      },
      '15': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 38,
          column: 28
        }
      },
      '16': {
        start: {
          line: 39,
          column: 4
        },
        end: {
          line: 44,
          column: 7
        }
      },
      '17': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 40,
          column: 112
        }
      },
      '18': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 41,
          column: 25
        }
      },
      '19': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 42,
          column: 22
        }
      },
      '20': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 43,
          column: 26
        }
      },
      '21': {
        start: {
          line: 46,
          column: 2
        },
        end: {
          line: 48,
          column: 4
        }
      },
      '22': {
        start: {
          line: 47,
          column: 4
        },
        end: {
          line: 47,
          column: 40
        }
      },
      '23': {
        start: {
          line: 50,
          column: 2
        },
        end: {
          line: 50,
          column: 54
        }
      },
      '24': {
        start: {
          line: 51,
          column: 19
        },
        end: {
          line: 56,
          column: 4
        }
      },
      '25': {
        start: {
          line: 57,
          column: 2
        },
        end: {
          line: 60,
          column: 4
        }
      },
      '26': {
        start: {
          line: 58,
          column: 4
        },
        end: {
          line: 58,
          column: 32
        }
      },
      '27': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 59,
          column: 22
        }
      },
      '28': {
        start: {
          line: 61,
          column: 2
        },
        end: {
          line: 63,
          column: 4
        }
      },
      '29': {
        start: {
          line: 62,
          column: 4
        },
        end: {
          line: 62,
          column: 89
        }
      },
      '30': {
        start: {
          line: 64,
          column: 2
        },
        end: {
          line: 66,
          column: 4
        }
      },
      '31': {
        start: {
          line: 65,
          column: 4
        },
        end: {
          line: 65,
          column: 35
        }
      },
      '32': {
        start: {
          line: 69,
          column: 0
        },
        end: {
          line: 274,
          column: 3
        }
      },
      '33': {
        start: {
          line: 70,
          column: 2
        },
        end: {
          line: 70,
          column: 37
        }
      },
      '34': {
        start: {
          line: 71,
          column: 2
        },
        end: {
          line: 73,
          column: 3
        }
      },
      '35': {
        start: {
          line: 72,
          column: 4
        },
        end: {
          line: 72,
          column: 11
        }
      },
      '36': {
        start: {
          line: 74,
          column: 2
        },
        end: {
          line: 74,
          column: 21
        }
      },
      '37': {
        start: {
          line: 75,
          column: 2
        },
        end: {
          line: 75,
          column: 22
        }
      },
      '38': {
        start: {
          line: 76,
          column: 2
        },
        end: {
          line: 76,
          column: 27
        }
      },
      '39': {
        start: {
          line: 77,
          column: 2
        },
        end: {
          line: 77,
          column: 26
        }
      },
      '40': {
        start: {
          line: 78,
          column: 2
        },
        end: {
          line: 78,
          column: 30
        }
      },
      '41': {
        start: {
          line: 79,
          column: 2
        },
        end: {
          line: 79,
          column: 49
        }
      },
      '42': {
        start: {
          line: 80,
          column: 18
        },
        end: {
          line: 80,
          column: 48
        }
      },
      '43': {
        start: {
          line: 81,
          column: 17
        },
        end: {
          line: 81,
          column: 19
        }
      },
      '44': {
        start: {
          line: 82,
          column: 2
        },
        end: {
          line: 85,
          column: 4
        }
      },
      '45': {
        start: {
          line: 87,
          column: 2
        },
        end: {
          line: 93,
          column: 5
        }
      },
      '46': {
        start: {
          line: 88,
          column: 4
        },
        end: {
          line: 92,
          column: 7
        }
      },
      '47': {
        start: {
          line: 95,
          column: 2
        },
        end: {
          line: 95,
          column: 263
        }
      },
      '48': {
        start: {
          line: 97,
          column: 2
        },
        end: {
          line: 97,
          column: 158
        }
      },
      '49': {
        start: {
          line: 99,
          column: 2
        },
        end: {
          line: 99,
          column: 262
        }
      },
      '50': {
        start: {
          line: 101,
          column: 2
        },
        end: {
          line: 139,
          column: 4
        }
      },
      '51': {
        start: {
          line: 126,
          column: 6
        },
        end: {
          line: 129,
          column: 7
        }
      },
      '52': {
        start: {
          line: 127,
          column: 8
        },
        end: {
          line: 127,
          column: 29
        }
      },
      '53': {
        start: {
          line: 128,
          column: 8
        },
        end: {
          line: 128,
          column: 21
        }
      },
      '54': {
        start: {
          line: 130,
          column: 6
        },
        end: {
          line: 133,
          column: 7
        }
      },
      '55': {
        start: {
          line: 131,
          column: 8
        },
        end: {
          line: 131,
          column: 29
        }
      },
      '56': {
        start: {
          line: 132,
          column: 8
        },
        end: {
          line: 132,
          column: 42
        }
      },
      '57': {
        start: {
          line: 134,
          column: 6
        },
        end: {
          line: 137,
          column: 7
        }
      },
      '58': {
        start: {
          line: 135,
          column: 8
        },
        end: {
          line: 135,
          column: 29
        }
      },
      '59': {
        start: {
          line: 136,
          column: 8
        },
        end: {
          line: 136,
          column: 45
        }
      },
      '60': {
        start: {
          line: 140,
          column: 2
        },
        end: {
          line: 156,
          column: 4
        }
      },
      '61': {
        start: {
          line: 141,
          column: 4
        },
        end: {
          line: 141,
          column: 51
        }
      },
      '62': {
        start: {
          line: 142,
          column: 4
        },
        end: {
          line: 142,
          column: 28
        }
      },
      '63': {
        start: {
          line: 143,
          column: 4
        },
        end: {
          line: 143,
          column: 28
        }
      },
      '64': {
        start: {
          line: 144,
          column: 4
        },
        end: {
          line: 155,
          column: 7
        }
      },
      '65': {
        start: {
          line: 145,
          column: 6
        },
        end: {
          line: 152,
          column: 7
        }
      },
      '66': {
        start: {
          line: 146,
          column: 8
        },
        end: {
          line: 146,
          column: 23
        }
      },
      '67': {
        start: {
          line: 147,
          column: 8
        },
        end: {
          line: 149,
          column: 9
        }
      },
      '68': {
        start: {
          line: 148,
          column: 10
        },
        end: {
          line: 148,
          column: 74
        }
      },
      '69': {
        start: {
          line: 150,
          column: 8
        },
        end: {
          line: 150,
          column: 42
        }
      },
      '70': {
        start: {
          line: 151,
          column: 8
        },
        end: {
          line: 151,
          column: 46
        }
      },
      '71': {
        start: {
          line: 153,
          column: 6
        },
        end: {
          line: 153,
          column: 32
        }
      },
      '72': {
        start: {
          line: 154,
          column: 6
        },
        end: {
          line: 154,
          column: 31
        }
      },
      '73': {
        start: {
          line: 157,
          column: 2
        },
        end: {
          line: 157,
          column: 18
        }
      },
      '74': {
        start: {
          line: 159,
          column: 2
        },
        end: {
          line: 163,
          column: 4
        }
      },
      '75': {
        start: {
          line: 160,
          column: 4
        },
        end: {
          line: 160,
          column: 22
        }
      },
      '76': {
        start: {
          line: 161,
          column: 4
        },
        end: {
          line: 161,
          column: 24
        }
      },
      '77': {
        start: {
          line: 162,
          column: 4
        },
        end: {
          line: 162,
          column: 21
        }
      },
      '78': {
        start: {
          line: 165,
          column: 2
        },
        end: {
          line: 217,
          column: 4
        }
      },
      '79': {
        start: {
          line: 166,
          column: 4
        },
        end: {
          line: 166,
          column: 29
        }
      },
      '80': {
        start: {
          line: 167,
          column: 4
        },
        end: {
          line: 167,
          column: 23
        }
      },
      '81': {
        start: {
          line: 168,
          column: 4
        },
        end: {
          line: 168,
          column: 24
        }
      },
      '82': {
        start: {
          line: 169,
          column: 25
        },
        end: {
          line: 181,
          column: 5
        }
      },
      '83': {
        start: {
          line: 182,
          column: 4
        },
        end: {
          line: 208,
          column: 5
        }
      },
      '84': {
        start: {
          line: 183,
          column: 6
        },
        end: {
          line: 204,
          column: 7
        }
      },
      '85': {
        start: {
          line: 184,
          column: 8
        },
        end: {
          line: 203,
          column: 9
        }
      },
      '86': {
        start: {
          line: 185,
          column: 23
        },
        end: {
          line: 185,
          column: 44
        }
      },
      '87': {
        start: {
          line: 186,
          column: 10
        },
        end: {
          line: 202,
          column: 11
        }
      },
      '88': {
        start: {
          line: 187,
          column: 12
        },
        end: {
          line: 187,
          column: 31
        }
      },
      '89': {
        start: {
          line: 188,
          column: 12
        },
        end: {
          line: 200,
          column: 14
        }
      },
      '90': {
        start: {
          line: 201,
          column: 12
        },
        end: {
          line: 201,
          column: 18
        }
      },
      '91': {
        start: {
          line: 206,
          column: 6
        },
        end: {
          line: 206,
          column: 24
        }
      },
      '92': {
        start: {
          line: 207,
          column: 6
        },
        end: {
          line: 207,
          column: 41
        }
      },
      '93': {
        start: {
          line: 209,
          column: 4
        },
        end: {
          line: 216,
          column: 7
        }
      },
      '94': {
        start: {
          line: 219,
          column: 2
        },
        end: {
          line: 223,
          column: 4
        }
      },
      '95': {
        start: {
          line: 220,
          column: 4
        },
        end: {
          line: 220,
          column: 23
        }
      },
      '96': {
        start: {
          line: 221,
          column: 4
        },
        end: {
          line: 221,
          column: 24
        }
      },
      '97': {
        start: {
          line: 222,
          column: 4
        },
        end: {
          line: 222,
          column: 29
        }
      },
      '98': {
        start: {
          line: 225,
          column: 2
        },
        end: {
          line: 228,
          column: 4
        }
      },
      '99': {
        start: {
          line: 226,
          column: 4
        },
        end: {
          line: 226,
          column: 28
        }
      },
      '100': {
        start: {
          line: 227,
          column: 4
        },
        end: {
          line: 227,
          column: 31
        }
      },
      '101': {
        start: {
          line: 230,
          column: 2
        },
        end: {
          line: 270,
          column: 4
        }
      },
      '102': {
        start: {
          line: 231,
          column: 4
        },
        end: {
          line: 269,
          column: 7
        }
      },
      '103': {
        start: {
          line: 243,
          column: 6
        },
        end: {
          line: 245,
          column: 7
        }
      },
      '104': {
        start: {
          line: 244,
          column: 8
        },
        end: {
          line: 244,
          column: 15
        }
      },
      '105': {
        start: {
          line: 246,
          column: 6
        },
        end: {
          line: 246,
          column: 30
        }
      },
      '106': {
        start: {
          line: 247,
          column: 27
        },
        end: {
          line: 252,
          column: 7
        }
      },
      '107': {
        start: {
          line: 253,
          column: 6
        },
        end: {
          line: 268,
          column: 9
        }
      },
      '108': {
        start: {
          line: 254,
          column: 8
        },
        end: {
          line: 261,
          column: 9
        }
      },
      '109': {
        start: {
          line: 255,
          column: 10
        },
        end: {
          line: 258,
          column: 13
        }
      },
      '110': {
        start: {
          line: 259,
          column: 10
        },
        end: {
          line: 259,
          column: 35
        }
      },
      '111': {
        start: {
          line: 260,
          column: 10
        },
        end: {
          line: 260,
          column: 17
        }
      },
      '112': {
        start: {
          line: 266,
          column: 8
        },
        end: {
          line: 266,
          column: 27
        }
      },
      '113': {
        start: {
          line: 267,
          column: 8
        },
        end: {
          line: 267,
          column: 24
        }
      },
      '114': {
        start: {
          line: 271,
          column: 2
        },
        end: {
          line: 273,
          column: 4
        }
      }
    },
    fnMap: {
      '0': {
        name: 'SourceFinance_edit',
        decl: {
          start: {
            line: 2,
            column: 9
          },
          end: {
            line: 2,
            column: 27
          }
        },
        loc: {
          start: {
            line: 2,
            column: 116
          },
          end: {
            line: 67,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 4,
            column: 24
          },
          end: {
            line: 4,
            column: 25
          }
        },
        loc: {
          start: {
            line: 4,
            column: 40
          },
          end: {
            line: 45,
            column: 3
          }
        },
        line: 4
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 39,
            column: 97
          },
          end: {
            line: 39,
            column: 98
          }
        },
        loc: {
          start: {
            line: 39,
            column: 105
          },
          end: {
            line: 44,
            column: 5
          }
        },
        line: 39
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 46,
            column: 22
          },
          end: {
            line: 46,
            column: 23
          }
        },
        loc: {
          start: {
            line: 46,
            column: 34
          },
          end: {
            line: 48,
            column: 3
          }
        },
        line: 46
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 57,
            column: 31
          },
          end: {
            line: 57,
            column: 32
          }
        },
        loc: {
          start: {
            line: 57,
            column: 51
          },
          end: {
            line: 60,
            column: 3
          }
        },
        line: 57
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 61,
            column: 27
          },
          end: {
            line: 61,
            column: 28
          }
        },
        loc: {
          start: {
            line: 61,
            column: 74
          },
          end: {
            line: 63,
            column: 3
          }
        },
        line: 61
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 64,
            column: 25
          },
          end: {
            line: 64,
            column: 26
          }
        },
        loc: {
          start: {
            line: 64,
            column: 72
          },
          end: {
            line: 66,
            column: 3
          }
        },
        line: 64
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 69,
            column: 50
          },
          end: {
            line: 69,
            column: 51
          }
        },
        loc: {
          start: {
            line: 69,
            column: 186
          },
          end: {
            line: 274,
            column: 1
          }
        },
        line: 69
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 87,
            column: 22
          },
          end: {
            line: 87,
            column: 23
          }
        },
        loc: {
          start: {
            line: 87,
            column: 28
          },
          end: {
            line: 93,
            column: 3
          }
        },
        line: 87
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 140,
            column: 18
          },
          end: {
            line: 140,
            column: 19
          }
        },
        loc: {
          start: {
            line: 140,
            column: 30
          },
          end: {
            line: 156,
            column: 3
          }
        },
        line: 140
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 144,
            column: 51
          },
          end: {
            line: 144,
            column: 52
          }
        },
        loc: {
          start: {
            line: 144,
            column: 59
          },
          end: {
            line: 155,
            column: 5
          }
        },
        line: 144
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 159,
            column: 18
          },
          end: {
            line: 159,
            column: 19
          }
        },
        loc: {
          start: {
            line: 159,
            column: 30
          },
          end: {
            line: 163,
            column: 3
          }
        },
        line: 159
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 165,
            column: 19
          },
          end: {
            line: 165,
            column: 20
          }
        },
        loc: {
          start: {
            line: 165,
            column: 33
          },
          end: {
            line: 217,
            column: 3
          }
        },
        line: 165
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 219,
            column: 21
          },
          end: {
            line: 219,
            column: 22
          }
        },
        loc: {
          start: {
            line: 219,
            column: 33
          },
          end: {
            line: 223,
            column: 3
          }
        },
        line: 219
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 225,
            column: 22
          },
          end: {
            line: 225,
            column: 23
          }
        },
        loc: {
          start: {
            line: 225,
            column: 46
          },
          end: {
            line: 228,
            column: 3
          }
        },
        line: 225
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 230,
            column: 25
          },
          end: {
            line: 230,
            column: 26
          }
        },
        loc: {
          start: {
            line: 230,
            column: 41
          },
          end: {
            line: 270,
            column: 3
          }
        },
        line: 230
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 242,
            column: 7
          },
          end: {
            line: 242,
            column: 8
          }
        },
        loc: {
          start: {
            line: 242,
            column: 20
          },
          end: {
            line: 269,
            column: 5
          }
        },
        line: 242
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 253,
            column: 95
          },
          end: {
            line: 253,
            column: 96
          }
        },
        loc: {
          start: {
            line: 253,
            column: 103
          },
          end: {
            line: 268,
            column: 7
          }
        },
        line: 253
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 271,
            column: 18
          },
          end: {
            line: 271,
            column: 19
          }
        },
        loc: {
          start: {
            line: 271,
            column: 33
          },
          end: {
            line: 273,
            column: 3
          }
        },
        line: 271
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 18,
            column: 4
          },
          end: {
            line: 20,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 4
          },
          end: {
            line: 20,
            column: 5
          }
        }, {
          start: {
            line: 18,
            column: 4
          },
          end: {
            line: 20,
            column: 5
          }
        }],
        line: 18
      },
      '1': {
        loc: {
          start: {
            line: 22,
            column: 4
          },
          end: {
            line: 24,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 4
          },
          end: {
            line: 24,
            column: 5
          }
        }, {
          start: {
            line: 22,
            column: 4
          },
          end: {
            line: 24,
            column: 5
          }
        }],
        line: 22
      },
      '2': {
        loc: {
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 22,
            column: 83
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 22,
            column: 44
          }
        }, {
          start: {
            line: 22,
            column: 48
          },
          end: {
            line: 22,
            column: 83
          }
        }],
        line: 22
      },
      '3': {
        loc: {
          start: {
            line: 26,
            column: 4
          },
          end: {
            line: 37,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 26,
            column: 4
          },
          end: {
            line: 37,
            column: 5
          }
        }, {
          start: {
            line: 26,
            column: 4
          },
          end: {
            line: 37,
            column: 5
          }
        }],
        line: 26
      },
      '4': {
        loc: {
          start: {
            line: 29,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 29,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        }, {
          start: {
            line: 29,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        }],
        line: 29
      },
      '5': {
        loc: {
          start: {
            line: 31,
            column: 10
          },
          end: {
            line: 34,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 31,
            column: 10
          },
          end: {
            line: 34,
            column: 11
          }
        }, {
          start: {
            line: 31,
            column: 10
          },
          end: {
            line: 34,
            column: 11
          }
        }],
        line: 31
      },
      '6': {
        loc: {
          start: {
            line: 31,
            column: 14
          },
          end: {
            line: 31,
            column: 88
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 31,
            column: 14
          },
          end: {
            line: 31,
            column: 40
          }
        }, {
          start: {
            line: 31,
            column: 44
          },
          end: {
            line: 31,
            column: 88
          }
        }],
        line: 31
      },
      '7': {
        loc: {
          start: {
            line: 40,
            column: 20
          },
          end: {
            line: 40,
            column: 110
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 40,
            column: 33
          },
          end: {
            line: 40,
            column: 69
          }
        }, {
          start: {
            line: 40,
            column: 72
          },
          end: {
            line: 40,
            column: 110
          }
        }],
        line: 40
      },
      '8': {
        loc: {
          start: {
            line: 71,
            column: 2
          },
          end: {
            line: 73,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 71,
            column: 2
          },
          end: {
            line: 73,
            column: 3
          }
        }, {
          start: {
            line: 71,
            column: 2
          },
          end: {
            line: 73,
            column: 3
          }
        }],
        line: 71
      },
      '9': {
        loc: {
          start: {
            line: 103,
            column: 14
          },
          end: {
            line: 103,
            column: 61
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 103,
            column: 42
          },
          end: {
            line: 103,
            column: 49
          }
        }, {
          start: {
            line: 103,
            column: 52
          },
          end: {
            line: 103,
            column: 61
          }
        }],
        line: 103
      },
      '10': {
        loc: {
          start: {
            line: 121,
            column: 28
          },
          end: {
            line: 121,
            column: 141
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 121,
            column: 56
          },
          end: {
            line: 121,
            column: 136
          }
        }, {
          start: {
            line: 121,
            column: 139
          },
          end: {
            line: 121,
            column: 141
          }
        }],
        line: 121
      },
      '11': {
        loc: {
          start: {
            line: 122,
            column: 18
          },
          end: {
            line: 122,
            column: 134
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 122,
            column: 46
          },
          end: {
            line: 122,
            column: 129
          }
        }, {
          start: {
            line: 122,
            column: 132
          },
          end: {
            line: 122,
            column: 134
          }
        }],
        line: 122
      },
      '12': {
        loc: {
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 129,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 129,
            column: 7
          }
        }, {
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 129,
            column: 7
          }
        }],
        line: 126
      },
      '13': {
        loc: {
          start: {
            line: 130,
            column: 6
          },
          end: {
            line: 133,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 130,
            column: 6
          },
          end: {
            line: 133,
            column: 7
          }
        }, {
          start: {
            line: 130,
            column: 6
          },
          end: {
            line: 133,
            column: 7
          }
        }],
        line: 130
      },
      '14': {
        loc: {
          start: {
            line: 134,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 134,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        }, {
          start: {
            line: 134,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        }],
        line: 134
      },
      '15': {
        loc: {
          start: {
            line: 147,
            column: 8
          },
          end: {
            line: 149,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 147,
            column: 8
          },
          end: {
            line: 149,
            column: 9
          }
        }, {
          start: {
            line: 147,
            column: 8
          },
          end: {
            line: 149,
            column: 9
          }
        }],
        line: 147
      },
      '16': {
        loc: {
          start: {
            line: 172,
            column: 18
          },
          end: {
            line: 172,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 172,
            column: 18
          },
          end: {
            line: 172,
            column: 41
          }
        }, {
          start: {
            line: 172,
            column: 45
          },
          end: {
            line: 172,
            column: 52
          }
        }],
        line: 172
      },
      '17': {
        loc: {
          start: {
            line: 172,
            column: 19
          },
          end: {
            line: 172,
            column: 32
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 172,
            column: 19
          },
          end: {
            line: 172,
            column: 26
          }
        }, {
          start: {
            line: 172,
            column: 30
          },
          end: {
            line: 172,
            column: 32
          }
        }],
        line: 172
      },
      '18': {
        loc: {
          start: {
            line: 182,
            column: 4
          },
          end: {
            line: 208,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 182,
            column: 4
          },
          end: {
            line: 208,
            column: 5
          }
        }, {
          start: {
            line: 182,
            column: 4
          },
          end: {
            line: 208,
            column: 5
          }
        }],
        line: 182
      },
      '19': {
        loc: {
          start: {
            line: 184,
            column: 8
          },
          end: {
            line: 203,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 184,
            column: 8
          },
          end: {
            line: 203,
            column: 9
          }
        }, {
          start: {
            line: 184,
            column: 8
          },
          end: {
            line: 203,
            column: 9
          }
        }],
        line: 184
      },
      '20': {
        loc: {
          start: {
            line: 186,
            column: 10
          },
          end: {
            line: 202,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 186,
            column: 10
          },
          end: {
            line: 202,
            column: 11
          }
        }, {
          start: {
            line: 186,
            column: 10
          },
          end: {
            line: 202,
            column: 11
          }
        }],
        line: 186
      },
      '21': {
        loc: {
          start: {
            line: 243,
            column: 6
          },
          end: {
            line: 245,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 243,
            column: 6
          },
          end: {
            line: 245,
            column: 7
          }
        }, {
          start: {
            line: 243,
            column: 6
          },
          end: {
            line: 245,
            column: 7
          }
        }],
        line: 243
      },
      '22': {
        loc: {
          start: {
            line: 254,
            column: 8
          },
          end: {
            line: 261,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 254,
            column: 8
          },
          end: {
            line: 261,
            column: 9
          }
        }, {
          start: {
            line: 254,
            column: 8
          },
          end: {
            line: 261,
            column: 9
          }
        }],
        line: 254
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function SourceFinance_edit($scope, $uibModalInstance, $deltahttp, $filter, $CRUDService, $translate, FileUploader) {
  cov_1c8fg2jx5r.f[0]++;
  cov_1c8fg2jx5r.s[0]++;

  $scope.alternative_path = null;
  cov_1c8fg2jx5r.s[1]++;
  $scope.setValidData = function (item) {
    cov_1c8fg2jx5r.f[1]++;

    var validateItem = (cov_1c8fg2jx5r.s[2]++, {
      CODE_SOURCE: $filter('uppercase')(item.CODE_SOURCE),
      LIBELLE_FIN: item.LIBELLE_FIN,
      ID_PROJET: item.ID_PROJET,
      AMOUNT: item.AMOUNT,
      CURRENCY: item.CURRENCY,
      EXCHANGE: item.EXCHANGE,
      NAME_CONTACT: item.NAME_CONTACT,
      TEL_CONTACT: item.TEL_CONTACT,
      EMAIL_CONTACT: item.EMAIL_CONTACT,
      id: item.id
    });
    cov_1c8fg2jx5r.s[3]++;
    $scope.error_code = false;
    cov_1c8fg2jx5r.s[4]++;
    if (!validateItem.CODE_SOURCE) {
      cov_1c8fg2jx5r.b[0][0]++;
      cov_1c8fg2jx5r.s[5]++;

      return;
    } else {
      cov_1c8fg2jx5r.b[0][1]++;
    }

    cov_1c8fg2jx5r.s[6]++;
    if ((cov_1c8fg2jx5r.b[2][0]++, validateItem.CODE_SOURCE.length > 10) || (cov_1c8fg2jx5r.b[2][1]++, validateItem.CODE_SOURCE.length < 1)) {
      cov_1c8fg2jx5r.b[1][0]++;
      cov_1c8fg2jx5r.s[7]++;

      return;
    } else {
      cov_1c8fg2jx5r.b[1][1]++;
    }

    cov_1c8fg2jx5r.s[8]++;
    if ($scope.add) {
      cov_1c8fg2jx5r.b[3][0]++;
      cov_1c8fg2jx5r.s[9]++;

      for (var i = 0; i < $scope.MyDataListe.length; i++) {
        var _item = (cov_1c8fg2jx5r.s[10]++, $scope.MyDataListe[i]);
        cov_1c8fg2jx5r.s[11]++;
        if ($scope.MyDataListe[i].id == _item.id) {
          cov_1c8fg2jx5r.b[4][0]++;
          cov_1c8fg2jx5r.s[12]++;

          // Verification doublons
          if ((cov_1c8fg2jx5r.b[6][0]++, _item.id != validateItem.id) && (cov_1c8fg2jx5r.b[6][1]++, validateItem.CODE_SOURCE == _item.CODE_SOURCE)) {
            cov_1c8fg2jx5r.b[5][0]++;
            cov_1c8fg2jx5r.s[13]++;

            $scope.error_code = true;
            cov_1c8fg2jx5r.s[14]++;
            return;
          } else {
            cov_1c8fg2jx5r.b[5][1]++;
          }
        } else {
          cov_1c8fg2jx5r.b[4][1]++;
        }
      }
    } else {
      cov_1c8fg2jx5r.b[3][1]++;
    }
    cov_1c8fg2jx5r.s[15]++;
    $scope.isloading = true;
    cov_1c8fg2jx5r.s[16]++;
    $CRUDService.save($scope.PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function (data) {
      cov_1c8fg2jx5r.f[2]++;
      cov_1c8fg2jx5r.s[17]++;

      $scope.notify($scope.add ? (cov_1c8fg2jx5r.b[7][0]++, $translate.instant('COMMON.MSG_ADD')) : (cov_1c8fg2jx5r.b[7][1]++, $translate.instant('COMMON.MSG_MODIF')));
      cov_1c8fg2jx5r.s[18]++;
      $scope.setCancel();
      cov_1c8fg2jx5r.s[19]++;
      $scope.getAll();
      cov_1c8fg2jx5r.s[20]++;
      $scope.closeModal();
    });
  };
  cov_1c8fg2jx5r.s[21]++;
  $scope.closeModal = function () {
    cov_1c8fg2jx5r.f[3]++;
    cov_1c8fg2jx5r.s[22]++;

    $uibModalInstance.dismiss('cancel');
  };

  cov_1c8fg2jx5r.s[23]++;
  $CRUDService.save($scope.PATH, { action: 'delfile' });
  var uploader = (cov_1c8fg2jx5r.s[24]++, $scope.uploader = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadfile&method=1&' + $CRUDService.getComplement() + '&li_bksb=Financement',
    alias: 'upload_file',
    removeAfterUpload: true
  }));
  cov_1c8fg2jx5r.s[25]++;
  uploader.onAfterAddingFile = function (fileItem) {
    cov_1c8fg2jx5r.f[4]++;
    cov_1c8fg2jx5r.s[26]++;

    fileItem.url = uploader.url;
    cov_1c8fg2jx5r.s[27]++;
    fileItem.upload();
  };
  cov_1c8fg2jx5r.s[28]++;
  uploader.onSuccessItem = function (fileItem, response, status, headers) {
    cov_1c8fg2jx5r.f[5]++;
    cov_1c8fg2jx5r.s[29]++;

    $scope.alternative_path = $deltahttp.getFinancePicture('_' + $CRUDService.getUser());
  };
  cov_1c8fg2jx5r.s[30]++;
  uploader.onErrorItem = function (fileItem, response, status, headers) {
    cov_1c8fg2jx5r.f[6]++;
    cov_1c8fg2jx5r.s[31]++;

    $scope.alternative_path = null;
  };
}

cov_1c8fg2jx5r.s[32]++;
angular.module('app').controller('SourceFinCtrl', function ($scope, SweetAlert, $deltadate, $deltahttp, $translate, $CRUDService, $otherHttpService, $location, $filter, $rootScope, $uibModal) {
  cov_1c8fg2jx5r.f[7]++;
  cov_1c8fg2jx5r.s[33]++;

  $rootScope.processPageRight('1_8');
  cov_1c8fg2jx5r.s[34]++;
  if ($rootScope.global_access_page_denied) {
    cov_1c8fg2jx5r.b[8][0]++;
    cov_1c8fg2jx5r.s[35]++;

    return;
  } else {
    cov_1c8fg2jx5r.b[8][1]++;
  }
  cov_1c8fg2jx5r.s[36]++;
  $scope.add = false;
  cov_1c8fg2jx5r.s[37]++;
  $scope.edit = false;
  cov_1c8fg2jx5r.s[38]++;
  $scope.btnAction = false;
  cov_1c8fg2jx5r.s[39]++;
  $scope.MyDataListe = [];
  cov_1c8fg2jx5r.s[40]++;
  $scope.PATH = 'Financement';
  cov_1c8fg2jx5r.s[41]++;
  $scope.lastUpdate = angular.toJson(new Date());
  var project = (cov_1c8fg2jx5r.s[42]++, $rootScope.getCurrentProject());
  var notify = (cov_1c8fg2jx5r.s[43]++, '');
  cov_1c8fg2jx5r.s[44]++;
  $scope.__labels = {
    EDIT: $translate.instant('COMMON.EDIT'),
    DELETE: $translate.instant('COMMON.DELETE')
  };

  cov_1c8fg2jx5r.s[45]++;
  $scope.printData = function () {
    cov_1c8fg2jx5r.f[8]++;
    cov_1c8fg2jx5r.s[46]++;

    $rootScope.printReport({
      link: $scope.PATH,
      params: { get: 'print' },
      title: $translate.instant('MENU_SHORT.S_F')
    });
  };

  cov_1c8fg2jx5r.s[47]++;
  $scope.templateBtnAdd = '<a id="addsourcefin" class="btn btn-primary btn-md btn-outline newBtn_formular" tooltip-placement="bottom" uib-tooltip="{{actionLabel.ADD}}"  ng-click="setAdd()"><i class="fa fa-plus"></i>' + $translate.instant('COMMON.ADD') + '  </a>';

  cov_1c8fg2jx5r.s[48]++;
  $scope.templateBtnPrint = '<button class="btn btn-primary btn-md btn-outline newBtn_formular" ng-click="printData()"><i class="fa fa-print"></i> </button>';

  cov_1c8fg2jx5r.s[49]++;
  $scope.templateBtn = '<div onload="setbtnadd()" class="display-flex" style="padding: 0 21px"  ng-if="LIST_RIGHTS_GLOBAL[LIST_RIGHTS_GLOBAL_KEY.FINANCING_SOURCE].update"><div style="flex: 1"></div><div class="m-r-xs">' + $scope.templateBtnAdd + '</div> </div>';

  cov_1c8fg2jx5r.s[50]++;
  $scope.params = {

    toolbar: $rootScope.currentRight.u ? (cov_1c8fg2jx5r.b[9][0]++, ['Add']) : (cov_1c8fg2jx5r.b[9][1]++, undefined),
    editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },
    columns: [{ width: 62, type: 'string',
      // eslint-disable-next-line no-template-curly-in-string
      template: '<div class="p-xs">${if(file)}<img class="img img-sm img-circle" src="${img}" /> ${else} <span> </span>${/if}</div>' }, { field: 'CODE_SOURCE', width: 120, headerText: $translate.instant('DEVISE.CODE'), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'LIBELLE_FIN', headerText: $translate.instant('FN_SFIN.NAME'), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'NAME_CONTACT', headerText: $translate.instant('FN_SFIN.NAME_CONTACT_LONG'), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'TEL_CONTACT', headerText: $translate.instant('FN_SFIN.TEL_CONTACT_LONG'), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'EMAIL_CONTACT', headerText: $translate.instant('FN_SFIN.EMAIL_CONTACT_LONG'), type: 'string', clipMode: 'EllipsisWithTooltip' },
    // headerTemplate: '#templateBtn' ,
    // headerTemplate: $scope.templateBtn,

    {
      headerText: '',
      width: 110,
      commands: [].concat($rootScope.currentRight.u ? (cov_1c8fg2jx5r.b[10][0]++, [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } }]) : (cov_1c8fg2jx5r.b[10][1]++, [])).concat($rootScope.currentRight.d ? (cov_1c8fg2jx5r.b[11][0]++, [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }]) : (cov_1c8fg2jx5r.b[11][1]++, []))
    }],
    actionBegin: function actionBegin(action) {
      cov_1c8fg2jx5r.s[51]++;

      if (action.requestType === 'add') {
        cov_1c8fg2jx5r.b[12][0]++;
        cov_1c8fg2jx5r.s[52]++;

        action.cancel = true;
        cov_1c8fg2jx5r.s[53]++;
        $scope.add();
      } else {
        cov_1c8fg2jx5r.b[12][1]++;
      }
      cov_1c8fg2jx5r.s[54]++;
      if (action.requestType === 'beginEdit') {
        cov_1c8fg2jx5r.b[13][0]++;
        cov_1c8fg2jx5r.s[55]++;

        action.cancel = true;
        cov_1c8fg2jx5r.s[56]++;
        $scope.setEdit(action.rowData.id);
      } else {
        cov_1c8fg2jx5r.b[13][1]++;
      }
      cov_1c8fg2jx5r.s[57]++;
      if (action.requestType === 'delete') {
        cov_1c8fg2jx5r.b[14][0]++;
        cov_1c8fg2jx5r.s[58]++;

        action.cancel = true;
        cov_1c8fg2jx5r.s[59]++;
        $scope.setDeleteData(action.data[0]);
      } else {
        cov_1c8fg2jx5r.b[14][1]++;
      }
    }
  };
  cov_1c8fg2jx5r.s[60]++;
  $scope.getAll = function () {
    cov_1c8fg2jx5r.f[9]++;
    cov_1c8fg2jx5r.s[61]++;

    $scope.lastUpdate = angular.toJson(new Date());
    cov_1c8fg2jx5r.s[62]++;
    $scope.MyDataListe = [];
    cov_1c8fg2jx5r.s[63]++;
    $scope.isloading = true;
    cov_1c8fg2jx5r.s[64]++;
    $CRUDService.getAll($scope.PATH, { get: 'all' }, function (data) {
      cov_1c8fg2jx5r.f[10]++;
      cov_1c8fg2jx5r.s[65]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var el = _step.value;
          cov_1c8fg2jx5r.s[66]++;

          el.file = true;
          cov_1c8fg2jx5r.s[67]++;
          if (el.file) {
            cov_1c8fg2jx5r.b[15][0]++;
            cov_1c8fg2jx5r.s[68]++;

            el.img = $deltahttp.getFinancePicture(el.id, $scope.lastUpdate);
          } else {
            cov_1c8fg2jx5r.b[15][1]++;
          }
          cov_1c8fg2jx5r.s[69]++;
          el.AMOUNT = parseFloat(el.AMOUNT);
          cov_1c8fg2jx5r.s[70]++;
          el.EXCHANGE = parseFloat(el.EXCHANGE);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_1c8fg2jx5r.s[71]++;
      $scope.MyDataListe = data;
      cov_1c8fg2jx5r.s[72]++;
      $scope.isloading = false;
    });
  };
  cov_1c8fg2jx5r.s[73]++;
  $scope.getAll();

  cov_1c8fg2jx5r.s[74]++;
  $scope.setAdd = function () {
    cov_1c8fg2jx5r.f[11]++;
    cov_1c8fg2jx5r.s[75]++;

    $scope.add = true;
    cov_1c8fg2jx5r.s[76]++;
    $scope.edit = false;
    cov_1c8fg2jx5r.s[77]++;
    $scope.setEdit();
  };

  cov_1c8fg2jx5r.s[78]++;
  $scope.setEdit = function (id) {
    cov_1c8fg2jx5r.f[12]++;
    cov_1c8fg2jx5r.s[79]++;

    $scope.btnAction = false;
    cov_1c8fg2jx5r.s[80]++;
    $scope.add = false;
    cov_1c8fg2jx5r.s[81]++;
    $scope.edit = false;
    var validateItem = (cov_1c8fg2jx5r.s[82]++, {
      CODE_SOURCE: '',
      LIBELLE_FIN: '',
      ID_PROJET: ((cov_1c8fg2jx5r.b[16][0]++, ((cov_1c8fg2jx5r.b[17][0]++, project) || (cov_1c8fg2jx5r.b[17][1]++, {})).project) || (cov_1c8fg2jx5r.b[16][1]++, { id: 0 })).id,
      imageUrl: $deltahttp.getFinancePicture(id),
      id: 0,
      AMOUNT: 0,
      CURRENCY: '',
      EXCHANGE: 0,
      NAME_CONTACT: '',
      TEL_CONTACT: '',
      EMAIL_CONTACT: ''
    });
    cov_1c8fg2jx5r.s[83]++;
    if (id) {
      cov_1c8fg2jx5r.b[18][0]++;
      cov_1c8fg2jx5r.s[84]++;

      for (var i = 0; i < $scope.MyDataListe.length; i++) {
        cov_1c8fg2jx5r.s[85]++;

        if ($scope.MyDataListe[i].id == id) {
          cov_1c8fg2jx5r.b[19][0]++;

          var item = (cov_1c8fg2jx5r.s[86]++, $scope.MyDataListe[i]);
          cov_1c8fg2jx5r.s[87]++;
          if (item.id == id) {
            cov_1c8fg2jx5r.b[20][0]++;
            cov_1c8fg2jx5r.s[88]++;

            $scope.edit = true;
            cov_1c8fg2jx5r.s[89]++;
            $scope.validateItem = {
              imageUrl: $deltahttp.getFinancePicture(id),
              CODE_SOURCE: item.CODE_SOURCE,
              LIBELLE_FIN: item.LIBELLE_FIN,
              ID_PROJET: item.ID_PROJET,
              id: item.id,
              AMOUNT: item.AMOUNT,
              CURRENCY: item.CURRENCY,
              EXCHANGE: item.EXCHANGE,
              NAME_CONTACT: item.NAME_CONTACT,
              TEL_CONTACT: item.TEL_CONTACT,
              EMAIL_CONTACT: item.EMAIL_CONTACT
            };
            cov_1c8fg2jx5r.s[90]++;
            break;
          } else {
            cov_1c8fg2jx5r.b[20][1]++;
          }
        } else {
          cov_1c8fg2jx5r.b[19][1]++;
        }
      }
    } else {
      cov_1c8fg2jx5r.b[18][1]++;
      cov_1c8fg2jx5r.s[91]++;

      $scope.add = true;
      cov_1c8fg2jx5r.s[92]++;
      $scope.validateItem = validateItem;
    }
    cov_1c8fg2jx5r.s[93]++;
    $uibModal.open({
      templateUrl: 'app/views/SourceFin/edit.html',
      controller: SourceFinance_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_1c8fg2jx5r.s[94]++;
  $scope.setCancel = function () {
    cov_1c8fg2jx5r.f[13]++;
    cov_1c8fg2jx5r.s[95]++;

    $scope.add = false;
    cov_1c8fg2jx5r.s[96]++;
    $scope.edit = false;
    cov_1c8fg2jx5r.s[97]++;
    $scope.btnAction = false;
  };

  cov_1c8fg2jx5r.s[98]++;
  $scope.selectUnit = function (SelectedData) {
    cov_1c8fg2jx5r.f[14]++;
    cov_1c8fg2jx5r.s[99]++;

    $scope.btnAction = true;
    cov_1c8fg2jx5r.s[100]++;
    $scope.item = SelectedData;
  };

  cov_1c8fg2jx5r.s[101]++;
  $scope.setDeleteData = function (item) {
    cov_1c8fg2jx5r.f[15]++;
    cov_1c8fg2jx5r.s[102]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_SFIN.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1c8fg2jx5r.f[16]++;
      cov_1c8fg2jx5r.s[103]++;

      if (isconfirm) {
        cov_1c8fg2jx5r.b[21][0]++;
        cov_1c8fg2jx5r.s[104]++;

        return;
      } else {
        cov_1c8fg2jx5r.b[21][1]++;
      }
      cov_1c8fg2jx5r.s[105]++;
      $scope.isloading = true;
      var validateItem = (cov_1c8fg2jx5r.s[106]++, {
        CODE_SOURCE: item.CODE_SOURCE,
        LIBELLE_FIN: item.LIBELLE_FIN,
        ID_PROJET: item.ID_PROJET,
        id: item.id
      });
      cov_1c8fg2jx5r.s[107]++;
      $CRUDService.delet($scope.PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_1c8fg2jx5r.f[17]++;
        cov_1c8fg2jx5r.s[108]++;

        if (data < 0) {
          cov_1c8fg2jx5r.b[22][0]++;
          cov_1c8fg2jx5r.s[109]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_1c8fg2jx5r.s[110]++;
          $scope.isloading = false;
          cov_1c8fg2jx5r.s[111]++;
          return;
        } else {
          cov_1c8fg2jx5r.b[22][1]++;
        }
        /* notify({
        message:$translate.instant("COMMON.MSG_DEL"),
        classes:'alert-info'
        }); */
        cov_1c8fg2jx5r.s[112]++;
        $scope.setCancel();
        cov_1c8fg2jx5r.s[113]++;
        $scope.getAll();
      });
    });
  };
  cov_1c8fg2jx5r.s[114]++;
  $scope.notify = function (MSG) {
    cov_1c8fg2jx5r.f[18]++;
  };
});