'use strict';

var cov_208pt2srjw = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/contract/contractCtrl.js',
      hash = 'aeab76b8b632acc1cee8277862b8c7f0d95b1b46',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/contract/contractCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 82,
          column: 3
        }
      },
      '1': {
        start: {
          line: 5,
          column: 16
        },
        end: {
          line: 5,
          column: 25
        }
      },
      '2': {
        start: {
          line: 6,
          column: 15
        },
        end: {
          line: 6,
          column: 30
        }
      },
      '3': {
        start: {
          line: 7,
          column: 17
        },
        end: {
          line: 7,
          column: 32
        }
      },
      '4': {
        start: {
          line: 8,
          column: 19
        },
        end: {
          line: 8,
          column: 35
        }
      },
      '5': {
        start: {
          line: 9,
          column: 15
        },
        end: {
          line: 9,
          column: 26
        }
      },
      '6': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 25
        }
      },
      '7': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 42
        }
      },
      '8': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 36
        }
      },
      '9': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 19,
          column: 4
        }
      },
      '10': {
        start: {
          line: 20,
          column: 2
        },
        end: {
          line: 44,
          column: 4
        }
      },
      '11': {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 38,
          column: 7
        }
      },
      '12': {
        start: {
          line: 31,
          column: 10
        },
        end: {
          line: 31,
          column: 69
        }
      },
      '13': {
        start: {
          line: 33,
          column: 10
        },
        end: {
          line: 33,
          column: 69
        }
      },
      '14': {
        start: {
          line: 35,
          column: 10
        },
        end: {
          line: 35,
          column: 69
        }
      },
      '15': {
        start: {
          line: 37,
          column: 10
        },
        end: {
          line: 37,
          column: 22
        }
      },
      '16': {
        start: {
          line: 45,
          column: 2
        },
        end: {
          line: 55,
          column: 4
        }
      },
      '17': {
        start: {
          line: 46,
          column: 4
        },
        end: {
          line: 48,
          column: 5
        }
      },
      '18': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 47,
          column: 13
        }
      },
      '19': {
        start: {
          line: 49,
          column: 4
        },
        end: {
          line: 51,
          column: 5
        }
      },
      '20': {
        start: {
          line: 50,
          column: 6
        },
        end: {
          line: 50,
          column: 13
        }
      },
      '21': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 52,
          column: 31
        }
      },
      '22': {
        start: {
          line: 53,
          column: 4
        },
        end: {
          line: 53,
          column: 34
        }
      },
      '23': {
        start: {
          line: 54,
          column: 4
        },
        end: {
          line: 54,
          column: 20
        }
      },
      '24': {
        start: {
          line: 56,
          column: 2
        },
        end: {
          line: 81,
          column: 4
        }
      },
      '25': {
        start: {
          line: 57,
          column: 4
        },
        end: {
          line: 57,
          column: 28
        }
      },
      '26': {
        start: {
          line: 58,
          column: 4
        },
        end: {
          line: 58,
          column: 25
        }
      },
      '27': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 59,
          column: 27
        }
      },
      '28': {
        start: {
          line: 61,
          column: 19
        },
        end: {
          line: 66,
          column: 5
        }
      },
      '29': {
        start: {
          line: 67,
          column: 4
        },
        end: {
          line: 72,
          column: 7
        }
      },
      '30': {
        start: {
          line: 68,
          column: 6
        },
        end: {
          line: 68,
          column: 31
        }
      },
      '31': {
        start: {
          line: 70,
          column: 6
        },
        end: {
          line: 70,
          column: 55
        }
      },
      '32': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 71,
          column: 84
        }
      },
      '33': {
        start: {
          line: 73,
          column: 18
        },
        end: {
          line: 76,
          column: 5
        }
      },
      '34': {
        start: {
          line: 77,
          column: 4
        },
        end: {
          line: 80,
          column: 7
        }
      },
      '35': {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 78,
          column: 31
        }
      },
      '36': {
        start: {
          line: 79,
          column: 6
        },
        end: {
          line: 79,
          column: 29
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 45
          },
          end: {
            line: 4,
            column: 46
          }
        },
        loc: {
          start: {
            line: 4,
            column: 151
          },
          end: {
            line: 82,
            column: 1
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 45,
            column: 26
          },
          end: {
            line: 45,
            column: 27
          }
        },
        loc: {
          start: {
            line: 45,
            column: 42
          },
          end: {
            line: 55,
            column: 3
          }
        },
        line: 45
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 56,
            column: 20
          },
          end: {
            line: 56,
            column: 21
          }
        },
        loc: {
          start: {
            line: 56,
            column: 32
          },
          end: {
            line: 81,
            column: 3
          }
        },
        line: 56
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 67,
            column: 39
          },
          end: {
            line: 67,
            column: 40
          }
        },
        loc: {
          start: {
            line: 67,
            column: 47
          },
          end: {
            line: 72,
            column: 5
          }
        },
        line: 67
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 77,
            column: 37
          },
          end: {
            line: 77,
            column: 38
          }
        },
        loc: {
          start: {
            line: 77,
            column: 45
          },
          end: {
            line: 80,
            column: 5
          }
        },
        line: 77
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 29,
            column: 6
          },
          end: {
            line: 38,
            column: 7
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 30,
            column: 8
          },
          end: {
            line: 31,
            column: 69
          }
        }, {
          start: {
            line: 32,
            column: 8
          },
          end: {
            line: 33,
            column: 69
          }
        }, {
          start: {
            line: 34,
            column: 8
          },
          end: {
            line: 35,
            column: 69
          }
        }, {
          start: {
            line: 36,
            column: 8
          },
          end: {
            line: 37,
            column: 22
          }
        }],
        line: 29
      },
      '1': {
        loc: {
          start: {
            line: 46,
            column: 4
          },
          end: {
            line: 48,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 46,
            column: 4
          },
          end: {
            line: 48,
            column: 5
          }
        }, {
          start: {
            line: 46,
            column: 4
          },
          end: {
            line: 48,
            column: 5
          }
        }],
        line: 46
      },
      '2': {
        loc: {
          start: {
            line: 49,
            column: 4
          },
          end: {
            line: 51,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 49,
            column: 4
          },
          end: {
            line: 51,
            column: 5
          }
        }, {
          start: {
            line: 49,
            column: 4
          },
          end: {
            line: 51,
            column: 5
          }
        }],
        line: 49
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0, 0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_208pt2srjw.s[0]++;
/**
 * Created by BKSB on 28/02/2017.
 */
angular.module('app').controller('contract', function ($scope, $deltaActor, $rootScope, $translate, $deltaPlanLogique, $deltahttp, $CRUDService, $deltadate) {
  cov_208pt2srjw.f[0]++;

  var PATHM = (cov_208pt2srjw.s[1]++, 'Marches');
  var PATH = (cov_208pt2srjw.s[2]++, 'Planification');
  var EXPAND = (cov_208pt2srjw.s[3]++, 'fa-angle-down');
  var COLLAPSE = (cov_208pt2srjw.s[4]++, 'fa-angle-right');
  var LEAF = (cov_208pt2srjw.s[5]++, 'fa-circle');

  cov_208pt2srjw.s[6]++;
  $scope.listMarche = [];
  cov_208pt2srjw.s[7]++;
  $deltaPlanLogique.addController($scope);
  cov_208pt2srjw.s[8]++;
  $scope.choise = { displayCl: true };
  cov_208pt2srjw.s[9]++;
  $scope.listOperator = [{ label: $translate.instant('COMMON.LESS_THAN_E'), id: 1 }, { label: $translate.instant('COMMON.GREAT_THAN_E'), id: 2 }, { label: $translate.instant('COMMON.EGAL_TO_E'), id: 3 }, { label: $translate.instant('COMMON.ALL_E'), id: 0 }];
  cov_208pt2srjw.s[10]++;
  $scope.filter = {
    cols: {
      displayM: true,
      displayT: true,
      displayO: true,
      displayMo: true
    },
    color: '52 , 255 , 44 ',
    fn: function fn(item) {
      cov_208pt2srjw.s[11]++;

      switch ($scope.filter.marche.op.id) {
        case 1:
          cov_208pt2srjw.b[0][0]++;
          cov_208pt2srjw.s[12]++;

          return $scope.filter.marche.item.ETAPES_MARCHE >= item.s;
        case 2:
          cov_208pt2srjw.b[0][1]++;
          cov_208pt2srjw.s[13]++;

          return $scope.filter.marche.item.ETAPES_MARCHE <= item.s;
        case 3:
          cov_208pt2srjw.b[0][2]++;
          cov_208pt2srjw.s[14]++;

          return $scope.filter.marche.item.ETAPES_MARCHE == item.s;
        default:
          cov_208pt2srjw.b[0][3]++;
          cov_208pt2srjw.s[15]++;

          return true;
      }
    },

    marche: {
      item: null,
      op: $scope.listOperator[$scope.listOperator.length - 1]
    }
  };
  cov_208pt2srjw.s[16]++;
  $scope.selectLogFrame = function (item) {
    cov_208pt2srjw.f[1]++;
    cov_208pt2srjw.s[17]++;

    if (!item) {
      cov_208pt2srjw.b[1][0]++;
      cov_208pt2srjw.s[18]++;

      return;
    } else {
      cov_208pt2srjw.b[1][1]++;
    }
    cov_208pt2srjw.s[19]++;
    if (!item.leaf) {
      cov_208pt2srjw.b[2][0]++;
      cov_208pt2srjw.s[20]++;

      return;
    } else {
      cov_208pt2srjw.b[2][1]++;
    }
    cov_208pt2srjw.s[21]++;
    this.choise.planlog = item;
    cov_208pt2srjw.s[22]++;
    this.choise.displayCl = false;
    cov_208pt2srjw.s[23]++;
    this.validate();
  };
  cov_208pt2srjw.s[24]++;
  $scope.validate = function () {
    cov_208pt2srjw.f[2]++;
    cov_208pt2srjw.s[25]++;

    $scope.isloading = true;
    cov_208pt2srjw.s[26]++;
    $scope.listData = [];
    cov_208pt2srjw.s[27]++;
    $scope.listMarche = [];

    var paramp = (cov_208pt2srjw.s[28]++, {
      get: 'allbyplan',
      idplan: -1,
      idcl: this.choise.planlog.id,
      by: 'activ'
    });
    cov_208pt2srjw.s[29]++;
    $CRUDService.getAll(PATHM, paramp, function (data) {
      cov_208pt2srjw.f[3]++;
      cov_208pt2srjw.s[30]++;

      $scope.listMarche = data;

      cov_208pt2srjw.s[31]++;
      $scope.filter.marche.item = $scope.listMarche[0];
      cov_208pt2srjw.s[32]++;
      $scope.filter.marche.op = $scope.listOperator[$scope.listOperator.length - 1];
    });
    var param = (cov_208pt2srjw.s[33]++, {
      get: 'suivicontract',
      idcl: this.choise.planlog.id
    });
    cov_208pt2srjw.s[34]++;
    $CRUDService.getAll(PATH, param, function (data) {
      cov_208pt2srjw.f[4]++;
      cov_208pt2srjw.s[35]++;

      $scope.isloading = false;
      cov_208pt2srjw.s[36]++;
      $scope.listData = data;
    });
  };
});