'use strict';

var cov_2ff0bzynse = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/geographic_map/geographic_mapCtrl.js',
      hash = '30518745c7b7631a14ea5a184d648fbf34dc1a02',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/geographic_map/geographic_mapCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 500,
          column: 3
        }
      },
      '1': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 37
        }
      },
      '2': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 8,
          column: 3
        }
      },
      '3': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 7,
          column: 11
        }
      },
      '4': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 14,
          column: 4
        }
      },
      '5': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 39
        }
      },
      '6': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 16,
          column: 26
        }
      },
      '7': {
        start: {
          line: 17,
          column: 17
        },
        end: {
          line: 17,
          column: 32
        }
      },
      '8': {
        start: {
          line: 18,
          column: 19
        },
        end: {
          line: 18,
          column: 35
        }
      },
      '9': {
        start: {
          line: 19,
          column: 15
        },
        end: {
          line: 19,
          column: 26
        }
      },
      '10': {
        start: {
          line: 20,
          column: 18
        },
        end: {
          line: 20,
          column: 30
        }
      },
      '11': {
        start: {
          line: 21,
          column: 2
        },
        end: {
          line: 21,
          column: 30
        }
      },
      '12': {
        start: {
          line: 22,
          column: 2
        },
        end: {
          line: 22,
          column: 28
        }
      },
      '13': {
        start: {
          line: 25,
          column: 2
        },
        end: {
          line: 25,
          column: 35
        }
      },
      '14': {
        start: {
          line: 26,
          column: 2
        },
        end: {
          line: 26,
          column: 30
        }
      },
      '15': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 27,
          column: 30
        }
      },
      '16': {
        start: {
          line: 28,
          column: 2
        },
        end: {
          line: 28,
          column: 34
        }
      },
      '17': {
        start: {
          line: 29,
          column: 2
        },
        end: {
          line: 29,
          column: 38
        }
      },
      '18': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 30,
          column: 26
        }
      },
      '19': {
        start: {
          line: 31,
          column: 2
        },
        end: {
          line: 31,
          column: 27
        }
      },
      '20': {
        start: {
          line: 32,
          column: 2
        },
        end: {
          line: 32,
          column: 28
        }
      },
      '21': {
        start: {
          line: 33,
          column: 2
        },
        end: {
          line: 33,
          column: 32
        }
      },
      '22': {
        start: {
          line: 34,
          column: 2
        },
        end: {
          line: 40,
          column: 5
        }
      },
      '23': {
        start: {
          line: 35,
          column: 4
        },
        end: {
          line: 39,
          column: 7
        }
      },
      '24': {
        start: {
          line: 43,
          column: 2
        },
        end: {
          line: 43,
          column: 29
        }
      },
      '25': {
        start: {
          line: 44,
          column: 2
        },
        end: {
          line: 58,
          column: 4
        }
      },
      '26': {
        start: {
          line: 59,
          column: 2
        },
        end: {
          line: 59,
          column: 37
        }
      },
      '27': {
        start: {
          line: 60,
          column: 2
        },
        end: {
          line: 64,
          column: 4
        }
      },
      '28': {
        start: {
          line: 61,
          column: 4
        },
        end: {
          line: 61,
          column: 36
        }
      },
      '29': {
        start: {
          line: 62,
          column: 4
        },
        end: {
          line: 62,
          column: 40
        }
      },
      '30': {
        start: {
          line: 63,
          column: 4
        },
        end: {
          line: 63,
          column: 39
        }
      },
      '31': {
        start: {
          line: 65,
          column: 2
        },
        end: {
          line: 81,
          column: 4
        }
      },
      '32': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 69,
          column: 5
        }
      },
      '33': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 68,
          column: 13
        }
      },
      '34': {
        start: {
          line: 70,
          column: 4
        },
        end: {
          line: 80,
          column: 7
        }
      },
      '35': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 74,
          column: 7
        }
      },
      '36': {
        start: {
          line: 72,
          column: 8
        },
        end: {
          line: 72,
          column: 34
        }
      },
      '37': {
        start: {
          line: 73,
          column: 8
        },
        end: {
          line: 73,
          column: 72
        }
      },
      '38': {
        start: {
          line: 75,
          column: 6
        },
        end: {
          line: 75,
          column: 35
        }
      },
      '39': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 79,
          column: 7
        }
      },
      '40': {
        start: {
          line: 78,
          column: 8
        },
        end: {
          line: 78,
          column: 44
        }
      },
      '41': {
        start: {
          line: 83,
          column: 2
        },
        end: {
          line: 92,
          column: 4
        }
      },
      '42': {
        start: {
          line: 84,
          column: 4
        },
        end: {
          line: 91,
          column: 7
        }
      },
      '43': {
        start: {
          line: 94,
          column: 2
        },
        end: {
          line: 96,
          column: 5
        }
      },
      '44': {
        start: {
          line: 95,
          column: 4
        },
        end: {
          line: 95,
          column: 34
        }
      },
      '45': {
        start: {
          line: 98,
          column: 2
        },
        end: {
          line: 104,
          column: 4
        }
      },
      '46': {
        start: {
          line: 105,
          column: 2
        },
        end: {
          line: 116,
          column: 5
        }
      },
      '47': {
        start: {
          line: 106,
          column: 4
        },
        end: {
          line: 106,
          column: 30
        }
      },
      '48': {
        start: {
          line: 107,
          column: 4
        },
        end: {
          line: 115,
          column: 7
        }
      },
      '49': {
        start: {
          line: 108,
          column: 23
        },
        end: {
          line: 108,
          column: 37
        }
      },
      '50': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 113,
          column: 7
        }
      },
      '51': {
        start: {
          line: 110,
          column: 8
        },
        end: {
          line: 110,
          column: 35
        }
      },
      '52': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 35
        }
      },
      '53': {
        start: {
          line: 112,
          column: 8
        },
        end: {
          line: 112,
          column: 15
        }
      },
      '54': {
        start: {
          line: 114,
          column: 6
        },
        end: {
          line: 114,
          column: 46
        }
      },
      '55': {
        start: {
          line: 118,
          column: 2
        },
        end: {
          line: 129,
          column: 5
        }
      },
      '56': {
        start: {
          line: 119,
          column: 4
        },
        end: {
          line: 119,
          column: 30
        }
      },
      '57': {
        start: {
          line: 120,
          column: 4
        },
        end: {
          line: 128,
          column: 7
        }
      },
      '58': {
        start: {
          line: 121,
          column: 23
        },
        end: {
          line: 121,
          column: 37
        }
      },
      '59': {
        start: {
          line: 122,
          column: 6
        },
        end: {
          line: 126,
          column: 7
        }
      },
      '60': {
        start: {
          line: 123,
          column: 8
        },
        end: {
          line: 123,
          column: 35
        }
      },
      '61': {
        start: {
          line: 124,
          column: 8
        },
        end: {
          line: 124,
          column: 35
        }
      },
      '62': {
        start: {
          line: 125,
          column: 8
        },
        end: {
          line: 125,
          column: 15
        }
      },
      '63': {
        start: {
          line: 127,
          column: 6
        },
        end: {
          line: 127,
          column: 43
        }
      },
      '64': {
        start: {
          line: 130,
          column: 2
        },
        end: {
          line: 139,
          column: 4
        }
      },
      '65': {
        start: {
          line: 131,
          column: 4
        },
        end: {
          line: 138,
          column: 7
        }
      },
      '66': {
        start: {
          line: 140,
          column: 2
        },
        end: {
          line: 149,
          column: 4
        }
      },
      '67': {
        start: {
          line: 141,
          column: 4
        },
        end: {
          line: 148,
          column: 7
        }
      },
      '68': {
        start: {
          line: 150,
          column: 2
        },
        end: {
          line: 159,
          column: 4
        }
      },
      '69': {
        start: {
          line: 151,
          column: 4
        },
        end: {
          line: 158,
          column: 6
        }
      },
      '70': {
        start: {
          line: 161,
          column: 2
        },
        end: {
          line: 161,
          column: 27
        }
      },
      '71': {
        start: {
          line: 162,
          column: 2
        },
        end: {
          line: 162,
          column: 29
        }
      },
      '72': {
        start: {
          line: 163,
          column: 2
        },
        end: {
          line: 163,
          column: 24
        }
      },
      '73': {
        start: {
          line: 164,
          column: 2
        },
        end: {
          line: 164,
          column: 28
        }
      },
      '74': {
        start: {
          line: 165,
          column: 2
        },
        end: {
          line: 165,
          column: 24
        }
      },
      '75': {
        start: {
          line: 166,
          column: 2
        },
        end: {
          line: 166,
          column: 23
        }
      },
      '76': {
        start: {
          line: 167,
          column: 2
        },
        end: {
          line: 167,
          column: 31
        }
      },
      '77': {
        start: {
          line: 170,
          column: 2
        },
        end: {
          line: 176,
          column: 4
        }
      },
      '78': {
        start: {
          line: 171,
          column: 4
        },
        end: {
          line: 171,
          column: 30
        }
      },
      '79': {
        start: {
          line: 172,
          column: 4
        },
        end: {
          line: 175,
          column: 7
        }
      },
      '80': {
        start: {
          line: 173,
          column: 6
        },
        end: {
          line: 173,
          column: 33
        }
      },
      '81': {
        start: {
          line: 174,
          column: 6
        },
        end: {
          line: 174,
          column: 60
        }
      },
      '82': {
        start: {
          line: 178,
          column: 2
        },
        end: {
          line: 189,
          column: 4
        }
      },
      '83': {
        start: {
          line: 179,
          column: 4
        },
        end: {
          line: 179,
          column: 30
        }
      },
      '84': {
        start: {
          line: 180,
          column: 4
        },
        end: {
          line: 188,
          column: 7
        }
      },
      '85': {
        start: {
          line: 181,
          column: 6
        },
        end: {
          line: 184,
          column: 7
        }
      },
      '86': {
        start: {
          line: 182,
          column: 8
        },
        end: {
          line: 182,
          column: 34
        }
      },
      '87': {
        start: {
          line: 183,
          column: 8
        },
        end: {
          line: 183,
          column: 48
        }
      },
      '88': {
        start: {
          line: 185,
          column: 6
        },
        end: {
          line: 185,
          column: 33
        }
      },
      '89': {
        start: {
          line: 186,
          column: 6
        },
        end: {
          line: 186,
          column: 33
        }
      },
      '90': {
        start: {
          line: 187,
          column: 6
        },
        end: {
          line: 187,
          column: 33
        }
      },
      '91': {
        start: {
          line: 190,
          column: 2
        },
        end: {
          line: 194,
          column: 4
        }
      },
      '92': {
        start: {
          line: 191,
          column: 4
        },
        end: {
          line: 193,
          column: 7
        }
      },
      '93': {
        start: {
          line: 192,
          column: 6
        },
        end: {
          line: 192,
          column: 35
        }
      },
      '94': {
        start: {
          line: 195,
          column: 2
        },
        end: {
          line: 198,
          column: 4
        }
      },
      '95': {
        start: {
          line: 196,
          column: 4
        },
        end: {
          line: 196,
          column: 102
        }
      },
      '96': {
        start: {
          line: 197,
          column: 4
        },
        end: {
          line: 197,
          column: 36
        }
      },
      '97': {
        start: {
          line: 199,
          column: 2
        },
        end: {
          line: 199,
          column: 31
        }
      },
      '98': {
        start: {
          line: 200,
          column: 2
        },
        end: {
          line: 200,
          column: 28
        }
      },
      '99': {
        start: {
          line: 201,
          column: 2
        },
        end: {
          line: 217,
          column: 4
        }
      },
      '100': {
        start: {
          line: 202,
          column: 4
        },
        end: {
          line: 202,
          column: 34
        }
      },
      '101': {
        start: {
          line: 203,
          column: 4
        },
        end: {
          line: 216,
          column: 7
        }
      },
      '102': {
        start: {
          line: 204,
          column: 6
        },
        end: {
          line: 215,
          column: 7
        }
      },
      '103': {
        start: {
          line: 205,
          column: 8
        },
        end: {
          line: 205,
          column: 34
        }
      },
      '104': {
        start: {
          line: 206,
          column: 8
        },
        end: {
          line: 206,
          column: 26
        }
      },
      '105': {
        start: {
          line: 207,
          column: 8
        },
        end: {
          line: 207,
          column: 39
        }
      },
      '106': {
        start: {
          line: 208,
          column: 8
        },
        end: {
          line: 208,
          column: 45
        }
      },
      '107': {
        start: {
          line: 210,
          column: 8
        },
        end: {
          line: 210,
          column: 37
        }
      },
      '108': {
        start: {
          line: 211,
          column: 8
        },
        end: {
          line: 211,
          column: 38
        }
      },
      '109': {
        start: {
          line: 212,
          column: 8
        },
        end: {
          line: 212,
          column: 41
        }
      },
      '110': {
        start: {
          line: 214,
          column: 8
        },
        end: {
          line: 214,
          column: 35
        }
      },
      '111': {
        start: {
          line: 219,
          column: 19
        },
        end: {
          line: 224,
          column: 4
        }
      },
      '112': {
        start: {
          line: 225,
          column: 2
        },
        end: {
          line: 229,
          column: 4
        }
      },
      '113': {
        start: {
          line: 226,
          column: 4
        },
        end: {
          line: 226,
          column: 32
        }
      },
      '114': {
        start: {
          line: 227,
          column: 4
        },
        end: {
          line: 227,
          column: 30
        }
      },
      '115': {
        start: {
          line: 228,
          column: 4
        },
        end: {
          line: 228,
          column: 22
        }
      },
      '116': {
        start: {
          line: 230,
          column: 2
        },
        end: {
          line: 249,
          column: 4
        }
      },
      '117': {
        start: {
          line: 231,
          column: 4
        },
        end: {
          line: 245,
          column: 5
        }
      },
      '118': {
        start: {
          line: 232,
          column: 23
        },
        end: {
          line: 232,
          column: 52
        }
      },
      '119': {
        start: {
          line: 233,
          column: 6
        },
        end: {
          line: 235,
          column: 7
        }
      },
      '120': {
        start: {
          line: 234,
          column: 8
        },
        end: {
          line: 234,
          column: 52
        }
      },
      '121': {
        start: {
          line: 236,
          column: 6
        },
        end: {
          line: 238,
          column: 7
        }
      },
      '122': {
        start: {
          line: 237,
          column: 8
        },
        end: {
          line: 237,
          column: 52
        }
      },
      '123': {
        start: {
          line: 239,
          column: 6
        },
        end: {
          line: 242,
          column: 9
        }
      },
      '124': {
        start: {
          line: 243,
          column: 6
        },
        end: {
          line: 243,
          column: 35
        }
      },
      '125': {
        start: {
          line: 244,
          column: 6
        },
        end: {
          line: 244,
          column: 13
        }
      },
      '126': {
        start: {
          line: 246,
          column: 4
        },
        end: {
          line: 248,
          column: 7
        }
      },
      '127': {
        start: {
          line: 247,
          column: 6
        },
        end: {
          line: 247,
          column: 29
        }
      },
      '128': {
        start: {
          line: 250,
          column: 2
        },
        end: {
          line: 252,
          column: 4
        }
      },
      '129': {
        start: {
          line: 251,
          column: 4
        },
        end: {
          line: 251,
          column: 33
        }
      },
      '130': {
        start: {
          line: 253,
          column: 2
        },
        end: {
          line: 255,
          column: 4
        }
      },
      '131': {
        start: {
          line: 254,
          column: 4
        },
        end: {
          line: 254,
          column: 37
        }
      },
      '132': {
        start: {
          line: 258,
          column: 28
        },
        end: {
          line: 264,
          column: 4
        }
      },
      '133': {
        start: {
          line: 265,
          column: 2
        },
        end: {
          line: 271,
          column: 5
        }
      },
      '134': {
        start: {
          line: 266,
          column: 4
        },
        end: {
          line: 266,
          column: 166
        }
      },
      '135': {
        start: {
          line: 267,
          column: 4
        },
        end: {
          line: 267,
          column: 48
        }
      },
      '136': {
        start: {
          line: 268,
          column: 4
        },
        end: {
          line: 268,
          column: 32
        }
      },
      '137': {
        start: {
          line: 269,
          column: 4
        },
        end: {
          line: 269,
          column: 30
        }
      },
      '138': {
        start: {
          line: 270,
          column: 4
        },
        end: {
          line: 270,
          column: 22
        }
      },
      '139': {
        start: {
          line: 272,
          column: 2
        },
        end: {
          line: 291,
          column: 5
        }
      },
      '140': {
        start: {
          line: 273,
          column: 4
        },
        end: {
          line: 273,
          column: 33
        }
      },
      '141': {
        start: {
          line: 275,
          column: 4
        },
        end: {
          line: 278,
          column: 5
        }
      },
      '142': {
        start: {
          line: 277,
          column: 6
        },
        end: {
          line: 277,
          column: 13
        }
      },
      '143': {
        start: {
          line: 279,
          column: 4
        },
        end: {
          line: 281,
          column: 6
        }
      },
      '144': {
        start: {
          line: 282,
          column: 4
        },
        end: {
          line: 289,
          column: 7
        }
      },
      '145': {
        start: {
          line: 292,
          column: 2
        },
        end: {
          line: 294,
          column: 5
        }
      },
      '146': {
        start: {
          line: 293,
          column: 4
        },
        end: {
          line: 293,
          column: 33
        }
      },
      '147': {
        start: {
          line: 295,
          column: 2
        },
        end: {
          line: 297,
          column: 5
        }
      },
      '148': {
        start: {
          line: 296,
          column: 4
        },
        end: {
          line: 296,
          column: 37
        }
      },
      '149': {
        start: {
          line: 300,
          column: 2
        },
        end: {
          line: 300,
          column: 23
        }
      },
      '150': {
        start: {
          line: 302,
          column: 2
        },
        end: {
          line: 406,
          column: 4
        }
      },
      '151': {
        start: {
          line: 303,
          column: 13
        },
        end: {
          line: 303,
          column: 15
        }
      },
      '152': {
        start: {
          line: 305,
          column: 4
        },
        end: {
          line: 305,
          column: 29
        }
      },
      '153': {
        start: {
          line: 307,
          column: 4
        },
        end: {
          line: 380,
          column: 5
        }
      },
      '154': {
        start: {
          line: 308,
          column: 6
        },
        end: {
          line: 322,
          column: 8
        }
      },
      '155': {
        start: {
          line: 323,
          column: 6
        },
        end: {
          line: 326,
          column: 8
        }
      },
      '156': {
        start: {
          line: 327,
          column: 11
        },
        end: {
          line: 380,
          column: 5
        }
      },
      '157': {
        start: {
          line: 328,
          column: 6
        },
        end: {
          line: 341,
          column: 8
        }
      },
      '158': {
        start: {
          line: 342,
          column: 6
        },
        end: {
          line: 344,
          column: 7
        }
      },
      '159': {
        start: {
          line: 343,
          column: 8
        },
        end: {
          line: 343,
          column: 38
        }
      },
      '160': {
        start: {
          line: 346,
          column: 6
        },
        end: {
          line: 357,
          column: 7
        }
      },
      '161': {
        start: {
          line: 347,
          column: 8
        },
        end: {
          line: 356,
          column: 9
        }
      },
      '162': {
        start: {
          line: 348,
          column: 10
        },
        end: {
          line: 348,
          column: 53
        }
      },
      '163': {
        start: {
          line: 349,
          column: 10
        },
        end: {
          line: 349,
          column: 45
        }
      },
      '164': {
        start: {
          line: 350,
          column: 10
        },
        end: {
          line: 350,
          column: 58
        }
      },
      '165': {
        start: {
          line: 351,
          column: 10
        },
        end: {
          line: 354,
          column: 12
        }
      },
      '166': {
        start: {
          line: 355,
          column: 10
        },
        end: {
          line: 355,
          column: 16
        }
      },
      '167': {
        start: {
          line: 359,
          column: 6
        },
        end: {
          line: 372,
          column: 8
        }
      },
      '168': {
        start: {
          line: 373,
          column: 6
        },
        end: {
          line: 379,
          column: 7
        }
      },
      '169': {
        start: {
          line: 374,
          column: 8
        },
        end: {
          line: 378,
          column: 9
        }
      },
      '170': {
        start: {
          line: 375,
          column: 10
        },
        end: {
          line: 375,
          column: 41
        }
      },
      '171': {
        start: {
          line: 376,
          column: 10
        },
        end: {
          line: 376,
          column: 58
        }
      },
      '172': {
        start: {
          line: 377,
          column: 10
        },
        end: {
          line: 377,
          column: 16
        }
      },
      '173': {
        start: {
          line: 381,
          column: 4
        },
        end: {
          line: 381,
          column: 19
        }
      },
      '174': {
        start: {
          line: 383,
          column: 4
        },
        end: {
          line: 383,
          column: 25
        }
      },
      '175': {
        start: {
          line: 385,
          column: 4
        },
        end: {
          line: 385,
          column: 54
        }
      },
      '176': {
        start: {
          line: 386,
          column: 4
        },
        end: {
          line: 391,
          column: 5
        }
      },
      '177': {
        start: {
          line: 387,
          column: 6
        },
        end: {
          line: 390,
          column: 7
        }
      },
      '178': {
        start: {
          line: 388,
          column: 8
        },
        end: {
          line: 388,
          column: 41
        }
      },
      '179': {
        start: {
          line: 389,
          column: 8
        },
        end: {
          line: 389,
          column: 14
        }
      },
      '180': {
        start: {
          line: 393,
          column: 4
        },
        end: {
          line: 397,
          column: 5
        }
      },
      '181': {
        start: {
          line: 394,
          column: 6
        },
        end: {
          line: 394,
          column: 24
        }
      },
      '182': {
        start: {
          line: 396,
          column: 6
        },
        end: {
          line: 396,
          column: 63
        }
      },
      '183': {
        start: {
          line: 398,
          column: 4
        },
        end: {
          line: 405,
          column: 7
        }
      },
      '184': {
        start: {
          line: 407,
          column: 2
        },
        end: {
          line: 448,
          column: 4
        }
      },
      '185': {
        start: {
          line: 408,
          column: 22
        },
        end: {
          line: 408,
          column: 31
        }
      },
      '186': {
        start: {
          line: 409,
          column: 4
        },
        end: {
          line: 447,
          column: 7
        }
      },
      '187': {
        start: {
          line: 421,
          column: 6
        },
        end: {
          line: 423,
          column: 7
        }
      },
      '188': {
        start: {
          line: 422,
          column: 8
        },
        end: {
          line: 422,
          column: 15
        }
      },
      '189': {
        start: {
          line: 424,
          column: 6
        },
        end: {
          line: 424,
          column: 26
        }
      },
      '190': {
        start: {
          line: 425,
          column: 27
        },
        end: {
          line: 434,
          column: 7
        }
      },
      '191': {
        start: {
          line: 435,
          column: 6
        },
        end: {
          line: 446,
          column: 9
        }
      },
      '192': {
        start: {
          line: 436,
          column: 8
        },
        end: {
          line: 436,
          column: 30
        }
      },
      '193': {
        start: {
          line: 437,
          column: 8
        },
        end: {
          line: 444,
          column: 9
        }
      },
      '194': {
        start: {
          line: 438,
          column: 10
        },
        end: {
          line: 441,
          column: 13
        }
      },
      '195': {
        start: {
          line: 442,
          column: 10
        },
        end: {
          line: 442,
          column: 35
        }
      },
      '196': {
        start: {
          line: 443,
          column: 10
        },
        end: {
          line: 443,
          column: 17
        }
      },
      '197': {
        start: {
          line: 445,
          column: 8
        },
        end: {
          line: 445,
          column: 40
        }
      },
      '198': {
        start: {
          line: 450,
          column: 2
        },
        end: {
          line: 454,
          column: 4
        }
      },
      '199': {
        start: {
          line: 451,
          column: 4
        },
        end: {
          line: 451,
          column: 25
        }
      },
      '200': {
        start: {
          line: 452,
          column: 4
        },
        end: {
          line: 452,
          column: 30
        }
      },
      '201': {
        start: {
          line: 453,
          column: 4
        },
        end: {
          line: 453,
          column: 39
        }
      },
      '202': {
        start: {
          line: 455,
          column: 2
        },
        end: {
          line: 462,
          column: 4
        }
      },
      '203': {
        start: {
          line: 456,
          column: 4
        },
        end: {
          line: 458,
          column: 5
        }
      },
      '204': {
        start: {
          line: 457,
          column: 6
        },
        end: {
          line: 457,
          column: 29
        }
      },
      '205': {
        start: {
          line: 459,
          column: 4
        },
        end: {
          line: 461,
          column: 7
        }
      },
      '206': {
        start: {
          line: 460,
          column: 6
        },
        end: {
          line: 460,
          column: 36
        }
      },
      '207': {
        start: {
          line: 463,
          column: 2
        },
        end: {
          line: 481,
          column: 4
        }
      },
      '208': {
        start: {
          line: 464,
          column: 4
        },
        end: {
          line: 470,
          column: 5
        }
      },
      '209': {
        start: {
          line: 465,
          column: 6
        },
        end: {
          line: 465,
          column: 29
        }
      },
      '210': {
        start: {
          line: 467,
          column: 6
        },
        end: {
          line: 469,
          column: 7
        }
      },
      '211': {
        start: {
          line: 468,
          column: 8
        },
        end: {
          line: 468,
          column: 28
        }
      },
      '212': {
        start: {
          line: 471,
          column: 4
        },
        end: {
          line: 480,
          column: 7
        }
      },
      '213': {
        start: {
          line: 472,
          column: 6
        },
        end: {
          line: 472,
          column: 39
        }
      },
      '214': {
        start: {
          line: 473,
          column: 6
        },
        end: {
          line: 479,
          column: 7
        }
      },
      '215': {
        start: {
          line: 474,
          column: 8
        },
        end: {
          line: 474,
          column: 32
        }
      },
      '216': {
        start: {
          line: 476,
          column: 8
        },
        end: {
          line: 478,
          column: 9
        }
      },
      '217': {
        start: {
          line: 477,
          column: 10
        },
        end: {
          line: 477,
          column: 31
        }
      },
      '218': {
        start: {
          line: 483,
          column: 2
        },
        end: {
          line: 487,
          column: 4
        }
      },
      '219': {
        start: {
          line: 484,
          column: 4
        },
        end: {
          line: 486,
          column: 5
        }
      },
      '220': {
        start: {
          line: 485,
          column: 6
        },
        end: {
          line: 485,
          column: 46
        }
      },
      '221': {
        start: {
          line: 489,
          column: 2
        },
        end: {
          line: 493,
          column: 5
        }
      },
      '222': {
        start: {
          line: 490,
          column: 4
        },
        end: {
          line: 490,
          column: 22
        }
      },
      '223': {
        start: {
          line: 491,
          column: 4
        },
        end: {
          line: 491,
          column: 35
        }
      },
      '224': {
        start: {
          line: 492,
          column: 4
        },
        end: {
          line: 492,
          column: 41
        }
      },
      '225': {
        start: {
          line: 495,
          column: 2
        },
        end: {
          line: 495,
          column: 27
        }
      },
      '226': {
        start: {
          line: 496,
          column: 2
        },
        end: {
          line: 496,
          column: 23
        }
      },
      '227': {
        start: {
          line: 497,
          column: 2
        },
        end: {
          line: 497,
          column: 28
        }
      },
      '228': {
        start: {
          line: 498,
          column: 2
        },
        end: {
          line: 498,
          column: 20
        }
      },
      '229': {
        start: {
          line: 503,
          column: 2
        },
        end: {
          line: 506,
          column: 4
        }
      },
      '230': {
        start: {
          line: 504,
          column: 4
        },
        end: {
          line: 504,
          column: 24
        }
      },
      '231': {
        start: {
          line: 505,
          column: 4
        },
        end: {
          line: 505,
          column: 40
        }
      },
      '232': {
        start: {
          line: 507,
          column: 20
        },
        end: {
          line: 530,
          column: 3
        }
      },
      '233': {
        start: {
          line: 508,
          column: 17
        },
        end: {
          line: 508,
          column: 104
        }
      },
      '234': {
        start: {
          line: 509,
          column: 4
        },
        end: {
          line: 528,
          column: 5
        }
      },
      '235': {
        start: {
          line: 511,
          column: 8
        },
        end: {
          line: 511,
          column: 105
        }
      },
      '236': {
        start: {
          line: 512,
          column: 8
        },
        end: {
          line: 512,
          column: 14
        }
      },
      '237': {
        start: {
          line: 514,
          column: 8
        },
        end: {
          line: 514,
          column: 108
        }
      },
      '238': {
        start: {
          line: 515,
          column: 8
        },
        end: {
          line: 515,
          column: 14
        }
      },
      '239': {
        start: {
          line: 517,
          column: 8
        },
        end: {
          line: 517,
          column: 108
        }
      },
      '240': {
        start: {
          line: 518,
          column: 8
        },
        end: {
          line: 518,
          column: 14
        }
      },
      '241': {
        start: {
          line: 520,
          column: 8
        },
        end: {
          line: 520,
          column: 108
        }
      },
      '242': {
        start: {
          line: 521,
          column: 8
        },
        end: {
          line: 521,
          column: 14
        }
      },
      '243': {
        start: {
          line: 523,
          column: 8
        },
        end: {
          line: 523,
          column: 108
        }
      },
      '244': {
        start: {
          line: 524,
          column: 8
        },
        end: {
          line: 524,
          column: 14
        }
      },
      '245': {
        start: {
          line: 526,
          column: 8
        },
        end: {
          line: 526,
          column: 105
        }
      },
      '246': {
        start: {
          line: 527,
          column: 8
        },
        end: {
          line: 527,
          column: 14
        }
      },
      '247': {
        start: {
          line: 529,
          column: 4
        },
        end: {
          line: 529,
          column: 122
        }
      },
      '248': {
        start: {
          line: 531,
          column: 14
        },
        end: {
          line: 531,
          column: 18
        }
      },
      '249': {
        start: {
          line: 532,
          column: 22
        },
        end: {
          line: 532,
          column: 26
        }
      },
      '250': {
        start: {
          line: 533,
          column: 2
        },
        end: {
          line: 548,
          column: 5
        }
      },
      '251': {
        start: {
          line: 534,
          column: 22
        },
        end: {
          line: 534,
          column: 47
        }
      },
      '252': {
        start: {
          line: 535,
          column: 4
        },
        end: {
          line: 540,
          column: 55
        }
      },
      '253': {
        start: {
          line: 541,
          column: 4
        },
        end: {
          line: 541,
          column: 48
        }
      },
      '254': {
        start: {
          line: 542,
          column: 17
        },
        end: {
          line: 542,
          column: 19
        }
      },
      '255': {
        start: {
          line: 543,
          column: 4
        },
        end: {
          line: 543,
          column: 77
        }
      },
      '256': {
        start: {
          line: 544,
          column: 4
        },
        end: {
          line: 544,
          column: 61
        }
      },
      '257': {
        start: {
          line: 545,
          column: 4
        },
        end: {
          line: 545,
          column: 77
        }
      },
      '258': {
        start: {
          line: 547,
          column: 4
        },
        end: {
          line: 547,
          column: 40
        }
      },
      '259': {
        start: {
          line: 549,
          column: 2
        },
        end: {
          line: 562,
          column: 4
        }
      },
      '260': {
        start: {
          line: 550,
          column: 4
        },
        end: {
          line: 550,
          column: 32
        }
      },
      '261': {
        start: {
          line: 551,
          column: 4
        },
        end: {
          line: 551,
          column: 29
        }
      },
      '262': {
        start: {
          line: 552,
          column: 14
        },
        end: {
          line: 555,
          column: 26
        }
      },
      '263': {
        start: {
          line: 556,
          column: 4
        },
        end: {
          line: 561,
          column: 7
        }
      },
      '264': {
        start: {
          line: 557,
          column: 21
        },
        end: {
          line: 557,
          column: 34
        }
      },
      '265': {
        start: {
          line: 558,
          column: 6
        },
        end: {
          line: 558,
          column: 61
        }
      },
      '266': {
        start: {
          line: 559,
          column: 6
        },
        end: {
          line: 559,
          column: 61
        }
      },
      '267': {
        start: {
          line: 560,
          column: 6
        },
        end: {
          line: 560,
          column: 28
        }
      },
      '268': {
        start: {
          line: 564,
          column: 2
        },
        end: {
          line: 566,
          column: 4
        }
      },
      '269': {
        start: {
          line: 565,
          column: 4
        },
        end: {
          line: 565,
          column: 122
        }
      },
      '270': {
        start: {
          line: 567,
          column: 2
        },
        end: {
          line: 578,
          column: 4
        }
      },
      '271': {
        start: {
          line: 568,
          column: 17
        },
        end: {
          line: 568,
          column: 32
        }
      },
      '272': {
        start: {
          line: 569,
          column: 4
        },
        end: {
          line: 571,
          column: 5
        }
      },
      '273': {
        start: {
          line: 570,
          column: 6
        },
        end: {
          line: 570,
          column: 18
        }
      },
      '274': {
        start: {
          line: 572,
          column: 4
        },
        end: {
          line: 576,
          column: 5
        }
      },
      '275': {
        start: {
          line: 573,
          column: 6
        },
        end: {
          line: 575,
          column: 7
        }
      },
      '276': {
        start: {
          line: 574,
          column: 8
        },
        end: {
          line: 574,
          column: 20
        }
      },
      '277': {
        start: {
          line: 577,
          column: 4
        },
        end: {
          line: 577,
          column: 17
        }
      },
      '278': {
        start: {
          line: 579,
          column: 2
        },
        end: {
          line: 646,
          column: 4
        }
      },
      '279': {
        start: {
          line: 580,
          column: 18
        },
        end: {
          line: 580,
          column: 24
        }
      },
      '280': {
        start: {
          line: 581,
          column: 4
        },
        end: {
          line: 584,
          column: 6
        }
      },
      '281': {
        start: {
          line: 585,
          column: 4
        },
        end: {
          line: 585,
          column: 76
        }
      },
      '282': {
        start: {
          line: 588,
          column: 4
        },
        end: {
          line: 597,
          column: 5
        }
      },
      '283': {
        start: {
          line: 589,
          column: 23
        },
        end: {
          line: 589,
          column: 109
        }
      },
      '284': {
        start: {
          line: 590,
          column: 6
        },
        end: {
          line: 596,
          column: 7
        }
      },
      '285': {
        start: {
          line: 591,
          column: 8
        },
        end: {
          line: 594,
          column: 10
        }
      },
      '286': {
        start: {
          line: 595,
          column: 8
        },
        end: {
          line: 595,
          column: 15
        }
      },
      '287': {
        start: {
          line: 598,
          column: 4
        },
        end: {
          line: 598,
          column: 30
        }
      },
      '288': {
        start: {
          line: 599,
          column: 14
        },
        end: {
          line: 610,
          column: 5
        }
      },
      '289': {
        start: {
          line: 612,
          column: 18
        },
        end: {
          line: 612,
          column: 22
        }
      },
      '290': {
        start: {
          line: 613,
          column: 4
        },
        end: {
          line: 645,
          column: 7
        }
      },
      '291': {
        start: {
          line: 614,
          column: 19
        },
        end: {
          line: 614,
          column: 27
        }
      },
      '292': {
        start: {
          line: 615,
          column: 6
        },
        end: {
          line: 615,
          column: 33
        }
      },
      '293': {
        start: {
          line: 616,
          column: 6
        },
        end: {
          line: 618,
          column: 7
        }
      },
      '294': {
        start: {
          line: 617,
          column: 8
        },
        end: {
          line: 617,
          column: 15
        }
      },
      '295': {
        start: {
          line: 619,
          column: 6
        },
        end: {
          line: 641,
          column: 7
        }
      },
      '296': {
        start: {
          line: 620,
          column: 23
        },
        end: {
          line: 620,
          column: 90
        }
      },
      '297': {
        start: {
          line: 621,
          column: 8
        },
        end: {
          line: 635,
          column: 9
        }
      },
      '298': {
        start: {
          line: 622,
          column: 10
        },
        end: {
          line: 622,
          column: 22
        }
      },
      '299': {
        start: {
          line: 623,
          column: 10
        },
        end: {
          line: 623,
          column: 30
        }
      },
      '300': {
        start: {
          line: 624,
          column: 10
        },
        end: {
          line: 624,
          column: 34
        }
      },
      '301': {
        start: {
          line: 625,
          column: 10
        },
        end: {
          line: 625,
          column: 34
        }
      },
      '302': {
        start: {
          line: 626,
          column: 10
        },
        end: {
          line: 626,
          column: 91
        }
      },
      '303': {
        start: {
          line: 627,
          column: 15
        },
        end: {
          line: 635,
          column: 9
        }
      },
      '304': {
        start: {
          line: 628,
          column: 10
        },
        end: {
          line: 628,
          column: 22
        }
      },
      '305': {
        start: {
          line: 629,
          column: 10
        },
        end: {
          line: 629,
          column: 30
        }
      },
      '306': {
        start: {
          line: 630,
          column: 10
        },
        end: {
          line: 630,
          column: 34
        }
      },
      '307': {
        start: {
          line: 631,
          column: 10
        },
        end: {
          line: 631,
          column: 34
        }
      },
      '308': {
        start: {
          line: 632,
          column: 10
        },
        end: {
          line: 632,
          column: 66
        }
      },
      '309': {
        start: {
          line: 634,
          column: 10
        },
        end: {
          line: 634,
          column: 42
        }
      },
      '310': {
        start: {
          line: 637,
          column: 8
        },
        end: {
          line: 637,
          column: 28
        }
      },
      '311': {
        start: {
          line: 638,
          column: 8
        },
        end: {
          line: 638,
          column: 32
        }
      },
      '312': {
        start: {
          line: 639,
          column: 8
        },
        end: {
          line: 639,
          column: 32
        }
      },
      '313': {
        start: {
          line: 640,
          column: 8
        },
        end: {
          line: 640,
          column: 89
        }
      },
      '314': {
        start: {
          line: 643,
          column: 6
        },
        end: {
          line: 643,
          column: 35
        }
      },
      '315': {
        start: {
          line: 644,
          column: 6
        },
        end: {
          line: 644,
          column: 26
        }
      },
      '316': {
        start: {
          line: 647,
          column: 2
        },
        end: {
          line: 670,
          column: 4
        }
      },
      '317': {
        start: {
          line: 648,
          column: 4
        },
        end: {
          line: 650,
          column: 5
        }
      },
      '318': {
        start: {
          line: 649,
          column: 6
        },
        end: {
          line: 649,
          column: 13
        }
      },
      '319': {
        start: {
          line: 674,
          column: 2
        },
        end: {
          line: 789,
          column: 4
        }
      },
      '320': {
        start: {
          line: 677,
          column: 6
        },
        end: {
          line: 681,
          column: 9
        }
      },
      '321': {
        start: {
          line: 684,
          column: 6
        },
        end: {
          line: 684,
          column: 45
        }
      },
      '322': {
        start: {
          line: 685,
          column: 6
        },
        end: {
          line: 685,
          column: 43
        }
      },
      '323': {
        start: {
          line: 686,
          column: 6
        },
        end: {
          line: 686,
          column: 78
        }
      },
      '324': {
        start: {
          line: 687,
          column: 6
        },
        end: {
          line: 689,
          column: 7
        }
      },
      '325': {
        start: {
          line: 688,
          column: 8
        },
        end: {
          line: 688,
          column: 56
        }
      },
      '326': {
        start: {
          line: 690,
          column: 6
        },
        end: {
          line: 714,
          column: 7
        }
      },
      '327': {
        start: {
          line: 691,
          column: 8
        },
        end: {
          line: 691,
          column: 41
        }
      },
      '328': {
        start: {
          line: 692,
          column: 8
        },
        end: {
          line: 696,
          column: 9
        }
      },
      '329': {
        start: {
          line: 693,
          column: 10
        },
        end: {
          line: 693,
          column: 42
        }
      },
      '330': {
        start: {
          line: 695,
          column: 10
        },
        end: {
          line: 695,
          column: 104
        }
      },
      '331': {
        start: {
          line: 698,
          column: 8
        },
        end: {
          line: 713,
          column: 9
        }
      },
      '332': {
        start: {
          line: 699,
          column: 21
        },
        end: {
          line: 699,
          column: 43
        }
      },
      '333': {
        start: {
          line: 700,
          column: 10
        },
        end: {
          line: 712,
          column: 11
        }
      },
      '334': {
        start: {
          line: 701,
          column: 12
        },
        end: {
          line: 705,
          column: 13
        }
      },
      '335': {
        start: {
          line: 702,
          column: 14
        },
        end: {
          line: 702,
          column: 46
        }
      },
      '336': {
        start: {
          line: 704,
          column: 14
        },
        end: {
          line: 704,
          column: 83
        }
      },
      '337': {
        start: {
          line: 706,
          column: 12
        },
        end: {
          line: 710,
          column: 13
        }
      },
      '338': {
        start: {
          line: 707,
          column: 14
        },
        end: {
          line: 707,
          column: 47
        }
      },
      '339': {
        start: {
          line: 709,
          column: 14
        },
        end: {
          line: 709,
          column: 83
        }
      },
      '340': {
        start: {
          line: 711,
          column: 12
        },
        end: {
          line: 711,
          column: 18
        }
      },
      '341': {
        start: {
          line: 717,
          column: 6
        },
        end: {
          line: 717,
          column: 31
        }
      },
      '342': {
        start: {
          line: 720,
          column: 19
        },
        end: {
          line: 720,
          column: 50
        }
      },
      '343': {
        start: {
          line: 721,
          column: 6
        },
        end: {
          line: 739,
          column: 9
        }
      },
      '344': {
        start: {
          line: 722,
          column: 8
        },
        end: {
          line: 728,
          column: 9
        }
      },
      '345': {
        start: {
          line: 723,
          column: 10
        },
        end: {
          line: 726,
          column: 13
        }
      },
      '346': {
        start: {
          line: 727,
          column: 10
        },
        end: {
          line: 727,
          column: 17
        }
      },
      '347': {
        start: {
          line: 729,
          column: 8
        },
        end: {
          line: 735,
          column: 9
        }
      },
      '348': {
        start: {
          line: 730,
          column: 10
        },
        end: {
          line: 733,
          column: 13
        }
      },
      '349': {
        start: {
          line: 734,
          column: 10
        },
        end: {
          line: 734,
          column: 17
        }
      },
      '350': {
        start: {
          line: 736,
          column: 8
        },
        end: {
          line: 736,
          column: 37
        }
      },
      '351': {
        start: {
          line: 737,
          column: 8
        },
        end: {
          line: 737,
          column: 59
        }
      },
      '352': {
        start: {
          line: 738,
          column: 8
        },
        end: {
          line: 738,
          column: 26
        }
      },
      '353': {
        start: {
          line: 742,
          column: 6
        },
        end: {
          line: 744,
          column: 7
        }
      },
      '354': {
        start: {
          line: 743,
          column: 8
        },
        end: {
          line: 743,
          column: 15
        }
      },
      '355': {
        start: {
          line: 745,
          column: 23
        },
        end: {
          line: 745,
          column: 25
        }
      },
      '356': {
        start: {
          line: 746,
          column: 6
        },
        end: {
          line: 750,
          column: 7
        }
      },
      '357': {
        start: {
          line: 747,
          column: 8
        },
        end: {
          line: 749,
          column: 9
        }
      },
      '358': {
        start: {
          line: 748,
          column: 10
        },
        end: {
          line: 748,
          column: 31
        }
      },
      '359': {
        start: {
          line: 751,
          column: 6
        },
        end: {
          line: 753,
          column: 9
        }
      },
      '360': {
        start: {
          line: 752,
          column: 8
        },
        end: {
          line: 752,
          column: 34
        }
      },
      '361': {
        start: {
          line: 756,
          column: 6
        },
        end: {
          line: 784,
          column: 9
        }
      },
      '362': {
        start: {
          line: 768,
          column: 8
        },
        end: {
          line: 770,
          column: 9
        }
      },
      '363': {
        start: {
          line: 769,
          column: 10
        },
        end: {
          line: 769,
          column: 17
        }
      },
      '364': {
        start: {
          line: 771,
          column: 8
        },
        end: {
          line: 771,
          column: 34
        }
      },
      '365': {
        start: {
          line: 772,
          column: 8
        },
        end: {
          line: 783,
          column: 11
        }
      },
      '366': {
        start: {
          line: 773,
          column: 10
        },
        end: {
          line: 773,
          column: 37
        }
      },
      '367': {
        start: {
          line: 774,
          column: 10
        },
        end: {
          line: 780,
          column: 11
        }
      },
      '368': {
        start: {
          line: 775,
          column: 12
        },
        end: {
          line: 778,
          column: 15
        }
      },
      '369': {
        start: {
          line: 779,
          column: 12
        },
        end: {
          line: 779,
          column: 19
        }
      },
      '370': {
        start: {
          line: 781,
          column: 10
        },
        end: {
          line: 781,
          column: 28
        }
      },
      '371': {
        start: {
          line: 782,
          column: 10
        },
        end: {
          line: 782,
          column: 36
        }
      },
      '372': {
        start: {
          line: 787,
          column: 6
        },
        end: {
          line: 787,
          column: 42
        }
      },
      '373': {
        start: {
          line: 793,
          column: 2
        },
        end: {
          line: 897,
          column: 4
        }
      },
      '374': {
        start: {
          line: 796,
          column: 6
        },
        end: {
          line: 796,
          column: 28
        }
      },
      '375': {
        start: {
          line: 797,
          column: 6
        },
        end: {
          line: 819,
          column: 9
        }
      },
      '376': {
        start: {
          line: 801,
          column: 8
        },
        end: {
          line: 801,
          column: 31
        }
      },
      '377': {
        start: {
          line: 802,
          column: 8
        },
        end: {
          line: 815,
          column: 11
        }
      },
      '378': {
        start: {
          line: 803,
          column: 10
        },
        end: {
          line: 810,
          column: 12
        }
      },
      '379': {
        start: {
          line: 812,
          column: 10
        },
        end: {
          line: 814,
          column: 13
        }
      },
      '380': {
        start: {
          line: 813,
          column: 12
        },
        end: {
          line: 813,
          column: 76
        }
      },
      '381': {
        start: {
          line: 816,
          column: 8
        },
        end: {
          line: 816,
          column: 71
        }
      },
      '382': {
        start: {
          line: 818,
          column: 8
        },
        end: {
          line: 818,
          column: 31
        }
      },
      '383': {
        start: {
          line: 822,
          column: 6
        },
        end: {
          line: 822,
          column: 32
        }
      },
      '384': {
        start: {
          line: 823,
          column: 6
        },
        end: {
          line: 825,
          column: 7
        }
      },
      '385': {
        start: {
          line: 824,
          column: 8
        },
        end: {
          line: 824,
          column: 56
        }
      },
      '386': {
        start: {
          line: 826,
          column: 6
        },
        end: {
          line: 829,
          column: 7
        }
      },
      '387': {
        start: {
          line: 827,
          column: 8
        },
        end: {
          line: 827,
          column: 31
        }
      },
      '388': {
        start: {
          line: 828,
          column: 8
        },
        end: {
          line: 828,
          column: 45
        }
      },
      '389': {
        start: {
          line: 830,
          column: 6
        },
        end: {
          line: 830,
          column: 28
        }
      },
      '390': {
        start: {
          line: 831,
          column: 6
        },
        end: {
          line: 853,
          column: 11
        }
      },
      '391': {
        start: {
          line: 833,
          column: 23
        },
        end: {
          line: 833,
          column: 36
        }
      },
      '392': {
        start: {
          line: 834,
          column: 10
        },
        end: {
          line: 837,
          column: 11
        }
      },
      '393': {
        start: {
          line: 835,
          column: 12
        },
        end: {
          line: 835,
          column: 35
        }
      },
      '394': {
        start: {
          line: 836,
          column: 12
        },
        end: {
          line: 836,
          column: 49
        }
      },
      '395': {
        start: {
          line: 838,
          column: 10
        },
        end: {
          line: 849,
          column: 13
        }
      },
      '396': {
        start: {
          line: 839,
          column: 34
        },
        end: {
          line: 848,
          column: 13
        }
      },
      '397': {
        start: {
          line: 851,
          column: 10
        },
        end: {
          line: 851,
          column: 33
        }
      },
      '398': {
        start: {
          line: 852,
          column: 10
        },
        end: {
          line: 852,
          column: 40
        }
      },
      '399': {
        start: {
          line: 856,
          column: 6
        },
        end: {
          line: 866,
          column: 9
        }
      },
      '400': {
        start: {
          line: 867,
          column: 6
        },
        end: {
          line: 867,
          column: 48
        }
      },
      '401': {
        start: {
          line: 870,
          column: 6
        },
        end: {
          line: 870,
          column: 31
        }
      },
      '402': {
        start: {
          line: 873,
          column: 25
        },
        end: {
          line: 873,
          column: 49
        }
      },
      '403': {
        start: {
          line: 874,
          column: 6
        },
        end: {
          line: 883,
          column: 7
        }
      },
      '404': {
        start: {
          line: 875,
          column: 8
        },
        end: {
          line: 882,
          column: 11
        }
      },
      '405': {
        start: {
          line: 884,
          column: 6
        },
        end: {
          line: 884,
          column: 28
        }
      },
      '406': {
        start: {
          line: 885,
          column: 6
        },
        end: {
          line: 891,
          column: 9
        }
      },
      '407': {
        start: {
          line: 886,
          column: 8
        },
        end: {
          line: 886,
          column: 32
        }
      },
      '408': {
        start: {
          line: 888,
          column: 8
        },
        end: {
          line: 888,
          column: 31
        }
      },
      '409': {
        start: {
          line: 889,
          column: 8
        },
        end: {
          line: 889,
          column: 35
        }
      },
      '410': {
        start: {
          line: 890,
          column: 8
        },
        end: {
          line: 890,
          column: 26
        }
      },
      '411': {
        start: {
          line: 894,
          column: 6
        },
        end: {
          line: 894,
          column: 42
        }
      },
      '412': {
        start: {
          line: 898,
          column: 2
        },
        end: {
          line: 901,
          column: 5
        }
      },
      '413': {
        start: {
          line: 899,
          column: 4
        },
        end: {
          line: 899,
          column: 35
        }
      },
      '414': {
        start: {
          line: 900,
          column: 4
        },
        end: {
          line: 900,
          column: 35
        }
      },
      '415': {
        start: {
          line: 906,
          column: 2
        },
        end: {
          line: 908,
          column: 4
        }
      },
      '416': {
        start: {
          line: 910,
          column: 2
        },
        end: {
          line: 912,
          column: 5
        }
      },
      '417': {
        start: {
          line: 911,
          column: 4
        },
        end: {
          line: 911,
          column: 40
        }
      },
      '418': {
        start: {
          line: 914,
          column: 2
        },
        end: {
          line: 922,
          column: 5
        }
      },
      '419': {
        start: {
          line: 915,
          column: 4
        },
        end: {
          line: 920,
          column: 9
        }
      },
      '420': {
        start: {
          line: 918,
          column: 8
        },
        end: {
          line: 918,
          column: 35
        }
      },
      '421': {
        start: {
          line: 919,
          column: 8
        },
        end: {
          line: 919,
          column: 28
        }
      },
      '422': {
        start: {
          line: 921,
          column: 4
        },
        end: {
          line: 921,
          column: 26
        }
      },
      '423': {
        start: {
          line: 924,
          column: 2
        },
        end: {
          line: 927,
          column: 3
        }
      },
      '424': {
        start: {
          line: 925,
          column: 4
        },
        end: {
          line: 925,
          column: 22
        }
      },
      '425': {
        start: {
          line: 926,
          column: 4
        },
        end: {
          line: 926,
          column: 11
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 55
          },
          end: {
            line: 4,
            column: 56
          }
        },
        loc: {
          start: {
            line: 4,
            column: 223
          },
          end: {
            line: 500,
            column: 1
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 34,
            column: 22
          },
          end: {
            line: 34,
            column: 23
          }
        },
        loc: {
          start: {
            line: 34,
            column: 28
          },
          end: {
            line: 40,
            column: 3
          }
        },
        line: 34
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 60,
            column: 24
          },
          end: {
            line: 60,
            column: 25
          }
        },
        loc: {
          start: {
            line: 60,
            column: 36
          },
          end: {
            line: 64,
            column: 3
          }
        },
        line: 60
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 65,
            column: 28
          },
          end: {
            line: 65,
            column: 29
          }
        },
        loc: {
          start: {
            line: 65,
            column: 40
          },
          end: {
            line: 81,
            column: 3
          }
        },
        line: 65
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 70,
            column: 56
          },
          end: {
            line: 70,
            column: 57
          }
        },
        loc: {
          start: {
            line: 70,
            column: 64
          },
          end: {
            line: 80,
            column: 5
          }
        },
        line: 70
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 83,
            column: 24
          },
          end: {
            line: 83,
            column: 25
          }
        },
        loc: {
          start: {
            line: 83,
            column: 36
          },
          end: {
            line: 92,
            column: 3
          }
        },
        line: 83
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 94,
            column: 34
          },
          end: {
            line: 94,
            column: 35
          }
        },
        loc: {
          start: {
            line: 94,
            column: 51
          },
          end: {
            line: 96,
            column: 3
          }
        },
        line: 94
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 105,
            column: 27
          },
          end: {
            line: 105,
            column: 28
          }
        },
        loc: {
          start: {
            line: 105,
            column: 36
          },
          end: {
            line: 116,
            column: 3
          }
        },
        line: 105
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 107,
            column: 82
          },
          end: {
            line: 107,
            column: 83
          }
        },
        loc: {
          start: {
            line: 107,
            column: 90
          },
          end: {
            line: 115,
            column: 5
          }
        },
        line: 107
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 118,
            column: 24
          },
          end: {
            line: 118,
            column: 25
          }
        },
        loc: {
          start: {
            line: 118,
            column: 33
          },
          end: {
            line: 129,
            column: 3
          }
        },
        line: 118
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 120,
            column: 79
          },
          end: {
            line: 120,
            column: 80
          }
        },
        loc: {
          start: {
            line: 120,
            column: 87
          },
          end: {
            line: 128,
            column: 5
          }
        },
        line: 120
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 130,
            column: 24
          },
          end: {
            line: 130,
            column: 25
          }
        },
        loc: {
          start: {
            line: 130,
            column: 36
          },
          end: {
            line: 139,
            column: 3
          }
        },
        line: 130
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 140,
            column: 27
          },
          end: {
            line: 140,
            column: 28
          }
        },
        loc: {
          start: {
            line: 140,
            column: 39
          },
          end: {
            line: 149,
            column: 3
          }
        },
        line: 140
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 150,
            column: 22
          },
          end: {
            line: 150,
            column: 23
          }
        },
        loc: {
          start: {
            line: 150,
            column: 38
          },
          end: {
            line: 159,
            column: 3
          }
        },
        line: 150
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 170,
            column: 18
          },
          end: {
            line: 170,
            column: 19
          }
        },
        loc: {
          start: {
            line: 170,
            column: 30
          },
          end: {
            line: 176,
            column: 3
          }
        },
        line: 170
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 172,
            column: 56
          },
          end: {
            line: 172,
            column: 57
          }
        },
        loc: {
          start: {
            line: 172,
            column: 64
          },
          end: {
            line: 175,
            column: 5
          }
        },
        line: 172
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 178,
            column: 20
          },
          end: {
            line: 178,
            column: 21
          }
        },
        loc: {
          start: {
            line: 178,
            column: 32
          },
          end: {
            line: 189,
            column: 3
          }
        },
        line: 178
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 180,
            column: 53
          },
          end: {
            line: 180,
            column: 54
          }
        },
        loc: {
          start: {
            line: 180,
            column: 61
          },
          end: {
            line: 188,
            column: 5
          }
        },
        line: 180
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 190,
            column: 23
          },
          end: {
            line: 190,
            column: 24
          }
        },
        loc: {
          start: {
            line: 190,
            column: 35
          },
          end: {
            line: 194,
            column: 3
          }
        },
        line: 190
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 191,
            column: 65
          },
          end: {
            line: 191,
            column: 66
          }
        },
        loc: {
          start: {
            line: 191,
            column: 73
          },
          end: {
            line: 193,
            column: 5
          }
        },
        line: 191
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 195,
            column: 25
          },
          end: {
            line: 195,
            column: 26
          }
        },
        loc: {
          start: {
            line: 195,
            column: 42
          },
          end: {
            line: 198,
            column: 3
          }
        },
        line: 195
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 201,
            column: 24
          },
          end: {
            line: 201,
            column: 25
          }
        },
        loc: {
          start: {
            line: 201,
            column: 37
          },
          end: {
            line: 217,
            column: 3
          }
        },
        line: 201
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 203,
            column: 66
          },
          end: {
            line: 203,
            column: 67
          }
        },
        loc: {
          start: {
            line: 203,
            column: 74
          },
          end: {
            line: 216,
            column: 5
          }
        },
        line: 203
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 225,
            column: 31
          },
          end: {
            line: 225,
            column: 32
          }
        },
        loc: {
          start: {
            line: 225,
            column: 51
          },
          end: {
            line: 229,
            column: 3
          }
        },
        line: 225
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 230,
            column: 27
          },
          end: {
            line: 230,
            column: 28
          }
        },
        loc: {
          start: {
            line: 230,
            column: 70
          },
          end: {
            line: 249,
            column: 3
          }
        },
        line: 230
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 246,
            column: 118
          },
          end: {
            line: 246,
            column: 119
          }
        },
        loc: {
          start: {
            line: 246,
            column: 126
          },
          end: {
            line: 248,
            column: 5
          }
        },
        line: 246
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 250,
            column: 25
          },
          end: {
            line: 250,
            column: 26
          }
        },
        loc: {
          start: {
            line: 250,
            column: 68
          },
          end: {
            line: 252,
            column: 3
          }
        },
        line: 250
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 253,
            column: 28
          },
          end: {
            line: 253,
            column: 29
          }
        },
        loc: {
          start: {
            line: 253,
            column: 54
          },
          end: {
            line: 255,
            column: 3
          }
        },
        line: 253
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 265,
            column: 41
          },
          end: {
            line: 265,
            column: 42
          }
        },
        loc: {
          start: {
            line: 265,
            column: 53
          },
          end: {
            line: 271,
            column: 3
          }
        },
        line: 265
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 272,
            column: 37
          },
          end: {
            line: 272,
            column: 38
          }
        },
        loc: {
          start: {
            line: 272,
            column: 74
          },
          end: {
            line: 291,
            column: 3
          }
        },
        line: 272
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 292,
            column: 35
          },
          end: {
            line: 292,
            column: 36
          }
        },
        loc: {
          start: {
            line: 292,
            column: 72
          },
          end: {
            line: 294,
            column: 3
          }
        },
        line: 292
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 295,
            column: 38
          },
          end: {
            line: 295,
            column: 39
          }
        },
        loc: {
          start: {
            line: 295,
            column: 58
          },
          end: {
            line: 297,
            column: 3
          }
        },
        line: 295
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 302,
            column: 16
          },
          end: {
            line: 302,
            column: 17
          }
        },
        loc: {
          start: {
            line: 302,
            column: 45
          },
          end: {
            line: 406,
            column: 3
          }
        },
        line: 302
      },
      '33': {
        name: '(anonymous_33)',
        decl: {
          start: {
            line: 407,
            column: 22
          },
          end: {
            line: 407,
            column: 23
          }
        },
        loc: {
          start: {
            line: 407,
            column: 38
          },
          end: {
            line: 448,
            column: 3
          }
        },
        line: 407
      },
      '34': {
        name: '(anonymous_34)',
        decl: {
          start: {
            line: 420,
            column: 7
          },
          end: {
            line: 420,
            column: 8
          }
        },
        loc: {
          start: {
            line: 420,
            column: 20
          },
          end: {
            line: 447,
            column: 5
          }
        },
        line: 420
      },
      '35': {
        name: '(anonymous_35)',
        decl: {
          start: {
            line: 435,
            column: 97
          },
          end: {
            line: 435,
            column: 98
          }
        },
        loc: {
          start: {
            line: 435,
            column: 105
          },
          end: {
            line: 446,
            column: 7
          }
        },
        line: 435
      },
      '36': {
        name: '(anonymous_36)',
        decl: {
          start: {
            line: 450,
            column: 22
          },
          end: {
            line: 450,
            column: 23
          }
        },
        loc: {
          start: {
            line: 450,
            column: 34
          },
          end: {
            line: 454,
            column: 3
          }
        },
        line: 450
      },
      '37': {
        name: '(anonymous_37)',
        decl: {
          start: {
            line: 455,
            column: 21
          },
          end: {
            line: 455,
            column: 22
          }
        },
        loc: {
          start: {
            line: 455,
            column: 38
          },
          end: {
            line: 462,
            column: 3
          }
        },
        line: 455
      },
      '38': {
        name: '(anonymous_38)',
        decl: {
          start: {
            line: 459,
            column: 84
          },
          end: {
            line: 459,
            column: 85
          }
        },
        loc: {
          start: {
            line: 459,
            column: 92
          },
          end: {
            line: 461,
            column: 5
          }
        },
        line: 459
      },
      '39': {
        name: '(anonymous_39)',
        decl: {
          start: {
            line: 463,
            column: 27
          },
          end: {
            line: 463,
            column: 28
          }
        },
        loc: {
          start: {
            line: 463,
            column: 44
          },
          end: {
            line: 481,
            column: 3
          }
        },
        line: 463
      },
      '40': {
        name: '(anonymous_40)',
        decl: {
          start: {
            line: 471,
            column: 67
          },
          end: {
            line: 471,
            column: 68
          }
        },
        loc: {
          start: {
            line: 471,
            column: 75
          },
          end: {
            line: 480,
            column: 5
          }
        },
        line: 471
      },
      '41': {
        name: '(anonymous_41)',
        decl: {
          start: {
            line: 483,
            column: 30
          },
          end: {
            line: 483,
            column: 31
          }
        },
        loc: {
          start: {
            line: 483,
            column: 43
          },
          end: {
            line: 487,
            column: 3
          }
        },
        line: 483
      },
      '42': {
        name: '(anonymous_42)',
        decl: {
          start: {
            line: 489,
            column: 30
          },
          end: {
            line: 489,
            column: 31
          }
        },
        loc: {
          start: {
            line: 489,
            column: 36
          },
          end: {
            line: 493,
            column: 3
          }
        },
        line: 489
      },
      '43': {
        name: 'geographic_mapCtrl_edit',
        decl: {
          start: {
            line: 502,
            column: 9
          },
          end: {
            line: 502,
            column: 32
          }
        },
        loc: {
          start: {
            line: 502,
            column: 124
          },
          end: {
            line: 671,
            column: 1
          }
        },
        line: 502
      },
      '44': {
        name: '(anonymous_44)',
        decl: {
          start: {
            line: 503,
            column: 22
          },
          end: {
            line: 503,
            column: 23
          }
        },
        loc: {
          start: {
            line: 503,
            column: 34
          },
          end: {
            line: 506,
            column: 3
          }
        },
        line: 503
      },
      '45': {
        name: '(anonymous_45)',
        decl: {
          start: {
            line: 507,
            column: 20
          },
          end: {
            line: 507,
            column: 21
          }
        },
        loc: {
          start: {
            line: 507,
            column: 29
          },
          end: {
            line: 530,
            column: 3
          }
        },
        line: 507
      },
      '46': {
        name: '(anonymous_46)',
        decl: {
          start: {
            line: 533,
            column: 20
          },
          end: {
            line: 533,
            column: 21
          }
        },
        loc: {
          start: {
            line: 533,
            column: 26
          },
          end: {
            line: 548,
            column: 3
          }
        },
        line: 533
      },
      '47': {
        name: '(anonymous_47)',
        decl: {
          start: {
            line: 549,
            column: 19
          },
          end: {
            line: 549,
            column: 20
          }
        },
        loc: {
          start: {
            line: 549,
            column: 39
          },
          end: {
            line: 562,
            column: 3
          }
        },
        line: 549
      },
      '48': {
        name: '(anonymous_48)',
        decl: {
          start: {
            line: 556,
            column: 20
          },
          end: {
            line: 556,
            column: 21
          }
        },
        loc: {
          start: {
            line: 556,
            column: 28
          },
          end: {
            line: 561,
            column: 5
          }
        },
        line: 556
      },
      '49': {
        name: '(anonymous_49)',
        decl: {
          start: {
            line: 564,
            column: 24
          },
          end: {
            line: 564,
            column: 25
          }
        },
        loc: {
          start: {
            line: 564,
            column: 40
          },
          end: {
            line: 566,
            column: 3
          }
        },
        line: 564
      },
      '50': {
        name: '(anonymous_50)',
        decl: {
          start: {
            line: 567,
            column: 21
          },
          end: {
            line: 567,
            column: 22
          }
        },
        loc: {
          start: {
            line: 567,
            column: 33
          },
          end: {
            line: 578,
            column: 3
          }
        },
        line: 567
      },
      '51': {
        name: '(anonymous_51)',
        decl: {
          start: {
            line: 579,
            column: 24
          },
          end: {
            line: 579,
            column: 25
          }
        },
        loc: {
          start: {
            line: 579,
            column: 42
          },
          end: {
            line: 646,
            column: 3
          }
        },
        line: 579
      },
      '52': {
        name: '(anonymous_52)',
        decl: {
          start: {
            line: 613,
            column: 88
          },
          end: {
            line: 613,
            column: 89
          }
        },
        loc: {
          start: {
            line: 613,
            column: 97
          },
          end: {
            line: 645,
            column: 5
          }
        },
        line: 613
      },
      '53': {
        name: '(anonymous_53)',
        decl: {
          start: {
            line: 647,
            column: 21
          },
          end: {
            line: 647,
            column: 22
          }
        },
        loc: {
          start: {
            line: 647,
            column: 48
          },
          end: {
            line: 670,
            column: 3
          }
        },
        line: 647
      },
      '54': {
        name: 'geographic_mapCtrl_level',
        decl: {
          start: {
            line: 673,
            column: 9
          },
          end: {
            line: 673,
            column: 33
          }
        },
        loc: {
          start: {
            line: 673,
            column: 127
          },
          end: {
            line: 790,
            column: 1
          }
        },
        line: 673
      },
      '55': {
        name: '(anonymous_55)',
        decl: {
          start: {
            line: 721,
            column: 93
          },
          end: {
            line: 721,
            column: 94
          }
        },
        loc: {
          start: {
            line: 721,
            column: 101
          },
          end: {
            line: 739,
            column: 7
          }
        },
        line: 721
      },
      '56': {
        name: '(anonymous_56)',
        decl: {
          start: {
            line: 751,
            column: 109
          },
          end: {
            line: 751,
            column: 110
          }
        },
        loc: {
          start: {
            line: 751,
            column: 117
          },
          end: {
            line: 753,
            column: 7
          }
        },
        line: 751
      },
      '57': {
        name: '(anonymous_57)',
        decl: {
          start: {
            line: 767,
            column: 9
          },
          end: {
            line: 767,
            column: 10
          }
        },
        loc: {
          start: {
            line: 767,
            column: 22
          },
          end: {
            line: 784,
            column: 7
          }
        },
        line: 767
      },
      '58': {
        name: '(anonymous_58)',
        decl: {
          start: {
            line: 772,
            column: 91
          },
          end: {
            line: 772,
            column: 92
          }
        },
        loc: {
          start: {
            line: 772,
            column: 99
          },
          end: {
            line: 783,
            column: 9
          }
        },
        line: 772
      },
      '59': {
        name: 'geographic_mapCtrl_generate',
        decl: {
          start: {
            line: 792,
            column: 9
          },
          end: {
            line: 792,
            column: 36
          }
        },
        loc: {
          start: {
            line: 792,
            column: 130
          },
          end: {
            line: 902,
            column: 1
          }
        },
        line: 792
      },
      '60': {
        name: '(anonymous_60)',
        decl: {
          start: {
            line: 800,
            column: 14
          },
          end: {
            line: 800,
            column: 15
          }
        },
        loc: {
          start: {
            line: 800,
            column: 26
          },
          end: {
            line: 817,
            column: 7
          }
        },
        line: 800
      },
      '61': {
        name: '(anonymous_61)',
        decl: {
          start: {
            line: 802,
            column: 55
          },
          end: {
            line: 802,
            column: 56
          }
        },
        loc: {
          start: {
            line: 802,
            column: 64
          },
          end: {
            line: 811,
            column: 9
          }
        },
        line: 802
      },
      '62': {
        name: '(anonymous_62)',
        decl: {
          start: {
            line: 811,
            column: 19
          },
          end: {
            line: 811,
            column: 20
          }
        },
        loc: {
          start: {
            line: 811,
            column: 31
          },
          end: {
            line: 815,
            column: 9
          }
        },
        line: 811
      },
      '63': {
        name: '(anonymous_63)',
        decl: {
          start: {
            line: 812,
            column: 47
          },
          end: {
            line: 812,
            column: 48
          }
        },
        loc: {
          start: {
            line: 812,
            column: 52
          },
          end: {
            line: 814,
            column: 11
          }
        },
        line: 812
      },
      '64': {
        name: '(anonymous_64)',
        decl: {
          start: {
            line: 817,
            column: 9
          },
          end: {
            line: 817,
            column: 10
          }
        },
        loc: {
          start: {
            line: 817,
            column: 18
          },
          end: {
            line: 819,
            column: 7
          }
        },
        line: 817
      },
      '65': {
        name: '(anonymous_65)',
        decl: {
          start: {
            line: 832,
            column: 14
          },
          end: {
            line: 832,
            column: 15
          }
        },
        loc: {
          start: {
            line: 832,
            column: 26
          },
          end: {
            line: 850,
            column: 9
          }
        },
        line: 832
      },
      '66': {
        name: '(anonymous_66)',
        decl: {
          start: {
            line: 839,
            column: 21
          },
          end: {
            line: 839,
            column: 22
          }
        },
        loc: {
          start: {
            line: 839,
            column: 34
          },
          end: {
            line: 848,
            column: 13
          }
        },
        line: 839
      },
      '67': {
        name: '(anonymous_67)',
        decl: {
          start: {
            line: 850,
            column: 11
          },
          end: {
            line: 850,
            column: 12
          }
        },
        loc: {
          start: {
            line: 850,
            column: 17
          },
          end: {
            line: 853,
            column: 9
          }
        },
        line: 850
      },
      '68': {
        name: '(anonymous_68)',
        decl: {
          start: {
            line: 885,
            column: 89
          },
          end: {
            line: 885,
            column: 90
          }
        },
        loc: {
          start: {
            line: 885,
            column: 98
          },
          end: {
            line: 887,
            column: 7
          }
        },
        line: 885
      },
      '69': {
        name: '(anonymous_69)',
        decl: {
          start: {
            line: 887,
            column: 11
          },
          end: {
            line: 887,
            column: 12
          }
        },
        loc: {
          start: {
            line: 887,
            column: 19
          },
          end: {
            line: 891,
            column: 7
          }
        },
        line: 887
      },
      '70': {
        name: '(anonymous_70)',
        decl: {
          start: {
            line: 898,
            column: 51
          },
          end: {
            line: 898,
            column: 52
          }
        },
        loc: {
          start: {
            line: 898,
            column: 59
          },
          end: {
            line: 901,
            column: 3
          }
        },
        line: 898
      },
      '71': {
        name: 'geographic_mapCtrl_importByFile',
        decl: {
          start: {
            line: 904,
            column: 9
          },
          end: {
            line: 904,
            column: 40
          }
        },
        loc: {
          start: {
            line: 904,
            column: 94
          },
          end: {
            line: 928,
            column: 1
          }
        },
        line: 904
      },
      '72': {
        name: '(anonymous_72)',
        decl: {
          start: {
            line: 910,
            column: 23
          },
          end: {
            line: 910,
            column: 24
          }
        },
        loc: {
          start: {
            line: 910,
            column: 29
          },
          end: {
            line: 912,
            column: 3
          }
        },
        line: 910
      },
      '73': {
        name: '(anonymous_73)',
        decl: {
          start: {
            line: 914,
            column: 21
          },
          end: {
            line: 914,
            column: 22
          }
        },
        loc: {
          start: {
            line: 914,
            column: 27
          },
          end: {
            line: 922,
            column: 3
          }
        },
        line: 914
      },
      '74': {
        name: '(anonymous_74)',
        decl: {
          start: {
            line: 917,
            column: 8
          },
          end: {
            line: 917,
            column: 9
          }
        },
        loc: {
          start: {
            line: 917,
            column: 16
          },
          end: {
            line: 920,
            column: 7
          }
        },
        line: 917
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }, {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }],
        line: 6
      },
      '1': {
        loc: {
          start: {
            line: 66,
            column: 4
          },
          end: {
            line: 69,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 66,
            column: 4
          },
          end: {
            line: 69,
            column: 5
          }
        }, {
          start: {
            line: 66,
            column: 4
          },
          end: {
            line: 69,
            column: 5
          }
        }],
        line: 66
      },
      '2': {
        loc: {
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 79,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 79,
            column: 7
          }
        }, {
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 79,
            column: 7
          }
        }],
        line: 77
      },
      '3': {
        loc: {
          start: {
            line: 94,
            column: 35
          },
          end: {
            line: 94,
            column: 46
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 94,
            column: 42
          },
          end: {
            line: 94,
            column: 46
          }
        }],
        line: 94
      },
      '4': {
        loc: {
          start: {
            line: 107,
            column: 69
          },
          end: {
            line: 107,
            column: 79
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 107,
            column: 69
          },
          end: {
            line: 107,
            column: 74
          }
        }, {
          start: {
            line: 107,
            column: 78
          },
          end: {
            line: 107,
            column: 79
          }
        }],
        line: 107
      },
      '5': {
        loc: {
          start: {
            line: 109,
            column: 6
          },
          end: {
            line: 113,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 109,
            column: 6
          },
          end: {
            line: 113,
            column: 7
          }
        }, {
          start: {
            line: 109,
            column: 6
          },
          end: {
            line: 113,
            column: 7
          }
        }],
        line: 109
      },
      '6': {
        loc: {
          start: {
            line: 114,
            column: 29
          },
          end: {
            line: 114,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 114,
            column: 29
          },
          end: {
            line: 114,
            column: 34
          }
        }, {
          start: {
            line: 114,
            column: 38
          },
          end: {
            line: 114,
            column: 39
          }
        }],
        line: 114
      },
      '7': {
        loc: {
          start: {
            line: 120,
            column: 66
          },
          end: {
            line: 120,
            column: 76
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 120,
            column: 66
          },
          end: {
            line: 120,
            column: 71
          }
        }, {
          start: {
            line: 120,
            column: 75
          },
          end: {
            line: 120,
            column: 76
          }
        }],
        line: 120
      },
      '8': {
        loc: {
          start: {
            line: 122,
            column: 6
          },
          end: {
            line: 126,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 122,
            column: 6
          },
          end: {
            line: 126,
            column: 7
          }
        }, {
          start: {
            line: 122,
            column: 6
          },
          end: {
            line: 126,
            column: 7
          }
        }],
        line: 122
      },
      '9': {
        loc: {
          start: {
            line: 127,
            column: 26
          },
          end: {
            line: 127,
            column: 36
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 127,
            column: 26
          },
          end: {
            line: 127,
            column: 31
          }
        }, {
          start: {
            line: 127,
            column: 35
          },
          end: {
            line: 127,
            column: 36
          }
        }],
        line: 127
      },
      '10': {
        loc: {
          start: {
            line: 196,
            column: 34
          },
          end: {
            line: 196,
            column: 101
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 196,
            column: 65
          },
          end: {
            line: 196,
            column: 93
          }
        }, {
          start: {
            line: 196,
            column: 96
          },
          end: {
            line: 196,
            column: 101
          }
        }],
        line: 196
      },
      '11': {
        loc: {
          start: {
            line: 204,
            column: 6
          },
          end: {
            line: 215,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 204,
            column: 6
          },
          end: {
            line: 215,
            column: 7
          }
        }, {
          start: {
            line: 204,
            column: 6
          },
          end: {
            line: 215,
            column: 7
          }
        }],
        line: 204
      },
      '12': {
        loc: {
          start: {
            line: 231,
            column: 4
          },
          end: {
            line: 245,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 231,
            column: 4
          },
          end: {
            line: 245,
            column: 5
          }
        }, {
          start: {
            line: 231,
            column: 4
          },
          end: {
            line: 245,
            column: 5
          }
        }],
        line: 231
      },
      '13': {
        loc: {
          start: {
            line: 233,
            column: 6
          },
          end: {
            line: 235,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 233,
            column: 6
          },
          end: {
            line: 235,
            column: 7
          }
        }, {
          start: {
            line: 233,
            column: 6
          },
          end: {
            line: 235,
            column: 7
          }
        }],
        line: 233
      },
      '14': {
        loc: {
          start: {
            line: 236,
            column: 6
          },
          end: {
            line: 238,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 236,
            column: 6
          },
          end: {
            line: 238,
            column: 7
          }
        }, {
          start: {
            line: 236,
            column: 6
          },
          end: {
            line: 238,
            column: 7
          }
        }],
        line: 236
      },
      '15': {
        loc: {
          start: {
            line: 275,
            column: 4
          },
          end: {
            line: 278,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 275,
            column: 4
          },
          end: {
            line: 278,
            column: 5
          }
        }, {
          start: {
            line: 275,
            column: 4
          },
          end: {
            line: 278,
            column: 5
          }
        }],
        line: 275
      },
      '16': {
        loc: {
          start: {
            line: 307,
            column: 4
          },
          end: {
            line: 380,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 307,
            column: 4
          },
          end: {
            line: 380,
            column: 5
          }
        }, {
          start: {
            line: 307,
            column: 4
          },
          end: {
            line: 380,
            column: 5
          }
        }],
        line: 307
      },
      '17': {
        loc: {
          start: {
            line: 327,
            column: 11
          },
          end: {
            line: 380,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 327,
            column: 11
          },
          end: {
            line: 380,
            column: 5
          }
        }, {
          start: {
            line: 327,
            column: 11
          },
          end: {
            line: 380,
            column: 5
          }
        }],
        line: 327
      },
      '18': {
        loc: {
          start: {
            line: 342,
            column: 6
          },
          end: {
            line: 344,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 342,
            column: 6
          },
          end: {
            line: 344,
            column: 7
          }
        }, {
          start: {
            line: 342,
            column: 6
          },
          end: {
            line: 344,
            column: 7
          }
        }],
        line: 342
      },
      '19': {
        loc: {
          start: {
            line: 347,
            column: 8
          },
          end: {
            line: 356,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 347,
            column: 8
          },
          end: {
            line: 356,
            column: 9
          }
        }, {
          start: {
            line: 347,
            column: 8
          },
          end: {
            line: 356,
            column: 9
          }
        }],
        line: 347
      },
      '20': {
        loc: {
          start: {
            line: 374,
            column: 8
          },
          end: {
            line: 378,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 374,
            column: 8
          },
          end: {
            line: 378,
            column: 9
          }
        }, {
          start: {
            line: 374,
            column: 8
          },
          end: {
            line: 378,
            column: 9
          }
        }],
        line: 374
      },
      '21': {
        loc: {
          start: {
            line: 387,
            column: 6
          },
          end: {
            line: 390,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 387,
            column: 6
          },
          end: {
            line: 390,
            column: 7
          }
        }, {
          start: {
            line: 387,
            column: 6
          },
          end: {
            line: 390,
            column: 7
          }
        }],
        line: 387
      },
      '22': {
        loc: {
          start: {
            line: 393,
            column: 4
          },
          end: {
            line: 397,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 393,
            column: 4
          },
          end: {
            line: 397,
            column: 5
          }
        }, {
          start: {
            line: 393,
            column: 4
          },
          end: {
            line: 397,
            column: 5
          }
        }],
        line: 393
      },
      '23': {
        loc: {
          start: {
            line: 393,
            column: 8
          },
          end: {
            line: 393,
            column: 37
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 393,
            column: 8
          },
          end: {
            line: 393,
            column: 24
          }
        }, {
          start: {
            line: 393,
            column: 28
          },
          end: {
            line: 393,
            column: 37
          }
        }],
        line: 393
      },
      '24': {
        loc: {
          start: {
            line: 421,
            column: 6
          },
          end: {
            line: 423,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 421,
            column: 6
          },
          end: {
            line: 423,
            column: 7
          }
        }, {
          start: {
            line: 421,
            column: 6
          },
          end: {
            line: 423,
            column: 7
          }
        }],
        line: 421
      },
      '25': {
        loc: {
          start: {
            line: 437,
            column: 8
          },
          end: {
            line: 444,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 437,
            column: 8
          },
          end: {
            line: 444,
            column: 9
          }
        }, {
          start: {
            line: 437,
            column: 8
          },
          end: {
            line: 444,
            column: 9
          }
        }],
        line: 437
      },
      '26': {
        loc: {
          start: {
            line: 456,
            column: 4
          },
          end: {
            line: 458,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 456,
            column: 4
          },
          end: {
            line: 458,
            column: 5
          }
        }, {
          start: {
            line: 456,
            column: 4
          },
          end: {
            line: 458,
            column: 5
          }
        }],
        line: 456
      },
      '27': {
        loc: {
          start: {
            line: 464,
            column: 4
          },
          end: {
            line: 470,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 464,
            column: 4
          },
          end: {
            line: 470,
            column: 5
          }
        }, {
          start: {
            line: 464,
            column: 4
          },
          end: {
            line: 470,
            column: 5
          }
        }],
        line: 464
      },
      '28': {
        loc: {
          start: {
            line: 473,
            column: 6
          },
          end: {
            line: 479,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 473,
            column: 6
          },
          end: {
            line: 479,
            column: 7
          }
        }, {
          start: {
            line: 473,
            column: 6
          },
          end: {
            line: 479,
            column: 7
          }
        }],
        line: 473
      },
      '29': {
        loc: {
          start: {
            line: 484,
            column: 4
          },
          end: {
            line: 486,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 484,
            column: 4
          },
          end: {
            line: 486,
            column: 5
          }
        }, {
          start: {
            line: 484,
            column: 4
          },
          end: {
            line: 486,
            column: 5
          }
        }],
        line: 484
      },
      '30': {
        loc: {
          start: {
            line: 509,
            column: 4
          },
          end: {
            line: 528,
            column: 5
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 510,
            column: 6
          },
          end: {
            line: 512,
            column: 14
          }
        }, {
          start: {
            line: 513,
            column: 6
          },
          end: {
            line: 515,
            column: 14
          }
        }, {
          start: {
            line: 516,
            column: 6
          },
          end: {
            line: 518,
            column: 14
          }
        }, {
          start: {
            line: 519,
            column: 6
          },
          end: {
            line: 521,
            column: 14
          }
        }, {
          start: {
            line: 522,
            column: 6
          },
          end: {
            line: 524,
            column: 14
          }
        }, {
          start: {
            line: 525,
            column: 6
          },
          end: {
            line: 527,
            column: 14
          }
        }],
        line: 509
      },
      '31': {
        loc: {
          start: {
            line: 553,
            column: 17
          },
          end: {
            line: 553,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 553,
            column: 17
          },
          end: {
            line: 553,
            column: 47
          }
        }, {
          start: {
            line: 553,
            column: 51
          },
          end: {
            line: 553,
            column: 52
          }
        }],
        line: 553
      },
      '32': {
        loc: {
          start: {
            line: 553,
            column: 54
          },
          end: {
            line: 553,
            column: 89
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 553,
            column: 54
          },
          end: {
            line: 553,
            column: 84
          }
        }, {
          start: {
            line: 553,
            column: 88
          },
          end: {
            line: 553,
            column: 89
          }
        }],
        line: 553
      },
      '33': {
        loc: {
          start: {
            line: 565,
            column: 30
          },
          end: {
            line: 565,
            column: 102
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 565,
            column: 45
          },
          end: {
            line: 565,
            column: 74
          }
        }, {
          start: {
            line: 565,
            column: 77
          },
          end: {
            line: 565,
            column: 102
          }
        }],
        line: 565
      },
      '34': {
        loc: {
          start: {
            line: 569,
            column: 4
          },
          end: {
            line: 571,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 569,
            column: 4
          },
          end: {
            line: 571,
            column: 5
          }
        }, {
          start: {
            line: 569,
            column: 4
          },
          end: {
            line: 571,
            column: 5
          }
        }],
        line: 569
      },
      '35': {
        loc: {
          start: {
            line: 572,
            column: 4
          },
          end: {
            line: 576,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 572,
            column: 4
          },
          end: {
            line: 576,
            column: 5
          }
        }, {
          start: {
            line: 572,
            column: 4
          },
          end: {
            line: 576,
            column: 5
          }
        }],
        line: 572
      },
      '36': {
        loc: {
          start: {
            line: 572,
            column: 9
          },
          end: {
            line: 572,
            column: 27
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 572,
            column: 9
          },
          end: {
            line: 572,
            column: 21
          }
        }, {
          start: {
            line: 572,
            column: 25
          },
          end: {
            line: 572,
            column: 27
          }
        }],
        line: 572
      },
      '37': {
        loc: {
          start: {
            line: 573,
            column: 6
          },
          end: {
            line: 575,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 573,
            column: 6
          },
          end: {
            line: 575,
            column: 7
          }
        }, {
          start: {
            line: 573,
            column: 6
          },
          end: {
            line: 575,
            column: 7
          }
        }],
        line: 573
      },
      '38': {
        loc: {
          start: {
            line: 573,
            column: 11
          },
          end: {
            line: 573,
            column: 29
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 573,
            column: 11
          },
          end: {
            line: 573,
            column: 23
          }
        }, {
          start: {
            line: 573,
            column: 27
          },
          end: {
            line: 573,
            column: 29
          }
        }],
        line: 573
      },
      '39': {
        loc: {
          start: {
            line: 588,
            column: 4
          },
          end: {
            line: 597,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 588,
            column: 4
          },
          end: {
            line: 597,
            column: 5
          }
        }, {
          start: {
            line: 588,
            column: 4
          },
          end: {
            line: 597,
            column: 5
          }
        }],
        line: 588
      },
      '40': {
        loc: {
          start: {
            line: 590,
            column: 6
          },
          end: {
            line: 596,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 590,
            column: 6
          },
          end: {
            line: 596,
            column: 7
          }
        }, {
          start: {
            line: 590,
            column: 6
          },
          end: {
            line: 596,
            column: 7
          }
        }],
        line: 590
      },
      '41': {
        loc: {
          start: {
            line: 616,
            column: 6
          },
          end: {
            line: 618,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 616,
            column: 6
          },
          end: {
            line: 618,
            column: 7
          }
        }, {
          start: {
            line: 616,
            column: 6
          },
          end: {
            line: 618,
            column: 7
          }
        }],
        line: 616
      },
      '42': {
        loc: {
          start: {
            line: 619,
            column: 6
          },
          end: {
            line: 641,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 619,
            column: 6
          },
          end: {
            line: 641,
            column: 7
          }
        }, {
          start: {
            line: 619,
            column: 6
          },
          end: {
            line: 641,
            column: 7
          }
        }],
        line: 619
      },
      '43': {
        loc: {
          start: {
            line: 621,
            column: 8
          },
          end: {
            line: 635,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 621,
            column: 8
          },
          end: {
            line: 635,
            column: 9
          }
        }, {
          start: {
            line: 621,
            column: 8
          },
          end: {
            line: 635,
            column: 9
          }
        }],
        line: 621
      },
      '44': {
        loc: {
          start: {
            line: 627,
            column: 15
          },
          end: {
            line: 635,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 627,
            column: 15
          },
          end: {
            line: 635,
            column: 9
          }
        }, {
          start: {
            line: 627,
            column: 15
          },
          end: {
            line: 635,
            column: 9
          }
        }],
        line: 627
      },
      '45': {
        loc: {
          start: {
            line: 648,
            column: 4
          },
          end: {
            line: 650,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 648,
            column: 4
          },
          end: {
            line: 650,
            column: 5
          }
        }, {
          start: {
            line: 648,
            column: 4
          },
          end: {
            line: 650,
            column: 5
          }
        }],
        line: 648
      },
      '46': {
        loc: {
          start: {
            line: 679,
            column: 17
          },
          end: {
            line: 679,
            column: 118
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 679,
            column: 51
          },
          end: {
            line: 679,
            column: 52
          }
        }, {
          start: {
            line: 679,
            column: 55
          },
          end: {
            line: 679,
            column: 118
          }
        }],
        line: 679
      },
      '47': {
        loc: {
          start: {
            line: 690,
            column: 6
          },
          end: {
            line: 714,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 690,
            column: 6
          },
          end: {
            line: 714,
            column: 7
          }
        }, {
          start: {
            line: 690,
            column: 6
          },
          end: {
            line: 714,
            column: 7
          }
        }],
        line: 690
      },
      '48': {
        loc: {
          start: {
            line: 692,
            column: 8
          },
          end: {
            line: 696,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 692,
            column: 8
          },
          end: {
            line: 696,
            column: 9
          }
        }, {
          start: {
            line: 692,
            column: 8
          },
          end: {
            line: 696,
            column: 9
          }
        }],
        line: 692
      },
      '49': {
        loc: {
          start: {
            line: 700,
            column: 10
          },
          end: {
            line: 712,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 700,
            column: 10
          },
          end: {
            line: 712,
            column: 11
          }
        }, {
          start: {
            line: 700,
            column: 10
          },
          end: {
            line: 712,
            column: 11
          }
        }],
        line: 700
      },
      '50': {
        loc: {
          start: {
            line: 701,
            column: 12
          },
          end: {
            line: 705,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 701,
            column: 12
          },
          end: {
            line: 705,
            column: 13
          }
        }, {
          start: {
            line: 701,
            column: 12
          },
          end: {
            line: 705,
            column: 13
          }
        }],
        line: 701
      },
      '51': {
        loc: {
          start: {
            line: 706,
            column: 12
          },
          end: {
            line: 710,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 706,
            column: 12
          },
          end: {
            line: 710,
            column: 13
          }
        }, {
          start: {
            line: 706,
            column: 12
          },
          end: {
            line: 710,
            column: 13
          }
        }],
        line: 706
      },
      '52': {
        loc: {
          start: {
            line: 722,
            column: 8
          },
          end: {
            line: 728,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 722,
            column: 8
          },
          end: {
            line: 728,
            column: 9
          }
        }, {
          start: {
            line: 722,
            column: 8
          },
          end: {
            line: 728,
            column: 9
          }
        }],
        line: 722
      },
      '53': {
        loc: {
          start: {
            line: 722,
            column: 12
          },
          end: {
            line: 722,
            column: 35
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 722,
            column: 12
          },
          end: {
            line: 722,
            column: 21
          }
        }, {
          start: {
            line: 722,
            column: 25
          },
          end: {
            line: 722,
            column: 35
          }
        }],
        line: 722
      },
      '54': {
        loc: {
          start: {
            line: 729,
            column: 8
          },
          end: {
            line: 735,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 729,
            column: 8
          },
          end: {
            line: 735,
            column: 9
          }
        }, {
          start: {
            line: 729,
            column: 8
          },
          end: {
            line: 735,
            column: 9
          }
        }],
        line: 729
      },
      '55': {
        loc: {
          start: {
            line: 737,
            column: 42
          },
          end: {
            line: 737,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 737,
            column: 42
          },
          end: {
            line: 737,
            column: 49
          }
        }, {
          start: {
            line: 737,
            column: 53
          },
          end: {
            line: 737,
            column: 57
          }
        }],
        line: 737
      },
      '56': {
        loc: {
          start: {
            line: 742,
            column: 6
          },
          end: {
            line: 744,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 742,
            column: 6
          },
          end: {
            line: 744,
            column: 7
          }
        }, {
          start: {
            line: 742,
            column: 6
          },
          end: {
            line: 744,
            column: 7
          }
        }],
        line: 742
      },
      '57': {
        loc: {
          start: {
            line: 747,
            column: 8
          },
          end: {
            line: 749,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 747,
            column: 8
          },
          end: {
            line: 749,
            column: 9
          }
        }, {
          start: {
            line: 747,
            column: 8
          },
          end: {
            line: 749,
            column: 9
          }
        }],
        line: 747
      },
      '58': {
        loc: {
          start: {
            line: 768,
            column: 8
          },
          end: {
            line: 770,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 768,
            column: 8
          },
          end: {
            line: 770,
            column: 9
          }
        }, {
          start: {
            line: 768,
            column: 8
          },
          end: {
            line: 770,
            column: 9
          }
        }],
        line: 768
      },
      '59': {
        loc: {
          start: {
            line: 774,
            column: 10
          },
          end: {
            line: 780,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 774,
            column: 10
          },
          end: {
            line: 780,
            column: 11
          }
        }, {
          start: {
            line: 774,
            column: 10
          },
          end: {
            line: 780,
            column: 11
          }
        }],
        line: 774
      },
      '60': {
        loc: {
          start: {
            line: 813,
            column: 48
          },
          end: {
            line: 813,
            column: 63
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 813,
            column: 48
          },
          end: {
            line: 813,
            column: 57
          }
        }, {
          start: {
            line: 813,
            column: 61
          },
          end: {
            line: 813,
            column: 63
          }
        }],
        line: 813
      },
      '61': {
        loc: {
          start: {
            line: 821,
            column: 33
          },
          end: {
            line: 821,
            column: 47
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 821,
            column: 45
          },
          end: {
            line: 821,
            column: 47
          }
        }],
        line: 821
      },
      '62': {
        loc: {
          start: {
            line: 823,
            column: 6
          },
          end: {
            line: 825,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 823,
            column: 6
          },
          end: {
            line: 825,
            column: 7
          }
        }, {
          start: {
            line: 823,
            column: 6
          },
          end: {
            line: 825,
            column: 7
          }
        }],
        line: 823
      },
      '63': {
        loc: {
          start: {
            line: 826,
            column: 6
          },
          end: {
            line: 829,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 826,
            column: 6
          },
          end: {
            line: 829,
            column: 7
          }
        }, {
          start: {
            line: 826,
            column: 6
          },
          end: {
            line: 829,
            column: 7
          }
        }],
        line: 826
      },
      '64': {
        loc: {
          start: {
            line: 834,
            column: 10
          },
          end: {
            line: 837,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 834,
            column: 10
          },
          end: {
            line: 837,
            column: 11
          }
        }, {
          start: {
            line: 834,
            column: 10
          },
          end: {
            line: 837,
            column: 11
          }
        }],
        line: 834
      },
      '65': {
        loc: {
          start: {
            line: 875,
            column: 19
          },
          end: {
            line: 875,
            column: 34
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 875,
            column: 19
          },
          end: {
            line: 875,
            column: 29
          }
        }, {
          start: {
            line: 875,
            column: 33
          },
          end: {
            line: 875,
            column: 34
          }
        }],
        line: 875
      },
      '66': {
        loc: {
          start: {
            line: 916,
            column: 85
          },
          end: {
            line: 916,
            column: 152
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 916,
            column: 110
          },
          end: {
            line: 916,
            column: 147
          }
        }, {
          start: {
            line: 916,
            column: 150
          },
          end: {
            line: 916,
            column: 152
          }
        }],
        line: 916
      },
      '67': {
        loc: {
          start: {
            line: 924,
            column: 2
          },
          end: {
            line: 927,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 924,
            column: 2
          },
          end: {
            line: 927,
            column: 3
          }
        }, {
          start: {
            line: 924,
            column: 2
          },
          end: {
            line: 927,
            column: 3
          }
        }],
        line: 924
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0,
      '248': 0,
      '249': 0,
      '250': 0,
      '251': 0,
      '252': 0,
      '253': 0,
      '254': 0,
      '255': 0,
      '256': 0,
      '257': 0,
      '258': 0,
      '259': 0,
      '260': 0,
      '261': 0,
      '262': 0,
      '263': 0,
      '264': 0,
      '265': 0,
      '266': 0,
      '267': 0,
      '268': 0,
      '269': 0,
      '270': 0,
      '271': 0,
      '272': 0,
      '273': 0,
      '274': 0,
      '275': 0,
      '276': 0,
      '277': 0,
      '278': 0,
      '279': 0,
      '280': 0,
      '281': 0,
      '282': 0,
      '283': 0,
      '284': 0,
      '285': 0,
      '286': 0,
      '287': 0,
      '288': 0,
      '289': 0,
      '290': 0,
      '291': 0,
      '292': 0,
      '293': 0,
      '294': 0,
      '295': 0,
      '296': 0,
      '297': 0,
      '298': 0,
      '299': 0,
      '300': 0,
      '301': 0,
      '302': 0,
      '303': 0,
      '304': 0,
      '305': 0,
      '306': 0,
      '307': 0,
      '308': 0,
      '309': 0,
      '310': 0,
      '311': 0,
      '312': 0,
      '313': 0,
      '314': 0,
      '315': 0,
      '316': 0,
      '317': 0,
      '318': 0,
      '319': 0,
      '320': 0,
      '321': 0,
      '322': 0,
      '323': 0,
      '324': 0,
      '325': 0,
      '326': 0,
      '327': 0,
      '328': 0,
      '329': 0,
      '330': 0,
      '331': 0,
      '332': 0,
      '333': 0,
      '334': 0,
      '335': 0,
      '336': 0,
      '337': 0,
      '338': 0,
      '339': 0,
      '340': 0,
      '341': 0,
      '342': 0,
      '343': 0,
      '344': 0,
      '345': 0,
      '346': 0,
      '347': 0,
      '348': 0,
      '349': 0,
      '350': 0,
      '351': 0,
      '352': 0,
      '353': 0,
      '354': 0,
      '355': 0,
      '356': 0,
      '357': 0,
      '358': 0,
      '359': 0,
      '360': 0,
      '361': 0,
      '362': 0,
      '363': 0,
      '364': 0,
      '365': 0,
      '366': 0,
      '367': 0,
      '368': 0,
      '369': 0,
      '370': 0,
      '371': 0,
      '372': 0,
      '373': 0,
      '374': 0,
      '375': 0,
      '376': 0,
      '377': 0,
      '378': 0,
      '379': 0,
      '380': 0,
      '381': 0,
      '382': 0,
      '383': 0,
      '384': 0,
      '385': 0,
      '386': 0,
      '387': 0,
      '388': 0,
      '389': 0,
      '390': 0,
      '391': 0,
      '392': 0,
      '393': 0,
      '394': 0,
      '395': 0,
      '396': 0,
      '397': 0,
      '398': 0,
      '399': 0,
      '400': 0,
      '401': 0,
      '402': 0,
      '403': 0,
      '404': 0,
      '405': 0,
      '406': 0,
      '407': 0,
      '408': 0,
      '409': 0,
      '410': 0,
      '411': 0,
      '412': 0,
      '413': 0,
      '414': 0,
      '415': 0,
      '416': 0,
      '417': 0,
      '418': 0,
      '419': 0,
      '420': 0,
      '421': 0,
      '422': 0,
      '423': 0,
      '424': 0,
      '425': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0, 0, 0, 0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0],
      '58': [0, 0],
      '59': [0, 0],
      '60': [0, 0],
      '61': [0],
      '62': [0, 0],
      '63': [0, 0],
      '64': [0, 0],
      '65': [0, 0],
      '66': [0, 0],
      '67': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2ff0bzynse.s[0]++;
/**
 * Geographic Map - controller
 */
angular.module('app').controller('geographic_mapCtrl', function ($scope, SweetAlert, $deltaLocation, $window, $deltaGeoLevel, FileUploader, $rootScope, $translate, $CRUDService, $otherHttpService, $filter, $uibModal, $deltahttp) {
  cov_2ff0bzynse.f[0]++;
  cov_2ff0bzynse.s[1]++;

  $rootScope.processPageRight('1_4');
  cov_2ff0bzynse.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_2ff0bzynse.b[0][0]++;
    cov_2ff0bzynse.s[3]++;

    return;
  } else {
    cov_2ff0bzynse.b[0][1]++;
  }
  // $deltaLocation.updateEditOsmId();
  cov_2ff0bzynse.s[4]++;
  $scope.__labels = {
    EDIT: $translate.instant('COMMON.EDIT'),
    DELETE: $translate.instant('COMMON.DELETE'),
    ADD_SUB: $translate.instant('GEOGRAPHIC_PLAN.ADD')
  };
  cov_2ff0bzynse.s[5]++;
  $deltaLocation.addController($scope);
  cov_2ff0bzynse.s[6]++;
  $scope.PATH = 'Periode';
  var EXPAND = (cov_2ff0bzynse.s[7]++, 'fa-angle-down');
  var COLLAPSE = (cov_2ff0bzynse.s[8]++, 'fa-angle-right');
  var LEAF = (cov_2ff0bzynse.s[9]++, 'fa-circle');
  var LOADING = (cov_2ff0bzynse.s[10]++, 'fa-spinner');
  cov_2ff0bzynse.s[11]++;
  $scope.idScope = $scope.$id;
  cov_2ff0bzynse.s[12]++;
  $scope.editOption = false;

  // Champ Personnalisé
  cov_2ff0bzynse.s[13]++;
  $scope.PATHCHAMP = 'ChampsPerso';
  cov_2ff0bzynse.s[14]++;
  $scope.PATHNG = 'NiveauGeo';
  cov_2ff0bzynse.s[15]++;
  $scope.PATHPG = 'Localites';
  cov_2ff0bzynse.s[16]++;
  $scope.show_champsperso = false;
  cov_2ff0bzynse.s[17]++;
  $scope.show_geographiclevel = false;
  cov_2ff0bzynse.s[18]++;
  $scope.isloading = true;
  cov_2ff0bzynse.s[19]++;
  $scope.listLocalite = [];
  cov_2ff0bzynse.s[20]++;
  $scope.isloadingCP = true;
  cov_2ff0bzynse.s[21]++;
  $scope.refreshLocation = null;
  cov_2ff0bzynse.s[22]++;
  $scope.printData = function () {
    cov_2ff0bzynse.f[1]++;
    cov_2ff0bzynse.s[23]++;

    $rootScope.printReport({
      link: $scope.PATHPG,
      params: { get: 'print' },
      title: $translate.instant('MENU_SHORT.P_G')
    });
  };

  // Champs Personnalisé
  cov_2ff0bzynse.s[24]++;
  $scope.listChampPerso = [];
  cov_2ff0bzynse.s[25]++;
  $scope.listAggregation = [{
    label: $translate.instant('GEOGRAPHIC_PLAN.SUM'),
    id: '1'
  }, {
    label: $translate.instant('GEOGRAPHIC_PLAN.MOY'),
    id: '2'
  }, {
    label: $translate.instant('GEOGRAPHIC_PLAN.MAXI'),
    id: '3'
  }, {
    label: $translate.instant('GEOGRAPHIC_PLAN.MINI'),
    id: '4'
  }];
  cov_2ff0bzynse.s[26]++;
  $scope.itemSelectedChampPerso = {};
  cov_2ff0bzynse.s[27]++;
  $scope.hidesubPanel = function () {
    cov_2ff0bzynse.f[2]++;
    cov_2ff0bzynse.s[28]++;

    $scope.show_champsperso = false;
    cov_2ff0bzynse.s[29]++;
    $scope.show_geographiclevel = false;
    cov_2ff0bzynse.s[30]++;
    $scope.show_geographicplan = false;
  };
  cov_2ff0bzynse.s[31]++;
  $scope.getAllChampPerso = function () {
    cov_2ff0bzynse.f[3]++;
    cov_2ff0bzynse.s[32]++;

    if ($scope.champPerso) {
      cov_2ff0bzynse.b[1][0]++;
      cov_2ff0bzynse.s[33]++;

      $scope.champPerso.isloading = true;
    } else {
      cov_2ff0bzynse.b[1][1]++;
    }
    cov_2ff0bzynse.s[34]++;
    $CRUDService.getAll($scope.PATHCHAMP, { get: 'all' }, function (data) {
      cov_2ff0bzynse.f[4]++;
      cov_2ff0bzynse.s[35]++;

      for (var i = 0; i < data.length; i++) {
        cov_2ff0bzynse.s[36]++;

        data[i].hideEditor = true;
        cov_2ff0bzynse.s[37]++;
        data[i].aggregation = $scope.listAggregation[data[i].AGGRE - 1];
      }
      cov_2ff0bzynse.s[38]++;
      $scope.listChampPerso = data;

      cov_2ff0bzynse.s[39]++;
      if ($scope.champPerso) {
        cov_2ff0bzynse.b[2][0]++;
        cov_2ff0bzynse.s[40]++;

        $scope.champPerso.isloading = false;
      } else {
        cov_2ff0bzynse.b[2][1]++;
      }
    });
  };

  cov_2ff0bzynse.s[41]++;
  $scope.generateData = function () {
    cov_2ff0bzynse.f[5]++;
    cov_2ff0bzynse.s[42]++;

    $uibModal.open({
      templateUrl: 'app/views/geographic_map/generate.html',
      controller: geographic_mapCtrl_generate,
      scope: $scope,
      // size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_2ff0bzynse.s[43]++;
  $scope.selectRefreshLocation = function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (cov_2ff0bzynse.b[3][0]++, null);
    cov_2ff0bzynse.f[6]++;
    cov_2ff0bzynse.s[44]++;

    $scope.refreshLocation = item;
  };

  cov_2ff0bzynse.s[45]++;
  $scope.actionLabel = {
    ADD: $translate.instant('GEOGRAPHIC_PLAN.ADD__'),
    LEVEL: $translate.instant('MENU.S_G'),
    CUSTOM: $translate.instant('GEOGRAPHIC_PLAN.CHAMPPERSO'),
    IMPORT: $translate.instant('GEOGRAPHIC_PLAN.IMPORT'),
    EXPORT: $translate.instant('GEOGRAPHIC_PLAN.EXPORT')
  };
  cov_2ff0bzynse.s[46]++;
  $scope.geoReferencing = function (index) {
    cov_2ff0bzynse.f[7]++;
    cov_2ff0bzynse.s[47]++;

    $scope.isloadingNG = true;
    cov_2ff0bzynse.s[48]++;
    $CRUDService.getAll($scope.PATHPG, { get: 'geo_reference', level: (cov_2ff0bzynse.b[4][0]++, index) || (cov_2ff0bzynse.b[4][1]++, 1) }, function (data) {
      cov_2ff0bzynse.f[8]++;

      var newIndex = (cov_2ff0bzynse.s[49]++, parseInt(data));
      cov_2ff0bzynse.s[50]++;
      if (newIndex === 0) {
        cov_2ff0bzynse.b[5][0]++;
        cov_2ff0bzynse.s[51]++;

        $scope.isloadingNG = false;
        cov_2ff0bzynse.s[52]++;
        $scope.refreshLocalities();
        cov_2ff0bzynse.s[53]++;
        return;
      } else {
        cov_2ff0bzynse.b[5][1]++;
      }
      cov_2ff0bzynse.s[54]++;
      $scope.geoReferencing(((cov_2ff0bzynse.b[6][0]++, index) || (cov_2ff0bzynse.b[6][1]++, 1)) + 1);
    });
  };

  cov_2ff0bzynse.s[55]++;
  $scope.geoPosition = function (index) {
    cov_2ff0bzynse.f[9]++;
    cov_2ff0bzynse.s[56]++;

    $scope.isloadingNG = true;
    cov_2ff0bzynse.s[57]++;
    $CRUDService.getAll($scope.PATHPG, { get: 'geo_arc_id', level: (cov_2ff0bzynse.b[7][0]++, index) || (cov_2ff0bzynse.b[7][1]++, 1) }, function (data) {
      cov_2ff0bzynse.f[10]++;

      var newIndex = (cov_2ff0bzynse.s[58]++, parseInt(data));
      cov_2ff0bzynse.s[59]++;
      if (newIndex === 0) {
        cov_2ff0bzynse.b[8][0]++;
        cov_2ff0bzynse.s[60]++;

        $scope.isloadingNG = false;
        cov_2ff0bzynse.s[61]++;
        $scope.refreshLocalities();
        cov_2ff0bzynse.s[62]++;
        return;
      } else {
        cov_2ff0bzynse.b[8][1]++;
      }
      cov_2ff0bzynse.s[63]++;
      $scope.geoPosition(((cov_2ff0bzynse.b[9][0]++, index) || (cov_2ff0bzynse.b[9][1]++, 1)) + 1);
    });
  };
  cov_2ff0bzynse.s[64]++;
  $scope.openGeoLevel = function () {
    cov_2ff0bzynse.f[11]++;
    cov_2ff0bzynse.s[65]++;

    $uibModal.open({
      templateUrl: 'app/views/geographic_map/level.html',
      controller: geographic_mapCtrl_level,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };
  cov_2ff0bzynse.s[66]++;
  $scope.openPlanGeoEdit = function () {
    cov_2ff0bzynse.f[12]++;
    cov_2ff0bzynse.s[67]++;

    $uibModal.open({
      templateUrl: 'app/views/geographic_map/edit.html',
      controller: geographic_mapCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };
  cov_2ff0bzynse.s[68]++;
  $scope.selectItem = function (item) {
    cov_2ff0bzynse.f[13]++;
    cov_2ff0bzynse.s[69]++;

    $scope.itemSelectedChampPerso = {
      id: item.id,
      LIBELLE_CHAMPSPERSO: item.LIBELLE_CHAMPSPERSO,
      ID_PROJET: item.ID_PROJET,
      AGGRE: item.AGGRE,
      LEVEL_CHAMPSPERSO: item.LEVEL_CHAMPSPERSO,
      CODE_NG: item.CODE_NG
    };
  };
  // Niveau géographique
  cov_2ff0bzynse.s[70]++;
  $scope.listGeoLevel = [];
  cov_2ff0bzynse.s[71]++;
  $scope.itemSelectedNG = {};
  cov_2ff0bzynse.s[72]++;
  $scope.hideAdd = true;
  cov_2ff0bzynse.s[73]++;
  $scope.isloadingNG = true;
  cov_2ff0bzynse.s[74]++;
  $scope.maxLength = 30;
  cov_2ff0bzynse.s[75]++;
  $scope.minLength = 0;
  cov_2ff0bzynse.s[76]++;
  $scope.customValuesList = [];

  // $scope.customValuesList
  cov_2ff0bzynse.s[77]++;
  $scope.export = function () {
    cov_2ff0bzynse.f[14]++;
    cov_2ff0bzynse.s[78]++;

    $scope.isloadingNG = true;
    cov_2ff0bzynse.s[79]++;
    $CRUDService.getAll($scope.PATHPG, { get: 'export' }, function (data) {
      cov_2ff0bzynse.f[15]++;
      cov_2ff0bzynse.s[80]++;

      $scope.isloadingNG = false;
      cov_2ff0bzynse.s[81]++;
      $window.open($deltahttp.getReporting(data), '_blank');
    });
  };

  cov_2ff0bzynse.s[82]++;
  $scope.getAllNG = function () {
    cov_2ff0bzynse.f[16]++;
    cov_2ff0bzynse.s[83]++;

    $scope.isloadingNG = true;
    cov_2ff0bzynse.s[84]++;
    $CRUDService.getAll($scope.PATHNG, { get: 'all' }, function (data) {
      cov_2ff0bzynse.f[17]++;
      cov_2ff0bzynse.s[85]++;

      for (var i = 0; i < data.length; i++) {
        cov_2ff0bzynse.s[86]++;

        data[i].hideEditor = true;
        cov_2ff0bzynse.s[87]++;
        data[i].LONG_NG = eval(data[i].LONG_NG);
      }
      cov_2ff0bzynse.s[88]++;
      $scope.listGeoLevel = data;
      cov_2ff0bzynse.s[89]++;
      $scope.isloadingNG = false;
      cov_2ff0bzynse.s[90]++;
      $deltaGeoLevel.clearData();
    });
  };
  cov_2ff0bzynse.s[91]++;
  $scope.canAddNewNG = function () {
    cov_2ff0bzynse.f[18]++;
    cov_2ff0bzynse.s[92]++;

    $otherHttpService.post($scope.PATHNG, { action: 'canAddNew' }, function (data) {
      cov_2ff0bzynse.f[19]++;
      cov_2ff0bzynse.s[93]++;

      $scope.hideAdd = data != 0;
    });
  };
  cov_2ff0bzynse.s[94]++;
  $scope.showNiveauGeo = function (value) {
    cov_2ff0bzynse.f[20]++;
    cov_2ff0bzynse.s[95]++;

    $scope.show_geographiclevel = angular.isUndefined(value) ? (cov_2ff0bzynse.b[10][0]++, !$scope.show_geographiclevel) : (cov_2ff0bzynse.b[10][1]++, value);
    cov_2ff0bzynse.s[96]++;
    $scope.show_champsperso = false;
  };
  cov_2ff0bzynse.s[97]++;
  $scope.loadingUpload = false;
  cov_2ff0bzynse.s[98]++;
  $scope.uploadProgress = 0;
  cov_2ff0bzynse.s[99]++;
  $scope.updateByFile = function (l) {
    cov_2ff0bzynse.f[21]++;
    cov_2ff0bzynse.s[100]++;

    $scope.loadingUploadLevel = l;
    cov_2ff0bzynse.s[101]++;
    $CRUDService.save($scope.PATHPG, { action: 'updateByFile', l: l }, function (data) {
      cov_2ff0bzynse.f[22]++;
      cov_2ff0bzynse.s[102]++;

      if (data == 0) {
        cov_2ff0bzynse.b[11][0]++;
        cov_2ff0bzynse.s[103]++;

        $scope.getAllChampPerso();
        cov_2ff0bzynse.s[104]++;
        $scope.getAllNG();
        cov_2ff0bzynse.s[105]++;
        $deltaLocation.removeAllData();
        cov_2ff0bzynse.s[106]++;
        $deltaLocation.addController($scope);
        // $scope.loadingData_bksb();
        cov_2ff0bzynse.s[107]++;
        $scope.loadingUpload = false;
        cov_2ff0bzynse.s[108]++;
        $scope.loadingUploadLevel = 0;
        cov_2ff0bzynse.s[109]++;
        $deltaLocation.updateEditOsmId();
      } else {
        cov_2ff0bzynse.b[11][1]++;
        cov_2ff0bzynse.s[110]++;

        $scope.updateByFile(l + 1);
      }
    });
  };
  // uploadfile
  var uploader = (cov_2ff0bzynse.s[111]++, $scope.uploader = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadcsvFile&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + $scope.PATHNG,
    alias: 'fileCVSPlanGeo',
    removeAfterUpload: true
  }));
  cov_2ff0bzynse.s[112]++;
  uploader.onAfterAddingFile = function (fileItem) {
    cov_2ff0bzynse.f[23]++;
    cov_2ff0bzynse.s[113]++;

    $scope.loadingUpload = true;
    cov_2ff0bzynse.s[114]++;
    $scope.uploadProgress = 0;
    cov_2ff0bzynse.s[115]++;
    fileItem.upload();
  };
  cov_2ff0bzynse.s[116]++;
  uploader.onSuccessItem = function (item, response, status, headers) {
    cov_2ff0bzynse.f[24]++;
    cov_2ff0bzynse.s[117]++;

    if (response.f == '') {
      cov_2ff0bzynse.b[12][0]++;

      var labelError = (cov_2ff0bzynse.s[118]++, 'GEOGRAPHIC_PLAN.ERROR_STRUC');
      cov_2ff0bzynse.s[119]++;
      if (response.s == 1) {
        cov_2ff0bzynse.b[13][0]++;
        cov_2ff0bzynse.s[120]++;

        labelError = 'GEOGRAPHIC_PLAN.ERROR_FORMAT';
      } else {
        cov_2ff0bzynse.b[13][1]++;
      }
      cov_2ff0bzynse.s[121]++;
      if (response.s == 2) {
        cov_2ff0bzynse.b[14][0]++;
        cov_2ff0bzynse.s[122]++;

        labelError = 'GEOGRAPHIC_PLAN.ERROR_CANADD';
      } else {
        cov_2ff0bzynse.b[14][1]++;
      }
      cov_2ff0bzynse.s[123]++;
      SweetAlert.swal({
        title: $translate.instant('COMMON.ERROR_'),
        text: $translate.instant(labelError)
      });
      cov_2ff0bzynse.s[124]++;
      $scope.loadingUpload = false;
      cov_2ff0bzynse.s[125]++;
      return;
    } else {
      cov_2ff0bzynse.b[12][1]++;
    }
    cov_2ff0bzynse.s[126]++;
    $CRUDService.save($scope.PATHNG, { action: 'miseAJourNgFile', label: $translate.instant('GEOGRAPHIC_PLAN.LEVEL') }, function (data) {
      cov_2ff0bzynse.f[25]++;
      cov_2ff0bzynse.s[127]++;

      $scope.updateByFile(1);
    });
  };
  cov_2ff0bzynse.s[128]++;
  uploader.onErrorItem = function (item, response, status, headers) {
    cov_2ff0bzynse.f[26]++;
    cov_2ff0bzynse.s[129]++;

    $scope.loadingUpload = false;
  };
  cov_2ff0bzynse.s[130]++;
  uploader.onProgressItem = function (item, progress) {
    cov_2ff0bzynse.f[27]++;
    cov_2ff0bzynse.s[131]++;

    $scope.uploadProgress = progress;
  };

  // upload shapefile or geojson
  var uploaderShapefile = (cov_2ff0bzynse.s[132]++, $scope.uploaderShapefile = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=load_location_file&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + $scope.PATHPG,
    alias: 'filePlanGeo',
    removeAfterUpload: true,
    formData: [{ enctype: 'multipart/form-data' }]
  }));
  cov_2ff0bzynse.s[133]++;
  uploaderShapefile.onAfterAddingFile = function (fileItem) {
    cov_2ff0bzynse.f[28]++;
    cov_2ff0bzynse.s[134]++;

    fileItem.url = $CRUDService.getServerAdress() + 'web_server.php?action=load_location_file&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + $scope.PATHPG;
    cov_2ff0bzynse.s[135]++;
    fileItem.headers = $CRUDService.getHeader();
    cov_2ff0bzynse.s[136]++;
    $scope.loadingUpload = true;
    cov_2ff0bzynse.s[137]++;
    $scope.uploadProgress = 0;
    cov_2ff0bzynse.s[138]++;
    fileItem.upload();
  };
  cov_2ff0bzynse.s[139]++;
  uploaderShapefile.onSuccessItem = function (item, response, status, headers) {
    cov_2ff0bzynse.f[29]++;
    cov_2ff0bzynse.s[140]++;

    $scope.loadingUpload = false;

    cov_2ff0bzynse.s[141]++;
    if (response.error != 0) {
      cov_2ff0bzynse.b[15][0]++;
      cov_2ff0bzynse.s[142]++;

      // display error
      return;
    } else {
      cov_2ff0bzynse.b[15][1]++;
    }
    cov_2ff0bzynse.s[143]++;
    $scope.importByFile = {
      properties: response.properties
    };
    cov_2ff0bzynse.s[144]++;
    $uibModal.open({
      templateUrl: 'app/views/geographic_map/import_file.html',
      controller: geographic_mapCtrl_importByFile,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
    // open modal for selected name option
  };
  cov_2ff0bzynse.s[145]++;
  uploaderShapefile.onErrorItem = function (item, response, status, headers) {
    cov_2ff0bzynse.f[30]++;
    cov_2ff0bzynse.s[146]++;

    $scope.loadingUpload = false;
  };
  cov_2ff0bzynse.s[147]++;
  uploaderShapefile.onProgressItem = function (item, progress) {
    cov_2ff0bzynse.f[31]++;
    cov_2ff0bzynse.s[148]++;

    $scope.uploadProgress = progress;
  };

  // Plan géographique
  cov_2ff0bzynse.s[149]++;
  $scope.planItem = {};

  cov_2ff0bzynse.s[150]++;
  $scope.edit = function (item, fils, right) {
    cov_2ff0bzynse.f[32]++;

    var el = (cov_2ff0bzynse.s[151]++, {});
    // scope.hidesubPanel();
    cov_2ff0bzynse.s[152]++;
    $scope.editOption = true;
    // $scope.show_geographicplan=true;
    cov_2ff0bzynse.s[153]++;
    if (!item) {
      cov_2ff0bzynse.b[16][0]++;
      cov_2ff0bzynse.s[154]++;
      // Ajouter first level
      el = {
        CARAC_L: '',
        CODE_LC: '',
        FIXE: '',
        NG: $scope.listGeoLevel[0],
        CODE_LP: null,
        CODE_NG: $scope.listGeoLevel[0].id,
        Localisation_x: 0,
        Localisation_y: 0,
        DESCRIPTION_L: '',

        expanded: false,
        id: 0,
        leaf: $scope.listGeoLevel.length == 1
      };
      cov_2ff0bzynse.s[155]++;
      $scope.editParam = {
        begin: el.FIXE,
        value: $scope.listGeoLevel[0].LONG_NG
      };
    } else {
        cov_2ff0bzynse.b[16][1]++;
        cov_2ff0bzynse.s[156]++;
        if (fils) {
          cov_2ff0bzynse.b[17][0]++;
          cov_2ff0bzynse.s[157]++;
          // add fils
          el = {
            CARAC_L: '',
            CODE_LC: item.CODE_LC,
            FIXE: item.CODE_LC,
            CODE_LP: item.id,
            Localisation_x: item.Localisation_x,
            Localisation_y: item.Localisation_y,

            CODE_NG: null,
            DESCRIPTION_L: '',

            expanded: false,
            id: 0
          };
          cov_2ff0bzynse.s[158]++;
          if (right) {
            cov_2ff0bzynse.b[18][0]++;
            cov_2ff0bzynse.s[159]++;

            el = Object.assign(el, right);
          } else {
            cov_2ff0bzynse.b[18][1]++;
          }

          cov_2ff0bzynse.s[160]++;
          for (var i = 0; i < $scope.listGeoLevel.length - 1; i++) {
            cov_2ff0bzynse.s[161]++;

            if ($scope.listGeoLevel[i].id == item.CODE_NG) {
              cov_2ff0bzynse.b[19][0]++;
              cov_2ff0bzynse.s[162]++;

              el.CODE_NG = $scope.listGeoLevel[i + 1].id;
              cov_2ff0bzynse.s[163]++;
              el.NG = $scope.listGeoLevel[i + 1];
              cov_2ff0bzynse.s[164]++;
              el.leaf = i + 2 == $scope.listGeoLevel.length;
              cov_2ff0bzynse.s[165]++;
              $scope.editParam = {
                begin: el.FIXE,
                value: $scope.listGeoLevel[i + 1].LONG_NG
              };
              cov_2ff0bzynse.s[166]++;
              break;
            } else {
              cov_2ff0bzynse.b[19][1]++;
            }
          }
        } else {
          cov_2ff0bzynse.b[17][1]++;
          cov_2ff0bzynse.s[167]++;
          // Update
          el = {
            CARAC_L: item.CARAC_L,
            CODE_LC: item.CODE_LC,
            FIXE: item.CODE_LC,
            CODE_LP: item.CODE_LP,
            CODE_NG: item.CODE_NG,
            DESCRIPTION_L: item.DESCRIPTION_L,
            Localisation_x: item.Localisation_x,
            Localisation_y: item.Localisation_y,
            UID_ARC: item.UID_ARC,

            expanded: item.expanded,
            id: item.id
          };
          cov_2ff0bzynse.s[168]++;
          for (var _i = 0; _i < $scope.listGeoLevel.length; _i++) {
            cov_2ff0bzynse.s[169]++;

            if ($scope.listGeoLevel[_i].id == item.CODE_NG) {
              cov_2ff0bzynse.b[20][0]++;
              cov_2ff0bzynse.s[170]++;

              el.NG = $scope.listGeoLevel[_i];
              cov_2ff0bzynse.s[171]++;
              el.leaf = _i + 1 == $scope.listGeoLevel.length;
              cov_2ff0bzynse.s[172]++;
              break;
            } else {
              cov_2ff0bzynse.b[20][1]++;
            }
          }
        }
      }cov_2ff0bzynse.s[173]++;
    el.champs = [];

    cov_2ff0bzynse.s[174]++;
    $scope.planItem = el;

    cov_2ff0bzynse.s[175]++;
    $scope.levelEditSelected = $scope.listGeoLevel[0];
    cov_2ff0bzynse.s[176]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $scope.listGeoLevel[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var level = _step.value;
        cov_2ff0bzynse.s[177]++;

        if (level.id == $scope.planItem.CODE_NG) {
          cov_2ff0bzynse.b[21][0]++;
          cov_2ff0bzynse.s[178]++;

          $scope.levelEditSelected = level;
          cov_2ff0bzynse.s[179]++;
          break;
        } else {
          cov_2ff0bzynse.b[21][1]++;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_2ff0bzynse.s[180]++;
    if ((cov_2ff0bzynse.b[23][0]++, el.CODE_LC == '') || (cov_2ff0bzynse.b[23][1]++, el.id > 0)) {
      cov_2ff0bzynse.b[22][0]++;
      cov_2ff0bzynse.s[181]++;

      $scope.REGEX = '';
    } else {
      cov_2ff0bzynse.b[22][1]++;
      cov_2ff0bzynse.s[182]++;

      $scope.REGEX = new RegExp('^' + $scope.planItem.CODE_LC);
    }
    cov_2ff0bzynse.s[183]++;
    $uibModal.open({
      templateUrl: 'app/views/geographic_map/edit.html',
      controller: geographic_mapCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };
  cov_2ff0bzynse.s[184]++;
  $scope.deleteItem = function (item) {
    cov_2ff0bzynse.f[33]++;

    var lasticone = (cov_2ff0bzynse.s[185]++, item.icon);
    cov_2ff0bzynse.s[186]++;
    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("GEOGRAPHIC_PLAN.CONFIRM_DELETE_LOCALTION"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_2ff0bzynse.f[34]++;
      cov_2ff0bzynse.s[187]++;

      if (isconfirm) {
        cov_2ff0bzynse.b[24][0]++;
        cov_2ff0bzynse.s[188]++;

        return;
      } else {
        cov_2ff0bzynse.b[24][1]++;
      }
      cov_2ff0bzynse.s[189]++;
      item.icon = LOADING;
      var validateItem = (cov_2ff0bzynse.s[190]++, {
        CARAC_L: item.CARAC_L,
        CODE_LC: item.CODE_LC,
        CODE_LP: item.CODE_LP,
        CODE_NG: item.CODE_NG,
        Localisation_x: item.Localisation_x,
        Localisation_y: item.Localisation_y,
        DESCRIPTION_L: item.DESCRIPTION_L,
        id: item.id
      });
      cov_2ff0bzynse.s[191]++;
      $CRUDService.delet($scope.PATHPG, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_2ff0bzynse.f[35]++;
        cov_2ff0bzynse.s[192]++;

        item.icon = lasticone;
        cov_2ff0bzynse.s[193]++;
        if (data < 0) {
          cov_2ff0bzynse.b[25][0]++;
          cov_2ff0bzynse.s[194]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_2ff0bzynse.s[195]++;
          $scope.isloading = false;
          cov_2ff0bzynse.s[196]++;
          return;
        } else {
          cov_2ff0bzynse.b[25][1]++;
        }
        cov_2ff0bzynse.s[197]++;
        $deltaLocation.removeData(item);
      });
    });
  };

  cov_2ff0bzynse.s[198]++;
  $scope.cancelItem = function () {
    cov_2ff0bzynse.f[36]++;
    cov_2ff0bzynse.s[199]++;

    $scope.planItem = {};
    cov_2ff0bzynse.s[200]++;
    $scope.editOption = false;
    cov_2ff0bzynse.s[201]++;
    $scope.show_geographicplan = false;
  };
  cov_2ff0bzynse.s[202]++;
  $scope.aggregate = function (champ) {
    cov_2ff0bzynse.f[37]++;
    cov_2ff0bzynse.s[203]++;

    if (champ) {
      cov_2ff0bzynse.b[26][0]++;
      cov_2ff0bzynse.s[204]++;

      champ.isLoading = true;
    } else {
      cov_2ff0bzynse.b[26][1]++;
    }
    cov_2ff0bzynse.s[205]++;
    $CRUDService.save($scope.PATHPG, { action: 'update_custom_field', id: champ.id }, function (data) {
      cov_2ff0bzynse.f[38]++;
      cov_2ff0bzynse.s[206]++;

      $scope.getCustomValues(champ);
    });
  };
  cov_2ff0bzynse.s[207]++;
  $scope.getCustomValues = function (champ) {
    cov_2ff0bzynse.f[39]++;
    cov_2ff0bzynse.s[208]++;

    if (champ) {
      cov_2ff0bzynse.b[27][0]++;
      cov_2ff0bzynse.s[209]++;

      champ.isLoading = true;
    } else {
      cov_2ff0bzynse.b[27][1]++;
      cov_2ff0bzynse.s[210]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = $scope.listChampPerso[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var ch = _step2.value;
          cov_2ff0bzynse.s[211]++;

          ch.isLoading = true;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    }
    cov_2ff0bzynse.s[212]++;
    $CRUDService.getAll($scope.PATHPG, { get: 'all_custom_fields' }, function (data) {
      cov_2ff0bzynse.f[40]++;
      cov_2ff0bzynse.s[213]++;

      $scope.customValuesList = data;
      cov_2ff0bzynse.s[214]++;
      if (champ) {
        cov_2ff0bzynse.b[28][0]++;
        cov_2ff0bzynse.s[215]++;

        champ.isLoading = false;
      } else {
        cov_2ff0bzynse.b[28][1]++;
        cov_2ff0bzynse.s[216]++;
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = $scope.listChampPerso[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var _ch = _step3.value;
            cov_2ff0bzynse.s[217]++;

            _ch.isLoading = false;
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      }
    });
  };

  cov_2ff0bzynse.s[218]++;
  $scope.updateCustomValues = function (p) {
    cov_2ff0bzynse.f[41]++;
    cov_2ff0bzynse.s[219]++;

    if (angular.isUndefined($scope.customValuesList[p.CODE_LC])) {
      cov_2ff0bzynse.b[29][0]++;
      cov_2ff0bzynse.s[220]++;

      $scope.customValuesList[p.CODE_LC] = {};
    } else {
      cov_2ff0bzynse.b[29][1]++;
    }
  };

  cov_2ff0bzynse.s[221]++;
  $scope.refreshLocalities = function () {
    cov_2ff0bzynse.f[42]++;
    cov_2ff0bzynse.s[222]++;

    $scope.getAllNG();
    cov_2ff0bzynse.s[223]++;
    $deltaLocation.removeAllData();
    cov_2ff0bzynse.s[224]++;
    $deltaLocation.addController($scope);
  };

  cov_2ff0bzynse.s[225]++;
  $scope.getCustomValues();
  cov_2ff0bzynse.s[226]++;
  $scope.canAddNewNG();
  cov_2ff0bzynse.s[227]++;
  $scope.getAllChampPerso();
  cov_2ff0bzynse.s[228]++;
  $scope.getAllNG();
  // $scope.getAllPLANGEO(0);
});

function geographic_mapCtrl_edit($scope, $uibModalInstance, $translate, $log, $filter, $CRUDService, $deltaLocation, $http) {
  cov_2ff0bzynse.f[43]++;
  cov_2ff0bzynse.s[229]++;

  $scope.closeModal = function () {
    cov_2ff0bzynse.f[44]++;
    cov_2ff0bzynse.s[230]++;

    $scope.cancelItem();
    cov_2ff0bzynse.s[231]++;
    $uibModalInstance.dismiss('cancel');
  };
  cov_2ff0bzynse.s[232]++;
  var getMapUrl = function getMapUrl(value) {
    cov_2ff0bzynse.f[45]++;

    var mapstr = (cov_2ff0bzynse.s[233]++, 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}');
    cov_2ff0bzynse.s[234]++;
    switch (value) {
      case 0:
        cov_2ff0bzynse.b[30][0]++;
        cov_2ff0bzynse.s[235]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}';
        cov_2ff0bzynse.s[236]++;
        break;
      case 1:
        cov_2ff0bzynse.b[30][1]++;
        cov_2ff0bzynse.s[237]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvs9jqk60fr91cscf0w98zth/tiles/256/{z}/{x}/{y}@2x';
        cov_2ff0bzynse.s[238]++;
        break;
      case 2:
        cov_2ff0bzynse.b[30][2]++;
        cov_2ff0bzynse.s[239]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbh28818ic1cmqcfaz1is2/tiles/256/{z}/{x}/{y}@2x';
        cov_2ff0bzynse.s[240]++;
        break;
      case 3:
        cov_2ff0bzynse.b[30][3]++;
        cov_2ff0bzynse.s[241]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbjkcx0mkz1cp3ot9cdfnk/tiles/256/{z}/{x}/{y}@2x';
        cov_2ff0bzynse.s[242]++;
        break;
      case 4:
        cov_2ff0bzynse.b[30][4]++;
        cov_2ff0bzynse.s[243]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/ck0wcr1og05gz1cq7ik9y0ct5/tiles/256/{z}/{x}/{y}@2x';
        cov_2ff0bzynse.s[244]++;
        break;
      default:
        cov_2ff0bzynse.b[30][5]++;
        cov_2ff0bzynse.s[245]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cj8x1v3iv05pz2rrtc9ps1tui/tiles/256/{z}/{x}/{y}';
        cov_2ff0bzynse.s[246]++;
        break;
    }
    cov_2ff0bzynse.s[247]++;
    return mapstr + '?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew';
  };
  var myMap = (cov_2ff0bzynse.s[248]++, null);
  var positionLayer = (cov_2ff0bzynse.s[249]++, null);
  cov_2ff0bzynse.s[250]++;
  $scope.initMap = function () {
    cov_2ff0bzynse.f[46]++;

    var grayscale = (cov_2ff0bzynse.s[251]++, L.tileLayer(getMapUrl(4)));
    cov_2ff0bzynse.s[252]++;
    myMap = L.map('location_xy', { fullscreenControl: {
        title: {
          false: $translate.instant('COMMON.FULLSCREEN'),
          true: $translate.instant('COMMON.EXIT_FULLSCREEN')
        }
      }, layers: [grayscale] }).setView([16.82, 11.3], 5);
    cov_2ff0bzynse.s[253]++;
    positionLayer = L.layerGroup().addTo(myMap);
    var base = (cov_2ff0bzynse.s[254]++, {});
    cov_2ff0bzynse.s[255]++;
    base[$translate.instant('COMMON.GRAYSCALE')] = L.tileLayer(getMapUrl(1));
    cov_2ff0bzynse.s[256]++;
    base[$translate.instant('COMMON.STREETMAP')] = grayscale;
    cov_2ff0bzynse.s[257]++;
    base[$translate.instant('COMMON.SATELLITE')] = L.tileLayer(getMapUrl(3));

    cov_2ff0bzynse.s[258]++;
    L.control.layers(base).addTo(myMap);
  };
  cov_2ff0bzynse.s[259]++;
  $scope.openMap = function (id, type) {
    cov_2ff0bzynse.f[47]++;
    cov_2ff0bzynse.s[260]++;

    positionLayer.clearLayers();
    cov_2ff0bzynse.s[261]++;
    myMap.toggleFullscreen();
    var l = (cov_2ff0bzynse.s[262]++, L.marker(L.latLng((cov_2ff0bzynse.b[31][0]++, $scope.planItem.Localisation_y) || (cov_2ff0bzynse.b[31][1]++, 0), (cov_2ff0bzynse.b[32][0]++, $scope.planItem.Localisation_x) || (cov_2ff0bzynse.b[32][1]++, 0)), { draggable: true }).addTo(positionLayer));
    cov_2ff0bzynse.s[263]++;
    l.on('dragend', function (data) {
      cov_2ff0bzynse.f[48]++;

      var latLng = (cov_2ff0bzynse.s[264]++, l.getLatLng());
      cov_2ff0bzynse.s[265]++;
      $scope.planItem.Localisation_x = latLng.lng.toFixed(9);
      cov_2ff0bzynse.s[266]++;
      $scope.planItem.Localisation_y = latLng.lat.toFixed(9);
      cov_2ff0bzynse.s[267]++;
      myMap.setView(latLng);
    });
  };

  cov_2ff0bzynse.s[268]++;
  $scope.getCodeError = function (item) {
    cov_2ff0bzynse.f[49]++;
    cov_2ff0bzynse.s[269]++;

    return $translate.instant(item.CODE_LP ? (cov_2ff0bzynse.b[33][0]++, 'COMMON.ERROR.CODE_ERROR_COM') : (cov_2ff0bzynse.b[33][1]++, 'COMMON.ERROR.CODE_ERROR'), $scope.editParam);
  };
  cov_2ff0bzynse.s[270]++;
  $scope.checkCode = function () {
    cov_2ff0bzynse.f[50]++;

    var item = (cov_2ff0bzynse.s[271]++, $scope.planItem);
    cov_2ff0bzynse.s[272]++;
    if (item.id != 0) {
      cov_2ff0bzynse.b[34][0]++;
      cov_2ff0bzynse.s[273]++;

      return true;
    } else {
      cov_2ff0bzynse.b[34][1]++;
    }
    cov_2ff0bzynse.s[274]++;
    if (((cov_2ff0bzynse.b[36][0]++, item.CODE_LC) || (cov_2ff0bzynse.b[36][1]++, '')).toUpperCase().startsWith($scope.editParam.begin)) {
      cov_2ff0bzynse.b[35][0]++;
      cov_2ff0bzynse.s[275]++;

      if (((cov_2ff0bzynse.b[38][0]++, item.CODE_LC) || (cov_2ff0bzynse.b[38][1]++, '')).length == $scope.editParam.value) {
        cov_2ff0bzynse.b[37][0]++;
        cov_2ff0bzynse.s[276]++;

        return true;
      } else {
        cov_2ff0bzynse.b[37][1]++;
      }
    } else {
      cov_2ff0bzynse.b[35][1]++;
    }
    cov_2ff0bzynse.s[277]++;
    return false;
  };
  cov_2ff0bzynse.s[278]++;
  $scope.validateItem = function (right_) {
    cov_2ff0bzynse.f[51]++;

    var right = (cov_2ff0bzynse.s[279]++, right_);
    cov_2ff0bzynse.s[280]++;
    $scope.error_plan_geo = {
      error: false,
      label: ''
    };
    cov_2ff0bzynse.s[281]++;
    $scope.planItem.CODE_LC = $filter('uppercase')($scope.planItem.CODE_LC);

    // Find Doublons
    cov_2ff0bzynse.s[282]++;
    if ($scope.planItem.id < 1) {
      cov_2ff0bzynse.b[39][0]++;

      var doubleEl = (cov_2ff0bzynse.s[283]++, $filter('filter')($scope.listData_bksb, { CODE_LC: $scope.planItem.CODE_LC }, true)[0]);
      cov_2ff0bzynse.s[284]++;
      if (doubleEl) {
        cov_2ff0bzynse.b[40][0]++;
        cov_2ff0bzynse.s[285]++;

        $scope.error_plan_geo = {
          error: 1,
          label: 'Le code est déjà attribué'
        };
        cov_2ff0bzynse.s[286]++;
        return;
      } else {
        cov_2ff0bzynse.b[40][1]++;
      }
    } else {
      cov_2ff0bzynse.b[39][1]++;
    }
    cov_2ff0bzynse.s[287]++;
    $scope.isloadingNG = true;
    var p = (cov_2ff0bzynse.s[288]++, {
      id: $scope.planItem.id,
      CODE_LC: $scope.planItem.CODE_LC,
      DESCRIPTION_L: $scope.planItem.DESCRIPTION_L,
      Localisation_x: parseFloat($scope.planItem.Localisation_x),
      Localisation_y: parseFloat($scope.planItem.Localisation_y),
      CARAC_L: $scope.planItem.CARAC_L,
      CODE_NG: $scope.planItem.CODE_NG,
      CODE_LP: $scope.planItem.CODE_LP,
      leaf: $scope.planItem.leaf,
      expanded: false
    });

    var oldid = (cov_2ff0bzynse.s[289]++, p.id);
    cov_2ff0bzynse.s[290]++;
    $CRUDService.save($scope.PATHPG, { action: 'miseAJour', valeur: $filter('json')(p) }, function (data_) {
      cov_2ff0bzynse.f[52]++;

      var data = (cov_2ff0bzynse.s[291]++, data_[0]);
      cov_2ff0bzynse.s[292]++;
      $scope.isloadingNG = false;
      cov_2ff0bzynse.s[293]++;
      if (data < 0) {
        cov_2ff0bzynse.b[41][0]++;
        cov_2ff0bzynse.s[294]++;

        return;
      } else {
        cov_2ff0bzynse.b[41][1]++;
      }
      cov_2ff0bzynse.s[295]++;
      if (oldid == 0) {
        cov_2ff0bzynse.b[42][0]++;

        var parent = (cov_2ff0bzynse.s[296]++, $filter('filter')($scope.listData_bksb, { id: p.CODE_LP }, true)[0]);
        cov_2ff0bzynse.s[297]++;
        if (!parent) {
          cov_2ff0bzynse.b[43][0]++;
          cov_2ff0bzynse.s[298]++;

          p.id = data;
          cov_2ff0bzynse.s[299]++;
          p.view = right.view;
          cov_2ff0bzynse.s[300]++;
          p.update = right.update;
          cov_2ff0bzynse.s[301]++;
          p.delete = right.delete;
          cov_2ff0bzynse.s[302]++;
          $deltaLocation.addData(p, $scope.planItem.NG.NIVEAU_NG, $scope.planItem.id != 0);
        } else {
            cov_2ff0bzynse.b[43][1]++;
            cov_2ff0bzynse.s[303]++;
            if (parent.isLoaded == true) {
              cov_2ff0bzynse.b[44][0]++;
              cov_2ff0bzynse.s[304]++;
              // Ajouter à la suite
              p.id = data;
              cov_2ff0bzynse.s[305]++;
              p.view = right.view;
              cov_2ff0bzynse.s[306]++;
              p.update = right.update;
              cov_2ff0bzynse.s[307]++;
              p.delete = right.delete;
              cov_2ff0bzynse.s[308]++;
              $deltaLocation.addData(p, $scope.planItem.NG.NIVEAU_NG);
            } else {
              cov_2ff0bzynse.b[44][1]++;
              cov_2ff0bzynse.s[309]++;

              $scope.loadingData_bksb(parent);
            }
          }
      } else {
        cov_2ff0bzynse.b[42][1]++;
        cov_2ff0bzynse.s[310]++;

        p.view = right.view;
        cov_2ff0bzynse.s[311]++;
        p.update = right.update;
        cov_2ff0bzynse.s[312]++;
        p.delete = right.delete;
        cov_2ff0bzynse.s[313]++;
        $deltaLocation.addData(p, $scope.planItem.NG.NIVEAU_NG, $scope.planItem.id != 0);
      }
      //  $scope.saveIdoms(p, data_[1]);
      cov_2ff0bzynse.s[314]++;
      $scope.updateCustomValues(p);
      cov_2ff0bzynse.s[315]++;
      $scope.closeModal();
    });
  };
  cov_2ff0bzynse.s[316]++;
  $scope.saveIdoms = function (locality, query) {
    cov_2ff0bzynse.f[53]++;
    cov_2ff0bzynse.s[317]++;

    if (query == null) {
      cov_2ff0bzynse.b[45][0]++;
      cov_2ff0bzynse.s[318]++;

      return;
    } else {
      cov_2ff0bzynse.b[45][1]++;
    }

    /*$http({
      method: 'GET',
      url: 'https://nominatim.openstreetmap.org/search?' + query
    }).then(response => {
      let data = $filter('filter')(response.data, {class: 'boundary'}, true);
      if (data.length == 0) {
        data = $filter('filter')(response.data, {class: 'place'}, true);
      }
      if (data.length == 0) {
        data = [{osm_id: -1, lat: -1, lon: -1, osm_type: 'n'}];
      }
       const _param = {id: parseFloat(data[0].osm_id), x: parseFloat(data[0].lat), y: parseFloat(data[0].lon), type: data[0].osm_type[0].toUpperCase()};
      $deltaLocation.saveIdoms(locality, _param);
      $CRUDService.save($scope.PATHPG, {action: 'updatePos', valeur: _param, id: locality.id}, data_ => {});
    }, () => {
     });*/
  };
}

function geographic_mapCtrl_level($scope, $uibModalInstance, $filter, $CRUDService, SweetAlert, $translate, $otherHttpService) {
  cov_2ff0bzynse.f[54]++;
  cov_2ff0bzynse.s[319]++;

  $scope.GeoLevel = {
    selectedItem: null,
    newItem: function newItem() {
      cov_2ff0bzynse.s[320]++;

      this.editItem({
        id: 0,
        LONG_NG: $scope.listGeoLevel.length == 0 ? (cov_2ff0bzynse.b[46][0]++, 1) : (cov_2ff0bzynse.b[46][1]++, $scope.listGeoLevel[$scope.listGeoLevel.length - 1].LONG_NG + 1),
        NIVEAU_NG: $scope.listGeoLevel.length + 1
      });
    },
    editItem: function editItem(item) {
      cov_2ff0bzynse.s[321]++;

      this.selectedItem = angular.copy(item);
      cov_2ff0bzynse.s[322]++;
      this.selectedItem.hideEditor = false;
      cov_2ff0bzynse.s[323]++;
      $scope.GeoLevel.listChampPersoTampon = [].concat($scope.listChampPerso);
      cov_2ff0bzynse.s[324]++;
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = $scope.GeoLevel.listChampPersoTampon[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var _el = _step4.value;
          cov_2ff0bzynse.s[325]++;

          _el.check = this.selectedItem.id == _el.CODE_NG;
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      cov_2ff0bzynse.s[326]++;
      if (this.selectedItem.id == 0) {
        cov_2ff0bzynse.b[47][0]++;
        cov_2ff0bzynse.s[327]++;

        this.selectedItem.maxLength = 30;
        cov_2ff0bzynse.s[328]++;
        if ($scope.listGeoLevel.length == 0) {
          cov_2ff0bzynse.b[48][0]++;
          cov_2ff0bzynse.s[329]++;

          this.selectedItem.minLength = 1;
        } else {
          cov_2ff0bzynse.b[48][1]++;
          cov_2ff0bzynse.s[330]++;

          this.selectedItem.minLength = $scope.listGeoLevel[$scope.listGeoLevel.length - 1].LONG_NG + 1;
        }
      } else {
        cov_2ff0bzynse.b[47][1]++;
        cov_2ff0bzynse.s[331]++;

        for (var i = 0; i < $scope.listGeoLevel.length; i++) {
          var el = (cov_2ff0bzynse.s[332]++, $scope.listGeoLevel[i]);
          cov_2ff0bzynse.s[333]++;
          if (el.id == this.selectedItem.id) {
            cov_2ff0bzynse.b[49][0]++;
            cov_2ff0bzynse.s[334]++;

            if (i == 0) {
              cov_2ff0bzynse.b[50][0]++;
              cov_2ff0bzynse.s[335]++;

              this.selectedItem.minLength = 1;
            } else {
              cov_2ff0bzynse.b[50][1]++;
              cov_2ff0bzynse.s[336]++;

              this.selectedItem.minLength = $scope.listGeoLevel[i - 1].LONG_NG + 1;
            }
            cov_2ff0bzynse.s[337]++;
            if (i == $scope.listGeoLevel.length - 1) {
              cov_2ff0bzynse.b[51][0]++;
              cov_2ff0bzynse.s[338]++;

              this.selectedItem.maxLength = 30;
            } else {
              cov_2ff0bzynse.b[51][1]++;
              cov_2ff0bzynse.s[339]++;

              this.selectedItem.maxLength = $scope.listGeoLevel[i + 1].LONG_NG - 1;
            }
            cov_2ff0bzynse.s[340]++;
            break;
          } else {
            cov_2ff0bzynse.b[49][1]++;
          }
        }
      }
    },
    cancelItem: function cancelItem() {
      cov_2ff0bzynse.s[341]++;

      this.selectedItem = null;
    },
    validate: function validate() {
      var item = (cov_2ff0bzynse.s[342]++, angular.copy(this.selectedItem));
      cov_2ff0bzynse.s[343]++;
      $CRUDService.save($scope.PATHNG, { action: 'miseAJour', valeur: $filter('json')(item) }, function (data) {
        cov_2ff0bzynse.f[55]++;
        cov_2ff0bzynse.s[344]++;

        if ((cov_2ff0bzynse.b[53][0]++, data == 0) || (cov_2ff0bzynse.b[53][1]++, data == -2)) {
          cov_2ff0bzynse.b[52][0]++;
          cov_2ff0bzynse.s[345]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_UPDATE'),
            text: $translate.instant('GEOGRAPHIC_PLAN.ERROR_UPDATE_LONG')
          });
          cov_2ff0bzynse.s[346]++;
          return;
        } else {
          cov_2ff0bzynse.b[52][1]++;
        }
        cov_2ff0bzynse.s[347]++;
        if (data < 1) {
          cov_2ff0bzynse.b[54][0]++;
          cov_2ff0bzynse.s[348]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_UPDATE'),
            text: $translate.instant('COMMON.TITLE_ERROR_ADD')
          });
          cov_2ff0bzynse.s[349]++;
          return;
        } else {
          cov_2ff0bzynse.b[54][1]++;
        }
        cov_2ff0bzynse.s[350]++;
        $scope.GeoLevel.cancelItem();
        cov_2ff0bzynse.s[351]++;
        $scope.GeoLevel.updatengChamPerso((cov_2ff0bzynse.b[55][0]++, item.id) || (cov_2ff0bzynse.b[55][1]++, data));
        cov_2ff0bzynse.s[352]++;
        $scope.getAllNG();
      });
    },
    updatengChamPerso: function updatengChamPerso(id) {
      cov_2ff0bzynse.s[353]++;

      if (id == 0) {
        cov_2ff0bzynse.b[56][0]++;
        cov_2ff0bzynse.s[354]++;

        return;
      } else {
        cov_2ff0bzynse.b[56][1]++;
      }
      var listerso = (cov_2ff0bzynse.s[355]++, []);
      cov_2ff0bzynse.s[356]++;
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = this.listChampPersoTampon[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var el = _step5.value;
          cov_2ff0bzynse.s[357]++;

          if (el.check) {
            cov_2ff0bzynse.b[57][0]++;
            cov_2ff0bzynse.s[358]++;

            listerso.push(el.id);
          } else {
            cov_2ff0bzynse.b[57][1]++;
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      cov_2ff0bzynse.s[359]++;
      $otherHttpService.post($scope.PATHCHAMP, { action: 'updateNG', idng: id, valeur: listerso.join(' , ') }, function (data) {
        cov_2ff0bzynse.f[56]++;
        cov_2ff0bzynse.s[360]++;

        $scope.getAllChampPerso();
      });
    },
    deleteItem: function deleteItem(item) {
      cov_2ff0bzynse.s[361]++;

      SweetAlert.swal({
        title: $translate.instant('COMMON.CONFIRM'),
        // text: $translate.instant("GEOGRAPHIC_PLAN.CONFIRM_DELETE_LEVEL"),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c8c8c8',

        confirmButtonText: $translate.instant('COMMON.NO'),
        cancelButtonText: $translate.instant('COMMON.YES'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_2ff0bzynse.f[57]++;
        cov_2ff0bzynse.s[362]++;

        if (isconfirm) {
          cov_2ff0bzynse.b[58][0]++;
          cov_2ff0bzynse.s[363]++;

          return;
        } else {
          cov_2ff0bzynse.b[58][1]++;
        }
        cov_2ff0bzynse.s[364]++;
        $scope.isloadingNG = true;
        cov_2ff0bzynse.s[365]++;
        $CRUDService.delet($scope.PATHNG, { action: 'supp', valeur: $filter('json')(item) }, function (data) {
          cov_2ff0bzynse.f[58]++;
          cov_2ff0bzynse.s[366]++;

          $scope.isloadingNG = false;
          cov_2ff0bzynse.s[367]++;
          if (data < 1) {
            cov_2ff0bzynse.b[59][0]++;
            cov_2ff0bzynse.s[368]++;

            SweetAlert.swal({
              title: $translate.instant('COMMON.ERROR_DELETE'),
              text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
            });
            cov_2ff0bzynse.s[369]++;
            return;
          } else {
            cov_2ff0bzynse.b[59][1]++;
          }
          cov_2ff0bzynse.s[370]++;
          $scope.getAllNG();
          cov_2ff0bzynse.s[371]++;
          $scope.getAllChampPerso();
        });
      });
    },
    closeModal: function closeModal() {
      cov_2ff0bzynse.s[372]++;

      $uibModalInstance.dismiss('cancel');
    }
  };
}

function geographic_mapCtrl_generate($scope, $uibModalInstance, $rootScope, $http, $translate, $filter, $CRUDService, SweetAlert) {
  cov_2ff0bzynse.f[59]++;
  cov_2ff0bzynse.s[373]++;

  $scope.generate = {
    selectedMode: true,
    getCountries: function getCountries() {
      var _this = this;

      cov_2ff0bzynse.s[374]++;

      this.isloading = true;
      cov_2ff0bzynse.s[375]++;
      $http({
        method: 'GET',
        url: $rootScope.getGeoLayers(null, undefined, false) + '/countries/1'
      }).then(function (response) {
        cov_2ff0bzynse.f[60]++;
        cov_2ff0bzynse.s[376]++;

        _this.isloading = false;
        cov_2ff0bzynse.s[377]++;
        $scope.generate.countries = response.data.map(function (value) {
          cov_2ff0bzynse.f[61]++;
          cov_2ff0bzynse.s[378]++;

          return {
            layer: 0,
            uid: value.uid,
            x: value.x,
            y: value.y,
            id: value.id,
            name: value.name
          };
        }).filter(function (location) {
          cov_2ff0bzynse.f[62]++;
          cov_2ff0bzynse.s[379]++;

          return !$scope.existingLocation.find(function (d) {
            cov_2ff0bzynse.f[63]++;
            cov_2ff0bzynse.s[380]++;

            return location.uid.toString() === ((cov_2ff0bzynse.b[60][0]++, d.UID_ARC) || (cov_2ff0bzynse.b[60][1]++, '')).toString();
          });
        });
        cov_2ff0bzynse.s[381]++;
        $scope.generate.selectedCountry = $scope.generate.countries[0];
      }, function (error) {
        cov_2ff0bzynse.f[64]++;
        cov_2ff0bzynse.s[382]++;

        _this.isloading = false;
      });
    },
    getAllOtherLocalities: function getAllOtherLocalities(level) {
      var _this2 = this;

      var finalData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_2ff0bzynse.b[61][0]++, []);
      cov_2ff0bzynse.s[383]++;

      this.selectedMode = false;
      cov_2ff0bzynse.s[384]++;
      if (finalData.length == 0) {
        cov_2ff0bzynse.b[62][0]++;
        cov_2ff0bzynse.s[385]++;

        finalData.push($scope.generate.selectedCountry);
      } else {
        cov_2ff0bzynse.b[62][1]++;
      }
      cov_2ff0bzynse.s[386]++;
      if (level > 5) {
        cov_2ff0bzynse.b[63][0]++;
        cov_2ff0bzynse.s[387]++;

        this.isloading = false;
        cov_2ff0bzynse.s[388]++;
        return this.drawTreeTable(finalData);
      } else {
        cov_2ff0bzynse.b[63][1]++;
      }
      cov_2ff0bzynse.s[389]++;
      this.isloading = true;
      cov_2ff0bzynse.s[390]++;
      $http.get($rootScope.getGeoLayers(level, undefined, false) + '/children/' + $scope.generate.selectedCountry.id + '/format=json').then(function (response) {
        cov_2ff0bzynse.f[65]++;

        var data = (cov_2ff0bzynse.s[391]++, response.data);
        cov_2ff0bzynse.s[392]++;
        if (data.length == 0) {
          cov_2ff0bzynse.b[64][0]++;
          cov_2ff0bzynse.s[393]++;

          _this2.isloading = false;
          cov_2ff0bzynse.s[394]++;
          return _this2.drawTreeTable(finalData);
        } else {
          cov_2ff0bzynse.b[64][1]++;
        }
        cov_2ff0bzynse.s[395]++;
        _this2.getAllOtherLocalities(level + 1, finalData.concat(data.map(function (locality) {
          cov_2ff0bzynse.f[66]++;
          cov_2ff0bzynse.s[396]++;
          return {
            layer: level,
            uid: locality.uid,
            id: locality.uid,
            x: locality.x,
            y: locality.y,
            id_p: locality.parent.uid,
            name: locality.name,
            type: locality.levelName
          };
        })));
      }, function () {
        cov_2ff0bzynse.f[67]++;
        cov_2ff0bzynse.s[397]++;

        _this2.isloading = false;
        cov_2ff0bzynse.s[398]++;
        _this2.drawTreeTable(finalData);
      });
    },
    drawTreeTable: function drawTreeTable(data) {
      cov_2ff0bzynse.s[399]++;

      this.treeGridObj = new ej.treegrid.TreeGrid({
        dataSource: data,
        idMapping: 'uid',
        parentIdMapping: 'id_p',
        treeColumnIndex: 0,
        columns: [{ field: 'name', headerText: $translate.instant('GEOGRAPHIC_PLAN.DESCRP') }, { field: 'type', width: '150px', headerText: $translate.instant('GEOGRAPHIC_PLAN.LEVEL') }],
        height: '500px'
      });
      cov_2ff0bzynse.s[400]++;
      this.treeGridObj.appendTo('#resume_tree');
    },
    cancel: function cancel() {
      cov_2ff0bzynse.s[401]++;

      this.selectedMode = true;
    },
    saveLocalities: function saveLocalities() {
      var _this3 = this;

      var exportData = (cov_2ff0bzynse.s[402]++, [[], [], [], [], [], []]);
      cov_2ff0bzynse.s[403]++;
      var _iteratorNormalCompletion6 = true;
      var _didIteratorError6 = false;
      var _iteratorError6 = undefined;

      try {
        for (var _iterator6 = this.treeGridObj.dataSource[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
          var item = _step6.value;
          cov_2ff0bzynse.s[404]++;

          exportData[(cov_2ff0bzynse.b[65][0]++, item.layer) || (cov_2ff0bzynse.b[65][1]++, 0)].push({
            id: item.uid,
            uid: item.uid,
            x: item.x,
            y: item.y,
            name: item.name,
            idp: item.id_p
          });
        }
      } catch (err) {
        _didIteratorError6 = true;
        _iteratorError6 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion6 && _iterator6.return) {
            _iterator6.return();
          }
        } finally {
          if (_didIteratorError6) {
            throw _iteratorError6;
          }
        }
      }

      cov_2ff0bzynse.s[405]++;
      this.isloading = true;
      cov_2ff0bzynse.s[406]++;
      $CRUDService.save($scope.PATHPG, { action: 'arcgis_import', data: exportData.filter(function (value) {
          cov_2ff0bzynse.f[68]++;
          cov_2ff0bzynse.s[407]++;

          return value.length > 0;
        }) }, function (data) {
        cov_2ff0bzynse.f[69]++;
        cov_2ff0bzynse.s[408]++;

        _this3.isloading = false;
        cov_2ff0bzynse.s[409]++;
        $scope.refreshLocalities();
        cov_2ff0bzynse.s[410]++;
        _this3.closeModal();
      });
    },
    closeModal: function closeModal() {
      cov_2ff0bzynse.s[411]++;

      $uibModalInstance.dismiss('cancel');
    }
  };
  cov_2ff0bzynse.s[412]++;
  $CRUDService.getAll($scope.PATHPG, { get: 'all' }, function (data) {
    cov_2ff0bzynse.f[70]++;
    cov_2ff0bzynse.s[413]++;

    $scope.existingLocation = data;
    cov_2ff0bzynse.s[414]++;
    $scope.generate.getCountries();
  });
}

function geographic_mapCtrl_importByFile($scope, $uibModalInstance, $CRUDService, $rootScope) {
  cov_2ff0bzynse.f[71]++;
  cov_2ff0bzynse.s[415]++;


  $scope.selected = {
    property: $scope.importByFile.properties[0]
  };

  cov_2ff0bzynse.s[416]++;
  $scope.closeModal = function () {
    cov_2ff0bzynse.f[72]++;
    cov_2ff0bzynse.s[417]++;

    $uibModalInstance.dismiss('cancel');
  };

  cov_2ff0bzynse.s[418]++;
  $scope.validate = function () {
    cov_2ff0bzynse.f[73]++;
    cov_2ff0bzynse.s[419]++;

    $CRUDService.save($scope.PATHPG, Object.assign({ action: 'process_import', property: $scope.selected.property }, $scope.refreshLocation ? (cov_2ff0bzynse.b[66][0]++, { location: $scope.refreshLocation.id }) : (cov_2ff0bzynse.b[66][1]++, {})), function (data) {
      cov_2ff0bzynse.f[74]++;
      cov_2ff0bzynse.s[420]++;

      $scope.refreshLocalities();
      cov_2ff0bzynse.s[421]++;
      $scope.closeModal();
    });
    cov_2ff0bzynse.s[422]++;
    $scope.loading = true;
  };

  cov_2ff0bzynse.s[423]++;
  if ($scope.importByFile.properties.length == 1) {
    cov_2ff0bzynse.b[67][0]++;
    cov_2ff0bzynse.s[424]++;

    $scope.validate();
    cov_2ff0bzynse.s[425]++;
    return;
  } else {
    cov_2ff0bzynse.b[67][1]++;
  }
}