'use strict';

var cov_21pnj1a1gi = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/layer/LayersCtrl.js',
      hash = '2f1b5ba2e2db1129096514892b0703eb9bab3718',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/layer/LayersCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 1
        },
        end: {
          line: 72,
          column: 4
        }
      },
      '1': {
        start: {
          line: 3,
          column: 3
        },
        end: {
          line: 3,
          column: 25
        }
      },
      '2': {
        start: {
          line: 4,
          column: 16
        },
        end: {
          line: 4,
          column: 23
        }
      },
      '3': {
        start: {
          line: 6,
          column: 3
        },
        end: {
          line: 70,
          column: 5
        }
      },
      '4': {
        start: {
          line: 11,
          column: 7
        },
        end: {
          line: 11,
          column: 27
        }
      },
      '5': {
        start: {
          line: 12,
          column: 7
        },
        end: {
          line: 17,
          column: 10
        }
      },
      '6': {
        start: {
          line: 13,
          column: 9
        },
        end: {
          line: 13,
          column: 26
        }
      },
      '7': {
        start: {
          line: 14,
          column: 9
        },
        end: {
          line: 14,
          column: 30
        }
      },
      '8': {
        start: {
          line: 16,
          column: 9
        },
        end: {
          line: 16,
          column: 30
        }
      },
      '9': {
        start: {
          line: 19,
          column: 7
        },
        end: {
          line: 19,
          column: 71
        }
      },
      '10': {
        start: {
          line: 22,
          column: 7
        },
        end: {
          line: 27,
          column: 10
        }
      },
      '11': {
        start: {
          line: 30,
          column: 7
        },
        end: {
          line: 30,
          column: 46
        }
      },
      '12': {
        start: {
          line: 31,
          column: 7
        },
        end: {
          line: 38,
          column: 10
        }
      },
      '13': {
        start: {
          line: 41,
          column: 7
        },
        end: {
          line: 58,
          column: 10
        }
      },
      '14': {
        start: {
          line: 51,
          column: 9
        },
        end: {
          line: 53,
          column: 10
        }
      },
      '15': {
        start: {
          line: 52,
          column: 11
        },
        end: {
          line: 52,
          column: 18
        }
      },
      '16': {
        start: {
          line: 54,
          column: 9
        },
        end: {
          line: 54,
          column: 33
        }
      },
      '17': {
        start: {
          line: 55,
          column: 9
        },
        end: {
          line: 57,
          column: 12
        }
      },
      '18': {
        start: {
          line: 56,
          column: 11
        },
        end: {
          line: 56,
          column: 25
        }
      },
      '19': {
        start: {
          line: 61,
          column: 7
        },
        end: {
          line: 68,
          column: 16
        }
      },
      '20': {
        start: {
          line: 62,
          column: 23
        },
        end: {
          line: 62,
          column: 121
        }
      },
      '21': {
        start: {
          line: 66,
          column: 9
        },
        end: {
          line: 66,
          column: 55
        }
      },
      '22': {
        start: {
          line: 71,
          column: 3
        },
        end: {
          line: 71,
          column: 26
        }
      },
      '23': {
        start: {
          line: 75,
          column: 16
        },
        end: {
          line: 75,
          column: 23
        }
      },
      '24': {
        start: {
          line: 76,
          column: 3
        },
        end: {
          line: 76,
          column: 25
        }
      },
      '25': {
        start: {
          line: 77,
          column: 3
        },
        end: {
          line: 77,
          column: 52
        }
      },
      '26': {
        start: {
          line: 79,
          column: 3
        },
        end: {
          line: 117,
          column: 5
        }
      },
      '27': {
        start: {
          line: 80,
          column: 26
        },
        end: {
          line: 80,
          column: 59
        }
      },
      '28': {
        start: {
          line: 81,
          column: 5
        },
        end: {
          line: 81,
          column: 70
        }
      },
      '29': {
        start: {
          line: 82,
          column: 5
        },
        end: {
          line: 82,
          column: 68
        }
      },
      '30': {
        start: {
          line: 83,
          column: 5
        },
        end: {
          line: 83,
          column: 27
        }
      },
      '31': {
        start: {
          line: 84,
          column: 5
        },
        end: {
          line: 86,
          column: 6
        }
      },
      '32': {
        start: {
          line: 85,
          column: 7
        },
        end: {
          line: 85,
          column: 14
        }
      },
      '33': {
        start: {
          line: 87,
          column: 5
        },
        end: {
          line: 89,
          column: 6
        }
      },
      '34': {
        start: {
          line: 88,
          column: 7
        },
        end: {
          line: 88,
          column: 14
        }
      },
      '35': {
        start: {
          line: 91,
          column: 5
        },
        end: {
          line: 96,
          column: 6
        }
      },
      '36': {
        start: {
          line: 92,
          column: 19
        },
        end: {
          line: 92,
          column: 50
        }
      },
      '37': {
        start: {
          line: 94,
          column: 7
        },
        end: {
          line: 94,
          column: 29
        }
      },
      '38': {
        start: {
          line: 95,
          column: 7
        },
        end: {
          line: 95,
          column: 14
        }
      },
      '39': {
        start: {
          line: 99,
          column: 23
        },
        end: {
          line: 101,
          column: 7
        }
      },
      '40': {
        start: {
          line: 100,
          column: 7
        },
        end: {
          line: 100,
          column: 156
        }
      },
      '41': {
        start: {
          line: 103,
          column: 22
        },
        end: {
          line: 105,
          column: 7
        }
      },
      '42': {
        start: {
          line: 104,
          column: 7
        },
        end: {
          line: 104,
          column: 113
        }
      },
      '43': {
        start: {
          line: 107,
          column: 5
        },
        end: {
          line: 107,
          column: 60
        }
      },
      '44': {
        start: {
          line: 109,
          column: 5
        },
        end: {
          line: 111,
          column: 6
        }
      },
      '45': {
        start: {
          line: 110,
          column: 7
        },
        end: {
          line: 110,
          column: 14
        }
      },
      '46': {
        start: {
          line: 113,
          column: 5
        },
        end: {
          line: 116,
          column: 8
        }
      },
      '47': {
        start: {
          line: 114,
          column: 7
        },
        end: {
          line: 114,
          column: 27
        }
      },
      '48': {
        start: {
          line: 115,
          column: 7
        },
        end: {
          line: 115,
          column: 30
        }
      },
      '49': {
        start: {
          line: 118,
          column: 3
        },
        end: {
          line: 120,
          column: 5
        }
      },
      '50': {
        start: {
          line: 119,
          column: 5
        },
        end: {
          line: 119,
          column: 41
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 48
          },
          end: {
            line: 2,
            column: 49
          }
        },
        loc: {
          start: {
            line: 2,
            column: 131
          },
          end: {
            line: 72,
            column: 2
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 12,
            column: 47
          },
          end: {
            line: 12,
            column: 48
          }
        },
        loc: {
          start: {
            line: 12,
            column: 55
          },
          end: {
            line: 15,
            column: 8
          }
        },
        line: 12
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 10
          },
          end: {
            line: 15,
            column: 11
          }
        },
        loc: {
          start: {
            line: 15,
            column: 16
          },
          end: {
            line: 17,
            column: 8
          }
        },
        line: 15
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 50,
            column: 10
          },
          end: {
            line: 50,
            column: 11
          }
        },
        loc: {
          start: {
            line: 50,
            column: 23
          },
          end: {
            line: 58,
            column: 8
          }
        },
        line: 50
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 55,
            column: 65
          },
          end: {
            line: 55,
            column: 66
          }
        },
        loc: {
          start: {
            line: 55,
            column: 71
          },
          end: {
            line: 57,
            column: 10
          }
        },
        line: 55
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 61,
            column: 16
          },
          end: {
            line: 61,
            column: 17
          }
        },
        loc: {
          start: {
            line: 61,
            column: 22
          },
          end: {
            line: 68,
            column: 8
          }
        },
        line: 61
      },
      '6': {
        name: 'LayersCtrlCtrl_edit',
        decl: {
          start: {
            line: 74,
            column: 10
          },
          end: {
            line: 74,
            column: 29
          }
        },
        loc: {
          start: {
            line: 74,
            column: 92
          },
          end: {
            line: 121,
            column: 2
          }
        },
        line: 74
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 79,
            column: 22
          },
          end: {
            line: 79,
            column: 23
          }
        },
        loc: {
          start: {
            line: 79,
            column: 28
          },
          end: {
            line: 117,
            column: 4
          }
        },
        line: 79
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 99,
            column: 47
          },
          end: {
            line: 99,
            column: 48
          }
        },
        loc: {
          start: {
            line: 99,
            column: 55
          },
          end: {
            line: 101,
            column: 6
          }
        },
        line: 99
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 103,
            column: 46
          },
          end: {
            line: 103,
            column: 47
          }
        },
        loc: {
          start: {
            line: 103,
            column: 54
          },
          end: {
            line: 105,
            column: 6
          }
        },
        line: 103
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 113,
            column: 91
          },
          end: {
            line: 113,
            column: 92
          }
        },
        loc: {
          start: {
            line: 113,
            column: 97
          },
          end: {
            line: 116,
            column: 6
          }
        },
        line: 113
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 118,
            column: 23
          },
          end: {
            line: 118,
            column: 24
          }
        },
        loc: {
          start: {
            line: 118,
            column: 35
          },
          end: {
            line: 120,
            column: 4
          }
        },
        line: 118
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 51,
            column: 9
          },
          end: {
            line: 53,
            column: 10
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 51,
            column: 9
          },
          end: {
            line: 53,
            column: 10
          }
        }, {
          start: {
            line: 51,
            column: 9
          },
          end: {
            line: 53,
            column: 10
          }
        }],
        line: 51
      },
      '1': {
        loc: {
          start: {
            line: 81,
            column: 32
          },
          end: {
            line: 81,
            column: 61
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 81,
            column: 32
          },
          end: {
            line: 81,
            column: 55
          }
        }, {
          start: {
            line: 81,
            column: 59
          },
          end: {
            line: 81,
            column: 61
          }
        }],
        line: 81
      },
      '2': {
        loc: {
          start: {
            line: 82,
            column: 31
          },
          end: {
            line: 82,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 82,
            column: 31
          },
          end: {
            line: 82,
            column: 53
          }
        }, {
          start: {
            line: 82,
            column: 57
          },
          end: {
            line: 82,
            column: 59
          }
        }],
        line: 82
      },
      '3': {
        loc: {
          start: {
            line: 84,
            column: 5
          },
          end: {
            line: 86,
            column: 6
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 84,
            column: 5
          },
          end: {
            line: 86,
            column: 6
          }
        }, {
          start: {
            line: 84,
            column: 5
          },
          end: {
            line: 86,
            column: 6
          }
        }],
        line: 84
      },
      '4': {
        loc: {
          start: {
            line: 87,
            column: 5
          },
          end: {
            line: 89,
            column: 6
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 87,
            column: 5
          },
          end: {
            line: 89,
            column: 6
          }
        }, {
          start: {
            line: 87,
            column: 5
          },
          end: {
            line: 89,
            column: 6
          }
        }],
        line: 87
      },
      '5': {
        loc: {
          start: {
            line: 87,
            column: 10
          },
          end: {
            line: 87,
            column: 38
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 87,
            column: 10
          },
          end: {
            line: 87,
            column: 32
          }
        }, {
          start: {
            line: 87,
            column: 36
          },
          end: {
            line: 87,
            column: 38
          }
        }],
        line: 87
      },
      '6': {
        loc: {
          start: {
            line: 100,
            column: 14
          },
          end: {
            line: 100,
            column: 155
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 100,
            column: 14
          },
          end: {
            line: 100,
            column: 125
          }
        }, {
          start: {
            line: 100,
            column: 129
          },
          end: {
            line: 100,
            column: 155
          }
        }],
        line: 100
      },
      '7': {
        loc: {
          start: {
            line: 104,
            column: 14
          },
          end: {
            line: 104,
            column: 112
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 104,
            column: 14
          },
          end: {
            line: 104,
            column: 82
          }
        }, {
          start: {
            line: 104,
            column: 86
          },
          end: {
            line: 104,
            column: 112
          }
        }],
        line: 104
      },
      '8': {
        loc: {
          start: {
            line: 107,
            column: 25
          },
          end: {
            line: 107,
            column: 59
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 107,
            column: 37
          },
          end: {
            line: 107,
            column: 38
          }
        }, {
          start: {
            line: 107,
            column: 42
          },
          end: {
            line: 107,
            column: 58
          }
        }],
        line: 107
      },
      '9': {
        loc: {
          start: {
            line: 107,
            column: 42
          },
          end: {
            line: 107,
            column: 58
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 107,
            column: 53
          },
          end: {
            line: 107,
            column: 54
          }
        }, {
          start: {
            line: 107,
            column: 57
          },
          end: {
            line: 107,
            column: 58
          }
        }],
        line: 107
      },
      '10': {
        loc: {
          start: {
            line: 109,
            column: 5
          },
          end: {
            line: 111,
            column: 6
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 109,
            column: 5
          },
          end: {
            line: 111,
            column: 6
          }
        }, {
          start: {
            line: 109,
            column: 5
          },
          end: {
            line: 111,
            column: 6
          }
        }],
        line: 109
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_21pnj1a1gi.s[0]++;

angular.module('app').controller('LayersCtrl', function ($scope, SweetAlert, $uibModal, $translate, $CRUDService, $timeout, $rootScope) {
  cov_21pnj1a1gi.f[0]++;
  cov_21pnj1a1gi.s[1]++;

  $scope.listLayer = [];
  var PATH = (cov_21pnj1a1gi.s[2]++, 'Layer');

  cov_21pnj1a1gi.s[3]++;
  $scope.layers = {
    list: [],
    loading: true,
    selectedItem: null,
    getAll: function getAll() {
      var _this = this;

      cov_21pnj1a1gi.s[4]++;

      this.loading = true;
      cov_21pnj1a1gi.s[5]++;
      $CRUDService.getAll(PATH, { get: 'all' }, function (data) {
        cov_21pnj1a1gi.f[1]++;
        cov_21pnj1a1gi.s[6]++;

        _this.list = data;
        cov_21pnj1a1gi.s[7]++;
        _this.loading = false;
      }, function () {
        cov_21pnj1a1gi.f[2]++;
        cov_21pnj1a1gi.s[8]++;

        _this.loading = false;
      });

      cov_21pnj1a1gi.s[9]++;
      $rootScope.getTileLayers(null, 'COMMON.STREETMAP', false, true);
    },
    addItem: function addItem() {
      cov_21pnj1a1gi.s[10]++;

      this.editItem({
        NAME_LAYER: '',
        URL_LAYER: '',
        TYPE_LAYER: 'TMS',
        id: 0
      });
    },
    editItem: function editItem(item) {
      cov_21pnj1a1gi.s[11]++;

      this.selectedItem = angular.copy(item);
      cov_21pnj1a1gi.s[12]++;
      $uibModal.open({
        templateUrl: 'app/views/layer/edit.html',
        controller: LayersCtrlCtrl_edit,
        scope: $scope,
        size: 'sm_',
        backdrop: 'static',
        windowClass: 'animated fadeInRight left-modal'
      });
    },
    deleteItem: function deleteItem(item) {
      var _this2 = this;

      cov_21pnj1a1gi.s[13]++;

      SweetAlert.swal({
        title: $translate.instant('COMMON.CONFIRM'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c8c8c8',
        confirmButtonText: $translate.instant('COMMON.NO'),
        cancelButtonText: $translate.instant('COMMON.YES'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_21pnj1a1gi.f[3]++;
        cov_21pnj1a1gi.s[14]++;

        if (isconfirm) {
          cov_21pnj1a1gi.b[0][0]++;
          cov_21pnj1a1gi.s[15]++;

          return;
        } else {
          cov_21pnj1a1gi.b[0][1]++;
        }
        cov_21pnj1a1gi.s[16]++;
        $scope.isloading = true;
        cov_21pnj1a1gi.s[17]++;
        $CRUDService.delet(PATH, { action: 'supp', id: item.id }, function () {
          cov_21pnj1a1gi.f[4]++;
          cov_21pnj1a1gi.s[18]++;

          _this2.getAll();
        });
      });
    },
    drawMap: function drawMap(layer) {
      cov_21pnj1a1gi.s[19]++;

      $timeout(function () {
        cov_21pnj1a1gi.f[5]++;

        var mymap = (cov_21pnj1a1gi.s[20]++, L.map('layer_map_' + layer.id, { zoomControl: false, attributionControl: false }).setView([0, 0], 0));
        /* if (layer.TYPE_LAYER == 'TMS') {
           L.tileLayer(layer.URL_LAYER).addTo(mymap);
         } else { */
        cov_21pnj1a1gi.s[21]++;
        L.tileLayer.wms(layer.URL_LAYER).addTo(mymap);
        // }
      }, 2000);
    }
  };
  cov_21pnj1a1gi.s[22]++;
  $scope.layers.getAll();
});

function LayersCtrlCtrl_edit($scope, $uibModalInstance, $translate, $filter, $CRUDService) {
  cov_21pnj1a1gi.f[6]++;

  var PATH = (cov_21pnj1a1gi.s[23]++, 'Layer');
  cov_21pnj1a1gi.s[24]++;
  $scope.error_code = 0;
  cov_21pnj1a1gi.s[25]++;
  $scope.validateItem = $scope.layers.selectedItem;

  cov_21pnj1a1gi.s[26]++;
  $scope.saveLayer = function () {
    cov_21pnj1a1gi.f[7]++;

    var validateItem = (cov_21pnj1a1gi.s[27]++, angular.copy($scope.validateItem));
    cov_21pnj1a1gi.s[28]++;
    validateItem.NAME_LAYER = ((cov_21pnj1a1gi.b[1][0]++, validateItem.NAME_LAYER) || (cov_21pnj1a1gi.b[1][1]++, '')).trim();
    cov_21pnj1a1gi.s[29]++;
    validateItem.URL_LAYER = ((cov_21pnj1a1gi.b[2][0]++, validateItem.URL_LAYER) || (cov_21pnj1a1gi.b[2][1]++, '')).trim();
    cov_21pnj1a1gi.s[30]++;
    $scope.error_code = 0;
    cov_21pnj1a1gi.s[31]++;
    if (validateItem.NAME_LAYER.length == 0) {
      cov_21pnj1a1gi.b[3][0]++;
      cov_21pnj1a1gi.s[32]++;

      return;
    } else {
      cov_21pnj1a1gi.b[3][1]++;
    }
    cov_21pnj1a1gi.s[33]++;
    if (((cov_21pnj1a1gi.b[5][0]++, validateItem.URL_LAYER) || (cov_21pnj1a1gi.b[5][1]++, '')).trim().length == 0) {
      cov_21pnj1a1gi.b[4][0]++;
      cov_21pnj1a1gi.s[34]++;

      return;
    } else {
      cov_21pnj1a1gi.b[4][1]++;
    }

    cov_21pnj1a1gi.s[35]++;
    try {
      var url = (cov_21pnj1a1gi.s[36]++, new URL(validateItem.URL_LAYER));
    } catch (e) {
      cov_21pnj1a1gi.s[37]++;

      $scope.error_code = 3;
      cov_21pnj1a1gi.s[38]++;
      return;
    }

    // Doublons
    var layerName = (cov_21pnj1a1gi.s[39]++, $scope.layers.list.find(function (item) {
      cov_21pnj1a1gi.f[8]++;
      cov_21pnj1a1gi.s[40]++;

      return (cov_21pnj1a1gi.b[6][0]++, $translate.instant(item.NAME_LAYER).toUpperCase() === $translate.instant(validateItem.NAME_LAYER).toUpperCase()) && (cov_21pnj1a1gi.b[6][1]++, item.id != validateItem.id);
    }));

    var layerUrl = (cov_21pnj1a1gi.s[41]++, $scope.layers.list.find(function (item) {
      cov_21pnj1a1gi.f[9]++;
      cov_21pnj1a1gi.s[42]++;

      return (cov_21pnj1a1gi.b[7][0]++, item.URL_LAYER.toUpperCase() == validateItem.URL_LAYER.toUpperCase()) && (cov_21pnj1a1gi.b[7][1]++, item.id != validateItem.id);
    }));

    cov_21pnj1a1gi.s[43]++;
    $scope.error_code = layerName ? (cov_21pnj1a1gi.b[8][0]++, 1) : (cov_21pnj1a1gi.b[8][1]++, layerUrl ? (cov_21pnj1a1gi.b[9][0]++, 2) : (cov_21pnj1a1gi.b[9][1]++, 0));

    cov_21pnj1a1gi.s[44]++;
    if ($scope.error_code > 0) {
      cov_21pnj1a1gi.b[10][0]++;
      cov_21pnj1a1gi.s[45]++;

      return;
    } else {
      cov_21pnj1a1gi.b[10][1]++;
    }

    cov_21pnj1a1gi.s[46]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function () {
      cov_21pnj1a1gi.f[10]++;
      cov_21pnj1a1gi.s[47]++;

      $scope.closeModal();
      cov_21pnj1a1gi.s[48]++;
      $scope.layers.getAll();
    });
  };
  cov_21pnj1a1gi.s[49]++;
  $scope.closeModal = function () {
    cov_21pnj1a1gi.f[11]++;
    cov_21pnj1a1gi.s[50]++;

    $uibModalInstance.dismiss('cancel');
  };
}