'use strict';

var cov_1ivwj4lohw = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/customDateTime.js',
      hash = '2e912a0f41e765509c34aa30f1518a8c3caa2ce5',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/customDateTime.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 209,
          column: 4
        }
      },
      '1': {
        start: {
          line: 3,
          column: 15
        },
        end: {
          line: 3,
          column: 37
        }
      },
      '2': {
        start: {
          line: 4,
          column: 21
        },
        end: {
          line: 4,
          column: 39
        }
      },
      '3': {
        start: {
          line: 6,
          column: 25
        },
        end: {
          line: 6,
          column: 38
        }
      },
      '4': {
        start: {
          line: 7,
          column: 22
        },
        end: {
          line: 7,
          column: 32
        }
      },
      '5': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 208,
          column: 4
        }
      },
      '6': {
        start: {
          line: 13,
          column: 19
        },
        end: {
          line: 13,
          column: 56
        }
      },
      '7': {
        start: {
          line: 14,
          column: 25
        },
        end: {
          line: 14,
          column: 65
        }
      },
      '8': {
        start: {
          line: 15,
          column: 20
        },
        end: {
          line: 15,
          column: 79
        }
      },
      '9': {
        start: {
          line: 16,
          column: 19
        },
        end: {
          line: 16,
          column: 41
        }
      },
      '10': {
        start: {
          line: 17,
          column: 20
        },
        end: {
          line: 17,
          column: 39
        }
      },
      '11': {
        start: {
          line: 18,
          column: 22
        },
        end: {
          line: 18,
          column: 97
        }
      },
      '12': {
        start: {
          line: 19,
          column: 19
        },
        end: {
          line: 19,
          column: 23
        }
      },
      '13': {
        start: {
          line: 20,
          column: 23
        },
        end: {
          line: 20,
          column: 36
        }
      },
      '14': {
        start: {
          line: 21,
          column: 23
        },
        end: {
          line: 21,
          column: 64
        }
      },
      '15': {
        start: {
          line: 22,
          column: 22
        },
        end: {
          line: 22,
          column: 26
        }
      },
      '16': {
        start: {
          line: 23,
          column: 20
        },
        end: {
          line: 23,
          column: 24
        }
      },
      '17': {
        start: {
          line: 24,
          column: 21
        },
        end: {
          line: 24,
          column: 25
        }
      },
      '18': {
        start: {
          line: 25,
          column: 20
        },
        end: {
          line: 25,
          column: 24
        }
      },
      '19': {
        start: {
          line: 26,
          column: 21
        },
        end: {
          line: 26,
          column: 25
        }
      },
      '20': {
        start: {
          line: 27,
          column: 23
        },
        end: {
          line: 27,
          column: 46
        }
      },
      '21': {
        start: {
          line: 28,
          column: 31
        },
        end: {
          line: 28,
          column: 63
        }
      },
      '22': {
        start: {
          line: 29,
          column: 23
        },
        end: {
          line: 29,
          column: 27
        }
      },
      '23': {
        start: {
          line: 30,
          column: 22
        },
        end: {
          line: 30,
          column: 27
        }
      },
      '24': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 35,
          column: 7
        }
      },
      '25': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 34,
          column: 31
        }
      },
      '26': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 37,
          column: 26
        }
      },
      '27': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 51
        }
      },
      '28': {
        start: {
          line: 44,
          column: 8
        },
        end: {
          line: 46,
          column: 9
        }
      },
      '29': {
        start: {
          line: 45,
          column: 10
        },
        end: {
          line: 45,
          column: 27
        }
      },
      '30': {
        start: {
          line: 47,
          column: 8
        },
        end: {
          line: 47,
          column: 25
        }
      },
      '31': {
        start: {
          line: 51,
          column: 8
        },
        end: {
          line: 51,
          column: 23
        }
      },
      '32': {
        start: {
          line: 52,
          column: 8
        },
        end: {
          line: 52,
          column: 52
        }
      },
      '33': {
        start: {
          line: 53,
          column: 8
        },
        end: {
          line: 53,
          column: 41
        }
      },
      '34': {
        start: {
          line: 57,
          column: 8
        },
        end: {
          line: 57,
          column: 23
        }
      },
      '35': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 58,
          column: 52
        }
      },
      '36': {
        start: {
          line: 59,
          column: 8
        },
        end: {
          line: 59,
          column: 41
        }
      },
      '37': {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 62,
          column: 42
        }
      },
      '38': {
        start: {
          line: 63,
          column: 6
        },
        end: {
          line: 63,
          column: 39
        }
      },
      '39': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 72,
          column: 7
        }
      },
      '40': {
        start: {
          line: 66,
          column: 8
        },
        end: {
          line: 66,
          column: 64
        }
      },
      '41': {
        start: {
          line: 68,
          column: 8
        },
        end: {
          line: 71,
          column: 10
        }
      },
      '42': {
        start: {
          line: 70,
          column: 10
        },
        end: {
          line: 70,
          column: 112
        }
      },
      '43': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 80,
          column: 7
        }
      },
      '44': {
        start: {
          line: 75,
          column: 8
        },
        end: {
          line: 75,
          column: 64
        }
      },
      '45': {
        start: {
          line: 77,
          column: 8
        },
        end: {
          line: 79,
          column: 10
        }
      },
      '46': {
        start: {
          line: 78,
          column: 10
        },
        end: {
          line: 78,
          column: 111
        }
      },
      '47': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 84,
          column: 7
        }
      },
      '48': {
        start: {
          line: 83,
          column: 8
        },
        end: {
          line: 83,
          column: 75
        }
      },
      '49': {
        start: {
          line: 87,
          column: 8
        },
        end: {
          line: 87,
          column: 50
        }
      },
      '50': {
        start: {
          line: 91,
          column: 8
        },
        end: {
          line: 91,
          column: 32
        }
      },
      '51': {
        start: {
          line: 92,
          column: 8
        },
        end: {
          line: 100,
          column: 9
        }
      },
      '52': {
        start: {
          line: 93,
          column: 10
        },
        end: {
          line: 93,
          column: 32
        }
      },
      '53': {
        start: {
          line: 94,
          column: 10
        },
        end: {
          line: 94,
          column: 36
        }
      },
      '54': {
        start: {
          line: 95,
          column: 10
        },
        end: {
          line: 95,
          column: 68
        }
      },
      '55': {
        start: {
          line: 96,
          column: 10
        },
        end: {
          line: 98,
          column: 11
        }
      },
      '56': {
        start: {
          line: 97,
          column: 12
        },
        end: {
          line: 97,
          column: 35
        }
      },
      '57': {
        start: {
          line: 99,
          column: 10
        },
        end: {
          line: 99,
          column: 28
        }
      },
      '58': {
        start: {
          line: 104,
          column: 8
        },
        end: {
          line: 107,
          column: 9
        }
      },
      '59': {
        start: {
          line: 105,
          column: 10
        },
        end: {
          line: 105,
          column: 26
        }
      },
      '60': {
        start: {
          line: 106,
          column: 10
        },
        end: {
          line: 106,
          column: 24
        }
      },
      '61': {
        start: {
          line: 108,
          column: 8
        },
        end: {
          line: 111,
          column: 9
        }
      },
      '62': {
        start: {
          line: 109,
          column: 10
        },
        end: {
          line: 109,
          column: 29
        }
      },
      '63': {
        start: {
          line: 110,
          column: 10
        },
        end: {
          line: 110,
          column: 27
        }
      },
      '64': {
        start: {
          line: 112,
          column: 8
        },
        end: {
          line: 112,
          column: 47
        }
      },
      '65': {
        start: {
          line: 115,
          column: 6
        },
        end: {
          line: 152,
          column: 7
        }
      },
      '66': {
        start: {
          line: 116,
          column: 8
        },
        end: {
          line: 151,
          column: 11
        }
      },
      '67': {
        start: {
          line: 117,
          column: 10
        },
        end: {
          line: 150,
          column: 11
        }
      },
      '68': {
        start: {
          line: 118,
          column: 12
        },
        end: {
          line: 149,
          column: 13
        }
      },
      '69': {
        start: {
          line: 123,
          column: 37
        },
        end: {
          line: 123,
          column: 42
        }
      },
      '70': {
        start: {
          line: 124,
          column: 14
        },
        end: {
          line: 127,
          column: 15
        }
      },
      '71': {
        start: {
          line: 125,
          column: 16
        },
        end: {
          line: 125,
          column: 37
        }
      },
      '72': {
        start: {
          line: 126,
          column: 16
        },
        end: {
          line: 126,
          column: 40
        }
      },
      '73': {
        start: {
          line: 128,
          column: 14
        },
        end: {
          line: 131,
          column: 15
        }
      },
      '74': {
        start: {
          line: 129,
          column: 16
        },
        end: {
          line: 129,
          column: 37
        }
      },
      '75': {
        start: {
          line: 130,
          column: 16
        },
        end: {
          line: 130,
          column: 40
        }
      },
      '76': {
        start: {
          line: 133,
          column: 14
        },
        end: {
          line: 135,
          column: 15
        }
      },
      '77': {
        start: {
          line: 134,
          column: 16
        },
        end: {
          line: 134,
          column: 45
        }
      },
      '78': {
        start: {
          line: 136,
          column: 14
        },
        end: {
          line: 138,
          column: 15
        }
      },
      '79': {
        start: {
          line: 137,
          column: 16
        },
        end: {
          line: 137,
          column: 45
        }
      },
      '80': {
        start: {
          line: 139,
          column: 14
        },
        end: {
          line: 139,
          column: 51
        }
      },
      '81': {
        start: {
          line: 141,
          column: 14
        },
        end: {
          line: 143,
          column: 15
        }
      },
      '82': {
        start: {
          line: 142,
          column: 16
        },
        end: {
          line: 142,
          column: 36
        }
      },
      '83': {
        start: {
          line: 144,
          column: 14
        },
        end: {
          line: 147,
          column: 15
        }
      },
      '84': {
        start: {
          line: 145,
          column: 16
        },
        end: {
          line: 145,
          column: 77
        }
      },
      '85': {
        start: {
          line: 146,
          column: 16
        },
        end: {
          line: 146,
          column: 41
        }
      },
      '86': {
        start: {
          line: 148,
          column: 14
        },
        end: {
          line: 148,
          column: 28
        }
      },
      '87': {
        start: {
          line: 155,
          column: 8
        },
        end: {
          line: 157,
          column: 9
        }
      },
      '88': {
        start: {
          line: 156,
          column: 10
        },
        end: {
          line: 156,
          column: 17
        }
      },
      '89': {
        start: {
          line: 158,
          column: 8
        },
        end: {
          line: 158,
          column: 50
        }
      },
      '90': {
        start: {
          line: 160,
          column: 8
        },
        end: {
          line: 160,
          column: 43
        }
      },
      '91': {
        start: {
          line: 161,
          column: 8
        },
        end: {
          line: 161,
          column: 24
        }
      },
      '92': {
        start: {
          line: 164,
          column: 8
        },
        end: {
          line: 182,
          column: 9
        }
      },
      '93': {
        start: {
          line: 165,
          column: 10
        },
        end: {
          line: 173,
          column: 13
        }
      },
      '94': {
        start: {
          line: 166,
          column: 12
        },
        end: {
          line: 166,
          column: 31
        }
      },
      '95': {
        start: {
          line: 167,
          column: 12
        },
        end: {
          line: 169,
          column: 13
        }
      },
      '96': {
        start: {
          line: 168,
          column: 14
        },
        end: {
          line: 168,
          column: 46
        }
      },
      '97': {
        start: {
          line: 170,
          column: 12
        },
        end: {
          line: 172,
          column: 13
        }
      },
      '98': {
        start: {
          line: 171,
          column: 14
        },
        end: {
          line: 171,
          column: 22
        }
      },
      '99': {
        start: {
          line: 175,
          column: 10
        },
        end: {
          line: 177,
          column: 13
        }
      },
      '100': {
        start: {
          line: 176,
          column: 12
        },
        end: {
          line: 176,
          column: 43
        }
      },
      '101': {
        start: {
          line: 179,
          column: 10
        },
        end: {
          line: 179,
          column: 39
        }
      },
      '102': {
        start: {
          line: 181,
          column: 10
        },
        end: {
          line: 181,
          column: 27
        }
      },
      '103': {
        start: {
          line: 186,
          column: 8
        },
        end: {
          line: 198,
          column: 9
        }
      },
      '104': {
        start: {
          line: 187,
          column: 22
        },
        end: {
          line: 187,
          column: 89
        }
      },
      '105': {
        start: {
          line: 188,
          column: 10
        },
        end: {
          line: 188,
          column: 94
        }
      },
      '106': {
        start: {
          line: 189,
          column: 10
        },
        end: {
          line: 189,
          column: 30
        }
      },
      '107': {
        start: {
          line: 192,
          column: 10
        },
        end: {
          line: 192,
          column: 73
        }
      },
      '108': {
        start: {
          line: 193,
          column: 10
        },
        end: {
          line: 193,
          column: 74
        }
      },
      '109': {
        start: {
          line: 194,
          column: 10
        },
        end: {
          line: 194,
          column: 35
        }
      },
      '110': {
        start: {
          line: 197,
          column: 10
        },
        end: {
          line: 197,
          column: 78
        }
      },
      '111': {
        start: {
          line: 199,
          column: 8
        },
        end: {
          line: 201,
          column: 11
        }
      },
      '112': {
        start: {
          line: 200,
          column: 10
        },
        end: {
          line: 200,
          column: 31
        }
      },
      '113': {
        start: {
          line: 203,
          column: 6
        },
        end: {
          line: 203,
          column: 40
        }
      },
      '114': {
        start: {
          line: 204,
          column: 6
        },
        end: {
          line: 204,
          column: 34
        }
      },
      '115': {
        start: {
          line: 205,
          column: 6
        },
        end: {
          line: 205,
          column: 43
        }
      },
      '116': {
        start: {
          line: 206,
          column: 6
        },
        end: {
          line: 206,
          column: 20
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 131
          },
          end: {
            line: 2,
            column: 132
          }
        },
        loc: {
          start: {
            line: 2,
            column: 212
          },
          end: {
            line: 209,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: 'formatter',
        decl: {
          start: {
            line: 39,
            column: 15
          },
          end: {
            line: 39,
            column: 24
          }
        },
        loc: {
          start: {
            line: 39,
            column: 32
          },
          end: {
            line: 41,
            column: 7
          }
        },
        line: 39
      },
      '2': {
        name: 'parser',
        decl: {
          start: {
            line: 43,
            column: 15
          },
          end: {
            line: 43,
            column: 21
          }
        },
        loc: {
          start: {
            line: 43,
            column: 33
          },
          end: {
            line: 48,
            column: 7
          }
        },
        line: 43
      },
      '3': {
        name: 'setMin',
        decl: {
          start: {
            line: 50,
            column: 15
          },
          end: {
            line: 50,
            column: 21
          }
        },
        loc: {
          start: {
            line: 50,
            column: 28
          },
          end: {
            line: 54,
            column: 7
          }
        },
        line: 50
      },
      '4': {
        name: 'setMax',
        decl: {
          start: {
            line: 56,
            column: 15
          },
          end: {
            line: 56,
            column: 21
          }
        },
        loc: {
          start: {
            line: 56,
            column: 28
          },
          end: {
            line: 60,
            column: 7
          }
        },
        line: 56
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 68,
            column: 34
          },
          end: {
            line: 68,
            column: 35
          }
        },
        loc: {
          start: {
            line: 68,
            column: 51
          },
          end: {
            line: 71,
            column: 9
          }
        },
        line: 68
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 77,
            column: 34
          },
          end: {
            line: 77,
            column: 35
          }
        },
        loc: {
          start: {
            line: 77,
            column: 51
          },
          end: {
            line: 79,
            column: 9
          }
        },
        line: 77
      },
      '7': {
        name: 'getTemplate',
        decl: {
          start: {
            line: 86,
            column: 15
          },
          end: {
            line: 86,
            column: 26
          }
        },
        loc: {
          start: {
            line: 86,
            column: 29
          },
          end: {
            line: 88,
            column: 7
          }
        },
        line: 86
      },
      '8': {
        name: 'updateInput',
        decl: {
          start: {
            line: 90,
            column: 15
          },
          end: {
            line: 90,
            column: 26
          }
        },
        loc: {
          start: {
            line: 90,
            column: 34
          },
          end: {
            line: 101,
            column: 7
          }
        },
        line: 90
      },
      '9': {
        name: 'clear',
        decl: {
          start: {
            line: 103,
            column: 15
          },
          end: {
            line: 103,
            column: 20
          }
        },
        loc: {
          start: {
            line: 103,
            column: 23
          },
          end: {
            line: 113,
            column: 7
          }
        },
        line: 103
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 116,
            column: 34
          },
          end: {
            line: 116,
            column: 35
          }
        },
        loc: {
          start: {
            line: 116,
            column: 62
          },
          end: {
            line: 151,
            column: 9
          }
        },
        line: 116
      },
      '11': {
        name: 'showPicker',
        decl: {
          start: {
            line: 154,
            column: 15
          },
          end: {
            line: 154,
            column: 25
          }
        },
        loc: {
          start: {
            line: 154,
            column: 28
          },
          end: {
            line: 202,
            column: 7
          }
        },
        line: 154
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 165,
            column: 31
          },
          end: {
            line: 165,
            column: 32
          }
        },
        loc: {
          start: {
            line: 165,
            column: 54
          },
          end: {
            line: 173,
            column: 11
          }
        },
        line: 165
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 175,
            column: 34
          },
          end: {
            line: 175,
            column: 35
          }
        },
        loc: {
          start: {
            line: 175,
            column: 40
          },
          end: {
            line: 177,
            column: 11
          }
        },
        line: 175
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 199,
            column: 33
          },
          end: {
            line: 199,
            column: 34
          }
        },
        loc: {
          start: {
            line: 199,
            column: 40
          },
          end: {
            line: 201,
            column: 9
          }
        },
        line: 199
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 13,
            column: 19
          },
          end: {
            line: 13,
            column: 56
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 13,
            column: 19
          },
          end: {
            line: 13,
            column: 31
          }
        }, {
          start: {
            line: 13,
            column: 35
          },
          end: {
            line: 13,
            column: 56
          }
        }],
        line: 13
      },
      '1': {
        loc: {
          start: {
            line: 15,
            column: 20
          },
          end: {
            line: 15,
            column: 79
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 15,
            column: 20
          },
          end: {
            line: 15,
            column: 46
          }
        }, {
          start: {
            line: 15,
            column: 50
          },
          end: {
            line: 15,
            column: 79
          }
        }],
        line: 15
      },
      '2': {
        loc: {
          start: {
            line: 16,
            column: 19
          },
          end: {
            line: 16,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 16,
            column: 19
          },
          end: {
            line: 16,
            column: 29
          }
        }, {
          start: {
            line: 16,
            column: 33
          },
          end: {
            line: 16,
            column: 41
          }
        }],
        line: 16
      },
      '3': {
        loc: {
          start: {
            line: 18,
            column: 22
          },
          end: {
            line: 18,
            column: 97
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 18,
            column: 40
          },
          end: {
            line: 18,
            column: 70
          }
        }, {
          start: {
            line: 18,
            column: 73
          },
          end: {
            line: 18,
            column: 97
          }
        }],
        line: 18
      },
      '4': {
        loc: {
          start: {
            line: 21,
            column: 23
          },
          end: {
            line: 21,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 21,
            column: 23
          },
          end: {
            line: 21,
            column: 37
          }
        }, {
          start: {
            line: 21,
            column: 41
          },
          end: {
            line: 21,
            column: 64
          }
        }],
        line: 21
      },
      '5': {
        loc: {
          start: {
            line: 27,
            column: 23
          },
          end: {
            line: 27,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 27,
            column: 23
          },
          end: {
            line: 27,
            column: 37
          }
        }, {
          start: {
            line: 27,
            column: 41
          },
          end: {
            line: 27,
            column: 46
          }
        }],
        line: 27
      },
      '6': {
        loc: {
          start: {
            line: 33,
            column: 6
          },
          end: {
            line: 35,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 33,
            column: 6
          },
          end: {
            line: 35,
            column: 7
          }
        }, {
          start: {
            line: 33,
            column: 6
          },
          end: {
            line: 35,
            column: 7
          }
        }],
        line: 33
      },
      '7': {
        loc: {
          start: {
            line: 44,
            column: 8
          },
          end: {
            line: 46,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 44,
            column: 8
          },
          end: {
            line: 46,
            column: 9
          }
        }, {
          start: {
            line: 44,
            column: 8
          },
          end: {
            line: 46,
            column: 9
          }
        }],
        line: 44
      },
      '8': {
        loc: {
          start: {
            line: 52,
            column: 24
          },
          end: {
            line: 52,
            column: 51
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 52,
            column: 31
          },
          end: {
            line: 52,
            column: 44
          }
        }, {
          start: {
            line: 52,
            column: 47
          },
          end: {
            line: 52,
            column: 51
          }
        }],
        line: 52
      },
      '9': {
        loc: {
          start: {
            line: 58,
            column: 24
          },
          end: {
            line: 58,
            column: 51
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 58,
            column: 31
          },
          end: {
            line: 58,
            column: 44
          }
        }, {
          start: {
            line: 58,
            column: 47
          },
          end: {
            line: 58,
            column: 51
          }
        }],
        line: 58
      },
      '10': {
        loc: {
          start: {
            line: 65,
            column: 6
          },
          end: {
            line: 72,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 65,
            column: 6
          },
          end: {
            line: 72,
            column: 7
          }
        }, {
          start: {
            line: 65,
            column: 6
          },
          end: {
            line: 72,
            column: 7
          }
        }],
        line: 65
      },
      '11': {
        loc: {
          start: {
            line: 70,
            column: 17
          },
          end: {
            line: 70,
            column: 111
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 70,
            column: 28
          },
          end: {
            line: 70,
            column: 104
          }
        }, {
          start: {
            line: 70,
            column: 107
          },
          end: {
            line: 70,
            column: 111
          }
        }],
        line: 70
      },
      '12': {
        loc: {
          start: {
            line: 70,
            column: 28
          },
          end: {
            line: 70,
            column: 104
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 70,
            column: 28
          },
          end: {
            line: 70,
            column: 50
          }
        }, {
          start: {
            line: 70,
            column: 55
          },
          end: {
            line: 70,
            column: 76
          }
        }, {
          start: {
            line: 70,
            column: 80
          },
          end: {
            line: 70,
            column: 103
          }
        }],
        line: 70
      },
      '13': {
        loc: {
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 80,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 80,
            column: 7
          }
        }, {
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 80,
            column: 7
          }
        }],
        line: 74
      },
      '14': {
        loc: {
          start: {
            line: 78,
            column: 17
          },
          end: {
            line: 78,
            column: 110
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 78,
            column: 28
          },
          end: {
            line: 78,
            column: 103
          }
        }, {
          start: {
            line: 78,
            column: 106
          },
          end: {
            line: 78,
            column: 110
          }
        }],
        line: 78
      },
      '15': {
        loc: {
          start: {
            line: 78,
            column: 28
          },
          end: {
            line: 78,
            column: 103
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 78,
            column: 28
          },
          end: {
            line: 78,
            column: 50
          }
        }, {
          start: {
            line: 78,
            column: 55
          },
          end: {
            line: 78,
            column: 76
          }
        }, {
          start: {
            line: 78,
            column: 80
          },
          end: {
            line: 78,
            column: 102
          }
        }],
        line: 78
      },
      '16': {
        loc: {
          start: {
            line: 82,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 82,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        }, {
          start: {
            line: 82,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        }],
        line: 82
      },
      '17': {
        loc: {
          start: {
            line: 92,
            column: 8
          },
          end: {
            line: 100,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 92,
            column: 8
          },
          end: {
            line: 100,
            column: 9
          }
        }, {
          start: {
            line: 92,
            column: 8
          },
          end: {
            line: 100,
            column: 9
          }
        }],
        line: 92
      },
      '18': {
        loc: {
          start: {
            line: 96,
            column: 10
          },
          end: {
            line: 98,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 96,
            column: 10
          },
          end: {
            line: 98,
            column: 11
          }
        }, {
          start: {
            line: 96,
            column: 10
          },
          end: {
            line: 98,
            column: 11
          }
        }],
        line: 96
      },
      '19': {
        loc: {
          start: {
            line: 104,
            column: 8
          },
          end: {
            line: 107,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 104,
            column: 8
          },
          end: {
            line: 107,
            column: 9
          }
        }, {
          start: {
            line: 104,
            column: 8
          },
          end: {
            line: 107,
            column: 9
          }
        }],
        line: 104
      },
      '20': {
        loc: {
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 111,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 111,
            column: 9
          }
        }, {
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 111,
            column: 9
          }
        }],
        line: 108
      },
      '21': {
        loc: {
          start: {
            line: 115,
            column: 6
          },
          end: {
            line: 152,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 115,
            column: 6
          },
          end: {
            line: 152,
            column: 7
          }
        }, {
          start: {
            line: 115,
            column: 6
          },
          end: {
            line: 152,
            column: 7
          }
        }],
        line: 115
      },
      '22': {
        loc: {
          start: {
            line: 117,
            column: 10
          },
          end: {
            line: 150,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 117,
            column: 10
          },
          end: {
            line: 150,
            column: 11
          }
        }, {
          start: {
            line: 117,
            column: 10
          },
          end: {
            line: 150,
            column: 11
          }
        }],
        line: 117
      },
      '23': {
        loc: {
          start: {
            line: 118,
            column: 12
          },
          end: {
            line: 149,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 118,
            column: 12
          },
          end: {
            line: 149,
            column: 13
          }
        }, {
          start: {
            line: 118,
            column: 12
          },
          end: {
            line: 149,
            column: 13
          }
        }],
        line: 118
      },
      '24': {
        loc: {
          start: {
            line: 124,
            column: 14
          },
          end: {
            line: 127,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 124,
            column: 14
          },
          end: {
            line: 127,
            column: 15
          }
        }, {
          start: {
            line: 124,
            column: 14
          },
          end: {
            line: 127,
            column: 15
          }
        }],
        line: 124
      },
      '25': {
        loc: {
          start: {
            line: 128,
            column: 14
          },
          end: {
            line: 131,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 128,
            column: 14
          },
          end: {
            line: 131,
            column: 15
          }
        }, {
          start: {
            line: 128,
            column: 14
          },
          end: {
            line: 131,
            column: 15
          }
        }],
        line: 128
      },
      '26': {
        loc: {
          start: {
            line: 133,
            column: 14
          },
          end: {
            line: 135,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 133,
            column: 14
          },
          end: {
            line: 135,
            column: 15
          }
        }, {
          start: {
            line: 133,
            column: 14
          },
          end: {
            line: 135,
            column: 15
          }
        }],
        line: 133
      },
      '27': {
        loc: {
          start: {
            line: 136,
            column: 14
          },
          end: {
            line: 138,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 136,
            column: 14
          },
          end: {
            line: 138,
            column: 15
          }
        }, {
          start: {
            line: 136,
            column: 14
          },
          end: {
            line: 138,
            column: 15
          }
        }],
        line: 136
      },
      '28': {
        loc: {
          start: {
            line: 139,
            column: 27
          },
          end: {
            line: 139,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 139,
            column: 27
          },
          end: {
            line: 139,
            column: 36
          }
        }, {
          start: {
            line: 139,
            column: 40
          },
          end: {
            line: 139,
            column: 50
          }
        }],
        line: 139
      },
      '29': {
        loc: {
          start: {
            line: 141,
            column: 14
          },
          end: {
            line: 143,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 141,
            column: 14
          },
          end: {
            line: 143,
            column: 15
          }
        }, {
          start: {
            line: 141,
            column: 14
          },
          end: {
            line: 143,
            column: 15
          }
        }],
        line: 141
      },
      '30': {
        loc: {
          start: {
            line: 144,
            column: 14
          },
          end: {
            line: 147,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 144,
            column: 14
          },
          end: {
            line: 147,
            column: 15
          }
        }, {
          start: {
            line: 144,
            column: 14
          },
          end: {
            line: 147,
            column: 15
          }
        }],
        line: 144
      },
      '31': {
        loc: {
          start: {
            line: 145,
            column: 40
          },
          end: {
            line: 145,
            column: 76
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 145,
            column: 40
          },
          end: {
            line: 145,
            column: 51
          }
        }, {
          start: {
            line: 145,
            column: 55
          },
          end: {
            line: 145,
            column: 76
          }
        }],
        line: 145
      },
      '32': {
        loc: {
          start: {
            line: 155,
            column: 8
          },
          end: {
            line: 157,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 155,
            column: 8
          },
          end: {
            line: 157,
            column: 9
          }
        }, {
          start: {
            line: 155,
            column: 8
          },
          end: {
            line: 157,
            column: 9
          }
        }],
        line: 155
      },
      '33': {
        loc: {
          start: {
            line: 164,
            column: 8
          },
          end: {
            line: 182,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 164,
            column: 8
          },
          end: {
            line: 182,
            column: 9
          }
        }, {
          start: {
            line: 164,
            column: 8
          },
          end: {
            line: 182,
            column: 9
          }
        }],
        line: 164
      },
      '34': {
        loc: {
          start: {
            line: 167,
            column: 12
          },
          end: {
            line: 169,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 167,
            column: 12
          },
          end: {
            line: 169,
            column: 13
          }
        }, {
          start: {
            line: 167,
            column: 12
          },
          end: {
            line: 169,
            column: 13
          }
        }],
        line: 167
      },
      '35': {
        loc: {
          start: {
            line: 170,
            column: 12
          },
          end: {
            line: 172,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 170,
            column: 12
          },
          end: {
            line: 172,
            column: 13
          }
        }, {
          start: {
            line: 170,
            column: 12
          },
          end: {
            line: 172,
            column: 13
          }
        }],
        line: 170
      },
      '36': {
        loc: {
          start: {
            line: 170,
            column: 16
          },
          end: {
            line: 170,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 170,
            column: 16
          },
          end: {
            line: 170,
            column: 23
          }
        }, {
          start: {
            line: 170,
            column: 27
          },
          end: {
            line: 170,
            column: 59
          }
        }],
        line: 170
      },
      '37': {
        loc: {
          start: {
            line: 186,
            column: 8
          },
          end: {
            line: 198,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 186,
            column: 8
          },
          end: {
            line: 198,
            column: 9
          }
        }, {
          start: {
            line: 186,
            column: 8
          },
          end: {
            line: 198,
            column: 9
          }
        }],
        line: 186
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1ivwj4lohw.s[0]++;

angular.module('app').directive('appDateTime', ['$compile', '$document', '$filter', 'dateTimeConfig', '$parse', 'datePickerUtils', function ($compile, $document, $filter, dateTimeConfig, $parse, datePickerUtils) {
  cov_1ivwj4lohw.f[0]++;

  var body = (cov_1ivwj4lohw.s[1]++, $document.find('body'));
  var dateFilter = (cov_1ivwj4lohw.s[2]++, $filter('mFormat'));

  var PRISTINE_CLASS = (cov_1ivwj4lohw.s[3]++, 'ng-pristine');
  var DIRTY_CLASS = (cov_1ivwj4lohw.s[4]++, 'ng-dirty');

  cov_1ivwj4lohw.s[5]++;
  return {
    require: 'ngModel',
    scope: true,
    link: function link(scope, element, attrs, ngModel) {
      var format = (cov_1ivwj4lohw.s[6]++, (cov_1ivwj4lohw.b[0][0]++, attrs.format) || (cov_1ivwj4lohw.b[0][1]++, dateTimeConfig.format));
      var parentForm = (cov_1ivwj4lohw.s[7]++, element.inheritedData('$formController'));
      var views = (cov_1ivwj4lohw.s[8]++, (cov_1ivwj4lohw.b[1][0]++, $parse(attrs.views)(scope)) || (cov_1ivwj4lohw.b[1][1]++, dateTimeConfig.views.concat()));
      var view = (cov_1ivwj4lohw.s[9]++, (cov_1ivwj4lohw.b[2][0]++, attrs.view) || (cov_1ivwj4lohw.b[2][1]++, views[0]));
      var index = (cov_1ivwj4lohw.s[10]++, views.indexOf(view));
      var dismiss = (cov_1ivwj4lohw.s[11]++, attrs.autoClose ? (cov_1ivwj4lohw.b[3][0]++, $parse(attrs.autoClose)(scope)) : (cov_1ivwj4lohw.b[3][1]++, dateTimeConfig.autoClose));
      var picker = (cov_1ivwj4lohw.s[12]++, null);
      var pickerID = (cov_1ivwj4lohw.s[13]++, element[0].id);
      var position = (cov_1ivwj4lohw.s[14]++, (cov_1ivwj4lohw.b[4][0]++, attrs.position) || (cov_1ivwj4lohw.b[4][1]++, dateTimeConfig.position));
      var container = (cov_1ivwj4lohw.s[15]++, null);
      var minDate = (cov_1ivwj4lohw.s[16]++, null);
      var minValid = (cov_1ivwj4lohw.s[17]++, null);
      var maxDate = (cov_1ivwj4lohw.s[18]++, null);
      var maxValid = (cov_1ivwj4lohw.s[19]++, null);
      var timezone = (cov_1ivwj4lohw.s[20]++, (cov_1ivwj4lohw.b[5][0]++, attrs.timezone) || (cov_1ivwj4lohw.b[5][1]++, false));
      var eventIsForPicker = (cov_1ivwj4lohw.s[21]++, datePickerUtils.eventIsForPicker);
      var dateChange = (cov_1ivwj4lohw.s[22]++, null);
      var shownOnce = (cov_1ivwj4lohw.s[23]++, false);
      var template = void 0;

      cov_1ivwj4lohw.s[24]++;
      if (index === -1) {
        cov_1ivwj4lohw.b[6][0]++;
        cov_1ivwj4lohw.s[25]++;

        views.splice(index, 1);
      } else {
        cov_1ivwj4lohw.b[6][1]++;
      }

      cov_1ivwj4lohw.s[26]++;
      views.unshift(view);

      function formatter(value) {
        cov_1ivwj4lohw.f[1]++;
        cov_1ivwj4lohw.s[27]++;

        return dateFilter(value, format, timezone);
      }

      function parser(viewValue) {
        cov_1ivwj4lohw.f[2]++;
        cov_1ivwj4lohw.s[28]++;

        if (viewValue.length === format.length) {
          cov_1ivwj4lohw.b[7][0]++;
          cov_1ivwj4lohw.s[29]++;

          return viewValue;
        } else {
          cov_1ivwj4lohw.b[7][1]++;
        }
        cov_1ivwj4lohw.s[30]++;
        return undefined;
      }

      function setMin(date) {
        cov_1ivwj4lohw.f[3]++;
        cov_1ivwj4lohw.s[31]++;

        minDate = date;
        cov_1ivwj4lohw.s[32]++;
        attrs.minDate = date ? (cov_1ivwj4lohw.b[8][0]++, date.format()) : (cov_1ivwj4lohw.b[8][1]++, date);
        cov_1ivwj4lohw.s[33]++;
        minValid = moment.isMoment(date);
      }

      function setMax(date) {
        cov_1ivwj4lohw.f[4]++;
        cov_1ivwj4lohw.s[34]++;

        maxDate = date;
        cov_1ivwj4lohw.s[35]++;
        attrs.maxDate = date ? (cov_1ivwj4lohw.b[9][0]++, date.format()) : (cov_1ivwj4lohw.b[9][1]++, date);
        cov_1ivwj4lohw.s[36]++;
        maxValid = moment.isMoment(date);
      }

      cov_1ivwj4lohw.s[37]++;
      ngModel.$formatters.push(formatter);
      cov_1ivwj4lohw.s[38]++;
      ngModel.$parsers.unshift(parser);

      cov_1ivwj4lohw.s[39]++;
      if (angular.isDefined(attrs.minDate)) {
        cov_1ivwj4lohw.b[10][0]++;
        cov_1ivwj4lohw.s[40]++;

        setMin(datePickerUtils.findParam(scope, attrs.minDate));

        cov_1ivwj4lohw.s[41]++;
        ngModel.$validators.min = function (value) {
          cov_1ivwj4lohw.f[5]++;
          cov_1ivwj4lohw.s[42]++;

          // If we don't have a min / max value, then any value is valid.
          return minValid ? (cov_1ivwj4lohw.b[11][0]++, (cov_1ivwj4lohw.b[12][0]++, moment.isMoment(value)) && ((cov_1ivwj4lohw.b[12][1]++, minDate.isSame(value)) || (cov_1ivwj4lohw.b[12][2]++, minDate.isBefore(value)))) : (cov_1ivwj4lohw.b[11][1]++, true);
        };
      } else {
        cov_1ivwj4lohw.b[10][1]++;
      }

      cov_1ivwj4lohw.s[43]++;
      if (angular.isDefined(attrs.maxDate)) {
        cov_1ivwj4lohw.b[13][0]++;
        cov_1ivwj4lohw.s[44]++;

        setMax(datePickerUtils.findParam(scope, attrs.maxDate));

        cov_1ivwj4lohw.s[45]++;
        ngModel.$validators.max = function (value) {
          cov_1ivwj4lohw.f[6]++;
          cov_1ivwj4lohw.s[46]++;

          return maxValid ? (cov_1ivwj4lohw.b[14][0]++, (cov_1ivwj4lohw.b[15][0]++, moment.isMoment(value)) && ((cov_1ivwj4lohw.b[15][1]++, maxDate.isSame(value)) || (cov_1ivwj4lohw.b[15][2]++, maxDate.isAfter(value)))) : (cov_1ivwj4lohw.b[14][1]++, true);
        };
      } else {
        cov_1ivwj4lohw.b[13][1]++;
      }

      cov_1ivwj4lohw.s[47]++;
      if (angular.isDefined(attrs.dateChange)) {
        cov_1ivwj4lohw.b[16][0]++;
        cov_1ivwj4lohw.s[48]++;

        dateChange = datePickerUtils.findFunction(scope, attrs.dateChange);
      } else {
        cov_1ivwj4lohw.b[16][1]++;
      }

      function getTemplate() {
        cov_1ivwj4lohw.f[7]++;
        cov_1ivwj4lohw.s[49]++;

        template = dateTimeConfig.template(attrs);
      }

      function updateInput(event) {
        cov_1ivwj4lohw.f[8]++;
        cov_1ivwj4lohw.s[50]++;

        event.stopPropagation();
        cov_1ivwj4lohw.s[51]++;
        if (ngModel.$pristine) {
          cov_1ivwj4lohw.b[17][0]++;
          cov_1ivwj4lohw.s[52]++;

          ngModel.$dirty = true;
          cov_1ivwj4lohw.s[53]++;
          ngModel.$pristine = false;
          cov_1ivwj4lohw.s[54]++;
          element.removeClass(PRISTINE_CLASS).addClass(DIRTY_CLASS);
          cov_1ivwj4lohw.s[55]++;
          if (parentForm) {
            cov_1ivwj4lohw.b[18][0]++;
            cov_1ivwj4lohw.s[56]++;

            parentForm.$setDirty();
          } else {
            cov_1ivwj4lohw.b[18][1]++;
          }
          cov_1ivwj4lohw.s[57]++;
          ngModel.$render();
        } else {
          cov_1ivwj4lohw.b[17][1]++;
        }
      }

      function clear() {
        cov_1ivwj4lohw.f[9]++;
        cov_1ivwj4lohw.s[58]++;

        if (picker) {
          cov_1ivwj4lohw.b[19][0]++;
          cov_1ivwj4lohw.s[59]++;

          picker.remove();
          cov_1ivwj4lohw.s[60]++;
          picker = null;
        } else {
          cov_1ivwj4lohw.b[19][1]++;
        }
        cov_1ivwj4lohw.s[61]++;
        if (container) {
          cov_1ivwj4lohw.b[20][0]++;
          cov_1ivwj4lohw.s[62]++;

          container.remove();
          cov_1ivwj4lohw.s[63]++;
          container = null;
        } else {
          cov_1ivwj4lohw.b[20][1]++;
        }
        cov_1ivwj4lohw.s[64]++;
        element[0].removeAttribute('readonly');
      }

      cov_1ivwj4lohw.s[65]++;
      if (pickerID) {
        cov_1ivwj4lohw.b[21][0]++;
        cov_1ivwj4lohw.s[66]++;

        scope.$on('pickerUpdate', function (event, pickerIDs, data) {
          cov_1ivwj4lohw.f[10]++;
          cov_1ivwj4lohw.s[67]++;

          if (eventIsForPicker(pickerIDs, pickerID)) {
            cov_1ivwj4lohw.b[22][0]++;
            cov_1ivwj4lohw.s[68]++;

            if (picker) {
              // Need to handle situation where the data changed but the picker is currently open.
              // To handle this, we can create the inner picker with a random ID, then forward
              // any events received to it.

              cov_1ivwj4lohw.b[23][0]++;
            } else {
              cov_1ivwj4lohw.b[23][1]++;

              var validateRequired = (cov_1ivwj4lohw.s[69]++, false);
              cov_1ivwj4lohw.s[70]++;
              if (angular.isDefined(data.minDate)) {
                cov_1ivwj4lohw.b[24][0]++;
                cov_1ivwj4lohw.s[71]++;

                setMin(data.minDate);
                cov_1ivwj4lohw.s[72]++;
                validateRequired = true;
              } else {
                cov_1ivwj4lohw.b[24][1]++;
              }
              cov_1ivwj4lohw.s[73]++;
              if (angular.isDefined(data.maxDate)) {
                cov_1ivwj4lohw.b[25][0]++;
                cov_1ivwj4lohw.s[74]++;

                setMax(data.maxDate);
                cov_1ivwj4lohw.s[75]++;
                validateRequired = true;
              } else {
                cov_1ivwj4lohw.b[25][1]++;
              }

              cov_1ivwj4lohw.s[76]++;
              if (angular.isDefined(data.minView)) {
                cov_1ivwj4lohw.b[26][0]++;
                cov_1ivwj4lohw.s[77]++;

                attrs.minView = data.minView;
              } else {
                cov_1ivwj4lohw.b[26][1]++;
              }
              cov_1ivwj4lohw.s[78]++;
              if (angular.isDefined(data.maxView)) {
                cov_1ivwj4lohw.b[27][0]++;
                cov_1ivwj4lohw.s[79]++;

                attrs.maxView = data.maxView;
              } else {
                cov_1ivwj4lohw.b[27][1]++;
              }
              cov_1ivwj4lohw.s[80]++;
              attrs.view = (cov_1ivwj4lohw.b[28][0]++, data.view) || (cov_1ivwj4lohw.b[28][1]++, attrs.view);

              cov_1ivwj4lohw.s[81]++;
              if (validateRequired) {
                cov_1ivwj4lohw.b[29][0]++;
                cov_1ivwj4lohw.s[82]++;

                ngModel.$validate();
              } else {
                cov_1ivwj4lohw.b[29][1]++;
              }
              cov_1ivwj4lohw.s[83]++;
              if (angular.isDefined(data.format)) {
                cov_1ivwj4lohw.b[30][0]++;
                cov_1ivwj4lohw.s[84]++;

                format = attrs.format = (cov_1ivwj4lohw.b[31][0]++, data.format) || (cov_1ivwj4lohw.b[31][1]++, dateTimeConfig.format);
                cov_1ivwj4lohw.s[85]++;
                ngModel.$modelValue = -1; // Triggers formatters. This value will be discarded.
              } else {
                cov_1ivwj4lohw.b[30][1]++;
              }
              cov_1ivwj4lohw.s[86]++;
              getTemplate();
            }
          } else {
            cov_1ivwj4lohw.b[22][1]++;
          }
        });
      } else {
        cov_1ivwj4lohw.b[21][1]++;
      }

      function showPicker() {
        cov_1ivwj4lohw.f[11]++;
        cov_1ivwj4lohw.s[87]++;

        if (picker) {
          cov_1ivwj4lohw.b[32][0]++;
          cov_1ivwj4lohw.s[88]++;

          return;
        } else {
          cov_1ivwj4lohw.b[32][1]++;
        }
        cov_1ivwj4lohw.s[89]++;
        element[0].setAttribute('readonly', true);
        // create picker element
        cov_1ivwj4lohw.s[90]++;
        picker = $compile(template)(scope);
        cov_1ivwj4lohw.s[91]++;
        scope.$digest();

        // If the picker has already been shown before then we shouldn't be binding to events, as these events are already bound to in this scope.
        cov_1ivwj4lohw.s[92]++;
        if (!shownOnce) {
          cov_1ivwj4lohw.b[33][0]++;
          cov_1ivwj4lohw.s[93]++;

          scope.$on('setDate', function (event, date, view) {
            cov_1ivwj4lohw.f[12]++;
            cov_1ivwj4lohw.s[94]++;

            updateInput(event);
            cov_1ivwj4lohw.s[95]++;
            if (dateChange) {
              cov_1ivwj4lohw.b[34][0]++;
              cov_1ivwj4lohw.s[96]++;

              dateChange(attrs.ngModel, date);
            } else {
              cov_1ivwj4lohw.b[34][1]++;
            }
            cov_1ivwj4lohw.s[97]++;
            if ((cov_1ivwj4lohw.b[36][0]++, dismiss) && (cov_1ivwj4lohw.b[36][1]++, views[views.length - 1] === view)) {
              cov_1ivwj4lohw.b[35][0]++;
              cov_1ivwj4lohw.s[98]++;

              clear();
            } else {
              cov_1ivwj4lohw.b[35][1]++;
            }
          });

          cov_1ivwj4lohw.s[99]++;
          scope.$on('hidePicker', function () {
            cov_1ivwj4lohw.f[13]++;
            cov_1ivwj4lohw.s[100]++;

            element.triggerHandler('blur');
          });

          cov_1ivwj4lohw.s[101]++;
          scope.$on('$destroy', clear);

          cov_1ivwj4lohw.s[102]++;
          shownOnce = true;
        } else {
          cov_1ivwj4lohw.b[33][1]++;
        }

        // move picker below input element

        cov_1ivwj4lohw.s[103]++;
        if (position === 'absolute') {
          cov_1ivwj4lohw.b[37][0]++;

          var pos = (cov_1ivwj4lohw.s[104]++, angular.extend(element.offset(), { height: element[0].offsetHeight }));
          cov_1ivwj4lohw.s[105]++;
          picker.css({ top: pos.top + pos.height, left: pos.left, display: 'block', position: position });
          cov_1ivwj4lohw.s[106]++;
          body.append(picker);
        } else {
          cov_1ivwj4lohw.b[37][1]++;
          cov_1ivwj4lohw.s[107]++;

          // relative
          container = angular.element('<div date-picker-wrapper></div>');
          cov_1ivwj4lohw.s[108]++;
          element[0].parentElement.insertBefore(container[0], element[0]);
          cov_1ivwj4lohw.s[109]++;
          container.append(picker);
          //          this approach doesn't work
          //          element.before(picker);
          cov_1ivwj4lohw.s[110]++;
          picker.css({ top: element[0].offsetHeight + 'px', display: 'block' });
        }
        cov_1ivwj4lohw.s[111]++;
        picker.bind('mousedown', function (evt) {
          cov_1ivwj4lohw.f[14]++;
          cov_1ivwj4lohw.s[112]++;

          evt.preventDefault();
        });
      }
      cov_1ivwj4lohw.s[113]++;
      element.bind('focus', showPicker);
      cov_1ivwj4lohw.s[114]++;
      element.bind('blur', clear);
      cov_1ivwj4lohw.s[115]++;
      element.bind('keypress', showPicker);
      cov_1ivwj4lohw.s[116]++;
      getTemplate();
    }
  };
}]);