'use strict';

var cov_uato2nufc = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/customdashboard/home_page/homePageCtrl.js',
      hash = '8dda16dbbaa83d3c64ec01b1f1a8b5e3deb55c51',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/customdashboard/home_page/homePageCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 464,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 26
        }
      },
      '2': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 3,
          column: 32
        }
      },
      '3': {
        start: {
          line: 4,
          column: 33
        },
        end: {
          line: 4,
          column: 57
        }
      },
      '4': {
        start: {
          line: 5,
          column: 20
        },
        end: {
          line: 5,
          column: 26
        }
      },
      '5': {
        start: {
          line: 6,
          column: 23
        },
        end: {
          line: 6,
          column: 31
        }
      },
      '6': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 7,
          column: 26
        }
      },
      '7': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 27,
          column: 4
        }
      },
      '8': {
        start: {
          line: 29,
          column: 2
        },
        end: {
          line: 29,
          column: 32
        }
      },
      '9': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 34,
          column: 4
        }
      },
      '10': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 31,
          column: 28
        }
      },
      '11': {
        start: {
          line: 32,
          column: 4
        },
        end: {
          line: 32,
          column: 29
        }
      },
      '12': {
        start: {
          line: 33,
          column: 4
        },
        end: {
          line: 33,
          column: 51
        }
      },
      '13': {
        start: {
          line: 36,
          column: 2
        },
        end: {
          line: 40,
          column: 4
        }
      },
      '14': {
        start: {
          line: 41,
          column: 2
        },
        end: {
          line: 41,
          column: 26
        }
      },
      '15': {
        start: {
          line: 43,
          column: 2
        },
        end: {
          line: 48,
          column: 5
        }
      },
      '16': {
        start: {
          line: 44,
          column: 18
        },
        end: {
          line: 44,
          column: 77
        }
      },
      '17': {
        start: {
          line: 45,
          column: 4
        },
        end: {
          line: 47,
          column: 5
        }
      },
      '18': {
        start: {
          line: 46,
          column: 6
        },
        end: {
          line: 46,
          column: 66
        }
      },
      '19': {
        start: {
          line: 50,
          column: 2
        },
        end: {
          line: 64,
          column: 5
        }
      },
      '20': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 51,
          column: 35
        }
      },
      '21': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 52,
          column: 34
        }
      },
      '22': {
        start: {
          line: 53,
          column: 4
        },
        end: {
          line: 60,
          column: 6
        }
      },
      '23': {
        start: {
          line: 62,
          column: 4
        },
        end: {
          line: 62,
          column: 49
        }
      },
      '24': {
        start: {
          line: 63,
          column: 4
        },
        end: {
          line: 63,
          column: 49
        }
      },
      '25': {
        start: {
          line: 65,
          column: 2
        },
        end: {
          line: 71,
          column: 5
        }
      },
      '26': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 66,
          column: 34
        }
      },
      '27': {
        start: {
          line: 67,
          column: 4
        },
        end: {
          line: 67,
          column: 121
        }
      },
      '28': {
        start: {
          line: 68,
          column: 4
        },
        end: {
          line: 68,
          column: 65
        }
      },
      '29': {
        start: {
          line: 69,
          column: 4
        },
        end: {
          line: 69,
          column: 49
        }
      },
      '30': {
        start: {
          line: 70,
          column: 4
        },
        end: {
          line: 70,
          column: 49
        }
      },
      '31': {
        start: {
          line: 73,
          column: 2
        },
        end: {
          line: 75,
          column: 5
        }
      },
      '32': {
        start: {
          line: 76,
          column: 2
        },
        end: {
          line: 95,
          column: 5
        }
      },
      '33': {
        start: {
          line: 77,
          column: 4
        },
        end: {
          line: 94,
          column: 7
        }
      },
      '34': {
        start: {
          line: 88,
          column: 6
        },
        end: {
          line: 90,
          column: 7
        }
      },
      '35': {
        start: {
          line: 89,
          column: 8
        },
        end: {
          line: 89,
          column: 15
        }
      },
      '36': {
        start: {
          line: 91,
          column: 6
        },
        end: {
          line: 93,
          column: 9
        }
      },
      '37': {
        start: {
          line: 92,
          column: 8
        },
        end: {
          line: 92,
          column: 24
        }
      },
      '38': {
        start: {
          line: 97,
          column: 2
        },
        end: {
          line: 121,
          column: 5
        }
      },
      '39': {
        start: {
          line: 98,
          column: 4
        },
        end: {
          line: 98,
          column: 50
        }
      },
      '40': {
        start: {
          line: 99,
          column: 4
        },
        end: {
          line: 99,
          column: 50
        }
      },
      '41': {
        start: {
          line: 100,
          column: 4
        },
        end: {
          line: 100,
          column: 35
        }
      },
      '42': {
        start: {
          line: 101,
          column: 4
        },
        end: {
          line: 106,
          column: 5
        }
      },
      '43': {
        start: {
          line: 102,
          column: 6
        },
        end: {
          line: 105,
          column: 7
        }
      },
      '44': {
        start: {
          line: 103,
          column: 8
        },
        end: {
          line: 103,
          column: 42
        }
      },
      '45': {
        start: {
          line: 104,
          column: 8
        },
        end: {
          line: 104,
          column: 15
        }
      },
      '46': {
        start: {
          line: 108,
          column: 4
        },
        end: {
          line: 111,
          column: 5
        }
      },
      '47': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 109,
          column: 54
        }
      },
      '48': {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 110,
          column: 13
        }
      },
      '49': {
        start: {
          line: 113,
          column: 4
        },
        end: {
          line: 120,
          column: 6
        }
      },
      '50': {
        start: {
          line: 123,
          column: 2
        },
        end: {
          line: 133,
          column: 5
        }
      },
      '51': {
        start: {
          line: 124,
          column: 18
        },
        end: {
          line: 124,
          column: 20
        }
      },
      '52': {
        start: {
          line: 125,
          column: 4
        },
        end: {
          line: 129,
          column: 5
        }
      },
      '53': {
        start: {
          line: 126,
          column: 6
        },
        end: {
          line: 128,
          column: 7
        }
      },
      '54': {
        start: {
          line: 127,
          column: 8
        },
        end: {
          line: 127,
          column: 27
        }
      },
      '55': {
        start: {
          line: 130,
          column: 4
        },
        end: {
          line: 132,
          column: 7
        }
      },
      '56': {
        start: {
          line: 135,
          column: 2
        },
        end: {
          line: 207,
          column: 5
        }
      },
      '57': {
        start: {
          line: 136,
          column: 4
        },
        end: {
          line: 136,
          column: 32
        }
      },
      '58': {
        start: {
          line: 138,
          column: 24
        },
        end: {
          line: 138,
          column: 58
        }
      },
      '59': {
        start: {
          line: 140,
          column: 24
        },
        end: {
          line: 140,
          column: 49
        }
      },
      '60': {
        start: {
          line: 141,
          column: 22
        },
        end: {
          line: 143,
          column: 65
        }
      },
      '61': {
        start: {
          line: 142,
          column: 6
        },
        end: {
          line: 142,
          column: 53
        }
      },
      '62': {
        start: {
          line: 143,
          column: 23
        },
        end: {
          line: 143,
          column: 51
        }
      },
      '63': {
        start: {
          line: 145,
          column: 16
        },
        end: {
          line: 147,
          column: 65
        }
      },
      '64': {
        start: {
          line: 146,
          column: 6
        },
        end: {
          line: 146,
          column: 53
        }
      },
      '65': {
        start: {
          line: 147,
          column: 23
        },
        end: {
          line: 147,
          column: 51
        }
      },
      '66': {
        start: {
          line: 149,
          column: 4
        },
        end: {
          line: 206,
          column: 7
        }
      },
      '67': {
        start: {
          line: 150,
          column: 6
        },
        end: {
          line: 154,
          column: 9
        }
      },
      '68': {
        start: {
          line: 151,
          column: 18
        },
        end: {
          line: 151,
          column: 73
        }
      },
      '69': {
        start: {
          line: 152,
          column: 8
        },
        end: {
          line: 152,
          column: 23
        }
      },
      '70': {
        start: {
          line: 153,
          column: 8
        },
        end: {
          line: 153,
          column: 17
        }
      },
      '71': {
        start: {
          line: 157,
          column: 6
        },
        end: {
          line: 164,
          column: 7
        }
      },
      '72': {
        start: {
          line: 158,
          column: 22
        },
        end: {
          line: 160,
          column: 10
        }
      },
      '73': {
        start: {
          line: 159,
          column: 10
        },
        end: {
          line: 159,
          column: 51
        }
      },
      '74': {
        start: {
          line: 161,
          column: 8
        },
        end: {
          line: 163,
          column: 9
        }
      },
      '75': {
        start: {
          line: 162,
          column: 10
        },
        end: {
          line: 162,
          column: 35
        }
      },
      '76': {
        start: {
          line: 167,
          column: 6
        },
        end: {
          line: 172,
          column: 7
        }
      },
      '77': {
        start: {
          line: 168,
          column: 20
        },
        end: {
          line: 170,
          column: 10
        }
      },
      '78': {
        start: {
          line: 169,
          column: 10
        },
        end: {
          line: 169,
          column: 44
        }
      },
      '79': {
        start: {
          line: 171,
          column: 8
        },
        end: {
          line: 171,
          column: 57
        }
      },
      '80': {
        start: {
          line: 176,
          column: 21
        },
        end: {
          line: 176,
          column: 95
        }
      },
      '81': {
        start: {
          line: 176,
          column: 51
        },
        end: {
          line: 176,
          column: 86
        }
      },
      '82': {
        start: {
          line: 177,
          column: 16
        },
        end: {
          line: 177,
          column: 17
        }
      },
      '83': {
        start: {
          line: 178,
          column: 16
        },
        end: {
          line: 178,
          column: 17
        }
      },
      '84': {
        start: {
          line: 179,
          column: 6
        },
        end: {
          line: 181,
          column: 7
        }
      },
      '85': {
        start: {
          line: 180,
          column: 8
        },
        end: {
          line: 180,
          column: 40
        }
      },
      '86': {
        start: {
          line: 183,
          column: 31
        },
        end: {
          line: 188,
          column: 8
        }
      },
      '87': {
        start: {
          line: 184,
          column: 20
        },
        end: {
          line: 186,
          column: 10
        }
      },
      '88': {
        start: {
          line: 185,
          column: 10
        },
        end: {
          line: 185,
          column: 44
        }
      },
      '89': {
        start: {
          line: 187,
          column: 8
        },
        end: {
          line: 187,
          column: 31
        }
      },
      '90': {
        start: {
          line: 189,
          column: 6
        },
        end: {
          line: 204,
          column: 7
        }
      },
      '91': {
        start: {
          line: 190,
          column: 8
        },
        end: {
          line: 199,
          column: 11
        }
      },
      '92': {
        start: {
          line: 200,
          column: 8
        },
        end: {
          line: 200,
          column: 28
        }
      },
      '93': {
        start: {
          line: 201,
          column: 8
        },
        end: {
          line: 203,
          column: 9
        }
      },
      '94': {
        start: {
          line: 202,
          column: 10
        },
        end: {
          line: 202,
          column: 19
        }
      },
      '95': {
        start: {
          line: 205,
          column: 6
        },
        end: {
          line: 205,
          column: 55
        }
      },
      '96': {
        start: {
          line: 209,
          column: 2
        },
        end: {
          line: 216,
          column: 5
        }
      },
      '97': {
        start: {
          line: 210,
          column: 18
        },
        end: {
          line: 210,
          column: 64
        }
      },
      '98': {
        start: {
          line: 211,
          column: 4
        },
        end: {
          line: 215,
          column: 5
        }
      },
      '99': {
        start: {
          line: 212,
          column: 6
        },
        end: {
          line: 212,
          column: 49
        }
      },
      '100': {
        start: {
          line: 214,
          column: 6
        },
        end: {
          line: 214,
          column: 50
        }
      },
      '101': {
        start: {
          line: 218,
          column: 2
        },
        end: {
          line: 222,
          column: 5
        }
      },
      '102': {
        start: {
          line: 219,
          column: 4
        },
        end: {
          line: 219,
          column: 39
        }
      },
      '103': {
        start: {
          line: 220,
          column: 4
        },
        end: {
          line: 220,
          column: 35
        }
      },
      '104': {
        start: {
          line: 221,
          column: 4
        },
        end: {
          line: 221,
          column: 99
        }
      },
      '105': {
        start: {
          line: 221,
          column: 84
        },
        end: {
          line: 221,
          column: 97
        }
      },
      '106': {
        start: {
          line: 224,
          column: 2
        },
        end: {
          line: 247,
          column: 5
        }
      },
      '107': {
        start: {
          line: 225,
          column: 17
        },
        end: {
          line: 225,
          column: 55
        }
      },
      '108': {
        start: {
          line: 227,
          column: 4
        },
        end: {
          line: 229,
          column: 7
        }
      },
      '109': {
        start: {
          line: 228,
          column: 6
        },
        end: {
          line: 228,
          column: 36
        }
      },
      '110': {
        start: {
          line: 230,
          column: 4
        },
        end: {
          line: 237,
          column: 8
        }
      },
      '111': {
        start: {
          line: 230,
          column: 54
        },
        end: {
          line: 237,
          column: 5
        }
      },
      '112': {
        start: {
          line: 238,
          column: 4
        },
        end: {
          line: 238,
          column: 40
        }
      },
      '113': {
        start: {
          line: 239,
          column: 4
        },
        end: {
          line: 246,
          column: 7
        }
      },
      '114': {
        start: {
          line: 249,
          column: 2
        },
        end: {
          line: 251,
          column: 5
        }
      },
      '115': {
        start: {
          line: 253,
          column: 2
        },
        end: {
          line: 304,
          column: 5
        }
      },
      '116': {
        start: {
          line: 254,
          column: 24
        },
        end: {
          line: 256,
          column: 6
        }
      },
      '117': {
        start: {
          line: 255,
          column: 6
        },
        end: {
          line: 255,
          column: 90
        }
      },
      '118': {
        start: {
          line: 257,
          column: 23
        },
        end: {
          line: 257,
          column: 62
        }
      },
      '119': {
        start: {
          line: 257,
          column: 47
        },
        end: {
          line: 257,
          column: 61
        }
      },
      '120': {
        start: {
          line: 259,
          column: 21
        },
        end: {
          line: 259,
          column: 23
        }
      },
      '121': {
        start: {
          line: 261,
          column: 4
        },
        end: {
          line: 297,
          column: 5
        }
      },
      '122': {
        start: {
          line: 262,
          column: 6
        },
        end: {
          line: 262,
          column: 50
        }
      },
      '123': {
        start: {
          line: 264,
          column: 29
        },
        end: {
          line: 264,
          column: 87
        }
      },
      '124': {
        start: {
          line: 264,
          column: 57
        },
        end: {
          line: 264,
          column: 85
        }
      },
      '125': {
        start: {
          line: 265,
          column: 6
        },
        end: {
          line: 267,
          column: 7
        }
      },
      '126': {
        start: {
          line: 266,
          column: 8
        },
        end: {
          line: 266,
          column: 17
        }
      },
      '127': {
        start: {
          line: 268,
          column: 6
        },
        end: {
          line: 268,
          column: 71
        }
      },
      '128': {
        start: {
          line: 270,
          column: 25
        },
        end: {
          line: 270,
          column: 27
        }
      },
      '129': {
        start: {
          line: 271,
          column: 25
        },
        end: {
          line: 271,
          column: 27
        }
      },
      '130': {
        start: {
          line: 272,
          column: 20
        },
        end: {
          line: 272,
          column: 22
        }
      },
      '131': {
        start: {
          line: 274,
          column: 6
        },
        end: {
          line: 286,
          column: 7
        }
      },
      '132': {
        start: {
          line: 275,
          column: 8
        },
        end: {
          line: 278,
          column: 9
        }
      },
      '133': {
        start: {
          line: 276,
          column: 10
        },
        end: {
          line: 276,
          column: 30
        }
      },
      '134': {
        start: {
          line: 277,
          column: 10
        },
        end: {
          line: 277,
          column: 19
        }
      },
      '135': {
        start: {
          line: 279,
          column: 18
        },
        end: {
          line: 279,
          column: 49
        }
      },
      '136': {
        start: {
          line: 281,
          column: 8
        },
        end: {
          line: 285,
          column: 9
        }
      },
      '137': {
        start: {
          line: 282,
          column: 10
        },
        end: {
          line: 282,
          column: 35
        }
      },
      '138': {
        start: {
          line: 284,
          column: 10
        },
        end: {
          line: 284,
          column: 35
        }
      },
      '139': {
        start: {
          line: 288,
          column: 6
        },
        end: {
          line: 290,
          column: 7
        }
      },
      '140': {
        start: {
          line: 289,
          column: 8
        },
        end: {
          line: 289,
          column: 116
        }
      },
      '141': {
        start: {
          line: 291,
          column: 6
        },
        end: {
          line: 293,
          column: 7
        }
      },
      '142': {
        start: {
          line: 292,
          column: 8
        },
        end: {
          line: 292,
          column: 116
        }
      },
      '143': {
        start: {
          line: 294,
          column: 6
        },
        end: {
          line: 296,
          column: 7
        }
      },
      '144': {
        start: {
          line: 295,
          column: 8
        },
        end: {
          line: 295,
          column: 106
        }
      },
      '145': {
        start: {
          line: 300,
          column: 23
        },
        end: {
          line: 300,
          column: 25
        }
      },
      '146': {
        start: {
          line: 302,
          column: 4
        },
        end: {
          line: 302,
          column: 27
        }
      },
      '147': {
        start: {
          line: 303,
          column: 4
        },
        end: {
          line: 303,
          column: 40
        }
      },
      '148': {
        start: {
          line: 305,
          column: 2
        },
        end: {
          line: 305,
          column: 25
        }
      },
      '149': {
        start: {
          line: 306,
          column: 2
        },
        end: {
          line: 378,
          column: 4
        }
      },
      '150': {
        start: {
          line: 307,
          column: 4
        },
        end: {
          line: 309,
          column: 5
        }
      },
      '151': {
        start: {
          line: 308,
          column: 6
        },
        end: {
          line: 308,
          column: 13
        }
      },
      '152': {
        start: {
          line: 311,
          column: 4
        },
        end: {
          line: 377,
          column: 7
        }
      },
      '153': {
        start: {
          line: 312,
          column: 6
        },
        end: {
          line: 312,
          column: 56
        }
      },
      '154': {
        start: {
          line: 314,
          column: 6
        },
        end: {
          line: 314,
          column: 45
        }
      },
      '155': {
        start: {
          line: 315,
          column: 6
        },
        end: {
          line: 319,
          column: 9
        }
      },
      '156': {
        start: {
          line: 316,
          column: 8
        },
        end: {
          line: 316,
          column: 31
        }
      },
      '157': {
        start: {
          line: 317,
          column: 8
        },
        end: {
          line: 317,
          column: 70
        }
      },
      '158': {
        start: {
          line: 318,
          column: 8
        },
        end: {
          line: 318,
          column: 23
        }
      },
      '159': {
        start: {
          line: 320,
          column: 32
        },
        end: {
          line: 320,
          column: 36
        }
      },
      '160': {
        start: {
          line: 321,
          column: 6
        },
        end: {
          line: 323,
          column: 7
        }
      },
      '161': {
        start: {
          line: 322,
          column: 8
        },
        end: {
          line: 322,
          column: 90
        }
      },
      '162': {
        start: {
          line: 325,
          column: 6
        },
        end: {
          line: 327,
          column: 7
        }
      },
      '163': {
        start: {
          line: 326,
          column: 8
        },
        end: {
          line: 326,
          column: 55
        }
      },
      '164': {
        start: {
          line: 329,
          column: 26
        },
        end: {
          line: 329,
          column: 72
        }
      },
      '165': {
        start: {
          line: 329,
          column: 58
        },
        end: {
          line: 329,
          column: 71
        }
      },
      '166': {
        start: {
          line: 330,
          column: 24
        },
        end: {
          line: 332,
          column: 67
        }
      },
      '167': {
        start: {
          line: 331,
          column: 8
        },
        end: {
          line: 331,
          column: 55
        }
      },
      '168': {
        start: {
          line: 332,
          column: 25
        },
        end: {
          line: 332,
          column: 53
        }
      },
      '169': {
        start: {
          line: 334,
          column: 18
        },
        end: {
          line: 336,
          column: 67
        }
      },
      '170': {
        start: {
          line: 335,
          column: 8
        },
        end: {
          line: 335,
          column: 55
        }
      },
      '171': {
        start: {
          line: 336,
          column: 25
        },
        end: {
          line: 336,
          column: 53
        }
      },
      '172': {
        start: {
          line: 338,
          column: 6
        },
        end: {
          line: 375,
          column: 9
        }
      },
      '173': {
        start: {
          line: 339,
          column: 8
        },
        end: {
          line: 343,
          column: 11
        }
      },
      '174': {
        start: {
          line: 340,
          column: 20
        },
        end: {
          line: 340,
          column: 75
        }
      },
      '175': {
        start: {
          line: 341,
          column: 10
        },
        end: {
          line: 341,
          column: 25
        }
      },
      '176': {
        start: {
          line: 342,
          column: 10
        },
        end: {
          line: 342,
          column: 19
        }
      },
      '177': {
        start: {
          line: 344,
          column: 8
        },
        end: {
          line: 344,
          column: 30
        }
      },
      '178': {
        start: {
          line: 345,
          column: 8
        },
        end: {
          line: 363,
          column: 9
        }
      },
      '179': {
        start: {
          line: 346,
          column: 22
        },
        end: {
          line: 348,
          column: 12
        }
      },
      '180': {
        start: {
          line: 347,
          column: 12
        },
        end: {
          line: 347,
          column: 44
        }
      },
      '181': {
        start: {
          line: 349,
          column: 10
        },
        end: {
          line: 349,
          column: 43
        }
      },
      '182': {
        start: {
          line: 350,
          column: 10
        },
        end: {
          line: 350,
          column: 59
        }
      },
      '183': {
        start: {
          line: 352,
          column: 28
        },
        end: {
          line: 357,
          column: 12
        }
      },
      '184': {
        start: {
          line: 353,
          column: 12
        },
        end: {
          line: 355,
          column: 13
        }
      },
      '185': {
        start: {
          line: 354,
          column: 14
        },
        end: {
          line: 354,
          column: 27
        }
      },
      '186': {
        start: {
          line: 356,
          column: 12
        },
        end: {
          line: 356,
          column: 57
        }
      },
      '187': {
        start: {
          line: 358,
          column: 10
        },
        end: {
          line: 360,
          column: 11
        }
      },
      '188': {
        start: {
          line: 359,
          column: 12
        },
        end: {
          line: 359,
          column: 61
        }
      },
      '189': {
        start: {
          line: 365,
          column: 8
        },
        end: {
          line: 367,
          column: 9
        }
      },
      '190': {
        start: {
          line: 366,
          column: 10
        },
        end: {
          line: 366,
          column: 40
        }
      },
      '191': {
        start: {
          line: 373,
          column: 8
        },
        end: {
          line: 373,
          column: 53
        }
      },
      '192': {
        start: {
          line: 374,
          column: 8
        },
        end: {
          line: 374,
          column: 63
        }
      },
      '193': {
        start: {
          line: 379,
          column: 2
        },
        end: {
          line: 395,
          column: 4
        }
      },
      '194': {
        start: {
          line: 380,
          column: 4
        },
        end: {
          line: 386,
          column: 6
        }
      },
      '195': {
        start: {
          line: 387,
          column: 4
        },
        end: {
          line: 394,
          column: 7
        }
      },
      '196': {
        start: {
          line: 397,
          column: 2
        },
        end: {
          line: 413,
          column: 3
        }
      },
      '197': {
        start: {
          line: 398,
          column: 4
        },
        end: {
          line: 398,
          column: 61
        }
      },
      '198': {
        start: {
          line: 399,
          column: 4
        },
        end: {
          line: 403,
          column: 7
        }
      },
      '199': {
        start: {
          line: 400,
          column: 6
        },
        end: {
          line: 400,
          column: 40
        }
      },
      '200': {
        start: {
          line: 401,
          column: 6
        },
        end: {
          line: 401,
          column: 29
        }
      },
      '201': {
        start: {
          line: 402,
          column: 6
        },
        end: {
          line: 402,
          column: 36
        }
      },
      '202': {
        start: {
          line: 405,
          column: 4
        },
        end: {
          line: 412,
          column: 7
        }
      },
      '203': {
        start: {
          line: 406,
          column: 6
        },
        end: {
          line: 406,
          column: 33
        }
      },
      '204': {
        start: {
          line: 407,
          column: 6
        },
        end: {
          line: 411,
          column: 9
        }
      },
      '205': {
        start: {
          line: 408,
          column: 8
        },
        end: {
          line: 408,
          column: 42
        }
      },
      '206': {
        start: {
          line: 409,
          column: 8
        },
        end: {
          line: 409,
          column: 31
        }
      },
      '207': {
        start: {
          line: 410,
          column: 8
        },
        end: {
          line: 410,
          column: 38
        }
      },
      '208': {
        start: {
          line: 415,
          column: 2
        },
        end: {
          line: 419,
          column: 5
        }
      },
      '209': {
        start: {
          line: 416,
          column: 4
        },
        end: {
          line: 416,
          column: 35
        }
      },
      '210': {
        start: {
          line: 417,
          column: 4
        },
        end: {
          line: 417,
          column: 50
        }
      },
      '211': {
        start: {
          line: 418,
          column: 4
        },
        end: {
          line: 418,
          column: 50
        }
      },
      '212': {
        start: {
          line: 421,
          column: 2
        },
        end: {
          line: 458,
          column: 4
        }
      },
      '213': {
        start: {
          line: 422,
          column: 17
        },
        end: {
          line: 422,
          column: 42
        }
      },
      '214': {
        start: {
          line: 424,
          column: 4
        },
        end: {
          line: 424,
          column: 35
        }
      },
      '215': {
        start: {
          line: 425,
          column: 4
        },
        end: {
          line: 425,
          column: 50
        }
      },
      '216': {
        start: {
          line: 426,
          column: 4
        },
        end: {
          line: 426,
          column: 50
        }
      },
      '217': {
        start: {
          line: 430,
          column: 4
        },
        end: {
          line: 457,
          column: 5
        }
      },
      '218': {
        start: {
          line: 431,
          column: 6
        },
        end: {
          line: 435,
          column: 7
        }
      },
      '219': {
        start: {
          line: 432,
          column: 8
        },
        end: {
          line: 432,
          column: 30
        }
      },
      '220': {
        start: {
          line: 434,
          column: 8
        },
        end: {
          line: 434,
          column: 57
        }
      },
      '221': {
        start: {
          line: 437,
          column: 6
        },
        end: {
          line: 456,
          column: 9
        }
      },
      '222': {
        start: {
          line: 438,
          column: 8
        },
        end: {
          line: 438,
          column: 36
        }
      },
      '223': {
        start: {
          line: 439,
          column: 8
        },
        end: {
          line: 443,
          column: 9
        }
      },
      '224': {
        start: {
          line: 440,
          column: 10
        },
        end: {
          line: 440,
          column: 71
        }
      },
      '225': {
        start: {
          line: 441,
          column: 10
        },
        end: {
          line: 441,
          column: 58
        }
      },
      '226': {
        start: {
          line: 442,
          column: 10
        },
        end: {
          line: 442,
          column: 17
        }
      },
      '227': {
        start: {
          line: 445,
          column: 8
        },
        end: {
          line: 451,
          column: 9
        }
      },
      '228': {
        start: {
          line: 446,
          column: 10
        },
        end: {
          line: 450,
          column: 11
        }
      },
      '229': {
        start: {
          line: 447,
          column: 12
        },
        end: {
          line: 447,
          column: 59
        }
      },
      '230': {
        start: {
          line: 448,
          column: 12
        },
        end: {
          line: 448,
          column: 46
        }
      },
      '231': {
        start: {
          line: 449,
          column: 12
        },
        end: {
          line: 449,
          column: 19
        }
      },
      '232': {
        start: {
          line: 452,
          column: 8
        },
        end: {
          line: 455,
          column: 9
        }
      },
      '233': {
        start: {
          line: 453,
          column: 10
        },
        end: {
          line: 453,
          column: 71
        }
      },
      '234': {
        start: {
          line: 454,
          column: 10
        },
        end: {
          line: 454,
          column: 58
        }
      },
      '235': {
        start: {
          line: 459,
          column: 2
        },
        end: {
          line: 459,
          column: 18
        }
      },
      '236': {
        start: {
          line: 460,
          column: 2
        },
        end: {
          line: 460,
          column: 23
        }
      },
      '237': {
        start: {
          line: 461,
          column: 2
        },
        end: {
          line: 463,
          column: 5
        }
      },
      '238': {
        start: {
          line: 462,
          column: 4
        },
        end: {
          line: 462,
          column: 28
        }
      },
      '239': {
        start: {
          line: 467,
          column: 15
        },
        end: {
          line: 467,
          column: 26
        }
      },
      '240': {
        start: {
          line: 468,
          column: 2
        },
        end: {
          line: 491,
          column: 4
        }
      },
      '241': {
        start: {
          line: 469,
          column: 17
        },
        end: {
          line: 469,
          column: 59
        }
      },
      '242': {
        start: {
          line: 470,
          column: 21
        },
        end: {
          line: 470,
          column: 23
        }
      },
      '243': {
        start: {
          line: 471,
          column: 16
        },
        end: {
          line: 471,
          column: 46
        }
      },
      '244': {
        start: {
          line: 472,
          column: 4
        },
        end: {
          line: 472,
          column: 31
        }
      },
      '245': {
        start: {
          line: 473,
          column: 4
        },
        end: {
          line: 473,
          column: 38
        }
      },
      '246': {
        start: {
          line: 474,
          column: 4
        },
        end: {
          line: 474,
          column: 44
        }
      },
      '247': {
        start: {
          line: 476,
          column: 19
        },
        end: {
          line: 476,
          column: 78
        }
      },
      '248': {
        start: {
          line: 478,
          column: 4
        },
        end: {
          line: 480,
          column: 5
        }
      },
      '249': {
        start: {
          line: 479,
          column: 6
        },
        end: {
          line: 479,
          column: 25
        }
      },
      '250': {
        start: {
          line: 482,
          column: 4
        },
        end: {
          line: 490,
          column: 7
        }
      },
      '251': {
        start: {
          line: 483,
          column: 6
        },
        end: {
          line: 488,
          column: 7
        }
      },
      '252': {
        start: {
          line: 484,
          column: 8
        },
        end: {
          line: 484,
          column: 28
        }
      },
      '253': {
        start: {
          line: 485,
          column: 8
        },
        end: {
          line: 485,
          column: 55
        }
      },
      '254': {
        start: {
          line: 487,
          column: 8
        },
        end: {
          line: 487,
          column: 28
        }
      },
      '255': {
        start: {
          line: 489,
          column: 6
        },
        end: {
          line: 489,
          column: 26
        }
      },
      '256': {
        start: {
          line: 493,
          column: 2
        },
        end: {
          line: 495,
          column: 4
        }
      },
      '257': {
        start: {
          line: 494,
          column: 4
        },
        end: {
          line: 494,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 58
          },
          end: {
            line: 1,
            column: 59
          }
        },
        loc: {
          start: {
            line: 1,
            column: 140
          },
          end: {
            line: 464,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 30,
            column: 26
          },
          end: {
            line: 30,
            column: 27
          }
        },
        loc: {
          start: {
            line: 30,
            column: 44
          },
          end: {
            line: 34,
            column: 3
          }
        },
        line: 30
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 43,
            column: 24
          },
          end: {
            line: 43,
            column: 25
          }
        },
        loc: {
          start: {
            line: 43,
            column: 35
          },
          end: {
            line: 48,
            column: 3
          }
        },
        line: 43
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 50,
            column: 25
          },
          end: {
            line: 50,
            column: 26
          }
        },
        loc: {
          start: {
            line: 50,
            column: 31
          },
          end: {
            line: 64,
            column: 3
          }
        },
        line: 50
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 65,
            column: 26
          },
          end: {
            line: 65,
            column: 27
          }
        },
        loc: {
          start: {
            line: 65,
            column: 34
          },
          end: {
            line: 71,
            column: 3
          }
        },
        line: 65
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 73,
            column: 30
          },
          end: {
            line: 73,
            column: 31
          }
        },
        loc: {
          start: {
            line: 73,
            column: 36
          },
          end: {
            line: 75,
            column: 3
          }
        },
        line: 73
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 76,
            column: 28
          },
          end: {
            line: 76,
            column: 29
          }
        },
        loc: {
          start: {
            line: 76,
            column: 34
          },
          end: {
            line: 95,
            column: 3
          }
        },
        line: 76
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 87,
            column: 7
          },
          end: {
            line: 87,
            column: 8
          }
        },
        loc: {
          start: {
            line: 87,
            column: 20
          },
          end: {
            line: 94,
            column: 5
          }
        },
        line: 87
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 91,
            column: 82
          },
          end: {
            line: 91,
            column: 83
          }
        },
        loc: {
          start: {
            line: 91,
            column: 90
          },
          end: {
            line: 93,
            column: 7
          }
        },
        line: 91
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 97,
            column: 28
          },
          end: {
            line: 97,
            column: 29
          }
        },
        loc: {
          start: {
            line: 97,
            column: 34
          },
          end: {
            line: 121,
            column: 3
          }
        },
        line: 97
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 123,
            column: 27
          },
          end: {
            line: 123,
            column: 28
          }
        },
        loc: {
          start: {
            line: 123,
            column: 33
          },
          end: {
            line: 133,
            column: 3
          }
        },
        line: 123
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 130,
            column: 87
          },
          end: {
            line: 130,
            column: 88
          }
        },
        loc: {
          start: {
            line: 130,
            column: 93
          },
          end: {
            line: 132,
            column: 5
          }
        },
        line: 130
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 135,
            column: 20
          },
          end: {
            line: 135,
            column: 21
          }
        },
        loc: {
          start: {
            line: 135,
            column: 26
          },
          end: {
            line: 207,
            column: 3
          }
        },
        line: 135
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 141,
            column: 62
          },
          end: {
            line: 141,
            column: 63
          }
        },
        loc: {
          start: {
            line: 141,
            column: 73
          },
          end: {
            line: 143,
            column: 5
          }
        },
        line: 141
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 143,
            column: 11
          },
          end: {
            line: 143,
            column: 12
          }
        },
        loc: {
          start: {
            line: 143,
            column: 23
          },
          end: {
            line: 143,
            column: 51
          }
        },
        line: 143
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 145,
            column: 52
          },
          end: {
            line: 145,
            column: 53
          }
        },
        loc: {
          start: {
            line: 145,
            column: 63
          },
          end: {
            line: 147,
            column: 5
          }
        },
        line: 145
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 147,
            column: 11
          },
          end: {
            line: 147,
            column: 12
          }
        },
        loc: {
          start: {
            line: 147,
            column: 23
          },
          end: {
            line: 147,
            column: 51
          }
        },
        line: 147
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 149,
            column: 77
          },
          end: {
            line: 149,
            column: 78
          }
        },
        loc: {
          start: {
            line: 149,
            column: 93
          },
          end: {
            line: 206,
            column: 5
          }
        },
        line: 149
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 150,
            column: 38
          },
          end: {
            line: 150,
            column: 39
          }
        },
        loc: {
          start: {
            line: 150,
            column: 49
          },
          end: {
            line: 154,
            column: 7
          }
        },
        line: 150
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 158,
            column: 42
          },
          end: {
            line: 158,
            column: 43
          }
        },
        loc: {
          start: {
            line: 158,
            column: 53
          },
          end: {
            line: 160,
            column: 9
          }
        },
        line: 158
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 168,
            column: 40
          },
          end: {
            line: 168,
            column: 41
          }
        },
        loc: {
          start: {
            line: 168,
            column: 49
          },
          end: {
            line: 170,
            column: 9
          }
        },
        line: 168
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 176,
            column: 37
          },
          end: {
            line: 176,
            column: 38
          }
        },
        loc: {
          start: {
            line: 176,
            column: 51
          },
          end: {
            line: 176,
            column: 86
          }
        },
        line: 176
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 183,
            column: 51
          },
          end: {
            line: 183,
            column: 52
          }
        },
        loc: {
          start: {
            line: 183,
            column: 62
          },
          end: {
            line: 188,
            column: 7
          }
        },
        line: 183
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 184,
            column: 39
          },
          end: {
            line: 184,
            column: 40
          }
        },
        loc: {
          start: {
            line: 184,
            column: 52
          },
          end: {
            line: 186,
            column: 9
          }
        },
        line: 184
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 209,
            column: 26
          },
          end: {
            line: 209,
            column: 27
          }
        },
        loc: {
          start: {
            line: 209,
            column: 37
          },
          end: {
            line: 216,
            column: 3
          }
        },
        line: 209
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 218,
            column: 21
          },
          end: {
            line: 218,
            column: 22
          }
        },
        loc: {
          start: {
            line: 218,
            column: 27
          },
          end: {
            line: 222,
            column: 3
          }
        },
        line: 218
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 221,
            column: 71
          },
          end: {
            line: 221,
            column: 72
          }
        },
        loc: {
          start: {
            line: 221,
            column: 84
          },
          end: {
            line: 221,
            column: 97
          }
        },
        line: 221
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 224,
            column: 36
          },
          end: {
            line: 224,
            column: 37
          }
        },
        loc: {
          start: {
            line: 224,
            column: 42
          },
          end: {
            line: 247,
            column: 3
          }
        },
        line: 224
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 227,
            column: 43
          },
          end: {
            line: 227,
            column: 44
          }
        },
        loc: {
          start: {
            line: 227,
            column: 56
          },
          end: {
            line: 229,
            column: 5
          }
        },
        line: 227
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 230,
            column: 40
          },
          end: {
            line: 230,
            column: 41
          }
        },
        loc: {
          start: {
            line: 230,
            column: 54
          },
          end: {
            line: 237,
            column: 5
          }
        },
        line: 230
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 249,
            column: 23
          },
          end: {
            line: 249,
            column: 24
          }
        },
        loc: {
          start: {
            line: 249,
            column: 31
          },
          end: {
            line: 251,
            column: 3
          }
        },
        line: 249
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 253,
            column: 26
          },
          end: {
            line: 253,
            column: 27
          }
        },
        loc: {
          start: {
            line: 253,
            column: 32
          },
          end: {
            line: 304,
            column: 3
          }
        },
        line: 253
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 254,
            column: 93
          },
          end: {
            line: 254,
            column: 94
          }
        },
        loc: {
          start: {
            line: 254,
            column: 117
          },
          end: {
            line: 256,
            column: 5
          }
        },
        line: 254
      },
      '33': {
        name: '(anonymous_33)',
        decl: {
          start: {
            line: 257,
            column: 39
          },
          end: {
            line: 257,
            column: 40
          }
        },
        loc: {
          start: {
            line: 257,
            column: 47
          },
          end: {
            line: 257,
            column: 61
          }
        },
        line: 257
      },
      '34': {
        name: '(anonymous_34)',
        decl: {
          start: {
            line: 261,
            column: 52
          },
          end: {
            line: 261,
            column: 53
          }
        },
        loc: {
          start: {
            line: 261,
            column: 64
          },
          end: {
            line: 263,
            column: 5
          }
        },
        line: 261
      },
      '35': {
        name: '(anonymous_35)',
        decl: {
          start: {
            line: 264,
            column: 48
          },
          end: {
            line: 264,
            column: 49
          }
        },
        loc: {
          start: {
            line: 264,
            column: 57
          },
          end: {
            line: 264,
            column: 85
          }
        },
        line: 264
      },
      '36': {
        name: '(anonymous_36)',
        decl: {
          start: {
            line: 306,
            column: 27
          },
          end: {
            line: 306,
            column: 28
          }
        },
        loc: {
          start: {
            line: 306,
            column: 54
          },
          end: {
            line: 378,
            column: 3
          }
        },
        line: 306
      },
      '37': {
        name: '(anonymous_37)',
        decl: {
          start: {
            line: 311,
            column: 70
          },
          end: {
            line: 311,
            column: 71
          }
        },
        loc: {
          start: {
            line: 311,
            column: 78
          },
          end: {
            line: 377,
            column: 5
          }
        },
        line: 311
      },
      '38': {
        name: '(anonymous_38)',
        decl: {
          start: {
            line: 315,
            column: 42
          },
          end: {
            line: 315,
            column: 43
          }
        },
        loc: {
          start: {
            line: 315,
            column: 53
          },
          end: {
            line: 319,
            column: 7
          }
        },
        line: 315
      },
      '39': {
        name: '(anonymous_39)',
        decl: {
          start: {
            line: 329,
            column: 45
          },
          end: {
            line: 329,
            column: 46
          }
        },
        loc: {
          start: {
            line: 329,
            column: 58
          },
          end: {
            line: 329,
            column: 71
          }
        },
        line: 329
      },
      '40': {
        name: '(anonymous_40)',
        decl: {
          start: {
            line: 330,
            column: 64
          },
          end: {
            line: 330,
            column: 65
          }
        },
        loc: {
          start: {
            line: 330,
            column: 75
          },
          end: {
            line: 332,
            column: 7
          }
        },
        line: 330
      },
      '41': {
        name: '(anonymous_41)',
        decl: {
          start: {
            line: 332,
            column: 13
          },
          end: {
            line: 332,
            column: 14
          }
        },
        loc: {
          start: {
            line: 332,
            column: 25
          },
          end: {
            line: 332,
            column: 53
          }
        },
        line: 332
      },
      '42': {
        name: '(anonymous_42)',
        decl: {
          start: {
            line: 334,
            column: 54
          },
          end: {
            line: 334,
            column: 55
          }
        },
        loc: {
          start: {
            line: 334,
            column: 65
          },
          end: {
            line: 336,
            column: 7
          }
        },
        line: 334
      },
      '43': {
        name: '(anonymous_43)',
        decl: {
          start: {
            line: 336,
            column: 13
          },
          end: {
            line: 336,
            column: 14
          }
        },
        loc: {
          start: {
            line: 336,
            column: 25
          },
          end: {
            line: 336,
            column: 53
          }
        },
        line: 336
      },
      '44': {
        name: '(anonymous_44)',
        decl: {
          start: {
            line: 338,
            column: 79
          },
          end: {
            line: 338,
            column: 80
          }
        },
        loc: {
          start: {
            line: 338,
            column: 95
          },
          end: {
            line: 375,
            column: 7
          }
        },
        line: 338
      },
      '45': {
        name: '(anonymous_45)',
        decl: {
          start: {
            line: 339,
            column: 40
          },
          end: {
            line: 339,
            column: 41
          }
        },
        loc: {
          start: {
            line: 339,
            column: 51
          },
          end: {
            line: 343,
            column: 9
          }
        },
        line: 339
      },
      '46': {
        name: '(anonymous_46)',
        decl: {
          start: {
            line: 346,
            column: 42
          },
          end: {
            line: 346,
            column: 43
          }
        },
        loc: {
          start: {
            line: 346,
            column: 51
          },
          end: {
            line: 348,
            column: 11
          }
        },
        line: 346
      },
      '47': {
        name: '(anonymous_47)',
        decl: {
          start: {
            line: 352,
            column: 54
          },
          end: {
            line: 352,
            column: 55
          }
        },
        loc: {
          start: {
            line: 352,
            column: 66
          },
          end: {
            line: 357,
            column: 11
          }
        },
        line: 352
      },
      '48': {
        name: '(anonymous_48)',
        decl: {
          start: {
            line: 379,
            column: 19
          },
          end: {
            line: 379,
            column: 20
          }
        },
        loc: {
          start: {
            line: 379,
            column: 31
          },
          end: {
            line: 395,
            column: 3
          }
        },
        line: 379
      },
      '49': {
        name: '(anonymous_49)',
        decl: {
          start: {
            line: 399,
            column: 108
          },
          end: {
            line: 399,
            column: 109
          }
        },
        loc: {
          start: {
            line: 399,
            column: 124
          },
          end: {
            line: 403,
            column: 5
          }
        },
        line: 399
      },
      '50': {
        name: '(anonymous_50)',
        decl: {
          start: {
            line: 405,
            column: 58
          },
          end: {
            line: 405,
            column: 59
          }
        },
        loc: {
          start: {
            line: 405,
            column: 67
          },
          end: {
            line: 412,
            column: 5
          }
        },
        line: 405
      },
      '51': {
        name: '(anonymous_51)',
        decl: {
          start: {
            line: 407,
            column: 63
          },
          end: {
            line: 407,
            column: 64
          }
        },
        loc: {
          start: {
            line: 407,
            column: 79
          },
          end: {
            line: 411,
            column: 7
          }
        },
        line: 407
      },
      '52': {
        name: '(anonymous_52)',
        decl: {
          start: {
            line: 415,
            column: 23
          },
          end: {
            line: 415,
            column: 24
          }
        },
        loc: {
          start: {
            line: 415,
            column: 29
          },
          end: {
            line: 419,
            column: 3
          }
        },
        line: 415
      },
      '53': {
        name: '(anonymous_53)',
        decl: {
          start: {
            line: 421,
            column: 18
          },
          end: {
            line: 421,
            column: 19
          }
        },
        loc: {
          start: {
            line: 421,
            column: 41
          },
          end: {
            line: 458,
            column: 3
          }
        },
        line: 421
      },
      '54': {
        name: '(anonymous_54)',
        decl: {
          start: {
            line: 437,
            column: 62
          },
          end: {
            line: 437,
            column: 63
          }
        },
        loc: {
          start: {
            line: 437,
            column: 70
          },
          end: {
            line: 456,
            column: 7
          }
        },
        line: 437
      },
      '55': {
        name: '(anonymous_55)',
        decl: {
          start: {
            line: 461,
            column: 71
          },
          end: {
            line: 461,
            column: 72
          }
        },
        loc: {
          start: {
            line: 461,
            column: 80
          },
          end: {
            line: 463,
            column: 3
          }
        },
        line: 461
      },
      '56': {
        name: 'homePageDashboardCtrl_edit',
        decl: {
          start: {
            line: 466,
            column: 9
          },
          end: {
            line: 466,
            column: 35
          }
        },
        loc: {
          start: {
            line: 466,
            column: 86
          },
          end: {
            line: 496,
            column: 1
          }
        },
        line: 466
      },
      '57': {
        name: '(anonymous_57)',
        decl: {
          start: {
            line: 468,
            column: 24
          },
          end: {
            line: 468,
            column: 25
          }
        },
        loc: {
          start: {
            line: 468,
            column: 36
          },
          end: {
            line: 491,
            column: 3
          }
        },
        line: 468
      },
      '58': {
        name: '(anonymous_58)',
        decl: {
          start: {
            line: 482,
            column: 36
          },
          end: {
            line: 482,
            column: 37
          }
        },
        loc: {
          start: {
            line: 482,
            column: 42
          },
          end: {
            line: 490,
            column: 5
          }
        },
        line: 482
      },
      '59': {
        name: '(anonymous_59)',
        decl: {
          start: {
            line: 493,
            column: 22
          },
          end: {
            line: 493,
            column: 23
          }
        },
        loc: {
          start: {
            line: 493,
            column: 34
          },
          end: {
            line: 495,
            column: 3
          }
        },
        line: 493
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 44,
            column: 19
          },
          end: {
            line: 44,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 44,
            column: 19
          },
          end: {
            line: 44,
            column: 53
          }
        }, {
          start: {
            line: 44,
            column: 57
          },
          end: {
            line: 44,
            column: 59
          }
        }],
        line: 44
      },
      '1': {
        loc: {
          start: {
            line: 45,
            column: 4
          },
          end: {
            line: 47,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 4
          },
          end: {
            line: 47,
            column: 5
          }
        }, {
          start: {
            line: 45,
            column: 4
          },
          end: {
            line: 47,
            column: 5
          }
        }],
        line: 45
      },
      '2': {
        loc: {
          start: {
            line: 46,
            column: 7
          },
          end: {
            line: 46,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 46,
            column: 7
          },
          end: {
            line: 46,
            column: 41
          }
        }, {
          start: {
            line: 46,
            column: 45
          },
          end: {
            line: 46,
            column: 47
          }
        }],
        line: 46
      },
      '3': {
        loc: {
          start: {
            line: 67,
            column: 42
          },
          end: {
            line: 67,
            column: 119
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 67,
            column: 42
          },
          end: {
            line: 67,
            column: 76
          }
        }, {
          start: {
            line: 67,
            column: 80
          },
          end: {
            line: 67,
            column: 119
          }
        }],
        line: 67
      },
      '4': {
        loc: {
          start: {
            line: 88,
            column: 6
          },
          end: {
            line: 90,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 88,
            column: 6
          },
          end: {
            line: 90,
            column: 7
          }
        }, {
          start: {
            line: 88,
            column: 6
          },
          end: {
            line: 90,
            column: 7
          }
        }],
        line: 88
      },
      '5': {
        loc: {
          start: {
            line: 102,
            column: 6
          },
          end: {
            line: 105,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 102,
            column: 6
          },
          end: {
            line: 105,
            column: 7
          }
        }, {
          start: {
            line: 102,
            column: 6
          },
          end: {
            line: 105,
            column: 7
          }
        }],
        line: 102
      },
      '6': {
        loc: {
          start: {
            line: 102,
            column: 29
          },
          end: {
            line: 102,
            column: 91
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 102,
            column: 29
          },
          end: {
            line: 102,
            column: 60
          }
        }, {
          start: {
            line: 102,
            column: 64
          },
          end: {
            line: 102,
            column: 91
          }
        }],
        line: 102
      },
      '7': {
        loc: {
          start: {
            line: 108,
            column: 4
          },
          end: {
            line: 111,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 108,
            column: 4
          },
          end: {
            line: 111,
            column: 5
          }
        }, {
          start: {
            line: 108,
            column: 4
          },
          end: {
            line: 111,
            column: 5
          }
        }],
        line: 108
      },
      '8': {
        loc: {
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 128,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 128,
            column: 7
          }
        }, {
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 128,
            column: 7
          }
        }],
        line: 126
      },
      '9': {
        loc: {
          start: {
            line: 161,
            column: 8
          },
          end: {
            line: 163,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 161,
            column: 8
          },
          end: {
            line: 163,
            column: 9
          }
        }, {
          start: {
            line: 161,
            column: 8
          },
          end: {
            line: 163,
            column: 9
          }
        }],
        line: 161
      },
      '10': {
        loc: {
          start: {
            line: 171,
            column: 23
          },
          end: {
            line: 171,
            column: 56
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 171,
            column: 43
          },
          end: {
            line: 171,
            column: 47
          }
        }, {
          start: {
            line: 171,
            column: 50
          },
          end: {
            line: 171,
            column: 56
          }
        }],
        line: 171
      },
      '11': {
        loc: {
          start: {
            line: 179,
            column: 6
          },
          end: {
            line: 181,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 179,
            column: 6
          },
          end: {
            line: 181,
            column: 7
          }
        }, {
          start: {
            line: 179,
            column: 6
          },
          end: {
            line: 181,
            column: 7
          }
        }],
        line: 179
      },
      '12': {
        loc: {
          start: {
            line: 201,
            column: 8
          },
          end: {
            line: 203,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 201,
            column: 8
          },
          end: {
            line: 203,
            column: 9
          }
        }, {
          start: {
            line: 201,
            column: 8
          },
          end: {
            line: 203,
            column: 9
          }
        }],
        line: 201
      },
      '13': {
        loc: {
          start: {
            line: 211,
            column: 4
          },
          end: {
            line: 215,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 211,
            column: 4
          },
          end: {
            line: 215,
            column: 5
          }
        }, {
          start: {
            line: 211,
            column: 4
          },
          end: {
            line: 215,
            column: 5
          }
        }],
        line: 211
      },
      '14': {
        loc: {
          start: {
            line: 236,
            column: 12
          },
          end: {
            line: 236,
            column: 45
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 236,
            column: 40
          },
          end: {
            line: 236,
            column: 41
          }
        }, {
          start: {
            line: 236,
            column: 44
          },
          end: {
            line: 236,
            column: 45
          }
        }],
        line: 236
      },
      '15': {
        loc: {
          start: {
            line: 255,
            column: 13
          },
          end: {
            line: 255,
            column: 89
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 255,
            column: 83
          },
          end: {
            line: 255,
            column: 84
          }
        }, {
          start: {
            line: 255,
            column: 87
          },
          end: {
            line: 255,
            column: 89
          }
        }],
        line: 255
      },
      '16': {
        loc: {
          start: {
            line: 265,
            column: 6
          },
          end: {
            line: 267,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 265,
            column: 6
          },
          end: {
            line: 267,
            column: 7
          }
        }, {
          start: {
            line: 265,
            column: 6
          },
          end: {
            line: 267,
            column: 7
          }
        }],
        line: 265
      },
      '17': {
        loc: {
          start: {
            line: 275,
            column: 8
          },
          end: {
            line: 278,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 275,
            column: 8
          },
          end: {
            line: 278,
            column: 9
          }
        }, {
          start: {
            line: 275,
            column: 8
          },
          end: {
            line: 278,
            column: 9
          }
        }],
        line: 275
      },
      '18': {
        loc: {
          start: {
            line: 281,
            column: 8
          },
          end: {
            line: 285,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 281,
            column: 8
          },
          end: {
            line: 285,
            column: 9
          }
        }, {
          start: {
            line: 281,
            column: 8
          },
          end: {
            line: 285,
            column: 9
          }
        }],
        line: 281
      },
      '19': {
        loc: {
          start: {
            line: 288,
            column: 6
          },
          end: {
            line: 290,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 288,
            column: 6
          },
          end: {
            line: 290,
            column: 7
          }
        }, {
          start: {
            line: 288,
            column: 6
          },
          end: {
            line: 290,
            column: 7
          }
        }],
        line: 288
      },
      '20': {
        loc: {
          start: {
            line: 291,
            column: 6
          },
          end: {
            line: 293,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 291,
            column: 6
          },
          end: {
            line: 293,
            column: 7
          }
        }, {
          start: {
            line: 291,
            column: 6
          },
          end: {
            line: 293,
            column: 7
          }
        }],
        line: 291
      },
      '21': {
        loc: {
          start: {
            line: 294,
            column: 6
          },
          end: {
            line: 296,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 294,
            column: 6
          },
          end: {
            line: 296,
            column: 7
          }
        }, {
          start: {
            line: 294,
            column: 6
          },
          end: {
            line: 296,
            column: 7
          }
        }],
        line: 294
      },
      '22': {
        loc: {
          start: {
            line: 307,
            column: 4
          },
          end: {
            line: 309,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 307,
            column: 4
          },
          end: {
            line: 309,
            column: 5
          }
        }, {
          start: {
            line: 307,
            column: 4
          },
          end: {
            line: 309,
            column: 5
          }
        }],
        line: 307
      },
      '23': {
        loc: {
          start: {
            line: 307,
            column: 8
          },
          end: {
            line: 307,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 307,
            column: 8
          },
          end: {
            line: 307,
            column: 30
          }
        }, {
          start: {
            line: 307,
            column: 34
          },
          end: {
            line: 307,
            column: 39
          }
        }],
        line: 307
      },
      '24': {
        loc: {
          start: {
            line: 311,
            column: 50
          },
          end: {
            line: 311,
            column: 67
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 311,
            column: 50
          },
          end: {
            line: 311,
            column: 62
          }
        }, {
          start: {
            line: 311,
            column: 66
          },
          end: {
            line: 311,
            column: 67
          }
        }],
        line: 311
      },
      '25': {
        loc: {
          start: {
            line: 317,
            column: 36
          },
          end: {
            line: 317,
            column: 68
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 317,
            column: 56
          },
          end: {
            line: 317,
            column: 63
          }
        }, {
          start: {
            line: 317,
            column: 66
          },
          end: {
            line: 317,
            column: 68
          }
        }],
        line: 317
      },
      '26': {
        loc: {
          start: {
            line: 321,
            column: 6
          },
          end: {
            line: 323,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 321,
            column: 6
          },
          end: {
            line: 323,
            column: 7
          }
        }, {
          start: {
            line: 321,
            column: 6
          },
          end: {
            line: 323,
            column: 7
          }
        }],
        line: 321
      },
      '27': {
        loc: {
          start: {
            line: 350,
            column: 25
          },
          end: {
            line: 350,
            column: 58
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 350,
            column: 45
          },
          end: {
            line: 350,
            column: 49
          }
        }, {
          start: {
            line: 350,
            column: 52
          },
          end: {
            line: 350,
            column: 58
          }
        }],
        line: 350
      },
      '28': {
        loc: {
          start: {
            line: 353,
            column: 12
          },
          end: {
            line: 355,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 353,
            column: 12
          },
          end: {
            line: 355,
            column: 13
          }
        }, {
          start: {
            line: 353,
            column: 12
          },
          end: {
            line: 355,
            column: 13
          }
        }],
        line: 353
      },
      '29': {
        loc: {
          start: {
            line: 358,
            column: 10
          },
          end: {
            line: 360,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 358,
            column: 10
          },
          end: {
            line: 360,
            column: 11
          }
        }, {
          start: {
            line: 358,
            column: 10
          },
          end: {
            line: 360,
            column: 11
          }
        }],
        line: 358
      },
      '30': {
        loc: {
          start: {
            line: 365,
            column: 8
          },
          end: {
            line: 367,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 365,
            column: 8
          },
          end: {
            line: 367,
            column: 9
          }
        }, {
          start: {
            line: 365,
            column: 8
          },
          end: {
            line: 367,
            column: 9
          }
        }],
        line: 365
      },
      '31': {
        loc: {
          start: {
            line: 397,
            column: 2
          },
          end: {
            line: 413,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 397,
            column: 2
          },
          end: {
            line: 413,
            column: 3
          }
        }, {
          start: {
            line: 397,
            column: 2
          },
          end: {
            line: 413,
            column: 3
          }
        }],
        line: 397
      },
      '32': {
        loc: {
          start: {
            line: 397,
            column: 6
          },
          end: {
            line: 397,
            column: 83
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 397,
            column: 6
          },
          end: {
            line: 397,
            column: 31
          }
        }, {
          start: {
            line: 397,
            column: 35
          },
          end: {
            line: 397,
            column: 83
          }
        }],
        line: 397
      },
      '33': {
        loc: {
          start: {
            line: 430,
            column: 4
          },
          end: {
            line: 457,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 430,
            column: 4
          },
          end: {
            line: 457,
            column: 5
          }
        }, {
          start: {
            line: 430,
            column: 4
          },
          end: {
            line: 457,
            column: 5
          }
        }],
        line: 430
      },
      '34': {
        loc: {
          start: {
            line: 431,
            column: 6
          },
          end: {
            line: 435,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 431,
            column: 6
          },
          end: {
            line: 435,
            column: 7
          }
        }, {
          start: {
            line: 431,
            column: 6
          },
          end: {
            line: 435,
            column: 7
          }
        }],
        line: 431
      },
      '35': {
        loc: {
          start: {
            line: 439,
            column: 8
          },
          end: {
            line: 443,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 439,
            column: 8
          },
          end: {
            line: 443,
            column: 9
          }
        }, {
          start: {
            line: 439,
            column: 8
          },
          end: {
            line: 443,
            column: 9
          }
        }],
        line: 439
      },
      '36': {
        loc: {
          start: {
            line: 439,
            column: 12
          },
          end: {
            line: 439,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 439,
            column: 12
          },
          end: {
            line: 439,
            column: 35
          }
        }, {
          start: {
            line: 439,
            column: 39
          },
          end: {
            line: 439,
            column: 64
          }
        }],
        line: 439
      },
      '37': {
        loc: {
          start: {
            line: 446,
            column: 10
          },
          end: {
            line: 450,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 446,
            column: 10
          },
          end: {
            line: 450,
            column: 11
          }
        }, {
          start: {
            line: 446,
            column: 10
          },
          end: {
            line: 450,
            column: 11
          }
        }],
        line: 446
      },
      '38': {
        loc: {
          start: {
            line: 452,
            column: 8
          },
          end: {
            line: 455,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 452,
            column: 8
          },
          end: {
            line: 455,
            column: 9
          }
        }, {
          start: {
            line: 452,
            column: 8
          },
          end: {
            line: 455,
            column: 9
          }
        }],
        line: 452
      },
      '39': {
        loc: {
          start: {
            line: 473,
            column: 18
          },
          end: {
            line: 473,
            column: 37
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 473,
            column: 32
          },
          end: {
            line: 473,
            column: 33
          }
        }, {
          start: {
            line: 473,
            column: 36
          },
          end: {
            line: 473,
            column: 37
          }
        }],
        line: 473
      },
      '40': {
        loc: {
          start: {
            line: 474,
            column: 21
          },
          end: {
            line: 474,
            column: 43
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 474,
            column: 38
          },
          end: {
            line: 474,
            column: 39
          }
        }, {
          start: {
            line: 474,
            column: 42
          },
          end: {
            line: 474,
            column: 43
          }
        }],
        line: 474
      },
      '41': {
        loc: {
          start: {
            line: 478,
            column: 4
          },
          end: {
            line: 480,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 478,
            column: 4
          },
          end: {
            line: 480,
            column: 5
          }
        }, {
          start: {
            line: 478,
            column: 4
          },
          end: {
            line: 480,
            column: 5
          }
        }],
        line: 478
      },
      '42': {
        loc: {
          start: {
            line: 483,
            column: 6
          },
          end: {
            line: 488,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 483,
            column: 6
          },
          end: {
            line: 488,
            column: 7
          }
        }, {
          start: {
            line: 483,
            column: 6
          },
          end: {
            line: 488,
            column: 7
          }
        }],
        line: 483
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0,
      '248': 0,
      '249': 0,
      '250': 0,
      '251': 0,
      '252': 0,
      '253': 0,
      '254': 0,
      '255': 0,
      '256': 0,
      '257': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_uato2nufc.s[0]++;
angular.module('app').controller('homePageDashboardCtrl', function ($scope, $CRUDService, $rootScope, $uibModal, SweetAlert, $translate, $filter) {
  cov_uato2nufc.f[0]++;

  var PATH = (cov_uato2nufc.s[1]++, 'Dashboard');
  var PATH_ANALYSE = (cov_uato2nufc.s[2]++, 'Analyse');
  var KEY_SELECTED_DASHBOARD = (cov_uato2nufc.s[3]++, 'KEY_SELECTED_DASHBOARD');
  var PATH_USER = (cov_uato2nufc.s[4]++, 'User');
  var PATH_PROJECT = (cov_uato2nufc.s[5]++, 'Projet');
  cov_uato2nufc.s[6]++;
  $scope.projectList = [];
  cov_uato2nufc.s[7]++;
  $scope.gridsterOpts = {
    columns: 8,
    margins: [20, 20],
    outerMargin: false,
    pushing: true,
    floating: true,
    draggable: {
      enabled: false,
      stop: function stop(event, element, widget) {
        // $scope.common.validateItem();
      }
    },
    resizable: {
      enabled: false,
      handles: ['n', 'e', 's', 'w', 'se', 'sw'],
      stop: function stop(event, element, widget) {
        // $scope.common.validateItem();
      }
    }
  };

  cov_uato2nufc.s[8]++;
  $scope.isopenDropdown = false;
  cov_uato2nufc.s[9]++;
  $scope.toggleDropdown = function ($event) {
    cov_uato2nufc.f[1]++;
    cov_uato2nufc.s[10]++;

    $event.preventDefault();
    cov_uato2nufc.s[11]++;
    $event.stopPropagation();
    cov_uato2nufc.s[12]++;
    $scope.isopenDropdown = !$scope.isopenDropdown;
  };

  cov_uato2nufc.s[13]++;
  $scope.choise = {
    id: 0,
    newAnalyses: [],
    filterText: ''
  };
  cov_uato2nufc.s[14]++;
  $scope.AnalyseList = [];

  cov_uato2nufc.s[15]++;
  $scope.removePanel = function (analyse) {
    cov_uato2nufc.f[2]++;

    var index = (cov_uato2nufc.s[16]++, ((cov_uato2nufc.b[0][0]++, $scope.selectedDashboard.STRUCTURE) || (cov_uato2nufc.b[0][1]++, [])).indexOf(analyse));
    cov_uato2nufc.s[17]++;
    if (index >= 0) {
      cov_uato2nufc.b[1][0]++;
      cov_uato2nufc.s[18]++;

      ((cov_uato2nufc.b[2][0]++, $scope.selectedDashboard.STRUCTURE) || (cov_uato2nufc.b[2][1]++, [])).splice(index, 1);
    } else {
      cov_uato2nufc.b[1][1]++;
    }
  };

  cov_uato2nufc.s[19]++;
  $scope.addDashboard = function () {
    cov_uato2nufc.f[3]++;
    cov_uato2nufc.s[20]++;

    $scope.choise.newAnalyses = [];
    cov_uato2nufc.s[21]++;
    $scope.editedDashboard = true;
    cov_uato2nufc.s[22]++;
    $scope.selectedDashboard = {
      id: 0,
      TITLE: '',
      PUBLIC: true,
      PORTFOLIO: true,
      STRUCTURE: [],
      SHARED_USERS: {}
    };

    cov_uato2nufc.s[23]++;
    $scope.gridsterOpts.draggable.enabled = true;
    cov_uato2nufc.s[24]++;
    $scope.gridsterOpts.resizable.enabled = true;
  };
  cov_uato2nufc.s[25]++;
  $scope.editDashboard = function (item) {
    cov_uato2nufc.f[4]++;
    cov_uato2nufc.s[26]++;

    $scope.editedDashboard = true;
    cov_uato2nufc.s[27]++;
    $scope.selectedDashboard.PORTFOLIO = (cov_uato2nufc.b[3][0]++, $scope.selectedDashboard.PORTFOLIO) || (cov_uato2nufc.b[3][1]++, $scope.selectedDashboard.PORTFOLIO == 1);
    cov_uato2nufc.s[28]++;
    $scope.oldDashboard = angular.copy($scope.selectedDashboard);
    cov_uato2nufc.s[29]++;
    $scope.gridsterOpts.draggable.enabled = true;
    cov_uato2nufc.s[30]++;
    $scope.gridsterOpts.resizable.enabled = true;
  };

  cov_uato2nufc.s[31]++;
  $scope.validateDashboard = function () {
    cov_uato2nufc.f[5]++;
  };
  cov_uato2nufc.s[32]++;
  $scope.deleteDashboard = function () {
    cov_uato2nufc.f[6]++;
    cov_uato2nufc.s[33]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',
      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_uato2nufc.f[7]++;
      cov_uato2nufc.s[34]++;

      if (isconfirm) {
        cov_uato2nufc.b[4][0]++;
        cov_uato2nufc.s[35]++;

        return;
      } else {
        cov_uato2nufc.b[4][1]++;
      }
      cov_uato2nufc.s[36]++;
      $CRUDService.delet(PATH, { action: 'supp', id: $scope.selectedDashboard.id }, function (data) {
        cov_uato2nufc.f[8]++;
        cov_uato2nufc.s[37]++;

        $scope.getAll();
      });
    });
  };

  cov_uato2nufc.s[38]++;
  $scope.cancelDashboard = function () {
    cov_uato2nufc.f[9]++;
    cov_uato2nufc.s[39]++;

    $scope.gridsterOpts.draggable.enabled = false;
    cov_uato2nufc.s[40]++;
    $scope.gridsterOpts.resizable.enabled = false;
    cov_uato2nufc.s[41]++;
    $scope.editedDashboard = false;
    cov_uato2nufc.s[42]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $scope.dashboardList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var dashboard = _step.value;
        cov_uato2nufc.s[43]++;

        if (dashboard.id == ((cov_uato2nufc.b[6][0]++, $scope.choise.selectedDashboard) || (cov_uato2nufc.b[6][1]++, $scope.selectedDashboard.id))) {
          cov_uato2nufc.b[5][0]++;
          cov_uato2nufc.s[44]++;

          $scope.selectDashboard(dashboard);
          cov_uato2nufc.s[45]++;
          return;
        } else {
          cov_uato2nufc.b[5][1]++;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_uato2nufc.s[46]++;
    if ($scope.dashboardList.length > 0) {
      cov_uato2nufc.b[7][0]++;
      cov_uato2nufc.s[47]++;

      $scope.selectDashboard($scope.dashboardList[0]);
      cov_uato2nufc.s[48]++;
      return;
    } else {
      cov_uato2nufc.b[7][1]++;
    }

    cov_uato2nufc.s[49]++;
    $scope.selectedDashboard = {
      id: 0,
      TITLE: '',
      PUBLIC: true,
      PORTFOLIO: true,
      STRUCTURE: [],
      SHARED_USERS: {}
    };
  };

  cov_uato2nufc.s[50]++;
  $scope.shareDashboard = function () {
    cov_uato2nufc.f[10]++;

    var users = (cov_uato2nufc.s[51]++, {});
    cov_uato2nufc.s[52]++;
    for (var keyUser in $scope.selectedDashboard.SHARED_USERS) {
      cov_uato2nufc.s[53]++;

      if ($scope.selectedDashboard.SHARED_USERS[keyUser]) {
        cov_uato2nufc.b[8][0]++;
        cov_uato2nufc.s[54]++;

        users[keyUser] = 1;
      } else {
        cov_uato2nufc.b[8][1]++;
      }
    }
    cov_uato2nufc.s[55]++;
    $CRUDService.save(PATH, { action: 'share', id: $scope.selectedDashboard.id, users: users }, function (id) {
      cov_uato2nufc.f[11]++;
    });
  };

  cov_uato2nufc.s[56]++;
  $scope.addView = function () {
    cov_uato2nufc.f[12]++;
    cov_uato2nufc.s[57]++;

    $scope.AnalyseListTemp = [];

    var myStructure = (cov_uato2nufc.s[58]++, $scope.selectedDashboard.STRUCTURE);

    var newAnalyses = (cov_uato2nufc.s[59]++, $scope.choise.newAnalyses);
    var ids_forms = (cov_uato2nufc.s[60]++, ['0_form'].concat(newAnalyses).filter(function (analyse) {
      cov_uato2nufc.f[13]++;
      cov_uato2nufc.s[61]++;

      return analyse.replace('_form', '') != analyse;
    }).map(function (analyse) {
      cov_uato2nufc.f[14]++;
      cov_uato2nufc.s[62]++;
      return analyse.replace('_form', '');
    }).join(' , '));

    var ids = (cov_uato2nufc.s[63]++, ['-1'].concat(newAnalyses).filter(function (analyse) {
      cov_uato2nufc.f[15]++;
      cov_uato2nufc.s[64]++;

      return analyse.replace('_form', '') == analyse;
    }).map(function (analyse) {
      cov_uato2nufc.f[16]++;
      cov_uato2nufc.s[65]++;
      return analyse.replace('_form', '');
    }).join(' , '));

    cov_uato2nufc.s[66]++;
    $CRUDService.getAll(PATH_ANALYSE, { get: 'all_by_right', ids_forms: ids_forms, ids: ids }, function (data_analyse) {
      cov_uato2nufc.f[17]++;
      cov_uato2nufc.s[67]++;

      data_analyse = data_analyse.map(function (analyse) {
        cov_uato2nufc.f[18]++;

        var _ = (cov_uato2nufc.s[68]++, Object.assign(analyse, angular.fromJson(analyse.VALUE)));
        cov_uato2nufc.s[69]++;
        _.VALUE = null;
        cov_uato2nufc.s[70]++;
        return _;
      });

      // Removed Dashboard
      cov_uato2nufc.s[71]++;

      var _loop = function _loop(i) {
        var items = (cov_uato2nufc.s[72]++, data_analyse.filter(function (analyse) {
          cov_uato2nufc.f[19]++;
          cov_uato2nufc.s[73]++;

          return myStructure[i].id_ == analyse.id_;
        }));
        cov_uato2nufc.s[74]++;
        if (items.length == 0) {
          cov_uato2nufc.b[9][0]++;
          cov_uato2nufc.s[75]++;

          myStructure.splice(i, 1);
        } else {
          cov_uato2nufc.b[9][1]++;
        }
      };

      for (var i = myStructure.length - 1; i >= 0; i--) {
        _loop(i);
      }

      // Existing Dashboard
      cov_uato2nufc.s[76]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        var _loop2 = function _loop2() {
          var analyse = _step2.value;

          var an_ = (cov_uato2nufc.s[77]++, data_analyse.filter(function (value) {
            cov_uato2nufc.f[20]++;
            cov_uato2nufc.s[78]++;

            return value.id_ == analyse.id_;
          }));
          cov_uato2nufc.s[79]++;
          analyse.data = an_.length == 0 ? (cov_uato2nufc.b[10][0]++, null) : (cov_uato2nufc.b[10][1]++, an_[0]);
        };

        for (var _iterator2 = myStructure[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          _loop2();
        }

        // new Dashboard
        // Max height
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      var items_ = (cov_uato2nufc.s[80]++, myStructure.map(function (structure) {
        cov_uato2nufc.f[21]++;
        cov_uato2nufc.s[81]++;
        return structure.sizeY + structure.row + 1;
      }).sort());
      var row = (cov_uato2nufc.s[82]++, 0);
      var col = (cov_uato2nufc.s[83]++, 0);
      cov_uato2nufc.s[84]++;
      if (items_.length > 0) {
        cov_uato2nufc.b[11][0]++;
        cov_uato2nufc.s[85]++;

        row = items_[items_.length - 1];
      } else {
        cov_uato2nufc.b[11][1]++;
      }

      var nonExistingItems = (cov_uato2nufc.s[86]++, data_analyse.filter(function (analyse) {
        cov_uato2nufc.f[22]++;

        var an_ = (cov_uato2nufc.s[87]++, myStructure.filter(function (structure) {
          cov_uato2nufc.f[23]++;
          cov_uato2nufc.s[88]++;

          return structure.id == analyse.id;
        }));
        cov_uato2nufc.s[89]++;
        return an_.length == 0;
      }));
      cov_uato2nufc.s[90]++;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = nonExistingItems[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var nonExistingItem = _step3.value;
          cov_uato2nufc.s[91]++;

          myStructure.push({
            sizeX: 2,
            sizeY: 2,
            row: row,
            col: col,
            id: nonExistingItem.id,
            id_: nonExistingItem.id_,
            TYPE: nonExistingItem.TYPE,
            data: nonExistingItem
          });
          cov_uato2nufc.s[92]++;
          col = (col + 2) % 8;
          cov_uato2nufc.s[93]++;
          if (col == 0) {
            cov_uato2nufc.b[12][0]++;
            cov_uato2nufc.s[94]++;

            row += 2;
          } else {
            cov_uato2nufc.b[12][1]++;
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      cov_uato2nufc.s[95]++;
      $scope.selectedDashboard.STRUCTURE = myStructure;
    });
  };

  cov_uato2nufc.s[96]++;
  $scope.toggleAnalyse = function (analyse) {
    cov_uato2nufc.f[24]++;

    var index = (cov_uato2nufc.s[97]++, $scope.choise.newAnalyses.indexOf(analyse.id_));
    cov_uato2nufc.s[98]++;
    if (index >= 0) {
      cov_uato2nufc.b[13][0]++;
      cov_uato2nufc.s[99]++;

      $scope.choise.newAnalyses.splice(index, 1);
    } else {
      cov_uato2nufc.b[13][1]++;
      cov_uato2nufc.s[100]++;

      $scope.choise.newAnalyses.push(analyse.id_);
    }
  };

  cov_uato2nufc.s[101]++;
  $scope.initView = function () {
    cov_uato2nufc.f[25]++;
    cov_uato2nufc.s[102]++;

    $scope.AnalyseListTemp = $scope.__;
    cov_uato2nufc.s[103]++;
    $scope.choise.newAnalyses = [];
    cov_uato2nufc.s[104]++;
    $scope.choise.newAnalyses = $scope.selectedDashboard.STRUCTURE.map(function (structure) {
      cov_uato2nufc.f[26]++;
      cov_uato2nufc.s[105]++;
      return structure.id_;
    });
  };

  cov_uato2nufc.s[106]++;
  $scope.validateSelectedAnalyse = function () {
    cov_uato2nufc.f[27]++;

    var item = (cov_uato2nufc.s[107]++, angular.copy($scope.selectedDashboard));

    cov_uato2nufc.s[108]++;
    item.STRUCTURE = item.STRUCTURE.filter(function (structure) {
      cov_uato2nufc.f[28]++;
      cov_uato2nufc.s[109]++;

      return structure.data != null;
    });
    cov_uato2nufc.s[110]++;
    item.STRUCTURE = item.STRUCTURE.map(function (structure) {
      cov_uato2nufc.f[29]++;
      cov_uato2nufc.s[111]++;
      return {
        id: structure.id,
        sizeX: structure.sizeX,
        sizeY: structure.sizeY,
        row: structure.row,
        col: structure.col,
        form: structure.data.TYPE > 4 ? (cov_uato2nufc.b[14][0]++, 1) : (cov_uato2nufc.b[14][1]++, 0)
      };
    });
    cov_uato2nufc.s[112]++;
    $scope.currentEditedDashbord = item;
    cov_uato2nufc.s[113]++;
    $uibModal.open({
      templateUrl: 'app/views/home_page/edit.html',
      controller: homePageDashboardCtrl_edit,
      scope: $scope,
      size: 'md',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_uato2nufc.s[114]++;
  $scope.deleteView = function (view) {
    cov_uato2nufc.f[30]++;
  };

  cov_uato2nufc.s[115]++;
  $scope.filterAnalyse = function () {
    cov_uato2nufc.f[31]++;

    var filterItems = (cov_uato2nufc.s[116]++, $filter('filter')($scope.AnalyseList, $scope.choise.filterText).sort(function (projectA, projectB) {
      cov_uato2nufc.f[32]++;
      cov_uato2nufc.s[117]++;

      return parseInt(projectA.ID_PROJET, 10) > parseInt(projectB.ID_PROJET, 10) ? (cov_uato2nufc.b[15][0]++, 1) : (cov_uato2nufc.b[15][1]++, -1);
    }));
    var idProjects = (cov_uato2nufc.s[118]++, filterItems.map(function (item) {
      cov_uato2nufc.f[33]++;
      cov_uato2nufc.s[119]++;
      return item.ID_PROJET;
    }));

    var myProjects = (cov_uato2nufc.s[120]++, []);

    cov_uato2nufc.s[121]++;
    var _iteratorNormalCompletion4 = true;
    var _didIteratorError4 = false;
    var _iteratorError4 = undefined;

    try {
      var _loop3 = function _loop3() {
        var project = _step4.value;

        var projectAnalyse = (cov_uato2nufc.s[123]++, filterItems.filter(function (item) {
          cov_uato2nufc.f[35]++;
          cov_uato2nufc.s[124]++;
          return item.ID_PROJET == project.id;
        }));
        cov_uato2nufc.s[125]++;
        if (projectAnalyse.length == 0) {
          cov_uato2nufc.b[16][0]++;
          cov_uato2nufc.s[126]++;

          return 'continue';
        } else {
          cov_uato2nufc.b[16][1]++;
        }
        cov_uato2nufc.s[127]++;
        myProjects.push({ id: project.id, label: project.LIBELLE_PROJET });

        var activities = (cov_uato2nufc.s[128]++, []);
        var indicators = (cov_uato2nufc.s[129]++, []);
        var forms = (cov_uato2nufc.s[130]++, []);

        cov_uato2nufc.s[131]++;
        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = projectAnalyse[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var _analyse = _step5.value;
            cov_uato2nufc.s[132]++;

            if (_analyse.NAMEREQUEST) {
              cov_uato2nufc.b[17][0]++;
              cov_uato2nufc.s[133]++;

              forms.push(_analyse);
              cov_uato2nufc.s[134]++;
              continue;
            } else {
              cov_uato2nufc.b[17][1]++;
            }
            var v = (cov_uato2nufc.s[135]++, angular.fromJson(_analyse.VALUE));

            cov_uato2nufc.s[136]++;
            if (v.ID_INDIC) {
              cov_uato2nufc.b[18][0]++;
              cov_uato2nufc.s[137]++;

              indicators.push(_analyse);
            } else {
              cov_uato2nufc.b[18][1]++;
              cov_uato2nufc.s[138]++;

              activities.push(_analyse);
            }
          }
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }

        cov_uato2nufc.s[139]++;
        if (activities.length > 0) {
          cov_uato2nufc.b[19][0]++;
          cov_uato2nufc.s[140]++;

          myProjects = myProjects.concat([{ subtitle: true, label: 'CUSTOM_DASHBOARD.ACTIVITIES' }].concat(activities));
        } else {
          cov_uato2nufc.b[19][1]++;
        }
        cov_uato2nufc.s[141]++;
        if (indicators.length > 0) {
          cov_uato2nufc.b[20][0]++;
          cov_uato2nufc.s[142]++;

          myProjects = myProjects.concat([{ subtitle: true, label: 'CUSTOM_DASHBOARD.INDICATORS' }].concat(indicators));
        } else {
          cov_uato2nufc.b[20][1]++;
        }
        cov_uato2nufc.s[143]++;
        if (forms.length > 0) {
          cov_uato2nufc.b[21][0]++;
          cov_uato2nufc.s[144]++;

          myProjects = myProjects.concat([{ subtitle: true, label: 'CUSTOM_DASHBOARD.FORMS' }].concat(forms));
        } else {
          cov_uato2nufc.b[21][1]++;
        }
      };

      for (var _iterator4 = $scope.projectList.filter(function (project_) {
        cov_uato2nufc.f[34]++;
        cov_uato2nufc.s[122]++;

        return idProjects.indexOf(project_.id) >= 0;
      })[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
        var _ret3 = _loop3();

        if (_ret3 === 'continue') continue;
      }

      // Activity
    } catch (err) {
      _didIteratorError4 = true;
      _iteratorError4 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion4 && _iterator4.return) {
          _iterator4.return();
        }
      } finally {
        if (_didIteratorError4) {
          throw _iteratorError4;
        }
      }
    }

    var activities = (cov_uato2nufc.s[145]++, []);

    cov_uato2nufc.s[146]++;
    $scope.__ = myProjects;
    cov_uato2nufc.s[147]++;
    $scope.AnalyseListTemp = myProjects;
  };
  cov_uato2nufc.s[148]++;
  $scope.filterText = '';
  cov_uato2nufc.s[149]++;
  $scope.selectDashboard = function (dashboard, edit) {
    cov_uato2nufc.f[36]++;
    cov_uato2nufc.s[150]++;

    if ((cov_uato2nufc.b[23][0]++, $scope.editedDashboard) && (cov_uato2nufc.b[23][1]++, !edit)) {
      cov_uato2nufc.b[22][0]++;
      cov_uato2nufc.s[151]++;

      return;
    } else {
      cov_uato2nufc.b[22][1]++;
    }

    cov_uato2nufc.s[152]++;
    $CRUDService.getAll(PATH, { get: 'single', id: (cov_uato2nufc.b[24][0]++, dashboard.id) || (cov_uato2nufc.b[24][1]++, 0) }, function (data) {
      cov_uato2nufc.f[37]++;
      cov_uato2nufc.s[153]++;

      data.STRUCTURE = angular.fromJson(data.STRUCTURE);
      // data.STRUCTURE.
      cov_uato2nufc.s[154]++;
      data.PORTFOLIO = data.PORTFOLIO == 1;
      cov_uato2nufc.s[155]++;
      data.STRUCTURE = data.STRUCTURE.map(function (analyse) {
        cov_uato2nufc.f[38]++;
        cov_uato2nufc.s[156]++;

        analyse.loading = true;
        cov_uato2nufc.s[157]++;
        analyse.id_ = analyse.id + (analyse.form == 1 ? (cov_uato2nufc.b[25][0]++, '_form') : (cov_uato2nufc.b[25][1]++, ''));
        cov_uato2nufc.s[158]++;
        return analyse;
      });
      var selectedDashboard = (cov_uato2nufc.s[159]++, data);
      cov_uato2nufc.s[160]++;
      if (angular.isString(selectedDashboard.SHARED_USERS)) {
        cov_uato2nufc.b[26][0]++;
        cov_uato2nufc.s[161]++;

        selectedDashboard.SHARED_USERS = angular.fromJson(selectedDashboard.SHARED_USERS);
      } else {
        cov_uato2nufc.b[26][1]++;
      }

      cov_uato2nufc.s[162]++;
      for (var userKey in selectedDashboard.SHARED_USERS) {
        cov_uato2nufc.s[163]++;

        selectedDashboard.SHARED_USERS[userKey] = true;
      }
      // $scope.selectedDashboard.STRUCTURE = [];
      var newAnalyses = (cov_uato2nufc.s[164]++, data.STRUCTURE.map(function (structure) {
        cov_uato2nufc.f[39]++;
        cov_uato2nufc.s[165]++;
        return structure.id_;
      }));
      var ids_forms = (cov_uato2nufc.s[166]++, ['0_form'].concat(newAnalyses).filter(function (analyse) {
        cov_uato2nufc.f[40]++;
        cov_uato2nufc.s[167]++;

        return analyse.replace('_form', '') != analyse;
      }).map(function (analyse) {
        cov_uato2nufc.f[41]++;
        cov_uato2nufc.s[168]++;
        return analyse.replace('_form', '');
      }).join(' , '));

      var ids = (cov_uato2nufc.s[169]++, ['-1'].concat(newAnalyses).filter(function (analyse) {
        cov_uato2nufc.f[42]++;
        cov_uato2nufc.s[170]++;

        return analyse.replace('_form', '') == analyse;
      }).map(function (analyse) {
        cov_uato2nufc.f[43]++;
        cov_uato2nufc.s[171]++;
        return analyse.replace('_form', '');
      }).join(' , '));

      cov_uato2nufc.s[172]++;
      $CRUDService.getAll(PATH_ANALYSE, { get: 'all_by_right', ids: ids, ids_forms: ids_forms }, function (data_analyse) {
        cov_uato2nufc.f[44]++;
        cov_uato2nufc.s[173]++;

        data_analyse = data_analyse.map(function (analyse) {
          cov_uato2nufc.f[45]++;

          var _ = (cov_uato2nufc.s[174]++, Object.assign(analyse, angular.fromJson(analyse.VALUE)));
          cov_uato2nufc.s[175]++;
          _.VALUE = null;
          cov_uato2nufc.s[176]++;
          return _;
        });
        cov_uato2nufc.s[177]++;
        $scope.printItem = {};
        cov_uato2nufc.s[178]++;
        var _iteratorNormalCompletion6 = true;
        var _didIteratorError6 = false;
        var _iteratorError6 = undefined;

        try {
          var _loop4 = function _loop4() {
            var analyse = _step6.value;

            var an_ = (cov_uato2nufc.s[179]++, data_analyse.filter(function (value) {
              cov_uato2nufc.f[46]++;
              cov_uato2nufc.s[180]++;

              return value.id == analyse.id;
            }));
            cov_uato2nufc.s[181]++;
            $scope.printItem[analyse.id] = 0;
            cov_uato2nufc.s[182]++;
            analyse.data = an_.length == 0 ? (cov_uato2nufc.b[27][0]++, null) : (cov_uato2nufc.b[27][1]++, an_[0]);

            var myProject = (cov_uato2nufc.s[183]++, $scope.projectList.filter(function (project_) {
              cov_uato2nufc.f[47]++;
              cov_uato2nufc.s[184]++;

              if (!analyse.data) {
                cov_uato2nufc.b[28][0]++;
                cov_uato2nufc.s[185]++;

                return false;
              } else {
                cov_uato2nufc.b[28][1]++;
              }
              cov_uato2nufc.s[186]++;
              return project_.id == analyse.data.ID_PROJET;
            }));
            cov_uato2nufc.s[187]++;
            if (myProject.length > 0) {
              cov_uato2nufc.b[29][0]++;
              cov_uato2nufc.s[188]++;

              analyse.data.CODE_PROJET = myProject[0].CODE_PRJ;
            } else {
              cov_uato2nufc.b[29][1]++;
            }

            // Set project
          };

          for (var _iterator6 = selectedDashboard.STRUCTURE[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
            _loop4();
          }
        } catch (err) {
          _didIteratorError6 = true;
          _iteratorError6 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion6 && _iterator6.return) {
              _iterator6.return();
            }
          } finally {
            if (_didIteratorError6) {
              throw _iteratorError6;
            }
          }
        }

        cov_uato2nufc.s[189]++;
        if (!edit) {
          cov_uato2nufc.b[30][0]++;
          cov_uato2nufc.s[190]++;

          $scope.editedDashboard = null;
        } else {
          cov_uato2nufc.b[30][1]++;
        }
        /* if ($scope.connectedUser.id != selectedDashboard.IDUSER || !$scope.displayOnly) {
          selectedDashboard.STRUCTURE = (selectedDashboard.STRUCTURE || []).filter(struct => {
            return struct.data != null;
          });
        } */
        cov_uato2nufc.s[191]++;
        $scope.selectedDashboard = selectedDashboard;
        cov_uato2nufc.s[192]++;
        $scope.choise.selectedDashboard = selectedDashboard.id;
      });
      // Get Dashboard Annual
    });
  };
  cov_uato2nufc.s[193]++;
  $scope.addItem = function () {
    cov_uato2nufc.f[48]++;
    cov_uato2nufc.s[194]++;

    $scope.currentEditedDashbord = {
      id: 0,
      TITLE: '',
      PUBLIC: true,
      PORTFOLIO: true,
      STRUCTURE: []
    };
    cov_uato2nufc.s[195]++;
    $uibModal.open({
      templateUrl: 'app/views/home_page/edit.html',
      controller: homePageDashboardCtrl_edit,
      scope: $scope,
      size: 'md',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_uato2nufc.s[196]++;
  if ((cov_uato2nufc.b[32][0]++, $rootScope.currentProject) && (cov_uato2nufc.b[32][1]++, $rootScope.currentProject.project.PORTFOLIO == 0)) {
    cov_uato2nufc.b[31][0]++;
    cov_uato2nufc.s[197]++;

    $scope.projectList = [$rootScope.currentProject.project];
    cov_uato2nufc.s[198]++;
    $CRUDService.getAll(PATH_ANALYSE, { get: 'all_by_right', project: $rootScope.currentProject.project.id }, function (data_analyse) {
      cov_uato2nufc.f[49]++;
      cov_uato2nufc.s[199]++;

      $scope.AnalyseList = data_analyse;
      cov_uato2nufc.s[200]++;
      $scope.filterAnalyse();
      cov_uato2nufc.s[201]++;
      $scope.isAlreadyLoaded = true;
    });
  } else {
    cov_uato2nufc.b[31][1]++;
    cov_uato2nufc.s[202]++;

    $CRUDService.getAll(PATH_PROJECT, { get: 'all_short' }, function (data_) {
      cov_uato2nufc.f[50]++;
      cov_uato2nufc.s[203]++;

      $scope.projectList = data_;
      cov_uato2nufc.s[204]++;
      $CRUDService.getAll(PATH_ANALYSE, { get: 'all_by_right' }, function (data_analyse) {
        cov_uato2nufc.f[51]++;
        cov_uato2nufc.s[205]++;

        $scope.AnalyseList = data_analyse;
        cov_uato2nufc.s[206]++;
        $scope.filterAnalyse();
        cov_uato2nufc.s[207]++;
        $scope.isAlreadyLoaded = true;
      });
    });
  }

  cov_uato2nufc.s[208]++;
  $scope.initSelect = function () {
    cov_uato2nufc.f[52]++;
    cov_uato2nufc.s[209]++;

    $scope.editedDashboard = false;
    cov_uato2nufc.s[210]++;
    $scope.gridsterOpts.draggable.enabled = false;
    cov_uato2nufc.s[211]++;
    $scope.gridsterOpts.resizable.enabled = false;
  };

  cov_uato2nufc.s[212]++;
  $scope.getAll = function (idDashboard) {
    cov_uato2nufc.f[53]++;

    var myId = (cov_uato2nufc.s[213]++, angular.copy(idDashboard));

    cov_uato2nufc.s[214]++;
    $scope.editedDashboard = false;
    cov_uato2nufc.s[215]++;
    $scope.gridsterOpts.draggable.enabled = false;
    cov_uato2nufc.s[216]++;
    $scope.gridsterOpts.resizable.enabled = false;

    // const id = parseInt(localStorage.getItem(KEY_SELECTED_DASHBOARD) || 0, 10);

    cov_uato2nufc.s[217]++;
    if ($scope.fromDashboard) {
      cov_uato2nufc.b[33][0]++;
      cov_uato2nufc.s[218]++;

      if ($scope.myDashboard == 0) {
        cov_uato2nufc.b[34][0]++;
        cov_uato2nufc.s[219]++;

        $scope.addDashboard();
      } else {
        cov_uato2nufc.b[34][1]++;
        cov_uato2nufc.s[220]++;

        $scope.selectDashboard({ id: $scope.myDashboard });
      }
    } else {
      cov_uato2nufc.b[33][1]++;
      cov_uato2nufc.s[221]++;

      $CRUDService.getAll(PATH, { get: 'all_space_dashboard' }, function (data) {
        cov_uato2nufc.f[54]++;
        cov_uato2nufc.s[222]++;

        $scope.dashboardList = data;
        cov_uato2nufc.s[223]++;
        if ((cov_uato2nufc.b[36][0]++, $scope.dashboardList[0]) && (cov_uato2nufc.b[36][1]++, angular.isUndefined(myId))) {
          cov_uato2nufc.b[35][0]++;
          cov_uato2nufc.s[224]++;

          $scope.choise.selectedDashboard = $scope.dashboardList[0].id;
          cov_uato2nufc.s[225]++;
          $scope.selectDashboard($scope.dashboardList[0]);
          cov_uato2nufc.s[226]++;
          return;
        } else {
          cov_uato2nufc.b[35][1]++;
        }

        cov_uato2nufc.s[227]++;
        var _iteratorNormalCompletion7 = true;
        var _didIteratorError7 = false;
        var _iteratorError7 = undefined;

        try {
          for (var _iterator7 = $scope.dashboardList[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
            var dashboard = _step7.value;
            cov_uato2nufc.s[228]++;

            if (dashboard.id == myId.id) {
              cov_uato2nufc.b[37][0]++;
              cov_uato2nufc.s[229]++;

              $scope.choise.selectedDashboard = dashboard.id;
              cov_uato2nufc.s[230]++;
              $scope.selectDashboard(dashboard);
              cov_uato2nufc.s[231]++;
              return;
            } else {
              cov_uato2nufc.b[37][1]++;
            }
          }
        } catch (err) {
          _didIteratorError7 = true;
          _iteratorError7 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion7 && _iterator7.return) {
              _iterator7.return();
            }
          } finally {
            if (_didIteratorError7) {
              throw _iteratorError7;
            }
          }
        }

        cov_uato2nufc.s[232]++;
        if ($scope.dashboardList[0]) {
          cov_uato2nufc.b[38][0]++;
          cov_uato2nufc.s[233]++;

          $scope.choise.selectedDashboard = $scope.dashboardList[0].id;
          cov_uato2nufc.s[234]++;
          $scope.selectDashboard($scope.dashboardList[0]);
        } else {
          cov_uato2nufc.b[38][1]++;
        }
      });
    }
  };
  cov_uato2nufc.s[235]++;
  $scope.getAll();
  cov_uato2nufc.s[236]++;
  $scope.userList = [];
  cov_uato2nufc.s[237]++;
  $CRUDService.getAll(PATH_USER, { get: 'all', simple: '1', p_bksb: 1 }, function (users) {
    cov_uato2nufc.f[55]++;
    cov_uato2nufc.s[238]++;

    $scope.userList = users;
  });
});

function homePageDashboardCtrl_edit($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_uato2nufc.f[56]++;

  var PATH = (cov_uato2nufc.s[239]++, 'Dashboard');
  cov_uato2nufc.s[240]++;
  $scope.validateItem = function () {
    cov_uato2nufc.f[57]++;

    var item = (cov_uato2nufc.s[241]++, angular.copy($scope.currentEditedDashbord));
    var listView = (cov_uato2nufc.s[242]++, []);
    var str = (cov_uato2nufc.s[243]++, angular.toJson(item.STRUCTURE));
    cov_uato2nufc.s[244]++;
    item.STRUCTURE = undefined;
    cov_uato2nufc.s[245]++;
    item.PUBLIC = item.PUBLIC ? (cov_uato2nufc.b[39][0]++, 1) : (cov_uato2nufc.b[39][1]++, 0);
    cov_uato2nufc.s[246]++;
    item.PORTFOLIO = item.PORTFOLIO ? (cov_uato2nufc.b[40][0]++, 1) : (cov_uato2nufc.b[40][1]++, 0);

    var params = (cov_uato2nufc.s[247]++, { action: 'MiseAJour', valeur: angular.toJson(item), c: str });

    cov_uato2nufc.s[248]++;
    if (!$scope.fromDashboard) {
      cov_uato2nufc.b[41][0]++;
      cov_uato2nufc.s[249]++;

      params.favoris = 1;
    } else {
      cov_uato2nufc.b[41][1]++;
    }

    cov_uato2nufc.s[250]++;
    $CRUDService.save(PATH, params, function (id) {
      cov_uato2nufc.f[58]++;
      cov_uato2nufc.s[251]++;

      if ($scope.fromDashboard) {
        cov_uato2nufc.b[42][0]++;
        cov_uato2nufc.s[252]++;

        $scope.initSelect();
        cov_uato2nufc.s[253]++;
        $scope.selectDashboard({ id: parseInt(id, 10) });
      } else {
        cov_uato2nufc.b[42][1]++;
        cov_uato2nufc.s[254]++;

        $scope.getAll({ id: id });
      }
      cov_uato2nufc.s[255]++;
      $scope.closeModal();
    });
  };

  cov_uato2nufc.s[256]++;
  $scope.closeModal = function () {
    cov_uato2nufc.f[59]++;
    cov_uato2nufc.s[257]++;

    $uibModalInstance.dismiss('cancel');
  };
}