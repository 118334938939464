'use strict';

var cov_i2k5yn43f = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/chart-syncfusion.js',
      hash = '669028ffb9fd747808f1f30f908b13591aafa22a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/chart-syncfusion.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 91,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 90,
          column: 4
        }
      },
      '2': {
        start: {
          line: 11,
          column: 6
        },
        end: {
          line: 13,
          column: 7
        }
      },
      '3': {
        start: {
          line: 12,
          column: 8
        },
        end: {
          line: 12,
          column: 25
        }
      },
      '4': {
        start: {
          line: 15,
          column: 21
        },
        end: {
          line: 53,
          column: 7
        }
      },
      '5': {
        start: {
          line: 16,
          column: 25
        },
        end: {
          line: 27,
          column: 9
        }
      },
      '6': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 30,
          column: 9
        }
      },
      '7': {
        start: {
          line: 29,
          column: 10
        },
        end: {
          line: 29,
          column: 17
        }
      },
      '8': {
        start: {
          line: 32,
          column: 20
        },
        end: {
          line: 32,
          column: 21
        }
      },
      '9': {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 44,
          column: 9
        }
      },
      '10': {
        start: {
          line: 34,
          column: 10
        },
        end: {
          line: 38,
          column: 11
        }
      },
      '11': {
        start: {
          line: 35,
          column: 12
        },
        end: {
          line: 37,
          column: 14
        }
      },
      '12': {
        start: {
          line: 40,
          column: 10
        },
        end: {
          line: 42,
          column: 11
        }
      },
      '13': {
        start: {
          line: 41,
          column: 12
        },
        end: {
          line: 41,
          column: 42
        }
      },
      '14': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 43,
          column: 18
        }
      },
      '15': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 50,
          column: 11
        }
      },
      '16': {
        start: {
          line: 47,
          column: 10
        },
        end: {
          line: 47,
          column: 55
        }
      },
      '17': {
        start: {
          line: 48,
          column: 10
        },
        end: {
          line: 48,
          column: 27
        }
      },
      '18': {
        start: {
          line: 49,
          column: 10
        },
        end: {
          line: 49,
          column: 42
        }
      },
      '19': {
        start: {
          line: 51,
          column: 8
        },
        end: {
          line: 51,
          column: 56
        }
      },
      '20': {
        start: {
          line: 52,
          column: 8
        },
        end: {
          line: 52,
          column: 41
        }
      },
      '21': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 59,
          column: 9
        }
      },
      '22': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 56,
          column: 28
        }
      },
      '23': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 58,
          column: 17
        }
      },
      '24': {
        start: {
          line: 61,
          column: 6
        },
        end: {
          line: 74,
          column: 9
        }
      },
      '25': {
        start: {
          line: 62,
          column: 8
        },
        end: {
          line: 62,
          column: 28
        }
      },
      '26': {
        start: {
          line: 64,
          column: 8
        },
        end: {
          line: 66,
          column: 9
        }
      },
      '27': {
        start: {
          line: 65,
          column: 10
        },
        end: {
          line: 65,
          column: 17
        }
      },
      '28': {
        start: {
          line: 67,
          column: 8
        },
        end: {
          line: 69,
          column: 9
        }
      },
      '29': {
        start: {
          line: 68,
          column: 10
        },
        end: {
          line: 68,
          column: 17
        }
      },
      '30': {
        start: {
          line: 70,
          column: 8
        },
        end: {
          line: 70,
          column: 70
        }
      },
      '31': {
        start: {
          line: 71,
          column: 8
        },
        end: {
          line: 73,
          column: 17
        }
      },
      '32': {
        start: {
          line: 72,
          column: 10
        },
        end: {
          line: 72,
          column: 92
        }
      },
      '33': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 81,
          column: 7
        }
      },
      '34': {
        start: {
          line: 77,
          column: 8
        },
        end: {
          line: 80,
          column: 11
        }
      },
      '35': {
        start: {
          line: 78,
          column: 10
        },
        end: {
          line: 78,
          column: 33
        }
      },
      '36': {
        start: {
          line: 79,
          column: 10
        },
        end: {
          line: 79,
          column: 67
        }
      },
      '37': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 87,
          column: 9
        }
      },
      '38': {
        start: {
          line: 84,
          column: 8
        },
        end: {
          line: 86,
          column: 9
        }
      },
      '39': {
        start: {
          line: 85,
          column: 10
        },
        end: {
          line: 85,
          column: 32
        }
      },
      '40': {
        start: {
          line: 88,
          column: 6
        },
        end: {
          line: 88,
          column: 15
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 51
          },
          end: {
            line: 1,
            column: 52
          }
        },
        loc: {
          start: {
            line: 1,
            column: 63
          },
          end: {
            line: 91,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 15,
            column: 21
          },
          end: {
            line: 15,
            column: 22
          }
        },
        loc: {
          start: {
            line: 15,
            column: 33
          },
          end: {
            line: 53,
            column: 7
          }
        },
        line: 15
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 46,
            column: 36
          },
          end: {
            line: 46,
            column: 37
          }
        },
        loc: {
          start: {
            line: 46,
            column: 46
          },
          end: {
            line: 50,
            column: 9
          }
        },
        line: 46
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 55,
            column: 19
          },
          end: {
            line: 55,
            column: 20
          }
        },
        loc: {
          start: {
            line: 55,
            column: 28
          },
          end: {
            line: 57,
            column: 7
          }
        },
        line: 55
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 57,
            column: 9
          },
          end: {
            line: 57,
            column: 10
          }
        },
        loc: {
          start: {
            line: 57,
            column: 21
          },
          end: {
            line: 59,
            column: 7
          }
        },
        line: 57
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 61,
            column: 19
          },
          end: {
            line: 61,
            column: 20
          }
        },
        loc: {
          start: {
            line: 61,
            column: 28
          },
          end: {
            line: 63,
            column: 7
          }
        },
        line: 61
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 63,
            column: 9
          },
          end: {
            line: 63,
            column: 10
          }
        },
        loc: {
          start: {
            line: 63,
            column: 21
          },
          end: {
            line: 74,
            column: 7
          }
        },
        line: 63
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 71,
            column: 17
          },
          end: {
            line: 71,
            column: 18
          }
        },
        loc: {
          start: {
            line: 71,
            column: 23
          },
          end: {
            line: 73,
            column: 9
          }
        },
        line: 71
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 77,
            column: 36
          },
          end: {
            line: 77,
            column: 37
          }
        },
        loc: {
          start: {
            line: 77,
            column: 45
          },
          end: {
            line: 80,
            column: 9
          }
        },
        line: 77
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 83,
            column: 28
          },
          end: {
            line: 83,
            column: 29
          }
        },
        loc: {
          start: {
            line: 83,
            column: 34
          },
          end: {
            line: 87,
            column: 7
          }
        },
        line: 83
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 11,
            column: 6
          },
          end: {
            line: 13,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 11,
            column: 6
          },
          end: {
            line: 13,
            column: 7
          }
        }, {
          start: {
            line: 11,
            column: 6
          },
          end: {
            line: 13,
            column: 7
          }
        }],
        line: 11
      },
      '1': {
        loc: {
          start: {
            line: 28,
            column: 8
          },
          end: {
            line: 30,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 8
          },
          end: {
            line: 30,
            column: 9
          }
        }, {
          start: {
            line: 28,
            column: 8
          },
          end: {
            line: 30,
            column: 9
          }
        }],
        line: 28
      },
      '2': {
        loc: {
          start: {
            line: 34,
            column: 10
          },
          end: {
            line: 38,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 34,
            column: 10
          },
          end: {
            line: 38,
            column: 11
          }
        }, {
          start: {
            line: 34,
            column: 10
          },
          end: {
            line: 38,
            column: 11
          }
        }],
        line: 34
      },
      '3': {
        loc: {
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 42,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 42,
            column: 11
          }
        }, {
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 42,
            column: 11
          }
        }],
        line: 40
      },
      '4': {
        loc: {
          start: {
            line: 47,
            column: 30
          },
          end: {
            line: 47,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 47,
            column: 30
          },
          end: {
            line: 47,
            column: 48
          }
        }, {
          start: {
            line: 47,
            column: 52
          },
          end: {
            line: 47,
            column: 54
          }
        }],
        line: 47
      },
      '5': {
        loc: {
          start: {
            line: 64,
            column: 8
          },
          end: {
            line: 66,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 64,
            column: 8
          },
          end: {
            line: 66,
            column: 9
          }
        }, {
          start: {
            line: 64,
            column: 8
          },
          end: {
            line: 66,
            column: 9
          }
        }],
        line: 64
      },
      '6': {
        loc: {
          start: {
            line: 64,
            column: 14
          },
          end: {
            line: 64,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 64,
            column: 14
          },
          end: {
            line: 64,
            column: 27
          }
        }, {
          start: {
            line: 64,
            column: 31
          },
          end: {
            line: 64,
            column: 44
          }
        }],
        line: 64
      },
      '7': {
        loc: {
          start: {
            line: 67,
            column: 8
          },
          end: {
            line: 69,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 8
          },
          end: {
            line: 69,
            column: 9
          }
        }, {
          start: {
            line: 67,
            column: 8
          },
          end: {
            line: 69,
            column: 9
          }
        }],
        line: 67
      },
      '8': {
        loc: {
          start: {
            line: 70,
            column: 28
          },
          end: {
            line: 70,
            column: 69
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 70,
            column: 28
          },
          end: {
            line: 70,
            column: 46
          }
        }, {
          start: {
            line: 70,
            column: 50
          },
          end: {
            line: 70,
            column: 69
          }
        }],
        line: 70
      },
      '9': {
        loc: {
          start: {
            line: 72,
            column: 29
          },
          end: {
            line: 72,
            column: 58
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 72,
            column: 45
          },
          end: {
            line: 72,
            column: 50
          }
        }, {
          start: {
            line: 72,
            column: 53
          },
          end: {
            line: 72,
            column: 58
          }
        }],
        line: 72
      },
      '10': {
        loc: {
          start: {
            line: 72,
            column: 60
          },
          end: {
            line: 72,
            column: 90
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 72,
            column: 60
          },
          end: {
            line: 72,
            column: 79
          }
        }, {
          start: {
            line: 72,
            column: 83
          },
          end: {
            line: 72,
            column: 90
          }
        }],
        line: 72
      },
      '11': {
        loc: {
          start: {
            line: 76,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 76,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        }, {
          start: {
            line: 76,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        }],
        line: 76
      },
      '12': {
        loc: {
          start: {
            line: 79,
            column: 36
          },
          end: {
            line: 79,
            column: 65
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 79,
            column: 36
          },
          end: {
            line: 79,
            column: 54
          }
        }, {
          start: {
            line: 79,
            column: 58
          },
          end: {
            line: 79,
            column: 65
          }
        }],
        line: 79
      },
      '13': {
        loc: {
          start: {
            line: 84,
            column: 8
          },
          end: {
            line: 86,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 84,
            column: 8
          },
          end: {
            line: 86,
            column: 9
          }
        }, {
          start: {
            line: 84,
            column: 8
          },
          end: {
            line: 86,
            column: 9
          }
        }],
        line: 84
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_i2k5yn43f.s[0]++;
angular.module('app').directive('chartSyncfusion', function ($timeout) {
  cov_i2k5yn43f.f[0]++;
  cov_i2k5yn43f.s[1]++;

  return {
    restrict: 'A',
    scope: {
      params: '=',
      export: '=?',
      exportWithRightClick: '=',
      idMenuContextuel: '@?'
    },
    link: function link(scope, element) {
      cov_i2k5yn43f.s[2]++;

      if (!scope.export) {
        cov_i2k5yn43f.b[0][0]++;
        cov_i2k5yn43f.s[3]++;

        scope.export = 0;
      } else {
        cov_i2k5yn43f.b[0][1]++;
      }

      cov_i2k5yn43f.s[4]++;
      var render = function render() {
        cov_i2k5yn43f.f[1]++;

        var palettes = (cov_i2k5yn43f.s[5]++, ['#BAF171', '#C4EAF6', '#E9B1FF', '#F49C94', '#FCF086', '#58D740', '#7DDAF8', '#CA6FEE', '#FE855F', '#F9E850', '#1EB799', '#29DAE9', '#B145F4', '#CD5145', '#EDDA2F', '#2EAB77', '#20C9B3', '#6F43EA', '#EB5757', '#EDC32F', '#66AF45', '#33ACED', '#562EBB', '#DA4A2B', '#D39F17', '#78A73D', '#208AC9', '#792AF9', '#F35634', '#B8953B', '#A5B212', '#3160BC', '#651E91', '#AE534B', '#F38434', '#12A5B2', '#5C83E7', '#911E71', '#7E3737', '#D36617', '#2F7980', '#035C88', '#401C5B', '#5E4138', '#B66225', '#0E3E53', '#093B69', '#240E35', '#3E2B11', '#B24712']);
        cov_i2k5yn43f.s[6]++;
        if (!scope.params) {
          cov_i2k5yn43f.b[1][0]++;
          cov_i2k5yn43f.s[7]++;

          return;
        } else {
          cov_i2k5yn43f.b[1][1]++;
        }

        var index = (cov_i2k5yn43f.s[8]++, 0);
        cov_i2k5yn43f.s[9]++;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = scope.params.series[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var series = _step.value;
            cov_i2k5yn43f.s[10]++;

            if (series.type == 'Column') {
              cov_i2k5yn43f.b[2][0]++;
              cov_i2k5yn43f.s[11]++;

              series.cornerRadius = {
                topLeft: 10, topRight: 10
              };
            } else {
              cov_i2k5yn43f.b[2][1]++;
            }

            cov_i2k5yn43f.s[12]++;
            if (!series.fill) {
              cov_i2k5yn43f.b[3][0]++;
              cov_i2k5yn43f.s[13]++;

              series.fill = palettes[index];
            } else {
              cov_i2k5yn43f.b[3][1]++;
            }
            cov_i2k5yn43f.s[14]++;
            index++;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        cov_i2k5yn43f.s[15]++;
        scope.params.afterExport = function (params) {
          cov_i2k5yn43f.f[2]++;
          cov_i2k5yn43f.s[16]++;

          scope.chart.title = (cov_i2k5yn43f.b[4][0]++, scope.params.title) || (cov_i2k5yn43f.b[4][1]++, '');
          cov_i2k5yn43f.s[17]++;
          scope.export = 0;
          cov_i2k5yn43f.s[18]++;
          scope.$emit('afterChartExport');
        };
        cov_i2k5yn43f.s[19]++;
        scope.chart = new ej.charts.Chart(scope.params);
        cov_i2k5yn43f.s[20]++;
        scope.chart.appendTo(element[0]);
      };

      cov_i2k5yn43f.s[21]++;
      scope.$watch(function (scope) {
        cov_i2k5yn43f.f[3]++;
        cov_i2k5yn43f.s[22]++;

        return scope.params;
      }, function (newValue) {
        cov_i2k5yn43f.f[4]++;
        cov_i2k5yn43f.s[23]++;

        render();
      });

      cov_i2k5yn43f.s[24]++;
      scope.$watch(function (scope) {
        cov_i2k5yn43f.f[5]++;
        cov_i2k5yn43f.s[25]++;

        return scope.export;
      }, function (newValue) {
        cov_i2k5yn43f.f[6]++;
        cov_i2k5yn43f.s[26]++;

        if (!((cov_i2k5yn43f.b[6][0]++, newValue == 1) || (cov_i2k5yn43f.b[6][1]++, newValue == 2))) {
          cov_i2k5yn43f.b[5][0]++;
          cov_i2k5yn43f.s[27]++;

          return;
        } else {
          cov_i2k5yn43f.b[5][1]++;
        }
        cov_i2k5yn43f.s[28]++;
        if (!scope.params) {
          cov_i2k5yn43f.b[7][0]++;
          cov_i2k5yn43f.s[29]++;

          return;
        } else {
          cov_i2k5yn43f.b[7][1]++;
        }
        cov_i2k5yn43f.s[30]++;
        scope.chart.title = (cov_i2k5yn43f.b[8][0]++, scope.params.title) || (cov_i2k5yn43f.b[8][1]++, scope.params.title_);
        cov_i2k5yn43f.s[31]++;
        $timeout(function () {
          cov_i2k5yn43f.f[7]++;
          cov_i2k5yn43f.s[32]++;

          scope.chart.export(newValue == 1 ? (cov_i2k5yn43f.b[9][0]++, 'PNG') : (cov_i2k5yn43f.b[9][1]++, 'PDF'), (cov_i2k5yn43f.b[10][0]++, scope.params.title_) || (cov_i2k5yn43f.b[10][1]++, 'chart'));
        }, 1500);
      });

      cov_i2k5yn43f.s[33]++;
      if (scope.exportWithRightClick) {
        cov_i2k5yn43f.b[11][0]++;
        cov_i2k5yn43f.s[34]++;

        element.bind('contextmenu', function (event) {
          cov_i2k5yn43f.f[8]++;
          cov_i2k5yn43f.s[35]++;

          event.preventDefault();
          cov_i2k5yn43f.s[36]++;
          scope.chart.export('PNG', (cov_i2k5yn43f.b[12][0]++, scope.params.title) || (cov_i2k5yn43f.b[12][1]++, 'chart'));
        });
      } else {
        cov_i2k5yn43f.b[11][1]++;
      }

      cov_i2k5yn43f.s[37]++;
      scope.$on('$destroy', function () {
        cov_i2k5yn43f.f[9]++;
        cov_i2k5yn43f.s[38]++;

        if (scope.chart) {
          cov_i2k5yn43f.b[13][0]++;
          cov_i2k5yn43f.s[39]++;

          scope.chart.destroy();
        } else {
          cov_i2k5yn43f.b[13][1]++;
        }
      });
      cov_i2k5yn43f.s[40]++;
      render();
    }
  };
});