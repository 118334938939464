'use strict';

var cov_256vlqqadz = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/assets/js/plugins/angular-sweetalert.min.js',
      hash = '54ea1d40846e3eeb9e0ec2e116cbd725ae2e0e0d',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/assets/js/plugins/angular-sweetalert.min.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 30,
          column: 4
        }
      },
      '1': {
        start: {
          line: 3,
          column: 15
        },
        end: {
          line: 3,
          column: 26
        }
      },
      '2': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 28,
          column: 4
        }
      },
      '3': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 11,
          column: 7
        }
      },
      '4': {
        start: {
          line: 6,
          column: 6
        },
        end: {
          line: 10,
          column: 40
        }
      },
      '5': {
        start: {
          line: 7,
          column: 8
        },
        end: {
          line: 9,
          column: 11
        }
      },
      '6': {
        start: {
          line: 8,
          column: 10
        },
        end: {
          line: 8,
          column: 26
        }
      },
      '7': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 15,
          column: 7
        }
      },
      '8': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 14,
          column: 38
        }
      },
      '9': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 19,
          column: 7
        }
      },
      '10': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 36
        }
      },
      '11': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 23,
          column: 7
        }
      },
      '12': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 38
        }
      },
      '13': {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 27,
          column: 7
        }
      },
      '14': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 35
        }
      },
      '15': {
        start: {
          line: 29,
          column: 2
        },
        end: {
          line: 29,
          column: 14
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 81
          },
          end: {
            line: 2,
            column: 82
          }
        },
        loc: {
          start: {
            line: 2,
            column: 103
          },
          end: {
            line: 30,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 5,
            column: 26
          },
          end: {
            line: 5,
            column: 27
          }
        },
        loc: {
          start: {
            line: 5,
            column: 32
          },
          end: {
            line: 11,
            column: 5
          }
        },
        line: 5
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 6,
            column: 45
          },
          end: {
            line: 6,
            column: 46
          }
        },
        loc: {
          start: {
            line: 6,
            column: 58
          },
          end: {
            line: 10,
            column: 7
          }
        },
        line: 6
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 7,
            column: 30
          },
          end: {
            line: 7,
            column: 31
          }
        },
        loc: {
          start: {
            line: 7,
            column: 36
          },
          end: {
            line: 9,
            column: 9
          }
        },
        line: 7
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 13,
            column: 26
          },
          end: {
            line: 13,
            column: 27
          }
        },
        loc: {
          start: {
            line: 13,
            column: 32
          },
          end: {
            line: 15,
            column: 5
          }
        },
        line: 13
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 17,
            column: 26
          },
          end: {
            line: 17,
            column: 27
          }
        },
        loc: {
          start: {
            line: 17,
            column: 32
          },
          end: {
            line: 19,
            column: 5
          }
        },
        line: 17
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 21,
            column: 26
          },
          end: {
            line: 21,
            column: 27
          }
        },
        loc: {
          start: {
            line: 21,
            column: 32
          },
          end: {
            line: 23,
            column: 5
          }
        },
        line: 21
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 25,
            column: 26
          },
          end: {
            line: 25,
            column: 27
          }
        },
        loc: {
          start: {
            line: 25,
            column: 32
          },
          end: {
            line: 27,
            column: 5
          }
        },
        line: 25
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 6
          },
          end: {
            line: 10,
            column: 39
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 6,
            column: 34
          },
          end: {
            line: 10,
            column: 14
          }
        }, {
          start: {
            line: 10,
            column: 17
          },
          end: {
            line: 10,
            column: 39
          }
        }],
        line: 6
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_256vlqqadz.s[0]++;
angular.module('oitozero.ngSweetAlert', []).factory('SweetAlert', ['$rootScope', function ($rootScope) {
  cov_256vlqqadz.f[0]++;

  var _swal = (cov_256vlqqadz.s[1]++, window.swal);
  var self = (cov_256vlqqadz.s[2]++, {
    swal: function swal(arg1, arg2, arg3) {
      cov_256vlqqadz.s[3]++;

      $rootScope.$evalAsync(function () {
        cov_256vlqqadz.f[1]++;
        cov_256vlqqadz.s[4]++;

        typeof arg2 == 'function' ? (cov_256vlqqadz.b[0][0]++, _swal(arg1, function (isConfirm) {
          cov_256vlqqadz.f[2]++;
          cov_256vlqqadz.s[5]++;
          // jshint ignore:line
          $rootScope.$evalAsync(function () {
            cov_256vlqqadz.f[3]++;
            cov_256vlqqadz.s[6]++;

            arg2(isConfirm);
          });
        }, arg3)) : (cov_256vlqqadz.b[0][1]++, _swal(arg1, arg2, arg3));
      });
    },
    success: function success(title, message) {
      cov_256vlqqadz.s[7]++;

      $rootScope.$evalAsync(function () {
        cov_256vlqqadz.f[4]++;
        cov_256vlqqadz.s[8]++;

        _swal(title, message, 'success');
      });
    },
    error: function error(title, message) {
      cov_256vlqqadz.s[9]++;

      $rootScope.$evalAsync(function () {
        cov_256vlqqadz.f[5]++;
        cov_256vlqqadz.s[10]++;

        _swal(title, message, 'error');
      });
    },
    warning: function warning(title, message) {
      cov_256vlqqadz.s[11]++;

      $rootScope.$evalAsync(function () {
        cov_256vlqqadz.f[6]++;
        cov_256vlqqadz.s[12]++;

        _swal(title, message, 'warning');
      });
    },
    info: function info(title, message) {
      cov_256vlqqadz.s[13]++;

      $rootScope.$evalAsync(function () {
        cov_256vlqqadz.f[7]++;
        cov_256vlqqadz.s[14]++;

        _swal(title, message, 'info');
      });
    }
  });
  cov_256vlqqadz.s[15]++;
  return self;
}]);