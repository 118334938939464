'use strict';

var cov_2n7wpr0qo0 = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/profil/ProfileCtrl.js',
      hash = '7b006c1e265c97babe5428ad0276041decd4eb8e',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/profil/ProfileCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 6,
          column: 3
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 48
          },
          end: {
            line: 4,
            column: 49
          }
        },
        loc: {
          start: {
            line: 4,
            column: 160
          },
          end: {
            line: 6,
            column: 1
          }
        },
        line: 4
      }
    },
    branchMap: {},
    s: {
      '0': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2n7wpr0qo0.s[0]++;
/**
 * Created by BKSB on 16/05/2017.
 */
angular.module('app').controller('ProfileCtrl', function ($scope, SweetAlert, $uibModal, $translate, $CRUDService, $otherHttpService, $location, $filter, $rootScope) {
  cov_2n7wpr0qo0.f[0]++;
});